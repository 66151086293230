import React from 'react';
import {css} from '@emotion/react';
import {BREAKPOINT_MEDIUM} from '@riiid/design-system';

type SwitchContainerProps = React.ComponentPropsWithoutRef<'div'>;

const SwitchContainer = React.forwardRef<HTMLDivElement, SwitchContainerProps>((props, ref) => {
  return (
    <div
      css={css`
        display: grid;
        grid-gap: 24px;
        gap: 24px;

        @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
          display: flex;
          justify-content: space-between;
        }
      `}
      ref={ref}
      {...props}
    />
  );
});

export default React.memo(SwitchContainer);
