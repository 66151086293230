import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query';
import {format} from 'date-fns';
import {useAtomValue} from 'jotai';
import {UpdateExamScoreRequest, UpdateExamScoreResponse} from '@santa-web/gen/open-api/service';
import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {getExamScoresQueryKey} from '@app/queryKeys';

type PatchExamScoreInput = {
  examDate: Date;
  totalScore: UpdateExamScoreRequest['totalScore'];
  partialScores: UpdateExamScoreRequest['partialScores'];
};

const usePatchExamScoreMutation = (
  option?: UseMutationOptions<UpdateExamScoreResponse, unknown, PatchExamScoreInput, unknown>
) => {
  const queryClient = useQueryClient();
  const {ExamScoreService} = useAtomValue(santaOpenapiServicesAtom);

  return useMutation(
    ({
      examDate,
      totalScore,
      partialScores,
    }: {
      examDate: Date;
      totalScore: UpdateExamScoreRequest['totalScore'];
      partialScores: UpdateExamScoreRequest['partialScores'];
    }) =>
      ExamScoreService.updateExamScore({
        examDate: format(examDate, 'yyyy-MM-dd') as unknown as Date,
        updateExamScoreRequest: {totalScore, partialScores},
      }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getExamScoresQueryKey());
      },
      ...option,
    }
  );
};

export default usePatchExamScoreMutation;
