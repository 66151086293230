import {useState} from 'react';
import {Field} from 'react-final-form';
import {useTranslation} from 'react-i18next';
import {COLOR_SANTA_L} from '@riiid/design-system';
import {format} from 'date-fns';
import {Typography} from '@santa-web/service-ui';
import {useDialogContext} from '@app/contexts/DialogContext';
import {
  ComboboxButton,
  ExamDatePickBottomSheet,
  ExamScoreInputField,
} from '@app/features/preferences/components/ExamScore';
import {ScoreConfig, StringDate} from '@app/features/preferences/types';

type ExamScoreFormFieldsProps = {
  scoreConfig: ScoreConfig;
  defaultDate?: StringDate;
  validDates: StringDate[];
  onDatePick: (date: StringDate) => void;
};

/**
 *
 * This form is has dependency with 'react-final-form' \<Form\> component.
 *
 * must render this component inside \<Form\> component.
 */
const ExamScoreFormFields = ({scoreConfig, validDates, onDatePick, defaultDate}: ExamScoreFormFieldsProps) => {
  const [isComboboxClicked, setIsComboboxClicked] = useState(false);
  const {openDialog, closeDialog} = useDialogContext();
  const {t} = useTranslation();
  const isDatePickerDisabled = validDates.length === 0;
  const onComboboxClick = () => {
    openDialog(
      <ExamDatePickBottomSheet
        defaultValue={defaultDate}
        dates={validDates}
        onCloseClick={() => {
          setIsComboboxClicked(false);
          closeDialog();
        }}
        onConfirm={onDatePick}
      />
    );
  };

  return (
    <>
      <Field name="date">
        {({input}) => {
          return (
            <ComboboxButton
              isDisabled={isDatePickerDisabled}
              value={input.value ? format(new Date(input.value), 'yyyy.MM.dd.') : ''}
              onClick={() => {
                setIsComboboxClicked(true);
                onComboboxClick();
              }}
              isSelected={isComboboxClicked}
              placeholder={t(`page_exam_score_form_date_input_placeholder`)}
              label={
                <Typography variant="caption1" color={COLOR_SANTA_L}>
                  {t('page_exam_score_form_date_input_label')}
                </Typography>
              }
            />
          );
        }}
      </Field>

      {scoreConfig.partials?.map((part, index) => (
        <ExamScoreInputField
          key={part.name}
          {...part}
          // final-form이 numeric key를 지원하지 않아서 key를 문자열로 변경. 해당 부분은 submitHandler에서 다시 배열로 치환
          // https://final-form.org/docs/react-final-form/faq#why-cant-i-have-numeric-keys-in-an-object
          name={`partialScores.key-${index}.score`}
          label={t(`page_exam_score_form_part_score_input_label`, {part: part.name})}
        />
      ))}
    </>
  );
};

export default ExamScoreFormFields;
export type {ExamScoreFormFieldsProps};
ExamScoreFormFields.displayName = 'ExamScoreFormFields';
