import {atom} from 'jotai';

import {cisService} from '@app/test-preparation/atoms/services';
import {CisesOverflowError} from '@app/test-preparation/atoms/services/cis/errors/cises-overflow';
import {PermissionDeniedError} from '@app/test-preparation/atoms/services/shared/errors/permission-denied';

type LoadNextCisesParams = {
  onPermissionDenied?(): Promise<void>;
  onCellDone?(): Promise<void>;
};

const loadNextCisesAtom = atom(null, async (get, set, {onPermissionDenied, onCellDone}: LoadNextCisesParams = {}) => {
  try {
    await set(cisService.loadNextCisesAtom);

    return true;
  } catch (error) {
    if (error instanceof PermissionDeniedError && onPermissionDenied) {
      await onPermissionDenied();

      return false;
    }

    if (error instanceof CisesOverflowError && onCellDone) {
      await onCellDone();

      return false;
    }

    throw error;
  }
});

export {loadNextCisesAtom};
