import React, {useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useInterval} from 'react-use';
import {css} from '@emotion/react';

import {Dim, DimProps, SystemErrorLayout} from '@santa-web/service-ui';

export interface LcAutoplayDialogProps extends DimProps {
  initialSecondLeft: number;
  playLc: () => void;
}

const LcAutoplayDialog = React.forwardRef<HTMLDivElement, LcAutoplayDialogProps>((props, ref) => {
  const {initialSecondLeft, playLc, ...rest} = props;
  const {t} = useTranslation();

  const [secondLeft, setSecondLeft] = useState(initialSecondLeft);

  useInterval(
    () => {
      if (secondLeft <= 0) return;
      const nextSecondLeft = secondLeft - 1;
      if (nextSecondLeft <= 0) {
        playLc();
      }
      setSecondLeft(nextSecondLeft);
    },
    secondLeft > 0 ? 1000 : null
  );

  return (
    <Dim
      css={css`
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      `}
      color="black"
      ref={ref}
      {...rest}>
      <SystemErrorLayout
        color="white-fix"
        css={css`
          white-space: pre-line;
        `}
        LottieAnimationProps={{
          autoplay: true,
          width: 40,
          height: 40,
        }}
        icon="equalizer-with-circle"
        title={t('virtual_exam_lc_autoplay_dialog_title', {secondLeft})}
        description={t('virtual_exam_lc_autoplay_dialog_description')}
      />
    </Dim>
  );
});

export default React.memo(LcAutoplayDialog);
