import React from 'react';
import {useTranslation} from 'react-i18next';
import {useAtomValue} from 'jotai';

import {TopNavBar as TopNavBarBase, TopNavBarProps} from '@santa-web/service-ui';
import {learningStore} from '@app/test-preparation/atoms/stores';

type Props = TopNavBarProps;

const ToeicCourseLessonTopNavBar = (props: Props): JSX.Element => {
  const {t} = useTranslation();

  const totalCisCount = useAtomValue(learningStore.cises.totalCisCountAtom);
  const completedCisCount = useAtomValue(learningStore.cises.completedCisCountAtom);
  const isLastCis = useAtomValue(learningStore.cises.isLastCisAtom);
  const lessonCisCount = useAtomValue(learningStore.cises.vocabularyCisCountAtom);
  const questionCisCount = useAtomValue(learningStore.cises.questionCisCountAtom);
  const totalElapsedTime = useAtomValue(learningStore.cises.totalElapsedTimeAtom);

  const isLesson = useAtomValue(learningStore.cis.isLessonAtom);

  const content = (() => {
    if (isLesson) {
      return t('learning_cell_top_nav_bar_course_lesson_content_lesson', {
        numberOfLessons: lessonCisCount - completedCisCount,
        numberOfQuestions: questionCisCount,
      });
    }

    if (isLastCis) {
      return t('learning_cell_top_nav_bar_course_lesson_content_last_question');
    }

    return t('learning_cell_top_nav_bar_course_lesson_content_question', {
      numberOfQuestions: totalCisCount - completedCisCount,
    });
  })();
  const progress = !isLesson ? (completedCisCount - lessonCisCount + 1) / questionCisCount : undefined;
  const timerSeconds = totalElapsedTime / 1000;

  return (
    <TopNavBarBase content={content} paginationIcon="exit" progress={progress} timerSeconds={timerSeconds} {...props} />
  );
};

export default React.memo(ToeicCourseLessonTopNavBar);
