import React from 'react';
import {css} from '@emotion/react';

import {SingleQuestionTitle} from '@santa-web/service-ui';
import DirectionContent from '@app/components/virtual-exam/VirtualExamDirectionView/components/DirectionContent';
import DirectionWrapper from '@app/components/virtual-exam/VirtualExamDirectionView/components/DirectionWrapper';

const Part1DirectionView = React.forwardRef<HTMLDivElement, React.ComponentPropsWithoutRef<'div'>>((props, ref) => {
  return (
    <DirectionWrapper {...props} ref={ref}>
      <DirectionWrapper gap={24}>
        <SingleQuestionTitle content={'LISTENING TEST'} />
        <DirectionContent>
          In the Listening test, you will be asked to demonstrate how well you understand spoken English. There are four
          parts, and directions are given for each part. You must mark your answer on the separate answer sheet. Do not
          write your answer in your test book.
        </DirectionContent>
      </DirectionWrapper>
      <DirectionWrapper gap={24}>
        <SingleQuestionTitle content={'PART 1'} />
        <DirectionContent>
          <b>Directions:</b> For each question in this part, you will hear four statements about a picture in your test
          book. When you hear the statements, you must select the one statement that best describes what you see in the
          picture. Then find the number of the question on your answer sheet and mark your answer. The statements will
          not be printed in your test book and will be spoken only one time.
        </DirectionContent>
        <img
          src="/img/virtual-exam/direction/part1.png"
          css={css`
            width: 100%;
            height: auto;
          `}
        />
        <DirectionContent>
          Statement (B), “The woman is typing on a keyboard” is the best description of the picture, so you should
          select answer (B) and mark it on your answer sheet.
        </DirectionContent>
      </DirectionWrapper>
    </DirectionWrapper>
  );
});

export default Part1DirectionView;
