import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {Column, InfinityList} from '@app/components';
import {
  CouponType,
  DiscountCouponCard,
  PermissionCouponCard,
  Coupon,
  CouponListEmpty,
  CouponListTabValue,
  CouponListInformation,
  CouponCardSkeleton,
} from '@app/features/coupon';

type CouponListProps = {
  coupons: Coupon[];
  isLoading: boolean;
  currentTab: CouponListTabValue;
  learningDomain: string;
  onEndReached: () => void;
  onCouponUseClick: (coupon: Coupon) => void;
};

const CouponList = ({
  coupons,
  isLoading,
  currentTab,
  learningDomain,
  onEndReached,
  onCouponUseClick,
}: CouponListProps) => {
  const {t} = useTranslation();
  const skeletonItems = [...new Array(3)];

  return (
    <Column gap={40}>
      <CouponListInformation learningDomain={learningDomain} />
      <InfinityList
        css={css`
          display: flex;
          flex-direction: column;
          gap: 12px;
        `}
        onEndReached={onEndReached}
        items={isLoading ? skeletonItems : coupons}
        getKey={(_, index) => `${index}`}
        emptyComponent={
          <CouponListEmpty
            text={
              currentTab === 'AVAILABLE'
                ? t('page_coupon_box_list_available_empty')
                : t('page_coupon_box_list_unavailable_empty')
            }
          />
        }
        renderItem={
          isLoading
            ? () => <CouponCardSkeleton />
            : coupon => {
                return coupon.couponType === CouponType.DISCOUNT ? (
                  <DiscountCouponCard
                    status={coupon.status}
                    displayName={coupon.displayName}
                    discountAmountWithUnit={coupon.discountAmountWithUnit}
                    minimumPriceToApplyWithCurrency={coupon.minimumPriceToApplyWithCurrency}
                    maximumDiscountAmountWithCurrency={coupon.maximumDiscountAmountWithCurrency}
                    from={coupon.from}
                    to={coupon.to}
                  />
                ) : (
                  <PermissionCouponCard
                    status={coupon.status}
                    displayName={coupon.displayName}
                    onCouponUseClick={() => onCouponUseClick(coupon)}
                    includes={coupon.includes}
                  />
                );
              }
        }
      />
    </Column>
  );
};

export default CouponList;
export type {CouponListProps};
CouponList.displayName = 'CouponList';
