import React from 'react';
import {css} from '@emotion/react';
import {
  BREAKPOINT_MEDIUM,
  COLOR_SANTA_A,
  COLOR_SANTA_D,
  COLOR_SANTA_DD,
  COLOR_SANTA_E,
  COLOR_SANTA_G,
  COLOR_SANTA_H,
  COLOR_SANTA_I,
  COLOR_SANTA_J,
  COLOR_SANTA_L,
} from '@riiid/design-system';
import {getLineClampCss, Label, Typography} from '@santa-web/service-ui';
import {BaseCard, Row, SkeletonGrayBox} from '@app/components';
import {PermitLabel, PermitLabelVariant} from '@app/features/permit';
import {withSkeleton} from '@app/utils/component';

type InformationListItem = {
  title: string;
  value?: string;
  label?: string;
  description?: string;
  isMultiline?: boolean;
  isDanger?: boolean;
};

type AdditionalInformation = {
  grantedFrom: string;
  permitTypes: string[];
};

type BasePermitCardProps = {
  title: string;
  description: string;
  learningDomain?: string;
  status: Exclude<PermitLabelVariant, 'subscription'>;
  listItems: InformationListItem[];
  additionalInformation?: AdditionalInformation;
  button?: React.ReactNode;
  isSubscription?: boolean;
};

const BasePermitCard = ({
  title,
  description,
  learningDomain,
  status,
  listItems,
  additionalInformation,
  button,
  isSubscription,
}: BasePermitCardProps) => {
  const shouldShowAdditionalInformation = !!additionalInformation;
  return (
    <BaseCard
      css={[
        css`
          display: grid;
          gap: 16px;
          padding: 20px;
          background-color: ${COLOR_SANTA_A};
          border: 1px solid ${COLOR_SANTA_D};
        `,
      ]}>
      <div
        css={css`
          display: grid;
          gap: 4px;
        `}>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 4px;
            @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
              flex-direction: row-reverse;
              justify-content: space-between;
              align-items: center;
              gap: 12px;
            }
          `}>
          <Row
            gap={8}
            alignItems="center"
            css={css`
              align-self: end;
            `}>
            {learningDomain && (
              <>
                <Label colorVariant="gray" variant="small">
                  {learningDomain}
                </Label>
                <div
                  css={css`
                    height: 16px;
                    width: 1px;
                    background-color: ${COLOR_SANTA_E};
                  `}
                />
              </>
            )}
            <Row gap={4}>
              {isSubscription && shouldShowAdditionalInformation && <PermitLabel variant="subscription" />}
              <PermitLabel variant={status} />
            </Row>
          </Row>
          {shouldShowAdditionalInformation && (
            <Typography variant="body3" color={COLOR_SANTA_L}>
              {additionalInformation.grantedFrom}
            </Typography>
          )}
        </div>
        <Typography variant="body1" fontWeight="bold" color={COLOR_SANTA_L}>
          {title}
        </Typography>
        {shouldShowAdditionalInformation && (
          <Typography
            css={css`
              ${getLineClampCss(1)}
              display: flex;
              align-items: center;
              gap: 4px;
            `}
            component="p"
            variant="body3"
            color={COLOR_SANTA_I}>
            {additionalInformation.permitTypes.map((type, idx, arr) => (
              <React.Fragment key={idx}>
                <span>{type}</span>
                {idx !== arr.length - 1 && <span>|</span>}
              </React.Fragment>
            ))}
          </Typography>
        )}
        <Typography variant="caption1" component="p" color={COLOR_SANTA_G}>
          {description}
        </Typography>
      </div>
      <hr
        css={css`
          margin: 0;
          border: none;
          border-bottom: 1px dashed ${COLOR_SANTA_L};
        `}
      />
      <ul
        css={css`
          display: grid;
          gap: 8px;

          list-style: none;
          margin: 0;
          padding: 0;
        `}>
        {listItems.map(({title, value, label, description, isDanger, isMultiline}) => (
          <li key={title}>
            <div
              css={css`
                display: flex;
                justify-content: space-between;
                align-items: center;
              `}>
              <Typography component="p" variant="caption1" color={COLOR_SANTA_J}>
                {title}
              </Typography>
              <Typography
                css={css`
                  display: flex;
                  gap: 8px;
                `}
                component="p"
                variant="caption1">
                {!isMultiline && (
                  <>
                    {value && (
                      <Typography component="span" variant="caption1" color={isDanger ? COLOR_SANTA_DD : COLOR_SANTA_H}>
                        {value}
                      </Typography>
                    )}
                    {value && label && (
                      <Typography component="span" variant="caption1" color={isDanger ? COLOR_SANTA_DD : COLOR_SANTA_I}>
                        |
                      </Typography>
                    )}
                  </>
                )}
                {label && (
                  <Typography
                    component="span"
                    variant="caption1"
                    fontWeight="bold"
                    color={isDanger ? COLOR_SANTA_DD : COLOR_SANTA_J}>
                    {label}
                  </Typography>
                )}
              </Typography>
            </div>
            {isMultiline && value && (
              <Typography
                css={css`
                  margin-top: 8px;
                `}
                component="p"
                variant="caption1"
                color={isDanger ? COLOR_SANTA_DD : COLOR_SANTA_H}>
                {value}
              </Typography>
            )}
            {description && (
              <Typography
                css={css`
                  margin-top: 4px;
                `}
                component="p"
                variant="caption1"
                color={COLOR_SANTA_G}>
                {description}
              </Typography>
            )}
          </li>
        ))}
      </ul>
      {button && button}
    </BaseCard>
  );
};

const Skeleton = () => {
  return (
    <BaseCard
      css={css`
        padding: 40px 20px 24px;
      `}>
      <SkeletonGrayBox
        css={css`
          margin: 0 0 24px auto;
        `}
        width={100}
        height={20}
      />
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: flex-start;
          gap: 12px;
          margin-bottom: 24px;
        `}>
        <SkeletonGrayBox width={122} height={12} />
        <SkeletonGrayBox width={200} height={32} />
        <SkeletonGrayBox width="100%" height={12} />
        <SkeletonGrayBox width="100%" height={12} />
      </div>
      <div
        css={css`
          display: flex;
          justify-content: space-between;
          gap: 80px;
        `}>
        <SkeletonGrayBox width={100} height={20} />
        <SkeletonGrayBox width="50%" height={20} />
      </div>
    </BaseCard>
  );
};

export default withSkeleton(BasePermitCard, Skeleton);
export type {BasePermitCardProps};
BasePermitCard.displayName = 'BasePermitCard';
