import { ImageSetFromJSON, ImageSetToJSON, } from './ImageSet';
import { PeriodFromJSON, PeriodToJSON, } from './Period';
import { ThumbnailContentImageSetAlignFromJSON, ThumbnailContentImageSetAlignToJSON, } from './ThumbnailContentImageSetAlign';
/**
 * Check if a given object implements the OfferGroupDisplayConfig interface.
 */
export function instanceOfOfferGroupDisplayConfig(value) {
    let isInstance = true;
    isInstance = isInstance && "detailImageSet" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "period" in value;
    isInstance = isInstance && "thumbnailBgImageSet" in value;
    isInstance = isInstance && "thumbnailContentImageSet" in value;
    isInstance = isInstance && "thumbnailContentImageSetAlign" in value;
    return isInstance;
}
export function OfferGroupDisplayConfigFromJSON(json) {
    return OfferGroupDisplayConfigFromJSONTyped(json, false);
}
export function OfferGroupDisplayConfigFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'detailImageSet': ImageSetFromJSON(json['detailImageSet']),
        'name': json['name'],
        'period': PeriodFromJSON(json['period']),
        'thumbnailBgImageSet': ImageSetFromJSON(json['thumbnailBgImageSet']),
        'thumbnailContentImageSet': ImageSetFromJSON(json['thumbnailContentImageSet']),
        'thumbnailContentImageSetAlign': ThumbnailContentImageSetAlignFromJSON(json['thumbnailContentImageSetAlign']),
    };
}
export function OfferGroupDisplayConfigToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'detailImageSet': ImageSetToJSON(value.detailImageSet),
        'name': value.name,
        'period': PeriodToJSON(value.period),
        'thumbnailBgImageSet': ImageSetToJSON(value.thumbnailBgImageSet),
        'thumbnailContentImageSet': ImageSetToJSON(value.thumbnailContentImageSet),
        'thumbnailContentImageSetAlign': ThumbnailContentImageSetAlignToJSON(value.thumbnailContentImageSetAlign),
    };
}
