import React from 'react';

import {
  ChatMessage,
  ChatMessageRole,
  ChatMessageStatus,
  ChatMessageFeedback,
} from '@santa-web/gen/open-api/content-learning-service';
import {ChatSantaBubbleFeedback} from '@santa-web/service-ui';
import useTestprepEvents from '@app/api/google-tag-manager/testprep';
import useChatMessagesQuery from '@app/hooks/chat/useChatMessagesQuery';
import useChatRoomQuery from '@app/hooks/chat/useChatRoomQuery';
import useDeleteChatMessageMutation from '@app/hooks/chat/useDeleteChatMessageMutation';
import useFeedbackChatMessageMutation from '@app/hooks/chat/useFeedbackChatMessageMutation';
import useReplyChatMessageMutation from '@app/hooks/chat/useReplyChatMessageMutation';
import useResendChatMessageMutation from '@app/hooks/chat/useResendChatMessageMutation';
import useSendChatMessageMutation from '@app/hooks/chat/useSendChatMessageMutation';
import {useNetwork} from '@app/hooks/useNetwork';
import ChatLayout, {Chat, ChatType} from '@app/test-preparation/components/chat/ChatLayout';

const INITIAL_LOADING_DELAY_MS = 2000;
const DELAYED_REPLY_WAITING_MS = 6000;

const getLoadingChat = (type: ChatType): Chat => ({
  id: -1,
  type,
  isLoading: true,
});

type ChatContainerProps = {
  contentInteractionStateId: number;
  contentId: string;
  initialInputValue: string;
  onClose: (inputValue: string) => void;
  shouldAutoSendInitialInputValue: boolean;
};

const ChatContainer = React.forwardRef<HTMLDivElement, ChatContainerProps>(
  (
    {contentInteractionStateId, contentId, initialInputValue, onClose, shouldAutoSendInitialInputValue: shouldAutoSend},
    ref
  ) => {
    const {pushAllAiChatSendQuestionClick} = useTestprepEvents();
    const {isOnline, stateSwitchCount} = useNetwork();
    const [shouldAutoSendInitialInputValue, setShouldAutoSendInitialInputValue] = React.useState(shouldAutoSend);

    const {data: chatRoom = {id: -1}, isInitialLoading: isChatRoomLoading} =
      useChatRoomQuery(contentInteractionStateId);
    const {
      data: chatMessages = [],
      refetch: refetchChatMessages,
      isInitialLoading: isChatMessagesLoading,
    } = useChatMessagesQuery(chatRoom.id);
    const {
      mutateAsync: sendChatMessage,
      isLoading: isSendWaiting,
      reset: resetSendChatMessage,
    } = useSendChatMessageMutation();
    const {
      mutateAsync: replyChatMessage,
      isLoading: _isReplyWaiting,
      reset: resetReplyChatMessage,
    } = useReplyChatMessageMutation();
    const {
      mutateAsync: resendChatMessage,
      isLoading: isResendWaiting,
      reset: resetResendChatMessage,
    } = useResendChatMessageMutation();
    const {mutateAsync: deleteChatMessage, reset: resetDeleteChatMessage} = useDeleteChatMessageMutation();
    const {mutateAsync: feedbackChatMessage, reset: resetFeedbackChatMessage} = useFeedbackChatMessageMutation();

    const [isFakeLoading, setIsFakeLoading] = React.useState(true);
    const [isReplyDelayed, setIsReplyDelayed] = React.useState(false);
    const [resendingChatId, setResendingChatId] = React.useState<number | null>(null);
    const [inputValue, setInputValue] = React.useState(initialInputValue);

    const firstChatMessage = React.useMemo(() => chatMessages[0] as ChatMessage | undefined, [chatMessages]);
    const lastChatMessage = React.useMemo(
      () => chatMessages[chatMessages.length - 1] as ChatMessage | undefined,
      [chatMessages]
    );

    const isInitialLoading = React.useMemo(
      () => isChatRoomLoading || isChatMessagesLoading,
      [isChatMessagesLoading, isChatRoomLoading]
    );
    const isReplyWaiting = React.useMemo(
      () => _isReplyWaiting && lastChatMessage?.status === ChatMessageStatus.ENQUEUED,
      [_isReplyWaiting, lastChatMessage?.status]
    );
    const isChatWaiting = React.useMemo(
      () => isSendWaiting || isResendWaiting || isReplyWaiting,
      [isReplyWaiting, isResendWaiting, isSendWaiting]
    );

    const chats = React.useMemo<Array<Chat>>(() => {
      if (isFakeLoading) {
        return [getLoadingChat('SANTA')];
      }

      return chatMessages
        .map<Chat>(chatMessage => ({
          id: chatMessage.id,
          type: chatMessage.role === ChatMessageRole.ASSISTANT ? 'SANTA' : 'USER',
          text: chatMessage.content,
          feedback: chatMessage.feedback && (chatMessage.feedback === ChatMessageFeedback.UP ? 'LIKE' : 'DISLIKE'),
          isLoading: false,
          isSelected:
            !isChatWaiting &&
            chatMessage !== firstChatMessage &&
            chatMessage === lastChatMessage &&
            chatMessage.role === ChatMessageRole.ASSISTANT,
          isError: chatMessage.status === ChatMessageStatus.ERROR,
        }))
        .filter(chat => chat.id !== resendingChatId)
        .concat(
          isSendWaiting || isResendWaiting ? getLoadingChat('USER') : isReplyWaiting ? getLoadingChat('SANTA') : []
        )
        .sort((chatMessageA, chatMessageB) =>
          chatMessageB.isError ? (chatMessageA.isError ? chatMessageA.id - chatMessageB.id : -1) : 0
        );
    }, [
      chatMessages,
      firstChatMessage,
      isChatWaiting,
      isFakeLoading,
      isReplyWaiting,
      isResendWaiting,
      isSendWaiting,
      lastChatMessage,
      resendingChatId,
    ]);

    const handleClose = React.useCallback(() => {
      onClose(inputValue);
    }, [inputValue, onClose]);

    const handleSend = React.useCallback(async () => {
      setInputValue('');

      await sendChatMessage({
        chatRoomId: chatRoom.id,
        chatMessageText: inputValue,
      });

      pushAllAiChatSendQuestionClick({
        cis_id: contentInteractionStateId,
        content_id: contentId,
      });
    }, [
      chatRoom.id,
      contentId,
      contentInteractionStateId,
      inputValue,
      pushAllAiChatSendQuestionClick,
      sendChatMessage,
    ]);

    const handleResend = React.useCallback(
      async (chat: Chat) => {
        setResendingChatId(chat.id);
        await resendChatMessage({
          chatRoomId: chatRoom.id,
          chatMessageId: chat.id,
        });
        setResendingChatId(null);
      },
      [chatRoom.id, resendChatMessage]
    );

    const handleRemove = React.useCallback(
      async (chat: Chat) => {
        await deleteChatMessage({
          chatRoomId: chatRoom.id,
          chatMessageId: chat.id,
        });
      },
      [chatRoom.id, deleteChatMessage]
    );

    const handleFeedback = React.useCallback(
      async (chat: Chat, feedback: ChatSantaBubbleFeedback) => {
        await feedbackChatMessage({
          chatRoomId: chatRoom.id,
          chatMessageId: chat.id,
          chatMessageFeedback: feedback,
        });
      },
      [chatRoom.id, feedbackChatMessage]
    );

    React.useEffect(() => {
      if (isOnline && stateSwitchCount > 0) {
        resetSendChatMessage();
        resetReplyChatMessage();
        resetResendChatMessage();
        resetDeleteChatMessage();
        resetFeedbackChatMessage();
        refetchChatMessages();
      }
    }, [
      isOnline,
      refetchChatMessages,
      resetDeleteChatMessage,
      resetFeedbackChatMessage,
      resetReplyChatMessage,
      resetResendChatMessage,
      resetSendChatMessage,
      stateSwitchCount,
    ]);

    React.useEffect(() => {
      if (isInitialLoading) {
        return;
      }

      if (chatMessages.length > 1) {
        setIsFakeLoading(false);
      } else {
        const timer = setTimeout(() => {
          setIsFakeLoading(false);
        }, INITIAL_LOADING_DELAY_MS);

        return () => clearTimeout(timer);
      }
    }, [chatMessages.length, isInitialLoading]);

    React.useEffect(() => {
      if (isOnline && lastChatMessage?.status === ChatMessageStatus.ENQUEUED) {
        replyChatMessage({
          chatRoomId: chatRoom.id,
          chatMessageId: lastChatMessage.id,
        });
      }
    }, [chatRoom.id, isOnline, lastChatMessage, replyChatMessage]);

    React.useEffect(() => {
      if (isReplyWaiting) {
        const timer = setTimeout(() => {
          setIsReplyDelayed(true);
        }, DELAYED_REPLY_WAITING_MS);

        return () => clearTimeout(timer);
      } else {
        setIsReplyDelayed(false);
      }
    }, [isReplyWaiting]);

    React.useEffect(() => {
      // ab test 때 initialInput은 fakeLoading 안보여주기로함. 차후 어떻게 진행할 지 논의 필요.
      if (isInitialLoading || !shouldAutoSendInitialInputValue) {
        return;
      }

      setShouldAutoSendInitialInputValue(false);

      if (isChatWaiting) {
        return;
      }

      handleSend();
    }, [isFakeLoading, isInitialLoading, handleSend, isChatWaiting, shouldAutoSendInitialInputValue]);

    return (
      <ChatLayout
        ref={ref}
        isOffline={!isOnline}
        isLoading={isInitialLoading}
        isChatLoadingDelayed={isReplyDelayed}
        isAutoSendError={Boolean(isChatWaiting && inputValue && shouldAutoSend)}
        chats={chats}
        inputValue={inputValue}
        onInputChange={setInputValue}
        onSend={handleSend}
        onResend={handleResend}
        onRemove={handleRemove}
        onFeedback={handleFeedback}
        onClose={handleClose}
        onRetry={handleClose}
      />
    );
  }
);

export default React.memo(ChatContainer);
