import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';

import ButtonTextWithCheckbox, {ButtonTextWithCheckboxProps} from '@app/components/ButtonTextWithCheckbox';

export interface WrongQuestionsOnlySwitchProps extends ButtonTextWithCheckboxProps {
  isWrongQuestionOnly: boolean;
  onClickIsWrongQuestionOnly: (event: React.MouseEvent<HTMLDivElement>) => void;
}

const WrongQuestionsOnlySwitch = React.forwardRef<HTMLDivElement, WrongQuestionsOnlySwitchProps>(
  ({isWrongQuestionOnly, onClickIsWrongQuestionOnly, ...props}, ref) => {
    const {t} = useTranslation();
    return (
      <ButtonTextWithCheckbox
        ref={ref}
        checked={isWrongQuestionOnly}
        onClick={onClickIsWrongQuestionOnly}
        css={css`
          display: flex;
        `}
        {...props}>
        {t('learning_cell_report_show_wrong_answers_only')}
      </ButtonTextWithCheckbox>
    );
  }
);

export default React.memo(WrongQuestionsOnlySwitch);
