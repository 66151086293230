/**
 * Check if a given object implements the ExternalLinkPermitResource interface.
 */
export function instanceOfExternalLinkPermitResource(value) {
    let isInstance = true;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "url" in value;
    return isInstance;
}
export function ExternalLinkPermitResourceFromJSON(json) {
    return ExternalLinkPermitResourceFromJSONTyped(json, false);
}
export function ExternalLinkPermitResourceFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'description': json['description'],
        'url': json['url'],
    };
}
export function ExternalLinkPermitResourceToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'description': value.description,
        'url': value.url,
    };
}
