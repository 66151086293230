import React from 'react';
import {format} from 'date-fns';

export interface DatetimeFormatProps {
  datetime: number;
  format: string;
}

const DatetimeFormat = ({datetime, format: formatString}: DatetimeFormatProps): JSX.Element => {
  return <>{format(datetime, formatString)}</>;
};

export default React.memo(DatetimeFormat);
