import React, {useContext, useEffect, useRef, useState} from 'react';
import mergeRefs from 'react-merge-refs';
import {css} from '@emotion/react';
import {COLOR_SANTA_BB} from '@riiid/design-system';

import {Snippet} from '@santa-web/gen/open-api/content/models/Snippet';
import {
  RiiidRichTextV2View,
  SelectionChangeEvent,
  useInitialTextNodePaths,
  TextNodePathsContext,
  rgbToRrtv2Rgba,
  RiiidRichTextV2Path,
  useBlankContext,
  BLANK_PATH,
} from '@santa-web/service-ui';
import {useHighlightToolBoxContext} from '@app/test-preparation/contexts/HighlightToolBoxContext';
import {IsUserHighlightEnabledContext} from '@app/test-preparation/contexts/IsUserHighlightEnabledContext';
import MarkupToolsContext from '@app/test-preparation/contexts/MarkupToolsContext';
import {SnippetViewBaseIdContext} from '@app/test-preparation/contexts/SnippetViewBaseIdContext';
import UnderlineMarkupContext from '@app/test-preparation/contexts/UnderlineMarkupContext';
import useSelectionChangeListener from '@app/test-preparation/hooks/selection/useSelectionChangeListener';
import useSlideWords from '@app/test-preparation/hooks/slide/useSlideWords';

interface Props {
  snippet?: Snippet | null;
  id?: string;
  index?: number;
  contentId?: string;
  isUserHighlightEnabled?: boolean;
}

const SnippetView = React.forwardRef<HTMLDivElement, Props>(({snippet, id, index = 0, contentId}, ref): JSX.Element => {
  const localRef = useRef<HTMLDivElement>(null);

  const highlightToolBoxContext = useHighlightToolBoxContext();
  const underlineMarkupContext = useContext(UnderlineMarkupContext);
  const markupToolsContext = useContext(MarkupToolsContext);
  const isUserHighlightEnabled = useContext(IsUserHighlightEnabledContext);
  const snippetViewBaseId = useContext(SnippetViewBaseIdContext);
  const textNodePaths = useInitialTextNodePaths();
  const [blankPaths, setBlankPaths] = useState<RiiidRichTextV2Path[]>([]);
  const blankContext = useBlankContext();

  const currentSlide = useSlideWords(textNodePaths, slide => {
    if (slide !== null && markupToolsContext.selectedTool === 'PENCIL') {
      underlineMarkupContext.onAddUnderlineMarkup({
        ...slide,
        snippetViewId,
      });
    }
  });

  const props = {
    documentBlockElements: JSON.parse(snippet?.oneOfData.rrtV2BlockElementsJson || 'null'),
    documentInlineElements: JSON.parse(snippet?.oneOfData.rrtV2InlineElementsJson || 'null'),
    documentSections: JSON.parse(snippet?.oneOfData.rrtV2SectionsJson || 'null'),
  };

  const snippetViewId = React.useMemo(() => `${snippetViewBaseId}.${id}`, [id, snippetViewBaseId]);

  const handleSelectionChange = React.useCallback(
    (event: SelectionChangeEvent) => {
      highlightToolBoxContext?.onSelectionChange(snippetViewId, event);
    },
    [highlightToolBoxContext, snippetViewId]
  );

  const highlights = React.useMemo(() => {
    const existingMarkups = underlineMarkupContext.getUnderlineHighlights(
      snippetViewId,
      blankPaths,
      blankContext?.overridings ?? []
    );

    const underlineMarkups = [...existingMarkups];

    if (currentSlide !== null && markupToolsContext.selectedTool === 'PENCIL') {
      underlineMarkups.push({
        id: 'current-slide-markup',
        anchor: currentSlide.anchor!,
        focus: currentSlide.focus!,
        type: 'underline',
        color: rgbToRrtv2Rgba(COLOR_SANTA_BB, 0.3),
      });
    }

    return (highlightToolBoxContext?.highlights[snippetViewId] ?? []).concat(underlineMarkups);
  }, [
    blankContext?.overridings,
    blankPaths,
    currentSlide,
    highlightToolBoxContext?.highlights,
    markupToolsContext.selectedTool,
    snippetViewId,
    underlineMarkupContext,
  ]);

  React.useEffect(() => {
    if (isUserHighlightEnabled) {
      return highlightToolBoxContext?.registerSnippetView(snippetViewId);
    }
  }, [highlightToolBoxContext, isUserHighlightEnabled, snippetViewId]);

  // content가 바뀔때마다 blank의 위치를 새로 계산
  useEffect(() => {
    const blankElements = localRef.current?.querySelectorAll<HTMLDivElement>(`[${BLANK_PATH}]`) ?? [];

    const _blankPaths = [...blankElements]
      .map(element => element.dataset.blankPath)
      .map(pathString => {
        const paths = pathString?.split(',') ?? [];

        return paths.map(path => (isNaN(Number(path)) ? path : Number(path)));
      });

    if (_blankPaths.length > 0) {
      setBlankPaths(_blankPaths);
    }
  }, [index, contentId]);

  useSelectionChangeListener(textNodePaths, handleSelectionChange);

  return (
    <TextNodePathsContext.Provider value={textNodePaths}>
      <div
        ref={mergeRefs([ref, localRef])}
        css={[
          // 해당 Snippet view에 문장 구조 분석이 활성화 중이라면 dimmed 처리 (분석 가능한 문장은 font-color 변경됨)
          css`
            * {
              user-select: text;
            }
          `,
        ]}>
        <RiiidRichTextV2View {...props} enableSelection={true} highlights={highlights} />
      </div>
    </TextNodePathsContext.Provider>
  );
});

export default React.memo(SnippetView);
