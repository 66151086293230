/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { ElapsedTimeReportFromJSON, ElapsedTimeReportToJSON, } from './ElapsedTimeReport';
import { SkillAnalyticsReportCaseFromJSON, SkillAnalyticsReportCaseToJSON, } from './SkillAnalyticsReportCase';
import { TagGroupProficiencyReportFromJSON, TagGroupProficiencyReportToJSON, } from './TagGroupProficiencyReport';
/**
 * Check if a given object implements the OneOfSkillAnalyticsReport interface.
 */
export function instanceOfOneOfSkillAnalyticsReport(value) {
    let isInstance = true;
    isInstance = isInstance && "oneOfCase" in value;
    return isInstance;
}
export function OneOfSkillAnalyticsReportFromJSON(json) {
    return OneOfSkillAnalyticsReportFromJSONTyped(json, false);
}
export function OneOfSkillAnalyticsReportFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'elapsedTimeReport': !exists(json, 'elapsedTimeReport') ? undefined : ElapsedTimeReportFromJSON(json['elapsedTimeReport']),
        'oneOfCase': SkillAnalyticsReportCaseFromJSON(json['oneOfCase']),
        'tagGroupProficiencyReport': !exists(json, 'tagGroupProficiencyReport') ? undefined : TagGroupProficiencyReportFromJSON(json['tagGroupProficiencyReport']),
    };
}
export function OneOfSkillAnalyticsReportToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'elapsedTimeReport': ElapsedTimeReportToJSON(value.elapsedTimeReport),
        'oneOfCase': SkillAnalyticsReportCaseToJSON(value.oneOfCase),
        'tagGroupProficiencyReport': TagGroupProficiencyReportToJSON(value.tagGroupProficiencyReport),
    };
}
