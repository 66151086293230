import React from 'react';
import {useMount} from 'react-use';
import {css} from '@emotion/react';

import config from '@app/config';
import {ROUTE_BLOCKED} from '@app/constants/ignore-error';
import {SantaResponseError} from '@app/utils/error';

type UnhandledRejection = {
  traceId?: string;
  reason: string;
  id: number;
};

const ThrowUnhandledRejection = () => {
  const idRef = React.useRef(0);

  const [errors, setErrors] = React.useState<Array<UnhandledRejection>>([]);

  const appendError = React.useCallback(
    (error: UnhandledRejection) => setErrors(errors => errors.concat(error)),
    [setErrors]
  );
  const handleErrorClick = React.useCallback(
    (error: UnhandledRejection) => {
      if (error.traceId) {
        navigator.clipboard.writeText(error.traceId).then(() => alert('copied trace id to clipboard'));
      }
      setErrors(errors => errors.filter(e => e.id !== error.id));
    },
    [setErrors]
  );

  useMount(() => {
    const showError = config.env !== 'production';

    if (!showError) {
      return;
    }

    // @TODO: show toast
    const listener = (e: PromiseRejectionEvent) => {
      if (e.reason === ROUTE_BLOCKED) return; // next router block을 위한 string throw는 무시합니다.

      console.error(e.reason);
      appendError({
        reason: e.reason.toString(),
        id: idRef.current++,
        traceId: e.reason instanceof SantaResponseError ? e.reason.traceId : undefined,
      });
    };

    window.addEventListener('unhandledrejection', listener);

    return () => {
      window.removeEventListener('unhandledrejection', listener);
    };
  });

  if (errors.length === 0) {
    return null;
  }

  return (
    <div
      css={css`
        z-index: 999999999;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        padding: 20px;
        display: flex;
        flex-direction: column;
      `}>
      {errors.map(error => (
        <div
          key={error.id}
          onClick={() => handleErrorClick(error)}
          css={css`
            margin: 4px;
            padding: 12px;
            border-radius: 4px;
            font-size: 18px;
            background-color: red;
            color: white;
          `}>
          {error.reason}
          {error.traceId && (
            <>
              <br />
              trace id: {error.traceId}
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export default ThrowUnhandledRejection;
