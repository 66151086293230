import {atom} from 'jotai';
import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';

const getLearningCellAtom = atom(async get => {
  const {LearningCellService} = await get(santaOpenapiServicesAtom);

  return async (learningCellId: number) =>
    await LearningCellService.getLearningCell({id: learningCellId}).then(({learningCell}) => learningCell);
});

export {getLearningCellAtom};
