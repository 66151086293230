import {useInfiniteQuery, UseInfiniteQueryOptions} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';
import {ListCouponsResponse} from '@santa-web/gen/open-api/service';
import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {getCouponsInfinityQueryKey} from '@app/queryKeys';

const useGetCouponInfinityQuery = <T = ListCouponsResponse>(
  args: {availableCondition: boolean; size: number},
  options?: UseInfiniteQueryOptions<
    ListCouponsResponse,
    unknown,
    T,
    ListCouponsResponse,
    ReturnType<typeof getCouponsInfinityQueryKey>
  >
) => {
  const {CouponService} = useAtomValue(santaOpenapiServicesAtom);
  const queryKey = getCouponsInfinityQueryKey(args.availableCondition);

  return {
    queryKey,
    ...useInfiniteQuery(queryKey, ({pageParam: cursor}) => CouponService.listCoupons({cursor, ...args}), {
      getNextPageParam: lastPage => lastPage.page?.cursor,
      ...options,
    }),
  };
};

export default useGetCouponInfinityQuery;
