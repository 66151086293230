import React from 'react';
import {useTranslation} from 'react-i18next';
import {differenceInDays, format} from 'date-fns';
import {useAtomValue} from 'jotai';
import useOfferEvents from '@app/api/google-tag-manager/offer';
import learningDomainAtom from '@app/atoms/core/learning-domain';
import {CurrentPassInformationCard, CurrentPassInformationCardProps} from '@app/features/permit/components';
import {useMyPassStatusQuery} from '@app/features/permit/hooks';
import {useTypedRouter} from '@app/hooks/useTypedRouter';

const CurrentPassInformationCardContainer = () => {
  const {t} = useTranslation();
  const learningDomain = useAtomValue(learningDomainAtom);
  const {data: passStatus} = useMyPassStatusQuery();
  const router = useTypedRouter();
  const {pushClickStore} = useOfferEvents();

  const passCardProps: CurrentPassInformationCardProps | null = React.useMemo(() => {
    if (!passStatus) return null;
    if (learningDomain.isInFreePromotion) {
      return {
        title: t('page_me_my_pass_info_free_promotion_title'),
        description: {type: 'string', value: t('page_me_my_pass_info_free_promotion_description')},
      };
    }
    const navigateToOfferGroupBoard = () => {
      pushClickStore({referrer: 'setting_authority'});
      router.push({pathname: '/offer-group/board', query: {referrer: 'setting_authority'}});
    };
    const navigateToPermitList = () => {
      router.push({pathname: '/permit/list'});
    };
    switch (passStatus.status) {
      case 'HAS_ACTIVE_PASS': {
        const activePass = passStatus.activePass;
        if (!activePass) {
          throw new Error('should have active pass');
        }
        return {
          title: activePass.displayName,
          description: {type: 'until', value: format(activePass.expireAt, 'yyyy.MM.dd HH:mm:ss')},
          count: {type: 'countdown', value: differenceInDays(activePass.expireAt, new Date())},
          shouldShowChevron: true,
          onClick: activePass.sourceType === 'ONBOARDING' ? navigateToOfferGroupBoard : navigateToPermitList,
        };
      }
      case 'HAS_PENDING_PASS':
        return {
          title: t('page_me_my_pass_info_default_permit_title'),
          description: {type: 'string', value: t('page_me_my_pass_info_default_permit_has_pending_pass_description')},
          shouldShowChevron: true,
          onClick: navigateToPermitList,
        };
      case 'HAS_NO_PASS':
        return {
          title: t('page_me_my_pass_info_default_permit_title'),
          description: {type: 'string', value: t('page_me_my_pass_info_default_permit_description')},
          shouldShowChevron: true,
          onClick: navigateToOfferGroupBoard,
        };
    }
  }, [passStatus, learningDomain.isInFreePromotion, t, pushClickStore, router]);

  if (!passCardProps) {
    return <CurrentPassInformationCard.Skeleton />;
  }
  return <CurrentPassInformationCard {...passCardProps} />;
};

export default CurrentPassInformationCardContainer;
CurrentPassInformationCardContainer.displayName = 'CurrentPassInformationCardContainer';
