import {useQueryClient, useMutation} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';

import {ChatMessage} from '@santa-web/gen/open-api/content-learning-service';
import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {getChatMessagesQueryKey} from '@app/queryKeys';

type MutationVariables = {
  chatRoomId: number;
  chatMessageId: number;
};

const useResendChatMessageMutation = () => {
  const queryClient = useQueryClient();

  const {ChatMessageService} = useAtomValue(santaOpenapiServicesAtom);

  return useMutation(
    ({chatMessageId}: MutationVariables) =>
      ChatMessageService.resendChatMessage({
        messageId: chatMessageId,
      }).then(({message: chatMessage}) => chatMessage),
    {
      onSuccess: async (newChatMessage, {chatRoomId, chatMessageId}) => {
        const chatMessagesQueryKey = getChatMessagesQueryKey(chatRoomId);

        await queryClient.cancelQueries(chatMessagesQueryKey);
        queryClient.setQueryData<Array<ChatMessage>>(chatMessagesQueryKey, (oldChatMessages = []) =>
          oldChatMessages.filter(({id}) => id !== chatMessageId).concat(newChatMessage)
        );
      },
    }
  );
};

export default useResendChatMessageMutation;
