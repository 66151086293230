import React from 'react';

import {useAtomValue} from 'jotai';
import {useDialogContext} from '@app/contexts/DialogContext';
import {useChatCautionBottomSheet} from '@app/hooks/useChatCautionBottomSheet';
import {learningStore} from '@app/test-preparation/atoms/stores';
import ChatDialog from '@app/test-preparation/components/chat/ChatDialog';
import {useTestprepConfigContext} from '@app/test-preparation/contexts/TestprepConfigContext';

export const useChatDialog = () => {
  const {chat} = useTestprepConfigContext();
  const {openDialog, closeDialog} = useDialogContext();
  const {openChatCautionBottomSheet} = useChatCautionBottomSheet();

  const cisId = useAtomValue(learningStore.cis.idAtom);
  const contentId = useAtomValue(learningStore.content.idAtom);

  const [initialInputValue, setInitialInputValue] = React.useState('');

  const openChatDialog = React.useCallback(
    async ({
      initialInputValue: _initialInputValue,
      shouldAutoSendInitialInputValue = false,
    }: {
      initialInputValue: string | undefined | null;
      shouldAutoSendInitialInputValue?: boolean;
    }) => {
      if (!chat.isAgree) {
        await new Promise<void>(resolve => {
          openChatCautionBottomSheet({
            onStartClick: async () => {
              await chat.handleAgree();
              closeDialog();
              resolve();
            },
          });
        });
      }

      openDialog(
        <ChatDialog
          contentInteractionStateId={cisId}
          contentId={contentId}
          initialInputValue={_initialInputValue ?? initialInputValue}
          onInputValueChange={setInitialInputValue}
          onClose={closeDialog}
          shouldAutoSendInitialInputValue={shouldAutoSendInitialInputValue}
        />
      );
    },
    [chat, cisId, closeDialog, contentId, initialInputValue, openChatCautionBottomSheet, openDialog]
  );

  React.useEffect(() => {
    setInitialInputValue('');
  }, [cisId]);

  return {openChatDialog};
};
