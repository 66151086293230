import React from 'react';
import {css} from '@emotion/react';
import {COLOR_SANTA_O, COLOR_SANTA_I} from '@riiid/design-system';
import ChevronLeft from '@riiid/design-system/icons/ChevronLeft';
import Chevronleftdouble from '@riiid/design-system/icons/Chevronleftdouble';
import ChevronRight from '@riiid/design-system/icons/ChevronRight';
import Chevronrightdouble from '@riiid/design-system/icons/Chevronrightdouble';

import {ButtonBase, ButtonBaseProps} from '@santa-web/service-ui';

export type JourneyPageIconButtonType = 'left-double' | 'left' | 'right' | 'right-double';

export interface JourneyPageIconButtonProps extends Omit<ButtonBaseProps, 'type' | 'isFullWidth'> {
  isDisabled?: boolean;
  type: JourneyPageIconButtonType;
}

const JourneyPageIconButton = React.forwardRef<HTMLButtonElement, JourneyPageIconButtonProps>(
  ({isDisabled, type, ...props}, ref) => {
    const Icon = {
      'left-double': Chevronleftdouble,
      left: ChevronLeft,
      right: ChevronRight,
      'right-double': Chevronrightdouble,
    }[type];

    return (
      <ButtonBase
        type="button"
        css={css`
          color: ${COLOR_SANTA_I};
          :not(:disabled):hover,
          :not(:disabled):active {
            color: ${COLOR_SANTA_O};
          }
          :disabled {
            opacity: 0.3;
          }
        `}
        isDisabled={isDisabled}
        ref={ref}
        {...props}>
        <Icon width={24} height={24} type="line" />
      </ButtonBase>
    );
  }
);

export default React.memo(JourneyPageIconButton);
