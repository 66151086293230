import {css} from '@emotion/react';
import {BREAKPOINT_MEDIUM, COLOR_SANTA_C} from '@riiid/design-system';

import TestPrepLayout, {TestPrepLayoutProps} from '@app/test-preparation/components/TestPrepLayout';
import {VOCA_VIEW_MAX_WIDTH} from '@app/test-preparation/constants/test-preparation-layout';

export type VocabularyBookmarkViewLayoutProps = TestPrepLayoutProps;

const VocabularyBookmarkViewLayout = ({children, ...props}: VocabularyBookmarkViewLayoutProps) => {
  return (
    <TestPrepLayout
      maxWidth={VOCA_VIEW_MAX_WIDTH}
      scrollLayoutProps={{
        css: css`
          background: ${COLOR_SANTA_C};
        `,
      }}
      {...props}>
      <div
        css={css`
          display: flex;
          justify-content: center;
          min-height: 100%;
        `}>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            max-width: ${VOCA_VIEW_MAX_WIDTH};
            width: 100%;

            @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
              padding: 0 20px;
            }
          `}>
          <div
            css={css`
              flex: 1;
            `}>
            {children}
          </div>
        </div>
      </div>
    </TestPrepLayout>
  );
};

export default VocabularyBookmarkViewLayout;
