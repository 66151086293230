import {useQuery, UseQueryOptions, UseQueryResult} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';

import {UserProfile} from '@santa-web/gen/open-api/service';
import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {getUserProfileQueryKey} from '@app/queryKeys';
import {SantaResponseError} from '@app/utils/error';

const useUserProfile = (options?: UseQueryOptions<UserProfile | null>): UseQueryResult<UserProfile | null> => {
  const {ProfileService} = useAtomValue(santaOpenapiServicesAtom);
  const userProfileQueryKey = getUserProfileQueryKey();

  return useQuery<UserProfile | null>(
    userProfileQueryKey,
    async () => {
      try {
        const {profile} = await ProfileService.getMyProfile({});
        return profile;
      } catch (e) {
        if (e instanceof SantaResponseError && e.response.status === 404) {
          return null;
        }
        throw e;
      }
    },
    {...options}
  );
};

export default useUserProfile;
