/**
 * Check if a given object implements the OrderPaymentTransactionIamport interface.
 */
export function instanceOfOrderPaymentTransactionIamport(value) {
    let isInstance = true;
    isInstance = isInstance && "impUid" in value;
    return isInstance;
}
export function OrderPaymentTransactionIamportFromJSON(json) {
    return OrderPaymentTransactionIamportFromJSONTyped(json, false);
}
export function OrderPaymentTransactionIamportFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'impUid': json['impUid'],
    };
}
export function OrderPaymentTransactionIamportToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'impUid': value.impUid,
    };
}
