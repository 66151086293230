import {useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {useClickAway, useKey} from 'react-use';
import {css} from '@emotion/react';
import {COLOR_FIX_SANTA_WHITE_1} from '@riiid/design-system';
import CloseIcon from '@riiid/design-system/icons/Close';
import {Button, Dim, DimProps, IconButton, TextButton} from '@santa-web/service-ui';
import {useBottomSheetAnimation} from '@santa-web/service-ui/src/components/BaseBottomSheet/useBottomSheetAnimation';
import {Row} from '@app/components';

type WelcomeMarketingBottomSheetProps = {
  onCTAClick: () => void;
  onCloseClick: () => void;
  onCloseForWholeDayClick: () => void;
  buttonContent?: string;
  imgSrc?: string;
} & Omit<DimProps, 'content'>;

const WelcomeMarketingBottomSheet = ({
  onCTAClick,
  onCloseClick,
  onCloseForWholeDayClick,
  buttonContent,
  imgSrc,
  ...rest
}: WelcomeMarketingBottomSheetProps) => {
  const {t} = useTranslation();
  const bottomSheetRef = useRef<HTMLDivElement>(null);
  const animationCss = useBottomSheetAnimation(true);
  useClickAway(bottomSheetRef, onCloseClick, ['mousedown']);
  useKey('Escape', onCloseClick);

  return (
    <Dim
      css={css`
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        align-items: center;
        flex-direction: column-reverse;
        box-sizing: border-box;
        z-index: 99999;
      `}
      {...rest}>
      <div
        css={css`
          width: 100%;
          max-width: 520px;
          height: 400px;
          border-top-left-radius: 20px;
          border-top-right-radius: 20px;
          background-color: #000;
          background-size: 600px 400px;
          background-image: url(${imgSrc});
          background-position: center;
          background-repeat: no-repeat;
          ${animationCss}
        `}
        ref={bottomSheetRef}>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            padding: 18px 20px 40px;
            height: 100%;
            position: relative;
          `}>
          <div
            css={css`
              display: flex;
              justify-content: center;
              position: relative;
            `}>
            <Row
              justifyContent="space-between"
              alignItems="center"
              css={css`
                z-index: 1;
                width: 100%;
                padding: 6px 0px;
              `}>
              <TextButton
                css={css`
                  text-decoration: underline;
                `}
                onClick={onCloseForWholeDayClick}
                color="white-fix"
                fontWeight="regular"
                typographyVariant="caption1">
                {t('alert_marketing_banner_button_neutral')}
              </TextButton>
              <IconButton
                onClick={onCloseClick}
                Icon={CloseIcon}
                IconProps={{
                  color: COLOR_FIX_SANTA_WHITE_1,
                  width: 24,
                  height: 24,
                }}
              />
            </Row>
          </div>
          {buttonContent && (
            <div
              css={css`
                height: 100%;
                position: relative;
              `}>
              <Button
                onClick={onCTAClick}
                css={css`
                  position: absolute;
                  bottom: 0;
                  width: 100%;
                  flex-shrink: 0;
                `}>
                {buttonContent}
              </Button>
            </div>
          )}
        </div>
      </div>
    </Dim>
  );
};

export default WelcomeMarketingBottomSheet;
export type {WelcomeMarketingBottomSheetProps};
WelcomeMarketingBottomSheet.displayName = 'WelcomeMarketingBottomSheet';
