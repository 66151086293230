import {useRouter} from 'next/router';
import {useMemo, useRef} from 'react';
import {Form, FormSpy} from 'react-final-form';
import {useTranslation} from 'react-i18next';
import {format} from 'date-fns';
import {FormApi, FormState} from 'final-form';
import {useAtomValue} from 'jotai';
import {ExamScoreConfig} from '@santa-web/gen/open-api/service';
import {useToastContext} from '@santa-web/service-ui';
import learningDomainAtom from '@app/atoms/core/learning-domain';
import {useDialogContext} from '@app/contexts/DialogContext';
import {DeleteConfirmDialog, ExitConfirmDialog} from '@app/features/preferences/components/ExamScore';
import {
  useAvailableExamDates,
  useDeleteExamScoreMutation,
  useGetExamScoreQuery,
  useGetScoreConfigQuery,
  usePatchExamScoreMutation,
  usePostExamScoreMutation,
  usePatchExamScoreDateMutation,
} from '@app/features/preferences/hooks';
import {ExamScoreForm, ScoreConfig, ScoreInfo, StringDate} from '@app/features/preferences/types';
import ExamScoreFormModalView from '@app/features/preferences/views/ExamScoreFormModalView';
import useBlockNavigation from '@app/hooks/useBlockExit';
import {useTypedSearchParams} from '@app/hooks/useTypedRouter';

type ExamScoreFormModalContainerProps = {
  isOpened: boolean;
  onClose: () => void;
};

const ExamScoreFormModalContainer = ({isOpened, onClose}: ExamScoreFormModalContainerProps) => {
  const {t} = useTranslation();
  const {displayName} = useAtomValue(learningDomainAtom);
  const {back} = useRouter();
  const isPristine = useRef(true);
  const isSubmitted = useRef(false);
  const {date} = useTypedSearchParams('/me/exam-score');
  const {openDialog, closeDialog} = useDialogContext();
  const scoreConfigQuery = useGetScoreConfigQuery();
  const availableDates = useAvailableExamDates({currentDate: date as StringDate});
  const currentDateScoreQuery = useGetExamScoreQuery({args: {date: date as StringDate}});
  const {mutateAsync: createScore, isLoading: isCreateLoading} = usePostExamScoreMutation();
  const {mutateAsync: updateScore, isLoading: isPatchLoading} = usePatchExamScoreMutation();
  const {mutateAsync: deleteScore, isLoading: isDeleteLoading} = useDeleteExamScoreMutation();
  const {mutateAsync: updateWithDate} = usePatchExamScoreDateMutation();
  const {openToast} = useToastContext();
  const openGrayToast = (message: string) => openToast({message, colorVariant: 'gray'});
  const openDangerToast = (message: string) => openToast({message, colorVariant: 'danger'});
  const scoreConfig = useMemo(
    () => scoreConfigQuery.data && ExamScoreConfigMapper(scoreConfigQuery.data, displayName),
    [scoreConfigQuery.data, displayName]
  );
  const currentDateScore = currentDateScoreQuery.data;
  const isEdit = date !== null;
  const isMutationLoading = [isCreateLoading, isPatchLoading, isDeleteLoading].some(Boolean);

  useBlockNavigation(() => !isPristine.current && !isSubmitted.current, handleExitWithoutSave);

  const handleDeleteScore = async () => {
    try {
      await deleteScore(date as StringDate);
      openGrayToast(t(`page_exam_score_delete_toast_success`));
      closeDialog();
      onClose();
    } catch (e) {
      openDangerToast(t(`dict_unknown_error_message`));
    }
  };

  const handleAddScore = async (input: ScoreInfo) => {
    await createScore({...input, date: new Date(input.date)});
    openGrayToast(t(`page_exam_score_create_toast_success`));
  };

  const handleUpdateScore = async (input: ScoreInfo) => {
    const isDateChange = date !== input.date;

    if (isDateChange) {
      // 백엔드 API는 date변경을 불허하는 쪽으로 생각하시고 api를 작성하셔서 date를 Id로 사용하고 있습니다.
      // 따라서 date를 변경할 경우, 기존 데이터를 삭제하고 새로운 데이터를 생성하는 방식으로 처리합니다. (API 스펙 업데이트 전까지)
      await updateWithDate({
        examDate: date as unknown as Date,
        newDate: new Date(input.date),
        totalScore: input.totalScore,
        partialScores: input.partialScores,
      });
    } else {
      await updateScore({...input, examDate: new Date(input.date)});
    }

    openGrayToast(t(`page_exam_score_update_toast_success`));
  };

  const handleSubmit = async (form: ExamScoreForm) => {
    const input = examScoreFormValuesToInput(form);

    try {
      isEdit ? await handleUpdateScore(input) : await handleAddScore(input);
      onClose();
    } catch (e) {
      openDangerToast(t(`dict_unknown_error_message`));
    }
  };

  const handleDeleteClick = () => {
    openDialog(<DeleteConfirmDialog isVisible onCancel={closeDialog} onDelete={handleDeleteScore} />);
  };

  const initialValue = useMemo(() => {
    if (!scoreConfig) return;

    return getInitialFormValue({
      defaultScoreInfo: currentDateScore && {
        date: format(currentDateScore?.examScore.date, 'yyyy-MM-dd') as StringDate,
        partialScores: currentDateScore?.examScore.partialScores ?? [],
        totalScore: currentDateScore?.examScore.totalScore ?? 0,
      },
      scoreConfig,
    });
  }, [currentDateScore, scoreConfig]);

  async function handleExitWithoutSave({unblockRoute}: {unblockRoute: () => void}) {
    const confirm = await new Promise(res =>
      openDialog(<ExitConfirmDialog isEdit={isEdit} onCancelClick={() => res(false)} onExitClick={() => res(true)} />)
    );

    if (confirm) {
      unblockRoute(); // Block을 off하지 않으면, 모든 navigate가 막힘.
      back();
    }

    closeDialog();
  }

  if (!scoreConfig || !isOpened) return null;

  return (
    <Form
      initialValues={initialValue}
      subscription={{values: true, touched: true, pristine: true, submitSucceeded: true, dirtySinceLastSubmit: true}}
      onSubmit={handleSubmit}
      validate={examScoreFormValidator}>
      {({form, handleSubmit, values}) => {
        return (
          <>
            <ExamScoreFormModalView
              isMutationLoading={isMutationLoading}
              currentDate={values.date}
              onSubmit={handleSubmit}
              isEdit={isEdit}
              onDatePick={date => form.change('date', date)}
              onDeleteClick={handleDeleteClick}
              onExitClick={onClose}
              validDates={availableDates}
              scoreConfig={scoreConfig}
              validScoreTotal={values.totalScore}
            />
            {/* 점수입력 및 각 점수 유효성검사 통과여부에 따라 totalScore 계산/적용 */}
            <FormSpy
              subscription={{
                values: true,
                touched: true,
                pristine: true,
                submitSucceeded: true,
                dirtySinceLastSubmit: true,
              }}
              onChange={(formState: FormState<ExamScoreForm, Partial<ExamScoreForm>>) => {
                const touchedFields = formState.touched ?? {};
                const isSomeFieldTouched = Object.values(touchedFields).some(Boolean);
                isPristine.current = formState.pristine;
                // 제출 이후에 변경이 또 있는 경우는 isSubmitted가 false로 설정되어야 함
                isSubmitted.current = !formState.dirtySinceLastSubmit && formState.submitSucceeded;

                /**
                 * 1. 필드에 터치조건이 없는 경우, initialValue 세팅시 점수합계핸들러가 동작함.
                 * 2. 점수합계핸들러는 각 점수필드 중 valid한 필드만 filtering하여 합계를 계산함.
                 * 3. 초기값 세팅시에는 각 필드가 registered되지 않아 valid값을 가져올 수 없어 totalScore가 0으로 설정함.
                 * 4. 따라서 touch 상태로, 핸들러 동작을 지연시켜 해결
                 *  */
                if (isSomeFieldTouched) {
                  totalExamScoreHandler({aggregationType: scoreConfig.aggregationType, formState, form});
                }
              }}
            />
          </>
        );
      }}
    </Form>
  );
};

const getInitialFormValue = ({
  scoreConfig,
  defaultScoreInfo,
}: {
  defaultScoreInfo?: ScoreInfo;
  scoreConfig: ScoreConfig;
}) => {
  const {partialScores, date: defaultDate, totalScore} = defaultScoreInfo ?? {};
  const defaultScoreFromConfig = scoreConfig.partials.map(({name}) => ({name, score: 0}));
  const partialScoresForm = partialScoreArrayToObject(partialScores ?? defaultScoreFromConfig);

  return {
    totalScore: totalScore ?? 0,
    date: defaultDate,
    partialScores: partialScoresForm,
  };
};

const partialScoreArrayToObject = (partialScores: ScoreInfo['partialScores']) => {
  return partialScores.reduce<ExamScoreForm['partialScores']>((acc, scoreInfo, index) => {
    // react-final-form에서 초기값과 현재값과의 비교해 변경여부를 판단하는 pristine값을 사용하는데
    // 초기값은 점수를 number타입, 폼에서의 점수는 string타입으로 pristine값이 항상 false가 되는 문제가 있음
    // 따라서 ExamScoreForm에서 사용하는 점수값들은 string으로 변환하여 사용
    acc[`key-${index}`] = {name: scoreInfo.name, score: scoreInfo?.score ? `${scoreInfo.score}` : undefined};
    return acc;
  }, {});
};

const partialScoreObjectToArray = (partialScores: ExamScoreForm['partialScores']) => {
  return Object.entries(partialScores).reduce<{name: string; score: number}[]>((acc, [key, value]) => {
    const [, stringIndex] = key.split('-');
    const index = Number(stringIndex);
    acc[index] = {...value, score: Number(value.score)};

    return acc;
  }, []);
};

const examScoreFormValuesToInput = (form: ExamScoreForm): ScoreInfo => {
  const partialScores = partialScoreObjectToArray(form.partialScores);

  return {
    ...form,
    partialScores,
  };
};

const examScoreFormValidator = (values: Partial<ExamScoreForm>) => {
  const errors: Record<string, string> = {};
  const hasMissingValue = values.partialScores ? Object.values(values.partialScores).some(({score}) => !score) : true;

  if (!values.date) errors['date'] = '날짜를 선택해주세요';
  if (hasMissingValue) errors['partialScores'] = '점수를 입력해주세요';
  if (Object.keys(errors).length > 0) return errors;
};

const totalExamScoreHandler = ({
  aggregationType,
  formState,
  form,
}: {
  aggregationType: ScoreConfig['aggregationType'];
  formState: FormState<ExamScoreForm, Partial<ExamScoreForm>>;
  form: FormApi<ExamScoreForm, Partial<ExamScoreForm>>;
}) => {
  const {values} = formState;
  const scores = Object.entries(values.partialScores);

  const validScores = scores.filter(([key]) => {
    // initialize시에는 field state가 undefined을 반환해 valid값이 false로 판단되는 문제가 있음
    // final-form의 getFieldState는 keyof FormValues를 받는데, nested object의 key를 받을 수 없어서 => unknown => keyof ExamScoreForm으로 타입캐스팅
    const isValid = form.getFieldState(`partialScores.${key}.score` as unknown as keyof ExamScoreForm)?.valid;
    return isValid;
  });
  const sum = validScores.reduce((acc, [, {score = 0}]) => acc + Number(score), 0);

  switch (aggregationType) {
    case 'SUM':
      return form.change('totalScore', sum);
    case 'AVERAGE_ROUND_UP':
      return form.change('totalScore', Math.ceil(sum / scores.length));
  }
};

const ExamScoreConfigMapper = (examScoreConfig: ExamScoreConfig, learningDomain: string): ScoreConfig => {
  return {
    name: learningDomain,
    aggregationType: examScoreConfig.total.aggregationType,
    isPercent: examScoreConfig.total.isPercent,
    partials: examScoreConfig.partials.map(({name, maxScore, minScore, scoreStep}) => ({
      name,
      maxScore,
      minScore,
      scoreStep,
    })),
  };
};

export default ExamScoreFormModalContainer;
ExamScoreFormModalContainer.displayName = 'ExamScoreFormModalContainer';
