/**
 * Check if a given object implements the MyNoteQuiz interface.
 */
export function instanceOfMyNoteQuiz(value) {
    let isInstance = true;
    isInstance = isInstance && "learningCellId" in value;
    isInstance = isInstance && "studiedQuestionCount" in value;
    isInstance = isInstance && "totalQuestionCount" in value;
    return isInstance;
}
export function MyNoteQuizFromJSON(json) {
    return MyNoteQuizFromJSONTyped(json, false);
}
export function MyNoteQuizFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'learningCellId': json['learningCellId'],
        'studiedQuestionCount': json['studiedQuestionCount'],
        'totalQuestionCount': json['totalQuestionCount'],
    };
}
export function MyNoteQuizToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'learningCellId': value.learningCellId,
        'studiedQuestionCount': value.studiedQuestionCount,
        'totalQuestionCount': value.totalQuestionCount,
    };
}
