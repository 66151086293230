import {Router, useRouter} from 'next/router';
import {useCallback, useEffect, useRef} from 'react';
import {ROUTE_BLOCKED} from '@app/constants/ignore-error';

type RouteChangeHandler = (nextPath: string, option: {shallow: boolean}) => void;

const useBlockNavigation = (
  isBlocked: boolean | (() => boolean),
  onBlocked: (arg: {nextPath: string; isShallow: boolean; unblockRoute: () => void}) => void
) => {
  // Props들이 변경되어도 listener가 변경되지 않도록 ref로 관리합니다.
  const {asPath} = useRouter();
  const isBlockedRef = useRef(isBlocked);
  const onBlockedRef = useRef(onBlocked);
  const handleRouteChangeStartRef = useRef<RouteChangeHandler | null>(null);

  /**
   * Route 이벤트에서 Blocking을 해제합니다. onBlocked에서 페이지를 이동할 경우 unblockRoute을 먼저 호출해줘야 이동할 수 있습니다.
   */
  const unblockRoute = useCallback(() => {
    if (handleRouteChangeStartRef.current) {
      Router.events.off('routeChangeStart', handleRouteChangeStartRef.current);
    }
  }, []);

  /**
   * URL 변경과 next router의 path의 동기화를 위한 함수
   */
  const syncUrlWithRouter = useCallback(() => {
    if (asPath !== location.pathname) {
      history.pushState(null, '', asPath);
    }
  }, [asPath]);

  const handleRouteChangeStart: RouteChangeHandler = useCallback(
    (nextPath, {shallow: isShallow}) => {
      const isBlockValid =
        typeof isBlockedRef['current'] === 'function' ? isBlockedRef.current() : isBlockedRef.current;

      if (isBlockValid) {
        syncUrlWithRouter();
        Router.events.emit('routeChangeError');
        onBlockedRef.current({
          nextPath,
          isShallow,
          unblockRoute,
        });
        throw ROUTE_BLOCKED;
      }
    },
    [unblockRoute, syncUrlWithRouter]
  );

  useEffect(() => {
    isBlockedRef.current = isBlocked;
  }, [isBlocked]);

  useEffect(() => {
    /**
     * unblockRoute에서 라우트 이벤트 구독을 종료하기 위해 리스너인 handleRouteChangeStart를 ref로 관리합니다.
     */
    handleRouteChangeStartRef.current = handleRouteChangeStart;
  }, [handleRouteChangeStart]);

  useEffect(() => {
    onBlockedRef.current = onBlocked;
  }, [onBlocked]);

  useEffect(() => {
    Router.events.on('routeChangeStart', handleRouteChangeStart);

    return () => {
      Router.events.off('routeChangeStart', handleRouteChangeStart);
    };
  }, [handleRouteChangeStart]);

  return {unblockRoute};
};

export default useBlockNavigation;
