function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
import React, { useContext } from "react";
import { visitor as defaultVisitor } from "@santa-web/gen/ssp/ubershape/riiid-rich-text-v2";
export var BlankContext = React.createContext(null);
export function useBlankContext() {
    return useContext(BlankContext);
}
export function useBlankOverriding(number) {
    var blankContext = useBlankContext();
    if (number == null || blankContext == null) return;
    return blankContext.overridings[number];
}
export function createBlankContextPreprocessingVisitor() {
    var context = {
        number: 0
    };
    return _object_spread_props(_object_spread({}, defaultVisitor), {
        visitBlank: function visitBlank(visitor, value) {
            return _object_spread_props(_object_spread({}, value), {
                number: context.number++
            });
        }
    });
}
