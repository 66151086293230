import {useTranslation} from 'react-i18next';
import {useAtomValue} from 'jotai';
import useCouponEvents from '@app/api/google-tag-manager/coupon';
import learningDomainAtom from '@app/atoms/core/learning-domain';
import {CouponView, useCoupon} from '@app/features/coupon';
import {useTypedSearchParams} from '@app/hooks/useTypedRouter';

const CouponContainer = () => {
  const {t} = useTranslation();
  const {displayName: learningDomain} = useAtomValue(learningDomainAtom);
  const {
    isLoading,
    couponList,
    hasNextPageRef,
    couponListFilter,
    openToast,
    closeToast,
    fetchNextPage,
    handleBackClick,
    handleCouponUse,
    setCouponListFilter,
    handleRegisterCoupon: _handleRegisterCoupon,
  } = useCoupon();
  const {code} = useTypedSearchParams('/coupon');
  const {pushMyPageCouponRegisterSuccess} = useCouponEvents();

  const handleRegisterCoupon = async (code: string) => {
    const result = await _handleRegisterCoupon(code);
    const couponTemplateId = result?.templateId;

    if (couponTemplateId) {
      pushMyPageCouponRegisterSuccess({coupon_template_id: couponTemplateId});
    }
  };

  const handleEndReached = async () => {
    if (!hasNextPageRef.current) return;

    try {
      openToast({
        message: t('page_coupon_box_list_loading_toast'),
        colorVariant: 'gray',
        status: 'loading',
        autoHide: false,
      });
      await fetchNextPage();
    } finally {
      closeToast();
    }
  };

  return (
    <CouponView
      isLoading={isLoading}
      initialCouponCode={code}
      couponList={couponList}
      couponListFilter={couponListFilter}
      setCouponListFilter={setCouponListFilter}
      learningDomain={learningDomain}
      onBackClick={handleBackClick}
      onEndReached={handleEndReached}
      onCouponUseClick={handleCouponUse}
      onCouponRegister={handleRegisterCoupon}
    />
  );
};

export default CouponContainer;
