import {useRouter} from 'next/router';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {BREAKPOINT_MEDIUM, COLOR_SANTA_C} from '@riiid/design-system';
import {
  URL_IMG_LOGO_SANTAALC_2_DARK,
  URL_IMG_LOGO_SANTA_2_WHITE,
  URL_IMG_PAGEBANNER_00,
} from '@riiid/design-system/images/santa';
import {TopNavBar, useIsGteMediumScreen} from '@santa-web/service-ui';
import {FrameLayout} from '@app/components';
import {NARROW_MAX_WIDTH, WIDE_MAX_WIDTH} from '@app/constants/max-width';
import {use100vh} from '@app/hooks/use100vh';

type OnboardingLayoutProps = {
  children: React.ReactNode;
  bottomArea?: React.ReactNode;
  isNarrow?: boolean;
  shouldShowTopImage?: boolean;
  hasBackgroundColor?: boolean;
  onBackButtonClick?(): void;
};

const OnboardingLayout = React.forwardRef<HTMLDivElement, OnboardingLayoutProps>(
  (
    {
      children,
      bottomArea,
      isNarrow = false,
      shouldShowTopImage = false,
      hasBackgroundColor = false,
      onBackButtonClick,
      ...rest
    },
    ref
  ) => {
    const {i18n} = useTranslation();
    const router = useRouter();
    const viewportHeight = use100vh();
    const isGteMediumScreen = useIsGteMediumScreen();
    const shouldShowTopNavBar = !shouldShowTopImage;
    const isJA = i18n.languages[0] === 'ja';
    return (
      <div
        css={css`
          height: ${viewportHeight};
        `}
        {...rest}
        ref={ref}>
        <FrameLayout
          topNavBar={
            shouldShowTopNavBar && (
              <TopNavBar paginationIcon="back" onPaginationIconClick={onBackButtonClick ?? router.back} />
            )
          }
          bottomArea={bottomArea}>
          <div
            css={[
              css`
                width: 100%;
                height: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                padding-bottom: var(--mobile-safe-area-bottom);
              `,
              hasBackgroundColor &&
                css`
                  background-color: ${COLOR_SANTA_C};
                `,
            ]}>
            <div
              css={css`
                flex: 1;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
                overflow-y: auto;
              `}>
              {shouldShowTopImage && (
                <div
                  css={css`
                    position: relative;
                    width: 100%;
                    height: calc(var(--mobile-safe-area-top) + ${isGteMediumScreen ? 280 : 180}px);
                  `}>
                  <img
                    css={css`
                      width: 100%;
                      height: 100%;
                      object-fit: cover;
                      object-position: center;
                    `}
                    src={URL_IMG_PAGEBANNER_00}
                  />
                  {isJA ? (
                    <img
                      css={css`
                        position: absolute;
                        bottom: 20px;
                        right: 40px;
                      `}
                      width={120}
                      height={28}
                      src={URL_IMG_LOGO_SANTAALC_2_DARK}
                    />
                  ) : (
                    <img
                      css={css`
                        position: absolute;
                        bottom: 20px;
                        right: 40px;
                      `}
                      width={83}
                      height={40}
                      src={URL_IMG_LOGO_SANTA_2_WHITE}
                    />
                  )}
                </div>
              )}
              <div
                css={css`
                  flex: 1;
                  width: 100%;
                  max-width: ${isNarrow ? NARROW_MAX_WIDTH : WIDE_MAX_WIDTH};
                  padding: 40px 20px;

                  @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
                    padding: 40px;
                  }
                `}>
                {children}
              </div>
            </div>
          </div>
        </FrameLayout>
      </div>
    );
  }
);

export default OnboardingLayout;
export type {OnboardingLayoutProps};
OnboardingLayout.displayName = 'OnboardingLayout';
