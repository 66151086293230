import { HighlightFromJSON, HighlightToJSON, } from './Highlight';
/**
 * Check if a given object implements the ListHighlightsResponse interface.
 */
export function instanceOfListHighlightsResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "highlights" in value;
    return isInstance;
}
export function ListHighlightsResponseFromJSON(json) {
    return ListHighlightsResponseFromJSONTyped(json, false);
}
export function ListHighlightsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'highlights': (json['highlights'].map(HighlightFromJSON)),
    };
}
export function ListHighlightsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'highlights': (value.highlights.map(HighlightToJSON)),
    };
}
