import React from 'react';
import {produce} from 'immer';

import {ITestprepConfig} from '@app/types/user-config/testprep-config';

export interface ITestprepConfigChat {
  isAgree: boolean;
  handleAgree(): Promise<unknown>;
}

export const useTestprepConfigChat = (
  testprepConfig: ITestprepConfig,
  setTestprepConfig: (testprepConfig: ITestprepConfig) => Promise<unknown>
): ITestprepConfigChat => {
  const isAgree = React.useMemo(() => testprepConfig.chat?.agree ?? false, [testprepConfig.chat?.agree]);
  const handleAgree = React.useCallback(
    () =>
      setTestprepConfig(
        produce(testprepConfig, draft => {
          draft.chat = {
            ...(draft.chat ?? {}),
            agree: true,
          };
        })
      ),
    [setTestprepConfig, testprepConfig]
  );

  return React.useMemo(
    () => ({
      isAgree,
      handleAgree,
    }),
    [handleAgree, isAgree]
  );
};
