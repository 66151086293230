import React from 'react';
import {useTranslation} from 'react-i18next';
import {useAsyncFn} from 'react-use';
import {css} from '@emotion/react';

import {Button, BottomSheetProps} from '@santa-web/service-ui';
import BottomSheetWithDim, {BottomSheetWithDimProps} from '@app/components/BottomSheetWithDim';
import {ALERT_DIALOG_Z_INDEX} from '@app/constants/zIndex';

interface UserExistsBottomSheetProps extends BottomSheetWithDimProps {
  onLoginClick: () => Promise<void>;
  onCloseClick?: React.EventHandler<React.MouseEvent>;
}

const UserExistsBottomSheet = React.forwardRef<HTMLDivElement, UserExistsBottomSheetProps>(
  ({onLoginClick, onCloseClick, ...props}, ref) => {
    const {t} = useTranslation();
    const [{loading: isLoggingIn}, handleLoginClick] = useAsyncFn(onLoginClick, [onLoginClick]);

    const ButtonsInBottomSheet = React.useMemo(
      () => [
        <Button colorVariant="brand" isFullWidth onClick={handleLoginClick} key="login" isDisabled={isLoggingIn}>
          {t('bottom_sheet_user_already_exists_primary')}
        </Button>,
        <Button variant="box-line" isFullWidth onClick={onCloseClick} key="exit" isDisabled={isLoggingIn}>
          {t('bottom_sheet_user_already_exists_secondary')}
        </Button>,
      ],
      [handleLoginClick, isLoggingIn, onCloseClick, t]
    );

    const bottomSheetProps = React.useMemo(
      (): BottomSheetProps => ({
        title: t('bottom_sheet_user_already_exists_title'),
        description: t('bottom_sheet_user_already_exists_description'),
        buttons: ButtonsInBottomSheet,
        onCloseClick,
        isLoadingAiAnimationVisible: true,
      }),
      [onCloseClick, ButtonsInBottomSheet, t]
    );

    return (
      <BottomSheetWithDim
        ref={ref}
        css={css`
          z-index: ${ALERT_DIALOG_Z_INDEX};
        `}
        isOpened
        BottomSheetProps={bottomSheetProps}
        {...props}
      />
    );
  }
);

export default React.memo(UserExistsBottomSheet);
