import {useMutation, useQueryClient} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';

import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {getLearningGoalKey} from '@app/queryKeys';

export default function useDeleteLearningGoalMutation() {
  const queryClient = useQueryClient();
  const {LearningGoalService} = useAtomValue(santaOpenapiServicesAtom);

  return useMutation({
    mutationFn: async () => {
      await LearningGoalService.deleteLearningGoal({});
    },
    onSuccess: () => {
      queryClient.setQueryData(getLearningGoalKey(), null);
    },
  });
}
