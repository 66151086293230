import Link, {LinkProps} from 'next/link';
import React from 'react';

import {Button, ButtonProps} from '@santa-web/service-ui';

export interface LinkButtonProps extends ButtonProps {
  as?: React.ComponentType<ButtonProps>;
  href: LinkProps['href'];
  passHref?: LinkProps['passHref'];
  openInNewTab?: boolean;
}

const LinkButton = React.memo(
  React.forwardRef<HTMLButtonElement, LinkButtonProps>(({as, href, passHref, openInNewTab, ...buttonProps}, ref) => {
    const ButtonComponent = as ?? Button;
    const button = <ButtonComponent type="button" {...buttonProps} ref={ref} />;
    const anchorProps = openInNewTab
      ? {
          target: '_blank',
          rel: 'noopener',
        }
      : undefined;
    return (
      <Link href={href} passHref={passHref} legacyBehavior>
        {passHref ? <a {...anchorProps}>{button}</a> : button}
      </Link>
    );
  })
);

export default LinkButton;
