import { URL_IMG_LOGO_SANTA_LIGHT, URL_IMG_LOGO_SANTA_DARK, URL_IMG_LOGO_ALC_LIGHT, URL_IMG_LOGO_ALC_DARK, URL_IMG_LOGO_SANTAARC_LIGHT, URL_IMG_LOGO_SANTAARC_DARK } from "@riiid/design-system/images/santa";
import { URL_GIF_CARD_AIBLOCK_BACKGROUND_01_LIGHT, URL_GIF_CARD_AIBLOCK_BACKGROUND_01_DARK, URL_GIF_CARD_AIBLOCK_BACKGROUND_01_960_LIGHT, URL_GIF_CARD_AIBLOCK_BACKGROUND_01_960_DARK, URL_GIF_CARD_AIBLOCK_BACKGROUND_02_LIGHT, URL_GIF_CARD_AIBLOCK_BACKGROUND_02_DARK, URL_GIF_CARD_AIBLOCK_BACKGROUND_02_960_LIGHT, URL_GIF_CARD_AIBLOCK_BACKGROUND_02_960_DARK, URL_GIF_CARD_AIBLOCK_BACKGROUND_03_LIGHT, URL_GIF_CARD_AIBLOCK_BACKGROUND_03_DARK, URL_GIF_CARD_AIBLOCK_BACKGROUND_03_960_LIGHT, URL_GIF_CARD_AIBLOCK_BACKGROUND_03_960_DARK, URL_GIF_CARD_AIBLOCK_BACKGROUND_04_LIGHT, URL_GIF_CARD_AIBLOCK_BACKGROUND_04_DARK, URL_GIF_CARD_AIBLOCK_BACKGROUND_04_960_LIGHT, URL_GIF_CARD_AIBLOCK_BACKGROUND_04_960_DARK, URL_GIF_CARD_AIBLOCK_ICON_AI_LIGHT, URL_GIF_CARD_AIBLOCK_ICON_AI_DARK, URL_GIF_CARD_AIBLOCK_ICON_DOWN_LIGHT, URL_GIF_CARD_AIBLOCK_ICON_DOWN_DARK, URL_GIF_CARD_AIBLOCK_ICON_INFO_LIGHT, URL_GIF_CARD_AIBLOCK_ICON_INFO_DARK, URL_GIF_CARD_AIBLOCK_ICON_RISE_LIGHT, URL_GIF_CARD_AIBLOCK_ICON_RISE_DARK } from "@riiid/design-system/lotties/santa";
import useColorTheme from "./useColorTheme";
export default function useColorThemeImage() {
    var colorTheme = useColorTheme();
    return colorTheme === "light" ? {
        cardAiBlockBackground1AnimationData: URL_GIF_CARD_AIBLOCK_BACKGROUND_01_LIGHT,
        cardAiBlockBackground2AnimationData: URL_GIF_CARD_AIBLOCK_BACKGROUND_02_LIGHT,
        cardAiBlockBackground3AnimationData: URL_GIF_CARD_AIBLOCK_BACKGROUND_03_LIGHT,
        cardAiBlockBackground4AnimationData: URL_GIF_CARD_AIBLOCK_BACKGROUND_04_LIGHT,
        cardAiBlockBackground1LargeAnimationData: URL_GIF_CARD_AIBLOCK_BACKGROUND_01_960_LIGHT,
        cardAiBlockBackground2LargeAnimationData: URL_GIF_CARD_AIBLOCK_BACKGROUND_02_960_LIGHT,
        cardAiBlockBackground3LargeAnimationData: URL_GIF_CARD_AIBLOCK_BACKGROUND_03_960_LIGHT,
        cardAiBlockBackground4LargeAnimationData: URL_GIF_CARD_AIBLOCK_BACKGROUND_04_960_LIGHT,
        riseLottie: URL_GIF_CARD_AIBLOCK_ICON_RISE_LIGHT,
        downLottie: URL_GIF_CARD_AIBLOCK_ICON_DOWN_LIGHT,
        infoLottie: URL_GIF_CARD_AIBLOCK_ICON_INFO_LIGHT,
        aipickLottie: URL_GIF_CARD_AIBLOCK_ICON_AI_LIGHT,
        santaLogoImageUrl: URL_IMG_LOGO_SANTA_LIGHT,
        santaAlcLogoImageUrl: URL_IMG_LOGO_SANTAARC_LIGHT,
        alcLogoImageUrl: URL_IMG_LOGO_ALC_LIGHT
    } : {
        cardAiBlockBackground1AnimationData: URL_GIF_CARD_AIBLOCK_BACKGROUND_01_DARK,
        cardAiBlockBackground2AnimationData: URL_GIF_CARD_AIBLOCK_BACKGROUND_02_DARK,
        cardAiBlockBackground3AnimationData: URL_GIF_CARD_AIBLOCK_BACKGROUND_03_DARK,
        cardAiBlockBackground4AnimationData: URL_GIF_CARD_AIBLOCK_BACKGROUND_04_DARK,
        cardAiBlockBackground1LargeAnimationData: URL_GIF_CARD_AIBLOCK_BACKGROUND_01_960_DARK,
        cardAiBlockBackground2LargeAnimationData: URL_GIF_CARD_AIBLOCK_BACKGROUND_02_960_DARK,
        cardAiBlockBackground3LargeAnimationData: URL_GIF_CARD_AIBLOCK_BACKGROUND_03_960_DARK,
        cardAiBlockBackground4LargeAnimationData: URL_GIF_CARD_AIBLOCK_BACKGROUND_04_960_DARK,
        riseLottie: URL_GIF_CARD_AIBLOCK_ICON_RISE_DARK,
        downLottie: URL_GIF_CARD_AIBLOCK_ICON_DOWN_DARK,
        infoLottie: URL_GIF_CARD_AIBLOCK_ICON_INFO_DARK,
        aipickLottie: URL_GIF_CARD_AIBLOCK_ICON_AI_DARK,
        santaLogoImageUrl: URL_IMG_LOGO_SANTA_DARK,
        santaAlcLogoImageUrl: URL_IMG_LOGO_SANTAARC_DARK,
        alcLogoImageUrl: URL_IMG_LOGO_ALC_DARK
    };
}
