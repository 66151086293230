/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { PointRewardL2ePaybackUrlFromJSON, PointRewardL2ePaybackUrlToJSON, } from './PointRewardL2ePaybackUrl';
import { PointRewardMyL2ePointFromJSON, PointRewardMyL2ePointToJSON, } from './PointRewardMyL2ePoint';
import { PointRewardMyRankPointFromJSON, PointRewardMyRankPointToJSON, } from './PointRewardMyRankPoint';
import { PointRewardRankPointStatusFromJSON, PointRewardRankPointStatusToJSON, } from './PointRewardRankPointStatus';
/**
 * Check if a given object implements the PointRewardInfo interface.
 */
export function instanceOfPointRewardInfo(value) {
    let isInstance = true;
    isInstance = isInstance && "myPointRanking" in value;
    isInstance = isInstance && "rankPointStatus" in value;
    return isInstance;
}
export function PointRewardInfoFromJSON(json) {
    return PointRewardInfoFromJSONTyped(json, false);
}
export function PointRewardInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'l2ePaybackUrl': !exists(json, 'l2ePaybackUrl') ? undefined : PointRewardL2ePaybackUrlFromJSON(json['l2ePaybackUrl']),
        'myL2ePoint': !exists(json, 'myL2ePoint') ? undefined : PointRewardMyL2ePointFromJSON(json['myL2ePoint']),
        'myPointRanking': PointRewardMyRankPointFromJSON(json['myPointRanking']),
        'rankPointStatus': PointRewardRankPointStatusFromJSON(json['rankPointStatus']),
    };
}
export function PointRewardInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'l2ePaybackUrl': PointRewardL2ePaybackUrlToJSON(value.l2ePaybackUrl),
        'myL2ePoint': PointRewardMyL2ePointToJSON(value.myL2ePoint),
        'myPointRanking': PointRewardMyRankPointToJSON(value.myPointRanking),
        'rankPointStatus': PointRewardRankPointStatusToJSON(value.rankPointStatus),
    };
}
