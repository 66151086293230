export var isVisibleElement = function(element, root, offset) {
    var _offset_left, _offset_right;
    var _ref = [
        (_offset_left = offset === null || offset === void 0 ? void 0 : offset.left) !== null && _offset_left !== void 0 ? _offset_left : 0,
        (_offset_right = offset === null || offset === void 0 ? void 0 : offset.right) !== null && _offset_right !== void 0 ? _offset_right : 0
    ], leftOffset = _ref[0], rightOffset = _ref[1];
    return root.getBoundingClientRect().x + leftOffset <= element.getBoundingClientRect().x && root.getBoundingClientRect().x + root.getBoundingClientRect().width - rightOffset >= element.getBoundingClientRect().x + element.getBoundingClientRect().width;
};
export var getElementRelativeX = function(element, scrollableRoot) {
    return element.getBoundingClientRect().x - scrollableRoot.getBoundingClientRect().x + scrollableRoot.scrollLeft;
};
export var getElementXToScrollLeft = function(element, scrollableRoot) {
    var offset = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : 0;
    return getElementRelativeX(element, scrollableRoot) - offset;
};
export var getElementXToScrollRight = function(element, scrollableRoot) {
    var offset = arguments.length > 2 && arguments[2] !== void 0 ? arguments[2] : 0;
    var relativeX = getElementRelativeX(element, scrollableRoot);
    return relativeX - scrollableRoot.getBoundingClientRect().width + element.getBoundingClientRect().width + offset;
};
