import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {COLOR_SANTA_BD, COLOR_SANTA_I, COLOR_SANTA_L, COLOR_SANTA_O_ALPHA} from '@riiid/design-system';

import {Typography} from '@santa-web/service-ui';
import AnalyticsProgress from '@app/components/AnalyticsProgress';
import BaseCard from '@app/components/card/BaseCard';
import Legend from '@app/components/Legend';
import LegendList from '@app/components/LegendList';

export interface AnalyticsProgressData {
  title: string;
  // This prop ranges from -100 to 100.
  nowPercent: number;
  // This prop ranges from -100 to 100.
  changedPercent?: number;
  // This prop ranges from -100 to 100.
  goalPercent?: number;
}

export type AnalyticsWeakPointCardProps = Omit<React.ComponentPropsWithoutRef<'div'>, 'title' | 'content'> & {
  title?: string | null;
  content?: string | null;
  data: AnalyticsProgressData[];
  isLegendVisible?: boolean | null;
  targetScore?: number | null;
  isScoreHidden?: boolean;
};

const AnalyticsWeakPointCard = React.forwardRef<HTMLDivElement, AnalyticsWeakPointCardProps>(
  ({title, data, content, isLegendVisible, targetScore, isScoreHidden = false, ...props}, ref) => {
    const {t} = useTranslation();
    return (
      <BaseCard
        css={css`
          padding: 20px;
        `}
        {...props}
        ref={ref}>
        <Typography
          css={css`
            margin-bottom: 12px;
          `}
          fontWeight="bold"
          variant="body2"
          color={COLOR_SANTA_L}>
          {title}
        </Typography>
        <Typography
          css={css`
            margin-bottom: 32px;
          `}
          fontWeight="regular"
          variant="body3"
          color={COLOR_SANTA_I}>
          {content}
        </Typography>
        {data.map(item => (
          <AnalyticsProgress
            css={css`
              margin-bottom: 32px;
            `}
            key={item.title}
            isEmpty={isScoreHidden}
            {...item}
          />
        ))}
        {isLegendVisible ? (
          <LegendList data-testid="AnalyticsWeakPointCard-LegendList">
            <Legend
              color={COLOR_SANTA_BD}
              subject={t('diagnosis_report_view_weak_point_card_weak_concept')}
              variant="square"
            />
            <Legend
              color={COLOR_SANTA_O_ALPHA(0.2)}
              subject={t('diagnosis_report_view_weak_point_card_weak_score_user', {targetScore: targetScore})}
              variant="square"
            />
          </LegendList>
        ) : null}
      </BaseCard>
    );
  }
);

export default React.memo(AnalyticsWeakPointCard);
