import { HighlightColorFromJSON, HighlightColorToJSON, } from './HighlightColor';
import { HighlightContentIdentifierFromJSON, HighlightContentIdentifierToJSON, } from './HighlightContentIdentifier';
/**
 * Check if a given object implements the Highlight interface.
 */
export function instanceOfHighlight(value) {
    let isInstance = true;
    isInstance = isInstance && "color" in value;
    isInstance = isInstance && "contentIdentifier" in value;
    isInstance = isInstance && "contentInteractionStateId" in value;
    isInstance = isInstance && "displayInfoJson" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "previewStr" in value;
    return isInstance;
}
export function HighlightFromJSON(json) {
    return HighlightFromJSONTyped(json, false);
}
export function HighlightFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'color': HighlightColorFromJSON(json['color']),
        'contentIdentifier': HighlightContentIdentifierFromJSON(json['contentIdentifier']),
        'contentInteractionStateId': json['contentInteractionStateId'],
        'displayInfoJson': json['displayInfoJson'],
        'id': json['id'],
        'previewStr': json['previewStr'],
    };
}
export function HighlightToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'color': HighlightColorToJSON(value.color),
        'contentIdentifier': HighlightContentIdentifierToJSON(value.contentIdentifier),
        'contentInteractionStateId': value.contentInteractionStateId,
        'displayInfoJson': value.displayInfoJson,
        'id': value.id,
        'previewStr': value.previewStr,
    };
}
