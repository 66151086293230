import React from 'react';
import {useTranslation} from 'react-i18next';
import {useAtomValue} from 'jotai';
import learningDomainAtom from '@app/atoms/core/learning-domain';
import {
  useActivatePass,
  useCanActivatePass,
  useConsumeTicket,
  usePassesInfiniteQuery,
  useTicketGroupsInfiniteQuery,
} from '@app/features/permit/hooks';
import {mapPassToPassCardProps, mapTicketGroupToTicketCardProps} from '@app/features/permit/utils';
import {PermitListPageView, PermitListPageViewProps, PermitListPageViewTab} from '@app/features/permit/views';
import useUser from '@app/hooks/user/useUser';
import {useTypedRouter, useTypedSearchParams} from '@app/hooks/useTypedRouter';

const PermitListPageContainer = () => {
  const {t} = useTranslation();
  const router = useTypedRouter();
  const searchParams = useTypedSearchParams('/permit/list');
  const tab: PermitListPageViewTab = searchParams.tab === 'ticket' ? 'ticket' : 'pass';
  const learningDomain = useAtomValue(learningDomainAtom);

  const {data: user} = useUser();
  const {data: passPages, hasNextPage: hasNextPass, fetchNextPage: fetchNextPasses} = usePassesInfiniteQuery();
  const {
    data: ticketPages,
    hasNextPage: hasNextTicket,
    fetchNextPage: fetchNextTickets,
  } = useTicketGroupsInfiniteQuery();
  const canActivatePass = useCanActivatePass();
  const {activatePass} = useActivatePass();
  const consumeTicket = useConsumeTicket();

  const isLoading = !user || !passPages || !ticketPages || canActivatePass == null;

  const passTabProps: PermitListPageViewProps['passTabProps'] = React.useMemo(
    () => ({
      hasNextCard: hasNextPass,
      items:
        passPages?.pages.flatMap(page =>
          page.pass.map(pass => ({
            ...mapPassToPassCardProps(pass, canActivatePass ?? false),
            id: pass.id,
            onButtonClick: () => activatePass({...pass, learningDomainDisplayName: learningDomain.displayName}),
            additionalInformation: {
              grantedFrom: pass.issuedFrom,
              permitTypes: pass.permitTypes.map(
                permitType =>
                  ({
                    RECOMMENDED_LEARNING_CONTENT: t('component_pass_card_permit_type_course_learning'),
                    REVIEW_QUIZ_LEARNING_CONTENT: t('component_pass_card_permit_type_review_quiz'),
                    SELF_STUDY_LEARNING_CONTENT: t('component_pass_card_permit_type_self_learning'),
                  }[permitType])
              ),
            },
          }))
        ) ?? [],
      onLastCardVisible: fetchNextPasses,
    }),
    [activatePass, canActivatePass, fetchNextPasses, hasNextPass, learningDomain.displayName, passPages?.pages, t]
  );

  const ticketTabProps: PermitListPageViewProps['ticketTabProps'] = React.useMemo(
    () => ({
      hasNextCard: hasNextTicket,
      items:
        ticketPages?.pages.flatMap(page =>
          page.ticketGroups.map(ticketGroup => ({
            ...mapTicketGroupToTicketCardProps(ticketGroup),
            onButtonClick: () => consumeTicket(ticketGroup),
            additionalInformation: {
              grantedFrom: ticketGroup.issuedFrom,
              permitTypes: [
                {
                  DOWNLOADABLE_FILE: t('component_ticket_card_permit_type_downloadable'),
                  GIFT_CODE: t('component_ticket_card_permit_type_gift_code'),
                  EXTERNAL_LINK: t('component_ticket_card_permit_type_external_link'),
                  RECOMMENDED_LEARNING_CONTENT: t('component_ticket_card_permit_type_course_learning'),
                }[ticketGroup.permitType],
              ],
            },
          }))
        ) ?? [],
      onLastCardVisible: fetchNextTickets,
    }),
    [consumeTicket, fetchNextTickets, hasNextTicket, t, ticketPages?.pages]
  );

  if (isLoading) {
    return <PermitListPageView.Skeleton onBackClick={router.back} />;
  }

  return (
    <PermitListPageView
      username={user.personalInfo?.name ?? 'USERNAME'}
      learningDomain={learningDomain.displayName}
      currentTab={tab}
      passTabProps={passTabProps}
      ticketTabProps={ticketTabProps}
      onTabChange={tab => router.replace({pathname: '/permit/list', query: {...searchParams, tab}})}
      onBackClick={router.back}
    />
  );
};

export default PermitListPageContainer;
PermitListPageContainer.displayName = 'PermitListPageContainer';
