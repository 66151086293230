import React from 'react';
import {useAtomValue} from 'jotai';
import useOnboardingEvents from '@app/api/google-tag-manager/onboarding';
import learningDomainAtom from '@app/atoms/core/learning-domain';
import {useDiagnosisConfigQuery, useGoToDiagnosisLearningCell} from '@app/features/diagnosis';
import {useOnboardingStep, useOnboardingStepCorrectionEffect} from '@app/features/onboarding/hooks';
import {DiagnosisPreparingView} from '@app/features/onboarding/views';
import useUserQuery from '@app/hooks/user/useUser';
import useUserProfileQuery from '@app/hooks/user/useUserProfile';
import {useTypedRouter} from '@app/hooks/useTypedRouter';
import {useQueryState} from '@app/misc/query-state';

const DiagnosisPreparingContainer = () => {
  const router = useTypedRouter();
  const queryState = useQueryState();
  const {goToPreviousStep} = useOnboardingStep();
  const {pushStartSignupEvent} = useOnboardingEvents();
  const learningDomain = useAtomValue(learningDomainAtom);
  const {data: user} = useUserQuery();
  const {data: userProfile} = useUserProfileQuery();
  const {data: diagnosisConfig} = useDiagnosisConfigQuery();

  const isLoading = user == null || userProfile == null || !diagnosisConfig;

  const goToDiagnosisLearningCell = useGoToDiagnosisLearningCell('onboarding');

  const handleDiagnosisAnswer = async (shouldGoToDiagnosis: boolean) => {
    if (shouldGoToDiagnosis) {
      await goToDiagnosisLearningCell();
    } else {
      pushStartSignupEvent();
      router.push({pathname: '/signup', query: queryState.toQuery()});
    }
  };

  useOnboardingStepCorrectionEffect();

  return isLoading ? null : (
    <DiagnosisPreparingView
      isNarrow={user.registrationType !== 'ANONYMOUS'}
      diagnosisStatus={userProfile.diagnosisInfo.status === 'READY' ? 'NOT_STARTED' : userProfile.diagnosisInfo.status}
      domainName={learningDomain.displayName}
      title={diagnosisConfig.config.displayConfig.titleText}
      description={diagnosisConfig.config.displayConfig.descriptionText}
      onAnswer={handleDiagnosisAnswer}
      onBackButtonClick={goToPreviousStep}
    />
  );
};

export default DiagnosisPreparingContainer;
DiagnosisPreparingContainer.displayName = 'DiagnosisPreparingContainer';
