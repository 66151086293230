import {useRouter} from 'next/router';
import React, {useCallback} from 'react';

import {useEventDetailQuery} from '@app/features/marketing/hooks';
import {EventDetailPageView, EventCtaButtonProps} from '@app/features/marketing/views';
import {useOpenNewWindow} from '@app/hooks/useOpenNewWindow';
import {isAppBridgeAvailable} from '@app/utils/app-bridge';

export interface EventDetailPageContainerProps {
  promotionId: number;
}

const EventDetailPageContainer = ({promotionId}: EventDetailPageContainerProps): JSX.Element => {
  const router = useRouter();
  const openNewWindow = useOpenNewWindow();
  const {data: promotion} = useEventDetailQuery(promotionId);

  const _buttons = React.useMemo(() => {
    const result = [];
    if (promotion?.mainButton) {
      result.push(promotion.mainButton);
    }
    promotion?.subButtons.forEach(subButton => {
      result.push(subButton);
    });
    return result;
  }, [promotion]);

  const buttons: EventCtaButtonProps[] = React.useMemo(
    () =>
      _buttons.map((button, idx) => ({
        id: idx,
        isDisabled: !button.active,
        value: button.text,
      })),
    [_buttons]
  );

  const handleButtonClick = useCallback(
    async (idx: number) => {
      const button = _buttons[idx];
      if (isAppBridgeAvailable()) {
        openNewWindow(button.appUrl);
        return;
      }
      if (button.webNewWindow) {
        openNewWindow(button.webUrl);
      } else {
        router.push(button.webUrl);
      }
    },
    [_buttons, openNewWindow, router]
  );

  return (
    <EventDetailPageView
      detailImageSet={promotion?.detailImageSet}
      buttons={buttons}
      onButtonClick={handleButtonClick}
      onExit={router.back}
    />
  );
};

export default React.memo(EventDetailPageContainer);
