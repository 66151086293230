import React from 'react';
import {browserService} from '@app/api/app-bridge/browser-service';
import {useNavigateDeepLink} from '@app/api/app-bridge/deep-link';
import {isAppBridgeAvailable} from '@app/utils/app-bridge';

export const useSubscribeDeepLinkEffect = () => {
  const navigateDeepLink = useNavigateDeepLink();
  React.useEffect(() => {
    if (!isAppBridgeAvailable()) {
      return;
    }
    browserService.subscribeDeepLink(navigateDeepLink);
  }, [navigateDeepLink]);
};
