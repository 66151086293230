/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { DisplayInfoV2FromJSON, DisplayInfoV2ToJSON, } from './DisplayInfoV2';
import { OneOfPassStatusFromJSON, OneOfPassStatusToJSON, } from './OneOfPassStatus';
import { PassPermitTypeFromJSON, PassPermitTypeToJSON, } from './PassPermitType';
/**
 * Check if a given object implements the PassListItemV2 interface.
 */
export function instanceOfPassListItemV2(value) {
    let isInstance = true;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "displayInfo" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "issuedFrom" in value;
    isInstance = isInstance && "permitTypes" in value;
    isInstance = isInstance && "status" in value;
    return isInstance;
}
export function PassListItemV2FromJSON(json) {
    return PassListItemV2FromJSONTyped(json, false);
}
export function PassListItemV2FromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'createdAt': (new Date(json['createdAt'])),
        'displayInfo': DisplayInfoV2FromJSON(json['displayInfo']),
        'expireAt': !exists(json, 'expireAt') ? undefined : (new Date(json['expireAt'])),
        'id': json['id'],
        'issuedFrom': json['issuedFrom'],
        'permitTypes': (json['permitTypes'].map(PassPermitTypeFromJSON)),
        'status': OneOfPassStatusFromJSON(json['status']),
    };
}
export function PassListItemV2ToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'createdAt': (value.createdAt.toISOString()),
        'displayInfo': DisplayInfoV2ToJSON(value.displayInfo),
        'expireAt': value.expireAt === undefined ? undefined : (value.expireAt.toISOString()),
        'id': value.id,
        'issuedFrom': value.issuedFrom,
        'permitTypes': (value.permitTypes.map(PassPermitTypeToJSON)),
        'status': OneOfPassStatusToJSON(value.status),
    };
}
