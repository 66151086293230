/**
 * Check if a given object implements the CreateOrderRequest interface.
 */
export function instanceOfCreateOrderRequest(value) {
    let isInstance = true;
    isInstance = isInstance && "offerId" in value;
    return isInstance;
}
export function CreateOrderRequestFromJSON(json) {
    return CreateOrderRequestFromJSONTyped(json, false);
}
export function CreateOrderRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'offerId': json['offerId'],
    };
}
export function CreateOrderRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'offerId': value.offerId,
    };
}
