/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { OfferAdditionalPropertyCaseFromJSON, OfferAdditionalPropertyCaseToJSON, } from './OfferAdditionalPropertyCase';
import { SinglePurchaseOfferAdditionalPropertiesFromJSON, SinglePurchaseOfferAdditionalPropertiesToJSON, } from './SinglePurchaseOfferAdditionalProperties';
import { SubscriptionOfferAdditionalPropertiesFromJSON, SubscriptionOfferAdditionalPropertiesToJSON, } from './SubscriptionOfferAdditionalProperties';
/**
 * Check if a given object implements the OneOfOfferAdditionalProperties interface.
 */
export function instanceOfOneOfOfferAdditionalProperties(value) {
    let isInstance = true;
    isInstance = isInstance && "oneOfCase" in value;
    return isInstance;
}
export function OneOfOfferAdditionalPropertiesFromJSON(json) {
    return OneOfOfferAdditionalPropertiesFromJSONTyped(json, false);
}
export function OneOfOfferAdditionalPropertiesFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'oneOfCase': OfferAdditionalPropertyCaseFromJSON(json['oneOfCase']),
        'singlePurchase': !exists(json, 'singlePurchase') ? undefined : SinglePurchaseOfferAdditionalPropertiesFromJSON(json['singlePurchase']),
        'subscription': !exists(json, 'subscription') ? undefined : SubscriptionOfferAdditionalPropertiesFromJSON(json['subscription']),
    };
}
export function OneOfOfferAdditionalPropertiesToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'oneOfCase': OfferAdditionalPropertyCaseToJSON(value.oneOfCase),
        'singlePurchase': SinglePurchaseOfferAdditionalPropertiesToJSON(value.singlePurchase),
        'subscription': SubscriptionOfferAdditionalPropertiesToJSON(value.subscription),
    };
}
