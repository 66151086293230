/* tslint:disable */
/* eslint-disable */
/**
 * 할인 쿠폰의 할인 방식 분류 (비율 or 고정 금액)
 *
 * - **RATE**: 비율 할인
 * - **AMOUNT**: 고정 금액 할인
 * @export
 */
export const CouponDetailsDiscountMethodCase = {
    RATE: 'RATE',
    AMOUNT: 'AMOUNT'
};
export function CouponDetailsDiscountMethodCaseFromJSON(json) {
    return CouponDetailsDiscountMethodCaseFromJSONTyped(json, false);
}
export function CouponDetailsDiscountMethodCaseFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function CouponDetailsDiscountMethodCaseToJSON(value) {
    return value;
}
