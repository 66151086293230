import {useRef, useEffect, useMemo, useCallback, memo} from 'react';
import {useTranslation} from 'react-i18next';
import {useInterval} from 'react-use';
import {YouTubeProps} from 'react-youtube';
import {css} from '@emotion/react';
import {BREAKPOINT_MEDIUM} from '@riiid/design-system';

import {Lesson} from '@santa-web/gen/open-api/content/models/Lesson';
import {EmptyMovieNote, YoutubeEmbed, MovieNote} from '@santa-web/service-ui';
import {useDeviceStateContext} from '@app/contexts/DeviceStateContext';
import {isEmptySnippet} from '@app/facade/snippet';
import useFloatingAreaHeight from '@app/hooks/useFloatingAreaHeight';
import SnippetView from '@app/test-preparation/components/SnippetView';

interface LessonLayoutProps {
  lesson: Lesson;
  youtubeTimestamp: number;
  onVideoPlay: () => void;
  onPlaybackRateChange: (playbackRate: number) => void;
  onTimestampChange?: (timestamp: number) => void;
  subscribePlaybackRateSelect: (listener: (playbackRate: number) => void) => () => void;
}

enum PlayerState {
  UNSTARTED = -1,
  ENDED = 0,
  PLAYING = 1,
  PAUSED = 2,
  BUFFERING = 3,
  CUED = 5,
}

// Partially Typed,
// reference : https://developers.google.com/youtube/iframe_api_reference?hl=ko#Playback_controls
interface YoutubeVideo {
  pauseVideo: () => void;
  getPlaybackRate(): number;
  getCurrentTime(): number;
  getDuration(): number;
  getPlayerState(): PlayerState;
  setPlaybackRate(suggestedRate: number): void;
  seekTo(seconds: number, allowSeekAhead: boolean): void;
}

// Partially typed
interface YoutubeHandlerEvent {
  target: YoutubeVideo;
}

const LessonLayout = ({
  lesson: {title, youtubeUrl, body},
  youtubeTimestamp,
  onVideoPlay,
  onPlaybackRateChange,
  onTimestampChange,
  subscribePlaybackRateSelect,
}: LessonLayoutProps) => {
  const {t} = useTranslation();
  const floatingAreaHeight = useFloatingAreaHeight();
  const isBackground = useDeviceStateContext() === 'BACKGROUND';

  const videoRef = useRef<YoutubeVideo>();

  useEffect(() => {
    if (!isBackground || !videoRef.current) return;
    videoRef.current.pauseVideo();
  }, [isBackground]);

  useEffect(() => {
    return subscribePlaybackRateSelect(playbackRate => videoRef.current?.setPlaybackRate(playbackRate));
  }, [subscribePlaybackRateSelect]);

  useInterval(() => handleTimestampChange(), 1000);

  const handlePlaybackRateChange = useCallback(() => {
    if (!videoRef.current) return;
    onPlaybackRateChange(videoRef.current.getPlaybackRate());
  }, [onPlaybackRateChange]);

  const handleTimestampChange = useCallback(() => {
    if (!videoRef.current || !onTimestampChange) return;
    if (videoRef.current.getPlayerState() !== PlayerState.PLAYING) return;
    onTimestampChange(Math.floor(videoRef.current.getCurrentTime()));
  }, [onTimestampChange]);

  const handleTimestampChangeOnEnd = useCallback(() => {
    if (!videoRef.current || !onTimestampChange) return;
    if (videoRef.current.getPlayerState() !== PlayerState.ENDED) return;
    onTimestampChange(Math.floor(videoRef.current.getDuration()));
  }, [onTimestampChange]);

  const content = useMemo(() => {
    if (isEmptySnippet(body)) return <EmptyMovieNote content={t('toeic_lesson_view_empty_movie_note')} />;
    return <SnippetView snippet={body} />;
  }, [t, body]);

  const youtubeProps = useMemo<YouTubeProps>(() => {
    const handlePlay = () => {
      onVideoPlay();
    };

    const handleReady = (event: YoutubeHandlerEvent) => {
      videoRef.current = event.target;
      videoRef.current.seekTo(youtubeTimestamp, true);
      videoRef.current.pauseVideo();
      handlePlaybackRateChange();
    };

    const baseProps = {
      onPlay: handlePlay,
      onReady: handleReady,
      onPlaybackRateChange: handlePlaybackRateChange,
      onTimestampChange: handleTimestampChange,
      onEnd: handleTimestampChangeOnEnd,
    };

    try {
      const youtubeURLObject = new URL(youtubeUrl || '');
      const videoId = youtubeURLObject.origin.includes('youtu.be')
        ? youtubeURLObject.pathname.substring(1)
        : new URLSearchParams(youtubeURLObject.search).get('v');

      return {
        videoId: videoId || '',
        ...baseProps,
      };
    } catch (error) {
      return {
        videoId: '',
        ...baseProps,
      };
    }
  }, [
    handlePlaybackRateChange,
    handleTimestampChange,
    handleTimestampChangeOnEnd,
    onVideoPlay,
    youtubeTimestamp,
    youtubeUrl,
  ]);

  return (
    <div
      css={css`
        position: relative;
        display: flex;
        flex-direction: column;
        @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
          height: 100%;
          flex-direction: row;
        }
      `}>
      <YoutubeEmbed
        YoutubeProps={youtubeProps}
        css={css`
          position: sticky;
          top: 0;
          z-index: 1;
          @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
            position: static;
            display: flex;
            flex-direction: column;
            justify-content: center;
            width: 50%;
            padding: 40px;
          }
        `}
      />
      <MovieNote
        css={css`
          padding-bottom: ${floatingAreaHeight}px;
          @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
            width: 50%;
            overflow-y: scroll;
          }
        `}
        pageTitle={t('toeic_lesson_view_movie_note_title')}
        title={title || ''}
        content={content}
      />
    </div>
  );
};

export default memo(LessonLayout);
