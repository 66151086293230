import 'swiper/css';
import React from 'react';
import {css} from '@emotion/react';
import {Swiper as SwiperClass} from 'swiper';
import {
  OfferGroupDetailTimer,
  CloseButton,
  DynamicImageLoader,
  OfferCardSlide,
  OfferLayout,
} from '@app/features/offer/components';
import {OfferGroupDetail} from '@app/features/offer/views/types';

type OfferGroupDetailListViewProps = {
  offerGroupDetails: Array<OfferGroupDetail>;
  onCloseClick(): void;
  onCurrentOfferGroupIndexChange(index: number): void;
  currentOfferGroupIndex: number;
};

const MAX_IMAGE_WIDTH = '440px';

const OfferGroupDetailListView = ({
  offerGroupDetails,
  onCloseClick,
  onCurrentOfferGroupIndexChange,
  currentOfferGroupIndex,
}: OfferGroupDetailListViewProps) => {
  const [shouldShowOfferDetailUIs, setShouldShowOfferDetailUIs] = React.useState(true);
  const currentTimer = offerGroupDetails[currentOfferGroupIndex].timer;
  const hasValidTimer = currentTimer && currentTimer.remainedTimeMs > 0;

  const representativeOffers = offerGroupDetails.map(({offerCards}) => {
    const offerCard = offerCards[0];
    if (offerCards.every(offerCard => offerCard.errorLabel)) {
      return offerCard;
    }
    return {...offerCard, errorLabel: undefined};
  });
  const {title: currentOfferGroupTitle, imageSrcs: currentOfferGroupImageUrls} =
    offerGroupDetails[currentOfferGroupIndex];

  const handleSlideChange = React.useCallback(
    (swiper: SwiperClass) => {
      onCurrentOfferGroupIndexChange(swiper.realIndex);
    },
    [onCurrentOfferGroupIndexChange]
  );

  const handleScroll: React.UIEventHandler<HTMLDivElement> = event => {
    const {scrollHeight, scrollTop, clientHeight} = event.currentTarget;
    const isAtTop = scrollTop === 0;
    const isAtBottom = Math.ceil(scrollTop + clientHeight) >= scrollHeight; // 안드로이드에선 scrollTop을 소숫점까지 처리함. <= 1의 오차로 인해 false로 평가되기에, 이를 보정하는 ceil 함수 적용

    setShouldShowOfferDetailUIs(isAtTop || isAtBottom);
  };

  return (
    <OfferLayout onScroll={handleScroll}>
      <div
        css={css`
          display: flex;
          flex-direction: column;
          align-items: center;
          height: 100%;
        `}>
        <DynamicImageLoader
          key={currentOfferGroupIndex}
          urls={currentOfferGroupImageUrls}
          onImageClick={() => setShouldShowOfferDetailUIs(!shouldShowOfferDetailUIs)}
          imgAlt={`${currentOfferGroupTitle}Image`}
          imgCss={css`
            max-width: ${MAX_IMAGE_WIDTH};
          `}
          imgStateWrapperCss={css`
            margin-top: 60px;
          `}
        />
        <div
          css={css`
            position: sticky;
            display: flex;
            justify-content: center;
            bottom: 0;
            width: 100%;
            visibility: ${shouldShowOfferDetailUIs ? 'visible' : 'hidden'};
          `}>
          <OfferCardSlide
            offerCards={representativeOffers}
            breakPointWidth={MAX_IMAGE_WIDTH}
            onSlideChange={handleSlideChange}
            initialIndex={currentOfferGroupIndex}
          />
        </div>
        <div
          css={
            !shouldShowOfferDetailUIs &&
            css`
              display: none;
            `
          }>
          <div
            css={css`
              position: absolute;
              top: 20px;
              left: 20px;
            `}>
            <CloseButton onClick={onCloseClick} />
          </div>
          {hasValidTimer && (
            <OfferGroupDetailTimer
              css={css`
                position: absolute;
                top: 24px;
                left: 50%;
                transform: translateX(-50%);
              `}
              {...currentTimer}
            />
          )}
        </div>
      </div>
    </OfferLayout>
  );
};

export default OfferGroupDetailListView;
