import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {COLOR_SANTA_J, COLOR_SANTA_A_ALPHA, COLOR_SANTA_C, COLOR_FIX_SANTA_GRAY_4} from '@riiid/design-system';

import {Typography} from '@santa-web/service-ui';
import {Column} from '@app/components';

export interface OfferRemainedCounterProps extends React.ComponentPropsWithoutRef<'div'> {
  count: number;
}

const OfferRemainedCounter = React.forwardRef<HTMLDivElement, OfferRemainedCounterProps>(({count, ...props}, ref) => {
  const {t} = useTranslation();
  return (
    <Column gap={8} alignItems="flex-end">
      <Typography color={COLOR_FIX_SANTA_GRAY_4} variant="caption2">
        {t('offer_remained_stock_label')}
      </Typography>
      <div
        css={css`
          display: flex;
        `}
        ref={ref}
        {...props}>
        {count
          .toString(10)
          .split('')
          .map((digit, i) => (
            <div
              key={i}
              css={css`
                position: relative;
                width: 20px;
                height: 24px;
                background: ${COLOR_SANTA_C};
                border-radius: 2px;
                display: flex;
                justify-content: center;
                align-items: center;
                margin-right: 2px;
                &:last-of-type {
                  margin-right: 0;
                }
              `}>
              <div
                css={css`
                  position: absolute;
                  width: 100%;
                  height: 50%;
                  top: 0;
                  left: 0;
                  background: ${COLOR_SANTA_A_ALPHA(0.5)};
                `}
              />
              <Typography
                variant="caption1"
                color={COLOR_SANTA_J}
                component="span"
                fontWeight="bold"
                css={css`
                  z-index: 0;
                `}>
                {digit}
              </Typography>
            </div>
          ))}
      </div>
    </Column>
  );
});

export default React.memo(OfferRemainedCounter);
