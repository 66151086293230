import Link from 'next/link';
import {useRouter} from 'next/router';
import React from 'react';
import {css} from '@emotion/react';
import {BREAKPOINT_MEDIUM, COLOR_SANTA_D, COLOR_SANTA_O} from '@riiid/design-system';
import SettingIcon from '@riiid/design-system/icons/Setting';
import {IconButton, TopNavBar} from '@santa-web/service-ui';
import {Footer, FrameLayout, ScrollLayout} from '@app/components';
import {NARROW_MAX_WIDTH, WIDE_MAX_WIDTH} from '@app/constants/max-width';
import {use100vh} from '@app/hooks/use100vh';
import {SantaNavigationPath} from '@app/types/navigation';

type PreferencePageLayoutContainerProps = {
  upperArea: React.ReactNode;
  lowerArea?: React.ReactNode;
  bottomArea?: React.ReactNode;
  isNarrow?: boolean;
  shouldShowFooter?: boolean;
  shouldShowSettingButton?: boolean;
  onBackButtonClick?: () => void;
};

const PreferencePageLayoutContainer = ({
  upperArea,
  lowerArea,
  bottomArea,
  isNarrow = false,
  shouldShowFooter,
  shouldShowSettingButton,
  onBackButtonClick,
}: PreferencePageLayoutContainerProps) => {
  const router = useRouter();
  const viewportHeight = use100vh();
  return (
    <div
      css={css`
        height: ${viewportHeight};
      `}>
      <FrameLayout
        topNavBar={
          <TopNavBar
            paginationIcon="back"
            onPaginationIconClick={onBackButtonClick ?? router.back}
            rightArea={
              shouldShowSettingButton && (
                <Link href={SantaNavigationPath['/me/setting']}>
                  <IconButton
                    css={css`
                      display: flex;
                      align-items: center;
                    `}
                    Icon={SettingIcon}
                    IconProps={{color: COLOR_SANTA_O, width: 24, height: 24}}
                  />
                </Link>
              )
            }
          />
        }
        bottomArea={bottomArea}>
        <ScrollLayout>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              justify-content: space-between;

              height: 100%;
              width: 100%;
            `}>
            <div
              css={css`
                flex: 1;
                width: 100%;
                display: flex;
                flex-direction: column;
                align-items: center;
              `}>
              <div
                css={css`
                  width: 100%;
                  height: 100%;
                  max-width: ${isNarrow ? NARROW_MAX_WIDTH : WIDE_MAX_WIDTH};
                  padding: 40px 20px;
                  @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
                    padding: 40px;
                  }
                `}>
                {upperArea}
              </div>
              {lowerArea && (
                <>
                  <hr
                    css={css`
                      margin: 0;
                      width: 100%;
                      border: none;
                      border-top: solid 8px ${COLOR_SANTA_D};
                    `}
                  />
                  <div
                    css={css`
                      width: 100%;
                      max-width: ${isNarrow ? NARROW_MAX_WIDTH : WIDE_MAX_WIDTH};
                      padding: 40px 20px;
                      @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
                        padding: 40px;
                      }
                    `}>
                    {lowerArea}
                  </div>
                </>
              )}
            </div>
            {shouldShowFooter && <Footer />}
          </div>
        </ScrollLayout>
      </FrameLayout>
    </div>
  );
};

export default PreferencePageLayoutContainer;
export type {PreferencePageLayoutContainerProps};
PreferencePageLayoutContainer.displayName = 'PreferencePageLayoutContainer';
