/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { StripeConfigFromJSON, StripeConfigToJSON, } from './StripeConfig';
/**
 * Check if a given object implements the StartOrderPaymentRequest interface.
 */
export function instanceOfStartOrderPaymentRequest(value) {
    let isInstance = true;
    return isInstance;
}
export function StartOrderPaymentRequestFromJSON(json) {
    return StartOrderPaymentRequestFromJSONTyped(json, false);
}
export function StartOrderPaymentRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'stripeConfig': !exists(json, 'stripeConfig') ? undefined : StripeConfigFromJSON(json['stripeConfig']),
    };
}
export function StartOrderPaymentRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'stripeConfig': StripeConfigToJSON(value.stripeConfig),
    };
}
