import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {
  COLOR_SANTA_A,
  COLOR_SANTA_L,
  COLOR_SANTA_G,
  COLOR_SANTA_DD,
  SHADOW_A_2_DOWN,
  COLOR_SANTA_J,
} from '@riiid/design-system';
import {Button, Typography} from '@santa-web/service-ui';
import OfferRemainedCounter from '@app/features/offer/components/OfferRemainedCounter';

type OfferCardProps = {
  title: string;
  description: string;
  discount?: {
    rate: string;
    originalPrice: string;
  };
  totalPriceInfo: {
    price: string;
    periodInfo?: {
      value: number;
      unit: 'DAY' | 'WEEK' | 'MONTH' | 'YEAR';
    };
  };
  monthlyPriceInfo?: {
    price: string;
  };
  remainedCount?: number;
  errorLabel?: string;

  isAnonymous?: boolean;
  shouldShowMonthlyPriceOnMain?: boolean;
  isSlide?: boolean;
  isSubscription?: boolean;
  isRecommended?: boolean;
  hasOfferOptions?: boolean;
  hasShadow?: boolean;

  onPurchaseButtonClick: React.MouseEventHandler<HTMLButtonElement>;
  onOptionButtonClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const OfferCard = React.forwardRef<HTMLDivElement, OfferCardProps>(
  (
    {
      title,
      description,
      remainedCount,
      totalPriceInfo,
      monthlyPriceInfo,
      discount,
      errorLabel,
      isAnonymous = false,
      isSlide = false,
      isSubscription = false,
      isRecommended = false,
      hasOfferOptions = false,
      hasShadow = false,
      shouldShowMonthlyPriceOnMain = false,
      onPurchaseButtonClick,
      onOptionButtonClick,
      ...props
    },
    ref
  ) => {
    const {t} = useTranslation();
    const {mainPriceString, subPriceString} = React.useMemo(() => {
      const formatTotalPrice = ({price, periodInfo}: OfferCardProps['totalPriceInfo'], isSub = false) => {
        let unitString: string | undefined;
        if (periodInfo) {
          switch (periodInfo.unit) {
            case 'DAY':
              unitString = t('dict_n_days', {n: periodInfo.value});
              break;
            case 'WEEK':
              unitString = t('dict_n_weeks', {n: periodInfo.value});
              break;
            case 'MONTH':
              unitString = t('dict_n_months', {n: periodInfo.value});
              break;
            case 'YEAR':
              unitString = t('dict_n_years', {n: periodInfo.value});
          }
        }
        if (!unitString) {
          return price;
        }
        return isSub ? `${unitString} ${price}` : `${price}/${unitString}`;
      };
      const formatMonthlyPrice = ({price}: Exclude<OfferCardProps['monthlyPriceInfo'], undefined>, isSub = false) => {
        return isSub
          ? t('component_offer_card_monthly_sub_label', {price})
          : t('component_offer_card_monthly_main_label', {price});
      };
      if (!monthlyPriceInfo) {
        return {
          mainPriceString: formatTotalPrice(totalPriceInfo),
        };
      }
      if (shouldShowMonthlyPriceOnMain) {
        return {
          mainPriceString: formatMonthlyPrice(monthlyPriceInfo),
          subPriceString: formatTotalPrice(totalPriceInfo, true),
        };
      }
      return {
        mainPriceString: formatTotalPrice(totalPriceInfo),
        subPriceString: formatMonthlyPrice(monthlyPriceInfo, true),
      };
    }, [monthlyPriceInfo, shouldShowMonthlyPriceOnMain, t, totalPriceInfo]);

    return (
      <div
        css={[
          css`
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 32px;

            background-color: ${COLOR_SANTA_A};
            width: 100%;
            border-radius: 8px;
            padding: 20px;
          `,
          isSlide &&
            css`
              border-radius: 8px 8px 0px 0px;
              padding: 20px 20px 40px 20px;
            `,
          hasShadow &&
            css`
              box-shadow: ${SHADOW_A_2_DOWN};
            `,
        ]}
        ref={ref}
        {...props}>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            gap: 12px;
          `}>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              gap: 20px;
            `}>
            <div
              css={css`
                display: flex;
                flex-direction: column;
                flex: 1 0 0;
                gap: 4px;
              `}>
              {isRecommended && (
                <Typography
                  variant="caption3"
                  fontWeight="bold"
                  css={css`
                    display: inline-flex;
                    padding: 0px 8px;
                    justify-content: center;
                    align-items: center;
                    border-radius: 999px;
                    border: 1px solid ${COLOR_SANTA_L};
                    width: max-content;
                  `}>
                  {t('component_offer_card_recommended')}
                </Typography>
              )}
              <Typography variant="body1">{title}</Typography>
              <Typography
                variant="caption1"
                css={css`
                  color: ${COLOR_SANTA_G};
                  text-overflow: ellipsis;
                  overflow: hidden;
                  word-break: break-word;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                `}>
                {description}
              </Typography>
            </div>
            <div
              css={css`
                display: flex;
                flex-direction: column;
                align-items: flex-end;
                gap: 12px;
              `}>
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  align-items: flex-end;
                `}>
                <div
                  css={css`
                    display: flex;
                    gap: 4px;
                    height: 20px;
                  `}>
                  {discount && (
                    <>
                      <Typography variant="caption1" fontWeight="bold" color={COLOR_SANTA_DD}>
                        {discount.rate}
                      </Typography>
                      <Typography
                        css={css`
                          text-decoration: line-through;
                        `}
                        variant="caption1"
                        color={COLOR_SANTA_G}>
                        {discount.originalPrice}
                      </Typography>
                    </>
                  )}
                </div>
                <div
                  css={css`
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                  `}>
                  <Typography variant="body2" fontWeight="bold">
                    {mainPriceString}
                  </Typography>
                  {subPriceString && (
                    <Typography component="p" variant="caption1" color={COLOR_SANTA_J}>
                      {subPriceString}
                    </Typography>
                  )}
                </div>
              </div>
              {remainedCount != null && <OfferRemainedCounter count={remainedCount} />}
            </div>
          </div>
        </div>

        <Button
          colorVariant="brand"
          isDisabled={!!errorLabel}
          onClick={hasOfferOptions ? onOptionButtonClick : onPurchaseButtonClick}>
          {errorLabel
            ? errorLabel
            : hasOfferOptions
            ? t('component_offer_card_display_option_button')
            : isAnonymous
            ? t('component_offer_card_anonymous_user_button')
            : isSubscription
            ? t('component_offer_card_subscribe_button')
            : t('component_offer_card_one_time_purchase_button')}
        </Button>
      </div>
    );
  }
);

export default OfferCard;
export type {OfferCardProps};
OfferCard.displayName = 'OfferCard';
