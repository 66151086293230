import React from 'react';
import {Trans} from 'react-i18next';
import {css} from '@emotion/react';
import {COLOR_SANTA_A, COLOR_SANTA_DD, COLOR_SANTA_L, SHADOW_A_2_DOWN} from '@riiid/design-system';
import TimerIcon from '@riiid/design-system/icons/Time';
import {Typography} from '@santa-web/service-ui';
import {formatOfferGroupRemainedTime} from '@app/features/offer/utils';

type OfferGroupDetailTimerOwnProps = {remainedTimeMs: number; color?: 'BLACK' | 'WHITE' | 'RED'};

type OfferGroupDetailTimerProps = OfferGroupDetailTimerOwnProps & React.HTMLAttributes<HTMLDivElement>;

/**
 * 시간이 줄어드는 처리는 본 컴포넌트 바깥에서 하고, 본 컴포넌트는 주어진 date의 차이를 그리는 역할만 합니다.
 */
const OfferGroupDetailTimer = ({remainedTimeMs, color: _color = 'WHITE', ...rest}: OfferGroupDetailTimerProps) => {
  const {backgroundColor, color} = React.useMemo(() => {
    switch (_color) {
      case 'BLACK':
        return {
          backgroundColor: COLOR_SANTA_L,
          color: COLOR_SANTA_A,
        };
      case 'RED':
        return {
          backgroundColor: COLOR_SANTA_DD,
          color: COLOR_SANTA_A,
        };
      case 'WHITE':
      default:
        return {
          backgroundColor: COLOR_SANTA_A,
          color: COLOR_SANTA_L,
        };
    }
  }, [_color]);

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        gap: 4px;

        padding: 6px 12px 6px 8px;
        border-radius: 9999px;
        background-color: ${backgroundColor};
        box-shadow: ${SHADOW_A_2_DOWN};
      `}
      {...rest}>
      <TimerIcon color={color} width={20} height={20} type="line" />
      <Typography component="p" variant="caption1" color={color}>
        <Trans
          i18nKey="component_offer_group_detail_timer_label"
          values={{time: formatOfferGroupRemainedTime(remainedTimeMs)}}
          components={{
            time: (
              <span
                css={css`
                  margin-right: 4px;
                `}
              />
            ),
          }}></Trans>
      </Typography>
    </div>
  );
};

export default OfferGroupDetailTimer;
export type {OfferGroupDetailTimerProps};
OfferGroupDetailTimer.displayName = 'OfferGroupDetailTimer';
