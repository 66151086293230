import {useDialogContext} from '@app/contexts/DialogContext';
import {PassActivationBottomSheet} from '@app/features/permit';

export const usePassActivationBottomSheet = () => {
  const {openDialog, closeDialog} = useDialogContext();
  const openPassActivationBottomSheet = (
    passTitle: string,
    learningDomain: string,
    onStartClick: () => Promise<void>
  ) => {
    openDialog(
      <PassActivationBottomSheet
        learningDomain={learningDomain}
        passTitle={passTitle}
        onStartClick={async () => {
          await onStartClick();
          closeDialog();
        }}
        onCloseClick={closeDialog}
      />
    );
  };
  return {openPassActivationBottomSheet};
};
