import React from 'react';

import {Lesson} from '@santa-web/gen/open-api/content/models/Lesson';

import LessonLayout from './layout';

interface Props {
  lesson: Lesson;
  youtubeTimestamp?: number;
  onVideoPlay(): void;
  onSpeedChange(speed: number): void;
  onTimestampChange?: (timestamp: number) => void;
  onSubscribePlaybackRateSelect(listener: (playbackRate: number) => void): () => void;
}
const LessonView = ({
  lesson,
  youtubeTimestamp,
  onVideoPlay,
  onSpeedChange,
  onTimestampChange,
  onSubscribePlaybackRateSelect,
}: Props): JSX.Element => {
  return (
    <LessonLayout
      lesson={lesson}
      youtubeTimestamp={youtubeTimestamp ?? 0}
      onVideoPlay={onVideoPlay}
      onPlaybackRateChange={onSpeedChange}
      onTimestampChange={onTimestampChange}
      subscribePlaybackRateSelect={onSubscribePlaybackRateSelect}
    />
  );
};

export default React.memo(LessonView);
