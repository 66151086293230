import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/mousewheel';

import React from 'react';
import {css} from '@emotion/react';
import {
  COLOR_SANTA_BC,
  COLOR_SANTA_L,
  COLOR_SANTA_M,
  COLOR_SANTA_O_ALPHA,
  LINE_HEIGHT_CAPTION1,
} from '@riiid/design-system';
// NOTE: swiper 패키지의 모듈 형태로 인해 타입 추론이 typescript 4.7 이상에서만 정상 동작함. swiper 패키지를 바깥에서 직접 사용하지는 않을 듯해 이대로 사용하기로 결정하였음.
// see: https://www.typescriptlang.org/docs/handbook/release-notes/typescript-4-7.html#packagejson-exports-imports-and-self-referencing
import {Swiper as SwiperClass} from 'swiper';
import {FreeMode, Mousewheel} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Typography} from '@santa-web/service-ui';

type HorizontalPickerItemProps = {
  value: number;
  label?: string;
};
type HorizontalPickerOwnProps = {
  defaultValue: number;
  items: Array<HorizontalPickerItemProps>;
  onPick: (value: number) => void;
};

type HorizontalPickerProps = HorizontalPickerOwnProps & React.HTMLAttributes<HTMLDivElement>;

const CIRCLE_SIZE = 48;
const ITEM_WIDTH = 40;

const HorizontalPicker = ({defaultValue, items, onPick, ...rest}: HorizontalPickerProps) => {
  const initialSlideIdx = React.useMemo(() => {
    const idx = items.findIndex(item => item.value === defaultValue);
    return idx < 0 ? 0 : idx;
  }, [defaultValue, items]);
  const circleRef = React.useRef<HTMLDivElement>(null);

  const hasMoreThanOneLabel = React.useMemo(() => items.some(({label}) => label), [items]);
  const [focusedItemIdx, setFocusedItemIdx] = React.useState<number>(initialSlideIdx);
  const focusedLabel = React.useMemo(() => items[focusedItemIdx]?.label, [focusedItemIdx, items]);

  const handleSlideChange = React.useCallback((swiper: SwiperClass) => {
    setFocusedItemIdx(swiper.realIndex);
  }, []);

  React.useEffect(() => {
    onPick(items[focusedItemIdx].value);
  }, [focusedItemIdx, items, onPick]);

  return (
    <div {...rest}>
      <div
        css={css`
          width: 100%;
          height: ${CIRCLE_SIZE}px;
          position: relative;
          display: flex;
          justify-content: center;
          align-items: center;

          .swiper-slide {
            width: ${ITEM_WIDTH}px;
          }

          .swiper-slide-active p {
            font-weight: bold;
            color: ${COLOR_SANTA_L};
          }
        `}>
        <div
          ref={circleRef}
          css={css`
            position: absolute;
            left: 50%;
            transform: translateX(-50%);
            width: ${CIRCLE_SIZE}px;
            height: ${CIRCLE_SIZE}px;
            background-color: ${COLOR_SANTA_BC};
            border-radius: 9999px;
          `}
        />
        <Swiper
          slidesPerView="auto"
          centeredSlides
          spaceBetween={12}
          initialSlide={initialSlideIdx}
          modules={[FreeMode, Mousewheel]}
          freeMode={{enabled: true, sticky: true}}
          mousewheel={{enabled: true}}
          onSlideChange={handleSlideChange}>
          {items.map(({value}) => (
            <SwiperSlide key={value}>
              <Typography
                css={css`
                  user-select: none;
                  text-align: center;
                  width: ${ITEM_WIDTH}px;
                `}
                color={COLOR_SANTA_O_ALPHA(0.3)}
                variant="body3">
                {value}
              </Typography>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div
        css={css`
          display: flex;
          justify-content: center;
          margin-top: 4px;
        `}>
        <Typography
          css={[
            hasMoreThanOneLabel &&
              css`
                min-height: ${LINE_HEIGHT_CAPTION1};
              `,
          ]}
          variant="caption1"
          component="p"
          color={COLOR_SANTA_M}>
          {focusedLabel}
        </Typography>
      </div>
    </div>
  );
};

export default HorizontalPicker;
export type {HorizontalPickerProps};
HorizontalPicker.displayName = 'HorizontalPicker';
