import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';

import {AlertProps, AlertDialog, AlertDialogProps} from '@santa-web/service-ui';
import {ALERT_DIALOG_Z_INDEX} from '@app/constants/zIndex';
import {useDialogContext} from '@app/contexts/DialogContext';

export interface VirtualExamExitAlertDialogProps extends AlertDialogProps {
  onPositiveClick?(): void | Promise<void>;
}

export default React.memo<VirtualExamExitAlertDialogProps>(
  React.forwardRef<HTMLDivElement, VirtualExamExitAlertDialogProps>(function VirtualExamExitAlertDialog(
    {onPositiveClick, ...props},
    ref
  ) {
    const {t} = useTranslation();
    const {closeDialog} = useDialogContext();
    const AlertProps = useMemo(
      (): AlertProps => ({
        title: t('virtual_exam_alert_exit_title'),
        description: t('virtual_exam_alert_exit_description'),
        negativeLabel: t('virtual_exam_alert_exit_negative_label'),
        positiveLabel: t('virtual_exam_alert_exit_positive_label'),
        onNegativeClick: closeDialog,
        onPositiveClick: () => {
          onPositiveClick?.();
          closeDialog();
        },
        css: css`
          white-space: pre-line;
        `,
      }),
      [closeDialog, onPositiveClick, t]
    );
    return (
      <AlertDialog
        ref={ref}
        css={css`
          z-index: ${ALERT_DIALOG_Z_INDEX};
        `}
        AlertProps={AlertProps}
        isVisible
        {...props}
      />
    );
  })
);
