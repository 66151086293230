import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';

import {AlertProps, AlertDialog, AlertDialogProps} from '@santa-web/service-ui';

type ChatTutorialAlertDialogProps = AlertDialogProps & {
  onNegativeClick?: () => void;
  onNeutralClick?: () => void;
};

const ChatExitAlertDialog = React.forwardRef<HTMLDivElement, ChatTutorialAlertDialogProps>(
  ({onNegativeClick, onNeutralClick, ...props}, ref) => {
    const {t} = useTranslation();
    const AlertProps = useMemo(
      (): AlertProps => ({
        title: t('chat_exit_alert_title'),
        description: t('chat_exit_alert_description'),
        negativeLabel: t('chat_exit_alert_cancel_label'),
        neutralLabel: t('chat_exit_alert_confirm_label'),
        onNegativeClick,
        onNeutralClick,
        css: css`
          white-space: pre-line;
        `,
      }),
      [onNegativeClick, onNeutralClick, t]
    );

    return <AlertDialog ref={ref} AlertProps={AlertProps} isVisible {...props} />;
  }
);

export default React.memo(ChatExitAlertDialog);
export type {ChatTutorialAlertDialogProps};
