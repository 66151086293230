/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {useTranslation} from 'react-i18next';
import Decimal from 'decimal.js';
import {createPriceFormatter} from '@app/facade/price/price-formatter';
import {OfferGroupOrderPageCommonViewProps} from '@app/features/offer/views/types';
import {useOpenNewWindow} from '@app/hooks/useOpenNewWindow';
import {useTypedRouter} from '@app/hooks/useTypedRouter';
// eslint-disable-next-line no-restricted-imports
import {getFormattedRate} from '@app/pages/toeic-speaking/utils';
import {useOfferGroupOrderQuery} from '.';

export const useOfferGroupOrderPageCommonViewProps = (id: number): OfferGroupOrderPageCommonViewProps | null => {
  const {t} = useTranslation();
  const router = useTypedRouter();
  const openNewWindow = useOpenNewWindow();

  const {data: order} = useOfferGroupOrderQuery(id);

  if (!order) {
    return null;
  }

  const offerGroup = order.offerGroup;
  const offer = order.offerGroup.offer;
  const priceFormatter = createPriceFormatter(order.amountData.currency);

  const displayInfo: OfferGroupOrderPageCommonViewProps['displayInfo'] = {
    subTitle: offerGroup.displayConfig.name,
    title: offer.displayConfig.name,
    description: offer.displayConfig.description,
    displayImageProps: {
      backgroundImageSet: offerGroup.displayConfig.thumbnailBgImageSet,
      contentImageSet: offerGroup.displayConfig.thumbnailContentImageSet,
      contentImageSetAlign: offerGroup.displayConfig.thumbnailContentImageSetAlign,
    },
  };

  const billSheetProps: OfferGroupOrderPageCommonViewProps['billSheetProps'] = {
    goodsInfo: {
      passes: offer.permitConfig.passSettings?.map(pass => ({
        displayName: pass.name,
        price: priceFormatter.getFormattedPrice(new Decimal(pass.price)),
        description: pass.description,
        duration: offer.additionalProperties.subscription?.subscriptionConfig.renewalTerm ?? {
          term: offer.additionalProperties.singlePurchase?.passExpirationInDays || 0,
          unit: 'DAY',
        },
      })),
      tickets: offer.permitConfig.ticketSettings?.map(ticket => ({
        displayName: ticket.name,
        price: priceFormatter.getFormattedPrice(new Decimal(ticket.price)),
        description: ticket.description,
        count: ticket.quantity,
      })),
    },
    billingInfo: {
      baseAmount: priceFormatter.getFormattedPrice(new Decimal(order.amountData.offerDefaultAmount)),
      discounts: (() => {
        const result = [];
        if (order.amountData.offerDiscountAmount) {
          result.push({
            displayName: t('offer_group_order_coupon_discount_label', {
              amount: `${getFormattedRate(
                new Decimal(order.amountData.offerDiscountAmount),
                new Decimal(order.amountData.offerDefaultAmount)
              )}`,
            }),
            amount: priceFormatter.getFormattedPrice(new Decimal(order.amountData.offerDiscountAmount)),
          });
        }
        if (order.amountData.couponDiscounts && order.amountData.couponDiscounts.length > 0) {
          result.push(
            ...order.amountData.couponDiscounts.map(({discountAmount}) => ({
              displayName: t('dict_coupon'),
              amount: priceFormatter.getFormattedPrice(new Decimal(discountAmount)),
            }))
          );
        }
        return result.length === 0 ? undefined : result;
      })(),
      totalAmount: priceFormatter.getFormattedPrice(new Decimal(order.amountData.totalChargeAmount)),
    },
  };

  const handleCautionClick = () => {
    openNewWindow(offer.noticeLink);
  };

  return {
    displayInfo,
    billSheetProps,
    onCautionClick: handleCautionClick,
    onGoBack: router.back,
  };
};
