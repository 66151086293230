import React from 'react';
import {TFunction, useTranslation} from 'react-i18next';
import {COLOR_SANTA_DC, COLOR_SANTA_I} from '@riiid/design-system';
import Decimal from 'decimal.js';

import {OneOfContentSummary, QuestionState} from '@santa-web/gen/open-api/content-learning-service';
import {Typography} from '@santa-web/service-ui';
import MyNoteCard, {MyNoteCardProps} from '@app/components/card/MyNoteCard';
import AnswerStateList from '@app/pages/learning-cell/report/components/AnswerStateList';

export interface Duration {
  minutes: number;
  seconds: number;
}
export function msToDuration(elapsedTimeMs: Decimal): Duration {
  const minutes = elapsedTimeMs
    .div(1000 * 60)
    .floor()
    .toNumber();
  const seconds = elapsedTimeMs
    .mod(1000 * 60)
    .div(1000)
    .floor()
    .toNumber();
  return {
    minutes,
    seconds,
  };
}
export function formatElapsedTime(elapsedTimeMs: Decimal, t: TFunction<'translation'>): string {
  const {minutes, seconds} = msToDuration(elapsedTimeMs);
  if (minutes > 0) {
    return t('card_elapsed_time_minutes_seconds', {
      minutes,
      seconds,
    });
  }
  return t('card_elapsed_time_seconds', {
    seconds: seconds.toString(),
  });
}

export interface ContentCardQuestionProps extends MyNoteCardProps {
  questionSetStates?: QuestionState[];
  questionIndex?: number;
  timeHidden?: boolean;
  contentSummary: OneOfContentSummary;
  elapsedTime: Decimal;
  recommendedElapsedTime: number;
}

const ContentCardQuestion = React.forwardRef<HTMLDivElement, ContentCardQuestionProps>(
  (
    {
      isBookmarked,
      onBookmarkClick,
      questionSetStates,
      questionIndex,
      timeHidden,
      contentSummary,
      elapsedTime,
      recommendedElapsedTime,
      ...props
    },
    ref
  ) => {
    const {t} = useTranslation();
    const answerStates = React.useMemo(
      () => (questionSetStates ?? []).map(state => state.answerState),
      [questionSetStates]
    );

    const elapsedTimeMs = elapsedTime;
    const time = recommendedElapsedTime && elapsedTimeMs.gte(0) && !timeHidden ? formatElapsedTime(elapsedTime, t) : '';
    const isOverRecommendTime = elapsedTimeMs.gt(recommendedElapsedTime);
    const content = (() => {
      if (contentSummary.oneOfCase === 'TEXT') {
        return (
          <Typography color={COLOR_SANTA_I} variant="caption1" display="block">
            {contentSummary.text}
          </Typography>
        );
      }
    })();

    return (
      <MyNoteCard
        ref={ref}
        title={t('learning_cell_report_question_index', {index: questionIndex})}
        status={<AnswerStateList answerStates={answerStates} />}
        time={time}
        timeProps={
          isOverRecommendTime
            ? {
                color: COLOR_SANTA_DC,
              }
            : undefined
        }
        content={content}
        imageUrl={contentSummary.oneOfCase === 'IMAGE_URL' ? contentSummary.imageUrl : undefined}
        isBookmarked={isBookmarked}
        onBookmarkClick={onBookmarkClick}
        {...props}
      />
    );
  }
);

export default ContentCardQuestion;
