import React from 'react';
import {css} from '@emotion/react';
import {COLOR_SANTA_CARD_A, COLOR_SANTA_CARD_C} from '@riiid/design-system';

export type CardProps = React.ComponentPropsWithoutRef<'div'>;

const BaseCard = React.forwardRef<HTMLDivElement, CardProps>(({children, ...props}, ref) => {
  const onClick = props.onClick;
  const additionalProps: Partial<Pick<React.HTMLAttributes<HTMLDivElement>, 'tabIndex' | 'role'>> = onClick
    ? {tabIndex: 0, role: 'button'}
    : {};
  return (
    <div
      ref={ref}
      css={[
        css`
          background-color: ${COLOR_SANTA_CARD_A};
          border-radius: 4px;
          padding: 20px;
        `,
        onClick &&
          css`
            &:active {
              background-color: ${COLOR_SANTA_CARD_C};
            }
          `,
      ]}
      {...additionalProps}
      {...props}>
      {children}
    </div>
  );
});

export default React.memo(BaseCard);
