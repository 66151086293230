import {useMemo} from 'react';

import {usePushEvent} from '.';

export interface ExplanationsObservedPayload extends Record<string, unknown> {
  part?: string;
  content_id?: string;
  question?: number;
  options?: number;
  toeic_tip?: number;
  lesson?: number;
  rule_verb?: number;
  qs?: number;
}

export interface DiagnosisReportObservedPayload extends Record<string, unknown> {
  pred_score?: number;
  relative_score?: number;
  pentagon?: number;
  weak_chapter?: number;
  DALC?: number;
}

const useComponentElapsedTimeEvents = () => {
  const pushEvent = usePushEvent();

  const useComponentElapsedTimeEvents = useMemo(
    () => ({
      pushExplanationsElapsedTime: async (payload: ExplanationsObservedPayload) => {
        await pushEvent('all_rc_sol_scroll', payload);
      },

      pushDiagnosisReportElapsedTimeEvent: async (payload: DiagnosisReportObservedPayload) => {
        await pushEvent('rec_res_scroll', payload);
      },
    }),
    [pushEvent]
  );

  return useComponentElapsedTimeEvents;
};

export default useComponentElapsedTimeEvents;
