import {ParsedUrlQuery} from 'querystring';

import {mobileService} from '@app/api/app-bridge/mobile-service';
import config from '@app/config';
import {AppleSignInAPI, Kakao, KakaoUser} from '@app/features/auth/types';
import {isAppBridgeAvailable} from '@app/utils/app-bridge';
import {loadScript} from '@app/utils/script';

// Google Login is performed by hook in @app/hooks/social-login/useGoogleSignIn;

export interface WindowWithProps extends Window {
  Kakao?: Kakao;
  AppleID?: AppleSignInAPI.AppleID;
}

const windowWithProps: WindowWithProps | undefined = typeof window !== 'undefined' ? window : undefined;

// Kakao Authorization
const isKakaoAvailable = () => windowWithProps?.Kakao != null;
const ensureKakao = async () => {
  if (!isKakaoAvailable()) {
    await loadScript(config.kakaoUrl);
    const Kakao = windowWithProps?.Kakao;
    Kakao?.init(config.kakaoKey);
  }
};
export const authorizeKakao = async () => {
  if (isAppBridgeAvailable()) {
    const {
      authData: {kakaoAccessToken},
      email,
      name,
    } = await mobileService.requestSocialLogin('KAKAO');
    return {kakaoAccessToken, email, name};
  }
  await ensureKakao();
  const accessTokenPromise = new Promise<string>((resolve, reject) => {
    windowWithProps?.Kakao?.Auth.login({
      success: ({access_token: accessToken}) => {
        resolve(accessToken);
      },
      fail: ({error_description}) => {
        reject(`Kakao Authorization: ${error_description}`);
      },
      persistAccessToken: false,
      throughTalk: true,
    });
  });

  const token = await accessTokenPromise;
  return {kakaoAccessToken: token};
};

export const getKakaoUser = async () => {
  await ensureKakao();
  return new Promise<KakaoUser>((resolve, reject) => {
    windowWithProps?.Kakao?.API.request({
      url: '/v2/user/me',
      success: resolve,
      fail: reject,
    });
  });
};

// Apple Authorization
const isAppleIDAvailable = () => windowWithProps?.AppleID != null;
const ensureAppleID = async () => {
  if (!isAppleIDAvailable()) {
    await loadScript(config.appleUrl);
    await windowWithProps?.AppleID?.auth.init({
      clientId: config.appleAppId,
      redirectURI: 'REDIRECT_URI',
      usePopup: true,
    });
  }
};
export const authorizeApple = async (
  redirectUri: string,
  query: ParsedUrlQuery
): Promise<{authorizationCode: string; identityToken: string; email?: string; name?: string}> => {
  if (isAppBridgeAvailable()) {
    const {authData, email, name} = await mobileService.requestSocialLogin('APPLE');
    return {...authData, email, name};
  }
  await ensureAppleID();
  const response = await windowWithProps?.AppleID?.auth.signIn({
    redirectURI: redirectUri,
    state: JSON.stringify(query),
  });
  if (response == null) throw new Error('Error in Apple Login');
  const {
    authorization: {code: authorizationCode, id_token: identityToken},
  } = response;
  return {authorizationCode, identityToken};
};

export const getLineIDToken = async (code: string, redirectUri: string) => {
  const data = new URLSearchParams({
    grant_type: 'authorization_code',
    redirect_uri: redirectUri,
    client_id: config.lineClientId,
    client_secret: config.lineClientSecret,
    code,
  });

  try {
    const response = await fetch(config.lineTokenUrl, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
      },
      body: data,
    });

    if (!response.ok) {
      throw new Error('Network response was not ok');
    }

    const responseData = await response.json();
    return responseData.id_token;
  } catch {
    return null;
  }
};
