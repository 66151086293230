import React from 'react';

import {SingleQuestionTitle} from '@santa-web/service-ui';
import DirectionContent from '@app/components/virtual-exam/VirtualExamDirectionView/components/DirectionContent';
import DirectionWrapper from '@app/components/virtual-exam/VirtualExamDirectionView/components/DirectionWrapper';

const Part2DirectionView = React.forwardRef<HTMLDivElement, React.ComponentPropsWithoutRef<'div'>>((props, ref) => {
  return (
    <DirectionWrapper gap={24} {...props} ref={ref}>
      <SingleQuestionTitle content={'PART 2'} />
      <DirectionContent>
        <b>Directions:</b> You will hear a question or statement and three responses spoken in English. They will not be
        printed in your test book and will be spoken only one time. Select the best response to the question or
        statement and mark the letter (A), (B), or (C) on your answer sheet.
      </DirectionContent>
    </DirectionWrapper>
  );
});

export default Part2DirectionView;
