import { ExamScoreFromJSON, ExamScoreToJSON, } from './ExamScore';
/**
 * Check if a given object implements the UpdateExamScoreResponse interface.
 */
export function instanceOfUpdateExamScoreResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "updated" in value;
    return isInstance;
}
export function UpdateExamScoreResponseFromJSON(json) {
    return UpdateExamScoreResponseFromJSONTyped(json, false);
}
export function UpdateExamScoreResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'updated': ExamScoreFromJSON(json['updated']),
    };
}
export function UpdateExamScoreResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'updated': ExamScoreToJSON(value.updated),
    };
}
