import React from 'react';

import {useFloatingChatButtonList} from '@santa-web/service-ui';
import useFloatingPaginationButtons from '@app/pages/content-interaction-states/FloatingPaginationButtons/useFloatingPaginationButtons';

const STYLE = {
  FLOATING_CHAT_BUTTON_HEIGHT: 48,
  VOCA_FLOATING_BUTTON_HEIGHT: 48,
  PAGINATION_BUTTON_HEIGHT: 40,
  MARGIN_BETWEEN_FLOATING_CHAT_BUTTONS: 12,
  FLOATING_AREA_PADDING: 40,
};

export interface UseFloatingAreaHeightProps {
  numberOfVocaFloatingButton?: number;
}

const useFloatingAreaHeight = (props?: UseFloatingAreaHeightProps) => {
  const {numberOfFloatingChatButtons} = useFloatingChatButtonList();

  const numberOfPaginationButton = useFloatingPaginationButtons().floatingPaginationButtons ? 1 : 0;
  const numberOfMarginBetweenFloatingChatButtons = numberOfFloatingChatButtons ? numberOfFloatingChatButtons - 1 : 0;

  const floatingAreaHeight = React.useMemo(() => {
    const {
      FLOATING_CHAT_BUTTON_HEIGHT,
      PAGINATION_BUTTON_HEIGHT,
      MARGIN_BETWEEN_FLOATING_CHAT_BUTTONS,
      FLOATING_AREA_PADDING,
      VOCA_FLOATING_BUTTON_HEIGHT,
    } = STYLE;

    return (
      numberOfFloatingChatButtons * FLOATING_CHAT_BUTTON_HEIGHT +
      numberOfMarginBetweenFloatingChatButtons * MARGIN_BETWEEN_FLOATING_CHAT_BUTTONS +
      numberOfPaginationButton * PAGINATION_BUTTON_HEIGHT +
      (props?.numberOfVocaFloatingButton ?? 0) * VOCA_FLOATING_BUTTON_HEIGHT +
      FLOATING_AREA_PADDING
    );
  }, [
    numberOfFloatingChatButtons,
    numberOfMarginBetweenFloatingChatButtons,
    numberOfPaginationButton,
    props?.numberOfVocaFloatingButton,
  ]);

  return floatingAreaHeight;
};

export default useFloatingAreaHeight;
