import {atom} from 'jotai';
import {setAtomLabels} from '@app/misc/atom-label';
import {cisService} from '@app/test-preparation/atoms/services';
import {contentRouterFactory} from '@app/test-preparation/atoms/stores/learning/utils/content-router';

const totalSummaryCountAtom = atom(get => get(cisService.summaryAtom).count);

const currentCisIndexAtom = atom(get => get(cisService.currentCisIndexAtom));

const totalElapsedTimeAtom = atom(get =>
  Object.values(get(cisService.elapsedTimeMapAtom)).reduce(
    (totalElapsedTime, elapsedTime) => totalElapsedTime + elapsedTime,
    0
  )
);

const totalCisCountAtom = atom(get => get(cisService.cisesAtom).length);
const questionCisCountAtom = atom(
  get =>
    get(cisService.cisesAtom).filter(cis =>
      cis.elementInteractionStates.every(eis => eis.details.oneOfCase === 'QUESTION')
    ).length
);
const lessonCisCountAtom = atom(
  get =>
    get(cisService.cisesAtom).filter(cis =>
      cis.elementInteractionStates.every(eis => eis.details.oneOfCase === 'LESSON')
    ).length
);
const vocabularyCisCountAtom = atom(
  get =>
    get(cisService.cisesAtom).filter(cis => cis.elementInteractionStates.every(eis => eis.details.oneOfCase === 'VOCA'))
      .length
);

const completedCisesAtom = atom(get => get(cisService.cisesAtom).filter(cis => cis.completedAtServer instanceof Date));
const completedCisCountAtom = atom(get => get(completedCisesAtom).length);
const completedQuestionCountAtom = atom(get =>
  get(completedCisesAtom).reduce(
    (sum, cis) => sum + cis.elementInteractionStates.reduce((sum, eis) => sum + (eis.details.question ? 1 : 0), 0),
    0
  )
);

const continuousCorrectCisCountsAtom = atom(get =>
  get(completedCisesAtom).reduce<Array<number>>(
    (counts, cis, index) => [
      ...counts,
      cis.elementInteractionStates.every(eis => eis.details.question?.answerState === 'CORRECT')
        ? (counts[index - 1] ?? 0) + 1
        : 0,
    ],
    []
  )
);
const continuousEarlyCompletedCisCountsAtom = atom(get => {
  const contentDataMap = get(cisService.contentDataMapAtom);
  const startCisIndex = get(cisService.startCisIndexAtom);

  const completedCises = get(completedCisesAtom);

  const interactedCises = completedCises.slice(startCisIndex);

  return interactedCises.reduce<Array<number>>((counts, cis, index) => {
    const contentData = contentDataMap[startCisIndex + index];

    if (contentData?.content === undefined) {
      throw new Error('Content not found');
    }

    return [
      ...counts,
      cis.elementInteractionStates.reduce((sum, {elapsedTimeMs}) => sum + elapsedTimeMs, 0) <=
      contentRouterFactory.create(contentData.content).getGuessElapsedTime()
        ? (counts[index - 1] ?? 0) + 1
        : 0,
    ];
  }, []);
});

const isLastCisAtom = atom(get => {
  const totalCisCount = get(totalCisCountAtom);
  const currentCisIndex = get(currentCisIndexAtom);

  return totalCisCount === currentCisIndex + 1;
});

export const cises = {
  totalSummaryCountAtom,

  currentCisIndexAtom,

  totalElapsedTimeAtom,

  totalCisCountAtom,
  questionCisCountAtom,
  vocabularyCisCountAtom,
  lessonCisCountAtom,

  completedCisCountAtom,
  completedQuestionCountAtom,

  continuousCorrectCisCountsAtom,
  continuousEarlyCompletedCisCountsAtom,

  isLastCisAtom,
};

setAtomLabels(
  {
    totalSummaryCountAtom,
    currentCisIndexAtom,
    totalElapsedTimeAtom,
    totalCisCountAtom,
    questionCisCountAtom,
    vocabularyCisCountAtom,
    lessonCisCountAtom,
    completedCisesAtom,
    completedCisCountAtom,
    completedQuestionCountAtom,
    continuousCorrectCisCountsAtom,
    continuousEarlyCompletedCisCountsAtom,
    isLastCisAtom,
  },
  'learningStore.cises.'
);
