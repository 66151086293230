import {useMemo} from 'react';

import {
  LearningCellConfigOneOfCase,
  LearningCellProvidedContext,
} from '@santa-web/gen/open-api/content-learning-service';
import {usePushEvent} from '@app/api/google-tag-manager';

export interface PushAllVocaFloatingButtonClickPayload extends Record<string, unknown> {
  text_type: string;
}

export interface PushAllVocaNextWordClick extends Record<string, unknown> {
  seehidefreq: number;
  know: boolean;
}

interface SpeedClickEventPayload extends Record<string, unknown> {
  cis_id: number;
}

interface SpeedChoiceEventPayload extends Record<string, unknown> {
  speed_type: number;
  cis_id: number;
}

interface ToolChoiceEventPayload extends Record<string, unknown> {
  tool_type: number;
}

interface PushAllQuestionElementConsumedClickPayload extends Record<string, unknown> {
  from: TestPrepFrom;
  part?: number;
  cis_id?: number;
}

interface PushQuestionContentMarkEventPayload extends Record<string, unknown> {
  mark_type: 'underline';
  from: TestPrepFrom;
  cis_id: number;
  content_id: string;
  sentence: string;
  // "(X,Y)" 형태의 string
  delta_coord: string;
}

interface PushQuestionContentMarkEraseClickPayload extends Record<string, unknown> {
  cis_id: number;
  sentence: string;
}

interface PushQuestionContentMarkIconClick extends Record<string, unknown> {
  mark_icon_type: 'underline' | 'erase';
  turnOnOff: 0 | 1;
  cis_id: number;
}

interface PushAllRcAiChatIconClick extends Record<string, unknown> {
  cis_id: number;
  content_id: string;
}

interface PushAllAiChatSendQuestionClick extends Record<string, unknown> {
  cis_id: number;
  content_id: string;
}

interface PushAbTestGptRecommendedQuestionClick extends Record<string, unknown> {
  qs_string: string;
  cis_id: number;
}

interface PushAllContentFontSizeChoice extends Record<string, unknown> {
  cis_id: number;
  content_id: string;
  font_size: number;
}

type TestPrepFrom =
  | 'lvltest'
  | 'rec_question'
  | 'rec_basic'
  | 'rec_review'
  | 'rec_lesson_qs'
  | 'rec_voca_qs'
  | 'sel_question'
  | 'sel_basic'
  | 'my_note_quizzes'
  | 'unknown';

export const getTestPrepFrom = (
  cellType: LearningCellProvidedContext | null,
  cellContentType: LearningCellConfigOneOfCase | null,
  isDiagnostics: boolean,
  isBasicQuestion: boolean
): TestPrepFrom => {
  const cycleMap: Record<LearningCellConfigOneOfCase, TestPrepFrom> = {
    QUESTION_SET: 'rec_question',
    QUESTION_STREAM: 'rec_question',
    LESSON: 'rec_lesson_qs',
    VOCA: 'rec_voca_qs',
  };

  switch (cellType) {
    case 'MY_NOTE':
      return 'my_note_quizzes';

    case 'DIAGNOSIS':
      return 'lvltest';

    case 'SELF_LEARNING':
      if (isBasicQuestion) return 'sel_basic';
      else if (cellContentType === 'QUESTION_STREAM' || cellContentType === 'QUESTION_SET') return 'sel_question';
      break;

    case 'COURSE_LEARNING':
      if (isBasicQuestion) return 'rec_basic';
      else if (isDiagnostics) return 'rec_review';
      else if (cellContentType && Object.keys(cycleMap).includes(cellContentType)) {
        return cycleMap[cellContentType];
      }
  }

  return 'unknown';
};

const useTestprepEvents = () => {
  const pushEvent = usePushEvent();

  const testprepEvents = useMemo(
    () => ({
      pushLcSpeedClick: async (payload: SpeedClickEventPayload) => {
        await pushEvent('all_lc_speed_clk', payload);
      },
      pushLcSpeedChoice: async (payload: SpeedChoiceEventPayload) => {
        await pushEvent('all_lc_speed_choice', payload);
      },
      pushLectureSpeedClick: async (payload: SpeedClickEventPayload) => {
        await pushEvent('sel_les_lec_speed_clk', payload);
      },
      pushLectureSpeedChoice: async (payload: SpeedChoiceEventPayload) => {
        await pushEvent('all_lecture_speed_choice', payload);
      },
      pushQuesToolChoice: async (payload: ToolChoiceEventPayload) => {
        await pushEvent('all_ques_tool_choice', payload);
      },
      pushAllQuestionElementConsumedClick: async (payload: PushAllQuestionElementConsumedClickPayload) => {
        await pushEvent('all_qs_elementconsumed_clk', payload);
      },
      pushAllVocaFloatingButtonClick: (payload: PushAllVocaFloatingButtonClickPayload) => {
        return pushEvent('all_voca_floatingbtn_clk', payload);
      },
      pushAllVocaPreviousWordClick: () => {
        return pushEvent('all_voca_previousword_clk');
      },
      pushAllVocaNextWordClick: (payload: PushAllVocaNextWordClick) => {
        return pushEvent('all_voca_nextword_clk', payload);
      },
      pushAllQuestionContentMarkEvent: (payload: PushQuestionContentMarkEventPayload) => {
        return pushEvent('all_qs_contentmark_event', payload);
      },
      pushAllQuestionContentMarkEraseClick: (payload: PushQuestionContentMarkEraseClickPayload) => {
        return pushEvent('all_qs_contentmarkerase_clk', payload);
      },
      pushAllQuestionContentMarkIconClick: (payload: PushQuestionContentMarkIconClick) => {
        return pushEvent('all_qs_contentmarkicon_clk', payload);
      },
      pushAllRcAiChatIconClick: (payload: PushAllRcAiChatIconClick) => {
        return pushEvent('all_rc_aichaticon_clk', payload);
      },
      pushAllAiChatSendQuestionClick: (payload: PushAllAiChatSendQuestionClick) => {
        return pushEvent('all_aichatsendquestion_clk', payload);
      },
      pushAbTestGptRecommendedQuestionClick: (payload: PushAbTestGptRecommendedQuestionClick) => {
        return pushEvent('abt_gptqsrec_clk', payload);
      },
      pushAllContentFontSizeChoice: (payload: PushAllContentFontSizeChoice) => {
        return pushEvent('all_content_fontsize_choice', payload);
      },
    }),
    [pushEvent]
  );

  return testprepEvents;
};

export default useTestprepEvents;
