import {ReactNode} from 'react';
import {css} from '@emotion/react';
import {COLOR_SANTA_G, COLOR_SANTA_L} from '@riiid/design-system';
import Togglebtnup from '@riiid/design-system/icons/Togglebtnup';
import {ButtonBase, Typography} from '@santa-web/service-ui';

type ComboboxButtonProps = {
  isSelected: boolean;
  label?: ReactNode;
  onClick: () => void;
  value?: string;
  placeholder?: string;
  isDisabled?: boolean;
};

const ComboboxButton = ({onClick, value, placeholder, isSelected, label, isDisabled}: ComboboxButtonProps) => {
  return (
    <div
      css={css`
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 8px;
        opacity: ${isDisabled ? 0.4 : 1};

        @media (hover: hover) {
          &:hover button {
            border-color: ${COLOR_SANTA_L};
          }
        }
      `}>
      <label onClick={onClick}>{label}</label>

      <ButtonBase
        isDisabled={isDisabled}
        isFullWidth
        onClick={onClick}
        css={css`
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 0 16px;
          height: 48px;
          border: 1px solid ${COLOR_SANTA_G};
          border-radius: 4px;
        `}>
        <Typography
          component="p"
          css={css`
            text-align: left;
            width: 100%;
          `}
          variant="body2"
          color={value ? COLOR_SANTA_L : COLOR_SANTA_G}>
          {value || placeholder}
        </Typography>
        <Togglebtnup
          width={24}
          height={24}
          color={COLOR_SANTA_L}
          css={css`
            margin-left: auto;
            transition: 0.2s;
            transform: ${isSelected ? '' : 'rotate(180deg)'};
          `}
        />
      </ButtonBase>
    </div>
  );
};

export default ComboboxButton;
export type {ComboboxButtonProps};
ComboboxButton.displayName = 'ComboboxButton';
