/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { LearningHistoryFilterItemBySelfLearningMockExamListFromJSON, LearningHistoryFilterItemBySelfLearningMockExamListToJSON, } from './LearningHistoryFilterItemBySelfLearningMockExamList';
import { LearningHistoryFilterTypeFromJSON, LearningHistoryFilterTypeToJSON, } from './LearningHistoryFilterType';
/**
 * Check if a given object implements the LearningHistoryFilterItem interface.
 */
export function instanceOfLearningHistoryFilterItem(value) {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "isAll" in value;
    isInstance = isInstance && "isChecked" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "tagName" in value;
    isInstance = isInstance && "type" in value;
    return isInstance;
}
export function LearningHistoryFilterItemFromJSON(json) {
    return LearningHistoryFilterItemFromJSONTyped(json, false);
}
export function LearningHistoryFilterItemFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'isAll': json['isAll'],
        'isChecked': json['isChecked'],
        'name': json['name'],
        'selfLearningMockExam': !exists(json, 'selfLearningMockExam') ? undefined : LearningHistoryFilterItemBySelfLearningMockExamListFromJSON(json['selfLearningMockExam']),
        'tagName': json['tagName'],
        'type': LearningHistoryFilterTypeFromJSON(json['type']),
    };
}
export function LearningHistoryFilterItemToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'isAll': value.isAll,
        'isChecked': value.isChecked,
        'name': value.name,
        'selfLearningMockExam': LearningHistoryFilterItemBySelfLearningMockExamListToJSON(value.selfLearningMockExam),
        'tagName': value.tagName,
        'type': LearningHistoryFilterTypeToJSON(value.type),
    };
}
