/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { LearningCellExtraInfoCourseLearningFromJSON, LearningCellExtraInfoCourseLearningToJSON, } from './LearningCellExtraInfoCourseLearning';
import { LearningCellExtraInfoDiagnosisFromJSON, LearningCellExtraInfoDiagnosisToJSON, } from './LearningCellExtraInfoDiagnosis';
import { LearningCellExtraInfoOneOfCaseFromJSON, LearningCellExtraInfoOneOfCaseToJSON, } from './LearningCellExtraInfoOneOfCase';
import { LearningCellExtraInfoSelfLearningFromJSON, LearningCellExtraInfoSelfLearningToJSON, } from './LearningCellExtraInfoSelfLearning';
/**
 * Check if a given object implements the LearningCellExtraInfoOneOf interface.
 */
export function instanceOfLearningCellExtraInfoOneOf(value) {
    let isInstance = true;
    isInstance = isInstance && "oneOfCase" in value;
    return isInstance;
}
export function LearningCellExtraInfoOneOfFromJSON(json) {
    return LearningCellExtraInfoOneOfFromJSONTyped(json, false);
}
export function LearningCellExtraInfoOneOfFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'courseLearning': !exists(json, 'courseLearning') ? undefined : LearningCellExtraInfoCourseLearningFromJSON(json['courseLearning']),
        'diagnosis': !exists(json, 'diagnosis') ? undefined : LearningCellExtraInfoDiagnosisFromJSON(json['diagnosis']),
        'myNote': !exists(json, 'myNote') ? undefined : json['myNote'],
        'oneOfCase': LearningCellExtraInfoOneOfCaseFromJSON(json['oneOfCase']),
        'selfLearning': !exists(json, 'selfLearning') ? undefined : LearningCellExtraInfoSelfLearningFromJSON(json['selfLearning']),
    };
}
export function LearningCellExtraInfoOneOfToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'courseLearning': LearningCellExtraInfoCourseLearningToJSON(value.courseLearning),
        'diagnosis': LearningCellExtraInfoDiagnosisToJSON(value.diagnosis),
        'myNote': value.myNote,
        'oneOfCase': LearningCellExtraInfoOneOfCaseToJSON(value.oneOfCase),
        'selfLearning': LearningCellExtraInfoSelfLearningToJSON(value.selfLearning),
    };
}
