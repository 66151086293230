/* tslint:disable */
/* eslint-disable */
/**
 * `updateLearningCell` 요청의 `FORBIDDEN` 응답 발생 원인으로 가능한 경우.
 *
 * - **NOT_OWNER_OF_LEARNING_CELL**: 대상 `LearningCell`의 소유자가 아님
 * - **NO_AUTHORITY**: 대상 학습 셀의 상태를 변경할 권한이 없음
 * @export
 */
export const UpdateLearningCellForbiddenErrorCase = {
    NOT_OWNER_OF_LEARNING_CELL: 'NOT_OWNER_OF_LEARNING_CELL',
    NO_AUTHORITY: 'NO_AUTHORITY'
};
export function UpdateLearningCellForbiddenErrorCaseFromJSON(json) {
    return UpdateLearningCellForbiddenErrorCaseFromJSONTyped(json, false);
}
export function UpdateLearningCellForbiddenErrorCaseFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function UpdateLearningCellForbiddenErrorCaseToJSON(value) {
    return value;
}
