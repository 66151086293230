import {useFormState} from 'react-final-form';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {BREAKPOINT_MEDIUM, COLOR_SANTA_C, COLOR_SANTA_I, COLOR_SANTA_J} from '@riiid/design-system';
import ReportErrorIcon from '@riiid/design-system/icons/ReportError';
import {Button, TextButton, TopNavBar, Typography} from '@santa-web/service-ui';
import {Column, Divider, FrameLayoutModal, ScrollLayout} from '@app/components';
import {NARROW_MAX_WIDTH} from '@app/constants/max-width';
import {ExamScoreFormFields, ExamScoreTotal} from '@app/features/preferences/components/ExamScore';
import {ScoreConfig, StringDate} from '@app/features/preferences/types';

type ExamScoreFormModalViewProps = {
  isEdit: boolean;
  isMutationLoading: boolean;
  scoreConfig: ScoreConfig;
  validDates: StringDate[];
  onDeleteClick: () => void;
  onDatePick: (date: StringDate) => void;
  onExitClick: () => void;
  onSubmit: () => void;
  validScoreTotal: number;
  currentDate?: StringDate;
};

const SECTION_PADDING_CSS = css`
  padding: 40px 20px;

  @media (min-width: ${BREAKPOINT_MEDIUM}) {
    padding: 40px;
  }
`;

const LIMITED_WIDTH_CSS = css`
  width: 100%;
  max-width: ${NARROW_MAX_WIDTH};
`;

const ExamScoreFormModalView = ({
  isEdit,
  validDates,
  currentDate,
  scoreConfig,
  validScoreTotal,
  isMutationLoading,
  onDeleteClick,
  onDatePick,
  onExitClick,
  onSubmit,
}: ExamScoreFormModalViewProps) => {
  const {t} = useTranslation();
  const {invalid, pristine} = useFormState();
  const isDatePickerDisabled = validDates.length === 0;

  return (
    <FrameLayoutModal
      isOpened={true}
      FrameLayoutProps={{
        bottomArea: (
          // 입력된 값이 유효하지 않거나, initialValues에서 변화가 없는 경우에는 제출버튼 disabled
          <SubmitButton
            isLoading={isMutationLoading}
            handleSubmit={onSubmit}
            isDisabled={invalid || pristine}
            isEdit={isEdit}
          />
        ),
        topNavBar: (
          <TopNavBar
            paginationIcon="exit"
            onPaginationIconClick={onExitClick}
            rightArea={isEdit && <DeleteButton onDelete={onDeleteClick} />}
          />
        ),
      }}>
      <ScrollLayout>
        <Column alignItems="center">
          <Column gap={40} css={[SECTION_PADDING_CSS, LIMITED_WIDTH_CSS]}>
            <Column gap={16}>
              <Typography variant="caption1" fontWeight="bold" component="p" color={COLOR_SANTA_I}>
                {t(`page_exam_score_form_title`)}
              </Typography>

              {isDatePickerDisabled && (
                <div
                  css={css`
                    display: flex;
                    align-items: center;
                    padding: 12px;
                    gap: 12px;
                    background-color: ${COLOR_SANTA_C};
                    border-radius: 4px;
                  `}>
                  <ReportErrorIcon
                    css={css`
                      flex-shrink: 0;
                    `}
                    type="line"
                    color={COLOR_SANTA_J}
                    width={28}
                    height={28}
                  />
                  <Typography variant="caption1" color={COLOR_SANTA_I}>
                    {t('page_exam_scroe_date_disabled')}
                  </Typography>
                </div>
              )}
            </Column>

            <ExamScoreFormFields
              defaultDate={currentDate}
              onDatePick={onDatePick}
              scoreConfig={scoreConfig}
              validDates={validDates}
            />
          </Column>

          <Divider
            css={css`
              flex-shrink: 0;
            `}
          />

          <div css={[SECTION_PADDING_CSS, LIMITED_WIDTH_CSS]}>
            <ExamScoreTotal isPercent={scoreConfig.isPercent} totalScore={validScoreTotal} />
          </div>
        </Column>
      </ScrollLayout>
    </FrameLayoutModal>
  );
};

const DeleteButton = ({onDelete}: {onDelete: () => void}) => {
  const {t} = useTranslation();
  return (
    <TextButton onClick={onDelete} color="black" fontWeight="regular" typographyVariant="body3">
      {t(`page_exam_score_form_delete_button_label`)}
    </TextButton>
  );
};

const SubmitButton = ({
  isEdit,
  isLoading,
  isDisabled,
  handleSubmit,
}: {
  isEdit: boolean;
  isLoading: boolean;
  isDisabled: boolean;
  handleSubmit: ExamScoreFormModalViewProps['onSubmit'];
}) => {
  const {t} = useTranslation();
  const createLabel = t(`page_exam_score_form_submit_button_label_create`);
  const updateLabel = t(`page_exam_score_form_submit_button_label_update`);
  const buttonLabel = isEdit ? updateLabel : createLabel;

  return (
    <Button
      isLoading={isLoading}
      isDisabled={isDisabled}
      onClick={handleSubmit}
      isFullWidth
      colorVariant="brand"
      css={css`
        position: sticky;
        bottom: 0;
      `}
      variant="bottom-fix">
      {buttonLabel}
    </Button>
  );
};

export default ExamScoreFormModalView;
export type {ExamScoreFormModalViewProps};
ExamScoreFormModalView.displayName = 'ExamScoreFormModalView';
