import {useCallback, useMemo} from 'react';

import {ShouldShowTutorialRequest} from '@santa-web/gen/ssp/messages/toeic/mobile';
import {BrowserStorage} from '@santa-web/service-common';
import {useIsGteMediumScreen} from '@santa-web/service-ui';
import {useIsLearningCell} from '@app/test-preparation/hooks';

type CommonTutorialType = NonNullable<ShouldShowTutorialRequest['tutorialType']>;
type WebTutorialType = 'CHAT_USAGE' | 'TESTPREP_UNDERLINE_TOOLTIP' | 'FONT_SIZE_ADJUST' | 'SELF_LEARNING_FIRST_TIME';
export type AllTutorialType = CommonTutorialType | WebTutorialType;

export interface IShouldShowTutorialRequest {
  tutorialType: AllTutorialType;
}

export interface IShouldShowTutorialResponse {
  showTutorial: boolean;
}

export default function useShouldShowTutorial(): (
  request: IShouldShowTutorialRequest
) => Promise<IShouldShowTutorialResponse> {
  const isLearningCell = useIsLearningCell();
  const isGteMediumScreen = useIsGteMediumScreen();

  const storage = useMemo(() => new BrowserStorage(window.localStorage), []);

  return useCallback(
    async request => {
      if (
        !isLearningCell &&
        !['TESTPREP_HIGHLIGHT_USAGE', 'CHAT_USAGE', 'SELF_LEARNING_FIRST_TIME'].includes(request.tutorialType)
      ) {
        return {showTutorial: false};
      }

      if (isGteMediumScreen && ['TESTPREP_SECOND_PAGE_UI_USAGE'].includes(request.tutorialType)) {
        return {showTutorial: false};
      }

      const key = `tutorial-${request.tutorialType}` as const;
      const showTutorial = storage.getItem(key) == null;
      storage.setItem(key, 'true');

      return {showTutorial};
    },
    [isLearningCell, isGteMediumScreen, storage]
  );
}
