import {useQuery} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';

import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {getDiagnosisQueryKey} from '@app/queryKeys';
import {SantaResponseError} from '@app/utils/error';

const useDiagnosisLearningCellQuery = () => {
  const {DiagnosisService} = useAtomValue(santaOpenapiServicesAtom);

  return useQuery(getDiagnosisQueryKey(), async () => {
    try {
      return await DiagnosisService.getDiagnosis({});
    } catch (e) {
      if (e instanceof SantaResponseError && e.response.status === 404) {
        return null;
      }
      throw e;
    }
  });
};

export default useDiagnosisLearningCellQuery;
