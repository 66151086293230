import {useQuery} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';
import {MarketingBannerList} from '@santa-web/gen/open-api/service';
import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {getMarketingBannerQueryKey} from '@app/queryKeys';

export const useMarketingBannerInfoQuery = () => {
  const {MarketingService} = useAtomValue(santaOpenapiServicesAtom);
  return useQuery<MarketingBannerList | null>({
    queryKey: getMarketingBannerQueryKey(),
    queryFn: async () => {
      return await MarketingService.getActiveMarketingBannerList({}).then(({active}) => active ?? null);
    },
  });
};
