import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {COLOR_SANTA_CARD_A} from '@riiid/design-system';
import {URL_GIF_ALERTIMAGE_TUTORIAL_PENCIL} from '@riiid/design-system/images/santa';

import {AlertProps, AlertDialog, AlertDialogProps} from '@santa-web/service-ui';
import {useDialogContext} from '@app/contexts/DialogContext';

export type UserUnderlineTutorialAlertDialogProps = AlertDialogProps;

export default React.memo<UserUnderlineTutorialAlertDialogProps>(
  React.forwardRef<HTMLDivElement, UserUnderlineTutorialAlertDialogProps>(({...props}, ref) => {
    const {t} = useTranslation();
    const {closeDialog} = useDialogContext();
    const AlertProps = React.useMemo(
      (): AlertProps => ({
        image: (
          <img
            css={css`
              width: 100%;
              height: 158px;
              background: ${COLOR_SANTA_CARD_A};
            `}
            src={URL_GIF_ALERTIMAGE_TUTORIAL_PENCIL}
          />
        ),
        title: t('user_underline_tutorial_alert_title'),
        description: t('user_underline_tutorial_alert_description'),
        neutralLabel: t('user_underline_tutorial_alert_label'),
        onNeutralClick: closeDialog,
        css: css`
          white-space: pre-line;
        `,
      }),
      [closeDialog, t]
    );

    return <AlertDialog ref={ref} AlertProps={AlertProps} isVisible {...props} />;
  })
);
