import {useCallback} from 'react';
import {useQueryClient} from '@tanstack/react-query';
import produce from 'immer';

import {ContentInteractionState} from '@santa-web/gen/open-api/content-learning-service';
import {getContentInteractionStatesQueryKey} from '@app/queryKeys';
import {ContentInteractionStateUpdatableProperties} from '@app/types/content-interaction-state';
import {IContentInteractionStateWithContent} from '@app/types/santa-service-protocol';

const findProperties = (
  propertiesList: ContentInteractionStateUpdatableProperties[],
  contentInteractionState: ContentInteractionState | null | undefined
): ContentInteractionStateUpdatableProperties | undefined => {
  return propertiesList.find(({id}) => id && contentInteractionState?.id && id === contentInteractionState.id);
};

const useMutateContentInteractionStates = (cellId: number | null | undefined) => {
  const queryClient = useQueryClient();

  return useCallback(
    (propertiesList: ContentInteractionStateUpdatableProperties[]) => {
      const queryKey = getContentInteractionStatesQueryKey(cellId);

      queryClient.setQueryData<IContentInteractionStateWithContent[]>(queryKey, origin =>
        produce(origin ?? [], draft => {
          for (const contentInteractionStateWithContent of draft) {
            const properties = findProperties(
              propertiesList,
              contentInteractionStateWithContent.contentInteractionState
            );

            contentInteractionStateWithContent.contentInteractionState = {
              ...contentInteractionStateWithContent.contentInteractionState,
              ...properties,
            };
          }
        })
      );
    },
    [cellId, queryClient]
  );
};

export default useMutateContentInteractionStates;
