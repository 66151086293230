import React from 'react';
import {css} from '@emotion/react';
import {
  COLOR_SANTA_G,
  COLOR_SANTA_I,
  COLOR_SANTA_BC_ALPHA,
  COLOR_SANTA_BE,
  COLOR_SANTA_CARD_A,
} from '@riiid/design-system';

import {ButtonBase, ButtonBaseProps, Typography} from '@santa-web/service-ui';

interface IconProps extends React.ComponentPropsWithoutRef<'svg'> {
  type?: 'line' | 'solid';
}

export interface DropdownToggleListItemProps extends ButtonBaseProps {
  Icon?: React.ComponentType<Pick<IconProps, 'width' | 'height' | 'color' | 'type'>>;
  IconProps?: IconProps;
  isSelected?: boolean;
  isDisabled?: boolean;
}

const DropdownToggleListItem = React.forwardRef<HTMLButtonElement, DropdownToggleListItemProps>(
  ({Icon, IconProps, children, isSelected, isDisabled, ...props}, ref) => {
    return (
      <ButtonBase
        isDisabled={isDisabled}
        css={[
          css`
            background-color: ${COLOR_SANTA_CARD_A};
            color: ${COLOR_SANTA_I};
            width: 100%;
            padding: 12px 20px;
          `,
          isDisabled
            ? css`
                color: ${COLOR_SANTA_G};
              `
            : isSelected
            ? css`
                background-color: ${COLOR_SANTA_BC_ALPHA(0.2)};
                color: ${COLOR_SANTA_BE};
              `
            : css`
                @media (hover: hover) {
                  &:hover {
                    background-color: ${COLOR_SANTA_BC_ALPHA(0.1)};
                  }
                }

                &:active {
                  background-color: ${COLOR_SANTA_BC_ALPHA(0.1)};
                }
              `,
        ]}
        ref={ref}
        {...props}>
        {Icon && (
          <Icon
            css={css`
              margin-right: 10px;
            `}
            width={24}
            height={24}
            type={isSelected ? 'solid' : 'line'}
            {...IconProps}
          />
        )}
        <Typography variant="caption1" fontWeight={isSelected ? 'bold' : 'regular'}>
          {children}
        </Typography>
      </ButtonBase>
    );
  }
);

export default React.memo(DropdownToggleListItem);
