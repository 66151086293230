import React from 'react';
import {css} from '@emotion/react';
import {COLOR_SANTA_L, COLOR_SANTA_I, COLOR_SANTA_BC, COLOR_SANTA_O, COLOR_SANTA_O_ALPHA} from '@riiid/design-system';
import {AreaChart, XAxis, YAxis, Area, CartesianGrid, ReferenceLine, ResponsiveContainer} from 'recharts';

import {Typography, TooltipCard} from '@santa-web/service-ui';
import BaseCard from '@app/components/card/BaseCard';

import ExamDistributionTick from './ExamDistributionTick';

export type DistributionDataType = {label: number; value: number};

export interface ExamDistributionCardProps {
  title: string;
  content: string;
  currentValue: number;
  tooltipContent: string;
  data: DistributionDataType[];
}

const ExamDistributionCard = React.forwardRef<HTMLDivElement, ExamDistributionCardProps>(
  ({title, content, data, tooltipContent, currentValue, ...props}, ref) => {
    const minX = data[0].label;
    const maxX = data[data.length - 1].label;
    const maxY = data.reduce((acc, cur) => Math.max(acc, cur.value), 0);
    const renderValue = currentValue < minX ? minX : currentValue > maxX ? maxX : currentValue;
    return (
      <BaseCard ref={ref} {...props}>
        <div
          css={css`
            display: grid;
            gap: 36px;
          `}>
          <div
            css={css`
              display: grid;
              gap: 12px;
            `}>
            <Typography variant="body2" fontWeight="bold" color={COLOR_SANTA_L}>
              {title}
            </Typography>
            <Typography variant="body3" color={COLOR_SANTA_I}>
              {content}
            </Typography>
          </div>
          <div
            css={css`
              position: relative;
            `}>
            {/* should use 99% in this chart */}
            {/* https://github.com/recharts/recharts/issues/172 */}
            <ResponsiveContainer width="99%" height={142}>
              <AreaChart data={data} margin={{bottom: 0}}>
                <defs>
                  <linearGradient id="colorDistribution" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="0%" stopColor={COLOR_SANTA_BC} stopOpacity={1} />
                    <stop offset="99%" stopColor={COLOR_SANTA_BC} stopOpacity={0.3} />
                    <stop offset="100%" stopColor={COLOR_SANTA_BC} stopOpacity={0.2} />
                  </linearGradient>
                </defs>
                <XAxis
                  dataKey="label"
                  type="number"
                  tick={ExamDistributionTick}
                  ticks={[minX, Math.floor((minX + maxX) / 2), maxX]}
                  tickLine={false}
                  domain={[minX, maxX]}
                  padding={{left: 20, right: 20}}
                  stroke="transparent"
                />
                <YAxis hide ticks={[(maxY * 1.3) / 2]} domain={[0, maxY * 1.3]} />
                <CartesianGrid vertical={false} stroke={COLOR_SANTA_O_ALPHA(0.1)} />
                <Area
                  type="basis"
                  dataKey="value"
                  stroke="transparent"
                  fill="url(#colorDistribution)"
                  fillOpacity={1}
                />
                <ReferenceLine x={renderValue} stroke={COLOR_SANTA_O} strokeDasharray="4" />
              </AreaChart>
            </ResponsiveContainer>
            <TooltipCard
              css={css`
                width: auto;
                position: absolute;
                top: 0;
                left: calc((99% - 40px) * ${(renderValue - minX) / (maxX - minX)} + 20px);
                transform: translateY(-50%) translateX(-50%);
              `}
              arrowAlign="center"
              arrowDirection="block-end"
              color="black"
              size="small"
              content={tooltipContent}
            />
          </div>
        </div>
      </BaseCard>
    );
  }
);

export default ExamDistributionCard;
