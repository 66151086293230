import React from 'react';
import {css} from '@emotion/react';
import {SHADOW_A_2_DOWN, COLOR_SANTA_O} from '@riiid/design-system';

import {Typography, TypographyProps, LottieAnimation, useColorThemeImage} from '@santa-web/service-ui';
import {AI_BLOCK_CONTENT_Z_INDEX} from '@app/constants/zIndex';
import exhaustiveCheck from '@app/utils/exhaustiveCheck';

import AiBlockCardIcon, {AiBlockCardIconType, AiBlockCardIconProps} from './AiBlockCardIcon';

interface IconProps extends React.ComponentPropsWithoutRef<'svg'> {
  type?: 'line' | 'solid';
}

export type AiBlockCardProps = Omit<React.ComponentPropsWithoutRef<'div'>, 'content'> & {
  content: string | undefined | null;
  contentProps?: TypographyProps;
  type: AiBlockCardIconType;
  AiBlockCardIconProps?: Omit<AiBlockCardIconProps, 'type'>;
  RightIcon?: React.ComponentType<Pick<IconProps, 'width' | 'height' | 'color' | 'type'>>;
  RightIconProps?: IconProps;
  isLargeSize?: boolean;
};

const AiBlockCard = React.forwardRef<HTMLDivElement, AiBlockCardProps>(
  (
    {content, contentProps, type, AiBlockCardIconProps, RightIcon, RightIconProps, isLargeSize = false, ...props},
    ref
  ) => {
    const {
      cardAiBlockBackground1AnimationData,
      cardAiBlockBackground2AnimationData,
      cardAiBlockBackground3AnimationData,
      cardAiBlockBackground4AnimationData,
      cardAiBlockBackground1LargeAnimationData,
      cardAiBlockBackground2LargeAnimationData,
      cardAiBlockBackground3LargeAnimationData,
      cardAiBlockBackground4LargeAnimationData,
    } = useColorThemeImage();

    const backgroundLottieAnimationData = React.useCallback(() => {
      if (isLargeSize) {
        switch (type) {
          case 'rise':
            return cardAiBlockBackground1LargeAnimationData;
          case 'down':
            return cardAiBlockBackground2LargeAnimationData;
          case 'info':
            return cardAiBlockBackground3LargeAnimationData;
          case 'aipick':
            return cardAiBlockBackground4LargeAnimationData;
          /* istanbul ignore next */
          default:
            exhaustiveCheck(type);
        }
      }
      switch (type) {
        case 'rise':
          return cardAiBlockBackground1AnimationData;
        case 'down':
          return cardAiBlockBackground2AnimationData;
        case 'info':
          return cardAiBlockBackground3AnimationData;
        case 'aipick':
          return cardAiBlockBackground4AnimationData;
        /* istanbul ignore next */
        default:
          exhaustiveCheck(type);
      }
    }, [
      isLargeSize,
      type,
      cardAiBlockBackground1LargeAnimationData,
      cardAiBlockBackground2LargeAnimationData,
      cardAiBlockBackground3LargeAnimationData,
      cardAiBlockBackground4LargeAnimationData,
      cardAiBlockBackground1AnimationData,
      cardAiBlockBackground2AnimationData,
      cardAiBlockBackground3AnimationData,
      cardAiBlockBackground4AnimationData,
    ])();

    return (
      <div
        css={css`
          position: relative;
          z-index: 0;
          display: flex;
          align-items: center;
          justify-content: space-between;

          height: 52px;
          padding: 0 16px;
          overflow: hidden;
          border-radius: 4px;

          cursor: pointer;

          @media (hover: hover) {
            :not(:active):hover {
              .aiblock-card-overlay {
                display: inherit;
              }
              box-shadow: ${SHADOW_A_2_DOWN};
            }
          }
        `}
        ref={ref}
        {...props}>
        <div
          css={css`
            &,
            & > * {
              position: absolute;
              top: 0;
              left: 0;
              width: 100%;
              height: 100%;
            }
          `}>
          <LottieAnimation src={backgroundLottieAnimationData} preserveAspectRatio="xMaxYMin slice" />
          <div
            className="aiblock-card-overlay"
            css={css`
              background: rgba(0, 0, 0, 0.3);
              display: none;
            `}
          />
        </div>
        <div
          css={css`
            display: flex;
            align-items: center;
            margin-right: 20px;
            z-index: ${AI_BLOCK_CONTENT_Z_INDEX};
          `}>
          <AiBlockCardIcon type={type} {...AiBlockCardIconProps} />
          <Typography
            css={css`
              margin-left: 8px;
            `}
            variant="body3"
            fontWeight="bold"
            color={COLOR_SANTA_O}
            {...contentProps}>
            {content}
          </Typography>
        </div>
        {RightIcon && (
          <RightIcon
            css={css`
              z-index: ${AI_BLOCK_CONTENT_Z_INDEX};
            `}
            width={24}
            height={24}
            color={COLOR_SANTA_O}
            {...RightIconProps}
          />
        )}
      </div>
    );
  }
);

export default React.memo(AiBlockCard);
