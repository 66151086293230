import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {Dim, SystemErrorLayout} from '@santa-web/service-ui';

const OfferGroupOrderProcessingDim = () => {
  const {t} = useTranslation();
  return (
    <Dim
      css={css`
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        justify-content: center;
        align-items: center;
      `}>
      <SystemErrorLayout
        icon="ai-logo"
        color="white-fix"
        title={t('page_offer_group_order_purchase_processing_title')}
        description={t('page_offer_group_order_purchase_processing_description')}
      />
    </Dim>
  );
};

export default OfferGroupOrderProcessingDim;
OfferGroupOrderProcessingDim.displayName = 'OfferGroupOrderProcessingDim';
