import {produce} from 'immer';
import {atom} from 'jotai';
import {LearningCellState} from '@santa-web/gen/open-api/content-learning-service';

import {learningCellRepository} from '@app/test-preparation/atoms/repositories';
import {learningCellService} from '@app/test-preparation/atoms/services';
import {PermissionDeniedError} from '@app/test-preparation/atoms/services/shared/errors/permission-denied';
import {handleUnexpectedError} from '@app/test-preparation/atoms/services/shared/errors/unexpected';

type UpdateLearningCellStateParams = {
  learningCellState: LearningCellState;
  shouldOptimisticUpdate?: boolean;
};

const updateLearningCellStateAtom = atom(
  null,
  async (get, set, {learningCellState, shouldOptimisticUpdate = true}: UpdateLearningCellStateParams) => {
    const updateLearningCell = await get(learningCellRepository.updateLearningCellAtom);

    const learningCell = get(learningCellService.learningCellAtom);

    try {
      const nextLearningCell = produce(learningCell, draft => {
        draft.state = learningCellState;
      });

      await updateLearningCell(nextLearningCell);

      if (shouldOptimisticUpdate) {
        set(learningCellService.learningCellAtom, nextLearningCell);
      }
    } catch (error) {
      if (PermissionDeniedError.shouldThrow(error)) {
        throw new PermissionDeniedError(error);
      }

      handleUnexpectedError(error);
    }
  }
);

export {updateLearningCellStateAtom};
