import React from 'react';
import {useTranslation} from 'react-i18next';
import {differenceInDays, format} from 'date-fns';
import {Button, ButtonProps} from '@santa-web/service-ui';
import {BasePermitCard, BasePermitCardProps} from '@app/features/permit/components';

const formatDate = (date: Date) => {
  return format(date, 'yyyy.MM.dd HH:mm:ss');
};

type PassCardProps = Omit<BasePermitCardProps, 'status' | 'button' | 'listItems'> & {
  status: Exclude<BasePermitCardProps['status'], 'used'>;
  period: {
    from: Date;
    to?: Date;
  };
  canStartPass?: boolean;
  onButtonClick?: () => void;
};

const PassCard = ({period, canStartPass = false, onButtonClick, ...basePermitCardProps}: PassCardProps) => {
  const {t} = useTranslation();
  const {status, isSubscription} = basePermitCardProps;
  const shouldShowAdditionalInformation = !!basePermitCardProps.additionalInformation;
  const willBeExpiredSoon = React.useMemo(() => {
    return status === 'in-use' && differenceInDays(new Date(), 14) <= 0;
  }, [status]);

  const listItems = React.useMemo((): BasePermitCardProps['listItems'] => {
    const isEndDanger =
      status === 'refunded' || status === 'refund-requested' || status === 'renew-failed' || willBeExpiredSoon;
    const expireLabel = {
      pending: t('component_pass_card_date_label_pending'),
      'in-use': willBeExpiredSoon ? t('component_pass_card_date_label_expire_soon') : undefined,
      expired: t('component_pass_card_date_label_expired'),
      'refund-requested': t('component_pass_card_date_label_refund_requested'),
      refunded: t('component_pass_card_date_label_refunded'),
      'renew-failed': t('component_pass_card_date_label_renew_failed'),
    }[status];
    if (shouldShowAdditionalInformation) {
      return [
        {
          title: t('component_pass_card_date_label_one_line'),
          value:
            status !== 'pending' && period.to ? `${formatDate(period.from)} ~ ${formatDate(period.to)}` : undefined,
          isMultiline: true,
          label: expireLabel,
          isDanger: isEndDanger,
          description: status === 'pending' ? t('component_pass_card_date_label_pending_description') : undefined,
        },
      ];
    }
    const startDateListItem: BasePermitCardProps['listItems'][number] = {
      title: t('component_pass_card_date_label_purchased_at'),
      value: formatDate(period.from),
    };
    const endDateListItem: BasePermitCardProps['listItems'][number] = {
      title: isSubscription
        ? t('component_pass_card_date_label_canceled_at')
        : t('component_pass_card_date_label_expire_at'),
      value: period.to && formatDate(period.to),
      label: expireLabel,
      isDanger: isEndDanger,
    };
    switch (status) {
      case 'pending':
        return [
          startDateListItem,
          {
            ...endDateListItem,
            title: t('component_pass_card_date_label_expire_at'),
            description: t('component_pass_card_date_label_pending_description'),
          },
        ];
      case 'in-use':
        return [
          startDateListItem,
          {
            ...endDateListItem,
            title: isSubscription
              ? t('component_pass_card_date_label_renew_at')
              : t('component_pass_card_date_label_expire_at'),
          },
        ];
      case 'expired':
      case 'refunded':
      case 'refund-requested':
      case 'renew-failed':
        return [startDateListItem, endDateListItem];
    }
  }, [isSubscription, period.from, period.to, shouldShowAdditionalInformation, status, willBeExpiredSoon, t]);

  const buttonProps: ButtonProps | undefined = React.useMemo(() => {
    if (status === 'pending') {
      return canStartPass
        ? {
            colorVariant: 'brand',
            children: t('component_pass_card_button_start_use'),
          }
        : {
            isDisabled: true,
            children: t('component_pass_card_button_cannot_use'),
          };
    }
    return undefined;
  }, [canStartPass, status, t]);

  return (
    <BasePermitCard
      {...basePermitCardProps}
      listItems={listItems}
      button={buttonProps && <Button {...buttonProps} onClick={onButtonClick} />}
    />
  );
};

export default PassCard;
export type {PassCardProps};
PassCard.displayName = 'PassCard';
