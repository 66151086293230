import Link from 'next/link';
import React from 'react';
import {TFunction, useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {COLOR_SANTA_I} from '@riiid/design-system';
import {createOnceCaller} from '@santa-web/service-common';
import {Typography, useToastContext} from '@santa-web/service-ui';
import {mobileService} from '@app/api/app-bridge/mobile-service';
import config from '@app/config';
import {useUserAgreementMutation} from '@app/features/auth';
import PreferenceListItem from '@app/features/preferences/components/PreferenceListItem';
import PreferencePageLayoutContainer from '@app/features/preferences/containers/PreferencePageLayoutContainer';
import {useGetMobileAppInfoQuery, useGetSystemConfigAppVersionsQuery} from '@app/features/preferences/hooks';
import {useOpenNewWindow} from '@app/hooks/useOpenNewWindow';
import useUserQuery from '@app/hooks/user/useUser';
import createVersionWrapper from '@app/new-structure/useCases/VersionChecker/VersionWrapper';
import {SantaNavigationPath} from '@app/types/navigation';
import {isAppBridgeAvailable} from '@app/utils/app-bridge';

const getExternalResourceMenuItems = (
  t: TFunction<'translation'>,
  language: string
): {href: string; title: string; isNew?: boolean}[] => {
  return [
    ...(function* () {
      yield {
        title: t('page_setting_list_item_notice'),
        href: t('notice_url'),
      };
      yield {
        title: t('page_setting_list_item_customer_service'),
        href: t('customer_service_url'),
      };
      if (language === 'ko') {
        yield {
          title: t('page_setting_list_item_community'),
          href: t('my_page_menu_community_href'),
        };
      }
      yield {
        title: t('page_setting_list_item_terms_of_service'),
        href: t('terms_of_service'),
      };
      yield {
        title: t('page_setting_list_item_terms_of_privacy'),
        href: t('terms_of_privacy'),
      };
    })(),
  ];
};

const MySettingPageContainer = () => {
  const {t, i18n} = useTranslation();
  const {data: user} = useUserQuery();
  const {mutateAsync: updateAgreement, isLoading: isAgreementUpdating} = useUserAgreementMutation();
  const {data: appInfo} = useGetMobileAppInfoQuery();
  const openNewWindow = useOpenNewWindow();
  const {data: systemConfigAppVersions} = useGetSystemConfigAppVersionsQuery(appInfo?.platform);
  const {toastContainer, openToast} = useToastContext();

  const currentMarketingAgreement = user?.agreement.marketing?.agreed ?? false;
  const isLoadingAgreement = user == null || isAgreementUpdating;

  const versionInfo = React.useMemo(() => {
    if (appInfo == null || appInfo.version == null || systemConfigAppVersions == null) {
      return null;
    }
    const convertVersionToString = (version: {major: number; minor: number; patch: number}) => {
      return `${version.major}.${version.minor}.${version.patch}`;
    };
    const hasUpdate = createVersionWrapper(systemConfigAppVersions.latest).gt(appInfo.version);
    return {
      hasUpdate,
      storeUrl: appInfo.platform === 'IOS' ? config.appStoreUrl : config.playStoreUrl,
      currentVersion: convertVersionToString(appInfo.version),
      latestVersion: convertVersionToString(systemConfigAppVersions.latest),
    };
  }, [appInfo, systemConfigAppVersions]);

  const handleMarketingAgreementToggle = createOnceCaller(async () => {
    if (isLoadingAgreement) {
      return;
    }
    const user = await updateAgreement({marketing: !currentMarketingAgreement});
    if (user.agreement.marketing?.agreed) {
      if (isAppBridgeAvailable()) {
        await mobileService.requestPermission('PUSH');
      }
      openToast({message: t('page_setting_list_item_marketing_toggle_on_toast'), colorVariant: 'gray'});
    } else {
      openToast({message: t('page_setting_list_item_marketing_toggle_off_toast'), colorVariant: 'gray'});
    }
  });

  return (
    <PreferencePageLayoutContainer
      isNarrow
      upperArea={
        <div>
          <Typography
            css={css`
              margin-bottom: 40px;
            `}
            component="h1"
            variant="caption1"
            fontWeight="bold"
            color={COLOR_SANTA_I}>
            {t('page_setting_title')}
          </Typography>
          <div
            css={css`
              display: grid;
              gap: 32px;
            `}>
            <PreferenceListItem
              title={{value: t('page_setting_list_item_marketing_toggle'), fontWeight: 'bold'}}
              action={{
                type: 'switch',
                value: currentMarketingAgreement,
                isLoading: isLoadingAgreement,
                onClick: handleMarketingAgreementToggle,
              }}
            />
            <Link href={SantaNavigationPath['/me/setting/theme']}>
              <PreferenceListItem
                isNew
                title={{value: t('page_setting_list_item_theme'), fontWeight: 'bold'}}
                action={{type: 'chevron'}}
              />
            </Link>
            {getExternalResourceMenuItems(t, i18n.languages[0]).map(item => (
              <PreferenceListItem
                key={item.href}
                isNew={item.isNew}
                title={{value: item.title, fontWeight: 'bold'}}
                action={{type: 'chevron', onClick: () => openNewWindow(item.href)}}
              />
            ))}
            {isAppBridgeAvailable() &&
              (versionInfo == null ? (
                <PreferenceListItem
                  title={{
                    value: t('page_setting_list_item_version_info'),
                    fontWeight: 'bold',
                  }}
                  isLoading
                  isDisabled
                  action={{
                    type: 'chevron',
                  }}
                />
              ) : (
                <PreferenceListItem
                  title={{
                    value: `${t('page_setting_list_item_version_info')}(${versionInfo.currentVersion})`,
                    fontWeight: 'bold',
                  }}
                  description={
                    versionInfo.hasUpdate
                      ? {
                          value: t('page_setting_list_item_version_info_update_description', {
                            version: versionInfo.latestVersion,
                          }),
                          colorVariant: 'brand',
                        }
                      : {
                          value: t('page_setting_list_item_version_info_latest_description'),
                        }
                  }
                  action={{
                    type: 'chevron',
                    onClick: () => openNewWindow(versionInfo.storeUrl),
                  }}
                />
              ))}
          </div>
          {toastContainer}
        </div>
      }
    />
  );
};

export default MySettingPageContainer;
MySettingPageContainer.displayName = 'MySettingPageContainer';
