import React from 'react';
import {css} from '@emotion/react';
import {COLOR_SANTA_I} from '@riiid/design-system';

import {Typography} from '@santa-web/service-ui';

import Dot, {LegendDotVariant} from './Dot';

export interface LegendProps {
  color: string;
  subject: string;
  variant: LegendDotVariant;
}

const Legend = React.forwardRef<HTMLDivElement, LegendProps>(({color, subject, variant, ...props}, ref) => {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
      `}
      {...props}
      ref={ref}>
      <Dot color={color} variant={variant} />
      <Typography variant="caption2" fontWeight="regular" color={COLOR_SANTA_I}>
        {subject}
      </Typography>
    </div>
  );
});

export default React.memo(Legend);
