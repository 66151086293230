/**
 * Check if a given object implements the PartialExamScoreConfig interface.
 */
export function instanceOfPartialExamScoreConfig(value) {
    let isInstance = true;
    isInstance = isInstance && "isPercent" in value;
    isInstance = isInstance && "maxScore" in value;
    isInstance = isInstance && "minScore" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "scoreStep" in value;
    return isInstance;
}
export function PartialExamScoreConfigFromJSON(json) {
    return PartialExamScoreConfigFromJSONTyped(json, false);
}
export function PartialExamScoreConfigFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'isPercent': json['isPercent'],
        'maxScore': json['maxScore'],
        'minScore': json['minScore'],
        'name': json['name'],
        'scoreStep': json['scoreStep'],
    };
}
export function PartialExamScoreConfigToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'isPercent': value.isPercent,
        'maxScore': value.maxScore,
        'minScore': value.minScore,
        'name': value.name,
        'scoreStep': value.scoreStep,
    };
}
