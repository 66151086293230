import React from 'react';
import {Trans} from 'react-i18next';
import {css} from '@emotion/react';
import {COLOR_SANTA_DD, COLOR_SANTA_G, COLOR_SANTA_L} from '@riiid/design-system';
import {Typography} from '@santa-web/service-ui';
import {BaseCard, Column, ImageSet, Row} from '@app/components';
import {OfferGroupDisplayImage, OfferRemainedCounter} from '@app/features/offer/components';
import Timer, {TimerProps} from './components/Timer';

type OfferGroupCardProps = {
  title: string;
  bgImageSet: ImageSet;
  contentImageSet: ImageSet;
  contentImageSetAlign: 'LEFT' | 'CENTER';
  discount?: string;
  timer?: TimerProps;
  remainedCount?: number;
  onClick: React.MouseEventHandler<HTMLDivElement>;
};

const OfferGroupCard = ({
  title,
  discount,
  timer,
  bgImageSet,
  contentImageSet,
  contentImageSetAlign,
  remainedCount,
  onClick,
}: OfferGroupCardProps) => {
  return (
    <BaseCard
      css={css`
        width: 100%;
        position: relative;
        padding: 0;
        overflow: hidden;
      `}
      onClick={onClick}>
      <OfferGroupDisplayImage
        backgroundImageSet={bgImageSet}
        contentImageSet={contentImageSet}
        contentImageSetAlign={contentImageSetAlign}>
        {timer && (
          <Timer
            css={css`
              position: absolute;
              bottom: 0;
            `}
            {...timer}
          />
        )}
      </OfferGroupDisplayImage>
      <Row
        css={css`
          padding: 12px 20px 16px;
        `}
        alignItems="flex-start"
        justifyContent="space-between">
        <Column>
          <Typography color={COLOR_SANTA_L} variant="body2">
            {title}
          </Typography>
          {discount && (
            <Typography color={COLOR_SANTA_G} variant="caption1" component="p">
              <Trans
                i18nKey="component_offer_group_card_discount_label"
                values={{percent: discount}}
                components={{
                  em: <Typography color={COLOR_SANTA_DD} variant="caption1" fontWeight="bold" component="span" />,
                }}
              />
            </Typography>
          )}
        </Column>
        {remainedCount != null && <OfferRemainedCounter count={remainedCount} />}
      </Row>
    </BaseCard>
  );
};

export default OfferGroupCard;
export type {OfferGroupCardProps};
OfferGroupCard.displayName = 'OfferGroupCard';
