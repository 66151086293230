import {useState} from 'react';
import {Form} from 'react-final-form';
import {useTranslation} from 'react-i18next';
import {Button} from '@santa-web/service-ui';
import {Column, TextField} from '@app/components';

type CouponRegisterFormProps = {
  initialValue?: string;
  onCouponRegister: (code: string) => Promise<void>;
};

const CouponRegisterForm = ({onCouponRegister, initialValue}: CouponRegisterFormProps) => {
  const [isLoading, setIsLoading] = useState(false);
  const {t} = useTranslation();

  return (
    <Form
      initialValues={{code: initialValue}}
      onSubmit={async ({code}, {initialize}) => {
        setIsLoading(true);
        await onCouponRegister(code);
        initialize({code: ''});
        setIsLoading(false);
      }}>
      {({handleSubmit, values}) => {
        const isValid = values.code?.length >= 1;
        return (
          <form onSubmit={handleSubmit}>
            <Column gap={40}>
              <TextField
                label={t('page_coupon_box_registration_input_field_label')} // KR: 쿠폰 코드
                name="code"
                hint={t('page_coupon_box_registration_input_field_hint')} // KR: 쿠폰 코드를 입력해 주세요
              />
              <Button type="submit" isDisabled={!isValid} colorVariant="brand" isLoading={isLoading}>
                {t('page_coupon_box_registration_input_field_submit')} {/** KR: 등록하기 */}
              </Button>
            </Column>
          </form>
        );
      }}
    </Form>
  );
};

export default CouponRegisterForm;
export type {CouponRegisterFormProps};
CouponRegisterForm.displayName = 'CouponRegisterForm';
