import { ImageSetFromJSON, ImageSetToJSON, } from './ImageSet';
import { LearningDomainKeyFromJSON, LearningDomainKeyToJSON, } from './LearningDomainKey';
/**
 * Check if a given object implements the ListVisibleLearningDomainsResponseLearningDomain interface.
 */
export function instanceOfListVisibleLearningDomainsResponseLearningDomain(value) {
    let isInstance = true;
    isInstance = isInstance && "availability" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "displayName" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "imageSet" in value;
    isInstance = isInstance && "isInFreePromotion" in value;
    isInstance = isInstance && "key" in value;
    return isInstance;
}
export function ListVisibleLearningDomainsResponseLearningDomainFromJSON(json) {
    return ListVisibleLearningDomainsResponseLearningDomainFromJSONTyped(json, false);
}
export function ListVisibleLearningDomainsResponseLearningDomainFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'availability': json['availability'],
        'description': json['description'],
        'displayName': json['displayName'],
        'id': json['id'],
        'imageSet': ImageSetFromJSON(json['imageSet']),
        'isInFreePromotion': json['isInFreePromotion'],
        'key': LearningDomainKeyFromJSON(json['key']),
    };
}
export function ListVisibleLearningDomainsResponseLearningDomainToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'availability': value.availability,
        'description': value.description,
        'displayName': value.displayName,
        'id': value.id,
        'imageSet': ImageSetToJSON(value.imageSet),
        'isInFreePromotion': value.isInFreePromotion,
        'key': LearningDomainKeyToJSON(value.key),
    };
}
