/* tslint:disable */
/* eslint-disable */
/**
 * `startDiagnosis` 요청의 `BAD_REQUEST` 응답 원인으로 가능한 경우.
 *
 * - **DIAGNOSIS_ALREADY_EXISTS**: 현재 사용자 및 사용자가 선택한 learning domain에 대해 이미 진단고사가 존재
 * - **DIAGNOSIS_NOT_PROVIDABLE**: 현재 사용자의 언어 및 사용자가 선택한 learning domain에 대해 진단고사 제공이 불가능
 * - **INVALID_DIAGNOSIS_CONTENT_OPTION_LC_REQUIRED**: LC가 필수인데 RC 옵션으로 `startDiagnosis` 요청을 보낸 경우
 * - **INVALID_DIAGNOSIS_CONTENT_OPTION_LC_UNAVAILABLE**: LC 문제 제공이 불가능한데 LC_RC 옵션으로 `startDiagnosis` 요청을 보낸 경우
 * @export
 */
export const StartDiagnosisBadRequestErrorCase = {
    DIAGNOSIS_ALREADY_EXISTS: 'DIAGNOSIS_ALREADY_EXISTS',
    DIAGNOSIS_NOT_PROVIDABLE: 'DIAGNOSIS_NOT_PROVIDABLE',
    INVALID_DIAGNOSIS_CONTENT_OPTION_LC_REQUIRED: 'INVALID_DIAGNOSIS_CONTENT_OPTION_LC_REQUIRED',
    INVALID_DIAGNOSIS_CONTENT_OPTION_LC_UNAVAILABLE: 'INVALID_DIAGNOSIS_CONTENT_OPTION_LC_UNAVAILABLE'
};
export function StartDiagnosisBadRequestErrorCaseFromJSON(json) {
    return StartDiagnosisBadRequestErrorCaseFromJSONTyped(json, false);
}
export function StartDiagnosisBadRequestErrorCaseFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function StartDiagnosisBadRequestErrorCaseToJSON(value) {
    return value;
}
