import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import CheckIcon from '@riiid/design-system/icons/Check';
import {useAtomValue} from 'jotai';
import {Typography} from '@santa-web/service-ui';
import {LEVEL_CHOICE_AB_TEST_KEY_VALUE_STORAGE_TYPE} from '@app/experiments/contents-level-choice-rate/constants';
import useIsContentLevelChoiceRateAvailable from '@app/experiments/contents-level-choice-rate/hooks/useIsContentLevelChoiceRateAvailable';
import {getFlooredScore, isContentMetadata} from '@app/experiments/contents-level-choice-rate/utils';
import useUserProfile from '@app/hooks/user/useUserProfile';
import useServerStorageQuery from '@app/hooks/useServerStorageQuery';
import {learningStore} from '@app/test-preparation/atoms/stores';

const MINIMUM_INTERACTION_LENGTH = 50;

type ChoiceRateInformationContainerProps = {questionIndex: number; choiceIndex: number};

const ChoiceRateInformationContainer = ({questionIndex, choiceIndex}: ChoiceRateInformationContainerProps) => {
  const {t} = useTranslation();
  const contentId = useAtomValue(learningStore.content.idAtom);
  const eises = useAtomValue(learningStore.cis.eisesAtom);
  const currentEis = eises[questionIndex];
  const {contentElementId: questionId} = currentEis;

  const {data: profile} = useUserProfile();
  const currentTargetScoreRange =
    profile?.onboardingInfo?.targetScore && getFlooredScore(profile.onboardingInfo.targetScore);

  const isChoiceRateInformationAvailable = useIsContentLevelChoiceRateAvailable() && currentTargetScoreRange != null;

  const {data: storageData} = useServerStorageQuery(LEVEL_CHOICE_AB_TEST_KEY_VALUE_STORAGE_TYPE, contentId);
  const contentMetadata = storageData?.value;

  const choicePercentage: number | null = React.useMemo(() => {
    if (currentTargetScoreRange == null || contentMetadata == null || !isContentMetadata(contentMetadata)) return null;
    try {
      const currentQuestionData = contentMetadata[questionId];
      const interactionLength = currentQuestionData.interactionLength[currentTargetScoreRange];
      if (interactionLength == null || interactionLength < MINIMUM_INTERACTION_LENGTH) {
        return null;
      }
      // 해당 문제에서 해당 점수대 유저의 interaction량이 MINIMUM_INTERACTION_LENGTH 이상인 경우, 컨텐츠에 따라 특정 선지 선택률이 없는 케이스가 정상일 수 있기 때문에(너무 쉬운 문제인 등) 추가 validation을 하지 않음
      const currentChoiceRate = Number(currentQuestionData.choiceRateMaps[choiceIndex][currentTargetScoreRange]) || 0;
      return Math.floor(currentChoiceRate * 100);
    } catch (e) {
      return null;
    }
  }, [choiceIndex, contentMetadata, currentTargetScoreRange, questionId]);

  return isChoiceRateInformationAvailable && choicePercentage !== null ? (
    <div
      css={css`
        display: flex;
        align-items: center;
      `}>
      <Typography
        css={css`
          margin-right: 4px;
        `}
        component="p"
        variant="caption2">
        {t('testprep_user_choice_rate_description', {score: currentTargetScoreRange, percentage: choicePercentage})}
      </Typography>
      <CheckIcon width={16} height={16} />
    </div>
  ) : null;
};

export default ChoiceRateInformationContainer;
export type {ChoiceRateInformationContainerProps};
ChoiceRateInformationContainer.displayName = 'ChoiceRateInformationContainer';
