/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { DisplayInfoV2FromJSON, DisplayInfoV2ToJSON, } from './DisplayInfoV2';
import { OneOfPassStatusFromJSON, OneOfPassStatusToJSON, } from './OneOfPassStatus';
/**
 * Check if a given object implements the OrderPass interface.
 */
export function instanceOfOrderPass(value) {
    let isInstance = true;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "displayInfo" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "learningDomainDisplayName" in value;
    isInstance = isInstance && "status" in value;
    return isInstance;
}
export function OrderPassFromJSON(json) {
    return OrderPassFromJSONTyped(json, false);
}
export function OrderPassFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'createdAt': (new Date(json['createdAt'])),
        'displayInfo': DisplayInfoV2FromJSON(json['displayInfo']),
        'expireAt': !exists(json, 'expireAt') ? undefined : (new Date(json['expireAt'])),
        'id': json['id'],
        'learningDomainDisplayName': json['learningDomainDisplayName'],
        'status': OneOfPassStatusFromJSON(json['status']),
    };
}
export function OrderPassToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'createdAt': (value.createdAt.toISOString()),
        'displayInfo': DisplayInfoV2ToJSON(value.displayInfo),
        'expireAt': value.expireAt === undefined ? undefined : (value.expireAt.toISOString()),
        'id': value.id,
        'learningDomainDisplayName': value.learningDomainDisplayName,
        'status': OneOfPassStatusToJSON(value.status),
    };
}
