export function getQuestionTitle(startIndex?: number, endIndex?: number): string {
  if (startIndex == null) return 'Question';
  if (endIndex == null || startIndex === endIndex) return `Question ${startIndex}`;
  return `Question ${startIndex}~${endIndex}`;
}

export function getQTitle(startIndex?: number, endIndex?: number): string {
  if (startIndex == null) return 'Q';
  if (endIndex == null || startIndex === endIndex) return `Q.${startIndex}`;
  return `Q.${startIndex}~${endIndex}`;
}
