import {useMutation, useQueryClient} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';
import {Order} from '@santa-web/gen/open-api/service';
import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {convertTypedUrlToFullUrl} from '@app/hooks/useTypedRouter';
import {getOfferGroupOrderQueryKey} from '@app/queryKeys';

export const usePrepareCheckoutOfferGroupOrderMutation = (orderId: number) => {
  const {OrderService} = useAtomValue(santaOpenapiServicesAtom);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (): Promise<Order> => {
      const order = queryClient.getQueryData<Order>(getOfferGroupOrderQueryKey(orderId))!;
      const paymentMethod = order.orderConfig?.selectedPaymentProvider;
      return await OrderService.startOrderPayment({
        id: orderId,
        startOrderPaymentRequest:
          paymentMethod === 'STRIPE'
            ? {
                stripeConfig: {
                  successPath: convertTypedUrlToFullUrl({
                    pathname: '/offer-group/order/complete',
                    query: {id: orderId},
                  }),
                  cancelPath: convertTypedUrlToFullUrl({
                    pathname: '/offer-group/order/complete',
                    query: {id: orderId, error_msg: 'user_canceled_stripe_payment'},
                  }),
                },
              }
            : {},
      }).then(({order}) => order);
    },
    onSuccess: order => {
      queryClient.setQueryData(getOfferGroupOrderQueryKey(order.id), order);
    },
  });
};
