import React from 'react';

interface useIntersectionObserverArgs extends Omit<IntersectionObserverInit, 'root'> {
  root?: React.RefObject<HTMLElement> | null;
  target: React.RefObject<HTMLElement>;
  onIntersect: (entry?: IntersectionObserverEntry) => unknown;
  offIntersect?: (entry?: IntersectionObserverEntry) => unknown;
  enabled?: boolean;
}

export default function useIntersectionObserver({
  root = null,
  target,
  onIntersect,
  offIntersect,
  threshold = 1.0,
  rootMargin = '0px',
  enabled = true,
}: useIntersectionObserverArgs) {
  React.useEffect(() => {
    if (!enabled) {
      return;
    }

    const observer = new IntersectionObserver(
      entries =>
        entries.forEach(entry => {
          if (entry.isIntersecting) {
            onIntersect(entry);
          } else if (offIntersect) {
            offIntersect(entry);
          }
        }),
      {
        root: root && root.current,
        rootMargin,
        threshold,
      }
    );

    const el = target && target.current;

    if (!el) {
      return;
    }

    observer.observe(el);

    return () => {
      observer.unobserve(el);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [target.current, enabled]);
}
