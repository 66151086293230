import Decimal from 'decimal.js';

import {Currency} from '@santa-web/gen/open-api/toeic-speaking';
import {createPriceFormatter} from '@app/facade/price/price-formatter';

export function getFormattedPrice(currency: Currency, amount: Decimal | number): string {
  return createPriceFormatter(currency).getFormattedPrice(new Decimal(amount));
}

export function getFormattedRate(amount: Decimal | number, totalAmount: Decimal | number): string {
  return (
    new Decimal(amount).div(new Decimal(totalAmount)).toDecimalPlaces(2, Decimal.ROUND_HALF_CEIL).mul(100).toString() +
    '%'
  );
}
