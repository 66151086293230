import { LanguageFromJSON, LanguageToJSON, } from './Language';
import { LearningDomainKeyFromJSON, LearningDomainKeyToJSON, } from './LearningDomainKey';
/**
 * Check if a given object implements the HighlightContentIdentifier interface.
 */
export function instanceOfHighlightContentIdentifier(value) {
    let isInstance = true;
    isInstance = isInstance && "contentId" in value;
    isInstance = isInstance && "language" in value;
    isInstance = isInstance && "learningDomainKey" in value;
    return isInstance;
}
export function HighlightContentIdentifierFromJSON(json) {
    return HighlightContentIdentifierFromJSONTyped(json, false);
}
export function HighlightContentIdentifierFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'contentId': json['contentId'],
        'language': LanguageFromJSON(json['language']),
        'learningDomainKey': LearningDomainKeyFromJSON(json['learningDomainKey']),
    };
}
export function HighlightContentIdentifierToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'contentId': value.contentId,
        'language': LanguageToJSON(value.language),
        'learningDomainKey': LearningDomainKeyToJSON(value.learningDomainKey),
    };
}
