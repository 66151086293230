import React from 'react';
import {css} from '@emotion/react';
import {COLOR_SANTA_DD, COLOR_SANTA_BC, COLOR_SANTA_G} from '@riiid/design-system';
import CheckIcon from '@riiid/design-system/icons/Check';
import CloseIcon from '@riiid/design-system/icons/Close';
import UnmarkedIcon from '@riiid/design-system/icons/Unmarked';

import {QuestionStateAnswerState} from '@santa-web/gen/open-api/content-learning-service';

export interface AnswerStateListProps extends React.HTMLAttributes<HTMLDivElement> {
  answerStates: QuestionStateAnswerState[];
}

const AnswerStateList = React.forwardRef<HTMLDivElement, AnswerStateListProps>(({answerStates, ...props}, ref) => (
  <div
    ref={ref}
    css={css`
      display: flex;
      align-items: center;
    `}
    {...props}>
    {answerStates.map((answerState, i) =>
      answerState === 'CORRECT' ? (
        <CheckIcon key={i} color={COLOR_SANTA_BC} width={24} height={24} />
      ) : answerState === 'INCORRECT' ? (
        <CloseIcon key={i} color={COLOR_SANTA_DD} width={24} height={24} />
      ) : (
        <UnmarkedIcon key={i} color={COLOR_SANTA_G} width={24} height={24} />
      )
    )}
  </div>
));

export default React.memo(AnswerStateList);
