import {getDefaultStore} from 'jotai';
import {atomWithDefault} from 'jotai/utils';

import {UserToken} from '@santa-web/gen/open-api/service';
import {BrowserStorage, BrowserStorageKeyValue, createOnceCaller} from '@santa-web/service-common';
import {AllTutorialType} from '@app/hooks/useShouldShowTutorial';
import i18n from '@app/i18n';
import {getLocaleString} from '@app/i18n/utils';
import {SantaResponseError} from '@app/utils/error';

import santaUnauthorizedOpenapiServicesAtom from './santa-unauthorized-openapi-services';

export type SantaBrowserStorageKeyValue = BrowserStorageKeyValue & {
  [key in `tutorial-${AllTutorialType}`]: 'true';
};

/**
 * @description 토큰을 조회, 저장, 삭제, 갱신하는 기능을 제공한다.
 */
export interface TokenManager {
  /**
   * @description 저장된 토큰을 가져온다. 저장되지 않은 경우 새로 받아와서 저장한다. 받아오는 방식은 웹뷰와 PC Web 환경에 따라 상이하므로 구현 참조.
   */
  getToken(): Promise<UserToken>;
  /**
   * @description 토큰을 갱신하고, 갱신한 토큰을 저장한다.
   * @returns 갱신된 토큰.
   */
  refreshToken(): Promise<UserToken>;
  /**
   * @description 토큰을 삭제한다.
   * @returns 삭제된 토큰. 토큰이 없는 상태였다면 undefined.
   */
  deleteToken(): Promise<UserToken | undefined>;
  /**
   * @description 토큰을 저장한다.
   * @param token 저장할 토큰.
   */
  setToken(token: UserToken): void;
}

export const pcWebTokenManagerAtom = atomWithDefault<Promise<TokenManager>>(async get => {
  const {AuthService} = await get(santaUnauthorizedOpenapiServicesAtom);
  const browserStorage = new BrowserStorage<SantaBrowserStorageKeyValue>(localStorage);
  const atomStore = getDefaultStore();

  function setToken(token: UserToken) {
    atomStore.set(tokenAtom, Promise.resolve(token));
    browserStorage.setItem('userToken', token);
  }
  const setAnonymousToken = createOnceCaller(async (): Promise<UserToken> => {
    const {userToken} = await AuthService.createAnonymousUser({
      createAnonymousUserRequest: {
        locale: getLocaleString(i18n.language),
      },
    });
    setToken(userToken);
    return userToken;
  });
  /**
   * PC Web 환경에서는 토큰이 저장되어 있지 않은 경우 익명 유저를 생성하여 그 유저의 토큰을 저장한 후 가져온다.
   */
  async function getToken(): Promise<UserToken> {
    const savedUserToken = browserStorage.getItem('userToken');
    return savedUserToken || setAnonymousToken();
  }
  async function deleteToken() {
    const deletedToken = browserStorage.getItem('userToken');
    await setAnonymousToken();
    return deletedToken;
  }
  const refreshToken = createOnceCaller(async (): Promise<UserToken> => {
    const expiredToken = browserStorage.getItem('userToken');
    if (expiredToken == null) throw new Error('cannot get userToken from browserStorage.');
    const refreshedToken = await (async () => {
      try {
        const {userToken} = await AuthService.refreshToken({
          refreshTokenRequest: {userToken: expiredToken},
        });
        return userToken;
      } catch (e) {
        if (e instanceof SantaResponseError && e.response.status === 401) {
          // refresh token이 만료된 경우이므로 storage에서 기존 토큰을 새로 발급받은 익명 토큰으로 교체
          return await setAnonymousToken();
        }
        throw e;
      }
    })();
    setToken(refreshedToken);
    return refreshedToken;
  });

  return {
    setToken,
    getToken,
    deleteToken,
    refreshToken,
  };
});

export const tokenManagerAtom = atomWithDefault<Promise<TokenManager>>(async get => {
  return await get(pcWebTokenManagerAtom);
});

/**
 * @description 토큰 변화를 구독하기 위한 atom
 */
export const tokenAtom = atomWithDefault<Promise<UserToken>>(async get => {
  const tokenManager = await get(tokenManagerAtom);
  return await tokenManager.getToken();
});

export default tokenManagerAtom;

pcWebTokenManagerAtom.debugPrivate = true;
tokenManagerAtom.debugPrivate = true;
tokenAtom.debugPrivate = true;
