import {atom} from 'jotai';
import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';

const completeCisesAtom = atom(async get => {
  const {ContentInteractionStateService} = await get(santaOpenapiServicesAtom);

  return async (learningCellId: number) =>
    await ContentInteractionStateService.completeAllContentInteractionStates({
      learningCellId,
    });
});

export {completeCisesAtom};
