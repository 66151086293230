import {useMutation, useQueryClient} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';
import {OneOfTransaction, Order} from '@santa-web/gen/open-api/service';
import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {getOfferGroupOrderQueryKey} from '@app/queryKeys';

export const useCompleteCheckoutOfferGroupOrderMutation = (orderId: number) => {
  const {OrderService} = useAtomValue(santaOpenapiServicesAtom);
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({transactionInfo}: {transactionInfo: OneOfTransaction}): Promise<Order> => {
      return await OrderService.completeOrderPayment({
        id: orderId,
        completeOrderPaymentRequest: {transactionInfo},
      }).then(({order}) => order);
    },
    onSuccess: order => {
      queryClient.setQueryData(getOfferGroupOrderQueryKey(order.id), order);
    },
  });
};
