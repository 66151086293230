import React, {useEffect, useMemo, useRef, useState} from 'react';

import {QuestionStateSubjective} from '@santa-web/gen/open-api/content-learning-service';
import {Subjective} from '@santa-web/gen/ssp/messages/inside/(Question)';
import {DragButtonTab, Item} from '@santa-web/service-ui';
import useEventCallback from '@app/hooks/useEventCallback';

// 문장 뒤에 문장 부호가 나오는 경우 답을 유추할 수 있기 때문에 제거합니다.
// 컨텐츠에는 기본적으로 빠져있어야하나, 방어적으로 작성했습니다.
const REG_EXP_LAST_PUNCTUATION_MARK = /[.?!]$/;

export function normalize(sentence: string): string {
  return sentence.replace(REG_EXP_LAST_PUNCTUATION_MARK, '');
}

export function denormalize(sentence: string, originalSentence: string): string {
  let denormalized = sentence;
  const lastPunctuationMarkMatchResult = REG_EXP_LAST_PUNCTUATION_MARK.exec(originalSentence);
  if (lastPunctuationMarkMatchResult) {
    denormalized = denormalized + lastPunctuationMarkMatchResult[0];
  }
  if (denormalized === originalSentence) return denormalized;
  return sentence;
}

export function getItemIdsFromUserAnswer(items: Item[], userAnswer: string, allowedAnswer: string): string[] {
  const normalizedAnswer = userAnswer == allowedAnswer ? normalize(userAnswer) : userAnswer;
  const itemIds: string[] = [];
  const _items = [...items];
  for (const word of normalizedAnswer.split(' ')) {
    const item = _items.find(item => item.content === word);
    if (item == null) continue;
    _items.splice(_items.indexOf(item), 1);
    itemIds.push(item.id);
  }
  return itemIds;
}

export function getAnswerFromItemIds(items: Item[], itemIds: string[]): string {
  return itemIds.map(id => items.find(item => item.id === id)?.content ?? '').join(' ');
}

interface Props {
  isCompleted?: boolean;
  isDisabled?: boolean;
  subjective: Subjective;
  questionStateSubjective: QuestionStateSubjective | undefined;
  onChange?(objectiveState: Partial<QuestionStateSubjective>): void;
}

const SubjectiveView = (props: Props): JSX.Element => {
  const {isCompleted, isDisabled, subjective, questionStateSubjective, onChange} = props;
  const [selectedItemIds, setSelectedItemIds] = useState<string[]>([]);

  const questionStateSubjectiveRef = useRef(questionStateSubjective);
  questionStateSubjectiveRef.current = questionStateSubjective;

  // FIXME: Implement it more graceful

  // We used `subjective` for deps because different questions can have the same answer.
  const items = useMemo(() => {
    const allowedAnswer = subjective?.allowedAnswers?.[0] ?? '';
    const words = normalize(allowedAnswer).split(' ') ?? [];
    words.sort(() => 0.5 - Math.random());
    return (
      words?.map((content, id) => ({
        id: id.toString(),
        content,
      })) ?? []
    );
  }, [subjective]);

  const allowedAnswer = subjective?.allowedAnswers?.[0] ?? '';

  useEffect(() => {
    const userAnswer = questionStateSubjectiveRef.current?.userAnswer ?? '';
    setSelectedItemIds(getItemIdsFromUserAnswer(items, userAnswer, allowedAnswer));
  }, [allowedAnswer, items]);

  const handleChangeSelectedItemIds = useEventCallback(
    (selectedItemIds: string[]) => {
      const userAnswer = getAnswerFromItemIds(items, selectedItemIds);
      onChange?.({...questionStateSubjective, userAnswer: denormalize(userAnswer, allowedAnswer)});
      setSelectedItemIds(selectedItemIds);
    },
    [allowedAnswer, items]
  );

  return (
    <DragButtonTab
      selectedItemIds={selectedItemIds}
      items={items}
      isDisabled={isDisabled || isCompleted}
      onChangeSelectedItemIds={handleChangeSelectedItemIds}
    />
  );
};

export default React.memo(SubjectiveView);
