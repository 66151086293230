import React from 'react';

import {SingleQuestionTitle} from '@santa-web/service-ui';
import DirectionContent from '@app/components/virtual-exam/VirtualExamDirectionView/components/DirectionContent';
import DirectionWrapper from '@app/components/virtual-exam/VirtualExamDirectionView/components/DirectionWrapper';

const Part4DirectionView = React.forwardRef<HTMLDivElement, React.ComponentPropsWithoutRef<'div'>>((props, ref) => {
  return (
    <DirectionWrapper gap={24} {...props} ref={ref}>
      <SingleQuestionTitle content={'PART 4'} />
      <DirectionContent>
        <b>Directions:</b> You will hear some talks given by a single speaker. You will be asked to answer three
        questions about what the speaker says in each talk. Select the best response to each question and mark the
        letter (A), (B), (C), or (D) on your answer sheet. The talks will not be printed in your test book and will be
        spoken only one time.
      </DirectionContent>
    </DirectionWrapper>
  );
});

export default Part4DirectionView;
