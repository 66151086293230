import React from 'react';
import {useTranslation} from 'react-i18next';
import {AlertDialog} from '@santa-web/service-ui';

type ResetPasswordMailSentDialogProps = {
  onConfirm: () => void;
};

const ResetPasswordMailSentDialog = ({onConfirm}: ResetPasswordMailSentDialogProps) => {
  const {t} = useTranslation();
  return (
    <AlertDialog
      isVisible
      AlertProps={{
        title: t('forgot_password_mail_sent_dialog_title'),
        description: t('forgot_password_success_alert'),
        positiveLabel: t('dict_confirm'),
        onPositiveClick: onConfirm,
      }}
    />
  );
};

export default ResetPasswordMailSentDialog;
export type {ResetPasswordMailSentDialogProps};
ResetPasswordMailSentDialog.displayName = 'ResetPasswordMailSentDialog';
