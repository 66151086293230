import {useCallback} from 'react';

import {ContentInteractionStateWithContent} from '@santa-web/gen/open-api/content-learning-service';
import useFetchContent from '@app/hooks/content-v3/useFetchContent';
import useFetchResources from '@app/hooks/resource/useFetchResources';
import {IContentInteractionStateWithContent} from '@app/types/santa-service-protocol';

type AddContentToContentInteractionStateFn = (
  contentInteractionStateWithContent: ContentInteractionStateWithContent
) => Promise<IContentInteractionStateWithContent>;

const useAddContentToContentInteractionStateWithContentInfo = (): AddContentToContentInteractionStateFn => {
  const fetchContent = useFetchContent();
  const fetchResources = useFetchResources();

  return useCallback(
    async contentInteractionStateWithContent => {
      const {contentUrl} = contentInteractionStateWithContent;

      const content = contentUrl != null ? await fetchContent(contentUrl) : contentUrl;

      const resources = await fetchResources(content);

      return {
        ...contentInteractionStateWithContent,
        content,
        resources,
      };
    },
    [fetchContent, fetchResources]
  );
};

export default useAddContentToContentInteractionStateWithContentInfo;
