import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {BREAKPOINT_MEDIUM} from '@riiid/design-system';
import {BottomSheet, Button} from '@santa-web/service-ui';
import {DimWithEscape} from '@app/components';

type PassActivationBottomSheetProps = {
  passTitle: string;
  learningDomain: string;
  onStartClick: () => Promise<void>;
  onCloseClick(): void;
};

const PassActivationBottomSheet = ({
  passTitle,
  learningDomain,
  onStartClick,
  onCloseClick,
}: PassActivationBottomSheetProps) => {
  const {t} = useTranslation();
  const [isLoading, setIsLoading] = React.useState(false);
  return (
    <DimWithEscape
      css={css`
        justify-content: flex-start;
        @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
          justify-content: center;
        }
      `}
      onEscape={onCloseClick}>
      <BottomSheet
        isOpened
        title={t('pass_activation_bottom_sheet_title', {passName: passTitle})}
        description={t('pass_activation_bottom_sheet_description')}
        buttons={[
          <Button
            key="confirm"
            isFullWidth
            isLoading={isLoading}
            colorVariant="brand"
            onClick={() => {
              setIsLoading(true);
              onStartClick().finally(() => setIsLoading(false));
            }}>
            {t('pass_activation_bottom_sheet_button', {domain: learningDomain})}
          </Button>,
        ]}
        onCloseClick={onCloseClick}
      />
    </DimWithEscape>
  );
};

export default PassActivationBottomSheet;
export type {PassActivationBottomSheetProps};
PassActivationBottomSheet.displayName = 'PassActivationBottomSheet';
