import React from 'react';

import BottomToolList, {BottomToolListProps} from '@app/components/ContentView/components/BottomToolList';
import PlaybackRateBottomToolList, {
  PlaybackRateBottomToolListProps,
} from '@app/components/ContentView/components/PlaybackRateBottomToolList';

export type TestPrepBottomToolListProps = PlaybackRateBottomToolListProps | BottomToolListProps;

const isInstanceofPlaybackBottomToolListProps = (
  value: TestPrepBottomToolListProps
): value is PlaybackRateBottomToolListProps => {
  return 'currentPlaybackRate' in value;
};

const TestPrepBottomToolList = (props: TestPrepBottomToolListProps) => {
  return isInstanceofPlaybackBottomToolListProps(props) ? (
    <PlaybackRateBottomToolList {...props} />
  ) : (
    <BottomToolList {...props} />
  );
};

export default React.memo(TestPrepBottomToolList);
