/**
 * Check if a given object implements the StripeConfig interface.
 */
export function instanceOfStripeConfig(value) {
    let isInstance = true;
    isInstance = isInstance && "cancelPath" in value;
    isInstance = isInstance && "successPath" in value;
    return isInstance;
}
export function StripeConfigFromJSON(json) {
    return StripeConfigFromJSONTyped(json, false);
}
export function StripeConfigFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'cancelPath': json['cancelPath'],
        'successPath': json['successPath'],
    };
}
export function StripeConfigToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'cancelPath': value.cancelPath,
        'successPath': value.successPath,
    };
}
