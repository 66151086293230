import {URL_IMG_GUIDE, URL_IMG_SELF_LECTURE_08} from '@riiid/design-system/images/santa';
import {addDays, subDays} from 'date-fns';
import {Period} from '@santa-web/gen/open-api/service';
import {ImageSet} from '@app/components';
import {Offer} from './Offer';

// NOTE: API 구현 전에 임시로 사용할 OfferGroup 클래스
export class OfferGroup {
  id: number;
  paymentConfig: {
    method: 'IN_APP' | 'IMPORT' | 'STRIPE';
    type: 'ONE_TIME' | 'SUBSCRIPTION';
  };
  displayConfig: {
    title: string;
    description: string;
    displayPeriod: Period;
    detailImageSets: Array<ImageSet>;
    thumbnailBgImageSet: ImageSet;
    thumbnailContentImageSet: ImageSet;
    thumbnailContentImageSetAlign: 'left' | 'center';
    timerConfig?: {
      colorVariant: 'red' | 'black' | 'white';
    };
  };
  salesConfig: {
    salesPeriod: Period;
  };
  isPurchasable: boolean;
  offers: Array<Offer>;

  constructor(args: OfferGroup) {
    this.id = args.id;
    this.offers = args.offers;
    this.displayConfig = args.displayConfig;
    this.salesConfig = args.salesConfig;
    this.paymentConfig = args.paymentConfig;
    this.isPurchasable = args.isPurchasable;
  }

  static getBaseOfferGroup({
    id,
    offers,
    isDetailImageValid,
  }: {
    id: number;
    offers: Offer[];
    isDetailImageValid?: boolean;
  }) {
    const validDetailImageSet: ImageSet = {
      imageUrlX1: 'https://d3vw7lqegp8nrd.cloudfront.net/public/resource/c49dff15-8f48-4851-b975-ae8e7ac9c8e2',
      imageUrlX2: 'https://d3vw7lqegp8nrd.cloudfront.net/public/resource/c49dff15-8f48-4851-b975-ae8e7ac9c8e2',
      imageUrlX3: 'https://d3vw7lqegp8nrd.cloudfront.net/public/resource/c49dff15-8f48-4851-b975-ae8e7ac9c8e2',
    };
    const invalidDetailImageSet: ImageSet = {
      imageUrlX1:
        'https://dqr3a0hw4v0oz.cloudfront.net/resource/66fd486d-6ba2-4462-bb50-45bbfbc74ade?Expires=1703695304&Signature=QgO7j9XZbtMIQSQQKv47MEOzAb5z9Db4G49kesubX6Aj25JeH7vcZK2RaOo4JjVHdXjkITJYjAT5mH8bAcxcQCQzC38~p~7rUOkNfaB2pChBXGsnFvExadevdqDEtI7Zlf5id~-BUs57Zg4OQhrYvAhSpM07hU6i-GevN3fJfInffZdeU7z-xeMVeJvjHheHzIgCMEtCJo-r6BnpZxif1AtEpsAdNQcwAcTUP3oeBh2s95x1UQXgDZimyqALV8kRFrjxxBBoHCKagw~Lk7vNMNM87D29IO1mDtW0NlVKaq2mQ7u8O2ZjxPE7LR5b5toYjoyLEF11fdcwkqW5TgVM-w__&Key-Pair-Id=K2X45EYMN2GD99',
      imageUrlX2:
        'https://dqr3a0hw4v0oz.cloudfront.net/resource/66fd486d-6ba2-4462-bb50-45bbfbc74ade?Expires=1703695304&Signature=QgO7j9XZbtMIQSQQKv47MEOzAb5z9Db4G49kesubX6Aj25JeH7vcZK2RaOo4JjVHdXjkITJYjAT5mH8bAcxcQCQzC38~p~7rUOkNfaB2pChBXGsnFvExadevdqDEtI7Zlf5id~-BUs57Zg4OQhrYvAhSpM07hU6i-GevN3fJfInffZdeU7z-xeMVeJvjHheHzIgCMEtCJo-r6BnpZxif1AtEpsAdNQcwAcTUP3oeBh2s95x1UQXgDZimyqALV8kRFrjxxBBoHCKagw~Lk7vNMNM87D29IO1mDtW0NlVKaq2mQ7u8O2ZjxPE7LR5b5toYjoyLEF11fdcwkqW5TgVM-w__&Key-Pair-Id=K2X45EYMN2GD99',
      imageUrlX3:
        'https://dqr3a0hw4v0oz.cloudfront.net/resource/66fd486d-6ba2-4462-bb50-45bbfbc74ade?Expires=1703695304&Signature=QgO7j9XZbtMIQSQQKv47MEOzAb5z9Db4G49kesubX6Aj25JeH7vcZK2RaOo4JjVHdXjkITJYjAT5mH8bAcxcQCQzC38~p~7rUOkNfaB2pChBXGsnFvExadevdqDEtI7Zlf5id~-BUs57Zg4OQhrYvAhSpM07hU6i-GevN3fJfInffZdeU7z-xeMVeJvjHheHzIgCMEtCJo-r6BnpZxif1AtEpsAdNQcwAcTUP3oeBh2s95x1UQXgDZimyqALV8kRFrjxxBBoHCKagw~Lk7vNMNM87D29IO1mDtW0NlVKaq2mQ7u8O2ZjxPE7LR5b5toYjoyLEF11fdcwkqW5TgVM-w__&Key-Pair-Id=K2X45EYMN2GD99',
    };
    const content = URL_IMG_GUIDE;
    return new OfferGroup({
      id,
      isPurchasable: true,
      paymentConfig: {
        type: 'ONE_TIME',
        method: 'IMPORT',
      },
      displayConfig: {
        title: `Offer group title ${id}`,
        description: `This is offer group description for ${id}`,
        displayPeriod: {
          startInclusive: subDays(new Date(), 1),
          endExclusive: addDays(new Date(), 3),
        },
        detailImageSets: isDetailImageValid ? [validDetailImageSet] : [invalidDetailImageSet],
        thumbnailBgImageSet: {
          imageUrlX1: URL_IMG_SELF_LECTURE_08,
          imageUrlX2: URL_IMG_SELF_LECTURE_08,
          imageUrlX3: URL_IMG_SELF_LECTURE_08,
        },
        thumbnailContentImageSet: {
          imageUrlX1: content,
          imageUrlX2: content,
          imageUrlX3: content,
        },
        thumbnailContentImageSetAlign: 'left',
      },
      salesConfig: {
        salesPeriod: {
          startInclusive: subDays(new Date(), 1),
          endExclusive: addDays(new Date(), 3),
        },
      },
      offers,
    });
  }
}
