import {useRouter} from 'next/router';
import React from 'react';
// eslint-disable-next-line import/no-relative-parent-imports
import {redirectConfigs} from '../../../redirects';

const PATH_FRAGMENT = '/:path*';

const clientSideRedirectConfigs: {
  source: string;
  destination: string;
  shouldSourceMatchSubPath?: boolean;
  shouldKeepSubPath?: boolean;
}[] = (() =>
  redirectConfigs.map(config => {
    const shouldSourceMatchSubPath = config.source.includes(PATH_FRAGMENT);
    const shouldKeepSubPath = shouldSourceMatchSubPath && config.destination.includes(PATH_FRAGMENT);
    return {
      source: config.source.replace(PATH_FRAGMENT, ''),
      destination: config.destination.replace(PATH_FRAGMENT, ''),
      shouldSourceMatchSubPath,
      shouldKeepSubPath,
    };
  }))();

/**
 * NOTE: next.config.js에 설정한 redirects는 Next.js 서버를 통해 라우팅되는 경우에만 동작하기 때문에,
 * 클라이언트 사이드 라우팅(router.push, <Link /> 등)에서는 동작하지 않습니다.
 * 따라서 클라이언트 사이드 라우팅을 통해 페이지에 접근하는 경우 next.config.js에 설정한 redirects에 맞게 라우팅을 수행합니다.
 */
const RedirectionGuard = ({children}: {children?: React.ReactNode}) => {
  const router = useRouter();
  const redirectInfo = React.useMemo(
    () =>
      clientSideRedirectConfigs.find(({source, shouldSourceMatchSubPath}) =>
        shouldSourceMatchSubPath ? router.pathname.match(RegExp(`^${source}(/.*|$)`)) : router.pathname === source
      ),
    [router.pathname]
  );
  const shouldRedirect = redirectInfo !== undefined;

  React.useEffect(() => {
    if (redirectInfo) {
      router.replace({
        pathname: redirectInfo.shouldKeepSubPath
          ? `${redirectInfo.destination}${router.pathname.replace(redirectInfo.source, '')}`
          : redirectInfo.destination,
      });
    }
  }, [redirectInfo, router]);

  return shouldRedirect ? null : <>{children}</>;
};

export default RedirectionGuard;
