/* tslint:disable */
/* eslint-disable */
/**
 * - **IN_PROGRESS**: 연속 학습 달성 중
 * - **NOT_STARTED**: 완료한 CIS 학습 기록이 없음 (진단 제외)
 * - **FAILED**: 연속 학습 달성 실패 (어제 학습 하지 않음)
 * @export
 */
export const LearningStatus = {
    IN_PROGRESS: 'IN_PROGRESS',
    NOT_STARTED: 'NOT_STARTED',
    FAILED: 'FAILED'
};
export function LearningStatusFromJSON(json) {
    return LearningStatusFromJSONTyped(json, false);
}
export function LearningStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function LearningStatusToJSON(value) {
    return value;
}
