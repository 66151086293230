import 'rc-slider/assets/index.css';

import React from 'react';
import {css} from '@emotion/react';
import {
  COLOR_SANTA_M,
  COLOR_SANTA_D,
  COLOR_SANTA_F,
  COLOR_SANTA_G,
  COLOR_SANTA_BC,
  COLOR_SANTA_BE,
  SHADOW_A_2_DOWN,
  COLOR_SANTA_CARD_A,
} from '@riiid/design-system';
import Slider, {SliderProps} from 'rc-slider';

import {Typography} from '@santa-web/service-ui';
import {clamp} from '@app/utils/math';

export interface ScoreSliderProps extends SliderProps {
  value: number;
  onChange(arg: number): void;
  selectableMin?: number;
  selectableMax?: number;
  min: number;
  max: number;
  step: number;
  marks: Record<string, string | number>;
}

const ScoreSlider = React.forwardRef<HTMLDivElement, ScoreSliderProps>(
  ({selectableMin, selectableMax, onChange, marks, ...props}, ref) => {
    const typographyMarks = React.useMemo(
      () =>
        Object.fromEntries(
          Object.keys(marks).map((score, index) => {
            return [
              score,
              <Typography
                key={index}
                variant="caption1"
                color={COLOR_SANTA_M}
                css={
                  (selectableMin != null && parseInt(score) < selectableMin) ||
                  (selectableMax != null && parseInt(score) > selectableMax)
                    ? css`
                        opacity: 0.2;
                        cursor: not-allowed;
                      `
                    : css`
                        opacity: 0.5;
                        &:hover,
                        &:active {
                          opacity: 1;
                        }
                      `
                }>
                {marks[score]}
              </Typography>,
            ];
          })
        ),
      [marks, selectableMin, selectableMax]
    );

    const handleChange = React.useCallback(
      (value: number) => {
        return onChange(clamp(value, selectableMin, selectableMax));
      },
      [onChange, selectableMin, selectableMax]
    );

    return (
      <div
        css={css`
          padding: 20px 14px 40px;

          .rc-slider {
            margin: 0 auto;
          }

          .rc-slider,
          .rc-slider-disabled * {
            background: none;
          }

          .rc-slider-handle {
            width: 28px;
            height: 28px;

            border-radius: 20px;
            transform: translate(-50%, -50%) !important;
            /* library doesn't support adding transform style without !important notation */
            margin-top: 0;
            border: 2px solid ${COLOR_SANTA_CARD_A};
            background-color: ${COLOR_SANTA_BC};
            box-shadow: ${SHADOW_A_2_DOWN};

            &:hover,
            &:active {
              border: 2px solid ${COLOR_SANTA_CARD_A};
              background-color: ${COLOR_SANTA_BE};
            }
          }

          .rc-slider-disabled .rc-slider-handle {
            background-color: ${COLOR_SANTA_F};
            border-color: ${COLOR_SANTA_CARD_A};
            box-shadow: ${SHADOW_A_2_DOWN};
          }

          .rc-slider-mark {
            top: 22px;
          }

          .rc-slider-dot {
            background-color: ${COLOR_SANTA_G};
            height: 8px;
            bottom: 0;
            width: 1px;
            border: none;
            border-radius: 0;
            margin-left: 0;

            &:first-of-type,
            &:last-of-type {
              display: none;
            }
          }

          .rc-slider-step {
            height: 8px;
            transform: translateY(-50%);
          }

          .rc-slider-rail {
            background-color: ${COLOR_SANTA_D};
            height: 8px;
            transform: translateY(-50%);
          }

          .rc-slider-track {
            background-color: ${COLOR_SANTA_BC};
            height: 8px;
            transform: translateY(-50%);
          }

          .rc-slider-disabled .rc-slider-track {
            background-color: ${COLOR_SANTA_F};
          }

          .rc-slider-disabled .rc-slider-mark-text > * {
            opacity: 0.2;
          }
        `}
        ref={ref}>
        <Slider {...props} onChange={handleChange} marks={typographyMarks} />
      </div>
    );
  }
);

export default React.memo(ScoreSlider);
