import {Part, LabeledValue} from '@app/types/common';

export const PART_NUMBERS = [1, 2, 3, 4, 5, 6, 7] as const;

export const PARTS: Part[] = PART_NUMBERS.map(num => `PART_${num}` as const);

export const PARTS_SET = new Set(PARTS);

export const PART_ITEMS: LabeledValue<Part>[] = PART_NUMBERS.map(num => ({
  value: `PART_${num}` as const,
  label: `Part ${num}`,
}));

export const GTELP_SUBJECTS = {
  gr: 'gr',
  lc: 'lc',
  rc_voca: 'rc_voca',
} as const;
