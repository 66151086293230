import {LearningDomainKey} from '@santa-web/gen/open-api/service';
import {BrowserStorage} from '@santa-web/service-common';

/**
 * 도메인마다 다르게 유지되어야 하는 데이터를 위한 extended browser storage
 */
export default class BrowserStorageWithLearningDomain<O extends {[key: string]: any}> extends BrowserStorage<O> {
  constructor(storage: Storage, private learningDomainKey: LearningDomainKey) {
    super(storage);
  }

  private getKeyByDomain(key: keyof O) {
    return `${this.learningDomainKey}_${key}`;
  }

  getItem<K extends keyof O>(key: K): O[K] | undefined {
    return super.getItem(this.getKeyByDomain(key));
  }

  setItem<K extends keyof O>(key: K, item: O[K]) {
    super.setItem(this.getKeyByDomain(key), item);
  }

  removeItem(key: keyof O) {
    super.removeItem(this.getKeyByDomain(key));
  }

  hasItem(key: keyof O) {
    return this.getItem(this.getKeyByDomain(key)) != null;
  }

  popItem<K extends keyof O>(key: K): O[K] | undefined {
    const item = this.getItem<K>(key);
    this.removeItem(key);
    return item;
  }
}
