import {useRouter} from 'next/router';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';

import {COLOR_SANTA_D, COLOR_SANTA_G} from '@riiid/design-system';
import {Button, SinglePageLayout, SystemErrorLayout, Typography, useToastContext} from '@santa-web/service-ui';
import Column from '@app/components/Column';
import {use100vh} from '@app/hooks/use100vh';
import {SantaResponseError} from '@app/utils/error';

interface FallbackProps {
  error: Error;
  eventId: string;
  resetError: () => void;
}

const SentryErrorBoundaryFallback = ({error, eventId, resetError}: FallbackProps) => {
  const viewportHeight = use100vh();
  const router = useRouter();
  const {t} = useTranslation();
  const {openToast, toastContainer} = useToastContext();

  const errorInformation = React.useMemo(() => {
    const result = [];
    if (error instanceof SantaResponseError) {
      const characterizedStatusCode = error.response.status
        .toString()
        .split('')
        .map(numChar => String.fromCharCode('A'.charCodeAt(0) + Number(numChar)))
        .join('');
      result.push(`[RE: ${characterizedStatusCode}]`);
      result.push(`s.${eventId}`);
      if (error.traceId) {
        result.push(`t.${error.traceId}`);
      }
    } else {
      result.push('[CE]');
      result.push(`s.${eventId}`);
    }
    return result.join(`
`);
  }, [error, eventId]);

  const handleErrorButtonClick = React.useCallback(async () => {
    resetError();
    router.back();
  }, [resetError, router]);

  const button = (
    <Button variant="box-line" onClick={handleErrorButtonClick}>
      {t('error_page_service_error_button')}
    </Button>
  );

  return (
    <div
      css={css`
        width: 100%;
        height: ${viewportHeight};
      `}>
      <SinglePageLayout isError>
        <SystemErrorLayout
          title={t('error_page_service_error_title')}
          description={
            <Column alignItems="center" gap={40}>
              {t('error_page_service_error_description')}
              <Column
                as="button"
                css={css`
                  width: 100%;
                  padding: 20px 20px 40px;
                  border: none;
                  border-radius: 16px;
                  background-color: ${COLOR_SANTA_D};
                  white-space: pre-line;
                  text-align: center;
                `}
                onClick={() =>
                  navigator.clipboard
                    .writeText(errorInformation)
                    .then(() =>
                      openToast({
                        message: t('component_error_fallback_information_toast'),
                        colorVariant: 'gray',
                        autoHide: true,
                      })
                    )
                    .catch(() =>
                      openToast({
                        message: t('component_error_fallback_information_toast_failed'),
                        colorVariant: 'danger',
                        autoHide: true,
                      })
                    )
                }
                alignItems="center"
                gap={12}>
                <Typography component="span" fontWeight="bold" variant="body3" color={COLOR_SANTA_G}>
                  {t('component_error_fallback_information_title')}
                </Typography>
                <Typography component="p" variant="caption1" color={COLOR_SANTA_G}>
                  {errorInformation}
                </Typography>
                <Typography component="p" fontWeight="bold" variant="caption1" color={COLOR_SANTA_G}>
                  {t('component_error_fallback_information_description')}
                </Typography>
              </Column>
            </Column>
          }
          button={button}
        />
      </SinglePageLayout>
      {toastContainer}
    </div>
  );
};

export default SentryErrorBoundaryFallback;
