import {MouseEventHandler, useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {
  COLOR_SANTA_A,
  COLOR_SANTA_N,
  COLOR_SANTA_F,
  COLOR_SANTA_L,
  COLOR_SANTA_M,
  SHADOW_A_2_DOWN,
  BREAKPOINT_MEDIUM,
} from '@riiid/design-system';
import EyecloseIcon from '@riiid/design-system/icons/Eyeclose';
import EyeoepnIcon from '@riiid/design-system/icons/Eyeopen';

import {AutoCloseTooltip, Typography} from '@santa-web/service-ui';
import useShouldShowVocaTutorial from '@app/components/ContentView/hooks/useShouldShowVocaTutorial';

export type VocaFloatingButtonStatus = 'DISPLAYING_VOCA_ONLY' | 'DISPLAYING_VOCA_AND_EXPLANATION';

export interface VocaFloatingButtonProps {
  isDisabled?: boolean;
  status: VocaFloatingButtonStatus;
  onShowExplanationClick(): void;
  onHideExplanationClick(): void;
  onKnowledgeClick(): void;
  onUnKnowledgeClick(): void;
}

const COMMON_STYLES = css`
  box-shadow: ${SHADOW_A_2_DOWN};
  background-color: ${COLOR_SANTA_M};
  border: none;
  cursor: pointer;
  pointer-events: auto;

  @media (hover: hover) {
    :not(:disabled):hover {
      background-color: ${COLOR_SANTA_L};
    }
  }

  :not(:disabled):active {
    background-color: ${COLOR_SANTA_N};
  }

  :disabled {
    background-color: ${COLOR_SANTA_F};
  }
`;

const VocaFloatingButton = ({
  isDisabled,
  status,
  onShowExplanationClick,
  onHideExplanationClick,
  onKnowledgeClick,
  onUnKnowledgeClick,
}: VocaFloatingButtonProps) => {
  const {t} = useTranslation();

  const centerButtonComponentMap: Record<
    VocaFloatingButtonStatus,
    {component: React.ReactNode; onClick: MouseEventHandler}
  > = useMemo(
    () => ({
      DISPLAYING_VOCA_ONLY: {
        component: (
          <EyecloseIcon color={COLOR_SANTA_A} width={24} height={24} data-testid="VocaFloatingButton-EyeCloseIcon" />
        ),
        onClick: onShowExplanationClick,
      },
      DISPLAYING_VOCA_AND_EXPLANATION: {
        component: (
          <EyeoepnIcon color={COLOR_SANTA_A} width={24} height={24} data-testid="VocaFloatingButton-EyeOpenIcon" />
        ),
        onClick: onHideExplanationClick,
      },
    }),
    [onHideExplanationClick, onShowExplanationClick]
  );

  return (
    <div
      css={css`
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        padding-bottom: 20px;
        padding-top: 12px;

        & > * + * {
          margin-left: 16px;
        }

        @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
          padding-bottom: 40px;
        }
      `}>
      <NavigateButton
        onClick={onUnKnowledgeClick}
        disabled={isDisabled}
        data-testid="VocaFloatingButton-UnKnowledgeButton">
        {t('toeic_vocabulary_onedirection_unknowledge')}
      </NavigateButton>
      <CenterButton
        onClick={centerButtonComponentMap[status].onClick}
        disabled={isDisabled}
        data-testid="VocaFloatingButton-CenterButton">
        {centerButtonComponentMap[status].component}
      </CenterButton>
      <NavigateButton onClick={onKnowledgeClick} disabled={isDisabled} data-testid="VocaFloatingButton-KnowledgeButton">
        {t('toeic_vocabulary_onedirection_knowledge')}
      </NavigateButton>
    </div>
  );
};

const CenterButton = (props: React.ComponentPropsWithoutRef<'button'>) => {
  const {children, ...restProps} = props;

  return (
    <button
      css={[
        COMMON_STYLES,
        css`
          width: 48px;
          height: 48px;
          border-radius: 50%;
          display: flex;
          align-items: center;
          justify-content: center;
        `,
      ]}
      {...restProps}>
      {children}
    </button>
  );
};

const NavigateButton = (props: React.ComponentPropsWithoutRef<'button'>) => {
  const {children, ...restProps} = props;
  return (
    <button
      css={[
        COMMON_STYLES,
        css`
          padding: 10px 20px;
          border-radius: 24px;
        `,
      ]}
      {...restProps}>
      <Typography variant="body3" fontWeight="bold" color={COLOR_SANTA_A}>
        {children}
      </Typography>
    </button>
  );
};

const WithTutorialTooltip = (props: VocaFloatingButtonProps) => {
  const shouldShowTooltip = useShouldShowVocaTutorial('VOCA_ONE_DIRECTION_TUTORIAL_TOOLTIP_SHOWN');
  const {t} = useTranslation();

  return shouldShowTooltip ? (
    <AutoCloseTooltip
      delayMs={300}
      displayMs={2000}
      tooltipCardProps={{
        arrowAlign: 'center',
        arrowDirection: 'block-end',
        color: 'brand',
        size: 'medium',
        content: t('toeic_vocabulary_onedirection_guide_tooltip_check_meaning'),
      }}>
      <VocaFloatingButton {...props} />
    </AutoCloseTooltip>
  ) : (
    <VocaFloatingButton {...props} />
  );
};

export default WithTutorialTooltip;
