import {atom} from 'jotai';
import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';

const listResourcesAtom = atom(async get => {
  const {ContentResourceService} = await get(santaOpenapiServicesAtom);

  return async (resourceIds: Array<string>) =>
    await ContentResourceService.bulkGetContentResources({ids: resourceIds}).then(({results}) =>
      results.map(result => result.success!)
    );
});

export {listResourcesAtom};
