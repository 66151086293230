// NOTE: `react-div-100vh` package에서 추가적인 최적화를 위해 복사해옴.
// https://github.com/mvasin/react-div-100vh/blob/master/packages/react-div-100vh/src/index.tsx
import React from 'react';
import {getIsMobileOs, getUserAgent} from '@app/utils/user-agent';

const DEFAULT_100VH = '100vh';

// You have to use `use100vh` for considering top safe area of mobile, tablet browser.
export function use100vh(): string {
  const [height, setHeight] = React.useState<string>(measureHeight());

  const wasRenderedOnClientAtLeastOnce = useWasRenderedOnClientAtLeastOnce();

  React.useEffect(() => {
    if (!wasRenderedOnClientAtLeastOnce) return;

    function handleResize() {
      const measuredHeight = measureHeight();
      setHeight(measuredHeight);
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [wasRenderedOnClientAtLeastOnce]);
  return wasRenderedOnClientAtLeastOnce ? height : DEFAULT_100VH;
}

export function measureHeight(): string {
  // 최적화한 부분
  if (!getIsMobileOs(getUserAgent())) {
    return DEFAULT_100VH;
  }
  return `${window.innerHeight}px`;
}

function useWasRenderedOnClientAtLeastOnce() {
  const [wasRenderedOnClientAtLeastOnce, setWasRenderedOnClientAtLeastOnce] = React.useState(false);

  React.useEffect(() => {
    if (isClient()) {
      setWasRenderedOnClientAtLeastOnce(true);
    }
  }, []);
  return wasRenderedOnClientAtLeastOnce;
}

function isClient() {
  return typeof window !== 'undefined' && typeof document !== 'undefined';
}
