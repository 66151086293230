import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {COLOR_SANTA_I} from '@riiid/design-system';
import {Typography} from '@santa-web/service-ui';
import PreferenceListItem from '@app/features/preferences/components/PreferenceListItem';
import PreferencePageLayoutContainer from '@app/features/preferences/containers/PreferencePageLayoutContainer';
import {ColorScheme} from '@app/features/preferences/utils';

const MyThemeSettingPageContainer = () => {
  const {t} = useTranslation();
  const [currentColorScheme, setCurrentColorScheme] = React.useState(ColorScheme.getFromRootElement());

  const handleColorSchemeChange = (colorScheme?: 'dark' | 'light') => {
    ColorScheme.setToStorage(colorScheme);
    ColorScheme.setToRootElement(colorScheme);
    setCurrentColorScheme(colorScheme);
  };

  return (
    <PreferencePageLayoutContainer
      isNarrow
      upperArea={
        <div>
          <Typography
            css={css`
              margin-bottom: 40px;
            `}
            component="h1"
            variant="caption1"
            fontWeight="bold"
            color={COLOR_SANTA_I}>
            {t('page_setting_list_item_theme')}
          </Typography>
          <div
            css={css`
              display: grid;
              gap: 32px;
            `}>
            <PreferenceListItem
              title={{value: t('page_setting_theme_list_item_same_as_device'), fontWeight: 'bold'}}
              action={{type: 'check', value: currentColorScheme == null, onClick: () => handleColorSchemeChange()}}
            />
            <PreferenceListItem
              title={{value: t('page_setting_theme_list_item_light'), fontWeight: 'bold'}}
              action={{
                type: 'check',
                value: currentColorScheme === 'light',
                onClick: () => handleColorSchemeChange('light'),
              }}
            />
            <PreferenceListItem
              title={{value: t('page_setting_theme_list_item_dark'), fontWeight: 'bold'}}
              action={{
                type: 'check',
                value: currentColorScheme === 'dark',
                onClick: () => handleColorSchemeChange('dark'),
              }}
            />
          </div>
        </div>
      }
    />
  );
};

export default MyThemeSettingPageContainer;
MyThemeSettingPageContainer.displayName = 'MyThemeSettingPageContainer';
