import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {URL_GIF_AIBUTTON_DEFAULT} from '@riiid/design-system/lotties/santa';
import {BottomSheet, Button} from '@santa-web/service-ui';
import useOfferEvents from '@app/api/google-tag-manager/offer';
import {DimWithEscape} from '@app/components';
import {BREAKPOINT_MEDIUM} from '@app/constants/breakpoints';
import {useTypedRouter} from '@app/hooks/useTypedRouter';

type ProductSuggestionBottomSheetContainerProps = {
  onNotPurchaseButtonClick?: () => void;
  onClose: () => void;
};

const ProductSuggestionBottomSheetContainer = ({
  onNotPurchaseButtonClick,
  onClose,
}: ProductSuggestionBottomSheetContainerProps) => {
  const {t} = useTranslation();
  const router = useTypedRouter();
  const {pushClickStore} = useOfferEvents();

  const handleButtonClick = async () => {
    pushClickStore({referrer: 'unavailable_self_learning'});
    await router.push({pathname: '/offer-group/board', query: {referrer: 'unavailable_self_learning'}});
    onClose();
  };

  const handleClose = () => {
    if (onNotPurchaseButtonClick) onNotPurchaseButtonClick();
    onClose();
  };

  return (
    <DimWithEscape
      css={css`
        justify-content: flex-start;
        @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
          justify-content: center;
        }
      `}
      onEscape={onClose}>
      <BottomSheet
        isOpened
        isLoadingAiAnimationVisible
        lottieSrc={URL_GIF_AIBUTTON_DEFAULT}
        onCloseClick={onClose}
        title={t('component_product_suggestion_bottom_sheet_title')}
        description={t('component_product_suggestion_bottom_sheet_description')}
        buttons={[
          <Button key="goToProductBoard" colorVariant="brand" isFullWidth onClick={handleButtonClick}>
            {t('component_product_suggestion_bottom_sheet_positive_button')}
          </Button>,
          <Button key="cancel" colorVariant="grey" isFullWidth onClick={handleClose}>
            {t('component_product_suggestion_bottom_sheet_negative_button')}
          </Button>,
        ]}
      />
    </DimWithEscape>
  );
};

export default ProductSuggestionBottomSheetContainer;
export type {ProductSuggestionBottomSheetContainerProps};
ProductSuggestionBottomSheetContainer.displayName = 'ProductSuggestionBottomSheetContainer';
