import produce from 'immer';
import {atom} from 'jotai';

import {ContentInteractionState} from '@santa-web/gen/open-api/content-learning-service';
import {cisRepository} from '@app/test-preparation/atoms/repositories';
import {cisService} from '@app/test-preparation/atoms/services';
import {sequentialCaller} from '@app/test-preparation/atoms/services/cis/utils/sequential-caller';
import {handleUnexpectedError} from '@app/test-preparation/atoms/services/shared/errors/unexpected';

type UpdateCisParams = {
  cis: ContentInteractionState;
  shouldUpdateFirst?: boolean;
  isMutationOnly?: boolean;
};

const updateCisAtom = atom(null, async (get, set, {cis, isMutationOnly, shouldUpdateFirst}: UpdateCisParams) => {
  const updateCis = await get(cisRepository.updateCisAtom);

  const cises = get(cisService.cisesAtom);
  const elapsedTimeMap = get(cisService.elapsedTimeMapAtom);

  try {
    const elapsedTime = elapsedTimeMap[cis.id];

    const nextCis = produce(cis, draft => {
      draft.elementInteractionStates.forEach((eis, _, eises) => {
        eis.elapsedTimeMs = Math.ceil(elapsedTime / eises.length);
      });
    });
    const nextCises = produce(cises, draft => {
      const cisIndex = draft.findIndex(({id}) => id === cis.id);

      if (cisIndex === -1) {
        throw new Error('Cis not found');
      }

      draft[cisIndex] = nextCis;
    });

    if (!shouldUpdateFirst) {
      set(cisService.cisesAtom, nextCises);
    }

    if (!isMutationOnly) {
      await sequentialCaller.call(async () => {
        await updateCis(nextCis);
      });
    }

    if (shouldUpdateFirst) {
      set(cisService.cisesAtom, nextCises);
    }
  } catch (error) {
    handleUnexpectedError(error);
  }
});

export {updateCisAtom};
