import {useCallback, useEffect, useRef, useState} from 'react';

import {RiiidRichTextViewV2Selection, ITextNodePathsContext} from '@santa-web/service-ui';
import {RIIID_RICH_TEXT_V2_VIEW_ID} from '@santa-web/service-ui/src/components/RiiidRichTextV2View/const';
import useThrottle from '@app/hooks/useThrottle';

import SlideInteractionTouch from './slide-interaction-touch';
import {
  compareRiiidRichTextV2Point,
  findClosestBlockElement,
  findClosestScrollableElement,
  findTextSegmentByAdjustedPoint,
  getAllWordPositionsInView,
  RiiidRichTextViewV2WordPosition,
} from './utils';

const TOP_PADDING = 2;
const BOTTOM_PADDING = 10;
const SLIDE_THRESHOLD_Y = 56;
const WORD_TOUCH_THRESHOLD = 0.3;
const MOUSE_MOVE_EVENT_THROTTLE_MS = 20;

type SlideInteractionSnapshot = {
  selection: RiiidRichTextViewV2Selection;
  scroll: {
    element: Element;
    top: number;
  } | null;
  touch: {
    x: number;
    y: number;
  };
  wordPositionsInSameLine: RiiidRichTextViewV2WordPosition[];
};

const useSlideWords = (
  textNodePaths: ITextNodePathsContext,
  onSlideEnd: (slide: RiiidRichTextViewV2Selection & {text: string; movement: string}) => void
) => {
  // 슬라이드가 시작된 시점의 데이터 snapshot
  const startSlideInteractionSnapshot = useRef<SlideInteractionSnapshot | null>(null);

  const [currentSlide, setCurrentSlide] = useState<RiiidRichTextViewV2Selection | null>(null);

  const handleGetWordPositionsInSameLine = useCallback(
    (target: HTMLElement, touch: {x: number; y: number}) => {
      const targetTextSegment = findTextSegmentByAdjustedPoint(
        target,
        {
          x: touch.x,
          y: touch.y,
        },
        BOTTOM_PADDING
      );

      if (targetTextSegment === null) {
        return null;
      }

      const viewerId = targetTextSegment.parentElement?.getAttribute(RIIID_RICH_TEXT_V2_VIEW_ID) ?? null;
      if (viewerId === null || viewerId !== textNodePaths.getRiiidRichTextV2ViewId()) {
        return null;
      }

      const closestBlockElement = findClosestBlockElement(targetTextSegment);
      if (closestBlockElement === null) {
        return null;
      }

      const wordPositions = getAllWordPositionsInView(closestBlockElement, textNodePaths.getPath);

      return wordPositions.reduce(
        (prev, wordPosition) => {
          // `TOP_PADDING`, `BOTTOM_PADDING`으로 인해 두 라인의 wordPositions가 반환되지 않도록 하기 위해 첫번째 단어의 y좌표로 고정 (wordPositions가 없는 경우 방어로직을 위해 touch.y 좌표 사용)
          const isSameLine =
            prev.fixedLineY === null
              ? [...wordPosition.range.getClientRects()].some(rect => {
                  const isRectInSameLine =
                    rect.y - TOP_PADDING <= touch.y &&
                    touch.y <= rect.y + rect.height + BOTTOM_PADDING &&
                    rect.width > 0;

                  if (isRectInSameLine && prev.fixedLineY === null) {
                    prev.fixedLineY = rect.y;
                  }

                  return isRectInSameLine;
                })
              : [...wordPosition.range.getClientRects()].some(rect => {
                  return rect.y === prev.fixedLineY && rect.width > 0;
                });

          if (isSameLine) {
            prev.wordPositionsInSameLine.push(wordPosition);
          }

          return prev;
        },
        {
          fixedLineY: null as number | null,
          wordPositionsInSameLine: [] as RiiidRichTextViewV2WordPosition[],
        }
      ).wordPositionsInSameLine;
    },
    [textNodePaths]
  );

  const handleTouchStart = useCallback(
    (e: TouchEvent) => {
      const touch = new SlideInteractionTouch(e.touches);
      if (touch.isMultiTouchEvent()) {
        return;
      }

      startSlideInteractionSnapshot.current = null;

      const targetTextSegment = findTextSegmentByAdjustedPoint(
        e.target as HTMLElement,
        {
          x: touch.x,
          y: touch.y,
        },
        BOTTOM_PADDING
      );

      if (targetTextSegment === null) {
        return;
      }

      const viewerId = targetTextSegment.parentElement?.getAttribute(RIIID_RICH_TEXT_V2_VIEW_ID) ?? null;
      if (viewerId === null || viewerId !== textNodePaths.getRiiidRichTextV2ViewId()) {
        return;
      }

      const closestBlockElement = findClosestBlockElement(targetTextSegment);
      if (closestBlockElement === null) {
        return;
      }

      const wordPositions = getAllWordPositionsInView(closestBlockElement, textNodePaths.getPath);
      const wordPositionsInSameLine = handleGetWordPositionsInSameLine(e.target as HTMLElement, touch);
      if (wordPositionsInSameLine === null || wordPositionsInSameLine.length === 0) {
        return;
      }

      const targetWordPosition =
        wordPositions.find(wordPosition => {
          const rects = wordPosition.range.getClientRects();

          return [...rects].some(rect => {
            return (
              rect.x <= touch.x &&
              touch.x <= rect.x + rect.width &&
              rect.y - TOP_PADDING <= touch.y &&
              touch.y <= rect.y + rect.height + BOTTOM_PADDING
            );
          });
        }) ?? wordPositionsInSameLine[0];

      if (targetWordPosition.selection.anchor === null || targetWordPosition.selection.focus === null) {
        return;
      }

      const closestScrollableElement = findClosestScrollableElement(closestBlockElement);
      startSlideInteractionSnapshot.current = {
        // 단어의 가장 앞이 공백일 경우 해당 공백은 슬라이드에 포함하지 않음
        selection:
          targetWordPosition.word[0] === ' '
            ? {
                ...targetWordPosition.selection,
                anchor: {
                  ...targetWordPosition.selection.anchor,
                  offset: targetWordPosition.selection.anchor.offset + 1,
                },
              }
            : targetWordPosition.selection,
        scroll:
          closestScrollableElement !== null
            ? {
                element: closestScrollableElement,
                top: closestScrollableElement.scrollTop,
              }
            : null,
        touch: {
          x: touch.x,
          y: touch.y,
        },
        wordPositionsInSameLine,
      };
    },
    [handleGetWordPositionsInSameLine, textNodePaths]
  );

  const unThrottledHandleTouchMove = useCallback(
    (e: TouchEvent) => {
      const touch = new SlideInteractionTouch(e.changedTouches);

      if (touch.isMultiTouchEvent() || document.getSelection()?.type === 'Range') {
        setCurrentSlide(null);
        return;
      }

      if (
        startSlideInteractionSnapshot.current === null ||
        touch.isClickEvent({
          clientX: startSlideInteractionSnapshot.current.touch.x,
          clientY: startSlideInteractionSnapshot.current.touch.y,
        })
      ) {
        setCurrentSlide(null);
        return;
      }

      if (Math.abs(touch.y - startSlideInteractionSnapshot.current.touch.y) >= SLIDE_THRESHOLD_Y) {
        // Y좌표가 `MIN_Y_MOVEMENT_TO_IGNORE_SLIDE` 이상 움직일 경우 슬라이드 인터렉션 취소
        startSlideInteractionSnapshot.current = null;
        setCurrentSlide(null);
        return;
      }

      const direction = startSlideInteractionSnapshot.current.touch.x < touch.x ? 'right' : 'left';
      if (direction === 'left') {
        setCurrentSlide(null);
        return;
      }

      // 두라인에 걸쳐 있는 단어가 있을 수 있기에 하나의 라인에서만 있는 단어의 Y좌표를 기준으로 판단함
      const fixedLineY = startSlideInteractionSnapshot.current.wordPositionsInSameLine.reduce((prevY, wordPosition) => {
        const rects = [...wordPosition.range.getClientRects()];

        return rects.length === 1 ? rects[0].y : prevY;
      }, 0);

      const targetWordPosition = startSlideInteractionSnapshot.current.wordPositionsInSameLine.reduce(
        (prev, wordPosition) => {
          const rects = [...wordPosition.range.getClientRects()];

          const isOverThreshold = rects.some(rect => {
            if (rect.width <= 0 || rect.y !== fixedLineY) {
              return false;
            }

            return rect.x + rect.width * WORD_TOUCH_THRESHOLD < touch.x;
          });

          return isOverThreshold ? wordPosition : prev;
        },
        null as null | RiiidRichTextViewV2WordPosition
      );

      if (targetWordPosition === null) {
        return;
      }

      if (
        targetWordPosition.selection.anchor === null ||
        targetWordPosition.selection.focus === null ||
        startSlideInteractionSnapshot.current.selection.anchor === null ||
        startSlideInteractionSnapshot.current.selection.focus === null
      ) {
        return;
      }

      setCurrentSlide({
        anchor: startSlideInteractionSnapshot.current.selection.anchor ?? null,
        focus: targetWordPosition.selection.focus,
      });

      const scrollMovementAppliedStartTouchPoint = {
        x: touch.x,
        y:
          startSlideInteractionSnapshot.current.scroll !== null
            ? startSlideInteractionSnapshot.current.touch.y +
              startSlideInteractionSnapshot.current.scroll.top -
              startSlideInteractionSnapshot.current.scroll.element.scrollTop
            : startSlideInteractionSnapshot.current.touch.y,
      };

      // react 18 적용 이후로 currentSlide가 DOM에 반영되기 전에 실행되어 wordPositionsInSameLine이 제대로 반영되지 않는 이슈가 있어 setTimeout으로 강제로 렌더링 이후에 실행되도록 함
      setTimeout(() => {
        if (startSlideInteractionSnapshot.current) {
          startSlideInteractionSnapshot.current.wordPositionsInSameLine =
            handleGetWordPositionsInSameLine(e.target as HTMLElement, scrollMovementAppliedStartTouchPoint) ?? [];
        }
      }, MOUSE_MOVE_EVENT_THROTTLE_MS);
    },
    [handleGetWordPositionsInSameLine]
  );

  const handleTouchMove = useThrottle(unThrottledHandleTouchMove, MOUSE_MOVE_EVENT_THROTTLE_MS);

  const handleTouchEnd = useCallback(
    (e: TouchEvent) => {
      const touch = new SlideInteractionTouch(e.changedTouches);

      if (currentSlide !== null) {
        const text =
          startSlideInteractionSnapshot.current?.wordPositionsInSameLine
            .filter(wordPosition => {
              if (
                currentSlide.anchor === null ||
                currentSlide.focus === null ||
                wordPosition.selection.anchor === null ||
                wordPosition.selection.focus === null
              ) {
                return false;
              }

              return (
                compareRiiidRichTextV2Point(wordPosition.selection.anchor, currentSlide.anchor) >= 0 &&
                compareRiiidRichTextV2Point(wordPosition.selection.focus, currentSlide.focus) <= 0
              );
            })
            .map(wordPosition => wordPosition.word)
            .join('') ?? '';

        const xDiff = touch.x - (startSlideInteractionSnapshot.current?.touch.x ?? 0);
        const yDiff = touch.y - (startSlideInteractionSnapshot.current?.touch.y ?? 0);

        onSlideEnd({
          ...currentSlide,
          text,
          // GA를 위한 데이터이므로 string 타입으로 반환합니다
          movement: `(${xDiff},${yDiff})`,
        });
        setCurrentSlide(null);
      }
    },
    [currentSlide, onSlideEnd]
  );

  useEffect(() => {
    document.addEventListener('touchstart', handleTouchStart);
    document.addEventListener('touchmove', handleTouchMove);
    document.addEventListener('touchend', handleTouchEnd);

    return () => {
      document.removeEventListener('touchstart', handleTouchStart);
      document.removeEventListener('touchmove', handleTouchMove);
      document.removeEventListener('touchend', handleTouchEnd);
    };
  }, [handleTouchEnd, handleTouchMove, handleTouchStart]);

  return currentSlide;
};

export default useSlideWords;
