import {useMutation, useQueryClient} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';
import {Order} from '@santa-web/gen/open-api/service';
import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {getOfferGroupOrderQueryKey} from '@app/queryKeys';

export const useOfferGroupOrderApplyCouponsMutation = (orderId: number) => {
  const {OrderService} = useAtomValue(santaOpenapiServicesAtom);
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (couponIds: number[]): Promise<Order> => {
      return await OrderService.configureCoupon({id: orderId, configureCouponRequest: {couponIds}}).then(
        ({order}) => order
      );
    },
    onSuccess: order => {
      queryClient.setQueryData(getOfferGroupOrderQueryKey(order.id), order);
    },
  });
};
