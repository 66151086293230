import {atom} from 'jotai';

import {Content} from '@santa-web/gen/open-api/content/models/Content';
import {setAtomLabels} from '@app/misc/atom-label';
import {learningCellService, cisService} from '@app/test-preparation/atoms/services';
import {CisesOverflowError} from '@app/test-preparation/atoms/services/cis/errors/cises-overflow';
import {LearningCellAlreadyDoneError} from '@app/test-preparation/atoms/services/learning-cell/errors/learning-cell-already-done';
import {PermissionDeniedError} from '@app/test-preparation/atoms/services/shared/errors/permission-denied';
import {learningStore} from '@app/test-preparation/atoms/stores';

type InitializeLearningParams = {
  cellId?: number;
  cisId?: number;
  content?: Content;
  onPermissionDenied?(): void;
  onCellDone?(cellId: number): Promise<void>;
};

const isInitialized = atom(false);
const initializeAtom = atom(
  null,
  async (get, set, {cellId, cisId, content, onPermissionDenied, onCellDone}: InitializeLearningParams) => {
    try {
      set(learningStore.isInitialized, false);

      if (cellId) {
        await set(learningCellService.initializeAtom, {learningCellId: cellId});
        await set(cisService.initializeAtom, {learningCellId: cellId});
      }

      if (cisId) {
        await set(cisService.initializeAtom, {cisId});
      }

      if (content) {
        await set(cisService.initializeAtom, {content});
      }

      set(learningStore.isInitialized, true);
    } catch (error) {
      if (error instanceof PermissionDeniedError && onPermissionDenied) {
        return onPermissionDenied();
      }

      if (error instanceof LearningCellAlreadyDoneError && onCellDone && cellId) {
        return onCellDone(cellId);
      }

      if (error instanceof CisesOverflowError && onCellDone && cellId) {
        await set(learningStore.doneCellAtom, {shouldOptimisticUpdate: false});
        return onCellDone(cellId);
      }

      throw error;
    }
  }
);

export {isInitialized, initializeAtom};

setAtomLabels(
  {
    isInitialized,
  },
  'learningStore.'
);
