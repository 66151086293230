import {useQuery} from '@tanstack/react-query';
import {mobileService} from '@app/api/app-bridge/mobile-service';
import {getAppInfoQueryKey} from '@app/queryKeys';
import {isAppBridgeAvailable} from '@app/utils/app-bridge';

export const useGetMobileAppInfoQuery = () => {
  return useQuery(getAppInfoQueryKey(), async () => {
    if (isAppBridgeAvailable()) {
      return await mobileService.getAppInfo();
    }
    return null;
  });
};
