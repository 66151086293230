/* tslint:disable */
/* eslint-disable */
/**
 * - **PENDING**: 학습대기
 * - **ACTIVE**: 이용중
 * - **EXPIRED**: 기간만료
 * - **REFUNDED**: 환불완료
 * @export
 */
export const SinglePurchasePassStatus = {
    PENDING: 'PENDING',
    ACTIVE: 'ACTIVE',
    EXPIRED: 'EXPIRED',
    REFUNDED: 'REFUNDED'
};
export function SinglePurchasePassStatusFromJSON(json) {
    return SinglePurchasePassStatusFromJSONTyped(json, false);
}
export function SinglePurchasePassStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function SinglePurchasePassStatusToJSON(value) {
    return value;
}
