import React from 'react';
import {css} from '@emotion/react';
import {ImageSet, ResponsiveImage} from '@app/components';

type OfferGroupDisplayImageOwnProps = {
  backgroundImageSet: ImageSet;
  contentImageSet: ImageSet;
  contentImageSetAlign: 'LEFT' | 'CENTER';
  children?: React.ReactNode;
};

type OfferGroupDisplayImageProps = OfferGroupDisplayImageOwnProps & React.HTMLAttributes<HTMLDivElement>;

const OfferGroupDisplayImage = ({
  backgroundImageSet,
  contentImageSet,
  contentImageSetAlign,
  children,
  ...rest
}: OfferGroupDisplayImageProps) => {
  return (
    <div
      css={css`
        position: relative;
        height: 180px;
      `}
      {...rest}>
      <ResponsiveImage
        imageSet={backgroundImageSet}
        css={css`
          width: 100%;
          height: 100%;
          object-fit: cover;
        `}
      />
      <ResponsiveImage
        imageSet={contentImageSet}
        css={[
          css`
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
          `,
          contentImageSetAlign === 'CENTER' &&
            css`
              transform: translateX(-50%);
              left: 50%;
            `,
        ]}
      />
      {children}
    </div>
  );
};

export default OfferGroupDisplayImage;
export type {OfferGroupDisplayImageProps};
OfferGroupDisplayImage.displayName = 'OfferGroupDisplayImage';
