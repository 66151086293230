/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { OfferGroupTimerConfigFromJSON, OfferGroupTimerConfigToJSON, } from './OfferGroupTimerConfig';
import { PeriodFromJSON, PeriodToJSON, } from './Period';
/**
 * Check if a given object implements the OfferGroupSalesConfig interface.
 */
export function instanceOfOfferGroupSalesConfig(value) {
    let isInstance = true;
    isInstance = isInstance && "salesPeriod" in value;
    return isInstance;
}
export function OfferGroupSalesConfigFromJSON(json) {
    return OfferGroupSalesConfigFromJSONTyped(json, false);
}
export function OfferGroupSalesConfigFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'salesPeriod': PeriodFromJSON(json['salesPeriod']),
        'timerConfig': !exists(json, 'timerConfig') ? undefined : OfferGroupTimerConfigFromJSON(json['timerConfig']),
    };
}
export function OfferGroupSalesConfigToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'salesPeriod': PeriodToJSON(value.salesPeriod),
        'timerConfig': OfferGroupTimerConfigToJSON(value.timerConfig),
    };
}
