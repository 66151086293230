import {atom} from 'jotai';

import {cisRepository} from '@app/test-preparation/atoms/repositories';
import {cisService} from '@app/test-preparation/atoms/services';

const completeCisesAtom = atom(null, async get => {
  const completeCises = await get(cisRepository.completeCisesAtom);

  const learningCellId = get(cisService.learningCellIdAtom);

  await completeCises(learningCellId);
});

export {completeCisesAtom};
