import React from 'react';
import {useTranslation} from 'react-i18next';

export default function useCommonValidator() {
  const {t} = useTranslation();
  const validator = React.useMemo(
    () =>
      ({
        required: (value: number | string | boolean | null | undefined) => {
          if (!value && value !== 0) {
            return t('common_validation_required_field');
          }
        },
        minLength(min: number, msg: string) {
          return function (text: string | undefined) {
            if ((text?.length ?? 0) < min) {
              return msg;
            }
          };
        },
        maxLength(max: number, msg: string) {
          return function (text: string | undefined) {
            if ((text?.length ?? 0) > max) {
              return msg;
            }
          };
        },
      } as const),
    [t]
  );

  return validator;
}
