import React from 'react';
import {css} from '@emotion/react';
import {COLOR_SANTA_G, COLOR_SANTA_C} from '@riiid/design-system';

import {Typography} from '@santa-web/service-ui';
import LoadingGrayLogoAnimation from '@app/components/LoadingGrayLogoAnimation';

export interface LoadingScreenProps {
  title: string;
  description: string;
}

const LoadingScreen = ({title, description, ...props}: LoadingScreenProps): JSX.Element => {
  return (
    <div
      css={css`
        background: ${COLOR_SANTA_C};
        padding: 0 20px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      `}
      {...props}>
      <LoadingGrayLogoAnimation width={60} height={60} />
      <Typography
        variant="body1"
        fontWeight="bold"
        color={COLOR_SANTA_G}
        css={css`
          margin-top: 16px;
          margin-bottom: 12px;
        `}>
        {title}
      </Typography>
      <Typography
        variant="caption1"
        fontWeight="bold"
        color={COLOR_SANTA_G}
        css={css`
          text-align: center;
          white-space: pre-line;
        `}>
        {description}
      </Typography>
    </div>
  );
};

export default React.memo(LoadingScreen);
