import React from 'react';
import TestPrepLayout, {TestPrepLayoutProps} from '@app/test-preparation/components/TestPrepLayout';
import {TOEIC_PART_5_VIEW_MAX_WIDTH} from '@app/test-preparation/constants/test-preparation-layout';

export type ToeicPart5ViewLayoutProps = TestPrepLayoutProps;

const ToeicPart5ViewLayout = ({children, ...rest}: ToeicPart5ViewLayoutProps) => {
  return (
    <TestPrepLayout maxWidth={TOEIC_PART_5_VIEW_MAX_WIDTH} {...rest}>
      {children}
    </TestPrepLayout>
  );
};

export default React.memo(ToeicPart5ViewLayout);
