import React from 'react';
import {Field, FieldProps, FieldRenderProps} from 'react-final-form';

import Dropdown, {DropdownProps} from '@app/components/Dropdown';

export type DropdownFieldProps<T extends string | number> = DropdownProps<T> & FieldProps<T, FieldRenderProps<T>>;

function DropdownComboboxField<T extends string | number = string>({
  name,
  validate,
  validateFields = [],
  ...dropdownProps
}: DropdownFieldProps<T>) {
  return (
    <Field<T> name={name} validate={validate} validateFields={validateFields}>
      {({input}) => {
        const {onChange, type, ...restInputProps} = input;
        return <Dropdown<T> onValueChanged={onChange} {...restInputProps} {...dropdownProps} value={input.value} />;
      }}
    </Field>
  );
}

export default React.memo(DropdownComboboxField) as typeof DropdownComboboxField;
