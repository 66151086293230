/* tslint:disable */
/* eslint-disable */
/**
 * `startContentInteractionState` 요청의 `FORBIDDEN` 응답 발생 원인으로 가능한 경우.
 *
 * - **NOT_OWNER_OF_CONTENT_INTERACTION_STATE**: 대상 `ContentInteractionState`의 소유자가 아님
 * - **NO_AUTHORITY**: 대상 `ContentInteractionState`를 시작 처리할 권한이 없음
 * @export
 */
export const StartContentInteractionStateForbiddenErrorCase = {
    NOT_OWNER_OF_CONTENT_INTERACTION_STATE: 'NOT_OWNER_OF_CONTENT_INTERACTION_STATE',
    NO_AUTHORITY: 'NO_AUTHORITY'
};
export function StartContentInteractionStateForbiddenErrorCaseFromJSON(json) {
    return StartContentInteractionStateForbiddenErrorCaseFromJSONTyped(json, false);
}
export function StartContentInteractionStateForbiddenErrorCaseFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function StartContentInteractionStateForbiddenErrorCaseToJSON(value) {
    return value;
}
