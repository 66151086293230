import produce from 'immer';
import cloneDeep from 'lodash.clonedeep';
import {ContentInteractionState, VocaStateSelfReport} from '@santa-web/gen/open-api/content-learning-service';

export const isValidResult = (selfReportsArray: VocaStateSelfReport[][]): boolean => {
  const stepCount = selfReportsArray[0].length;
  for (let currentStep = 0; currentStep < stepCount; currentStep += 1) {
    const currentStepSelfReports = selfReportsArray.map(selfReports => selfReports[currentStep]);
    const isLastStep = currentStep === stepCount - 1;
    if (!isLastStep) {
      if (
        currentStep === 0 &&
        currentStepSelfReports.some(
          selfReport => selfReport.vocaOngoingStep !== 'STEP1' || selfReport.isKnowledge === undefined
        )
      ) {
        return false;
      }
      if (currentStepSelfReports.every(selfReport => selfReport.isKnowledge === undefined)) {
        return false;
      }
    }
  }
  return true;
};

const calibrateVocabularyCises = (cises: Array<ContentInteractionState>) => {
  const calibratedSelfReports = fillVocabularySelfReport(cises.map(getSelfReports));
  return produce(cises, draft => {
    draft.forEach((cis, index) => {
      if (cis.elementInteractionStates[0].details.voca) {
        cis.elementInteractionStates[0].details.voca.selfReports = calibratedSelfReports[index];
      }
    });
  });
};

const getSelfReports = (cis: ContentInteractionState) => {
  return cis.elementInteractionStates[0].details.voca?.selfReports ?? [];
};

const MAX_LOOP_COUNT = 1000;

/**
 * selfReports간의 위계를 맞춰 MxN 행렬로 만들어 줍니다.
 * NOTE: 테스트를 위해 export되었습니다.
 */
const fillVocabularySelfReport = (_selfReportsArray: VocaStateSelfReport[][]): VocaStateSelfReport[][] => {
  const selfReportsArray = cloneDeep(_selfReportsArray);
  const isEverySelfReportsEmpty = selfReportsArray.every(selfReports => selfReports.length === 0);
  if (isEverySelfReportsEmpty) {
    return produce(selfReportsArray, draft => {
      draft.forEach(selfReports =>
        selfReports.push({
          isKnowledge: undefined,
          vocaOngoingStep: 'STEP1',
        })
      );
    });
  }

  const isSolvingStep1 =
    selfReportsArray.some(selfReports => selfReports.length === 0) &&
    selfReportsArray.every(selfReports => selfReports.length < 2);
  if (isSolvingStep1) {
    const res = produce(selfReportsArray, draft => {
      draft.forEach(selfReports => {
        if (selfReports.length === 0) {
          selfReports.push({
            isKnowledge: undefined,
            vocaOngoingStep: 'STEP1',
          });
        }
      });
    });

    return res;
  }

  const result: VocaStateSelfReport[][] = selfReportsArray.map(selfReports => {
    const selfReport = selfReports.shift();
    if (!selfReport) {
      throw new VocabularySelfReportsCorruptedError('In step 2, every self reports should have at least one report');
    }
    return [
      {
        isKnowledge: selfReport.isKnowledge,
        vocaOngoingStep: selfReport.vocaOngoingStep,
      },
    ];
  });

  const isEveryLeftSelfReportsEmpty = () => selfReportsArray.every(selfReports => selfReports.length === 0);

  for (let loopCount = 0; !isEveryLeftSelfReportsEmpty(); loopCount += 1) {
    if (loopCount > MAX_LOOP_COUNT) {
      throw new VocabularySelfReportsCorruptedError('Max loop count exceeded');
    }

    const isLastSessionVocabularyKnowledge = (selfReports: VocaStateSelfReport[]) => {
      const filteredSelfReports = selfReports.filter(selfReport => selfReport.isKnowledge !== undefined);
      return filteredSelfReports[filteredSelfReports.length - 1]?.isKnowledge;
    };
    const isEveryLastSessionVocabularyKnowledge = result.every(isLastSessionVocabularyKnowledge);

    if (isEveryLastSessionVocabularyKnowledge) {
      result.forEach((selfReports, idx) => {
        const nextSelfReport = selfReportsArray[idx].shift();
        if (nextSelfReport === undefined) {
          selfReports.push({
            isKnowledge: undefined,
            vocaOngoingStep: 'STEP2',
          });
        } else {
          selfReports.push(nextSelfReport);
        }
      });
    } else {
      result.forEach((selfReports, idx) => {
        if (isLastSessionVocabularyKnowledge(selfReports)) {
          selfReports.push({
            isKnowledge: undefined,
            vocaOngoingStep: 'STEP2',
          });
        } else {
          const nextSelfReport = selfReportsArray[idx].shift();
          selfReports.push(
            nextSelfReport ?? {
              isKnowledge: undefined,
              vocaOngoingStep: 'STEP2',
            }
          );
        }
      });
    }
  }

  if (!isValidResult(result)) {
    throw new VocabularySelfReportsCorruptedError('Calibrated vocabulary self reports are invalid');
  }

  return result;
};

class VocabularySelfReportsCorruptedError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'VocaSelfReportsCorruptedError';
  }
}

export {calibrateVocabularyCises, fillVocabularySelfReport, VocabularySelfReportsCorruptedError};
