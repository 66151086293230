import React from 'react';
import {css} from '@emotion/react';

import ButtonTextWithCheckbox from '@app/components/ButtonTextWithCheckbox';
import {LabeledValue} from '@app/types/common';

interface Props<T> extends Omit<React.ComponentPropsWithoutRef<'div'>, 'onChange'> {
  isColumnDirection?: boolean;
  checkAllText?: string;
  values: T[];
  allLabeledValues: LabeledValue<T>[];
  onChange: (values: T[]) => void;
}

function CheckboxGroup<T>({
  isColumnDirection = false,
  checkAllText,
  values,
  allLabeledValues,
  onChange,
  ...divProps
}: Props<T>) {
  const isAllChecked = values.length >= allLabeledValues.length;

  const allValues = React.useMemo(() => {
    return allLabeledValues.map(({value}) => value);
  }, [allLabeledValues]);

  const handleCheckAll = React.useCallback(() => {
    if (isAllChecked) {
      onChange([]);
    } else {
      onChange(allValues);
    }
  }, [onChange, allValues, isAllChecked]);

  const createHandleItemClick = React.useCallback(
    (value: T) => () => {
      if (values.includes(value)) {
        return onChange(values.filter(v => v !== value));
      } else {
        return onChange([...values, value]);
      }
    },
    [values, onChange]
  );

  const checkboxes = React.useMemo(() => {
    return allLabeledValues.map(({label, value}) => {
      return (
        <ButtonTextWithCheckbox key={label} checked={values.includes(value)} onClick={createHandleItemClick(value)}>
          {label}
        </ButtonTextWithCheckbox>
      );
    });
  }, [allLabeledValues, values, createHandleItemClick]);

  return (
    <div {...divProps}>
      {checkAllText != null ? (
        <ButtonTextWithCheckbox
          checked={isAllChecked}
          onClick={handleCheckAll}
          css={css`
            display: flex;
            margin-bottom: 24px;
          `}>
          {checkAllText}
        </ButtonTextWithCheckbox>
      ) : null}
      <div
        css={css`
          display: grid;
          ${!isColumnDirection && 'grid-template-columns: 1fr 1fr'};
          grid-row-gap: 12px;
        `}>
        {checkboxes}
      </div>
    </div>
  );
}

export default React.memo(CheckboxGroup) as typeof CheckboxGroup;
