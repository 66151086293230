import React from 'react';
import {useTranslation} from 'react-i18next';

import {BottomSheetListSwitchToggleItem, BottomSheetListWithDim} from '@app/components';

import BottomSheetListRangeInputItem from '@app/components/BottomSheetListRangeInputItem';
import {useTestprepConfigContext} from '@app/test-preparation/contexts/TestprepConfigContext';
import {ITestprepConfigSwitchableItem} from '@app/test-preparation/contexts/TestprepConfigContext/switchable';
import {SwitchableAction} from '@app/types/user-config/testprep-config/switchable';

export interface TestprepConfigBottomSheetListProps {
  isOpened: boolean;
  onClickClose(): void;
}

const TestprepConfigBottomSheetList = ({isOpened, onClickClose}: TestprepConfigBottomSheetListProps): JSX.Element => {
  const {t} = useTranslation();

  const {switchable, fontSize} = useTestprepConfigContext();

  const getSwitchToggleItemProps = React.useCallback(
    ({switchableAction, isEnabled}: ITestprepConfigSwitchableItem) => {
      const commonProps = {
        id: switchableAction,
        isChecked: isEnabled,
        onChange: (event: React.ChangeEvent<HTMLInputElement>) => {
          switchable.handleSwitchableChange(switchableAction, event.target.checked);
        },
      };

      const specificProps: Record<SwitchableAction, {title: string; subText: string}> = {
        HIGHLIGHT: {
          title: t('testprep_config_switchable_highlight_title'),
          subText: t('testprep_config_switchable_highlight_sub_text'),
        },
        UNSOLVABLE_QUESTION: {
          title: t('testprep_config_switchable_unsolvable_question_title'),
          subText: t('testprep_config_switchable_unsolvable_question_sub_text'),
        },
      };

      return {
        ...commonProps,
        ...specificProps[switchableAction],
      };
    },
    [switchable, t]
  );

  return (
    <BottomSheetListWithDim
      isOpened={isOpened}
      BottomSheetListProps={{
        children: (
          <>
            {switchable.switchableItems.map(switchableItem => (
              <BottomSheetListSwitchToggleItem
                key={switchableItem.switchableAction}
                {...getSwitchToggleItemProps(switchableItem)}
              />
            ))}
            {fontSize && (
              <BottomSheetListRangeInputItem
                title={t('testprep_config_switchable_font_size_adjust_title')}
                subText={t('testprep_config_switchable_font_size_adjust_sub_text')}
                rangeInputProps={fontSize.rangeInputProps}
              />
            )}
          </>
        ),
        onCloseClick: onClickClose,
      }}
    />
  );
};

export default React.memo(TestprepConfigBottomSheetList);
