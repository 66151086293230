import {Decimal} from 'decimal.js';

import {ContentInteractionState} from '@santa-web/gen/open-api/content-learning-service';
import {
  CISUpdatableProperties,
  ContentInteractionState as LegacyContentInteractionState,
  LessonState,
  QuestionState,
} from '@santa-web/gen/ssp/messages/inside/contentlearning';
import {createValue as createCISUpdatablePropertiesValue} from '@santa-web/gen/ssp/messages/inside/contentlearning/CISUpdatableProperties';
import {createValue as createQuestionSetStateValue} from '@santa-web/gen/ssp/messages/inside/contentlearning/QuestionSetState';
import * as QuestionSetStateFacade from '@app/facade/question-set-state';

export const getStatesByCase = (contentInteractionState: ContentInteractionState | null | undefined) => {
  if (
    contentInteractionState?.elementInteractionStates == null ||
    contentInteractionState.elementInteractionStates.length === 0
  ) {
    return {
      oneOfCase: 'UNSPECIFIED',
      states: [],
    } as const;
  }

  const {elementInteractionStates} = contentInteractionState;

  switch (contentInteractionState.elementInteractionStates[0].details.oneOfCase) {
    case 'QUESTION':
      return {
        oneOfCase: 'QUESTION',
        states: elementInteractionStates.map(eis => eis.details.question!),
      } as const;

    case 'LESSON':
      return {
        oneOfCase: 'LESSON',
        states: elementInteractionStates.map(eis => eis.details.lesson!),
      } as const;

    case 'VOCA':
      return {
        oneOfCase: 'VOCA',
        states: elementInteractionStates.map(eis => eis.details.voca!),
      } as const;
  }
};

export const getElapsedTimeMs = (contentInteractionState: ContentInteractionState | null | undefined): Decimal => {
  return (
    contentInteractionState?.elementInteractionStates
      .map(({elapsedTimeMs}) => elapsedTimeMs)
      .reduce((sum, curr) => sum.add(curr), new Decimal(0)) ?? new Decimal(0)
  );
};

export const getIsCompleted = (contentInteractionState: ContentInteractionState | null | undefined): boolean => {
  return contentInteractionState?.completedAtServer != null;
};

export const getIsAnyContentsCompleted = (
  contentInteractionStates: ContentInteractionState[] | null | undefined
): boolean => {
  return Boolean(contentInteractionStates?.some(contentInteractionState => getIsCompleted(contentInteractionState)));
};

export const getQuestionState = (
  contentInteractionState: LegacyContentInteractionState | null | undefined,
  index: number
): QuestionState | null | undefined => {
  const questionSetState =
    contentInteractionState?.state?.field === 'questionSetState' ? contentInteractionState.state.value : null;
  return QuestionSetStateFacade.getQuestionState(questionSetState, index);
};

export const getAccumulatedNumberOfQuestions = (
  contentInteractionStates: ContentInteractionState[] | null | undefined
): number =>
  (contentInteractionStates ?? [])
    .map(contentInteractionState => {
      const {oneOfCase, states} = getStatesByCase(contentInteractionState);
      if (oneOfCase === 'QUESTION') {
        return states.length;
      }

      return 0;
    })
    .reduce((sum, x) => sum + x, 0);

export function applyUpdatableProperties(
  contentInteractionState: LegacyContentInteractionState | null | undefined,
  properties: CISUpdatableProperties | null | undefined
) {
  if (contentInteractionState == null || properties == null) return;

  if (properties.questionStates.length !== 0) {
    const existingQuestionSetState =
      contentInteractionState.state?.field === 'questionSetState' ? contentInteractionState.state.value : null;
    contentInteractionState.state = {
      field: 'questionSetState',
      value: createQuestionSetStateValue({
        ...existingQuestionSetState,
        questionStates: properties.questionStates,
        isCompleted: properties.questionStates.every(questionState => questionState.isCompleted),
        isCorrect: properties.questionStates.every(questionState => questionState.isCorrect),
      }),
    };
  }

  if (properties.lessonStates.length !== 0) {
    contentInteractionState.state = {
      field: 'lessonState',
      value: properties.lessonStates[0],
    };
  }

  if (properties.vocaStates.length !== 0) {
    contentInteractionState.state = {
      field: 'vocaState',
      value: properties.vocaStates[0],
    };
  }

  if (properties.startedAt) {
    contentInteractionState.startedAt = properties.startedAt;
  }

  if (properties.completedAt) {
    contentInteractionState.completedAt = properties.completedAt;
  }

  if (properties.isBookmarked !== undefined) {
    contentInteractionState.isBookmarked = properties.isBookmarked;
  }
}

export function toUpdatableProperties(
  contentInteractionState: LegacyContentInteractionState | null | undefined
): CISUpdatableProperties {
  const result: CISUpdatableProperties = createCISUpdatablePropertiesValue({
    startedAt: contentInteractionState?.startedAt,
    completedAt: contentInteractionState?.completedAt,
    isBookmarked: contentInteractionState?.isBookmarked,
  });
  switch (contentInteractionState?.state?.field) {
    case 'questionSetState':
      result.questionStates = contentInteractionState.state.value.questionStates;
      break;
    case 'lessonState':
      result.lessonStates = [contentInteractionState.state.value];
      break;
    case 'vocaState':
      result.vocaStates = [contentInteractionState.state.value];
  }

  return result;
}

export const getLessonState = (
  contentInteractionState: LegacyContentInteractionState | null | undefined
): LessonState | null | undefined => {
  if (contentInteractionState?.state?.field === 'lessonSetState') {
    return contentInteractionState.state.value.lessonStates[0];
  }
  if (contentInteractionState?.state?.field === 'lessonState') {
    return contentInteractionState.state.value;
  }
  return undefined;
};

export const getNumberOfCompletedContents = (
  contentInteractionStates: ContentInteractionState[] | null | undefined
): number => {
  return (
    contentInteractionStates?.filter(contentInteractionState => getIsCompleted(contentInteractionState)).length ?? 0
  );
};
