import { LearningGoalFromJSON, LearningGoalToJSON, } from './LearningGoal';
/**
 * Check if a given object implements the CreateLearningGoalResponse interface.
 */
export function instanceOfCreateLearningGoalResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "learningGoal" in value;
    return isInstance;
}
export function CreateLearningGoalResponseFromJSON(json) {
    return CreateLearningGoalResponseFromJSONTyped(json, false);
}
export function CreateLearningGoalResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'learningGoal': LearningGoalFromJSON(json['learningGoal']),
    };
}
export function CreateLearningGoalResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'learningGoal': LearningGoalToJSON(value.learningGoal),
    };
}
