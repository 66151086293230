import {useMutation, UseMutationOptions} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';
import {Coupon} from '@santa-web/gen/open-api/service';
import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';

const useCouponRegisterMutation = (options?: UseMutationOptions<Coupon, unknown, string, unknown>) => {
  const {CouponService} = useAtomValue(santaOpenapiServicesAtom);
  const mutateFunction = (couponCode: string) => CouponService.registerCoupon({registerCouponRequest: {couponCode}});

  return useMutation({
    mutationFn: mutateFunction,
    ...options,
  });
};

export default useCouponRegisterMutation;
