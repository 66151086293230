import {Content} from '@santa-web/gen/open-api/content/models/Content';
import {toeicRecommendMsSet} from '@app/constants/recommendTime';
import {ContentRouter} from '@app/test-preparation/atoms/stores/learning/utils/content-router';
import {hasIntersection} from '@app/utils/array';

class ToeicContentRouter implements ContentRouter {
  constructor(private content: Content) {}

  getMeta<Name extends string>(name: Name): Name extends `${string}List` ? Array<string> : string {
    return (this.content.oneOfDomainContent.toeicContent as any)[name] ?? (name.endsWith('List') ? [] : '');
  }
  getQuestionSet() {
    return this.content.oneOfDomainContent.toeicContent?.oneOfType.questionSet ?? null;
  }
  getLesson() {
    return this.content.oneOfDomainContent.toeicContent?.oneOfType.lesson ?? null;
  }
  getVocabulary() {
    return this.content.oneOfDomainContent.toeicContent?.oneOfType.vocabulary ?? null;
  }
  getRecommendedElapsedTime() {
    if (this.isBasicQuestion()) {
      return 30 * 1000;
    }

    const parts = this.content.oneOfDomainContent.toeicContent?.partList ?? [];

    for (const part of parts) {
      const time = toeicRecommendMsSet[part];
      if (time != null) {
        const numberOfQuestions =
          this.content.oneOfDomainContent.toeicContent?.oneOfType.questionSet?.questions.length ?? 0;
        return time * numberOfQuestions;
      }
    }

    return null;
  }
  getGuessElapsedTime() {
    const partList = this.content.oneOfDomainContent.toeicContent?.partList ?? [];

    if (hasIntersection(partList, ['p3', 'p4', 'p6', 'p7'])) {
      return 30 * 1000;
    }

    return 3 * 1000;
  }
  isLC() {
    return this.content.oneOfDomainContent.toeicContent?.subject === 'lc';
  }
  isBasicQuestion() {
    return ['sentence_completion', 'basic_training', 'basic_grammar', 'basic_voca'].includes(
      this.content.oneOfDomainContent.toeicContent?.dalc ?? ''
    );
  }
}

export {ToeicContentRouter};
