/**
 * Check if a given object implements the WeaknessBlock interface.
 */
export function instanceOfWeaknessBlock(value) {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "isDecreased" in value;
    isInstance = isInstance && "leadingText" in value;
    isInstance = isInstance && "subtitle" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "weaknessRatio" in value;
    return isInstance;
}
export function WeaknessBlockFromJSON(json) {
    return WeaknessBlockFromJSONTyped(json, false);
}
export function WeaknessBlockFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'isDecreased': json['isDecreased'],
        'leadingText': json['leadingText'],
        'subtitle': json['subtitle'],
        'title': json['title'],
        'weaknessRatio': json['weaknessRatio'],
    };
}
export function WeaknessBlockToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'isDecreased': value.isDecreased,
        'leadingText': value.leadingText,
        'subtitle': value.subtitle,
        'title': value.title,
        'weaknessRatio': value.weaknessRatio,
    };
}
