/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { ExamScoreConfigFromJSON, } from '../models';
/**
 *
 */
export class ExamScoreConfigV1Api extends runtime.BaseAPI {
    /**
     * 시험 점수 설정 정보를 조회합니다.
     */
    async getExamScoreConfigRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/learning-domain/v1/exam-score-config`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ExamScoreConfigFromJSON(jsonValue));
    }
    /**
     * 시험 점수 설정 정보를 조회합니다.
     */
    async getExamScoreConfig(requestParameters, initOverrides) {
        const response = await this.getExamScoreConfigRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
