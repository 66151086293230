import {atom} from 'jotai';
import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';

const createCisesAtom = atom(async get => {
  const {ContentInteractionStateService} = await get(santaOpenapiServicesAtom);

  return async (learningCellId: number) =>
    await ContentInteractionStateService.createContentInteractionStates({
      learningCellId,
    }).then(({created}) => created);
});

export {createCisesAtom};
