import { DisplayInfoV2FromJSON, DisplayInfoV2ToJSON, } from './DisplayInfoV2';
import { TicketListItemV2FromJSON, TicketListItemV2ToJSON, } from './TicketListItemV2';
import { TicketPermitTypeFromJSON, TicketPermitTypeToJSON, } from './TicketPermitType';
import { TicketStatusFromJSON, TicketStatusToJSON, } from './TicketStatus';
/**
 * Check if a given object implements the OrderTicketGroup interface.
 */
export function instanceOfOrderTicketGroup(value) {
    let isInstance = true;
    isInstance = isInstance && "displayInfo" in value;
    isInstance = isInstance && "expiredAt" in value;
    isInstance = isInstance && "learningDomainDisplayName" in value;
    isInstance = isInstance && "permitType" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "tickets" in value;
    return isInstance;
}
export function OrderTicketGroupFromJSON(json) {
    return OrderTicketGroupFromJSONTyped(json, false);
}
export function OrderTicketGroupFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'displayInfo': DisplayInfoV2FromJSON(json['displayInfo']),
        'expiredAt': (new Date(json['expiredAt'])),
        'learningDomainDisplayName': json['learningDomainDisplayName'],
        'permitType': TicketPermitTypeFromJSON(json['permitType']),
        'status': TicketStatusFromJSON(json['status']),
        'tickets': (json['tickets'].map(TicketListItemV2FromJSON)),
    };
}
export function OrderTicketGroupToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'displayInfo': DisplayInfoV2ToJSON(value.displayInfo),
        'expiredAt': (value.expiredAt.toISOString()),
        'learningDomainDisplayName': value.learningDomainDisplayName,
        'permitType': TicketPermitTypeToJSON(value.permitType),
        'status': TicketStatusToJSON(value.status),
        'tickets': (value.tickets.map(TicketListItemV2ToJSON)),
    };
}
