function _instanceof(left, right) {
    if (right != null && typeof Symbol !== "undefined" && right[Symbol.hasInstance]) {
        return !!right[Symbol.hasInstance](left);
    } else {
        return left instanceof right;
    }
}
export var getRectOfLines = function(element) {
    var width = element.offsetWidth;
    var rectOfLines = [];
    var prevTop = null;
    var _element_children;
    var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
    try {
        for(var _iterator = ((_element_children = element.children) !== null && _element_children !== void 0 ? _element_children : [])[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
            var child = _step.value;
            if (!_instanceof(child, HTMLElement)) continue;
            var top = child.offsetTop;
            var height = child.offsetHeight;
            if (prevTop == null || top > prevTop) {
                rectOfLines.push({
                    top: top,
                    right: width,
                    bottom: top + height,
                    left: 0,
                    width: width,
                    height: height,
                    x: 0,
                    y: top,
                    toJSON: function() {
                        return {};
                    }
                });
            }
            prevTop = top;
        }
    } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
    } finally{
        try {
            if (!_iteratorNormalCompletion && _iterator.return != null) {
                _iterator.return();
            }
        } finally{
            if (_didIteratorError) {
                throw _iteratorError;
            }
        }
    }
    return rectOfLines;
};
