import React from 'react';
import {css} from '@emotion/react';
import {COLOR_SANTA_D, COLOR_SANTA_E, COLOR_SANTA_I, COLOR_SANTA_J} from '@riiid/design-system';
import ReportErrorIcon from '@riiid/design-system/icons/ReportError';
import {Typography} from '@santa-web/service-ui';
import {Column, Row} from '@app/components';

type ProductOrderBaseInfoOwnProps = {
  description: string;
  button?: React.ReactNode;
};

type ProductOrderBaseInfoProps = ProductOrderBaseInfoOwnProps & React.HTMLAttributes<HTMLDivElement>;

const ProductOrderBaseInfo = ({description, button, ...props}: ProductOrderBaseInfoProps) => {
  return (
    <Row
      as="section"
      gap={12}
      alignItems="center"
      css={css`
        width: 100%;
        padding: 12px;

        background-color: ${COLOR_SANTA_D};
        border: 1px solid ${COLOR_SANTA_E};
        border-radius: 4px;
      `}
      {...props}>
      <ReportErrorIcon color={COLOR_SANTA_J} width={28} height={28} type="line" />
      <Column gap={4}>
        <Typography component="p" variant="caption1" color={COLOR_SANTA_I}>
          {description}
        </Typography>
        {button && button}
      </Column>
    </Row>
  );
};

export default ProductOrderBaseInfo;
export type {ProductOrderBaseInfoProps};
ProductOrderBaseInfo.displayName = 'ProductOrderBaseInfo';
