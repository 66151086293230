import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {URL_GIF_ALERTIMAGE_TUTORIAL_DRAGNDROP} from '@riiid/design-system/lotties/santa';

import {AlertProps, AlertDialog, AlertDialogProps, LottieAnimation} from '@santa-web/service-ui';
import {useDialogContext} from '@app/contexts/DialogContext';

export type SentenceCompletionTutorialAlertDialogProps = AlertDialogProps;

export default React.memo<SentenceCompletionTutorialAlertDialogProps>(
  React.forwardRef<HTMLDivElement, SentenceCompletionTutorialAlertDialogProps>(
    function SentenceCompletionTutorialAlertDialog({...props}, ref) {
      const {t} = useTranslation();

      const {closeDialog} = useDialogContext();

      const AlertProps = useMemo(
        (): AlertProps => ({
          image: <LottieAnimation src={URL_GIF_ALERTIMAGE_TUTORIAL_DRAGNDROP} />,
          title: t('tutorial_sentence_completion_title'),
          description: t('tutorial_sentence_completion_description'),
          neutralLabel: t('tutorial_sentence_completion_neutral_label'),
          onNeutralClick: closeDialog,
          css: css`
            white-space: pre-line;
          `,
        }),
        [t, closeDialog]
      );

      return <AlertDialog ref={ref} AlertProps={AlertProps} isVisible {...props} />;
    }
  )
);
