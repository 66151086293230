import React from 'react';
import {useTranslation} from 'react-i18next';
import {Label, LabelColorVariant} from '@santa-web/service-ui';

type OfferGroupOrderLabelOneTimeVariant = 'pending' | 'purchased' | 'expired' | 'refunded' | 'failed';
type OfferGroupOrderLabelSubscriptionVariant =
  | 'subscription'
  | 'subscription-in-use'
  | 'subscription-canceled'
  | 'subscription-expired'
  | 'subscription-failed';

type OfferGroupOrderLabelProps = {
  variant: OfferGroupOrderLabelOneTimeVariant | OfferGroupOrderLabelSubscriptionVariant;
};

const OfferGroupOrderLabel = ({variant}: OfferGroupOrderLabelProps) => {
  const {t} = useTranslation();
  const {colorVariant, value} = React.useMemo((): {colorVariant: LabelColorVariant; value: string} => {
    switch (variant) {
      case 'pending':
        return {colorVariant: 'secondary-purple', value: t('component_offer_group_order_card_label_pending')};
      case 'purchased':
        return {colorVariant: 'brand-dark', value: t('component_offer_group_order_card_label_purchased')};
      case 'expired':
        return {colorVariant: 'dark', value: t('component_offer_group_order_card_label_expired')};
      case 'refunded':
        return {colorVariant: 'danger-dark', value: t('component_offer_group_order_card_label_refunded')};
      case 'failed':
        return {colorVariant: 'danger-dark', value: t('component_offer_group_order_card_label_failed')};
      case 'subscription':
        return {colorVariant: 'secondary-green', value: t('component_offer_group_order_card_label_subscription')};
      case 'subscription-in-use':
        return {colorVariant: 'brand-dark', value: t('component_offer_group_order_card_label_subscription_in_use')};
      case 'subscription-canceled':
        return {colorVariant: 'dark', value: t('component_offer_group_order_card_label_subscription_canceled')};
      case 'subscription-expired':
        return {colorVariant: 'dark', value: t('component_offer_group_order_card_label_subscription_expired')};
      case 'subscription-failed':
        return {colorVariant: 'danger-dark', value: t('component_offer_group_order_card_label_subscription_failed')};
    }
  }, [t, variant]);
  return (
    <Label colorVariant={colorVariant} variant="small">
      {value}
    </Label>
  );
};

export default OfferGroupOrderLabel;
export type {OfferGroupOrderLabelProps};
OfferGroupOrderLabel.displayName = 'OfferGroupOrderLabel';
