export type CouponListTabValue = 'AVAILABLE' | 'PAST';

export type CouponStatus = 'REGISTERED' | 'USED' | 'EXPIRED' | 'REVOKED';

export type AdvancedCouponStatus =
  | 'PENDING' // 사용대기 => couponStatus === 'REGISTERED' && CouponType === 'DISCOUNT' && (현재일자 < 쿠폰사용 시작일)
  | 'AVAILABLE' // 사용가능 => couponStatus === 'REGISTERED' && CouponType === 'DISCOUNT' && (현재일자 > 쿠폰사용 시작일) && (현재일자 < 쿠폰사용 종료일)
  | 'REGISTERED_AND_READY'; // 등록완료 => couponStatus === 'REGISTERED' && CouponType === 'PERMIT'

export enum CouponType {
  DISCOUNT = 'DISCOUNT',
  PERMIT = 'PERMIT',
}

export enum CouponDiscountMethod {
  AMOUNT = 'AMOUNT',
  RATE = 'RATE',
}

type CouponBase = {
  couponType: CouponType;
  status: CouponStatus;
  displayName: string;
};

export type DiscountCoupon = CouponBase & {
  couponType: CouponType.DISCOUNT;
  discountAmountWithUnit: string; // 뒤에 currency 혹은 %가 붙은 할인금액(율) 텍스트
  minimumPriceToApplyWithCurrency: string;
  maximumDiscountAmountWithCurrency: string;
  from: Date;
  to: Date;
};

export type PermissionCoupon = CouponBase & {
  couponType: CouponType.PERMIT;
  includes: string[];
};

export type Coupon = DiscountCoupon | PermissionCoupon;
