import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {
  COLOR_FIX_SANTA_GRAY_8,
  COLOR_FIX_SANTA_WHITE_1,
  COLOR_FIX_SANTA_WHITE_1_ALPHA,
  COLOR_FIX_SANTA_WHITE_3,
} from '@riiid/design-system';
import InfoIcon from '@riiid/design-system/icons/Info';

import {TooltipBox} from '@santa-web/service-ui';

interface PosImageComponentProps {
  imageUrl: string;
  onLoad(): void;
  shouldShowImage: boolean;
  alt: string;
  sendGaEvent?(): Promise<void>;
}

const PosImageComponent = ({
  imageUrl,
  onLoad: addCount,
  shouldShowImage,
  alt,
  sendGaEvent,
}: PosImageComponentProps): JSX.Element => {
  const {t} = useTranslation();
  return (
    <div
      css={css`
        display: flex;
        position: relative;
        justify-content: center;
        width: 100%;
        margin-bottom: 20px;
        background-color: ${shouldShowImage ? COLOR_FIX_SANTA_GRAY_8 : COLOR_FIX_SANTA_WHITE_3};
        border-radius: 8px;
        overflow: hidden;
        @media screen and (min-width: 430px) {
          max-height: 280px;
        }
      `}>
      <img
        src={imageUrl}
        onLoad={() => addCount()}
        alt={alt}
        css={css`
          width: ${(5 / 9) * 100}vw;
          height: ${(5 / 9) * 100}vw;
          visibility: ${shouldShowImage ? 'visible' : 'hidden'};
          @media screen and (min-width: 430px) {
            height: 280px;
            width: 280px;
          }
        `}
      />
      <TooltipBox
        isDisabledClick={false}
        title={t('toeic_vocabulary_pos_ai_image_information')}
        direction="bottom-right"
        size="medium"
        variant="black"
        TooltipBoxCardProps={{style: {marginTop: '4px'}}}
        css={css`
          display: flex;
          justify-content: flex-end;
          flex-grow: 1;
          position: absolute;
          top: 12px;
          right: 12px;
        `}>
        <div
          onClick={() => sendGaEvent && sendGaEvent()}
          css={css`
            display: flex;
            justify-content: center;
            width: 28px;
            height: 28px;
            border-radius: 50%;
            background-color: ${COLOR_FIX_SANTA_WHITE_1_ALPHA(0.2)};
          `}>
          <InfoIcon
            type="line"
            width={15}
            height={15}
            color={COLOR_FIX_SANTA_WHITE_1}
            css={css`
              margin: auto;
            `}
          />
        </div>
      </TooltipBox>
    </div>
  );
};
export default React.memo(PosImageComponent);
