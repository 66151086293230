import { ContentResourceFromJSON, ContentResourceToJSON, } from './ContentResource';
import { ToeicVirtualExamAudioDirectionMappingsFromJSON, ToeicVirtualExamAudioDirectionMappingsToJSON, } from './ToeicVirtualExamAudioDirectionMappings';
/**
 * Check if a given object implements the ToeicVirtualExamContentMetadata interface.
 */
export function instanceOfToeicVirtualExamContentMetadata(value) {
    let isInstance = true;
    isInstance = isInstance && "audioDirectionMappings" in value;
    isInstance = isInstance && "audioListeningMappings" in value;
    isInstance = isInstance && "audioResource" in value;
    isInstance = isInstance && "questionContentUrls" in value;
    return isInstance;
}
export function ToeicVirtualExamContentMetadataFromJSON(json) {
    return ToeicVirtualExamContentMetadataFromJSONTyped(json, false);
}
export function ToeicVirtualExamContentMetadataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'audioDirectionMappings': ToeicVirtualExamAudioDirectionMappingsFromJSON(json['audioDirectionMappings']),
        'audioListeningMappings': json['audioListeningMappings'],
        'audioResource': ContentResourceFromJSON(json['audioResource']),
        'questionContentUrls': json['questionContentUrls'],
    };
}
export function ToeicVirtualExamContentMetadataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'audioDirectionMappings': ToeicVirtualExamAudioDirectionMappingsToJSON(value.audioDirectionMappings),
        'audioListeningMappings': value.audioListeningMappings,
        'audioResource': ContentResourceToJSON(value.audioResource),
        'questionContentUrls': value.questionContentUrls,
    };
}
