import React from 'react';
import {useTranslation} from 'react-i18next';

import {useAtomValue} from 'jotai';
import {TopNavBar, TopNavBarProps} from '@santa-web/service-ui';
import {learningStore} from '@app/test-preparation/atoms/stores';

export type ToeicSelfVocabularyTopNavBarProps = TopNavBarProps;

function ToeicSelfVocabularyTopNavBar(props: ToeicSelfVocabularyTopNavBarProps): React.ReactElement | null {
  const {...restProps} = props;

  const {t} = useTranslation();

  const currentCisIndex = useAtomValue(learningStore.cises.currentCisIndexAtom);
  const questionCisCount = useAtomValue(learningStore.cises.questionCisCountAtom);
  const vocabularyCisCount = useAtomValue(learningStore.cises.vocabularyCisCountAtom);
  const totalElapsedTime = useAtomValue(learningStore.cises.totalElapsedTimeAtom);

  const isQuestion = useAtomValue(learningStore.cis.isQuestionAtom);

  const currentSessionCisIndex = useAtomValue(learningStore.vocabularySession.currentSessionCisIndexAtom);
  const currentSessionCisCount = useAtomValue(learningStore.vocabularySession.currentSessionCisCountAtom);

  const totalCisCount = isQuestion ? questionCisCount : currentSessionCisCount;
  const currentIndex = isQuestion ? currentCisIndex - vocabularyCisCount : currentSessionCisIndex;

  const content = t('learning_cell_top_nav_bar_self_vocabulary_content_vocabulary', {
    currentWordIndex: currentIndex + 1,
    numberOfWords: totalCisCount,
  });
  const progress = (currentIndex + 1) / totalCisCount;
  const timerSeconds = totalElapsedTime / 1000;

  return (
    <TopNavBar content={content} paginationIcon="exit" timerSeconds={timerSeconds} progress={progress} {...restProps} />
  );
}

export default React.memo(ToeicSelfVocabularyTopNavBar);
