import React from 'react';
import {useTranslation} from 'react-i18next';
import {format} from 'date-fns';
import {Button} from '@santa-web/service-ui';
import {BasePermitCard, BasePermitCardProps} from '@app/features/permit/components';

type TicketCardProps = Omit<BasePermitCardProps, 'status' | 'isSubscription' | 'button' | 'listItems'> & {
  status: Exclude<BasePermitCardProps['status'], 'pending' | 'refund-requested' | 'renew-failed'>;
  expireAt: Date;
  countInfo?: {
    remained: number;
    total: number;
  };
  type: 'course-learning' | 'file-download' | 'gift-code' | 'information-input';
  onButtonClick?: () => Promise<void>;
};

type ButtonLabelRecord = Record<TicketCardProps['status'], string | null>;

const TicketCard = ({expireAt, countInfo, onButtonClick, type, ...basePermitCardProps}: TicketCardProps) => {
  const {t} = useTranslation();
  const [isLoading, setIsLoading] = React.useState(false);
  const {status} = basePermitCardProps;
  const isAvailable = status === 'in-use';
  const buttonLabel = React.useMemo(() => {
    switch (type) {
      case 'course-learning': {
        const courseButtonLabels: ButtonLabelRecord = {
          'in-use': t('component_ticket_card_button_course_learning_available'),
          used: t('component_ticket_card_button_course_learning_used'),
          expired: t('component_ticket_card_button_course_learning_expired'),
          refunded: t('component_ticket_card_button_course_learning_refunded'),
        };
        return courseButtonLabels[status];
      }
      case 'file-download': {
        const courseButtonLabels: ButtonLabelRecord = {
          'in-use': t('component_ticket_card_button_downloadable_available'),
          used: null,
          expired: t('component_ticket_card_button_downloadable_expired'),
          refunded: t('component_ticket_card_button_downloadable_refunded'),
        };
        return courseButtonLabels[status];
      }
      case 'gift-code': {
        const courseButtonLabels: ButtonLabelRecord = {
          'in-use': t('component_ticket_card_button_gift_code_available'),
          used: null,
          expired: t('component_ticket_card_button_gift_code_expired'),
          refunded: t('component_ticket_card_button_gift_code_refunded'),
        };
        return courseButtonLabels[status];
      }
      case 'information-input': {
        const courseButtonLabels: ButtonLabelRecord = {
          'in-use': t('component_ticket_card_button_external_link_available'),
          used: null,
          expired: t('component_ticket_card_button_external_link_expired'),
          refunded: t('component_ticket_card_button_external_link_refunded'),
        };
        return courseButtonLabels[status];
      }
    }
  }, [status, type, t]);

  const listItems = React.useMemo(() => {
    const result: BasePermitCardProps['listItems'] = [
      {
        title: t('component_pass_card_date_label_expire_at'),
        value: format(expireAt, 'yyyy.MM.dd HH:mm:ss'),
        label:
          status === 'in-use' && !isAvailable
            ? t('component_permit_label_used')
            : status === 'expired'
            ? t('component_permit_label_expired')
            : status === 'refunded'
            ? t('component_permit_label_refunded')
            : undefined,
        isDanger: status === 'refunded',
      },
    ];
    if (countInfo) {
      result.push({
        title: t('component_ticket_card_remained_count'),
        value: t('component_ticket_card_remained_count_detail', {
          count: countInfo.remained,
          totalCount: countInfo.total,
        }),
      });
    }
    return result;
  }, [countInfo, expireAt, isAvailable, status, t]);

  return (
    <BasePermitCard
      {...basePermitCardProps}
      status={status}
      listItems={listItems}
      button={
        buttonLabel && (
          <Button
            colorVariant="black"
            isDisabled={!isAvailable}
            isLoading={isLoading}
            onClick={() => {
              setIsLoading(true);
              return onButtonClick?.().finally(() => setIsLoading(false));
            }}>
            {buttonLabel}
          </Button>
        )
      }
    />
  );
};

export default TicketCard;
export type {TicketCardProps};
TicketCard.displayName = 'TicketCard';
