import { CursorPageFromJSON, CursorPageToJSON, } from './CursorPage';
import { TicketGroupFromJSON, TicketGroupToJSON, } from './TicketGroup';
/**
 * Check if a given object implements the ListTicketGroupResponse interface.
 */
export function instanceOfListTicketGroupResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "page" in value;
    isInstance = isInstance && "ticketGroups" in value;
    return isInstance;
}
export function ListTicketGroupResponseFromJSON(json) {
    return ListTicketGroupResponseFromJSONTyped(json, false);
}
export function ListTicketGroupResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'page': CursorPageFromJSON(json['page']),
        'ticketGroups': (json['ticketGroups'].map(TicketGroupFromJSON)),
    };
}
export function ListTicketGroupResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'page': CursorPageToJSON(value.page),
        'ticketGroups': (value.ticketGroups.map(TicketGroupToJSON)),
    };
}
