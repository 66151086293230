import dynamic from 'next/dynamic';
import React from 'react';
import {useTranslation} from 'react-i18next';

import {Tooltip, TooltipProps, TooltipCardProps} from '@santa-web/service-ui';
import {useDialogContext} from '@app/contexts/DialogContext';
import useShouldShowTutorial from '@app/hooks/useShouldShowTutorial';

const ChatTutorialAlertDialog = dynamic(() => import('@app/components/alert-dialog/ChatTutorialAlertDialog'));

type ChatTutorialProps = Omit<TooltipProps, 'TooltipCardProps'>;

const ChatTutorial = React.forwardRef<HTMLDivElement, ChatTutorialProps>(({...props}, ref) => {
  const {t} = useTranslation();
  const getShouldShowTutorial = useShouldShowTutorial();
  const {openDialog, addClearListener, removeClearListener} = useDialogContext();

  const [shouldShowTutorial, setShouldShowTutorial] = React.useState(false);
  const [isTooltipOpened, setIsTooltipOpened] = React.useState(false);

  const tooltipCardProps = React.useMemo<TooltipCardProps>(
    () => ({
      arrowAlign: 'center',
      arrowDirection: 'block-end',
      color: 'brand',
      size: 'medium',
      content: t('santa_lumi_tutorial_tooltip'),
    }),
    [t]
  );

  React.useEffect(() => {
    (async () => {
      const {showTutorial: shouldShowTutorial} = await getShouldShowTutorial({tutorialType: 'CHAT_USAGE'});

      if (!shouldShowTutorial) {
        return;
      }

      setShouldShowTutorial(shouldShowTutorial);
      openDialog(<ChatTutorialAlertDialog />);
    })();
  }, [getShouldShowTutorial, openDialog]);

  React.useEffect(() => {
    const handleClear = async () => {
      if (shouldShowTutorial) {
        setShouldShowTutorial(false);
        setIsTooltipOpened(true);

        setTimeout(() => {
          setIsTooltipOpened(false);
        }, 3000);
      }
    };

    addClearListener(handleClear);

    return () => removeClearListener(handleClear);
  }, [addClearListener, removeClearListener, shouldShowTutorial]);

  return <Tooltip ref={ref} {...props} TooltipCardProps={tooltipCardProps} isOpened={isTooltipOpened} />;
});

export default React.memo(ChatTutorial);
export type {ChatTutorialProps as ChatTutorialTooltipProps};
