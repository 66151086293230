/* tslint:disable */
/* eslint-disable */
/**
 * TOEIC 선택학습 실전 모의고사 이벤트의 종류.
 *
 * - **ENTER**: TOEIC 선택학습 실전 모의고사 시작
 * - **FOCUS**: TOEIC 선택학습 실전 모의고사 문제 선택
 * - **PLACE_ANSWER**: TOEIC 선택학습 실전 모의고사 문제에 대한 답안 입력
 * - **SUBMIT**: TOEIC 선택학습 실전 모의고사 답안 최종 제출
 * @export
 */
export const ToeicVirtualExamEventDetailsOneOfCase = {
    ENTER: 'ENTER',
    FOCUS: 'FOCUS',
    PLACE_ANSWER: 'PLACE_ANSWER',
    SUBMIT: 'SUBMIT'
};
export function ToeicVirtualExamEventDetailsOneOfCaseFromJSON(json) {
    return ToeicVirtualExamEventDetailsOneOfCaseFromJSONTyped(json, false);
}
export function ToeicVirtualExamEventDetailsOneOfCaseFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ToeicVirtualExamEventDetailsOneOfCaseToJSON(value) {
    return value;
}
