/* tslint:disable */
/* eslint-disable */
/**
 * 과목 별 점수로부터 총 점수를 계산하는 방식.
 *
 * - **SUM**: 과목 점수의 합계
 * - **AVERAGE_ROUND_UP**: 과목 점수의 평균 (올림)
 * @export
 */
export const ExamScoreAggregationType = {
    SUM: 'SUM',
    AVERAGE_ROUND_UP: 'AVERAGE_ROUND_UP'
};
export function ExamScoreAggregationTypeFromJSON(json) {
    return ExamScoreAggregationTypeFromJSONTyped(json, false);
}
export function ExamScoreAggregationTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ExamScoreAggregationTypeToJSON(value) {
    return value;
}
