import { SubscriptionRenewalTermUnitFromJSON, SubscriptionRenewalTermUnitToJSON, } from './SubscriptionRenewalTermUnit';
/**
 * Check if a given object implements the SubscriptionRenewalTerm interface.
 */
export function instanceOfSubscriptionRenewalTerm(value) {
    let isInstance = true;
    isInstance = isInstance && "term" in value;
    isInstance = isInstance && "unit" in value;
    return isInstance;
}
export function SubscriptionRenewalTermFromJSON(json) {
    return SubscriptionRenewalTermFromJSONTyped(json, false);
}
export function SubscriptionRenewalTermFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'term': json['term'],
        'unit': SubscriptionRenewalTermUnitFromJSON(json['unit']),
    };
}
export function SubscriptionRenewalTermToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'term': value.term,
        'unit': SubscriptionRenewalTermUnitToJSON(value.unit),
    };
}
