import { HighlightTemplateFromJSON, HighlightTemplateToJSON, } from './HighlightTemplate';
/**
 * Check if a given object implements the CreateHighlightsRequest interface.
 */
export function instanceOfCreateHighlightsRequest(value) {
    let isInstance = true;
    isInstance = isInstance && "highlightTemplates" in value;
    return isInstance;
}
export function CreateHighlightsRequestFromJSON(json) {
    return CreateHighlightsRequestFromJSONTyped(json, false);
}
export function CreateHighlightsRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'highlightTemplates': (json['highlightTemplates'].map(HighlightTemplateFromJSON)),
    };
}
export function CreateHighlightsRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'highlightTemplates': (value.highlightTemplates.map(HighlightTemplateToJSON)),
    };
}
