/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { PointRewardLeaderBoardMyRankFromJSON, PointRewardLeaderBoardMyRankToJSON, } from './PointRewardLeaderBoardMyRank';
import { PointRewardRankEntryFromJSON, PointRewardRankEntryToJSON, } from './PointRewardRankEntry';
/**
 * Check if a given object implements the PointRewardLeaderBoard interface.
 */
export function instanceOfPointRewardLeaderBoard(value) {
    let isInstance = true;
    isInstance = isInstance && "accumulateUntil" in value;
    isInstance = isInstance && "entries" in value;
    return isInstance;
}
export function PointRewardLeaderBoardFromJSON(json) {
    return PointRewardLeaderBoardFromJSONTyped(json, false);
}
export function PointRewardLeaderBoardFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'accumulateUntil': (new Date(json['accumulateUntil'])),
        'entries': (json['entries'].map(PointRewardRankEntryFromJSON)),
        'myRank': !exists(json, 'myRank') ? undefined : PointRewardLeaderBoardMyRankFromJSON(json['myRank']),
    };
}
export function PointRewardLeaderBoardToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'accumulateUntil': (value.accumulateUntil.toISOString()),
        'entries': (value.entries.map(PointRewardRankEntryToJSON)),
        'myRank': PointRewardLeaderBoardMyRankToJSON(value.myRank),
    };
}
