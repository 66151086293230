/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
/**
 * Check if a given object implements the CheckActiveMarketingCampaignResponse interface.
 */
export function instanceOfCheckActiveMarketingCampaignResponse(value) {
    let isInstance = true;
    return isInstance;
}
export function CheckActiveMarketingCampaignResponseFromJSON(json) {
    return CheckActiveMarketingCampaignResponseFromJSONTyped(json, false);
}
export function CheckActiveMarketingCampaignResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'mostRecentStartsAt': !exists(json, 'mostRecentStartsAt') ? undefined : (new Date(json['mostRecentStartsAt'])),
    };
}
export function CheckActiveMarketingCampaignResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'mostRecentStartsAt': value.mostRecentStartsAt === undefined ? undefined : (value.mostRecentStartsAt.toISOString()),
    };
}
