import React from 'react';

import {mobileService} from '@app/api/app-bridge/mobile-service';
import {useIsPreview} from '@app/test-preparation/hooks';
import {isAppBridgeAvailable} from '@app/utils/app-bridge';

function useABTest(variantKey: string) {
  const isPreview = useIsPreview();
  const [value, setValue] = React.useState<string | null>(null);

  const setABTestValue = React.useCallback(async (key: string) => {
    const res = await mobileService.getABTestingExperiment(key);
    setValue(res.value ?? null);
  }, []);

  React.useEffect(() => {
    if (!isAppBridgeAvailable() || isPreview) {
      return;
    }
    setABTestValue(variantKey);
  }, [setABTestValue, isPreview, variantKey]);

  return value;
}

export default useABTest;
