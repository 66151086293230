import React from 'react';
import {css} from '@emotion/react';
import {
  COLOR_SANTA_O,
  COLOR_SANTA_I,
  COLOR_SANTA_L,
  COLOR_SANTA_BD,
  COLOR_SANTA_A,
  COLOR_SANTA_C,
  SHADOW_A_2_DOWN,
  COLOR_FIX_SANTA_WHITE_1,
} from '@riiid/design-system';
import LocationIcon from '@riiid/design-system/icons/Location';

import {Typography} from '@santa-web/service-ui';

import ArrowUpIcon from './ArrowUpIcon';

export interface JourneyDotProps extends React.ComponentPropsWithoutRef<'div'> {
  isGoal?: boolean;
  isNow?: boolean;
  isSelected?: boolean;
  title: string;
  score?: number;
}

const JourneyDot = React.forwardRef<HTMLDivElement, JourneyDotProps>(
  ({isGoal, isNow, isSelected, title, score, onClick, ...props}, ref) => {
    const scoreColor = isSelected ? COLOR_FIX_SANTA_WHITE_1 : isGoal ? COLOR_SANTA_A : COLOR_SANTA_I;
    const backgroundColor = (() => {
      if (isGoal) {
        return {default: COLOR_SANTA_L, hover: COLOR_SANTA_O, selected: COLOR_SANTA_O};
      }
      return {default: COLOR_SANTA_C, hover: COLOR_SANTA_C, selected: COLOR_SANTA_BD};
    })();

    return (
      <div
        css={css`
          position: relative;
          height: 100%;
        `}
        ref={ref}
        {...props}>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: center;
            cursor: pointer;
            margin-top: 36px;
          `}
          onClick={onClick}>
          <Typography
            css={css`
              margin-bottom: 4px;
            `}
            variant="caption2"
            fontWeight="regular"
            color={COLOR_SANTA_I}>
            {title}
          </Typography>
          <div
            data-testid="JourneyDot-MainDiv"
            css={[
              css`
                display: flex;
                align-items: center;
                justify-content: center;

                width: 48px;
                height: 48px;

                border-radius: 100%;
              `,
              isSelected
                ? css`
                    background-color: ${backgroundColor.selected};
                    box-shadow: ${SHADOW_A_2_DOWN};
                  `
                : css`
                    background-color: ${backgroundColor.default};
                    :hover,
                    :active {
                      background-color: ${backgroundColor.hover};
                      box-shadow: ${SHADOW_A_2_DOWN};
                    }
                  `,
            ]}>
            {isNow ? (
              <LocationIcon data-testid="JourneyDot-NowIcon" width={24} height={24} color={scoreColor} />
            ) : (
              <Typography variant="body3" fontWeight={isGoal || isSelected ? 'bold' : 'regular'} color={scoreColor}>
                {score ?? '?'}
              </Typography>
            )}
          </div>
        </div>
        {isSelected && (
          <ArrowUpIcon
            data-testid="JourneyDot-ArrowUpIcon"
            css={css`
              position: absolute;
              transform: translateX(-50%);
              left: 50%;
              bottom: 0;
            `}
          />
        )}
      </div>
    );
  }
);

export default React.memo(JourneyDot);
