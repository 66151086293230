import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';

import {Button, BottomSheetProps} from '@santa-web/service-ui';
import BottomSheetWithDim, {BottomSheetWithDimProps} from '@app/components/BottomSheetWithDim';
import {ALERT_DIALOG_Z_INDEX} from '@app/constants/zIndex';

export interface VirtualExamTimeOverBottomSheetWithDimProps extends Omit<BottomSheetWithDimProps, 'BottomSheetProps'> {
  onSubmitAnswers?(): void | Promise<void>;
}

const VirtualExamTimeOverBottomSheetWithDim = React.forwardRef<
  HTMLDivElement,
  VirtualExamTimeOverBottomSheetWithDimProps
>(({onSubmitAnswers, ...props}, ref) => {
  const {t} = useTranslation();
  const bottomSheetProps = React.useMemo(
    (): BottomSheetProps => ({
      isLoadingAiAnimationVisible: true,
      title: t('bottom_sheet_virtual_exam_time_over_title'),
      description: t('bottom_sheet_virtual_exam_time_over_description'),
      buttons: [
        <Button variant="solid" colorVariant="brand" isFullWidth onClick={onSubmitAnswers} key="submitAnswers">
          {t('bottom_sheet_virtual_exam_time_over_submit_answers')}
        </Button>,
      ],
    }),
    [t, onSubmitAnswers]
  );
  return (
    <BottomSheetWithDim
      css={css`
        z-index: ${ALERT_DIALOG_Z_INDEX};
      `}
      isOpened
      BottomSheetProps={bottomSheetProps}
      ref={ref}
      {...props}
    />
  );
});

export default React.memo(VirtualExamTimeOverBottomSheetWithDim);
