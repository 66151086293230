import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {Label, LabelProps} from '@santa-web/service-ui';
import {CouponStatus, AdvancedCouponStatus} from '@app/features/coupon';

type CouponStatusLabelProps = {
  status: CouponStatus | AdvancedCouponStatus;
} & Partial<LabelProps>;

const CouponStatusLabel = ({status, ...labelProps}: CouponStatusLabelProps) => {
  const {t} = useTranslation();
  const LabelByStatus = (props: Omit<CouponStatusLabelProps, 'status'>) => {
    switch (status) {
      case 'REGISTERED_AND_READY':
        return (
          <Label variant="small" colorVariant="dark" {...props}>
            {t('page_coupon_box_coupon_status_registered_and_ready')} {/** KR: 등록완료 */}
          </Label>
        );
      case 'PENDING':
        return (
          <Label variant="small" colorVariant="danger-light" {...props}>
            {t('page_coupon_box_coupon_status_pending')} {/** KR: 사용대기 */}
          </Label>
        );
      case 'AVAILABLE':
        return (
          <Label variant="small" colorVariant="brand-light" {...props}>
            {t('page_coupon_box_coupon_status_available')} {/** KR: 사용가능 */}
          </Label>
        );
      case 'USED':
        return (
          <Label variant="small" colorVariant="dark" {...props}>
            {t('page_coupon_box_coupon_status_used')} {/** KR: 사용완료 */}
          </Label>
        );
      case 'EXPIRED':
        return (
          <Label variant="small" colorVariant="dark" {...props}>
            {t('page_coupon_box_coupon_status_expired')} {/** KR: 기간만료 */}
          </Label>
        );
      default:
        return null;
    }
  };

  return (
    <LabelByStatus
      {...labelProps}
      css={css`
        width: fit-content;
      `}
    />
  );
};

export default CouponStatusLabel;
export type {CouponStatusLabelProps};
CouponStatusLabel.displayName = 'CouponStatusLabel';
