import React from 'react';
import {css, SerializedStyles} from '@emotion/react';
import {COLOR_SANTA_L, COLOR_SANTA_H, SHADOW_A_2_DOWN} from '@riiid/design-system';
import BookmarkIcon from '@riiid/design-system/icons/Bookmark';

import {Typography, TypographyProps, IconButton, getLineClampCss} from '@santa-web/service-ui';
import BaseCard from '@app/components/card/BaseCard';

export type MyNoteCardProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'title' | 'content'> & {
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  content?: React.ReactNode;
  imageUrl?: string | null;
  status?: React.ReactNode;
  time?: React.ReactNode;
  timeProps?: TypographyProps;
  customContentCss?: SerializedStyles;
  isBookmarked?: boolean;
  onBookmarkClick?: React.MouseEventHandler;
};

const MyNoteCard = React.forwardRef<HTMLDivElement, MyNoteCardProps>(
  (
    {
      title,
      subTitle,
      content,
      imageUrl,
      status,
      customContentCss,
      isBookmarked,
      onBookmarkClick,
      time,
      timeProps,
      ...props
    },
    ref
  ) => {
    const displayedContent = imageUrl ? (
      <img
        src={imageUrl}
        css={css`
          object-fit: cover;
          width: 100%;
          height: 140px;
        `}
        alt={`Question ${title ?? ''} Image`}
      />
    ) : (
      content
    );

    return (
      <BaseCard
        css={css`
          min-width: 0;
          overflow-wrap: break-word;
          &:not(:active):hover {
            box-shadow: ${SHADOW_A_2_DOWN};
          }
        `}
        {...props}
        ref={ref}>
        <div
          css={css`
            display: flex;
            align-items: center;
          `}>
          <div>
            <Typography
              css={css`
                flex-grow: 0;
              `}
              color={COLOR_SANTA_L}
              variant="body3"
              fontWeight="bold">
              {title}
            </Typography>
            {subTitle && (
              <Typography
                variant="caption1"
                fontWeight="regular"
                color={COLOR_SANTA_L}
                css={css`
                  margin-top: 4px;
                `}>
                {subTitle}
              </Typography>
            )}
          </div>

          {time && (
            <Typography
              css={css`
                flex-shrink: 0;
                flex-grow: 1;
                margin-left: 20px;
                text-align: right;
                align-self: flex-start;
              `}
              display="block"
              color={COLOR_SANTA_H}
              variant="caption1"
              {...timeProps}>
              {time}
            </Typography>
          )}
        </div>
        {displayedContent && (
          <div
            css={[
              css`
                margin-top: 12px;
              `,
              getLineClampCss(2),
              customContentCss,
            ]}
            data-testid="MyNoteCard-content">
            {displayedContent}
          </div>
        )}
        <div
          css={css`
            margin-top: 20px;
            display: flex;
            justify-content: space-between;
            align-items: center;
          `}>
          <div
            css={css`
              display: flex;
            `}>
            {isBookmarked != null && (
              <IconButton
                Icon={BookmarkIcon}
                onClick={onBookmarkClick}
                css={css`
                  padding: 8px;
                  margin: -8px;
                `}
                IconProps={{
                  type: isBookmarked ? 'solid' : 'line',
                }}
                data-testid={`MyNoteCard-Bookmark-${isBookmarked ? 'solid' : 'line'}`}
              />
            )}
          </div>
          <div
            css={css`
              display: flex;
            `}>
            {status}
          </div>
        </div>
      </BaseCard>
    );
  }
);

export default React.memo(MyNoteCard);
