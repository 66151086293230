/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { ImageSetFromJSON, ImageSetToJSON, } from './ImageSet';
import { MarketingPopupCtaButtonFromJSON, MarketingPopupCtaButtonToJSON, } from './MarketingPopupCtaButton';
/**
 * Check if a given object implements the MarketingPopup interface.
 */
export function instanceOfMarketingPopup(value) {
    let isInstance = true;
    isInstance = isInstance && "backgroundImage" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "title" in value;
    return isInstance;
}
export function MarketingPopupFromJSON(json) {
    return MarketingPopupFromJSONTyped(json, false);
}
export function MarketingPopupFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'backgroundImage': ImageSetFromJSON(json['backgroundImage']),
        'ctaButton': !exists(json, 'ctaButton') ? undefined : MarketingPopupCtaButtonFromJSON(json['ctaButton']),
        'id': json['id'],
        'title': json['title'],
    };
}
export function MarketingPopupToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'backgroundImage': ImageSetToJSON(value.backgroundImage),
        'ctaButton': MarketingPopupCtaButtonToJSON(value.ctaButton),
        'id': value.id,
        'title': value.title,
    };
}
