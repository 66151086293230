import React from 'react';
import {useTranslation} from 'react-i18next';
import {Portal} from 'react-portal';
import {css} from '@emotion/react';

import {BookmarkCard, BookmarkCardSkeleton, BookmarkLayout} from '@santa-web/service-ui';
import MemorizeStyleView from '@app/components/ContentView/components/MemorizeStyleView';
import {MemorizeStyle, BookmarkItem} from '@app/types/vocabulary';

export interface VocabularyBookmarkViewProps {
  bookmarkList: BookmarkItem[];
  onBookmarkCardClick?(id?: number | null, prevIsBookmarked?: boolean | null): React.MouseEventHandler;
  title: string;
  isMemorizeStyleViewVisible: boolean;
  isLoading: boolean;
  onMemorizeStyleClick(memorizeStyle: MemorizeStyle): Promise<void>;
  onMemorizeStyleViewCloseClick(): void;
}

const VocabularyBookmarkView = ({
  title,
  bookmarkList,
  onBookmarkCardClick,
  isMemorizeStyleViewVisible,
  onMemorizeStyleClick,
  isLoading,
  onMemorizeStyleViewCloseClick,
}: VocabularyBookmarkViewProps) => {
  const {t} = useTranslation();

  const bookmarkCards =
    bookmarkList.length === 0
      ? Array(5)
          .fill(null)
          .map((_, index) => <BookmarkCardSkeleton key={index} />)
      : bookmarkList.map(({cisId, isBookmarked, vocabulary}) => (
          <BookmarkCard
            key={vocabulary.id}
            content={vocabulary.word}
            subContent={vocabulary.meaning}
            bookmarkIconButtonProps={{
              onClick: onBookmarkCardClick?.(cisId, isBookmarked),
              IconProps: {
                type: isBookmarked ? 'solid' : 'line',
              },
            }}
          />
        ));

  return (
    <>
      <BookmarkLayout
        css={css`
          white-space: pre-line;
        `}
        pageTitle={t('toeic_vocabulary_view_bookmark_page_title')}
        title={title}
        bookmarkCards={bookmarkCards}
      />
      {isMemorizeStyleViewVisible && (
        <Portal>
          <MemorizeStyleView
            isDisabled={isLoading}
            onMemorizeStyleClick={onMemorizeStyleClick}
            onCloseClick={onMemorizeStyleViewCloseClick}
          />
        </Portal>
      )}
    </>
  );
};

export default React.memo(VocabularyBookmarkView);
