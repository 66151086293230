import React from 'react';
import {css} from '@emotion/react';

import {ElementInteractionState, QuestionState} from '@santa-web/gen/open-api/content-learning-service';
import {Question} from '@santa-web/gen/open-api/content/models/Question';
import {JoinProviders} from '@santa-web/service-common';
import {BlankContext, SingleQuestionTitle} from '@santa-web/service-ui';
import {ScrollAreaBottomViewProps} from '@app/components/ContentView/types';
import QuestionView, {ChoiceAnswerHandler} from '@app/test-preparation/components/QuestionView';
import SingleColumnLayout from '@app/test-preparation/components/SingleColumnLayout';
import {IsUserHighlightEnabledContext} from '@app/test-preparation/contexts/IsUserHighlightEnabledContext';
import {SnippetViewBaseIdContext} from '@app/test-preparation/contexts/SnippetViewBaseIdContext';
import {useBlankContext} from '@app/test-preparation/hooks/rrt-v2';

interface GtelpGrammarQuestionViewProps extends Partial<ScrollAreaBottomViewProps> {
  isDisabled?: boolean;
  isExplanationVisible?: boolean;
  question?: Question;
  questionElementState?: ElementInteractionState;
  questionTitle: string;
  scrollAreaBottomRef?: React.RefObject<HTMLDivElement>;
  scrollAreaMarginBottom?: number;
  onQuestionStateChange?(questionState: QuestionState): Promise<void>;
  canSelectUnsolvableQuestion: boolean;
  onChoiceAnswer?: ChoiceAnswerHandler;
  isUserHighlightEnabled: boolean;
  isCompleted: boolean;
  cisId: number;
  contentId: string;
}

const GtelpGrammarQuestionView = ({
  isDisabled = false,
  isExplanationVisible = true,
  question,
  questionElementState,
  questionTitle,
  scrollAreaBottomRef,
  scrollAreaMarginBottom,
  onQuestionStateChange,
  canSelectUnsolvableQuestion,
  onChoiceAnswer,
  isUserHighlightEnabled,
  isCompleted,
  cisId,
  contentId,
}: GtelpGrammarQuestionViewProps): JSX.Element => {
  const blankContext = useBlankContext();

  return (
    <SingleColumnLayout>
      <div
        css={css`
          flex: 1;
        `}>
        <JoinProviders
          providers={
            <>
              <SnippetViewBaseIdContext.Provider value={cisId.toString()} />
              <IsUserHighlightEnabledContext.Provider value={isUserHighlightEnabled} />
              <BlankContext.Provider value={blankContext} />
            </>
          }>
          <QuestionView
            isDisabled={isDisabled}
            isExplanationInvisible={!isExplanationVisible}
            questionTitle={<SingleQuestionTitle content={questionTitle} />}
            question={question}
            questionState={questionElementState?.details.question}
            onChange={onQuestionStateChange}
            canSelectUnsolvableQuestion={canSelectUnsolvableQuestion}
            onChoiceAnswer={onChoiceAnswer}
            elementInteractionStateId={questionElementState?.id}
            isCompleted={isCompleted}
            contentId={contentId}
          />
        </JoinProviders>
        <div
          ref={scrollAreaBottomRef}
          css={css`
            height: ${scrollAreaMarginBottom}px;
          `}
        />
      </div>
    </SingleColumnLayout>
  );
};

export default React.memo(GtelpGrammarQuestionView);
