import {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {COLOR_SANTA_BD, COLOR_SANTA_H, COLOR_SANTA_J} from '@riiid/design-system';
import {format, isAfter, isBefore} from 'date-fns';
import {Typography} from '@santa-web/service-ui';
import {Column, Divider, LineClamp} from '@app/components';
import {CouponBaseCard, CouponStatus, isCouponDisabled, AdvancedCouponStatus} from '@app/features/coupon';

type DiscountCouponCardProps = {
  discountAmountWithUnit: string;
  displayName: string;
  minimumPriceToApplyWithCurrency?: string;
  maximumDiscountAmountWithCurrency?: string;
  from?: Date;
  to?: Date;
  status: CouponStatus;
};

const DiscountCouponCard = ({
  status,
  discountAmountWithUnit,
  displayName,
  minimumPriceToApplyWithCurrency,
  maximumDiscountAmountWithCurrency,
  from,
  to,
}: DiscountCouponCardProps) => {
  const {t} = useTranslation();
  const advancedState = useMemo((): CouponStatus | AdvancedCouponStatus => {
    if (status !== 'REGISTERED') return status;

    const now = new Date();

    if (from && isBefore(now, from)) {
      return 'PENDING';
    }

    if (from && isAfter(now, from) && to && isBefore(now, to)) {
      return 'AVAILABLE';
    }

    return status;
  }, [from, to, status]);

  const isDisabled = isCouponDisabled(status);

  const shouldDisplayFromDate = isDisabled || (!isDisabled && advancedState === 'PENDING');

  return (
    <CouponBaseCard isDisabled={isDisabled} status={advancedState}>
      <Column
        gap={12}
        css={css`
          margin-top: 4px;
        `}>
        <Column gap={4}>
          <Typography variant="body3" fontWeight="bold" color={COLOR_SANTA_BD}>
            {t('page_coupon_box_discount_coupon_discount_amount', {discountAmount: discountAmountWithUnit})}{' '}
            {/** KR: {{discountAmount}} 할인 */}
          </Typography>
          <LineClamp limit={2} color={COLOR_SANTA_J}>
            <Typography component="p" variant="caption1" color={COLOR_SANTA_J}>
              {displayName}
            </Typography>
          </LineClamp>
        </Column>

        <Divider
          height={1}
          css={css`
            background-color: transparent;
            background-image: linear-gradient(to right, black 20%, rgba(255, 255, 255, 0) 0%);
            background-position: bottom;
            background-size: 5px 1px;
            background-repeat: repeat-x;
          `}
        />

        <Column gap={8}>
          <Column gap={4}>
            {minimumPriceToApplyWithCurrency && (
              <Typography variant="caption1" color={COLOR_SANTA_H}>
                {t('page_coupon_box_discount_coupon_minimum_price', {minimumPrice: minimumPriceToApplyWithCurrency})}
                {/** KR : {{minimumPrice}} 이상부터 사용 가능 */}
              </Typography>
            )}

            {maximumDiscountAmountWithCurrency && (
              <Typography variant="caption1" color={COLOR_SANTA_H}>
                {t('page_coupon_box_discount_coupon_maximum_discount_amount', {
                  maximumAmount: maximumDiscountAmountWithCurrency,
                })}
                {/** KR : 최대 {{maximumAmount}} 까지 할인 */}
              </Typography>
            )}
          </Column>

          <Column
            css={css`
              opacity: 0.6;
            `}
            gap={4}>
            {from && shouldDisplayFromDate && (
              <Typography variant="caption1" color={COLOR_SANTA_H}>
                {t('page_coupon_box_discount_coupon_from_date', {fromDate: format(from, 'yyyy. MM. dd HH:mm:ss')})}
                {/** KR: {{fromDate}}부터 */}
              </Typography>
            )}
            {to && (
              <Typography variant="caption1" color={COLOR_SANTA_H}>
                {t('page_coupon_box_discount_coupon_until_date', {untilDate: format(to, 'yyyy. MM. dd HH:mm:ss')})}
                {/** KR: {{untilDate}}까지 */}
              </Typography>
            )}
          </Column>
        </Column>
      </Column>
    </CouponBaseCard>
  );
};

export default DiscountCouponCard;
export type {DiscountCouponCardProps};
DiscountCouponCard.displayName = 'DiscountCouponCard';
