import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {GoogleLogin} from '@react-oauth/google';
import {Button, ButtonBase, LoginButton} from '@santa-web/service-ui';
import {mobileService} from '@app/api/app-bridge/mobile-service';
import {AuthProviderType, getAuthItems, OtherItem} from '@app/features/auth';
import useLanguage from '@app/hooks/i18n/useLanguage';
import {isAppBridgeAvailable} from '@app/utils/app-bridge';
import {getUserAgent, isAndroid} from '@app/utils/user-agent';

type UseAuthButtonsParam = {
  type: 'login' | 'signup';
  isLoading?: boolean;
  onAppleAuth: React.MouseEventHandler<HTMLButtonElement>;
  onKakaoAuth: React.MouseEventHandler<HTMLButtonElement>;
  onLineAuth: React.MouseEventHandler<HTMLButtonElement>;
  onGoogleAuth: (data: {idToken: string; email?: string; name?: string}) => void;
  onEmailAuth: React.MouseEventHandler<HTMLButtonElement>;
};

const useAuthButtons = ({
  type,
  isLoading = false,
  onAppleAuth,
  onKakaoAuth,
  onLineAuth,
  onGoogleAuth,
  onEmailAuth,
}: UseAuthButtonsParam) => {
  const {t} = useTranslation();
  const language = useLanguage();
  const [isOptionalButtonsHidden, setIsOptionalButtonsHidden] = React.useState(true);

  const translations = React.useMemo((): Record<AuthProviderType | 'other', string> => {
    if (type === 'login') {
      return {
        google: t('sign_in_with_google'),
        apple: t('sign_in_with_apple'),
        line: t('sign_in_with_line'),
        kakao: t('sign_in_with_kakao'),
        email: t('sign_in_with_email'),
        other: t('sign_in_no_ways'),
      };
    } else {
      return {
        google: t('sign_up_with_google'),
        apple: t('sign_up_with_apple'),
        line: t('sign_up_with_line'),
        kakao: t('sign_up_with_kakao'),
        email: t('sign_up_with_email'),
        other: t('sign_up_another_way'),
      };
    }
  }, [t, type]);

  const showOtherButtons = React.useCallback(() => {
    setIsOptionalButtonsHidden(false);
  }, []);

  const createAppleButton = () => (
    <LoginButton key="apple-btn" variant="apple" isFullWidth onClick={onAppleAuth} isDisabled={isLoading}>
      {translations.apple}
    </LoginButton>
  );

  const createKakaoButton = () => (
    <LoginButton key="kakao-btn" variant="kakao" isFullWidth onClick={onKakaoAuth} isDisabled={isLoading}>
      {translations.kakao}
    </LoginButton>
  );

  const createLineButton = () => (
    <LoginButton key="line-btn" variant="line" isFullWidth onClick={onLineAuth} isDisabled={isLoading}>
      {translations.line}
    </LoginButton>
  );

  const createGoogleButton = () =>
    isAppBridgeAvailable() ? (
      <LoginButton
        key="google-btn"
        variant="google"
        isFullWidth
        onClick={async () => {
          const {
            authData: {idToken},
            email,
            name,
          } = await mobileService.requestSocialLogin('GOOGLE');
          onGoogleAuth({idToken, email, name});
        }}
        isDisabled={isLoading}>
        {translations.google}
      </LoginButton>
    ) : (
      <ButtonBase
        css={css`
          width: 100%;
          max-width: 400px;
          display: flex;
          justify-content: center;
          overflow: hidden;
          background-color: #1b73e8;
          border-radius: 4px;
        `}>
        <GoogleLogin
          containerProps={{style: {backgroundColor: '#1B73E8'}}}
          logo_alignment="center"
          width="400px"
          size="large"
          theme="filled_blue"
          text="signin_with"
          locale={language}
          onSuccess={credentialResponse => {
            onGoogleAuth({idToken: credentialResponse.credential ?? ''});
          }}
          use_fedcm_for_prompt
        />
      </ButtonBase>
    );

  const createOtherButtons = (items: OtherItem[]) => {
    if (isOptionalButtonsHidden) {
      return (
        <Button key="other" isFullWidth colorVariant="transparent" onClick={showOtherButtons}>
          {translations.other}
        </Button>
      );
    }

    return items.map(item => {
      switch (item.type) {
        case 'email':
          return (
            <Button key="email-btn" isFullWidth variant="box-line" onClick={onEmailAuth}>
              {translations.email}
            </Button>
          );
      }
    });
  };

  return getAuthItems(language).flatMap((loginItem, index) => {
    const createButton = () => {
      switch (loginItem.type) {
        case 'apple':
          if (isAppBridgeAvailable() && isAndroid(getUserAgent())) {
            return null;
          }
          return createAppleButton();
        case 'kakao':
          return createKakaoButton();
        case 'google':
          return createGoogleButton();
        case 'line':
          return createLineButton();
        case 'other':
          return createOtherButtons(loginItem.items);
      }
    };
    return <React.Fragment key={index}>{createButton()}</React.Fragment>;
  });
};

export default useAuthButtons;
