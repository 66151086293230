/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { CouponDetailsDiscountMethodFromJSON, CouponDetailsDiscountMethodToJSON, } from './CouponDetailsDiscountMethod';
/**
 * Check if a given object implements the CouponDetailsDiscount interface.
 */
export function instanceOfCouponDetailsDiscount(value) {
    let isInstance = true;
    isInstance = isInstance && "currency" in value;
    isInstance = isInstance && "method" in value;
    isInstance = isInstance && "minPurchasePrice" in value;
    isInstance = isInstance && "usableFrom" in value;
    isInstance = isInstance && "usableUntil" in value;
    return isInstance;
}
export function CouponDetailsDiscountFromJSON(json) {
    return CouponDetailsDiscountFromJSONTyped(json, false);
}
export function CouponDetailsDiscountFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'currency': json['currency'],
        'maxDiscountAmount': !exists(json, 'maxDiscountAmount') ? undefined : json['maxDiscountAmount'],
        'method': CouponDetailsDiscountMethodFromJSON(json['method']),
        'minPurchasePrice': json['minPurchasePrice'],
        'usableFrom': (new Date(json['usableFrom'])),
        'usableUntil': (new Date(json['usableUntil'])),
    };
}
export function CouponDetailsDiscountToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'currency': value.currency,
        'maxDiscountAmount': value.maxDiscountAmount,
        'method': CouponDetailsDiscountMethodToJSON(value.method),
        'minPurchasePrice': value.minPurchasePrice,
        'usableFrom': (value.usableFrom.toISOString()),
        'usableUntil': (value.usableUntil.toISOString()),
    };
}
