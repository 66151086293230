import { OrderOfferFromJSON, OrderOfferToJSON, } from './OrderOffer';
import { OrderOfferGroupDisplayConfigFromJSON, OrderOfferGroupDisplayConfigToJSON, } from './OrderOfferGroupDisplayConfig';
/**
 * Check if a given object implements the OrderOfferGroup interface.
 */
export function instanceOfOrderOfferGroup(value) {
    let isInstance = true;
    isInstance = isInstance && "displayConfig" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "offer" in value;
    isInstance = isInstance && "targetDomains" in value;
    return isInstance;
}
export function OrderOfferGroupFromJSON(json) {
    return OrderOfferGroupFromJSONTyped(json, false);
}
export function OrderOfferGroupFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'displayConfig': OrderOfferGroupDisplayConfigFromJSON(json['displayConfig']),
        'id': json['id'],
        'offer': OrderOfferFromJSON(json['offer']),
        'targetDomains': json['targetDomains'],
    };
}
export function OrderOfferGroupToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'displayConfig': OrderOfferGroupDisplayConfigToJSON(value.displayConfig),
        'id': value.id,
        'offer': OrderOfferToJSON(value.offer),
        'targetDomains': value.targetDomains,
    };
}
