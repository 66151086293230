import React from 'react';
import {css} from '@emotion/react';
import {COLOR_FIX_SPACE_BLUE_GRAY_9, COLOR_RIIID_GREEN_3, SHADOW_B_1_DOWN} from '@riiid/design-system';
import {Typography} from '@santa-web/service-ui';

type BenefitLabelProps = {content: string};

const BenefitLabel = ({content}: BenefitLabelProps) => {
  return (
    <div
      css={css`
        display: flex;
        justify-content: center;
        align-items: center;
        width: fit-content;
        padding: 0 8px;
        background-color: ${COLOR_RIIID_GREEN_3};
        box-shadow: ${SHADOW_B_1_DOWN};
        border-radius: 4px;
      `}>
      <Typography component="p" variant="caption2" fontWeight="bold" color={COLOR_FIX_SPACE_BLUE_GRAY_9}>
        {content}
      </Typography>
    </div>
  );
};

export default BenefitLabel;
export type {BenefitLabelProps};
BenefitLabel.displayName = 'BenefitLabel';
