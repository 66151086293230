import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {COLOR_SANTA_G, COLOR_SANTA_J, COLOR_SANTA_BD} from '@riiid/design-system';

import {Typography, Label} from '@santa-web/service-ui';
import AnalyticsProgressBar, {AnalyticsProgressBarProps} from '@app/components/AnalyticsProgressBar';

export interface AnalyticsProgressProps extends AnalyticsProgressBarProps {
  title: string;
  // This prop ranges from -100 to 100.
  changedPercent?: number;
  isEmpty?: boolean;
}

const AnalyticsProgress = React.forwardRef<HTMLDivElement, AnalyticsProgressProps>(
  ({title, nowPercent, goalPercent, changedPercent, isEmpty = false, ...props}, ref) => {
    const {t} = useTranslation();
    return (
      <div ref={ref} {...props}>
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            margin-bottom: 4px;
          `}>
          <Typography fontWeight="regular" variant="caption1" color={COLOR_SANTA_J}>
            {title}
          </Typography>
          {!isEmpty && changedPercent ? (
            <Label data-testid="AnalyticsProgress-changedPercent" colorVariant="gray" variant="small">
              {`${Math.abs(changedPercent).toFixed(1)}% ${
                changedPercent > 0 ? t('result_view_analytics_up') : t('result_view_analytics_down')
              }`}
            </Label>
          ) : null}
        </div>
        <div
          css={css`
            display: flex;
          `}>
          <Typography fontWeight="bold" variant="body3" color={COLOR_SANTA_BD}>
            {isEmpty ? '? ' : Number.isInteger(nowPercent) ? nowPercent : nowPercent.toFixed(1)}%
          </Typography>
          {goalPercent ? (
            <>
              <Typography
                css={css`
                  margin: 0 4px;
                `}
                fontWeight="bold"
                variant="body3"
                color={COLOR_SANTA_G}>
                /
              </Typography>
              <Typography
                data-testid="AnalyticsProgress-GoalPercent"
                fontWeight="bold"
                variant="body3"
                color={COLOR_SANTA_J}>
                {Number.isInteger(goalPercent) ? goalPercent : goalPercent.toFixed(1)}%
              </Typography>
            </>
          ) : null}
        </div>
        <AnalyticsProgressBar nowPercent={isEmpty ? 0 : nowPercent} goalPercent={goalPercent} />
      </div>
    );
  }
);

export default React.memo(AnalyticsProgress);
