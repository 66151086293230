import React from 'react';

import UserAgent from '@app/types/user-agent';
import {getUserAgent} from '@app/utils/user-agent';

const useUserAgent = (): UserAgent => {
  return React.useMemo(() => {
    return getUserAgent();
  }, []);
};

export default useUserAgent;
