import TestPrepLayout, {TestPrepLayoutProps} from '@app/test-preparation/components/TestPrepLayout';
import {BASIC_SENTENCE_COMPLETION_VIEW_MAX_WIDTH} from '@app/test-preparation/constants/test-preparation-layout';

export type SentenceCompletionQuestionViewLayoutProps = TestPrepLayoutProps;

const SentenceCompletionQuestionViewLayout = ({children, ...rest}: TestPrepLayoutProps) => {
  return (
    <TestPrepLayout maxWidth={BASIC_SENTENCE_COMPLETION_VIEW_MAX_WIDTH} {...rest}>
      {children}
    </TestPrepLayout>
  );
};

export default SentenceCompletionQuestionViewLayout;
