/* tslint:disable */
/* eslint-disable */
/**
 * 필터 아이템의 종류.
 * - 클라이언트에서 필터를 사용자에게 노출할 때 해당 필터 아이템 종류 별로 묶어서 보여준다.
 * - 서버에서 학습 기록 조회를 할 때 선택한 필터에 대해서 같은 필터 종류 별로 OR 연산을 취한 후 OR 연산을 취한 조건에 AND 연산을 취한
 *   조건으로 검색을 진행한다.
 *
 * - **BOOKMARK**: 북마크된 CIS만을 검색할 지 여부.
 * - **CORRECTNESS**: 문제를 맞췄는지 틀렸는지 여부.
 * - **CELL_CATEGORY**: 학습을 진행한 학습 셀의 종류에 따른 구분.
 * - **CONTENT_CATEGORY**: CIS로 학습한 콘텐츠의 속성에 따른 구분.
 * @export
 */
export const LearningHistoryFilterType = {
    BOOKMARK: 'BOOKMARK',
    CORRECTNESS: 'CORRECTNESS',
    CELL_CATEGORY: 'CELL_CATEGORY',
    CONTENT_CATEGORY: 'CONTENT_CATEGORY'
};
export function LearningHistoryFilterTypeFromJSON(json) {
    return LearningHistoryFilterTypeFromJSONTyped(json, false);
}
export function LearningHistoryFilterTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function LearningHistoryFilterTypeToJSON(value) {
    return value;
}
