import React from 'react';
import {css} from '@emotion/react';
import {COLOR_SANTA_E} from '@riiid/design-system';

import {Snippet} from '@santa-web/gen/open-api/content/models/Snippet';
import SnippetView from '@app/test-preparation/components/SnippetView';

export interface SnippetListViewProps extends React.ComponentPropsWithoutRef<'div'> {
  snippets?: Snippet[];
  id?: string;
}

const SnippetListView = React.forwardRef<HTMLDivElement, SnippetListViewProps>(({snippets, id, ...props}, ref) => {
  return (
    <div
      ref={ref}
      css={css`
        & > *:not(:last-child)::after {
          display: block;

          content: ' ';

          width: 100%;
          height: 0;

          margin: 40px 0;

          border-top: 10px dotted ${COLOR_SANTA_E};
        }
      `}
      {...props}>
      {snippets?.map((snippet, i) => (
        <SnippetView key={i} snippet={snippet} id={`${id}.${i}`} index={i} />
      ))}
    </div>
  );
});

export default React.memo(SnippetListView);
