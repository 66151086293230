/**
 * Check if a given object implements the SystemConfig interface.
 */
export function instanceOfSystemConfig(value) {
    let isInstance = true;
    isInstance = isInstance && "key" in value;
    isInstance = isInstance && "value" in value;
    return isInstance;
}
export function SystemConfigFromJSON(json) {
    return SystemConfigFromJSONTyped(json, false);
}
export function SystemConfigFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'key': json['key'],
        'value': json['value'],
    };
}
export function SystemConfigToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'key': value.key,
        'value': value.value,
    };
}
