import {Content} from '@santa-web/gen/open-api/content/models/Content';
import {GTELP_SUBJECTS} from '@app/constants/common';
import {gtelpRecommendMsSet} from '@app/constants/recommendTime';
import {ContentRouter} from '@app/test-preparation/atoms/stores/learning/utils/content-router';

class GtelpContentRouter implements ContentRouter {
  constructor(private content: Content) {}
  getMeta<Name extends string>(name: Name): Name extends `${string}List` ? Array<string> : string {
    return (this.content.oneOfDomainContent.gtelpContent as any)[name] ?? (name.endsWith('List') ? [] : '');
  }
  getQuestionSet() {
    return this.content.oneOfDomainContent.gtelpContent?.oneOfType.questionSet ?? null;
  }
  getLesson() {
    return this.content.oneOfDomainContent.gtelpContent?.oneOfType.lesson ?? null;
  }
  getVocabulary() {
    return this.content.oneOfDomainContent.gtelpContent?.oneOfType.vocabulary ?? null;
  }
  getRecommendedElapsedTime() {
    const subject = this.content.oneOfDomainContent.gtelpContent?.subject;
    const numberOfQuestions =
      this.content.oneOfDomainContent.gtelpContent?.oneOfType.questionSet?.questions.length ?? 0;
    if (!subject) return null;
    return (gtelpRecommendMsSet[subject] ?? 0) * numberOfQuestions;
  }
  getGuessElapsedTime() {
    const subject = this.content.oneOfDomainContent.gtelpContent?.subject;

    if (subject === GTELP_SUBJECTS.rc_voca || subject === GTELP_SUBJECTS.lc) {
      return 30 * 1000;
    }
    return 3 * 1000;
  }
  isLC() {
    return this.content.oneOfDomainContent.gtelpContent?.subject === GTELP_SUBJECTS.lc;
  }
  isBasicQuestion() {
    // NOTE: M1, M2 시점에서 GTELP 도메인의 기초학습 성격 컨텐츠가 제공되지 않아 무조건 false를 내려줌
    return false;
  }
}

export {GtelpContentRouter};
