import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';

import {AlertProps, AlertDialog, AlertDialogProps} from '@santa-web/service-ui';
import {ALERT_DIALOG_Z_INDEX} from '@app/constants/zIndex';
import {useDialogContext} from '@app/contexts/DialogContext';

export interface CourseTargetScoreExitAlertDialogProps extends AlertDialogProps {
  closeTargetScoreModal: () => void;
}

export default React.memo<CourseTargetScoreExitAlertDialogProps>(
  React.forwardRef<HTMLDivElement, CourseTargetScoreExitAlertDialogProps>(function CourseTargetScoreExitAlertDialog(
    {closeTargetScoreModal, ...props},
    ref
  ) {
    const {t} = useTranslation();
    const {closeDialog} = useDialogContext();

    const handlePositiveClick = React.useCallback(() => {
      closeDialog();
      closeTargetScoreModal();
    }, [closeDialog, closeTargetScoreModal]);

    const AlertProps = useMemo(
      (): AlertProps => ({
        title: t('dialog_course_target_score_exit_alert_title'),
        description: t('dialog_course_target_score_exit_alert_description'),
        negativeLabel: t('dialog_course_target_score_exit_alert_negative_label'),
        positiveLabel: t('dialog_course_target_score_exit_alert_positive_label'),
        onNegativeClick: closeDialog,
        onPositiveClick: handlePositiveClick,
        css: css`
          white-space: pre-line;
        `,
      }),
      [closeDialog, handlePositiveClick, t]
    );

    return (
      <AlertDialog
        ref={ref}
        css={css`
          z-index: ${ALERT_DIALOG_Z_INDEX};
        `}
        AlertProps={AlertProps}
        isVisible
        {...props}
      />
    );
  })
);
