/* tslint:disable */
/* eslint-disable */
/**
 * - **SINGLE_PURCHASE**: 단건 결제
 * - **SUBSCRIPTION**: 구독
 * @export
 */
export const OrderPassStatusOneOfCase = {
    SINGLE_PURCHASE: 'SINGLE_PURCHASE',
    SUBSCRIPTION: 'SUBSCRIPTION'
};
export function OrderPassStatusOneOfCaseFromJSON(json) {
    return OrderPassStatusOneOfCaseFromJSONTyped(json, false);
}
export function OrderPassStatusOneOfCaseFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function OrderPassStatusOneOfCaseToJSON(value) {
    return value;
}
