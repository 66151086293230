/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { CheckRegisteredCouponResponseFromJSON, CouponFromJSON, ListAllAvailableDiscountCouponsResponseFromJSON, ListCouponsResponseFromJSON, RegisterCouponRequestToJSON, } from '../models';
/**
 *
 */
export class CouponV1Api extends runtime.BaseAPI {
    /**
     * 가장 최근에 등록된 쿠폰 정보를 조회한다.
     */
    async checkRegisteredCouponRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/user/v1/coupons/check-registered`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CheckRegisteredCouponResponseFromJSON(jsonValue));
    }
    /**
     * 가장 최근에 등록된 쿠폰 정보를 조회한다.
     */
    async checkRegisteredCoupon(requestParameters, initOverrides) {
        const response = await this.checkRegisteredCouponRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 사용 가능한 모든 할인 쿠폰 목록을 조회한다. ([주문에 설정할 쿠폰을 조회할 때 사용할 목적으로 추가함](https://riiid-pioneer.atlassian.net/browse/SP-80))
     */
    async listAllAvailableDiscountCouponsRaw(requestParameters, initOverrides) {
        if (requestParameters.discountMethods === null || requestParameters.discountMethods === undefined) {
            throw new runtime.RequiredError('discountMethods', 'Required parameter requestParameters.discountMethods was null or undefined when calling listAllAvailableDiscountCoupons.');
        }
        const queryParameters = {};
        if (requestParameters.discountMethods) {
            queryParameters['discountMethods'] = requestParameters.discountMethods;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/api/user/v1/coupons/all-available-discount-coupons`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListAllAvailableDiscountCouponsResponseFromJSON(jsonValue));
    }
    /**
     * 사용 가능한 모든 할인 쿠폰 목록을 조회한다. ([주문에 설정할 쿠폰을 조회할 때 사용할 목적으로 추가함](https://riiid-pioneer.atlassian.net/browse/SP-80))
     */
    async listAllAvailableDiscountCoupons(requestParameters, initOverrides) {
        const response = await this.listAllAvailableDiscountCouponsRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 사용자가 등록한 쿠폰 목록을 등록 시점 역순으로 조회한다.
     */
    async listCouponsRaw(requestParameters, initOverrides) {
        if (requestParameters.availableCondition === null || requestParameters.availableCondition === undefined) {
            throw new runtime.RequiredError('availableCondition', 'Required parameter requestParameters.availableCondition was null or undefined when calling listCoupons.');
        }
        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size', 'Required parameter requestParameters.size was null or undefined when calling listCoupons.');
        }
        const queryParameters = {};
        if (requestParameters.availableCondition !== undefined) {
            queryParameters['availableCondition'] = requestParameters.availableCondition;
        }
        if (requestParameters.cursor !== undefined) {
            queryParameters['cursor'] = requestParameters.cursor;
        }
        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/api/user/v1/coupons`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListCouponsResponseFromJSON(jsonValue));
    }
    /**
     * 사용자가 등록한 쿠폰 목록을 등록 시점 역순으로 조회한다.
     */
    async listCoupons(requestParameters, initOverrides) {
        const response = await this.listCouponsRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 쿠폰을 등록한다.
     */
    async registerCouponRaw(requestParameters, initOverrides) {
        if (requestParameters.registerCouponRequest === null || requestParameters.registerCouponRequest === undefined) {
            throw new runtime.RequiredError('registerCouponRequest', 'Required parameter requestParameters.registerCouponRequest was null or undefined when calling registerCoupon.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/user/v1/coupons`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RegisterCouponRequestToJSON(requestParameters.registerCouponRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CouponFromJSON(jsonValue));
    }
    /**
     * 쿠폰을 등록한다.
     */
    async registerCoupon(requestParameters, initOverrides) {
        const response = await this.registerCouponRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
