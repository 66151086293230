import {useQuery} from '@tanstack/react-query';

import {mobileService} from '@app/api/app-bridge/mobile-service';

const useMobileSafeArea = () => {
  return useQuery(['useMobileSafeArea'], async () => {
    try {
      const value = await mobileService.getSafeArea();
      return value ?? null;
    } catch {
      return null;
    }
  });
};

export default useMobileSafeArea;
