/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { CursorPageFromJSON, CursorPageToJSON, } from './CursorPage';
import { HighlightsPreviewFromJSON, HighlightsPreviewToJSON, } from './HighlightsPreview';
/**
 * Check if a given object implements the ListHighlightsPreviewsResponse interface.
 */
export function instanceOfListHighlightsPreviewsResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "highlightsPreviews" in value;
    return isInstance;
}
export function ListHighlightsPreviewsResponseFromJSON(json) {
    return ListHighlightsPreviewsResponseFromJSONTyped(json, false);
}
export function ListHighlightsPreviewsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'highlightsPreviews': (json['highlightsPreviews'].map(HighlightsPreviewFromJSON)),
        'page': !exists(json, 'page') ? undefined : CursorPageFromJSON(json['page']),
    };
}
export function ListHighlightsPreviewsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'highlightsPreviews': (value.highlightsPreviews.map(HighlightsPreviewToJSON)),
        'page': CursorPageToJSON(value.page),
    };
}
