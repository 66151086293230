import { ContentInteractionStateFromJSON, ContentInteractionStateToJSON, } from './ContentInteractionState';
/**
 * Check if a given object implements the UpdateContentInteractionStateRequest interface.
 */
export function instanceOfUpdateContentInteractionStateRequest(value) {
    let isInstance = true;
    isInstance = isInstance && "contentInteractionState" in value;
    isInstance = isInstance && "timestampClient" in value;
    return isInstance;
}
export function UpdateContentInteractionStateRequestFromJSON(json) {
    return UpdateContentInteractionStateRequestFromJSONTyped(json, false);
}
export function UpdateContentInteractionStateRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'contentInteractionState': ContentInteractionStateFromJSON(json['contentInteractionState']),
        'timestampClient': json['timestampClient'],
    };
}
export function UpdateContentInteractionStateRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'contentInteractionState': ContentInteractionStateToJSON(value.contentInteractionState),
        'timestampClient': value.timestampClient,
    };
}
