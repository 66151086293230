import {useMutation, useQueryClient} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';

import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {getGetUserConfigQueryKey} from '@app/queryKeys';
import {IUserConfig} from '@app/types/user-config';

interface MutationInput {
  userConfig: IUserConfig;
}

const useSetUserConfig = () => {
  const queryClient = useQueryClient();

  const {UserConfigService} = useAtomValue(santaOpenapiServicesAtom);

  return useMutation(
    ({userConfig}: MutationInput) => {
      return UserConfigService.updateMyUserConfig({
        updateMyUserConfigRequest: {userConfig: {json: JSON.stringify(userConfig)}},
      });
    },
    {
      onSuccess: async (_, {userConfig}) => {
        const getUserConfigQueryKey = getGetUserConfigQueryKey();

        await queryClient.cancelQueries(getUserConfigQueryKey);
        queryClient.setQueryData<IUserConfig>(getUserConfigQueryKey, () => userConfig);
      },
    }
  );
};

export default useSetUserConfig;
