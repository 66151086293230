/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { PointRewardRankEntryFromJSON, PointRewardRankEntryToJSON, } from './PointRewardRankEntry';
import { PointRewardRequestRewardUrlFromJSON, PointRewardRequestRewardUrlToJSON, } from './PointRewardRequestRewardUrl';
/**
 * Check if a given object implements the PointRewardRankPointStatus interface.
 */
export function instanceOfPointRewardRankPointStatus(value) {
    let isInstance = true;
    isInstance = isInstance && "isPaid" in value;
    isInstance = isInstance && "lastWeekTopPoint" in value;
    isInstance = isInstance && "myCurrentPoint" in value;
    isInstance = isInstance && "surroundings" in value;
    return isInstance;
}
export function PointRewardRankPointStatusFromJSON(json) {
    return PointRewardRankPointStatusFromJSONTyped(json, false);
}
export function PointRewardRankPointStatusFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'isPaid': json['isPaid'],
        'lastWeekRequestRewardUrl': !exists(json, 'lastWeekRequestRewardUrl') ? undefined : PointRewardRequestRewardUrlFromJSON(json['lastWeekRequestRewardUrl']),
        'lastWeekTopPoint': json['lastWeekTopPoint'],
        'myCurrentPoint': json['myCurrentPoint'],
        'surroundings': (json['surroundings'].map(PointRewardRankEntryFromJSON)),
    };
}
export function PointRewardRankPointStatusToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'isPaid': value.isPaid,
        'lastWeekRequestRewardUrl': PointRewardRequestRewardUrlToJSON(value.lastWeekRequestRewardUrl),
        'lastWeekTopPoint': value.lastWeekTopPoint,
        'myCurrentPoint': value.myCurrentPoint,
        'surroundings': (value.surroundings.map(PointRewardRankEntryToJSON)),
    };
}
