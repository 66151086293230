import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {COLOR_SANTA_B, COLOR_SANTA_E, COLOR_SANTA_J} from '@riiid/design-system';
import ChevronMiniRightIcon from '@riiid/design-system/icons/ChevronMiniRight';
import {Typography} from '@santa-web/service-ui';

type OfferGroupOrderCautionButtonProps = {onClick: () => void};

const OfferGroupOrderCautionButton = ({onClick}: OfferGroupOrderCautionButtonProps) => {
  const {t} = useTranslation();
  return (
    <button
      onClick={onClick}
      css={css`
        display: flex;
        justify-content: space-between;
        align-items: center;

        width: 100%;
        padding: 12px;
        border: 1px solid ${COLOR_SANTA_E};
        border-radius: 4px;
        background-color: ${COLOR_SANTA_B};

        cursor: pointer;
      `}>
      <Typography component="p" variant="caption1" color={COLOR_SANTA_J} align="left">
        {t('component_offer_group_order_caution_button')}
      </Typography>
      <ChevronMiniRightIcon width={24} height={24} color={COLOR_SANTA_J} />
    </button>
  );
};

export default OfferGroupOrderCautionButton;
export type {OfferGroupOrderCautionButtonProps};
OfferGroupOrderCautionButton.displayName = 'OfferGroupOrderCautionButton';
