import { SelfLearningUnitFilterFromJSON, SelfLearningUnitFilterToJSON, } from './SelfLearningUnitFilter';
/**
 * Check if a given object implements the ListSelfLearningUnitFiltersResponse interface.
 */
export function instanceOfListSelfLearningUnitFiltersResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "filters" in value;
    return isInstance;
}
export function ListSelfLearningUnitFiltersResponseFromJSON(json) {
    return ListSelfLearningUnitFiltersResponseFromJSONTyped(json, false);
}
export function ListSelfLearningUnitFiltersResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'filters': (json['filters'].map(SelfLearningUnitFilterFromJSON)),
    };
}
export function ListSelfLearningUnitFiltersResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'filters': (value.filters.map(SelfLearningUnitFilterToJSON)),
    };
}
