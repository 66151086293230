import React, {ReactNode} from 'react';
import {css} from '@emotion/react';
import {COLOR_SANTA_CARD_A, COLOR_SANTA_CARD_D} from '@riiid/design-system';
import {Column, ColumnProps} from '@app/components';
import {CouponStatusLabel, CouponStatusLabelProps} from '@app/features/coupon';

type CouponBaseCardProps = {isDisabled?: boolean; footer?: ReactNode} & Partial<
  Pick<CouponStatusLabelProps, 'status'>
> &
  ColumnProps<'div'>;

const CouponBaseCard = ({status, isDisabled = false, children, footer, ...columnProps}: CouponBaseCardProps) => {
  return (
    <Column
      css={css`
        width: 100%;
        background-color: ${isDisabled ? COLOR_SANTA_CARD_D : COLOR_SANTA_CARD_A};
        border-radius: 4px;
        padding: 20px;
      `}
      {...columnProps}>
      {status && (
        <CouponStatusLabel
          css={css`
            margin-left: auto;
          `}
          status={status}
        />
      )}
      <div
        css={css`
          opacity: ${isDisabled ? 0.6 : 1};
        `}>
        {children}
      </div>

      {/* isDisabled로 인한 Opacity 변화에 영향을 받으면 안되는 하단 컴포넌트 */}
      {footer}
    </Column>
  );
};

export default CouponBaseCard;
export type {CouponBaseCardProps};
CouponBaseCard.displayName = 'CouponBaseCard';
