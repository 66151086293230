import React from 'react';
import {css, SerializedStyles} from '@emotion/react';
import {COLOR_SANTA_I, COLOR_SANTA_L} from '@riiid/design-system';

import {Typography} from '@santa-web/service-ui';

export interface CardListProps extends Omit<React.HTMLAttributes<HTMLDivElement>, 'title'> {
  pageTitle?: React.ReactNode;
  pageTitleProps?: React.ComponentPropsWithRef<'div'>;
  title?: React.ReactNode;
  header?: React.ReactNode;
  listDivCss?: SerializedStyles;
}

type Props = CardListProps;

// TODO: distinguish card list by usage: report page, self learning page
const CardList = React.forwardRef<HTMLDivElement, Props>(
  ({pageTitle, pageTitleProps, title, children, header, listDivCss, ...props}, ref) => {
    const displayedHeader = (() => {
      if (title) {
        return (
          <Typography color={COLOR_SANTA_L} variant="h5" fontWeight="regular" data-testid="CardList-pageTitle">
            {title}
          </Typography>
        );
      } else if (header) {
        return header;
      } else {
        return null;
      }
    })();

    return (
      <div
        ref={ref}
        css={css`
          display: flex;
          flex-direction: column;
          width: 100%;
        `}
        {...props}>
        {pageTitle && (
          <div
            css={css`
              margin-bottom: 24px;
            `}
            data-testid="CardList-pageTitle"
            {...pageTitleProps}>
            <Typography color={COLOR_SANTA_I} variant="caption1" fontWeight="bold">
              {pageTitle}
            </Typography>
          </div>
        )}
        {displayedHeader && (
          <div
            css={css`
              margin-bottom: 40px;
            `}>
            {displayedHeader}
          </div>
        )}
        <div
          css={[
            css`
              display: grid;
              grid-gap: 12px;
              gap: 12px;
            `,
            listDivCss,
          ]}>
          {children}
        </div>
      </div>
    );
  }
);

export default React.memo(CardList);
