/* tslint:disable */
/* eslint-disable */
/**
 * 쿠폰 종류 (할인/권한 쿠폰)
 *
 * - **DISCOUNT**: 할인 쿠폰
 * - **PERMIT**: 권한 쿠폰
 * @export
 */
export const CouponDetailsCase = {
    DISCOUNT: 'DISCOUNT',
    PERMIT: 'PERMIT'
};
export function CouponDetailsCaseFromJSON(json) {
    return CouponDetailsCaseFromJSONTyped(json, false);
}
export function CouponDetailsCaseFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function CouponDetailsCaseToJSON(value) {
    return value;
}
