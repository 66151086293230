/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {useRouter} from 'next/router';
import {useQueryClient} from '@tanstack/react-query';
import {addDays} from 'date-fns';
import Decimal from 'decimal.js';
import {Order} from '@santa-web/gen/open-api/service';
import {mobileService} from '@app/api/app-bridge/mobile-service';
import {requestIamportPayment} from '@app/api/payment/iamport';
import useUser from '@app/hooks/user/useUser';
import {useTypedRouter, useTypedSearchParams} from '@app/hooks/useTypedRouter';
import {getOfferGroupOrderQueryKey} from '@app/queryKeys';
import {SantaNavigationPath} from '@app/types/navigation';
import {useStoreTransactionData} from '.';

export const useCheckoutOfferGroupOrder = (orderId: number) => {
  const searchParams = useTypedSearchParams('/offer-group/order');
  const router = useTypedRouter();
  const nextRouter = useRouter();
  const storeTransactionData = useStoreTransactionData();
  const queryClient = useQueryClient();

  const {data: user} = useUser();

  return async (): Promise<void> => {
    const order = queryClient.getQueryData<Order>(getOfferGroupOrderQueryKey(orderId))!;
    const paymentProvider = order.orderConfig?.selectedPaymentProvider;
    const isSubscription = order.offerGroup.offer.additionalProperties.subscription !== undefined;

    if (!paymentProvider) {
      throw new Error('no payment provider selected');
    }
    if (paymentProvider === 'IAMPORT_KCP' || paymentProvider === 'IAMPORT_NAVERPAY') {
      if (isSubscription) {
        throw new Error('iamport subscription payment not supported');
      }
      const selectedPg = ({IAMPORT_KCP: 'kcp', IAMPORT_NAVERPAY: 'naverpay'} as const)[paymentProvider];
      const offerGroup = order.offerGroup;
      const offer = order.offerGroup.offer;
      await requestIamportPayment(
        {
          productId: offer.id.toString(),
          // https://riiid.slack.com/archives/C0301C40NKC/p1729060834980699?thread_ts=1728978365.134749&cid=C0301C40NKC
          productTitle: `${offerGroup.displayConfig.name} - ${offer.displayConfig.name} (order id: ${offer.id})`,
          billId: order.paymentData!.billId,
          amount: new Decimal(order.amountData.totalChargeAmount).toNumber(),
          orderId: order.id.toString(),
          expectedFulfillmentsExpireAt:
            selectedPg === 'naverpay'
              ? addDays(
                  new Date(),
                  offer.additionalProperties.singlePurchase?.passExpirationInDays?.valueOf() ?? 0
                ).valueOf()
              : undefined,
          isMobile: false,
          referrer: undefined,
          method: 'card',
          pg: selectedPg,
          buyerTel: user?.personalInfo?.phoneNumber,
        },
        // NOTE: 이 path로 redirection됨
        SantaNavigationPath['/offer-group/order/complete']
      );
      return;
    } else if (paymentProvider === 'APP_STORE' || paymentProvider === 'PLAY_STORE') {
      try {
        // 값 상세에 대해서는 request_in_app_payment.proto 참고
        const {verificationData} = await mobileService.requestInAppPayment({
          isSubscription,
          storeProductId: order.orderConfig!.storeProductId!.toString(),
          purchaseIdentifier: order.paymentData!.billId.toString(),
        });
        storeTransactionData.set(verificationData);
        router.replace({pathname: '/offer-group/order/complete', query: searchParams});
      } catch (e) {
        router.replace({
          pathname: '/offer-group/order/complete',
          query: {...searchParams, error_msg: e instanceof Error ? e.message : String(e)},
        });
      }
      return;
    } else {
      // STRIPE
      if (!isSubscription) {
        throw new Error('stripe non-subscription payment not supported');
      }
      nextRouter.replace(order.paymentData!.stripePaymentUrl!);
    }
  };
};
