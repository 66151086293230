import {useQuery} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';
import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {getSystemConfigAppVersionsQueryKey} from '@app/queryKeys';

const PLATFORM_TO_SYSTEM_CONFIG_TYPE_MAP = {
  IOS: 'VERSIONS_IOS',
  ANDROID: 'VERSIONS_ANDROID',
} as const;

type AppVersion = {
  major: number;
  minor: number;
  patch: number;
};

type AppVersions = {
  required: AppVersion;
  optional: AppVersion;
  latest: AppVersion;
};

type RawAppVersion = `${number}.${number}.${number}`;

type RawSystemConfigAppVersions = {
  required: RawAppVersion;
  optional: RawAppVersion;
  latest: RawAppVersion;
};

type RawSystemConfigAppVersionsByCountry = {
  default: RawSystemConfigAppVersions | null;
  kr: RawSystemConfigAppVersions | null;
  jp: RawSystemConfigAppVersions | null;
  tw: RawSystemConfigAppVersions | null;
  us: RawSystemConfigAppVersions | null;
  vn: RawSystemConfigAppVersions | null;
};

function parseAppVersion(appVersion: RawAppVersion): AppVersion {
  const [major, minor, patch] = appVersion.split('.');

  return {
    major: Number(major),
    minor: Number(minor),
    patch: Number(patch),
  };
}

export const useGetSystemConfigAppVersionsQuery = (platform?: 'IOS' | 'ANDROID') => {
  const {SystemConfigService} = useAtomValue(santaOpenapiServicesAtom);
  return useQuery<AppVersions>({
    queryKey: getSystemConfigAppVersionsQueryKey(platform),
    queryFn: async () => {
      if (!platform) {
        throw new SystemConfigError('platform is required');
      }
      const {value} = await SystemConfigService.getSystemConfig({
        key: PLATFORM_TO_SYSTEM_CONFIG_TYPE_MAP[platform],
      });
      const versions = JSON.parse(value) as RawSystemConfigAppVersionsByCountry;
      if (!versions.default) {
        throw new SystemConfigError('default version not found');
      }
      return {
        required: parseAppVersion(versions.default.required),
        optional: parseAppVersion(versions.default.optional),
        latest: parseAppVersion(versions.default.latest),
      };
    },
    enabled: !!platform,
  });
};

class SystemConfigError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'SystemConfigError';
  }
}
