import {atom} from 'jotai';

import {cisService} from '@app/test-preparation/atoms/services';
import {PermissionDeniedError} from '@app/test-preparation/atoms/services/shared/errors/permission-denied';

type StartCisParams = {
  onPermissionDenied?(): void;
};

const startCisAtom = atom(null, async (get, set, {onPermissionDenied}: StartCisParams) => {
  try {
    await set(cisService.startCurrentCisAtom);
  } catch (error) {
    if (error instanceof PermissionDeniedError && onPermissionDenied) {
      return onPermissionDenied();
    }

    throw error;
  }
});

export {startCisAtom};
