import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {
  COLOR_RIIID_RED_1,
  COLOR_SANTA_B,
  COLOR_SANTA_BD,
  COLOR_SANTA_D,
  COLOR_SANTA_DD,
  COLOR_SANTA_G,
  COLOR_SANTA_I,
  COLOR_SANTA_J,
  COLOR_SANTA_L,
  COLOR_SPACE_BLUE_WHITE_3,
} from '@riiid/design-system';
import ChevronIcon from '@riiid/design-system/icons/ChevronRight';
import {URL_IMG_COUPON} from '@riiid/design-system/images/santa';
import {Typography} from '@santa-web/service-ui';
import {BaseCard, SkeletonGrayBox} from '@app/components';
import {withSkeleton} from '@app/utils/component';

type CurrentPassInformationCardProps = {
  title: string;
  description: {type: 'until' | 'string' | 'creation'; value: string};
  count?: {type: 'countdown' | 'amount'; value: number; isArgent?: boolean};
  shouldShowChevron?: boolean;
  onClick?: React.MouseEventHandler<HTMLDivElement>;
};

const CurrentPassInformationCard = ({
  title,
  description,
  count,
  shouldShowChevron,
  onClick,
}: CurrentPassInformationCardProps) => {
  const {t} = useTranslation();

  const descriptionComponent = React.useMemo(() => {
    switch (description.type) {
      case 'creation':
        return (
          <Typography component="p" variant="caption1" color={COLOR_SANTA_J}>
            <Trans
              t={t}
              i18nKey="component_current_learning_authority_creation_label"
              values={{time: description.value}}
              components={{b: <Typography component="span" variant="caption1" color={COLOR_SANTA_DD} />}}
            />
          </Typography>
        );
      case 'until':
        return (
          <Typography component="p" variant="caption1" color={COLOR_SANTA_I}>
            <Trans
              t={t}
              i18nKey="component_current_learning_authority_until_label"
              values={{date: description.value}}
            />
          </Typography>
        );
      case 'string':
        return (
          <Typography component="p" variant="caption1" color={COLOR_SANTA_I}>
            {description.value}
          </Typography>
        );
    }
  }, [description.type, description.value, t]);

  const countProps = React.useMemo(() => {
    if (!count) return null;
    switch (count.type) {
      case 'amount':
        return {
          value: t('component_current_learning_authority_count_label_amount', {n: count.value}),
          color: count.value === 0 ? COLOR_SANTA_G : COLOR_SANTA_J,
          backgroundColor: COLOR_SANTA_D,
        };
      case 'countdown':
        return {
          value: `D-${count.value}`,
          color: count.isArgent ? COLOR_SANTA_DD : COLOR_SANTA_BD,
          backgroundColor: count.isArgent ? COLOR_RIIID_RED_1 : COLOR_SPACE_BLUE_WHITE_3,
        };
    }
  }, [count, t]);

  return (
    <BaseCard
      css={css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 16px;
        background-color: ${COLOR_SANTA_B};
        border-color: 1px solid ${COLOR_SANTA_D};
      `}
      onClick={onClick}>
      <>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 4px;
          `}>
          <div
            css={css`
              display: flex;
              gap: 8px;
              align-items: center;
            `}>
            <img src={URL_IMG_COUPON} width={20} height={20} alt="" />
            <Typography component="p" variant="caption1" color={COLOR_SANTA_J}>
              {t('component_current_learning_authority_title')}
            </Typography>
          </div>
          <Typography variant="body3" fontWeight="bold" color={COLOR_SANTA_L}>
            {title}
          </Typography>
          {descriptionComponent}
        </div>
        {(countProps || shouldShowChevron) && (
          <div
            css={css`
              display: flex;
              gap: 12px;
              align-items: center;
            `}>
            {countProps && (
              <Typography
                css={css`
                  padding: 0 8px;
                  border-radius: 9999px;
                  background-color: ${countProps.backgroundColor};
                `}
                variant="body1"
                fontWeight="bold"
                color={countProps.color}>
                {countProps.value}
              </Typography>
            )}
            {shouldShowChevron && <ChevronIcon color={COLOR_SANTA_L} width={20} height={20} />}
          </div>
        )}
      </>
    </BaseCard>
  );
};

const Skeleton = () => {
  return (
    <BaseCard
      css={css`
        display: flex;
        align-items: center;
        justify-content: space-between;
        background-color: ${COLOR_SANTA_B};
        border-color: 1px solid ${COLOR_SANTA_D};
      `}>
      <div>
        <SkeletonGrayBox
          css={css`
            margin-bottom: 8px;
          `}
          width={144}
          height={16}
        />
        <SkeletonGrayBox
          css={css`
            margin-bottom: 4px;
          `}
          width={172}
          height={20}
        />
        <SkeletonGrayBox width={104} height={20} />
      </div>
      <SkeletonGrayBox width={52} height={20} />
    </BaseCard>
  );
};

export default withSkeleton(CurrentPassInformationCard, Skeleton);
export type {CurrentPassInformationCardProps};
CurrentPassInformationCard.displayName = 'CurrentPassInformationCard';
