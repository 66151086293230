/**
 * Check if a given object implements the PriceAmount interface.
 */
export function instanceOfPriceAmount(value) {
    let isInstance = true;
    isInstance = isInstance && "discountAmount" in value;
    isInstance = isInstance && "monthlySalesAmount" in value;
    isInstance = isInstance && "originalAmount" in value;
    isInstance = isInstance && "salesAmount" in value;
    return isInstance;
}
export function PriceAmountFromJSON(json) {
    return PriceAmountFromJSONTyped(json, false);
}
export function PriceAmountFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'discountAmount': json['discountAmount'],
        'monthlySalesAmount': json['monthlySalesAmount'],
        'originalAmount': json['originalAmount'],
        'salesAmount': json['salesAmount'],
    };
}
export function PriceAmountToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'discountAmount': value.discountAmount,
        'monthlySalesAmount': value.monthlySalesAmount,
        'originalAmount': value.originalAmount,
        'salesAmount': value.salesAmount,
    };
}
