import React from 'react';
import {css} from '@emotion/react';

export type DoubleColumnLayoutProps = React.ComponentPropsWithoutRef<'div'>;

const DoubleColumnLayout = React.memo(
  React.forwardRef<HTMLDivElement, DoubleColumnLayoutProps>(function DoubleColumnLayout(props, ref) {
    const {children, ...restProps} = props;
    return (
      <div
        ref={ref}
        css={[
          css`
            display: flex;
            justify-content: center;
            height: 100%;
          `,
        ]}
        {...restProps}>
        <div
          css={[
            css`
              width: 100%;
              max-width: 1360px;
            `,
          ]}>
          {children}
        </div>
      </div>
    );
  })
);

export default DoubleColumnLayout;
