import {LearningCellState, LearningCell} from '@santa-web/gen/open-api/content-learning-service';

class LearningCellAlreadyDoneError extends Error {
  constructor(public cause: LearningCell) {
    super('Learning cell already done error');

    this.name = 'LearningCellAlreadyDoneError';
  }

  static shouldThrow(learningCell: LearningCell) {
    return learningCell.state === LearningCellState.DONE;
  }
}

export {LearningCellAlreadyDoneError};
