import React from 'react';
import {css} from '@emotion/react';

export type WebComponentWrapperProps = React.ComponentPropsWithoutRef<'div'>;

const WebComponentWrapper = React.forwardRef<HTMLDivElement, WebComponentWrapperProps>(({children, ...props}, ref) => {
  return (
    <div
      css={css`
        width: 100%;
      `}
      ref={ref}
      {...props}>
      <div
        css={css`
          margin: 0 auto;
          max-width: 1080px;
        `}>
        {children}
      </div>
    </div>
  );
});

export default React.memo(WebComponentWrapper);
