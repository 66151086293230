import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {COLOR_SANTA_L, COLOR_SANTA_G, COLOR_SANTA_I} from '@riiid/design-system';
import InfoIcon from '@riiid/design-system/icons/Info';

import {Typography, TooltipBox} from '@santa-web/service-ui';
import DonutResultChart, {DonutResultChartProps} from '@app/components/DonutResultChart';
import {formatChartSeconds} from '@app/utils/text';

export interface ChartItemProps extends React.HTMLAttributes<HTMLDivElement> {
  title: string;
  type: DonutResultChartProps['chartType'];
  currentNumber: number;
  totalNumber: number;
  isKnowledgeVoca?: boolean;
}

const ChartItem = React.forwardRef<HTMLDivElement, ChartItemProps>(
  ({title, currentNumber, totalNumber, type, isKnowledgeVoca, ...props}, ref) => {
    const {t} = useTranslation();

    const knowledgeVocaTooltipContent = t('learning_cell_report_tooltip_knowledge_vocabularies');

    return (
      <div
        css={css`
          flex-grow: 1;
          flex-basis: 0;
          display: flex;
          flex-direction: column;
          align-items: center;
        `}
        ref={ref}
        {...props}>
        <DonutResultChart
          chartType={type}
          chartSize={96}
          value={{
            correct: currentNumber,
            total: totalNumber,
          }}
        />
        <Typography
          color={COLOR_SANTA_L}
          variant="caption2"
          fontWeight="bold"
          css={css`
            display: flex;
            align-items: center;
            margin-top: 8px;
          `}>
          {isKnowledgeVoca ? (
            <TooltipBox direction="bottom-left" size="large" variant="black" title={knowledgeVocaTooltipContent}>
              <div
                css={css`
                  display: flex;
                  align-items: center;
                `}>
                {title}
                <InfoIcon
                  type="line"
                  width={20}
                  height={20}
                  color={COLOR_SANTA_I}
                  css={css`
                    display: flex;
                    margin-left: 4px;
                  `}
                />
              </div>
            </TooltipBox>
          ) : (
            title
          )}
        </Typography>
        <Typography
          color={COLOR_SANTA_G}
          variant="caption2"
          display="block"
          css={css`
            height: 20px;
          `}>
          {type === 'time' ? t('learning_cell_report_recommended_time', {time: formatChartSeconds(totalNumber)}) : ''}
        </Typography>
      </div>
    );
  }
);

export default React.memo(ChartItem);
