import {useMutation, useQueryClient} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';
import {UpdateMyAgreementRequest} from '@santa-web/gen/open-api/service';
import santaAuthorizedOpenapiServicesAtom from '@app/atoms/core/santa-authorized-openapi-services';
import {getUserQueryKey} from '@app/queryKeys';

export const useUserAgreementMutation = () => {
  const queryClient = useQueryClient();
  const {UserService} = useAtomValue(santaAuthorizedOpenapiServicesAtom);
  return useMutation({
    mutationFn: async (request: UpdateMyAgreementRequest) => {
      const {user} = await UserService.updateMyAgreement({updateMyAgreementRequest: request});
      return user;
    },
    onSuccess: response => {
      queryClient.setQueryData(getUserQueryKey(), response);
    },
  });
};
