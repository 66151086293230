import produce from 'immer';
import {atom} from 'jotai';

import {ContentInteractionState} from '@santa-web/gen/open-api/content-learning-service';
import {cisRepository} from '@app/test-preparation/atoms/repositories';
import {cisService} from '@app/test-preparation/atoms/services';
import {PermissionDeniedError} from '@app/test-preparation/atoms/services/shared/errors/permission-denied';
import {handleUnexpectedError} from '@app/test-preparation/atoms/services/shared/errors/unexpected';

type StartCurrentCisParams = {
  isMutationOnly?: boolean;
};

const startCurrentCisAtom = atom(null, async (get, set, {isMutationOnly}: StartCurrentCisParams = {}) => {
  const startCis = await get(cisRepository.startCisAtom);

  const cises = get(cisService.cisesAtom);
  const currentCisIndex = get(cisService.currentCisIndexAtom);
  const cis = get(cisService.cisAtom);

  try {
    const nextCis: ContentInteractionState = produce(cis, draft => {
      draft.startedAtServer = new Date();
    });
    const nextCises = produce(cises, draft => {
      draft[currentCisIndex] = nextCis;
    });

    if (!isMutationOnly) {
      await startCis(nextCis.id);
    }

    set(cisService.cisesAtom, nextCises);
  } catch (error) {
    if (PermissionDeniedError.shouldThrow(error)) {
      throw new PermissionDeniedError(error);
    }

    handleUnexpectedError(error);
  }
});

export {startCurrentCisAtom};
