import {PriceFormatter} from './PriceFormatter';

export class JpPriceFormatter extends PriceFormatter {
  getCurrencyText(): string {
    return '円';
  }
  getLocale(): string {
    return 'ja-JP';
  }
  getCutUnit(): number {
    return 10;
  }
  getMonthlyCeilUnit(): number {
    return 1;
  }
}
