import {useMutation, useQueryClient} from '@tanstack/react-query';
import produce from 'immer';
import {useAtomValue} from 'jotai';

import {ContentInteractionState} from '@santa-web/gen/open-api/content-learning-service';
import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import useMutateContentInteractionStates from '@app/hooks/content-interaction-state/useMutateContentInteractionStates';
import {getContentInteractionStatesQueryKey} from '@app/queryKeys';

const useUpdateContentInteractionStates = (cellId: number | null | undefined, isOptimisticUpdating = true) => {
  const {ContentInteractionStateService} = useAtomValue(santaOpenapiServicesAtom);
  const queryClient = useQueryClient();
  const mutateContentInteractionStates = useMutateContentInteractionStates(cellId);

  return useMutation(
    async (contentInteractionStates: ContentInteractionState[]) => {
      const now = Date.now();

      await Promise.all(
        contentInteractionStates.map(contentInteractionState => {
          const newContentInteractionState = produce(contentInteractionState, draft => {
            if (draft.elementInteractionStates[0].details.voca?.selfReports !== undefined) {
              draft.elementInteractionStates[0].details.voca.selfReports =
                draft.elementInteractionStates[0].details.voca.selfReports.filter(
                  ({isKnowledge}) => isKnowledge !== undefined
                );
            }
          });

          return ContentInteractionStateService.updateContentInteractionState({
            contentInteractionStateId: contentInteractionState.id,
            updateContentInteractionStateRequest: {
              timestampClient: now,
              contentInteractionState: newContentInteractionState,
            },
          });
        })
      );
    },
    {
      async onMutate(contentInteractionStates: ContentInteractionState[]) {
        if (!isOptimisticUpdating) return;
        const queryKey = getContentInteractionStatesQueryKey(cellId);

        await queryClient.cancelQueries(queryKey);
        await mutateContentInteractionStates(contentInteractionStates);
      },
      async onError(e) {
        const queryKey = getContentInteractionStatesQueryKey(cellId);
        queryClient.removeQueries(queryKey);
        throw e;
      },
    }
  );
};

export default useUpdateContentInteractionStates;
