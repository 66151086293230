import useMedia from "react-use/lib/useMedia";
import { BREAKPOINT_MEDIUM, BREAKPOINT_LARGE } from "@riiid/design-system";
export function useBreakpoint(breakpoint) {
    return useMedia("screen and (min-width: ".concat(breakpoint, ")"));
}
export function useIsGteMediumScreen() {
    return useBreakpoint(BREAKPOINT_MEDIUM);
}
export function useIsGteLargeScreen() {
    return useBreakpoint(BREAKPOINT_LARGE);
}
