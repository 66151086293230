/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { GetLearningHistoryFilterItemsResponseFromJSON, ListLearningHistoriesResponseFromJSON, } from '../models';
/**
 *
 */
export class LearningHistoryV1Api extends runtime.BaseAPI {
    /**
     * 현재 학습 도메인에 대한 필터 선택지 목록을 조회한다. - 만약 이전에 해당 학습 도메인에서 `listLearningHistories` 요청을 통해 학습 기록을 조회한 적이 있을 경우   `isChecked`가 해당 시점에 학습 기록 조회 요청을 보낼 때 선택한 필터 아이템에 맞게 설정된다. - 만약 이전에 해당 학습 도메인에서 학습 기록을 조회한 적이 없을 경우 각 학습 도메인 별로 지정된 기본 값에 따라   `isChecked`이 설정된다.
     * 현재 학습 도메인에 맞는 필터 선택지 목록을 조회한다.
     */
    async getLearningHistoryFilterItemsRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/content-learning/v1/learning-histories/filter`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetLearningHistoryFilterItemsResponseFromJSON(jsonValue));
    }
    /**
     * 현재 학습 도메인에 대한 필터 선택지 목록을 조회한다. - 만약 이전에 해당 학습 도메인에서 `listLearningHistories` 요청을 통해 학습 기록을 조회한 적이 있을 경우   `isChecked`가 해당 시점에 학습 기록 조회 요청을 보낼 때 선택한 필터 아이템에 맞게 설정된다. - 만약 이전에 해당 학습 도메인에서 학습 기록을 조회한 적이 없을 경우 각 학습 도메인 별로 지정된 기본 값에 따라   `isChecked`이 설정된다.
     * 현재 학습 도메인에 맞는 필터 선택지 목록을 조회한다.
     */
    async getLearningHistoryFilterItems(requestParameters, initOverrides) {
        const response = await this.getLearningHistoryFilterItemsRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 선택한 필터를 바탕으로 완료한 `ContentInteractionState`에 대한 학습 기록을 조회한다. 정렬 순서는 `completedAt`의 내림차순이다. 또한 `filterItemIds`에 설정한 필터 선택지 ID에 따라 이후 `getLearningHistoryFilterItems` 요청의 응답이 변경된다. (Also see: [[DX] 복습 - 학습 기록 조회 순서](https://www.notion.so/riiid/DX-4d766876eb984cf38aa8dfb9fa696774?pvs=4#294d444068254cd195009036e3ba2b79))
     * 선택한 필터를 바탕으로 학습 기록을 조회한다.
     */
    async listLearningHistoriesRaw(requestParameters, initOverrides) {
        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size', 'Required parameter requestParameters.size was null or undefined when calling listLearningHistories.');
        }
        if (requestParameters.filterItemIds === null || requestParameters.filterItemIds === undefined) {
            throw new runtime.RequiredError('filterItemIds', 'Required parameter requestParameters.filterItemIds was null or undefined when calling listLearningHistories.');
        }
        const queryParameters = {};
        if (requestParameters.cursor !== undefined) {
            queryParameters['cursor'] = requestParameters.cursor;
        }
        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }
        if (requestParameters.filterItemIds) {
            queryParameters['filterItemIds'] = requestParameters.filterItemIds;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/api/content-learning/v1/learning-histories/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListLearningHistoriesResponseFromJSON(jsonValue));
    }
    /**
     * 선택한 필터를 바탕으로 완료한 `ContentInteractionState`에 대한 학습 기록을 조회한다. 정렬 순서는 `completedAt`의 내림차순이다. 또한 `filterItemIds`에 설정한 필터 선택지 ID에 따라 이후 `getLearningHistoryFilterItems` 요청의 응답이 변경된다. (Also see: [[DX] 복습 - 학습 기록 조회 순서](https://www.notion.so/riiid/DX-4d766876eb984cf38aa8dfb9fa696774?pvs=4#294d444068254cd195009036e3ba2b79))
     * 선택한 필터를 바탕으로 학습 기록을 조회한다.
     */
    async listLearningHistories(requestParameters, initOverrides) {
        const response = await this.listLearningHistoriesRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
