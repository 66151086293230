import React from 'react';
import {useTranslation} from 'react-i18next';
import {AlertDialog} from '@santa-web/service-ui';

type ExitConfirmDialogProps = {
  isEdit?: boolean;
  onCancelClick: () => void;
  onExitClick: () => void;
};

const ExitConfirmDialog = ({isEdit, onExitClick, onCancelClick}: ExitConfirmDialogProps) => {
  const {t} = useTranslation();

  const createAlertTitle = t(`page_exam_score_form_create_exit_alert_title`);
  const updateAlertTitle = t(`page_exam_score_form_update_exit_alert_title`);
  const commonDescription = t(`page_exam_score_form_common_exit_alert_description`);

  return (
    <AlertDialog
      isVisible
      AlertProps={{
        title: isEdit ? updateAlertTitle : createAlertTitle,
        description: commonDescription,
        positiveLabel: t('dict_exit'),
        onPositiveClick: onExitClick,
        negativeLabel: t('dict_cancel'),
        onNegativeClick: onCancelClick,
      }}
    />
  );
};

export default ExitConfirmDialog;

export type {ExitConfirmDialogProps};
ExitConfirmDialog.displayName = 'ExitConfirmDialog';
