function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
var visitLeafNode = function(rrtv2Json, onLeftNode) {
    var modifyPath = function(value, f) {
        path.push(value);
        var result = f();
        path.pop();
        return result;
    };
    var path = [];
    var visitor = {
        visitDocumentSections: function visitDocumentSections(visitor, _value) {
            return modifyPath("children", function() {
                var value = _object_spread({}, _value);
                if (value["children"] != null) {
                    value["children"] = value["children"].map(function(x, i) {
                        return modifyPath(i, function() {
                            return visitor.visitSection(visitor, x);
                        });
                    });
                }
                return value;
            });
        },
        visitDocumentBlockElements: function visitDocumentBlockElements(visitor, _value) {
            return modifyPath("children", function() {
                var value = _object_spread({}, _value);
                if (value["children"] != null) {
                    value["children"] = value["children"].map(function(x, i) {
                        return modifyPath(i, function() {
                            return visitor.visitBlockElement(visitor, x);
                        });
                    });
                }
                return value;
            });
        },
        visitDocumentInlineElements: function visitDocumentInlineElements(visitor, _value) {
            return modifyPath("children", function() {
                var value = _object_spread({}, _value);
                if (value["children"] != null) {
                    value["children"] = value["children"].map(function(x, i) {
                        return modifyPath(i, function() {
                            return visitor.visitInlineElement(visitor, x);
                        });
                    });
                }
                return value;
            });
        },
        visitSection: function visitSection(visitor, _value) {
            return modifyPath("children", function() {
                var value = _object_spread({}, _value);
                if (value["children"] != null) {
                    value["children"] = value["children"].map(function(x, i) {
                        return modifyPath(i, function() {
                            return visitor.visitBlockElement(visitor, x);
                        });
                    });
                }
                return value;
            });
        },
        visitBlockElement: function visitBlockElement(visitor, value) {
            return modifyPath(1, function() {
                switch(value[0]){
                    case "heading":
                        return [
                            value[0],
                            visitor.visitHeading(visitor, value[1])
                        ];
                    case "paragraph":
                        return [
                            value[0],
                            visitor.visitParagraph(visitor, value[1])
                        ];
                    case "figure":
                        return [
                            value[0],
                            visitor.visitFigure(visitor, value[1])
                        ];
                    case "line-numbered-area":
                        return [
                            value[0],
                            visitor.visitLineNumberedArea(visitor, value[1])
                        ];
                    case "precalculated-line-numbered-area":
                        return [
                            value[0],
                            visitor.visitPrecalculatedLineNumberedArea(visitor, value[1])
                        ];
                    case "dialog-list":
                        return [
                            value[0],
                            visitor.visitDialogList(visitor, value[1])
                        ];
                    case "note":
                        return [
                            value[0],
                            visitor.visitNote(visitor, value[1])
                        ];
                    case "table":
                        return [
                            value[0],
                            visitor.visitTable(visitor, value[1])
                        ];
                    default:
                        return value;
                }
            });
        },
        visitInlineElement: function visitInlineElement(visitor, value) {
            return modifyPath(1, function() {
                switch(value[0]){
                    case "text":
                        return [
                            value[0],
                            visitor.visitText(visitor, value[1])
                        ];
                    case "math-expression":
                        return [
                            value[0],
                            visitor.visitMathExpression(visitor, value[1])
                        ];
                    case "image":
                        return [
                            value[0],
                            visitor.visitImage(visitor, value[1])
                        ];
                    case "point":
                        return [
                            value[0],
                            visitor.visitPoint(visitor, value[1])
                        ];
                    case "refer-to":
                        return [
                            value[0],
                            visitor.visitReferTo(visitor, value[1])
                        ];
                    case "referred-by":
                        return [
                            value[0],
                            visitor.visitReferredBy(visitor, value[1])
                        ];
                    case "blank":
                        return [
                            value[0],
                            visitor.visitBlank(visitor, value[1])
                        ];
                    default:
                        return value;
                }
            });
        },
        visitMathExpression: function visitMathExpression(visitor, value) {
            return modifyPath(1, function() {
                switch(value[0]){
                    case "tex":
                        {
                            return [
                                value[0],
                                visitor.visitTex(visitor, value[1])
                            ];
                        }
                    default:
                        return value;
                }
            });
        },
        visitHeading: function visitHeading(visitor, _value) {
            var value = _object_spread({}, _value);
            if (value["level"] != null) {
                value["level"] = visitor.visitHeadingLevel(visitor, value["level"]);
            }
            if (value["align"] != null) {
                value["align"] = visitor.visitTextAlign(visitor, value["align"]);
            }
            if (value["children"] != null) {
                var children = value["children"];
                value["children"] = modifyPath("children", function() {
                    return children.map(function(x, i) {
                        return modifyPath(i, function() {
                            return visitor.visitInlineElement(visitor, x);
                        });
                    });
                });
            }
            return value;
        },
        visitParagraph: function visitParagraph(visitor, _value) {
            var value = _object_spread({}, _value);
            if (value["align"] != null) {
                value["align"] = visitor.visitTextAlign(visitor, value["align"]);
            }
            if (value["numbering"] != null) {
                value["numbering"] = visitor.visitNumbering(visitor, value["numbering"]);
            }
            if (value["children"] != null) {
                var children = value["children"];
                value["children"] = modifyPath("children", function() {
                    return children.map(function(x, i) {
                        return modifyPath(i, function() {
                            return visitor.visitInlineElement(visitor, x);
                        });
                    });
                });
            }
            return value;
        },
        visitFigure: function visitFigure(visitor, _value) {
            var value = _object_spread({}, _value);
            if (value["element"] != null) {
                value["element"] = visitor.visitFigureElement(visitor, value["element"]);
            }
            if (value["caption"] != null) {
                var caption = value["caption"];
                value["caption"] = modifyPath("caption", function() {
                    return caption.map(function(x, i) {
                        return modifyPath(i, function() {
                            return visitor.visitInlineElement(visitor, x);
                        });
                    });
                });
            }
            return value;
        },
        visitFigureElement: function visitFigureElement(visitor, value) {
            return modifyPath(1, function() {
                switch(value[0]){
                    case "image":
                        {
                            return [
                                value[0],
                                visitor.visitImage(visitor, value[1])
                            ];
                        }
                    default:
                        return value;
                }
            });
        },
        visitLineNumberedArea: function visitLineNumberedArea(visitor, _value) {
            var value = _object_spread({}, _value);
            if (value["children"] != null) {
                value["children"] = value["children"].map(function(x) {
                    return visitor.visitBlockElement(visitor, x);
                });
            }
            return value;
        },
        visitPrecalculatedLineNumberedArea: function visitPrecalculatedLineNumberedArea(visitor, _value) {
            var value = _object_spread({}, _value);
            if (value["lines"] != null) {
                value["lines"] = value["lines"].map(function(x) {
                    return visitor.visitPrecalculatedLine(visitor, x);
                });
            }
            return value;
        },
        visitPrecalculatedLine: function visitPrecalculatedLine(visitor, _value) {
            var value = _object_spread({}, _value);
            if (value["children"] != null) {
                value["children"] = value["children"].map(function(x) {
                    return visitor.visitInlineElement(visitor, x);
                });
            }
            return value;
        },
        visitDialogList: function visitDialogList(visitor, _value) {
            var value = _object_spread({}, _value);
            if (value["children"] != null) {
                var children = value["children"];
                value["children"] = modifyPath("children", function() {
                    return children.map(function(x, i) {
                        return modifyPath(i, function() {
                            return visitor.visitDialog(visitor, x);
                        });
                    });
                });
            }
            return value;
        },
        visitDialog: function visitDialog(visitor, _value) {
            var value = _object_spread({}, _value);
            if (value["children"] != null) {
                var children = value["children"];
                value["children"] = modifyPath("children", function() {
                    return children.map(function(x, i) {
                        return modifyPath(i, function() {
                            return visitor.visitInlineElement(visitor, x);
                        });
                    });
                });
            }
            return value;
        },
        visitText: function visitText(visitor, _value) {
            var value = _object_spread({}, _value);
            if (value["weight"] != null) {
                value["weight"] = visitor.visitTextWeight(visitor, value["weight"]);
            }
            if (value["supsub"] != null) {
                value["supsub"] = visitor.visitSupsub(visitor, value["supsub"]);
            }
            onLeftNode({
                path: path
            });
            return value;
        },
        visitTex: function visitTex(visitor, _value) {
            var value = _object_spread({}, _value);
            return value;
        },
        visitImage: function visitImage(visitor, _value) {
            var value = _object_spread({}, _value);
            return value;
        },
        visitTable: function visitTable(visitor, _value) {
            var value = _object_spread({}, _value);
            if (value["head"] != null) {
                var head = value["head"];
                value["head"] = modifyPath("head", function() {
                    return visitor.visitTableHead(visitor, head);
                });
            }
            if (value["body"] != null) {
                var body = value["body"];
                value["body"] = modifyPath("body", function() {
                    return visitor.visitTableBody(visitor, body);
                });
            }
            if (value["foot"] != null) {
                var foot = value["foot"];
                value["foot"] = modifyPath("foot", function() {
                    return visitor.visitTableFoot(visitor, foot);
                });
            }
            return value;
        },
        visitTableHead: function visitTableHead(visitor, _value) {
            var value = _object_spread({}, _value);
            if (value["rows"] != null) {
                var rows = value["rows"];
                value["rows"] = modifyPath("rows", function() {
                    return rows.map(function(x, i) {
                        return modifyPath(i, function() {
                            return visitor.visitTableRow(visitor, x);
                        });
                    });
                });
            }
            return value;
        },
        visitTableBody: function visitTableBody(visitor, _value) {
            var value = _object_spread({}, _value);
            if (value["rows"] != null) {
                var rows = value["rows"];
                value["rows"] = modifyPath("rows", function() {
                    return rows.map(function(x, i) {
                        return modifyPath(i, function() {
                            return visitor.visitTableRow(visitor, x);
                        });
                    });
                });
            }
            return value;
        },
        visitTableFoot: function visitTableFoot(visitor, _value) {
            var value = _object_spread({}, _value);
            if (value["rows"] != null) {
                var rows = value["rows"];
                value["rows"] = modifyPath("rows", function() {
                    return rows.map(function(x, i) {
                        return modifyPath(i, function() {
                            return visitor.visitTableRow(visitor, x);
                        });
                    });
                });
            }
            return value;
        },
        visitTableRow: function visitTableRow(visitor, _value) {
            var value = _object_spread({}, _value);
            if (value["cells"] != null) {
                var cells = value["cells"];
                value["cells"] = modifyPath("cells", function() {
                    return cells.map(function(x, i) {
                        return modifyPath(i, function() {
                            return visitor.visitTableCell(visitor, x);
                        });
                    });
                });
            }
            return value;
        },
        visitTableCell: function visitTableCell(visitor, _value) {
            var value = _object_spread({}, _value);
            if (value["align"] != null) {
                value["align"] = visitor.visitTextAlign(visitor, value["align"]);
            }
            if (value["realtor-background"] != null) {
                value["realtor-background"] = visitor.visitRealtorTableCellBackground(visitor, value["realtor-background"]);
            }
            if (value["children"] != null) {
                var children = value["children"];
                value["children"] = modifyPath("children", function() {
                    return children.map(function(x, i) {
                        return modifyPath(i, function() {
                            return visitor.visitBlockElement(visitor, x);
                        });
                    });
                });
            }
            return value;
        },
        visitPoint: function visitPoint(visitor, _value) {
            var value = _object_spread({}, _value);
            return value;
        },
        visitReferTo: function visitReferTo(visitor, _value) {
            var value = _object_spread({}, _value);
            return value;
        },
        visitReferredBy: function visitReferredBy(visitor, _value) {
            var value = _object_spread({}, _value);
            if (value["children"] != null) {
                value["children"] = value["children"].map(function(x) {
                    return visitor.visitInlineElement(visitor, x);
                });
            }
            return value;
        },
        visitNote: function visitNote(visitor, _value) {
            var value = _object_spread({}, _value);
            if (value["children"] != null) {
                var children = value["children"];
                value["children"] = modifyPath("children", function() {
                    return children.map(function(x, i) {
                        return modifyPath(i, function() {
                            return visitor.visitInlineElement(visitor, x);
                        });
                    });
                });
            }
            return value;
        },
        visitBlank: function visitBlank(visitor, _value) {
            var value = _object_spread({}, _value);
            onLeftNode({
                path: path
            });
            return value;
        },
        visitRealtorTableCellBackground: function visitRealtorTableCellBackground(visitor, value) {
            return value;
        },
        visitTextWeight: function visitTextWeight(visitor, value) {
            return value;
        },
        visitTextAlign: function visitTextAlign(visitor, value) {
            return value;
        },
        visitSupsub: function visitSupsub(visitor, value) {
            return value;
        },
        visitHeadingLevel: function visitHeadingLevel(visitor, value) {
            return value;
        },
        visitNumbering: function visitNumbering(visitor, value) {
            return value;
        }
    };
    if (rrtv2Json.documentBlockElements) {
        visitor.visitDocumentBlockElements(visitor, rrtv2Json.documentBlockElements);
    } else if (rrtv2Json.documentInlineElements) {
        visitor.visitDocumentInlineElements(visitor, rrtv2Json.documentInlineElements);
    } else if (rrtv2Json.documentSections) {
        visitor.visitDocumentSections(visitor, rrtv2Json.documentSections);
    }
};
export default visitLeafNode;
