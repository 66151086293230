import React from 'react';
import {OrderPass, PassListItemV2} from '@santa-web/gen/open-api/service';
import {useActivatePassMutation, useCanActivatePass, usePassActivationBottomSheet} from '@app/features/permit/hooks';
import {useTypedRouter} from '@app/hooks/useTypedRouter';

export const useActivatePass = () => {
  const router = useTypedRouter();
  const canActivatePass = useCanActivatePass();
  const {mutateAsync: activatePass, isLoading} = useActivatePassMutation();
  const {openPassActivationBottomSheet} = usePassActivationBottomSheet();

  return {
    activatePass: React.useCallback(
      (pass: (OrderPass | PassListItemV2) & {learningDomainDisplayName: string}) => {
        if (!canActivatePass) return;
        openPassActivationBottomSheet(pass.displayInfo.name, pass.learningDomainDisplayName, async () => {
          await activatePass(pass.id);
          router.push('/');
        });
        return;
      },
      [activatePass, canActivatePass, openPassActivationBottomSheet, router]
    ),
    isActivatingPass: isLoading,
  };
};
