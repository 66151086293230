import React from 'react';
import {useTranslation} from 'react-i18next';
import SettingIcon from '@riiid/design-system/icons/Setting';

import {IconButton, IconButtonProps, Tooltip, TooltipCardProps} from '@santa-web/service-ui';
import useShouldShowTutorial from '@app/hooks/useShouldShowTutorial';

export type TestprepConfigIconButtonProps = Partial<IconButtonProps> & {shouldShowConfigTooltip?: boolean};

const TestprepConfigIconButton = ({
  IconProps,
  onClick,
  shouldShowConfigTooltip,
  ...props
}: TestprepConfigIconButtonProps) => {
  const {t} = useTranslation();
  const getShouldShowTutorial = useShouldShowTutorial();
  const [isTooltipOpened, setIsTooltipOpened] = React.useState(false);
  const [shouldShowTutorial, setShouldShowTutorial] = React.useState(false);
  const iconProps: IconButtonProps['IconProps'] = {
    ...IconProps,
    type: 'line',
  };
  const tooltipCardProps: TooltipCardProps = {
    color: 'brand',
    size: 'medium',
    arrowDirection: 'block-end',
    arrowAlign: 'end',
    content: t('testprep_config_switchable_tooltip_font_size_adjust'),
  };

  React.useEffect(() => {
    (async () => {
      const {showTutorial: shouldShowTutorial} = await getShouldShowTutorial({tutorialType: 'FONT_SIZE_ADJUST'});
      if (!shouldShowTutorial) {
        return;
      }
      setShouldShowTutorial(shouldShowTutorial);
    })();
  }, [getShouldShowTutorial]);

  React.useEffect(() => {
    if (shouldShowTutorial && shouldShowConfigTooltip) {
      setIsTooltipOpened(true);
      setTimeout(() => {
        setIsTooltipOpened(false);
      }, 3000);
    }
  }, [shouldShowConfigTooltip, shouldShowTutorial]);

  return (
    <Tooltip isOpened={isTooltipOpened} TooltipCardProps={tooltipCardProps}>
      <IconButton {...props} Icon={SettingIcon} IconProps={iconProps} onClick={onClick} />
    </Tooltip>
  );
};

export default React.memo(TestprepConfigIconButton);
