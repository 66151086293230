import {RiiidRichTextV2Path, RiiidRichTextV2Point, RiiidRichTextViewV2Selection} from '@santa-web/service-ui';
import {
  RIIID_RICH_TEXT_V2_VIEW_ID,
  TEXT_SEGMENT_ELEMENT,
  TEXT_VIEW_NODE_ID,
} from '@santa-web/service-ui/src/components/RiiidRichTextV2View/const';

type Point = {
  x: number;
  y: number;
};

export type RiiidRichTextViewV2WordPosition = {
  selection: RiiidRichTextViewV2Selection;
  range: Range;
  word: string;
};

const VIEW_SIDE_PADDING = 40;

export const isTextSegment = (element: Element) => {
  return element.getAttribute(TEXT_SEGMENT_ELEMENT) !== null;
};

export const findClosestScrollableElement = (element: Element | null): Element | null => {
  let targetElement = element;

  while (targetElement !== null) {
    if (targetElement.scrollHeight > targetElement.clientHeight) {
      return targetElement;
    }

    targetElement = targetElement.parentElement;
  }

  return null;
};

export const findClosestBlockElement = (element: Element | null): Element | null => {
  let targetElement = element;

  // TODO: data-testid 수정 필요
  while (targetElement !== null && targetElement !== document.body) {
    if (targetElement.getAttribute('data-testid') === 'BlockElementListView-block-element') {
      return targetElement;
    }

    targetElement = targetElement.parentElement;
  }

  return null;
};

export const findTextSegmentByAdjustedPoint = (
  element: Element,
  initialPoint: Point,
  bottomAdjustValue: number
): Element | null => {
  if (isTextSegment(element)) {
    return element;
  }

  const adjustedPoint = {
    x: initialPoint.x < VIEW_SIDE_PADDING ? VIEW_SIDE_PADDING : initialPoint.x,
    y: initialPoint.y,
  };

  const adjustedElement = document.elementFromPoint(adjustedPoint.x, adjustedPoint.y);
  if (adjustedElement !== null && isTextSegment(adjustedElement)) {
    return adjustedElement;
  }

  if (adjustedElement?.getAttribute(TEXT_SEGMENT_ELEMENT) === null) {
    return document.elementFromPoint(adjustedPoint.x, initialPoint.y - bottomAdjustValue);
  }

  return null;
};

const getAllTextViewWordsInView = (viewer: Element) => {
  return [...viewer.querySelectorAll(`[${RIIID_RICH_TEXT_V2_VIEW_ID}]`)].flatMap(
    element => element.textContent?.split(/(?= )/g) ?? []
  );
};

export const getAllWordPositionsInView = (
  viewer: Element,
  getTextViewPath: (id: string) => RiiidRichTextV2Path | null
): RiiidRichTextViewV2WordPosition[] => {
  const textViewElements = [...viewer.querySelectorAll(`[${RIIID_RICH_TEXT_V2_VIEW_ID}]`)];

  const textSegmentElements = textViewElements
    .flatMap(textViewElement => {
      const textSegmentElementsInTextView = [...textViewElement.querySelectorAll(`[${TEXT_SEGMENT_ELEMENT}]`)];
      const textViewId = textViewElement.getAttribute(TEXT_VIEW_NODE_ID);
      if (textViewId === null) {
        return [];
      }

      const path = getTextViewPath(textViewId) ?? [];

      return textSegmentElementsInTextView.map(textSegmentElement => ({
        element: textSegmentElement,
        path,
        content: textSegmentElement.textContent ?? '',
      }));
    })
    .filter(textSegment => textSegment.element.childNodes[0] !== undefined);

  const positions: RiiidRichTextViewV2WordPosition[] = [];

  const words = getAllTextViewWordsInView(viewer);
  const textViewsSet = new Set();

  const cursor = {
    index: 0,
    offset: 0,
    textViewOffset: 0,
  };

  words.forEach(word => {
    const range = new Range();

    if (word.length === 0) {
      return;
    }

    const selection: RiiidRichTextViewV2Selection = {
      anchor: null,
      focus: null,
    };

    if (!textViewsSet.has(textSegmentElements[cursor.index].path.join(','))) {
      cursor.offset = 0;
      cursor.textViewOffset = 0;
      textViewsSet.add(textSegmentElements[cursor.index].path.join(','));
    }

    const nextCursor = {...cursor};

    const currentTextSegment = textSegmentElements[cursor.index];

    range.setStart(currentTextSegment.element.childNodes[0], cursor.offset);

    if (word.length < currentTextSegment.content.length - cursor.offset) {
      selection.anchor = {
        path: currentTextSegment.path,
        offset: cursor.textViewOffset,
      };
      selection.focus = {
        path: currentTextSegment.path,
        offset: cursor.textViewOffset + word.length,
      };

      nextCursor.offset += word.length;
      nextCursor.textViewOffset += word.length;
      range.setEnd(currentTextSegment.element.childNodes[0], cursor.offset + word.length);
    }
    // 단어가 현재 TextSegment의 남은 content와 동일한 경우 (TextViewNode가 바뀌기 바로 직전 Segment는 이쪽 조건을 만족)
    else if (word.length === currentTextSegment.content.length - cursor.offset) {
      selection.anchor = {
        path: currentTextSegment.path,
        offset: cursor.textViewOffset,
      };
      selection.focus = {
        path: currentTextSegment.path,
        offset: cursor.textViewOffset + word.length,
      };

      nextCursor.offset = 0;
      nextCursor.textViewOffset += word.length;
      nextCursor.index += 1;
      range.setEnd(currentTextSegment.element.childNodes[0], cursor.offset + word.length);
    }
    // 단어가 여러 TextSegment에 걸쳐있는 경우
    else {
      let remainedWordLength = word.length - (currentTextSegment.content.length - cursor.offset);

      const innerCursor = cursor.index + 1;

      for (let i = innerCursor; i < textSegmentElements.length; i += 1) {
        if (!textViewsSet.has(textSegmentElements[i].path.join(','))) {
          nextCursor.offset = 0;
          nextCursor.textViewOffset = 0;
          textViewsSet.add(textSegmentElements[cursor.index].path.join(','));
        }

        if (remainedWordLength < textSegmentElements[i].content.length) {
          range.setEnd(textSegmentElements[i].element.childNodes[0], remainedWordLength);

          nextCursor.index = i;
          nextCursor.offset = remainedWordLength;
          nextCursor.textViewOffset += word.length;

          selection.anchor = {
            path: currentTextSegment.path,
            offset: cursor.textViewOffset,
          };
          selection.focus = {
            path: textSegmentElements[nextCursor.index].path,
            offset: nextCursor.textViewOffset,
          };

          break;
        } else if (remainedWordLength === textSegmentElements[i].content.length) {
          range.setEnd(textSegmentElements[i].element.childNodes[0], textSegmentElements[i].content.length);

          nextCursor.index = i + 1;
          nextCursor.offset = 0;
          nextCursor.textViewOffset += word.length;

          selection.anchor = {
            path: currentTextSegment.path,
            offset: cursor.textViewOffset,
          };
          selection.focus = {
            path: textSegmentElements[nextCursor.index - 1].path,
            offset: nextCursor.textViewOffset,
          };

          break;
        }
        remainedWordLength -= textSegmentElements[i].content.length;
      }
    }

    cursor.index = nextCursor.index;
    cursor.offset = nextCursor.offset;
    cursor.textViewOffset = nextCursor.textViewOffset;

    positions.push({
      range,
      selection,
      word,
    });
  });

  return positions;
};

// a > b 1, a = b 0, a < b -1
export const compareRiiidRichTextV2Point = (a: RiiidRichTextV2Point, b: RiiidRichTextV2Point): number => {
  // path가 같은 경우
  if (a.path.toString() === b.path.toString()) {
    if (a.offset === b.offset) {
      return 0;
    }

    return a.offset > b.offset ? 1 : -1;
  }

  const comparisonLength = Math.min(a.path.length, b.path.length);

  for (let i = 0; i < comparisonLength; i += 1) {
    if (a.path[i] !== b.path[i] && !isNaN(Number(a.path[i])) && !isNaN(Number(b.path[i]))) {
      return a.path[i] > b.path[i] ? 1 : -1;
    }
  }

  return 0;
};
