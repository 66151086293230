import React, {useMemo} from 'react';
import {css} from '@emotion/react';
import {BREAKPOINT_MEDIUM} from '@riiid/design-system';

import {useFloatingChatButtonList} from '@santa-web/service-ui';
import {VirtualExamFloatingChatButtonBox} from '@app/components/virtual-exam';
import useFloatingAreaHeight from '@app/hooks/useFloatingAreaHeight';
import {BasePart} from '@app/new-structure/entities/base/parts';
import SingleColumnLayout from '@app/test-preparation/components/SingleColumnLayout';
import Part1DirectionView from './layouts/Part1DirectionView';
import Part2DirectionView from './layouts/Part2DirectionView';
import Part3DirectionView from './layouts/Part3DirectionView';
import Part4DirectionView from './layouts/Part4DirectionView';
import Part5DirectionView from './layouts/Part5DirectionView';
import Part6DirectionView from './layouts/Part6DirectionView';
import Part7DirectionView from './layouts/Part7DirectionView';

export interface VirtualExamDirectionViewProps extends React.ComponentPropsWithoutRef<'div'> {
  part: BasePart;
}

const VirtualExamDirectionView = React.forwardRef<HTMLDivElement, VirtualExamDirectionViewProps>(
  ({part, ...props}, ref) => {
    const mainView = useMemo(() => {
      switch (part) {
        case 'PART_1':
          return <Part1DirectionView {...props} ref={ref} />;
        case 'PART_2':
          return <Part2DirectionView {...props} ref={ref} />;
        case 'PART_3':
          return <Part3DirectionView {...props} ref={ref} />;
        case 'PART_4':
          return <Part4DirectionView {...props} ref={ref} />;
        case 'PART_5':
          return <Part5DirectionView {...props} ref={ref} />;
        case 'PART_6':
          return <Part6DirectionView {...props} ref={ref} />;
        case 'PART_7':
          return <Part7DirectionView {...props} ref={ref} />;
        default:
          throw new Error('Direction should exist only from part 1 to part 7');
      }
    }, [part, props, ref]);

    const {floatingChatButtonList} = useFloatingChatButtonList();
    const floatingAreaHeight = useFloatingAreaHeight();

    return (
      <SingleColumnLayout>
        <div
          css={css`
            flex: 1;
            padding: 20px;
            margin-bottom: ${floatingAreaHeight}px;
            @media (min-width: ${BREAKPOINT_MEDIUM}) {
              padding: 40px;
            }
          `}>
          {mainView}
        </div>
        <VirtualExamFloatingChatButtonBox>{floatingChatButtonList}</VirtualExamFloatingChatButtonBox>
      </SingleColumnLayout>
    );
  }
);

export default VirtualExamDirectionView;
