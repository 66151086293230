import {useRouter} from 'next/router';
import React, {useState, useRef, useEffect, useCallback} from 'react';
import {GoogleOAuthProvider} from '@react-oauth/google';
import {useAtomValue} from 'jotai';
import {v4 as generateUuid} from 'uuid';

import {CommonCheckUserExistsResponse} from '@santa-web/gen/open-api/service';
import {mobileService} from '@app/api/app-bridge/mobile-service';
import santaAuthorizedOpenapiServicesAtom from '@app/atoms/core/santa-authorized-openapi-services';
import tokenManagerAtom from '@app/atoms/core/token-manager';
import config from '@app/config';
import {useUserExistsBottomSheet, SnsSignupAndSigninInfo, useSnsLogin, isSocialSignUpVariant} from '@app/features/auth';
import {authorizeKakao, authorizeApple, getLineIDToken} from '@app/features/auth/api';
import {SignupPageView} from '@app/features/auth/views';
import {
  isB2bState,
  isRedirectionState,
  redirect,
  useGoToB2bOfferGroupOrderPage,
  useQueryState,
} from '@app/misc/query-state';
import {isAppBridgeAvailable} from '@app/utils/app-bridge';

const SignupPageContainer = () => {
  const router = useRouter();
  const {AuthService} = useAtomValue(santaAuthorizedOpenapiServicesAtom);
  const tokenManager = useAtomValue(tokenManagerAtom);
  const login = useSnsLogin();
  const queryState = useQueryState();
  const goToB2bOfferGroupOrderPage = useGoToB2bOfferGroupOrderPage();

  // NOTE: email signup is occurred in `signup/email` page
  const handleSnsLogin = useCallback(
    async (request?: SnsSignupAndSigninInfo) => {
      if (request == null) throw new Error('Request cannot be null');
      const userToken = await login(request);
      tokenManager.setToken(userToken);
      if (isB2bState(queryState)) {
        await goToB2bOfferGroupOrderPage(queryState);
      } else if (isRedirectionState(queryState)) {
        await redirect(router, queryState);
      } else {
        await router.push('/');
      }
    },
    [goToB2bOfferGroupOrderPage, login, queryState, router, tokenManager]
  );

  const [isSigningUp, setIsSigningUp] = useState(false);
  const loginRequestRef = useRef<SnsSignupAndSigninInfo>();

  const {openBottomSheet} = useUserExistsBottomSheet();

  const handleSnsSignup = useCallback(
    async ({email, name, ...request}: SnsSignupAndSigninInfo & {email?: string; name?: string}) => {
      const handleBottomSheetLogin = async () => handleSnsLogin(loginRequestRef.current);

      try {
        setIsSigningUp(true);

        let response: CommonCheckUserExistsResponse;
        switch (request.socialSignupVariant) {
          case 'APPLE':
            response = await AuthService.checkUserExistsWithApple({
              checkUserExistsWithAppleRequest: {
                registrationInfo: request.registrationInfo,
              },
            });
            break;
          case 'GOOGLE':
            response = await AuthService.checkUserExistsWithGoogle({
              checkUserExistsWithGoogleRequest: {
                registrationInfo: request.registrationInfo,
              },
            });
            break;
          case 'KAKAO':
            response = await AuthService.checkUserExistsWithKakao({
              checkUserExistsWithKakaoRequest: {
                registrationInfo: request.registrationInfo,
              },
            });
            break;
          case 'LINE':
            response = await AuthService.checkUserExistsWithLine({
              checkUserExistsWithLineRequest: {
                registrationInfo: request.registrationInfo,
              },
            });
        }
        const exists = response.isExisting;
        if (exists) {
          loginRequestRef.current = request;
          openBottomSheet(handleBottomSheetLogin);
          return;
        }

        if (!isSocialSignUpVariant(request.socialSignupVariant))
          throw new Error(`${request.socialSignupVariant} is not a requestKey of social sign up`);
        await router.push(
          {
            pathname: `/signup/sns`,
            query: {
              request: JSON.stringify(request),
              email: email && encodeURIComponent(email),
              name: name && encodeURIComponent(name),
              ...queryState.toQuery(),
            },
          },
          `/signup/sns`
        );
      } finally {
        setIsSigningUp(false);
      }
    },
    [AuthService, handleSnsLogin, openBottomSheet, queryState, router]
  );

  const handleGoogleSignup = async ({idToken, email, name}: {idToken: string; email?: string; name?: string}) => {
    try {
      await handleSnsSignup({
        socialSignupVariant: 'GOOGLE',
        registrationInfo: {
          idToken,
        },
        email,
        name,
      });
    } catch (e) {
      // TODO: Error handling
      console.error(e);
    }
  };

  const handleKakaoSignup = async () => {
    try {
      const {kakaoAccessToken, email, name} = await authorizeKakao();
      await handleSnsSignup({
        socialSignupVariant: 'KAKAO',
        registrationInfo: {
          kakaoAccessToken,
        },
        email,
        name,
      });
    } catch (e) {
      // TODO: Error handling
      console.error(e);
    }
  };

  const handleAppleSignup = async () => {
    try {
      const redirectUri = window.location.origin + window.location.pathname;
      const {identityToken, authorizationCode, email, name} = await authorizeApple(redirectUri, queryState.toQuery());
      await handleSnsSignup({
        socialSignupVariant: 'APPLE',
        registrationInfo: {
          identityToken,
          authorizationCode,
          redirectUri,
        },
        email,
        name,
      });
    } catch (e) {
      // TODO: Error handling
      console.error(e);
    }
  };

  const handleLineAuthenticate = async () => {
    if (isAppBridgeAvailable()) {
      const {
        authData: {lineIdToken},
        email,
        name,
      } = await mobileService.requestSocialLogin('LINE');
      await handleSnsSignup({
        socialSignupVariant: 'LINE',
        registrationInfo: {
          lineIdToken,
        },
        email,
        name,
      });
      return;
    }
    const redirectUri = window.location.origin + window.location.pathname;
    try {
      // Line Authorization
      router.push({
        pathname: config.lineAuthUrl,
        query: {
          response_type: 'code',
          client_id: config.lineClientId,
          redirect_uri: redirectUri,
          state: generateUuid(),
          scope: 'openid',
        },
      });
    } catch (e) {
      // TODO: Error handling
      console.error(e);
    }
  };

  const handleEmailSignup = () => {
    router.push({pathname: '/signup/email', query: queryState.toQuery()});
  };

  useEffect(() => {
    const {query} = router;

    const handleLineSignup = async (code: string) => {
      try {
        setIsSigningUp(true);
        const redirectUri = window.location.origin + window.location.pathname;
        const token = await getLineIDToken(code, redirectUri);

        await handleSnsSignup({
          socialSignupVariant: 'LINE',
          registrationInfo: {
            lineIdToken: token,
          },
        });
      } catch (e) {
        // TODO: Error handling
        console.error(e);
      }
    };
    const lineCode = Array.isArray(query['code']) ? query['code'].join('') : query['code'];
    if (lineCode) {
      handleLineSignup(lineCode);
    }
  }, [AuthService, handleSnsSignup, openBottomSheet, router]);

  return (
    <GoogleOAuthProvider clientId={config.googleClientId}>
      <SignupPageView
        onKakaoSignup={handleKakaoSignup}
        onGoogleSignup={handleGoogleSignup}
        onAppleSignup={handleAppleSignup}
        onLineSignup={handleLineAuthenticate}
        onEmailSignup={handleEmailSignup}
        isSigningUp={isSigningUp}
      />
    </GoogleOAuthProvider>
  );
};

export default SignupPageContainer;
