import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {COLOR_SANTA_L, COLOR_SANTA_O} from '@riiid/design-system';

import {Typography, useIsGteMediumScreen} from '@santa-web/service-ui';
import AnalyticsTimeCard from '@app/components/card/AnalyticsTimeCard';
import BaseCard, {CardProps} from '@app/components/card/BaseCard';
import {formatChartSeconds} from '@app/utils/text';

export interface ResponsiveTimeCardProps extends CardProps {
  title?: string;
  seconds: number;
}

const ResponsiveTimeCard = React.forwardRef<HTMLDivElement, ResponsiveTimeCardProps>(
  ({title, seconds, ...props}, ref) => {
    const {t} = useTranslation();
    const isGteMediumScreen = useIsGteMediumScreen();

    const cardTitle = React.useMemo(() => title ?? t('learning_cell_report_total_elapsed_time'), [title, t]);

    return isGteMediumScreen ? (
      <AnalyticsTimeCard title={cardTitle} time={seconds} ref={ref} />
    ) : (
      <BaseCard
        ref={ref}
        css={css`
          padding: 20px;
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}
        {...props}>
        <Typography color={COLOR_SANTA_L} variant="body2" fontWeight="bold">
          {cardTitle}
        </Typography>
        <Typography color={COLOR_SANTA_O} variant="body2" fontWeight="regular">
          {formatChartSeconds(seconds)}
        </Typography>
      </BaseCard>
    );
  }
);

export default React.memo(ResponsiveTimeCard);
