function _array_like_to_array(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _array_without_holes(arr) {
    if (Array.isArray(arr)) return _array_like_to_array(arr);
}
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _iterable_to_array(iter) {
    if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}
function _non_iterable_spread() {
    throw new TypeError("Invalid attempt to spread non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function _to_consumable_array(arr) {
    return _array_without_holes(arr) || _iterable_to_array(arr) || _unsupported_iterable_to_array(arr) || _non_iterable_spread();
}
function _unsupported_iterable_to_array(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _array_like_to_array(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _array_like_to_array(o, minLen);
}
// To-do: We should be calculating scale transformation
var defaultScale = {
    scaleX: 1,
    scaleY: 1
};
var createWrapListSortingStrategy = function(containerWidth) {
    return function wrapListSortingStrategy(param) {
        var rects = param.rects, activeIndex = param.activeIndex, overIndex = param.overIndex, index = param.index;
        var _replacedRects, _Math;
        var replacedRects = _to_consumable_array(rects);
        (_replacedRects = replacedRects).splice.apply(_replacedRects, [
            overIndex,
            0
        ].concat(_to_consumable_array(replacedRects.splice(activeIndex, 1))));
        var left = rects[0].left;
        var top = rects[0].top;
        var lastLineBreakIndex = 0;
        var getItemGapWidth = function() {
            return rects.length <= 1 ? 0 : rects[1].left - (rects[0].left + rects[0].width);
        };
        var getMaxHeight = function(newLineBreakIndex) {
            return (_Math = Math).max.apply(_Math, _to_consumable_array(replacedRects.slice(lastLineBreakIndex, newLineBreakIndex).map(function(x) {
                return x.height;
            })));
        };
        var getItemGapHeight = function() {
            if (rects.length <= 1) return 0;
            var nextLineIdx = rects.findIndex(function(x) {
                return x.top > rects[0].top;
            });
            if (nextLineIdx < 0) return 0;
            return rects[nextLineIdx].top - (rects[0].top + getMaxHeight(nextLineIdx));
        };
        var itemGapWidth = getItemGapWidth();
        var itemGapHeight = getItemGapHeight();
        var newRects = replacedRects.map(function(newRect, i) {
            var maxLeft = rects[0].left + containerWidth;
            if (left + newRect.width > maxLeft) {
                left = rects[0].left;
                top += getMaxHeight(i) + itemGapHeight;
                lastLineBreakIndex = i;
            }
            var rect = {
                left: left,
                top: top
            };
            left += newRect.width + itemGapWidth;
            return rect;
        });
        var newIndex = index === activeIndex ? overIndex : activeIndex < index && index <= overIndex ? index - 1 : overIndex <= index && index < activeIndex ? index + 1 : index;
        return _object_spread({
            x: newRects[newIndex].left - rects[index].left,
            y: newRects[newIndex].top - rects[index].top
        }, defaultScale);
    };
};
export default createWrapListSortingStrategy;
