import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {COLOR_FIX_SANTA_GRAY_9} from '@riiid/design-system';

import {TextButton} from '@santa-web/service-ui';
import {
  HIGHLIGHT_COLORS,
  TOOL_BOX_HEIGHT,
  TOOL_BOX_ARROW_SIZE,
  TOOL_BOX_ARROW_SIDE_GAP,
  TOOL_BOX_POSITION_TOP,
  TOOL_BOX_POSITION_BOTTOM,
  TOOL_BOX_POSITION_LEFT,
  TOOL_BOX_POSITION_RIGHT,
  TOOL_BOX_POSITION_CENTER,
} from '@app/test-preparation/constants/highlight';
import {useHighlightToolBoxContext} from '@app/test-preparation/contexts/HighlightToolBoxContext';

type ColorChipProps = React.ComponentProps<'div'> & {color: string};

const ColorChip = ({color, ...props}: ColorChipProps) => (
  <div
    {...props}
    css={css`
      width: 20px;
      height: 20px;
      border-radius: 50%;
      background: ${color};
      cursor: pointer;
    `}></div>
);

export type HighlightToolBoxProps = React.ComponentPropsWithoutRef<'div'>;

const HighlightToolBox = React.forwardRef<HTMLDivElement, HighlightToolBoxProps>(
  ({...props}: HighlightToolBoxProps, ref): JSX.Element => {
    const {t} = useTranslation();
    const highlightToolBoxContext = useHighlightToolBoxContext();

    if (!highlightToolBoxContext) {
      throw new Error('useHighlightToolBoxContext must be used within a HighlightToolBoxProvider');
    }

    const {color, setColor, isColorPicking, setIsColorPicking, isEditing, onHighlightAdd, onHighlightRemove} =
      highlightToolBoxContext;

    const handlePointerEvent = React.useCallback((event: React.PointerEvent) => {
      document.getSelection()?.empty();
      event.stopPropagation();
      event.preventDefault();
    }, []);

    return (
      <div
        {...props}
        css={css`
          display: none;
          position: absolute;
          top: 0;
          left: 0;
          z-index: 100;
          user-select: none;
        `}
        ref={ref}
        onPointerDown={handlePointerEvent}
        onPointerUp={handlePointerEvent}>
        <div
          css={css`
            display: inline-grid;
            grid-template-columns: ${Array.from(Array(!isColorPicking ? 2 : HIGHLIGHT_COLORS.length))
              .map(() => 'auto')
              .join(' ')};
            grid-column-gap: 22px;
            align-items: center;
            background: ${COLOR_FIX_SANTA_GRAY_9};
            height: ${TOOL_BOX_HEIGHT}px;
            padding: 0px 16px;
            border-radius: 4px;
            position: relative;

            &::before {
              content: '';
              width: 0;
              height: 0;
              border-left: ${TOOL_BOX_ARROW_SIZE}px solid transparent;
              border-right: ${TOOL_BOX_ARROW_SIZE}px solid transparent;
              position: absolute;
            }
            .${TOOL_BOX_POSITION_TOP} &::before {
              border-top: ${TOOL_BOX_ARROW_SIZE}px solid ${COLOR_FIX_SANTA_GRAY_9};
              bottom: -${TOOL_BOX_ARROW_SIZE}px;
            }
            .${TOOL_BOX_POSITION_BOTTOM} &::before {
              border-bottom: ${TOOL_BOX_ARROW_SIZE}px solid ${COLOR_FIX_SANTA_GRAY_9};
              top: -${TOOL_BOX_ARROW_SIZE}px;
            }
            .${TOOL_BOX_POSITION_LEFT} &::before {
              right: ${TOOL_BOX_ARROW_SIDE_GAP}px;
            }
            .${TOOL_BOX_POSITION_RIGHT} &::before {
              left: ${TOOL_BOX_ARROW_SIDE_GAP}px;
            }
            .${TOOL_BOX_POSITION_CENTER} &::before {
              left: calc((100% - ${TOOL_BOX_ARROW_SIZE * 2}px) / 2);
            }
          `}>
          {!isColorPicking ? (
            <>
              {!isEditing ? (
                <TextButton typographyVariant="body3" fontWeight="regular" color="white-fix" onClick={onHighlightAdd}>
                  {t('highlight_toolbox_create_label')}
                </TextButton>
              ) : (
                <TextButton
                  typographyVariant="body3"
                  fontWeight="regular"
                  color="white-fix"
                  onClick={onHighlightRemove}>
                  {t('highlight_toolbox_delete_label')}
                </TextButton>
              )}
              <ColorChip color={color} onClick={() => setIsColorPicking(true)} />
            </>
          ) : (
            HIGHLIGHT_COLORS.map(color => (
              <ColorChip
                key={color}
                color={color}
                onClick={() => {
                  setColor(color);
                }}
              />
            ))
          )}
        </div>
      </div>
    );
  }
);

export default React.memo(HighlightToolBox);
