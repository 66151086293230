import React from 'react';
import {useTranslation} from 'react-i18next';

import {useAtomValue} from 'jotai';
import {TopNavBar, TopNavBarProps} from '@santa-web/service-ui';
import {learningStore} from '@app/test-preparation/atoms/stores';

export interface ToeicSelfQuestionTopNavBarProps extends TopNavBarProps {
  contentInteractionStateIndex?: number;
}

function ToeicSelfQuestionTopNavBar(props: ToeicSelfQuestionTopNavBarProps): React.ReactElement | null {
  const {...restProps} = props;

  const {t} = useTranslation();

  const currentCisIndex = useAtomValue(learningStore.cises.currentCisIndexAtom);
  const totalElapsedTime = useAtomValue(learningStore.cises.totalElapsedTimeAtom);

  const content = t('learning_cell_top_nav_bar_self_question_content_question', {
    numberOfQuestions: currentCisIndex + 1,
  });
  const timerSeconds = totalElapsedTime / 1000;

  return <TopNavBar content={content} paginationIcon="exit" timerSeconds={timerSeconds} {...restProps} />;
}

export default React.memo(ToeicSelfQuestionTopNavBar);
