import {PriceFormatter} from './PriceFormatter';

export class VnPriceFormatter extends PriceFormatter {
  getCurrencyText(): string {
    return ' đ';
  }
  getLocale(): string {
    return 'vi-VN';
  }
  getCutUnit(): number {
    return 1000;
  }
  getMonthlyCeilUnit(): number {
    return 3;
  }
}
