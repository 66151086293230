import {useAtomValue} from 'jotai';
import {StartDiagnosisBadRequestErrorCase} from '@santa-web/gen/open-api/content-learning-service';

import useLevelTestEvents from '@app/api/google-tag-manager/level-test';
import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import useDiagnosisQuery from '@app/features/diagnosis/hooks/useDiagnosisLearningCellQuery';
import {useTypedRouter} from '@app/hooks/useTypedRouter';
import {useQueryState} from '@app/misc/query-state';
import {LearningCellReferrer} from '@app/types/learning-cell';
import {SantaResponseError} from '@app/utils/error';
import {useDiagnosisConfigQuery, useDiagnosisListeningBottomSheet} from '.';

export default function useGoToDiagnosisLearningCell(referrer?: LearningCellReferrer) {
  const router = useTypedRouter();
  const queryState = useQueryState();
  const {DiagnosisService} = useAtomValue(santaOpenapiServicesAtom);
  const {data: diagnosisLearningCell} = useDiagnosisQuery();
  const {data: diagnosisConfig} = useDiagnosisConfigQuery();
  const {openDiagnosisListeningBottomSheet} = useDiagnosisListeningBottomSheet();
  const {pushStartLevelTestEvent} = useLevelTestEvents();
  const fromParam = referrerToFromParameter(referrer);

  const _goToDiagnosisLearningCell = async (learningCellId: number) => {
    await router.push({
      pathname: '/learning-cell',
      query: {...queryState.toQuery(), cellId: learningCellId, referrer},
    });
  };

  const getDiagnosisLearningCellId = async (shouldIncludeListening: boolean) => {
    try {
      const {learningCellId} = await DiagnosisService.startDiagnosis({
        diagnosisContentOption: shouldIncludeListening ? 'LC_RC' : 'RC',
      });
      return learningCellId;
    } catch (e) {
      if (e instanceof SantaResponseError) {
        if (e.santaErrorCode === StartDiagnosisBadRequestErrorCase.DIAGNOSIS_ALREADY_EXISTS) {
          const {learningCellId} = await DiagnosisService.getDiagnosis({});
          return learningCellId;
        }
      }
      throw e;
    }
  };

  const goToDiagnosisLearningCell = async () => {
    if (diagnosisLearningCell === undefined) return;
    if (diagnosisLearningCell) {
      fromParam && pushStartLevelTestEvent({from: fromParam, isContinued: true});
      await _goToDiagnosisLearningCell(diagnosisLearningCell.learningCellId);
    } else {
      const handleDiagnosisListeningAnswer = async (shouldIncludeListening: boolean) => {
        fromParam && pushStartLevelTestEvent({from: fromParam, isContinued: false});
        const learningCellId = await getDiagnosisLearningCellId(shouldIncludeListening);
        _goToDiagnosisLearningCell(learningCellId);
      };
      const isListeningAvailable = diagnosisConfig?.config.lcAvailable;

      if (isListeningAvailable) {
        const isListeningRequired = diagnosisConfig.config.lcRequired;
        openDiagnosisListeningBottomSheet(isListeningRequired, handleDiagnosisListeningAnswer);
      } else {
        await handleDiagnosisListeningAnswer(false);
      }
    }
  };

  return goToDiagnosisLearningCell;
}

const referrerToFromParameter = (referrer: LearningCellReferrer) => {
  switch (referrer) {
    case 'course':
      return 'course';
    case 'onboarding':
      return 'onboarding';
    case 'self_learning':
      return 'selective';
    case 'analytics':
      return 'analytics';
    default:
      return null;
  }
};
