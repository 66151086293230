import {Portal} from 'react-portal';
import {css} from '@emotion/react';
import {BREAKPOINT_MEDIUM, COLOR_SANTA_C} from '@riiid/design-system';

import {ElementInteractionState, QuestionState} from '@santa-web/gen/open-api/content-learning-service';
import {QuestionSet} from '@santa-web/gen/open-api/content/models/QuestionSet';
import {SingleQuestionTitle, PlayButton, HelpArea, ObjectiveQuestionPage} from '@santa-web/service-ui';
import ReplayAudioTutorialTooltip from '@app/components/ContentView/components/ReplayAudioTutorialTooltip';
import {getQuestionTitle} from '@app/components/ContentView/utilities';
import {isEmptySnippet} from '@app/facade/snippet';
import {use100vh} from '@app/hooks/use100vh';
import ExplanationListView from '@app/test-preparation/components/ExplanationListView';
import PassageListView from '@app/test-preparation/components/PassageListView';
import QuestionView, {ChoiceAnswerHandler} from '@app/test-preparation/components/QuestionView';
import SnippetView from '@app/test-preparation/components/SnippetView';
import {IsUserHighlightEnabledContext} from '@app/test-preparation/contexts/IsUserHighlightEnabledContext';
import {SnippetViewBaseIdContext} from '@app/test-preparation/contexts/SnippetViewBaseIdContext';

export interface GtelpListeningQuestionViewProps {
  audio: React.ReactNode;
  soundController: React.ReactNode;
  shouldShowReplayAudioTutorial: boolean;
  isLoading: boolean;
  isQuestionViewDisabled: boolean;
  isExplanationVisible: boolean;
  questionSet: QuestionSet | null | undefined;
  questionElementStates?: ElementInteractionState[];
  questionTitle: string;
  startQuestionIndex: number;
  onQuestionStateChange?(questionIndex: number): (questionState: QuestionState) => void;
  onPlayButtonClick(): void;
  canSelectUnsolvableQuestion: boolean;
  onChoiceAnswer?: ChoiceAnswerHandler;
  isUserHighlightEnabled: boolean;
  isCompleted: boolean;
  cisId: number;
  contentId: string;
}

const GtelpListeningQuestionView = ({
  audio,
  soundController,
  shouldShowReplayAudioTutorial,
  isLoading,
  isQuestionViewDisabled,
  isExplanationVisible,
  questionSet,
  questionElementStates,
  questionTitle,
  startQuestionIndex,
  onQuestionStateChange,
  onPlayButtonClick,
  canSelectUnsolvableQuestion,
  onChoiceAnswer,
  isUserHighlightEnabled,
  isCompleted,
  cisId,
  contentId,
}: GtelpListeningQuestionViewProps) => {
  const viewportHeight = use100vh();
  const playButton = (
    <ReplayAudioTutorialTooltip isOpened={shouldShowReplayAudioTutorial}>
      {isExplanationVisible && <PlayButton isDisabled={isLoading} onClick={onPlayButtonClick} />}
    </ReplayAudioTutorialTooltip>
  );

  const soundControllerElement = (
    <Portal>
      <div
        css={css`
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          height: ${viewportHeight};
          pointer-events: none;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
        `}>
        {soundController}
      </div>
    </Portal>
  );

  const passageBodies = questionSet?.passages.map(passage => passage.body) ?? [];
  const passageListView = !passageBodies.every(isEmptySnippet) && <PassageListView passages={questionSet?.passages} />;
  const explanationListView = isExplanationVisible && <ExplanationListView explanations={questionSet?.explanations} />;
  const questions = questionSet?.questions;
  const description = questionSet?.description;

  return (
    <SnippetViewBaseIdContext.Provider value={cisId.toString()}>
      <IsUserHighlightEnabledContext.Provider value={isUserHighlightEnabled}>
        {audio}
        {soundControllerElement}
        <div>
          {description && (
            <div
              css={css`
                @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
                  padding: 40px 20px 0;
                }
              `}>
              <HelpArea isIconVisible>
                <SnippetView snippet={description} />
              </HelpArea>
            </div>
          )}
          <div
            css={css`
              & > *:not(:last-child)::after {
                display: flex;
                width: 100%;
                height: 12px;
                background: ${COLOR_SANTA_C};
                content: '';
              }
            `}>
            {isExplanationVisible ? (
              <div>
                <ObjectiveQuestionPage
                  questionTitle={<SingleQuestionTitle content={questionTitle} playButton={playButton} />}
                  passages={passageListView}
                  explanations={explanationListView}
                />
              </div>
            ) : (
              passageListView && (
                <div>
                  <ObjectiveQuestionPage passages={passageListView} />
                </div>
              )
            )}
            {questions?.map((question, questionIndex) => (
              <div key={questionIndex}>
                <QuestionView
                  questionIndex={questionIndex}
                  questionTitle={
                    <SingleQuestionTitle
                      content={getQuestionTitle(startQuestionIndex + questionIndex)}
                      playButton={playButton}
                    />
                  }
                  isDisabled={isQuestionViewDisabled}
                  isExplanationInvisible={!isExplanationVisible}
                  isPassageInvisible={!isCompleted}
                  question={question}
                  questionState={questionElementStates?.[questionIndex].details.question}
                  elementInteractionStateId={questionElementStates?.[questionIndex].id}
                  isCompleted={isCompleted}
                  onChange={onQuestionStateChange?.(questionIndex)}
                  canSelectUnsolvableQuestion={canSelectUnsolvableQuestion}
                  onChoiceAnswer={onChoiceAnswer}
                  contentId={contentId}
                />
              </div>
            ))}
          </div>
        </div>
      </IsUserHighlightEnabledContext.Provider>
    </SnippetViewBaseIdContext.Provider>
  );
};

export default GtelpListeningQuestionView;
