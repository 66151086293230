import {useMemo} from 'react';
import {usePushEvent} from '.';

type OnbType = 'registration_onb' | 'change_domain_onb';

interface SelectTestEventPayload extends Record<string, unknown> {
  answer: 'y' | 'n';
  from_onb: OnbType;
}

interface SelectScoreEventPayload extends Record<string, unknown> {
  score: number;
  from_onb: OnbType;
}

type LearningDomainSelectEventPayload = {
  from_onb: OnbType;
};

const useOnboardingEvents = () => {
  const pushEvent = usePushEvent();

  const onboardingEvents = useMemo(
    () => ({
      pushStartOnboardingEvent: async () => {
        await pushEvent('start_onboarding');
      },

      pushStartSignupEvent: async () => {
        await pushEvent('start_signup');
      },

      pushLoginFromOnboardingEvent: async () => {
        await pushEvent('login_from_onboarding');
      },

      pushLearningDomainSelect: async (payload: LearningDomainSelectEventPayload) => {
        await pushEvent('onb_learningdomain_select', payload);
      },

      pushOnboardingTimerClick: async () => {
        await pushEvent('onboarding_timer_button');
      },

      pushSelectTestEvent: async (payload: SelectTestEventPayload) => {
        await pushEvent('select_test', payload);
      },

      pushSelectScoreEvent: async (payload: SelectScoreEventPayload) => {
        await pushEvent('select_score', payload);
      },
    }),
    [pushEvent]
  );

  return onboardingEvents;
};

export default useOnboardingEvents;
