import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';

import {Button, BottomSheetProps} from '@santa-web/service-ui';
import BottomSheetWithDim, {BottomSheetWithDimProps} from '@app/components/BottomSheetWithDim';
import {ALERT_DIALOG_Z_INDEX} from '@app/constants/zIndex';
import {useDialogContext} from '@app/contexts/DialogContext';

export interface DiagnosisBottomSheetWithDimProps extends BottomSheetWithDimProps {
  onPositiveClick?(): void;
  onNegativeClick?(): void;
}

export default React.memo(
  React.forwardRef<HTMLDivElement, DiagnosisBottomSheetWithDimProps>(
    ({onPositiveClick, onNegativeClick, ...props}, ref) => {
      const {t} = useTranslation();
      const {closeDialog} = useDialogContext();
      const bottomSheetProps = React.useMemo(
        (): BottomSheetProps => ({
          title: t('bottom_sheet_diagnosis_listening_title'),
          description: t('bottom_sheet_diagnosis_listening_description'),
          isLoadingAiAnimationVisible: true,
          buttons: [
            <Button colorVariant="brand" isFullWidth onClick={onPositiveClick} key="withLC">
              {t('__diagnosis_intro_availableTestLc_answer_positive')}
            </Button>,
            <Button variant="box-line" isFullWidth onClick={onNegativeClick} key="withoutLC">
              {t('__diagnosis_intro_availableTestLc_answer_negative')}
            </Button>,
          ],
          onCloseClick: closeDialog,
        }),
        [onPositiveClick, onNegativeClick, closeDialog, t]
      );
      return (
        <BottomSheetWithDim
          ref={ref}
          css={css`
            z-index: ${ALERT_DIALOG_Z_INDEX};
          `}
          isOpened
          BottomSheetProps={bottomSheetProps}
          {...props}
        />
      );
    }
  )
);
