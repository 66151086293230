import Link from 'next/link';
import React from 'react';
import {z} from 'zod';
import {convertTypedUrlToNextUrl, TypedUrl, NextUrl} from '@app/hooks/useTypedRouter';
import {SantaNavigationPath, TYPED_QUERY_METADATA} from '@app/types/navigation';

type TypedLinkProps<
  HrefPath extends SantaNavigationPath,
  AsPath extends SantaNavigationPath,
  HrefQuery extends z.infer<(typeof TYPED_QUERY_METADATA)[HrefPath]>,
  AsQuery extends z.infer<(typeof TYPED_QUERY_METADATA)[AsPath]>
> = Omit<Parameters<typeof Link>[0], 'href' | 'as'> & {
  href: TypedUrl<NextUrl, HrefPath, HrefQuery>;
  as?: TypedUrl<NextUrl, AsPath, AsQuery>;
};

export const TypedLink = <
  HrefPath extends SantaNavigationPath,
  AsPath extends SantaNavigationPath,
  HrefQuery extends z.infer<(typeof TYPED_QUERY_METADATA)[HrefPath]>,
  AsQuery extends z.infer<(typeof TYPED_QUERY_METADATA)[AsPath]>
>({
  href,
  as,
  ...props
}: TypedLinkProps<HrefPath, AsPath, HrefQuery, AsQuery>) => {
  return <Link {...props} href={convertTypedUrlToNextUrl(href)} as={as && convertTypedUrlToNextUrl(as)} />;
};

export default TypedLink;
export type {TypedLinkProps};
TypedLink.displayName = 'TypedLink';
