/**
 * Check if a given object implements the ToeicVirtualExamQuestionAnswerState interface.
 */
export function instanceOfToeicVirtualExamQuestionAnswerState(value) {
    let isInstance = true;
    isInstance = isInstance && "answer" in value;
    isInstance = isInstance && "eliminations" in value;
    return isInstance;
}
export function ToeicVirtualExamQuestionAnswerStateFromJSON(json) {
    return ToeicVirtualExamQuestionAnswerStateFromJSONTyped(json, false);
}
export function ToeicVirtualExamQuestionAnswerStateFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'answer': json['answer'],
        'eliminations': json['eliminations'],
    };
}
export function ToeicVirtualExamQuestionAnswerStateToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'answer': value.answer,
        'eliminations': value.eliminations,
    };
}
