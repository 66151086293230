/* tslint:disable */
/* eslint-disable */
/**
 * - **ACTIVE**: 구독중
 * - **CANCELED**: 해지했으나 이용 기간이 남은 경우
 * - **EXPIRED**: 기간만료
 * - **REFUNDED**: 환불완료
 * - **TERMINATED**: 구독중지
 * @export
 */
export const SubscriptionPassStatus = {
    ACTIVE: 'ACTIVE',
    CANCELED: 'CANCELED',
    EXPIRED: 'EXPIRED',
    REFUNDED: 'REFUNDED',
    TERMINATED: 'TERMINATED'
};
export function SubscriptionPassStatusFromJSON(json) {
    return SubscriptionPassStatusFromJSONTyped(json, false);
}
export function SubscriptionPassStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function SubscriptionPassStatusToJSON(value) {
    return value;
}
