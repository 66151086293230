/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { CheckNewOfferGroupResponseFromJSON, GetOfferGroupBoardResponseFromJSON, GetOfferGroupResponseFromJSON, } from '../models';
/**
 *
 */
export class OfferV1Api extends runtime.BaseAPI {
    /**
     * 유저의 국가 및 학습 도메인의 Default OfferGroupBoard에 등록된 OfferGroup이 있는지 확인한다. OfferGroup이 있는 경우, 가장 최근 노출되기 시작한 OfferGroup의 노출 시작 시점을 조회한다.
     */
    async checkNewOfferGroupRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/commerce/v1/offer/offer-group/check-new`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CheckNewOfferGroupResponseFromJSON(jsonValue));
    }
    /**
     * 유저의 국가 및 학습 도메인의 Default OfferGroupBoard에 등록된 OfferGroup이 있는지 확인한다. OfferGroup이 있는 경우, 가장 최근 노출되기 시작한 OfferGroup의 노출 시작 시점을 조회한다.
     */
    async checkNewOfferGroup(requestParameters, initOverrides) {
        const response = await this.checkNewOfferGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * ID에 해당하는 OfferGroup을 조회한다.
     */
    async getOfferGroupRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getOfferGroup.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/commerce/v1/offer/offer-group/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetOfferGroupResponseFromJSON(jsonValue));
    }
    /**
     * ID에 해당하는 OfferGroup을 조회한다.
     */
    async getOfferGroup(requestParameters, initOverrides) {
        const response = await this.getOfferGroupRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * boardCode에 해당하는 OfferGroupBoard를 조회한다.  boardCode가 없으면 유저의 국가 및 학습 도메인의 Default OfferGroupBoard를 조회한다.
     */
    async getOfferGroupBoardRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        if (requestParameters.boardCode !== undefined) {
            queryParameters['boardCode'] = requestParameters.boardCode;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/api/commerce/v1/offer/offer-group-board`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetOfferGroupBoardResponseFromJSON(jsonValue));
    }
    /**
     * boardCode에 해당하는 OfferGroupBoard를 조회한다.  boardCode가 없으면 유저의 국가 및 학습 도메인의 Default OfferGroupBoard를 조회한다.
     */
    async getOfferGroupBoard(requestParameters, initOverrides) {
        const response = await this.getOfferGroupBoardRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
