import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {COLOR_SANTA_F} from '@riiid/design-system';
import CartIcon from '@riiid/design-system/icons/Cart';
import {Typography} from '@santa-web/service-ui';
import {ListLayout} from '@app/features/offer/components';

type OfferGroupOrderListPageEmptyViewProps = {
  onBackClick: () => void;
};

const OfferGroupOrderListPageEmptyView = ({onBackClick}: OfferGroupOrderListPageEmptyViewProps) => {
  const {t} = useTranslation();
  return (
    <ListLayout onGoBack={onBackClick}>
      <div
        css={css`
          margin-top: 40vh;
          display: flex;
          justify-content: center;
          align-items: center;
        `}>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 16px;
            transform: translateY(-50%);
          `}>
          <CartIcon width={40} height={40} color={COLOR_SANTA_F} type="line" />
          <Typography component="h1" variant="caption1" fontWeight="bold" color={COLOR_SANTA_F}>
            {t('page_offer_group_order_list_empty')}
          </Typography>
        </div>
      </div>
    </ListLayout>
  );
};

export default OfferGroupOrderListPageEmptyView;
export type {OfferGroupOrderListPageEmptyViewProps};
OfferGroupOrderListPageEmptyView.displayName = 'OfferGroupOrderListPageEmptyView';
