import {PropsWithChildren} from 'react';
import {css} from '@emotion/react';
import {BREAKPOINT_MEDIUM} from '@riiid/design-system';

import TestPrepLayout, {TestPrepLayoutProps} from '@app/test-preparation/components/TestPrepLayout';
import {VOCA_VIEW_MAX_WIDTH} from '@app/test-preparation/constants/test-preparation-layout';

export type VocabularyMemorizeViewLayoutProps = TestPrepLayoutProps;

const VocabularyMemorizeViewLayout = ({children, ...props}: PropsWithChildren<VocabularyMemorizeViewLayoutProps>) => {
  return (
    <TestPrepLayout maxWidth={VOCA_VIEW_MAX_WIDTH} scrollLayoutProps={{}} {...props}>
      <div
        css={css`
          display: flex;
          justify-content: center;
          min-height: 100%;
        `}>
        <div
          css={css`
            width: 100%;
            max-width: ${VOCA_VIEW_MAX_WIDTH};
            @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
              padding: 0 20px;
            }
          `}>
          {children}
        </div>
      </div>
    </TestPrepLayout>
  );
};

export default VocabularyMemorizeViewLayout;
