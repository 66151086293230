import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {COLOR_SANTA_D, COLOR_SANTA_DC, COLOR_SANTA_G, COLOR_SANTA_I, COLOR_SANTA_L} from '@riiid/design-system';
import {Typography} from '@santa-web/service-ui';

type BillSheetBaseGoodsInfo = {
  displayName: string;
  price: string;
  description: string;
};

export type BillSheetTicketGoodsInfo = BillSheetBaseGoodsInfo & {count: number};
export type BillSheetPassGoodsInfo = BillSheetBaseGoodsInfo & {
  duration: {term: number; unit: 'DAY' | 'MONTH' | 'WEEK' | 'YEAR'};
};

type OfferGroupOrderBillSheetProps = {
  goodsInfo: {
    passes?: BillSheetPassGoodsInfo[];
    tickets?: BillSheetTicketGoodsInfo[];
  };
  billingInfo: {
    baseAmount: string;
    discounts?: {
      displayName: string;
      amount: string;
    }[];
    totalAmount: string;
  };
};

const OfferGroupOrderBillSheet = ({
  goodsInfo: {passes, tickets},
  billingInfo: {baseAmount, discounts, totalAmount},
}: OfferGroupOrderBillSheetProps) => {
  const {t} = useTranslation();
  return (
    <div
      css={css`
        display: grid;
        gap: 32px;
      `}>
      <div
        css={css`
          display: grid;
          gap: 24px;
        `}>
        {passes?.map(pass => (
          <GoodsInfoItem key={pass.displayName} {...pass} />
        ))}
        {tickets?.map(ticket => (
          <GoodsInfoItem key={ticket.displayName} {...ticket} />
        ))}
      </div>
      <hr
        css={css`
          width: 100%;
          margin: 0;
          border: none;
          border-bottom: 1px solid ${COLOR_SANTA_D};
        `}
      />
      <div
        css={css`
          display: grid;
          gap: 24px;
        `}>
        <Row>
          <Typography variant="body3" color={COLOR_SANTA_L}>
            {t('component_offer_group_order_bill_sheet_total_price')}
          </Typography>
          <Typography variant="body3" color={COLOR_SANTA_L}>
            {baseAmount}
          </Typography>
        </Row>
        {discounts && (
          <>
            <hr
              css={css`
                width: 100%;
                margin: 0;
                border: none;
                border-bottom: 1px solid ${COLOR_SANTA_D};
              `}
            />
            <div
              css={css`
                display: grid;
                gap: 12px;
              `}>
              {discounts.map((discount, idx) => (
                <Row key={idx}>
                  <Typography variant="body3" color={COLOR_SANTA_I}>
                    {discount.displayName}
                  </Typography>
                  <Typography variant="body3" color={COLOR_SANTA_DC}>
                    -{discount.amount}
                  </Typography>
                </Row>
              ))}
            </div>
          </>
        )}
        <hr
          css={css`
            width: 100%;
            margin: 0;
            border: none;
            border-bottom: 1px dashed ${COLOR_SANTA_L};
          `}
        />
        <Row>
          <Typography variant="body3" fontWeight="bold" color={COLOR_SANTA_L}>
            {t('component_offer_group_order_bill_sheet_charge_amount')}
          </Typography>
          <Typography variant="body1" fontWeight="bold" color={COLOR_SANTA_L}>
            {totalAmount}
          </Typography>
        </Row>
      </div>
    </div>
  );
};

const Row = ({children}: {children: React.ReactNode}) => {
  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 20px;
      `}>
      {children}
    </div>
  );
};

const GoodsInfoItem = ({
  displayName,
  duration,
  count,
  description,
}: BillSheetBaseGoodsInfo & Partial<BillSheetTicketGoodsInfo> & Partial<BillSheetPassGoodsInfo>) => {
  const {t} = useTranslation();
  return (
    <div>
      <Row>
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 8px;
          `}>
          <Typography variant="body3" fontWeight="bold" color={COLOR_SANTA_L}>
            {displayName}
          </Typography>
          {duration && (
            <>
              <Typography aria-hidden variant="body3" color={COLOR_SANTA_L}>
                |
              </Typography>
              <Typography variant="body3" color={COLOR_SANTA_L}>
                {(() => {
                  switch (duration.unit) {
                    case 'DAY':
                      return t('dict_n_days', {n: duration.term});
                    case 'WEEK':
                      return t('dict_n_weeks', {n: duration.term});
                    case 'MONTH':
                      return t('dict_n_months', {n: duration.term});
                    case 'YEAR':
                      return t('dict_n_years', {n: duration.term});
                  }
                })()}
              </Typography>
            </>
          )}
        </div>
        {count && (
          <Typography variant="body3" color={COLOR_SANTA_L}>
            ×{count}
          </Typography>
        )}
      </Row>
      <Typography variant="caption1" component="p" color={COLOR_SANTA_G}>
        {description}
      </Typography>
    </div>
  );
};

export default OfferGroupOrderBillSheet;
export type {OfferGroupOrderBillSheetProps};
OfferGroupOrderBillSheet.displayName = 'OfferGroupOrderBillSheet';
