import {useQuery, UseQueryResult} from '@tanstack/react-query';
import produce from 'immer';
import {useAtomValue} from 'jotai';
import cloneDeep from 'lodash.clonedeep';

import {
  ContentInteractionState,
  ContentInteractionStateWithContent,
} from '@santa-web/gen/open-api/content-learning-service';
import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import useAddContentToContentInteractionStateWithContentInfo from '@app/hooks/content-interaction-state/useAddContentToContentInteractionState';
import {getContentInteractionStatesQueryKey} from '@app/queryKeys';
import {IContentInteractionStateWithContent} from '@app/types/santa-service-protocol';
import {SantaResponseError} from '@app/utils/error';

const MAX_PAGINATION_SIZE = 100;

const useContentInteractionStateWithContents = (
  cellId: number | null | undefined,
  onPermissionDenied?: (error?: SantaResponseError) => void
): UseQueryResult<IContentInteractionStateWithContent[]> => {
  const {ContentInteractionStateService} = useAtomValue(santaOpenapiServicesAtom);
  const addContentToContentInteractionStateWithContentInfo = useAddContentToContentInteractionStateWithContentInfo();

  const queryKey = getContentInteractionStatesQueryKey(cellId);

  return useQuery(
    queryKey,
    async () => {
      if (cellId == null) {
        return;
      }

      const {count: cisesSummaryCount} = await ContentInteractionStateService.summarizeContentInteractionStates({
        learningCellId: cellId,
      });
      let cises: ContentInteractionStateWithContent[] = [];

      if (cisesSummaryCount === 0) {
        const {created} = await ContentInteractionStateService.createContentInteractionStates({
          learningCellId: cellId,
        });

        cises.push(...created);
      } else {
        let cursor = '';

        do {
          const {
            contentInteractionStates: nextCises,
            page: {cursor: nextCursor},
          } = await ContentInteractionStateService.listContentInteractionStates({
            learningCellId: cellId,
            size: MAX_PAGINATION_SIZE,
            cursor: cursor || undefined,
          });
          cursor = nextCursor;
          cises.push(...nextCises);
        } while (cursor);
      }

      const hasVocabularyCis = cises.find(
        cis => cis.contentInteractionState.elementInteractionStates[0].details.oneOfCase === 'VOCA'
      );

      if (hasVocabularyCis) {
        const data = cloneDeep(cises.map(({contentInteractionState}) => contentInteractionState));
        const response = await fillVocabularySelfReport(data, (id: number, cis: ContentInteractionState) =>
          ContentInteractionStateService.updateContentInteractionState({
            contentInteractionStateId: id,
            updateContentInteractionStateRequest: {
              contentInteractionState: cis,
              timestampClient: Date.now(),
            },
          })
        );

        if (response.length !== 0) {
          cises = produce(cises, draft => {
            draft.forEach(cisc => {
              const targetCis = response.find(cisInResponse => cisc.contentInteractionState.id === cisInResponse.id);
              if (targetCis !== undefined) {
                cisc.contentInteractionState = targetCis;
              }
            });
          });
        }
      }

      return await Promise.all((cises ?? []).map(addContentToContentInteractionStateWithContentInfo));
    },
    {
      enabled: cellId != null,
      cacheTime: 0,
      onError: error => {
        if (error instanceof SantaResponseError) {
          if (error.response.status === 403) {
            onPermissionDenied?.(error);

            return;
          }
        }

        throw error;
      },
    }
  );
};

const getSelfReports = (cis: ContentInteractionState) => {
  return cis.elementInteractionStates[0].details.voca?.selfReports ?? [];
};

const MAX_LOOP_COUNT = 1000;

// Return content interaction state should update
export const fillVocabularySelfReport = async (
  cises: ContentInteractionState[],
  updateContentInteractionState: (
    id: number,
    cis: ContentInteractionState
  ) => Promise<ContentInteractionStateWithContent>
): Promise<ContentInteractionState[]> => {
  const vocabularyCises = cises.filter(cis => cis.elementInteractionStates[0].details.oneOfCase === 'VOCA');

  const isEveryVocabularyCisEmpty = vocabularyCises.every(cis => getSelfReports(cis).length === 0);
  if (isEveryVocabularyCisEmpty) {
    return produce(vocabularyCises, draft => {
      draft.forEach(cis =>
        getSelfReports(cis).push({
          isKnowledge: undefined,
          vocaOngoingStep: 'STEP1',
        })
      );
    });
  }

  const hasAlreadyFilled = vocabularyCises.every(
    cis => getSelfReports(cis).length === getSelfReports(vocabularyCises[0]).length
  );

  if (hasAlreadyFilled) {
    return [];
  }

  const isSolvingStep1 =
    vocabularyCises.some(cis => getSelfReports(cis).length === 0) &&
    vocabularyCises.every(cis => getSelfReports(cis).length < 2);
  if (isSolvingStep1) {
    const res = produce(vocabularyCises, draft => {
      draft.forEach(cis => {
        if (getSelfReports(cis).length === 0) {
          getSelfReports(cis).push({
            isKnowledge: undefined,
            vocaOngoingStep: 'STEP1',
          });
        }
      });
    });

    return res;
  }

  const result = [];
  for (const cis of vocabularyCises) {
    const deepCopied = cloneDeep(cis);
    const selfReport = getSelfReports(cis).splice(0, 1)[0];

    // 어휘 학습 데이터가 잘못들어가 있는 경우 클라이언트 사이드에서 채워서 서버 데이터 업데이트
    // 관련 context 쓰레드: https://riiid.slack.com/archives/C032E87CVGD/p1681870055119359
    if (selfReport === undefined) {
      deepCopied.elementInteractionStates[0].details.voca!.selfReports = [
        {
          isKnowledge: true,
          vocaOngoingStep: 'STEP1',
        },
      ];

      await updateContentInteractionState(deepCopied.id, deepCopied);
    } else {
      deepCopied.elementInteractionStates[0].details.voca!.selfReports = [
        {
          isKnowledge: selfReport.isKnowledge,
          vocaOngoingStep: selfReport.vocaOngoingStep,
        },
      ];
    }

    result.push(deepCopied);
  }

  const isLeftVocabularyCisEmpty = () => vocabularyCises.every(cis => getSelfReports(cis).length === 0);

  for (let loopCount = 0; !isLeftVocabularyCisEmpty(); loopCount += 1) {
    if (loopCount > MAX_LOOP_COUNT) {
      throw new Error(`[TEST-PREP] vocabulary self report is wrong. Max loop count exceeded.  ${cises[0].id}`);
    }

    const isEveryLastSessionVocabularyKnowledge = result.every(cis => {
      const selfReports = getSelfReports(cis);
      return selfReports[selfReports.length - 1].isKnowledge !== false;
    });

    if (isEveryLastSessionVocabularyKnowledge) {
      result.forEach((cis, cisIndex) => {
        const nextSelfReport = getSelfReports(vocabularyCises[cisIndex]).shift();
        if (nextSelfReport === undefined) {
          getSelfReports(cis).push(
            nextSelfReport ?? {
              isKnowledge: undefined,
              vocaOngoingStep: 'STEP2',
            }
          );
        } else {
          getSelfReports(cis).push(nextSelfReport);
        }
      });
    } else {
      result.forEach((cis, cisIndex) => {
        const selfReports = getSelfReports(cis);
        if (selfReports[selfReports.length - 1].isKnowledge === false) {
          const nextSelfReport = getSelfReports(vocabularyCises[cisIndex]).shift();
          getSelfReports(cis).push(
            nextSelfReport ?? {
              isKnowledge: undefined,
              vocaOngoingStep: 'STEP2',
            }
          );
        } else {
          getSelfReports(cis).push({
            isKnowledge: undefined,
            vocaOngoingStep: 'STEP2',
          });
        }
      });
    }
  }

  return result;
};

export default useContentInteractionStateWithContents;
