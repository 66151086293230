import TestPrepLayout, {TestPrepLayoutProps} from '@app/test-preparation/components/TestPrepLayout';
import {GTELP_GRAMMAR_QUESTION_VIEW_MAX_WIDTH} from '@app/test-preparation/constants/test-preparation-layout';

export type GtelpGrammarQuestionViewLayoutProps = TestPrepLayoutProps;

const GtelpGrammarQuestionViewLayout = ({children, ...rest}: GtelpGrammarQuestionViewLayoutProps) => {
  return (
    <TestPrepLayout maxWidth={GTELP_GRAMMAR_QUESTION_VIEW_MAX_WIDTH} {...rest}>
      {children}
    </TestPrepLayout>
  );
};

export default GtelpGrammarQuestionViewLayout;
