import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {
  BREAKPOINT_LARGE,
  BREAKPOINT_MEDIUM,
  COLOR_SANTA_C,
  COLOR_SANTA_D,
  COLOR_SANTA_E,
  COLOR_SANTA_I,
  COLOR_SANTA_L,
} from '@riiid/design-system';
import ChevronIcon from '@riiid/design-system/icons/ChevronMiniDown';
import {useAtomValue} from 'jotai';
import {Typography} from '@santa-web/service-ui';
import useOfferEvents from '@app/api/google-tag-manager/offer';
import learningDomainAtom, {learningDomainsAtom} from '@app/atoms/core/learning-domain';
import {ResponsiveImage, SkeletonGrayBox} from '@app/components';
import TypedLink from '@app/components/TypedLink';
import {useGetCouponInfinityQuery} from '@app/features/coupon';
import {CurrentPassInformationCard, CurrentPassInformationCardContainer} from '@app/features/permit';
import {
  FriendRecommendationCard,
  FriendRecommendationCardContainer,
  PreferenceListItem,
  PreferencePageLayoutContainer,
} from '@app/features/preferences';
import {IconVariant} from '@app/features/preferences/components/PreferenceListItem';
import {useGetExamScoresQuery, useNewFlag} from '@app/features/preferences/hooks';
import useGetLearningGoalQuery from '@app/features/preferences/hooks/useGetLearningGoalQuery';
import useIsUnderAppReview from '@app/hooks/useIsUnderAppReview';
import useUserQuery from '@app/hooks/user/useUser';
import useUserProfileQuery from '@app/hooks/user/useUserProfile';

const MyPageContainer = () => {
  const {t} = useTranslation();
  const {data: user} = useUserQuery();
  const {data: profile} = useUserProfileQuery();
  const {data: learningGoal} = useGetLearningGoalQuery();
  const {data: examScores} = useGetExamScoresQuery();
  const {data: availableCouponData, isLoading: isGetCouponLoading} = useGetCouponInfinityQuery({
    availableCondition: true,
    size: 30,
  });
  const availableCouponCount = availableCouponData?.pages[0].coupons.length ?? 0;
  const isCouponBoxEmpty = availableCouponCount === 0;

  const newFlag = useNewFlag();
  const isUnderAppReview = useIsUnderAppReview();
  const learningDomains = useAtomValue(learningDomainsAtom);
  const learningDomain = useAtomValue(learningDomainAtom);
  const {pushClickStore} = useOfferEvents();

  const isLearningDomainInFreePromotion = learningDomain.isInFreePromotion;

  const certifiedScore = examScores?.examScores?.[0]?.totalScore;
  const learningGoalLabel: string | undefined = React.useMemo(() => {
    if (!learningGoal) return undefined;
    const {studyMinutesPerDay, dayOfWeeks} = learningGoal;
    return dayOfWeeks.length === 7
      ? t('settings_learning_goal_exists_every_day', {day: dayOfWeeks.length, time: studyMinutesPerDay})
      : t('settings_learning_goal_exists', {day: dayOfWeeks.length, time: studyMinutesPerDay});
  }, [learningGoal, t]);
  const hasMultipleDomain = learningDomains.length > 1;

  const isLoading = profile == null || user == null || learningGoal === undefined || examScores == null;
  const isKoreanUser = user?.language === 'KO';

  return isLoading ? (
    <Skeleton />
  ) : (
    <PreferencePageLayoutContainer
      shouldShowSettingButton
      shouldShowFooter
      upperArea={
        <div
          css={css`
            display: grid;
            gap: 28px;
            @media screen and (min-width: ${BREAKPOINT_LARGE}) {
              grid-template-columns: repeat(2, 1fr);
            }
          `}>
          <div
            css={css`
              display: grid;
              align-content: space-between;
              gap: 28px;
            `}>
            <div
              css={css`
                display: flex;
                align-items: center;
                justify-content: space-between;
              `}>
              <div>
                <Typography
                  css={css`
                    margin-bottom: 8px;
                  `}
                  component="h2"
                  variant="caption1"
                  fontWeight="bold"
                  color={COLOR_SANTA_I}>
                  {t('dict_username', {name: user.personalInfo?.name ?? 'USER'})}
                </Typography>
                {hasMultipleDomain ? (
                  <TypedLink
                    href="/onboarding"
                    css={css`
                      display: flex;
                      align-items: center;
                      gap: 4px;
                    `}>
                    <Typography component="h1" variant="h5" fontWeight="bold" color={COLOR_SANTA_L}>
                      {t('page_me_domain_learning_title', {domain: learningDomain.displayName})}
                    </Typography>
                    <ChevronIcon width={32} height={32} color={COLOR_SANTA_L} />
                  </TypedLink>
                ) : (
                  <Typography component="h1" variant="h5" fontWeight="bold" color={COLOR_SANTA_L}>
                    {t('page_me_domain_learning_title', {domain: learningDomain.displayName})}
                  </Typography>
                )}
              </div>
              <ResponsiveImage
                css={css`
                  object-fit: cover;
                  object-position: center center;
                  border-radius: 9999px;
                  overflow: hidden;
                `}
                width={96}
                height={96}
                imageSet={learningDomain.imageSet}
                alt=""
              />
            </div>
            <CurrentPassInformationCardContainer />
          </div>
          <div
            css={css`
              display: grid;
              gap: 32px;
              @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
                border: 1px solid ${COLOR_SANTA_D};
                border-radius: 8px;
                padding: 20px;
              }
            `}>
            <PreferenceListItem
              title={{value: t('dict_prediction_score')}}
              icon={IconVariant.PREDICTION_SCORE}
              hintText={{
                value: t('dict_n_score', {n: profile.currentEstimatedScore ?? '--'}),
                colorVariant: profile.currentEstimatedScore ? 'brand' : 'gray',
                fontWeight: 'bold',
              }}
              description={
                profile.currentEstimatedScore == null
                  ? {value: t('page_me_list_item_prediction_score_diagnosis_description'), colorVariant: 'brand'}
                  : undefined
              }
              action={{type: 'chevron', disabled: true}}
            />
            <TypedLink href="/me/target-score">
              <PreferenceListItem
                title={{value: t('dict_target_score')}}
                icon={IconVariant.TARGET_SCORE}
                hintText={{value: t('dict_n_score', {n: profile.onboardingInfo.targetScore}), fontWeight: 'bold'}}
                action={{type: 'chevron'}}
              />
            </TypedLink>
            <TypedLink href="/me/learning-goal">
              <PreferenceListItem
                title={{value: t('dict_study_goal')}}
                icon={IconVariant.STUDY_GOAL}
                hintText={{value: learningGoalLabel ?? t('page_me_list_item_study_goal_hint')}}
                action={{type: 'chevron'}}
              />
            </TypedLink>
            {isKoreanUser && (
              <TypedLink href="/me/exam-score">
                <PreferenceListItem
                  title={{value: t('page_me_list_item_exam_score_title')}}
                  icon={IconVariant.EXAM_SCORE}
                  hintText={{
                    value: certifiedScore
                      ? t('dict_n_score', {n: certifiedScore})
                      : t('page_me_list_item_exam_score_hint'),
                    fontWeight: certifiedScore ? 'bold' : 'regular',
                  }}
                  action={{type: 'chevron'}}
                />
              </TypedLink>
            )}
          </div>
        </div>
      }
      lowerArea={
        <div
          css={css`
            display: grid;
            gap: 32px;
          `}>
          <Typography component="h2" variant="caption1" fontWeight="bold" color={COLOR_SANTA_I}>
            {t('page_me_list_title_my_information')}
          </Typography>
          <TypedLink href="/me/account">
            <PreferenceListItem
              title={{value: t('page_me_list_item_account_info_title'), fontWeight: 'bold'}}
              icon={IconVariant.ACCOUNT_INFO}
              action={{type: 'chevron'}}
            />
          </TypedLink>
          <hr
            css={css`
              border: 0;
              margin: 0;
              border-top: solid 1px ${COLOR_SANTA_E};
            `}
          />
          {!isLearningDomainInFreePromotion && (
            <>
              <TypedLink href="/offer-group/order/list">
                <PreferenceListItem
                  title={{value: t('page_me_list_item_purchase_list_title'), fontWeight: 'bold'}}
                  icon={IconVariant.PURCHASE_LIST}
                  action={{type: 'chevron'}}
                />
              </TypedLink>
              <TypedLink href="/permit/list">
                <PreferenceListItem
                  title={{value: t('page_me_list_item_authority_list_title'), fontWeight: 'bold'}}
                  icon={IconVariant.PERMIT_LIST}
                  action={{type: 'chevron'}}
                />
              </TypedLink>
              <TypedLink
                onClick={() => pushClickStore({referrer: 'setting_seeallproduct'})}
                href={{pathname: '/offer-group/board', query: {referrer: 'setting_seeallproduct'}}}>
                <PreferenceListItem
                  isNew={newFlag?.isNewFlagMap.offerGroup}
                  title={{value: t('page_me_list_item_product_list_title'), fontWeight: 'bold'}}
                  icon={IconVariant.PRODUCT_BOARD}
                  action={{type: 'chevron', onClick: () => newFlag?.removeNewFlag('offerGroup')}}
                />
              </TypedLink>
              {!isUnderAppReview && (
                <TypedLink href="/coupon">
                  <PreferenceListItem
                    isNew={newFlag?.isNewFlagMap.coupon}
                    title={{value: t('page_me_list_item_coupon_box_title'), fontWeight: 'bold'}}
                    isLoading={isGetCouponLoading}
                    icon={IconVariant.COUPON_BOX}
                    description={{
                      value: isCouponBoxEmpty
                        ? t('page_me_list_item_coupon_box_description_empty')
                        : t('page_me_list_item_coupon_box_description', {
                            domain: learningDomain.displayName,
                            n: availableCouponCount,
                          }),
                      colorVariant: isCouponBoxEmpty ? 'black' : 'brand',
                    }}
                    action={{type: 'chevron', onClick: () => newFlag?.removeNewFlag('coupon')}}
                  />
                </TypedLink>
              )}
            </>
          )}
          <TypedLink href="/event/list">
            <PreferenceListItem
              isNew={newFlag?.isNewFlagMap.event}
              title={{value: t('dict_event'), fontWeight: 'bold'}}
              icon={IconVariant.EVENT}
              action={{type: 'chevron', onClick: () => newFlag?.removeNewFlag('event')}}
            />
          </TypedLink>
          {isKoreanUser && !isLearningDomainInFreePromotion && <FriendRecommendationCardContainer />}
        </div>
      }
    />
  );
};

const Skeleton = () => {
  return (
    <PreferencePageLayoutContainer
      shouldShowSettingButton
      shouldShowFooter
      upperArea={
        <div>
          <div
            css={css`
              display: grid;
              gap: 28px;
              @media screen and (min-width: ${BREAKPOINT_LARGE}) {
                grid-template-columns: repeat(2, 1fr);
              }
            `}>
            <div
              css={css`
                display: grid;
                align-content: space-between;
                gap: 28px;
              `}>
              <div
                css={css`
                  display: grid;
                  grid-template-columns: 1fr auto;
                  align-items: center;
                  gap: 64px;
                  @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
                    gap: 16px;
                  }
                `}>
                <div>
                  <SkeletonGrayBox
                    css={css`
                      margin-bottom: 12px;
                    `}
                    width="calc(100% - 114px)"
                    height={12}
                  />
                  <SkeletonGrayBox width="100%" height={28} />
                </div>
                <SkeletonGrayBox
                  css={css`
                    border-radius: 9999px;
                  `}
                  width={96}
                  height={96}
                />
              </div>
              <CurrentPassInformationCard.Skeleton />
            </div>
            <div
              css={css`
                display: grid;
                grid-template-columns: auto 1fr;
                align-items: center;
                gap: 32px 12px;
                @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
                  border: 1px solid ${COLOR_SANTA_D};
                  border-radius: 8px;
                  padding: 20px;
                }
              `}>
              {Array.from({length: 4}).map((_, idx) => (
                <React.Fragment key={idx}>
                  <SkeletonGrayBox
                    color={COLOR_SANTA_C}
                    width={32}
                    height={32}
                    css={css`
                      border-radius: 9999px;
                    `}
                  />
                  <SkeletonGrayBox color={COLOR_SANTA_C} width="100%" height={20} />
                </React.Fragment>
              ))}
            </div>
          </div>
        </div>
      }
      lowerArea={
        <div>
          <SkeletonGrayBox
            css={css`
              margin-bottom: 32px;
            `}
            color={COLOR_SANTA_C}
            width={80}
            height={12}
          />
          <div
            css={css`
              display: grid;
              grid-template-columns: auto 1fr;
              align-items: center;
              gap: 32px 12px;
              margin-bottom: 32px;
            `}>
            {Array.from({length: 5}).map((_, idx) => (
              <React.Fragment key={idx}>
                <SkeletonGrayBox
                  color={COLOR_SANTA_C}
                  width={32}
                  height={32}
                  css={css`
                    border-radius: 9999px;
                  `}
                />
                <SkeletonGrayBox color={COLOR_SANTA_C} width="100%" height={20} />
              </React.Fragment>
            ))}
          </div>
          <FriendRecommendationCard.Skeleton />
        </div>
      }
    />
  );
};

export default MyPageContainer;
