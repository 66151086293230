import React from 'react';

import {QuestionState} from '@santa-web/gen/open-api/content-learning-service';
import {QuestionSet} from '@santa-web/gen/open-api/content/models/QuestionSet';
import {SinglePageLayout} from '@santa-web/service-ui';
import VirtualExamPart5View from '@app/components/ToeicContentView/VirtualExamQuestionView/VirtualExamPart5View';
import VirtualExamPart6To7View from '@app/components/ToeicContentView/VirtualExamQuestionView/VirtualExamPart6To7View';
import {VirtualExamPart} from '@app/new-structure/entities/base/parts';
import {
  QuestionAnswerStateWithIndex,
  convertToContentQuestionState,
} from '@app/new-structure/entities/virtual-exam/QuestionAnswerState';

import VirtualExamPart1To2View from './VirtualExamPart1To2View';
import VirtualExamPart3To4View from './VirtualExamPart3To4View';
export interface VirtualExamQuestionViewProps {
  isLoading?: boolean;
  part: VirtualExamPart;
  contentId?: string;
  questionSet?: QuestionSet;
  questionStates: QuestionAnswerStateWithIndex[];
  questionStartIndex: number;
  onQuestionAnswerStateChange: (targetQuestionNumber: number) => (questionAnswerState: QuestionState) => Promise<void>;
}

const VirtualExamQuestionView = ({
  isLoading,
  part,
  contentId,
  questionSet,
  questionStates,
  questionStartIndex,
  onQuestionAnswerStateChange,
}: VirtualExamQuestionViewProps): React.ReactElement | null => {
  const contentQuestionStates = React.useMemo(
    () => questionStates.map(convertToContentQuestionState),
    [questionStates]
  );

  const view = (() => {
    if (isLoading || contentId == null) return <div>Loading</div>;

    if (questionSet == null) throw Error('QuestionSet must be exist!');
    switch (part) {
      case 'PART_1':
      case 'PART_2':
        return (
          <VirtualExamPart1To2View
            isPart2={part === 'PART_2'}
            contentId={contentId}
            questionSet={questionSet}
            questionStates={contentQuestionStates}
            questionStartNumber={questionStartIndex + 1}
            onQuestionAnswerStateChange={onQuestionAnswerStateChange(0)}
          />
        );
      case 'PART_3':
      case 'PART_4':
        return (
          <VirtualExamPart3To4View
            contentId={contentId}
            questionSet={questionSet}
            questionStates={contentQuestionStates}
            questionStartNumber={questionStartIndex + 1}
            onQuestionAnswerStateChange={onQuestionAnswerStateChange}
          />
        );
      case 'PART_5':
        return (
          <VirtualExamPart5View
            contentId={contentId}
            questionSet={questionSet}
            questionStates={contentQuestionStates}
            questionStartNumber={questionStartIndex + 1}
            onQuestionAnswerStateChange={onQuestionAnswerStateChange(0)}
          />
        );
      case 'PART_6':
      case 'PART_7':
        return (
          <VirtualExamPart6To7View
            contentId={contentId}
            questionSet={questionSet}
            questionStates={contentQuestionStates}
            questionStartNumber={questionStartIndex + 1}
            onQuestionAnswerStateChange={onQuestionAnswerStateChange}
          />
        );
      default:
        throw new Error('Other parts are not implemented yet.');
    }
  })();

  return <SinglePageLayout isLoading={isLoading}>{view}</SinglePageLayout>;
};

export default React.memo(VirtualExamQuestionView);
