import React from 'react';
import {
  TOP_NAV_BAR_BACK_BUTTON_APPEAR_EVENT_NAME,
  TOP_NAV_BAR_BACK_BUTTON_DISAPPEAR_EVENT_NAME,
} from '@santa-web/service-ui';
import {mobileService} from '@app/api/app-bridge/mobile-service';
import {isAppBridgeAvailable} from '@app/utils/app-bridge';

export const useToggleNavigationGestureEffect = () => {
  React.useEffect(() => {
    if (!isAppBridgeAvailable()) return;
    mobileService.setIsNavigationGestureAvailable(false);
  }, []);

  React.useEffect(() => {
    if (!isAppBridgeAvailable()) return;
    const handleTopNavBarBackButtonAppear = () => {
      mobileService.setIsNavigationGestureAvailable(true);
    };
    const handleTopNavBarBackButtonDisAppear = () => {
      mobileService.setIsNavigationGestureAvailable(false);
    };
    document.addEventListener(TOP_NAV_BAR_BACK_BUTTON_APPEAR_EVENT_NAME, handleTopNavBarBackButtonAppear);
    document.addEventListener(TOP_NAV_BAR_BACK_BUTTON_DISAPPEAR_EVENT_NAME, handleTopNavBarBackButtonDisAppear);
    return () => {
      document.removeEventListener(TOP_NAV_BAR_BACK_BUTTON_APPEAR_EVENT_NAME, handleTopNavBarBackButtonAppear);
      document.removeEventListener(TOP_NAV_BAR_BACK_BUTTON_DISAPPEAR_EVENT_NAME, handleTopNavBarBackButtonDisAppear);
    };
  }, []);
};
