import React from 'react';
import {css} from '@emotion/react';
import {COLOR_SANTA_A} from '@riiid/design-system';
import {BaseCard, SkeletonGrayBox} from '@app/components';
import {ListLayout} from '@app/features/offer/components';

type OfferGroupOrderListPageSkeletonViewProps = {onBackClick: () => void};

const OfferGroupOrderListPageSkeletonView = ({onBackClick}: OfferGroupOrderListPageSkeletonViewProps) => {
  return (
    <ListLayout onGoBack={onBackClick}>
      <SkeletonGrayBox
        css={css`
          margin-bottom: 40px;
        `}
        width={88}
        height={12}
      />
      <ul
        css={css`
          margin: 0;
          padding: 0;
          list-style: none;
          display: grid;
          gap: 12px;
        `}>
        {Array.from({length: 3}, (_, i) => (
          <li key={i}>
            <BaseCard
              css={css`
                padding: 40px 20px;
                background-color: ${COLOR_SANTA_A};
              `}>
              <SkeletonGrayBox
                css={css`
                  margin-bottom: 24px;
                `}
                width={100}
                height={20}
              />
              <SkeletonGrayBox
                css={css`
                  margin-bottom: 12px;
                `}
                width="100%"
                height={12}
              />
              <SkeletonGrayBox width="80%" height={12} />
            </BaseCard>
          </li>
        ))}
      </ul>
    </ListLayout>
  );
};

export default OfferGroupOrderListPageSkeletonView;
export type {OfferGroupOrderListPageSkeletonViewProps};
OfferGroupOrderListPageSkeletonView.displayName = 'OfferGroupOrderListPageSkeletonView';
