/* tslint:disable */
/* eslint-disable */
/**
 *
 * @export
 */
export const BulkGetContentResourcesResultCase = {
    SUCCESS: 'SUCCESS',
    FAILURE: 'FAILURE'
};
export function BulkGetContentResourcesResultCaseFromJSON(json) {
    return BulkGetContentResourcesResultCaseFromJSONTyped(json, false);
}
export function BulkGetContentResourcesResultCaseFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function BulkGetContentResourcesResultCaseToJSON(value) {
    return value;
}
