import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';
import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {StringDate} from '@app/features/preferences/types';
import {getExamScoresQueryKey} from '@app/queryKeys';

const useDeleteExamScoreMutation = (
  option?: Omit<UseMutationOptions<void, unknown, StringDate, unknown>, 'mutationFn'>
) => {
  const queryClient = useQueryClient();
  const {ExamScoreService} = useAtomValue(santaOpenapiServicesAtom);

  return useMutation<void, unknown, StringDate, unknown>(
    (examDate: StringDate) => ExamScoreService.deleteExamScore({examDate: examDate as unknown as Date}),
    {
      ...option,
      onSuccess: () => {
        queryClient.invalidateQueries(getExamScoresQueryKey());
      },
    }
  );
};

export default useDeleteExamScoreMutation;
