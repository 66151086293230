import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';

import {Button, BottomSheetProps} from '@santa-web/service-ui';
import BottomSheetWithDim, {BottomSheetWithDimProps} from '@app/components/BottomSheetWithDim';
import {ALERT_DIALOG_Z_INDEX} from '@app/constants/zIndex';

interface InvalidUserBottomSheetProps extends BottomSheetWithDimProps {
  onSignupClick?: React.EventHandler<React.MouseEvent>;
  onCloseClick?: React.EventHandler<React.MouseEvent>;
}

const InvalidUserBottomSheet = React.forwardRef<HTMLDivElement, InvalidUserBottomSheetProps>(
  ({onSignupClick, onCloseClick, ...props}, ref) => {
    const {t} = useTranslation();
    const bottomSheetProps = React.useMemo(
      (): BottomSheetProps => ({
        title: t('bottom_sheet_user_not_exists_title'),
        description: t('bottom_sheet_user_not_exists_description'),
        isLoadingAiAnimationVisible: true,
        buttons: [
          <Button colorVariant="brand" isFullWidth key="login" onClick={onSignupClick}>
            {t('bottom_sheet_user_not_exists_primary')}
          </Button>,
          <Button variant="box-line" isFullWidth key="close" onClick={onCloseClick}>
            {t('bottom_sheet_user_not_exists_secondary')}
          </Button>,
        ],
        onCloseClick,
      }),
      [onCloseClick, onSignupClick, t]
    );

    return (
      <BottomSheetWithDim
        ref={ref}
        css={css`
          z-index: ${ALERT_DIALOG_Z_INDEX};
        `}
        isOpened
        BottomSheetProps={bottomSheetProps}
        {...props}
      />
    );
  }
);

export default React.memo(InvalidUserBottomSheet);
