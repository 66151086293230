/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { PointRewardSummaryFromJSON, PointRewardSummaryToJSON, } from './PointRewardSummary';
/**
 * Check if a given object implements the PointRewardSummaryResponse interface.
 */
export function instanceOfPointRewardSummaryResponse(value) {
    let isInstance = true;
    return isInstance;
}
export function PointRewardSummaryResponseFromJSON(json) {
    return PointRewardSummaryResponseFromJSONTyped(json, false);
}
export function PointRewardSummaryResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'summary': !exists(json, 'summary') ? undefined : PointRewardSummaryFromJSON(json['summary']),
    };
}
export function PointRewardSummaryResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'summary': PointRewardSummaryToJSON(value.summary),
    };
}
