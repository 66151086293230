/* tslint:disable */
/* eslint-disable */
/**
 *
 * @export
 */
export const LearningHistoryAnswerState = {
    CORRECT: 'CORRECT',
    INCORRECT: 'INCORRECT',
    DOES_NOT_KNOW: 'DOES_NOT_KNOW'
};
export function LearningHistoryAnswerStateFromJSON(json) {
    return LearningHistoryAnswerStateFromJSONTyped(json, false);
}
export function LearningHistoryAnswerStateFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function LearningHistoryAnswerStateToJSON(value) {
    return value;
}
