import {useMutation, useQueryClient} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';

import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {getListHighlightInfosQueryKey} from '@app/queryKeys';
import {HighlightInfo, ClickedHighlightInfo} from '@app/test-preparation/types/highlight';

interface MutationInput {
  cisId: number;
  clickedHighlightInfo: ClickedHighlightInfo;
}

export default function useDeleteHighlightInfo() {
  const queryClient = useQueryClient();
  const {HighlightService} = useAtomValue(santaOpenapiServicesAtom);

  return useMutation(
    ({clickedHighlightInfo}: MutationInput) => {
      const {highlight} = clickedHighlightInfo;
      return HighlightService.deleteHighlight({highlightId: highlight.id});
    },
    {
      onSuccess: async (_, {cisId, clickedHighlightInfo}) => {
        const listHighlightQueryKey = getListHighlightInfosQueryKey(cisId);

        await queryClient.cancelQueries(listHighlightQueryKey);
        queryClient.setQueryData<HighlightInfo[]>(listHighlightQueryKey, (old = []) =>
          old.filter(highlightInfo => highlightInfo.highlight.id !== clickedHighlightInfo.highlight.id)
        );
      },
    }
  );
}
