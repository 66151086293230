type TouchLike = {
  clientX: number;
  clientY: number;
};

// SlideInteraction 정책 문서: https://www.notion.so/riiid/c05d3459deb041a1a8df68192c0eaaa4?pvs=4#9a066a048d6f4c20b7e3b4ea317da866
class SlideInteractionTouch {
  private touch: Touch;

  constructor(private touches: TouchList) {
    if (touches.length === 0) {
      throw new Error('Cannot construct EnhancedTouches with empty TouchList');
    }

    this.touch = touches[0];
  }

  isMultiTouchEvent() {
    return this.touches.length > 1;
  }

  isClickEvent(target: TouchLike) {
    return Math.abs(target.clientX - this.touch.clientX) < 5;
  }

  get x() {
    return this.touch.clientX;
  }

  get y() {
    return this.touch.clientY;
  }
}

export default SlideInteractionTouch;
