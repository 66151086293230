import {useMemo} from 'react';

import {usePushEvent} from '.';

interface IncludeListeningEventPayload extends Record<string, unknown> {
  answer: 'y' | 'n';
}

interface QuitLevelTestPayload extends Record<string, unknown> {
  content_id: string;
  question_order: number;
}

interface RecommendedResultQuestionSolutionsResultClickPayload extends Record<string, unknown> {
  correct?: boolean;
}

interface pushRecommendedResultTabClickEventPayload extends Record<string, unknown> {
  tab: 'overall' | 'solutions';
}

interface RecommendedResultSolutionsPlayRecommendedLectureClickPayload extends Record<string, unknown> {
  cis_id?: string;
}

interface StartLevelTestPayload extends Record<string, unknown> {
  // eslint-disable-next-line
  from: 'onboarding' | 'course' | 'selective' | 'analytics' | (string & {});
  isContinued: boolean;
}

const useLevelTestEvents = () => {
  const pushEvent = usePushEvent();

  const levelTestEvents = useMemo(
    () => ({
      pushStartLevelTestEvent: async (payload: StartLevelTestPayload) => {
        await pushEvent('start_leveltest', {
          from: payload.from,
          is_continued: payload.isContinued ? 1 : 0,
        });
      },
      pushSkipLevelTestEvent: async () => {
        await pushEvent('skip_leveltest');
      },
      pushIncludeListeningEvent: async (payload: IncludeListeningEventPayload) => {
        await pushEvent('include_listening', payload);
      },
      pushQuitLevelTestEvent: async (payload: QuitLevelTestPayload) => {
        await pushEvent('quit_leveltest', payload);
      },
      pushFinishLevelTestEvent: async () => {
        await pushEvent('finish_leveltest');
      },
      pushRecommendedResultFinishLevelTestClickEvent: async (referrer: string | undefined) => {
        let from;
        switch (referrer) {
          case 'course':
          case 'analytics':
            from = 'course_test';
            break;
          case 'onboarding':
          default:
            from = 'onb_test';
        }
        await pushEvent('rec_res_finishlvltest_clk', {from});
      },
      pushRecommendedResultHowAiWorksClickEvent: async () => {
        await pushEvent('rec_res_howaiworks_clk');
      },
      pushRecommendedResultTabClickEvent: async (payload: pushRecommendedResultTabClickEventPayload) => {
        await pushEvent('rec_res_tab_clk', payload);
      },
      pushRecommendedResultQuestionSolutionsResultClickEvent: async (
        payload: RecommendedResultQuestionSolutionsResultClickPayload
      ) => {
        await pushEvent('rec_res_qsresult_clk', payload);
      },
      pushRecommendedResultSolutionsPlayRecommendedLectureClickEvent: async (
        payload: RecommendedResultSolutionsPlayRecommendedLectureClickPayload
      ) => {
        await pushEvent('rec_res_sol_playreclecture_clk', payload);
      },
    }),
    [pushEvent]
  );

  return levelTestEvents;
};

export default useLevelTestEvents;
