import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {BREAKPOINT_MEDIUM, COLOR_SANTA_C} from '@riiid/design-system';
import {useAtomValue} from 'jotai';

import {LearningCellReport, PartialEstimatedScore} from '@santa-web/gen/open-api/content-learning-service';
import {TabNav, TabNavButton, useIsGteMediumScreen} from '@santa-web/service-ui';
import learningDomainAtom from '@app/atoms/core/learning-domain';
import Legend from '@app/components/Legend';
import LegendList from '@app/components/LegendList';
import ReportScoreSection from '@app/components/ReportScoreSection';
import TotalScoreGraph, {CHART_COLORS} from '@app/components/TotalScoreGraph';
import {ExplanationView, DeepAnalyticsView} from '@app/features/diagnosis/views';
import {DiagnosisReportViewProps} from '@app/features/diagnosis/views/DiagnosisReportPageView';
import {useTypedRouter} from '@app/hooks/useTypedRouter';
import LearningCellReportContentsView from '@app/pages/learning-cell/report/LearningCellReportContentsView';
import {GenerateContentCardClickHandler} from '@app/pages/learning-cell/report/LearningCellReportView';
import {getFilteredCisIds, getReportContentCardId} from '@app/pages/learning-cell/report/util';
import ComponentObserveTimer from '@app/test-preparation/components/ComponentObserveTimer';
import {useObservingTimeContext} from '@app/test-preparation/contexts/ObservingTimeContext';
import {IContentInteractionStateWithContent} from '@app/types/santa-service-protocol';

export type DiagnosisReportTab = 'analytics' | 'explanation';

export type DiagnosisReportContentViewProps = Omit<DiagnosisReportViewProps, 'cellId' | 'onNavBarClick'> & {
  isPredictionScoreHidden?: boolean;
  learningCellReport?: LearningCellReport | null;
  contentInteractionStatesWithContents?: IContentInteractionStateWithContent[];
};

const DiagnosisReportContentView = ({
  diagnosisReport,
  userName,
  isPredictionScoreHidden = false,
  isConfettiVisible,
  learningCellReport,
  contentInteractionStatesWithContents,
  onPredictionScoreDescriptionButtonClick,
  onTabChange,
}: DiagnosisReportContentViewProps) => {
  const router = useTypedRouter();
  const {t} = useTranslation();
  const learningDomain = useAtomValue(learningDomainAtom);

  const isGteMediumScreen = useIsGteMediumScreen();
  const observingTimeContext = useObservingTimeContext();

  // 현재 2,3 개 length 까지만 지원중입니다. 그 외 일 경우 수정이 필수로 필요합니다
  const domainSegments = React.useMemo(
    () =>
      [...diagnosisReport.estimatedScore.partials] as
        | [PartialEstimatedScore, PartialEstimatedScore]
        | [PartialEstimatedScore, PartialEstimatedScore, PartialEstimatedScore],
    [diagnosisReport.estimatedScore.partials]
  );

  const [selectedTab, setSelectedTab] = React.useState<DiagnosisReportTab>(
    router.query.tab === 'analytics' || router.query.tab === 'explanation' ? router.query.tab : 'analytics'
  );

  const handleAnalyticsClick = () => {
    setSelectedTab('analytics');
    onTabChange('analytics');

    router.replace({
      query: {...router.query, tab: 'analytics'},
    });
  };

  const handleExplanationClick = () => {
    setSelectedTab('explanation');
    onTabChange('explanation');

    router.replace({
      query: {...router.query, tab: 'explanation'},
    });
  };

  React.useEffect(() => {
    observingTimeContext?.setDefaultComponentObservedPayload({});
  }, [observingTimeContext]);

  const scoreGraphIndexList = React.useMemo(
    () => (
      <div>
        <LegendList>
          {diagnosisReport.estimatedScore.partials.map((partial, index) => {
            return (
              <Legend
                key={partial.name}
                subject={partial.name}
                color={CHART_COLORS[domainSegments.length][index]}
                variant={'circle'}
              />
            );
          })}
        </LegendList>
      </div>
    ),
    [diagnosisReport.estimatedScore.partials, domainSegments.length]
  );

  const generateContentCardClickHandler: GenerateContentCardClickHandler = React.useCallback(
    ({contentInteractionStateIndex, isWrongOnly, vocaResultType}) =>
      async () => {
        await router.replace({
          hash: getReportContentCardId(contentInteractionStateIndex),
          query: router.query,
        });

        if (contentInteractionStatesWithContents) {
          await router.push({
            pathname: '/content-interaction-states',
            query: {
              cisIds: getFilteredCisIds({
                ciscs: contentInteractionStatesWithContents,
                isWrongOnly,
                vocaResultType,
              }),
              currentCisIndex: contentInteractionStateIndex,
            },
          });
        }
      },
    [contentInteractionStatesWithContents, router]
  );

  const contentsView = React.useMemo(
    () => (
      <LearningCellReportContentsView
        contentInteractionStatesWithContents={contentInteractionStatesWithContents}
        questionTimeHidden={learningCellReport?.extraInfo.oneOfCase === 'MY_NOTE'}
        generateContentCardClickHandler={generateContentCardClickHandler}
      />
    ),
    [contentInteractionStatesWithContents, generateContentCardClickHandler, learningCellReport?.extraInfo.oneOfCase]
  );

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        height: 100%;
        min-height: 100%;
      `}>
      <ComponentObserveTimer componentName="pred_score">
        <ReportScoreSection
          isConfettiVisible={isConfettiVisible}
          onAiBlockClick={onPredictionScoreDescriptionButtonClick}
          subTitle={t('learning_cell_report_diagnosis_page_title')}
          mainTitle={
            userName
              ? t('diagnosis_report_view_title_with_user', {userName: userName})
              : t('diagnosis_report_view_title')
          }
          content={
            <div
              css={css`
                @media screen and (max-width: ${BREAKPOINT_MEDIUM}) {
                  display: grid;
                  flex-grow: 1;
                  gap: 24px;
                }
              `}>
              <div
                css={css`
                  display: flex;
                  justify-content: center;
                `}>
                <TotalScoreGraph
                  title={t('result_view_prediction_card_score')}
                  isScoreHidden={isPredictionScoreHidden}
                  isPercent={diagnosisReport.estimatedScore.isPercent}
                  goalScore={diagnosisReport.skillInformation.targetScore}
                  maxScore={diagnosisReport.estimatedScore.maxScore}
                  totalScore={diagnosisReport.estimatedScore.totalScore}
                  segments={domainSegments}
                />
              </div>
              {!isGteMediumScreen && scoreGraphIndexList}
            </div>
          }
          subContent={isGteMediumScreen && scoreGraphIndexList}
        />
      </ComponentObserveTimer>
      <div
        css={css`
          background: ${COLOR_SANTA_C};
        `}>
        <div
          css={css`
            max-width: 1080px;
            margin: 0 auto;
          `}>
          <TabNav>
            <TabNavButton isSelected={selectedTab === 'analytics'} onClick={handleAnalyticsClick}>
              {t('diagnosis_report_tab_title_deep_analytics')}
            </TabNavButton>
            <TabNavButton isSelected={selectedTab === 'explanation'} onClick={handleExplanationClick}>
              {t('diagnosis_report_tab_title_explanation')}
            </TabNavButton>
          </TabNav>
          <div
            css={css`
              padding: 40px 20px;
              display: grid;
              @media (min-width: ${BREAKPOINT_MEDIUM}) {
                padding: 48px 40px 80px;
                gap: 8px;
              }
            `}>
            {selectedTab === 'analytics' && (
              <DeepAnalyticsView
                diagnosisReport={diagnosisReport!}
                isPredictionScoreHidden={isPredictionScoreHidden}
                learningDomain={learningDomain}
              />
            )}
            {selectedTab === 'explanation' && learningCellReport?.extraInfo.oneOfCase === 'DIAGNOSIS' && (
              <ExplanationView report={learningCellReport} contentsView={contentsView} />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default DiagnosisReportContentView;
