import {atom} from 'jotai';

import {LearningCellState} from '@santa-web/gen/open-api/content-learning-service';
import {learningCellRepository} from '@app/test-preparation/atoms/repositories';
import {learningCellService} from '@app/test-preparation/atoms/services';
import {LearningCellAlreadyDoneError} from '@app/test-preparation/atoms/services/learning-cell/errors/learning-cell-already-done';

type InitializeLearningCellParams = {
  learningCellId: number;
};

const initializeAtom = atom(null, async (get, set, {learningCellId}: InitializeLearningCellParams) => {
  const getLearningCell = await get(learningCellRepository.getLearningCellAtom);

  // Set learning cell
  const learningCell = await getLearningCell(learningCellId);

  set(learningCellService.learningCellAtom, learningCell);

  if (learningCell.state === LearningCellState.READY) {
    await set(learningCellService.updateLearningCellStateAtom, {learningCellState: LearningCellState.ONGOING});
  }

  if (LearningCellAlreadyDoneError.shouldThrow(learningCell)) {
    throw new LearningCellAlreadyDoneError(learningCell);
  }
});

export {initializeAtom};
