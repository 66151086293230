import React from 'react';
import {Field, FieldProps, FieldRenderProps} from 'react-final-form';

import {RadioButton, RadioButtonProps} from '@santa-web/service-ui';

export type RadioButtonFieldProps = RadioButtonProps & FieldProps<string, FieldRenderProps<string>, HTMLLabelElement>;

function RadioButtonField({name, value, validate, validateFields = [], ...props}: RadioButtonFieldProps) {
  return (
    <Field<string> type="radio" name={name} value={value} validate={validate} validateFields={validateFields}>
      {({input}) => {
        return <RadioButton {...input} {...props} />;
      }}
    </Field>
  );
}

export default React.memo(RadioButtonField);
