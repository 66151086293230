import {usePushEvent} from '.';

const useFlashDealEvent = () => {
  const pushEvent = usePushEvent();

  return {
    pushFlashDealExit: () => {
      pushEvent('flashdeal_exit_button');
    },

    pushFlashDealNext: () => {
      pushEvent('flashdeal_next_button');
    },

    pushFlashDealProductCard: () => {
      pushEvent('flashdeal_product_card');
    },

    pushL2EFlashDealExit: () => {
      pushEvent('flashdeal_exit_button_L2E');
    },

    pushL2EFlashDealNext: () => {
      pushEvent('flashdeal_next_button_L2E');
    },

    pushL2EFlashDealProductCard: () => {
      pushEvent('flashdeal_product_card_L2E');
    },
  };
};

export default useFlashDealEvent;
