import {Snippet} from '@santa-web/gen/open-api/content/models/Snippet';
import {Snippet as LegacySnippet} from '@santa-web/gen/ssp/messages/inside';

import {summarize, summarizeLegacyV2} from './summarizer';

export function isEmptySnippet(snippet: Snippet | null | undefined): boolean {
  return snippet == null || summarize(snippet).trim().length === 0;
}

export function isEmptySnippetLegacyV2(snippet: LegacySnippet | null | undefined): boolean {
  return snippet == null || summarizeLegacyV2(snippet).trim().length === 0;
}
