import {MouseEventHandler, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import ResetIcon from '@riiid/design-system/icons/Reset';

import {IconButton, TooltipBox} from '@santa-web/service-ui';
import useShouldShowVocaTutorial from '@app/components/ContentView/hooks/useShouldShowVocaTutorial';

export interface PreviousVocaIconProps {
  isDisabled?: boolean;
  onClick?: MouseEventHandler;
}

const PreviousVocaIcon = ({isDisabled, onClick}: PreviousVocaIconProps) => {
  const shouldShowTooltip = useShouldShowVocaTutorial('VOCA_PREVIOUS_CONTENT_TUTORIAL_TOOLTIP_SHOWN');
  const [isTooltipDisplayDelayEnd, setIsTooltipDisplayDelayEnd] = useState(false);
  const {t} = useTranslation();

  useEffect(() => {
    const timeout = setTimeout(() => {
      setIsTooltipDisplayDelayEnd(true);
    }, 2000);

    return () => clearTimeout(timeout);
  }, []);

  return (
    <div
      css={css`
        display: flex;
        justify-content: flex-end;
        flex: 1;
      `}>
      <TooltipBox
        isDisabledClick
        title={t('toeic_vocabulary_onedirection_guide_tooltip_go_previous')}
        auto={shouldShowTooltip && isTooltipDisplayDelayEnd ? 2000 : null}
        direction="top-right"
        size="medium"
        variant="brand">
        <IconButton
          data-testid="PreviousVocaIcon-IconButton"
          isDisabled={isDisabled}
          Icon={ResetIcon}
          IconProps={{
            type: 'line',
          }}
          onClick={onClick}
        />
      </TooltipBox>
    </div>
  );
};

export default PreviousVocaIcon;
