import React from 'react';

import produce from 'immer';
import {ContentInteractionState, OneOfContentSummary} from '@santa-web/gen/open-api/content-learning-service';
import {Content} from '@santa-web/gen/open-api/content/models/Content';
import {MyNoteCardProps} from '@app/components/card/MyNoteCard';
import {getElapsedTimeMs, getStatesByCase} from '@app/facade/content-interaction-state';
import {useTypedRouter} from '@app/hooks/useTypedRouter';
import {useHandleBookmarkClick} from '@app/pages/learning-cell/hooks/useHandleBookmarkClick';
import {useUpdateCis} from '@app/pages/learning-cell/hooks/useUpdateCis';
import {contentRouterFactory} from '@app/test-preparation/atoms/stores/learning/utils/content-router';

import ContentCardLesson from './ContentCardLesson';
import ContentCardQuestion from './ContentCardQuestion';
import ContentCardVoca from './ContentCardVoca';

export type ContentCardProps = Omit<MyNoteCardProps, 'content'> & {
  content?: Content | null;
  contentInteractionState?: ContentInteractionState | null;
  questionIndex?: number;
  questionTimeHidden?: boolean;
  contentSummary: OneOfContentSummary;
};

const ContentCard = React.forwardRef<HTMLDivElement, ContentCardProps>(
  ({content, contentInteractionState, questionIndex, questionTimeHidden, contentSummary, onClick, ...props}, ref) => {
    const router = useTypedRouter();

    const updateCis = useUpdateCis({
      cellId: contentInteractionState?.learningCellId,
    });
    const {handleBookmarkClick: _handleBookmarkClick} = useHandleBookmarkClick({
      isBookmarked: contentInteractionState?.isBookmarked ?? false,
      toggleBookmark: async () => {
        if (contentInteractionState) {
          updateCis(
            produce(contentInteractionState, draft => {
              draft.isBookmarked = !draft.isBookmarked;
            })
          );
        }
      },
    });
    const handleBookmarkClick: React.MouseEventHandler = e => {
      e.stopPropagation();
      _handleBookmarkClick();
    };

    const isBookmarked = contentInteractionState?.isBookmarked;

    const handleClick =
      onClick ??
      (async () => {
        if (contentInteractionState?.id) {
          await router.push({pathname: '/content-interaction-states', query: {cisIds: [contentInteractionState.id]}});
        }
      });

    if (content == null || contentInteractionState == null) return null;

    const contentInteractionStateByCase = getStatesByCase(contentInteractionState);

    if (contentInteractionStateByCase.oneOfCase === 'QUESTION') {
      return (
        <ContentCardQuestion
          ref={ref}
          questionIndex={questionIndex}
          isBookmarked={isBookmarked}
          onBookmarkClick={handleBookmarkClick}
          questionSetStates={contentInteractionStateByCase.states}
          contentSummary={contentSummary}
          onClick={handleClick}
          timeHidden={questionTimeHidden}
          elapsedTime={getElapsedTimeMs(contentInteractionState)}
          recommendedElapsedTime={contentRouterFactory.create(content).getRecommendedElapsedTime() ?? 0}
          {...props}
        />
      );
    }
    if (contentInteractionStateByCase.oneOfCase === 'LESSON') {
      return (
        <ContentCardLesson
          ref={ref}
          lessonTitle={contentRouterFactory.create(content).getLesson()?.title}
          isBookmarked={isBookmarked}
          onBookmarkClick={handleBookmarkClick}
          onClick={handleClick}
          {...props}
        />
      );
    }
    if (contentInteractionStateByCase.oneOfCase === 'VOCA') {
      const vocaContent = contentRouterFactory.create(content).getVocabulary();
      const vocaCIS = contentInteractionState.elementInteractionStates;

      return (
        <ContentCardVoca
          ref={ref}
          word={vocaContent?.word}
          meaning={
            vocaContent && vocaContent.vocaPosSet.length !== 0
              ? vocaContent?.vocaPosSet.map(pos => pos.meaning).join(' / ')
              : vocaContent?.meaning
          }
          knownVoca={vocaCIS[0].details.voca?.selfReports[0].isKnowledge}
          isBookmarked={isBookmarked}
          onBookmarkClick={handleBookmarkClick}
          onClick={handleClick}
          {...props}
        />
      );
    }
    return null;
  }
);

export default ContentCard;
