import React, {CSSProperties, HTMLAttributes} from 'react';
import {css} from '@emotion/react';
import {getLineClampCss} from '@santa-web/service-ui';

type LineClampProps = {
  limit?: number;
  color?: string;
  wordWrap?: CSSProperties['wordWrap'];
} & HTMLAttributes<HTMLDivElement>;

const LineClamp = ({limit, color, wordWrap = 'break-word', ...divProps}: LineClampProps) => {
  return (
    <div
      css={css`
        word-wrap: ${wordWrap};
        ${limit ? getLineClampCss(limit, color) : undefined}
      `}
      {...divProps}
    />
  );
};

export default LineClamp;
export type {LineClampProps};
LineClamp.displayName = 'LineClamp';
