/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { AbortOrderPaymentRequestToJSON, CompleteOrderPaymentRequestToJSON, ConfigureCouponRequestToJSON, ConfigurePaymentProviderRequestToJSON, CreateOrderRequestToJSON, GetStripeCustomerPortalUrlResponseFromJSON, ListOrderResponseFromJSON, OrderResponseFromJSON, RevokeOrderRequestToJSON, StartOrderPaymentRequestToJSON, } from '../models';
/**
 *
 */
export class OrderV1Api extends runtime.BaseAPI {
    /**
     * 주문에 대한 결제가 실패했음을 처리한다.
     */
    async abortOrderPaymentRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling abortOrderPayment.');
        }
        if (requestParameters.abortOrderPaymentRequest === null || requestParameters.abortOrderPaymentRequest === undefined) {
            throw new runtime.RequiredError('abortOrderPaymentRequest', 'Required parameter requestParameters.abortOrderPaymentRequest was null or undefined when calling abortOrderPayment.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/commerce/v1/order/{id}/payment/abort`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: AbortOrderPaymentRequestToJSON(requestParameters.abortOrderPaymentRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => OrderResponseFromJSON(jsonValue));
    }
    /**
     * 주문에 대한 결제가 실패했음을 처리한다.
     */
    async abortOrderPayment(requestParameters, initOverrides) {
        const response = await this.abortOrderPaymentRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 주문에 대한 결제를 완료한다.
     */
    async completeOrderPaymentRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling completeOrderPayment.');
        }
        if (requestParameters.completeOrderPaymentRequest === null || requestParameters.completeOrderPaymentRequest === undefined) {
            throw new runtime.RequiredError('completeOrderPaymentRequest', 'Required parameter requestParameters.completeOrderPaymentRequest was null or undefined when calling completeOrderPayment.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/commerce/v1/order/{id}/payment/complete`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CompleteOrderPaymentRequestToJSON(requestParameters.completeOrderPaymentRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => OrderResponseFromJSON(jsonValue));
    }
    /**
     * 주문에 대한 결제를 완료한다.
     */
    async completeOrderPayment(requestParameters, initOverrides) {
        const response = await this.completeOrderPaymentRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 주문에 쿠폰을 적용한다.
     */
    async configureCouponRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling configureCoupon.');
        }
        if (requestParameters.configureCouponRequest === null || requestParameters.configureCouponRequest === undefined) {
            throw new runtime.RequiredError('configureCouponRequest', 'Required parameter requestParameters.configureCouponRequest was null or undefined when calling configureCoupon.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/commerce/v1/order/{id}/coupon`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ConfigureCouponRequestToJSON(requestParameters.configureCouponRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => OrderResponseFromJSON(jsonValue));
    }
    /**
     * 주문에 쿠폰을 적용한다.
     */
    async configureCoupon(requestParameters, initOverrides) {
        const response = await this.configureCouponRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 주문에 결제 수단을 지정한다.
     */
    async configurePaymentProviderRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling configurePaymentProvider.');
        }
        if (requestParameters.configurePaymentProviderRequest === null || requestParameters.configurePaymentProviderRequest === undefined) {
            throw new runtime.RequiredError('configurePaymentProviderRequest', 'Required parameter requestParameters.configurePaymentProviderRequest was null or undefined when calling configurePaymentProvider.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/commerce/v1/order/{id}/payment-provider`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: ConfigurePaymentProviderRequestToJSON(requestParameters.configurePaymentProviderRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => OrderResponseFromJSON(jsonValue));
    }
    /**
     * 주문에 결제 수단을 지정한다.
     */
    async configurePaymentProvider(requestParameters, initOverrides) {
        const response = await this.configurePaymentProviderRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 주문을 생성한다.
     */
    async createOrderRaw(requestParameters, initOverrides) {
        if (requestParameters.createOrderRequest === null || requestParameters.createOrderRequest === undefined) {
            throw new runtime.RequiredError('createOrderRequest', 'Required parameter requestParameters.createOrderRequest was null or undefined when calling createOrder.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/commerce/v1/order`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateOrderRequestToJSON(requestParameters.createOrderRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => OrderResponseFromJSON(jsonValue));
    }
    /**
     * 주문을 생성한다.
     */
    async createOrder(requestParameters, initOverrides) {
        const response = await this.createOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 주문 ID로 주문을 조회한다.
     */
    async getOrderRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getOrder.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/commerce/v1/order/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => OrderResponseFromJSON(jsonValue));
    }
    /**
     * 주문 ID로 주문을 조회한다.
     */
    async getOrder(requestParameters, initOverrides) {
        const response = await this.getOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * Stripe의 customer portal url을 조회한다.
     */
    async getStripeCustomerPortalUrlRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/commerce/v1/order/stripe-customer-portal-url`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetStripeCustomerPortalUrlResponseFromJSON(jsonValue));
    }
    /**
     * Stripe의 customer portal url을 조회한다.
     */
    async getStripeCustomerPortalUrl(requestParameters, initOverrides) {
        const response = await this.getStripeCustomerPortalUrlRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 주문 목록을 조회한다.
     */
    async listOrderRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/commerce/v1/order`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListOrderResponseFromJSON(jsonValue));
    }
    /**
     * 주문 목록을 조회한다.
     */
    async listOrder(requestParameters, initOverrides) {
        const response = await this.listOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 주문에 대한 결제를 취소한다.
     */
    async revokeOrderRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling revokeOrder.');
        }
        if (requestParameters.revokeOrderRequest === null || requestParameters.revokeOrderRequest === undefined) {
            throw new runtime.RequiredError('revokeOrderRequest', 'Required parameter requestParameters.revokeOrderRequest was null or undefined when calling revokeOrder.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/commerce/v1/order/{id}/revoke`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: RevokeOrderRequestToJSON(requestParameters.revokeOrderRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => OrderResponseFromJSON(jsonValue));
    }
    /**
     * 주문에 대한 결제를 취소한다.
     */
    async revokeOrder(requestParameters, initOverrides) {
        const response = await this.revokeOrderRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 주문에 대한 결제를 시작한다.
     */
    async startOrderPaymentRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling startOrderPayment.');
        }
        if (requestParameters.startOrderPaymentRequest === null || requestParameters.startOrderPaymentRequest === undefined) {
            throw new runtime.RequiredError('startOrderPaymentRequest', 'Required parameter requestParameters.startOrderPaymentRequest was null or undefined when calling startOrderPayment.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/commerce/v1/order/{id}/payment/start`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: StartOrderPaymentRequestToJSON(requestParameters.startOrderPaymentRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => OrderResponseFromJSON(jsonValue));
    }
    /**
     * 주문에 대한 결제를 시작한다.
     */
    async startOrderPayment(requestParameters, initOverrides) {
        const response = await this.startOrderPaymentRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
