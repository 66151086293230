/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { DurationFromJSON, DurationToJSON, } from './Duration';
import { LearningCellReportCorrectnessInfoFromJSON, LearningCellReportCorrectnessInfoToJSON, } from './LearningCellReportCorrectnessInfo';
import { LearningCellReportOneOfExtraInfoFromJSON, LearningCellReportOneOfExtraInfoToJSON, } from './LearningCellReportOneOfExtraInfo';
/**
 * Check if a given object implements the LearningCellReport interface.
 */
export function instanceOfLearningCellReport(value) {
    let isInstance = true;
    isInstance = isInstance && "extraInfo" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "totalElapsedTime" in value;
    return isInstance;
}
export function LearningCellReportFromJSON(json) {
    return LearningCellReportFromJSONTyped(json, false);
}
export function LearningCellReportFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'extraInfo': LearningCellReportOneOfExtraInfoFromJSON(json['extraInfo']),
        'questionCorrectnessInfo': !exists(json, 'questionCorrectnessInfo') ? undefined : LearningCellReportCorrectnessInfoFromJSON(json['questionCorrectnessInfo']),
        'recommendedElapsedTime': !exists(json, 'recommendedElapsedTime') ? undefined : DurationFromJSON(json['recommendedElapsedTime']),
        'title': json['title'],
        'totalElapsedTime': DurationFromJSON(json['totalElapsedTime']),
        'vocaCorrectnessInfo': !exists(json, 'vocaCorrectnessInfo') ? undefined : LearningCellReportCorrectnessInfoFromJSON(json['vocaCorrectnessInfo']),
    };
}
export function LearningCellReportToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'extraInfo': LearningCellReportOneOfExtraInfoToJSON(value.extraInfo),
        'questionCorrectnessInfo': LearningCellReportCorrectnessInfoToJSON(value.questionCorrectnessInfo),
        'recommendedElapsedTime': DurationToJSON(value.recommendedElapsedTime),
        'title': value.title,
        'totalElapsedTime': DurationToJSON(value.totalElapsedTime),
        'vocaCorrectnessInfo': LearningCellReportCorrectnessInfoToJSON(value.vocaCorrectnessInfo),
    };
}
