import React from 'react';

import {BrowserStorage} from '@santa-web/service-common';

const DIAGNOSIS_REPORT_CONFETTI_EFFECT = 'DIAGNOSIS_REPORT_CONFETTI_EFFECT';

const useIsConfettiVisible = (isCompletingCycle?: boolean) => {
  const storage = React.useMemo(
    () => new BrowserStorage<{[DIAGNOSIS_REPORT_CONFETTI_EFFECT]?: true}>(window.sessionStorage),
    []
  );
  const isConfettiVisible = React.useMemo(
    () => isCompletingCycle && storage.getItem(DIAGNOSIS_REPORT_CONFETTI_EFFECT) !== true,
    [isCompletingCycle, storage]
  );

  React.useEffect(() => {
    if (isConfettiVisible) {
      storage.setItem(DIAGNOSIS_REPORT_CONFETTI_EFFECT, true);
    }
  }, [isConfettiVisible, storage]);

  return isConfettiVisible;
};

export default useIsConfettiVisible;
