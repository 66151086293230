import React from 'react';
import {Form, FormProps} from 'react-final-form';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {
  COLOR_SANTA_B,
  COLOR_SANTA_C,
  COLOR_SANTA_E,
  COLOR_SANTA_H,
  COLOR_SANTA_I,
  COLOR_SANTA_J,
} from '@riiid/design-system';
import isEmail from 'validator/lib/isEmail';

import {Button, Typography} from '@santa-web/service-ui';
import ButtonList from '@app/components/ButtonList';
import ButtonTextWithCheckbox from '@app/components/ButtonTextWithCheckbox';
import TextField, {TextFieldProps} from '@app/components/final-form/TextField';
import FullWidthSolidButton from '@app/components/FullWidthSolidButton';
import LoadableButton from '@app/components/LoadableButton';
import SingleColumn from '@app/components/SingleColumn';
import {useCommonValidator, useNameValidator} from '@app/features/auth/hooks';
import {SignupFormValues} from '@app/features/auth/types';
import {OnboardingLayout} from '@app/features/onboarding';
import {OnSubmit} from '@app/types/form';

import AgreementPrivacyInfo from './AgreementPrivacyInfo';
import CheckboxFieldItem from './CheckboxFieldItem';

interface CommonProps {
  initialValues: SignupFormValues;
  passwordFields?: React.ReactElement<TextFieldProps>[];
  onSubmit: OnSubmit<SignupFormValues>;
  signUpType: 'email' | 'sns';
  onEmailLogin?: React.MouseEventHandler<HTMLButtonElement>;
}

const SignupForm = ({passwordFields, initialValues, signUpType, onSubmit, onEmailLogin}: CommonProps) => {
  const {t} = useTranslation();
  const nameValidator = useNameValidator();
  const commonValidator = useCommonValidator();

  const validateFormat = React.useCallback(
    (email?: string) => {
      if (!isEmail(email ?? '')) {
        return t('sign_error_email_invalid_format');
      }
    },
    [t]
  );

  const handleSubmit: FormProps<SignupFormValues>['onSubmit'] = React.useCallback(
    async (values, form, callback) => {
      const validationResult = validateFormat(values.email);
      if (validationResult) {
        return {email: validationResult};
      }
      return onSubmit(values, form, callback);
    },
    [onSubmit, validateFormat]
  );

  return (
    <Form<SignupFormValues> onSubmit={handleSubmit} initialValues={initialValues}>
      {({handleSubmit, hasValidationErrors, submitting, values, form}) => {
        const isLoading = submitting;
        const isDisabled = hasValidationErrors || isLoading;

        const allAgreed = [
          values.agreeServiceTerms,
          values.agreePrivacy,
          values.agreeMarketing,
          values.agreeAge14OrOlder,
        ].every(Boolean);

        function handleAgreeAllClick() {
          form.batch(() => {
            const notAllAgreed = !allAgreed;
            form.change('agreeServiceTerms', notAllAgreed);
            form.change('agreePrivacy', notAllAgreed);
            form.change('agreeMarketing', notAllAgreed);
            form.change('agreeAge14OrOlder', notAllAgreed);
          });
        }

        return (
          <form onSubmit={handleSubmit}>
            <OnboardingLayout
              isNarrow
              bottomArea={
                signUpType === 'sns' && (
                  <LoadableButton
                    as={FullWidthSolidButton}
                    isLoading={isLoading}
                    isDisabled={isDisabled}
                    colorVariant="brand"
                    variant="bottom-fix"
                    loadingAnimationVariant="gray">
                    {t('sign_up_complete')}
                  </LoadableButton>
                )
              }>
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                `}>
                <SingleColumn
                  gap={40}
                  customCss={css`
                    margin-bottom: 40px;
                  `}>
                  <Typography variant="caption1" fontWeight="bold" color={COLOR_SANTA_I}>
                    {t('page_signup_sub_title')}
                  </Typography>
                  <TextField
                    key="name"
                    type="text"
                    name="name"
                    validate={nameValidator}
                    label={t('component_sign_form_name_label')}
                    hint={t('component_sign_form_name_hint')}
                  />
                  <TextField
                    key="email"
                    type="text"
                    name="email"
                    validate={validateFormat}
                    label={t('component_sign_form_email_label')}
                    hint={t('component_sign_form_email_hint')}
                  />
                  {passwordFields}
                </SingleColumn>
                <hr
                  css={css`
                    border: none;
                    width: 100vw;
                    margin: 0 calc((100vw - 100%) / 2 * -1) 40px;
                    border-bottom: 8px solid ${COLOR_SANTA_C};
                  `}
                />
                <ButtonTextWithCheckbox
                  customCss={css`
                    display: flex;
                    margin-bottom: 24px;
                  `}
                  checked={allAgreed}
                  onClick={handleAgreeAllClick}>
                  {t('sign_agree_to_all')}
                </ButtonTextWithCheckbox>
                <SingleColumn gap={8}>
                  <CheckboxFieldItem
                    name="agreeServiceTerms"
                    validate={commonValidator.required}
                    href={t('terms_of_service')}>
                    {t('sign_agreement_terms_of_service')}
                  </CheckboxFieldItem>
                  <SingleColumn gap={8}>
                    <CheckboxFieldItem
                      name="agreePrivacy"
                      validate={commonValidator.required}
                      href={t('terms_of_privacy')}>
                      {t('sign_agreement_privacy_info')}
                    </CheckboxFieldItem>
                    <div
                      css={css`
                        background-color: ${COLOR_SANTA_B};
                        border: 1px solid ${COLOR_SANTA_E};
                        border-radius: 4px;
                        padding: 12px;
                        display: flex;
                        flex-direction: column;
                      `}>
                      <Typography variant="caption1" color={COLOR_SANTA_J}>
                        {t('sign_agreement_privacy_info_preview_title')}
                      </Typography>
                      <div
                        css={css`
                          margin-top: 8px;
                          display: grid;
                          grid-gap: 4px;
                          color: ${COLOR_SANTA_H};
                        `}>
                        <AgreementPrivacyInfo
                          title={t('sign_agreement_privacy_info_preview_head_01')}
                          subTitle={t('sign_agreement_privacy_info_preview_description_01')}
                        />
                        <AgreementPrivacyInfo
                          title={t('sign_agreement_privacy_info_preview_head_02')}
                          subTitle={t('sign_agreement_privacy_info_preview_description_02')}
                        />
                        <AgreementPrivacyInfo
                          title={t('sign_agreement_privacy_info_preview_head_03')}
                          subTitle={t('sign_agreement_privacy_info_preview_description_03')}
                        />
                      </div>
                      <Typography
                        variant="caption1"
                        color={COLOR_SANTA_H}
                        css={css`
                          margin-top: 8px;
                        `}>
                        {t('sign_agreement_privacy_info_preview_body')}
                      </Typography>
                    </div>
                  </SingleColumn>
                  <CheckboxFieldItem name="agreeAge14OrOlder" validate={commonValidator.required}>
                    {t('sign_agreement_age')}
                  </CheckboxFieldItem>
                  <CheckboxFieldItem name="agreeMarketing">{t('sign_agreement_marketing')}</CheckboxFieldItem>
                </SingleColumn>
                {signUpType === 'email' && (
                  <ButtonList
                    css={css`
                      margin-top: 40px;
                    `}>
                    <LoadableButton
                      as={FullWidthSolidButton}
                      isLoading={isLoading}
                      isDisabled={isDisabled}
                      colorVariant="brand"
                      loadingAnimationVariant="gray">
                      {t('sign_up_complete')}
                    </LoadableButton>
                    <Button onClick={onEmailLogin} isFullWidth variant="solid" colorVariant="transparent">
                      {t('sign_in_with_registered_email')}
                    </Button>
                  </ButtonList>
                )}
              </div>
            </OnboardingLayout>
          </form>
        );
      }}
    </Form>
  );
};

export default SignupForm;
