/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { RelatedOrderV2FromJSON, RelatedOrderV2ToJSON, } from './RelatedOrderV2';
import { SourceTypeV2FromJSON, SourceTypeV2ToJSON, } from './SourceTypeV2';
/**
 * Check if a given object implements the ActivePassV2 interface.
 */
export function instanceOfActivePassV2(value) {
    let isInstance = true;
    isInstance = isInstance && "displayName" in value;
    isInstance = isInstance && "expireAt" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "sourceType" in value;
    return isInstance;
}
export function ActivePassV2FromJSON(json) {
    return ActivePassV2FromJSONTyped(json, false);
}
export function ActivePassV2FromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'displayName': json['displayName'],
        'expireAt': (new Date(json['expireAt'])),
        'id': json['id'],
        'relatedOrder': !exists(json, 'relatedOrder') ? undefined : RelatedOrderV2FromJSON(json['relatedOrder']),
        'sourceType': SourceTypeV2FromJSON(json['sourceType']),
    };
}
export function ActivePassV2ToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'displayName': value.displayName,
        'expireAt': (value.expireAt.toISOString()),
        'id': value.id,
        'relatedOrder': RelatedOrderV2ToJSON(value.relatedOrder),
        'sourceType': SourceTypeV2ToJSON(value.sourceType),
    };
}
