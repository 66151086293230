import React from 'react';
import {css} from '@emotion/react';

export type TabNavVerticalProps = React.ComponentPropsWithoutRef<'div'>;

const TabNavVertical = React.forwardRef<HTMLDivElement, TabNavVerticalProps>(({children, ...props}, ref) => {
  return (
    <div
      css={css`
        display: inline-grid;
        grid-auto-flow: row;
        row-gap: 40px;
        grid-row-gap: 40px;
      `}
      ref={ref}
      {...props}>
      {children}
    </div>
  );
});

export default React.memo(TabNavVertical);
