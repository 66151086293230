import {PriceFormatter} from './PriceFormatter';

export class KrPriceFormatter extends PriceFormatter {
  getCurrencyText(): string {
    return '원';
  }
  getLocale(): string {
    return 'ko-KR';
  }
  getCutUnit(): number {
    return 10;
  }
  getMonthlyCeilUnit(): number {
    return 2;
  }
}
