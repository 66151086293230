/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { AmountDataFromJSON, AmountDataToJSON, } from './AmountData';
import { OrderConfigFromJSON, OrderConfigToJSON, } from './OrderConfig';
import { OrderOfferGroupFromJSON, OrderOfferGroupToJSON, } from './OrderOfferGroup';
import { OrderStatusFromJSON, OrderStatusToJSON, } from './OrderStatus';
import { PaymentDataFromJSON, PaymentDataToJSON, } from './PaymentData';
import { PermitItemsFromJSON, PermitItemsToJSON, } from './PermitItems';
import { TimestampInfoFromJSON, TimestampInfoToJSON, } from './TimestampInfo';
/**
 * Check if a given object implements the Order interface.
 */
export function instanceOfOrder(value) {
    let isInstance = true;
    isInstance = isInstance && "amountData" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "offerGroup" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "timestampInfo" in value;
    return isInstance;
}
export function OrderFromJSON(json) {
    return OrderFromJSONTyped(json, false);
}
export function OrderFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'amountData': AmountDataFromJSON(json['amountData']),
        'id': json['id'],
        'offerGroup': OrderOfferGroupFromJSON(json['offerGroup']),
        'orderConfig': !exists(json, 'orderConfig') ? undefined : OrderConfigFromJSON(json['orderConfig']),
        'paymentData': !exists(json, 'paymentData') ? undefined : PaymentDataFromJSON(json['paymentData']),
        'permitItems': !exists(json, 'permitItems') ? undefined : PermitItemsFromJSON(json['permitItems']),
        'status': OrderStatusFromJSON(json['status']),
        'timestampInfo': TimestampInfoFromJSON(json['timestampInfo']),
    };
}
export function OrderToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'amountData': AmountDataToJSON(value.amountData),
        'id': value.id,
        'offerGroup': OrderOfferGroupToJSON(value.offerGroup),
        'orderConfig': OrderConfigToJSON(value.orderConfig),
        'paymentData': PaymentDataToJSON(value.paymentData),
        'permitItems': PermitItemsToJSON(value.permitItems),
        'status': OrderStatusToJSON(value.status),
        'timestampInfo': TimestampInfoToJSON(value.timestampInfo),
    };
}
