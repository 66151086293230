import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {COLOR_SANTA_D, COLOR_SANTA_E, COLOR_SANTA_I, COLOR_SANTA_J} from '@riiid/design-system';
import ReportErrorIcon from '@riiid/design-system/icons/ReportError';
import {Typography} from '@santa-web/service-ui';
import {OfferGroupCard, OfferGroupCardProps as _OfferGroupCardProps} from '@app/features/offer';
import {ListLayout, ListLayoutProps} from '@app/features/offer/components';

export type OfferGroupCardProps = Omit<_OfferGroupCardProps, 'onClick'> & {id: number};

type OfferGroupBoardProps = Pick<ListLayoutProps, 'onGoBack'> & {
  learningDomain: string;
  offerGroupCards: OfferGroupCardProps[];
  onOfferGroupCardClick: (offerGroupId: number) => void;
};

const OfferGroupBoard = ({learningDomain, offerGroupCards, onOfferGroupCardClick, onGoBack}: OfferGroupBoardProps) => {
  const {t} = useTranslation();
  return (
    <ListLayout
      info={
        <div
          css={css`
            display: flex;
            justify-content: flex-start;
            align-items: center;
            padding: 12px;
            background-color: ${COLOR_SANTA_D};
            border: 1px solid ${COLOR_SANTA_E};
            border-radius: 4px;
          `}>
          <ReportErrorIcon
            css={css`
              margin-right: 12px;
            `}
            color={COLOR_SANTA_J}
            width={28}
            height={28}
            type="line"
          />
          <Typography color={COLOR_SANTA_I} variant="caption1">
            {t('page_offer_group_board_list_information', {domain: learningDomain})}
          </Typography>
        </div>
      }
      onGoBack={onGoBack}>
      <div
        css={css`
          display: grid;
          gap: 12px;
        `}>
        {offerGroupCards.map(offerGroupCard => (
          <OfferGroupCard
            key={offerGroupCard.id}
            {...offerGroupCard}
            onClick={() => onOfferGroupCardClick(offerGroupCard.id)}
          />
        ))}
      </div>
    </ListLayout>
  );
};

export default OfferGroupBoard;
export type {OfferGroupBoardProps};
OfferGroupBoard.displayName = 'OfferGroupBoard';
