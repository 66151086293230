import React from 'react';
import {useTranslation} from 'react-i18next';
import {Interpolation, Theme, css} from '@emotion/react';
import {COLOR_SANTA_G} from '@riiid/design-system';
import Warning from '@riiid/design-system/icons/Warning';
import {LoadingCircleAnimation, Typography} from '@santa-web/service-ui';

type DynamicImageLoaderProps = {
  urls: Array<string>;
  onImageClick?(): void;
  imgCss?: Interpolation<Theme>;
  imgStateWrapperCss?: Interpolation<Theme>;
  imgAlt?: string;
};

const DynamicImageLoader = ({urls, onImageClick, imgCss, imgStateWrapperCss, imgAlt = ''}: DynamicImageLoaderProps) => {
  const {t} = useTranslation();

  const [isImageLoading, setIsImageLoading] = React.useState(true);
  const [isImageLoadFailed, setIsImageLoadFailed] = React.useState(false);
  const [loadedImageCount, setLoadedImageCount] = React.useState(0);

  const handleImageLoad = () => {
    setLoadedImageCount(prevCount => prevCount + 1);
  };

  const handleImageLoadFailed = () => {
    setIsImageLoading(false);
    setIsImageLoadFailed(true);
  };

  React.useEffect(() => {
    if (loadedImageCount === urls.length) {
      setIsImageLoading(false);
    }
  }, [urls.length, loadedImageCount]);

  return (
    <>
      {(isImageLoadFailed || isImageLoading) && (
        <div
          css={[
            css`
              height: 100%;
              width: 100%;
              display: flex;
              flex-direction: column;
              justify-content: center;
              align-items: center;
              gap: 4px;
            `,
            imgStateWrapperCss,
          ]}>
          {isImageLoading && <LoadingCircleAnimation width={32} height={32} variant="gray" />}
          {isImageLoadFailed && (
            <>
              <Warning width={40} height={40} type="solid" size="L" color={COLOR_SANTA_G} />
              <Typography variant="caption1" fontWeight="regular" color={COLOR_SANTA_G}>
                {t('image_loaded_fail')}
              </Typography>
            </>
          )}
        </div>
      )}
      {urls.map((url, index) => {
        return (
          <img
            key={index}
            css={[
              css`
                position: relative;
                width: 100%;
              `,
              imgCss,
            ]}
            src={url}
            alt={imgAlt}
            onLoad={handleImageLoad}
            onError={handleImageLoadFailed}
            onClick={onImageClick}
            style={{display: isImageLoading || isImageLoadFailed ? 'none' : 'block'}}
          />
        );
      })}
    </>
  );
};

export default DynamicImageLoader;
export type {DynamicImageLoaderProps};
DynamicImageLoader.displayName = 'DynamicImageLoader';
