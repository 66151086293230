import { DayOfWeekFromJSON, DayOfWeekToJSON, } from './DayOfWeek';
/**
 * Check if a given object implements the LearningGoal interface.
 */
export function instanceOfLearningGoal(value) {
    let isInstance = true;
    isInstance = isInstance && "dayOfWeeks" in value;
    isInstance = isInstance && "studyMinutesPerDay" in value;
    return isInstance;
}
export function LearningGoalFromJSON(json) {
    return LearningGoalFromJSONTyped(json, false);
}
export function LearningGoalFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'dayOfWeeks': (json['dayOfWeeks'].map(DayOfWeekFromJSON)),
        'studyMinutesPerDay': json['studyMinutesPerDay'],
    };
}
export function LearningGoalToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'dayOfWeeks': (value.dayOfWeeks.map(DayOfWeekToJSON)),
        'studyMinutesPerDay': value.studyMinutesPerDay,
    };
}
