import React from 'react';

import {useAtomValue} from 'jotai';
import {TopNavBarProps} from '@santa-web/service-ui';

import {learningStore} from '@app/test-preparation/atoms/stores';
import ToeicSelfLessonTopNavBar from './ToeicSelfLessonTopNavBar';
import ToeicSelfQuestionTopNavBar from './ToeicSelfQuestionTopNavBar';
import ToeicSelfVocabularyTopNavBar from './ToeicSelfVocabularyTopNavBar';

export type ToeicSelfTopNavBarProps = TopNavBarProps;

function ToeicSelfTopNavBar(props: ToeicSelfTopNavBarProps): React.ReactElement | null {
  const isLessonCell = useAtomValue(learningStore.cell.isLessonAtom);
  const isVocabularyCell = useAtomValue(learningStore.cell.isVocabularyAtom);

  if (isLessonCell) {
    return <ToeicSelfLessonTopNavBar {...props} />;
  }

  if (isVocabularyCell) {
    return <ToeicSelfVocabularyTopNavBar {...props} />;
  }

  return <ToeicSelfQuestionTopNavBar {...props} />;
}

export default React.memo(ToeicSelfTopNavBar);
