import {format} from 'date-fns';

import {mobileService} from '@app/api/app-bridge/mobile-service';
import config from '@app/config';
import {PaymentFlowReferrer} from '@app/misc/query-state';
import {IamportCompleteUrlQueryParam, IamportPgMethod} from '@app/types/payment';
import {isAppBridgeAvailable} from '@app/utils/app-bridge';
import {delay} from '@app/utils/async';
import {loadScript} from '@app/utils/script';

export type RequestIamportPaymentParam = {
  productId: string;
  productTitle: string;
  orderId: string;
  billId: string;
  amount: number;
  buyerTel?: string;
  expectedFulfillmentsExpireAt?: number;
  isMobile?: boolean;
  referrer?: PaymentFlowReferrer;
} & IamportPgMethod;

export interface RequestIamportPayment {
  (param: RequestIamportPaymentParam, redirectUrl: string): Promise<void>;
}

export interface CommonIamportRequestPayParam {
  name: string;
  merchant_uid: string;
  amount: number;
  m_redirect_url: string;
  app_scheme: string;
  company: '(주)뤼이드';
  pg_provider?: 'kcp' | 'naverpay';
  buyer_tel: string;
}
export interface KcpIamportRequestPayParam extends CommonIamportRequestPayParam {
  pg: 'kcp';
  pay_method: 'card';
}

export interface NaverProductItem {
  categoryType: 'BOOK' | 'PRODUCT' | 'ETC';
  categoryId: 'GENERAL' | 'DIGITAL_CONTENT' | 'ETC';
  uid: string;
  name: string;
  count: number;
}

export interface NaverPayIamportRequestPayParam extends CommonIamportRequestPayParam {
  pg: 'naverpay';
  pay_method: 'card';
  // 이용완료일자
  naverUseCfm: string;
  naverPopupMode: boolean;
  naverProducts: NaverProductItem[];
}
export type IamportRequestPayParam = KcpIamportRequestPayParam | NaverPayIamportRequestPayParam;
export type IamportRequestPayResponse =
  | {
      success: true;
      imp_uid: string;
      merchant_uid: string;
      paid_amount: number;
      apply_num: string;
      pg_provider?: 'kcp' | 'naverpay';
    }
  | {
      success: false;
      imp_uid: string | null;
      error_code?: string;
      error_msg: string;
      pg_provider?: 'kcp' | 'naverpay';
    };
export interface IamportRequestPayCallback {
  (response: IamportRequestPayResponse): void;
}

export interface Iamport {
  init(key: string): void;
  request_pay(param: IamportRequestPayParam, successCallback: IamportRequestPayCallback): void;
}

export const loadDeps = (() => {
  let scriptLoadingState: 'idle' | 'loading' | 'loaded' | 'failed' = 'idle';
  return async () => {
    if (scriptLoadingState === 'loaded') return;
    if (scriptLoadingState === 'loading') {
      const check = async (): Promise<unknown> => {
        if (scriptLoadingState === 'loaded' || scriptLoadingState === 'failed') return;
        await delay(300);
        return check();
      };
      await check();
      return;
    }
    scriptLoadingState = 'loading';

    return Promise.all([loadScript(config.jqueryUrl), loadScript(config.iamportUrl)])
      .then(async () => {
        (window as any).IMP.init(config.iamportCode);
      })
      .then(() => (scriptLoadingState = 'loaded'))
      .catch(() => (scriptLoadingState = 'failed'));
  };
})();

export const getIamport = async (): Promise<Iamport> => {
  await loadDeps();
  return (window as any).IMP;
};

export const getMobileRedirectUrl = (
  param: Pick<IamportCompleteUrlQueryParam, 'orderId' | 'referrer'>,
  redirectPath: string
) => {
  const redirectUrl = new URL(redirectPath, window.location.origin);
  redirectUrl.searchParams.append('method', 'iamport');
  redirectUrl.searchParams.append('id', param.orderId);
  redirectUrl.searchParams.append('orderId', param.orderId);
  if (param.referrer) {
    redirectUrl.searchParams.append('referrer', param.referrer);
  }
  return redirectUrl;
};

export const getRedirectUrl = (param: IamportCompleteUrlQueryParam, redirectPath: string): URL => {
  const redirectUrl = new URL(redirectPath, window.location.origin);
  redirectUrl.searchParams.append('method', 'iamport');
  redirectUrl.searchParams.append('id', param.orderId);
  redirectUrl.searchParams.append('orderId', param.orderId);
  redirectUrl.searchParams.append('imp_uid', param.impUid);
  redirectUrl.searchParams.append('merchant_uid', param.billId);

  if (param.pgProvider) {
    redirectUrl.searchParams.append('pg_provider', param.pgProvider);
  }
  if (param.error_code) {
    redirectUrl.searchParams.append('error_code', param.error_code);
  }
  if (param.error_msg) {
    redirectUrl.searchParams.append('error_msg', param.error_msg);
  }
  if (param.referrer) {
    redirectUrl.searchParams.append('referrer', param.referrer);
  }

  return redirectUrl;
};

export const requestIamportPayment: RequestIamportPayment = async (
  {
    productId,
    productTitle,
    orderId,
    amount,
    billId,
    pg,
    method,
    expectedFulfillmentsExpireAt,
    buyerTel,
    isMobile,
    referrer,
  },
  redirectPath
): Promise<void> => {
  const iamport = await getIamport();

  const commonParam: CommonIamportRequestPayParam = {
    m_redirect_url: getMobileRedirectUrl({orderId, referrer}, redirectPath).href,
    amount,
    merchant_uid: billId,
    app_scheme: 'aitutor',
    company: '(주)뤼이드',
    name: productTitle,
    buyer_tel: buyerTel ?? '',
  };

  const iamportParam: IamportRequestPayParam = (() => {
    switch (pg) {
      case 'kcp': {
        const result: KcpIamportRequestPayParam = {
          ...commonParam,
          pg: 'kcp',
          pay_method: method,
        };
        return result;
      }
      case 'naverpay': {
        if (!expectedFulfillmentsExpireAt) {
          throw new Error('confirmedDate should be specified on naverpay');
        }

        // https://github.com/iamport/iamport-manual/blob/master/NAVERPAY/sample/naverpay-pg.md
        const result: NaverPayIamportRequestPayParam = {
          ...commonParam,
          pg: 'naverpay',
          pay_method: method,
          naverUseCfm: format(expectedFulfillmentsExpireAt, 'yyyyMMdd'),
          naverPopupMode: !isMobile,
          naverProducts: [
            {
              categoryType: 'ETC',
              categoryId: 'ETC',
              name: productTitle,
              uid: productId,
              count: 1,
            },
          ],
        };
        return result;
      }
    }
  })();

  // 인앱 환경에서는 인앱 환경에 세팅된 IAMPORT SDK를 호출
  if (isAppBridgeAvailable()) {
    const response = await mobileService.requestIamportPayment({
      userCode: config.iamportCode,
      name: iamportParam.name,
      merchantUid: iamportParam.merchant_uid,
      amount: iamportParam.amount,
      appScheme: iamportParam.app_scheme,
      company: iamportParam.company,
      pg: iamportParam.pg,
      payMethod: iamportParam.pay_method,
      buyerTel: iamportParam.buyer_tel,
      ...(iamportParam.pg === 'naverpay'
        ? {
            naverUseCfm: iamportParam.naverUseCfm,
            naverProduct: iamportParam.naverProducts[0],
          }
        : {}),
    });
    window.location.href = getRedirectUrl(
      {
        orderId: orderId.toString(),
        impUid: response.impUid,
        billId,
        success: response.isSuccess,
        pgProvider: iamportParam.pg,
        error_msg: response.errorMessage,
      },
      redirectPath
    ).href;
  } else {
    // JS SDK 호출
    return new Promise((resolve, reject) => {
      iamport.request_pay(iamportParam, res => {
        if (!res.success && !res.imp_uid) {
          return reject({
            code: res.error_code,
            message: res.error_msg,
          });
        }
        if (!res.imp_uid) return reject('unexpected response');
        window.location.href = getRedirectUrl(
          {
            orderId,
            impUid: res.imp_uid,
            billId,
            success: res.success,
            pgProvider: res.pg_provider,
            error_code: 'error_code' in res ? res.error_code : undefined,
            error_msg: 'error_msg' in res ? res.error_msg : undefined,
            referrer,
          },
          redirectPath
        ).href;
        resolve();
      });
    });
  }
};
