import {useQueryClient, useMutation} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';

import {ChatMessage} from '@santa-web/gen/open-api/content-learning-service';
import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {getChatMessagesQueryKey} from '@app/queryKeys';

type MutationVariables = {
  chatRoomId: number;
  chatMessageId: number;
};

const useDeleteChatMessageMutation = () => {
  const queryClient = useQueryClient();

  const {ChatMessageService} = useAtomValue(santaOpenapiServicesAtom);

  return useMutation(
    async ({chatMessageId}: MutationVariables) =>
      ChatMessageService.deleteChatMessage({
        messageId: chatMessageId,
      }),
    {
      onSuccess: async (_, {chatRoomId, chatMessageId}) => {
        const chatMessagesQueryKey = getChatMessagesQueryKey(chatRoomId);

        await queryClient.cancelQueries(chatMessagesQueryKey);
        queryClient.setQueryData<Array<ChatMessage>>(chatMessagesQueryKey, (oldChatMessages = []) =>
          oldChatMessages.filter(({id}) => id !== chatMessageId)
        );
      },
    }
  );
};

export default useDeleteChatMessageMutation;
