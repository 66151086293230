export type FloatingButtonCase =
  | 'submit'
  | 'goToNextPart'
  | 'goToNextPartContent'
  | 'moveToQuestions'
  | 'moveToListeningQuestion';

export interface FloatingButtonItem {
  title: string;
  priority: number;
  onClick: () => Promise<void>;
}

export const floatingButtonPriorities: Record<FloatingButtonCase, number> = {
  submit: 3,
  goToNextPart: 2,
  goToNextPartContent: 2,
  moveToQuestions: 2,
  moveToListeningQuestion: 1,
};
