function _array_like_to_array(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _array_with_holes(arr) {
    if (Array.isArray(arr)) return arr;
}
function _iterable_to_array_limit(arr, i) {
    var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];
    if (_i == null) return;
    var _arr = [];
    var _n = true;
    var _d = false;
    var _s, _e;
    try {
        for(_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true){
            _arr.push(_s.value);
            if (i && _arr.length === i) break;
        }
    } catch (err) {
        _d = true;
        _e = err;
    } finally{
        try {
            if (!_n && _i["return"] != null) _i["return"]();
        } finally{
            if (_d) throw _e;
        }
    }
    return _arr;
}
function _non_iterable_rest() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _sliced_to_array(arr, i) {
    return _array_with_holes(arr) || _iterable_to_array_limit(arr, i) || _unsupported_iterable_to_array(arr, i) || _non_iterable_rest();
}
function _unsupported_iterable_to_array(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _array_like_to_array(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _array_like_to_array(o, minLen);
}
import React from "react";
import { isVisibleElement, getElementXToScrollRight, getElementXToScrollLeft } from "../utils";
var useTabNavChevronHelpers = function() {
    var offset = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : 0;
    var scrollRef = React.useRef(null);
    var childrenListRef = React.useRef([]);
    var handleLeftButtonClick = React.useCallback(function() {
        var scroll = scrollRef.current;
        if (!scroll) return;
        var _childrenListRef_current_filter = _sliced_to_array(childrenListRef.current.filter(function(element) {
            return isVisibleElement(element, scroll, {
                left: offset,
                right: offset
            });
        }), 1), target = _childrenListRef_current_filter[0];
        if (!target) return;
        scroll.scrollTo({
            left: getElementXToScrollRight(target, scroll, offset),
            behavior: "smooth"
        });
    }, [
        offset
    ]);
    var handleRightButtonClick = React.useCallback(function() {
        var scroll = scrollRef.current;
        if (!scroll) return;
        var _childrenListRef_current_filter_slice = _sliced_to_array(childrenListRef.current.filter(function(element) {
            return isVisibleElement(element, scroll, {
                left: offset,
                right: offset
            });
        }).slice(-1), 1), target = _childrenListRef_current_filter_slice[0];
        if (!target) return;
        scroll.scrollTo({
            left: getElementXToScrollLeft(target, scroll, offset),
            behavior: "smooth"
        });
    }, [
        offset
    ]);
    return {
        scrollRef: scrollRef,
        childrenListRef: childrenListRef,
        handleLeftButtonClick: handleLeftButtonClick,
        handleRightButtonClick: handleRightButtonClick
    };
};
export default useTabNavChevronHelpers;
