import {useQueryClient, useMutation} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';

import {ChatMessage, ChatMessageFeedback} from '@santa-web/gen/open-api/content-learning-service';
import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {getChatMessagesQueryKey} from '@app/queryKeys';

type MutationVariables = {
  chatRoomId: number;
  chatMessageId: number;
  chatMessageFeedback: 'LIKE' | 'DISLIKE';
};

const useFeedbackChatMessageMutation = () => {
  const queryClient = useQueryClient();

  const {ChatMessageService} = useAtomValue(santaOpenapiServicesAtom);

  return useMutation(
    ({chatMessageId, chatMessageFeedback}: MutationVariables) =>
      ChatMessageService.feedbackChatMessage({
        messageId: chatMessageId,
        feedbackChatMessageRequest: {
          thumbs: chatMessageFeedback === 'LIKE' ? ChatMessageFeedback.UP : ChatMessageFeedback.DOWN,
        },
      }).then(({message: chatMessage}) => chatMessage),
    {
      onSuccess: async (newChatMessage, {chatRoomId, chatMessageId}) => {
        const chatMessagesQueryKey = getChatMessagesQueryKey(chatRoomId);

        await queryClient.cancelQueries(chatMessagesQueryKey);
        queryClient.setQueryData<Array<ChatMessage>>(chatMessagesQueryKey, (oldChatMessages = []) =>
          oldChatMessages.map(chatMessage => (chatMessage.id === chatMessageId ? newChatMessage : chatMessage))
        );
      },
    }
  );
};

export default useFeedbackChatMessageMutation;
