/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { LearningHistoryAnswerStateFromJSON, LearningHistoryAnswerStateToJSON, } from './LearningHistoryAnswerState';
/**
 * Check if a given object implements the LearningHistory interface.
 */
export function instanceOfLearningHistory(value) {
    let isInstance = true;
    isInstance = isInstance && "completedAt" in value;
    isInstance = isInstance && "contentInteractionStateId" in value;
    isInstance = isInstance && "isBookmarked" in value;
    isInstance = isInstance && "title" in value;
    return isInstance;
}
export function LearningHistoryFromJSON(json) {
    return LearningHistoryFromJSONTyped(json, false);
}
export function LearningHistoryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'answerStates': !exists(json, 'answerStates') ? undefined : (json['answerStates'].map(LearningHistoryAnswerStateFromJSON)),
        'completedAt': (new Date(json['completedAt'])),
        'contentInteractionStateId': json['contentInteractionStateId'],
        'isBookmarked': json['isBookmarked'],
        'previewImageUrl': !exists(json, 'previewImageUrl') ? undefined : json['previewImageUrl'],
        'previewTags': !exists(json, 'previewTags') ? undefined : json['previewTags'],
        'previewText': !exists(json, 'previewText') ? undefined : json['previewText'],
        'title': json['title'],
    };
}
export function LearningHistoryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'answerStates': value.answerStates === undefined ? undefined : (value.answerStates.map(LearningHistoryAnswerStateToJSON)),
        'completedAt': (value.completedAt.toISOString()),
        'contentInteractionStateId': value.contentInteractionStateId,
        'isBookmarked': value.isBookmarked,
        'previewImageUrl': value.previewImageUrl,
        'previewTags': value.previewTags,
        'previewText': value.previewText,
        'title': value.title,
    };
}
