const RANGE_OF_YEARS = 100;
const MAX_NUMBER_OF_MONTHS = 12;
const MAX_NUMBER_OF_DAYS = 31;
const MAX_NUMBER_OF_WEEKS = 6;

export const YEARS = Array.from({length: RANGE_OF_YEARS}, (_, i) => ({
  label: String(1950 + i).padStart(4, '0'),
  value: 1950 + i,
}));
export const MONTHS = Array.from({length: MAX_NUMBER_OF_MONTHS}, (_, i) => ({
  label: String(i + 1).padStart(2, '0'),
  value: i + 1,
}));
export const DAYS = Array.from({length: MAX_NUMBER_OF_DAYS}, (_, i) => ({
  label: String(i + 1).padStart(2, '0'),
  value: i + 1,
}));
export const WEEKS = Array.from({length: MAX_NUMBER_OF_WEEKS}, (_, i) => ({label: `Week ${i + 1}`, value: i + 1}));
