import React from 'react';
import {useTranslation} from 'react-i18next';
import {useToastContext} from '@santa-web/service-ui';
import {mobileService} from '@app/api/app-bridge/mobile-service';
import {FriendRecommendationCard, useMyReferralInfoQuery} from '@app/features/preferences';
import {useOpenNewWindow} from '@app/hooks/useOpenNewWindow';
import {isAppBridgeAvailable} from '@app/utils/app-bridge';

// KR only
const FriendRecommendationCardContainer = () => {
  const {t} = useTranslation();
  const {openToast} = useToastContext();
  const openNewWindow = useOpenNewWindow();

  const {data: referralInfo} = useMyReferralInfoQuery();

  return referralInfo ? (
    <FriendRecommendationCard
      friendCount={referralInfo.referredCount}
      onBenefitButtonClick={() => openNewWindow('https://riiid.notion.site/d194d1b245584486b44cf31d4d0b29c1')}
      onShareButtonClick={() => {
        const deepLinkValue = encodeURIComponent(
          `riiidaitutor://toeic/settings/coupon?code=${referralInfo.referralCode}`
        );
        const referralText = `친구 초대하면 최대 50% 쿠폰 증정, 산타로 친구랑 토익 졸업하기! https://aitutorsanta.onelink.me/zwZO/kp2y05xk?af_dp=${deepLinkValue}&af_force_deeplink=true&deep_link_value=${deepLinkValue}`;
        if (isAppBridgeAvailable()) {
          mobileService.openShareSheet(referralText);
        } else {
          navigator.clipboard
            .writeText(referralText)
            .then(() => {
              openToast({colorVariant: 'brand', message: t('friend_recommendation_card_copy_toast_success')});
            })
            .catch(() => {
              openToast({
                colorVariant: 'danger',
                message: t('friend_recommendation_card_copy_toast_failure'),
              });
            });
        }
      }}
    />
  ) : (
    <FriendRecommendationCard.Skeleton />
  );
};

export default FriendRecommendationCardContainer;
FriendRecommendationCardContainer.displayName = 'FriendRecommendationCardContainer';
