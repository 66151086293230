/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { BulkGetContentResourcesResponseFromJSON, ContentResourceFromJSON, } from '../models';
/**
 *
 */
export class ContentResourceV1Api extends runtime.BaseAPI {
    /**
     * 여러 콘텐츠 리소스 ID에 대해서 `getContentResource` API의 동작을 수행한다.
     */
    async bulkGetContentResourcesRaw(requestParameters, initOverrides) {
        if (requestParameters.ids === null || requestParameters.ids === undefined) {
            throw new runtime.RequiredError('ids', 'Required parameter requestParameters.ids was null or undefined when calling bulkGetContentResources.');
        }
        const queryParameters = {};
        if (requestParameters.ids) {
            queryParameters['ids'] = requestParameters.ids;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/api/content-learning/v1/resources`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => BulkGetContentResourcesResponseFromJSON(jsonValue));
    }
    /**
     * 여러 콘텐츠 리소스 ID에 대해서 `getContentResource` API의 동작을 수행한다.
     */
    async bulkGetContentResources(requestParameters, initOverrides) {
        const response = await this.bulkGetContentResourcesRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 현재 사용자가 선택한 학습 도메인에 속한 콘텐츠 리소스 중 주어진 ID에 해당하는 리소스를 조회한다.
     */
    async getContentResourceRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getContentResource.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/content-learning/v1/resources/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ContentResourceFromJSON(jsonValue));
    }
    /**
     * 현재 사용자가 선택한 학습 도메인에 속한 콘텐츠 리소스 중 주어진 ID에 해당하는 리소스를 조회한다.
     */
    async getContentResource(requestParameters, initOverrides) {
        const response = await this.getContentResourceRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
