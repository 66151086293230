import {useRouter} from 'next/router';
import React, {useCallback, useEffect, useMemo} from 'react';
import {useAtom, useAtomValue} from 'jotai';
import {CreateMyProfileErrorCase, UserOnboardingStatus, UserProfile} from '@santa-web/gen/open-api/service';
import {createOnceCaller} from '@santa-web/service-common';
import useOnboardingEvents from '@app/api/google-tag-manager/onboarding';
import learningDomainAtom, {learningDomainsAtom} from '@app/atoms/core/learning-domain';
import {
  DomainSelectionView,
  useCreateUserProfileMutation,
  useOnboardingStep,
  useOnboardingStepCorrectionEffect,
} from '@app/features/onboarding';
import useUserQuery from '@app/hooks/user/useUser';
import useUserProfileQuery from '@app/hooks/user/useUserProfile';
import {SantaResponseError} from '@app/utils/error';

const DomainSelectionContainer = () => {
  const router = useRouter();
  const {goToNextStep} = useOnboardingStep();
  const {pushLearningDomainSelect} = useOnboardingEvents();
  const learningDomains = useAtomValue(learningDomainsAtom);
  const [learningDomain, setLearningDomain] = useAtom(learningDomainAtom);
  const {data: user, refetch: refetchUser} = useUserQuery();
  const {data: userProfile, refetch: refetchUserProfile} = useUserProfileQuery();
  const {mutateAsync: _createUserProfile} = useCreateUserProfileMutation();
  const [_isLoading, setIsLoading] = React.useState(false);
  const [isReadyToRequest, setIsReadyToRequest] = React.useState(false);

  const isAnonymousUser = user && user.registrationType === 'ANONYMOUS';
  const isFirstOnboarding = isAnonymousUser || userProfile == null;
  const isLoading = _isLoading || userProfile === undefined || user == null;

  const handleDomainSelection = async (domainId: number) => {
    const targetDomain = learningDomains.find(({id}) => id === domainId);
    if (!targetDomain) {
      throw new Error(`unexpected onboarding domain selection: ${domainId}`);
    }
    setIsLoading(true);
    setLearningDomain(Promise.resolve(targetDomain));
    setIsReadyToRequest(true);
  };

  const createUserProfileOnce = useMemo(() => createOnceCaller(_createUserProfile), [_createUserProfile]);
  const refetchUserProfileOnce = useMemo(() => createOnceCaller(refetchUserProfile), [refetchUserProfile]);
  const refetchUserOnce = useMemo(() => createOnceCaller(refetchUser), [refetchUser]);

  const createUserProfile = useCallback(async () => {
    let profile: UserProfile;
    try {
      profile = await createUserProfileOnce();
    } catch (e) {
      if (e instanceof SantaResponseError && e.santaErrorCode === CreateMyProfileErrorCase.PROFILE_ALREADY_EXISTS) {
        const {data: _profile} = await refetchUserProfileOnce();
        if (!_profile) {
          throw new Error('profile should exist');
        }
        profile = _profile;
      } else {
        throw e;
      }
    } finally {
      // user property에 선택한 learningDomain이 반영되는것을 보장하도록 finally에서 호출
      pushLearningDomainSelect({from_onb: isAnonymousUser ? 'registration_onb' : 'change_domain_onb'});
      setIsLoading(false);
      setIsReadyToRequest(false);
    }

    // 도메인 변경에 따라 쿼리 캐시를 모두 clear한 상황, user데이터가 비어있는 상태. 다시 user를 호출해서 가입정보를 불러옴.
    const {data: _user} = await refetchUserOnce();

    if (_user?.registeredAt && profile.onboardingInfo.status === UserOnboardingStatus.DONE) {
      router.push('/');
    } else {
      goToNextStep();
    }
  }, [
    router,
    isAnonymousUser,
    refetchUserOnce,
    goToNextStep,
    createUserProfileOnce,
    refetchUserProfileOnce,
    pushLearningDomainSelect,
  ]);

  useEffect(() => {
    if (!isReadyToRequest) return;
    createUserProfile();
  }, [isReadyToRequest, createUserProfile]);

  useOnboardingStepCorrectionEffect();

  return isAnonymousUser == null ? null : (
    <DomainSelectionView
      isNarrow={!isAnonymousUser}
      isLoading={isLoading}
      shouldBlockSameDomain={!isFirstOnboarding}
      currentDomainId={isFirstOnboarding ? undefined : learningDomain.id}
      domains={learningDomains.map(({id, displayName, description, imageSet}) => ({
        id,
        description,
        name: displayName,
        imageSrc: imageSet.imageUrlX1,
      }))}
      onBackButtonClick={router.back}
      onDomainSelect={handleDomainSelection}
    />
  );
};

export default DomainSelectionContainer;
DomainSelectionContainer.displayName = 'DomainSelectionContainer';
