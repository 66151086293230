import {useRouter} from 'next/router';

import {SnsSignupAndSigninInfo} from '@app/features/auth/types';

const useAuthRequest = () => {
  const router = useRouter();
  const requestString = router.query.request;
  try {
    if (typeof requestString === 'string') return JSON.parse(requestString) as SnsSignupAndSigninInfo;
    return undefined;
  } catch {
    throw new Error('AuthRequest query is not valid');
  }
};

export default useAuthRequest;
