import React from 'react';
import {Form, FormRenderProps} from 'react-final-form';
import {FormApi} from 'final-form';

import Modal from '@app/components/Modal';

interface ModalFormProps<T> {
  initialValues: T;
  onSubmit: (values: T, form: FormApi<T>) => void | Promise<void>;
  isModalOpen: boolean;
  closeModal: () => void;
  onCancel: (form: FormApi<T>) => void;
  SubmitButton: React.ComponentType<FormRenderProps<T>>;
  children: (formRenderProps: FormRenderProps<T>) => React.ReactNode;
}

function ModalForm<T>({
  isModalOpen,
  onSubmit,
  initialValues,
  onCancel,
  closeModal,
  SubmitButton,
  children,
}: ModalFormProps<T>) {
  return (
    <Form<T> onSubmit={onSubmit} initialValues={initialValues}>
      {formRenderProps => {
        const {dirty, form} = formRenderProps;

        function handleRequestClose() {
          if (dirty) {
            onCancel(form);
          } else {
            closeModal();
          }
        }

        return (
          <Modal
            isOpen={isModalOpen}
            onRequestClose={handleRequestClose}
            footer={<SubmitButton {...formRenderProps} />}>
            {children(formRenderProps)}
          </Modal>
        );
      }}
    </Form>
  );
}

export default React.memo(ModalForm) as typeof ModalForm;
