/**
 * Check if a given object implements the CouponOneOfDetailsPermit interface.
 */
export function instanceOfCouponOneOfDetailsPermit(value) {
    let isInstance = true;
    isInstance = isInstance && "items" in value;
    return isInstance;
}
export function CouponOneOfDetailsPermitFromJSON(json) {
    return CouponOneOfDetailsPermitFromJSONTyped(json, false);
}
export function CouponOneOfDetailsPermitFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'items': json['items'],
    };
}
export function CouponOneOfDetailsPermitToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'items': value.items,
    };
}
