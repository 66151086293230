import {DistributionDataType} from '@app/components/card/ExamDistributionCard';

export const DIAGNOSIS_RESULT_DATA: DistributionDataType[] = [
  {
    label: 50,
    value: 0,
  },
  {
    label: 100,
    value: 0,
  },
  {
    label: 150,
    value: 622,
  },
  {
    label: 200,
    value: 515,
  },
  {
    label: 250,
    value: 733,
  },
  {
    label: 300,
    value: 43,
  },
  {
    label: 350,
    value: 960,
  },
  {
    label: 400,
    value: 16062,
  },
  {
    label: 450,
    value: 29075,
  },
  {
    label: 500,
    value: 48850,
  },
  {
    label: 550,
    value: 48355,
  },
  {
    label: 600,
    value: 37730,
  },
  {
    label: 650,
    value: 38240,
  },
  {
    label: 700,
    value: 36717,
  },
  {
    label: 750,
    value: 31238,
  },
  {
    label: 800,
    value: 20683,
  },
  {
    label: 850,
    value: 17964,
  },
  {
    label: 900,
    value: 9963,
  },
  {
    label: 950,
    value: 8040,
  },
  {
    label: 990,
    value: 154,
  },
];

export const DISTRIBUTION_CHART_DATA = DIAGNOSIS_RESULT_DATA.filter(({label}) => label >= 350);

export const TOTAL_USERS = DIAGNOSIS_RESULT_DATA.reduce((acc, {value}) => acc + value, 0);
