/* tslint:disable */
/* eslint-disable */
/**
 * - **SINGLE_PURCHASE**: 단건
 * - **SUBSCRIPTION**: 구독
 * @export
 */
export const OfferAdditionalPropertyCase = {
    SINGLE_PURCHASE: 'SINGLE_PURCHASE',
    SUBSCRIPTION: 'SUBSCRIPTION'
};
export function OfferAdditionalPropertyCaseFromJSON(json) {
    return OfferAdditionalPropertyCaseFromJSONTyped(json, false);
}
export function OfferAdditionalPropertyCaseFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function OfferAdditionalPropertyCaseToJSON(value) {
    return value;
}
