/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { CreateHighlightsRequestToJSON, CreateHighlightsResponseFromJSON, HighlightFromJSON, HighlightTemplateToJSON, ListHighlightsResponseFromJSON, } from '../models';
/**
 *
 */
export class HighlightV1Api extends runtime.BaseAPI {
    /**
     * 대상 `ContentInteractionState`에 대한 하이라이트를 생성합니다.
     * 하이라이트 생성
     */
    async createHighlightsRaw(requestParameters, initOverrides) {
        if (requestParameters.contentInteractionStateId === null || requestParameters.contentInteractionStateId === undefined) {
            throw new runtime.RequiredError('contentInteractionStateId', 'Required parameter requestParameters.contentInteractionStateId was null or undefined when calling createHighlights.');
        }
        if (requestParameters.createHighlightsRequest === null || requestParameters.createHighlightsRequest === undefined) {
            throw new runtime.RequiredError('createHighlightsRequest', 'Required parameter requestParameters.createHighlightsRequest was null or undefined when calling createHighlights.');
        }
        const queryParameters = {};
        if (requestParameters.contentInteractionStateId !== undefined) {
            queryParameters['contentInteractionStateId'] = requestParameters.contentInteractionStateId;
        }
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/content-learning-x/v1/highlights`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateHighlightsRequestToJSON(requestParameters.createHighlightsRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CreateHighlightsResponseFromJSON(jsonValue));
    }
    /**
     * 대상 `ContentInteractionState`에 대한 하이라이트를 생성합니다.
     * 하이라이트 생성
     */
    async createHighlights(requestParameters, initOverrides) {
        const response = await this.createHighlightsRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 대상 하이라이트를 삭제합니다.
     * 하이라이트 삭제
     */
    async deleteHighlightRaw(requestParameters, initOverrides) {
        if (requestParameters.highlightId === null || requestParameters.highlightId === undefined) {
            throw new runtime.RequiredError('highlightId', 'Required parameter requestParameters.highlightId was null or undefined when calling deleteHighlight.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/content-learning-x/v1/highlights/{highlightId}`.replace(`{${"highlightId"}}`, encodeURIComponent(String(requestParameters.highlightId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * 대상 하이라이트를 삭제합니다.
     * 하이라이트 삭제
     */
    async deleteHighlight(requestParameters, initOverrides) {
        await this.deleteHighlightRaw(requestParameters, initOverrides);
    }
    /**
     * 대상 `ContentInteractionState`에 대한 하이라이트를 모두 조회합니다.
     * 하이라이트 목록 조회
     */
    async listHighlightsRaw(requestParameters, initOverrides) {
        if (requestParameters.contentInteractionStateId === null || requestParameters.contentInteractionStateId === undefined) {
            throw new runtime.RequiredError('contentInteractionStateId', 'Required parameter requestParameters.contentInteractionStateId was null or undefined when calling listHighlights.');
        }
        const queryParameters = {};
        if (requestParameters.contentInteractionStateId !== undefined) {
            queryParameters['contentInteractionStateId'] = requestParameters.contentInteractionStateId;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/api/content-learning-x/v1/highlights`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListHighlightsResponseFromJSON(jsonValue));
    }
    /**
     * 대상 `ContentInteractionState`에 대한 하이라이트를 모두 조회합니다.
     * 하이라이트 목록 조회
     */
    async listHighlights(requestParameters, initOverrides) {
        const response = await this.listHighlightsRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 대상 하이라이트를 수정합니다.
     * 하이라이트 수정
     */
    async updateHighlightRaw(requestParameters, initOverrides) {
        if (requestParameters.highlightId === null || requestParameters.highlightId === undefined) {
            throw new runtime.RequiredError('highlightId', 'Required parameter requestParameters.highlightId was null or undefined when calling updateHighlight.');
        }
        if (requestParameters.highlightTemplate === null || requestParameters.highlightTemplate === undefined) {
            throw new runtime.RequiredError('highlightTemplate', 'Required parameter requestParameters.highlightTemplate was null or undefined when calling updateHighlight.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/content-learning-x/v1/highlights/{highlightId}`.replace(`{${"highlightId"}}`, encodeURIComponent(String(requestParameters.highlightId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: HighlightTemplateToJSON(requestParameters.highlightTemplate),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => HighlightFromJSON(jsonValue));
    }
    /**
     * 대상 하이라이트를 수정합니다.
     * 하이라이트 수정
     */
    async updateHighlight(requestParameters, initOverrides) {
        const response = await this.updateHighlightRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
