import {useQuery} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';
import {Order} from '@santa-web/gen/open-api/service';
import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {getOfferGroupOrdersQueryKey} from '@app/queryKeys';

export const useOfferGroupOrdersQuery = () => {
  const {OrderService} = useAtomValue(santaOpenapiServicesAtom);

  return useQuery<Order[]>({
    queryKey: getOfferGroupOrdersQueryKey(),
    queryFn: async () => {
      return OrderService.listOrder({}).then(({orders}) => orders);
    },
  });
};
