import React from 'react';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';

import {UserProfile, UpdateMyOnboardingInfoRequest} from '@santa-web/gen/open-api/service';
import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {getUserProfileQueryKey} from '@app/queryKeys';

export default function useUpdateUserProfileOnboardingInfo() {
  const {ProfileService} = useAtomValue(santaOpenapiServicesAtom);
  const userProfileQueryKey = getUserProfileQueryKey();
  const queryClient = useQueryClient();

  const onSuccess = React.useCallback(
    function (userProfile: UserProfile) {
      queryClient.setQueryData<UserProfile>(userProfileQueryKey, userProfile);
    },
    [queryClient, userProfileQueryKey]
  );

  const mutationFn = React.useCallback(
    async (updateMyOnboardingInfoRequest: UpdateMyOnboardingInfoRequest) => {
      const {profile} = await ProfileService.updateMyOnboardingInfo({
        updateMyOnboardingInfoRequest,
      });
      return profile;
    },
    [ProfileService]
  );
  return useMutation(mutationFn, {onSuccess});
}
