import {useMutation, useQueryClient} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';

import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {getHighlightsPreviewsQueryKey, getListHighlightInfosQueryKey} from '@app/queryKeys';
import {HIGHLIGHT_COLOR_MAP} from '@app/test-preparation/constants/highlight';
import {HighlightColor, HighlightInfo, SelectedHighlightInfo} from '@app/test-preparation/types/highlight';

interface MutationInput {
  cisId: number;
  selectedHighlightInfo: SelectedHighlightInfo;
  color: HighlightColor;
}

export default function useCreateHighlightInfo() {
  const queryClient = useQueryClient();
  const {HighlightService} = useAtomValue(santaOpenapiServicesAtom);

  return useMutation(
    ({cisId, selectedHighlightInfo, color}: MutationInput) => {
      const {snippetViewId, highlight, text} = selectedHighlightInfo;

      return HighlightService.createHighlights({
        contentInteractionStateId: cisId,
        createHighlightsRequest: {
          highlightTemplates: [
            {
              color: HIGHLIGHT_COLOR_MAP[color],
              displayInfoJson: JSON.stringify({snippetViewId, highlight}),
              previewStr: text,
            },
          ],
        },
      });
    },
    {
      onSuccess: async ({highlights}, {cisId, selectedHighlightInfo}) => {
        const [cisHighlightInfo] = highlights;
        const {id} = cisHighlightInfo;

        const listHighlightQueryKey = getListHighlightInfosQueryKey(cisId);

        queryClient.invalidateQueries(getHighlightsPreviewsQueryKey(), {type: 'all'});

        await queryClient.cancelQueries(listHighlightQueryKey);
        queryClient.setQueryData<HighlightInfo[]>(listHighlightQueryKey, (old = []) => [
          {
            ...selectedHighlightInfo,
            highlight: {
              ...selectedHighlightInfo.highlight,
              id,
            },
          },
          ...old,
        ]);
      },
    }
  );
}
