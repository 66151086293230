import React from 'react';
import {css} from '@emotion/react';
import {COLOR_FIX_SANTA_WHITE_1} from '@riiid/design-system';

import {Typography} from '@santa-web/service-ui';

export interface PageBannerProps extends React.ComponentPropsWithoutRef<'div'> {
  imageUrl: string;
  title: string;
  description?: string;
}

const PageBanner = React.forwardRef<HTMLDivElement, PageBannerProps>(
  ({imageUrl, title, description, ...props}, ref) => {
    return (
      <div
        css={css`
          height: 200px;

          display: flex;
          align-items: center;
          justify-content: center;

          background: url(${imageUrl});
          background-repeat: no-repeat;
          background-size: cover;
          background-position: 50% 0;
        `}
        ref={ref}
        {...props}>
        <div
          css={css`
            width: 100%;
            max-width: 1080px;
            padding: 0 40px;
          `}>
          <Typography
            css={css`
              white-space: pre-line;
            `}
            variant="h5"
            fontWeight="regular"
            color={COLOR_FIX_SANTA_WHITE_1}>
            {title}
          </Typography>
          {description && (
            <Typography
              css={css`
                margin-top: 8px;
              `}
              variant="body3"
              fontWeight="regular"
              color={COLOR_FIX_SANTA_WHITE_1}>
              {description}
            </Typography>
          )}
        </div>
      </div>
    );
  }
);

export default React.memo(PageBanner);
