import React from 'react';
import {css, SerializedStyles} from '@emotion/react';

export interface SingleColumnProps extends React.ComponentPropsWithoutRef<'div'> {
  gap: number | string;
  customCss?: SerializedStyles;
}

const SingleColumn = React.memo(
  React.forwardRef<HTMLDivElement, SingleColumnProps>(({gap: gap_, customCss, ...props}, ref) => {
    const gap = typeof gap_ === 'number' ? `${gap_}px` : gap_;
    return (
      <div
        css={css`
          display: grid;
          grid-row-gap: ${gap};
          row-gap: ${gap};

          ${customCss}
        `}
        {...props}
        ref={ref}
      />
    );
  })
);

export default SingleColumn;
