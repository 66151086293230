import {atom} from 'jotai';
import {ContentInteractionState} from '@santa-web/gen/open-api/content-learning-service';
import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';

const updateCisAtom = atom(async get => {
  const {ContentInteractionStateService} = await get(santaOpenapiServicesAtom);

  return async (cis: ContentInteractionState) =>
    ContentInteractionStateService.updateContentInteractionState({
      contentInteractionStateId: cis.id,
      updateContentInteractionStateRequest: {
        contentInteractionState: cis,
        timestampClient: Date.now(),
      },
    });
});

export {updateCisAtom};
