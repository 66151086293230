import {css} from '@emotion/react';
import {COLOR_SANTA_C} from '@riiid/design-system';

import {LoadingGreyAnimation, TopNavBar} from '@santa-web/service-ui';
import {use100vh} from '@app/hooks/use100vh';

type PageLoadingProps = {
  onExitClick?: () => void;
};

const PageLoading = ({onExitClick}: PageLoadingProps) => {
  const viewportHeight = use100vh();
  return (
    <div
      css={css`
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        width: 100%;
        height: ${viewportHeight};
        background-color: ${COLOR_SANTA_C};
        overflow: hidden;
      `}>
      <LoadingGreyAnimation width={40} height={40} />
      <div
        css={css`
          position: absolute;
          width: 100%;
          height: 100%;
          background-color: ${COLOR_SANTA_C};
        `}>
        <TopNavBar paginationIcon="exit" onPaginationIconClick={onExitClick} />
      </div>
    </div>
  );
};

export default PageLoading;
PageLoading.displayName = 'PageLoading';
