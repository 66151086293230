import React from 'react';
import {css} from '@emotion/react';
import {COLOR_SANTA_D, COLOR_SANTA_E, COLOR_SANTA_H, COLOR_SANTA_I, COLOR_SANTA_L} from '@riiid/design-system';

import {Typography, ButtonBase, ButtonBaseProps} from '@santa-web/service-ui';

export interface BottomSheetListItemProps extends Omit<ButtonBaseProps, 'title'> {
  title: React.ReactNode;
  caption?: React.ReactNode;
  isSelected?: boolean;
  isDisabled?: boolean;
  endContent?: React.ReactNode;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
}

const BottomSheetListItem = React.forwardRef<HTMLButtonElement, BottomSheetListItemProps>(
  ({title, caption, isSelected, endContent, isDisabled, ...props}, ref) => {
    const captionTypography = (() => {
      if (!caption) return null;
      return (
        <Typography
          variant="caption1"
          fontWeight="regular"
          color={isDisabled ? COLOR_SANTA_E : isSelected ? COLOR_SANTA_I : COLOR_SANTA_H}
          component="p"
          css={css`
            margin-bottom: 4px;
          `}>
          {caption}
        </Typography>
      );
    })();

    const titleTypography = (() => {
      return (
        <Typography
          variant="body3"
          fontWeight="bold"
          color={isDisabled ? COLOR_SANTA_E : isSelected ? COLOR_SANTA_L : COLOR_SANTA_H}>
          {title}
        </Typography>
      );
    })();

    return (
      <ButtonBase
        ref={ref}
        css={css`
          padding-bottom: 12px;
          background: transparent;
          display: flex;
          align-items: center;
          border-bottom: 1px solid ${COLOR_SANTA_D};
          text-align: left;
        `}
        isDisabled={isDisabled}
        isFullWidth
        {...props}>
        <div
          css={css`
            flex-grow: 1;
            margin-right: 20px;
          `}>
          {captionTypography}
          {titleTypography}
        </div>
        {endContent}
      </ButtonBase>
    );
  }
);

export default React.memo(BottomSheetListItem);
