import {useQuery} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';
import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {getStripeCustomerPortalUrlQueryKey} from '@app/queryKeys';

export const useStripeCustomerPortalUrlQuery = (id: number) => {
  const {OrderService} = useAtomValue(santaOpenapiServicesAtom);
  return useQuery<string | null>({
    queryKey: getStripeCustomerPortalUrlQueryKey(id),
    queryFn: async () => {
      try {
        const {path} = await OrderService.getStripeCustomerPortalUrl({id});
        return path;
      } catch (e) {
        return null;
      }
    },
  });
};
