import React from 'react';
import {css} from '@emotion/react';
import {COLOR_SANTA_D} from '@riiid/design-system';
import {Label} from '@santa-web/service-ui';
import {OfferGroupOrderLabel, OfferGroupOrderLabelProps} from '@app/features/offer/components/order';

type OfferGroupOrderStatus = 'pending' | 'normal' | 'canceled' | 'expired' | 'refunded' | 'failed';

type OfferGroupOrderCardLabelListProps = {
  domains: string[];
} & (
  | {
      isSubscription: true;
      subscriptionStatus: Exclude<OfferGroupOrderStatus, 'pending'>;
    }
  | {
      isSubscription?: false;
      status: Exclude<OfferGroupOrderStatus, 'canceled'>;
    }
);

const OfferGroupOrderCardLabelList = ({domains, ...props}: OfferGroupOrderCardLabelListProps) => {
  const {labelVariant} = React.useMemo((): {
    labelVariant: OfferGroupOrderLabelProps['variant'];
  } => {
    if (props.isSubscription) {
      switch (props.subscriptionStatus) {
        case 'normal':
          return {labelVariant: 'subscription-in-use'};
        case 'canceled':
          return {labelVariant: 'subscription-canceled'};
        case 'expired':
          return {labelVariant: 'subscription-expired'};
        case 'failed':
          return {labelVariant: 'subscription-failed'};
        case 'refunded':
          return {labelVariant: 'refunded'};
      }
    } else {
      switch (props.status) {
        case 'pending':
          return {labelVariant: 'pending'};
        case 'normal':
          return {labelVariant: 'purchased'};
        case 'expired':
          return {labelVariant: 'expired'};
        case 'refunded':
          return {labelVariant: 'refunded'};
        case 'failed':
          return {labelVariant: 'failed'};
      }
    }
  }, [props]);

  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        gap: 8px;
      `}>
      <div
        css={css`
          display: flex;
          gap: 4px;
        `}>
        {domains.map(domain => (
          <Label key={domain} colorVariant="gray" variant="small">
            {domain}
          </Label>
        ))}
      </div>
      <div
        css={css`
          height: 16px;
          width: 1px;
          background-color: ${COLOR_SANTA_D};
        `}
      />
      <div
        css={css`
          display: flex;
          gap: 4px;
        `}>
        {props.isSubscription && <OfferGroupOrderLabel variant="subscription" />}
        <OfferGroupOrderLabel variant={labelVariant} />
      </div>
    </div>
  );
};

export default OfferGroupOrderCardLabelList;
export type {OfferGroupOrderCardLabelListProps};
OfferGroupOrderCardLabelList.displayName = 'OfferGroupOrderCardLabelList';
