import { DurationFromJSON, DurationToJSON, } from './Duration';
import { LearningStatisticsAchievementFromJSON, LearningStatisticsAchievementToJSON, } from './LearningStatisticsAchievement';
import { LearningStatisticsInfoFromJSON, LearningStatisticsInfoToJSON, } from './LearningStatisticsInfo';
/**
 * Check if a given object implements the LearningStatisticsOverview interface.
 */
export function instanceOfLearningStatisticsOverview(value) {
    let isInstance = true;
    isInstance = isInstance && "achievement" in value;
    isInstance = isInstance && "learningStatisticsInfo" in value;
    isInstance = isInstance && "totalElapsedTime" in value;
    return isInstance;
}
export function LearningStatisticsOverviewFromJSON(json) {
    return LearningStatisticsOverviewFromJSONTyped(json, false);
}
export function LearningStatisticsOverviewFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'achievement': LearningStatisticsAchievementFromJSON(json['achievement']),
        'learningStatisticsInfo': LearningStatisticsInfoFromJSON(json['learningStatisticsInfo']),
        'totalElapsedTime': DurationFromJSON(json['totalElapsedTime']),
    };
}
export function LearningStatisticsOverviewToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'achievement': LearningStatisticsAchievementToJSON(value.achievement),
        'learningStatisticsInfo': LearningStatisticsInfoToJSON(value.learningStatisticsInfo),
        'totalElapsedTime': DurationToJSON(value.totalElapsedTime),
    };
}
