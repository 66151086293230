import React, {useEffect, useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {COLOR_SANTA_BC, COLOR_SANTA_I, COLOR_SANTA_L, COLOR_SANTA_O_ALPHA} from '@riiid/design-system';
import {PolarGrid, PolarRadiusAxis, Radar, RadarChart} from 'recharts';

import {Typography} from '@santa-web/service-ui';
import BaseCard from '@app/components/card/BaseCard';
import Legend from '@app/components/Legend';
import LegendList from '@app/components/LegendList';

import RadarChartBackground from './RadarChartBackground';
import RadarChartRadiusTick from './RadarChartRadiusTick';

export interface AnalyticsRadarCardData {
  subject: string;
  nowPercent: number;
  goalPercent: number;
}
export type AnalyticsRadarCardDataTuple = AnalyticsRadarCardData[];

export type AnalyticsRadarCardProps = Omit<React.ComponentPropsWithoutRef<'div'>, 'title' | 'content'> & {
  title?: string | null;
  content?: string | null;
  targetScore?: number | null;
  data: AnalyticsRadarCardDataTuple;
  isScoreHidden?: boolean;
};

const ANGLE = {
  3: {startAngle: -30, endAngle: 330},
  4: {startAngle: -45, endAngle: 315},
  5: {startAngle: 90, endAngle: -270},
};

// 현재 3~5각만 포함하고 있습니다. 추가 시 수정 필요합니다.
const AnalyticsRadarCard = React.forwardRef<HTMLDivElement, AnalyticsRadarCardProps>(
  ({title, content, targetScore, data: _data, isScoreHidden = false, ...props}, ref) => {
    const {t} = useTranslation();
    const cardContainerRef = useRef<HTMLDivElement | null>(null);
    const radarChartRef = useRef<HTMLDivElement | null>(null);

    const data: AnalyticsRadarCardDataTuple = isScoreHidden
      ? _data.map(({goalPercent, subject}) => ({goalPercent, subject, nowPercent: 50}))
      : _data;
    const {startAngle, endAngle} =
      data.length === 3 || data.length === 4 || data.length === 5 ? ANGLE[data.length] : {startAngle: 0, endAngle: 0};

    useEffect(() => {
      const onResize = () => {
        if (!cardContainerRef.current || !radarChartRef.current) return;

        const {width: cardWidth} = cardContainerRef.current.getBoundingClientRect();
        const scaleRatio = cardWidth / 360;
        const safeScaleRatio = scaleRatio > 1 ? 1 : scaleRatio;
        radarChartRef.current.style.scale = `${safeScaleRatio}`;
      };

      onResize(); // initial container size check and make sure chart has right scale

      window.addEventListener('resize', onResize);
      return () => window.removeEventListener('resize', onResize);
    }, []);

    return (
      <BaseCard
        css={css`
          padding: 20px;
        `}
        {...props}
        ref={element => {
          cardContainerRef.current = element;

          if (!ref) return;

          if (typeof ref === 'function') {
            ref(element);
          } else {
            ref.current = element;
          }
        }}>
        <Typography
          css={css`
            margin-bottom: 12px;
          `}
          fontWeight="bold"
          variant="body2"
          color={COLOR_SANTA_L}>
          {title}
        </Typography>
        <Typography
          css={css`
            margin-bottom: 24px;
          `}
          fontWeight="regular"
          variant="body3"
          color={COLOR_SANTA_I}>
          {content}
        </Typography>
        <div
          ref={radarChartRef}
          css={css`
            display: flex;
            flex-direction: column;
            align-items: center;
          `}>
          <div
            css={css`
              position: relative;
              display: flex;
              justify-content: center;
            `}>
            <RadarChart
              css={css`
                .recharts-polar-grid-concentric-circle {
                  stroke: ${COLOR_SANTA_O_ALPHA(0.1)};
                }
              `}
              data={data}
              width={194}
              height={279}
              cy={149}
              outerRadius={97}
              startAngle={startAngle}
              endAngle={endAngle}>
              {!isScoreHidden && (
                <defs>
                  <linearGradient id="nowPercent" x1="0" y1="0" x2="0" y2="1">
                    <stop offset="0%" stopColor={COLOR_SANTA_BC} stopOpacity={0.5} />
                    <stop offset="100%" stopColor={COLOR_SANTA_BC} stopOpacity={0.1} />
                  </linearGradient>
                </defs>
              )}
              <PolarGrid gridType="circle" radialLines={false} />
              <PolarRadiusAxis
                angle={-90}
                domain={[0, 100]}
                type="number"
                axisLine={false}
                tickCount={6}
                tick={RadarChartRadiusTick}
              />
              <Radar
                name={t('analytics_pentagon_card_radar_goal_label')}
                dataKey="goalPercent"
                stroke={COLOR_SANTA_I}
                strokeWidth={2}
                fillOpacity={0}
              />
              <Radar
                name={t('analytics_pentagon_card_radar_now_label')}
                dataKey="nowPercent"
                stroke={COLOR_SANTA_BC}
                strokeWidth={2}
                fill={isScoreHidden ? 'transparent' : 'url(#nowPercent)'}
                strokeDasharray={isScoreHidden ? 5 : 0}
              />
            </RadarChart>
            <RadarChartBackground
              data={data}
              isScoreHidden={isScoreHidden}
              css={css`
                position: absolute;
              `}
            />
          </div>
          <LegendList
            css={css`
              margin-top: 24px;
            `}>
            <Legend
              color={COLOR_SANTA_BC}
              subject={t('diagnosis_report_view_pentagon_card_my_skill')}
              variant="square"
            />
            <Legend
              color={COLOR_SANTA_I}
              subject={t('diagnosis_report_view_pentagon_card_score_user', {
                targetScore: targetScore,
              })}
              variant="square"
            />
          </LegendList>
        </div>
      </BaseCard>
    );
  }
);

export default React.memo(AnalyticsRadarCard);
