import React, {useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {COLOR_SANTA_E} from '@riiid/design-system';
import PerksIcon from '@riiid/design-system/icons/Perks';

import {Typography} from '@santa-web/service-ui';
import {SkeletonGrayBox} from '@app/components';
import {EventListCard, EventListCardOwnProps} from '@app/features/marketing/components';
import useIntersectionObserver from '@app/hooks/useIntersectionObserver';
import {withSkeleton} from '@app/utils/component';

export interface EventListViewProps {
  isEnded?: boolean;
  hasNextItem?: boolean;
  eventItems: ({id: number} & Omit<EventListCardOwnProps, 'onClick'>)[];
  onLastItemIntersect?: () => void;
  onItemClick: (id: number) => void;
}

const EventListView = ({
  isEnded = false,
  hasNextItem = false,
  eventItems,
  onLastItemIntersect,
  onItemClick,
}: EventListViewProps): JSX.Element => {
  const {t} = useTranslation();
  const intersectionRef = useRef<HTMLDivElement>(null);

  useIntersectionObserver({
    target: intersectionRef,
    onIntersect: () => onLastItemIntersect?.(),
    enabled: hasNextItem,
  });

  return (
    <div
      css={css`
        display: grid;
        gap: 12px;
        width: 100%;
      `}>
      {eventItems.length === 0 ? (
        <div
          css={css`
            display: inline-flex;
            flex-direction: column;
            align-items: center;
          `}>
          <PerksIcon
            css={css`
              margin-bottom: 16px;
            `}
            type="line"
            color={COLOR_SANTA_E}
            width={40}
            height={40}
          />
          <Typography component="p" variant="caption1" fontWeight="bold" color={COLOR_SANTA_E}>
            {isEnded ? t('event_info_end_not_exist') : t('event_info_active_not_exist')}
          </Typography>
        </div>
      ) : (
        eventItems.map(({id, ...props}) => {
          return <EventListCard key={id} {...props} onClick={() => onItemClick(id)} />;
        })
      )}
    </div>
  );
};

const Skeleton = () => (
  <div
    css={css`
      display: grid;
      grid-auto-flow: row;
      grid-gap: 12px;
      gap: 12px;
    `}>
    {Array(3)
      .fill(0)
      .map((_, i) => (
        <SkeletonGrayBox key={i} width="100%" height={160} />
      ))}
  </div>
);

export default withSkeleton(React.memo(EventListView), Skeleton);
