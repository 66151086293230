import {Decimal} from 'decimal.js';

import {ceil} from '@app/facade/price';

export abstract class PriceFormatter {
  getFormattedPrice(price: Decimal): string {
    return price.toNumber().toLocaleString(this.getLocale()) + this.getCurrencyText();
  }
  abstract getCurrencyText(): string;
  abstract getLocale(): string;
  abstract getCutUnit(): number;
  cut(price: Decimal): Decimal {
    return Decimal.max(price.sub(price.mod(new Decimal(this.getCutUnit()))), new Decimal(0));
  }

  abstract getMonthlyCeilUnit(): number;
  ceilMonthlyPrice(price: Decimal): Decimal {
    return ceil(price, this.getMonthlyCeilUnit());
  }
}
