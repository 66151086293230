import React from 'react';
import {useAtomValue} from 'jotai';
import useOnboardingEvents from '@app/api/google-tag-manager/onboarding';
import learningDomainAtom from '@app/atoms/core/learning-domain';
import {
  useOnboardingStep,
  useOnboardingStepCorrectionEffect,
  useUpdateUserProfileOnboardingInfoMutation,
} from '@app/features/onboarding/hooks';
import {ExamExperienceView} from '@app/features/onboarding/views';
import useUserQuery from '@app/hooks/user/useUser';

const ExamExperienceContainer = () => {
  const {goToNextStep, goToPreviousStep} = useOnboardingStep();
  const {pushSelectTestEvent} = useOnboardingEvents();
  const learningDomain = useAtomValue(learningDomainAtom);
  const {data: user} = useUserQuery();
  const {mutateAsync: updateMyOnboardingInfo, isLoading} = useUpdateUserProfileOnboardingInfoMutation();
  const isAnonymousUser = user && user.registrationType === 'ANONYMOUS';

  const handleDomainExperience = async (hasExperience: boolean) => {
    pushSelectTestEvent({
      answer: hasExperience ? 'y' : 'n',
      from_onb: isAnonymousUser ? 'registration_onb' : 'change_domain_onb',
    });
    await updateMyOnboardingInfo({hasExperience});
    goToNextStep();
  };

  useOnboardingStepCorrectionEffect();

  return user ? (
    <ExamExperienceView
      isNarrow={!isAnonymousUser}
      isLoading={isLoading}
      domainName={learningDomain.displayName}
      onAnswer={handleDomainExperience}
      onBackButtonClick={goToPreviousStep}
    />
  ) : null;
};

export default ExamExperienceContainer;
ExamExperienceContainer.displayName = 'ExamExperienceContainer';
