/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { EstimatedTagProficienciesWithTargetFromJSON, EstimatedTagProficienciesWithTargetToJSON, } from './EstimatedTagProficienciesWithTarget';
import { TagGroupProficiencyReportTypeFromJSON, TagGroupProficiencyReportTypeToJSON, } from './TagGroupProficiencyReportType';
/**
 * Check if a given object implements the TagGroupProficiencyReport interface.
 */
export function instanceOfTagGroupProficiencyReport(value) {
    let isInstance = true;
    isInstance = isInstance && "estimatedTagProficienciesWithTarget" in value;
    isInstance = isInstance && "titleName" in value;
    isInstance = isInstance && "type" in value;
    return isInstance;
}
export function TagGroupProficiencyReportFromJSON(json) {
    return TagGroupProficiencyReportFromJSONTyped(json, false);
}
export function TagGroupProficiencyReportFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'estimatedTagProficienciesWithTarget': EstimatedTagProficienciesWithTargetFromJSON(json['estimatedTagProficienciesWithTarget']),
        'tagGroupName': !exists(json, 'tagGroupName') ? undefined : json['tagGroupName'],
        'titleName': json['titleName'],
        'type': TagGroupProficiencyReportTypeFromJSON(json['type']),
    };
}
export function TagGroupProficiencyReportToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'estimatedTagProficienciesWithTarget': EstimatedTagProficienciesWithTargetToJSON(value.estimatedTagProficienciesWithTarget),
        'tagGroupName': value.tagGroupName,
        'titleName': value.titleName,
        'type': TagGroupProficiencyReportTypeToJSON(value.type),
    };
}
