import React from 'react';
import {OnboardingStep} from '@app/features/onboarding/types';
import {useTypedRouter, useTypedSearchParams} from '@app/hooks/useTypedRouter';
import {useQueryState} from '@app/misc/query-state';

const useOnboardingSteps = () => {
  const router = useTypedRouter();
  const queryState = useQueryState();
  const {step = OnboardingStep.SELECT_DOMAIN} = useTypedSearchParams('/onboarding');

  const goToStep = React.useCallback(
    (step: OnboardingStep) => {
      router.push({pathname: '/onboarding', query: {...queryState.toQuery(), step}});
    },
    [queryState, router]
  );

  const goToNextStep = React.useCallback(() => {
    goToStep(step + 1);
  }, [step, goToStep]);

  const goToPreviousStep = React.useCallback(() => {
    router.back();
  }, [router]);

  return React.useMemo(
    () => ({
      currentStep: step as OnboardingStep,
      goToStep,
      goToNextStep,
      goToPreviousStep,
    }),
    [step, goToNextStep, goToPreviousStep, goToStep]
  );
};

export default useOnboardingSteps;
