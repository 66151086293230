/* tslint:disable */
/* eslint-disable */
/**
 *
 * @export
 */
export const DayOfWeek = {
    MONDAY: 'MONDAY',
    TUESDAY: 'TUESDAY',
    WEDNESDAY: 'WEDNESDAY',
    THURSDAY: 'THURSDAY',
    FRIDAY: 'FRIDAY',
    SATURDAY: 'SATURDAY',
    SUNDAY: 'SUNDAY'
};
export function DayOfWeekFromJSON(json) {
    return DayOfWeekFromJSONTyped(json, false);
}
export function DayOfWeekFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function DayOfWeekToJSON(value) {
    return value;
}
