/**
 * Check if a given object implements the PointRewardL2ePaybackUrl interface.
 */
export function instanceOfPointRewardL2ePaybackUrl(value) {
    let isInstance = true;
    isInstance = isInstance && "offerName" in value;
    isInstance = isInstance && "point" in value;
    isInstance = isInstance && "url" in value;
    isInstance = isInstance && "validUntil" in value;
    return isInstance;
}
export function PointRewardL2ePaybackUrlFromJSON(json) {
    return PointRewardL2ePaybackUrlFromJSONTyped(json, false);
}
export function PointRewardL2ePaybackUrlFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'offerName': json['offerName'],
        'point': json['point'],
        'url': json['url'],
        'validUntil': (new Date(json['validUntil'])),
    };
}
export function PointRewardL2ePaybackUrlToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'offerName': value.offerName,
        'point': value.point,
        'url': value.url,
        'validUntil': (value.validUntil.toISOString()),
    };
}
