import React from 'react';
import {css} from '@emotion/react';

export type ReportViewWrapperProps = React.ComponentPropsWithoutRef<'div'>;

const ReportViewWrapper = React.forwardRef<HTMLDivElement, ReportViewWrapperProps>(
  (props, ref): JSX.Element => (
    <div
      ref={ref}
      css={css`
        display: flex;
        flex-direction: column;
        height: 100%;
      `}
      {...props}
    />
  )
);

export default React.memo(ReportViewWrapper);
