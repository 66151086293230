import { PartialExamScoreFromJSON, PartialExamScoreToJSON, } from './PartialExamScore';
/**
 * Check if a given object implements the UpdateExamScoreRequest interface.
 */
export function instanceOfUpdateExamScoreRequest(value) {
    let isInstance = true;
    isInstance = isInstance && "partialScores" in value;
    isInstance = isInstance && "totalScore" in value;
    return isInstance;
}
export function UpdateExamScoreRequestFromJSON(json) {
    return UpdateExamScoreRequestFromJSONTyped(json, false);
}
export function UpdateExamScoreRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'partialScores': (json['partialScores'].map(PartialExamScoreFromJSON)),
        'totalScore': json['totalScore'],
    };
}
export function UpdateExamScoreRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'partialScores': (value.partialScores.map(PartialExamScoreToJSON)),
        'totalScore': value.totalScore,
    };
}
