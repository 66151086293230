import React from 'react';
import {css} from '@emotion/react';
import {COLOR_SANTA_C} from '@riiid/design-system';

export interface SpacingProps extends React.ComponentPropsWithoutRef<'div'> {
  height: number;
  color?: string;
}
const Spacing = ({height, color = COLOR_SANTA_C, ...props}: SpacingProps): JSX.Element => {
  return (
    <div
      css={css`
        height: ${height}px;
        background-color: ${color};
      `}
      {...props}
    />
  );
};

export default React.memo(Spacing);
