import { MarketingCampaignFromJSON, MarketingCampaignToJSON, } from './MarketingCampaign';
/**
 * Check if a given object implements the ListAllActiveMarketingCampaignsResponse interface.
 */
export function instanceOfListAllActiveMarketingCampaignsResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "marketingCampaigns" in value;
    return isInstance;
}
export function ListAllActiveMarketingCampaignsResponseFromJSON(json) {
    return ListAllActiveMarketingCampaignsResponseFromJSONTyped(json, false);
}
export function ListAllActiveMarketingCampaignsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'marketingCampaigns': (json['marketingCampaigns'].map(MarketingCampaignFromJSON)),
    };
}
export function ListAllActiveMarketingCampaignsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'marketingCampaigns': (value.marketingCampaigns.map(MarketingCampaignToJSON)),
    };
}
