import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';

import {LearningCycleCellType} from '@santa-web/gen/open-api/content-learning-service';
import {BottomSheetProps, Button} from '@santa-web/service-ui';
import BottomSheetWithDim, {BottomSheetWithDimProps} from '@app/components/BottomSheetWithDim';
import {ALERT_DIALOG_Z_INDEX} from '@app/constants/zIndex';
import {useDialogContext} from '@app/contexts/DialogContext';

export interface CourseTutorialBottomSheetWithDimProps extends BottomSheetWithDimProps {
  cellType: LearningCycleCellType;
  onButtonClick?(): void;
}

const useTutorialBottomSheetPropsFromType = () => {
  const {t} = useTranslation();

  return React.useCallback(
    (type: LearningCycleCellType): Pick<BottomSheetProps, 'title' | 'description'> => {
      switch (type) {
        case LearningCycleCellType.QUESTION:
          return {
            title: t('bottom_sheet_course_tutorial_question_title'),
            description: t('bottom_sheet_course_tutorial_question_description'),
          };
        case LearningCycleCellType.LESSON:
          return {
            title: t('bottom_sheet_course_tutorial_lesson_title'),
            description: t('bottom_sheet_course_tutorial_lesson_description'),
          };
        case LearningCycleCellType.VOCABULARY:
          return {
            title: t('bottom_sheet_course_tutorial_voca_title'),
            description: t('bottom_sheet_course_tutorial_voca_description'),
          };
        case LearningCycleCellType.REVIEW:
          return {
            title: t('bottom_sheet_course_tutorial_review_title'),
            description: t('bottom_sheet_course_tutorial_review_description'),
          };
        case LearningCycleCellType.MOCK_EXAM:
          return {
            title: t('bottom_sheet_course_tutorial_mockExam_title'),
            description: t('bottom_sheet_course_tutorial_mockExam_description'),
          };
      }
      throw new Error('Unexpected learning cell type for getTutorialBottomSheetPropsFromType!');
    },
    [t]
  );
};

export default React.memo<CourseTutorialBottomSheetWithDimProps>(
  React.forwardRef<HTMLDivElement, CourseTutorialBottomSheetWithDimProps>(function CourseTutorialBottomSheetWithDim(
    props,
    ref
  ) {
    const {t} = useTranslation();
    const {cellType, onButtonClick, ...restProps} = props;

    const {closeDialog} = useDialogContext();
    const getTutorialBottomSheetPropsFromType = useTutorialBottomSheetPropsFromType();

    const onBottomSheetButtonClick = React.useCallback(() => {
      closeDialog();
      onButtonClick?.();
    }, [closeDialog, onButtonClick]);

    const BottomSheetProps = React.useMemo(
      (): BottomSheetProps => ({
        ...getTutorialBottomSheetPropsFromType(cellType),
        isLoadingAiAnimationVisible: true,
        buttons: [
          <Button key={1} onClick={onBottomSheetButtonClick} variant="solid" colorVariant="brand" isFullWidth>
            {t('__learningcell_bottomSheet_button_title')}
          </Button>,
        ],
        onCloseClick: closeDialog,
      }),
      [onBottomSheetButtonClick, getTutorialBottomSheetPropsFromType, closeDialog, cellType, t]
    );

    return (
      <BottomSheetWithDim
        ref={ref}
        css={css`
          z-index: ${ALERT_DIALOG_Z_INDEX};
        `}
        isOpened
        BottomSheetProps={BottomSheetProps}
        {...restProps}
      />
    );
  })
);
