import { OfferFromJSON, OfferToJSON, } from './Offer';
import { OfferGroupDisplayConfigFromJSON, OfferGroupDisplayConfigToJSON, } from './OfferGroupDisplayConfig';
import { OfferGroupSalesConfigFromJSON, OfferGroupSalesConfigToJSON, } from './OfferGroupSalesConfig';
import { PaymentMethodFromJSON, PaymentMethodToJSON, } from './PaymentMethod';
/**
 * Check if a given object implements the OfferGroup interface.
 */
export function instanceOfOfferGroup(value) {
    let isInstance = true;
    isInstance = isInstance && "displayConfig" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "offers" in value;
    isInstance = isInstance && "paymentMethod" in value;
    isInstance = isInstance && "salesConfig" in value;
    return isInstance;
}
export function OfferGroupFromJSON(json) {
    return OfferGroupFromJSONTyped(json, false);
}
export function OfferGroupFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'displayConfig': OfferGroupDisplayConfigFromJSON(json['displayConfig']),
        'id': json['id'],
        'offers': (json['offers'].map(OfferFromJSON)),
        'paymentMethod': PaymentMethodFromJSON(json['paymentMethod']),
        'salesConfig': OfferGroupSalesConfigFromJSON(json['salesConfig']),
    };
}
export function OfferGroupToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'displayConfig': OfferGroupDisplayConfigToJSON(value.displayConfig),
        'id': value.id,
        'offers': (value.offers.map(OfferToJSON)),
        'paymentMethod': PaymentMethodToJSON(value.paymentMethod),
        'salesConfig': OfferGroupSalesConfigToJSON(value.salesConfig),
    };
}
