/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { ImageSetFromJSON, ImageSetToJSON, } from './ImageSet';
import { SelfLearningContentTypeAsEnumFromJSON, SelfLearningContentTypeAsEnumToJSON, } from './SelfLearningContentTypeAsEnum';
/**
 * Check if a given object implements the SelfLearningUnitGroupDetail interface.
 */
export function instanceOfSelfLearningUnitGroupDetail(value) {
    let isInstance = true;
    isInstance = isInstance && "backgroundImage" in value;
    isInstance = isInstance && "contentType" in value;
    isInstance = isInstance && "isLocked" in value;
    isInstance = isInstance && "title" in value;
    return isInstance;
}
export function SelfLearningUnitGroupDetailFromJSON(json) {
    return SelfLearningUnitGroupDetailFromJSONTyped(json, false);
}
export function SelfLearningUnitGroupDetailFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'backgroundImage': ImageSetFromJSON(json['backgroundImage']),
        'caption': !exists(json, 'caption') ? undefined : json['caption'],
        'contentType': SelfLearningContentTypeAsEnumFromJSON(json['contentType']),
        'description': !exists(json, 'description') ? undefined : json['description'],
        'isLocked': json['isLocked'],
        'progressRate': !exists(json, 'progressRate') ? undefined : json['progressRate'],
        'subTitle': !exists(json, 'subTitle') ? undefined : json['subTitle'],
        'title': json['title'],
    };
}
export function SelfLearningUnitGroupDetailToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'backgroundImage': ImageSetToJSON(value.backgroundImage),
        'caption': value.caption,
        'contentType': SelfLearningContentTypeAsEnumToJSON(value.contentType),
        'description': value.description,
        'isLocked': value.isLocked,
        'progressRate': value.progressRate,
        'subTitle': value.subTitle,
        'title': value.title,
    };
}
