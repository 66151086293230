import React from 'react';
import {useCreateOfferGroupOrderMutation, useOfferGroupOrderQuery} from '@app/features/offer/hooks';
import {OfferGroupOrderFailurePageView} from '@app/features/offer/views';
import {useTypedRouter, useTypedSearchParams} from '@app/hooks/useTypedRouter';

const OfferGroupOrderFailurePageContainer = () => {
  const router = useTypedRouter();
  const searchParams = useTypedSearchParams('/offer-group/order/failure');

  const {data: order} = useOfferGroupOrderQuery(searchParams.id);
  const {mutateAsync: createOrder, isLoading: isCreatingOrder} = useCreateOfferGroupOrderMutation();

  const isLoading = !order || isCreatingOrder;

  const handleButtonClick = async () => {
    if (!order) {
      return;
    }
    const offerId = order.offerGroup.offer.id;
    const newOrder = await createOrder({offerId});
    router.replace({pathname: '/offer-group/order', query: {...searchParams, id: newOrder.id, offerId}});
  };

  return <OfferGroupOrderFailurePageView isLoading={isLoading} onButtonClick={handleButtonClick} />;
};

export default OfferGroupOrderFailurePageContainer;
OfferGroupOrderFailurePageContainer.displayName = 'OfferGroupOrderFailurePageContainer';
