import {useCallback} from 'react';
import {useQueryClient} from '@tanstack/react-query';

import {Content} from '@santa-web/gen/open-api/content/models/Content';
import {getContentQueryKey} from '@app/queryKeys';

const useFetchContent = (): ((url: string) => Promise<Content>) => {
  const queryClient = useQueryClient();

  return useCallback(
    async url => {
      const queryKey = getContentQueryKey(url);
      return await queryClient.fetchQuery(queryKey, async () => {
        const response = await fetch(url);
        return response.json();
      });
    },
    [queryClient]
  );
};

export default useFetchContent;
