/* tslint:disable */
/* eslint-disable */
/**
 * - **MONTHLY**: 월할 가격 표시
 * - **TOTAL**: 총 가격 표시
 * @export
 */
export const PriceDisplayType = {
    MONTHLY: 'MONTHLY',
    TOTAL: 'TOTAL'
};
export function PriceDisplayTypeFromJSON(json) {
    return PriceDisplayTypeFromJSONTyped(json, false);
}
export function PriceDisplayTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function PriceDisplayTypeToJSON(value) {
    return value;
}
