import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {COLOR_SANTA_E, COLOR_SANTA_I, COLOR_SANTA_J, COLOR_SANTA_L} from '@riiid/design-system';
import {Typography, TypographyProps} from '@santa-web/service-ui';

const Divider = () => (
  <hr
    css={css`
      margin: 40px 0;
      border: none;
      background-color: ${COLOR_SANTA_E};
      height: 1px;
    `}
  />
);

const Section = ({children}: React.HTMLAttributes<HTMLDivElement>) => (
  <div
    css={css`
      & + & {
        margin-top: 40px;
      }
    `}>
    {children}
  </div>
);

const SectionTitle = (props: TypographyProps) => (
  <Typography
    css={css`
      display: block;
      margin-bottom: 12px;
    `}
    variant="caption1"
    fontWeight="bold"
    color={COLOR_SANTA_J}
    {...props}
  />
);

const Description = (props: TypographyProps) => (
  <Typography
    css={css`
      white-space: pre-wrap;
    `}
    variant="caption1"
    color={COLOR_SANTA_I}
    {...props}
  />
);

const OfferGroupOrderTermsOfUse = () => {
  const {t} = useTranslation();
  return (
    <div>
      <div>
        <Typography
          css={css`
            margin-bottom: 20px;
          `}
          variant="body3"
          color={COLOR_SANTA_L}
          fontWeight="bold">
          {t('offer_group_order_terms_of_use_information_title')}
        </Typography>
        <Trans
          t={t}
          i18nKey="offer_group_order_terms_of_use_information_content"
          components={{
            section: <Section />,
            title: <SectionTitle />,
            description: <Description />,
          }}
        />
      </div>
      <Divider />
      <div>
        <Typography
          css={css`
            margin-bottom: 20px;
          `}
          variant="body3"
          color={COLOR_SANTA_L}
          fontWeight="bold">
          {t('offer_group_order_terms_of_use_caution_title')}
        </Typography>
        <Trans
          t={t}
          i18nKey="offer_group_order_terms_of_use_caution_content"
          components={{
            section: <Section />,
            title: <SectionTitle />,
            description: <Description />,
          }}
        />
      </div>
      <Divider />
      <div>
        <Typography
          css={css`
            margin-bottom: 12px;
          `}
          variant="body3"
          color={COLOR_SANTA_L}
          fontWeight="bold">
          {t('offer_group_order_terms_of_use_support_title')}
        </Typography>
        <a href={`mailto:${t('customer_center_email')}`}>
          <Description>{t('customer_center_email')}</Description>
        </a>
      </div>
    </div>
  );
};

export default OfferGroupOrderTermsOfUse;
OfferGroupOrderTermsOfUse.displayName = 'OfferGroupOrderTermsOfUse';
