// import {atom, useAtomValue} from 'jotai';
import React from 'react';
import {useTypedRouter} from '@app/hooks/useTypedRouter';
import {SantaNavigationPath} from '@app/types/navigation';

// export const shouldBlockDeepLinkNavigationAtom = atom<boolean>(false);

const getIsSantaNavigationPath = (path: string): path is keyof typeof SantaNavigationPath =>
  Object.keys(SantaNavigationPath).includes(path);

// Predefined key for deferred deep link: https://dev.appsflyer.com/hc/docs/dl_ios_ocds_ddl
const DEEP_LINK_VALUE_KEY = 'deep_link_value';

const extractQueryParam = (path: string, key: string): any | null => {
  const pattern = new RegExp(`${key}=([^&]*)`);
  const result = path.match(pattern);
  if (!result || result.length < 2) return null;
  return result[1];
};

// https://www.notion.so/riiid/be5d1ac00015451ba901ad38d9bc4727?v=bd71359376f0484baa67002735a059f6
export const useNavigateDeepLink = () => {
  const router = useTypedRouter();

  return React.useCallback(
    async (_deepLink: string): Promise<void> => {
      const deepLink = _deepLink.includes(DEEP_LINK_VALUE_KEY)
        ? decodeURIComponent(extractQueryParam(_deepLink, DEEP_LINK_VALUE_KEY)!)
        : _deepLink;
      // const shouldBlockDeepLinkNavigation = useAtomValue(shouldBlockDeepLinkNavigationAtom);

      // if (shouldBlockDeepLinkNavigation) {
      //   return;
      // }

      if (deepLink.includes('/main')) {
        await router.push('/');
        return;
      }

      // ONE DEPTH MENU ================================
      // 추천학습 ----------------------------------------
      if (deepLink.includes('/course')) {
        // 현재 추천 학습의 학습 가능한 셀 학습화면으로 이동
        if (deepLink.includes('/course/learning-cell')) {
          router.push('/course/learning-cell');
          return;
        }
        router.push('/course');
        return;
      }
      // 선택학습 ----------------------------------------
      if (deepLink.includes('/self')) {
        if (
          deepLink.includes('/self/question') ||
          deepLink.includes('/self/lesson') ||
          deepLink.includes('/self/voca') ||
          deepLink.includes('/self/virtual-exam')
        ) {
          const segments = deepLink.split('/');
          const type = segments[segments.length - 1];
          // NOTE: 토익 전용
          const contentTypeIndex = {question: 0, lesson: 1, voca: 2, 'virtual-exam': 3}[type];
          router.push({
            pathname: '/self-learning/group/direct',
            query: {
              contentTypeIndex,
              stopOnOneDepth: true,
            },
          });
          return;
        }
        if (deepLink.includes('/self/specific')) {
          const [tabIndex, categoryIndex, oneDepthIndex] = [
            extractQueryParam(deepLink, 'tabIndex'),
            extractQueryParam(deepLink, 'categoryIndex'),
            extractQueryParam(deepLink, 'oneDepthIndex'),
          ];
          router.push({
            pathname: '/self-learning/group/direct',
            query: {
              contentTypeIndex: tabIndex ?? undefined,
              unitGroupFilterIndex: categoryIndex ?? undefined,
              unitGroupIndex: oneDepthIndex ?? undefined,
            },
          });
          return;
        }
        router.push('/self-learning');
        return;
      }
      // 분석 ----------------------------------------
      if (deepLink.includes('/worksheet')) {
        router.push('/analytics/statistics');
        return;
      }
      if (deepLink.includes('/analysis')) {
        if (deepLink.includes('/analysis/learning')) {
          const type = (extractQueryParam(deepLink, 'type') ?? 'day') as 'week' | 'month' | 'day';
          router.push({
            pathname: '/analytics/statistics',
            query: {
              focus: 'lower',
              tab: type,
            },
          });
          return;
        }
        router.push('/analytics');
        return;
      }
      // 복습 ----------------------------------------
      if (deepLink.includes('/mynote')) {
        router.push('/review');
        return;
      }

      // 상품, 결제 ======================================
      // 레거시 상품 관련 ----------------------------------
      if (
        deepLink.includes('/product-board') ||
        deepLink.includes('/product-detail') ||
        deepLink.includes('/product-stripe-detail')
      ) {
        router.push({
          pathname: '/offer-group/board',
          query: {
            referrer: 'deep_link',
          },
        });
        return;
      }

      // offer group 관련 --------------------------------
      if (deepLink.includes('/offer-group')) {
        if (deepLink.includes('/offer-group-detail')) {
          router.push({
            pathname: '/offer-group/board/detail',
            query: {
              offerGroupId: extractQueryParam(deepLink, 'offergroupId'),
              boardCode: extractQueryParam(deepLink, 'code'),
              referrer: 'deep_link',
            },
          });
          return;
        }
        if (deepLink.includes('/offer-group-board')) {
          router.push({
            pathname: '/offer-group/board',
            query: {
              referrer: 'deep_link',
              boardCode: extractQueryParam(deepLink, 'code'),
            },
          });
          return;
        }
      }
      // L2E 관련 ----------------------------------------
      if (deepLink.includes('learn-to-earn-detail')) {
        await router.push('/l2e');
        return;
      }

      // 내 정보 페이지 ====================================
      // 설정 --------------------------------------------
      if (deepLink.includes('/settings')) {
        if (deepLink.includes('/settings/coupon')) {
          router.push({pathname: '/coupon', query: {code: extractQueryParam(deepLink, 'code') ?? undefined}});
          return;
        }
        if (deepLink.includes('/settings/device-settings')) {
          router.push('/me/setting');
          return;
        }
        router.push('/me');
        return;
      }
      // 이벤트 ------------------------------------------
      if (deepLink.includes('/event')) {
        if (deepLink.includes('/event-detail')) {
          router.push({pathname: '/event/detail', query: {promotionId: extractQueryParam(deepLink, 'eventId')}});
          return;
        }
        router.push('/event/list');
        return;
      }

      if (deepLink.includes('/crm-question')) {
        router.push({pathname: '/learning-cell/crm', query: {contentId: extractQueryParam(deepLink, 'contentId')}});
        return;
      }

      // FALLBACK ========================================
      if (getIsSantaNavigationPath(deepLink)) {
        await router.push(deepLink);
        return;
      }
      router.push('/');
    },
    [router]
  );
};
