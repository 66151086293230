/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { SystemConfigFromJSON, } from '../models';
/**
 *
 */
export class SystemConfigV1Api extends runtime.BaseAPI {
    /**
     * 주어진 시스템 설정 키에 대한 시스템 설정을 조회한다.
     */
    async getSystemConfigRaw(requestParameters, initOverrides) {
        if (requestParameters.key === null || requestParameters.key === undefined) {
            throw new runtime.RequiredError('key', 'Required parameter requestParameters.key was null or undefined when calling getSystemConfig.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/system-config/v1/{key}`.replace(`{${"key"}}`, encodeURIComponent(String(requestParameters.key))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => SystemConfigFromJSON(jsonValue));
    }
    /**
     * 주어진 시스템 설정 키에 대한 시스템 설정을 조회한다.
     */
    async getSystemConfig(requestParameters, initOverrides) {
        const response = await this.getSystemConfigRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
