import {Decimal} from 'decimal.js';

import {ElementInteractionState, QuestionState} from '@santa-web/gen/open-api/content-learning-service';
import {
  QuestionSetState as LegacyQuestionSetState,
  QuestionState as LegacyQuestionState,
} from '@santa-web/gen/ssp/messages/inside/contentlearning';
import * as QuestionStateFacade from '@app/facade/question-state';

export const getCumulativeElapsedTimeMs = (
  questionElementStates: ElementInteractionState[] | null | undefined
): Decimal => {
  return (questionElementStates ?? []).reduce((sum, state) => sum.add(state.elapsedTimeMs), new Decimal(0));
};

export const getQuestionState = (
  questionSetState: LegacyQuestionSetState | null | undefined,
  index: number
): LegacyQuestionState | null | undefined => {
  return questionSetState?.questionStates[index];
};

export const getNumberOfSelectedQuestions = (questionStates: QuestionState[] | null): number => {
  return questionStates?.filter(QuestionStateFacade.getIsSelected).length ?? 0;
};

export const getIsCorrect = (questionStates: QuestionState[] | null | undefined) => {
  return Boolean(questionStates?.every(questionState => questionState.answerState === 'CORRECT'));
};
