import {useRouter} from 'next/router';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {COLOR_SANTA_I, COLOR_SANTA_L} from '@riiid/design-system';
import {Alert, Button, NewTextField, Typography, useToastContext} from '@santa-web/service-ui';
import {DimWithEscape} from '@app/components';
import {useDialogContext} from '@app/contexts/DialogContext';
import PreferencePageLayoutContainer from '@app/features/preferences/containers/PreferencePageLayoutContainer';
import useUpdateUserPersonalInfo from '@app/hooks/user/useUpdateUserPersonalInfo';
import useUserQuery from '@app/hooks/user/useUser';

const MyNamePageContainer = () => {
  const {t} = useTranslation();
  const router = useRouter();
  const {data: user} = useUserQuery();
  const {mutateAsync: updateInfo, isLoading} = useUpdateUserPersonalInfo();
  const defaultName = user?.personalInfo?.name ?? 'User';

  const {toastContainer, openToast} = useToastContext();
  const {openDialog, closeDialog} = useDialogContext();

  const [name, setName] = React.useState(defaultName);

  const errorMessage = React.useMemo(() => {
    if (name.length < 1) {
      return t('page_name_change_error_label_min_length');
    }
    if (name.length > 20) {
      return t('page_name_change_error_label_max_length');
    }
    return undefined;
  }, [name.length, t]);

  React.useEffect(() => {
    setName(defaultName);
  }, [defaultName]);

  const handleNameSubmit = async () => {
    await updateInfo({name});
    openToast({message: t('page_name_change_toast'), colorVariant: 'gray'});
  };

  const handleGoBack = async () => {
    if (defaultName !== name) {
      openDialog(
        <DimWithEscape onEscape={closeDialog}>
          <Alert
            title={t('component_change_alert_title')}
            description={t('component_change_alert_description')}
            negativeLabel={t('dict_cancel')}
            onNegativeClick={closeDialog}
            positiveLabel={t('dict_exit')}
            onPositiveClick={() => {
              router.back();
              closeDialog();
            }}
          />
        </DimWithEscape>
      );
    } else {
      router.back();
    }
  };

  return (
    <PreferencePageLayoutContainer
      isNarrow
      onBackButtonClick={handleGoBack}
      upperArea={
        <div>
          <Typography
            css={css`
              margin-bottom: 12px;
            `}
            component="h1"
            variant="caption1"
            fontWeight="bold"
            color={COLOR_SANTA_I}>
            {t('page_name_change_sub_title')}
          </Typography>
          <Typography
            css={css`
              margin-bottom: 40px;
            `}
            component="h2"
            variant="h5"
            fontWeight="bold"
            color={COLOR_SANTA_L}>
            {t('page_name_change_title')}
          </Typography>
          <NewTextField
            isDisabled={user == null}
            value={name}
            label={t('dict_name')}
            error={errorMessage}
            onChange={e => {
              setName(e.target.value);
            }}
          />
          {toastContainer}
        </div>
      }
      bottomArea={
        <Button
          variant="bottom-fix"
          colorVariant="brand"
          isDisabled={defaultName === name || errorMessage != null}
          isLoading={isLoading}
          onClick={handleNameSubmit}>
          {t('page_name_change_button')}
        </Button>
      }
    />
  );
};

export default MyNamePageContainer;
MyNamePageContainer.displayName = 'MyNamePageContainer';
