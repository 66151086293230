import {useCallback, useRef} from 'react';

import {ContentInteractionState} from '@santa-web/gen/open-api/content-learning-service';
import {SequentialCaller} from '@santa-web/service-common';
import useMutateContentInteractionStates from '@app/hooks/content-interaction-state/useMutateContentInteractionStates';
import useUpdateContentInteractionStates from '@app/hooks/content-interaction-state/useUpdateContentInteractionStates';
import {isPermissionDeniedGrpcError} from '@app/types/grpc';
import {SantaResponseError} from '@app/utils/error';

interface Props {
  cellId: number | null | undefined;
  onPermissionDenied?: (error?: SantaResponseError) => void;
}

export const useUpdateCis = ({cellId, onPermissionDenied}: Props) => {
  const sequentialCallerRef = useRef(new SequentialCaller());

  const mutateContentInteractionStates = useMutateContentInteractionStates(cellId);
  const {mutateAsync: rawUpdateContentInteractionStates} = useUpdateContentInteractionStates(cellId, false);

  const updateCis = useCallback(
    async (properties: ContentInteractionState) => {
      const propertiesList = [properties];

      try {
        mutateContentInteractionStates(propertiesList);
        await sequentialCallerRef.current.call(() => rawUpdateContentInteractionStates(propertiesList));
      } catch (e) {
        if (isPermissionDeniedGrpcError(e)) {
          onPermissionDenied?.();
        }
      }
    },
    [mutateContentInteractionStates, onPermissionDenied, rawUpdateContentInteractionStates]
  );
  return updateCis;
};
