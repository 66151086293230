import React from 'react';
import {css} from '@emotion/react';
import {COLOR_SANTA_L, BREAKPOINT_MEDIUM} from '@riiid/design-system';

import {Typography} from '@santa-web/service-ui';

export interface LearningResultContainerProps extends React.HTMLAttributes<HTMLDivElement> {
  learningResultTitle: string;
}

const LearningResultContainer = React.forwardRef<HTMLDivElement, LearningResultContainerProps>(
  ({learningResultTitle, children, ...props}, ref) => {
    return (
      <div
        ref={ref}
        css={css`
          margin-top: 48px;
        `}
        {...props}>
        <Typography
          color={COLOR_SANTA_L}
          variant="body2"
          fontWeight="bold"
          css={css`
            margin-bottom: 12px;

            @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
              margin-bottom: 24px;
            }
          `}>
          {learningResultTitle}
        </Typography>
        {children}
      </div>
    );
  }
);

export default React.memo(LearningResultContainer);
