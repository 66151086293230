import {QuestionState, ToeicVirtualExamQuestionAnswerState} from '@santa-web/gen/open-api/content-learning-service';

export interface QuestionAnswerStateWithIndex extends ToeicVirtualExamQuestionAnswerState {
  questionStateIndex?: number;
  questionIndex?: number;
}

export const convertToContentQuestionState = (questionState: QuestionAnswerStateWithIndex): QuestionState => {
  const userAnswer = questionState.answer;
  const eliminatedChoiceIndices = questionState.eliminations.map(Number);

  return {
    id: -999, // not used
    answerState: 'INCORRECT', // not used
    details: {
      oneOfCase: 'OBJECTIVE',
      objective: {
        correctAnswer: [999], // not used
        userAnswer: userAnswer === -1 ? [] : [userAnswer],
        eliminatedAnswers: eliminatedChoiceIndices,
      },
    },
  };
};

export const convertToVirtualExamQuestionState = ({
  answer,
  eliminations,
}: QuestionAnswerStateWithIndex): ToeicVirtualExamQuestionAnswerState => {
  return {
    answer,
    eliminations,
  };
};

export const convertFromVirtualExamQuestionState = (
  {answer, eliminations}: ToeicVirtualExamQuestionAnswerState,
  questionIndex?: number,
  questionStateIndex?: number
): QuestionAnswerStateWithIndex => {
  return {
    answer,
    eliminations,
    questionIndex,
    questionStateIndex,
  };
};

export const convertContentToVirtualExamQuestionState = ({
  details,
}: QuestionState): ToeicVirtualExamQuestionAnswerState => {
  const objective = details.objective;
  const answer = objective?.userAnswer[0];
  const eliminations = objective?.eliminatedAnswers;

  return {
    answer: answer ?? -1,
    eliminations: eliminations ?? [],
  };
};

export const getIsQuestionSolved = (questionAnswerState: QuestionAnswerStateWithIndex): boolean => {
  return Number(questionAnswerState.answer) !== -1;
};

export const getIsQuestionsSolved = (questionAnswerStates: QuestionAnswerStateWithIndex[]): boolean => {
  return questionAnswerStates.every(getIsQuestionSolved);
};

export const getNumberOfUnsolvedQuestions = (questionAnswerStates: QuestionAnswerStateWithIndex[]): number => {
  return questionAnswerStates.filter(questionAnswerState => !getIsQuestionSolved(questionAnswerState)).length;
};
