import React from 'react';
import {Portal} from 'react-portal';
import {css} from '@emotion/react';
import {COLOR_SANTA_O, COLOR_SANTA_G, COLOR_SANTA_I} from '@riiid/design-system';
import ChevronMiniDown from '@riiid/design-system/icons/ChevronMiniDown';
import ChevronMiniUp from '@riiid/design-system/icons/ChevronMiniUp';

import {ButtonBase, Typography, BottomSheetListItem, ButtonBaseProps, TypographyVariant} from '@santa-web/service-ui';
import BottomSheetListWithDim from '@app/components/BottomSheetListWithDim';

export interface Option<T extends string | number = string> {
  label: string;
  value: T;
}
export interface DropdownProps<T extends string | number = string> extends ButtonBaseProps {
  options: Option<T>[];
  value?: T;
  onValueChanged?(value: T): void;
  hint: string;
  isDisabled?: boolean;
  typographyVariant?: TypographyVariant;
}

const DropdownInner = <T extends string | number>(
  {options, value, hint, onValueChanged, isDisabled, typographyVariant, ...props}: DropdownProps<T>,
  ref: React.ForwardedRef<HTMLButtonElement>
) => {
  const [isOpened, setIsOpened] = React.useState(false);
  const selectedOption = React.useMemo(() => {
    return value == null ? undefined : options.find(option => option.value === value);
  }, [value, options]);
  const ChevronComponent = isOpened ? ChevronMiniUp : ChevronMiniDown;

  return (
    <ButtonBase
      ref={ref}
      isDisabled={isDisabled}
      isFullWidth
      onClick={() => {
        setIsOpened(true);
      }}
      type="button"
      {...props}>
      <div
        css={[
          css`
            width: 100%;
            margin-bottom: 24px;
            color: ${selectedOption ? COLOR_SANTA_I : COLOR_SANTA_G};
            border-bottom: 1px solid ${selectedOption ? COLOR_SANTA_I : COLOR_SANTA_G};
            display: flex;
            text-align: left;
            align-items: center;
          `,
          !isDisabled &&
            css`
              @media (hover: hover) {
                :hover {
                  color: ${COLOR_SANTA_O};
                  border-bottom: 1px solid ${COLOR_SANTA_O};
                }
              }
              :active,
              :focus {
                color: ${COLOR_SANTA_O};
                border-bottom: 1px solid ${COLOR_SANTA_O};
              }
            `,
          isDisabled &&
            css`
              opacity: 0.4;
            `,
        ]}>
        <Typography
          variant={typographyVariant || 'body2'}
          fontWeight="regular"
          css={css`
            margin-bottom: 4px;
            flex-grow: 1;
          `}>
          {selectedOption ? selectedOption.label : hint}
        </Typography>
        <ChevronComponent type="line" size="L" width="24" height="24" />
        <Portal>
          <BottomSheetListWithDim
            BottomSheetListProps={{
              onCloseClick: () => {
                setIsOpened(false);
              },
              children: options.map(option => (
                <BottomSheetListItem
                  key={option.value}
                  title={option.label}
                  isSelected={option.value === value}
                  onClick={e => {
                    e.stopPropagation();
                    onValueChanged?.(option.value);
                    setIsOpened(false);
                  }}
                />
              )),
            }}
            isOpened={isOpened}
          />
        </Portal>
      </div>
    </ButtonBase>
  );
};
const Dropdown = React.forwardRef<HTMLButtonElement, DropdownProps>(DropdownInner) as <T extends string | number>(
  props: DropdownProps<T> & {ref?: React.ForwardedRef<HTMLButtonElement>}
) => ReturnType<typeof DropdownInner>;

export default React.memo(Dropdown) as typeof Dropdown;
