import {ReactNode} from 'react';
import {css} from '@emotion/react';
import {
  COLOR_FIX_SANTA_GRAY_1,
  COLOR_FIX_SANTA_GRAY_4,
  COLOR_FIX_SPACE_BLUE_BLACK_1,
  COLOR_FIX_SPACE_BLUE_GRAY_9,
  COLOR_RIIID_GREEN_3,
  COLOR_RIIID_YELLOW_3,
  SHADOW_A_1_DOWN,
  SHADOW_A_1_TOP,
  SHADOW_B_1_DOWN,
} from '@riiid/design-system';
import {Button, TextButton, Typography} from '@santa-web/service-ui';
import {Column, FrameLayout, Row, ScrollLayout} from '@app/components';
import {use100vh} from '@app/hooks/use100vh';

export type L2EFlashDealPageViewProps = {
  onBuyClick: () => void;
  onCloseClick: () => void;
  offerGroupCard: ReactNode;
  countdownTimer: ReactNode;
};

const L2EFlashDealPageView = ({
  offerGroupCard,
  countdownTimer,
  onBuyClick,
  onCloseClick,
}: L2EFlashDealPageViewProps) => {
  const fullHeight = use100vh();
  return (
    <FrameLayout
      css={css`
        height: ${fullHeight};
        background-color: ${COLOR_FIX_SPACE_BLUE_BLACK_1};
      `}
      bottomArea={
        <Column
          alignItems="center"
          css={css`
            position: sticky;
            bottom: 0;
            padding: 20px 20px calc(var(--mobile-safe-area-bottom, 0px) + 40px);
            background: linear-gradient(180deg, #1226aa 0%, #000b53 100%);
            box-shadow: ${SHADOW_A_1_TOP};
          `}>
          <Column
            gap={12}
            alignItems="center"
            css={css`
              width: 100%;
              max-width: 600px;
            `}>
            <Button isFullWidth colorVariant="brand" onClick={onBuyClick}>
              상품 구매하고 학습 계속하기
            </Button>
            <TextButton fontWeight="regular" typographyVariant="body3" color="white-fix" onClick={onCloseClick}>
              아니요, 괜찮아요
            </TextButton>
          </Column>
        </Column>
      }>
      <ScrollLayout
        css={css`
          position: relative;
          overscroll-behavior: none;
          overflow-x: hidden;
        `}>
        {/* bg-1 */}
        <Column
          alignItems="center"
          css={css`
            position: relative;
            height: calc(328px + var(--mobile-safe-area-top, 0px));
            width: 100%;
            overflow: hidden;
          `}>
          <Confetti
            css={css`
              z-index: 2;
              position: absolute;
              top: var(--mobile-safe-area-top, 0px);
              width: 100%;
              max-width: 600px;
              height: 150px;
            `}
          />
          <Spotlight
            css={css`
              position: absolute;
              top: 0;
              left: 50%;
              height: calc(275px + var(--mobile-safe-area-top, 0px));
              transform: translateX(-50%);
              z-index: 1;
            `}
          />
          <img
            css={css`
              z-index: 0;
              position: absolute;
              bottom: 0;
            `}
            width={230}
            height={216}
            src={'/img/flash-deal/coin-3-x.png'}
            alt="coin"
          />
          <Column
            css={css`
              padding-top: calc(var(--mobile-safe-area-top, 0px) + 64px);
            `}
            gap={4}>
            <Row
              justifyContent="center"
              css={css`
                z-index: 1;
                background: ${COLOR_FIX_SANTA_GRAY_1};
                box-shadow: ${SHADOW_A_1_DOWN};
                border-radius: 8px;
                padding: 0px 12px;
                color: ${COLOR_FIX_SPACE_BLUE_BLACK_1};
              `}>
              <Typography variant="body3" fontWeight="bold">
                방금 학습으로 포인트가 쌓였어요
              </Typography>
            </Row>

            <Column
              css={css`
                z-index: 1;
              `}>
              <Typography
                css={css`
                  text-align: center;
                `}
                color={COLOR_FIX_SANTA_GRAY_1}
                variant="h3"
                fontWeight="bold">
                <strong
                  css={css`
                    color: ${COLOR_RIIID_YELLOW_3};
                  `}>
                  690,000,000
                </strong>{' '}
                원
              </Typography>
              <Typography
                variant="h3"
                color={COLOR_FIX_SANTA_GRAY_1}
                css={css`
                  margin-top: -12px;
                  text-align: center;
                `}>
                환급 완료!
              </Typography>
              <Typography
                variant="caption2"
                color={COLOR_FIX_SANTA_GRAY_4}
                css={css`
                  text-align: center;
                `}>
                2024년 9월 말 기준 누적 금액
              </Typography>
            </Column>
          </Column>
        </Column>

        {/* bg-2 */}
        <Column
          alignItems="center"
          css={css`
            width: 100%;
          `}>
          <Column
            gap={20}
            css={css`
              width: 100%;
              position: relative;
              height: 449px;
              padding: 32px 20px 0px;
              max-width: 600px;
            `}>
            <Confetti
              css={css`
                z-index: 0;
                position: absolute;
                left: 26px;
                bottom: -100px;
                width: 154px;
                height: 613px;
                opacity: 0.5;
              `}
              rotate="left"
            />
            <Confetti
              css={css`
                z-index: 0;
                position: absolute;
                right: 26px;
                bottom: -100px;
                width: 154px;
                height: 613px;
                opacity: 0.5;
              `}
              rotate="right"
            />
            <Column
              css={css`
                z-index: 1;
              `}
              alignItems="center"
              gap={4}>
              <Row
                css={css`
                  width: fit-content;
                  border-radius: 4px;
                  padding: 0px 8px;
                  background-color: ${COLOR_RIIID_GREEN_3};
                  box-shadow: ${SHADOW_B_1_DOWN};
                  justify-content: center;
                `}>
                <Typography variant="caption2" fontWeight="bold" color={COLOR_FIX_SPACE_BLUE_GRAY_9}>
                  신규 회원 단독!
                </Typography>
              </Row>
              <Column alignItems="center">
                <Typography variant="h5" fontWeight="bold" color={COLOR_FIX_SANTA_GRAY_1}>
                  최대{' '}
                  <strong
                    css={css`
                      color: ${COLOR_RIIID_GREEN_3};
                    `}>
                    1,245,000원
                  </strong>{' '}
                  환급에 도전!
                </Typography>
                <Typography variant="body3" color={COLOR_FIX_SANTA_GRAY_1}>
                  ⬇︎ 내 포인트,{' '}
                  <strong
                    css={css`
                      color: ${COLOR_RIIID_GREEN_3};
                    `}>
                    환급
                  </strong>
                  받으러 가볼까요? ⬇︎
                </Typography>
              </Column>
            </Column>
            <div
              css={css`
                width: 100%;
                position: relative;

                &::before {
                  content: '';
                  position: absolute;
                  top: -38px;
                  left: 50%;
                  transform: translateX(-50%);
                  width: clamp(454px, 100%, 554px);
                  height: calc(100% + 52px);
                  border-radius: 554px;
                  background: radial-gradient(50% 50% at 50% 50%, #9ecdff 0%, rgba(236, 245, 255, 0.2) 100%);
                  filter: blur(15px);
                }
              `}>
              {offerGroupCard}
            </div>
          </Column>
        </Column>

        {/* Timer */}
        <Column
          css={css`
            margin-bottom: 40px;
          `}
          gap={28}
          alignItems="center">
          <Typography
            css={css`
              text-align: center;
            `}
            variant="h5"
            fontWeight="bold"
            color={COLOR_FIX_SANTA_GRAY_1}>
            무료 학습 기간이 끝나면 <br /> 다시 만날 수 없는{' '}
            <strong
              css={css`
                color: ${COLOR_RIIID_GREEN_3};
              `}>
              혜택
            </strong>
          </Typography>

          {countdownTimer}
        </Column>
      </ScrollLayout>
    </FrameLayout>
  );
};

const Spotlight = (props: React.HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      {...props}
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        z-index: 0;
      `}>
      <svg xmlns="http://www.w3.org/2000/svg" height="100%" viewBox="0 0 360 275" fill="none">
        <g filter="url(#filter0_f_10054_197285)">
          <path d="M145 -15H215L326 235H33L145 -15Z" fill="url(#paint0_linear_10054_197285)" />
        </g>
        <defs>
          <filter
            id="filter0_f_10054_197285"
            x="-7"
            y="-55"
            width="373"
            height="330"
            filterUnits="userSpaceOnUse"
            colorInterpolationFilters="sRGB">
            <feFlood floodOpacity="0" result="BackgroundImageFix" />
            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
            <feGaussianBlur stdDeviation="20" result="effect1_foregroundBlur_10054_197285" />
          </filter>
          <linearGradient
            id="paint0_linear_10054_197285"
            x1="179.5"
            y1="-15"
            x2="179.5"
            y2="235"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#DFEDFF" />
            <stop offset="0.852201" stopColor="#000B53" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

const Confetti = ({
  rotate = 'top',
  ...props
}: React.HTMLAttributes<HTMLDivElement> & {rotate?: 'left' | 'right' | 'top'}) => {
  const url =
    rotate === 'top'
      ? '/img/flash-deal/confetti-3-x.png'
      : rotate === 'right'
      ? '/img/flash-deal/confetti-right-3-x.png'
      : '/img/flash-deal/confetti-left-3-x.png';

  return (
    <div
      {...props}
      css={css`
        background-image: url(${url});
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
      `}></div>
  );
};

export default L2EFlashDealPageView;
