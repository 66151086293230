import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {COLOR_SANTA_L} from '@riiid/design-system';

import {Typography} from '@santa-web/service-ui';
import BaseCard, {CardProps} from '@app/components/card/BaseCard';

export type ChartWrapperCardProps = CardProps;

const ChartWrapperCard = React.forwardRef<HTMLDivElement, ChartWrapperCardProps>(({children, ...props}, ref) => {
  const {t} = useTranslation();

  return (
    <BaseCard {...props} ref={ref}>
      <Typography color={COLOR_SANTA_L} variant="body2" fontWeight="bold">
        {t('learning_cell_report_learning_histories')}
      </Typography>
      <div
        css={css`
          display: flex;
          flex-direction: row;
          margin: 20px -20px 0 -20px;
        `}>
        {children}
      </div>
    </BaseCard>
  );
});

export default React.memo(ChartWrapperCard);
