import {CreateContentInteractionStatesBadRequestErrorCase} from '@santa-web/gen/open-api/content-learning-service';
import {SantaResponseError} from '@app/utils/error';

class CisesOverflowError extends Error {
  constructor(public cause: Error) {
    super('Cises overflow error');

    this.name = 'CisesOverflowError';
  }

  static shouldThrow(error: Error) {
    return (
      error instanceof SantaResponseError &&
      error.santaErrorCode === CreateContentInteractionStatesBadRequestErrorCase.NO_MORE_CONTENTS_TO_PROVIDE
    );
  }
}

export {CisesOverflowError};
