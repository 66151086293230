import React from 'react';
import {css} from '@emotion/react';
import {COLOR_SANTA_L_ALPHA, COLOR_SANTA_BD} from '@riiid/design-system';
import FilterIcon from '@riiid/design-system/icons/Filter';

import {IconButton, IconButtonProps} from '@santa-web/service-ui';

export interface FilterButtonProps {
  isDirty?: boolean;
  isDisabled?: boolean;
  onClick: IconButtonProps['onClick'];
  ButtonProps?: IconButtonProps;
}

const FilterButton = ({isDirty, isDisabled, onClick, ButtonProps}: FilterButtonProps): JSX.Element => {
  const [isPressing, setIsPressing] = React.useState(false);
  const onPressingStart = React.useCallback(() => setIsPressing(true), [setIsPressing]);
  const onPressingEnd = React.useCallback(() => setIsPressing(false), [setIsPressing]);
  return (
    <div
      css={css`
        display: inline-flex;
        position: relative;
      `}>
      {isPressing ? (
        <div
          css={css`
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 32px;
            height: 32px;
            background: ${COLOR_SANTA_L_ALPHA(0.1)};
            border-radius: 50%;
            pointer-events: none;
          `}
        />
      ) : null}
      <IconButton
        Icon={FilterIcon}
        isDisabled={isDisabled}
        IconProps={
          isDirty
            ? {
                color: COLOR_SANTA_BD,
              }
            : undefined
        }
        onClick={onClick}
        onTouchStart={onPressingStart}
        onTouchCancel={onPressingEnd}
        onTouchEnd={onPressingEnd}
        onContextMenu={onPressingEnd}
        onMouseDown={onPressingStart}
        onMouseLeave={onPressingEnd}
        onMouseUp={onPressingEnd}
        {...ButtonProps}
      />
    </div>
  );
};

export default React.memo(FilterButton);
