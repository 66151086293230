import {useQuery} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';
import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {getDiagnosisConfigQueryKey} from '@app/queryKeys';

export default function useDiagnosisConfigQuery() {
  const {DiagnosisService} = useAtomValue(santaOpenapiServicesAtom);
  return useQuery({
    queryKey: getDiagnosisConfigQueryKey(),
    queryFn: async () => {
      return await DiagnosisService.getDiagnosisConfig({});
    },
  });
}
