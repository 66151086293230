import {css} from '@emotion/react';
import {AlertDialog} from '@santa-web/service-ui';
import {ALERT_DIALOG_Z_INDEX} from '@app/constants/zIndex';
import {useDialogContext} from '@app/contexts/DialogContext';

export const useFlashDealExitAlertDialog = (onExit: () => void) => {
  const {openDialog, closeDialog} = useDialogContext();
  const openFlashDealExitAlertDialog = () =>
    openDialog(
      <AlertDialog
        isVisible
        AlertProps={{
          css: css`
            white-space: pre-line;
          `,
          title: `페이지 이탈 시\n이 혜택은 더이상 누릴 수 없습니다`,
          description: '페이지를 나가시겠어요?',
          negativeLabel: '취소',
          onNegativeClick: closeDialog,
          positiveLabel: '나가기',
          onPositiveClick: onExit,
        }}
        css={css`
          z-index: ${ALERT_DIALOG_Z_INDEX};
        `}
      />
    );
  return openFlashDealExitAlertDialog;
};
