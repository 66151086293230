import React from 'react';
import {css} from '@emotion/react';
import {COLOR_SANTA_I, COLOR_SANTA_L} from '@riiid/design-system';
import {URL_IMG_BOTTOM_CARD, URL_IMG_TOP_CARD} from '@riiid/design-system/images/santa';

import {Typography} from '@santa-web/service-ui';
import BaseCard from '@app/components/card/BaseCard';

type ExamRankingCardVariant = 'top' | 'bottom';
export interface ExamRankingCardProps {
  title: string;
  content: string;
  variant?: ExamRankingCardVariant;
}

const ExamRankingCard = ({title, content, variant = 'top'}: ExamRankingCardProps) => {
  return (
    <BaseCard
      css={css`
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        padding: 16px;

        background-image: url(${variant === 'top' ? URL_IMG_TOP_CARD : URL_IMG_BOTTOM_CARD});
        background-repeat: no-repeat;
        background-size: 140px 80px;
        background-position: calc(100% - 16px) 0;
      `}>
      <Typography variant="caption1" color={COLOR_SANTA_I}>
        {title}
      </Typography>
      <Typography variant="body3" color={COLOR_SANTA_L} fontWeight="bold">
        {content}
      </Typography>
    </BaseCard>
  );
};

export default ExamRankingCard;
