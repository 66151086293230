import React from 'react';
import {css} from '@emotion/react';
import {COLOR_SANTA_O, COLOR_SANTA_E, COLOR_SANTA_BE, COLOR_SANTA_L} from '@riiid/design-system';

import {ButtonBase, ButtonBaseProps, Typography, FontWeightVariant} from '@santa-web/service-ui';

export interface TabButtonProps extends ButtonBaseProps {
  isSelected?: boolean;
  fontWeight?: FontWeightVariant;
}

const TabButton = React.forwardRef<HTMLButtonElement, TabButtonProps>(
  ({isSelected, isDisabled, fontWeight, children, ...props}, ref) => {
    const opacity = isDisabled ? '0.2' : isSelected ? '1' : '0.4';

    return (
      <ButtonBase
        ref={ref}
        data-selected={isSelected}
        css={[
          css`
            opacity: ${opacity};
            border: 1px solid ${isSelected ? COLOR_SANTA_BE : COLOR_SANTA_L};
            padding: 8px 16px;
            border-radius: 4px;
          `,
          !isDisabled &&
            css`
              &:hover,
              &:active {
                background-color: ${COLOR_SANTA_E};
              }
            `,
        ]}
        isDisabled={isDisabled}
        {...props}>
        <Typography
          color={isSelected ? COLOR_SANTA_BE : COLOR_SANTA_O}
          variant="caption1"
          fontWeight={fontWeight || 'bold'}>
          {children}
        </Typography>
      </ButtonBase>
    );
  }
);

export default React.memo(TabButton);
