import produce from 'immer';
import {atom} from 'jotai';

import {cisService} from '@app/test-preparation/atoms/services';

const updateLessonCisAtom = atom(null, async (get, set, {youtubeTimestamp}: {youtubeTimestamp: number}) => {
  const cis = get(cisService.cisAtom);
  const nextCis = produce(cis, draft => {
    if (cis.elementInteractionStates.length >= 2 && cis.elementInteractionStates[0].details.oneOfCase === 'LESSON') {
      throw new Error(`Lesson content should contain single eis, but it has ${cis.elementInteractionStates.length}`);
    }

    if (draft.elementInteractionStates[0].details.lesson) {
      draft.elementInteractionStates[0].details.lesson.youtubeTimestamp = youtubeTimestamp;
    }
  });

  await set(cisService.updateCisAtom, {cis: nextCis});
});

export {updateLessonCisAtom};
