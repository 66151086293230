import React from 'react';
import {css} from '@emotion/react';

export type DevContentInfoHeaderProps = React.ComponentPropsWithoutRef<'div'>;

const DevContentInfoHeader = React.forwardRef<HTMLDivElement, DevContentInfoHeaderProps>((props, ref) => {
  return (
    <div
      css={css`
        position: absolute;
        width: 100%;
        top: 4px;

        opacity: 0.5;

        overflow: 'overlay';

        font-size: 8px;
        text-align: center;
      `}
      ref={ref}
      {...props}
    />
  );
});

export default React.memo(DevContentInfoHeader);
