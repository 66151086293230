import {atom} from 'jotai';

import {Content} from '@santa-web/gen/open-api/content/models/Content';
import {cisRepository} from '@app/test-preparation/atoms/repositories';
import {cisService} from '@app/test-preparation/atoms/services';
import {findFirstIncompleteCisIndex} from '@app/test-preparation/atoms/services/cis/utils/cis';

type InitializeCisesParams =
  | {learningCellId: number; cisId?: undefined; content?: undefined}
  | {learningCellId?: undefined; cisId: number; content?: undefined}
  | {learningCellId?: undefined; cisId?: undefined; content: Content};

const initializeAtom = atom(null, async (get, set, params: InitializeCisesParams) => {
  // Set preview content
  if (params.content !== undefined) {
    set(cisService.contentDataMapAtom, {0: {url: '', content: params.content}});
    set(cisService.cisesAtom, [{elementInteractionStates: []} as any]);
    set(cisService.currentCisIndexAtom, 0);

    return;
  }

  // Set single cis
  if (params.cisId !== undefined) {
    const getCis = await get(cisRepository.getCis);

    const {contentInteractionState: cis, contentUrl: url} = await getCis(params.cisId);

    set(cisService.contentDataMapAtom, {0: {url}});
    set(cisService.cisesAtom, [cis]);
    set(cisService.startCisIndexAtom, 0);
    await set(cisService.setCurrentCisIndexAtom, {index: 0});

    return;
  }

  const getCisesSummary = await get(cisRepository.getCisesSummaryAtom);

  // Set learning cell id
  set(cisService.learningCellIdAtom, params.learningCellId);

  // Set cises summary
  const cisesSummary = await getCisesSummary(params.learningCellId);

  set(cisService.summaryAtom, cisesSummary);

  // Set cises
  set(cisService.cisesAtom, []);

  if (cisesSummary.count > 0) {
    await set(cisService.loadCurrentCisesAtom);
  } else {
    await set(cisService.loadNextCisesAtom);
  }

  const cises = get(cisService.cisesAtom);

  // Set Elapsed time
  const initialElapsedTimeMap = cises.reduce<Record<string, number>>(
    (elapsedTimeMap, cis) => ({
      ...elapsedTimeMap,
      [cis.id]: cis.elementInteractionStates.reduce((sum, eis) => sum + eis.elapsedTimeMs, 0),
    }),
    {}
  );

  set(cisService.elapsedTimeMapAtom, initialElapsedTimeMap);

  // Set current cis index
  const firstIncompleteCisIndex = findFirstIncompleteCisIndex(cises);
  const hasIncomplete = firstIncompleteCisIndex !== -1;

  if (hasIncomplete) {
    await set(cisService.setCurrentCisIndexAtom, {index: firstIncompleteCisIndex});
    set(cisService.startCisIndexAtom, firstIncompleteCisIndex);
  } else {
    const totalCisCount = cises.length;

    await set(cisService.loadNextCisesAtom);
    await set(cisService.setCurrentCisIndexAtom, {index: totalCisCount});
    set(cisService.startCisIndexAtom, totalCisCount);
  }
});

export {initializeAtom};
