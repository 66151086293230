import React from 'react';
import {ErrorBoundary} from '@sentry/nextjs';
import Fallback from './Fallback';

const SentryErrorBoundary = ({children}: {children: React.ReactNode}) => {
  return (
    <ErrorBoundary
      fallback={({resetError, error, eventId}) => <Fallback resetError={resetError} eventId={eventId} error={error} />}>
      {children}
    </ErrorBoundary>
  );
};

export default SentryErrorBoundary;
