/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { CreateExamScoreRequestToJSON, CreateExamScoreResponseFromJSON, GetExamScoreResponseFromJSON, ListExamScoresResponseFromJSON, UpdateExamScoreRequestToJSON, UpdateExamScoreResponseFromJSON, } from '../models';
/**
 *
 */
export class ExamScoreV1Api extends runtime.BaseAPI {
    /**
     * 사용자가 응시한 시험의 점수를 등록한다.
     */
    async createExamScoreRaw(requestParameters, initOverrides) {
        if (requestParameters.createExamScoreRequest === null || requestParameters.createExamScoreRequest === undefined) {
            throw new runtime.RequiredError('createExamScoreRequest', 'Required parameter requestParameters.createExamScoreRequest was null or undefined when calling createExamScore.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/user/v1/exam-scores/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateExamScoreRequestToJSON(requestParameters.createExamScoreRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CreateExamScoreResponseFromJSON(jsonValue));
    }
    /**
     * 사용자가 응시한 시험의 점수를 등록한다.
     */
    async createExamScore(requestParameters, initOverrides) {
        const response = await this.createExamScoreRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * `examDate`에 대해 등록된 시험 점수를 삭제한다.
     */
    async deleteExamScoreRaw(requestParameters, initOverrides) {
        if (requestParameters.examDate === null || requestParameters.examDate === undefined) {
            throw new runtime.RequiredError('examDate', 'Required parameter requestParameters.examDate was null or undefined when calling deleteExamScore.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/user/v1/exam-scores/{examDate}`.replace(`{${"examDate"}}`, encodeURIComponent(String(requestParameters.examDate))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * `examDate`에 대해 등록된 시험 점수를 삭제한다.
     */
    async deleteExamScore(requestParameters, initOverrides) {
        await this.deleteExamScoreRaw(requestParameters, initOverrides);
    }
    /**
     * `examDate`에 대해 등록된 시험 점수를 조회한다.
     */
    async getExamScoreRaw(requestParameters, initOverrides) {
        if (requestParameters.examDate === null || requestParameters.examDate === undefined) {
            throw new runtime.RequiredError('examDate', 'Required parameter requestParameters.examDate was null or undefined when calling getExamScore.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/user/v1/exam-scores/{examDate}`.replace(`{${"examDate"}}`, encodeURIComponent(String(requestParameters.examDate))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetExamScoreResponseFromJSON(jsonValue));
    }
    /**
     * `examDate`에 대해 등록된 시험 점수를 조회한다.
     */
    async getExamScore(requestParameters, initOverrides) {
        const response = await this.getExamScoreRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 사용자가 현재 학습 도메인에 대해 등록한 시험 점수 목록을 모두 조회한다.
     */
    async listExamScoresRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/user/v1/exam-scores/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListExamScoresResponseFromJSON(jsonValue));
    }
    /**
     * 사용자가 현재 학습 도메인에 대해 등록한 시험 점수 목록을 모두 조회한다.
     */
    async listExamScores(requestParameters, initOverrides) {
        const response = await this.listExamScoresRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * `examDate`에 대해 등록된 시험 점수를 수정한다.
     */
    async updateExamScoreRaw(requestParameters, initOverrides) {
        if (requestParameters.examDate === null || requestParameters.examDate === undefined) {
            throw new runtime.RequiredError('examDate', 'Required parameter requestParameters.examDate was null or undefined when calling updateExamScore.');
        }
        if (requestParameters.updateExamScoreRequest === null || requestParameters.updateExamScoreRequest === undefined) {
            throw new runtime.RequiredError('updateExamScoreRequest', 'Required parameter requestParameters.updateExamScoreRequest was null or undefined when calling updateExamScore.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/user/v1/exam-scores/{examDate}`.replace(`{${"examDate"}}`, encodeURIComponent(String(requestParameters.examDate))),
            method: 'PATCH',
            headers: headerParameters,
            query: queryParameters,
            body: UpdateExamScoreRequestToJSON(requestParameters.updateExamScoreRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => UpdateExamScoreResponseFromJSON(jsonValue));
    }
    /**
     * `examDate`에 대해 등록된 시험 점수를 수정한다.
     */
    async updateExamScore(requestParameters, initOverrides) {
        const response = await this.updateExamScoreRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
