import {atom} from 'jotai';
import {LessonState, QuestionState, VocaState} from '@santa-web/gen/open-api/content-learning-service';
import {setAtomLabels} from '@app/misc/atom-label';
import {cisService} from '@app/test-preparation/atoms/services';

const idAtom = atom(get => get(cisService.cisAtom).id);
const languageAtom = atom(get => get(cisService.cisAtom).language);

const elapsedTimeAtom = atom(get => get(cisService.elapsedTimeMapAtom)[get(idAtom)]);

const eisesAtom = atom(get => get(cisService.cisAtom).elementInteractionStates);
const totalEisCountAtom = atom(get => get(eisesAtom).length);
const correctnessAtom = atom(get => get(eisesAtom).map(eis => eis.details.question?.answerState === 'CORRECT'));

const isBookmarked = atom(get => get(cisService.cisAtom).isBookmarked);
const isStartedAtom = atom(get => get(cisService.cisAtom).startedAtServer instanceof Date);
const isCompletedAtom = atom(get => get(cisService.cisAtom).completedAtServer instanceof Date);

const isQuestionAtom = atom(get => get(eisesAtom).every(eis => eis.details.oneOfCase === 'QUESTION'));
const isLessonAtom = atom(get => get(eisesAtom).every(eis => eis.details.oneOfCase === 'LESSON'));
const isVocabularyAtom = atom(get => get(eisesAtom).every(eis => eis.details.oneOfCase === 'VOCA'));

const questionStatesAtom = atom(get => {
  const eises = get(eisesAtom);
  const isQuestion = get(isQuestionAtom);

  return isQuestion
    ? eises
        .map(eis => eis.details.question)
        .filter((questionState): questionState is QuestionState => Boolean(questionState))
    : null;
});
const lessonStatesAtom = atom(get => {
  const eises = get(eisesAtom);
  const isLesson = get(isLessonAtom);

  return isLesson
    ? eises.map(eis => eis.details.lesson).filter((lessonState): lessonState is LessonState => Boolean(lessonState))
    : null;
});
const vocabularyStatesAtom = atom(get => {
  const eises = get(eisesAtom);
  const isVocabulary = get(isVocabularyAtom);

  return isVocabulary
    ? eises.map(eis => eis.details.voca).filter((vocaState): vocaState is VocaState => Boolean(vocaState))
    : null;
});

export const cis = {
  idAtom,
  languageAtom,

  elapsedTimeAtom,

  eisesAtom,
  totalEisCountAtom,
  correctnessAtom,

  isBookmarked,
  isStartedAtom,
  isCompletedAtom,

  isQuestionAtom,
  isLessonAtom,
  isVocabularyAtom,

  questionStatesAtom,
  lessonStatesAtom,
  vocabularyStatesAtom,
};

setAtomLabels(
  {
    idAtom,
    languageAtom,
    elapsedTimeAtom,
    eisesAtom,
    totalEisCountAtom,
    correctnessAtom,
    isBookmarked,
    isStartedAtom,
    isCompletedAtom,
    isQuestionAtom,
    isLessonAtom,
    isVocabularyAtom,
    questionStatesAtom,
    lessonStatesAtom,
    vocabularyStatesAtom,
  },
  'learningStore.cis.'
);
