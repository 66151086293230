import { useContext } from "react";
import FloatingChatButtonListContext from "../../contexts/FloatingChatButtonListContext";
export default function useFloatingChatButtonList() {
    var _useContext = useContext(FloatingChatButtonListContext), floatingChatButtonList = _useContext.floatingChatButtonList, setFloatingChatButtonProps = _useContext.setFloatingChatButtonProps, deleteFloatingChatButton = _useContext.deleteFloatingChatButton, floatingChatButtonPropsMapRef = _useContext.floatingChatButtonPropsMapRef, updateFloatingChatButtonListRef = _useContext.updateFloatingChatButtonListRef, numberOfFloatingChatButtons = _useContext.numberOfFloatingChatButtons;
    return {
        floatingChatButtonList: floatingChatButtonList,
        setFloatingChatButtonProps: setFloatingChatButtonProps,
        deleteFloatingChatButton: deleteFloatingChatButton,
        floatingChatButtonPropsMapRef: floatingChatButtonPropsMapRef,
        updateFloatingChatButtonListRef: updateFloatingChatButtonListRef,
        numberOfFloatingChatButtons: numberOfFloatingChatButtons
    };
}
