import React from 'react';
import {useQueryClient} from '@tanstack/react-query';
import {delay} from '@app/utils/async';
import {throttle} from '@app/utils/throttle';

export const TRANSITION_DURATION_MS = 100;
export const LOADING_ICON_SIZE = 32;
export const LOADING_INDICATOR_MARGIN = 20;
const TOUCH_MOVE_DIFF_Y_THRESHOLD = 10;
const LOADING_INDICATOR_MIN_HEIGHT = LOADING_ICON_SIZE + LOADING_INDICATOR_MARGIN * 2;
const LOADING_INDICATOR_MAX_HEIGHT = 100;

const getIsTouchableDevice = () => {
  return 'ontouchstart' in window || navigator.maxTouchPoints > 0;
};

export const usePullToRefresh = (isDisabled = false) => {
  const queryClient = useQueryClient();
  const scrollAreaRef = React.useRef<HTMLDivElement>(null);
  const touchStartEventRef = React.useRef<TouchEvent | null>(null);
  const [isRefreshing, setIsRefreshing] = React.useState(false);

  React.useEffect(() => {
    if (!getIsTouchableDevice() || isDisabled) {
      return;
    }
    const filterInvalidEvent = (event: TouchEvent): {currentTouch: Touch; scrollArea: HTMLDivElement} | null => {
      const isDimmed = document.getElementById('dim')?.contains(event.target as Node);
      const currentTouch = event.changedTouches.item(0);
      const scrollArea = scrollAreaRef.current;
      if (!currentTouch || !scrollArea || scrollArea.scrollTop > 0 || isDimmed) {
        return null;
      }
      return {currentTouch, scrollArea};
    };

    const filterInvalidMoveEndEventData = (event: TouchEvent) => {
      const filteredEvent = filterInvalidEvent(event);
      const firstTouch = touchStartEventRef.current?.changedTouches.item(0);
      if (!filteredEvent || !firstTouch) {
        return null;
      }
      const {scrollArea, currentTouch} = filteredEvent;
      const [startY, endY] = [firstTouch.clientY, currentTouch.clientY];
      const diffY = endY - startY;
      if (diffY < TOUCH_MOVE_DIFF_Y_THRESHOLD) {
        return null;
      }
      return {scrollArea, diffY};
    };

    const handleTouchStartEvent = async (touchStartEvent: TouchEvent) => {
      const filteredEvent = filterInvalidEvent(touchStartEvent);
      if (!filteredEvent) {
        return;
      }
      const {currentTouch, scrollArea} = filteredEvent;
      const {clientY: touchY} = currentTouch;
      const {top, height} = scrollArea.getBoundingClientRect();
      const bottom = top + height;
      if (top <= touchY && touchY <= bottom) {
        touchStartEventRef.current = touchStartEvent;
      } else {
        touchStartEventRef.current = null;
      }
    };

    const handleTouchMoveEvent = throttle((touchMoveEvent: TouchEvent) => {
      const filteredEventData = filterInvalidMoveEndEventData(touchMoveEvent);
      if (!filteredEventData) {
        return;
      }
      const {scrollArea, diffY} = filteredEventData;
      scrollArea.style.transform = `translateY(${Math.max(
        Math.min(diffY, LOADING_INDICATOR_MAX_HEIGHT),
        LOADING_INDICATOR_MIN_HEIGHT
      )}px)`;
    }, TRANSITION_DURATION_MS);

    const handleTouchEndEvent = async (touchEndEvent: TouchEvent) => {
      const filteredEventData = filterInvalidMoveEndEventData(touchEndEvent);
      if (!filteredEventData) {
        touchStartEventRef.current = null;
        return;
      }
      touchStartEventRef.current = null;
      const {scrollArea} = filteredEventData;
      try {
        setIsRefreshing(true);
        await Promise.all([delay(TRANSITION_DURATION_MS), queryClient.invalidateQueries()]);
      } finally {
        setIsRefreshing(false);
        scrollArea.style.transform = 'translateY(0px)';
      }
    };

    document.addEventListener('touchstart', handleTouchStartEvent);
    document.addEventListener('touchmove', handleTouchMoveEvent);
    document.addEventListener('touchend', handleTouchEndEvent);

    return () => {
      document.removeEventListener('touchstart', handleTouchStartEvent);
      document.removeEventListener('touchmove', handleTouchMoveEvent);
      document.removeEventListener('touchend', handleTouchEndEvent);
    };
  }, [isDisabled, queryClient]);

  return {isRefreshing, scrollAreaRef};
};
