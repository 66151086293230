import { LearningHistoryFilterItemFromJSON, LearningHistoryFilterItemToJSON, } from './LearningHistoryFilterItem';
/**
 * Check if a given object implements the GetLearningHistoryFilterItemsResponse interface.
 */
export function instanceOfGetLearningHistoryFilterItemsResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "learningHistoryFilterItems" in value;
    return isInstance;
}
export function GetLearningHistoryFilterItemsResponseFromJSON(json) {
    return GetLearningHistoryFilterItemsResponseFromJSONTyped(json, false);
}
export function GetLearningHistoryFilterItemsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'learningHistoryFilterItems': (json['learningHistoryFilterItems'].map(LearningHistoryFilterItemFromJSON)),
    };
}
export function GetLearningHistoryFilterItemsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'learningHistoryFilterItems': (value.learningHistoryFilterItems.map(LearningHistoryFilterItemToJSON)),
    };
}
