import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {COLOR_SANTA_BD} from '@riiid/design-system';
import {Alert, Button, Typography, useToastContext} from '@santa-web/service-ui';
import {DimWithEscape} from '@app/components';
import HorizontalPicker from '@app/components/HorizontalPicker';
import {useDialogContext} from '@app/contexts/DialogContext';
import {OnboardingInformationArea, OnboardingLayout, OnboardingQuestionViewCommonProps} from '@app/features/onboarding';

type TargetScoreChangingViewProps = OnboardingQuestionViewCommonProps & {
  currentEstimatedScore?: number;
  defaultTargetScore: number;
  targetScoreItems: Array<{value: number; label?: string}>;
  onPick: (targetScore: number) => Promise<void>;
};

const TargetScoreChangingView = ({
  isLoading = false,
  currentEstimatedScore,
  defaultTargetScore,
  targetScoreItems,
  onPick,
  onBackButtonClick,
}: TargetScoreChangingViewProps) => {
  const {t} = useTranslation();
  const [targetScore, setTargetScore] = React.useState(defaultTargetScore);

  const {openToast, toastContainer} = useToastContext();
  const {openDialog, closeDialog} = useDialogContext();

  const handleBackButtonClick = () => {
    if (targetScore !== defaultTargetScore) {
      openDialog(
        <DimWithEscape onEscape={closeDialog}>
          <Alert
            title={t('component_change_alert_title')}
            description={t('component_change_alert_description')}
            negativeLabel={t('dict_cancel')}
            onNegativeClick={closeDialog}
            positiveLabel={t('dict_exit')}
            onPositiveClick={() => {
              onBackButtonClick();
              closeDialog();
            }}
          />
        </DimWithEscape>
      );
    } else {
      onBackButtonClick();
    }
  };

  const handlePickScore = async () => {
    await onPick(targetScore);
    openToast({colorVariant: 'gray', message: t('page_target_score_change_toast')});
  };

  return (
    <OnboardingLayout
      isNarrow
      onBackButtonClick={handleBackButtonClick}
      bottomArea={
        <Button
          isLoading={isLoading}
          isDisabled={targetScore === defaultTargetScore}
          variant="bottom-fix"
          colorVariant="brand"
          isFullWidth
          onClick={handlePickScore}>
          {t('page_target_score_change_button')}
        </Button>
      }>
      <div
        css={css`
          height: 100%;
          display: flex;
          flex-direction: column;
        `}>
        <OnboardingInformationArea
          shouldShowAiImage
          subTitle={t('dict_target_score')}
          title={t('page_target_score_change_title')}
          description={t('page_target_score_change_description')}
        />
        <Typography
          css={css`
            margin-top: 8px;
          `}
          component="p"
          variant="caption1"
          color={COLOR_SANTA_BD}>
          {t('page_target_score_change_prediction_score', {score: currentEstimatedScore ?? '--'})}
        </Typography>
        <div
          css={css`
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;

            width: 100vw;
            margin-left: 50%;
            transform: translateX(-50%);
          `}>
          <HorizontalPicker
            css={css`
              overflow-x: hidden;
            `}
            defaultValue={targetScore}
            items={targetScoreItems}
            onPick={setTargetScore}
          />
        </div>
      </div>
      {toastContainer}
    </OnboardingLayout>
  );
};

export default TargetScoreChangingView;
export type {TargetScoreChangingViewProps};
TargetScoreChangingView.displayName = 'TargetScoreChangingView';
