/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
/**
 * Check if a given object implements the LearningStatisticsCountInfo interface.
 */
export function instanceOfLearningStatisticsCountInfo(value) {
    let isInstance = true;
    isInstance = isInstance && "completedLearningCycle" in value;
    isInstance = isInstance && "question" in value;
    isInstance = isInstance && "vocabulary" in value;
    return isInstance;
}
export function LearningStatisticsCountInfoFromJSON(json) {
    return LearningStatisticsCountInfoFromJSONTyped(json, false);
}
export function LearningStatisticsCountInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'completedLearningCycle': json['completedLearningCycle'],
        'lesson': !exists(json, 'lesson') ? undefined : json['lesson'],
        'mockExam': !exists(json, 'mockExam') ? undefined : json['mockExam'],
        'question': json['question'],
        'vocabulary': json['vocabulary'],
    };
}
export function LearningStatisticsCountInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'completedLearningCycle': value.completedLearningCycle,
        'lesson': value.lesson,
        'mockExam': value.mockExam,
        'question': value.question,
        'vocabulary': value.vocabulary,
    };
}
