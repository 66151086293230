import React from 'react';
import {css} from '@emotion/react';
import {BREAKPOINT_MEDIUM, COLOR_SANTA_A} from '@riiid/design-system';

type OfferGroupOrderSectionOwnProps = {
  hasTwoBackgroundColor?: boolean;
  backgroundBreakpoint?: number;
  children: React.ReactNode;
};
type OfferGroupOrderSectionProps = OfferGroupOrderSectionOwnProps & React.HTMLAttributes<HTMLElement>;

const OfferGroupOrderSection = ({
  hasTwoBackgroundColor = false,
  backgroundBreakpoint = 200,
  children,
  ...rest
}: OfferGroupOrderSectionProps) => {
  return (
    <section
      css={[
        css`
          width: 100%;
          padding: 40px 20px;

          display: flex;
          justify-content: center;
          background-color: ${COLOR_SANTA_A};

          @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
            padding: 40px;
          }
        `,
        hasTwoBackgroundColor &&
          css`
            background: linear-gradient(
              180deg,
              transparent ${backgroundBreakpoint}px,
              ${COLOR_SANTA_A} ${backgroundBreakpoint}px
            );
          `,
      ]}
      {...rest}>
      <div
        css={css`
          width: 100%;
          max-width: ${BREAKPOINT_MEDIUM};
        `}>
        {children}
      </div>
    </section>
  );
};

export default OfferGroupOrderSection;
export type {OfferGroupOrderSectionProps};
OfferGroupOrderSection.displayName = 'OfferGroupOrderSection';
