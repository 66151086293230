import React from 'react';
import {css} from '@emotion/react';
import {COLOR_SANTA_C} from '@riiid/design-system';

import {QuestionState} from '@santa-web/gen/open-api/content-learning-service';
import {QuestionSet} from '@santa-web/gen/open-api/content/models/QuestionSet';
import {ObjectiveQuestionPage, SingleQuestionTitle, useFloatingChatButtonList} from '@santa-web/service-ui';
import {getQuestionTitle} from '@app/components/ContentView/utilities';
import {VirtualExamFloatingChatButtonBox} from '@app/components/virtual-exam';
import {isEmptySnippet} from '@app/facade/snippet';
import useFloatingAreaHeight from '@app/hooks/useFloatingAreaHeight';
import PassageListView from '@app/test-preparation/components/PassageListView';
import QuestionView from '@app/test-preparation/components/QuestionView';
import SingleColumnLayout from '@app/test-preparation/components/SingleColumnLayout';

export interface VirtualExamPart3To4ViewProps {
  contentId: string;
  questionSet: QuestionSet;
  questionStates: QuestionState[];
  questionStartNumber: number;
  onQuestionAnswerStateChange: (index: number) => (questionState: QuestionState) => void | Promise<void>;
}

const VirtualExamPart3To4View = ({
  contentId,
  questionSet,
  questionStates,
  questionStartNumber: startQuestionIndex,
  onQuestionAnswerStateChange,
}: VirtualExamPart3To4ViewProps) => {
  const questions = questionSet.questions;

  const passageBodies = questionSet.passages.map(passage => passage.body);
  const passageListView = !passageBodies.every(isEmptySnippet) && <PassageListView passages={questionSet.passages} />;

  const {floatingChatButtonList} = useFloatingChatButtonList();
  const floatingAreaHeight = useFloatingAreaHeight();

  return (
    <SingleColumnLayout>
      <div
        css={css`
          flex: 1;
          margin-bottom: ${floatingAreaHeight}px;
        `}>
        <div>
          {/* Description is removed; Because it's hard to maintain correct numbers in content file */}
          <div
            css={css`
              & > *:not(:last-child)::after {
                display: flex;
                width: 100%;
                height: 12px;
                background: ${COLOR_SANTA_C};
                content: '';
              }
            `}>
            {passageListView && (
              <div>
                <ObjectiveQuestionPage passages={passageListView} />
              </div>
            )}
            {questions.map((question, questionIndex) => (
              <div key={questionIndex}>
                <QuestionView
                  isCompleted={false}
                  contentId={contentId}
                  questionTitle={<SingleQuestionTitle content={getQuestionTitle(startQuestionIndex + questionIndex)} />}
                  question={question}
                  questionState={questionStates[questionIndex]}
                  onChange={onQuestionAnswerStateChange(questionIndex)}
                  canSelectUnsolvableQuestion={false}
                />
              </div>
            ))}
          </div>
        </div>
      </div>
      <VirtualExamFloatingChatButtonBox>{floatingChatButtonList}</VirtualExamFloatingChatButtonBox>
    </SingleColumnLayout>
  );
};

export default React.memo(VirtualExamPart3To4View);
