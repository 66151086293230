import React from 'react';
import {css} from '@emotion/react';
import {COLOR_SANTA_G, COLOR_SANTA_L, COLOR_SANTA_BD} from '@riiid/design-system';
import TogglebtndownIcon from '@riiid/design-system/icons/Togglebtndown';

export interface SelfProgressBarProps extends React.ComponentPropsWithoutRef<'div'> {
  isDisabled?: boolean;
  totalCount: number;
  completedCount: number;
  unnecessaryCount?: number;
}

const SelfProgressBar = React.forwardRef<HTMLDivElement, SelfProgressBarProps>(
  ({totalCount, completedCount, unnecessaryCount, isDisabled, ...props}, ref) => {
    return (
      <div
        css={css`
          position: relative;
        `}
        ref={ref}
        {...props}>
        {unnecessaryCount ? (
          <div
            data-testid="SelfProgressBar-UnnecessaryChevron"
            css={css`
              display: flex;
              position: absolute;
              right: ${(unnecessaryCount / totalCount) * 100}%;
              transform: translateX(50%);
            `}>
            <TogglebtndownIcon
              css={css`
                width: 16px;
                height: 16px;
                color: ${COLOR_SANTA_L};
              `}
              type="line"
            />
          </div>
        ) : null}
        <div
          css={css`
            padding-top: 16px;
          `}>
          <div
            css={css`
              width: 100%;
              position: relative;
              &,
              & > * {
                height: 4px;
                border-radius: 100px;
              }
            `}>
            <div
              css={css`
                opacity: 0.2;
                background-color: ${COLOR_SANTA_BD};
              `}
            />
            {unnecessaryCount ? (
              <div
                data-testid="SelfProgressBar-UnnecessaryRail"
                css={[
                  css`
                    position: absolute;
                    top: 0;
                    right: 0;
                    max-width: 100%;
                    background-color: ${COLOR_SANTA_L};
                    opacity: 0.2;
                  `,
                  totalCount === unnecessaryCount + completedCount
                    ? css`
                        width: 100%;
                      `
                    : css`
                        width: ${(unnecessaryCount / totalCount) * 100}%;
                      `,
                ]}
              />
            ) : null}
            <div
              data-testid="SelfProgressBar-CompletedRail"
              css={css`
                position: absolute;
                top: 0;
                left: 0;
                width: ${(completedCount / totalCount) * 100}%;
                max-width: 100%;
                background-color: ${isDisabled ? COLOR_SANTA_G : COLOR_SANTA_BD};
              `}
            />
          </div>
        </div>
      </div>
    );
  }
);

export default React.memo(SelfProgressBar);
