import React from 'react';

import useIntersectionObserver from '@app/hooks/useIntersectionObserver';

export const useQuestionTitleListGradient = () => {
  const questionTitleListLayoutRef = React.useRef<HTMLDivElement>(null);
  const questionTitleFirstItemRef = React.useRef<HTMLButtonElement>(null);
  const questionTitleLastItemRef = React.useRef<HTMLButtonElement>(null);
  const [isLeftGradientVisible, setIsLeftGradientVisible] = React.useState(false);
  const [isRightGradientVisible, setIsRightGradientVisible] = React.useState(true);
  useIntersectionObserver({
    root: questionTitleListLayoutRef,
    target: questionTitleFirstItemRef,
    onIntersect: () => setIsLeftGradientVisible(false),
    offIntersect: () => setIsLeftGradientVisible(true),
  });
  useIntersectionObserver({
    root: questionTitleListLayoutRef,
    target: questionTitleLastItemRef,
    onIntersect: () => setIsRightGradientVisible(false),
    offIntersect: () => setIsRightGradientVisible(true),
  });

  return {
    questionTitleListLayoutRef,
    questionTitleFirstItemRef,
    questionTitleLastItemRef,
    isLeftGradientVisible,
    isRightGradientVisible,
  };
};
