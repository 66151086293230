import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {COLOR_SANTA_BC} from '@riiid/design-system';
import produce from 'immer';
import {atom, useAtomValue} from 'jotai';

import {QuestionStateObjective} from '@santa-web/gen/open-api/content-learning-service';
import {Objective} from '@santa-web/gen/open-api/content/models/Objective';
import {Typography} from '@santa-web/service-ui';
import config from '@app/config';
import ChoiceRateInformationContainer from '@app/experiments/contents-level-choice-rate/containers/ChoiceRateInformationContainer';
import useIsContentLevelChoiceRateABTestTarget from '@app/experiments/contents-level-choice-rate/hooks/useIsContentLevelChoiceRateABTestTarget';
import * as ObjectiveFacade from '@app/facade/question-state-objective';
import {isEmptySnippet} from '@app/facade/snippet';
import {testprepFeatureAtom} from '@app/test-preparation/atoms/testprep-feature';
import QaButton from '@app/test-preparation/components/QaButton';
import SnippetView from '@app/test-preparation/components/SnippetView';

export const highlightCorrectChoiceAtom = atom(config.env !== 'production');
highlightCorrectChoiceAtom.debugLabel = 'highlightCorrectChoice';

interface Props {
  isCompleted?: boolean;
  isDisabled?: boolean;
  contentId: string;
  objective: Objective;
  questionStateObjective?: QuestionStateObjective | null;
  onChange?(objectiveState: QuestionStateObjective): void;
  canSelectUnsolvableQuestion: boolean;
  onInteract(payload: {userChoice: number}): void;
  snippetViewId: string;
  questionIndex?: number;
}

const ObjectiveView = React.forwardRef<HTMLDivElement, Props>((props, ref): JSX.Element => {
  const {
    isCompleted,
    isDisabled,
    objective,
    questionStateObjective,
    onChange,
    canSelectUnsolvableQuestion,
    onInteract,
    snippetViewId,
    questionIndex,
  } = props;
  const {choices, choiceTranslations} = objective;
  const {t} = useTranslation();
  const choiceIconContents = Array(choices.length)
    .fill(null)
    .map((_, idx) => String.fromCharCode('A'.charCodeAt(0) + idx));
  const highlightCorrectChoice = useAtomValue(highlightCorrectChoiceAtom);
  const testprepFeature = useAtomValue(testprepFeatureAtom);

  const isUnsolvableQuestionSelected = ObjectiveFacade.getIsSelected(questionStateObjective, -1);

  const isUnsolvableQuestionVisible = isCompleted ? isUnsolvableQuestionSelected : canSelectUnsolvableQuestion;

  const isChoiceRateInformationAvailable = useIsContentLevelChoiceRateABTestTarget();

  const handleQAButtonClick = useCallback(
    (index: number) => {
      return () => {
        if (questionStateObjective == null || isCompleted || isDisabled) return;

        onInteract({userChoice: index});

        onChange?.(
          produce(questionStateObjective, draft => {
            ObjectiveFacade.choiceAnswer(draft, index);
          })
        );
      };
    },
    [isCompleted, isDisabled, onChange, onInteract, questionStateObjective]
  );

  React.useEffect(() => {
    if (!questionStateObjective || isCompleted || canSelectUnsolvableQuestion || !isUnsolvableQuestionSelected) return;

    onChange?.(
      produce(questionStateObjective, draft => {
        ObjectiveFacade.emptyAnswer(draft);
      })
    );
  }, [canSelectUnsolvableQuestion, isCompleted, isUnsolvableQuestionSelected, onChange, questionStateObjective]);

  return (
    <div ref={ref}>
      {choices.map((choice, index) => {
        const choiceTranslation = choiceTranslations[index];
        const isCorrectChoice = objective.oneOfAnswers.andAnswers?.includes(index);

        const content = (
          <div
            css={
              highlightCorrectChoice &&
              isCorrectChoice &&
              css`
                text-shadow: ${COLOR_SANTA_BC} 0 0 10px;
              `
            }>
            <SnippetView snippet={choice} id={`${snippetViewId}.${index}`} />
          </div>
        );
        const iconContent = choiceIconContents[index];
        const isErased = ObjectiveFacade.getIsEliminated(questionStateObjective, index);
        const isSelected = ObjectiveFacade.getIsSelected(questionStateObjective, index);
        const isCorrect =
          (isSelected && ObjectiveFacade.getIsCorrect(questionStateObjective, objective)) ||
          (!isSelected && objective.oneOfAnswers.andAnswers?.includes(index));

        const translation = isCompleted && !isEmptySnippet(choiceTranslation) && (
          <SnippetView snippet={choiceTranslation} />
        );

        const handleEraseClick = (e: React.MouseEvent) => {
          e.stopPropagation();

          if (questionStateObjective == null || isCompleted || isDisabled) return;

          onChange?.(
            produce(questionStateObjective, draft => {
              ObjectiveFacade.eliminateChoice(draft, index);
            })
          );
        };

        return (
          <QaButton
            key={index}
            content={content}
            subContent={
              isChoiceRateInformationAvailable && (
                <ChoiceRateInformationContainer questionIndex={questionIndex ?? 0} choiceIndex={index} />
              )
            }
            iconContent={iconContent}
            isCompleted={isCompleted}
            isCorrect={isCorrect}
            isDisabled={isDisabled}
            isErased={isErased}
            isSelected={isSelected}
            translation={translation}
            onClick={handleQAButtonClick(index)}
            onEraseClick={handleEraseClick}
            tutorQuestionButtonProps={
              testprepFeature.qaButton?.tutorQuestionButton && isSelected && isCompleted
                ? {
                    onClick: () =>
                      testprepFeature.qaButton?.tutorQuestionButton?.handleClick?.({
                        hasMultipleQuestions: questionIndex != null,
                        isCorrect,
                        questionIndex: questionIndex ?? 0,
                        choiceIndex: index,
                        choices: choiceIconContents,
                      }),
                    isDisabled: testprepFeature.qaButton.tutorQuestionButton.clickedQuestionIndices.includes(
                      questionIndex ?? 0
                    ),
                  }
                : undefined
            }
          />
        );
      })}
      {isUnsolvableQuestionVisible && (
        <QaButton
          data-is-unsolvable="true"
          content={<Typography variant="body3">{t('toeic_question_view_unsolvable_question')}</Typography>}
          iconContent="?"
          isCompleted={isCompleted}
          isCorrect={false}
          isDisabled={isDisabled}
          isErasable={false}
          isSelected={isUnsolvableQuestionSelected}
          onClick={handleQAButtonClick(-1)}
        />
      )}
    </div>
  );
});

export default React.memo(ObjectiveView);
