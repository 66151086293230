import Empty from './Empty';
import List from './List';
import Skeleton from './Skeleton';

export type {OfferGroupOrderListPageViewProps} from './List';
export type {OfferGroupOrderListPageEmptyViewProps} from './Empty';
export type {OfferGroupOrderListPageSkeletonViewProps} from './Skeleton';

export default {
  List: List,
  Empty: Empty,
  Skeleton: Skeleton,
};
