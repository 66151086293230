import {css} from '@emotion/react';
import {COLOR_SANTA_A, COLOR_SANTA_J} from '@riiid/design-system';
import {Table, TableProps, Typography} from '@santa-web/service-ui';
import {ScoreInfo, ScoreRowClickHandler} from '@app/features/preferences/types';
import ScoreTableBody from './ScoreTableBody';
import ScoreTableEmpty from './ScoreTableEmptyBody';
import ScoreTableHeader from './ScoreTableHeader';
import ScoreTableSkeletonBody from './ScoreTableSkeletonBody';

type ExamScoreTableProps = {
  /**
   * 해당 도메인의 시험과목 리스트
   * ex. TOEIC인 경우 ['LC', 'RC']
   */
  examParts: string[];
  scores: ScoreInfo[];
  onRowClick?: ScoreRowClickHandler;
  isLoading?: boolean;
  caption?: string;
} & Omit<TableProps, 'children'>;

const ExamScoreTable = ({examParts, scores, onRowClick, isLoading, caption, ...tableProps}: ExamScoreTableProps) => {
  const isEmpty = scores.length === 0;
  const totalColumns = examParts.length + 2; // 시험 과목 갯수(examParts.length) + 2(시험일, total)

  return (
    <Table
      {...tableProps}
      css={css`
        width: 100%;
        table-layout: fixed;
        border-collapse: separate;
        border-spacing: 0px 8px;
      `}>
      {caption && (
        <caption
          css={css`
            padding: 20px 0px 32px;
            background-color: ${COLOR_SANTA_A};
            position: sticky;
            top: 0px;
          `}>
          <Typography
            css={css`
              text-align: left;
            `}
            variant="body3"
            color={COLOR_SANTA_J}
            fontWeight="bold">
            {caption}
          </Typography>
        </caption>
      )}
      <ScoreTableHeader examParts={examParts} stickyTop={caption ? 72 : 0} />
      {isLoading ? (
        <ScoreTableSkeletonBody colspan={totalColumns} />
      ) : isEmpty ? (
        <ScoreTableEmpty colSpan={totalColumns} />
      ) : (
        <ScoreTableBody scores={scores} onRowClick={onRowClick} />
      )}
    </Table>
  );
};

export default ExamScoreTable;
export type {ExamScoreTableProps};
ExamScoreTable.displayName = 'ExamScoreTable';
