/* tslint:disable */
/* eslint-disable */
/**
 * - **NOT_EXISTS**: 해당 이메일 계정이 존재하지 않는 경우
 * - **WRONG_PASSWORD**: 해당 이메일 계정은 있으나 비밀번호가 틀린 경우
 * @export
 */
export const LoginWithEmailPasswordBadRequestErrorCase = {
    NOT_EXISTS: 'NOT_EXISTS',
    WRONG_PASSWORD: 'WRONG_PASSWORD'
};
export function LoginWithEmailPasswordBadRequestErrorCaseFromJSON(json) {
    return LoginWithEmailPasswordBadRequestErrorCaseFromJSONTyped(json, false);
}
export function LoginWithEmailPasswordBadRequestErrorCaseFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function LoginWithEmailPasswordBadRequestErrorCaseToJSON(value) {
    return value;
}
