import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {COLOR_SANTA_G} from '@riiid/design-system';
import WarningIcon from '@riiid/design-system/icons/Warning';

import {Typography} from '@santa-web/service-ui';

export type EmptyContentNoticeProps = React.ComponentPropsWithoutRef<'div'>;

const EmptyContentNotice = React.forwardRef<HTMLDivElement, EmptyContentNoticeProps>(({children, ...props}, ref) => {
  const {t} = useTranslation();
  return (
    <div
      css={css`
        padding: 40px 0;
        display: flex;
        flex-direction: column;
        align-items: center;
      `}
      ref={ref}
      {...props}>
      <WarningIcon width="32" height="32" type="solid" color={COLOR_SANTA_G} />
      <Typography
        css={css`
          margin-top: 16px;
        `}
        variant="caption1"
        fontWeight="bold"
        color={COLOR_SANTA_G}>
        {t('learning_cell_report_empty_content_notice')}
      </Typography>
    </div>
  );
});

export default React.memo(EmptyContentNotice);
