import React from 'react';
import {css} from '@emotion/react';
import {COLOR_SANTA_I, COLOR_SANTA_DD} from '@riiid/design-system';

import {Typography, TopNavBar, TopNavBarProps, formatTimerSeconds, useIsGteMediumScreen} from '@santa-web/service-ui';
import DropdownToggleList from '@app/components/DropdownToggleList';
import {useVirtualExamPartLabels} from '@app/new-structure/adapters/virtual-exam';
import {VirtualExamPart} from '@app/new-structure/entities/base/parts';

export interface VirtualExamTopNavBarProps extends Omit<TopNavBarProps, 'rightArea'> {
  totalSeconds: number;
  totalLeftSeconds: number;
  partLeftSeconds: number;
  isTimerWarning?: boolean;
  partPrefix: string;
  selectedPart: VirtualExamPart;
  onPartSelect(part: VirtualExamPart): void;
  progressRatio: number;
}

const VirtualExamTopNavBar = React.forwardRef<HTMLDivElement, VirtualExamTopNavBarProps>(
  (
    {
      totalSeconds,
      totalLeftSeconds,
      partLeftSeconds,
      isTimerWarning,
      partPrefix,
      selectedPart,
      onPartSelect,
      progressRatio,
      ...props
    },
    ref
  ) => {
    const [isOpened, setIsOpened] = React.useState(false);
    const isGteMediumScreen = useIsGteMediumScreen();
    const dualTimer = React.useMemo(() => {
      const timerColor = !isTimerWarning ? COLOR_SANTA_I : COLOR_SANTA_DD;
      if (!isGteMediumScreen) {
        return (
          <div
            css={css`
              display: flex;
              flex-direction: column;
              text-align: right;
            `}>
            <Typography variant="caption2" fontWeight="bold" color={timerColor}>
              {formatTimerSeconds(totalLeftSeconds)}
            </Typography>
            <Typography variant="caption2" fontWeight="regular" color={timerColor}>
              {partPrefix} {formatTimerSeconds(partLeftSeconds)}
            </Typography>
          </div>
        );
      }

      return (
        <div
          css={css`
            display: flex;
            text-align: right;
          `}>
          <Typography variant="body3" fontWeight="regular" color={timerColor}>
            {partPrefix} {formatTimerSeconds(partLeftSeconds)}
          </Typography>
          <Typography
            css={css`
              margin: 0 12px;
            `}
            variant="body3"
            fontWeight="bold"
            color={timerColor}>
            |
          </Typography>
          <Typography variant="body3" fontWeight="bold" color={timerColor}>
            {formatTimerSeconds(totalLeftSeconds)}
          </Typography>
        </div>
      );
    }, [isGteMediumScreen, totalLeftSeconds, partLeftSeconds, isTimerWarning, partPrefix]);

    const virtualExamPartLabels = useVirtualExamPartLabels();
    const partOptions = React.useMemo(
      () =>
        Object.entries(virtualExamPartLabels).map(([key, value]) => ({label: value, value: key as VirtualExamPart})),
      [virtualExamPartLabels]
    );

    const selectedPartOption = React.useMemo(() => {
      return {
        value: selectedPart,
        label: virtualExamPartLabels[selectedPart],
      };
    }, [selectedPart, virtualExamPartLabels]);

    return (
      <TopNavBar
        progress={progressRatio}
        subContent={
          <DropdownToggleList
            options={partOptions}
            selectedOption={selectedPartOption}
            isOpened={isOpened}
            setIsOpened={setIsOpened}
            onValueSelect={onPartSelect}
          />
        }
        rightArea={dualTimer}
        ref={ref}
        {...props}
      />
    );
  }
);

export default React.memo(VirtualExamTopNavBar);
