import {useMutation} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';
import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {useLogout} from '@app/hooks/useLogout';

export const useWithdrawMutation = () => {
  const logout = useLogout('/');
  const {AuthService} = useAtomValue(santaOpenapiServicesAtom);
  return useMutation({
    mutationFn: async () => {
      await AuthService.withdraw({withdrawRequest: {reason: 'iOS withdrawal', reasonType: 'UNKNOWN'}});
    },
    onSuccess: () => {
      return logout();
    },
  });
};
