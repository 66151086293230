import {useAtomValue} from 'jotai';
import {learningStore} from '@app/test-preparation/atoms/stores';
import {useIsLearningCell} from '@app/test-preparation/hooks';

const useIsContentLevelChoiceRateAvailable = () => {
  const isLearningCell = useIsLearningCell();
  const isCompleted = useAtomValue(learningStore.cis.isCompletedAtom);
  const isDiagnosis = useAtomValue(learningStore.cell.isDiagnosisAtom);
  const learningDomain = useAtomValue(learningStore.content.domainAtom);
  const isToeicContent = learningDomain === 'TOEIC';

  return isToeicContent && isLearningCell && isCompleted && !isDiagnosis;
};

export default useIsContentLevelChoiceRateAvailable;
