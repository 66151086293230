import { ContentInteractionStateWithContentFromJSON, ContentInteractionStateWithContentToJSON, } from './ContentInteractionStateWithContent';
import { EstimatedTagProficiencyFromJSON, EstimatedTagProficiencyToJSON, } from './EstimatedTagProficiency';
import { ToeicVirtualExamReportCorrectStatFromJSON, ToeicVirtualExamReportCorrectStatToJSON, } from './ToeicVirtualExamReportCorrectStat';
/**
 * Check if a given object implements the ToeicVirtualExamReportWeakTagInfo interface.
 */
export function instanceOfToeicVirtualExamReportWeakTagInfo(value) {
    let isInstance = true;
    isInstance = isInstance && "contentInteractionStates" in value;
    isInstance = isInstance && "correctStat" in value;
    isInstance = isInstance && "tagProficiency" in value;
    return isInstance;
}
export function ToeicVirtualExamReportWeakTagInfoFromJSON(json) {
    return ToeicVirtualExamReportWeakTagInfoFromJSONTyped(json, false);
}
export function ToeicVirtualExamReportWeakTagInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'contentInteractionStates': (json['contentInteractionStates'].map(ContentInteractionStateWithContentFromJSON)),
        'correctStat': ToeicVirtualExamReportCorrectStatFromJSON(json['correctStat']),
        'tagProficiency': EstimatedTagProficiencyFromJSON(json['tagProficiency']),
    };
}
export function ToeicVirtualExamReportWeakTagInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'contentInteractionStates': (value.contentInteractionStates.map(ContentInteractionStateWithContentToJSON)),
        'correctStat': ToeicVirtualExamReportCorrectStatToJSON(value.correctStat),
        'tagProficiency': EstimatedTagProficiencyToJSON(value.tagProficiency),
    };
}
