/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { SelfLearningAllQuestionPermissionStatusFromJSON, SelfLearningAllQuestionPermissionStatusToJSON, } from './SelfLearningAllQuestionPermissionStatus';
/**
 * Check if a given object implements the SelfLearningAllQuestion interface.
 */
export function instanceOfSelfLearningAllQuestion(value) {
    let isInstance = true;
    isInstance = isInstance && "permissionStatus" in value;
    isInstance = isInstance && "progressRate" in value;
    isInstance = isInstance && "studiedContentCount" in value;
    isInstance = isInstance && "totalContentCount" in value;
    return isInstance;
}
export function SelfLearningAllQuestionFromJSON(json) {
    return SelfLearningAllQuestionFromJSONTyped(json, false);
}
export function SelfLearningAllQuestionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'permissionStatus': SelfLearningAllQuestionPermissionStatusFromJSON(json['permissionStatus']),
        'predictedCorrectPercent': !exists(json, 'predictedCorrectPercent') ? undefined : json['predictedCorrectPercent'],
        'progressRate': json['progressRate'],
        'studiedContentCount': json['studiedContentCount'],
        'totalContentCount': json['totalContentCount'],
    };
}
export function SelfLearningAllQuestionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'permissionStatus': SelfLearningAllQuestionPermissionStatusToJSON(value.permissionStatus),
        'predictedCorrectPercent': value.predictedCorrectPercent,
        'progressRate': value.progressRate,
        'studiedContentCount': value.studiedContentCount,
        'totalContentCount': value.totalContentCount,
    };
}
