import React from 'react';

import {usePushEvent} from '.';

interface MyPageCouponRegisterSuccessPayload extends Record<string, unknown> {
  coupon_template_id: number;
}

const useCouponEvents = () => {
  const pushEvent = usePushEvent();

  const couponEvents = React.useMemo(
    () => ({
      pushMyPageCouponRegisterSuccess: async (payload: MyPageCouponRegisterSuccessPayload) => {
        await pushEvent('myp_cpn_coupon_register_success', payload);
      },
    }),
    [pushEvent]
  );

  return couponEvents;
};

export default useCouponEvents;
