import { MarketingBannerListItemFromJSON, MarketingBannerListItemToJSON, } from './MarketingBannerListItem';
/**
 * Check if a given object implements the MarketingBannerList interface.
 */
export function instanceOfMarketingBannerList(value) {
    let isInstance = true;
    isInstance = isInstance && "delayMs" in value;
    isInstance = isInstance && "items" in value;
    return isInstance;
}
export function MarketingBannerListFromJSON(json) {
    return MarketingBannerListFromJSONTyped(json, false);
}
export function MarketingBannerListFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'delayMs': json['delayMs'],
        'items': (json['items'].map(MarketingBannerListItemFromJSON)),
    };
}
export function MarketingBannerListToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'delayMs': value.delayMs,
        'items': (value.items.map(MarketingBannerListItemToJSON)),
    };
}
