import {getStatesByCase} from '@app/facade/content-interaction-state';
import {VocaResultType} from '@app/pages/learning-cell/report/LearningCellReportContentsView';
import {IContentInteractionStateWithContent} from '@app/types/santa-service-protocol';

const getReportContentCardId = (index: number) => `report-content-card-${index}`;

const getFilteredCisIds = ({
  ciscs,
  isWrongOnly,
  vocaResultType,
}: {
  ciscs: Array<IContentInteractionStateWithContent>;
  isWrongOnly: boolean;
  vocaResultType: VocaResultType;
}) => {
  const cises = ciscs.map(({contentInteractionState: cis}) => cis);
  const hasVocaState = cises.some(cis => cis.elementInteractionStates[0].details.oneOfCase === 'VOCA');

  return ciscs
    .map(({contentInteractionState: cis}) => cis)
    .filter(cis => {
      const statesByCase = getStatesByCase(cis);

      // Voca가 포함됐다면 vocaResultType에 따라 필터링
      if (hasVocaState && vocaResultType === 'vocas') {
        return statesByCase.oneOfCase === 'VOCA';
      }
      // Voca가 포함됐지만 vocaResultType이 questions인 경우
      else if (hasVocaState && vocaResultType === 'questions') {
        // Question만 필터링
        if (statesByCase.oneOfCase !== 'QUESTION') {
          return false;
        }

        // 오답 필터링일 경우 오답만 필터링
        if (isWrongOnly) {
          return !statesByCase.states.every(state => state.answerState === 'CORRECT');
        }

        return true;
      }
      // Voca가 포함되지 않았을 경우 isWrongOnly를 통해서만 필터링
      else if (!hasVocaState) {
        // 오답 필터링일 경우 Question 중 오답만 필터링
        if (
          isWrongOnly &&
          (statesByCase.oneOfCase !== 'QUESTION' || statesByCase.states.every(state => state.answerState === 'CORRECT'))
        ) {
          return false;
        }

        return true;
      }
    })
    .map(({id}) => id);
};

export {getReportContentCardId, getFilteredCisIds};
