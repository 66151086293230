/* tslint:disable */
/* eslint-disable */
/**
 * 하이라이트 색상.
 * @export
 */
export const HighlightColor = {
    BRAND: 'BRAND',
    RIIID_RED: 'RIIID_RED',
    RIIID_PURPLE: 'RIIID_PURPLE',
    RIIID_YELLOW: 'RIIID_YELLOW',
    RIIID_GREEN: 'RIIID_GREEN'
};
export function HighlightColorFromJSON(json) {
    return HighlightColorFromJSONTyped(json, false);
}
export function HighlightColorFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function HighlightColorToJSON(value) {
    return value;
}
