import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/mousewheel';

import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {BREAKPOINT_MEDIUM} from '@riiid/design-system';
import {FreeMode, Mousewheel} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';
import {Button, useIsGteMediumScreen} from '@santa-web/service-ui';
import {
  DomainListItem,
  DomainListItemProps,
  OnboardingInformationArea,
  OnboardingLayout,
  OnboardingQuestionViewCommonProps,
} from '@app/features/onboarding';

type Domain = Omit<DomainListItemProps, 'isSelected' | 'onClick'> & {
  id: number;
};

type DomainSelectionViewProps = OnboardingQuestionViewCommonProps & {
  currentDomainId?: number;
  domains: Array<Domain>;
  onDomainSelect: (domainId: number) => void;
  shouldBlockSameDomain?: boolean;
};

const DomainSelectionView = (props: DomainSelectionViewProps) => {
  return props.domains.length > 1 ? <MultiView {...props} /> : <SingleView {...props} />;
};

const MultiView = ({
  isLoading = false,
  isNarrow,
  currentDomainId,
  domains,
  onBackButtonClick,
  onDomainSelect,
  shouldBlockSameDomain,
}: DomainSelectionViewProps) => {
  const {t} = useTranslation();
  const isGteMediumScreen = useIsGteMediumScreen();
  const shouldListPaginated = !isGteMediumScreen;
  const [selectedDomainId, setSelectedDomainId] = React.useState<undefined | number>(currentDomainId);

  const onButtonClick = () => {
    if (selectedDomainId) {
      onDomainSelect(selectedDomainId);
    }
  };

  return (
    <OnboardingLayout
      isNarrow={isNarrow}
      onBackButtonClick={onBackButtonClick}
      bottomArea={
        <Button
          isLoading={isLoading}
          colorVariant="brand"
          variant="bottom-fix"
          isDisabled={!selectedDomainId || (shouldBlockSameDomain && selectedDomainId === currentDomainId)}
          onClick={onButtonClick}>
          {t('page_onboarding_domain_selection_complete_button')}
        </Button>
      }>
      <div
        css={css`
          height: 100%;
          display: flex;
          flex-direction: column;
        `}>
        <OnboardingInformationArea
          isNarrow
          subTitle={t('page_onboarding_domain_selection_sub_title')}
          title={t('page_onboarding_domain_selection_title')}
          description={t('page_onboarding_domain_selection_description')}
        />
        <div
          role="radiogroup"
          css={css`
            flex: 1;
            margin: 24px -20px -20px -20px;

            .swiper {
              height: 100%;
            }

            .swiper-slide {
              width: calc(100vw - 80px);
              height: calc(100% - 20px);
            }

            @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
              margin: 80px -20px 0;

              .swiper {
                height: initial;
              }

              .swiper-slide {
                margin-left: 20px;
                width: calc(100% - 40px);
              }
            }
          `}>
          <Swiper
            enabled={shouldListPaginated}
            slidesPerView="auto"
            direction={shouldListPaginated ? 'horizontal' : 'vertical'}
            centeredSlides={shouldListPaginated}
            initialSlide={currentDomainId ? domains.findIndex(({id}) => id === currentDomainId) : 0}
            modules={[FreeMode, Mousewheel]}
            freeMode={{enabled: true, sticky: true}}
            mousewheel={{enabled: true}}
            spaceBetween={20}>
            {domains.map(({id, ...domain}) => (
              <SwiperSlide key={id}>
                <DomainListItem
                  {...domain}
                  onClick={() => setSelectedDomainId(id)}
                  isOnLearning={id === currentDomainId}
                  isSelected={id === selectedDomainId}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      </div>
    </OnboardingLayout>
  );
};

const SingleView = ({isLoading = false, domains, onBackButtonClick, onDomainSelect}: DomainSelectionViewProps) => {
  const {t} = useTranslation();
  const {id, ...domain} = domains[0];

  const onButtonClick = () => {
    onDomainSelect(id);
  };

  return (
    <OnboardingLayout
      onBackButtonClick={onBackButtonClick}
      bottomArea={
        <Button isLoading={isLoading} colorVariant="brand" variant="bottom-fix" onClick={onButtonClick}>
          {t('page_onboarding_domain_confirm_button')}
        </Button>
      }>
      <div
        css={css`
          height: 100%;
          display: flex;
          flex-direction: column;
          gap: 24px;
          @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
            gap: 80px;
          }
        `}>
        <OnboardingInformationArea
          title={t('page_onboarding_domain_confirm_title', {domain: domain.name})}
          description={t('page_onboarding_domain_confirm_description', {domain: domain.name})}
        />
        <DomainListItem {...domain} shouldHideCheckbox />
      </div>
    </OnboardingLayout>
  );
};

export default DomainSelectionView;
export type {DomainSelectionViewProps};
DomainSelectionView.displayName = 'DomainSelectionView';
