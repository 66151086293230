import {Decimal} from 'decimal.js';

export const getFormattedPercent = (percent: Decimal, withPercent = true): string => {
  return percent.mul(new Decimal(100)).toNumber() + (withPercent ? '%' : '');
};

export const ceil = (price: Decimal, fromN: number): Decimal => {
  const unit = new Decimal(10).pow(fromN);
  return price.div(unit).ceil().mul(unit);
};
