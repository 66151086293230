import {atom} from 'jotai';

import {cisRepository} from '@app/test-preparation/atoms/repositories';
import {cisService} from '@app/test-preparation/atoms/services';
import {CisesOverflowError} from '@app/test-preparation/atoms/services/cis/errors/cises-overflow';
import {handleUnexpectedError} from '@app/test-preparation/atoms/services/shared/errors/unexpected';

const loadNextCisesAtom = atom(null, async (get, set) => {
  const createCises = await get(cisRepository.createCisesAtom);

  const learningCellId = get(cisService.learningCellIdAtom);
  const cises = get(cisService.cisesAtom);
  const contentDataMap = get(cisService.contentDataMapAtom);

  try {
    const ciscs = await createCises(learningCellId);

    const nextContentDataMap = ciscs.reduce(
      (contentData, {contentUrl}, index) => ({
        ...contentData,
        [cises.length + index]: {
          url: contentUrl,
        },
      }),
      contentDataMap
    );

    set(cisService.contentDataMapAtom, nextContentDataMap);

    const createdCises = ciscs.map(({contentInteractionState: cis}) => cis);
    const nextCises = cises.concat(createdCises);

    set(cisService.cisesAtom, nextCises);
  } catch (error) {
    if (CisesOverflowError.shouldThrow(error)) {
      throw new CisesOverflowError(error);
    }

    handleUnexpectedError(error);
  }
});

export {loadNextCisesAtom};
