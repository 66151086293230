import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {COLOR_SANTA_O, COLOR_SANTA_K, COLOR_SANTA_BD, COLOR_SANTA_DD, COLOR_SANTA_J} from '@riiid/design-system';
import CloseIcon from '@riiid/design-system/icons/Close';
import {Cell, Pie, PieChart} from 'recharts';

import {Typography} from '@santa-web/service-ui';
import DonutResultChartLabel from '@app/components/DonutResultChart/DonutResultChartLabel';
import {DonutResultChartType} from '@app/components/DonutResultChart/types';

const getTimeChartData = (elapsedTime: number | null, recommendedTime: number | null) => {
  if (elapsedTime === null || recommendedTime === null) {
    return {correctValue: 0, diffValue: 0, backgroundValue: 1};
  }

  if (recommendedTime === 0) {
    return {correctValue: 1, diffValue: 1, backgroundValue: 0};
  }

  const clamp = (x: number, min: number, max: number) => Math.max(min, Math.min(max, x));
  const correctValue = clamp(elapsedTime, 0, recommendedTime);
  const diffTime = elapsedTime - recommendedTime;
  const diffValue = clamp(diffTime, -recommendedTime, recommendedTime);
  const backgroundValue = clamp(recommendedTime - diffTime, 0, recommendedTime);

  return {
    correctValue,
    diffValue,
    backgroundValue,
  };
};

const getCountChartData = (correctValue: number, totalValue: number) => {
  const diffValue = 0;
  const backgroundValue = totalValue - correctValue;
  return {correctValue, diffValue, backgroundValue};
};

export interface DonutResultChartProps {
  chartSize?: number;
  chartType: DonutResultChartType;
  value: {
    total: number;
    correct: number;
  } | null;
}

const DonutResultChart = React.forwardRef<HTMLDivElement, DonutResultChartProps>(
  ({chartSize = 100, chartType, value, ...props}, ref) => {
    const {t} = useTranslation();
    const {
      correctValue: _correctValue,
      diffValue: _diffValue,
      backgroundValue: _backgroundValue,
    } = chartType === 'time'
      ? getTimeChartData(value?.correct ?? null, value?.total ?? null)
      : getCountChartData(value?.correct ?? 0, value?.total ?? 0);

    const data: Array<{name: string; value: number; color: string; opacity?: number}> = [
      {name: 'correctValue', value: _correctValue, color: COLOR_SANTA_BD},
    ];
    if (Math.abs(_diffValue) > 0) {
      data.push({
        name: 'diffValue',
        value: Math.abs(_diffValue),
        color: _diffValue > 0 ? COLOR_SANTA_DD : COLOR_SANTA_O,
        opacity: _diffValue > 0 ? 1 : 0.2,
      });
    }
    data.push({name: 'backgroundValue', value: value ? _backgroundValue : 1, color: COLOR_SANTA_K, opacity: 0.1});

    const chartRadius = (chartSize - 1) / 2;
    const innerRadius = chartRadius * 0.9;
    const startAngle = chartType === 'count' ? 270 : 234.3;
    const endAngle = chartType === 'count' ? -90 : -54.3;

    return (
      <div
        css={css`
          position: relative;
          width: ${chartSize}px;
          height: ${chartSize}px;
        `}
        ref={ref}
        {...props}>
        <PieChart width={chartSize} height={chartSize}>
          <Pie
            data={data}
            innerRadius={innerRadius}
            outerRadius={chartRadius}
            startAngle={startAngle}
            endAngle={endAngle}
            paddingAngle={1}
            dataKey="value"
            blendStroke>
            {data.map((item, index) => (
              <Cell key={index} fill={item.color} />
            ))}
          </Pie>
        </PieChart>
        <div
          css={css`
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            top: 0;
          `}>
          {value || chartType === 'count' ? (
            <DonutResultChartLabel
              chartType={chartType}
              correctValue={value?.correct ?? 0}
              totalValue={value?.total ?? 0}
            />
          ) : (
            <CloseIcon
              css={css`
                width: 32px;
                height: 32px;
                color: ${COLOR_SANTA_O};
                opacity: 0.1;
              `}
            />
          )}
        </div>
        {chartType === 'time' && (
          <div
            css={css`
              display: flex;
              position: absolute;
              bottom: 0;
              justify-content: center;
              word-break: break-all;
              width: 100%;
            `}>
            <Typography
              variant="caption2"
              fontWeight="bold"
              color={_diffValue > 0 && value ? COLOR_SANTA_DD : COLOR_SANTA_J}
              css={css`
                width: 30px;
                text-align: center;
              `}>
              {value
                ? _diffValue > 0
                  ? t('learning_cell_report_time_over')
                  : t('learning_cell_report_time_proper')
                : t('learning_cell_report_time_empty')}
            </Typography>
          </div>
        )}
      </div>
    );
  }
);

export default React.memo(DonutResultChart);
