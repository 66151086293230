import {useQuery} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';

import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {getEventDetailQueryKey} from '@app/queryKeys';

export const useEventDetailQuery = (id: number) => {
  const {MarketingService} = useAtomValue(santaOpenapiServicesAtom);
  return useQuery(getEventDetailQueryKey(id), async () => await MarketingService.getMarketingCampaignById({id}));
};
