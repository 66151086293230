import React from 'react';
import {useOfferGroupOrdersQuery} from '@app/features/offer/hooks';
import {mapOfferGroupOrderToOfferGroupOrderCardLabelListProps} from '@app/features/offer/utils';
import {OfferGroupOrderListPageView, OfferGroupOrderListPageViewProps} from '@app/features/offer/views';
import useUser from '@app/hooks/user/useUser';
import {useTypedRouter} from '@app/hooks/useTypedRouter';

const OfferGroupOrderListPageContainer = () => {
  const router = useTypedRouter();
  const {data: user} = useUser();
  const {data: orders} = useOfferGroupOrdersQuery();

  if (!orders || !user) {
    return <OfferGroupOrderListPageView.Skeleton onBackClick={router.back} />;
  }
  if (orders.length === 0) {
    return <OfferGroupOrderListPageView.Empty onBackClick={router.back} />;
  }

  const cardItems: OfferGroupOrderListPageViewProps['cardItems'] = orders.map(order => {
    const labelListProps = mapOfferGroupOrderToOfferGroupOrderCardLabelListProps(order);
    return {
      id: order.id,
      subTitle: order.offerGroup.displayConfig.name,
      title: order.offerGroup.offer.displayConfig.name,
      onClick: () => router.push({pathname: '/offer-group/order/detail', query: {id: order.id}}),
      purchasedAt: order.timestampInfo.paymentSucceededAt!,
      subscriptionAdditionalDate: labelListProps.isSubscription
        ? labelListProps.subscriptionStatus === 'failed'
          ? undefined
          : labelListProps.subscriptionStatus === 'normal'
          ? order.timestampInfo.subscriptionRenewAt
          : order.permitItems?.passes?.[0].expireAt ?? order.permitItems?.tickets?.[0].expiredAt
        : undefined,
      ...labelListProps,
    };
  });

  return (
    <OfferGroupOrderListPageView.List
      username={user.personalInfo?.name ?? 'USERNAME'}
      cardItems={cardItems}
      onBackClick={router.back}
    />
  );
};

export default OfferGroupOrderListPageContainer;
OfferGroupOrderListPageContainer.displayName = 'OfferGroupOrderListPageContainer';
