import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {
  COLOR_SANTA_C,
  COLOR_SANTA_CARD_B,
  COLOR_SANTA_G,
  COLOR_SANTA_I,
  COLOR_SANTA_L,
  COLOR_SANTA_M,
} from '@riiid/design-system';
import ChevronMiniRightIcon from '@riiid/design-system/icons/ChevronMiniRight';
import CloseIcon from '@riiid/design-system/icons/Close';
import CreditCard from '@riiid/design-system/icons/Creditcard';
import Naver from '@riiid/design-system/icons/Naver';
import ReportErrorIcon from '@riiid/design-system/icons/ReportError';
import {Button, IconButton, TopNavBar, Typography} from '@santa-web/service-ui';
import {
  OfferGroupOrderSection,
  OfferLayout,
  OfferGroupOrderBillSheet,
  OfferGroupDisplayImage,
  OfferGroupOrderTermsOfUse,
  OfferGroupOrderSectionTitle,
  OfferGroupOrderCautionButton,
  OfferGroupOrderProcessingDim,
} from '@app/features/offer/components';
import {
  OfferGroupOrderCoupon,
  OfferGroupOrderPageCommonViewProps,
  OfferGroupOrderPaymentProvider,
} from '@app/features/offer/views/types';

type OfferGroupOrderPageViewProps = OfferGroupOrderPageCommonViewProps & {
  isProcessingCheckout?: boolean;
  isUpdatingOrder?: boolean;
  subscriptionInfo?: {
    duration: string;
  };
  paymentProviderInfo?: {
    items: OfferGroupOrderPaymentProvider[];
    appliedPaymentProvider: OfferGroupOrderPaymentProvider;
    onPaymentProviderSelect: (paymentProvider: OfferGroupOrderPaymentProvider) => void;
  };
  couponInfo?: {
    totalCount: number;
    appliedItems: Omit<OfferGroupOrderCoupon, 'inUnavailable'>[];
    onCouponButtonClick: () => void;
    onCouponCancelClick: (id: number) => void;
  };
  onPurchaseClick: () => void;
};

const OfferGroupOrderPageView = ({
  isUpdatingOrder,
  isProcessingCheckout,
  subscriptionInfo,
  displayInfo,
  paymentProviderInfo,
  couponInfo,
  billSheetProps,
  onCautionClick,
  onPurchaseClick,
  onGoBack,
}: OfferGroupOrderPageViewProps) => {
  const {t} = useTranslation();
  return (
    <>
      <OfferLayout
        topNavBar={<TopNavBar paginationIcon="back" onPaginationIconClick={onGoBack} />}
        bottomArea={
          <Button
            isFullWidth
            variant="bottom-fix"
            isLoading={isUpdatingOrder}
            isDisabled={isUpdatingOrder}
            colorVariant="brand"
            onClick={onPurchaseClick}>
            {subscriptionInfo
              ? t('page_offer_group_subscription_purchase_button', {
                  chargeAmount: billSheetProps.billingInfo.totalAmount,
                  renewalTerm: subscriptionInfo.duration,
                })
              : t('page_offer_group_order_one_time_purchase_button', {
                  chargeAmount: billSheetProps.billingInfo.totalAmount,
                })}
          </Button>
        }>
        <div
          css={css`
            width: 100%;
            display: grid;
            gap: 8px;
          `}>
          <OfferGroupOrderSection hasTwoBackgroundColor>
            <Typography
              css={css`
                margin-bottom: 32px;
              `}
              component="h1"
              variant="caption1"
              fontWeight="bold"
              color={COLOR_SANTA_I}>
              {t('page_offer_group_order_title')}
            </Typography>
            <OfferGroupDisplayImage
              css={css`
                margin-bottom: 32px;
              `}
              {...displayInfo.displayImageProps}
            />
            <div>
              <Typography
                css={css`
                  margin-bottom: 4px;
                `}
                component="h3"
                variant="caption1"
                fontWeight="regular"
                color={COLOR_SANTA_L}>
                {displayInfo.subTitle}
              </Typography>
              <Typography
                css={css`
                  margin-bottom: 16px;
                `}
                component="h2"
                variant="body1"
                fontWeight="bold"
                color={COLOR_SANTA_L}>
                {displayInfo.title}
              </Typography>
              <Typography variant="body3" color={COLOR_SANTA_G}>
                {displayInfo.description}
              </Typography>
            </div>
          </OfferGroupOrderSection>
          {paymentProviderInfo && (
            <OfferGroupOrderSection>
              <OfferGroupOrderSectionTitle value={t('page_offer_group_order_section_title_payment_method')} />
              <ul
                css={css`
                  display: flex;
                  gap: 12px;
                  width: 100%;

                  margin: 0;
                  padding: 0;
                  list-style: none;
                `}>
                {paymentProviderInfo.items.map(paymentProvider => {
                  let Icon: React.ElementType;
                  let content: string;
                  switch (paymentProvider) {
                    case 'kcp':
                      Icon = CreditCard;
                      content = t('page_offer_group_order_payment_method_credit_card');
                      break;
                    case 'naverpay':
                      Icon = Naver;
                      content = t('page_offer_group_order_payment_method_naver_pay');
                      break;
                  }
                  return (
                    <li
                      key={paymentProvider}
                      css={css`
                        width: 100%;
                      `}>
                      <Button
                        isFullWidth
                        variant="box-line-toggle"
                        isSelected={paymentProvider === paymentProviderInfo.appliedPaymentProvider}
                        onClick={() => paymentProviderInfo.onPaymentProviderSelect(paymentProvider)}>
                        <span
                          css={css`
                            display: flex;
                            justify-content: center;
                            align-items: center;
                          `}>
                          <Icon
                            height={24}
                            width={24}
                            type="line"
                            css={css`
                              margin-right: 8px;
                            `}
                          />
                          {content}
                        </span>
                      </Button>
                    </li>
                  );
                })}
              </ul>
            </OfferGroupOrderSection>
          )}
          {couponInfo && (
            <OfferGroupOrderSection>
              <OfferGroupOrderSectionTitle value={t('page_offer_group_order_section_title_coupon')} />
              <Button
                variant="box-line"
                isFullWidth
                isDisabled={couponInfo.totalCount === 0}
                onClick={couponInfo.onCouponButtonClick}>
                <span
                  css={css`
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 4px;
                  `}>
                  <span>{t('page_offer_group_order_coupon_count', {n: couponInfo.totalCount})}</span>
                  <ChevronMiniRightIcon width={24} height={24} color={COLOR_SANTA_M} />
                </span>
              </Button>
              {couponInfo.appliedItems.length > 0 && (
                <ul
                  css={css`
                    display: grid;
                    gap: 8px;

                    margin: 0;
                    margin-top: 16px;
                    padding: 0;
                    list-style: none;
                  `}>
                  {couponInfo.appliedItems.map(({id, name, information}) => (
                    <li
                      key={id}
                      css={css`
                        width: 100%;
                      `}>
                      <CouponCard
                        name={name}
                        information={information}
                        onCouponCancelClick={() => couponInfo.onCouponCancelClick(id)}
                      />
                    </li>
                  ))}
                </ul>
              )}
            </OfferGroupOrderSection>
          )}
          <OfferGroupOrderSection>
            <OfferGroupOrderSectionTitle value={t('page_offer_group_order_section_title_permit_list')} />
            <OfferGroupOrderBillSheet {...billSheetProps} />
          </OfferGroupOrderSection>
          <OfferGroupOrderSection>
            <OfferGroupOrderSectionTitle
              value={t('page_offer_group_order_section_title_caution')}
              icon={<ReportErrorIcon width={28} height={28} color={COLOR_SANTA_L} type="line" />}
            />
            <OfferGroupOrderCautionButton onClick={onCautionClick} />
          </OfferGroupOrderSection>
          <OfferGroupOrderSection
            css={css`
              margin-top: -8px;
              background-color: ${COLOR_SANTA_C};
            `}>
            <OfferGroupOrderTermsOfUse />
          </OfferGroupOrderSection>
        </div>
      </OfferLayout>
      {isProcessingCheckout && <OfferGroupOrderProcessingDim />}
    </>
  );
};

const CouponCard = ({
  name,
  information,
  onCouponCancelClick,
}: {
  name: string;
  information: string;
  onCouponCancelClick: () => void;
}) => {
  return (
    <div
      css={css`
        width: 100%;
        padding: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        border: none;
        border-radius: 4px;
        background-color: ${COLOR_SANTA_CARD_B};
      `}>
      <div>
        <Typography component="p" variant="caption2" color={COLOR_SANTA_L}>
          {name}
        </Typography>
        <Typography variant="body3" fontWeight="bold" color={COLOR_SANTA_L}>
          {information}
        </Typography>
      </div>
      <IconButton
        Icon={CloseIcon}
        IconProps={{width: 24, height: 24, color: COLOR_SANTA_L}}
        onClick={onCouponCancelClick}
      />
    </div>
  );
};

export default OfferGroupOrderPageView;
export type {OfferGroupOrderPageViewProps};
OfferGroupOrderPageView.displayName = 'OfferGroupOrderPageView';
