/* tslint:disable */
/* eslint-disable */
/**
 *
 * @export
 */
export const SelfLearningAllQuestionPermissionStatus = {
    ALLOWED: 'ALLOWED',
    NOT_ALLOWED: 'NOT_ALLOWED'
};
export function SelfLearningAllQuestionPermissionStatusFromJSON(json) {
    return SelfLearningAllQuestionPermissionStatusFromJSONTyped(json, false);
}
export function SelfLearningAllQuestionPermissionStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function SelfLearningAllQuestionPermissionStatusToJSON(value) {
    return value;
}
