import React from 'react';
import {css} from '@emotion/react';
import {COLOR_SANTA_D, COLOR_SANTA_H, COLOR_SANTA_L} from '@riiid/design-system';

import {Typography, RangeInput, RangeInputProps} from '@santa-web/service-ui';

export interface BottomSheetListRangeInputItemProps {
  title: React.ReactNode;
  subText?: React.ReactNode;
  rangeInputProps: RangeInputProps;
}

const BottomSheetListRangeInputItem = React.forwardRef<HTMLDivElement, BottomSheetListRangeInputItemProps>(
  ({title, subText, rangeInputProps, ...props}, ref) => {
    return (
      <div
        ref={ref}
        css={css`
          display: flex;
          flex-direction: column;
          border-bottom: 1px solid ${COLOR_SANTA_D};
          padding-bottom: 12px;
        `}
        {...props}>
        <Typography variant="body3" fontWeight="bold" color={COLOR_SANTA_L}>
          {title}
        </Typography>
        {subText && (
          <Typography variant="caption1" fontWeight="regular" color={COLOR_SANTA_H}>
            {subText}
          </Typography>
        )}
        <RangeInput
          css={css`
            margin-top: 12px;
          `}
          {...rangeInputProps}
        />
      </div>
    );
  }
);

export default React.memo(BottomSheetListRangeInputItem);
