import {
  COLOR_FIX_SPACE_BLUE_GRAY_2,
  COLOR_RIIID_SECONDARY_RB,
  COLOR_RIIID_SECONDARY_PB,
  COLOR_RIIID_SECONDARY_YB,
  COLOR_RIIID_SECONDARY_GB,
} from '@riiid/design-system';
import {HighlightColor} from '@santa-web/gen/open-api/content-learning-service';

const HIGHLIGHT_DEFAULT_COLOR = COLOR_FIX_SPACE_BLUE_GRAY_2;
const HIGHLIGHT_COLORS = [
  HIGHLIGHT_DEFAULT_COLOR,
  COLOR_RIIID_SECONDARY_RB,
  COLOR_RIIID_SECONDARY_PB,
  COLOR_RIIID_SECONDARY_YB,
  COLOR_RIIID_SECONDARY_GB,
] as const;
const HIGHLIGHT_COLOR_MAP: Record<(typeof HIGHLIGHT_COLORS)[number], HighlightColor> = {
  [HIGHLIGHT_DEFAULT_COLOR]: 'BRAND',
  [COLOR_RIIID_SECONDARY_RB]: 'RIIID_RED',
  [COLOR_RIIID_SECONDARY_PB]: 'RIIID_PURPLE',
  [COLOR_RIIID_SECONDARY_YB]: 'RIIID_YELLOW',
  [COLOR_RIIID_SECONDARY_GB]: 'RIIID_GREEN',
};

const TOOL_BOX_HEIGHT = 48;
const TOOL_BOX_SELECTION_GAP = 8;
const TOOL_BOX_ARROW_SIZE = 4;
const TOOL_BOX_ARROW_SIDE_GAP = 16;
const TOOL_BOX_SIDE_HALF_GAP = TOOL_BOX_ARROW_SIZE + TOOL_BOX_ARROW_SIDE_GAP;
const TOOL_BOX_SIDE_GAP = TOOL_BOX_SIDE_HALF_GAP * 2;

const TOOL_BOX_POSITION_TOP = 'top';
const TOOL_BOX_POSITION_BOTTOM = 'bottom';
const TOOL_BOX_POSITION_LEFT = 'left';
const TOOL_BOX_POSITION_RIGHT = 'right';
const TOOL_BOX_POSITION_CENTER = 'center';
const TOOL_BOX_VERTICAL_POSITIONS = [TOOL_BOX_POSITION_TOP, TOOL_BOX_POSITION_BOTTOM] as const;
const TOOL_BOX_HORIZONTAL_POSITIONS = [
  TOOL_BOX_POSITION_LEFT,
  TOOL_BOX_POSITION_RIGHT,
  TOOL_BOX_POSITION_CENTER,
] as const;
const TOOL_BOX_ALL_POSITIONS = [...TOOL_BOX_VERTICAL_POSITIONS, ...TOOL_BOX_HORIZONTAL_POSITIONS] as const;

export {
  HIGHLIGHT_DEFAULT_COLOR,
  HIGHLIGHT_COLORS,
  HIGHLIGHT_COLOR_MAP,
  TOOL_BOX_HEIGHT,
  TOOL_BOX_SELECTION_GAP,
  TOOL_BOX_ARROW_SIZE,
  TOOL_BOX_ARROW_SIDE_GAP,
  TOOL_BOX_SIDE_HALF_GAP,
  TOOL_BOX_SIDE_GAP,
  TOOL_BOX_POSITION_TOP,
  TOOL_BOX_POSITION_BOTTOM,
  TOOL_BOX_POSITION_LEFT,
  TOOL_BOX_POSITION_RIGHT,
  TOOL_BOX_POSITION_CENTER,
  TOOL_BOX_VERTICAL_POSITIONS,
  TOOL_BOX_HORIZONTAL_POSITIONS,
  TOOL_BOX_ALL_POSITIONS,
};
