import React from 'react';
import {css} from '@emotion/react';
import {Dim} from '@santa-web/service-ui';
import LoadingGrayLogoAnimation from '@app/components/LoadingGrayLogoAnimation';
import {BOTTOM_SHEET_Z_INDEX} from '@app/constants/zIndex';

const LoadingDim = () => {
  return (
    <Dim
      css={css`
        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        display: flex;
        z-index: ${BOTTOM_SHEET_Z_INDEX};
        align-items: center;
        justify-content: center;
      `}>
      <LoadingGrayLogoAnimation width={40} height={40} />
    </Dim>
  );
};

export default LoadingDim;
LoadingDim.displayName = 'LoadingDim';
