import {useMutation, useQueryClient} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';

import {rgbToRrtv2Rgba} from '@santa-web/service-ui';
import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {getListHighlightInfosQueryKey} from '@app/queryKeys';
import {HIGHLIGHT_COLOR_MAP} from '@app/test-preparation/constants/highlight';
import {HighlightColor, HighlightInfo, ClickedHighlightInfo} from '@app/test-preparation/types/highlight';

interface MutationInput {
  cisId: number;
  clickedHighlightInfo: ClickedHighlightInfo;
  color: HighlightColor;
}

export default function useUpdateHighlightInfo() {
  const queryClient = useQueryClient();

  const {HighlightService} = useAtomValue(santaOpenapiServicesAtom);

  return useMutation(
    ({clickedHighlightInfo, color}: MutationInput) => {
      const {snippetViewId, highlight, text} = clickedHighlightInfo;

      return HighlightService.updateHighlight({
        highlightId: highlight.id,
        highlightTemplate: {
          color: HIGHLIGHT_COLOR_MAP[color],
          displayInfoJson: JSON.stringify({
            snippetViewId,
            highlight: {
              ...highlight,
              color: rgbToRrtv2Rgba(color),
            },
          }),
          previewStr: text,
        },
      });
    },
    {
      onSuccess: async (_, {cisId, clickedHighlightInfo, color}) => {
        const {highlight} = clickedHighlightInfo;

        const listHighlightQueryKey = getListHighlightInfosQueryKey(cisId);

        await queryClient.cancelQueries(listHighlightQueryKey);
        queryClient.setQueryData<HighlightInfo[]>(listHighlightQueryKey, (old = []) =>
          old.map(highlightInfo =>
            highlightInfo.highlight.id === highlight.id
              ? {
                  ...clickedHighlightInfo,
                  highlight: {
                    ...highlight,
                    color: rgbToRrtv2Rgba(color),
                  },
                }
              : highlightInfo
          )
        );
      },
    }
  );
}
