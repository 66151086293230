import React from 'react';
import {useTranslation} from 'react-i18next';

import {css} from '@emotion/react';
import {COLOR_SANTA_G, COLOR_SANTA_J, COLOR_SANTA_L} from '@riiid/design-system';
import CheckboxIcon from '@riiid/design-system/icons/Checkbox';
import {Typography} from '@santa-web/service-ui';
import {use100vh} from '@app/hooks/use100vh';

const ResetPasswordCompleted = (): JSX.Element => {
  const {t} = useTranslation();
  const viewportHeight = use100vh();
  return (
    <div
      css={css`
        display: grid;
        justify-items: center;
        align-content: center;
        gap: 24px;
        height: ${viewportHeight};
      `}>
      <CheckboxIcon width={40} height={40} color={COLOR_SANTA_G} />
      <Typography variant="h5" component="h1" fontWeight="regular" color={COLOR_SANTA_L}>
        {t('page_reset_password_done_title')}
      </Typography>
      <Typography
        variant="body3"
        color={COLOR_SANTA_J}
        css={css`
          text-align: center;
          white-space: pre-line;
        `}>
        {t('page_reset_password_done_description')}
      </Typography>
    </div>
  );
};

export default React.memo(ResetPasswordCompleted);
