import {atom} from 'jotai';
import {TutorQuestionButtonFeature} from '@app/test-preparation/hooks/testprep-feature/useTutorQuestionButtonFeature';

export interface TestprepFeature {
  qaButton?: {
    tutorQuestionButton?: TutorQuestionButtonFeature;
  };
}

export const testprepFeatureAtom = atom<TestprepFeature>({});
