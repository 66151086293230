/**
 * Check if a given object implements the CouponDiscount interface.
 */
export function instanceOfCouponDiscount(value) {
    let isInstance = true;
    isInstance = isInstance && "couponId" in value;
    isInstance = isInstance && "discountAmount" in value;
    return isInstance;
}
export function CouponDiscountFromJSON(json) {
    return CouponDiscountFromJSONTyped(json, false);
}
export function CouponDiscountFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'couponId': json['couponId'],
        'discountAmount': json['discountAmount'],
    };
}
export function CouponDiscountToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'couponId': value.couponId,
        'discountAmount': value.discountAmount,
    };
}
