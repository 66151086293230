import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';

import {Button, ButtonProps, BottomSheetProps} from '@santa-web/service-ui';
import BottomSheetWithDim from '@app/components/BottomSheetWithDim';
import {MemorizeStyle} from '@app/types/vocabulary';

interface Props {
  isDisabled?: boolean;
  onMemorizeStyleClick(memorizeStyle: MemorizeStyle): void;
  onCloseClick?: React.EventHandler<React.MouseEvent>;
}

const MemorizeStyleView = (props: Props) => {
  const {isDisabled, onMemorizeStyleClick, onCloseClick} = props;

  const {t} = useTranslation();

  const handleClickWordFirst = useCallback(() => {
    onMemorizeStyleClick('wordFirst');
  }, [onMemorizeStyleClick]);

  const handleClickMeaningFirst = useCallback(() => {
    onMemorizeStyleClick('meaningFirst');
  }, [onMemorizeStyleClick]);

  const bottomSheetButtonProps = React.useMemo(
    (): Partial<ButtonProps> => ({
      variant: 'solid',
      colorVariant: 'brand',
      isDisabled,
      isFullWidth: true,
    }),
    [isDisabled]
  );

  const ButtonsInBottomSheet = React.useMemo(
    () => [
      <Button key="1" onClick={handleClickWordFirst} {...bottomSheetButtonProps}>
        {t('toeic_vocabulary_view_word_first')}
      </Button>,
      <Button key="2" onClick={handleClickMeaningFirst} {...bottomSheetButtonProps}>
        {t('toeic_vocabulary_view_meaning_first')}
      </Button>,
    ],
    [handleClickWordFirst, handleClickMeaningFirst, t, bottomSheetButtonProps]
  );

  const BottomSheetProps = React.useMemo(
    (): BottomSheetProps => ({
      title: t('toeic_vocabulary_view_memorization_style_title'),
      description: t('toeic_vocabulary_view_memorization_style_description'),
      isLoadingAiAnimationVisible: true,
      buttons: ButtonsInBottomSheet,
      onCloseClick: isDisabled ? undefined : onCloseClick,
    }),
    [isDisabled, onCloseClick, t, ButtonsInBottomSheet]
  );

  return (
    <div
      css={css`
        position: fixed;

        top: 0;
        right: 0;
        bottom: 0;
        left: 0;

        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      `}>
      <BottomSheetWithDim isOpened BottomSheetProps={BottomSheetProps} />
    </div>
  );
};

export default React.memo(MemorizeStyleView);
