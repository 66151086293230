/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { UserDiagnosisInfoFromJSON, UserDiagnosisInfoToJSON, } from './UserDiagnosisInfo';
import { UserOnboardingInfoFromJSON, UserOnboardingInfoToJSON, } from './UserOnboardingInfo';
/**
 * Check if a given object implements the UserProfile interface.
 */
export function instanceOfUserProfile(value) {
    let isInstance = true;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "diagnosisInfo" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "onboardingInfo" in value;
    return isInstance;
}
export function UserProfileFromJSON(json) {
    return UserProfileFromJSONTyped(json, false);
}
export function UserProfileFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'createdAt': (new Date(json['createdAt'])),
        'currentEstimatedScore': !exists(json, 'currentEstimatedScore') ? undefined : json['currentEstimatedScore'],
        'diagnosisInfo': UserDiagnosisInfoFromJSON(json['diagnosisInfo']),
        'id': json['id'],
        'onboardingInfo': UserOnboardingInfoFromJSON(json['onboardingInfo']),
        'targetExamDate': !exists(json, 'targetExamDate') ? undefined : (new Date(json['targetExamDate'])),
    };
}
export function UserProfileToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'createdAt': (value.createdAt.toISOString()),
        'currentEstimatedScore': value.currentEstimatedScore,
        'diagnosisInfo': UserDiagnosisInfoToJSON(value.diagnosisInfo),
        'id': value.id,
        'onboardingInfo': UserOnboardingInfoToJSON(value.onboardingInfo),
        'targetExamDate': value.targetExamDate === undefined ? undefined : (value.targetExamDate.toISOString().substr(0, 10)),
    };
}
