import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {
  COLOR_SANTA_A,
  COLOR_SANTA_D,
  COLOR_SANTA_F,
  COLOR_SANTA_BC,
  COLOR_FIX_SANTA_WHITE_1,
  COLOR_SANTA_O,
  COLOR_SANTA_L,
} from '@riiid/design-system';
import CheckIcon from '@riiid/design-system/icons/Check';
import {Typography} from '@santa-web/service-ui';
import {DayOfWeek} from '@app/features/preferences/types/my-learning-goal';

type DayOfWeekCheckboxProps = {
  dayOfWeek: DayOfWeek;
  isSelected?: boolean;
  isDisabled?: boolean;
  onClick: () => void;
};

const DayOfWeekCheckbox = ({dayOfWeek, isSelected = false, isDisabled = false, onClick}: DayOfWeekCheckboxProps) => {
  const {t} = useTranslation();
  const mapDayOfWeekToWord: Record<DayOfWeek, string> = {
    MONDAY: t('dict_monday'),
    TUESDAY: t('dict_tuesday'),
    WEDNESDAY: t('dict_wednesday'),
    THURSDAY: t('dict_thursday'),
    FRIDAY: t('dict_friday'),
    SATURDAY: t('dict_saturday'),
    SUNDAY: t('dict_sunday'),
  };

  const getBgColor = (isSelected: boolean) => {
    if (isSelected) return COLOR_SANTA_BC;
    return COLOR_SANTA_D;
  };

  return (
    <button
      onClick={() => onClick()}
      className="day-of-week-checkbox-button"
      disabled={isDisabled}
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 4px;
        width: min-content;
        padding: 0;
        border: none;
        background: transparent;
        opacity: ${isDisabled ? 0.4 : 1};
        cursor: ${isDisabled ? 'not-allowed' : 'pointer'};
      `}>
      <input
        checked={isSelected}
        disabled={isDisabled}
        type="checkbox"
        css={css`
          display: none;
        `}
      />
      <label
        css={css`
          width: 32px;
          height: 32px;
          display: flex;
          justify-content: center;
          align-items: center;
          border-radius: 9999px;
          background: ${getBgColor(isSelected)};
          cursor: ${isDisabled ? 'not-allowed' : 'pointer'};
          @media (hover: hover) {
            :hover {
              background: ${!isDisabled && !isSelected && COLOR_SANTA_F};
            }
          }
          :active {
            background: ${!isDisabled && !isSelected && COLOR_SANTA_F};
          }
        `}>
        <CheckIcon type="line" color={isSelected ? COLOR_FIX_SANTA_WHITE_1 : COLOR_SANTA_A} width={20} height={20} />
      </label>
      <Typography
        css={css`
          .day-of-week-checkbox-button:hover & {
            @media (hover: hover) {
              color: ${!isDisabled && !isSelected && COLOR_SANTA_O};
            }
          }
          .day-of-week-checkbox-button:active & {
            color: ${!isDisabled && !isSelected && COLOR_SANTA_O};
          }
        `}
        variant="body3"
        color={COLOR_SANTA_L}
        fontWeight={isSelected ? 'bold' : 'regular'}>
        {mapDayOfWeekToWord[dayOfWeek]}
      </Typography>
    </button>
  );
};

export default DayOfWeekCheckbox;
export type {DayOfWeekCheckboxProps, DayOfWeek};
DayOfWeekCheckbox.displayName = 'DayOfWeekCheckbox';
