import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {
  COLOR_SANTA_A,
  COLOR_SANTA_BC,
  COLOR_SANTA_H,
  COLOR_SANTA_I,
  COLOR_SANTA_J,
  COLOR_SANTA_L,
} from '@riiid/design-system';
import ReportErrorIcon from '@riiid/design-system/icons/ReportError';
import {Button, Typography} from '@santa-web/service-ui';
import {FrameLayout, ScrollLayout} from '@app/components';
import {use100vh} from '@app/hooks/use100vh';

type OfferGroupOrderFailurePageViewProps = {isLoading?: boolean; onButtonClick: () => void};

const OfferGroupOrderFailurePageView = ({isLoading, onButtonClick}: OfferGroupOrderFailurePageViewProps) => {
  const {t} = useTranslation();
  const height = use100vh();
  return (
    <div
      css={css`
        height: ${height};
        padding-top: var(--mobile-safe-area-top);
      `}>
      <FrameLayout
        bottomArea={
          <Button colorVariant="brand" variant="bottom-fix" onClick={onButtonClick} isLoading={isLoading}>
            {t('page_offer_group_order_failure_cta')}
          </Button>
        }>
        <ScrollLayout>
          <div
            css={css`
              padding: 40px 20px 24px;
              box-sizing: border-box;
              min-height: 100%;
              background-color: ${COLOR_SANTA_A};
              display: flex;
              flex-direction: column;
              @media screen and (min-width: 768px) {
                padding: 40px 40px 24px;
              }
            `}>
            <Typography
              variant="caption1"
              fontWeight="bold"
              color={COLOR_SANTA_I}
              css={css`
                display: block;
                margin-bottom: 40px;
              `}>
              {t('order_result_error_page_title')}
            </Typography>
            <div>
              <ReportErrorIcon
                type="line"
                color={COLOR_SANTA_BC}
                height={56}
                width={56}
                css={css`
                  margin-bottom: 12px;
                `}
              />
              <Typography
                variant="h5"
                fontWeight="regular"
                color={COLOR_SANTA_L}
                css={css`
                  white-space: pre-line;
                `}>
                <Trans
                  i18nKey="order_result_payment_failed"
                  components={{
                    b: <Typography variant="h5" fontWeight="bold" color={COLOR_SANTA_L} component="span" />,
                  }}
                />
              </Typography>
            </div>
            <Typography
              variant="body3"
              fontWeight="regular"
              color={COLOR_SANTA_J}
              css={css`
                margin-top: 20px;
              `}>
              {t('order_result_payment_failed_please_click_button')}
            </Typography>
            <div
              css={css`
                flex-grow: 1;
                display: flex;
                justify-content: center;
                align-items: flex-end;
              `}>
              <Typography variant="caption1" fontWeight="regular" color={COLOR_SANTA_H}>
                <Trans
                  i18nKey="order_result_payment_call_our_support_center"
                  components={{
                    a: (
                      <Typography variant="caption1" fontWeight="bold">
                        <a
                          rel="noreferrer"
                          target="_blank"
                          href={t('customer_service_url')}
                          css={css`
                            color: inherit;
                            text-decoration: underline;
                          `}
                        />
                      </Typography>
                    ),
                  }}
                />
              </Typography>
            </div>
          </div>
        </ScrollLayout>
      </FrameLayout>
    </div>
  );
};

export default OfferGroupOrderFailurePageView;
export type {OfferGroupOrderFailurePageViewProps};
OfferGroupOrderFailurePageView.displayName = 'OfferGroupOrderFailurePageView';
