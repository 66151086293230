/**
 * Check if a given object implements the VocabularyLearningHistory interface.
 */
export function instanceOfVocabularyLearningHistory(value) {
    let isInstance = true;
    isInstance = isInstance && "contentInteractionStateId" in value;
    isInstance = isInstance && "isBookmarked" in value;
    isInstance = isInstance && "lastStudiedAt" in value;
    isInstance = isInstance && "markedAsKnown" in value;
    isInstance = isInstance && "meaning" in value;
    isInstance = isInstance && "word" in value;
    return isInstance;
}
export function VocabularyLearningHistoryFromJSON(json) {
    return VocabularyLearningHistoryFromJSONTyped(json, false);
}
export function VocabularyLearningHistoryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'contentInteractionStateId': json['contentInteractionStateId'],
        'isBookmarked': json['isBookmarked'],
        'lastStudiedAt': (new Date(json['lastStudiedAt'])),
        'markedAsKnown': json['markedAsKnown'],
        'meaning': json['meaning'],
        'word': json['word'],
    };
}
export function VocabularyLearningHistoryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'contentInteractionStateId': value.contentInteractionStateId,
        'isBookmarked': value.isBookmarked,
        'lastStudiedAt': (value.lastStudiedAt.toISOString()),
        'markedAsKnown': value.markedAsKnown,
        'meaning': value.meaning,
        'word': value.word,
    };
}
