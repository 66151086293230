import {HTMLAttributes, ReactNode} from 'react';
import {css} from '@emotion/react';
import {
  BREAKPOINT_MEDIUM,
  COLOR_FIX_SANTA_GRAY_1,
  COLOR_FIX_SANTA_GRAY_4,
  COLOR_FIX_SANTA_GRAY_7,
  COLOR_FIX_SPACE_BLUE_BLACK_1,
  COLOR_RIIID_GREEN_3,
  COLOR_SPACE_BLUE_WHITE_2,
  SHADOW_A_1_TOP,
} from '@riiid/design-system';
import {Button, TextButton, Typography} from '@santa-web/service-ui';
import {Column, FrameLayout, ScrollLayout} from '@app/components';
import {use100vh} from '@app/hooks/use100vh';

export type NewUserFlashDealPageViewProps = {
  onBuyClick: () => void;
  onCloseClick: () => void;
  offerGroupCard: ReactNode;
  countdownTimer: ReactNode;
};

const NewUserFlashDealPageView = ({
  offerGroupCard,
  countdownTimer,
  onBuyClick,
  onCloseClick,
}: NewUserFlashDealPageViewProps) => {
  const fullHeight = use100vh();
  return (
    <FrameLayout
      css={css`
        height: ${fullHeight};
      `}
      bottomArea={
        <Column
          alignItems="center"
          css={css`
            width: 100%;
            bottom: 0;
            padding: 20px 20px calc(var(--mobile-safe-area-bottom, 0px) + 40px);
            background: linear-gradient(180deg, #1226aa 0%, #000b53 100%);
            box-shadow: ${SHADOW_A_1_TOP};
          `}>
          <Column
            gap={12}
            alignItems="center"
            css={css`
              width: 100%;
              max-width: 600px;
            `}>
            <Button isFullWidth colorVariant="brand" onClick={onBuyClick}>
              상품 구매하고 학습 계속하기
            </Button>
            <TextButton fontWeight="regular" typographyVariant="body3" color="white-fix" onClick={onCloseClick}>
              아니요, 괜찮아요
            </TextButton>
          </Column>
        </Column>
      }>
      <ScrollLayout
        css={css`
          position: relative;
          background-color: ${COLOR_FIX_SPACE_BLUE_BLACK_1};
          overflow: auto;
          overscroll-behavior: none;
        `}>
        <Confetti
          css={css`
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            max-height: 546px;
          `}
        />
        <Column
          alignItems="center"
          gap={24}
          css={css`
            z-index: 0;
            width: 100%;
            position: relative;
            padding-top: calc(var(--mobile-safe-area-top, 0px) + 54px);

            &::before {
              content: '';
              z-index: -1;
              position: absolute;
              top: 0;
              width: 294px;
              height: 100%;
              border-radius: 540px;
              background: radial-gradient(50% 50% at 50% 50%, #00062f 0%, rgba(0, 6, 47, 0) 100%);
              filter: blur(5px);
            }
          `}>
          <Column gap={4}>
            <Typography
              variant="h2"
              color={COLOR_FIX_SANTA_GRAY_1}
              css={css`
                text-align: center;
              `}>
              신규 유저
            </Typography>

            <Typography
              variant="h2"
              color={COLOR_FIX_SANTA_GRAY_1}
              css={css`
                margin-top: -12px;
                text-align: center;
              `}>
              단독{' '}
              <strong
                css={css`
                  color: ${COLOR_RIIID_GREEN_3};
                `}>
                웰컴
              </strong>{' '}
              혜택
            </Typography>

            <Typography
              css={css`
                text-align: center;
              `}
              variant="h5"
              fontWeight="regular"
              color={COLOR_FIX_SANTA_GRAY_1}>
              딱 <b>3일</b> 동안만
            </Typography>
          </Column>

          <img
            css={css`
              margin-bottom: 8px;
            `}
            width={150}
            src="/img/flash-deal/tickets-group.png"
            alt="ticket-group"
          />

          <Typography
            css={css`
              text-align: center;
            `}
            variant="h5"
            color={COLOR_FIX_SANTA_GRAY_1}>
            산타와 함께 <br /> 내 점수 앞자리를 바꾸세요
          </Typography>
        </Column>

        <Column
          gap={24}
          alignItems="center"
          css={css`
            padding: 0px 20px;
            position: relative;
            margin-top: 20px;
            width: 100%;
            margin-bottom: 40px;

            @media (min-width: ${BREAKPOINT_MEDIUM}) {
              padding: 0px 40px;
            }
          `}>
          <Column
            gap={24}
            css={css`
              width: 100%;
              max-width: 600px;
            `}>
            {offerGroupCard}

            <Column gap={4}>
              <Column
                css={css`
                  position: relative;
                `}
                gap={4}>
                <Increase
                  css={css`
                    height: 150px;
                    position: absolute;
                    bottom: -21px;
                    left: 55%;
                  `}
                />

                <div
                  css={css`
                    width: 100%;
                    background-color: ${COLOR_SPACE_BLUE_WHITE_2};
                    padding: 4px 20px;
                    border-radius: 8px;
                  `}>
                  <Typography
                    css={css`
                      text-align: center;
                    `}
                    color={COLOR_FIX_SANTA_GRAY_7}
                    variant="body3">
                    <strong>{500}점</strong>대 이하 유저는 평균{' '}
                    <strong
                      css={css`
                        color: ${COLOR_RIIID_GREEN_3};
                      `}>
                      630점
                    </strong>{' '}
                    달성!
                  </Typography>
                </div>

                <div
                  css={css`
                    width: 100%;
                    background-color: ${COLOR_SPACE_BLUE_WHITE_2};
                    padding: 4px 20px;
                    border-radius: 8px;
                  `}>
                  <Typography
                    css={css`
                      text-align: center;
                    `}
                    color={COLOR_FIX_SANTA_GRAY_7}
                    variant="body3">
                    <strong>600점</strong>대 유저는 평균{' '}
                    <strong
                      css={css`
                        color: ${COLOR_RIIID_GREEN_3};
                      `}>
                      745점
                    </strong>{' '}
                    달성!
                  </Typography>
                </div>
                <div
                  css={css`
                    width: 100%;
                    background-color: ${COLOR_SPACE_BLUE_WHITE_2};
                    padding: 4px 20px;
                    border-radius: 8px;
                  `}>
                  <Typography
                    css={css`
                      text-align: center;
                    `}
                    color={COLOR_FIX_SANTA_GRAY_7}
                    variant="body3">
                    <strong>700점</strong>대 유저는 평균{' '}
                    <strong
                      css={css`
                        color: ${COLOR_RIIID_GREEN_3};
                      `}>
                      835점
                    </strong>{' '}
                    달성!
                  </Typography>
                </div>
                <div
                  css={css`
                    width: 100%;
                    background-color: ${COLOR_SPACE_BLUE_WHITE_2};
                    padding: 4px 20px;
                    border-radius: 8px;
                  `}>
                  <Typography
                    css={css`
                      text-align: center;
                    `}
                    color={COLOR_FIX_SANTA_GRAY_7}
                    variant="body3">
                    <strong>800점</strong>대 유저는 평균{' '}
                    <strong
                      css={css`
                        color: ${COLOR_RIIID_GREEN_3};
                      `}>
                      45점
                    </strong>{' '}
                    상승!
                  </Typography>
                </div>
              </Column>

              <Typography
                css={css`
                  margin-top: 4px;
                `}
                variant="caption3"
                color={COLOR_FIX_SANTA_GRAY_4}>
                *2021.06.27 이후 산타 한국 유저 대상 자사 예측 점수 상승 결과입니다
              </Typography>
            </Column>
          </Column>
        </Column>

        {/* Timer */}
        <Column
          css={css`
            margin-bottom: 40px;
          `}
          gap={28}
          alignItems="center">
          <Typography
            css={css`
              text-align: center;
            `}
            variant="h5"
            fontWeight="bold"
            color={COLOR_FIX_SANTA_GRAY_1}>
            무료 학습 기간이 끝나면 <br /> 다시 만날 수 없는{' '}
            <strong
              css={css`
                color: ${COLOR_RIIID_GREEN_3};
              `}>
              혜택
            </strong>
          </Typography>

          {countdownTimer}
        </Column>
      </ScrollLayout>
    </FrameLayout>
  );
};

const Confetti = (props: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div
      {...props}
      css={css`
        background-position: top;
        background-image: url('/img/flash-deal/confetti2-3-x.png');
        background-repeat: repeat-x;
        background-size: cover;

        @media (min-width: ${BREAKPOINT_MEDIUM}) {
          background-size: contain;
        }
      `}></div>
  );
};

const Increase = (props: HTMLAttributes<HTMLDivElement>) => {
  return (
    <div {...props}>
      <svg xmlns="http://www.w3.org/2000/svg" width="148" height="150" viewBox="0 0 148 150" fill="none">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M148 37.2928L125.417 0L102.833 37.2928H116.725V39.7795C116.725 62.8639 115.229 85.622 101.895 102.891C88.9087 119.71 62.8513 133.426 8.27834 133.426H0V150H8.27834C65.1839 150 97.4889 135.7 114.995 113.027C132.154 90.8038 133.281 62.595 133.281 39.7795V37.2928H148Z"
          fill="url(#paint0_linear_10132_189735)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_10132_189735"
            x1="148"
            y1="75.0002"
            x2="0"
            y2="75.0002"
            gradientUnits="userSpaceOnUse">
            <stop stopColor="#77BAFF" />
            <stop offset="0.11" stopColor="#78BBFF" stopOpacity="0.98" />
            <stop offset="0.24" stopColor="#7CBDFF" stopOpacity="0.93" />
            <stop offset="0.37" stopColor="#83C0FF" stopOpacity="0.84" />
            <stop offset="0.5" stopColor="#8CC4FF" stopOpacity="0.72" />
            <stop offset="0.64" stopColor="#98CAFF" stopOpacity="0.57" />
            <stop offset="0.77" stopColor="#A6D1FF" stopOpacity="0.38" />
            <stop offset="0.91" stopColor="#B7D9FF" stopOpacity="0.16" />
            <stop offset="1" stopColor="#C3DFFF" stopOpacity="0" />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
};

export default NewUserFlashDealPageView;
