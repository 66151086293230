import React from 'react';
import {useTranslation} from 'react-i18next';
import {useIsGteLargeScreen} from '@santa-web/service-ui';
import useOfferEvents from '@app/api/google-tag-manager/offer';
import {
  useOfferGroupsQuery,
  OfferGroupAndOfferDetailListView,
  OfferGroupDetailListView,
  OfferGroupDetail,
  useCreateOfferGroupOrderMutation,
  ListSkeleton,
} from '@app/features/offer';
import {mapOfferGroupToOfferGroupDetail} from '@app/features/offer/utils';
import useNowBySecond from '@app/hooks/useNowBySecond';
import useUser from '@app/hooks/user/useUser';
import {useTypedRouter, useTypedSearchParams} from '@app/hooks/useTypedRouter';
import {B2bState} from '@app/misc/query-state';
import {SantaNavigationPath} from '@app/types/navigation';

const OfferGroupBoardDetailPageContainer = () => {
  const {t} = useTranslation();
  const router = useTypedRouter();
  const searchParams = useTypedSearchParams('/offer-group/board/detail');
  const now = useNowBySecond();
  const isGteLargeScreen = useIsGteLargeScreen();
  const {data: user} = useUser();
  const isAnonymous = user?.registeredAt == null;
  const {boardCode, offerGroupId} = searchParams;
  const {pushSwipeOfferGroupEvent, pushClickOfferOption, pushClickOfferBuy} = useOfferEvents();

  const handlePaginationClick = () => {
    if (searchParams.backTo) {
      return router.replace(searchParams.backTo as SantaNavigationPath);
    }
    return router.back();
  };

  const {data: offerGroups} = useOfferGroupsQuery(boardCode);
  const {mutateAsync: createOfferGroupOrder} = useCreateOfferGroupOrderMutation();

  const offerGroupDetails: OfferGroupDetail[] | undefined = React.useMemo(() => {
    return offerGroups?.map(offerGroup =>
      mapOfferGroupToOfferGroupDetail({
        t,
        isAnonymous,
        offerGroup,
        now,
        onPurchaseButtonClick: async (offerId: number) => {
          pushClickOfferBuy({
            offerGroupId: offerGroup.id,
            boardCode,
            offerId,
            offerDisplayName: offerGroup.offers.find(offer => offer.id === offerId)!.displayConfig.name,
          });
          if (isAnonymous) {
            router.push({pathname: '/onboarding/intro', query: new B2bState({productId: String(offerId)}).toQuery()});
            return;
          }
          const order = await createOfferGroupOrder({offerId});
          router.push({pathname: '/offer-group/order', query: {...searchParams, id: order.id, offerId}});
        },
        onOptionButtonClick: () => {
          pushClickOfferOption({offerGroupId: offerGroup.id, boardCode});
          router.push({pathname: '/offer-group/offer/list', query: searchParams});
        },
      })
    );
  }, [
    offerGroups,
    t,
    isAnonymous,
    now,
    pushClickOfferBuy,
    boardCode,
    createOfferGroupOrder,
    router,
    searchParams,
    pushClickOfferOption,
  ]);

  const selectedOfferGroupIndex = React.useMemo(() => {
    if (!offerGroupDetails) {
      return undefined;
    }
    const offerGroupIndex = offerGroupDetails.findIndex(offerGroup => offerGroup.id === offerGroupId);
    if (offerGroupIndex === -1) {
      throw new Error('no such offer group in current offer group board');
    }
    return offerGroupIndex;
  }, [offerGroupId, offerGroupDetails]);

  if (!offerGroupDetails || selectedOfferGroupIndex == null) {
    return <ListSkeleton onGoBack={handlePaginationClick} />;
  }

  const handleChangeOfferGroupIndex = (index: number) => {
    const prevIndex = offerGroupDetails.findIndex(({id}) => id === offerGroupId);
    const direction = index > prevIndex ? 'right' : 'left';
    const nextOfferGroupId = offerGroupDetails[index].id;

    pushSwipeOfferGroupEvent({
      boardCode,
      swipe_direction: direction,
      offerGroupId: nextOfferGroupId,
    });

    router.replace({
      pathname: '/offer-group/board/detail',
      query: {...searchParams, offerGroupId: nextOfferGroupId},
    });
  };

  return isGteLargeScreen ? (
    <OfferGroupAndOfferDetailListView
      offerGroupDetails={offerGroupDetails}
      currentOfferGroupIndex={selectedOfferGroupIndex}
      onCurrentOfferGroupIndexChange={handleChangeOfferGroupIndex}
      onBackClick={handlePaginationClick}
    />
  ) : (
    <OfferGroupDetailListView
      offerGroupDetails={offerGroupDetails}
      currentOfferGroupIndex={selectedOfferGroupIndex}
      onCurrentOfferGroupIndexChange={handleChangeOfferGroupIndex}
      onCloseClick={handlePaginationClick}
    />
  );
};

export default OfferGroupBoardDetailPageContainer;
OfferGroupBoardDetailPageContainer.displayName = 'OfferGroupBoardDetailPageContainer';
