import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {Button} from '@santa-web/service-ui';
import {OnboardingInformationArea, OnboardingLayout, OnboardingQuestionViewCommonProps} from '@app/features/onboarding';

type DomainExperienceViewProps = OnboardingQuestionViewCommonProps & {
  domainName: string;
  onAnswer: (hasExperience: boolean) => void;
};

const DomainExperienceView = ({
  isLoading = false,
  isNarrow,
  domainName,
  onAnswer,
  onBackButtonClick,
}: DomainExperienceViewProps) => {
  const {t} = useTranslation();
  return (
    <OnboardingLayout isNarrow={isNarrow} onBackButtonClick={onBackButtonClick}>
      <div
        css={css`
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        `}>
        <OnboardingInformationArea
          shouldShowAiImage
          subTitle={domainName}
          title={t('page_onboarding_domain_experience_title', {domainName})}
          description={t('page_onboarding_domain_experience_description', {domainName})}
        />
        <div
          css={css`
            display: grid;
            gap: 8px;
          `}>
          <Button isLoading={isLoading} colorVariant="brand" isFullWidth onClick={() => onAnswer(true)}>
            {t('page_onboarding_domain_experience_positive_button')}
          </Button>
          <Button isLoading={isLoading} colorVariant="brand" isFullWidth onClick={() => onAnswer(false)}>
            {t('page_onboarding_domain_experience_negative_button')}
          </Button>
        </div>
      </div>
    </OnboardingLayout>
  );
};

export default DomainExperienceView;
export type {DomainExperienceViewProps};
DomainExperienceView.displayName = 'DomainExperienceView';
