import React from 'react';
import {ErrorBoundary} from 'react-error-boundary';
import {css} from '@emotion/react';

import {use100vh} from '@app/hooks/use100vh';
import ChatContainer from '@app/test-preparation/components/chat/ChatContainer';
import ChatLayout from '@app/test-preparation/components/chat/ChatLayout';

type ChatDialogProps = {
  contentInteractionStateId: number;
  contentId: string;
  initialInputValue: string;
  onInputValueChange: (inputValue: string) => void;
  onClose: () => void;
  shouldAutoSendInitialInputValue: boolean;
};

const ChatDialog = React.forwardRef<HTMLDivElement, ChatDialogProps>(
  (
    {
      contentInteractionStateId,
      contentId,
      initialInputValue,
      onInputValueChange,
      onClose,
      shouldAutoSendInitialInputValue,
    },
    ref
  ) => {
    const viewportHeight = use100vh();
    const handleContainerClose = React.useCallback(
      (inputValue: string) => {
        onInputValueChange(inputValue);
        onClose();
      },
      [onClose, onInputValueChange]
    );

    return (
      <div
        ref={ref}
        css={css`
          position: absolute;
          top: 0;
          z-index: 50;

          width: 100%;
          height: ${viewportHeight};
        `}>
        {
          <ErrorBoundary fallback={<ChatLayout isError onClose={onClose} onRetry={onClose} />}>
            <ChatContainer
              contentInteractionStateId={contentInteractionStateId}
              contentId={contentId}
              initialInputValue={initialInputValue}
              onClose={handleContainerClose}
              shouldAutoSendInitialInputValue={shouldAutoSendInitialInputValue}
            />
          </ErrorBoundary>
        }
      </div>
    );
  }
);

export default React.memo(ChatDialog);
