import React from 'react';
import {produce} from 'immer';

import {useAtomValue} from 'jotai';
import useTestprepEvents from '@app/api/google-tag-manager/testprep';
import {learningStore} from '@app/test-preparation/atoms/stores';
import useFeatureAvailableByDomain from '@app/test-preparation/hooks/feature-available/useFeatureAvailableByDomain';
import {ITestprepConfig} from '@app/types/user-config/testprep-config';
import {SwitchableAction} from '@app/types/user-config/testprep-config/switchable';

export interface ITestprepConfigSwitchableItem {
  switchableAction: SwitchableAction;
  isEnabled: boolean;
}

export interface ITestprepConfigSwitchable {
  switchableItems: ITestprepConfigSwitchableItem[];
  getIsSwitchableActionEnabled(switchableAction: SwitchableAction): boolean;
  handleSwitchableChange(switchableAction: SwitchableAction, isEnabled: boolean): void;
}

const switchableActions: SwitchableAction[] = ['HIGHLIGHT', 'UNSOLVABLE_QUESTION'];

const getSwitchableItem = (switchableAction: SwitchableAction, isEnabled: boolean): ITestprepConfigSwitchableItem => ({
  switchableAction,
  isEnabled,
});

export const useTestprepConfigSwitchable = (
  testprepConfig: ITestprepConfig,
  setTestprepConfig: (testprepConfig: ITestprepConfig) => void
): ITestprepConfigSwitchable => {
  const {pushQuesToolChoice} = useTestprepEvents();

  let isDiagnosisCell = false;
  try {
    // NOTE: cis 페이지에서는 cell을 가져오지 않기 떄문에 오류가 발생함. 따라서 공통 hook을 사용할 수 있게 하기 위해 try-catch하도록 함
    const _isDiagnosisCell = useAtomValue(learningStore.cell.isDiagnosisAtom);
    isDiagnosisCell = _isDiagnosisCell;
  } catch (e) {}
  const isLesson = useAtomValue(learningStore.cis.isLessonAtom);
  const isVocabulary = useAtomValue(learningStore.cis.isVocabularyAtom);
  const isSwitchableAvailable = !isDiagnosisCell && !isLesson && !isVocabulary;
  const isHighlightSwitchableByDomain = useFeatureAvailableByDomain('switchable-highlight');
  const isUnsolvableSwitchableByDomain = useFeatureAvailableByDomain('switchable-unsolvable');
  const switchableActionAvailabilityMap = React.useMemo((): Record<SwitchableAction, boolean> => {
    return {
      HIGHLIGHT: isSwitchableAvailable && isHighlightSwitchableByDomain,
      UNSOLVABLE_QUESTION: isSwitchableAvailable && isUnsolvableSwitchableByDomain,
    };
  }, [isHighlightSwitchableByDomain, isSwitchableAvailable, isUnsolvableSwitchableByDomain]);

  const switchableItems = React.useMemo(
    () =>
      switchableActions
        .filter(switchableAction => switchableActionAvailabilityMap[switchableAction])
        .map(switchableAction =>
          getSwitchableItem(switchableAction, testprepConfig.switchable?.[switchableAction] ?? true)
        ),
    [switchableActionAvailabilityMap, testprepConfig.switchable]
  );

  const getIsSwitchableActionEnabled = React.useCallback(
    (switchableAction: SwitchableAction) =>
      switchableItems.find(switchableItem => switchableItem.switchableAction === switchableAction)?.isEnabled ?? false,
    [switchableItems]
  );

  const handleSwitchableChange = React.useCallback(
    (switchableAction: SwitchableAction, isEnabled: boolean) => {
      setTestprepConfig(
        produce(testprepConfig, draft => {
          draft.switchable = {
            ...(draft.switchable ?? {}),
            [switchableAction]: isEnabled,
          };
        })
      );

      pushQuesToolChoice({
        tool_type:
          switchableItems.findIndex(switchableItem => switchableItem.switchableAction === switchableAction) + 1,
      });
    },
    [setTestprepConfig, switchableItems, testprepConfig, pushQuesToolChoice]
  );

  return React.useMemo(
    () => ({
      switchableItems,
      getIsSwitchableActionEnabled,
      handleSwitchableChange,
    }),
    [getIsSwitchableActionEnabled, handleSwitchableChange, switchableItems]
  );
};
