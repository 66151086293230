import React, {useState} from 'react';
import {css} from '@emotion/react';
import {COLOR_SANTA_L} from '@riiid/design-system';
import {Swiper as SwiperClass} from 'swiper';
import {FreeMode, Mousewheel, EffectCoverflow} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';
import 'swiper/css';
import 'swiper/css/free-mode';
import 'swiper/css/mousewheel';
import 'swiper/css/effect-coverflow';
import {Typography} from '@santa-web/service-ui';

type Item = {value: number; label: string};

type WheelSlotProps = {
  items: Array<Item>;
  min?: number;
  max?: number;
  defaultValue?: number;
  shouldReverse?: boolean;
  onChange?: (item: Item) => void;
};

const noop = () => {};

const WheelSlot = ({items, min, max, defaultValue, shouldReverse = false, onChange = noop}: WheelSlotProps) => {
  const [isReady, setIsReady] = useState(false);

  const getIsWithinLimits = (item: Item) => {
    let pass = true;

    if (min !== undefined) {
      pass = item.value >= min;
    }
    if (max !== undefined) {
      pass = pass && item.value <= max;
    }

    return pass;
  };

  const filteredItems = items.filter(getIsWithinLimits);
  const sortedItems = React.useMemo(
    () => (shouldReverse ? filteredItems.slice().reverse() : filteredItems),
    [filteredItems, shouldReverse]
  );

  const initialIndex = filteredItems.findIndex(item => item.value === defaultValue);
  const normalizedIndex = shouldReverse ? items.length - initialIndex - 1 : initialIndex;

  return (
    <div
      css={css`
        height: 200px;
        width: fit-content;
        overflow: hidden;
        & > div {
          // don't know why but it doesn't work without this
          height: 100%;
        }
      `}>
      <Swiper
        slidesPerView={4} // I know UI shows 5 slides, but 4 is the best fit
        spaceBetween={10}
        initialSlide={normalizedIndex}
        direction="vertical"
        centeredSlides
        grabCursor
        touchEventsTarget="container"
        mousewheel
        modules={[Mousewheel, FreeMode, EffectCoverflow]}
        freeMode={{enabled: true, sticky: true}}
        effect="coverflow"
        coverflowEffect={{
          rotate: 25,
          stretch: 0,
          depth: 50,
          modifier: 1,
          slideShadows: false,
        }}
        /**
         * min, max값의 수정으로 인해 filteredItems의 길이가 바뀌는 경우, 기본동작은 index를 유지하게 되어있음
         * ex) 0번 인덱스가 '1'을 가리키다가, 짧아져서 '10'을 가리킬 수 있음.
         * 그런 경우에 대비해, index를 변경해주는 작업
         */
        onSlidesLengthChange={(swiper: SwiperClass) => {
          const index = filteredItems.findIndex(item => item.value === defaultValue);
          swiper.slideTo(index, 0);
        }}
        onAfterInit={() => setIsReady(true)}
        onSlideChange={(swiper: SwiperClass) => isReady && onChange(filteredItems[swiper.activeIndex])}>
        {sortedItems.map(item => (
          <SwiperSlide title={item.value.toString()} key={item.value}>
            {({isActive}) => (
              <Typography
                color={COLOR_SANTA_L}
                component="p"
                variant="h5"
                fontWeight="regular"
                css={css`
                  text-align: center;
                  opacity: ${isActive ? 1 : 0.4};
                `}>
                {item.label}
              </Typography>
            )}
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default React.memo(
  WheelSlot,
  (prev, next) => prev.min === next.min && prev.max === next.max && prev.defaultValue === next.defaultValue
);
export type {WheelSlotProps};
WheelSlot.displayName = 'WheelSlot';
