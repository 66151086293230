import { CursorPageFromJSON, CursorPageToJSON, } from './CursorPage';
import { PassListItemV2FromJSON, PassListItemV2ToJSON, } from './PassListItemV2';
/**
 * Check if a given object implements the ListMyPassResponseV2 interface.
 */
export function instanceOfListMyPassResponseV2(value) {
    let isInstance = true;
    isInstance = isInstance && "page" in value;
    isInstance = isInstance && "pass" in value;
    return isInstance;
}
export function ListMyPassResponseV2FromJSON(json) {
    return ListMyPassResponseV2FromJSONTyped(json, false);
}
export function ListMyPassResponseV2FromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'page': CursorPageFromJSON(json['page']),
        'pass': (json['pass'].map(PassListItemV2FromJSON)),
    };
}
export function ListMyPassResponseV2ToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'page': CursorPageToJSON(value.page),
        'pass': (value.pass.map(PassListItemV2ToJSON)),
    };
}
