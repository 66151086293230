import React from 'react';
import mergeRefs from 'react-merge-refs';
import {Portal} from 'react-portal';
import {useClickAway} from 'react-use';
import {css, SerializedStyles} from '@emotion/react';
import {BREAKPOINT_MEDIUM, COLOR_SANTA_CARD_A, SHADOW_A_2_DOWN} from '@riiid/design-system';

import {BottomSheetListItem, TypographyVariant, useIsGteMediumScreen} from '@santa-web/service-ui';
import BottomSheetListWithDim from '@app/components/BottomSheetListWithDim';
import {Option} from '@app/components/Dropdown';
import DropdownToggle from '@app/components/DropdownToggle';
import DropdownToggleListItem from '@app/components/DropdownToggleList/DropdownToggleListItem';
import {DROPDOWN_Z_INDEX} from '@app/constants/zIndex';

export interface DropdownToggleListProps<T extends string | number = string>
  extends React.ComponentPropsWithoutRef<'div'> {
  isOpened: boolean;
  setIsOpened: React.Dispatch<React.SetStateAction<boolean>>;
  options: Option<T>[];
  selectedOption: Option<T>;
  onValueSelect(value: T): void;
  direction?: 'top-left' | 'top-right' | 'bottom-left' | 'bottom-right';
  itemContainerCss?: SerializedStyles;
  isChevronHidden?: boolean;
  typographyVariant?: TypographyVariant;
}

const DropdownToggleList = <T extends string | number>(
  {
    isOpened,
    setIsOpened,
    options,
    selectedOption,
    onValueSelect,
    direction = 'bottom-left',
    itemContainerCss,
    isChevronHidden = false,
    typographyVariant = 'body3',
    ...props
  }: DropdownToggleListProps<T>,
  ref: React.ForwardedRef<HTMLDivElement>
) => {
  const localRef = React.useRef<HTMLDivElement>(null);
  const isGteMediumScreen = useIsGteMediumScreen();
  const handleDropdownClick = React.useCallback(() => {
    setIsOpened(prev => !prev);
  }, [setIsOpened]);

  useClickAway(localRef, e => {
    if (isOpened && isGteMediumScreen) {
      e.stopPropagation();
      setIsOpened(false);
    }
  });

  const [isBottom, isLeft] = React.useMemo(() => {
    switch (direction) {
      case 'top-left':
        return [false, true];
      case 'top-right':
        return [false, false];
      case 'bottom-left':
        return [true, true];
      case 'bottom-right':
        return [true, false];
    }
  }, [direction]);

  return (
    <div
      css={css`
        position: relative;
      `}
      ref={mergeRefs([ref, localRef])}
      {...props}>
      <DropdownToggle
        isOpened={isOpened}
        onClick={handleDropdownClick}
        isChevronHidden={isChevronHidden}
        typographyVariant={typographyVariant}>
        {selectedOption.label}
      </DropdownToggle>
      {isOpened &&
        (isGteMediumScreen ? (
          <div
            ref={localRef}
            css={[
              isBottom
                ? css`
                    top: 100%;
                    margin-top: 12px;
                  `
                : css`
                    bottom: 100%;
                    margin-bottom: 12px;
                  `,
              isLeft
                ? css`
                    left: 0;
                  `
                : css`
                    right: 0;
                  `,
              css`
                display: none;
                position: absolute;
                overflow: hidden;
                border-radius: 4px;
                z-index: ${DROPDOWN_Z_INDEX};
                box-shadow: ${SHADOW_A_2_DOWN};
                background-color: ${COLOR_SANTA_CARD_A};

                @media (min-width: ${BREAKPOINT_MEDIUM}) {
                  display: inherit;
                }
              `,
            ]}>
            <div
              css={[
                css`
                  width: 264px;
                  margin: 8px 0;
                `,
                itemContainerCss,
              ]}>
              {options.map(option => (
                <DropdownToggleListItem
                  key={option.value}
                  onClick={e => {
                    e.stopPropagation();
                    onValueSelect(option.value);
                    setIsOpened(false);
                  }}
                  isSelected={option.value === selectedOption.value}>
                  {option.label}
                </DropdownToggleListItem>
              ))}
            </div>
          </div>
        ) : (
          <Portal>
            <BottomSheetListWithDim
              css={css`
                @media (min-width: ${BREAKPOINT_MEDIUM}) {
                  display: none;
                }
              `}
              isOpened={isOpened}
              BottomSheetListProps={{
                onCloseClick: () => {
                  setIsOpened(false);
                },
                children: options.map(option => (
                  <BottomSheetListItem
                    key={option.value}
                    title={option.label}
                    isSelected={option.value === selectedOption.value}
                    onClick={e => {
                      e.stopPropagation();
                      onValueSelect(option.value);
                      setIsOpened(false);
                    }}
                  />
                )),
              }}
            />
          </Portal>
        ))}
    </div>
  );
};

export default React.memo(
  React.forwardRef<HTMLDivElement, DropdownToggleListProps>(DropdownToggleList) as <T extends string | number>(
    props: DropdownToggleListProps<T> & {ref?: React.ForwardedRef<HTMLDivElement>}
  ) => ReturnType<typeof DropdownToggleList>
) as typeof DropdownToggleList;
