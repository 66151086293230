import { ToeicVirtualExamFromJSON, ToeicVirtualExamToJSON, } from './ToeicVirtualExam';
import { ToeicVirtualExamInstanceStatusFromJSON, ToeicVirtualExamInstanceStatusToJSON, } from './ToeicVirtualExamInstanceStatus';
/**
 * Check if a given object implements the ToeicVirtualExamInstance interface.
 */
export function instanceOfToeicVirtualExamInstance(value) {
    let isInstance = true;
    isInstance = isInstance && "contentUserAnswerStates" in value;
    isInstance = isInstance && "examData" in value;
    isInstance = isInstance && "learningCellId" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "totalElapsedTimeMs" in value;
    return isInstance;
}
export function ToeicVirtualExamInstanceFromJSON(json) {
    return ToeicVirtualExamInstanceFromJSONTyped(json, false);
}
export function ToeicVirtualExamInstanceFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'contentUserAnswerStates': json['contentUserAnswerStates'],
        'examData': ToeicVirtualExamFromJSON(json['examData']),
        'learningCellId': json['learningCellId'],
        'status': ToeicVirtualExamInstanceStatusFromJSON(json['status']),
        'totalElapsedTimeMs': json['totalElapsedTimeMs'],
    };
}
export function ToeicVirtualExamInstanceToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'contentUserAnswerStates': value.contentUserAnswerStates,
        'examData': ToeicVirtualExamToJSON(value.examData),
        'learningCellId': value.learningCellId,
        'status': ToeicVirtualExamInstanceStatusToJSON(value.status),
        'totalElapsedTimeMs': value.totalElapsedTimeMs,
    };
}
