import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {COLOR_SANTA_D, COLOR_SANTA_E, COLOR_SANTA_I, COLOR_SANTA_J} from '@riiid/design-system';
import ReportErrorIcon from '@riiid/design-system/icons/ReportError';
import {Typography} from '@santa-web/service-ui';
import {Row} from '@app/components';

type CouponListInformationProps = {
  learningDomain: string;
};

const CouponListInformation = ({learningDomain}: CouponListInformationProps) => {
  const {t} = useTranslation();
  return (
    <Row
      alignItems="center"
      css={css`
        gap: 12px;
        padding: 12px;
        border-radius: 4px;
        background-color: ${COLOR_SANTA_D};
        border: 1px solid ${COLOR_SANTA_E};
      `}>
      <ReportErrorIcon
        color={COLOR_SANTA_J}
        type="line"
        css={css`
          width: 28px;
          height: 28px;
        `}
      />
      <Typography variant="caption1" color={COLOR_SANTA_I}>
        {t('page_coupon_box_list_learning_domain', {learningDomain})}
      </Typography>
    </Row>
  );
};

export default CouponListInformation;
export type {CouponListInformationProps};
CouponListInformation.displayName = 'CouponListInformation';
