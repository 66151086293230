/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { CreateLearningGoalRequestToJSON, CreateLearningGoalResponseFromJSON, GetLearningGoalResponseFromJSON, } from '../models';
/**
 *
 */
export class LearningGoalV1Api extends runtime.BaseAPI {
    /**
     * 학습 도메인 별로 학습 목표(어느 요일, 하루에 몇 분 공부할지)를 설정한다.
     * 학습 목표를 설정한다.
     */
    async createLearningGoalRaw(requestParameters, initOverrides) {
        if (requestParameters.createLearningGoalRequest === null || requestParameters.createLearningGoalRequest === undefined) {
            throw new runtime.RequiredError('createLearningGoalRequest', 'Required parameter requestParameters.createLearningGoalRequest was null or undefined when calling createLearningGoal.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/analytics/v1/learning-goal/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: CreateLearningGoalRequestToJSON(requestParameters.createLearningGoalRequest),
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CreateLearningGoalResponseFromJSON(jsonValue));
    }
    /**
     * 학습 도메인 별로 학습 목표(어느 요일, 하루에 몇 분 공부할지)를 설정한다.
     * 학습 목표를 설정한다.
     */
    async createLearningGoal(requestParameters, initOverrides) {
        const response = await this.createLearningGoalRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 학습 도메인 별로 학습 목표를 삭제한다.
     * 학습 목표를 삭제한다.
     */
    async deleteLearningGoalRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/analytics/v1/learning-goal/`,
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * 학습 도메인 별로 학습 목표를 삭제한다.
     * 학습 목표를 삭제한다.
     */
    async deleteLearningGoal(requestParameters, initOverrides) {
        await this.deleteLearningGoalRaw(requestParameters, initOverrides);
    }
    /**
     * 학습 도메인 별로 학습 목표를 조회한다.
     * 학습 목표를 조회한다.
     */
    async getLearningGoalRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/analytics/v1/learning-goal/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetLearningGoalResponseFromJSON(jsonValue));
    }
    /**
     * 학습 도메인 별로 학습 목표를 조회한다.
     * 학습 목표를 조회한다.
     */
    async getLearningGoal(requestParameters, initOverrides) {
        const response = await this.getLearningGoalRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
