function _array_like_to_array(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _array_with_holes(arr) {
    if (Array.isArray(arr)) return arr;
}
function _array_without_holes(arr) {
    if (Array.isArray(arr)) return _array_like_to_array(arr);
}
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _iterable_to_array(iter) {
    if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}
function _iterable_to_array_limit(arr, i) {
    var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];
    if (_i == null) return;
    var _arr = [];
    var _n = true;
    var _d = false;
    var _s, _e;
    try {
        for(_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true){
            _arr.push(_s.value);
            if (i && _arr.length === i) break;
        }
    } catch (err) {
        _d = true;
        _e = err;
    } finally{
        try {
            if (!_n && _i["return"] != null) _i["return"]();
        } finally{
            if (_d) throw _e;
        }
    }
    return _arr;
}
function _non_iterable_rest() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _non_iterable_spread() {
    throw new TypeError("Invalid attempt to spread non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
function _sliced_to_array(arr, i) {
    return _array_with_holes(arr) || _iterable_to_array_limit(arr, i) || _unsupported_iterable_to_array(arr, i) || _non_iterable_rest();
}
function _to_consumable_array(arr) {
    return _array_without_holes(arr) || _iterable_to_array(arr) || _unsupported_iterable_to_array(arr) || _non_iterable_spread();
}
function _unsupported_iterable_to_array(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _array_like_to_array(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _array_like_to_array(o, minLen);
}
import { useCallback, useState } from "react";
import { arrayMove } from "@dnd-kit/sortable";
export default function useMultipleContainerSortable(items, onChangeItems) {
    var _useState = _sliced_to_array(useState(), 2), activeId = _useState[0], setActiveId = _useState[1];
    var _useState1 = _sliced_to_array(useState(), 2), clonedItems = _useState1[0], setClonedItems = _useState1[1];
    var isContainerId = useCallback(function(id) {
        return id in items;
    }, [
        items
    ]);
    var findContainerId = useCallback(function(id) {
        if (isContainerId(id)) {
            return id;
        }
        return Object.keys(items).find(function(key) {
            return items[key].find(function(item) {
                return item.id === id;
            }) != null;
        });
    }, [
        isContainerId,
        items
    ]);
    var findOverContainerId = useCallback(function(id) {
        if (isContainerId(id)) {
            return undefined;
        }
        return Object.keys(items).find(function(key) {
            return items[key].every(function(item) {
                return item.id !== id;
            });
        });
    }, [
        isContainerId,
        items
    ]);
    var getIndex = useCallback(function(id) {
        var container = findContainerId(id);
        if (!container) {
            return -1;
        }
        return items[container].findIndex(function(item) {
            return item.id === id;
        });
    }, [
        findContainerId,
        items
    ]);
    var onDragCancel = useCallback(function() {
        if (clonedItems) {
            onChangeItems === null || onChangeItems === void 0 ? void 0 : onChangeItems(clonedItems);
        }
        setActiveId(undefined);
        setClonedItems(undefined);
    }, [
        clonedItems,
        onChangeItems
    ]);
    var onDragStart = useCallback(function(param) {
        var active = param.active;
        setActiveId(active.id);
        setClonedItems(items);
    }, [
        items
    ]);
    var onDragOver = useCallback(function(param) {
        var active = param.active, over = param.over;
        var overId = over === null || over === void 0 ? void 0 : over.id;
        if (!overId) {
            return;
        }
        var overContainer = findContainerId(overId);
        var activeContainer = findContainerId(active.id);
        if (!overContainer || !activeContainer) {
            return;
        }
        if (activeContainer !== overContainer) {
            var activeItems = items[activeContainer];
            var overItems = items[overContainer];
            var overIndex = overItems.findIndex(function(item) {
                return item.id === overId;
            });
            var activeIndex = activeItems.findIndex(function(item) {
                return item.id === active.id;
            });
            var newIndex;
            if (overId in items) {
                newIndex = overItems.length + 1;
            } else {
                var isBelowLastItem = over && overIndex === overItems.length - 1;
                var modifier = isBelowLastItem ? 1 : 0;
                newIndex = overIndex >= 0 ? overIndex + modifier : overItems.length + 1;
            }
            var _obj;
            var newItems = _object_spread_props(_object_spread({}, items), (_obj = {}, _define_property(_obj, activeContainer, _to_consumable_array(items[activeContainer].filter(function(item) {
                return item.id !== active.id;
            }))), _define_property(_obj, overContainer, _to_consumable_array(items[overContainer].slice(0, newIndex)).concat([
                items[activeContainer][activeIndex]
            ], _to_consumable_array(items[overContainer].slice(newIndex, items[overContainer].length)))), _obj));
            onChangeItems === null || onChangeItems === void 0 ? void 0 : onChangeItems(newItems);
        }
    }, [
        findContainerId,
        items,
        onChangeItems
    ]);
    var onDragEnd = useCallback(function(param) {
        var active = param.active, over = param.over;
        var activeContainer = findContainerId(active.id);
        if (!activeContainer) {
            setActiveId(undefined);
            return;
        }
        var overId = over === null || over === void 0 ? void 0 : over.id;
        if (overId == null) {
            onChangeItems === null || onChangeItems === void 0 ? void 0 : onChangeItems(items);
            setActiveId(undefined);
            return;
        }
        var overContainer = findContainerId(overId);
        if (activeContainer && overContainer) {
            var activeIndex = items[activeContainer].findIndex(function(item) {
                return item.id === active.id;
            });
            var overIndex = items[overContainer].findIndex(function(item) {
                return item.id === overId;
            });
            if (activeIndex !== overIndex) {
                var newItems = _object_spread_props(_object_spread({}, items), _define_property({}, overContainer, arrayMove(items[overContainer], activeIndex, overIndex)));
                onChangeItems === null || onChangeItems === void 0 ? void 0 : onChangeItems(newItems);
            }
        }
        setActiveId(undefined);
    }, [
        findContainerId,
        items,
        onChangeItems
    ]);
    var onClick = useCallback(function(id) {
        var activeContainerId = findContainerId(id);
        var overContainerId = findOverContainerId(id);
        if (!activeContainerId || !overContainerId) {
            return;
        }
        var activeItems = items[activeContainerId];
        var overItems = items[overContainerId];
        var clickedItem = activeItems.find(function(item) {
            return item.id === id;
        });
        if (!clickedItem) {
            return;
        }
        var _obj;
        var newItems = _object_spread_props(_object_spread({}, items), (_obj = {}, _define_property(_obj, activeContainerId, activeItems.filter(function(item) {
            return item.id !== clickedItem.id;
        })), _define_property(_obj, overContainerId, overItems.concat(clickedItem)), _obj));
        onChangeItems === null || onChangeItems === void 0 ? void 0 : onChangeItems(newItems);
    }, [
        findContainerId,
        findOverContainerId,
        items,
        onChangeItems
    ]);
    var activeItem = function() {
        if (activeId == null) return;
        var containerId = findContainerId(activeId);
        if (containerId == null) return;
        return items[containerId][getIndex(activeId)];
    }();
    return {
        activeId: activeId,
        activeItem: activeItem,
        onDragStart: onDragStart,
        onDragOver: onDragOver,
        onDragEnd: onDragEnd,
        onDragCancel: onDragCancel,
        onClick: onClick
    };
}
