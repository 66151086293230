import React from 'react';

import {SingleQuestionTitle} from '@santa-web/service-ui';
import DirectionContent from '@app/components/virtual-exam/VirtualExamDirectionView/components/DirectionContent';
import DirectionWrapper from '@app/components/virtual-exam/VirtualExamDirectionView/components/DirectionWrapper';

const Part5DirectionView = React.forwardRef<HTMLDivElement, React.ComponentPropsWithoutRef<'div'>>((props, ref) => {
  return (
    <DirectionWrapper {...props} ref={ref}>
      <DirectionWrapper gap={24}>
        <SingleQuestionTitle content={'READING TEST'} />
        <DirectionContent>
          In the Reading test, you will read a variety of texts and answer several different types of reading
          comprehension questions. There are three parts, and directions are given for each part. You are encouraged to
          answer as many questions as possible within the time allowed.
        </DirectionContent>
        <DirectionContent>
          You must mark your answers on the separate answer sheet. Do not write your answers in your test book.
        </DirectionContent>
      </DirectionWrapper>
      <DirectionWrapper gap={24}>
        <SingleQuestionTitle content={'PART 5'} />
        <DirectionContent>
          <b>Directions:</b> A word or phrase is missing in each of the sentences below. Four answer choices are given
          below each sentence. Select the best answer to complete the sentence. Then mark the letter (A), (B), (C) or
          (D) on your answer sheet.
        </DirectionContent>
      </DirectionWrapper>
    </DirectionWrapper>
  );
});

export default Part5DirectionView;
