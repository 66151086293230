import { OfferGroupFromJSON, OfferGroupToJSON, } from './OfferGroup';
/**
 * Check if a given object implements the OfferGroupBoard interface.
 */
export function instanceOfOfferGroupBoard(value) {
    let isInstance = true;
    isInstance = isInstance && "boardCode" in value;
    isInstance = isInstance && "country" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "learningDomainId" in value;
    isInstance = isInstance && "offerGroups" in value;
    return isInstance;
}
export function OfferGroupBoardFromJSON(json) {
    return OfferGroupBoardFromJSONTyped(json, false);
}
export function OfferGroupBoardFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'boardCode': json['boardCode'],
        'country': json['country'],
        'id': json['id'],
        'learningDomainId': json['learningDomainId'],
        'offerGroups': (json['offerGroups'].map(OfferGroupFromJSON)),
    };
}
export function OfferGroupBoardToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'boardCode': value.boardCode,
        'country': value.country,
        'id': value.id,
        'learningDomainId': value.learningDomainId,
        'offerGroups': (value.offerGroups.map(OfferGroupToJSON)),
    };
}
