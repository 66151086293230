/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { KakaoRegistrationInfoFromJSON, KakaoRegistrationInfoToJSON, } from './KakaoRegistrationInfo';
import { SignupRequestAgreementInfoFromJSON, SignupRequestAgreementInfoToJSON, } from './SignupRequestAgreementInfo';
import { UserPersonalInfoFromJSON, UserPersonalInfoToJSON, } from './UserPersonalInfo';
/**
 * Check if a given object implements the SignupWithKakaoRequest interface.
 */
export function instanceOfSignupWithKakaoRequest(value) {
    let isInstance = true;
    isInstance = isInstance && "agreementInfo" in value;
    isInstance = isInstance && "kakaoRegistrationInfo" in value;
    return isInstance;
}
export function SignupWithKakaoRequestFromJSON(json) {
    return SignupWithKakaoRequestFromJSONTyped(json, false);
}
export function SignupWithKakaoRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'agreementInfo': SignupRequestAgreementInfoFromJSON(json['agreementInfo']),
        'kakaoRegistrationInfo': KakaoRegistrationInfoFromJSON(json['kakaoRegistrationInfo']),
        'personalInfo': !exists(json, 'personalInfo') ? undefined : UserPersonalInfoFromJSON(json['personalInfo']),
    };
}
export function SignupWithKakaoRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'agreementInfo': SignupRequestAgreementInfoToJSON(value.agreementInfo),
        'kakaoRegistrationInfo': KakaoRegistrationInfoToJSON(value.kakaoRegistrationInfo),
        'personalInfo': UserPersonalInfoToJSON(value.personalInfo),
    };
}
