import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';

import {Button} from '@santa-web/service-ui';
import BottomSheetListWithDim from '@app/components/BottomSheetListWithDim';
import OfferGroupOrderCouponBottomSheetListItem, {
  OfferGroupOrderCouponBottomSheetListItemProps,
} from './OfferGroupOrderCouponBottomSheetListItem';

export interface OfferGroupOrderCouponBottomSheetListProps {
  items: (Omit<OfferGroupOrderCouponBottomSheetListItemProps, 'onClick'> & {id: number})[];
  isOpened: boolean;
  isLoading?: boolean;
  isAnyCouponSelected?: boolean;
  onApplyClick(couponIds: number[]): void;
  onCloseClick(): void;
}

const OfferGroupOrderCouponBottomSheetList = ({
  items,
  isLoading,
  isOpened,
  onApplyClick,
  onCloseClick,
}: OfferGroupOrderCouponBottomSheetListProps): JSX.Element => {
  const {t} = useTranslation();
  const [itemsDraft, setItemsDraft] = React.useState(items);

  React.useEffect(() => {
    setItemsDraft(items);
  }, [items]);

  const selectedItemIds = React.useMemo(() => items.filter(item => item.isSelected).map(item => item.id), [items]);
  const selectedItemDraftIds = React.useMemo(
    () => itemsDraft.filter(item => item.isSelected).map(item => item.id),
    [itemsDraft]
  );

  const handleCouponClick = React.useCallback((couponId: number) => {
    setItemsDraft(couponListItems =>
      couponListItems.map(couponListItem => ({...couponListItem, isSelected: couponListItem.id === couponId}))
    );
  }, []);

  const isItemsChanged = React.useMemo(
    () =>
      selectedItemIds
        .filter(itemId => !selectedItemDraftIds.includes(itemId))
        .concat(selectedItemDraftIds.filter(itemId => !selectedItemIds.includes(itemId))).length > 0,
    [selectedItemDraftIds, selectedItemIds]
  );

  const handleApplyClick = React.useCallback(() => {
    onApplyClick(itemsDraft.filter(item => item.isSelected).map(item => item.id));
  }, [itemsDraft, onApplyClick]);

  return (
    <BottomSheetListWithDim
      isOpened={isOpened}
      BottomSheetListProps={{
        css: css`
          margin-top: 60px;
        `,
        onCloseClick: onCloseClick,
        children: itemsDraft.map(item => (
          <OfferGroupOrderCouponBottomSheetListItem
            key={item.id}
            title={item.title}
            caption={item.caption}
            isSelected={item.isSelected}
            isUnavailable={item.isUnavailable}
            onClick={() => handleCouponClick(item.id)}
          />
        )),
        buttons: (
          <Button
            isLoading={isLoading}
            variant="solid"
            colorVariant="brand"
            onClick={handleApplyClick}
            isDisabled={!isItemsChanged}>
            {t('order_coupon_bottom_sheet_apply')}
          </Button>
        ),
      }}
    />
  );
};

export default React.memo(OfferGroupOrderCouponBottomSheetList);
