/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { ToeicVirtualExamEventDetailsFocusFromJSON, ToeicVirtualExamEventDetailsFocusToJSON, } from './ToeicVirtualExamEventDetailsFocus';
import { ToeicVirtualExamEventDetailsOneOfCaseFromJSON, ToeicVirtualExamEventDetailsOneOfCaseToJSON, } from './ToeicVirtualExamEventDetailsOneOfCase';
import { ToeicVirtualExamEventDetailsPlaceAnswerFromJSON, ToeicVirtualExamEventDetailsPlaceAnswerToJSON, } from './ToeicVirtualExamEventDetailsPlaceAnswer';
/**
 * Check if a given object implements the ToeicVirtualExamEventDetails interface.
 */
export function instanceOfToeicVirtualExamEventDetails(value) {
    let isInstance = true;
    isInstance = isInstance && "oneOfCase" in value;
    return isInstance;
}
export function ToeicVirtualExamEventDetailsFromJSON(json) {
    return ToeicVirtualExamEventDetailsFromJSONTyped(json, false);
}
export function ToeicVirtualExamEventDetailsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'enter': !exists(json, 'enter') ? undefined : json['enter'],
        'focus': !exists(json, 'focus') ? undefined : ToeicVirtualExamEventDetailsFocusFromJSON(json['focus']),
        'oneOfCase': ToeicVirtualExamEventDetailsOneOfCaseFromJSON(json['oneOfCase']),
        'placeAnswer': !exists(json, 'placeAnswer') ? undefined : ToeicVirtualExamEventDetailsPlaceAnswerFromJSON(json['placeAnswer']),
        'submit': !exists(json, 'submit') ? undefined : json['submit'],
    };
}
export function ToeicVirtualExamEventDetailsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'enter': value.enter,
        'focus': ToeicVirtualExamEventDetailsFocusToJSON(value.focus),
        'oneOfCase': ToeicVirtualExamEventDetailsOneOfCaseToJSON(value.oneOfCase),
        'placeAnswer': ToeicVirtualExamEventDetailsPlaceAnswerToJSON(value.placeAnswer),
        'submit': value.submit,
    };
}
