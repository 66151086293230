import {createContext, useCallback, useMemo, useState} from 'react';

import {useAtomValue} from 'jotai';
import {learningStore} from '@app/test-preparation/atoms/stores';
import {isAppBridgeAvailable} from '@app/utils/app-bridge';
import {hasIntersection} from '@app/utils/array';

export type MarkupTool = 'PENCIL' | 'ERASER' | 'NONE';

interface IMarkupToolsContext {
  selectedTool: MarkupTool;
  onSelectTool(tool: MarkupTool): void;
  canProvide: boolean;
}

const DEFAULT_MARKUP_TOOLS_VALUE: IMarkupToolsContext = {
  selectedTool: 'NONE',
  canProvide: false,
  onSelectTool: () => {},
};

const MarkupToolsContext = createContext<IMarkupToolsContext>(DEFAULT_MARKUP_TOOLS_VALUE);

export const useMarkupToolsValue = (): IMarkupToolsContext => {
  const [selectedTool, setSelectedTool] = useState<MarkupTool>('NONE');

  const isDiagnosis = useAtomValue(learningStore.cell.isDiagnosisAtom);

  const isCompleted = useAtomValue(learningStore.cis.isCompletedAtom);

  const dalc = useAtomValue(learningStore.content.dalcAtom);
  const parts = useAtomValue(learningStore.content.partsAtom);

  const handleSelectTool = useCallback((tool: MarkupTool) => {
    setSelectedTool(tool);
  }, []);

  // NOTE: 도메인 확장 시 해당 기능 확장 확인 후 대응 필요
  const canProvide =
    (isDiagnosis || !isCompleted) &&
    !hasIntersection(parts, ['p1', 'p2']) &&
    dalc !== 'sentence_completion' &&
    isAppBridgeAvailable();

  return useMemo(() => {
    return canProvide
      ? {
          selectedTool,
          canProvide: true,
          onSelectTool: handleSelectTool,
        }
      : DEFAULT_MARKUP_TOOLS_VALUE;
  }, [canProvide, handleSelectTool, selectedTool]);
};

export default MarkupToolsContext;

export const MarkupToolsContextProvider = ({children}: {children?: React.ReactNode}) => {
  const value = useMarkupToolsValue();

  return <MarkupToolsContext.Provider value={value}>{children}</MarkupToolsContext.Provider>;
};
