/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
/**
 * Check if a given object implements the SelfLearningUnitGroupMetadata interface.
 */
export function instanceOfSelfLearningUnitGroupMetadata(value) {
    let isInstance = true;
    isInstance = isInstance && "contentCount" in value;
    return isInstance;
}
export function SelfLearningUnitGroupMetadataFromJSON(json) {
    return SelfLearningUnitGroupMetadataFromJSONTyped(json, false);
}
export function SelfLearningUnitGroupMetadataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'contentCount': json['contentCount'],
        'proficiency': !exists(json, 'proficiency') ? undefined : json['proficiency'],
    };
}
export function SelfLearningUnitGroupMetadataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'contentCount': value.contentCount,
        'proficiency': value.proficiency,
    };
}
