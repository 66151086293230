import React from 'react';
import {useTranslation} from 'react-i18next';

import {useAtomValue} from 'jotai';
import {TopNavBar as TopNavBarBase, TopNavBarProps} from '@santa-web/service-ui';
import {learningStore} from '@app/test-preparation/atoms/stores';

type Props = TopNavBarProps;

const ToeicCourseQuestionTopNavBar = (props: Props): JSX.Element => {
  const {t} = useTranslation();

  const requiredCountToComplete = useAtomValue(learningStore.cell.requiredCountToCompleteAtom);
  const isDiagnosis = useAtomValue(learningStore.cell.isDiagnosisAtom);

  const currentCisIndex = useAtomValue(learningStore.cises.currentCisIndexAtom);
  const totalCisCount = useAtomValue(learningStore.cises.totalCisCountAtom);
  const totalElapsedTime = useAtomValue(learningStore.cises.totalElapsedTimeAtom);

  // 나중에 복습퀴즈일 때 총갯수가 내려오게 되면 수정 필요
  const totalCount = isDiagnosis ? requiredCountToComplete ?? 0 : totalCisCount;
  const isLastCis = currentCisIndex === totalCount - 1;

  const content = (() => {
    if (isLastCis) {
      return t('learning_cell_top_nav_bar_course_question_content_last_question');
    }
    return t('learning_cell_top_nav_bar_course_question_content_question', {
      numberOfQuestions: totalCount - currentCisIndex,
    });
  })();
  const progress = (currentCisIndex + 1) / totalCount;
  const timerSeconds = totalElapsedTime / 1000;

  return (
    <TopNavBarBase content={content} paginationIcon="exit" progress={progress} timerSeconds={timerSeconds} {...props} />
  );
};

export default React.memo(ToeicCourseQuestionTopNavBar);
