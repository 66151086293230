/**
 * Check if a given object implements the ToeicVirtualExamReportElapsedTimeStat interface.
 */
export function instanceOfToeicVirtualExamReportElapsedTimeStat(value) {
    let isInstance = true;
    isInstance = isInstance && "lcElapsedTimeMs" in value;
    isInstance = isInstance && "part5ElapsedTImeMs" in value;
    isInstance = isInstance && "part6ElapsedTImeMs" in value;
    isInstance = isInstance && "part7ElapsedTImeMs" in value;
    isInstance = isInstance && "rcElapsedTimeMs" in value;
    return isInstance;
}
export function ToeicVirtualExamReportElapsedTimeStatFromJSON(json) {
    return ToeicVirtualExamReportElapsedTimeStatFromJSONTyped(json, false);
}
export function ToeicVirtualExamReportElapsedTimeStatFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'lcElapsedTimeMs': json['lcElapsedTimeMs'],
        'part5ElapsedTImeMs': json['part5ElapsedTImeMs'],
        'part6ElapsedTImeMs': json['part6ElapsedTImeMs'],
        'part7ElapsedTImeMs': json['part7ElapsedTImeMs'],
        'rcElapsedTimeMs': json['rcElapsedTimeMs'],
    };
}
export function ToeicVirtualExamReportElapsedTimeStatToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'lcElapsedTimeMs': value.lcElapsedTimeMs,
        'part5ElapsedTImeMs': value.part5ElapsedTImeMs,
        'part6ElapsedTImeMs': value.part6ElapsedTImeMs,
        'part7ElapsedTImeMs': value.part7ElapsedTImeMs,
        'rcElapsedTimeMs': value.rcElapsedTimeMs,
    };
}
