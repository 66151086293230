import {useQuery} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';
import {ListCouponsRequest} from '@santa-web/gen/open-api/service';
import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {getCouponsQueryKey} from '@app/queryKeys';

export const useCouponsQuery = (filter: ListCouponsRequest) => {
  const {CouponService} = useAtomValue(santaOpenapiServicesAtom);

  return useQuery({
    queryKey: getCouponsQueryKey(filter),
    queryFn: () => CouponService.listCoupons({...filter}),
  });
};
