import Head from 'next/head';
import React from 'react';
import {css, Global} from '@emotion/react';

const WebviewStyles = (): JSX.Element => {
  return (
    <>
      <Head>
        {/* Disable pinch zoom */}
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, maximum-scale=1.0, minimum-scale=1, user-scalable=no, viewport-fit=cover"
        />
      </Head>
      <Global
        styles={css`
          * {
            -ms-overflow-style: none; /* IE and Edge */
            scrollbar-width: none; /* Firefox */
          }
          *::-webkit-scrollbar {
            display: none; /* for Chrome, Safari, and Opera */
          }
          *:not(input, textarea) {
            /* Prevent text selection */
            user-select: none;

            /* Prevent long tap action on links (only works in iOS Safari) */
            -webkit-touch-callout: none;
          }
        `}
      />
    </>
  );
};

export default WebviewStyles;
