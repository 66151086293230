import React from 'react';

type Network = {
  isOnline: boolean;
  stateSwitchCount: number;
};

const useNetwork = (): Network => {
  const [network, setNetwork] = React.useState<Network>({
    isOnline: window.navigator.onLine,
    stateSwitchCount: 0,
  });

  React.useEffect(() => {
    const handleOnline = () => {
      setNetwork({
        isOnline: true,
        stateSwitchCount: network.stateSwitchCount + 1,
      });
    };
    const handleOffline = () => {
      setNetwork({
        isOnline: false,
        stateSwitchCount: network.stateSwitchCount + 1,
      });
    };

    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, [network.stateSwitchCount]);

  return React.useMemo(() => network, [network]);
};

export {useNetwork};
