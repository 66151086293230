/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
/**
 * Check if a given object implements the UserPersonalInfo interface.
 */
export function instanceOfUserPersonalInfo(value) {
    let isInstance = true;
    return isInstance;
}
export function UserPersonalInfoFromJSON(json) {
    return UserPersonalInfoFromJSONTyped(json, false);
}
export function UserPersonalInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'email': !exists(json, 'email') ? undefined : json['email'],
        'name': !exists(json, 'name') ? undefined : json['name'],
        'phoneNumber': !exists(json, 'phoneNumber') ? undefined : json['phoneNumber'],
    };
}
export function UserPersonalInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'email': value.email,
        'name': value.name,
        'phoneNumber': value.phoneNumber,
    };
}
