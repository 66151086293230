import React from 'react';
import {css} from '@emotion/react';
import {COLOR_SANTA_I, COLOR_SANTA_L} from '@riiid/design-system';
import ToggleBtnDownIcon from '@riiid/design-system/icons/Togglebtndown';
import ToggleBtnUpIcon from '@riiid/design-system/icons/Togglebtnup';

import {Typography, ButtonBase, ButtonBaseProps, TypographyVariant} from '@santa-web/service-ui';

export interface DropdownToggleProps extends ButtonBaseProps {
  isOpened: boolean;
  isChevronHidden?: boolean;
  typographyVariant?: TypographyVariant;
}

const DropdownToggle = React.forwardRef<HTMLButtonElement, DropdownToggleProps>(
  ({children, isDisabled, isOpened, isChevronHidden = false, typographyVariant = 'body3', ...props}, ref) => {
    const dropdownColor = React.useMemo(() => (!isDisabled ? COLOR_SANTA_L : COLOR_SANTA_I), [isDisabled]);

    return (
      <ButtonBase
        css={css`
          display: flex;
          align-items: center;
        `}
        isDisabled={isDisabled}
        ref={ref}
        {...props}>
        <Typography variant={typographyVariant} fontWeight="bold" color={dropdownColor}>
          {children}
        </Typography>
        <div
          css={css`
            display: flex;
            margin-left: 4px;
            align-items: center;
          `}>
          {!isChevronHidden &&
            (isOpened ? (
              <ToggleBtnUpIcon width={32} height={32} color={dropdownColor} data-testid="DropdownToggle-BtnUpIcon" />
            ) : (
              <ToggleBtnDownIcon
                width={32}
                height={32}
                color={dropdownColor}
                data-testid="DropdownToggle-BtnDownIcon"
              />
            ))}
        </div>
      </ButtonBase>
    );
  }
);

export default React.memo(DropdownToggle);
