import {BrowserStorage} from '@santa-web/service-common';

export class ColorScheme {
  private static get browserStorage() {
    return new BrowserStorage<{'santa-color-scheme': 'dark' | 'light'}>(localStorage);
  }

  static getFromStorage() {
    return this.browserStorage.getItem('santa-color-scheme');
  }

  static setToStorage(colorScheme?: 'dark' | 'light') {
    if (colorScheme) {
      this.browserStorage.setItem('santa-color-scheme', colorScheme);
    } else {
      this.browserStorage.removeItem('santa-color-scheme');
    }
  }

  static getFromRootElement() {
    const data = document.documentElement.getAttribute('data-color-scheme');
    return data ? (data as 'dark' | 'light') : undefined;
  }

  static setToRootElement(colorScheme?: 'dark' | 'light') {
    const root = document.documentElement;
    const key = 'data-color-scheme';
    if (colorScheme) {
      root.setAttribute(key, colorScheme);
    } else {
      root.removeAttribute(key);
    }
  }
}
