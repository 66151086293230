import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {Button, RadioButton} from '@santa-web/service-ui';
import {OnboardingInformationArea, OnboardingLayout} from '@app/features/onboarding';

type LanguageSelectionViewProps = {
  currentLanguageKey: string;
  languageMap: Record<string, string>;
  onConfirm: (languageKey: string) => Promise<void>;
  onClose: () => void;
};

const LanguageSelectionView = React.forwardRef<HTMLDivElement, LanguageSelectionViewProps>(
  ({currentLanguageKey, languageMap, onConfirm, onClose, ...rest}, ref) => {
    const {t} = useTranslation();
    const [isLoading, setIsLoading] = React.useState(false);
    const [selectedLanguageKey, setSelectedLanguageKey] = React.useState<string>(currentLanguageKey);
    return (
      <OnboardingLayout
        onBackButtonClick={onClose}
        bottomArea={
          <Button
            isLoading={isLoading}
            variant="bottom-fix"
            colorVariant="brand"
            isFullWidth
            onClick={() => {
              setIsLoading(true);
              onConfirm(selectedLanguageKey).finally(() => setIsLoading(false));
            }}>
            {t('component_language_selection_modal_confirm_button')}
          </Button>
        }
        ref={ref}
        {...rest}>
        <OnboardingInformationArea
          subTitle={t('component_language_selection_modal_sub_title')}
          title={t('component_language_selection_modal_title')}
          description={t('component_language_selection_modal_sub_description')}
        />
        <div
          css={css`
            margin-top: 40px;
            display: grid;
            gap: 12px;
          `}>
          {Object.entries(languageMap).map(([key, text]) => (
            <RadioButton
              variant="brand"
              key={key}
              name={key}
              value={key}
              checked={selectedLanguageKey === key}
              onClick={() => setSelectedLanguageKey(key)}
              label={text}
            />
          ))}
        </div>
      </OnboardingLayout>
    );
  }
);

export default LanguageSelectionView;
export type {LanguageSelectionViewProps};
LanguageSelectionView.displayName = 'LanguageSelectionView';
