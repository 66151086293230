import React from 'react';
import {useTranslation} from 'react-i18next';

import {LearningCellReport} from '@santa-web/gen/open-api/content-learning-service';
import ResponsiveTimeCard from '@app/components/card/ResponsiveTimeCard';
import ChartItem from '@app/pages/learning-cell/report/components/ChartItem';
import ChartWrapperCard from '@app/pages/learning-cell/report/components/ChartWrapperCard';
import LearningCardList from '@app/pages/learning-cell/report/components/LearningCardList';
import ReportViewWrapper, {ReportViewWrapperProps} from '@app/pages/learning-cell/report/components/ReportViewWrapper';

export interface ExplanationViewProps extends ReportViewWrapperProps {
  report: LearningCellReport;
  contentsView: React.ReactNode;
}

const ExplanationView = React.forwardRef<HTMLDivElement, ExplanationViewProps>(
  ({report, contentsView, ...props}, ref) => {
    const {totalElapsedTime, recommendedElapsedTime, questionCorrectnessInfo} = report;

    const {t} = useTranslation();

    return (
      <ReportViewWrapper ref={ref} {...props}>
        <LearningCardList>
          <ResponsiveTimeCard seconds={totalElapsedTime.seconds} />
          <ChartWrapperCard>
            <ChartItem
              title={t('learning_cell_report_number_of_correct_answers')}
              type="count"
              currentNumber={questionCorrectnessInfo?.correctCount ?? 0}
              totalNumber={questionCorrectnessInfo?.totalCount ?? 0}
            />
            {recommendedElapsedTime?.seconds && (
              <ChartItem
                title={t('learning_cell_report_elapsed_time')}
                type="time"
                currentNumber={totalElapsedTime.seconds}
                totalNumber={recommendedElapsedTime.seconds}
              />
            )}
          </ChartWrapperCard>
        </LearningCardList>
        {contentsView}
      </ReportViewWrapper>
    );
  }
);

export default React.memo(ExplanationView);
