import React from 'react';

import {useAtomValue} from 'jotai';
import {TopNavBarProps} from '@santa-web/service-ui';

import {learningStore} from '@app/test-preparation/atoms/stores';
import ToeicCourseTopNavBar from './ToeicCourseTopNavBar';
import ToeicSelfTopNavBar from './ToeicSelfTopNavBar';

export interface ToeicSelfTopNavBarProps extends TopNavBarProps {
  onExitClick?(): void;
}

function ToeicTopNavBar({onExitClick, ...props}: ToeicSelfTopNavBarProps): React.ReactElement | null {
  const isSelfLearning = useAtomValue(learningStore.cell.isSelfLearningAtom);

  if (isSelfLearning) {
    return <ToeicSelfTopNavBar onPaginationIconClick={onExitClick} {...props} />;
  }

  return <ToeicCourseTopNavBar onPaginationIconClick={onExitClick} {...props} />;
}

export default React.memo(ToeicTopNavBar);
