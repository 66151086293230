import React from 'react';
import {COLOR_SANTA_CARD_A, COLOR_SANTA_G} from '@riiid/design-system';

import {Typography} from '@santa-web/service-ui';

const RadarChartRadiusTick = ({
  x,
  y,
  payload: {value},
}: {
  x: number;
  y: number;
  payload: {
    value: number;
  };
}): React.ReactElement<SVGElement> => {
  const whiteBackgroundWidth = value === 100 ? 15.5 : 12.5;
  return (
    <g>
      {value ? (
        <>
          <rect
            x={x - whiteBackgroundWidth}
            y={y - 9}
            width={whiteBackgroundWidth * 2}
            height="18"
            fill={COLOR_SANTA_CARD_A}
          />
          <Typography
            component="text"
            fontWeight="regular"
            variant="caption2"
            fill={COLOR_SANTA_G}
            x={x}
            y={y + 4}
            textAnchor="middle">
            {value ? `${value}%` : null}
          </Typography>
        </>
      ) : null}
    </g>
  );
};

export default RadarChartRadiusTick;
