import {atom} from 'jotai';
import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';

const getCis = atom(async get => {
  const {ContentInteractionStateService} = await get(santaOpenapiServicesAtom);

  return async (cisId: number) =>
    await ContentInteractionStateService.getContentInteractionState({
      contentInteractionStateId: cisId,
    });
});

export {getCis};
