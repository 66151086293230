import {useMutation} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';

import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {UnderlineMarkupInfo} from '@app/test-preparation/types/underlineMarkup';

const useCreateUnderlineMarkups = (cisId: number | null | undefined) => {
  const {UnderlineService} = useAtomValue(santaOpenapiServicesAtom);

  return useMutation(async (underlines: UnderlineMarkupInfo[]) => {
    if (cisId == null) {
      return;
    }

    await UnderlineService.createUnderline({
      createUnderlineRequest: {
        contentInteractionStateId: cisId,
        createUnderlineInfo: underlines.map(underline => ({
          displayInfo: JSON.stringify({
            snippetViewId: underline.snippetViewId,
            highlight: underline.highlight,
            text: underline.text,
          }),
        })),
      },
    });
  });
};

export default useCreateUnderlineMarkups;
