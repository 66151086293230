import {useRouter} from 'next/router';
import React from 'react';
import {useAtomValue} from 'jotai';
import learningDomainAtom from '@app/atoms/core/learning-domain';
import {useUpdateUserProfileOnboardingInfoMutation} from '@app/features/onboarding/hooks';
import {onboardingInformationFactory} from '@app/features/onboarding/utils';
import {TargetScoreChangingView} from '@app/features/onboarding/views';
import useUserProfileQuery from '@app/hooks/user/useUserProfile';

const TargetScoreChangingContainer = () => {
  const router = useRouter();
  const learningDomain = useAtomValue(learningDomainAtom);
  const {data: profile} = useUserProfileQuery();

  const {mutateAsync: updateMyOnboardingInfo, isLoading} = useUpdateUserProfileOnboardingInfoMutation();

  const onboardingInformation = onboardingInformationFactory(learningDomain.key);

  const handleTargetScoreSelection = async (targetScore: number) => {
    await updateMyOnboardingInfo({targetScore});
  };

  return (
    <TargetScoreChangingView
      isLoading={isLoading}
      currentEstimatedScore={profile?.currentEstimatedScore}
      defaultTargetScore={profile?.onboardingInfo.targetScore ?? onboardingInformation.defaultTargetScore}
      targetScoreItems={onboardingInformation.targetScoreItems}
      onPick={handleTargetScoreSelection}
      onBackButtonClick={router.back}
    />
  );
};

export default TargetScoreChangingContainer;
TargetScoreChangingContainer.displayName = 'TargetScoreChangingContainer';
