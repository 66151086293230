/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { UserOnboardingStatusFromJSON, UserOnboardingStatusToJSON, } from './UserOnboardingStatus';
/**
 * Check if a given object implements the UserOnboardingInfo interface.
 */
export function instanceOfUserOnboardingInfo(value) {
    let isInstance = true;
    isInstance = isInstance && "status" in value;
    return isInstance;
}
export function UserOnboardingInfoFromJSON(json) {
    return UserOnboardingInfoFromJSONTyped(json, false);
}
export function UserOnboardingInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'hasExperience': !exists(json, 'hasExperience') ? undefined : json['hasExperience'],
        'status': UserOnboardingStatusFromJSON(json['status']),
        'targetScore': !exists(json, 'targetScore') ? undefined : json['targetScore'],
    };
}
export function UserOnboardingInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'hasExperience': value.hasExperience,
        'status': UserOnboardingStatusToJSON(value.status),
        'targetScore': value.targetScore,
    };
}
