import React from 'react';
import {css} from '@emotion/react';

import {Vocabulary} from '@santa-web/gen/open-api/content/models/Vocabulary';
import {VocaLayout, VocaLayoutProps} from '@santa-web/service-ui';
import FullVocaView from '@app/components/ContentView/components/FullVocaView';

export interface BaseVocaViewProps extends VocaLayoutProps {
  type: 'word' | 'meaning' | 'full';
  isDisabled?: boolean;
  vocabulary?: Vocabulary | null;
  floatingArea?: JSX.Element;
}

const BaseVocaView = ({type, vocabulary, isDisabled, floatingArea, ...props}: BaseVocaViewProps) => {
  const meaning =
    vocabulary && vocabulary.vocaPosSet.length !== 0
      ? vocabulary?.vocaPosSet.map(pos => pos.meaning).join(' / ')
      : vocabulary?.meaning;

  const view = (() => {
    switch (type) {
      case 'word':
        return <VocaLayout word={vocabulary?.word} {...props} />;
      case 'meaning':
        return <VocaLayout meaning={meaning} {...props} />;
      case 'full':
        return <FullVocaView isDisabled={isDisabled} vocabulary={vocabulary} {...props} />;
    }
  })();

  if (type === 'full') {
    return (
      <div
        css={css`
          display: flex;
          flex-direction: column;
          min-height: 100%;
        `}>
        <div
          css={css`
            flex: 1;
          `}>
          {view}
          {floatingArea}
        </div>
      </div>
    );
  } else {
    return (
      <div
        css={css`
          position: relative;
          height: 100%;
        `}>
        {view}
        <div
          css={css`
            position: absolute;
            bottom: 0;

            width: 100%;
          `}
        />
      </div>
    );
  }
};

export default React.memo(BaseVocaView);
