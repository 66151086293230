import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {BREAKPOINT_MEDIUM, COLOR_SANTA_C} from '@riiid/design-system';

import {TabNav, TabNavButton, TopNavBar} from '@santa-web/service-ui';
import {FrameLayout, ScrollLayout} from '@app//components';
import {use100vh} from '@app/hooks/use100vh';

export type EventListPageTab = 'active' | 'inactive';

type EventListPageLayoutProps = {
  tab: EventListPageTab;
  children: React.ReactNode;
  onExit: () => void;
  onTabChange: (tab: EventListPageTab) => void;
};

const EventListPageLayout = ({tab, children, onExit, onTabChange}: EventListPageLayoutProps) => {
  const {t} = useTranslation();
  const height = use100vh();
  return (
    <div
      css={css`
        height: ${height};
        background-color: ${COLOR_SANTA_C};
      `}>
      <FrameLayout topNavBar={<TopNavBar paginationIcon="back" onPaginationIconClick={onExit} />}>
        <div
          css={css`
            height: 100%;
            display: flex;
            justify-content: center;
          `}>
          <div
            css={css`
              display: flex;
              flex-direction: column;
              width: 100%;
              max-width: ${BREAKPOINT_MEDIUM};
              margin: 0 20px;
              @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
                margin: 0 40px;
              }
            `}>
            <TabNav
              css={css`
                margin: 0 -20px;
                @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
                  margin: 0 -40px;
                }
              `}>
              <TabNavButton isSelected={tab === 'active'} onClick={() => onTabChange('active')}>
                {t('event_info_active')}
              </TabNavButton>
              <TabNavButton isSelected={tab === 'inactive'} onClick={() => onTabChange('inactive')}>
                {t('event_info_end')}
              </TabNavButton>
            </TabNav>
            <ScrollLayout
              css={css`
                padding: 40px 0;
              `}>
              {children}
            </ScrollLayout>
          </div>
        </div>
      </FrameLayout>
    </div>
  );
};

export default EventListPageLayout;
export type {EventListPageLayoutProps};
EventListPageLayout.displayName = 'EventListPageLayout';
