import {atom} from 'jotai';
import {setAtomLabels} from '@app/misc/atom-label';
import {ServiceNotInitializedError} from '@app/test-preparation/atoms/services/shared/errors/not-initialized';

const _learningCellIdAtom = atom<number | null>(null);
const learningCellIdAtom = atom(
  get => {
    const learningCellId = get(_learningCellIdAtom);

    if (learningCellId === null) {
      throw new ServiceNotInitializedError('CisService');
    }

    return learningCellId;
  },
  (get, set, learningCellId: number) => {
    set(_learningCellIdAtom, learningCellId);
  }
);

export {learningCellIdAtom};

setAtomLabels(
  {
    _learningCellIdAtom,
    learningCellIdAtom,
  },
  'cisService.'
);
