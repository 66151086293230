import React from 'react';
import {css} from '@emotion/react';
import {COLOR_SANTA_BD, COLOR_SANTA_L} from '@riiid/design-system';
import Time from '@riiid/design-system/icons/Time';

import {Typography} from '@santa-web/service-ui';
import BaseCard from '@app/components/card/BaseCard';
import {formatSeconds} from '@app/utils/text';

export interface AnalyticsTimeCardProps extends Omit<React.ComponentPropsWithoutRef<'div'>, 'title'> {
  title?: string | null;
  time?: number | null;
}

const AnalyticsTimeCard = React.forwardRef<HTMLDivElement, AnalyticsTimeCardProps>(({title, time, ...props}, ref) => {
  return (
    <BaseCard
      css={css`
        padding: 20px;
        min-height: 211px;
      `}
      {...props}
      ref={ref}>
      <Typography
        css={css`
          margin-bottom: 40px;
        `}
        fontWeight="bold"
        variant="body2"
        color={COLOR_SANTA_L}>
        {title}
      </Typography>
      <div
        css={css`
          display: inline-flex;
          align-items: center;
          justify-content: center;
          width: 100%;
        `}>
        <Time
          width={36}
          height={36}
          type="solid"
          color={COLOR_SANTA_BD}
          css={css`
            margin-right: 12px;
          `}
        />
        <Typography fontWeight="regular" variant="h4" color={COLOR_SANTA_L}>
          {formatSeconds(time ?? 0, 'hht : mm : ss')}
        </Typography>
      </div>
    </BaseCard>
  );
});

export default React.memo(AnalyticsTimeCard);
