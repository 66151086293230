import React from 'react';
import {css} from '@emotion/react';
import {COLOR_FIX_SPACE_BLUE_GRAY_7} from '@riiid/design-system';
import {URL_GIF_CLOCK} from '@riiid/design-system/lotties/santa';
import {LottieAnimation, Typography} from '@santa-web/service-ui';
import CountdownNumber from './CountdownNumber';

type CountdownTimerProps = {hour: number; minute: number; second: number};

const CountdownTimer = ({hour, minute, second}: CountdownTimerProps) => {
  return (
    <div
      role="timer"
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;
      `}>
      <div
        css={css`
          width: 40px;
          height: 40px;
          margin-right: 12px;
        `}>
        <LottieAnimation aria-hidden="true" src={URL_GIF_CLOCK} width={40} height={40} loop />
      </div>
      <p
        css={css`
          display: flex;
          align-items: center;
          justify-content: center;
          gap: 4px;
          margin: 0;
        `}>
        <CountdownNumber value={hour} />
        <Typography variant="h4" color={COLOR_FIX_SPACE_BLUE_GRAY_7} component="span">
          :
        </Typography>
        <CountdownNumber value={minute} />
        <Typography variant="h4" color={COLOR_FIX_SPACE_BLUE_GRAY_7} component="span">
          :
        </Typography>
        <CountdownNumber value={second} />
      </p>
    </div>
  );
};

export default CountdownTimer;
export type {CountdownTimerProps};
CountdownTimer.displayName = 'CountdownTimer';
