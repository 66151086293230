import React, {useMemo} from 'react';
import {css} from '@emotion/react';

import {QuestionState} from '@santa-web/gen/open-api/content-learning-service';
import {Question} from '@santa-web/gen/open-api/content/models/Question';
import {QuestionSet} from '@santa-web/gen/open-api/content/models/QuestionSet';
import {DocumentBlockElements} from '@santa-web/gen/ssp/ubershape/riiid-rich-text-v2';
import {SingleQuestionTitle, useFloatingChatButtonList} from '@santa-web/service-ui';
import {getQuestionTitle} from '@app/components/ContentView/utilities';
import {VirtualExamFloatingChatButtonBox} from '@app/components/virtual-exam';
import useFloatingAreaHeight from '@app/hooks/useFloatingAreaHeight';
import QuestionView from '@app/test-preparation/components/QuestionView';
import SingleColumnLayout from '@app/test-preparation/components/SingleColumnLayout';

export interface VirtualExamPart1To2ViewProps {
  isPart2: boolean;
  contentId: string;
  questionSet: QuestionSet;
  questionStates: QuestionState[];
  questionStartNumber: number;
  onQuestionAnswerStateChange(questionState: QuestionState): void | Promise<void>;
}

const fillToeicPart1To2Choices = (question: Question): Question => {
  const documentBlockElements: DocumentBlockElements = {
    children: [
      [
        'paragraph',
        {
          children: [['text', {value: 'Mark your answer'}]],
        },
      ],
    ],
  };
  const questionObjective = question.oneOfTypeSpec.objective;
  return {
    ...question,
    oneOfTypeSpec: {
      oneOfCase: 'OBJECTIVE',
      objective: {
        choiceTranslations: [],
        oneOfAnswers: {
          oneOfCase: 'AND_ANSWER',
        },
        ...questionObjective,
        choices:
          questionObjective?.choices.map(() => ({
            oneOfData: {
              oneOfCase: 'RRT_V2_BLOCK_ELEMENTS_JSON',
              rrtV2BlockElementsJson: JSON.stringify(documentBlockElements),
            },
          })) ?? [],
      },
    },
  };
};

const VirtualExamPart1To2View = ({
  isPart2,
  contentId,
  questionSet,
  questionStates,
  questionStartNumber,
  onQuestionAnswerStateChange,
}: VirtualExamPart1To2ViewProps) => {
  if (questionSet.questions.length !== 1) throw new Error('A length of questions for part 1 and part 2 must be 1');
  if (questionStates.length !== 1) throw new Error('A length of questionStates for part 1 and part 2 must be 1');

  const question = questionSet.questions[0];
  const questionState = questionStates[0];

  const {floatingChatButtonList} = useFloatingChatButtonList();
  const floatingAreaHeight = useFloatingAreaHeight();

  const toeicPart1To2Question = useMemo(() => {
    let _question: Question = question;
    if (isPart2) {
      _question = {..._question, description: undefined} as unknown as Question;
    }
    _question = fillToeicPart1To2Choices(_question);
    return _question;
  }, [isPart2, question]);

  return (
    <SingleColumnLayout>
      <div
        css={css`
          flex: 1;
          margin-bottom: ${floatingAreaHeight}px;
        `}>
        <QuestionView
          isCompleted={false}
          contentId={contentId}
          isExplanationInvisible
          questionTitle={<SingleQuestionTitle content={getQuestionTitle(questionStartNumber)} />}
          question={toeicPart1To2Question}
          questionState={questionState}
          onChange={onQuestionAnswerStateChange}
          canSelectUnsolvableQuestion={false}
        />
      </div>
      <VirtualExamFloatingChatButtonBox>{floatingChatButtonList}</VirtualExamFloatingChatButtonBox>
    </SingleColumnLayout>
  );
};

export default React.memo(VirtualExamPart1To2View);
