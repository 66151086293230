import { HighlightFromJSON, HighlightToJSON, } from './Highlight';
/**
 * Check if a given object implements the CreateHighlightsResponse interface.
 */
export function instanceOfCreateHighlightsResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "highlights" in value;
    return isInstance;
}
export function CreateHighlightsResponseFromJSON(json) {
    return CreateHighlightsResponseFromJSONTyped(json, false);
}
export function CreateHighlightsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'highlights': (json['highlights'].map(HighlightFromJSON)),
    };
}
export function CreateHighlightsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'highlights': (value.highlights.map(HighlightToJSON)),
    };
}
