import React from 'react';
import {css} from '@emotion/react';

import {Label} from '@santa-web/service-ui';

export interface TagListProps extends React.HTMLAttributes<HTMLDivElement> {
  tags: string[];
}

const TagList = React.forwardRef<HTMLDivElement, TagListProps>(({tags, ...props}, ref) => (
  <div
    css={css`
      > * + * {
        margin-left: 4px;
      }
    `}
    ref={ref}
    {...props}>
    {tags.map((tag, index) => (
      <Label key={index} colorVariant="gray-fix" variant="small">
        {tag}
      </Label>
    ))}
  </div>
));

export default React.memo(TagList);
