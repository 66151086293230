import {ContentInteractionState} from '@santa-web/gen/open-api/content-learning-service';
import {VocaExample} from '@santa-web/gen/open-api/content/models/VocaExample';
import {VocaPoS} from '@santa-web/gen/open-api/content/models/VocaPoS';
import {getStatesByCase} from '@app/facade/content-interaction-state';
import {IContentInteractionStateWithContent} from '@app/types/santa-service-protocol';

export function getExampleVoiceResourceId(
  vocabularyLike: Pick<VocaExample, 'voiceResourceId' | 'accentResourceId'> | null | undefined
): string | null | undefined {
  if (vocabularyLike == null) return;

  const {voiceResourceId, accentResourceId} = vocabularyLike;
  const {enUs, enGb} = accentResourceId ?? {};

  return voiceResourceId || enUs || enGb;
}

export function getCurrentCycleContentInteractions(
  contentInteractionStateWithContents: IContentInteractionStateWithContent[]
) {
  // 아니라면 모르는 단어만
  // 모르는단어가 없다면 그냥 또 전체
  const isEverySolved = contentInteractionStateWithContents.every(
    ({contentInteractionState}) =>
      contentInteractionState.elementInteractionStates[0].details.voca?.selfReports.length ?? 0 > 0
  );

  // STEP1 (다 풀지 못했다면) -> 전체 반환
  if (!isEverySolved) {
    return contentInteractionStateWithContents;
  }

  const isEveryKnowledge = contentInteractionStateWithContents.every(({contentInteractionState}) =>
    isKnowledge(contentInteractionState)
  );

  // STEP2 전체 복습 (모든 단어를 안다면) -> 전체 반환
  if (isEveryKnowledge) {
    return contentInteractionStateWithContents;
  }

  return contentInteractionStateWithContents.filter(
    ({contentInteractionState}) => !isKnowledge(contentInteractionState)
  );
}

export const getCurrentCycleFirstContentInteractionStateIndex = (
  contentInteractionStateWithContents: IContentInteractionStateWithContent[]
) => {
  const currentVocaCycleContentInteractionState = getCurrentCycleContentInteractions(
    contentInteractionStateWithContents
  );

  return (
    contentInteractionStateWithContents.findIndex(
      cis => currentVocaCycleContentInteractionState[0]?.contentInteractionState?.id === cis.contentInteractionState?.id
    ) ?? -1
  );
};
export function getPosVoiceResourceId(
  vocabularyLike: Pick<VocaPoS, 'accentResourceId'> | null | undefined
): string | null | undefined {
  if (vocabularyLike == null) return;

  const {enUs, enGb} = vocabularyLike.accentResourceId;

  return enUs || enGb;
}

/**
 * @description 풀지 않은 ContentInteractionState의 Step을 반환합니다
 */
export const getUnsolvedVocaStep = (contentInteractionState: ContentInteractionState | null | undefined) => {
  const statesByCase = getStatesByCase(contentInteractionState);
  if (statesByCase.oneOfCase !== 'VOCA') {
    return null;
  }

  const steps = statesByCase.states.flatMap(state => state.selfReports.map(report => report.vocaOngoingStep));

  if (steps.length === 0) {
    return 'STEP1' as const;
  }

  return 'STEP2' as const;
};

/**
 * @description 푼 ContentInteractionState의 Step을 반환합니다
 */
export const getSolvedVocaStep = (contentInteractionState: ContentInteractionState | null | undefined) => {
  const statesByCase = getStatesByCase(contentInteractionState);
  if (statesByCase.oneOfCase !== 'VOCA') {
    return null;
  }

  const steps = statesByCase.states.flatMap(state => state.selfReports.map(report => report.vocaOngoingStep));

  if (steps.includes('STEP2')) {
    return 'STEP2';
  }

  return 'STEP1';
};

export const isKnowledge = (contentInteractionState: ContentInteractionState | null | undefined): boolean => {
  return (
    contentInteractionState?.elementInteractionStates[0].details.voca?.selfReports.reduce<boolean>((prev, curr) => {
      return curr.isKnowledge != undefined ? curr.isKnowledge : prev;
    }, false) ?? false
  );
};

export const getLastSelfReport = (contentInteractionState: ContentInteractionState | null | undefined) => {
  const selfReports = contentInteractionState?.elementInteractionStates[0].details.voca?.selfReports;
  if (selfReports == null || selfReports.length === 0) {
    return null;
  }

  return selfReports[selfReports.length - 1];
};

export function getCurrentVocaSessionCiscs(
  ciscs: IContentInteractionStateWithContent[] | undefined,
  currentSession: number
) {
  if (ciscs === undefined) {
    return [];
  }

  if (
    currentSession === 0 &&
    ciscs.every(
      ({contentInteractionState}) =>
        contentInteractionState.elementInteractionStates[0].details.voca?.selfReports.length === 1
    ) === true
  ) {
    return ciscs ?? [];
  }

  if (currentSession < 1) {
    return ciscs ?? [];
  }

  const isEveryVocaKnowledge = ciscs.every(
    ({contentInteractionState}) =>
      contentInteractionState.elementInteractionStates[0].details.voca?.selfReports[currentSession - 1].isKnowledge !==
      false
  );

  if (isEveryVocaKnowledge) {
    return ciscs ?? [];
  }

  return (
    ciscs.filter(({contentInteractionState}) => {
      return (
        contentInteractionState.elementInteractionStates[0].details.voca?.selfReports[currentSession - 1]
          .isKnowledge === false
      );
    }) ?? []
  );
}

export const getCurrentVocaSession = (ciscs: IContentInteractionStateWithContent[] | undefined | null) => {
  return (
    Math.max(
      ...(ciscs
        ?.filter(
          ({contentInteractionState}) =>
            contentInteractionState.elementInteractionStates[0].details.oneOfCase === 'VOCA'
        )
        .map(
          ({contentInteractionState}) =>
            contentInteractionState.elementInteractionStates[0].details.voca?.selfReports.length ?? 0
        ) ?? [])
    ) - 1
  );
};
