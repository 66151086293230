import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {COLOR_SANTA_DD, COLOR_SANTA_H, COLOR_SANTA_J, COLOR_SANTA_L, SHADOW_A_2_DOWN} from '@riiid/design-system';
import WarningIcon from '@riiid/design-system/icons/Warning';
import {format} from 'date-fns';
import {Typography} from '@santa-web/service-ui';
import {BaseCard, Column, Row} from '@app/components';
import {OfferGroupOrderCardLabelList, OfferGroupOrderCardLabelListProps} from '@app/features/offer/components/order';

type OfferGroupOrderCardProps = OfferGroupOrderCardLabelListProps & {
  subTitle: string;
  title: string;
  purchasedAt: Date;
  subscriptionAdditionalDate?: Date;
  onClick: () => void;
};

const formatDate = (date: Date) => format(date, 'yyyy.MM.dd HH:mm:ss');

const OfferGroupOrderCard = ({domains, subTitle, title, purchasedAt, onClick, ...props}: OfferGroupOrderCardProps) => {
  const {t} = useTranslation();
  const hasError = props.isSubscription && props.subscriptionStatus === 'failed';
  const subscriptionDateInfo = React.useMemo(() => {
    if (props.isSubscription) {
      switch (props.subscriptionStatus) {
        case 'normal':
          return {
            label: t('component_offer_group_order_card_subscription_label_normal'),
            value: props.subscriptionAdditionalDate ? formatDate(props.subscriptionAdditionalDate) : '-',
          };
        case 'canceled':
          return {
            label: t('component_offer_group_order_card_subscription_label_canceled'),
            value: props.subscriptionAdditionalDate ? formatDate(props.subscriptionAdditionalDate) : '-',
          };
        case 'refunded':
        case 'expired':
          return {
            label: t('component_offer_group_order_card_subscription_label_expired'),
            value: props.subscriptionAdditionalDate ? formatDate(props.subscriptionAdditionalDate) : '-',
          };
        case 'failed':
          return {
            label: t('component_offer_group_order_card_subscription_label_failed'),
            value: t('component_offer_group_order_card_error_label'),
          };
      }
    }
    return undefined;
  }, [props, t]);

  return (
    <BaseCard
      css={css`
        padding: 20px;
        @media screen and (hover: hover) {
          :hover {
            box-shadow: ${SHADOW_A_2_DOWN};
          }
        }
      `}
      onClick={onClick}>
      <div
        css={css`
          display: grid;
          gap: 16px;
        `}>
        <OfferGroupOrderCardLabelList domains={domains} {...props} />
        <div>
          <Typography
            css={css`
              margin-bottom: 4px;
            `}
            component="p"
            variant="caption1"
            color={COLOR_SANTA_L}>
            {subTitle}
          </Typography>
          <Typography variant="body1" fontWeight="bold" color={COLOR_SANTA_L}>
            {title}
          </Typography>
        </div>
        <Column gap={4}>
          <Row gap={20} justifyContent="space-between" alignItems="center">
            <Typography component="p" variant="caption1" color={COLOR_SANTA_J}>
              {t('component_offer_group_order_card_one_time_label_default')}
            </Typography>
            <Typography component="p" variant="caption1" color={COLOR_SANTA_H}>
              {formatDate(purchasedAt)}
            </Typography>
          </Row>
          {subscriptionDateInfo && (
            <Row gap={20} justifyContent="space-between" alignItems="center">
              <Typography component="p" variant="caption1" color={COLOR_SANTA_J}>
                {subscriptionDateInfo.label}
              </Typography>
              <Typography component="p" variant="caption1" color={hasError ? COLOR_SANTA_DD : COLOR_SANTA_H}>
                {subscriptionDateInfo.value}
              </Typography>
            </Row>
          )}
        </Column>
      </div>
      {hasError && (
        <div
          css={css`
            margin-top: 24px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            gap: 4px;
          `}>
          <Typography component="p" variant="caption1" color={COLOR_SANTA_DD}>
            {t('component_offer_group_order_card_error_description')}
          </Typography>
          <WarningIcon width={20} height={20} color={COLOR_SANTA_DD} />
        </div>
      )}
    </BaseCard>
  );
};

export default OfferGroupOrderCard;
export type {OfferGroupOrderCardProps};
OfferGroupOrderCard.displayName = 'OfferGroupOrderCard';
