/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { OrderPassFromJSON, OrderPassToJSON, } from './OrderPass';
import { OrderTicketGroupFromJSON, OrderTicketGroupToJSON, } from './OrderTicketGroup';
/**
 * Check if a given object implements the PermitItems interface.
 */
export function instanceOfPermitItems(value) {
    let isInstance = true;
    return isInstance;
}
export function PermitItemsFromJSON(json) {
    return PermitItemsFromJSONTyped(json, false);
}
export function PermitItemsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'passes': !exists(json, 'passes') ? undefined : (json['passes'].map(OrderPassFromJSON)),
        'tickets': !exists(json, 'tickets') ? undefined : (json['tickets'].map(OrderTicketGroupFromJSON)),
    };
}
export function PermitItemsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'passes': value.passes === undefined ? undefined : (value.passes.map(OrderPassToJSON)),
        'tickets': value.tickets === undefined ? undefined : (value.tickets.map(OrderTicketGroupToJSON)),
    };
}
