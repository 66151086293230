/* tslint:disable */
/* eslint-disable */
/**
 * `createContentInteractionStates` 요청의 `BAD_REQUEST` 응답 발생 원인으로 가능한 경우.
 *
 * - **INCOMPLETE_CONTENT_INTERACTION_STATE_EXISTS**: 아직 완료하지 않은 `ContentInteractionState`가 존재
 * - **NO_AUTHORITY**: `ContentInteractionState`를 생성할 권한이 없음
 * - **NO_MORE_CONTENTS_TO_PROVIDE**: 대상 `LearningCell`에서 더 이상 제공할 컨텐츠가 없음
 * - **INVALID_LEARNING_CELL_STATE**: 대항 `LearningCell`의 상태가 `ONGOING`이 아님
 * @export
 */
export const CreateContentInteractionStatesBadRequestErrorCase = {
    INCOMPLETE_CONTENT_INTERACTION_STATE_EXISTS: 'INCOMPLETE_CONTENT_INTERACTION_STATE_EXISTS',
    NO_AUTHORITY: 'NO_AUTHORITY',
    NO_MORE_CONTENTS_TO_PROVIDE: 'NO_MORE_CONTENTS_TO_PROVIDE',
    INVALID_LEARNING_CELL_STATE: 'INVALID_LEARNING_CELL_STATE'
};
export function CreateContentInteractionStatesBadRequestErrorCaseFromJSON(json) {
    return CreateContentInteractionStatesBadRequestErrorCaseFromJSONTyped(json, false);
}
export function CreateContentInteractionStatesBadRequestErrorCaseFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function CreateContentInteractionStatesBadRequestErrorCaseToJSON(value) {
    return value;
}
