import {useTranslation} from 'react-i18next';

import {VirtualExamPart} from '@app/new-structure/entities/base/parts';

export const useVirtualExamPartLabels = (): Record<VirtualExamPart, string> => {
  const {t} = useTranslation();
  return {
    PART_1: 'PART 1',
    PART_2: 'PART 2',
    PART_3: 'PART 3',
    PART_4: 'PART 4',
    PART_5: 'PART 5',
    PART_6: 'PART 6',
    PART_7: 'PART 7',
    PART_ALL: t('virtual_exam_part_all_label'),
  };
};
