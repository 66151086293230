import {Atom, atom} from 'jotai';

const withDefault = <T>(targetAtom: Atom<T>, defaultValue: T): Atom<T> => {
  targetAtom.debugPrivate = true;

  return atom(get => {
    try {
      return get(targetAtom);
    } catch (error) {
      return defaultValue;
    }
  });
};

export {withDefault};
