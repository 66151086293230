/* tslint:disable */
/* eslint-disable */
/**
 *
 * @export
 */
export const OrderPaymentTransactionOneOfCase = {
    IAMPORT: 'IAMPORT',
    APP_STORE: 'APP_STORE',
    PLAY_STORE: 'PLAY_STORE',
    STRIPE: 'STRIPE'
};
export function OrderPaymentTransactionOneOfCaseFromJSON(json) {
    return OrderPaymentTransactionOneOfCaseFromJSONTyped(json, false);
}
export function OrderPaymentTransactionOneOfCaseFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function OrderPaymentTransactionOneOfCaseToJSON(value) {
    return value;
}
