import {useMutation} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';
import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';

export const useGetTicketResourceMutation = () => {
  const {TicketService} = useAtomValue(santaOpenapiServicesAtom);
  return useMutation({
    mutationFn: async (id: number) => await TicketService.getTicketResource({id}),
  });
};
