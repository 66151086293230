import React from 'react';
import {OnboardingStep} from '@app/features/onboarding/types';
import useUserProfileQuery from '@app/hooks/user/useUserProfile';
import {useOnboardingStep} from '.';

const useOnboardingStepCorrectionEffect = () => {
  const {currentStep, goToStep, goToNextStep} = useOnboardingStep();
  const {data: userProfile} = useUserProfileQuery();

  React.useEffect(() => {
    if (userProfile === undefined) {
      return;
    }
    if (userProfile === null) {
      if (currentStep !== OnboardingStep.SELECT_DOMAIN) {
        goToStep(OnboardingStep.SELECT_DOMAIN);
      }
      return;
    }

    const {hasExperience, targetScore} = userProfile.onboardingInfo;

    if (currentStep === OnboardingStep.PREPARE_DIAGNOSIS) {
      if (hasExperience == null) {
        goToStep(OnboardingStep.ASK_EXPERIENCE);
      } else if (targetScore == null) {
        goToStep(OnboardingStep.SET_TARGET_SCORE);
      }
    }
  }, [currentStep, goToNextStep, goToStep, userProfile]);
};

export default useOnboardingStepCorrectionEffect;
