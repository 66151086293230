import {useMemo} from 'react';

import {useAtomValue} from 'jotai';
import {BlankContext} from '@santa-web/service-ui';
import {summarize} from '@app/facade/snippet/summarizer';
import {learningStore} from '@app/test-preparation/atoms/stores';
import {useIsExplanationInvisible} from '@app/test-preparation/hooks';

export function useBlankContext(): BlankContext {
  const isExplanationInvisible = useIsExplanationInvisible();

  const completedQuestionCount = useAtomValue(learningStore.cises.completedQuestionCountAtom);

  const questionStates = useAtomValue(learningStore.cis.questionStatesAtom);
  const isCompleted = useAtomValue(learningStore.cis.isCompletedAtom);

  const questionSet = useAtomValue(learningStore.content.questionSetAtom);
  const questions = questionSet?.questions;

  const startQuestionIndex = completedQuestionCount + 1 - (isCompleted && questionStates ? questionStates.length : 0);

  return useMemo<BlankContext>(
    () => ({
      overridings: (questionStates ?? []).map((questionState, i) => {
        const question = questions?.[i];
        const numberOfQuestions = questions?.length ?? 0;
        const isCorrect = questionState.answerState === 'CORRECT';
        const objective = question?.oneOfTypeSpec.objective;
        const choices = objective?.choices;
        const answer = objective?.oneOfAnswers.andAnswers?.[0] ?? -1;
        const choice = choices?.[answer];

        return {
          number: numberOfQuestions > 1 ? startQuestionIndex + i : undefined,
          isCorrect: !isExplanationInvisible && isCompleted ? isCorrect : undefined,
          value: !isExplanationInvisible && isCompleted && choice ? summarize(choice).trim() : undefined,
        };
      }),
    }),
    [isCompleted, isExplanationInvisible, questionStates, questions, startQuestionIndex]
  );
}
