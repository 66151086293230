import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {COLOR_SANTA_G} from '@riiid/design-system';
import {Table, Typography} from '@santa-web/service-ui';

type ScoreTableEmptyBodyProps = {
  colSpan: number;
};

const ScoreTableEmptyBody = ({colSpan}: ScoreTableEmptyBodyProps) => {
  const {t} = useTranslation();
  return (
    <Table.Body
      css={css`
        height: 100px;
      `}>
      <Table.Row>
        <Table.Row.Cell colSpan={colSpan} align="center">
          <Typography component="p" variant="caption1" color={COLOR_SANTA_G}>
            {t(`page_exam_score_table_empty_info`)}
          </Typography>
        </Table.Row.Cell>
      </Table.Row>
    </Table.Body>
  );
};

export default ScoreTableEmptyBody;
export type {ScoreTableEmptyBodyProps};
ScoreTableEmptyBody.displayName = 'ScoreTableEmptyBody';
