import {GTelpUserSegment, ToeicUserSegment} from '@santa-web/gen/open-api/content-learning-service';
import {DIAGNOSIS_RESULT_DATA, TOTAL_USERS} from '@app/constants/diagnosis-data';
import exhaustiveCheck from '@app/utils/exhaustiveCheck';

export const getSimpleUserSeg = (userSeg: ToeicUserSegment | GTelpUserSegment) => {
  switch (userSeg) {
    case 'ADVANCED':
    case 'ADVANCED_N':
      return 'advanced';
    case 'BASIC':
    case 'BASIC_N':
      return 'basic';
    case 'INTERMEDIATE':
    case 'INTERMEDIATE_N':
      return 'intermediate';
    default:
      return exhaustiveCheck(userSeg as never);
  }
};

export const getUserRanking = (userScore: number) => {
  const upperUsers = DIAGNOSIS_RESULT_DATA.reduce((acc, {label, value}) => {
    if (label > userScore) return acc + value;
    if (label === userScore) return acc + value / 2;
    return acc;
  }, 0);
  const result = Math.floor((upperUsers / TOTAL_USERS) * 100);
  return result < 1 ? 1 : result;
};
