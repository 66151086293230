export const loadScript = (url: string) =>
  new Promise((resolve, reject) => {
    if (!document) {
      reject(new Error('Document was not defined'));
    }

    const script = document.createElement('script');

    script.type = 'text/javascript';
    script.src = url;
    script.async = true;
    script.onload = () => {
      resolve(script);
    };

    script.onerror = msg => {
      reject(new Error(`Error in loading script: ${msg}`));
    };

    script.onabort = msg => {
      reject(new Error(`Script loading aborted: ${msg}`));
    };

    document.body.appendChild(script);
  });
