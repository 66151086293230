/**
 * Check if a given object implements the SelfLearningUnitProgress interface.
 */
export function instanceOfSelfLearningUnitProgress(value) {
    let isInstance = true;
    isInstance = isInstance && "caption" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "rate" in value;
    isInstance = isInstance && "title" in value;
    return isInstance;
}
export function SelfLearningUnitProgressFromJSON(json) {
    return SelfLearningUnitProgressFromJSONTyped(json, false);
}
export function SelfLearningUnitProgressFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'caption': json['caption'],
        'description': json['description'],
        'rate': json['rate'],
        'title': json['title'],
    };
}
export function SelfLearningUnitProgressToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'caption': value.caption,
        'description': value.description,
        'rate': value.rate,
        'title': value.title,
    };
}
