import React from 'react';
import {useTranslation} from 'react-i18next';
import {AlertDialog} from '@santa-web/service-ui';

type ResetPasswordMailFailedDialogProps = {
  onConfirm: () => void;
};

const ResetPasswordMailFailedDialog = ({onConfirm}: ResetPasswordMailFailedDialogProps) => {
  const {t} = useTranslation();
  return (
    <AlertDialog
      isVisible
      AlertProps={{
        title: t('forgot_password_mail_failed_dialog_title'),
        description: t('forgot_password_mail_failed_dialog_description'),
        positiveLabel: t('dict_confirm'),
        onPositiveClick: onConfirm,
      }}
    />
  );
};

export default ResetPasswordMailFailedDialog;
export type {ResetPasswordMailFailedDialogProps};
ResetPasswordMailFailedDialog.displayName = 'ResetPasswordMailFailedDialog';
