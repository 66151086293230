import {atom} from 'jotai';
import {ContentResource} from '@santa-web/gen/open-api/content-learning-service';
import {Content} from '@santa-web/gen/open-api/content/models/Content';
import {setAtomLabels} from '@app/misc/atom-label';
import {cisService} from '@app/test-preparation/atoms/services';
import {ServiceNotInitializedError} from '@app/test-preparation/atoms/services/shared/errors/not-initialized';

type ContentData = {
  url: string;
  content?: Content;
  resources?: Array<ContentResource>;
};

type ContentDataMap = Record<string, ContentData | undefined>;

type ResourceMap = Record<string, ContentResource | undefined>;

const contentDataMapAtom = atom<ContentDataMap>({});

const contentAtom = atom(get => {
  const currentCisIndex = get(cisService.currentCisIndexAtom);
  const contentDataMap = get(contentDataMapAtom);
  const content = contentDataMap[currentCisIndex]?.content;

  if (content === undefined) {
    throw new ServiceNotInitializedError('CisService');
  }

  return content;
});

const resourceMapAtom = atom(get => {
  const currentCisIndex = get(cisService.currentCisIndexAtom);
  const contentDataMap = get(contentDataMapAtom);
  const resources = contentDataMap[currentCisIndex]?.resources;

  if (resources === undefined) {
    throw new ServiceNotInitializedError('CisService');
  }

  return resources.reduce<ResourceMap>(
    (resourceMap, resource) => ({
      ...resourceMap,
      [resource.id]: resource,
    }),
    {}
  );
});

export {contentDataMapAtom, contentAtom, resourceMapAtom};

setAtomLabels(
  {
    contentDataMapAtom,
    contentAtom,
    resourceMapAtom,
  },
  'cisService.'
);
