import React from 'react';
import {css} from '@emotion/react';
import {
  COLOR_SANTA_O,
  COLOR_SANTA_G,
  COLOR_SANTA_CARD_A,
  COLOR_SANTA_CARD_C,
  COLOR_SANTA_A,
} from '@riiid/design-system';

import {Typography, TypographyProps} from '@santa-web/service-ui';

interface IconProps extends React.ComponentPropsWithoutRef<'svg'> {
  type?: 'line' | 'solid';
}

export interface StudyCellIconTagProps extends React.ComponentPropsWithoutRef<'div'> {
  content?: string;
  contentProps?: TypographyProps;
  Icon?: React.ComponentType<Pick<IconProps, 'width' | 'height' | 'color' | 'type'>>;
  IconProps?: IconProps;
  isDone?: boolean;
}

const StudyCellIconTag = React.forwardRef<HTMLDivElement, StudyCellIconTagProps>(
  ({content, contentProps, Icon, IconProps, isDone, ...props}, ref) => {
    const defaultStyle = Icon
      ? css`
          padding: 2px;
        `
      : css`
          padding: 0 8px;
        `;
    return (
      <div
        css={[
          defaultStyle,
          css`
            display: inline-flex;
            width: fit-content;
            border: ${isDone ? COLOR_SANTA_CARD_C : COLOR_SANTA_CARD_A} 2px solid;
            border-radius: 999px;
            background-color: ${COLOR_SANTA_O};
          `,
          isDone &&
            css`
              background-color: ${COLOR_SANTA_G};
            `,
        ]}
        ref={ref}
        {...props}>
        {Icon ? (
          <Icon
            data-testid="StudyCellIconTag-Icon"
            width={16}
            height={16}
            color={isDone ? COLOR_SANTA_CARD_C : COLOR_SANTA_A}
            {...IconProps}
          />
        ) : (
          <Typography
            variant="caption2"
            fontWeight="bold"
            color={isDone ? COLOR_SANTA_CARD_C : COLOR_SANTA_A}
            {...contentProps}>
            {content}
          </Typography>
        )}
      </div>
    );
  }
);

export default React.memo(StudyCellIconTag);
