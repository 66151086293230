import {useDialogContext} from '@app/contexts/DialogContext';
import {OfferGroupOrderCouponBottomSheetContainer} from '@app/features/offer/containers';

export const useOfferGroupOrderCouponBottomSheetContainer = (orderId: number) => {
  const {openDialog, closeDialog} = useDialogContext();

  const openCouponBottomSheet = () => {
    openDialog(<OfferGroupOrderCouponBottomSheetContainer onCloseClick={closeDialog} orderId={orderId} />);
  };

  return openCouponBottomSheet;
};
