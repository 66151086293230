import {Field} from 'react-final-form';
import {TFunction, useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {COLOR_SANTA_L} from '@riiid/design-system';
import {NewTextField, NewTextFieldProps} from '@santa-web/service-ui';

type ExamScoreInputProps = {
  name: string;
  label?: string;
  maxScore: number;
  minScore: number;
  scoreStep: number;
} & Omit<NewTextFieldProps, 'defaultValue' | 'value' | 'onChange' | 'error'>;

/**
 * This component has dependency on react-final-form.
 *
 * you need to use `<Form>` component for render this component.
 *
 * you can set initialValue via `initialValues`prop of `<Form>`
 */
const ExamScoreInputField = ({
  name,
  label,
  maxScore,
  minScore,
  scoreStep,
  ...newTextFieldProps
}: ExamScoreInputProps) => {
  const {t} = useTranslation();
  const validator = getValidator({maxScore, minScore, scoreStep, translator: t});

  return (
    <Field
      name={name}
      validate={value => {
        const {isValid, error} = validator(value);
        if (isValid) return;
        return error;
      }}>
      {props => (
        <NewTextField
          css={css`
            & input {
              color: ${COLOR_SANTA_L};
            }
          `}
          inputProps={{
            ...props.input,
            inputMode: 'numeric',
            pattern: '[0-9]*',
            onChange: e => {
              // Allow only Number(0-9)
              const safeValue = e.target.value.replace(/[^0-9]/g, '');
              e.target.value = safeValue ?? '';

              props.input.onChange(e);
            },
            max: maxScore,
            min: minScore,
          }}
          error={props.meta.dirty && props.meta.error}
          label={label}
          hint={`ex) ${maxScore}`}
          {...newTextFieldProps}
        />
      )}
    </Field>
  );
};

function getValidator({
  maxScore,
  minScore,
  scoreStep,
  translator: t,
}: {
  maxScore: number;
  minScore: number;
  scoreStep: number;
  translator: TFunction<'translation'>;
}) {
  const outOfRange = t(`page_exam_score_form_part_score_input_error_out_of_range`, {
    minScore,
    maxScore,
  });
  const invalidScore = t(`page_exam_score_form_part_score_input_error_invalid`);

  return (value?: string): {isValid: boolean; error?: string} => {
    const valueAsNumber = parseInt(value ?? '');

    if (value === undefined) {
      return {isValid: false, error: ''};
    }

    if (isNaN(valueAsNumber)) {
      return {isValid: false, error: invalidScore};
    }

    if (valueAsNumber > maxScore) {
      return {isValid: false, error: outOfRange};
    }

    if (valueAsNumber < minScore) {
      return {isValid: false, error: outOfRange};
    }

    if (valueAsNumber % scoreStep !== 0) {
      return {isValid: false, error: invalidScore};
    }

    return {isValid: true};
  };
}

export default ExamScoreInputField;
export type {ExamScoreInputProps};
ExamScoreInputField.displayName = 'ExamScoreInputField';
