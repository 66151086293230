import React from 'react';

import {SingleQuestionTitle} from '@santa-web/service-ui';
import DirectionContent from '@app/components/virtual-exam/VirtualExamDirectionView/components/DirectionContent';
import DirectionWrapper from '@app/components/virtual-exam/VirtualExamDirectionView/components/DirectionWrapper';

const Part6DirectionView = React.forwardRef<HTMLDivElement, React.ComponentPropsWithoutRef<'div'>>((props, ref) => {
  return (
    <DirectionWrapper gap={24} {...props} ref={ref}>
      <SingleQuestionTitle content={'PART 6'} />
      <DirectionContent>
        <b>Directions:</b> Read the texts that follow. A word, phrase, or sentence is missing in parts of each text.
        Four answer choices for each question are given below the text. Select the best answer to complete the text.
        Then mark the letter (A), (B), (C) or (D) on your answer sheet.
      </DirectionContent>
    </DirectionWrapper>
  );
});

export default Part6DirectionView;
