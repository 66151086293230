import {useTranslation} from 'react-i18next';
import {AlertDialog} from '@santa-web/service-ui';

type CouponRegisterFailAlertProps = {
  isVisible: boolean;
  onClose: () => void;
};

const CouponRegisterFailAlert = ({isVisible, onClose}: CouponRegisterFailAlertProps) => {
  const {t} = useTranslation();
  return (
    <AlertDialog
      isVisible={isVisible}
      AlertProps={{
        title: t('page_coupon_box_registration_failure_dialog_title'),
        positiveLabel: t('dict_confirm'),
        onPositiveClick: onClose,
      }}
    />
  );
};

export default CouponRegisterFailAlert;
export type {CouponRegisterFailAlertProps};
CouponRegisterFailAlert.displayName = 'CouponRegisterFailAlert';
