import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {COLOR_SANTA_H, COLOR_SANTA_L} from '@riiid/design-system';

import {Typography} from '@santa-web/service-ui';
import DonutResultChart from '@app/components/DonutResultChart';
import {formatSeconds} from '@app/utils/text';

export interface AnalyticsRcTimeGraphProps {
  elapsedTime: number;
  recommendedTime: number;
  title: string;
  isEmpty?: boolean;
}

const AnalyticsRcTimeGraph = ({
  elapsedTime,
  recommendedTime,
  title,
  isEmpty = false,
  ...props
}: AnalyticsRcTimeGraphProps): JSX.Element => {
  const {t} = useTranslation();

  return (
    <div
      css={css`
        display: inline-flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        width: 88px;
      `}
      {...props}>
      <DonutResultChart
        value={
          isEmpty
            ? null
            : {
                correct: elapsedTime,
                total: recommendedTime,
              }
        }
        chartType={'time'}
        chartSize={88}
      />
      <Typography
        variant="caption2"
        fontWeight="bold"
        color={COLOR_SANTA_L}
        css={css`
          margin-top: 8px;
        `}>
        {title}
      </Typography>
      <Typography variant="caption2" fontWeight="regular" color={COLOR_SANTA_H}>
        {t('analytics_rc_recommended_time', {
          recommendedTime: formatSeconds(Math.round(recommendedTime), 'mmt:ss'),
        })}
      </Typography>
    </div>
  );
};

export default React.memo(AnalyticsRcTimeGraph);
