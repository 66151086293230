import React from 'react';
import {useTranslation} from 'react-i18next';
import Decimal from 'decimal.js';
import {LoadingDim} from '@app/components';
import {createPriceFormatter} from '@app/facade/price/price-formatter';
import {useAvailableDiscountCouponsQuery} from '@app/features/coupon';
import {
  OfferGroupOrderCouponBottomSheetList,
  OfferGroupOrderCouponBottomSheetListProps,
} from '@app/features/offer/components';
import {useOfferGroupOrderApplyCouponsMutation, useOfferGroupOrderQuery} from '@app/features/offer/hooks';

type OfferGroupOrderCouponBottomSheetContainerProps = {orderId: number; onCloseClick: () => void};

const OfferGroupOrderCouponBottomSheetContainer = ({
  orderId,
  onCloseClick,
}: OfferGroupOrderCouponBottomSheetContainerProps) => {
  const {t} = useTranslation();
  const {data: order} = useOfferGroupOrderQuery(orderId);
  const {data: coupons} = useAvailableDiscountCouponsQuery(
    {
      discountMethods: order?.orderConfig?.availableCouponDiscountMethods ?? [],
    },
    {enabled: !!order}
  );
  const {mutateAsync: applyCoupons, isLoading: isMutating} = useOfferGroupOrderApplyCouponsMutation(orderId);
  if (!order || !coupons) {
    return <LoadingDim />;
  }
  const handleApplyClick = async (couponIds: number[]) => {
    await applyCoupons(couponIds);
    onCloseClick();
  };
  const priceFormatter = createPriceFormatter(order.amountData.currency);
  const couponItems: OfferGroupOrderCouponBottomSheetListProps['items'] = coupons.coupons
    ?.filter(coupon => coupon.details?.oneOfCase === 'DISCOUNT')
    .map(coupon => {
      return {
        id: coupon.id,
        caption: coupon.displayName,
        title: t('offer_group_order_coupon_discount_label', {
          amount:
            coupon.details?.discount?.method?.oneOfCase === 'AMOUNT' && coupon.details?.discount?.method?.amount
              ? priceFormatter.getFormattedPrice(new Decimal(coupon.details?.discount?.method?.amount))
              : `${coupon?.details?.discount?.method?.rate}%`,
        }),
        isSelected: (order.orderConfig?.appliedCouponIds ?? []).includes(coupon.id),
        isUnavailable: coupon.details?.discount?.minPurchasePrice
          ? new Decimal(coupon.details.discount.minPurchasePrice).gt(new Decimal(order.amountData.totalChargeAmount))
          : false,
      };
    });

  return (
    <OfferGroupOrderCouponBottomSheetList
      isOpened
      isLoading={isMutating}
      onCloseClick={onCloseClick}
      onApplyClick={handleApplyClick}
      items={couponItems}
    />
  );
};

export default OfferGroupOrderCouponBottomSheetContainer;
export type {OfferGroupOrderCouponBottomSheetContainerProps};
OfferGroupOrderCouponBottomSheetContainer.displayName = 'OfferGroupOrderCouponBottomSheetContainer';
