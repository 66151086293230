import {useState} from 'react';

import {
  InvalidResetPasswordLink,
  ResetPasswordCompleted,
  ResetPasswordFormContainer,
  useIsValidResetPasswordToken,
} from '@app/features/auth';

interface Props {
  token: string;
}

const ResetPasswordPageContainer = ({token}: Props): JSX.Element => {
  const [isResetCompleted, setIsResetCompleted] = useState(false);
  const {data: isValidToken} = useIsValidResetPasswordToken(token);

  const handleResetComplete = () => {
    setIsResetCompleted(true);
  };

  if (isResetCompleted) {
    return <ResetPasswordCompleted />;
  } else {
    if (isValidToken) {
      return <ResetPasswordFormContainer token={token} onResetComplete={handleResetComplete} />;
    } else {
      return <InvalidResetPasswordLink />;
    }
  }
};

export default ResetPasswordPageContainer;
