import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {COLOR_SANTA_BC, COLOR_SANTA_BD, COLOR_SANTA_I, COLOR_SANTA_L} from '@riiid/design-system';

import {EstimatedTagProficiencyWithTarget} from '@santa-web/gen/open-api/content-learning-service';
import {Typography, BarGraph, BarGraphProps} from '@santa-web/service-ui';
import BaseCard from '@app/components/card/BaseCard';
import Legend from '@app/components/Legend';
import LegendList from '@app/components/LegendList';

export type AnswerRateCardProps = Omit<React.ComponentPropsWithoutRef<'div'>, 'title' | 'content'> & {
  title?: string | null;
  content?: string | null;
  proficiencies: EstimatedTagProficiencyWithTarget[];
  isScoreHidden?: boolean;
  targetScore?: number;
};
const AnalyticsPartGraphCard = React.forwardRef<HTMLDivElement, AnswerRateCardProps>(
  ({title, content, proficiencies, isScoreHidden, targetScore}, ref): JSX.Element => {
    const {t} = useTranslation();
    const data: BarGraphProps['data'] = proficiencies.map(proficiency => {
      return {
        label: proficiency.estimatedTagProficiency.translatedTagName,
        bars: [
          {
            value: Math.floor(proficiency.estimatedTagProficiency.proficiency * 100),
            barColor: COLOR_SANTA_BC,
            textColor: COLOR_SANTA_BD,
            isEmpty: isScoreHidden,
          },
          {
            value: Math.floor(proficiency.targetTagProficiency * 100),
            barColor: COLOR_SANTA_I,
            textColor: COLOR_SANTA_I,
          },
        ],
      };
    });

    return (
      <BaseCard
        css={css`
          display: grid;
          padding: 20px;
          gap: 24px;
        `}
        ref={ref}>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            gap: 12px;
          `}>
          <Typography fontWeight="bold" variant="body2" color={COLOR_SANTA_L}>
            {title}
          </Typography>
          <Typography variant="body3" color={COLOR_SANTA_I}>
            {content}
          </Typography>
        </div>
        <BarGraph data={data} />
        <LegendList>
          <Legend color={COLOR_SANTA_BC} subject={t('bar_graph_score_mine')} variant="square" />
          <Legend color={COLOR_SANTA_I} subject={t('bar_graph_score_user', {targetScore})} variant="square" />
        </LegendList>
      </BaseCard>
    );
  }
);

export default React.memo(AnalyticsPartGraphCard);
