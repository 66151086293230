import React from 'react';
import {css} from '@emotion/react';
import {COLOR_SANTA_C, COLOR_SANTA_C_ALPHA} from '@riiid/design-system';
import {Swiper as SwiperClass} from 'swiper';
import {Pagination} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';
import OfferCard, {OfferCardProps} from '@app/features/offer/components/OfferCard';

const BLUR_WIDTH = '80px';

type OfferCardSlideProps = {
  offerCards: Array<OfferCardProps>;
  breakPointWidth: string;
  onSlideChange?: (swiper: SwiperClass) => void;
  initialIndex?: number;
};

const OfferCardSlide = React.forwardRef<HTMLDivElement, OfferCardSlideProps>(
  ({offerCards, breakPointWidth, onSlideChange, initialIndex = 0}, ref) => {
    return (
      <div
        ref={ref}
        css={css`
          background-color: transparent;
          width: 100%;
          max-width: 600px;
          @media screen and (min-width: ${breakPointWidth}) {
            ::before {
              position: absolute;
              left: calc((100% - ${breakPointWidth}) / 2 - ${BLUR_WIDTH});
              width: ${BLUR_WIDTH};
              height: 100%;
              content: '';
              background: linear-gradient(90deg, ${COLOR_SANTA_C}, ${COLOR_SANTA_C_ALPHA(0)});
              z-index: 99;
            }
            ::after {
              position: absolute;
              top: 0;
              right: calc((100% - ${breakPointWidth}) / 2 - ${BLUR_WIDTH});
              width: ${BLUR_WIDTH};
              height: 100%;
              content: '';
              background: linear-gradient(270deg, ${COLOR_SANTA_C}, ${COLOR_SANTA_C_ALPHA(0)});
              z-index: 99;
            }
          }
        `}>
        <div
          css={css`
            position: relative;
            height: 100%;
            .swiper-slide {
              font-size: 18px;
              display: flex;
              justify-content: center;
              width: calc(100% - 48px);
              max-width: 392px;
              height: auto;
            }
          `}>
          <Swiper
            slidesPerView={'auto'}
            centeredSlides={true}
            onSlideChange={onSlideChange}
            pagination={{
              clickable: true,
            }}
            initialSlide={initialIndex}
            spaceBetween={8}
            modules={[Pagination]}>
            {offerCards.map((offerCard, index) => {
              return (
                <SwiperSlide key={index}>
                  <OfferCard {...offerCard} hasShadow isSlide />
                </SwiperSlide>
              );
            })}
          </Swiper>
        </div>
      </div>
    );
  }
);

export default OfferCardSlide;
export type {OfferCardSlideProps};
OfferCardSlide.displayName = 'OfferCardSlide';
