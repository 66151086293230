import React, {useCallback} from 'react';

import {useDialogContext} from '@app/contexts/DialogContext';
import {UserExistsBottomSheet} from '@app/features/auth/components';

interface IInvalidUserBottomSheet {
  openBottomSheet: (onClick: () => Promise<void>) => void;
}

export default function useUserExistsBottomSheet(): IInvalidUserBottomSheet {
  const {openDialog, closeDialog} = useDialogContext();

  const openBottomSheet = useCallback(
    (onLoginClick: () => Promise<void>) => {
      openDialog(<UserExistsBottomSheet onLoginClick={onLoginClick} onCloseClick={closeDialog} />);
    },
    [openDialog, closeDialog]
  );

  return {
    openBottomSheet,
  };
}
