import React from 'react';
import {css} from '@emotion/react';
import {
  BREAKPOINT_MEDIUM,
  COLOR_DEEPOCEAN_BLUE_GRAY_4_ALPHA,
  COLOR_FIX_SANTA_WHITE_1,
  COLOR_FIX_SANTA_WHITE_1_ALPHA,
} from '@riiid/design-system';
import {Typography} from '@santa-web/service-ui';
import BenefitIcon, {BenefitIconProps} from './BenefitIcon';

type BenefitCardProps = {
  iconType: BenefitIconProps['type'];
  title: string;
  description: string;
};

const BenefitCard = ({iconType, title, description}: BenefitCardProps) => {
  return (
    <section
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 16px 16px 12px;
        border-radius: 4px;
        background: linear-gradient(
          126.65deg,
          ${COLOR_FIX_SANTA_WHITE_1_ALPHA(0.2)} 0%,
          ${COLOR_DEEPOCEAN_BLUE_GRAY_4_ALPHA(0.2)} 100%
        );
        @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
          padding: 16px 12px 20px;
        }
      `}>
      <BenefitIcon type={iconType} />
      <Typography
        css={css`
          text-align: center;
          margin-top: 8px;
        `}
        variant="body3"
        fontWeight="bold"
        color={COLOR_FIX_SANTA_WHITE_1}>
        {title}
      </Typography>
      <Typography
        css={css`
          text-align: center;
        `}
        component="p"
        variant="caption1"
        color={COLOR_FIX_SANTA_WHITE_1}>
        {description}
      </Typography>
    </section>
  );
};

export default BenefitCard;
export type {BenefitCardProps};
BenefitCard.displayName = 'BenefitCard';
