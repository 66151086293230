/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
/**
 * Check if a given object implements the LearningCellExtraInfoSelfLearning interface.
 */
export function instanceOfLearningCellExtraInfoSelfLearning(value) {
    let isInstance = true;
    return isInstance;
}
export function LearningCellExtraInfoSelfLearningFromJSON(json) {
    return LearningCellExtraInfoSelfLearningFromJSONTyped(json, false);
}
export function LearningCellExtraInfoSelfLearningFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'isToeicVirtualExam': !exists(json, 'isToeicVirtualExam') ? undefined : json['isToeicVirtualExam'],
    };
}
export function LearningCellExtraInfoSelfLearningToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'isToeicVirtualExam': value.isToeicVirtualExam,
    };
}
