import React from 'react';
import {useTranslation} from 'react-i18next';

import {composeValidators} from '@app/features/auth/utils/validator';

import useCommonValidator from './useCommonValidator';

export default function useNameValidator() {
  const {t} = useTranslation();
  const commonValidator = useCommonValidator();
  const noWhiteSpace = React.useCallback(
    (value: string | null | undefined) => {
      if (value && value.match(/(^\s|\s$)/)) {
        return t('name_validation_fill_it_again');
      }
    },
    [t]
  );

  return composeValidators(
    noWhiteSpace,
    commonValidator.required,
    commonValidator.maxLength(20, t('sign_error_name_over_length'))
  );
}
