import React from 'react';
import {css} from '@emotion/react';

export type SentenceCompletionLayoutProps = React.ComponentPropsWithoutRef<'div'>;

export default React.memo(
  React.forwardRef<HTMLDivElement, SentenceCompletionLayoutProps>(function SentenceCompletionLayout(props, ref) {
    const {children, ...restProps} = props;
    return (
      <div
        ref={ref}
        css={css`
          display: flex;
          justify-content: center;
          min-height: 100%;
        `}
        {...restProps}>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            width: 100%;
            max-width: 680px;
          `}>
          {children}
        </div>
      </div>
    );
  })
);
