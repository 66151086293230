import React from 'react';
import {css} from '@emotion/react';

export type TabButtonListProps = React.ComponentPropsWithoutRef<'div'>;

const TabButtonList = React.forwardRef<HTMLDivElement, TabButtonListProps>((props, ref) => {
  return (
    <div
      css={css`
        margin-top: -8px;

        > button {
          margin-right: 8px;
          margin-top: 8px;

          &:last-of-type {
            margin-right: 0;
          }
        }
      `}
      ref={ref}
      {...props}
    />
  );
});

export default React.memo(TabButtonList);
