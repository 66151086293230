import {useQuery} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';
import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {getLatestEventDisplaysAtQueryKey} from '@app/queryKeys';

export const useLatestEventDisplaysAtQuery = () => {
  const {MarketingService} = useAtomValue(santaOpenapiServicesAtom);
  return useQuery<Date | null>({
    queryKey: getLatestEventDisplaysAtQueryKey(),
    queryFn: async () =>
      await MarketingService.checkActiveMarketingCampaign({}).then(
        ({mostRecentStartsAt}) => mostRecentStartsAt ?? null
      ),
  });
};
