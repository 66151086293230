import React from 'react';
import {css} from '@emotion/react';

import {LottieAnimation, LottieAnimationProps, useColorThemeImage} from '@santa-web/service-ui';
import exhaustiveCheck from '@app/utils/exhaustiveCheck';

export type AiBlockCardIconType = 'rise' | 'down' | 'info' | 'aipick';

export interface AiBlockCardIconProps extends React.ComponentPropsWithoutRef<'div'> {
  type: AiBlockCardIconType;
  LottieAnimationProps?: Omit<LottieAnimationProps, 'src'>;
}

const AiBlockCardIcon = React.forwardRef<HTMLDivElement, AiBlockCardIconProps>(
  ({type, LottieAnimationProps, ...props}, ref) => {
    const {riseLottie, downLottie, infoLottie, aipickLottie} = useColorThemeImage();
    const animationData = React.useCallback(() => {
      switch (type) {
        case 'rise':
          return riseLottie;
        case 'down':
          return downLottie;
        case 'info':
          return infoLottie;
        case 'aipick':
          return aipickLottie;
        /* istanbul ignore next */
        default:
          return exhaustiveCheck(type);
      }
    }, [aipickLottie, downLottie, infoLottie, riseLottie, type])();

    return (
      <div
        css={css`
          display: inline-flex;
        `}
        ref={ref}
        {...props}>
        <LottieAnimation width={20} height={20} src={animationData} {...LottieAnimationProps} />
      </div>
    );
  }
);

export default React.memo(AiBlockCardIcon);
