/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { ImageSetFromJSON, ImageSetToJSON, } from './ImageSet';
/**
 * Check if a given object implements the MarketingBannerListItem interface.
 */
export function instanceOfMarketingBannerListItem(value) {
    let isInstance = true;
    isInstance = isInstance && "backgroundColor" in value;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "isExternal" in value;
    isInstance = isInstance && "link" in value;
    isInstance = isInstance && "messageColor" in value;
    isInstance = isInstance && "title" in value;
    isInstance = isInstance && "webLink" in value;
    return isInstance;
}
export function MarketingBannerListItemFromJSON(json) {
    return MarketingBannerListItemFromJSONTyped(json, false);
}
export function MarketingBannerListItemFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'backgroundColor': json['backgroundColor'],
        'description': json['description'],
        'imageSet': !exists(json, 'imageSet') ? undefined : ImageSetFromJSON(json['imageSet']),
        'isExternal': json['isExternal'],
        'link': json['link'],
        'messageColor': json['messageColor'],
        'title': json['title'],
        'webLink': json['webLink'],
    };
}
export function MarketingBannerListItemToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'backgroundColor': value.backgroundColor,
        'description': value.description,
        'imageSet': ImageSetToJSON(value.imageSet),
        'isExternal': value.isExternal,
        'link': value.link,
        'messageColor': value.messageColor,
        'title': value.title,
        'webLink': value.webLink,
    };
}
