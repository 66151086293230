function _array_like_to_array(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _array_with_holes(arr) {
    if (Array.isArray(arr)) return arr;
}
function _iterable_to_array_limit(arr, i) {
    var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];
    if (_i == null) return;
    var _arr = [];
    var _n = true;
    var _d = false;
    var _s, _e;
    try {
        for(_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true){
            _arr.push(_s.value);
            if (i && _arr.length === i) break;
        }
    } catch (err) {
        _d = true;
        _e = err;
    } finally{
        try {
            if (!_n && _i["return"] != null) _i["return"]();
        } finally{
            if (_d) throw _e;
        }
    }
    return _arr;
}
function _non_iterable_rest() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _sliced_to_array(arr, i) {
    return _array_with_holes(arr) || _iterable_to_array_limit(arr, i) || _unsupported_iterable_to_array(arr, i) || _non_iterable_rest();
}
function _tagged_template_literal(strings, raw) {
    if (!raw) {
        raw = strings.slice(0);
    }
    return Object.freeze(Object.defineProperties(strings, {
        raw: {
            value: Object.freeze(raw)
        }
    }));
}
function _unsupported_iterable_to_array(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _array_like_to_array(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _array_like_to_array(o, minLen);
}
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  from {\n    opacity: 0;\n    transform: translateY(100%);\n  }\n\n  to {\n    opacity: 100%;\n    transform: none;\n  }\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  from {\n    opacity: 100%;\n    transform: none;\n  }\n\n  to {\n    opacity: 0;\n    transform: translateY(100%);\n  }\n"
    ]);
    _templateObject1 = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject2() {
    var data = _tagged_template_literal([
        "\n    animation: ",
        " 0.17s\n      cubic-bezier(0.18, 0.64, 0.52, 1) forwards;\n  "
    ]);
    _templateObject2 = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject3() {
    var data = _tagged_template_literal([
        "\n        opacity: 1;\n        user-select: initial;\n      "
    ]);
    _templateObject3 = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject4() {
    var data = _tagged_template_literal([
        "\n    opacity: 0;\n\n    @media screen and (max-width: calc(",
        " - 1px)) {\n      transform: translateY(100%);\n      ",
        "\n    }\n    @media screen and (min-width: ",
        ") {\n      transition: opacity 0.17s cubic-bezier(0.18, 0.64, 0.52, 1);\n      user-select: none;\n\n      ",
        "\n    }\n  "
    ]);
    _templateObject4 = function _templateObject() {
        return data;
    };
    return data;
}
import { useEffect, useState } from "react";
import { css, keyframes } from "@emotion/react";
import { BREAKPOINT_MEDIUM } from "@riiid/design-system";
var bottomSheetInKeyframes = keyframes(_templateObject());
export var bottomSheetOutKeyframes = keyframes(_templateObject1());
var bottomSheetAnimationCss = function(animationState) {
    if (animationState === "INIT") return;
    return css(_templateObject2(), animationState === "OPEN" ? bottomSheetInKeyframes : bottomSheetOutKeyframes);
};
export var useBottomSheetAnimation = function() {
    var isOpened = arguments.length > 0 && arguments[0] !== void 0 ? arguments[0] : false;
    var _useState = _sliced_to_array(useState("INIT"), 2), animationState = _useState[0], setAnimationState = _useState[1];
    useEffect(function() {
        if (animationState !== "OPEN" && isOpened) {
            setAnimationState("OPEN");
        } else if (animationState === "OPEN" && !isOpened) {
            setAnimationState("CLOSE");
        }
    }, [
        isOpened,
        animationState
    ]);
    return css(_templateObject4(), BREAKPOINT_MEDIUM, bottomSheetAnimationCss(animationState), BREAKPOINT_MEDIUM, animationState === "OPEN" && css(_templateObject3()));
};
