import {
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useMutation,
  UseMutationOptions,
  useQuery,
  UseQueryOptions,
} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';
import {BaseAPI} from '@santa-web/gen/open-api/service';
import {OpenAPIServices} from '@app/api/openapi';
import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';

type ServiceMethods<T extends keyof OpenAPIServices> = keyof Omit<OpenAPIServices[T], keyof BaseAPI | `${string}Raw`>;

type Request<
  ServiceName extends keyof OpenAPIServices,
  MethodName extends ServiceMethods<ServiceName>
> = OpenAPIServices[ServiceName][MethodName] extends (...args: any[]) => any
  ? OpenAPIServices[ServiceName][MethodName]
  : never;

type Response<ServiceName extends keyof OpenAPIServices, MethodName extends ServiceMethods<ServiceName>> = Awaited<
  ReturnType<Request<ServiceName, MethodName>>
>;

type Param<ServiceName extends keyof OpenAPIServices, MethodName extends ServiceMethods<ServiceName>> = Parameters<
  Request<ServiceName, MethodName>
>[0];

export const queryKeyFactory = (serviceName: string, methodName: string, params: any) => {
  if (Array.isArray(params)) {
    return [serviceName, methodName, ...params] as QueryKey;
  }

  return [serviceName, methodName, params] as QueryKey;
};

export const infinityQueryKeyFactory = (serviceName: string, methodName: string, params: any) => {
  if (params.cursor) {
    const {cursor, ...restParams} = params;
    params = restParams;
  }

  return queryKeyFactory(serviceName, methodName, params);
};

// 쿼리 훅 제너레이터
export function generateQueryHook<
  Service extends keyof OpenAPIServices,
  Method extends ServiceMethods<Service>,
  QueryFunction extends Request<Service, Method>
>(serviceName: Service, methodName: Method, queryKeyFn?: (params: Param<Service, Method>) => QueryKey) {
  // Parameter가 Object라서 인자 변동 있을때마다 호출이 될 수 있음. 다만, QueryKey를 통해 API 호출자체는 Cache됨.
  // WARNING: 혹시나 성능상의 문제 발생시 여기 확인해 볼 것
  return <Select = Response<Service, Method>>(
    params: Param<Service, Method>,
    options?: UseQueryOptions<Response<Service, Method>, unknown, Select, QueryKey>
  ) => {
    const services = useAtomValue(santaOpenapiServicesAtom);
    const queryFn = () => {
      return (services[serviceName][methodName] as QueryFunction)(params);
    };
    const queryKey = queryKeyFn ? queryKeyFn(params) : queryKeyFactory(serviceName, String(methodName), params);

    return {
      queryKey,
      ...useQuery<Response<Service, Method>, unknown, Select, QueryKey>(queryKey, () => queryFn(), options),
    };
  };
}

// 뮤테이션 훅 제너레이터
export function generateMutationHook<
  Service extends keyof OpenAPIServices,
  Method extends ServiceMethods<Service>,
  MutationFunction extends Request<Service, Method>
>(serviceName: Service, methodName: Method) {
  return (options?: UseMutationOptions<Response<Service, Method>, Error, Param<Service, Method>, unknown>) => {
    const services = useAtomValue(santaOpenapiServicesAtom);
    const mutationFn = (params: Param<Service, Method>) => {
      return (services[serviceName][methodName] as unknown as MutationFunction)(params);
    };

    return useMutation<Response<Service, Method>, Error, Param<Service, Method>, unknown>(
      params => mutationFn(params),
      {
        ...options,
      }
    );
  };
}

// 무한 쿼리 훅 제너레이터
export function generateInfiniteQueryHook<
  Service extends keyof OpenAPIServices,
  Method extends ServiceMethods<Service>,
  QueryFunction extends Request<Service, Method>
>(
  serviceName: Service,
  methodName: Method,
  getNextPageParam: (lastPage: Response<Service, Method>) => Partial<Param<Service, Method>> | undefined,
  queryKeyFn?: (params: Param<Service, Method>) => QueryKey
) {
  return (
    params: Param<Service, Method>,
    options?: UseInfiniteQueryOptions<Response<Service, Method>, unknown, Response<Service, Method>>
  ) => {
    const services = useAtomValue(santaOpenapiServicesAtom);
    const queryFn = (params: Param<Service, Method>) => {
      return (services[serviceName][methodName] as QueryFunction)(params);
    };

    const queryKey = queryKeyFn ? queryKeyFn(params) : infinityQueryKeyFactory(serviceName, String(methodName), params);
    const query = useInfiniteQuery<Response<Service, Method>, unknown, Response<Service, Method>, QueryKey>(
      queryKey,
      ({pageParam = undefined}) => queryFn({...params, ...pageParam}),
      {getNextPageParam, ...options}
    );

    return {
      queryKey,
      ...query,
    };
  };
}
