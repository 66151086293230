import {LoginSignupItem} from '@app/features/auth/types';
import {Language} from '@app/i18n/types';

export const getAuthItems = (language: Language): LoginSignupItem[] => {
  switch (language) {
    case 'ko': {
      return [{type: 'kakao'}, {type: 'apple'}, {type: 'google'}, {type: 'other', items: [{type: 'email'}]}];
    }
    case 'ja':
    case 'zh': {
      return [{type: 'line'}, {type: 'apple'}, {type: 'google'}, {type: 'other', items: [{type: 'email'}]}];
    }
    case 'en':
    case 'vi':
    case 'th': {
      {
        return [{type: 'apple'}, {type: 'google'}, {type: 'other', items: [{type: 'email'}]}];
      }
    }
  }
};
