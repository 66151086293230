import React from 'react';
import {COLOR_SANTA_J} from '@riiid/design-system';

import {Typography} from '@santa-web/service-ui';

export type DirectionContentProps = React.ComponentPropsWithoutRef<'p'>;

const DirectionContent = React.forwardRef<HTMLParagraphElement, DirectionContentProps>((props, ref) => {
  return <Typography color={COLOR_SANTA_J} variant="body3" component="p" {...props} ref={ref} />;
});

export default DirectionContent;
