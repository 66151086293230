import React from 'react';
import {Field, FieldProps as FieldProps_, FieldRenderProps} from 'react-final-form';

import {NewTextField as BaseTextField, NewTextFieldProps as BaseTextFieldProps} from '@santa-web/service-ui';

type FieldValue = string | undefined;
type FieldProps = FieldProps_<FieldValue, FieldRenderProps<FieldValue>>;

export type TextFieldProps = BaseTextFieldProps & Pick<FieldProps, 'name' | 'validate' | 'validateFields'>;

function TextField({name, type, validate, validateFields = [], ...props}: TextFieldProps) {
  return (
    <Field<FieldValue> name={name} validate={validate} validateFields={validateFields}>
      {({input, meta}) => {
        const validationError = meta.dirty && meta.error;
        const submissionError = !meta.dirtySinceLastSubmit && meta.submitError;
        const displayedError = validationError || submissionError;
        return <BaseTextField {...input} {...props} type={type} error={displayedError} />;
      }}
    </Field>
  );
}

export default React.memo(TextField);
