/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { PointRewardGuideUrlResponseFromJSON, PointRewardInfoResponseFromJSON, PointRewardLeaderBoardResponseFromJSON, PointRewardSummaryResponseFromJSON, } from '../models';
/**
 *
 */
export class PointRewardV2Api extends runtime.BaseAPI {
    /**
     * 지난 주 포인트 리워드 랭킹 조회
     */
    async getLastWeekPointRewardLeaderBoardRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/point-reward/v2/last-week-leader-board`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => PointRewardLeaderBoardResponseFromJSON(jsonValue));
    }
    /**
     * 지난 주 포인트 리워드 랭킹 조회
     */
    async getLastWeekPointRewardLeaderBoard(requestParameters, initOverrides) {
        const response = await this.getLastWeekPointRewardLeaderBoardRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 포인트 리워드 가이드 URL 조회
     */
    async getPointRewardGuideUrlRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/point-reward/v2/guide-url`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => PointRewardGuideUrlResponseFromJSON(jsonValue));
    }
    /**
     * 포인트 리워드 가이드 URL 조회
     */
    async getPointRewardGuideUrl(requestParameters, initOverrides) {
        const response = await this.getPointRewardGuideUrlRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 포인트 리워드 정보 조회
     */
    async getPointRewardInfoRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/point-reward/v2/info`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => PointRewardInfoResponseFromJSON(jsonValue));
    }
    /**
     * 포인트 리워드 정보 조회
     */
    async getPointRewardInfo(requestParameters, initOverrides) {
        const response = await this.getPointRewardInfoRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 포인트 리워드 요약 정보 조회
     */
    async getPointRewardSummaryRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/point-reward/v2/summary`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => PointRewardSummaryResponseFromJSON(jsonValue));
    }
    /**
     * 포인트 리워드 요약 정보 조회
     */
    async getPointRewardSummary(requestParameters, initOverrides) {
        const response = await this.getPointRewardSummaryRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 이번 주 포인트 리워드 랭킹 조회
     */
    async getThisWeekPointRewardLeaderBoardRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/point-reward/v2/this-week-leader-board`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => PointRewardLeaderBoardResponseFromJSON(jsonValue));
    }
    /**
     * 이번 주 포인트 리워드 랭킹 조회
     */
    async getThisWeekPointRewardLeaderBoard(requestParameters, initOverrides) {
        const response = await this.getThisWeekPointRewardLeaderBoardRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
