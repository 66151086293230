export const AI_BLOCK_CONTENT_Z_INDEX = 1;

export const DROPDOWN_Z_INDEX = 1;

export const GNB_HEADER_Z_INDEX = 30;

export const MODAL_Z_INDEX = 50;

export const ALERT_DIALOG_Z_INDEX = 50;

export const BOTTOM_SHEET_Z_INDEX = 50;

export const FRAME_LAYOUT_BOTTOM_Z_INDEX = 0;
