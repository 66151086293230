import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {COLOR_SANTA_L} from '@riiid/design-system';

import {DiagnosisReport} from '@santa-web/gen/open-api/content-learning-service';
import {
  LearningDomainKey,
  ListVisibleLearningDomainsResponseLearningDomain as LearningDomain,
} from '@santa-web/gen/open-api/service';
import {Typography} from '@santa-web/service-ui';
import AnalyticsRadarCard, {AnalyticsRadarCardDataTuple} from '@app/components/card/AnalyticsPentagonCard';
import AnalyticsPieImageCard from '@app/components/card/AnalyticsPieImageCard';
import AnalyticsWeakPointCard from '@app/components/card/AnalyticsWeakPointCard';
import ExamDistributionCard from '@app/components/card/ExamDistributionCard';
import ExamRankingCard from '@app/components/card/ExamRankingCard';
import MultiColumn from '@app/components/MultiColumn';
import {DISTRIBUTION_CHART_DATA, TOTAL_USERS} from '@app/constants/diagnosis-data';
import {getSimpleUserSeg, getUserRanking} from '@app/features/diagnosis/utils';
import ComponentObserveTimer from '@app/test-preparation/components/ComponentObserveTimer';

export interface DeepAnalyticsViewProps {
  diagnosisReport: DiagnosisReport;
  isPredictionScoreHidden?: boolean;
  learningDomain: LearningDomain;
}

const ANALYTICS_BY_DOMAIN: Record<string, LearningDomainKey[]> = {
  ranking: [LearningDomainKey.TOEIC],
  distribution: [LearningDomainKey.TOEIC],
  radar: [LearningDomainKey.TOEIC, LearningDomainKey.G_TELP],
  weakness: [LearningDomainKey.TOEIC, LearningDomainKey.G_TELP],
  pie: [LearningDomainKey.TOEIC],
};

// FIXME-LD
const ANALYTICS_WEAK_POINTS_COUNT: Record<LearningDomainKey, number> = {
  [LearningDomainKey.TOEIC]: 59,
  [LearningDomainKey.G_TELP]: 16,
};

const DeepAnalyticsView = ({
  diagnosisReport,
  isPredictionScoreHidden = false,
  learningDomain,
}: DeepAnalyticsViewProps) => {
  const {t} = useTranslation();

  const targetScore = diagnosisReport.skillInformation.targetScore;
  const currentScore = diagnosisReport.estimatedScore.totalScore;
  const pentagon = diagnosisReport.skillInformation.estimationAndTargetPairs.map(estimationAndTargetPair => ({
    subject: estimationAndTargetPair.estimatedTagProficiency.translatedTagName,
    nowPercent: Math.round(estimationAndTargetPair.estimatedTagProficiency.proficiency * 100),
    goalPercent: Math.round(estimationAndTargetPair.targetTagProficiency * 100),
  })) as AnalyticsRadarCardDataTuple;
  const chapterInformation = diagnosisReport.weakChapterInformation.estimationAndTargetPairs.map(
    estimationAndTargetPair => ({
      title: estimationAndTargetPair.estimatedTagProficiency.translatedTagName,
      nowPercent: estimationAndTargetPair.estimatedTagProficiency.proficiency * 100,
      goalPercent: estimationAndTargetPair.targetTagProficiency * 100,
    })
  );

  const userSeg = React.useMemo(() => {
    switch (learningDomain.key) {
      case LearningDomainKey.TOEIC:
        return diagnosisReport.userSegment.toeic;

      case LearningDomainKey.G_TELP:
        return diagnosisReport.userSegment.gTelp;
    }
  }, [diagnosisReport.userSegment.gTelp, diagnosisReport.userSegment.toeic, learningDomain.key]);

  const translatedContents = React.useMemo(() => {
    if (!userSeg || userSeg === 'UNKNOWN') {
      return {
        examDistributionCard: '',
        pieImageCard: '',
      };
    }

    switch (getSimpleUserSeg(userSeg)) {
      case 'basic':
        return {
          examDistributionCard: t('diagnosis_report_view_exam_distribution_card_content_basic'),
          pieImageCard: t('diagnosis_report_view_pie_image_card_content_basic'),
        };
      case 'intermediate':
        return {
          examDistributionCard: t('diagnosis_report_view_exam_distribution_card_content_intermediate'),
          pieImageCard: t('diagnosis_report_view_pie_image_card_content_intermediate'),
        };
      case 'advanced':
        return {
          examDistributionCard: t('diagnosis_report_view_exam_distribution_card_content_advanced'),
          pieImageCard: t('diagnosis_report_view_pie_image_card_content_advanced'),
        };
    }
  }, [t, userSeg]);

  //TODO: ComponentObserveTimer 해결하면 front에서 analytics 순서 보장해서 내려주기 || MultiColumn left right 추가해서 순서 보장 으로 리팩토링
  return (
    <>
      <div>
        {/* FIXME-LD */}
        {learningDomain.key === LearningDomainKey.TOEIC ? (
          <Trans
            t={t}
            i18nKey="diagnosis_report_view_card_section_title"
            values={{
              totalUsers: TOTAL_USERS.toLocaleString(),
            }}
            components={{
              normal: <Typography variant="body1" fontWeight="regular" />,
              strong: <Typography color={COLOR_SANTA_L} variant="body1" fontWeight="bold" />,
            }}
          />
        ) : (
          <Trans
            t={t}
            i18nKey="diagnosis_report_view_card_section_title_domain"
            values={{
              domain: learningDomain.displayName,
            }}
            components={{
              normal: <Typography variant="body1" fontWeight="regular" />,
              strong: <Typography color={COLOR_SANTA_L} variant="body1" fontWeight="bold" />,
            }}
          />
        )}
      </div>
      <MultiColumn>
        {ANALYTICS_BY_DOMAIN.ranking.includes(learningDomain.key) && (
          <ExamRankingCard
            title={t('diagnosis_report_view_exam_ranking_card_title')}
            content={t('diagnosis_report_view_exam_ranking_card_content', {
              userRanking: getUserRanking(currentScore),
            })}
            variant={getUserRanking(currentScore) <= 50 ? 'top' : 'bottom'}
          />
        )}
        {!isPredictionScoreHidden && ANALYTICS_BY_DOMAIN.distribution.includes(learningDomain.key) && (
          <ComponentObserveTimer componentName="relative_score">
            <ExamDistributionCard
              title={t('diagnosis_report_view_exam_distribution_card_title')}
              content={translatedContents.examDistributionCard}
              currentValue={currentScore}
              tooltipContent={t('diagnosis_report_view_exam_distribution_card_tooltip_content')}
              data={DISTRIBUTION_CHART_DATA}
            />
          </ComponentObserveTimer>
        )}
        {ANALYTICS_BY_DOMAIN.radar.includes(learningDomain.key) && (
          <ComponentObserveTimer componentName="pentagon">
            <AnalyticsRadarCard
              title={t('diagnosis_report_view_pentagon_card_title', {
                domain: learningDomain.displayName,
              })}
              content={t('diagnosis_report_view_pentagon_card_content', {
                targetScore,
                domain: learningDomain.displayName,
                counts: pentagon.length,
              })}
              data={pentagon}
              targetScore={Number(targetScore)}
            />
          </ComponentObserveTimer>
        )}
        {ANALYTICS_BY_DOMAIN.weakness.includes(learningDomain.key) && (
          <ComponentObserveTimer componentName="weak_chapter">
            <AnalyticsWeakPointCard
              title={t('diagnosis_report_view_weak_point_card_title')}
              content={t('diagnosis_report_view_weak_point_card_content', {
                domain: learningDomain.displayName,
                targetScore,
                counts: ANALYTICS_WEAK_POINTS_COUNT[learningDomain.key],
              })}
              data={chapterInformation}
              isLegendVisible
              targetScore={Number(targetScore)}
            />
          </ComponentObserveTimer>
        )}
        {ANALYTICS_BY_DOMAIN.pie.includes(learningDomain.key) && (
          <ComponentObserveTimer componentName="DALC">
            <AnalyticsPieImageCard
              title={t('diagnosis_report_view_pie_image_card_title')}
              content={translatedContents.pieImageCard}
              userSeg={userSeg ?? 'UNKNOWN'}
            />
          </ComponentObserveTimer>
        )}
      </MultiColumn>
    </>
  );
};

export default React.memo(DeepAnalyticsView);
