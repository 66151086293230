import { PartialExamScoreConfigFromJSON, PartialExamScoreConfigToJSON, } from './PartialExamScoreConfig';
import { TotalExamScoreConfigFromJSON, TotalExamScoreConfigToJSON, } from './TotalExamScoreConfig';
/**
 * Check if a given object implements the ExamScoreConfig interface.
 */
export function instanceOfExamScoreConfig(value) {
    let isInstance = true;
    isInstance = isInstance && "partials" in value;
    isInstance = isInstance && "total" in value;
    return isInstance;
}
export function ExamScoreConfigFromJSON(json) {
    return ExamScoreConfigFromJSONTyped(json, false);
}
export function ExamScoreConfigFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'partials': (json['partials'].map(PartialExamScoreConfigFromJSON)),
        'total': TotalExamScoreConfigFromJSON(json['total']),
    };
}
export function ExamScoreConfigToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'partials': (value.partials.map(PartialExamScoreConfigToJSON)),
        'total': TotalExamScoreConfigToJSON(value.total),
    };
}
