import React, {useCallback} from 'react';

import {MarketingCampaign} from '@santa-web/gen/open-api/service';
import {EventListPageLayout} from '@app/features/marketing/components';
import {useActiveEventsQuery, useInactiveEventsInfiniteQuery} from '@app/features/marketing/hooks';
import {EventListView, EventListViewProps} from '@app/features/marketing/views';
import {useTypedRouter, useTypedSearchParams} from '@app/hooks/useTypedRouter';

const mapMarketingCampaignToEventItem = (
  promotion: MarketingCampaign,
  isEnded?: boolean
): EventListViewProps['eventItems'][number] => {
  return {
    id: Number(promotion.id),
    isEnded,
    thumbnailTitle: promotion.thumbnailTitle,
    thumbnailDescription: promotion.thumbnailDescription,
    thumbnailImageSet: promotion.thumbnailImageSet,
  };
};

const EventListPageContainer = (): JSX.Element => {
  const router = useTypedRouter();
  const {tab = 'active'} = useTypedSearchParams('/event/list');

  const {data: _activeEvents} = useActiveEventsQuery();
  const {data: inactiveEventPages, hasNextPage, fetchNextPage} = useInactiveEventsInfiniteQuery();

  const handleEventItemClick = useCallback(
    (eventId: number) => {
      router.push({pathname: '/event/detail', query: {promotionId: eventId}});
    },
    [router]
  );

  const activeEvents = React.useMemo(
    () => _activeEvents?.map(item => mapMarketingCampaignToEventItem(item)),
    [_activeEvents]
  );
  const inactiveEvents = React.useMemo(
    () =>
      inactiveEventPages?.pages.flatMap(page =>
        page.marketingCampaigns.map(item => mapMarketingCampaignToEventItem(item, true))
      ),
    [inactiveEventPages?.pages]
  );

  const isLoading = !activeEvents || !inactiveEvents;

  return (
    <EventListPageLayout
      tab={tab}
      onTabChange={tab => router.replace({pathname: '/event/list', query: {tab}})}
      onExit={router.back}>
      {isLoading ? (
        <EventListView.Skeleton />
      ) : (
        <>
          {tab === 'active' && <EventListView eventItems={activeEvents} onItemClick={handleEventItemClick} />}
          {tab === 'inactive' && (
            <EventListView
              isEnded
              hasNextItem={hasNextPage}
              eventItems={inactiveEvents}
              onItemClick={handleEventItemClick}
              onLastItemIntersect={fetchNextPage}
            />
          )}
        </>
      )}
    </EventListPageLayout>
  );
};

export default React.memo(EventListPageContainer);
