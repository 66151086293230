import {useQuery} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';

import {getDefaultValue} from '@santa-web/gen/ssp/messages/toeic/app/ListCISHighlightInfosRequest';
import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {getListHighlightInfosQueryKey} from '@app/queryKeys';
import {HighlightInfo} from '@app/test-preparation/types/highlight';

const useListHighlightInfos = (contentInteractionStateId = -1) => {
  const {HighlightService} = useAtomValue(santaOpenapiServicesAtom);

  return useQuery(getListHighlightInfosQueryKey(contentInteractionStateId), async () => {
    if (
      !contentInteractionStateId ||
      getDefaultValue().contentInteractionStateId === contentInteractionStateId.toString()
    ) {
      return [];
    }

    const response = await HighlightService.listHighlights({contentInteractionStateId});

    return response.highlights.map(_highlight => {
      const {id, previewStr: text, displayInfoJson} = _highlight;
      const {snippetViewId, highlight} = JSON.parse(displayInfoJson) as HighlightInfo;
      const highlightInfo: HighlightInfo = {
        snippetViewId,
        highlight: {
          ...highlight,
          id,
        },
        text,
      };

      return highlightInfo;
    });
  });
};

export default useListHighlightInfos;
