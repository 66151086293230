import {atom} from 'jotai';
import {Content} from '@santa-web/gen/open-api/content/models/Content';

const getContentAtom = atom(() => {
  return async (url: string) => {
    const response = await fetch(url);

    return response.json() as Promise<Content>;
  };
});

export {getContentAtom};
