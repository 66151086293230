/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { CursorPageFromJSON, CursorPageToJSON, } from './CursorPage';
import { MarketingCampaignFromJSON, MarketingCampaignToJSON, } from './MarketingCampaign';
/**
 * Check if a given object implements the ListEndedMarketingCampaignsResponse interface.
 */
export function instanceOfListEndedMarketingCampaignsResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "marketingCampaigns" in value;
    return isInstance;
}
export function ListEndedMarketingCampaignsResponseFromJSON(json) {
    return ListEndedMarketingCampaignsResponseFromJSONTyped(json, false);
}
export function ListEndedMarketingCampaignsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'marketingCampaigns': (json['marketingCampaigns'].map(MarketingCampaignFromJSON)),
        'page': !exists(json, 'page') ? undefined : CursorPageFromJSON(json['page']),
    };
}
export function ListEndedMarketingCampaignsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'marketingCampaigns': (value.marketingCampaigns.map(MarketingCampaignToJSON)),
        'page': CursorPageToJSON(value.page),
    };
}
