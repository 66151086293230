import {useMemo} from 'react';
import {css} from '@emotion/react';

import {Vocabulary} from '@santa-web/gen/open-api/content/models/Vocabulary';
import {FloatingQuestion} from '@santa-web/service-ui';
import BaseVocaView from '@app/components/ContentView/components/BaseVocaView';

export interface VocabularyMemorizeViewProps {
  type: 'word' | 'meaning' | 'full';
  vocabulary: Vocabulary;
  isLoading?: boolean;
  floatingQuestion?: string;
  floatingArea?: JSX.Element;
}

const VocabularyMemorizeView = ({
  type,
  isLoading,
  vocabulary,
  floatingQuestion,
  floatingArea,
}: VocabularyMemorizeViewProps) => {
  const floatingQuestionComponent = useMemo(
    () =>
      floatingQuestion !== undefined ? (
        <FloatingQuestion
          css={css`
            white-space: pre-line;
          `}
          content={floatingQuestion}
        />
      ) : null,
    [floatingQuestion]
  );

  return (
    <BaseVocaView
      type={type}
      isDisabled={isLoading}
      vocabulary={vocabulary}
      floatingQuestion={floatingQuestionComponent}
      floatingArea={floatingArea}
    />
  );
};

export default VocabularyMemorizeView;
