import React from 'react';
import mergeRefs from 'react-merge-refs';
import {useInterval} from 'react-use';

import {ExplanationsObservedPayload} from '@app/api/google-tag-manager/component-elapsed-time';
import useIntersectionObserver from '@app/hooks/useIntersectionObserver';
import {useObservingTimeContext} from '@app/test-preparation/contexts/ObservingTimeContext';
interface ComponentObservedTimeProps {
  children: React.ReactElement;
  isAvailable?: boolean;
  questionIndex?: number;
  componentName: keyof ExplanationsObservedPayload;
  threshold?: number;
}

const HUNDRED_MILLISECONDS = 100;

const ComponentObserveTimer = React.forwardRef<HTMLElement, ComponentObservedTimeProps>(
  ({children, isAvailable = true, questionIndex = 0, componentName, threshold = 0.6}, ref) => {
    const intersectionRef = React.useRef<HTMLElement | null>(null);
    const [isObserved, setIsObserved] = React.useState(false);
    const observingTimeContext = useObservingTimeContext();
    useInterval(
      () => {
        if (observingTimeContext && isAvailable) {
          const observedMilliSeconds = observingTimeContext.componentObservedPayload.current[questionIndex]?.[
            componentName
          ] as number | undefined;
          observingTimeContext.updateComponentObservedPayload(
            {
              [componentName]: observedMilliSeconds
                ? observedMilliSeconds + HUNDRED_MILLISECONDS
                : HUNDRED_MILLISECONDS,
            },
            questionIndex
          );
        }
      },
      isObserved ? HUNDRED_MILLISECONDS : null
    );

    try {
      React.Children.only(children);
    } catch {
      throw new Error('Children should have only one child when check time of observing component.');
    }
    const clonedChild = React.cloneElement(children, {ref: mergeRefs([ref, intersectionRef])});
    useIntersectionObserver({
      onIntersect: () => {
        if (isAvailable) {
          setIsObserved(true);
        }
      },
      offIntersect: () => {
        if (isAvailable) {
          setIsObserved(false);
        }
      },
      target: intersectionRef,
      enabled: true,
      threshold: threshold,
    });

    return clonedChild;
  }
);

export default React.memo(ComponentObserveTimer);
