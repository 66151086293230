import React from 'react';
import {intervalToDuration, isAfter} from 'date-fns';
import useFlashDealEvent from '@app/api/google-tag-manager/flashdeal';
import useOfferEvents from '@app/api/google-tag-manager/offer';
import config, {Config} from '@app/config';
import {useIsNewUserFlashDealAvailable} from '@app/features/marketing/hooks';
import {useFlashDealExitAlertDialog} from '@app/features/marketing/hooks/useFlashDealExitAlertDialog';
import {FlashDealPageView} from '@app/features/marketing/views';
import {useOfferGroupQuery, useOfferGroupsQuery} from '@app/features/offer';
import {mapOfferGroupToOfferGroupCardProps} from '@app/features/offer/utils';
import {useMyPassStatusQuery} from '@app/features/permit';
import useIsUnderAppReview from '@app/hooks/useIsUnderAppReview';
import useNowBySecond from '@app/hooks/useNowBySecond';
import useUser from '@app/hooks/user/useUser';
import {useTypedRouter, useTypedSearchParams} from '@app/hooks/useTypedRouter';

const offerGroupIdMap: Record<Config['env'], number> = {
  development: 13,
  staging: 10,
  production: 1,
};
const offerGroupId = offerGroupIdMap[config.env];

const getCountdown = (from: Date, to: Date): {hour: number; minute: number; second: number} => {
  if (isAfter(from, to)) return {hour: 0, minute: 0, second: 0};
  const {days = 0, hours = 0, minutes = 0, seconds = 0} = intervalToDuration({start: from, end: to});
  return {hour: hours + days * 24, minute: minutes, second: seconds};
};

const FlashDealPageContainer = () => {
  const router = useTypedRouter();
  const {referrer} = useTypedSearchParams('/event/flash-deal');
  const currentDate = useNowBySecond();
  const isUnderAppReview = useIsUnderAppReview();
  const {pushFlashDealExit, pushFlashDealNext, pushFlashDealProductCard} = useFlashDealEvent();
  const {pushClickStore} = useOfferEvents();

  const {data: user} = useUser();
  const {data: defaultOfferGroups} = useOfferGroupsQuery();
  const {data: _offerGroup} = useOfferGroupQuery(offerGroupId);
  const {data: passStatus} = useMyPassStatusQuery();

  const backTo = referrer === 'diagnosis_report' ? '/course' : undefined;

  const offerGroupInfo = React.useMemo(() => {
    if (defaultOfferGroups == null || _offerGroup == null) return undefined;
    // NOTE: 심사중일 때는 default offer group board의 첫번째 인앱 결제 offer group으로 대체합니다.
    if (isUnderAppReview) {
      return {
        boardCode: undefined,
        offerGroup: defaultOfferGroups[0],
      };
    }
    return {boardCode: 'ONBOARDING', offerGroup: _offerGroup};
  }, [_offerGroup, defaultOfferGroups, isUnderAppReview]);

  const exit = () => {
    if (referrer === 'course_cell_report' || referrer === 'diagnosis_report') {
      router.push('/course');
    } else {
      router.back();
    }
  };
  const openExitAlertDialog = useFlashDealExitAlertDialog(exit);

  const isFlashDealAvailable = useIsNewUserFlashDealAvailable();
  const isLoading = isFlashDealAvailable === undefined || !user || !offerGroupInfo || !passStatus;

  if (isLoading) {
    return null;
  }
  if (!isFlashDealAvailable || !passStatus.activePass) {
    router.replace('/404');
    return null;
  }

  const {hour, minute, second} = getCountdown(currentDate, passStatus.activePass.expireAt);

  const createPurchaseClickHandler = (type: 'flashdeal_next_button' | 'flashdeal_product_card') => {
    const pushCorrespondingFlashDealEvent =
      type === 'flashdeal_next_button' ? pushFlashDealNext : pushFlashDealProductCard;

    return async () => {
      pushClickStore({referrer: 'flash_deal'});
      pushCorrespondingFlashDealEvent();
      const query = {offerGroupId: offerGroupInfo.offerGroup.id, referrer: 'flash_deal' as const, backTo};
      router.replace({
        pathname: '/offer-group/board/detail',
        query: offerGroupInfo.boardCode ? {...query, boardCode: offerGroupInfo.boardCode} : query,
      });
    };
  };

  const handleExitClick = async () => {
    pushFlashDealExit();
    if (hour === 0 && minute === 0 && second === 0) {
      openExitAlertDialog();
      return;
    }
    exit();
  };

  return (
    <FlashDealPageView
      offerGroupCardProps={mapOfferGroupToOfferGroupCardProps(offerGroupInfo.offerGroup, currentDate)}
      countdown={{hour, minute, second}}
      onPurchaseButtonClick={createPurchaseClickHandler('flashdeal_next_button')}
      onOfferGroupCardClick={createPurchaseClickHandler('flashdeal_product_card')}
      onExitButtonClick={handleExitClick}
    />
  );
};

export default FlashDealPageContainer;
FlashDealPageContainer.displayName = 'FlashDealPageContainer';
