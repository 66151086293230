import { LearningGoalStatusFromJSON, LearningGoalStatusToJSON, } from './LearningGoalStatus';
/**
 * Check if a given object implements the GetLearningGoalStatusResponse interface.
 */
export function instanceOfGetLearningGoalStatusResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "status" in value;
    return isInstance;
}
export function GetLearningGoalStatusResponseFromJSON(json) {
    return GetLearningGoalStatusResponseFromJSONTyped(json, false);
}
export function GetLearningGoalStatusResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'status': LearningGoalStatusFromJSON(json['status']),
    };
}
export function GetLearningGoalStatusResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'status': LearningGoalStatusToJSON(value.status),
    };
}
