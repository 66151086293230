import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';

import {Button, ButtonProps, Dim, DimProps, SystemErrorLayout, SystemErrorLayoutProps} from '@santa-web/service-ui';

export interface VirtualExamResumeDialogProps extends DimProps {
  onStartClick?(): void;
  SystemErrorLayoutProps?: SystemErrorLayoutProps;
  ButtonProps?: ButtonProps;
}

const VirtualExamResumeDialog = React.forwardRef<HTMLDivElement, VirtualExamResumeDialogProps>(
  function VirtualExamResumeDialog({onStartClick, SystemErrorLayoutProps, ButtonProps, ...props}, ref) {
    const {t} = useTranslation();

    const systemErrorLayout = useMemo(() => {
      const title = t('virtual_exam_resume_dialog_title');
      const button = (
        <Button colorVariant="grey" variant="solid" onClick={onStartClick} {...ButtonProps}>
          {t('virtual_exam_resume_dialog_button')}
        </Button>
      );

      return (
        <SystemErrorLayout
          css={css`
            white-space: pre-line;
          `}
          color="white-fix"
          icon="warning"
          title={title}
          button={button}
          {...SystemErrorLayoutProps}
        />
      );
    }, [t, onStartClick, SystemErrorLayoutProps, ButtonProps]);

    return (
      <Dim
        css={css`
          position: fixed;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
        `}
        color="black"
        ref={ref}
        {...props}>
        {systemErrorLayout}
      </Dim>
    );
  }
);

export default React.memo(VirtualExamResumeDialog);
