import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {COLOR_SANTA_A, COLOR_SANTA_D, COLOR_SANTA_I} from '@riiid/design-system';
import {Table, Typography} from '@santa-web/service-ui';

type ScoreTableHeaderProps = {
  examParts: string[];
  stickyTop?: number;
};

const ScoreTableHeader = ({examParts, stickyTop = 0}: ScoreTableHeaderProps) => {
  const {t} = useTranslation();
  return (
    <Table.Head
      css={css`
        position: sticky;
        top: ${stickyTop}px;
        background: ${COLOR_SANTA_A};
      `}>
      <Table.Row
        role="row"
        css={css`
          & > td {
            padding: 0 0 8px;
            border-bottom: 1px solid ${COLOR_SANTA_D} !important;
          }
        `}>
        <Table.Row.Cell align="center" scope="col">
          <Typography component="p" variant="caption2" color={COLOR_SANTA_I}>
            {t(`page_exam_score_table_field_date`)}
          </Typography>
        </Table.Row.Cell>
        {examParts.map(part => (
          <Table.Row.Cell align="center" key={part} scope="col">
            <Typography component="p" variant="caption2" color={COLOR_SANTA_I}>
              {part}
            </Typography>
          </Table.Row.Cell>
        ))}
        <Table.Row.Cell align="center" scope="col">
          <Typography component="p" variant="caption2" color={COLOR_SANTA_I} fontWeight="bold">
            Total
          </Typography>
        </Table.Row.Cell>
      </Table.Row>
    </Table.Head>
  );
};

export default ScoreTableHeader;
