import {atom} from 'jotai';

import {cisService} from '@app/test-preparation/atoms/services';

type SetCurrentCisIndexAtomParams = {
  index: number;
};

const setCurrentCisIndexAtom = atom(null, async (get, set, {index}: SetCurrentCisIndexAtomParams) => {
  await set(cisService.setCurrentCisIndexAtom, {index});
});

export {setCurrentCisIndexAtom};
