import React from 'react';
import {css} from '@emotion/react';
import {COLOR_SANTA_L_ALPHA, COLOR_SANTA_A} from '@riiid/design-system';
import CloseIcon from '@riiid/design-system/icons/Close';
import {ButtonBase} from '@santa-web/service-ui';

export type CloseButtonProps = {
  onClick(): void;
};

const CloseButton = ({onClick}: CloseButtonProps) => {
  return (
    <ButtonBase
      onClick={onClick}
      css={css`
        display: flex;
        align-items: center;
        justify-content: center;

        position: relative;
        width: 40px;
        height: 40px;
        border-radius: 999px;
        background-color: ${COLOR_SANTA_L_ALPHA(0.4)};
        :hover {
          background-color: ${COLOR_SANTA_L_ALPHA(0.6)};
        }
        :active {
          background-color: ${COLOR_SANTA_L_ALPHA(0.4)};
        }
      `}>
      <CloseIcon width={24} height={24} color={COLOR_SANTA_A} />
    </ButtonBase>
  );
};

export default CloseButton;
CloseButton.displayName = 'CloseButton';
