import { LearningDomainKeyFromJSON, LearningDomainKeyToJSON, } from './LearningDomainKey';
/**
 * Check if a given object implements the ContentResource interface.
 */
export function instanceOfContentResource(value) {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "learningDomainKey" in value;
    isInstance = isInstance && "mimeType" in value;
    isInstance = isInstance && "url" in value;
    return isInstance;
}
export function ContentResourceFromJSON(json) {
    return ContentResourceFromJSONTyped(json, false);
}
export function ContentResourceFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'learningDomainKey': LearningDomainKeyFromJSON(json['learningDomainKey']),
        'mimeType': json['mimeType'],
        'url': json['url'],
    };
}
export function ContentResourceToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'learningDomainKey': LearningDomainKeyToJSON(value.learningDomainKey),
        'mimeType': value.mimeType,
        'url': value.url,
    };
}
