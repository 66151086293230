import React, {useEffect} from 'react';
import {Portal} from 'react-portal';

import {DialogContext, IDialogContext, useDialogContextProvider} from '@app/contexts/DialogContext';
import {useTypedRouter} from '@app/hooks/useTypedRouter';

export type DialogProviderProps = Pick<React.ProviderProps<IDialogContext>, 'children'>;

const DialogProvider = ({children}: DialogProviderProps) => {
  const dialogContext = useDialogContextProvider();
  const {dialog} = dialogContext;
  const router = useTypedRouter();

  useEffect(() => {
    const handleRouteChangeStart = (url: string) => {
      const pathname = url.split('?')[0];
      if (pathname !== router.pathname) {
        dialogContext.dequeue?.();
      }
    };

    router.events.on('routeChangeStart', handleRouteChangeStart);

    return () => {
      router.events.off('routeChangeStart', handleRouteChangeStart);
    };
  }, [router, dialogContext]);

  return (
    <DialogContext.Provider value={dialogContext}>
      {children}
      {dialog && <Portal>{dialog}</Portal>}
    </DialogContext.Provider>
  );
};

export default DialogProvider;
