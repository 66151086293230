import {format, isAfter, isBefore} from 'date-fns';
import Decimal from 'decimal.js';
import {TFunction} from 'i18next';
import {Offer, OfferGroup, Order} from '@santa-web/gen/open-api/service';
import {createPriceFormatter} from '@app/facade/price/price-formatter';
import {OfferGroupDetail, OfferGroupOrderCardLabelListProps, OfferGroupCardProps} from '@app/features/offer';

const getOfferCardErrorLabel = (offer: Offer, offerGroup: OfferGroup, now: Date, t: TFunction): string | undefined => {
  if (isAfter(offerGroup.salesConfig.salesPeriod.startInclusive, now)) {
    return t('offer_card_not_purchasable_not_started', {
      date: format(offerGroup.salesConfig.salesPeriod.startInclusive, 'MM/dd'),
    });
  }
  if (offer.additionalProperties.singlePurchase?.stockConfig?.stockCount === 0) {
    return t('offer_card_not_purchasable_out_of_stock');
  }
  if (isBefore(offerGroup.salesConfig.salesPeriod.endExclusive, now)) {
    return t('offer_card_not_purchasable_expired');
  }
  return offer.isPurchasable ? undefined : t('offer_card_not_purchasable_etc');
};

export const mapOfferGroupToOfferGroupDetail = ({
  t,
  isAnonymous,
  offerGroup,
  now,
  onPurchaseButtonClick,
  onOptionButtonClick,
}: {
  t: TFunction;
  offerGroup: OfferGroup;
  now: Date;
  onPurchaseButtonClick: (offerId: number) => void;
} & Pick<OfferGroupDetail['offerCards'][number], 'onOptionButtonClick' | 'isAnonymous'>): OfferGroupDetail => {
  const {
    id,
    displayConfig: {name: title, detailImageSet},
    offers,
  } = offerGroup;
  return {
    id,
    title,
    imageSrcs: [detailImageSet.imageUrlX1],
    timer: offerGroup.salesConfig.timerConfig && {
      color: offerGroup.salesConfig.timerConfig.bgColor,
      remainedTimeMs: offerGroup.salesConfig.salesPeriod.endExclusive.getTime() - now.getTime(),
    },
    offerCards: offers.map(offer => {
      const {
        id,
        priceConfig: {
          priceAmount: {originalAmount, discountAmount, salesAmount, monthlySalesAmount},
          discountRate,
          currency,
        },
        displayConfig: {name: title, description, isRecommended, priceDisplayType},
      } = offer;
      const canHaveSubPrice = (() => {
        if (offer.additionalProperties.singlePurchase) {
          const representativePassExpirationDays = offer.additionalProperties.singlePurchase.passExpirationInDays;
          return representativePassExpirationDays !== undefined && representativePassExpirationDays >= 60;
        } else if (offer.additionalProperties.subscription) {
          const {term, unit} = offer.additionalProperties.subscription.subscriptionConfig.renewalTerm;
          switch (unit) {
            case 'WEEK':
            case 'MONTH':
              return term !== 1;
            case 'DAY':
              return term !== 30 && term !== 7;
            default:
              return true;
          }
        }
        throw new Error('unreachable');
      })();
      const shouldShowMonthlyPriceOnMain = canHaveSubPrice && priceDisplayType === 'MONTHLY';
      return {
        id,
        title,
        hasOfferOptions: offers.length > 1,
        isRecommended,
        description,

        totalPriceInfo: {
          price: createPriceFormatter(currency).getFormattedPrice(new Decimal(salesAmount)),
          periodInfo: offer.additionalProperties.singlePurchase?.passExpirationInDays
            ? {
                value: offer.additionalProperties.singlePurchase.passExpirationInDays,
                unit: 'DAY',
              }
            : offer.additionalProperties.subscription
            ? {
                value: offer.additionalProperties.subscription.subscriptionConfig.renewalTerm.term,
                unit: offer.additionalProperties.subscription.subscriptionConfig.renewalTerm.unit,
              }
            : undefined,
        },
        monthlyPriceInfo: shouldShowMonthlyPriceOnMain
          ? {
              price: createPriceFormatter(currency).getFormattedPrice(new Decimal(monthlySalesAmount)),
            }
          : undefined,
        shouldShowMonthlyPriceOnMain: shouldShowMonthlyPriceOnMain,
        discount: discountAmount
          ? {
              originalPrice: createPriceFormatter(currency).getFormattedPrice(new Decimal(originalAmount)),
              rate: `${discountRate}%`,
            }
          : undefined,
        remainedCount: offer.additionalProperties.singlePurchase?.stockConfig?.stockCount,
        isAnonymous,
        isSubscription: offer.additionalProperties.subscription !== undefined,
        errorLabel: getOfferCardErrorLabel(offer, offerGroup, now, t),
        onPurchaseButtonClick: () => onPurchaseButtonClick(offer.id),
        onOptionButtonClick,
      };
    }),
  };
};

export const mapOfferGroupOrderToOfferGroupOrderCardLabelListProps = (
  order: Order
): OfferGroupOrderCardLabelListProps => {
  if (order.status === 'CREATED') {
    throw new Error('Order should not be in CREATED state');
  }
  const hasPendingPass = order.permitItems?.passes?.some(pass => pass.status.singlePurchase === 'PENDING');
  if (order.offerGroup.offer.additionalProperties.singlePurchase) {
    return {
      domains: order.offerGroup.targetDomains,
      isSubscription: false,
      status: (() => {
        switch (order.status) {
          case 'PAYMENT_COMPLETED':
          case 'REFUND_REQUESTED':
            return hasPendingPass ? 'pending' : 'normal';
          case 'REVOKED':
            return 'failed';
          case 'REFUNDED':
            return 'refunded';
          case 'EXPIRED':
            return 'expired';
          default:
            throw new Error('invalid order status');
        }
      })(),
    };
  } else {
    return {
      domains: order.offerGroup.targetDomains,
      isSubscription: true,
      subscriptionStatus: (() => {
        switch (order.status) {
          case 'PAYMENT_COMPLETED':
          case 'REFUND_REQUESTED':
            return 'normal';
          case 'PAYMENT_ABORTED':
          case 'SUBSCRIPTION_RENEW_FAILED':
          case 'PAYMENT_COMPLETED_BUT_UNUSABLE':
          case 'REVOKED':
            return 'failed';
          case 'SUBSCRIPTION_CANCELED':
            return 'canceled';
          case 'REFUNDED':
            return 'refunded';
          case 'EXPIRED':
            return 'expired';
          default:
            throw new Error('invalid order status');
        }
      })(),
    };
  }
};

export const mapOfferGroupToOfferGroupCardProps = (
  offerGroup: OfferGroup,
  now: Date
): {id: number} & Omit<OfferGroupCardProps, 'onClick'> => {
  const {
    id,
    offers,
    displayConfig: {
      name: title,
      thumbnailBgImageSet: bgImageSet,
      thumbnailContentImageSet: contentImageSet,
      thumbnailContentImageSetAlign: contentImageSetAlign,
    },
    salesConfig,
  } = offerGroup;
  const {timerConfig} = salesConfig;
  const getIsValidCount = (count: number | undefined): count is number => count !== undefined && count > 0;
  const remainedCount = offerGroup.offers.reduce((prev: number | undefined, cur) => {
    const stockCount = cur.additionalProperties.singlePurchase?.stockConfig?.stockCount;
    if (prev === undefined) {
      return getIsValidCount(stockCount) ? stockCount : undefined;
    }
    return getIsValidCount(stockCount) ? Math.min(prev, stockCount) : prev;
  }, undefined);
  const maxDiscountRate = offers.reduce((prev, {priceConfig}) => {
    return Math.max(Number(priceConfig.discountRate), prev);
  }, 0);
  const timer: OfferGroupCardProps['timer'] = (() => {
    if (!timerConfig) {
      return undefined;
    }
    const remainedTimeMs = salesConfig.salesPeriod.endExclusive.getTime() - now.getTime();
    if (remainedTimeMs <= 0) {
      return undefined;
    }

    const isBeforeDisplayTimerStartsAt =
      salesConfig.timerConfig?.startsAt && isBefore(now, salesConfig.timerConfig.startsAt);

    if (isBeforeDisplayTimerStartsAt) {
      return undefined;
    }

    return {
      colorVariant: timerConfig.bgColor,
      remainedTimeMs,
    };
  })();
  return {
    id,
    title,
    bgImageSet,
    contentImageSet,
    contentImageSetAlign: contentImageSetAlign,
    discount: maxDiscountRate ? `${maxDiscountRate}%` : undefined,
    remainedCount,
    timer,
  };
};
