import produce from 'immer';
import {atom} from 'jotai';

import {QuestionState} from '@santa-web/gen/open-api/content-learning-service';
import {cisService} from '@app/test-preparation/atoms/services';

type ChangeQuestionStatesAtomParams = {
  questionStates: Array<QuestionState>;
};

const changeQuestionStatesAtom = atom(null, async (get, set, {questionStates}: ChangeQuestionStatesAtomParams) => {
  const cis = get(cisService.cisAtom);

  const nextCis = produce(cis, draft => {
    draft.elementInteractionStates.forEach((eis, index) => {
      eis.details.question = questionStates[index];
    });
  });

  await set(cisService.updateCisAtom, {cis: nextCis});
});

export {changeQuestionStatesAtom};
