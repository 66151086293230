/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { HighlightsPreviewFromJSON, ListHighlightsPreviewsResponseFromJSON, } from '../models';
/**
 *
 */
export class HighlightsPreviewV1Api extends runtime.BaseAPI {
    /**
     * 대상 `ContentInteractionState`에 대한 하이라이트 미리보기를 조회한다.
     * 하이라이트 미리보기 조회
     */
    async getHighlightsPreviewRaw(requestParameters, initOverrides) {
        if (requestParameters.contentInteractionStateId === null || requestParameters.contentInteractionStateId === undefined) {
            throw new runtime.RequiredError('contentInteractionStateId', 'Required parameter requestParameters.contentInteractionStateId was null or undefined when calling getHighlightsPreview.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/content-learning-x/v1/highlights-previews/{contentInteractionStateId}`.replace(`{${"contentInteractionStateId"}}`, encodeURIComponent(String(requestParameters.contentInteractionStateId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => HighlightsPreviewFromJSON(jsonValue));
    }
    /**
     * 대상 `ContentInteractionState`에 대한 하이라이트 미리보기를 조회한다.
     * 하이라이트 미리보기 조회
     */
    async getHighlightsPreview(requestParameters, initOverrides) {
        const response = await this.getHighlightsPreviewRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 대상 사용자의 선택된 학습 도메인에 대한 하이라이트 미리보기 목록을 조회한다. 하이라이트 미리보기는 사용자가 생성한 하이라이트를 생성된 `ContentInteractionState`에 대해 묶어서 제공한다. 정렬 순서는 `firstHighlightCreatedAt`의 내림차순이다.
     * 하이라이트 미리보기 목록 조회
     */
    async listHighlightsPreviewsRaw(requestParameters, initOverrides) {
        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size', 'Required parameter requestParameters.size was null or undefined when calling listHighlightsPreviews.');
        }
        if (requestParameters.colorCondition === null || requestParameters.colorCondition === undefined) {
            throw new runtime.RequiredError('colorCondition', 'Required parameter requestParameters.colorCondition was null or undefined when calling listHighlightsPreviews.');
        }
        const queryParameters = {};
        if (requestParameters.cursor !== undefined) {
            queryParameters['cursor'] = requestParameters.cursor;
        }
        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }
        if (requestParameters.colorCondition) {
            queryParameters['colorCondition'] = requestParameters.colorCondition;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/api/content-learning-x/v1/highlights-previews`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListHighlightsPreviewsResponseFromJSON(jsonValue));
    }
    /**
     * 대상 사용자의 선택된 학습 도메인에 대한 하이라이트 미리보기 목록을 조회한다. 하이라이트 미리보기는 사용자가 생성한 하이라이트를 생성된 `ContentInteractionState`에 대해 묶어서 제공한다. 정렬 순서는 `firstHighlightCreatedAt`의 내림차순이다.
     * 하이라이트 미리보기 목록 조회
     */
    async listHighlightsPreviews(requestParameters, initOverrides) {
        const response = await this.listHighlightsPreviewsRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
