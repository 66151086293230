import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';

import {
  BREAKPOINT_MEDIUM,
  COLOR_SANTA_C,
  COLOR_SANTA_D,
  COLOR_SANTA_E,
  COLOR_SANTA_F,
  COLOR_SANTA_I,
  COLOR_SANTA_J,
} from '@riiid/design-system';
import CouponIcon from '@riiid/design-system/icons/Coupon';
import ReportErrorIcon from '@riiid/design-system/icons/ReportError';
import {TabScope, TabScopeButton, TopNavBar, Typography} from '@santa-web/service-ui';
import {Column, SkeletonGrayBox} from '@app/components';
import FrameLayout from '@app/components/FrameLayout';
import ScrollLayout from '@app/components/ScrollLayout';
import {BasePermitCard, PassCard, PassCardProps, TicketCard, TicketCardProps} from '@app/features/permit/components';
import {use100vh} from '@app/hooks/use100vh';
import useIntersectionObserver from '@app/hooks/useIntersectionObserver';
import {withSkeleton} from '@app/utils/component';

export type PermitListPageViewTab = 'pass' | 'ticket';

export type TabProps<Data> = {
  hasNextCard?: boolean;
  items: Data;
  onLastCardVisible: () => void;
};

type PermitListPageViewProps = {
  username: string;
  learningDomain: string;
  currentTab?: PermitListPageViewTab;
  passTabProps: TabProps<({id: number} & PassCardProps)[]>;
  ticketTabProps: TabProps<TicketCardProps[]>;
  onTabChange: (tab: PermitListPageViewTab) => void;
  onBackClick: () => void;
};

const PermitListPageView = ({
  currentTab = 'pass',
  username,
  learningDomain,
  passTabProps,
  ticketTabProps,
  onTabChange,
  onBackClick,
}: PermitListPageViewProps) => {
  const viewportHeight = use100vh();
  const {t} = useTranslation();
  const lastPassCardRef = React.useRef<HTMLLIElement>(null);
  const lastTicketCardRef = React.useRef<HTMLLIElement>(null);

  useIntersectionObserver({
    enabled: currentTab === 'pass' && passTabProps.hasNextCard,
    target: lastPassCardRef,
    onIntersect: passTabProps.onLastCardVisible,
  });

  useIntersectionObserver({
    enabled: currentTab === 'ticket' && ticketTabProps.hasNextCard,
    target: lastTicketCardRef,
    onIntersect: ticketTabProps.onLastCardVisible,
  });

  return (
    <div
      css={css`
        height: ${viewportHeight};
        position: relative;
      `}>
      <FrameLayout topNavBar={<TopNavBar paginationIcon="back" onPaginationIconClick={onBackClick} />}>
        <ScrollLayout
          css={css`
            background-color: ${COLOR_SANTA_C};
          `}>
          <div
            css={css`
              display: flex;
              justify-content: center;
              height: 100%;
            `}>
            <div
              css={css`
                display: flex;
                flex-direction: column;
                gap: 40px;
                width: 100%;
                max-width: 600px;
                height: 100%;
                margin: 40px 20px;
                @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
                  margin: 40px;
                }
              `}>
              <TabScope
                css={css`
                  width: 100%;
                  display: grid;
                  grid-template-columns: 1fr 1fr;
                `}>
                <TabScopeButton isSelected={currentTab === 'pass'} onClick={() => onTabChange('pass')}>
                  {t('page_permit_list_tab_pass')}
                </TabScopeButton>
                <TabScopeButton isSelected={currentTab === 'ticket'} onClick={() => onTabChange('ticket')}>
                  {t('page_permit_list_tab_ticket')}
                </TabScopeButton>
              </TabScope>
              <div>
                <Typography
                  css={css`
                    margin-bottom: 16px;
                  `}
                  component="h1"
                  variant="caption1"
                  fontWeight="bold"
                  color={COLOR_SANTA_I}>
                  {t('page_permit_list_title', {username})}
                </Typography>
                <div
                  css={css`
                    width: 100%;
                    padding: 12px;
                    display: flex;
                    align-items: center;
                    gap: 12px;

                    border: 1px solid ${COLOR_SANTA_E};
                    border-radius: 4px;
                    background-color: ${COLOR_SANTA_D};
                  `}>
                  <ReportErrorIcon width={28} height={28} color={COLOR_SANTA_J} type="line" />
                  <Typography component="p" variant="caption1" color={COLOR_SANTA_I}>
                    {currentTab === 'pass'
                      ? t('page_permit_list_pass_description', {domain: learningDomain})
                      : t('page_permit_list_ticket_description', {domain: learningDomain})}
                  </Typography>
                </div>
              </div>
              <ul
                css={css`
                  height: 100%;
                  margin: 0;
                  padding: 0;
                  list-style: none;

                  display: grid;
                  gap: 12px;
                `}>
                {currentTab === 'pass' &&
                  (passTabProps.items.length === 0 ? (
                    <Empty type="pass" />
                  ) : (
                    passTabProps.items.map(({id, ...props}, idx, arr) => (
                      <li key={id} ref={idx === arr.length - 1 ? lastPassCardRef : null}>
                        <PassCard {...props} />
                      </li>
                    ))
                  ))}
                {currentTab === 'ticket' &&
                  (ticketTabProps.items.length === 0 ? (
                    <Empty type="ticket" />
                  ) : (
                    ticketTabProps.items.map((props, idx, arr) => (
                      <li key={idx} ref={idx === arr.length - 1 ? lastTicketCardRef : null}>
                        <TicketCard {...props} />
                      </li>
                    ))
                  ))}
              </ul>
            </div>
          </div>
        </ScrollLayout>
      </FrameLayout>
    </div>
  );
};

const Empty = ({type}: {type: 'pass' | 'ticket'}) => {
  const {t} = useTranslation();
  return (
    <Column
      css={css`
        height: 100%;
      `}
      gap={16}
      justifyContent="center"
      alignItems="center">
      <CouponIcon width={40} height={40} type="line" color={COLOR_SANTA_F} />
      <Typography component="p" variant="caption1" fontWeight="bold" color={COLOR_SANTA_F}>
        {type === 'pass' ? t('page_permit_list_pass_empty') : t('page_permit_list_ticket_empty')}
      </Typography>
    </Column>
  );
};

const Skeleton = ({onBackClick}: Pick<PermitListPageViewProps, 'onBackClick'>) => {
  const viewportHeight = use100vh();
  return (
    <div
      css={css`
        height: ${viewportHeight};
        position: relative;
      `}>
      <FrameLayout topNavBar={<TopNavBar paginationIcon="back" onPaginationIconClick={onBackClick} />}>
        <ScrollLayout
          css={css`
            background-color: ${COLOR_SANTA_C};
          `}>
          <div
            css={css`
              display: flex;
              justify-content: center;
            `}>
            <div
              css={css`
                display: flex;
                flex-direction: column;
                width: 100%;
                max-width: 600px;
                height: 100%;
                margin: 40px 20px;
                @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
                  margin: 40px;
                }
              `}>
              <SkeletonGrayBox
                css={css`
                  margin-bottom: 16px;
                `}
                width={88}
                height={12}
              />
              <SkeletonGrayBox
                css={css`
                  margin-bottom: 40px;
                `}
                width="100%"
                height={64}
              />
              <div
                css={css`
                  display: grid;
                  gap: 12px;
                `}>
                {Array.from({length: 3}).map((_, idx) => (
                  <BasePermitCard.Skeleton key={idx} />
                ))}
              </div>
            </div>
          </div>
        </ScrollLayout>
      </FrameLayout>
    </div>
  );
};

export default withSkeleton(PermitListPageView, Skeleton);
export type {PermitListPageViewProps};
PermitListPageView.displayName = 'PermitListPageView';
