import {useMutation, useQueryClient} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';
import {Order, PaymentProvider} from '@santa-web/gen/open-api/service';
import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {getOfferGroupOrderQueryKey} from '@app/queryKeys';

export const useUpdateOfferGroupOrderPaymentProviderMutation = (orderId: number) => {
  const {OrderService} = useAtomValue(santaOpenapiServicesAtom);
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (paymentProvider: PaymentProvider): Promise<Order> => {
      return await OrderService.configurePaymentProvider({
        id: orderId,
        configurePaymentProviderRequest: {paymentProvider},
      }).then(({order}) => order);
    },
    onSuccess: order => {
      queryClient.setQueryData(getOfferGroupOrderQueryKey(order.id), order);
    },
  });
};
