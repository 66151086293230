import React from 'react';
import {css} from '@emotion/react';
import {BREAKPOINT_MEDIUM} from '@riiid/design-system';
import {TopNavBar} from '@santa-web/service-ui';
import {OfferLayout} from '@app/features/offer';

type ListLayoutProps = {
  onGoBack: React.MouseEventHandler<HTMLButtonElement>;
  info?: React.ReactNode;
  children: React.ReactNode;
};

const ListLayout = ({onGoBack, info, children}: ListLayoutProps) => {
  return (
    <OfferLayout topNavBar={<TopNavBar paginationIcon="back" onPaginationIconClick={onGoBack} />}>
      <div
        css={css`
          display: flex;
          justify-content: center;
        `}>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: center;
            width: 100%;
            max-width: 600px;
            height: 100%;
            margin: 40px 20px;
            @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
              margin: 40px;
            }
          `}>
          {info && (
            <div
              css={css`
                width: 100%;
                margin-bottom: 40px;
              `}>
              {info}
            </div>
          )}
          <div
            css={css`
              width: 100%;
              height: 100%;
            `}>
            {children}
          </div>
        </div>
      </div>
    </OfferLayout>
  );
};

export default ListLayout;
export type {ListLayoutProps};
ListLayout.displayName = 'ListLayout';
