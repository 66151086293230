import { ToeicVirtualExamEventDetailsFromJSON, ToeicVirtualExamEventDetailsToJSON, } from './ToeicVirtualExamEventDetails';
/**
 * Check if a given object implements the ToeicVirtualExamEvent interface.
 */
export function instanceOfToeicVirtualExamEvent(value) {
    let isInstance = true;
    isInstance = isInstance && "details" in value;
    isInstance = isInstance && "eventTime" in value;
    return isInstance;
}
export function ToeicVirtualExamEventFromJSON(json) {
    return ToeicVirtualExamEventFromJSONTyped(json, false);
}
export function ToeicVirtualExamEventFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'details': ToeicVirtualExamEventDetailsFromJSON(json['details']),
        'eventTime': (new Date(json['eventTime'])),
    };
}
export function ToeicVirtualExamEventToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'details': ToeicVirtualExamEventDetailsToJSON(value.details),
        'eventTime': (value.eventTime.toISOString()),
    };
}
