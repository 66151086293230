import { ToeicVirtualExamContentMetadataFromJSON, ToeicVirtualExamContentMetadataToJSON, } from './ToeicVirtualExamContentMetadata';
/**
 * Check if a given object implements the ToeicVirtualExam interface.
 */
export function instanceOfToeicVirtualExam(value) {
    let isInstance = true;
    isInstance = isInstance && "contentMetadata" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "lcDurationMs" in value;
    isInstance = isInstance && "rcDurationMs" in value;
    return isInstance;
}
export function ToeicVirtualExamFromJSON(json) {
    return ToeicVirtualExamFromJSONTyped(json, false);
}
export function ToeicVirtualExamFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'contentMetadata': ToeicVirtualExamContentMetadataFromJSON(json['contentMetadata']),
        'id': json['id'],
        'lcDurationMs': json['lcDurationMs'],
        'rcDurationMs': json['rcDurationMs'],
    };
}
export function ToeicVirtualExamToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'contentMetadata': ToeicVirtualExamContentMetadataToJSON(value.contentMetadata),
        'id': value.id,
        'lcDurationMs': value.lcDurationMs,
        'rcDurationMs': value.rcDurationMs,
    };
}
