import {useQuery} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';
import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {getMyPassStatusQueryKey} from '@app/queryKeys';

export const useMyPassStatusQuery = () => {
  const {PassService} = useAtomValue(santaOpenapiServicesAtom);
  return useQuery({
    queryKey: getMyPassStatusQueryKey(),
    queryFn: async () => {
      return await PassService.getMyPassStatusV2({});
    },
  });
};
