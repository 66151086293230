import {useQuery} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';
import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {getLatestCouponRegisteredAtQueryKey} from '@app/queryKeys';

export const useLatestCouponRegisteredAtQuery = () => {
  const {CouponService} = useAtomValue(santaOpenapiServicesAtom);
  return useQuery<Date | null>({
    queryKey: getLatestCouponRegisteredAtQueryKey(),
    queryFn: () =>
      CouponService.checkRegisteredCoupon({}).then(({mostRecentRegisteredAt}) => mostRecentRegisteredAt ?? null),
  });
};
