import {atom} from 'jotai';

import {cisRepository} from '@app/test-preparation/atoms/repositories';
import {cisService} from '@app/test-preparation/atoms/services';

const completeCurrentCisAtom = atom(null, async (get, set) => {
  const completeCis = await get(cisRepository.completeCisAtom);

  const cis = get(cisService.cisAtom);

  // Update elapsed time before complete
  await set(cisService.updateCisAtom, {cis});

  const {contentInteractionState: nextCis} = await completeCis(cis.id);
  await set(cisService.updateCisAtom, {cis: nextCis, isMutationOnly: true});
});

export {completeCurrentCisAtom};
