import {Language} from '@santa-web/gen/open-api/service';

export const USER_LANGUAGE_TEXT_MAP: Record<Language, string> = {
  KO: '한국어',
  JA: '日本語',
  ZH: '中文',
  TH: 'ภาษาไทย',
  VI: 'Tiếng Việt',
  EN: 'English',
};
