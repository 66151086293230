import React from 'react';
import {produce} from 'immer';

import {OmrListRow, OmrQuestionDot} from '@santa-web/service-ui';
import {BasePart, VirtualExamPart} from '@app/new-structure/entities/base/parts';
import {QuestionAnswerStateWithIndex} from '@app/new-structure/entities/virtual-exam/QuestionAnswerState';

export interface VirtualExamOmrListRow extends React.ComponentPropsWithoutRef<'div'> {
  part: BasePart;
  contentIndex: number;
  partContentIndex: number;
  questionState: QuestionAnswerStateWithIndex;
  onAnswerDotClick: (
    contentIndex: number,
    questionIndex: number
  ) => (_questionAnswerState: QuestionAnswerStateWithIndex) => Promise<void>;
  onChevronClick: (part: VirtualExamPart, partContentIndex: number) => void;
}

const answerLabels = ['A', 'B', 'C', 'D'];

const VirtualExamOmrListRow = React.forwardRef<HTMLDivElement, VirtualExamOmrListRow>(
  ({part, contentIndex, partContentIndex, questionState, onAnswerDotClick, onChevronClick}, ref) => {
    const currentPartAnswerLabels = React.useMemo(
      () => (part !== 'PART_2' ? answerLabels : answerLabels.slice(0, -1)),
      [part]
    );

    const handleChevronClick = React.useCallback(() => {
      onChevronClick(part, partContentIndex);
    }, [part, partContentIndex, onChevronClick]);

    if (questionState.questionIndex == null) {
      throw new Error('questionIndex in VirtualExamOmrListRow cannot be null!');
    }

    return (
      <OmrListRow label={questionState.questionIndex + 1} ref={ref} onChevronClick={handleChevronClick}>
        {currentPartAnswerLabels.map((answer, answerIndex) => {
          return (
            <OmrQuestionDot
              key={answerIndex}
              isSelected={Number(questionState.answer) === answerIndex}
              onClick={() => {
                if (Number(questionState.answer) === answerIndex || questionState.questionStateIndex == null) return;
                const updatedQuestionState = produce(questionState, draft => {
                  draft.answer = answerIndex;
                });
                onAnswerDotClick(contentIndex, questionState.questionStateIndex)(updatedQuestionState);
              }}>
              {answer}
            </OmrQuestionDot>
          );
        })}
      </OmrListRow>
    );
  }
);

export default React.memo(VirtualExamOmrListRow, (prevProps, nextProps) => {
  return (
    prevProps.questionState.answer === nextProps.questionState.answer &&
    prevProps.questionState.questionIndex === nextProps.questionState.questionIndex &&
    prevProps.questionState.questionStateIndex === nextProps.questionState.questionStateIndex &&
    prevProps.contentIndex === nextProps.contentIndex &&
    prevProps.partContentIndex === nextProps.partContentIndex &&
    prevProps.part === nextProps.part &&
    prevProps.onAnswerDotClick === nextProps.onAnswerDotClick &&
    prevProps.onChevronClick === nextProps.onChevronClick
  );
});
