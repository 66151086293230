import {useContext} from 'react';
import {css} from '@emotion/react';
import {BREAKPOINT_MEDIUM} from '@riiid/design-system';

import {FloatingPaginationButtons} from '@santa-web/service-ui';
import FloatingPaginationButtonsContext from '@app/pages/content-interaction-states/FloatingPaginationButtons/FloatingPaginationButtonsContext';

const useFloatingPaginationButtons = () => {
  const {onPreviousClick, onNextClick, isPreviousDisabled, isNextDisabled, isVisible} = useContext(
    FloatingPaginationButtonsContext
  );

  const floatingPaginationButtons = isVisible ? (
    <div
      css={css`
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: flex-end;
        position: sticky;
        right: 0px;
        bottom: 0px;
        padding: 20px;

        & > * {
          pointer-events: auto;
        }

        @media (min-width: ${BREAKPOINT_MEDIUM}) {
          padding: 40px;
        }
      `}>
      <FloatingPaginationButtons
        onPreviousClick={onPreviousClick}
        onNextClick={onNextClick}
        isPreviousDisabled={isPreviousDisabled}
        isNextDisabled={isNextDisabled}
      />
    </div>
  ) : null;

  return {
    floatingPaginationButtons,
  };
};

export default useFloatingPaginationButtons;
