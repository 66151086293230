import {atom} from 'jotai';
import {setAtomLabels} from '@app/misc/atom-label';
import {learningCellService} from '@app/test-preparation/atoms/services';

const idAtom = atom(
  get => get(learningCellService.learningCellAtom).extraInfo.value.courseLearning?.learningCycleId ?? null
);

export const cycle = {
  idAtom,
};

setAtomLabels(
  {
    idAtom,
  },
  'learningStore.cycle.'
);
