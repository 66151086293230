import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';

import {Button, BottomSheetProps} from '@santa-web/service-ui';
import BottomSheetWithDim, {BottomSheetWithDimProps} from '@app/components/BottomSheetWithDim';
import {ALERT_DIALOG_Z_INDEX} from '@app/constants/zIndex';

export type LearningPermitBottomSheetWithDimProps = BottomSheetWithDimProps & {
  onPositiveButtonClick?: React.MouseEventHandler<HTMLButtonElement>;
  onNegativeButtonClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const LearningPermitBottomSheetWithDim = React.forwardRef<HTMLDivElement, LearningPermitBottomSheetWithDimProps>(
  function ({onPositiveButtonClick, onNegativeButtonClick, ...props}, ref) {
    const {t} = useTranslation();

    const BottomSheetProps = React.useMemo(
      (): BottomSheetProps => ({
        title: t('bottom_sheet_learning_permit_title'),
        description: t('bottom_sheet_learning_permit_subtitle'),
        isLoadingAiAnimationVisible: true,
        buttons: [
          <Button key={1} onClick={onPositiveButtonClick} variant="solid" colorVariant="brand" isFullWidth>
            {t('bottom_sheet_learning_permit_positive')}
          </Button>,
          <Button key={2} onClick={onNegativeButtonClick} variant="solid" colorVariant="grey" isFullWidth>
            {t('bottom_sheet_learning_permit_title_negative')}
          </Button>,
        ],
      }),
      [onNegativeButtonClick, onPositiveButtonClick, t]
    );

    return (
      <BottomSheetWithDim
        css={css`
          z-index: ${ALERT_DIALOG_Z_INDEX};
        `}
        isOpened
        BottomSheetProps={BottomSheetProps}
        ref={ref}
        {...props}
      />
    );
  }
);

LearningPermitBottomSheetWithDim.displayName = 'LearningPermitBottomSheetWithDim';

export default React.memo(LearningPermitBottomSheetWithDim);
