import {
  GetABTestingExperimentRequestPayload,
  GetABTestingExperimentResponsePayload,
  GetAppInfoRequestPayload,
  GetAppInfoResponsePayload,
  GetSafeAreaRequestPayload,
  GetSafeAreaResponsePayload,
  MobileHandlerName,
  OpenLinkRequestPayload,
  OpenLinkResponsePayload,
  SendEventTrackingRequestPayload,
  SendEventTrackingResponsePayload,
  RequestInAppPaymentRequestPayload,
  RequestInAppPaymentResponsePayload,
  RequestIamportPaymentRequestPayload,
  RequestIamportPaymentResponsePayload,
  RequestSocialLoginRequestPayload,
  RequestSocialLoginResponsePayload,
  RequestPermissionRequestPayload,
  RequestPermissionResponsePayload,
  SetUserPropertiesRequestPayload,
  SetUserPropertiesResponsePayload,
  SetIsNavigationGestureAvailableRequestPayload,
  SetIsNavigationGestureAvailableResponsePayload,
  OpenShareSheetRequestPayload,
  OpenShareSheetResponsePayload,
} from '@santa-web/gen/ssp/messages/bridge/mobile';
import {appBridgeRepository} from './AppBridgeRepository';
import {IAppBridgeRepository} from './AppBridgeRepository.interface';

type OAuthProvider = 'APPLE' | 'GOOGLE' | 'KAKAO' | 'LINE';

type RequestSocialLoginResponse<SelectedProvider extends OAuthProvider> = {
  authData: SelectedProvider extends 'APPLE'
    ? {identityToken: string; authorizationCode: string}
    : SelectedProvider extends 'GOOGLE'
    ? {idToken: string}
    : SelectedProvider extends 'KAKAO'
    ? {kakaoAccessToken: string}
    : {lineIdToken: string};
  email?: string;
  name?: string;
};
class MobileService {
  constructor(private readonly repository: IAppBridgeRepository<MobileHandlerName, never>) {}

  async getAppInfo() {
    return await this.repository.requestToMobileApp<GetAppInfoRequestPayload, GetAppInfoResponsePayload>(
      'GET_APP_INFO',
      {}
    );
  }

  async getSafeArea() {
    return await this.repository.requestToMobileApp<
      Required<GetSafeAreaRequestPayload>,
      Required<GetSafeAreaResponsePayload>
    >('GET_SAFE_AREA', {});
  }

  async openLink(url: string) {
    await this.repository.requestToMobileApp<OpenLinkRequestPayload, OpenLinkResponsePayload>('OPEN_LINK', {
      url,
    });
  }

  async sendEventTracking(eventName: string, serializedPayload: string) {
    await this.repository.requestToMobileApp<SendEventTrackingRequestPayload, SendEventTrackingResponsePayload>(
      'SEND_EVENT_TRACKING',
      {name: eventName, payload: serializedPayload}
    );
  }

  async getABTestingExperiment(experimentName: string) {
    return await this.repository.requestToMobileApp<
      GetABTestingExperimentRequestPayload,
      GetABTestingExperimentResponsePayload
    >('GET_AB_TESTING_EXPERIMENT', {name: experimentName});
  }

  async openShareSheet(message: string) {
    await this.repository.requestToMobileApp<OpenShareSheetRequestPayload, OpenShareSheetResponsePayload>(
      'OPEN_SHARE_SHEET',
      {value: message}
    );
  }

  async requestInAppPayment({
    isSubscription,
    storeProductId,
    purchaseIdentifier,
  }: {
    isSubscription: boolean;
    storeProductId: string;
    purchaseIdentifier: string;
  }) {
    return await this.repository.requestToMobileApp<
      RequestInAppPaymentRequestPayload,
      RequestInAppPaymentResponsePayload
    >('REQUEST_IN_APP_PAYMENT', {isConsumable: !isSubscription, storeProductId, purchaseIdentifier});
  }

  async requestIamportPayment(payload: {
    userCode: string;
    pg: string;
    payMethod: string;
    merchantUid: string;
    name: string;
    amount: number;
    appScheme: string;
    company: string;
    buyerTel: string;
    naverUseCfm?: string;
    naverProduct?: {name: string; uid: string; count: number; categoryType: string; categoryId: string};
  }) {
    return await this.repository.requestToMobileApp<
      RequestIamportPaymentRequestPayload,
      RequestIamportPaymentResponsePayload
    >('REQUEST_IAMPORT_PAYMENT', payload);
  }

  // 웹뷰 환경에서 각 OAuth Provider SDK를 사용하지 않고 작업하려면 각 provider마다 특이사항이 너무 많다는 판단 하에
  // (특히 구글 인증의 경우 user-agent를 웹 환경으로 오버라이딩해야 함, 가능은 하지만 좋지 않다고 판단)
  // 앱 환경에서 OAuth login을 진행하고 결과를 웹뷰가 받아 도메인 서버에 로그인하도록 함.
  async requestSocialLogin<SelectedProvider extends OAuthProvider>(
    provider: SelectedProvider
  ): Promise<RequestSocialLoginResponse<SelectedProvider>> {
    const response = await this.repository.requestToMobileApp<
      RequestSocialLoginRequestPayload,
      RequestSocialLoginResponsePayload
    >('REQUEST_SOCIAL_LOGIN', {provider});
    return {authData: JSON.parse(atob(response.authData)), email: response.email, name: response.name};
  }

  async requestPermission(permission: 'PUSH') {
    const {succeeded} = await this.repository.requestToMobileApp<
      RequestPermissionRequestPayload,
      RequestPermissionResponsePayload
    >('REQUEST_PERMISSION', {permission});
    return succeeded;
  }

  async setUserProperties(payload: {userId?: string; learningDomain?: string; language?: string; isLoggedIn?: string}) {
    await this.repository.requestToMobileApp<SetUserPropertiesRequestPayload, SetUserPropertiesResponsePayload>(
      'SET_USER_PROPERTIES',
      payload
    );
  }

  async setIsNavigationGestureAvailable(isAvailable: boolean) {
    await this.repository.requestToMobileApp<
      SetIsNavigationGestureAvailableRequestPayload,
      SetIsNavigationGestureAvailableResponsePayload
    >('SET_IS_NAVIGATION_GESTURE_AVAILABLE', {enabled: isAvailable});
  }
}

export const mobileService = new MobileService(appBridgeRepository);
