import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {COLOR_SANTA_I} from '@riiid/design-system';
import {Typography} from '@santa-web/service-ui';
import {Column} from '@app/components';
import {
  ListLayout,
  OfferGroupOrderCard,
  OfferGroupOrderCardProps,
  ProductOrderNavigationInfo,
} from '@app/features/offer/components';

type OfferGroupOrderListPageViewProps = {
  username: string;
  cardItems: ({id: number} & OfferGroupOrderCardProps)[];
  onProductOrderNavigationButtonClick?: () => void;
  onBackClick: () => void;
};

const OfferGroupOrderListPageView = ({
  username,
  cardItems,
  onProductOrderNavigationButtonClick,
  onBackClick,
}: OfferGroupOrderListPageViewProps) => {
  const {t} = useTranslation();
  return (
    <ListLayout onGoBack={onBackClick}>
      <Column
        gap={16}
        css={css`
          width: 100%;
          margin-bottom: 40px;
        `}>
        <Typography component="h1" variant="caption1" fontWeight="bold" color={COLOR_SANTA_I}>
          {t('page_offer_group_order_list_title', {username})}
        </Typography>
        {onProductOrderNavigationButtonClick && (
          <ProductOrderNavigationInfo onNavigationButtonClick={onProductOrderNavigationButtonClick} />
        )}
      </Column>
      <ul
        css={css`
          margin: 0;
          padding: 0;
          list-style: none;

          display: grid;
          gap: 12px;
        `}>
        {cardItems.map(cardItem => (
          <OfferGroupOrderCard key={cardItem.id} {...cardItem} />
        ))}
      </ul>
    </ListLayout>
  );
};

export default OfferGroupOrderListPageView;
export type {OfferGroupOrderListPageViewProps};
OfferGroupOrderListPageView.displayName = 'OfferGroupOrderListPageView';
