function every(arr, fn) {
    if (!Array.isArray(arr))
        return false;
    return arr.every(fn);
}
function isBoolean(value) { return typeof value === 'boolean'; }
function isNumber(value) { return typeof value === 'number'; }
function isString(value) { return typeof value === 'string'; }
export function encodeDocumentSections(value) { return JSON.stringify(value); }
export function decodeDocumentSections(value) { const obj = JSON.parse(value); if (!isDocumentSections(obj))
    throw new Error(); return obj; }
export function encodeDocumentBlockElements(value) { return JSON.stringify(value); }
export function decodeDocumentBlockElements(value) { const obj = JSON.parse(value); if (!isDocumentBlockElements(obj))
    throw new Error(); return obj; }
export function encodeDocumentInlineElements(value) { return JSON.stringify(value); }
export function decodeDocumentInlineElements(value) { const obj = JSON.parse(value); if (!isDocumentInlineElements(obj))
    throw new Error(); return obj; }
export const roots = ['document-sections', 'document-block-elements', 'document-inline-elements'];
export function isDocumentSections(value) {
    if (value == null)
        return false;
    if (typeof value !== 'object')
        return false;
    if (value['children'] != null && !every(value['children'], isSection)) {
        return false;
    }
    return true;
}
export function isDocumentBlockElements(value) {
    if (value == null)
        return false;
    if (typeof value !== 'object')
        return false;
    if (value['children'] != null && !every(value['children'], isBlockElement)) {
        return false;
    }
    return true;
}
export function isDocumentInlineElements(value) {
    if (value == null)
        return false;
    if (typeof value !== 'object')
        return false;
    if (value['children'] != null && !every(value['children'], isInlineElement)) {
        return false;
    }
    return true;
}
export function isSection(value) {
    if (value == null)
        return false;
    if (typeof value !== 'object')
        return false;
    if (value['children'] != null && !every(value['children'], isBlockElement)) {
        return false;
    }
    return true;
}
export function isBlockElement(value) {
    if (!Array.isArray(value))
        return false;
    if (value.length !== 2)
        return false;
    switch (value[0]) {
        case 'heading': return isHeading(value[1]);
        case 'paragraph': return isParagraph(value[1]);
        case 'figure': return isFigure(value[1]);
        case 'line-numbered-area': return isLineNumberedArea(value[1]);
        case 'precalculated-line-numbered-area': return isPrecalculatedLineNumberedArea(value[1]);
        case 'dialog-list': return isDialogList(value[1]);
        case 'note': return isNote(value[1]);
        case 'table': return isTable(value[1]);
        default: return false;
    }
}
export function isInlineElement(value) {
    if (!Array.isArray(value))
        return false;
    if (value.length !== 2)
        return false;
    switch (value[0]) {
        case 'text': return isText(value[1]);
        case 'math-expression': return isMathExpression(value[1]);
        case 'image': return isImage(value[1]);
        case 'point': return isPoint(value[1]);
        case 'refer-to': return isReferTo(value[1]);
        case 'referred-by': return isReferredBy(value[1]);
        case 'blank': return isBlank(value[1]);
        default: return false;
    }
}
export function isMathExpression(value) {
    if (!Array.isArray(value))
        return false;
    if (value.length !== 2)
        return false;
    switch (value[0]) {
        case 'tex': return isTex(value[1]);
        default: return false;
    }
}
export function isHeading(value) {
    if (value == null)
        return false;
    if (typeof value !== 'object')
        return false;
    if (value['level'] != null && !isHeadingLevel(value['level'])) {
        return false;
    }
    if (value['align'] != null && !isTextAlign(value['align'])) {
        return false;
    }
    if (value['children'] != null && !every(value['children'], isInlineElement)) {
        return false;
    }
    return true;
}
export function isParagraph(value) {
    if (value == null)
        return false;
    if (typeof value !== 'object')
        return false;
    if (value['align'] != null && !isTextAlign(value['align'])) {
        return false;
    }
    if (value['numbering'] != null && !isNumbering(value['numbering'])) {
        return false;
    }
    if (value['indent-level'] != null && !isNumber(value['indent-level'])) {
        return false;
    }
    if (value['children'] != null && !every(value['children'], isInlineElement)) {
        return false;
    }
    return true;
}
export function isFigure(value) {
    if (value == null)
        return false;
    if (typeof value !== 'object')
        return false;
    if (value['element'] != null && !isFigureElement(value['element'])) {
        return false;
    }
    if (value['caption'] != null && !every(value['caption'], isInlineElement)) {
        return false;
    }
    return true;
}
export function isFigureElement(value) {
    if (!Array.isArray(value))
        return false;
    if (value.length !== 2)
        return false;
    switch (value[0]) {
        case 'image': return isImage(value[1]);
        default: return false;
    }
}
export function isLineNumberedArea(value) {
    if (value == null)
        return false;
    if (typeof value !== 'object')
        return false;
    if (value['children'] != null && !every(value['children'], isBlockElement)) {
        return false;
    }
    return true;
}
export function isPrecalculatedLineNumberedArea(value) {
    if (value == null)
        return false;
    if (typeof value !== 'object')
        return false;
    if (value['lines'] != null && !every(value['lines'], isPrecalculatedLine)) {
        return false;
    }
    return true;
}
export function isPrecalculatedLine(value) {
    if (value == null)
        return false;
    if (typeof value !== 'object')
        return false;
    if (value['children'] != null && !every(value['children'], isInlineElement)) {
        return false;
    }
    return true;
}
export function isDialogList(value) {
    if (value == null)
        return false;
    if (typeof value !== 'object')
        return false;
    if (value['children'] != null && !every(value['children'], isDialog)) {
        return false;
    }
    return true;
}
export function isDialog(value) {
    if (value == null)
        return false;
    if (typeof value !== 'object')
        return false;
    if (value['children'] != null && !every(value['children'], isInlineElement)) {
        return false;
    }
    if (value['by'] != null && !isString(value['by'])) {
        return false;
    }
    if (value['at'] != null && !isString(value['at'])) {
        return false;
    }
    return true;
}
export function isText(value) {
    if (value == null)
        return false;
    if (typeof value !== 'object')
        return false;
    if (value['value'] != null && !isString(value['value'])) {
        return false;
    }
    if (value['weight'] != null && !isTextWeight(value['weight'])) {
        return false;
    }
    if (value['italic'] != null && !isBoolean(value['italic'])) {
        return false;
    }
    if (value['underline'] != null && !isBoolean(value['underline'])) {
        return false;
    }
    if (value['strike'] != null && !isBoolean(value['strike'])) {
        return false;
    }
    if (value['supsub'] != null && !isSupsub(value['supsub'])) {
        return false;
    }
    if (value['color'] != null && !isString(value['color'])) {
        return false;
    }
    if (value['font-size'] != null && !isString(value['font-size'])) {
        return false;
    }
    if (value['font-family'] != null && !isString(value['font-family'])) {
        return false;
    }
    return true;
}
export function isTex(value) {
    if (value == null)
        return false;
    if (typeof value !== 'object')
        return false;
    if (value['value'] != null && !isString(value['value'])) {
        return false;
    }
    return true;
}
export function isImage(value) {
    if (value == null)
        return false;
    if (typeof value !== 'object')
        return false;
    if (value['src'] != null && !isString(value['src'])) {
        return false;
    }
    if (value['alt'] != null && !isString(value['alt'])) {
        return false;
    }
    if (value['width'] != null && !isNumber(value['width'])) {
        return false;
    }
    if (value['height'] != null && !isNumber(value['height'])) {
        return false;
    }
    return true;
}
export function isTable(value) {
    if (value == null)
        return false;
    if (typeof value !== 'object')
        return false;
    if (value['head'] != null && !isTableHead(value['head'])) {
        return false;
    }
    if (value['body'] != null && !isTableBody(value['body'])) {
        return false;
    }
    if (value['foot'] != null && !isTableFoot(value['foot'])) {
        return false;
    }
    return true;
}
export function isTableHead(value) {
    if (value == null)
        return false;
    if (typeof value !== 'object')
        return false;
    if (value['rows'] != null && !every(value['rows'], isTableRow)) {
        return false;
    }
    return true;
}
export function isTableBody(value) {
    if (value == null)
        return false;
    if (typeof value !== 'object')
        return false;
    if (value['rows'] != null && !every(value['rows'], isTableRow)) {
        return false;
    }
    return true;
}
export function isTableFoot(value) {
    if (value == null)
        return false;
    if (typeof value !== 'object')
        return false;
    if (value['rows'] != null && !every(value['rows'], isTableRow)) {
        return false;
    }
    return true;
}
export function isTableRow(value) {
    if (value == null)
        return false;
    if (typeof value !== 'object')
        return false;
    if (value['cells'] != null && !every(value['cells'], isTableCell)) {
        return false;
    }
    return true;
}
export function isTableCell(value) {
    if (value == null)
        return false;
    if (typeof value !== 'object')
        return false;
    if (value['align'] != null && !isTextAlign(value['align'])) {
        return false;
    }
    if (value['colspan'] != null && !isNumber(value['colspan'])) {
        return false;
    }
    if (value['rowspan'] != null && !isNumber(value['rowspan'])) {
        return false;
    }
    if (value['children'] != null && !every(value['children'], isBlockElement)) {
        return false;
    }
    if (value['background'] != null && !isString(value['background'])) {
        return false;
    }
    if (value['realtor-background'] != null && !isRealtorTableCellBackground(value['realtor-background'])) {
        return false;
    }
    return true;
}
export function isPoint(value) {
    if (value == null)
        return false;
    if (typeof value !== 'object')
        return false;
    if (value['id'] != null && !isString(value['id'])) {
        return false;
    }
    return true;
}
export function isReferTo(value) {
    if (value == null)
        return false;
    if (typeof value !== 'object')
        return false;
    if (value['start-point-id'] != null && !isString(value['start-point-id'])) {
        return false;
    }
    if (value['end-point-id'] != null && !isString(value['end-point-id'])) {
        return false;
    }
    return true;
}
export function isReferredBy(value) {
    if (value == null)
        return false;
    if (typeof value !== 'object')
        return false;
    if (value['by'] != null && !isString(value['by'])) {
        return false;
    }
    if (value['children'] != null && !every(value['children'], isInlineElement)) {
        return false;
    }
    return true;
}
export function isNote(value) {
    if (value == null)
        return false;
    if (typeof value !== 'object')
        return false;
    if (value['children'] != null && !every(value['children'], isInlineElement)) {
        return false;
    }
    return true;
}
export function isBlank(value) {
    if (value == null)
        return false;
    if (typeof value !== 'object')
        return false;
    if (value['number'] != null && !isNumber(value['number'])) {
        return false;
    }
    return true;
}
export function isRealtorTableCellBackground(value) {
    if (typeof value !== 'string')
        return false;
    return !!isRealtorTableCellBackground.table[value];
}
isRealtorTableCellBackground.table = { '#none': true, '#strong': true };
export function isTextWeight(value) {
    if (typeof value !== 'string')
        return false;
    return !!isTextWeight.table[value];
}
isTextWeight.table = { '#w100': true, '#w200': true, '#w300': true, '#w400': true, '#w500': true, '#w600': true, '#w700': true, '#w800': true, '#w900': true };
export function isTextAlign(value) {
    if (typeof value !== 'string')
        return false;
    return !!isTextAlign.table[value];
}
isTextAlign.table = { '#justify': true, '#left': true, '#center': true, '#right': true };
export function isSupsub(value) {
    if (typeof value !== 'string')
        return false;
    return !!isSupsub.table[value];
}
isSupsub.table = { '#none': true, '#sup': true, '#sub': true };
export function isHeadingLevel(value) {
    if (typeof value !== 'string')
        return false;
    return !!isHeadingLevel.table[value];
}
isHeadingLevel.table = { '#h1': true, '#h2': true, '#h3': true, '#h4': true, '#h5': true, '#h6': true };
export function isNumbering(value) {
    if (typeof value !== 'string')
        return false;
    return !!isNumbering.table[value];
}
isNumbering.table = { '#none': true, '#disc': true, '#decimal': true, '#upper-roman': true, '#lower-roman': true };
export const visitor = {
    visitDocumentSections(visitor, _value) {
        const value = { ..._value };
        if (value['children'] != null) {
            value['children'] = value['children'].map(x => visitor.visitSection(visitor, x));
        }
        return value;
    },
    visitDocumentBlockElements(visitor, _value) {
        const value = { ..._value };
        if (value['children'] != null) {
            value['children'] = value['children'].map(x => visitor.visitBlockElement(visitor, x));
        }
        return value;
    },
    visitDocumentInlineElements(visitor, _value) {
        const value = { ..._value };
        if (value['children'] != null) {
            value['children'] = value['children'].map(x => visitor.visitInlineElement(visitor, x));
        }
        return value;
    },
    visitSection(visitor, _value) {
        const value = { ..._value };
        if (value['children'] != null) {
            value['children'] = value['children'].map(x => visitor.visitBlockElement(visitor, x));
        }
        return value;
    },
    visitBlockElement(visitor, value) {
        switch (value[0]) {
            case 'heading':
                return [value[0], visitor.visitHeading(visitor, value[1])];
            case 'paragraph':
                return [value[0], visitor.visitParagraph(visitor, value[1])];
            case 'figure':
                return [value[0], visitor.visitFigure(visitor, value[1])];
            case 'line-numbered-area':
                return [value[0], visitor.visitLineNumberedArea(visitor, value[1])];
            case 'precalculated-line-numbered-area':
                return [value[0], visitor.visitPrecalculatedLineNumberedArea(visitor, value[1])];
            case 'dialog-list':
                return [value[0], visitor.visitDialogList(visitor, value[1])];
            case 'note':
                return [value[0], visitor.visitNote(visitor, value[1])];
            case 'table':
                return [value[0], visitor.visitTable(visitor, value[1])];
            default:
                return value;
        }
    },
    visitInlineElement(visitor, value) {
        switch (value[0]) {
            case 'text':
                return [value[0], visitor.visitText(visitor, value[1])];
            case 'math-expression':
                return [value[0], visitor.visitMathExpression(visitor, value[1])];
            case 'image':
                return [value[0], visitor.visitImage(visitor, value[1])];
            case 'point':
                return [value[0], visitor.visitPoint(visitor, value[1])];
            case 'refer-to':
                return [value[0], visitor.visitReferTo(visitor, value[1])];
            case 'referred-by':
                return [value[0], visitor.visitReferredBy(visitor, value[1])];
            case 'blank':
                return [value[0], visitor.visitBlank(visitor, value[1])];
            default:
                return value;
        }
    },
    visitMathExpression(visitor, value) {
        switch (value[0]) {
            case 'tex':
                return [value[0], visitor.visitTex(visitor, value[1])];
            default:
                return value;
        }
    },
    visitHeading(visitor, _value) {
        const value = { ..._value };
        if (value['level'] != null) {
            value['level'] = visitor.visitHeadingLevel(visitor, value['level']);
        }
        if (value['align'] != null) {
            value['align'] = visitor.visitTextAlign(visitor, value['align']);
        }
        if (value['children'] != null) {
            value['children'] = value['children'].map(x => visitor.visitInlineElement(visitor, x));
        }
        return value;
    },
    visitParagraph(visitor, _value) {
        const value = { ..._value };
        if (value['align'] != null) {
            value['align'] = visitor.visitTextAlign(visitor, value['align']);
        }
        if (value['numbering'] != null) {
            value['numbering'] = visitor.visitNumbering(visitor, value['numbering']);
        }
        if (value['children'] != null) {
            value['children'] = value['children'].map(x => visitor.visitInlineElement(visitor, x));
        }
        return value;
    },
    visitFigure(visitor, _value) {
        const value = { ..._value };
        if (value['element'] != null) {
            value['element'] = visitor.visitFigureElement(visitor, value['element']);
        }
        if (value['caption'] != null) {
            value['caption'] = value['caption'].map(x => visitor.visitInlineElement(visitor, x));
        }
        return value;
    },
    visitFigureElement(visitor, value) {
        switch (value[0]) {
            case 'image':
                return [value[0], visitor.visitImage(visitor, value[1])];
            default:
                return value;
        }
    },
    visitLineNumberedArea(visitor, _value) {
        const value = { ..._value };
        if (value['children'] != null) {
            value['children'] = value['children'].map(x => visitor.visitBlockElement(visitor, x));
        }
        return value;
    },
    visitPrecalculatedLineNumberedArea(visitor, _value) {
        const value = { ..._value };
        if (value['lines'] != null) {
            value['lines'] = value['lines'].map(x => visitor.visitPrecalculatedLine(visitor, x));
        }
        return value;
    },
    visitPrecalculatedLine(visitor, _value) {
        const value = { ..._value };
        if (value['children'] != null) {
            value['children'] = value['children'].map(x => visitor.visitInlineElement(visitor, x));
        }
        return value;
    },
    visitDialogList(visitor, _value) {
        const value = { ..._value };
        if (value['children'] != null) {
            value['children'] = value['children'].map(x => visitor.visitDialog(visitor, x));
        }
        return value;
    },
    visitDialog(visitor, _value) {
        const value = { ..._value };
        if (value['children'] != null) {
            value['children'] = value['children'].map(x => visitor.visitInlineElement(visitor, x));
        }
        return value;
    },
    visitText(visitor, _value) {
        const value = { ..._value };
        if (value['weight'] != null) {
            value['weight'] = visitor.visitTextWeight(visitor, value['weight']);
        }
        if (value['supsub'] != null) {
            value['supsub'] = visitor.visitSupsub(visitor, value['supsub']);
        }
        return value;
    },
    visitTex(visitor, _value) {
        const value = { ..._value };
        return value;
    },
    visitImage(visitor, _value) {
        const value = { ..._value };
        return value;
    },
    visitTable(visitor, _value) {
        const value = { ..._value };
        if (value['head'] != null) {
            value['head'] = visitor.visitTableHead(visitor, value['head']);
        }
        if (value['body'] != null) {
            value['body'] = visitor.visitTableBody(visitor, value['body']);
        }
        if (value['foot'] != null) {
            value['foot'] = visitor.visitTableFoot(visitor, value['foot']);
        }
        return value;
    },
    visitTableHead(visitor, _value) {
        const value = { ..._value };
        if (value['rows'] != null) {
            value['rows'] = value['rows'].map(x => visitor.visitTableRow(visitor, x));
        }
        return value;
    },
    visitTableBody(visitor, _value) {
        const value = { ..._value };
        if (value['rows'] != null) {
            value['rows'] = value['rows'].map(x => visitor.visitTableRow(visitor, x));
        }
        return value;
    },
    visitTableFoot(visitor, _value) {
        const value = { ..._value };
        if (value['rows'] != null) {
            value['rows'] = value['rows'].map(x => visitor.visitTableRow(visitor, x));
        }
        return value;
    },
    visitTableRow(visitor, _value) {
        const value = { ..._value };
        if (value['cells'] != null) {
            value['cells'] = value['cells'].map(x => visitor.visitTableCell(visitor, x));
        }
        return value;
    },
    visitTableCell(visitor, _value) {
        const value = { ..._value };
        if (value['align'] != null) {
            value['align'] = visitor.visitTextAlign(visitor, value['align']);
        }
        if (value['realtor-background'] != null) {
            value['realtor-background'] = visitor.visitRealtorTableCellBackground(visitor, value['realtor-background']);
        }
        if (value['children'] != null) {
            value['children'] = value['children'].map(x => visitor.visitBlockElement(visitor, x));
        }
        return value;
    },
    visitPoint(visitor, _value) {
        const value = { ..._value };
        return value;
    },
    visitReferTo(visitor, _value) {
        const value = { ..._value };
        return value;
    },
    visitReferredBy(visitor, _value) {
        const value = { ..._value };
        if (value['children'] != null) {
            value['children'] = value['children'].map(x => visitor.visitInlineElement(visitor, x));
        }
        return value;
    },
    visitNote(visitor, _value) {
        const value = { ..._value };
        if (value['children'] != null) {
            value['children'] = value['children'].map(x => visitor.visitInlineElement(visitor, x));
        }
        return value;
    },
    visitBlank(visitor, _value) {
        const value = { ..._value };
        return value;
    },
    visitRealtorTableCellBackground(visitor, value) {
        return value;
    },
    visitTextWeight(visitor, value) {
        return value;
    },
    visitTextAlign(visitor, value) {
        return value;
    },
    visitSupsub(visitor, value) {
        return value;
    },
    visitHeadingLevel(visitor, value) {
        return value;
    },
    visitNumbering(visitor, value) {
        return value;
    }
};
