import { BulkGetContentResourcesResultEntryFromJSON, BulkGetContentResourcesResultEntryToJSON, } from './BulkGetContentResourcesResultEntry';
/**
 * Check if a given object implements the BulkGetContentResourcesResponse interface.
 */
export function instanceOfBulkGetContentResourcesResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "results" in value;
    return isInstance;
}
export function BulkGetContentResourcesResponseFromJSON(json) {
    return BulkGetContentResourcesResponseFromJSONTyped(json, false);
}
export function BulkGetContentResourcesResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'results': (json['results'].map(BulkGetContentResourcesResultEntryFromJSON)),
    };
}
export function BulkGetContentResourcesResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'results': (value.results.map(BulkGetContentResourcesResultEntryToJSON)),
    };
}
