import React from 'react';
import {z} from 'zod';

import {offerGroupReferrerQuery} from '@app/types/navigation';
import {usePushEvent} from '.';

interface BaseOfferEventPayload extends Record<string, unknown> {
  boardCode?: string;
  offerGroupId: number;
}

interface SwipeOfferGroupEventPayload extends BaseOfferEventPayload {
  swipe_direction: 'left' | 'right';
}

interface PushClickOfferBuyEventPayload extends BaseOfferEventPayload {
  offerId: number;
  offerDisplayName: string;
}

type ClickStoreEventPayload = z.infer<typeof offerGroupReferrerQuery>;

const useOfferEvents = () => {
  const pushEvent = usePushEvent();

  const offerEvents = React.useMemo(
    () => ({
      pushClickStore: async (payload: ClickStoreEventPayload) => {
        await pushEvent('click_store', payload);
      },

      pushClickOfferGroupEvent: async (payload: BaseOfferEventPayload) => {
        await pushEvent('click_offer_group', payload);
      },

      pushSwipeOfferGroupEvent: async (payload: SwipeOfferGroupEventPayload) => {
        await pushEvent('offer_group_swipe', payload);
      },

      pushClickOfferOption: async (payload: BaseOfferEventPayload) => {
        await pushEvent('click_offer_option', payload);
      },

      pushClickOfferBuy: async (payload: PushClickOfferBuyEventPayload) => {
        await pushEvent('click_offer_buy', payload);
      },

      pushClickOfferPay: async (payload: PushClickOfferBuyEventPayload) => {
        await pushEvent('click_offer_pay', payload);
      },

      pushClickHomePaid: async (payload: Pick<PushClickOfferBuyEventPayload, 'offerId' | 'offerDisplayName'>) => {
        await pushEvent('click_home_paid', payload);
      },
    }),
    [pushEvent]
  );

  return offerEvents;
};

export default useOfferEvents;
