import { OrderFromJSON, OrderToJSON, } from './Order';
/**
 * Check if a given object implements the OrderResponse interface.
 */
export function instanceOfOrderResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "order" in value;
    return isInstance;
}
export function OrderResponseFromJSON(json) {
    return OrderResponseFromJSONTyped(json, false);
}
export function OrderResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'order': OrderFromJSON(json['order']),
    };
}
export function OrderResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'order': OrderToJSON(value.order),
    };
}
