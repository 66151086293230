/* tslint:disable */
/* eslint-disable */
/**
 * TOEIC 선택학습 실전 모의고사 인스턴스의 상태.
 *
 * - **CREATED**: TOEIC 선택학습 실전 모의고사 인스턴스가 생성됨
 * - **ONGOING**: 사용자가 TOEIC 선택학습 실전 모의고사를 시작함
 * - **SUBMITTED**: 사용자가 TOEIC 선택학습 실전 모의고사의 답안을 최종 제출함
 * - **FINISHED**: TOEIC 선택학습 실전 모의고사 채점이 완료됨
 * @export
 */
export const ToeicVirtualExamInstanceStatus = {
    CREATED: 'CREATED',
    ONGOING: 'ONGOING',
    SUBMITTED: 'SUBMITTED',
    FINISHED: 'FINISHED'
};
export function ToeicVirtualExamInstanceStatusFromJSON(json) {
    return ToeicVirtualExamInstanceStatusFromJSONTyped(json, false);
}
export function ToeicVirtualExamInstanceStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ToeicVirtualExamInstanceStatusToJSON(value) {
    return value;
}
