import React from 'react';
import {css} from '@emotion/react';
import {COLOR_SANTA_F} from '@riiid/design-system';
import CouponIcon from '@riiid/design-system/icons/Coupon';
import {Typography} from '@santa-web/service-ui';
import {Column} from '@app/components';

type CouponListEmptyProps = {
  text: string;
};

const CouponListEmpty = ({text}: CouponListEmptyProps) => {
  return (
    <Column gap={16} alignItems="center" justifyContent="center">
      <CouponIcon
        css={css`
          width: 40px;
          height: 40px;
        `}
        type="line"
        color={COLOR_SANTA_F}
      />
      <Typography variant="caption1" fontWeight="bold" color={COLOR_SANTA_F}>
        {text}
      </Typography>
    </Column>
  );
};

export default CouponListEmpty;
export type {CouponListEmptyProps};
CouponListEmpty.displayName = 'CouponListEmpty';
