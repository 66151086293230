/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { AppleRegistrationInfoFromJSON, AppleRegistrationInfoToJSON, } from './AppleRegistrationInfo';
import { SignupRequestAgreementInfoFromJSON, SignupRequestAgreementInfoToJSON, } from './SignupRequestAgreementInfo';
import { UserPersonalInfoFromJSON, UserPersonalInfoToJSON, } from './UserPersonalInfo';
/**
 * Check if a given object implements the SignupWithAppleRequest interface.
 */
export function instanceOfSignupWithAppleRequest(value) {
    let isInstance = true;
    isInstance = isInstance && "agreementInfo" in value;
    isInstance = isInstance && "appleRegistrationInfo" in value;
    return isInstance;
}
export function SignupWithAppleRequestFromJSON(json) {
    return SignupWithAppleRequestFromJSONTyped(json, false);
}
export function SignupWithAppleRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'agreementInfo': SignupRequestAgreementInfoFromJSON(json['agreementInfo']),
        'appleRegistrationInfo': AppleRegistrationInfoFromJSON(json['appleRegistrationInfo']),
        'personalInfo': !exists(json, 'personalInfo') ? undefined : UserPersonalInfoFromJSON(json['personalInfo']),
    };
}
export function SignupWithAppleRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'agreementInfo': SignupRequestAgreementInfoToJSON(value.agreementInfo),
        'appleRegistrationInfo': AppleRegistrationInfoToJSON(value.appleRegistrationInfo),
        'personalInfo': UserPersonalInfoToJSON(value.personalInfo),
    };
}
