import { ElapsedTimeReportItemFromJSON, ElapsedTimeReportItemToJSON, } from './ElapsedTimeReportItem';
/**
 * Check if a given object implements the ElapsedTimeReport interface.
 */
export function instanceOfElapsedTimeReport(value) {
    let isInstance = true;
    isInstance = isInstance && "items" in value;
    isInstance = isInstance && "titleName" in value;
    return isInstance;
}
export function ElapsedTimeReportFromJSON(json) {
    return ElapsedTimeReportFromJSONTyped(json, false);
}
export function ElapsedTimeReportFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'items': (json['items'].map(ElapsedTimeReportItemFromJSON)),
        'titleName': json['titleName'],
    };
}
export function ElapsedTimeReportToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'items': (value.items.map(ElapsedTimeReportItemToJSON)),
        'titleName': value.titleName,
    };
}
