import { CursorPageFromJSON, CursorPageToJSON, } from './CursorPage';
import { SelfLearningUnitFromJSON, SelfLearningUnitToJSON, } from './SelfLearningUnit';
/**
 * Check if a given object implements the ListSelfLearningUnitsResponse interface.
 */
export function instanceOfListSelfLearningUnitsResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "cells" in value;
    isInstance = isInstance && "page" in value;
    return isInstance;
}
export function ListSelfLearningUnitsResponseFromJSON(json) {
    return ListSelfLearningUnitsResponseFromJSONTyped(json, false);
}
export function ListSelfLearningUnitsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'cells': (json['cells'].map(SelfLearningUnitFromJSON)),
        'page': CursorPageFromJSON(json['page']),
    };
}
export function ListSelfLearningUnitsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'cells': (value.cells.map(SelfLearningUnitToJSON)),
        'page': CursorPageToJSON(value.page),
    };
}
