import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {BREAKPOINT_MEDIUM, COLOR_SANTA_BC, COLOR_SANTA_L} from '@riiid/design-system';
import CheckIcon from '@riiid/design-system/icons/Check';
import {Button, Typography} from '@santa-web/service-ui';
import {BottomSheetListWithDim, BottomSheetListItem} from '@app/components';
import {useDialogContext} from '@app/contexts/DialogContext';

export default function LearningGoalBottomSheet({
  learningGoalOptionList,
  selectedStudyMinutes,
  setSelectedStudyMinutes,
  curStudyMinutesPerDay,
}: {
  learningGoalOptionList: {label: string; value: number}[];
  selectedStudyMinutes: number | null;
  setSelectedStudyMinutes: (value: number | null) => void;
  curStudyMinutesPerDay: number | null;
}) {
  const {t} = useTranslation();
  const {closeDialog} = useDialogContext();
  const [tmpStudyMinutes, setTmpStudyMinutes] = React.useState<number | null>(curStudyMinutesPerDay);

  return (
    <BottomSheetListWithDim
      isOpened={true}
      BottomSheetListProps={{
        onCloseClick: () => {
          if (!selectedStudyMinutes) {
            setTmpStudyMinutes(null);
          } else {
            setTmpStudyMinutes(selectedStudyMinutes);
          }
          closeDialog();
        },
        children: (
          <div
            css={css`
              display: grid;
              grid-gap: 12px;
            `}>
            <Typography
              variant="h5"
              fontWeight="bold"
              color={COLOR_SANTA_L}
              css={css`
                display: none;
                @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
                  display: block;
                  margin-top: 16px;
                  margin-bottom: 40px;
                }
              `}>
              {t('page_set_study_goal_set_description')}
            </Typography>
            {learningGoalOptionList.map(option => (
              <BottomSheetListItem
                key={option.value}
                title={option.label}
                isSelected={option.value === tmpStudyMinutes}
                endContent={
                  option.value === tmpStudyMinutes && <CheckIcon color={COLOR_SANTA_BC} width={24} height={24} />
                }
                onClick={() => setTmpStudyMinutes(option.value)}
              />
            ))}
          </div>
        ),
        buttons: [
          <Button
            key="save"
            isDisabled={tmpStudyMinutes === null}
            colorVariant="brand"
            onClick={() => {
              setSelectedStudyMinutes(tmpStudyMinutes);
              closeDialog();
            }}>
            {t('page_set_study_goal_save_learning_time')}
          </Button>,
        ],
      }}
    />
  );
}
