import React from 'react';
import {css} from '@emotion/react';
import {COLOR_SANTA_D, COLOR_SANTA_E, COLOR_SANTA_H, COLOR_SANTA_L} from '@riiid/design-system';

import {Typography, SwitchToggle} from '@santa-web/service-ui';

export interface BottomSheetListSwitchToggleItemProps extends Omit<React.ComponentPropsWithoutRef<'div'>, 'title'> {
  id: string;
  title: React.ReactNode;
  subText?: React.ReactNode;
  isChecked?: boolean;
  isDisabled?: boolean;
  onChange?: React.ChangeEventHandler<HTMLInputElement>;
}

const BottomSheetListSwitchToggleItem = React.forwardRef<HTMLDivElement, BottomSheetListSwitchToggleItemProps>(
  ({id, title, subText, isChecked, isDisabled, onChange, ...props}, ref) => {
    const titleTypography = (
      <Typography variant="body3" fontWeight="bold" color={isDisabled ? COLOR_SANTA_E : COLOR_SANTA_L}>
        {title}
      </Typography>
    );

    const subTextTypography = subText && (
      <Typography variant="caption1" fontWeight="regular" color={isDisabled ? COLOR_SANTA_E : COLOR_SANTA_H}>
        {subText}
      </Typography>
    );

    return (
      <div
        ref={ref}
        css={css`
          display: flex;
          align-items: center;
          border-bottom: 1px solid ${COLOR_SANTA_D};
          padding-bottom: 12px;
        `}
        {...props}>
        <div
          css={css`
            flex-grow: 1;
            flex-basis: 0;
            margin-right: 23px;
          `}>
          {titleTypography}
          {subTextTypography}
        </div>
        <SwitchToggle isChecked={isChecked} isDisabled={isDisabled} onChange={onChange} inputProps={{id}} />
      </div>
    );
  }
);

export default React.memo(BottomSheetListSwitchToggleItem);
