import {atom} from 'jotai';
import {SummarizeContentInteractionStatesResponse} from '@santa-web/gen/open-api/content-learning-service';
import {setAtomLabels} from '@app/misc/atom-label';
import {ServiceNotInitializedError} from '@app/test-preparation/atoms/services/shared/errors/not-initialized';

const _summaryAtom = atom<SummarizeContentInteractionStatesResponse | null>(null);
const summaryAtom = atom(
  get => {
    const cisesSummary = get(_summaryAtom);

    if (cisesSummary === null) {
      throw new ServiceNotInitializedError('CisService');
    }

    return cisesSummary;
  },
  (get, set, cisesSummary: SummarizeContentInteractionStatesResponse) => {
    set(_summaryAtom, cisesSummary);
  }
);

export {summaryAtom};

setAtomLabels(
  {
    _summaryAtom,
    summaryAtom,
  },
  'cisService.'
);
