import React from 'react';
import {useAtomValue} from 'jotai';
import {LearningDomainKey} from '@santa-web/gen/open-api/service';
import BrowserStorageWithLearningDomain from '@app/api/browser-storage';
import learningDomainAtom from '@app/atoms/core/learning-domain';
import {useLatestCouponRegisteredAtQuery} from '@app/features/coupon';
import {useLatestEventDisplaysAtQuery} from '@app/features/marketing';
import {useLatestOfferGroupDisplaysAtQuery} from '@app/features/offer';

type NewFlagKey = 'offerGroup' | 'coupon' | 'event' | 'total';
export type LatestTimestampInfo = Record<NewFlagKey, number | undefined>;

type NewFlagStorageKey = `${NewFlagKey}LatestTimestamp`;
type LatestTimestampStorageInfo = Record<NewFlagStorageKey, number>;

export const useNewFlag = () => {
  const learningDomain = useAtomValue(learningDomainAtom);
  const {data: latestOfferGroupDisplaysAt} = useLatestOfferGroupDisplaysAtQuery();
  const {data: latestCouponRegisteredAt} = useLatestCouponRegisteredAtQuery();
  const {data: latestEventDisplaysAt} = useLatestEventDisplaysAtQuery();
  const [, forceRender] = React.useState([]);

  return React.useMemo(() => {
    const latestTimestamps: LatestTimestampInfo = getLatestTimestamps({
      offerGroup: latestOfferGroupDisplaysAt?.getTime(),
      coupon: latestCouponRegisteredAt?.getTime(),
      event: latestEventDisplaysAt?.getTime(),
    });
    return {
      isNewFlagMap: getIsNewFlagMap(latestTimestamps, learningDomain.key),
      removeNewFlag: (key: NewFlagKey) => {
        updateStorageTimestamp(key, latestTimestamps, learningDomain.key);
        forceRender([]);
      },
    };
  }, [latestCouponRegisteredAt, latestEventDisplaysAt, latestOfferGroupDisplaysAt, learningDomain.key]);
};

export const getLatestTimestamps = (_latestTimestamps: Omit<LatestTimestampInfo, 'total'>) => {
  const featureTimestamps: Omit<LatestTimestampInfo, 'total'> = {
    offerGroup: _latestTimestamps.offerGroup,
    coupon: _latestTimestamps.coupon,
    event: _latestTimestamps.event,
  };
  const validFeatureTimestamps = Object.values(featureTimestamps).filter(
    (timestamp): timestamp is number => timestamp !== undefined
  );
  return {
    ...featureTimestamps,
    total: validFeatureTimestamps.length === 0 ? undefined : Math.max(...validFeatureTimestamps),
  };
};

export const getIsNewFlagMap = (latestTimestamps: LatestTimestampInfo, currentLearningDomain: LearningDomainKey) => {
  const browserStorage = new BrowserStorageWithLearningDomain<LatestTimestampStorageInfo>(
    localStorage,
    currentLearningDomain
  );

  const storedTimestamps: LatestTimestampInfo = {
    offerGroup: browserStorage.getItem('offerGroupLatestTimestamp') ?? undefined,
    coupon: browserStorage.getItem('couponLatestTimestamp') ?? undefined,
    event: browserStorage.getItem('eventLatestTimestamp') ?? undefined,
    total: browserStorage.getItem('totalLatestTimestamp') ?? undefined,
  };

  return {
    offerGroup: storedTimestamps.offerGroup !== latestTimestamps.offerGroup,
    coupon: storedTimestamps.coupon !== latestTimestamps.coupon,
    event: storedTimestamps.event !== latestTimestamps.event,
    total: storedTimestamps.total !== latestTimestamps.total,
  };
};

export const updateStorageTimestamp = (
  key: NewFlagKey,
  latestTimestamps: LatestTimestampInfo,
  currentLearningDomain: LearningDomainKey
) => {
  const browserStorage = new BrowserStorageWithLearningDomain<LatestTimestampStorageInfo>(
    localStorage,
    currentLearningDomain
  );
  const storageKey = `${key}LatestTimestamp` as NewFlagStorageKey;
  const timestamp = latestTimestamps[key];
  if (timestamp === undefined) {
    browserStorage.removeItem(storageKey);
  } else {
    browserStorage.setItem(storageKey, timestamp);
  }
};
