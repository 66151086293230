/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { GetMyPassStatusResponseV2FromJSON, ListMyPassResponseV2FromJSON, } from '../models';
/**
 *
 */
export class PermitUserV2PassApi extends runtime.BaseAPI {
    /**
     * Pass를 활성화한다.
     * 패스 활성화
     */
    async activatePassV2Raw(requestParameters, initOverrides) {
        if (requestParameters.passId === null || requestParameters.passId === undefined) {
            throw new runtime.RequiredError('passId', 'Required parameter requestParameters.passId was null or undefined when calling activatePassV2.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/permit/v2/pass/{passId}/activate`.replace(`{${"passId"}}`, encodeURIComponent(String(requestParameters.passId))),
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * Pass를 활성화한다.
     * 패스 활성화
     */
    async activatePassV2(requestParameters, initOverrides) {
        await this.activatePassV2Raw(requestParameters, initOverrides);
    }
    /**
     * 현재 pass 상태를 조회한다.
     * 내 패스 상태 조회
     */
    async getMyPassStatusV2Raw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/permit/v2/pass/me/status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetMyPassStatusResponseV2FromJSON(jsonValue));
    }
    /**
     * 현재 pass 상태를 조회한다.
     * 내 패스 상태 조회
     */
    async getMyPassStatusV2(requestParameters, initOverrides) {
        const response = await this.getMyPassStatusV2Raw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 자신에게 지급된 pass 목록을 조회한다.
     * 내 패스 목록 조회
     */
    async listMyPassesV2Raw(requestParameters, initOverrides) {
        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size', 'Required parameter requestParameters.size was null or undefined when calling listMyPassesV2.');
        }
        const queryParameters = {};
        if (requestParameters.cursor !== undefined) {
            queryParameters['cursor'] = requestParameters.cursor;
        }
        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/api/permit/v2/pass/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListMyPassResponseV2FromJSON(jsonValue));
    }
    /**
     * 자신에게 지급된 pass 목록을 조회한다.
     * 내 패스 목록 조회
     */
    async listMyPassesV2(requestParameters, initOverrides) {
        const response = await this.listMyPassesV2Raw(requestParameters, initOverrides);
        return await response.value();
    }
}
