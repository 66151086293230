import React from 'react';
import {css} from '@emotion/react';

import {Typography} from '@santa-web/service-ui';

type AgreementPrivacyInfosProps = {
  title: string;
  subTitle: string;
};

const AgreementPrivacyInfo = React.memo(({title, subTitle}: AgreementPrivacyInfosProps) => {
  return (
    <div
      css={css`
        display: flex;
      `}>
      <Typography variant="caption1" fontWeight="bold" css={{marginRight: '12px'}}>
        {title}
      </Typography>
      <Typography variant="caption1">{subTitle}</Typography>
    </div>
  );
});

export default AgreementPrivacyInfo;
