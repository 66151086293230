import React from 'react';

import useSetUserConfig from '@app/hooks/user-config/useSetUserConfig';
import useUserConfig from '@app/hooks/user-config/useUserConfig';
import {ITestprepConfigChat, useTestprepConfigChat} from '@app/test-preparation/contexts/TestprepConfigContext/chat';
import {
  ITestprepConfigSwitchable,
  useTestprepConfigSwitchable,
} from '@app/test-preparation/contexts/TestprepConfigContext/switchable';
import {ITestprepConfig} from '@app/types/user-config/testprep-config';
import useTestprepConfigFontSize, {ITestprepConfigFontSize} from './font-size';

export interface ITestprepConfigContext {
  switchable: ITestprepConfigSwitchable;
  fontSize: ITestprepConfigFontSize;
  chat: ITestprepConfigChat;
}

const TestprepConfigContext = React.createContext<ITestprepConfigContext | null>(null);

const useTestprepConfigContextValue = (): ITestprepConfigContext | null => {
  const {data: userConfig} = useUserConfig();
  const {mutateAsync: setUserConfig} = useSetUserConfig();

  const testprepConfig = userConfig?.testprepConfig ?? {};

  const setTestprepConfig = (testprepConfig: ITestprepConfig) =>
    setUserConfig({
      userConfig: {
        ...userConfig,
        testprepConfig,
      },
    });

  const switchable = useTestprepConfigSwitchable(testprepConfig, setTestprepConfig);
  const chat = useTestprepConfigChat(testprepConfig, setTestprepConfig);
  const fontSize = useTestprepConfigFontSize();

  return React.useMemo(
    () => ({
      switchable,
      fontSize,
      chat,
    }),
    [chat, fontSize, switchable]
  );
};

export const TestprepConfigContextProvider = ({children}: {children?: React.ReactNode}) => {
  const testprepConfigContextValue = useTestprepConfigContextValue();

  return <TestprepConfigContext.Provider value={testprepConfigContextValue}>{children}</TestprepConfigContext.Provider>;
};

export const useTestprepConfigContext = (): ITestprepConfigContext => {
  const testprepConfigContext = React.useContext(TestprepConfigContext);
  const fontSize = useTestprepConfigFontSize();

  if (!testprepConfigContext) {
    return {
      switchable: {
        switchableItems: [],
        getIsSwitchableActionEnabled: () => false,
        handleSwitchableChange: () => {},
      },
      fontSize,
      chat: {
        isAgree: false,
        handleAgree: () => Promise.resolve(),
      },
    };
  }

  return testprepConfigContext;
};
