/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { CheckActiveMarketingCampaignResponseFromJSON, GetActiveMarketingBannerListResponseFromJSON, GetAvailableMarketingPopupResponseFromJSON, ListAllActiveMarketingCampaignsResponseFromJSON, ListEndedMarketingCampaignsResponseFromJSON, MarketingCampaignFromJSON, MarketingPopupFromJSON, } from '../models';
/**
 *
 */
export class MarketingV1Api extends runtime.BaseAPI {
    /**
     * 현재 진행 중인 마케팅 이벤트를 확인하여 그 중 가장 최근에 시작된 이벤트의 시작 시점을 조회한다.
     */
    async checkActiveMarketingCampaignRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/marketing/v1/campaign/check-active`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => CheckActiveMarketingCampaignResponseFromJSON(jsonValue));
    }
    /**
     * 현재 진행 중인 마케팅 이벤트를 확인하여 그 중 가장 최근에 시작된 이벤트의 시작 시점을 조회한다.
     */
    async checkActiveMarketingCampaign(requestParameters, initOverrides) {
        const response = await this.checkActiveMarketingCampaignRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 현재 사용자의 국가 및 학습 도메인에 대한 활성화 상태의 롤링 배너를 조회한다.
     */
    async getActiveMarketingBannerListRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/marketing/v1/banners/active`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetActiveMarketingBannerListResponseFromJSON(jsonValue));
    }
    /**
     * 현재 사용자의 국가 및 학습 도메인에 대한 활성화 상태의 롤링 배너를 조회한다.
     */
    async getActiveMarketingBannerList(requestParameters, initOverrides) {
        const response = await this.getActiveMarketingBannerListRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 현재 사용자에게 노출 가능한 하단 팝업을 최대 1개 검색한다.
     */
    async getAvailableMarketingPopupRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        if (requestParameters.excludedIds) {
            queryParameters['excludedIds'] = requestParameters.excludedIds;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/api/marketing/v1/popup/available`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetAvailableMarketingPopupResponseFromJSON(jsonValue));
    }
    /**
     * 현재 사용자에게 노출 가능한 하단 팝업을 최대 1개 검색한다.
     */
    async getAvailableMarketingPopup(requestParameters, initOverrides) {
        const response = await this.getAvailableMarketingPopupRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 마케팅 이벤트 ID에 해당하는 마케팅 이벤트 정보를 조회한다.
     */
    async getMarketingCampaignByIdRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getMarketingCampaignById.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/marketing/v1/campaign/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => MarketingCampaignFromJSON(jsonValue));
    }
    /**
     * 마케팅 이벤트 ID에 해당하는 마케팅 이벤트 정보를 조회한다.
     */
    async getMarketingCampaignById(requestParameters, initOverrides) {
        const response = await this.getMarketingCampaignByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 팝업 ID에 해당하는 팝업 정보를 조회한다.
     */
    async getMarketingPopupByIdRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getMarketingPopupById.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/marketing/v1/popup/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => MarketingPopupFromJSON(jsonValue));
    }
    /**
     * 팝업 ID에 해당하는 팝업 정보를 조회한다.
     */
    async getMarketingPopupById(requestParameters, initOverrides) {
        const response = await this.getMarketingPopupByIdRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 현재 진행 중인 마케팅 이벤트를 모두 조회한다.
     */
    async listAllActiveMarketingCampaignsRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/marketing/v1/campaign/active`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListAllActiveMarketingCampaignsResponseFromJSON(jsonValue));
    }
    /**
     * 현재 진행 중인 마케팅 이벤트를 모두 조회한다.
     */
    async listAllActiveMarketingCampaigns(requestParameters, initOverrides) {
        const response = await this.listAllActiveMarketingCampaignsRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 종료된 마케팅 이벤트 목록을 종료 시점 및 마케팅 이벤트 ID의 역순으로 조회한다.
     */
    async listEndedMarketingCampaignsRaw(requestParameters, initOverrides) {
        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size', 'Required parameter requestParameters.size was null or undefined when calling listEndedMarketingCampaigns.');
        }
        const queryParameters = {};
        if (requestParameters.cursor !== undefined) {
            queryParameters['cursor'] = requestParameters.cursor;
        }
        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/api/marketing/v1/campaign/ended`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListEndedMarketingCampaignsResponseFromJSON(jsonValue));
    }
    /**
     * 종료된 마케팅 이벤트 목록을 종료 시점 및 마케팅 이벤트 ID의 역순으로 조회한다.
     */
    async listEndedMarketingCampaigns(requestParameters, initOverrides) {
        const response = await this.listEndedMarketingCampaignsRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
