import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';

import {useAuthButtons} from '@app/features/auth';
import {OnboardingInformationArea, OnboardingLayout} from '@app/features/onboarding';

export interface SignupViewProps {
  onKakaoSignup: React.EventHandler<React.MouseEvent>;
  onGoogleSignup: Parameters<typeof useAuthButtons>[0]['onGoogleAuth'];
  onAppleSignup: React.EventHandler<React.MouseEvent>;
  onLineSignup: React.EventHandler<React.MouseEvent>;
  onEmailSignup: React.EventHandler<React.MouseEvent>;
  isSigningUp?: boolean;
}

const SignupPageView = ({
  onKakaoSignup,
  onGoogleSignup,
  onAppleSignup,
  onLineSignup,
  onEmailSignup,
  isSigningUp,
}: SignupViewProps) => {
  const {t} = useTranslation();
  const buttons = useAuthButtons({
    type: 'signup',
    isLoading: isSigningUp,
    onAppleAuth: onAppleSignup,
    onEmailAuth: onEmailSignup,
    onGoogleAuth: onGoogleSignup,
    onLineAuth: onLineSignup,
    onKakaoAuth: onKakaoSignup,
  });

  return (
    <OnboardingLayout>
      <div
        css={css`
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        `}>
        <OnboardingInformationArea
          shouldShowAiImage
          subTitle={t('page_signup_sub_title')}
          title={t('page_signup_title')}
          description={t('page_signup_description')}
        />
        <div
          css={css`
            display: grid;
            justify-items: center;
            gap: 8px;
          `}>
          {buttons}
        </div>
      </div>
    </OnboardingLayout>
  );
};

export default React.memo(SignupPageView);
