/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { OrderPassStatusOneOfCaseFromJSON, OrderPassStatusOneOfCaseToJSON, } from './OrderPassStatusOneOfCase';
import { SinglePurchasePassStatusFromJSON, SinglePurchasePassStatusToJSON, } from './SinglePurchasePassStatus';
import { SubscriptionPassStatusFromJSON, SubscriptionPassStatusToJSON, } from './SubscriptionPassStatus';
/**
 * Check if a given object implements the OneOfPassStatus interface.
 */
export function instanceOfOneOfPassStatus(value) {
    let isInstance = true;
    isInstance = isInstance && "oneOfCase" in value;
    return isInstance;
}
export function OneOfPassStatusFromJSON(json) {
    return OneOfPassStatusFromJSONTyped(json, false);
}
export function OneOfPassStatusFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'oneOfCase': OrderPassStatusOneOfCaseFromJSON(json['oneOfCase']),
        'singlePurchase': !exists(json, 'singlePurchase') ? undefined : SinglePurchasePassStatusFromJSON(json['singlePurchase']),
        'subscription': !exists(json, 'subscription') ? undefined : SubscriptionPassStatusFromJSON(json['subscription']),
    };
}
export function OneOfPassStatusToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'oneOfCase': OrderPassStatusOneOfCaseToJSON(value.oneOfCase),
        'singlePurchase': SinglePurchasePassStatusToJSON(value.singlePurchase),
        'subscription': SubscriptionPassStatusToJSON(value.subscription),
    };
}
