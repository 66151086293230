import {useRouter} from 'next/router';
import React from 'react';
import {css} from '@emotion/react';

import {Button, Typography} from '@santa-web/service-ui';
import {use100vh} from '@app/hooks/use100vh';

const NotImplementedLearningDomainDiagnosisPageContainer = () => {
  const router = useRouter();
  const viewportHeight = use100vh();
  return (
    <div
      css={css`
        height: ${viewportHeight};
        padding: 10vh 0;
        display: flex;
        flex-direction: column;
        align-items: center;
      `}>
      <Typography variant="h5">Diagnosis report page</Typography>
      <Typography>! Not implemented for this domain yet !</Typography>
      <Typography>Click below button to exit</Typography>
      <Button
        css={css`
          margin-top: 30px;
        `}
        colorVariant="brand"
        onClick={router.back}>
        Exit from this page
      </Button>
    </div>
  );
};

export default React.memo(NotImplementedLearningDomainDiagnosisPageContainer);
