import { PartialEstimatedScoreFromJSON, PartialEstimatedScoreToJSON, } from './PartialEstimatedScore';
/**
 * Check if a given object implements the EstimatedScore interface.
 */
export function instanceOfEstimatedScore(value) {
    let isInstance = true;
    isInstance = isInstance && "isPercent" in value;
    isInstance = isInstance && "maxScore" in value;
    isInstance = isInstance && "partials" in value;
    isInstance = isInstance && "totalScore" in value;
    return isInstance;
}
export function EstimatedScoreFromJSON(json) {
    return EstimatedScoreFromJSONTyped(json, false);
}
export function EstimatedScoreFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'isPercent': json['isPercent'],
        'maxScore': json['maxScore'],
        'partials': (json['partials'].map(PartialEstimatedScoreFromJSON)),
        'totalScore': json['totalScore'],
    };
}
export function EstimatedScoreToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'isPercent': value.isPercent,
        'maxScore': value.maxScore,
        'partials': (value.partials.map(PartialEstimatedScoreToJSON)),
        'totalScore': value.totalScore,
    };
}
