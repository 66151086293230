import { CursorPageFromJSON, CursorPageToJSON, } from './CursorPage';
import { SelfLearningUnitGroupFromJSON, SelfLearningUnitGroupToJSON, } from './SelfLearningUnitGroup';
/**
 * Check if a given object implements the GetSelfLearningUnitGroupsResponse interface.
 */
export function instanceOfGetSelfLearningUnitGroupsResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "groups" in value;
    isInstance = isInstance && "page" in value;
    return isInstance;
}
export function GetSelfLearningUnitGroupsResponseFromJSON(json) {
    return GetSelfLearningUnitGroupsResponseFromJSONTyped(json, false);
}
export function GetSelfLearningUnitGroupsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'groups': (json['groups'].map(SelfLearningUnitGroupFromJSON)),
        'page': CursorPageFromJSON(json['page']),
    };
}
export function GetSelfLearningUnitGroupsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'groups': (value.groups.map(SelfLearningUnitGroupToJSON)),
        'page': CursorPageToJSON(value.page),
    };
}
