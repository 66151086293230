import {useQuery} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';

import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {getGetUserConfigQueryKey} from '@app/queryKeys';
import {IUserConfig} from '@app/types/user-config';
import {SantaResponseError} from '@app/utils/error';

const useUserConfig = () => {
  const {UserConfigService} = useAtomValue(santaOpenapiServicesAtom);

  return useQuery(getGetUserConfigQueryKey(), async (): Promise<IUserConfig> => {
    try {
      const response = await UserConfigService.getMyUserConfig({});
      const userConfig: IUserConfig = JSON.parse(response.userConfig.json ?? '{}');
      return userConfig;
    } catch (e) {
      if (e instanceof SantaResponseError && e.response.status === 404) {
        return {};
      }
      throw e;
    }
  });
};

export default useUserConfig;
