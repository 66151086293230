import React, {CSSProperties} from 'react';
import {css} from '@emotion/react';
import {COLOR_SANTA_C} from '@riiid/design-system';

type DividerProps = {
  className?: string;
  height?: CSSProperties['height'];
  color?: CSSProperties['backgroundColor'];
};

const Divider = ({className, height = '8px', color = COLOR_SANTA_C}: DividerProps) => {
  return (
    <div
      css={css`
        width: 100%;
        height: ${typeof height === 'string' ? height : height + 'px'};
        background-color: ${color};
      `}
      className={className}
    />
  );
};

export default Divider;
export type {DividerProps};
Divider.displayName = 'Divider';
