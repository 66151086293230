/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React from 'react';
import {useTranslation} from 'react-i18next';
import Decimal from 'decimal.js';
import {PaymentProvider} from '@santa-web/gen/open-api/service';
import useOfferEvents from '@app/api/google-tag-manager/offer';
import {LoadingDim} from '@app/components';
import {createPriceFormatter} from '@app/facade/price/price-formatter';
import {useAvailableDiscountCouponsQuery} from '@app/features/coupon';
import {
  useOfferGroupOrderPageCommonViewProps,
  OfferGroupOrderPageView,
  useCheckoutOfferGroupOrder,
  useOfferGroupOrderCancelCouponMutation,
  useOfferGroupOrderCouponBottomSheetContainer,
  usePrepareCheckoutOfferGroupOrderMutation,
  useUpdateOfferGroupOrderPaymentProviderMutation,
  OfferGroupOrderPaymentProvider,
  OfferGroupOrderState,
  useOfferGroupOrderQuery,
  OfferGroupOrderPageViewProps,
} from '@app/features/offer';
import useIsUnderAppReview from '@app/hooks/useIsUnderAppReview';
import {useTypedRouter, useTypedSearchParams} from '@app/hooks/useTypedRouter';

const OfferGroupOrderPageContainer = () => {
  const {t} = useTranslation();
  const [isLoading, setIsLoading] = React.useState(false);
  const router = useTypedRouter();
  const {pushClickOfferPay} = useOfferEvents();
  const searchParams = useTypedSearchParams('/offer-group/order');
  const {id} = searchParams;
  const commonViewProps = useOfferGroupOrderPageCommonViewProps(id);

  const isUnderAppReview = useIsUnderAppReview();
  const {data: order} = useOfferGroupOrderQuery(id);
  const {data: coupons} = useAvailableDiscountCouponsQuery(
    {discountMethods: order?.orderConfig?.availableCouponDiscountMethods ?? []},
    {enabled: !!order}
  );
  const {mutateAsync: cancelCoupon, isLoading: isCancelingCoupon} = useOfferGroupOrderCancelCouponMutation(id);
  const {mutateAsync: updatePaymentProvider, isLoading: isUpdatingPaymentProvider} =
    useUpdateOfferGroupOrderPaymentProviderMutation(id);
  const {mutateAsync: prepareCheckoutOrder, isLoading: isLoadingPrepareCheckoutOrder} =
    usePrepareCheckoutOfferGroupOrderMutation(id);
  const checkoutOrder = useCheckoutOfferGroupOrder(id);

  const openCouponBottomSheet = useOfferGroupOrderCouponBottomSheetContainer(id);

  const isMutating = isUpdatingPaymentProvider || isCancelingCoupon;
  const isProcessingCheckout = isLoadingPrepareCheckoutOrder || isLoading;

  if (!commonViewProps || !order || !coupons) {
    return <LoadingDim />;
  }

  const offerGroup = order.offerGroup;
  const offer = offerGroup.offer;
  const priceFormatter = createPriceFormatter(order.amountData.currency);

  const subscriptionInfo: OfferGroupOrderPageViewProps['subscriptionInfo'] = offer.additionalProperties.subscription
    ? {
        duration: (() => {
          const {unit, term} = offer.additionalProperties.subscription.subscriptionConfig.renewalTerm;
          switch (unit) {
            case 'DAY':
              return t('dict_n_days', {n: term});
            case 'WEEK':
              return t('dict_n_weeks', {n: term});
            case 'MONTH':
              return t('dict_n_months', {n: term});
            case 'YEAR':
              return t('dict_n_years', {n: term});
          }
        })(),
      }
    : undefined;

  const paymentProviderInfo: OfferGroupOrderPageViewProps['paymentProviderInfo'] = (() => {
    const iamportPaymentProviders = order.orderConfig!.availablePaymentProviders.filter(
      (paymentProvider): paymentProvider is Extract<PaymentProvider, 'IAMPORT_KCP' | 'IAMPORT_NAVERPAY'> =>
        paymentProvider === 'IAMPORT_KCP' || paymentProvider === 'IAMPORT_NAVERPAY'
    );
    if (iamportPaymentProviders.length === 0) {
      return undefined;
    }
    const mapPaymentProvider = (paymentProvider: PaymentProvider): OfferGroupOrderPaymentProvider =>
      paymentProvider === 'IAMPORT_KCP' ? 'kcp' : 'naverpay';

    return {
      items: iamportPaymentProviders.map(mapPaymentProvider),
      appliedPaymentProvider: mapPaymentProvider(order.orderConfig!.selectedPaymentProvider),
      onPaymentProviderSelect: (paymentProvider: OfferGroupOrderPaymentProvider) =>
        updatePaymentProvider(paymentProvider === 'kcp' ? 'IAMPORT_KCP' : 'IAMPORT_NAVERPAY'),
    };
  })();

  const couponInfo: OfferGroupOrderPageViewProps['couponInfo'] =
    order.orderConfig!.isCouponApplicable && !isUnderAppReview
      ? {
          totalCount: coupons.coupons.length,
          appliedItems: (order.orderConfig?.appliedCouponIds ?? []).map(couponId => {
            const coupon = coupons.coupons.find(coupon => coupon.id === couponId);
            if (!coupon) {
              throw new Error('Applied coupon is not in coupon list');
            }
            const discountConfig = coupon.details?.discount;
            return {
              id: coupon.id,
              name: coupon.displayName,
              information: t('offer_group_order_coupon_discount_label', {
                amount: `${
                  discountConfig?.method?.oneOfCase === 'AMOUNT' && discountConfig.method.amount
                    ? priceFormatter.getFormattedPrice(new Decimal(discountConfig.method.amount))
                    : `${discountConfig?.method?.rate}%`
                }`,
              }),
            };
          }),
          onCouponButtonClick: openCouponBottomSheet,
          onCouponCancelClick: cancelCoupon,
        }
      : undefined;

  const handlePurchaseClick = async () => {
    try {
      setIsLoading(true);
      pushClickOfferPay({
        offerGroupId: offerGroup.id,
        boardCode: searchParams?.boardCode,
        offerId: offerGroup.offer.id,
        offerDisplayName: offerGroup.offer.displayConfig.name,
      });
      const order = await prepareCheckoutOrder();
      // 0원 결제일 때 해당 케이스 발생할 수 있음
      if (order.status === OfferGroupOrderState.PAYMENT_COMPLETED) {
        router.push({pathname: '/offer-group/order/result', query: {id}});
        return;
      }
      await checkoutOrder();
      // NOTE: 이후 각 payment provider에 맞는 결제가 진행되고 /offer-group/order/complete로 redirection됨
    } catch (e) {
      // 정상 결제 케이스에서는 반드시 다른 페이지로 redirection되므로 페이지를 이탈할 때까지 계속 로딩 상태를 보여주고, 에러 상황에서만 false로 세팅해줌
      setIsLoading(false);
      throw e;
    }
  };

  return (
    <OfferGroupOrderPageView
      {...commonViewProps}
      isUpdatingOrder={isMutating}
      isProcessingCheckout={isProcessingCheckout}
      paymentProviderInfo={paymentProviderInfo}
      couponInfo={couponInfo}
      subscriptionInfo={subscriptionInfo}
      onPurchaseClick={handlePurchaseClick}
    />
  );
};

export default OfferGroupOrderPageContainer;
OfferGroupOrderPageContainer.displayName = 'OfferGroupOrderPageContainer';
