/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
/**
 * Check if a given object implements the PointRewardMyL2ePoint interface.
 */
export function instanceOfPointRewardMyL2ePoint(value) {
    let isInstance = true;
    isInstance = isInstance && "currentPoint" in value;
    isInstance = isInstance && "dDay" in value;
    isInstance = isInstance && "targetPoint" in value;
    return isInstance;
}
export function PointRewardMyL2ePointFromJSON(json) {
    return PointRewardMyL2ePointFromJSONTyped(json, false);
}
export function PointRewardMyL2ePointFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'currentPoint': json['currentPoint'],
        'dDay': json['dDay'],
        'pointDifference': !exists(json, 'pointDifference') ? undefined : json['pointDifference'],
        'targetPoint': json['targetPoint'],
    };
}
export function PointRewardMyL2ePointToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'currentPoint': value.currentPoint,
        'dDay': value.dDay,
        'pointDifference': value.pointDifference,
        'targetPoint': value.targetPoint,
    };
}
