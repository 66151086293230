import { PartialExamScoreFromJSON, PartialExamScoreToJSON, } from './PartialExamScore';
/**
 * Check if a given object implements the CreateExamScoreRequest interface.
 */
export function instanceOfCreateExamScoreRequest(value) {
    let isInstance = true;
    isInstance = isInstance && "date" in value;
    isInstance = isInstance && "partialScores" in value;
    isInstance = isInstance && "totalScore" in value;
    return isInstance;
}
export function CreateExamScoreRequestFromJSON(json) {
    return CreateExamScoreRequestFromJSONTyped(json, false);
}
export function CreateExamScoreRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'date': (new Date(json['date'])),
        'partialScores': (json['partialScores'].map(PartialExamScoreFromJSON)),
        'totalScore': json['totalScore'],
    };
}
export function CreateExamScoreRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'date': (value.date.toISOString().substr(0, 10)),
        'partialScores': (value.partialScores.map(PartialExamScoreToJSON)),
        'totalScore': value.totalScore,
    };
}
