import {
  UpdateLearningCellForbiddenErrorCase,
  StartContentInteractionStateForbiddenErrorCase,
} from '@santa-web/gen/open-api/content-learning-service';
import {SantaResponseError} from '@app/utils/error';

class PermissionDeniedError extends Error {
  constructor(public cause: Error) {
    super('Permission denied error');

    this.name = 'PermissionDeniedError';
  }

  static shouldThrow(error: Error) {
    return (
      error instanceof SantaResponseError &&
      (error.santaErrorCode === UpdateLearningCellForbiddenErrorCase.NO_AUTHORITY ||
        error.santaErrorCode === StartContentInteractionStateForbiddenErrorCase.NO_AUTHORITY)
    );
  }
}

export {PermissionDeniedError};
