import {useCallback} from 'react';
import {useAtomValue} from 'jotai';

import {ContentResource} from '@santa-web/gen/open-api/content-learning-service';
import {Content} from '@santa-web/gen/open-api/content/models/Content';
import {LearningDomainKey} from '@santa-web/gen/open-api/service/models/LearningDomainKey';
import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';

function getToeicResourceIds(content: Content) {
  return [
    ...(content.oneOfDomainContent.toeicContent?.oneOfType.questionSet?.passages.map(
      passage => passage.audioResourceId
    ) ?? []),
    ...(content.oneOfDomainContent.toeicContent?.oneOfType.questionSet?.questions.map(
      question => question.audioResourceId
    ) ?? []),
    content.oneOfDomainContent.toeicContent?.oneOfType.vocabulary?.voiceResourceId,
    content.oneOfDomainContent.toeicContent?.oneOfType.vocabulary?.accentResources.enGb,
    content.oneOfDomainContent.toeicContent?.oneOfType.vocabulary?.accentResources.enUs,
    ...(content.oneOfDomainContent.toeicContent?.oneOfType.vocabulary?.vocaPosSet.flatMap(postSet => [
      ...postSet.vocaExamples.flatMap(example => [example.accentResourceId.enGb, example.accentResourceId.enUs]),
      ...postSet.posImageResourceIds,
      postSet.accentResourceId.enGb,
      postSet.accentResourceId.enUs,
      ...postSet.posImageResourceIds,
    ]) ?? []),
    ...(content.oneOfDomainContent.toeicContent?.oneOfType.vocabulary?.examples.flatMap(example => [
      example.accentResourceId.enGb,
      example.accentResourceId.enUs,
    ]) ?? []),
  ];
}

function getGtelpResourceIds(content: Content) {
  return [
    ...(content.oneOfDomainContent.gtelpContent?.oneOfType.questionSet?.passages.map(
      passage => passage.audioResourceId
    ) ?? []),
    ...(content.oneOfDomainContent.gtelpContent?.oneOfType.questionSet?.questions.map(
      question => question.audioResourceId
    ) ?? []),
    content.oneOfDomainContent.gtelpContent?.oneOfType.vocabulary?.voiceResourceId,
    content.oneOfDomainContent.gtelpContent?.oneOfType.vocabulary?.accentResources.enGb,
    content.oneOfDomainContent.gtelpContent?.oneOfType.vocabulary?.accentResources.enUs,
    ...(content.oneOfDomainContent.gtelpContent?.oneOfType.vocabulary?.vocaPosSet.flatMap(postSet => [
      ...postSet.vocaExamples.flatMap(example => [example.accentResourceId.enGb, example.accentResourceId.enUs]),
      ...postSet.posImageResourceIds,
      postSet.accentResourceId.enGb,
      postSet.accentResourceId.enUs,
      ...postSet.posImageResourceIds,
    ]) ?? []),
    ...(content.oneOfDomainContent.gtelpContent?.oneOfType.vocabulary?.examples.flatMap(example => [
      example.accentResourceId.enGb,
      example.accentResourceId.enUs,
    ]) ?? []),
  ];
}

function getResourceIds(learningDomainKey: LearningDomainKey, content: Content | null | undefined) {
  if (content == null) {
    return [];
  }

  return {
    [LearningDomainKey.TOEIC]: getToeicResourceIds,
    [LearningDomainKey.G_TELP]: getGtelpResourceIds,
  }
    [learningDomainKey](content)
    .filter((resourceId): resourceId is string => resourceId !== undefined && resourceId !== '');
}

function useFetchResources(): (content: Content | null | undefined) => Promise<Record<string, ContentResource>> {
  const {ContentResourceService} = useAtomValue(santaOpenapiServicesAtom);

  return useCallback(
    async (content: Content | null | undefined): Promise<Record<string, ContentResource>> => {
      if (content == null) return {};

      const contentDomain = content.baseContent.domain;

      const resourceIds = getResourceIds(contentDomain === 'GTELP' ? 'G_TELP' : 'TOEIC', content);
      if (resourceIds.length === 0) return {};
      const {results} = await ContentResourceService.bulkGetContentResources({ids: resourceIds});
      return Object.fromEntries(
        results.map(result => result.success!).map(resource => [String(resource.id), resource])
      );
    },
    [ContentResourceService]
  );
}

export default useFetchResources;
