import {Table} from '@santa-web/service-ui';
import {ScoreRowClickHandler, ScoreInfo} from '@app/features/preferences/types';
import ScoreTableRow from './ScoreTableRow';

type ScoreTableBodyProps = {
  scores: ScoreInfo[];
  onRowClick?: ScoreRowClickHandler;
};

const ScoreTableBody = ({scores = [], onRowClick}: ScoreTableBodyProps) => {
  return (
    <Table.Body>
      {scores.map(score => (
        <ScoreTableRow score={score} key={score.date} onRowClick={onRowClick} />
      ))}
    </Table.Body>
  );
};

export default ScoreTableBody;
