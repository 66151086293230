/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { CouponDetailsDiscountMethodCaseFromJSON, CouponDetailsDiscountMethodCaseToJSON, } from './CouponDetailsDiscountMethodCase';
import { PaymentProviderFromJSON, PaymentProviderToJSON, } from './PaymentProvider';
/**
 * Check if a given object implements the OrderConfig interface.
 */
export function instanceOfOrderConfig(value) {
    let isInstance = true;
    isInstance = isInstance && "availablePaymentProviders" in value;
    isInstance = isInstance && "isCouponApplicable" in value;
    isInstance = isInstance && "selectedPaymentProvider" in value;
    return isInstance;
}
export function OrderConfigFromJSON(json) {
    return OrderConfigFromJSONTyped(json, false);
}
export function OrderConfigFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'appliedCouponIds': !exists(json, 'appliedCouponIds') ? undefined : json['appliedCouponIds'],
        'availableCouponDiscountMethods': !exists(json, 'availableCouponDiscountMethods') ? undefined : (json['availableCouponDiscountMethods'].map(CouponDetailsDiscountMethodCaseFromJSON)),
        'availablePaymentProviders': (json['availablePaymentProviders'].map(PaymentProviderFromJSON)),
        'isCouponApplicable': json['isCouponApplicable'],
        'selectedPaymentProvider': PaymentProviderFromJSON(json['selectedPaymentProvider']),
        'storeProductId': !exists(json, 'storeProductId') ? undefined : json['storeProductId'],
    };
}
export function OrderConfigToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'appliedCouponIds': value.appliedCouponIds,
        'availableCouponDiscountMethods': value.availableCouponDiscountMethods === undefined ? undefined : (value.availableCouponDiscountMethods.map(CouponDetailsDiscountMethodCaseToJSON)),
        'availablePaymentProviders': (value.availablePaymentProviders.map(PaymentProviderToJSON)),
        'isCouponApplicable': value.isCouponApplicable,
        'selectedPaymentProvider': PaymentProviderToJSON(value.selectedPaymentProvider),
        'storeProductId': value.storeProductId,
    };
}
