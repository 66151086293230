// @FIXME: this is a workaround for nextjs's async script loading and polyfills
// it might be resolvable by next/script in Next 11
import '@app/polyfills';

import {Language} from '@santa-web/gen/open-api/service';
import {Country as GrpcCountry, Language as GrpcLanguage} from '@santa-web/gen/ssp/messages/inside';
import {COUNTRIES, Country as I18nCountry, Language as I18nLanguage, LANGUAGES} from '@app/i18n/types';

export const INSIDE_COUNTRY_MAP: Record<I18nCountry, GrpcCountry> = {
  KR: 'COUNTRY_KR',
  US: 'COUNTRY_US',
  JP: 'COUNTRY_JP',
  VN: 'COUNTRY_VN',
  TW: 'COUNTRY_TW',
  TH: 'COUNTRY_TH',
};
export const INVERTED_INSIDE_COUNTRY_MAP = Object.fromEntries(Object.entries(INSIDE_COUNTRY_MAP).map(x => x.reverse()));
export const INSIDE_COUNTRIES = Object.values(INSIDE_COUNTRY_MAP);

export const INSIDE_LANGUAGE_MAP: Record<I18nLanguage, Language> = {
  ko: 'KO',
  en: 'EN',
  ja: 'JA',
  vi: 'VI',
  zh: 'ZH',
  th: 'TH',
};
export const INVERTED_INSIDE_LANGUAGE_MAP = Object.fromEntries(
  Object.entries(INSIDE_LANGUAGE_MAP).map(x => x.reverse())
);
export const INSIDE_LANGUAGES = Object.values(INSIDE_LANGUAGE_MAP);

export const getLanguage = (i18nLang = ''): I18nLanguage => {
  return LANGUAGES.find(language => i18nLang.startsWith(language)) ?? 'en';
};

export const getCountry = (i18nLang: string): I18nCountry => {
  const language = getLanguage(i18nLang);
  return COUNTRIES[language];
};

export const getIsInsideLanguage = (language: string): language is Language => {
  return Object.values(Language).includes(language as any);
};

export const getInsideLanguage = (i18nLang: string): Language => {
  const language = getLanguage(i18nLang);
  return INSIDE_LANGUAGE_MAP[language];
};

export const getInsideCountry = (i18nLang: string) => {
  const country = getCountry(i18nLang);
  return INSIDE_COUNTRY_MAP[country];
};

// will be removed
export function convertGrpcInsideLanguageToIsoLanguage(language?: GrpcLanguage): I18nLanguage | undefined {
  switch (language) {
    case 'LANGUAGE_EN':
      return 'en';
    case 'LANGUAGE_JA':
      return 'ja';
    case 'LANGUAGE_KO':
      return 'ko';
    case 'LANGUAGE_TH':
      return 'th';
    case 'LANGUAGE_ZH':
      return 'zh';
    case 'LANGUAGE_VI':
      return 'vi';
    case 'UNSPECIFIED_LANGUAGE':
    case 'LANGUAGE_PT':
    case 'LANGUAGE_ES':
    default:
      return undefined;
  }
}

export function convertInsideLanguageToIsoLanguage(language: Language): I18nLanguage | undefined {
  return INVERTED_INSIDE_LANGUAGE_MAP[language];
}

export function convertInsideCountryToIsoCountry(country: GrpcCountry): I18nCountry | undefined {
  return INVERTED_INSIDE_COUNTRY_MAP[country];
}

export type LocaleType = `${I18nLanguage}_${I18nCountry}`;

export function getLocaleString(i18nLanguage: string): LocaleType {
  const language = getLanguage(i18nLanguage);
  const country = getCountry(i18nLanguage);
  return `${language}_${country}`;
}
