import {useL2EFlashDealDisplay} from '@app/features/marketing';
import useUser from '@app/hooks/user/useUser';
import {useIsNewUserFlashDealAvailable} from '.';

const ONE_DAY = 24 * 60 * 60 * 1000;

export const useIsL2EFlashDealAvailable = () => {
  const isNewUserFlashDealAvailable = useIsNewUserFlashDealAvailable();
  const {getCount, getNextDisplayTime, setNextDisplayTime} = useL2EFlashDealDisplay();
  const {data: user} = useUser();

  const checkMarketingPolicy = () => {
    if (!user?.registeredAt) return false;

    const afterRegisteredTimeMs = new Date().getTime() - user.registeredAt.getTime();

    const isFirst24hours = afterRegisteredTimeMs < ONE_DAY; // 최초 24시간 이내임 == (현재시간 - 가입타임스탬프 < 24시간)

    if (isFirst24hours) {
      const isLessThanTwo = getCount() < 2;
      // 다음 표시 기준시간 설정. 가입 24시간 이후(2일차)
      setNextDisplayTime(user.registeredAt.getTime() + ONE_DAY);
      return isLessThanTwo;
    }

    const isReadyToDisplay = getNextDisplayTime() < new Date().getTime();

    if (isReadyToDisplay) {
      // 2일차 플래시딜 표시 후 다음 표시 기준시간 설정. 가입 48시간 이후(3일차)
      setNextDisplayTime(user.registeredAt.getTime() + 2 * ONE_DAY);
    }

    return isReadyToDisplay;
  };

  return () => isNewUserFlashDealAvailable && checkMarketingPolicy();
};
