import {FieldValidator} from 'final-form';
import pReduce from 'p-reduce';

export const composeValidators =
  <T>(...validators: FieldValidator<T>[]): FieldValidator<T> =>
  async (value, allValues, meta) => {
    return await pReduce(
      validators,
      async (acc, validator) => {
        return (await acc) ?? (await validator(value, allValues, meta));
      },
      undefined
    );
  };
