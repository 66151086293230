import produce from 'immer';
import {atom} from 'jotai';

import {cisService} from '@app/test-preparation/atoms/services';

const increaseElapsedTimeAtom = atom(null, (get, set) => {
  const elapsedTimeMap = get(cisService.elapsedTimeMapAtom);
  const cis = get(cisService.cisAtom);

  const nextElapsedTimeMap = produce(elapsedTimeMap, draft => {
    draft[cis.id] = (draft[cis.id] ?? 0) + 1000;
  });

  set(cisService.elapsedTimeMapAtom, nextElapsedTimeMap);
});

export {increaseElapsedTimeAtom};
