import React, {useCallback, useRef} from 'react';
import {Field, Form} from 'react-final-form';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {COLOR_SANTA_I} from '@riiid/design-system';
import {ValidationErrors} from 'final-form';
import {useAtomValue} from 'jotai';

import {Button, NewTextField as TextField, Typography} from '@santa-web/service-ui';
import santaAuthorizedOpenapiServicesAtom from '@app/atoms/core/santa-authorized-openapi-services';
import {SingleColumn} from '@app/components';
import {OnboardingLayout} from '@app/features/onboarding';

interface Props {
  token: string;
  onResetComplete?(): void;
}

interface FormValues {
  password?: string;
  password2?: string;
}

const ResetPasswordFormContainer = ({token, onResetComplete}: Props): JSX.Element => {
  const {t} = useTranslation();
  const {AuthService} = useAtomValue(santaAuthorizedOpenapiServicesAtom);

  const handleSubmit = useCallback(
    async (values: FormValues) => {
      await AuthService.resetPassword({
        resetPasswordRequest: {
          token,
          password: values.password ?? '',
        },
      });
      onResetComplete?.();
    },
    [AuthService, token, onResetComplete]
  );

  const validatePassword = useCallback(
    (password: string): string | undefined => {
      const isIncludingSpace = / /.test(password);
      if (isIncludingSpace) {
        return t('reset_password_validation_password_space');
      }

      if (password.length < 8) {
        return t('reset_password_validation_password_length');
      }

      const isIncludingAlphabet = /[a-z]/i.test(password);
      const isIncludingNumber = /\d/.test(password);
      const isIncludingSpecialCharacters = /[!@#$%^&*()\-_=+,./<>?;':"[\]{}\\|~₩]/.test(password);
      if ([isIncludingAlphabet, isIncludingNumber, isIncludingSpecialCharacters].filter(x => x).length < 2) {
        return t('reset_password_validation_password_difficulty');
      }
    },
    [t]
  );

  const validatePassword2 = useCallback(
    (password: string, password2: string): string | undefined => {
      if (validatePassword(password) != null) {
        return;
      }

      if (password !== password2) {
        return t('reset_password_validation_password2_different');
      }
    },
    [t, validatePassword]
  );

  const validate = useCallback(
    (values: FormValues): ValidationErrors => {
      const errors: ValidationErrors = {};
      errors.password = validatePassword(values.password ?? '');
      errors.password2 = validatePassword2(values.password ?? '', values.password2 ?? '');
      return errors;
    },
    [validatePassword, validatePassword2]
  );

  const validateRef = useRef<() => void>();

  const debouncedValidate = useCallback(
    (values: FormValues): ValidationErrors => {
      return new Promise(resolve => {
        validateRef.current?.();
        const intervalId = window.setTimeout(() => {
          const errors = validate(values);
          resolve(errors);
        }, 500);
        validateRef.current = () => {
          window.clearInterval(intervalId);
          resolve({});
        };
      });
    },
    [validate]
  );

  return (
    <Form<FormValues> onSubmit={handleSubmit} validate={debouncedValidate}>
      {({handleSubmit, invalid, pristine, submitting, validating}) => (
        <form
          css={css`
            display: flex;
            flex-direction: column;

            height: 100%;
          `}
          onSubmit={handleSubmit}>
          <OnboardingLayout
            isNarrow
            bottomArea={
              <Button
                isFullWidth
                variant="bottom-fix"
                colorVariant="brand"
                isDisabled={invalid || pristine || submitting || validating}>
                {t('page_reset_password_form_submit_button')}
              </Button>
            }>
            <SingleColumn gap={40}>
              <Typography color={COLOR_SANTA_I} fontWeight="bold" variant="caption1">
                {t('page_reset_password_form_sub_title')}
              </Typography>
              <Field<string> name="password">
                {({input, meta}) => (
                  <TextField
                    error={meta.dirty ? meta.error : undefined}
                    label={t('page_reset_password_form_new_password_label')}
                    hint={t('page_reset_password_form_new_password_hint')}
                    {...input}
                    type="password"
                  />
                )}
              </Field>
              <Field<string> name="password2">
                {({input, meta}) => (
                  <TextField
                    error={meta.dirty ? meta.error : undefined}
                    label={t('page_reset_password_form_new_password_confirm_label')}
                    hint={t('page_reset_password_form_new_password_confirm_hint')}
                    {...input}
                    type="password"
                  />
                )}
              </Field>
            </SingleColumn>
          </OnboardingLayout>
        </form>
      )}
    </Form>
  );
};

export default React.memo(ResetPasswordFormContainer);
