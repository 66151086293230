import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {COLOR_RIIID_SECONDARY_GC, COLOR_SANTA_A, COLOR_SANTA_C, COLOR_SANTA_L} from '@riiid/design-system';
import ChevronMiniRightIcon from '@riiid/design-system/icons/ChevronMiniRight';
import TimeIcon from '@riiid/design-system/icons/Time';
import {ButtonBase, Typography} from '@santa-web/service-ui';

type DailyGoalSettingButtonProps = {
  isDisabled?: boolean;
  dailyGoal?: string;
  onClick: () => void;
};

const DailyGoalSettingButton = ({isDisabled = false, dailyGoal, onClick}: DailyGoalSettingButtonProps) => {
  const {t} = useTranslation();
  return (
    <ButtonBase
      onClick={onClick}
      isDisabled={isDisabled}
      css={css`
        width: 100%;
        padding: 4px 8px;
        border-radius: 4px;
        background-color: ${COLOR_SANTA_A};
        cursor: ${dailyGoal && 'default'};
        @media (hover: hover) {
          :hover {
            background-color: ${!isDisabled && COLOR_SANTA_C};
          }
        }
        :active {
          background-color: ${!isDisabled && COLOR_SANTA_C};
        }
      `}>
      <div
        css={css`
          width: 100%;
          opacity: ${isDisabled ? 0.2 : 1};
          display: flex;
          align-items: center;
          justify-content: space-between;
        `}>
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 12px;
          `}>
          <TimeIcon type="solid" color={COLOR_RIIID_SECONDARY_GC} width={24} height={24} />
          <Typography variant="body3" color={COLOR_SANTA_L}>
            {t('page_set_study_goal_set_description')}
          </Typography>
        </div>
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 4px;
          `}>
          <Typography variant="body3" fontWeight={dailyGoal ? 'bold' : 'regular'} color={COLOR_SANTA_L}>
            {dailyGoal ? dailyGoal : t('page_set_study_goal_set_action_button')}
          </Typography>
          <ChevronMiniRightIcon color={COLOR_SANTA_L} type="solid" width={24} height={24} />
        </div>
      </div>
    </ButtonBase>
  );
};

export default DailyGoalSettingButton;
export type {DailyGoalSettingButtonProps};
DailyGoalSettingButton.displayName = 'DailyGoalSettingButton';
