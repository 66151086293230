import React from 'react';
import {Field, FieldRenderProps, FieldProps} from 'react-final-form';

import ButtonTextWithCheckbox from '@app/components/ButtonTextWithCheckbox';

type FieldValue = boolean | undefined;

export interface CheckboxFieldProps
  extends Pick<FieldProps<FieldValue, FieldRenderProps<FieldValue>>, 'name' | 'validate' | 'validateFields'> {
  children: React.ReactNode;
}

type RenderProps = Pick<FieldRenderProps<FieldValue>, 'input' | 'children'>;

function Render({input, children}: RenderProps) {
  const handleClick: React.MouseEventHandler<HTMLDivElement> = React.useCallback(
    e => {
      e.preventDefault();
      input.onChange(!input.value);
    },
    [input]
  );
  return (
    <ButtonTextWithCheckbox {...input} checked={input.value} onClick={handleClick}>
      {children}
    </ButtonTextWithCheckbox>
  );
}

function CheckboxField({name, children, validate, validateFields = []}: CheckboxFieldProps) {
  return (
    <Field<boolean | undefined> name={name} validate={validate} validateFields={validateFields}>
      {({input}) => {
        return <Render input={input}>{children}</Render>;
      }}
    </Field>
  );
}

export default React.memo(CheckboxField);
