import {useRouter} from 'next/router';
import React from 'react';
import {useTranslation} from 'react-i18next';
import produce from 'immer';
import {useAtomValue} from 'jotai';

import {SignupWithEmailPasswordBadRequestErrorCase} from '@santa-web/gen/open-api/service';
import {mobileService} from '@app/api/app-bridge/mobile-service';
import useSignupEvents from '@app/api/google-tag-manager/sign-up';
import santaAuthorizedOpenapiServicesAtom from '@app/atoms/core/santa-authorized-openapi-services';
import tokenManagerAtom from '@app/atoms/core/token-manager';
import TextField from '@app/components/final-form/TextField';
import {BaseSignupForm} from '@app/features/auth/components';
import {usePasswordValidator} from '@app/features/auth/hooks';
import {SignupFormValues} from '@app/features/auth/types';
import {toSignupCommonProps} from '@app/features/auth/utils';
import useUser from '@app/hooks/user/useUser';
import {
  isB2bState,
  isRedirectionState,
  redirect,
  useGoToB2bOfferGroupOrderPage,
  useQueryState,
} from '@app/misc/query-state';
import {OnSubmit} from '@app/types/form';
import {isAppBridgeAvailable} from '@app/utils/app-bridge';
import {SantaResponseError} from '@app/utils/error';

const defaultInitialValues: Partial<SignupFormValues> = {
  name: '',
  email: '',
  password: '',
  passwordConfirmation: '',
  agreeServiceTerms: false,
  agreePrivacy: false,
  agreeAge14OrOlder: false,
  agreeMarketing: false,
};

const EmailSignupPageContainer = () => {
  const passwordValidator = usePasswordValidator();
  const router = useRouter();
  const {AuthService} = useAtomValue(santaAuthorizedOpenapiServicesAtom);
  const tokenManager = useAtomValue(tokenManagerAtom);
  const {t} = useTranslation();
  const queryState = useQueryState();
  const {data: user} = useUser();
  const {pushFinishSignupEvent} = useSignupEvents();
  const goToB2bOfferGroupOrderPage = useGoToB2bOfferGroupOrderPage();

  const initialValues = React.useMemo(() => {
    return produce(defaultInitialValues, draft => {
      if (user?.personalInfo?.name) draft.name = user.personalInfo.name;
      if (user?.personalInfo?.email) draft.email = user.personalInfo.email;
      return draft;
    });
  }, [user]);

  const handleSubmit: OnSubmit<SignupFormValues> = React.useCallback(
    async value => {
      try {
        const {userToken} = await AuthService.signupWithEmailPassword({
          signupWithEmailPasswordRequest: {
            ...toSignupCommonProps(value),
            emailPasswordRegistrationInfo: {
              email: value.email ?? '',
              password: value.password ?? '',
            },
          },
        });
        tokenManager.setToken(userToken);
      } catch (e) {
        if (e instanceof SantaResponseError) {
          if (e.santaErrorCode === SignupWithEmailPasswordBadRequestErrorCase.ALREADY_EXISTS) {
            return {email: t('sign_error_email_already_exist')};
          }
        }
      }

      pushFinishSignupEvent({provider: 'EMAIL'});
      if (isAppBridgeAvailable() && value.agreeMarketing) {
        await mobileService.requestPermission('PUSH');
      }

      if (isB2bState(queryState)) {
        await goToB2bOfferGroupOrderPage(queryState);
      } else if (isRedirectionState(queryState)) {
        await redirect(router, queryState);
      } else {
        await router.push({pathname: '/'});
      }
    },
    [pushFinishSignupEvent, queryState, AuthService, tokenManager, t, router]
  );

  const passwordFields = [
    <TextField
      key="password"
      type="password"
      name="password"
      validate={passwordValidator.signup}
      label={t('component_sign_form_password_label')}
      hint={t('component_signup_form_password_hint')}
    />,
    <TextField
      key="passwordConfirmation"
      type="password"
      name="passwordConfirmation"
      validate={passwordValidator.confirm}
      label={t('component_sign_form_password_confirm_label')}
      hint={t('component_sign_form_password_confirm_hint')}
    />,
  ];

  return (
    <BaseSignupForm
      initialValues={initialValues}
      onSubmit={handleSubmit}
      passwordFields={passwordFields}
      signUpType="email"
      onEmailLogin={() => router.push('/login/email', {query: queryState.toQuery()})}
    />
  );
};

export default EmailSignupPageContainer;
