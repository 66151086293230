/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
/**
 * Check if a given object implements the QuestionStateTrueFalse interface.
 */
export function instanceOfQuestionStateTrueFalse(value) {
    let isInstance = true;
    isInstance = isInstance && "correctAnswer" in value;
    return isInstance;
}
export function QuestionStateTrueFalseFromJSON(json) {
    return QuestionStateTrueFalseFromJSONTyped(json, false);
}
export function QuestionStateTrueFalseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'correctAnswer': json['correctAnswer'],
        'userAnswer': !exists(json, 'userAnswer') ? undefined : json['userAnswer'],
    };
}
export function QuestionStateTrueFalseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'correctAnswer': value.correctAnswer,
        'userAnswer': value.userAnswer,
    };
}
