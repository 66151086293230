import React from 'react';
import {css} from '@emotion/react';
import {COLOR_SANTA_J} from '@riiid/design-system';
import ChevronMiniRight from '@riiid/design-system/icons/ChevronMiniRight';

import CheckboxField, {CheckboxFieldProps} from '@app/components/final-form/CheckboxField';

export type CheckboxFieldItemProps = CheckboxFieldProps & {
  href?: string;
};

const CheckboxFieldItem = React.memo(({href, ...props}: CheckboxFieldItemProps) => {
  return (
    <div
      css={css`
        display: flex;
        justify-content: space-between;

        a {
          display: grid;
          place-items: center;
        }
      `}>
      <CheckboxField {...props} />
      {href && (
        <a href={href} target="_blank" rel="noreferrer">
          <ChevronMiniRight width={24} height={24} color={COLOR_SANTA_J} />
        </a>
      )}
    </div>
  );
});

export default CheckboxFieldItem;
