import React from 'react';

import {LottieAnimation, LottieAnimationProps} from '@santa-web/service-ui';

import loadingLottie from './loading-lottie.json';

export type LoadingGrayLogoAnimationProps = Omit<LottieAnimationProps, 'src'>;

const LoadingGrayLogoAnimation = ({...props}: LoadingGrayLogoAnimationProps): JSX.Element => {
  return <LottieAnimation src={loadingLottie} {...props} />;
};

export default React.memo(LoadingGrayLogoAnimation);
