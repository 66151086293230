import React from 'react';
import {useTranslation} from 'react-i18next';
import {TextButton} from '@santa-web/service-ui';
import {OFFER_GROUP_RELEASE_DATE, ProductOrderBaseInfo} from '@app/features/offer';

type ProductOrderNavigationInfoOwnProps = {
  onNavigationButtonClick: () => void;
};

type ProductOrderNavigationInfoProps = ProductOrderNavigationInfoOwnProps & React.HTMLAttributes<HTMLDivElement>;

const ProductOrderNavigationInfo = ({onNavigationButtonClick, ...props}: ProductOrderNavigationInfoProps) => {
  const {t} = useTranslation();
  return (
    <ProductOrderBaseInfo
      description={t('legacy_order_list_navigation_info_description', OFFER_GROUP_RELEASE_DATE)}
      button={
        <TextButton
          typographyVariant="caption1"
          fontWeight="regular"
          color="black"
          isChevronVisible
          onClick={onNavigationButtonClick}>
          {t('legacy_order_list_navigation_info_button')}
        </TextButton>
      }
      {...props}
    />
  );
};

export default ProductOrderNavigationInfo;
export type {ProductOrderNavigationInfoProps};
ProductOrderNavigationInfo.displayName = 'ProductOrderNavigationInfo';
