import React, {useCallback} from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';

import {Button, BottomSheetProps} from '@santa-web/service-ui';
import BottomSheetWithDim, {BottomSheetWithDimProps} from '@app/components/BottomSheetWithDim';
import {ALERT_DIALOG_Z_INDEX} from '@app/constants/zIndex';
import {useDialogContext} from '@app/contexts/DialogContext';

export interface VirtualExamSubmitBottomSheetWithDimProps extends Omit<BottomSheetWithDimProps, 'BottomSheetProps'> {
  goToAllPartsList?(): void | Promise<void>;
  onSubmitAnswers?(): void | Promise<void>;
  isCompleted?: boolean;
  remainingQuestionCount?: number;
}

const VirtualExamSubmitBottomSheetWithDim = React.forwardRef<HTMLDivElement, VirtualExamSubmitBottomSheetWithDimProps>(
  ({isCompleted, onSubmitAnswers, goToAllPartsList, remainingQuestionCount, ...props}, ref) => {
    const {t} = useTranslation();
    const {closeDialog} = useDialogContext();

    const handleGoAllPartsClick = useCallback(async () => {
      await goToAllPartsList?.();
      closeDialog();
    }, [goToAllPartsList, closeDialog]);

    const bottomSheetProps = React.useMemo(
      (): BottomSheetProps => ({
        isLoadingAiAnimationVisible: true,
        title: t('bottom_sheet_virtual_exam_title'),
        description: isCompleted
          ? t('bottom_sheet_virtual_exam_description_completed')
          : t('bottom_sheet_virtual_exam_description_not_completed', {length: remainingQuestionCount}),
        buttons: [
          ...(function* () {
            if (!isCompleted) {
              yield (
                <Button colorVariant="brand" isFullWidth onClick={handleGoAllPartsClick} key="goToAllParts">
                  {t('bottom_sheet_virtual_exam_button_go_to_all_parts')}
                </Button>
              );
            }
            yield (
              <Button
                colorVariant={isCompleted ? 'brand' : 'black'}
                variant={isCompleted ? 'solid' : 'box-line'}
                isFullWidth
                onClick={onSubmitAnswers}
                key="submitAnswers">
                {t('bottom_sheet_virtual_exam_button_submit_answers')}
              </Button>
            );
          })(),
        ],
        onCloseClick: closeDialog,
      }),
      [t, isCompleted, goToAllPartsList, remainingQuestionCount, onSubmitAnswers, closeDialog, handleGoAllPartsClick]
    );
    return (
      <BottomSheetWithDim
        css={css`
          z-index: ${ALERT_DIALOG_Z_INDEX};
        `}
        isOpened
        BottomSheetProps={bottomSheetProps}
        ref={ref}
        {...props}
      />
    );
  }
);

export default React.memo(VirtualExamSubmitBottomSheetWithDim);
