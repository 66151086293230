/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { ListTicketGroupResponseFromJSON, OneOfTicketResourcesFromJSON, } from '../models';
/**
 *
 */
export class PermitUserV2TicketApi extends runtime.BaseAPI {
    /**
     * ID로 지정한 티켓의 자원을 조회한다. 만약 권한 차감을 진행한 적이 없으면 권한 차감도 함께 진행한다.
     * 티켓 자원 조회
     */
    async getTicketResourceRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getTicketResource.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/permit/v2/ticket/{id}/resource`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => OneOfTicketResourcesFromJSON(jsonValue));
    }
    /**
     * ID로 지정한 티켓의 자원을 조회한다. 만약 권한 차감을 진행한 적이 없으면 권한 차감도 함께 진행한다.
     * 티켓 자원 조회
     */
    async getTicketResource(requestParameters, initOverrides) {
        const response = await this.getTicketResourceRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 자신에게 지급된 티켓 그룹 목록을 조회한다.
     * 내 티켓 그룹 목록 조회
     */
    async listMyTicketGroupsRaw(requestParameters, initOverrides) {
        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size', 'Required parameter requestParameters.size was null or undefined when calling listMyTicketGroups.');
        }
        const queryParameters = {};
        if (requestParameters.cursor !== undefined) {
            queryParameters['cursor'] = requestParameters.cursor;
        }
        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/api/permit/v2/ticket/me`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListTicketGroupResponseFromJSON(jsonValue));
    }
    /**
     * 자신에게 지급된 티켓 그룹 목록을 조회한다.
     * 내 티켓 그룹 목록 조회
     */
    async listMyTicketGroups(requestParameters, initOverrides) {
        const response = await this.listMyTicketGroupsRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
