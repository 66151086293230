import TestPrepLayout, {TestPrepLayoutProps} from '@app/test-preparation/components/TestPrepLayout';
import {TOEIC_PART_6_TO_7_VIEW_MAX_WIDTH} from '@app/test-preparation/constants/test-preparation-layout';

export type ToeicPart6and7ViewLayoutProps = TestPrepLayoutProps;

const ToeicPart6and7ViewLayout = ({children, ...rest}: ToeicPart6and7ViewLayoutProps) => {
  return (
    <TestPrepLayout maxWidth={TOEIC_PART_6_TO_7_VIEW_MAX_WIDTH} {...rest}>
      {children}
    </TestPrepLayout>
  );
};

export default ToeicPart6and7ViewLayout;
