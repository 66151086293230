/**
 * Check if a given object implements the TicketSettings interface.
 */
export function instanceOfTicketSettings(value) {
    let isInstance = true;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "price" in value;
    isInstance = isInstance && "quantity" in value;
    return isInstance;
}
export function TicketSettingsFromJSON(json) {
    return TicketSettingsFromJSONTyped(json, false);
}
export function TicketSettingsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'description': json['description'],
        'id': json['id'],
        'name': json['name'],
        'price': json['price'],
        'quantity': json['quantity'],
    };
}
export function TicketSettingsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'description': value.description,
        'id': value.id,
        'name': value.name,
        'price': value.price,
        'quantity': value.quantity,
    };
}
