import {PriceFormatter} from './PriceFormatter';

export class ThPriceFormatter extends PriceFormatter {
  getCurrencyText(): string {
    return 'บาท';
  }
  getLocale(): string {
    return 'th-TH';
  }
  getCutUnit(): number {
    return 1;
  }
  getMonthlyCeilUnit(): number {
    return 1;
  }
}
