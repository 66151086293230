/**
 * Check if a given object implements the PointRewardRankUpBenefit interface.
 */
export function instanceOfPointRewardRankUpBenefit(value) {
    let isInstance = true;
    isInstance = isInstance && "pointDifference" in value;
    isInstance = isInstance && "targetRank" in value;
    isInstance = isInstance && "targetReward" in value;
    return isInstance;
}
export function PointRewardRankUpBenefitFromJSON(json) {
    return PointRewardRankUpBenefitFromJSONTyped(json, false);
}
export function PointRewardRankUpBenefitFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'pointDifference': json['pointDifference'],
        'targetRank': json['targetRank'],
        'targetReward': json['targetReward'],
    };
}
export function PointRewardRankUpBenefitToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'pointDifference': value.pointDifference,
        'targetRank': value.targetRank,
        'targetReward': value.targetReward,
    };
}
