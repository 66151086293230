import React from 'react';

import {useToastContextValue, toastContext} from '@santa-web/service-ui';

export type ToastProviderProps = {
  children?: React.ReactNode;
};

const ToastProvider = ({children}: ToastProviderProps) => {
  const toastContextValue = useToastContextValue();

  return <toastContext.Provider value={toastContextValue}>{children}</toastContext.Provider>;
};

export default ToastProvider;
