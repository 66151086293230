/* tslint:disable */
/* eslint-disable */
/**
 *
 * @export
 */
export const SubscriptionRenewalTermUnit = {
    DAY: 'DAY',
    WEEK: 'WEEK',
    MONTH: 'MONTH',
    YEAR: 'YEAR'
};
export function SubscriptionRenewalTermUnitFromJSON(json) {
    return SubscriptionRenewalTermUnitFromJSONTyped(json, false);
}
export function SubscriptionRenewalTermUnitFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function SubscriptionRenewalTermUnitToJSON(value) {
    return value;
}
