import {useAtomValue} from 'jotai';
import {ContentResource} from '@santa-web/gen/open-api/content-learning-service';
import {learningStore} from '@app/test-preparation/atoms/stores';

export function useResource(resourceId: string | null | undefined): ContentResource | null | undefined {
  return useResources([resourceId])[0];
}

export function useResources(resourceIds: (string | null | undefined)[]): (ContentResource | null | undefined)[] {
  const resourceMap = useAtomValue(learningStore.content.resourceMapAtom);

  return resourceIds.map(resourceId => (resourceId == null ? resourceId : resourceMap[resourceId]));
}
