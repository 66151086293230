import {addWeeks, getDay, subDays, startOfMonth, isBefore, isAfter, startOfDay} from 'date-fns';
import {WheelDate} from '.';

/**
 * 주어진 날이 해당 달의 몇번째 주에 속하는지 리턴 (max: 6)
 */
export const getWeek = (date: Date) => {
  const nthDateOfMonth = date.getDate(); // 해당 달에서 몇번째 날 인지 (1~31)
  const startDayOfMonth = getDay(startOfMonth(date)); // 해당 달의 첫번째 날이 무슨 요일인지 (0~6, 일요일 = 0)
  const nthWeekOfMonth = Math.ceil((nthDateOfMonth + startDayOfMonth) / 7);
  return nthWeekOfMonth;
};

/**
 * 주어진 날짜를 정규화하여 리턴
 * - month: 1~12
 * - week: 5~6
 * - day: 1~31
 */
export const getNormalizedDate = (date: Date) => {
  const year = date.getFullYear();
  const month = date.getMonth() + 1;
  const week = getWeek(date);
  const day = date.getDate();
  return {year, month, week, day};
};

export const getDateStringFormat = (date: Pick<WheelDate, 'year' | 'month' | 'day'>) => {
  const year = date.year.toString().padStart(4, '0');
  const month = date.month.toString().padStart(2, '0');
  const day = date.day.toString().padStart(2, '0');
  return `${year}-${month}-${day}`;
};

export const getDenormalizedDate = (date: WheelDate) => {
  // padStart 안해주면, 브라우저마다 다른 결과가 나올 수 있음
  // https://www.notion.so/riiid/new-Date-1205bc5f630780d6ab70f1b98afdfb98?pvs=4
  return new Date(getDateStringFormat(date));
};

/**
 * day를 제외한 year, month, week으로 날짜를 구할 때 사용.
 * 해당 week의 첫번째 날 (일요일)을 구함.
 * week 정보를 포함해야하므로 WheelDate를 인자로 받음.
 */
export const getFirstDayInWeek = (wheelDate: WheelDate): WheelDate => {
  const year = wheelDate.year.toString().padStart(4, '0');
  const month = wheelDate.month.toString().padStart(2, '0');
  const randomDayOfWeek = addWeeks(startOfMonth(new Date(`${year}-${month}-01`)), wheelDate.week - 1);
  const offsetToFirstDayInWeek = randomDayOfWeek.getDate() === 1 ? 0 : getDay(randomDayOfWeek); // 0:일요일, 1:월요일,,,
  const firstDayInWeek = subDays(randomDayOfWeek, offsetToFirstDayInWeek);
  return {
    year: wheelDate.year,
    month: wheelDate.month,
    week: wheelDate.week,
    day: firstDayInWeek.getDate(),
  };
};

/**
 * date-fns 에서는 ~InCalendar의 의미가 time (h, m, s)을 제거한것임.
 * 즉 순수한 날짜만 비교하는 함수임.
 */
export const isBeforeDayInCalendar = (date1: Date, date2: Date) => {
  return isBefore(startOfDay(date1), startOfDay(date2));
};

export const isAfterDayInCalendar = (date1: Date, date2: Date) => {
  return isAfter(startOfDay(date1), startOfDay(date2));
};
