/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { CouponDetailsCaseFromJSON, CouponDetailsCaseToJSON, } from './CouponDetailsCase';
import { CouponDetailsDiscountFromJSON, CouponDetailsDiscountToJSON, } from './CouponDetailsDiscount';
import { CouponOneOfDetailsPermitFromJSON, CouponOneOfDetailsPermitToJSON, } from './CouponOneOfDetailsPermit';
/**
 * Check if a given object implements the CouponDetails interface.
 */
export function instanceOfCouponDetails(value) {
    let isInstance = true;
    isInstance = isInstance && "oneOfCase" in value;
    return isInstance;
}
export function CouponDetailsFromJSON(json) {
    return CouponDetailsFromJSONTyped(json, false);
}
export function CouponDetailsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'discount': !exists(json, 'discount') ? undefined : CouponDetailsDiscountFromJSON(json['discount']),
        'oneOfCase': CouponDetailsCaseFromJSON(json['oneOfCase']),
        'permit': !exists(json, 'permit') ? undefined : CouponOneOfDetailsPermitFromJSON(json['permit']),
    };
}
export function CouponDetailsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'discount': CouponDetailsDiscountToJSON(value.discount),
        'oneOfCase': CouponDetailsCaseToJSON(value.oneOfCase),
        'permit': CouponOneOfDetailsPermitToJSON(value.permit),
    };
}
