/**
 * Check if a given object implements the LearningHistoryFilterItemBySelfLearningMockExam interface.
 */
export function instanceOfLearningHistoryFilterItemBySelfLearningMockExam(value) {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "isChecked" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "tagName" in value;
    return isInstance;
}
export function LearningHistoryFilterItemBySelfLearningMockExamFromJSON(json) {
    return LearningHistoryFilterItemBySelfLearningMockExamFromJSONTyped(json, false);
}
export function LearningHistoryFilterItemBySelfLearningMockExamFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'isChecked': json['isChecked'],
        'name': json['name'],
        'tagName': json['tagName'],
    };
}
export function LearningHistoryFilterItemBySelfLearningMockExamToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'isChecked': value.isChecked,
        'name': value.name,
        'tagName': value.tagName,
    };
}
