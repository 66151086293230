import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {useAudio} from 'react-use';
import {css} from '@emotion/react';

import {Button, ButtonProps, Dim, DimProps, SystemErrorLayout, SystemErrorLayoutProps} from '@santa-web/service-ui';
import {browserService} from '@app/api/app-bridge/browser-service';

export interface SoundTestDialogProps extends DimProps {
  onStartClick?(): void;
  SystemErrorLayoutProps?: SystemErrorLayoutProps;
  ButtonProps?: ButtonProps;
}

export default React.memo(
  React.forwardRef<HTMLDivElement, SoundTestDialogProps>(function SoundTestDialog(props, ref) {
    const {onStartClick, SystemErrorLayoutProps, ButtonProps, ...restProps} = props;
    const {t} = useTranslation();

    const [audio, state, controls] = useAudio({
      src: t('sound_test_dialog_audio_src'),
      loop: true,
    });

    React.useEffect(() => {
      const unsubscribe = browserService.subscribeDeviceStateChange(async state => {
        if (state === 'BACKGROUND') {
          controls.pause();
        } else {
          controls.play()?.catch(e => {
            if (e.message.includes('The play() request was interrupted')) {
              controls.pause();
              return;
            }
            throw e;
          });
        }
      });
      return unsubscribe;
    }, [controls]);

    const handlePlayButtonClick = controls.play;
    const handleStartButtonClick = onStartClick;

    const systemErrorLayout = useMemo(() => {
      const title = state.paused ? t('sound_test_dialog_paused_title') : t('sound_test_dialog_playing_title');
      const description = state.paused
        ? t('sound_test_dialog_paused_description')
        : t('sound_test_dialog_playing_description');

      const button = state.paused ? (
        <Button key="paused" colorVariant="grey-fix" variant="solid" onClick={handlePlayButtonClick} {...ButtonProps}>
          {t('sound_test_dialog_start_sound_test')}
        </Button>
      ) : (
        <Button key="playing" colorVariant="grey-fix" variant="solid" onClick={handleStartButtonClick} {...ButtonProps}>
          {t('sound_test_dialog_start_solving_questions')}
        </Button>
      );

      const LottieAnimationProps = {
        isStopped: state.paused,
        autoplay: false,
        width: 40,
        height: 40,
      };

      return (
        <SystemErrorLayout
          css={css`
            white-space: pre-line;
          `}
          LottieAnimationProps={LottieAnimationProps}
          icon="equalizer-with-circle"
          title={title}
          description={description}
          button={button}
          color="white-fix"
          {...SystemErrorLayoutProps}
        />
      );
    }, [state.paused, t, handlePlayButtonClick, handleStartButtonClick, SystemErrorLayoutProps, ButtonProps]);

    return (
      <Dim
        css={css`
          position: fixed;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          display: flex;
          align-items: center;
          justify-content: center;
        `}
        ref={ref}
        {...restProps}>
        {systemErrorLayout}
        {audio}
      </Dim>
    );
  })
);
