import 'swiper/css';

import React from 'react';
import {css} from '@emotion/react';
import {COLOR_FIX_SANTA_WHITE_1} from '@riiid/design-system';
// NOTE: swiper 패키지의 모듈 형태로 인해 타입 추론이 typescript 4.7 이상에서만 정상 동작함. swiper 패키지를 바깥에서 직접 사용하지는 않을 듯해 이대로 사용하기로 결정하였음.
// see: https://www.typescriptlang.org/docs/handbook/release-notes/typescript-4-7.html#packagejson-exports-imports-and-self-referencing
import {Swiper as SwiperClass} from 'swiper';
import {Autoplay} from 'swiper/modules';
import {Swiper, SwiperSlide} from 'swiper/react';

import {Typography} from '@santa-web/service-ui';

export interface MarketingBannerItem {
  title: string;
  description: string;
  imgSrc?: string;
  styles: {
    backgroundColor: string;
    color: string;
  };
  onClick?: React.MouseEventHandler<HTMLDivElement>;
}

export interface MarketingBannerCardProps {
  delayMs: number;
  items: MarketingBannerItem[];
}

const MarketingBannerCard = React.forwardRef<HTMLDivElement, MarketingBannerCardProps>(
  ({items, delayMs, ...rest}, ref) => {
    const [currentBannerIndex, setCurrentBannerIndex] = React.useState(0);

    const handleSlideChange = React.useCallback((swiper: SwiperClass) => {
      setCurrentBannerIndex(swiper.realIndex);
    }, []);

    return (
      <div
        css={css`
          position: relative;
          width: 100%;
          height: 80px;
          border-radius: 4px;
          overflow: hidden;
          user-select: none;
          isolation: isolate;
        `}
        ref={ref}
        {...rest}>
        <Swiper
          slidesPerView={1}
          loop
          modules={[Autoplay]}
          autoplay={{delay: delayMs, disableOnInteraction: false}}
          onSlideChange={handleSlideChange}>
          {items.map(({title, description, styles: {color, backgroundColor}, imgSrc, onClick}, idx) => (
            <SwiperSlide key={idx}>
              <div
                css={css`
                  display: flex;
                  justify-content: flex-start;
                  align-items: flex-start;
                  flex-wrap: wrap;
                  padding: 16px 20px;
                  color: ${color};
                  background-color: ${backgroundColor};
                  box-sizing: border-box;
                  cursor: pointer;
                `}
                onClick={onClick}>
                <div
                  css={css`
                    display: flex;
                    flex-direction: column;
                    width: 220px;
                    margin-bottom: 120px;
                  `}>
                  <Typography
                    css={css`
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      overflow-x: hidden;
                    `}
                    variant="body3"
                    fontWeight="bold">
                    {title}
                  </Typography>
                  <Typography
                    css={css`
                      text-overflow: ellipsis;
                      white-space: nowrap;
                      overflow-x: hidden;
                    `}
                    variant="caption1">
                    {description}
                  </Typography>
                </div>
                <div
                  css={[
                    css`
                      margin-top: -6px;
                      width: 100%;
                      max-width: calc(100% - 220px);
                      min-width: 60px;
                      height: 60px;
                    `,
                    imgSrc &&
                      css`
                        background-image: url(${imgSrc});
                        background-size: 60px 60px;
                        background-repeat: no-repeat;
                        background-position: top center;
                      `,
                  ]}
                />
              </div>
            </SwiperSlide>
          ))}
        </Swiper>
        {items.length !== 1 && (
          <Typography
            css={css`
              position: absolute;
              right: 20px;
              bottom: 16px;
              z-index: 1;
            `}
            variant="caption2"
            color={COLOR_FIX_SANTA_WHITE_1}>
            {currentBannerIndex + 1} / {items.length}
          </Typography>
        )}
      </div>
    );
  }
);

export default React.memo(MarketingBannerCard);

MarketingBannerCard.displayName = 'MarketingBannerCard';
