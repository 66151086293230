import React, {MouseEventHandler} from 'react';
import {useTranslation} from 'react-i18next';

import {useAtomValue} from 'jotai';
import {SelectionChangeEvent, Highlight, useToastContext, rgbToRrtv2Rgba, HIGHLIGHT_IDS} from '@santa-web/service-ui';
import {browserService} from '@app/api/app-bridge/browser-service';
import useCreateHighlightInfo from '@app/hooks/highlight/useCreateHighlightInfo';
import useDeleteHighlightInfo from '@app/hooks/highlight/useDeleteHighlightInfo';
import useListHighlightInfos from '@app/hooks/highlight/useListHighlightInfos';
import useUpdateHighlightInfo from '@app/hooks/highlight/useUpdateHighlightInfo';
import {learningStore} from '@app/test-preparation/atoms/stores';
import {
  HIGHLIGHT_DEFAULT_COLOR,
  TOOL_BOX_HEIGHT,
  TOOL_BOX_SELECTION_GAP,
  TOOL_BOX_SIDE_HALF_GAP,
  TOOL_BOX_SIDE_GAP,
  TOOL_BOX_POSITION_TOP,
  TOOL_BOX_POSITION_BOTTOM,
  TOOL_BOX_POSITION_LEFT,
  TOOL_BOX_POSITION_RIGHT,
  TOOL_BOX_POSITION_CENTER,
  TOOL_BOX_VERTICAL_POSITIONS,
  TOOL_BOX_HORIZONTAL_POSITIONS,
  TOOL_BOX_ALL_POSITIONS,
} from '@app/test-preparation/constants/highlight';
import {useTestprepConfigContext} from '@app/test-preparation/contexts/TestprepConfigContext';
import {useIsHighlightable} from '@app/test-preparation/hooks/highlight';
import {HighlightColor, SelectedHighlightInfo, ClickedHighlightInfo} from '@app/test-preparation/types/highlight';
import {isAppBridgeAvailable} from '@app/utils/app-bridge';

const selectionChangeEventBySnippetViewId: Record<string, SelectionChangeEvent | null> = {};
const selectionChangeEventBySnippetViewIdBuffer: Record<string, SelectionChangeEvent[]> = {};

const pointerStartPosition = {x: 0, y: 0};
const pointerEndPosition = {x: 0, y: 0};

interface IHighlightToolBoxContext {
  toolBoxRef: React.RefObject<HTMLDivElement>;
  scrollLayoutRef: React.RefObject<HTMLDivElement>;
  highlights: Record<string, Highlight[]>;
  color: HighlightColor;
  setColor(color: HighlightColor): void;
  isColorPicking: boolean;
  setIsColorPicking(isColorPicking: boolean): void;
  isEditing: boolean;
  registerSnippetView(snippetViewId: string): () => void;
  onSelectionChange(snippetViewId: string, event: SelectionChangeEvent): void;
  onHighlightAdd(): void;
  onHighlightRemove(): void;
}

const HighlightToolBoxContext = React.createContext<IHighlightToolBoxContext | null>(null);

const useCreateHighlightToolBoxContext = (): IHighlightToolBoxContext => {
  const {t} = useTranslation();
  const {openToast} = useToastContext();
  const {switchable} = useTestprepConfigContext();

  const cisId = useAtomValue(learningStore.cis.idAtom);

  const {data: highlightInfos = []} = useListHighlightInfos(cisId);
  const {mutateAsync: createHighlightInfo} = useCreateHighlightInfo();
  const {mutateAsync: updateHighlightInfo} = useUpdateHighlightInfo();
  const {mutateAsync: deleteHighlightInfo} = useDeleteHighlightInfo();

  const toolBoxRef = React.useRef<HTMLDivElement | null>(null);
  const scrollLayoutRef = React.useRef<HTMLDivElement | null>(null);

  const [selectedHighlightInfo, setSelectedHighlightInfo] = React.useState<SelectedHighlightInfo | null>(null);
  const [clickedHighlightInfo, setClickedHighlightInfo] = React.useState<ClickedHighlightInfo | null>(null);
  const [color, _setColor] = React.useState<HighlightColor>(HIGHLIGHT_DEFAULT_COLOR);
  const [isColorPicking, _setIsColorPicking] = React.useState(false);

  const setToolBoxVisibility = React.useCallback((type: 'init' | 'visible' | 'none') => {
    if (!toolBoxRef.current) return;

    switch (type) {
      case 'init':
        toolBoxRef.current.style.display = 'initial';
        toolBoxRef.current.style.visibility = 'hidden';
        break;
      case 'visible':
        toolBoxRef.current.style.visibility = 'visible';
        break;
      case 'none':
        toolBoxRef.current.style.display = 'none';
        break;
    }
  }, []);

  const setIsColorPicking = React.useCallback(
    (isColorPicking: boolean) => {
      setToolBoxVisibility('init');
      _setIsColorPicking(isColorPicking);
    },
    [setToolBoxVisibility]
  );

  const highlights: Record<string, Highlight[]> = React.useMemo(() => {
    const currentSelectedHighlight = selectedHighlightInfo
      ? {
          [selectedHighlightInfo.snippetViewId]: [
            {
              ...selectedHighlightInfo.highlight,
              id: selectedHighlightInfo.highlight.id.toString(),
              type: 'bg-color',
            } as Highlight,
          ],
        }
      : {};

    const generateHighlightClickHandler = (): MouseEventHandler => {
      return event => {
        if (!(event.currentTarget instanceof HTMLElement)) {
          return;
        }

        const highlightIdsAttribute = event.currentTarget.getAttribute(HIGHLIGHT_IDS);
        const highlightIds = new Set(highlightIdsAttribute?.split(',').map(Number) ?? []);

        const targetHighlightInfos = highlightInfos.filter(highlightInfo =>
          highlightIds.has(highlightInfo.highlight.id)
        );

        // 여러 하이라이트가 겹칠경우 마지막 인덱스의 하이라이트를 대상으로 함
        const [targetHighlightInfo] = targetHighlightInfos.slice(-1);

        if (targetHighlightInfo === undefined) {
          return;
        }

        setClickedHighlightInfo({
          ...targetHighlightInfo,
          event,
        });
        _setColor(`rgb(${targetHighlightInfo.highlight.color.slice(0, 3).join(',')})` as HighlightColor);
        setIsColorPicking(false);
      };
    };

    return highlightInfos.reduce(
      (highlights, {snippetViewId, highlight}) => ({
        ...highlights,
        [snippetViewId]: [
          ...(highlights[snippetViewId] ?? []),
          {
            ...highlight,
            id: highlight.id.toString(),
            type: 'bg-color',
            onClick: generateHighlightClickHandler(),
          },
        ],
      }),
      currentSelectedHighlight
    );
  }, [highlightInfos, selectedHighlightInfo, setIsColorPicking]);

  const isWebview = isAppBridgeAvailable();
  const isEditing = React.useMemo(() => clickedHighlightInfo !== null, [clickedHighlightInfo]);

  const setToolBoxPosition = React.useCallback(
    (
      left: number,
      top: number,
      arrowDirecition: {
        vertical: (typeof TOOL_BOX_VERTICAL_POSITIONS)[number];
        horizontal: (typeof TOOL_BOX_HORIZONTAL_POSITIONS)[number];
      }
    ) => {
      if (!toolBoxRef.current) return;

      toolBoxRef.current.classList.remove(...TOOL_BOX_ALL_POSITIONS);
      toolBoxRef.current.classList.add(arrowDirecition.vertical, arrowDirecition.horizontal);
      toolBoxRef.current.style.transform = `translate3d(${left}px, ${top}px, 100px)`;
    },
    [toolBoxRef.current]
  );

  const setColor = React.useCallback(
    async (color: HighlightColor) => {
      if (selectedHighlightInfo) {
        setSelectedHighlightInfo({
          ...selectedHighlightInfo,
          highlight: {
            ...selectedHighlightInfo.highlight,
            color: rgbToRrtv2Rgba(color),
          },
        });

        _setColor(color);
        setIsColorPicking(false);
      }

      if (clickedHighlightInfo) {
        await updateHighlightInfo({
          cisId,
          clickedHighlightInfo,
          color,
        });
        setClickedHighlightInfo(null);
        setToolBoxVisibility('none');
      }
    },
    [cisId, clickedHighlightInfo, selectedHighlightInfo, setIsColorPicking, setToolBoxVisibility, updateHighlightInfo]
  );

  const calculateSelectedToolBox = React.useCallback(() => {
    if (!toolBoxRef.current || !scrollLayoutRef.current || !selectedHighlightInfo) return;

    const selectionRects = selectedHighlightInfo.rects;
    const isSelectionForward =
      new Set(Array.from(selectionRects).map(({y}) => y)).size === 1
        ? pointerEndPosition.x >= pointerStartPosition.x
        : pointerEndPosition.y >= pointerStartPosition.y;
    const lastSelectionRect = selectionRects[isSelectionForward ? selectionRects.length - 1 : 0];

    if (!lastSelectionRect) return;

    const lastSelectionSideDirection =
      lastSelectionRect[isSelectionForward ? TOOL_BOX_POSITION_RIGHT : TOOL_BOX_POSITION_LEFT];

    const isDirectionTop =
      lastSelectionRect.top - scrollLayoutRef.current.getBoundingClientRect().top >
      TOOL_BOX_HEIGHT + TOOL_BOX_SELECTION_GAP
        ? true
        : false;
    const isDirectionLeft = lastSelectionSideDirection - toolBoxRef.current.offsetWidth >= TOOL_BOX_SIDE_HALF_GAP;
    const isDirectionRight =
      lastSelectionSideDirection + toolBoxRef.current.offsetWidth <= window.innerWidth - TOOL_BOX_SIDE_HALF_GAP;

    const rectTop =
      lastSelectionRect.top - scrollLayoutRef.current.getBoundingClientRect().top + scrollLayoutRef.current.scrollTop;
    const top = isDirectionTop
      ? rectTop - TOOL_BOX_SELECTION_GAP - toolBoxRef.current.offsetHeight
      : rectTop + TOOL_BOX_SELECTION_GAP + lastSelectionRect.height;

    const toolBoxArrowVerticalDirection = isDirectionTop ? TOOL_BOX_POSITION_TOP : TOOL_BOX_POSITION_BOTTOM;

    if (isDirectionLeft) {
      setToolBoxPosition(
        lastSelectionSideDirection - toolBoxRef.current.offsetWidth + (isSelectionForward ? 0 : TOOL_BOX_SIDE_GAP),
        top,
        {vertical: toolBoxArrowVerticalDirection, horizontal: TOOL_BOX_POSITION_LEFT}
      );
    } else if (isDirectionRight) {
      setToolBoxPosition(lastSelectionSideDirection - (isSelectionForward ? TOOL_BOX_SIDE_GAP : 0), top, {
        vertical: toolBoxArrowVerticalDirection,
        horizontal: TOOL_BOX_POSITION_RIGHT,
      });
    } else {
      setToolBoxPosition(
        lastSelectionSideDirection -
          toolBoxRef.current.offsetWidth / 2 +
          (isSelectionForward ? -TOOL_BOX_SIDE_HALF_GAP : TOOL_BOX_SIDE_HALF_GAP),
        top,
        {vertical: toolBoxArrowVerticalDirection, horizontal: TOOL_BOX_POSITION_CENTER}
      );
    }

    setToolBoxVisibility('visible');
  }, [scrollLayoutRef.current, selectedHighlightInfo, setToolBoxPosition, setToolBoxVisibility, toolBoxRef.current]);

  const calculateClickToolBox = React.useCallback(() => {
    if (!toolBoxRef.current || !scrollLayoutRef.current || !clickedHighlightInfo) return;

    const {clientX, clientY, nativeEvent} = clickedHighlightInfo.event;
    const clickedRect = Array.from((nativeEvent.target as Element).getClientRects()).find(
      ({y, height}) => y + height > clientY
    );

    if (!clickedRect) return;

    const isDirectionTop =
      clickedRect.top - scrollLayoutRef.current.getBoundingClientRect().top > TOOL_BOX_HEIGHT + TOOL_BOX_SELECTION_GAP
        ? true
        : false;
    const isDirectionCenter =
      clientX - toolBoxRef.current.offsetWidth / 2 >= TOOL_BOX_SIDE_HALF_GAP &&
      clientX + toolBoxRef.current.offsetWidth / 2 <= window.innerWidth - TOOL_BOX_SIDE_HALF_GAP;
    const isDirectionLeft = clientX - toolBoxRef.current.offsetWidth + TOOL_BOX_SIDE_HALF_GAP >= TOOL_BOX_SIDE_HALF_GAP;

    const rectTop =
      clickedRect.top - scrollLayoutRef.current.getBoundingClientRect().top + scrollLayoutRef.current.scrollTop;
    const top = isDirectionTop
      ? rectTop - TOOL_BOX_SELECTION_GAP - toolBoxRef.current.offsetHeight
      : rectTop + TOOL_BOX_SELECTION_GAP + clickedRect.height;

    const toolBoxArrowVerticalDirection = isDirectionTop ? TOOL_BOX_POSITION_TOP : TOOL_BOX_POSITION_BOTTOM;

    if (isDirectionCenter) {
      setToolBoxPosition(clientX - toolBoxRef.current.offsetWidth / 2, top, {
        vertical: toolBoxArrowVerticalDirection,
        horizontal: TOOL_BOX_POSITION_CENTER,
      });
    } else if (isDirectionLeft) {
      setToolBoxPosition(clientX - toolBoxRef.current.offsetWidth + TOOL_BOX_SIDE_HALF_GAP, top, {
        vertical: toolBoxArrowVerticalDirection,
        horizontal: TOOL_BOX_POSITION_LEFT,
      });
    } else {
      setToolBoxPosition(clientX - TOOL_BOX_SIDE_HALF_GAP, top, {
        vertical: toolBoxArrowVerticalDirection,
        horizontal: TOOL_BOX_POSITION_RIGHT,
      });
    }

    setToolBoxVisibility('visible');
  }, [clickedHighlightInfo, setToolBoxPosition, setToolBoxVisibility]);

  const registerSnippetView = React.useCallback((snippetViewId: string) => {
    selectionChangeEventBySnippetViewId[snippetViewId] = null;
    selectionChangeEventBySnippetViewIdBuffer[snippetViewId] = [];

    return () => {
      delete selectionChangeEventBySnippetViewId[snippetViewId];
      delete selectionChangeEventBySnippetViewIdBuffer[snippetViewId];
    };
  }, []);

  const handleSelectionChange = React.useCallback((snippetViewId: string, event: SelectionChangeEvent) => {
    if (!selectionChangeEventBySnippetViewIdBuffer[snippetViewId]) return;

    selectionChangeEventBySnippetViewIdBuffer[snippetViewId].push(event);

    const selectionChangeEventBySnippetViewIdBufferEntries = Object.entries(selectionChangeEventBySnippetViewIdBuffer);

    const isCorrect = !selectionChangeEventBySnippetViewIdBufferEntries.some(([, events]) => events.length === 0);

    if (isCorrect) {
      selectionChangeEventBySnippetViewIdBufferEntries.forEach(([snippetViewId, events]) => {
        const event = events.shift();

        if (event) {
          selectionChangeEventBySnippetViewId[snippetViewId] = event;
        }
      });
    }
  }, []);

  const handleSelectionStart = React.useCallback(
    (event: PointerEvent) => {
      const {clientX, clientY} = event;

      pointerStartPosition.x = clientX;
      pointerStartPosition.y = clientY;

      Object.keys(selectionChangeEventBySnippetViewId).forEach(snippetViewId => {
        selectionChangeEventBySnippetViewId[snippetViewId] = null;
      });

      setToolBoxVisibility('init');
      setSelectedHighlightInfo(null);
      _setColor(HIGHLIGHT_DEFAULT_COLOR);
    },
    [setToolBoxVisibility]
  );

  const handleSelectionEnd = React.useCallback(
    (event: {clientX: number; clientY: number}) => {
      // 온전한 selection event란? anchor와 focus 둘 다 있는 녀석
      // 전부다 온전하지 않거나 (toolbox를 안보여줌)
      // 하나만 온전해야 한다 (이 하나를 가지고 적절한 위치에 toolbox를 그려줌)
      // 그 외의 경우는 존재하지 않아야함 존재한다면 잘못 짰을 가능성이 높음.
      const selectionChangeEventEntries = Object.entries(selectionChangeEventBySnippetViewId);

      const [snippetViewId, selectionChangeEvent] =
        selectionChangeEventEntries.find(
          ([, selectionChangeEvent]) =>
            selectionChangeEvent?.rrtv2Selection?.anchor && selectionChangeEvent?.rrtv2Selection?.focus
        ) ?? [];

      if (
        !toolBoxRef.current ||
        !scrollLayoutRef.current ||
        !snippetViewId ||
        !selectionChangeEvent?.rrtv2Selection ||
        !selectionChangeEvent?.selection
      )
        return;

      const {clientX, clientY} = event;

      pointerEndPosition.x = clientX;
      pointerEndPosition.y = clientY;

      const {selection, rrtv2Selection} = selectionChangeEvent;

      if (selection.rangeCount > 0 && !selection.isCollapsed && rrtv2Selection) {
        const text = selection.toString();
        const range = selection.getRangeAt(0);
        const rects = range.getClientRects();

        if (isWebview) {
          selection.empty();
        }

        setTimeout(() => {
          setIsColorPicking(false);
          setClickedHighlightInfo(null);
          setSelectedHighlightInfo({
            snippetViewId,
            highlight: {
              id: -1,
              anchor: rrtv2Selection.anchor!,
              focus: rrtv2Selection.focus!,
              color: rgbToRrtv2Rgba(color),
            },
            text,
            rects,
          });
        }, 10);
      } else {
        setToolBoxVisibility('none');
      }
    },
    [color, isWebview, setIsColorPicking, setToolBoxVisibility]
  );

  const handleHighlightAdd = React.useCallback(async () => {
    if (!selectedHighlightInfo) return;

    await createHighlightInfo({
      cisId,
      selectedHighlightInfo: selectedHighlightInfo,
      color,
    });

    setSelectedHighlightInfo(null);
    setToolBoxVisibility('none');
  }, [cisId, color, createHighlightInfo, selectedHighlightInfo, setToolBoxVisibility]);

  const handleHighlightRemove = React.useCallback(async () => {
    if (!clickedHighlightInfo) return;

    await deleteHighlightInfo({
      cisId,
      clickedHighlightInfo,
    });

    openToast({
      colorVariant: 'gray',
      message: t('highlight_delete_bottom_toast'),
    });

    setToolBoxVisibility('none');
  }, [cisId, clickedHighlightInfo, deleteHighlightInfo, openToast, setToolBoxVisibility, t]);

  React.useEffect(() => {
    const handleContextMenu = (event: MouseEvent) => {
      const isHighlightEnabled = switchable.getIsSwitchableActionEnabled('HIGHLIGHT') ?? false;
      if (isHighlightEnabled) {
        event.preventDefault();
      }
    };

    document.addEventListener('pointerdown', handleSelectionStart, false);
    document.addEventListener('contextmenu', handleContextMenu, false);

    if (isWebview) {
      const unsubscribePointerUp = browserService.subscribePointerUp(async (dx, dy) => {
        handleSelectionEnd({
          clientX: dx,
          clientY: dy,
        });
      });
      return () => {
        document.removeEventListener('pointerdown', handleSelectionStart);
        document.removeEventListener('contextmenu', handleContextMenu);
        unsubscribePointerUp();
      };
    } else {
      document.addEventListener('pointerup', handleSelectionEnd, false);
      return () => {
        document.removeEventListener('pointerdown', handleSelectionStart);
        document.removeEventListener('contextmenu', handleContextMenu);
        document.removeEventListener('pointerup', handleSelectionEnd);
      };
    }
  }, [handleSelectionEnd, handleSelectionStart, isWebview, switchable]);

  React.useEffect(() => {
    calculateSelectedToolBox();
    calculateClickToolBox();
  }, [isColorPicking, calculateClickToolBox, calculateSelectedToolBox]);

  React.useEffect(() => {
    document.getSelection()?.empty();
    calculateSelectedToolBox();
  }, [selectedHighlightInfo, calculateSelectedToolBox]);

  React.useEffect(() => {
    calculateClickToolBox();
  }, [clickedHighlightInfo, calculateClickToolBox]);

  return React.useMemo(
    () => ({
      toolBoxRef,
      scrollLayoutRef,
      highlights,
      color,
      setColor,
      isColorPicking,
      setIsColorPicking,
      isEditing,
      registerSnippetView,
      onSelectionChange: handleSelectionChange,
      onHighlightAdd: handleHighlightAdd,
      onHighlightRemove: handleHighlightRemove,
    }),
    [
      color,
      handleHighlightAdd,
      handleHighlightRemove,
      handleSelectionChange,
      highlights,
      isColorPicking,
      isEditing,
      registerSnippetView,
      setColor,
      setIsColorPicking,
    ]
  );
};

export const HighlightToolBoxContextProvider = ({children}: {children?: React.ReactNode}) => {
  const isHighlightable = useIsHighlightable();

  const _highlightToolBoxContext = useCreateHighlightToolBoxContext();
  const highlightToolBoxContext = isHighlightable ? _highlightToolBoxContext : null;

  return (
    <HighlightToolBoxContext.Provider value={highlightToolBoxContext}>{children}</HighlightToolBoxContext.Provider>
  );
};

export const useHighlightToolBoxContext = (): IHighlightToolBoxContext | null => {
  const highlightToolBoxContext = React.useContext(HighlightToolBoxContext);

  return highlightToolBoxContext;
};
