import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';

import {Button, BottomSheetProps} from '@santa-web/service-ui';
import BottomSheetWithDim, {BottomSheetWithDimProps} from '@app/components/BottomSheetWithDim';
import {ALERT_DIALOG_Z_INDEX} from '@app/constants/zIndex';
import {useDialogContext} from '@app/contexts/DialogContext';

export interface LearningAuthorityBottomSheetWithDimProps extends BottomSheetWithDimProps {
  onPositiveButtonClick?: React.MouseEventHandler<HTMLButtonElement>;
}

export default React.memo(
  React.forwardRef<HTMLDivElement, LearningAuthorityBottomSheetWithDimProps>(function (
    {onPositiveButtonClick, ...props},
    ref
  ) {
    const {t} = useTranslation();
    const {closeDialog: closeBottomSheet} = useDialogContext();

    const handleBottomSheetPositiveButtonClick: React.MouseEventHandler<HTMLButtonElement> = React.useCallback(
      async event => {
        closeBottomSheet();
        onPositiveButtonClick?.(event);
      },
      [closeBottomSheet, onPositiveButtonClick]
    );

    const BottomSheetProps = React.useMemo(
      (): BottomSheetProps => ({
        title: t('bottom_sheet_learning_authority_title'),
        description: t('bottom_sheet_learning_authority_description'),
        isLoadingAiAnimationVisible: true,
        buttons: [
          <Button
            key={1}
            onClick={handleBottomSheetPositiveButtonClick}
            variant="solid"
            colorVariant="brand"
            isFullWidth>
            {t('bottom_sheet_learning_authority_button_positive_label')}
          </Button>,
          <Button key={2} onClick={closeBottomSheet} variant="solid" colorVariant="grey" isFullWidth>
            {t('bottom_sheet_learning_authority_button_negative_label')}
          </Button>,
        ],
      }),
      [closeBottomSheet, handleBottomSheetPositiveButtonClick, t]
    );

    return (
      <BottomSheetWithDim
        css={css`
          z-index: ${ALERT_DIALOG_Z_INDEX};
        `}
        isOpened
        BottomSheetProps={BottomSheetProps}
        ref={ref}
        {...props}
      />
    );
  })
);
