import React, {ComponentPropsWithoutRef, CSSProperties, PropsWithChildren} from 'react';
import {css} from '@emotion/react';
import {COLOR_SANTA_A} from '@riiid/design-system';

type TopBottomGradientProps = PropsWithChildren<{
  blurHeight?: CSSProperties['height'];
  zIndex?: number;
  blurColor?: CSSProperties['backgroundColor'];
  blurColorEnd?: CSSProperties['backgroundColor'];
}> &
  ComponentPropsWithoutRef<'div'>;

const TopBottomGradient = ({
  children,
  blurHeight = 24,
  zIndex = 2,
  /**
   * alpha included color recommended
   */
  blurColor = COLOR_SANTA_A,
  ...divProps
}: TopBottomGradientProps) => {
  const height = typeof blurHeight === 'number' ? `${blurHeight}px` : blurHeight;
  return (
    <div
      css={css`
        position: relative;
      `}
      {...divProps}>
      <div
        css={css`
          position: absolute;
          height: ${height};
          top: 0px;
          width: 100%;
          background: linear-gradient(180deg, ${blurColor} 0%, transparent 100%);
          z-index: ${zIndex};
        `}
      />
      {children}
      <div
        css={css`
          position: absolute;
          height: ${height};
          bottom: 0px;
          width: 100%;
          background: linear-gradient(360deg, ${blurColor} 0%, transparent 100%);
          z-index: ${zIndex};
          opacity: 1;
        `}
      />
    </div>
  );
};

export default TopBottomGradient;
export type {TopBottomGradientProps};
TopBottomGradient.displayName = 'TopBottomGradient';
