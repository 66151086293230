import React from 'react';

import {LearningCell} from '@app/types/learning-cell';
import {Nullishable} from '@app/utils/type';

type LearningCellContext = LearningCell | null | undefined;

const LearningCellContext = React.createContext<Nullishable<LearningCell>>(undefined);

export default LearningCellContext;
