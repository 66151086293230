import {Locale} from '@santa-web/gen/open-api/content/models/Locale';
import {ContentTag, LanguageTag} from '@santa-web/gen/ssp/messages/inside';

// Toeic content tag list sheet
// https://docs.google.com/spreadsheets/d/1fH8qhXOJkJJlRLZwur3iiXIXcGww3sgHopd6FXrpxgg/edit#gid=201644423

export function getValuesFromTags(tags: ContentTag[] | null | undefined, key: string): string[] {
  const filteredTags = (tags ?? [])?.filter(tag => tag.key === key);
  const values = filteredTags.flatMap(({value}) => {
    if (value?.value?.field === 'stringList') return value.value.value.value;
    if (value?.value?.field === 'stringValue') return [value.value.value];
    return [];
  });
  return values;
}

export function getChapters(tags: ContentTag[] | null | undefined) {
  return getValuesFromTags(tags, 'chapter');
}

export function getParts(tags: ContentTag[] | null | undefined) {
  return getValuesFromTags(tags, 'part');
}

export function getSkills(tags: ContentTag[] | null | undefined) {
  return getValuesFromTags(tags, 'skill');
}

export function getDalcs(tags: ContentTag[] | null | undefined) {
  return getValuesFromTags(tags, 'dalc');
}

export function isLC(tags: ContentTag[] | null | undefined) {
  const subjects = getValuesFromTags(tags, 'subject');
  return subjects.some(subject => subject === 'lc');
}

export function getIsBasicQuestion(tags: ContentTag[] | null | undefined) {
  const dalcs = getDalcs(tags);
  const basicQuestionDalcs = ['sentence_completion', 'basic_training', 'basic_grammar', 'basic_voca'];
  return dalcs.some(dalc => basicQuestionDalcs.includes(dalc));
}

export function getLanguageTagFromLocale(locale: Locale): LanguageTag {
  switch (locale) {
    case 'KO_KR':
      return {
        country: 'COUNTRY_KR',
        language: 'LANGUAGE_KO',
      };
    case 'JA_JP':
      return {
        country: 'COUNTRY_JP',
        language: 'LANGUAGE_JA',
      };
    case 'EN_US':
      return {
        country: 'COUNTRY_US',
        language: 'LANGUAGE_EN',
      };
    case 'VI_VN':
      return {
        country: 'COUNTRY_VN',
        language: 'LANGUAGE_VI',
      };
    case 'ZH_TW':
      return {
        country: 'COUNTRY_TW',
        language: 'LANGUAGE_ZH',
      };
    case 'TH_TH':
      return {
        country: 'COUNTRY_TH',
        language: 'LANGUAGE_TH',
      };
  }
}
