import React from 'react';

import {useAtomValue} from 'jotai';
import {learningStore} from '@app/test-preparation/atoms/stores';
import {useIsPreview} from '@app/test-preparation/hooks';
import {hasIntersection} from '@app/utils/array';

const CHAT_AVAILABLE_TOEIC_PARTS = ['p2', 'p3', 'p4', 'p5', 'p6', 'p7'];
const CHAT_UNAVAILABLE_TOEIC_DALCS = ['sentence_completion', 'basic_training', 'basic_grammar'];
const CHAT_AVAILABLE_GTELP_DALCS = ['test_practice_listening', 'test_practice_grammar', 'test_practice_reading'];

const useIsChatAvailable = () => {
  const isPreview = useIsPreview();

  const isDiagnosis = useAtomValue(learningStore.cell.isDiagnosisAtom);

  const isCompleted = useAtomValue(learningStore.cis.isCompletedAtom);
  const isQuestion = useAtomValue(learningStore.cis.isQuestionAtom);

  const dalc = useAtomValue(learningStore.content.dalcAtom);
  const parts = useAtomValue(learningStore.content.partsAtom);

  /**
   * FIXME-LD
   * Prompt 작업이 돼있는 컨텐츠만 가능
   **/
  const isAvailableContent = React.useMemo(
    () =>
      isQuestion &&
      ((hasIntersection(CHAT_AVAILABLE_TOEIC_PARTS, parts) && !CHAT_UNAVAILABLE_TOEIC_DALCS.includes(dalc)) ||
        CHAT_AVAILABLE_GTELP_DALCS.includes(dalc)),
    [dalc, isQuestion, parts]
  );

  return !isPreview && isCompleted && !isDiagnosis && isAvailableContent;
};

export {useIsChatAvailable};
