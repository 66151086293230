import React from 'react';
import {css, SerializedStyles} from '@emotion/react';
import {COLOR_SANTA_O, COLOR_SANTA_E, COLOR_SANTA_J, COLOR_SANTA_I} from '@riiid/design-system';
import Checkbox from '@riiid/design-system/icons/Checkbox';

import {Typography} from '@santa-web/service-ui';

// TODO: Move to design system & change name after the policy for this component is fixed

export interface ButtonTextWithCheckboxProps extends React.HTMLAttributes<HTMLDivElement> {
  checked?: boolean;
  disabled?: boolean;
  customCss?: SerializedStyles;
}

const ButtonTextWithCheckbox = React.forwardRef<HTMLDivElement, ButtonTextWithCheckboxProps>(
  ({children, checked, onClick, disabled, customCss, ...props}, ref) => {
    const handleClick = React.useCallback(
      (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
        if (!disabled) onClick?.(e);
      },
      [disabled, onClick]
    );

    return (
      <div
        ref={ref}
        {...props}
        css={css`
          color: ${COLOR_SANTA_J};
          display: inline-flex;
          align-items: center;

          &:hover {
            color: ${COLOR_SANTA_I};
          }

          &:active,
          &:focus {
            color: ${COLOR_SANTA_O};
          }

          ${disabled
            ? `
              color: ${COLOR_SANTA_E};
              pointer-events: none;
              `
            : ''}

          ${customCss}
        `}
        onClick={handleClick}>
        <Checkbox
          type={checked ? 'solid' : 'line'}
          width="24"
          height="24"
          data-testid={`ButtonTextWithCheckbox-Checkbox-${checked ? 'solid' : 'line'}`}
        />
        <Typography
          variant="body3"
          fontWeight="regular"
          css={css`
            margin-left: 4px;
          `}>
          {children}
        </Typography>
      </div>
    );
  }
);

export default React.memo(ButtonTextWithCheckbox);
