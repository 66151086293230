import React from 'react';
import {css} from '@emotion/react';

import {Button, ButtonProps, LoadingCircleAnimation, LoadingCircleAnimationProps} from '@santa-web/service-ui';

interface LoadableButtonProps extends ButtonProps {
  as?: React.ComponentType<ButtonProps>;
  isLoading: boolean;
  loadingAnimationVariant: LoadingCircleAnimationProps['variant'];
}

const LoadableButton = React.memo(
  React.forwardRef<HTMLButtonElement, LoadableButtonProps>(
    ({isLoading, loadingAnimationVariant, children, as, ...restProps}, ref) => {
      const ButtonComponent = as ?? Button;
      return (
        <ButtonComponent {...restProps} ref={ref}>
          <span
            css={css`
              position: relative;
            `}>
            <span
              css={
                isLoading &&
                css`
                  visibility: hidden;
                `
              }>
              {children}
            </span>
            {isLoading && (
              <span
                css={css`
                  width: 24px;
                  height: 24px;
                  position: absolute;
                  left: 50%;
                  top: 50%;
                  transform: translate(-50%, -50%);
                `}>
                <LoadingCircleAnimation width={24} height={24} variant={loadingAnimationVariant} />
              </span>
            )}
          </span>
        </ButtonComponent>
      );
    }
  )
);

export default LoadableButton;
