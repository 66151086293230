/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { CursorPageFromJSON, CursorPageToJSON, } from './CursorPage';
import { VocabularyLearningHistoryFromJSON, VocabularyLearningHistoryToJSON, } from './VocabularyLearningHistory';
/**
 * Check if a given object implements the ListVocabularyLearningHistoriesResponse interface.
 */
export function instanceOfListVocabularyLearningHistoriesResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "vocabularyLearningHistories" in value;
    return isInstance;
}
export function ListVocabularyLearningHistoriesResponseFromJSON(json) {
    return ListVocabularyLearningHistoriesResponseFromJSONTyped(json, false);
}
export function ListVocabularyLearningHistoriesResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'nextCursorPage': !exists(json, 'nextCursorPage') ? undefined : CursorPageFromJSON(json['nextCursorPage']),
        'vocabularyLearningHistories': (json['vocabularyLearningHistories'].map(VocabularyLearningHistoryFromJSON)),
    };
}
export function ListVocabularyLearningHistoriesResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'nextCursorPage': CursorPageToJSON(value.nextCursorPage),
        'vocabularyLearningHistories': (value.vocabularyLearningHistories.map(VocabularyLearningHistoryToJSON)),
    };
}
