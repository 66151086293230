import {Version, VersionWrapper} from './types';

const createVersionWrapper = (version: Version): VersionWrapper => {
  const self: VersionWrapper = {
    get major() {
      return version.major;
    },
    get minor() {
      return version.minor;
    },
    get patch() {
      return version.patch;
    },
    compare(other: Version) {
      if (self.major !== other.major) {
        return self.major - other.major;
      }

      if (self.minor !== other.minor) {
        return self.minor - other.minor;
      }

      return self.patch - other.patch;
    },
    gte(other: Version): boolean {
      return this.compare(other) >= 0;
    },
    gt(other: Version): boolean {
      return this.compare(other) > 0;
    },
    eq(other: Version): boolean {
      return this.compare(other) === 0;
    },
  };

  return self;
};

export default createVersionWrapper;
