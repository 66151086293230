import React from 'react';
import {Portal} from 'react-portal';
import useClickAway from 'react-use/lib/useClickAway';
import useKey from 'react-use/lib/useKey';
import {css, SerializedStyles} from '@emotion/react';
import {BREAKPOINT_MEDIUM, COLOR_SANTA_CARD_A} from '@riiid/design-system';

import {Dim, useToastContext} from '@santa-web/service-ui';
import ThickTopNavBar from '@app/components/ThickTopNavBar';
import {MODAL_Z_INDEX} from '@app/constants/zIndex';

export interface ModalProps {
  isOpen: boolean;
  children: React.ReactNode;
  footer?: React.ReactNode;
  headerRightElement?: React.ReactNode;
  onRequestClose: () => void;
  BodyComponent?: React.ComponentType;
  bodyCss?: SerializedStyles;
}

type SubcomponentProps = Pick<ModalProps, 'children'> & {
  customCss?: SerializedStyles;
};

const Modal = ({children, isOpen, onRequestClose, headerRightElement, footer, BodyComponent, bodyCss}: ModalProps) => {
  const ref = React.useRef<HTMLDivElement>(null);
  const {toastContainer} = useToastContext();

  useClickAway(ref, () => {
    onRequestClose();
  });

  useKey(e => e.key === 'Esc' || e.key === 'Escape', onRequestClose);

  if (!isOpen) {
    return null;
  }

  const ModalBody = BodyComponent ?? Modal.Body;

  return (
    <Modal.Overlay>
      <Modal.Content ref={ref}>
        <Modal.Header onRequestClose={onRequestClose} headerRightElement={headerRightElement} />
        <ModalBody customCss={bodyCss}>
          <>
            {children}
            {toastContainer}
          </>
        </ModalBody>
        {footer && <Modal.Footer>{footer}</Modal.Footer>}
      </Modal.Content>
    </Modal.Overlay>
  );
};

Modal.Overlay = ({children}: SubcomponentProps) => {
  return (
    <Portal>
      <Dim
        color="black"
        css={css`
          position: fixed;
          top: 0;
          display: flex;
          align-items: center;
          justify-content: center;

          padding: 0;

          z-index: ${MODAL_Z_INDEX};

          @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
            padding: 48px 0;
          }
        `}
        role="dialog"
        aria-label="overlay">
        {children}
      </Dim>
    </Portal>
  );
};

Modal.Content = React.forwardRef<HTMLDivElement, SubcomponentProps>(({children}, ref) => {
  return (
    <div
      ref={ref}
      css={css`
        position: relative;
        display: grid;
        grid-template-rows: auto 1fr auto;

        overflow: hidden;

        background-color: ${COLOR_SANTA_CARD_A};

        width: 100%;
        height: 100%;

        @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
          width: 680px;
          max-height: 800px;
          border-radius: 8px;
        }
      `}>
      {children}
    </div>
  );
});

Modal.Body = ({children, customCss}: SubcomponentProps) => {
  return (
    <div
      css={css`
        position: relative;
        padding: 20px;
        overflow-y: auto;
        word-break: keep-all;

        @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
          padding: 40px;
        }

        ${customCss}
      `}
      role="main"
      aria-label="body">
      {children}
    </div>
  );
};

Modal.Header = ({headerRightElement, onRequestClose}: Pick<ModalProps, 'headerRightElement' | 'onRequestClose'>) => {
  return (
    <ThickTopNavBar
      css={css`
        background-color: ${COLOR_SANTA_CARD_A};
      `}
      rightElement={headerRightElement}
      onCloseClick={onRequestClose}
    />
  );
};

Modal.Footer = ({children}: SubcomponentProps) => {
  return <div>{children}</div>;
};

export default React.memo(Modal);
