import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {COLOR_SANTA_BD, COLOR_SANTA_J} from '@riiid/design-system';
import {Button, Typography} from '@santa-web/service-ui';
import {Column, LineClamp} from '@app/components';
import {CouponBaseCard, CouponStatus, isCouponDisabled} from '@app/features/coupon';

type PermissionCouponCardProps = {
  status: CouponStatus;
  displayName: string;
  includes?: string[];
  onCouponUseClick(): void;
};

const PermissionCouponCard = ({status, displayName, includes, onCouponUseClick}: PermissionCouponCardProps) => {
  const {t} = useTranslation();
  const isDisabled = isCouponDisabled(status);
  return (
    <CouponBaseCard
      isDisabled={isDisabled}
      status={status === 'REGISTERED' ? 'REGISTERED_AND_READY' : status}
      footer={
        <Button
          css={css`
            margin-top: 16px;
          `}
          colorVariant="black"
          onClick={onCouponUseClick}>
          {t('page_coupon_box_permit_coupon_use_button_label')} {/** KR: 내 학습권 및 부가상품에 사용하기 */}
        </Button>
      }>
      <Column
        gap={4}
        css={css`
          margin-top: 8px;
        `}>
        <LineClamp limit={2} color={COLOR_SANTA_BD}>
          <Typography variant="body3" fontWeight="bold" color={COLOR_SANTA_BD}>
            {displayName}
          </Typography>
        </LineClamp>

        {includes?.map(include => (
          <LineClamp limit={2} key={include} color={COLOR_SANTA_J}>
            <Typography variant="caption1" color={COLOR_SANTA_J}>
              {include}
            </Typography>
          </LineClamp>
        ))}
      </Column>
    </CouponBaseCard>
  );
};

export default PermissionCouponCard;
export type {PermissionCouponCardProps};
PermissionCouponCard.displayName = 'PermissionCouponCard';
