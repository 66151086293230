import React from 'react';
import {css} from '@emotion/react';
import ListIcon from '@riiid/design-system/icons/List';

import BottomToolList, {BottomToolListProps} from '@app/components/ContentView/components/BottomToolList';
import DropdownToggleList from '@app/components/DropdownToggleList';

export interface PlaybackRateOption {
  label: string;
  value: number;
}

export interface PlaybackRateBottomToolListProps extends BottomToolListProps {
  currentPlaybackRate: number;
  playbackRateOptions: PlaybackRateOption[];
  setPlaybackRate(playbackRate: number): void;
  onPlaybackRateDropdownClick(): void;
  onListClick?: () => void;
}

const PlaybackRateBottomToolList = ({
  currentPlaybackRate,
  playbackRateOptions,
  setPlaybackRate,
  onPlaybackRateDropdownClick,
  onListClick,
  children,
  ...props
}: PlaybackRateBottomToolListProps): JSX.Element => {
  const [isPlaybackRateDropdownOpened, setIsPlaybackRateDropdownOpened] = React.useState(false);

  const handleDropdownToggleListOpen: typeof setIsPlaybackRateDropdownOpened = (...args) => {
    onPlaybackRateDropdownClick();
    setIsPlaybackRateDropdownOpened(...args);
  };

  const selectedOption = React.useMemo(() => {
    const option = playbackRateOptions.find(option => Math.abs(option.value - currentPlaybackRate) <= 1e-5);
    if (option) return option;
    return {
      value: -1,
      label: '-x',
    };
  }, [currentPlaybackRate, playbackRateOptions]);

  return (
    <BottomToolList {...props}>
      <div
        css={css`
          display: flex;
          justify-content: flex-end;
          align-items: center;
        `}>
        <DropdownToggleList
          isOpened={isPlaybackRateDropdownOpened}
          selectedOption={selectedOption}
          setIsOpened={handleDropdownToggleListOpen}
          onValueSelect={setPlaybackRate}
          options={playbackRateOptions}
          direction="top-right"
          itemContainerCss={css`
            width: 84px;
            max-height: 258px;
            overflow-y: auto;
          `}
          isChevronHidden={onListClick !== undefined}
        />
      </div>
      {children}
      {onListClick && (
        <div
          css={css`
            display: flex;
            justify-content: flex-end;
            align-items: center;
          `}>
          <ListIcon cursor="pointer" type="line" width={24} height={24} onClick={onListClick} />
        </div>
      )}
    </BottomToolList>
  );
};

export default React.memo(PlaybackRateBottomToolList);
