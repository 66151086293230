/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
/**
 *
 */
export class CourseLearningV1Api extends runtime.BaseAPI {
    /**
     * 추천학습 권한을 확인한다.
     * 추천학습 권한을 확인한다.
     */
    async checkCourseLearningPermitRaw(requestParameters, initOverrides) {
        if (requestParameters.learningCellId === null || requestParameters.learningCellId === undefined) {
            throw new runtime.RequiredError('learningCellId', 'Required parameter requestParameters.learningCellId was null or undefined when calling checkCourseLearningPermit.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/content-learning/v1/course-learning/permit/{learningCellId}`.replace(`{${"learningCellId"}}`, encodeURIComponent(String(requestParameters.learningCellId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * 추천학습 권한을 확인한다.
     * 추천학습 권한을 확인한다.
     */
    async checkCourseLearningPermit(requestParameters, initOverrides) {
        await this.checkCourseLearningPermitRaw(requestParameters, initOverrides);
    }
}
