import React, {useMemo} from 'react';

import {Passage} from '@santa-web/gen/open-api/content/models/Passage';
import SnippetListView from '@app/test-preparation/components/SnippetListView';

export interface PassageListView extends React.ComponentPropsWithoutRef<'div'> {
  passages?: Passage[] | null;
}

const PassageListView = React.forwardRef<HTMLDivElement, PassageListView>(({passages, ...props}, ref) => {
  const snippets = useMemo(() => passages?.map(passage => passage?.body ?? {}), [passages]);
  return <SnippetListView ref={ref} snippets={snippets} id="passage" {...props} />;
});

export default React.memo(PassageListView);
