import {useCallback, useState} from 'react';
import {useInterval} from 'react-use';

const INTERVAL_MS = 1000;

export default function useVocaExplanationTimer(enabled?: boolean) {
  const [timer, setTimer] = useState(0);

  useInterval(
    () => {
      setTimer(timer => timer + 1);
    },
    enabled && timer >= 0 ? INTERVAL_MS : null
  );

  const resetTimer = useCallback(() => setTimer(0), []);

  return {
    timer,
    resetTimer,
  };
}
