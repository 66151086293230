/**
 * Check if a given object implements the PointRewardRequestRewardUrl interface.
 */
export function instanceOfPointRewardRequestRewardUrl(value) {
    let isInstance = true;
    isInstance = isInstance && "month" in value;
    isInstance = isInstance && "rank" in value;
    isInstance = isInstance && "url" in value;
    isInstance = isInstance && "validUntil" in value;
    isInstance = isInstance && "week" in value;
    return isInstance;
}
export function PointRewardRequestRewardUrlFromJSON(json) {
    return PointRewardRequestRewardUrlFromJSONTyped(json, false);
}
export function PointRewardRequestRewardUrlFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'month': json['month'],
        'rank': json['rank'],
        'url': json['url'],
        'validUntil': (new Date(json['validUntil'])),
        'week': json['week'],
    };
}
export function PointRewardRequestRewardUrlToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'month': value.month,
        'rank': value.rank,
        'url': value.url,
        'validUntil': (value.validUntil.toISOString()),
        'week': value.week,
    };
}
