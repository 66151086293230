/**
 * Check if a given object implements the PointRewardNewMemberBonusPoint interface.
 */
export function instanceOfPointRewardNewMemberBonusPoint(value) {
    let isInstance = true;
    isInstance = isInstance && "bonusPoint" in value;
    isInstance = isInstance && "validUntil" in value;
    return isInstance;
}
export function PointRewardNewMemberBonusPointFromJSON(json) {
    return PointRewardNewMemberBonusPointFromJSONTyped(json, false);
}
export function PointRewardNewMemberBonusPointFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'bonusPoint': json['bonusPoint'],
        'validUntil': (new Date(json['validUntil'])),
    };
}
export function PointRewardNewMemberBonusPointToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'bonusPoint': value.bonusPoint,
        'validUntil': (value.validUntil.toISOString()),
    };
}
