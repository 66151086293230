import { ExamScoreAggregationTypeFromJSON, ExamScoreAggregationTypeToJSON, } from './ExamScoreAggregationType';
import { ExamScoreUsageFromJSON, ExamScoreUsageToJSON, } from './ExamScoreUsage';
/**
 * Check if a given object implements the TotalExamScoreConfig interface.
 */
export function instanceOfTotalExamScoreConfig(value) {
    let isInstance = true;
    isInstance = isInstance && "aggregationType" in value;
    isInstance = isInstance && "defaultValue" in value;
    isInstance = isInstance && "isPercent" in value;
    isInstance = isInstance && "maxScore" in value;
    isInstance = isInstance && "minScore" in value;
    isInstance = isInstance && "scoreStep" in value;
    isInstance = isInstance && "usages" in value;
    return isInstance;
}
export function TotalExamScoreConfigFromJSON(json) {
    return TotalExamScoreConfigFromJSONTyped(json, false);
}
export function TotalExamScoreConfigFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'aggregationType': ExamScoreAggregationTypeFromJSON(json['aggregationType']),
        'defaultValue': json['defaultValue'],
        'isPercent': json['isPercent'],
        'maxScore': json['maxScore'],
        'minScore': json['minScore'],
        'scoreStep': json['scoreStep'],
        'usages': (json['usages'].map(ExamScoreUsageFromJSON)),
    };
}
export function TotalExamScoreConfigToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'aggregationType': ExamScoreAggregationTypeToJSON(value.aggregationType),
        'defaultValue': value.defaultValue,
        'isPercent': value.isPercent,
        'maxScore': value.maxScore,
        'minScore': value.minScore,
        'scoreStep': value.scoreStep,
        'usages': (value.usages.map(ExamScoreUsageToJSON)),
    };
}
