import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {COLOR_SANTA_F, COLOR_SANTA_BC} from '@riiid/design-system';
import CheckIcon from '@riiid/design-system/icons/Check';

import {Typography} from '@santa-web/service-ui';
import BottomSheetListItem from '@app/components/BottomSheetListItem';

export type OfferGroupOrderCouponBottomSheetListItemProps = {
  caption: string;
  title: string;
  isSelected: boolean;
  isUnavailable: boolean;
  onClick: () => void;
};

const OfferGroupOrderCouponBottomSheetListItem = ({
  title,
  caption,
  isSelected,
  isUnavailable,
  onClick,
}: OfferGroupOrderCouponBottomSheetListItemProps): JSX.Element => {
  const {t} = useTranslation();
  return (
    <BottomSheetListItem
      title={title}
      caption={caption}
      isSelected={isSelected}
      isDisabled={isUnavailable}
      onClick={onClick}
      endContent={
        isUnavailable ? (
          <Typography variant="caption1" fontWeight="regular" color={COLOR_SANTA_F}>
            {t('order_coupon_item_unavailable')}
          </Typography>
        ) : isSelected ? (
          <CheckIcon
            data-testid="OfferGroupOrderCouponBottomSheetListItem-CheckIcon"
            color={COLOR_SANTA_BC}
            height={24}
            width={24}
          />
        ) : undefined
      }
      css={css`
        & + & {
          padding-top: 12px;
        }
      `}
    />
  );
};

export default React.memo(OfferGroupOrderCouponBottomSheetListItem);
