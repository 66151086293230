import {useGetMobileAppInfoQuery, useGetSystemConfigAppVersionsQuery} from '@app/features/preferences/hooks';
import createVersionWrapper from '@app/new-structure/useCases/VersionChecker/VersionWrapper';

/**
 * @description App이 Store 심사 상태인지 확인합니다. iOS만 동작하며 Android는 항상 false를 반환합니다.
 */
function useIsUnderAppReview() {
  const {data: appInfo} = useGetMobileAppInfoQuery();
  const {data: systemConfigAppVersion} = useGetSystemConfigAppVersionsQuery(appInfo?.platform);

  if (appInfo == null || systemConfigAppVersion == null) {
    return false;
  }

  // Android는 업데이트 후 어드민의 SystemConfig의 latest version을 업데이트하는 작업이 보장되지 않음.
  // 그로인해 사용자에게 노출되는 앱이 심사 중인 상태가 될 수 있으므로 항상 false를 반환합니다.
  if (appInfo.platform === 'ANDROID') {
    return false;
  }

  const currentAppVersion = createVersionWrapper({
    major: appInfo.version?.major ?? 0,
    minor: appInfo.version?.minor ?? 0,
    patch: appInfo.version?.patch ?? 0,
  });

  return currentAppVersion.gt(systemConfigAppVersion.latest);
}

export default useIsUnderAppReview;
