import {useRouter} from 'next/router';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {useAtomValue} from 'jotai';

import {CommonSignupResponse} from '@santa-web/gen/open-api/service';
import {mobileService} from '@app/api/app-bridge/mobile-service';
import useSignupEvents from '@app/api/google-tag-manager/sign-up';
import santaAuthorizedOpenapiServicesAtom from '@app/atoms/core/santa-authorized-openapi-services';
import tokenManagerAtom from '@app/atoms/core/token-manager';
import {BaseSignupForm} from '@app/features/auth/components';
import {SnsSignupAndSigninInfo, SignupFormValues} from '@app/features/auth/types';
import {toSignupCommonProps} from '@app/features/auth/utils';
import {useTypedSearchParams} from '@app/hooks/useTypedRouter';
import {
  isB2bState,
  isRedirectionState,
  redirect,
  useGoToB2bOfferGroupOrderPage,
  useQueryState,
} from '@app/misc/query-state';
import {OnSubmit} from '@app/types/form';
import {isAppBridgeAvailable} from '@app/utils/app-bridge';

interface Props {
  snsAuthRequest?: SnsSignupAndSigninInfo;
}

const initialValues: Partial<SignupFormValues> = {
  name: '',
  email: '',
  agreeServiceTerms: false,
  agreePrivacy: false,
  agreeAge14OrOlder: false,
  agreeMarketing: false,
};

const SnsSignupPageContainer = ({snsAuthRequest}: Props) => {
  const router = useRouter();
  const {email, name} = useTypedSearchParams('/signup/sns');
  const {AuthService} = useAtomValue(santaAuthorizedOpenapiServicesAtom);
  const tokenManager = useAtomValue(tokenManagerAtom);
  const {t} = useTranslation();
  const queryState = useQueryState();
  const {pushFinishSignupEvent} = useSignupEvents();
  const goToB2bOfferGroupOrderPage = useGoToB2bOfferGroupOrderPage();

  const handleSubmit: OnSubmit<SignupFormValues> = React.useCallback(
    async values => {
      try {
        let response: CommonSignupResponse;
        if (!snsAuthRequest) {
          throw new Error();
        }
        switch (snsAuthRequest.socialSignupVariant) {
          case 'APPLE':
            response = await AuthService.signupWithApple({
              signupWithAppleRequest: {
                ...toSignupCommonProps(values),
                appleRegistrationInfo: snsAuthRequest.registrationInfo,
              },
            });
            break;
          case 'GOOGLE':
            response = await AuthService.signupWithGoogle({
              signupWithGoogleRequest: {
                ...toSignupCommonProps(values),
                googleRegistrationInfo: snsAuthRequest.registrationInfo,
              },
            });
            break;
          case 'KAKAO':
            response = await AuthService.signupWithKakao({
              signupWithKakaoRequest: {
                ...toSignupCommonProps(values),
                kakaoRegistrationInfo: snsAuthRequest.registrationInfo,
              },
            });
            break;
          case 'LINE':
            response = await AuthService.signupWithLine({
              signupWithLineRequest: {
                ...toSignupCommonProps(values),
                lineRegistrationInfo: snsAuthRequest.registrationInfo,
              },
            });
        }
        const {userToken} = response;
        tokenManager.setToken(userToken);

        pushFinishSignupEvent({provider: snsAuthRequest.socialSignupVariant});
        if (isAppBridgeAvailable() && values.agreeMarketing) {
          await mobileService.requestPermission('PUSH');
        }

        if (isB2bState(queryState)) {
          await goToB2bOfferGroupOrderPage(queryState);
        } else if (isRedirectionState(queryState)) {
          await redirect(router, queryState);
        } else {
          await router.push({pathname: '/'});
        }
      } catch (err: any) {
        alert(err.message ?? t('alert_unknown_error_title'));
      }
    },
    [
      snsAuthRequest,
      tokenManager,
      pushFinishSignupEvent,
      queryState,
      AuthService,
      goToB2bOfferGroupOrderPage,
      router,
      t,
    ]
  );

  return (
    <BaseSignupForm
      initialValues={{
        ...initialValues,
        email: email ? decodeURIComponent(email) : '',
        name: name ? decodeURIComponent(name) : '',
      }}
      onSubmit={handleSubmit}
      signUpType="sns"
    />
  );
};

export default SnsSignupPageContainer;
