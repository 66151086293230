import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';

import {AlertProps, AlertDialog} from '@santa-web/service-ui';
import {ALERT_DIALOG_Z_INDEX} from '@app/constants/zIndex';
import {useDialogContext} from '@app/contexts/DialogContext';
import {useLogout} from '@app/hooks/useLogout';

export default React.memo(
  React.forwardRef<HTMLDivElement>(function LogoutAlertDialog(_, ref) {
    const {t} = useTranslation();
    const {closeDialog} = useDialogContext();
    const logout = useLogout();

    const handlePositiveClick = React.useCallback(async () => {
      closeDialog();
      await logout();
    }, [closeDialog, logout]);

    const AlertProps = useMemo(
      (): AlertProps => ({
        title: t('dialog_logout_alert_title'),
        description: t('dialog_logout_alert_description'),
        positiveLabel: t('dialog_logout_alert_positive_label'),
        negativeLabel: t('dialog_logout_alert_negative_label'),
        onPositiveClick: handlePositiveClick,
        onNegativeClick: closeDialog,
      }),
      [closeDialog, handlePositiveClick, t]
    );

    return (
      <AlertDialog
        ref={ref}
        css={css`
          z-index: ${ALERT_DIALOG_Z_INDEX};
        `}
        AlertProps={AlertProps}
        isVisible
      />
    );
  })
);
