import React from 'react';
import {css} from '@emotion/react';
import {COLOR_SANTA_L} from '@riiid/design-system';
import {Typography} from '@santa-web/service-ui';

type OfferGroupOrderSectionTitleProps = {icon?: React.ReactNode; value: string};

const OfferGroupOrderSectionTitle = ({icon, value}: OfferGroupOrderSectionTitleProps) => {
  return (
    <div
      css={css`
        display: flex;
        align-items: center;
        gap: 8px;
        margin-bottom: 32px;
      `}>
      {icon && icon}
      <Typography component="h4" variant="body1" fontWeight="bold" color={COLOR_SANTA_L}>
        {value}
      </Typography>
    </div>
  );
};

export default OfferGroupOrderSectionTitle;
export type {OfferGroupOrderSectionTitleProps};
OfferGroupOrderSectionTitle.displayName = 'OfferGroupOrderSectionTitle';
