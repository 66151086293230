import React from 'react';
import {COLOR_SANTA_C} from '@riiid/design-system';

export type ArrowUpIconProps = React.ComponentPropsWithoutRef<'svg'>;

const ArrowUpIcon = React.forwardRef<SVGSVGElement, ArrowUpIconProps>(({color = COLOR_SANTA_C, ...props}, ref) => {
  return (
    <svg width="20" height="12" viewBox="0 0 20 12" xmlns="http://www.w3.org/2000/svg" ref={ref} {...props}>
      <path d="M20 12H0L10 0L20 12Z" fill={color} />
    </svg>
  );
});

export default React.memo(ArrowUpIcon);
