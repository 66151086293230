import React from 'react';
import {LoadingDim} from '@app/components';
import {
  OfferGroupOrderDetailView,
  useOfferGroupOrderDetailViewProps,
  useOfferGroupOrderQuery,
} from '@app/features/offer';
import {useTypedSearchParams} from '@app/hooks/useTypedRouter';

const OfferGroupOrderDetailPageContainer = () => {
  const {id} = useTypedSearchParams('/offer-group/order/detail');
  const {data: order} = useOfferGroupOrderQuery(id);

  const viewProps = useOfferGroupOrderDetailViewProps(id);

  const isLoading = !order || !viewProps;

  if (isLoading) {
    return <LoadingDim />;
  }

  return <OfferGroupOrderDetailView {...viewProps} />;
};

export default OfferGroupOrderDetailPageContainer;
OfferGroupOrderDetailPageContainer.displayName = 'OfferGroupOrderDetailPageContainer';
