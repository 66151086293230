import {useQuery} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';

import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {getActiveEventsQueryKey} from '@app/queryKeys';

export const useActiveEventsQuery = () => {
  const {MarketingService} = useAtomValue(santaOpenapiServicesAtom);
  return useQuery(
    getActiveEventsQueryKey(),
    async () =>
      await MarketingService.listAllActiveMarketingCampaigns({}).then(({marketingCampaigns}) => marketingCampaigns)
  );
};
