import React from 'react';
import mergeRefs from 'react-merge-refs';
import {useMeasure, useScroll} from 'react-use';
import {css} from '@emotion/react';
import {COLOR_SANTA_E, COLOR_SANTA_E_ALPHA, COLOR_SANTA_G} from '@riiid/design-system';

import useIntersectionObserver from '@app/hooks/useIntersectionObserver';

import JourneyDot from './JourneyDot';
import JourneyPageIconButton from './JourneyPageIconButton';

const journeySideMarginWidth = 320;

export interface JourneyProps extends React.ComponentPropsWithoutRef<'div'> {
  onGoalClick: React.MouseEventHandler;
  hasNextPage?: boolean;
  onFetchNext: () => Promise<void>;
  targetScore?: number;
  journeyDots?:
    | React.ReactNode
    | React.ReactComponentElement<typeof JourneyDot>
    | React.ReactComponentElement<typeof JourneyDot>[];
}

const Journey = React.forwardRef<HTMLDivElement, JourneyProps>(
  ({onGoalClick, hasNextPage, onFetchNext, targetScore, journeyDots, ...props}, ref) => {
    const scrollRef = React.useRef<HTMLDivElement>(null);
    const leftEndRef = React.useRef<HTMLDivElement>(null);
    const rightEndRef = React.useRef<HTMLDivElement>(null);
    const [measureRef, {width}] = useMeasure();
    const {x} = useScroll(scrollRef);
    const [isLeftPageIconButtonVisible, setIsLeftPageIconButtonVisible] = React.useState(false);
    const [isRightPageIconButtonVisible, setIsRightPageIconButtonVisible] = React.useState(false);

    useIntersectionObserver({
      root: scrollRef,
      target: leftEndRef,
      onIntersect() {
        setIsLeftPageIconButtonVisible(false);
      },
      offIntersect() {
        setIsLeftPageIconButtonVisible(true);
      },
    });

    useIntersectionObserver({
      root: scrollRef,
      target: rightEndRef,
      onIntersect() {
        setIsRightPageIconButtonVisible(false);
        onFetchNext();
      },
      offIntersect() {
        setIsRightPageIconButtonVisible(true);
      },
    });

    const handleLeftDoubleButtonClick = React.useCallback(() => {
      scrollRef.current?.scrollTo({left: 0, behavior: 'smooth'});
    }, []);

    const handleLeftButtonClick = React.useCallback(() => {
      scrollRef.current?.scrollTo({left: x - (width - journeySideMarginWidth), behavior: 'smooth'});
    }, [x, width]);

    const handleRightButtonClick = React.useCallback(() => {
      scrollRef.current?.scrollTo({left: x + width - journeySideMarginWidth, behavior: 'smooth'});
    }, [x, width]);

    const handleRightDoubleButtonClick = React.useCallback(() => {
      scrollRef.current?.scrollTo({left: scrollRef.current?.scrollWidth, behavior: 'smooth'});
    }, []);

    return (
      <div
        css={css`
          height: 140px;
          background-color: ${COLOR_SANTA_E};
          display: flex;
          justify-content: center;
        `}
        ref={ref}
        {...props}>
        <div
          css={css`
            width: 100%;
            max-width: 1080px;
            position: relative;
          `}>
          <div
            css={css`
              width: 100%;
              position: relative;
              height: 100%;

              overflow-x: scroll;

              scrollbar-width: none;
              ::-webkit-scrollbar {
                display: none;
              }
              display: flex;
            `}
            ref={mergeRefs([measureRef, scrollRef])}>
            <div ref={leftEndRef} />
            <div
              css={css`
                height: 100%;
                display: inline-grid;
                grid-auto-flow: column;
                column-gap: 20px;
                grid-column-gap: 20px;
                padding: 0 40px;
              `}>
              <JourneyDot title="GOAL" isGoal score={targetScore} onClick={onGoalClick} />
              <div
                css={css`
                  margin-top: 80px;
                  min-width: 1px;
                  height: 20px;
                  background-color: ${COLOR_SANTA_G};
                  opacity: 0.24;
                `}
              />
              {journeyDots}
            </div>
            <div ref={rightEndRef} />
          </div>
          {isLeftPageIconButtonVisible && (
            <div
              css={css`
                display: flex;
                position: absolute;
                top: 0;
                left: 0;
                height: 100%;
              `}>
              <div
                css={css`
                  background-color: ${COLOR_SANTA_E};
                  height: 100%;
                  padding-top: 70px;
                  padding-left: 40px;
                `}>
                <div
                  css={css`
                    display: inline-grid;
                    grid-auto-flow: column;
                    column-gap: 12px;
                    grid-column-gap: 12px;
                  `}>
                  <JourneyPageIconButton onClick={handleLeftDoubleButtonClick} type="left-double" />
                  <JourneyPageIconButton onClick={handleLeftButtonClick} type="left" />
                </div>
              </div>
              <div
                css={css`
                  width: 60px;
                  height: 100%;
                  background: linear-gradient(90deg, ${COLOR_SANTA_E}, ${COLOR_SANTA_E_ALPHA(0)});
                `}
              />
            </div>
          )}
          {(isRightPageIconButtonVisible || hasNextPage) && (
            <div
              css={css`
                display: flex;
                position: absolute;
                top: 0;
                right: 0;
                height: 100%;
              `}>
              <div
                css={css`
                  width: 60px;
                  height: 100%;
                  background: linear-gradient(-90deg, ${COLOR_SANTA_E}, ${COLOR_SANTA_E_ALPHA(0)});
                `}
              />
              <div
                css={css`
                  background-color: ${COLOR_SANTA_E};
                  height: 100%;
                  padding-top: 70px;
                  padding-right: 40px;
                `}>
                <div
                  css={css`
                    display: inline-grid;
                    grid-auto-flow: column;
                    column-gap: 12px;
                    grid-column-gap: 12px;
                  `}>
                  <JourneyPageIconButton onClick={handleRightButtonClick} type="right" />
                  <JourneyPageIconButton onClick={handleRightDoubleButtonClick} type="right-double" />
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default React.memo(Journey);
