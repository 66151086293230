import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {
  BREAKPOINT_MEDIUM,
  COLOR_FIX_SANTA_GRAY_5,
  COLOR_FIX_SANTA_GRAY_6,
  COLOR_FIX_SANTA_GRAY_9,
  COLOR_FIX_SPACE_BLUE_WHITE_3,
  COLOR_SANTA_C,
  COLOR_SPACE_BLUE_GRAY_1,
  COLOR_SPACE_BLUE_GRAY_6,
} from '@riiid/design-system';
import WarningIcon from '@riiid/design-system/icons/Warning';
import {URL_ICON_REFERRAL_COUPON} from '@riiid/design-system/images/santa';
import {Button, TextButton, Typography, useIsGteMediumScreen} from '@santa-web/service-ui';
import {BaseCard, SkeletonGrayBox} from '@app/components';
import {withSkeleton} from '@app/utils/component';

type FriendRecommendationCardProps = {
  isError?: boolean;
  friendCount?: number;
  onBenefitButtonClick: React.MouseEventHandler<HTMLButtonElement>;
  onShareButtonClick?: React.MouseEventHandler<HTMLButtonElement>;
};

const FriendRecommendationCard = ({
  isError = false,
  friendCount,
  onBenefitButtonClick,
  onShareButtonClick,
}: FriendRecommendationCardProps) => {
  const {t} = useTranslation();
  const isGteMediumScreen = useIsGteMediumScreen();
  return (
    <BaseCard
      css={css`
        background-color: ${COLOR_FIX_SPACE_BLUE_WHITE_3};
        padding: 20px;
        @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
          padding: 20px 24px;
        }
      `}>
      <div
        css={css`
          display: flex;
          gap: 20px;
        `}>
        <img src={URL_ICON_REFERRAL_COUPON} width={64} height={64} alt="" />
        <div
          css={css`
            width: 100%;
            display: flex;
            flex-direction: column;
            gap: 8px;
            @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
              flex-direction: row;
              justify-content: space-between;
              align-items: center;
            }
          `}>
          <div>
            <Typography component="p" color={COLOR_FIX_SANTA_GRAY_9} variant="caption1">
              {t('component_friend_recommendation_card_sub_title')}
            </Typography>
            <Typography color={COLOR_FIX_SANTA_GRAY_9} variant="body3" fontWeight="bold">
              <Trans
                t={t}
                i18nKey="component_friend_recommendation_card_title"
                components={{
                  b: <Typography component="span" variant="body3" fontWeight="bold" color={COLOR_SPACE_BLUE_GRAY_6} />,
                }}
              />
            </Typography>
          </div>
          <TextButton
            color="black-fix"
            typographyVariant="caption1"
            fontWeight="regular"
            isChevronVisible
            onClick={onBenefitButtonClick}>
            {t('component_friend_recommendation_card_text_button')}
          </TextButton>
        </div>
      </div>
      <hr
        css={css`
          margin: 12px 0;
          border: none;
          border-top: solid 1px ${COLOR_SPACE_BLUE_GRAY_1};
        `}
      />
      <div
        css={css`
          display: flex;
          flex-direction: column;
          gap: 12px;
          @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
          }
        `}>
        <Typography
          component="p"
          variant="caption1"
          css={css`
            display: flex;
            justify-content: space-between;
            gap: 20px;
            @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
              justify-content: flex-start;
            }
          `}>
          <Typography component="span" variant="caption1" color={COLOR_FIX_SANTA_GRAY_6}>
            {t('component_friend_recommendation_card_description')}
          </Typography>
          <Typography component="span" variant="caption1" color={COLOR_FIX_SANTA_GRAY_9} fontWeight="bold">
            {t('dict_n_people', {n: friendCount == null || isError ? '-' : friendCount})}
          </Typography>
        </Typography>
        {!isError && (
          <Button
            css={css`
              @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
                width: 200px;
              }
            `}
            onClick={onShareButtonClick}
            colorVariant="brand"
            isFullWidth={!isGteMediumScreen}
            sizeVariant={isGteMediumScreen ? 'medium' : 'small'}>
            {t('component_friend_recommendation_card_button')}
          </Button>
        )}
      </div>
      {isError && (
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 8px;
            margin-top: 8px;
          `}>
          <WarningIcon width={20} height={20} color={COLOR_FIX_SANTA_GRAY_5} />
          <Typography component="p" variant="caption1" color={COLOR_FIX_SANTA_GRAY_5}>
            {t('component_friend_recommendation_card_description_error')}
          </Typography>
        </div>
      )}
    </BaseCard>
  );
};

const Skeleton = () => {
  return (
    <BaseCard
      css={css`
        background-color: ${COLOR_SANTA_C};
        padding: 40px 20px;
      `}>
      <SkeletonGrayBox
        css={css`
          margin-bottom: 24px;
        `}
        width={100}
        height={20}
      />
      <SkeletonGrayBox
        css={css`
          max-width: 560px;
          margin-bottom: 12px;
        `}
        width="100%"
        height={12}
      />
      <SkeletonGrayBox
        css={css`
          max-width: 320px;
        `}
        width="100%"
        height={12}
      />
    </BaseCard>
  );
};

export default withSkeleton(FriendRecommendationCard, Skeleton);
export type {FriendRecommendationCardProps};
FriendRecommendationCard.displayName = 'FriendRecommendationCard';
