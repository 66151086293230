import React, {useEffect, useMemo, useRef, useState} from 'react';
import {css} from '@emotion/react';
import {
  COLOR_SANTA_O,
  COLOR_SANTA_E,
  COLOR_SANTA_H,
  COLOR_SANTA_BD,
  COLOR_SANTA_A,
  COLOR_SANTA_BC_ALPHA,
  COLOR_SANTA_BE,
  COLOR_SANTA_BC,
  COLOR_SANTA_BA,
} from '@riiid/design-system';
import {PieChart, Pie, Cell} from 'recharts';

import {Typography, TooltipCard} from '@santa-web/service-ui';

import PartScore from './components/PartScore';

type Segment = {name: string; shortName: string; maxScore: number; score: number};
type TwoOrThreeTuple<T> = [T, T] | [T, T, T];

export interface TotalScoreGraphProps {
  title: string;
  goalScore?: number;
  isPercent: boolean;
  maxScore: number;
  totalScore: number;
  isScoreHidden?: boolean;
  segments: TwoOrThreeTuple<Segment>;
}

export const CHART_COLORS = {2: [COLOR_SANTA_BC, COLOR_SANTA_BE], 3: [COLOR_SANTA_BA, COLOR_SANTA_BD, COLOR_SANTA_BE]};
const RING_WIDTH = 8;

// 현재 2,3 개 color까지만 지원중입니다. 그 외 일 경우 수정이 필수로 필요합니다
const TotalScoreGraph = ({
  title,
  goalScore,
  isScoreHidden = false,
  maxScore,
  totalScore,
  isPercent,
  segments,
  ...props
}: TotalScoreGraphProps): JSX.Element => {
  const [tooltipPosition, setTooltipPosition] = useState<null | {x: number; y: number}>(null);
  const isTooltipAvailable = goalScore !== undefined;
  const tooltipRef = useRef(null);
  const CHART_SIZE = 212;

  const isTooltipLocatedBottom = isTooltipAvailable && goalScore < (maxScore * 2) / 3 && goalScore > maxScore / 3;

  const segmentsWithColor = React.useMemo(
    () =>
      segments.map((partial, index) => {
        return {
          ...partial,
          score: isPercent ? partial.score / segments.length : partial.score,
          color: CHART_COLORS[segments.length][index],
        };
      }),
    [isPercent, segments]
  );
  const targetData = useMemo(() => {
    if (goalScore === undefined) {
      return [
        {value: totalScore, color: COLOR_SANTA_BD},
        {value: maxScore - totalScore, color: COLOR_SANTA_O, opacity: 0.15},
      ];
    }
    if (totalScore > goalScore) {
      if (goalScore < segmentsWithColor[0].score) {
        return [
          {value: goalScore - maxScore / 1000, color: segmentsWithColor[0].color},
          {value: maxScore / 1000, color: COLOR_SANTA_A, ref: tooltipRef},
          {value: segmentsWithColor[0].score - goalScore, color: segmentsWithColor[0].color},
          {value: segmentsWithColor[1].score, color: segmentsWithColor[1].color},
          {value: segmentsWithColor[2]?.score ?? 0, color: segmentsWithColor[2]?.color},
          {value: maxScore - totalScore, color: COLOR_SANTA_O, opacity: 0.15},
        ];
      }
      if (goalScore < segmentsWithColor[0].score + segmentsWithColor[1]?.score) {
        return [
          {value: segmentsWithColor[0].score, color: segmentsWithColor[0].color},
          {value: goalScore - segmentsWithColor[0].score, color: segmentsWithColor[1].color},
          {value: maxScore / 1000, color: COLOR_SANTA_A, ref: tooltipRef},
          {
            value: segmentsWithColor[0].score + segmentsWithColor[1].score - goalScore,
            color: segmentsWithColor[1].color,
          },
          {value: segmentsWithColor[2]?.score, color: segmentsWithColor[2]?.color},
          {value: maxScore - totalScore, color: COLOR_SANTA_O, opacity: 0.15},
        ];
      }
      return [
        {value: segmentsWithColor[0].score, color: segmentsWithColor[0].color},
        {value: segmentsWithColor[1].score, color: segmentsWithColor[1].color},
        {
          value: goalScore - segmentsWithColor[0].score - segmentsWithColor[1].score,
          color: segmentsWithColor[2]?.color,
        },
        {value: maxScore / 1000, color: COLOR_SANTA_A, ref: tooltipRef},
        {value: totalScore - goalScore, color: segmentsWithColor[2]?.color},
        {value: maxScore - totalScore, color: COLOR_SANTA_O, opacity: 0.15},
      ];
    }
    return [
      {value: segmentsWithColor[0].score, color: segmentsWithColor[0].color},
      {value: segmentsWithColor[1].score, color: segmentsWithColor[1].color},
      {value: segmentsWithColor[2]?.score, color: segmentsWithColor[2]?.color},
      {value: goalScore - totalScore - maxScore / 1000, color: COLOR_SANTA_O, opacity: 0.15},
      {value: maxScore / 1000, color: COLOR_SANTA_A, ref: tooltipRef},
      {value: maxScore - goalScore, color: COLOR_SANTA_O, opacity: 0.15},
    ];
  }, [goalScore, maxScore, segmentsWithColor, totalScore]);

  const handleAnimationEnd = React.useCallback(() => {
    if (isTooltipAvailable && tooltipRef.current) {
      const {props}: {props: {tooltipPosition: {x: number; y: number}}} = tooltipRef.current;
      if (props.tooltipPosition.x !== tooltipPosition?.x && props.tooltipPosition.y !== tooltipPosition?.y) {
        setTooltipPosition({x: props.tooltipPosition.x, y: props.tooltipPosition.y});
      }
    }
  }, [isTooltipAvailable, tooltipPosition]);

  useEffect(() => {
    if (tooltipPosition !== null) {
      handleAnimationEnd();
    }
  }, [handleAnimationEnd, tooltipPosition]);

  return (
    <>
      <div
        css={css`
          display: inline-flex;
          position: relative;
        `}
        {...props}>
        {isTooltipAvailable && (
          <TooltipCard
            css={css`
              width: auto;
              position: absolute;
              z-index: 1;
              ${tooltipPosition !== null &&
              `transform: translateY(${tooltipPosition.y - (isTooltipLocatedBottom ? -13 : 36)}px)
                       translateX(${tooltipPosition.x - 23}px);`}
              opacity: ${tooltipPosition !== null ? 1 : 0};
              transition: opacity 0.2s ease;
            `}
            arrowAlign="center"
            arrowDirection={isTooltipLocatedBottom ? 'block-start' : 'block-end'}
            color="black"
            size="small"
            content={`Goal ${goalScore}`}
          />
        )}
        <div
          css={css`
            position: absolute;
            top: 8px;
            right: 0;
            bottom: 0;
            left: 8px;

            height: ${CHART_SIZE - RING_WIDTH * 2}px;
            width: ${CHART_SIZE - RING_WIDTH * 2}px;
            border-radius: 50%;
            background: linear-gradient(${COLOR_SANTA_BC_ALPHA(0)}, ${COLOR_SANTA_BC_ALPHA(0.15)});
            display: flex;
            flex-direction: column;
            align-items: center;
          `}>
          <Typography
            variant="caption2"
            fontWeight="regular"
            color={COLOR_SANTA_H}
            css={css`
              margin-top: 28px;
            `}>
            {title}
          </Typography>
          <Typography variant="h3" fontWeight="regular" color={COLOR_SANTA_BE}>
            {isScoreHidden ? '?' : totalScore}
          </Typography>
          <div
            css={css`
              display: flex;
            `}>
            {segments.map((partial, index) => {
              return (
                <React.Fragment key={partial.name}>
                  <PartScore
                    name={partial.shortName}
                    score={isScoreHidden ? '-' : `${partial.score}${isPercent ? '%' : ''}`}
                    size={segments.length === 2 ? 'large' : 'small'}
                  />
                  {segments.length === 2 && index !== segments.length - 1 && (
                    <div
                      css={css`
                        width: 0;
                        border-left: 1px solid ${COLOR_SANTA_E};
                        margin-top: 13px;
                        height: 32px;
                      `}
                    />
                  )}
                </React.Fragment>
              );
            })}
          </div>
        </div>
        <PieChart width={CHART_SIZE} height={CHART_SIZE}>
          <Pie
            data={targetData}
            dataKey="value"
            cx="50%"
            cy="50%"
            innerRadius={CHART_SIZE / 2 - RING_WIDTH}
            outerRadius={CHART_SIZE / 2}
            startAngle={90}
            endAngle={-270}
            paddingAngle={0}
            onAnimationEnd={handleAnimationEnd}
            blendStroke>
            {targetData.map((entry, index) => (
              <Cell
                key={`target-${index}`}
                ref={entry.ref}
                fill={entry.color}
                opacity={entry.opacity ?? 1}
                strokeOpacity={0}
              />
            ))}
          </Pie>
        </PieChart>
      </div>
    </>
  );
};

export default React.memo(TotalScoreGraph);
