import {useAtomValue} from 'jotai';
import {learningStore} from '@app/test-preparation/atoms/stores';
import {useHighlightToolBoxContext} from '@app/test-preparation/contexts/HighlightToolBoxContext';
import {useTestprepConfigContext} from '@app/test-preparation/contexts/TestprepConfigContext';
import useFeatureAvailableByDomain from '@app/test-preparation/hooks/feature-available/useFeatureAvailableByDomain';

export function useIsHighlightable(): boolean {
  const isHighlightAvailableByDomain = useFeatureAvailableByDomain('highlight');

  const isCompleted = useAtomValue(learningStore.cis.isCompletedAtom);

  return isHighlightAvailableByDomain && isCompleted;
}

export function useIsHighlightEnabled(): boolean {
  const {switchable} = useTestprepConfigContext();
  const HighlightToolboxContext = useHighlightToolBoxContext();

  const isHighlightEnabled = switchable.getIsSwitchableActionEnabled('HIGHLIGHT');
  const isHighlightToolboxContextProvided = Boolean(HighlightToolboxContext);

  return isHighlightEnabled && isHighlightToolboxContextProvided;
}
