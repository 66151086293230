import {atom} from 'jotai';

import {cisRepository} from '@app/test-preparation/atoms/repositories';
import {cisService} from '@app/test-preparation/atoms/services';
import {handleUnexpectedError} from '@app/test-preparation/atoms/services/shared/errors/unexpected';

const loadCurrentCisesAtom = atom(null, async (get, set) => {
  const listCises = await get(cisRepository.listCisesAtom);

  const learningCellId = get(cisService.learningCellIdAtom);

  try {
    const ciscs = await listCises(learningCellId);

    const nextContentDataMap = ciscs.reduce(
      (contentDataMap, {contentUrl}, index) => ({
        ...contentDataMap,
        [index]: {
          url: contentUrl,
        },
      }),
      {}
    );

    set(cisService.contentDataMapAtom, nextContentDataMap);

    const nextCises = ciscs.map(({contentInteractionState: cis}) => cis);

    set(cisService.cisesAtom, nextCises);
  } catch (error) {
    handleUnexpectedError(error);
  }
});

export {loadCurrentCisesAtom};
