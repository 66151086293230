import React, {useCallback} from 'react';

import {useDialogContext} from '@app/contexts/DialogContext';
import InvalidUserBottomSheet from '@app/features/auth/components/InvalidUserBottomSheet';

interface IInvalidUserBottomSheet {
  openBottomSheet: (onClick?: () => void) => void;
}

export default function useInvalidUserBottomSheet(): IInvalidUserBottomSheet {
  const {openDialog, closeDialog} = useDialogContext();

  const openBottomSheet = useCallback(
    (onSignupClick?: React.EventHandler<React.MouseEvent>) => {
      const handleSignupClick: React.EventHandler<React.MouseEvent> = e => {
        onSignupClick?.(e);
        closeDialog();
      };
      openDialog(<InvalidUserBottomSheet onSignupClick={handleSignupClick} onCloseClick={closeDialog} />);
    },
    [openDialog, closeDialog]
  );

  return {
    openBottomSheet,
  };
}
