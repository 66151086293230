/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { MarketingBannerListFromJSON, MarketingBannerListToJSON, } from './MarketingBannerList';
/**
 * Check if a given object implements the GetActiveMarketingBannerListResponse interface.
 */
export function instanceOfGetActiveMarketingBannerListResponse(value) {
    let isInstance = true;
    return isInstance;
}
export function GetActiveMarketingBannerListResponseFromJSON(json) {
    return GetActiveMarketingBannerListResponseFromJSONTyped(json, false);
}
export function GetActiveMarketingBannerListResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'active': !exists(json, 'active') ? undefined : MarketingBannerListFromJSON(json['active']),
    };
}
export function GetActiveMarketingBannerListResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'active': MarketingBannerListToJSON(value.active),
    };
}
