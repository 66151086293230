import React from 'react';
import {css} from '@emotion/react';
import {ListLayout, OfferCard} from '@app/features/offer/components';
import {OfferGroupDetail} from '@app/features/offer/views';

type OfferListPageViewProps = {
  onGoBack: React.MouseEventHandler<HTMLButtonElement>;
  offerCards: OfferGroupDetail['offerCards'];
};

const OfferListPageView = ({offerCards, onGoBack}: OfferListPageViewProps) => {
  return (
    <ListLayout onGoBack={onGoBack}>
      <div
        css={css`
          display: grid;
          gap: 12px;
        `}>
        {offerCards.map(offerCard => (
          <OfferCard key={offerCard.id} {...offerCard} hasOfferOptions={false} />
        ))}
      </div>
    </ListLayout>
  );
};

export default OfferListPageView;
export type {OfferListPageViewProps};
OfferListPageView.displayName = 'OfferListPageView';
