import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';

import {BottomSheetProps, Button} from '@santa-web/service-ui';
import BottomSheetWithDim, {BottomSheetWithDimProps} from '@app/components/BottomSheetWithDim';
import {SelfLearningContentType} from '@app/constants/content-type';
import {ALERT_DIALOG_Z_INDEX} from '@app/constants/zIndex';
import {useDialogContext} from '@app/contexts/DialogContext';

export interface NotGrantedBottomSheetWithDimProps extends BottomSheetWithDimProps {
  contentType?: SelfLearningContentType;
  onButtonClick?(): void;
}

export default React.memo<NotGrantedBottomSheetWithDimProps>(
  React.forwardRef<HTMLDivElement, NotGrantedBottomSheetWithDimProps>(function NotGrantedBottomSheetWithDim(
    props,
    ref
  ) {
    const {contentType, onButtonClick, ...restProps} = props;

    const {t} = useTranslation();
    const {closeDialog} = useDialogContext();

    const onBottomSheetButtonClick = React.useCallback(() => {
      closeDialog();
      onButtonClick?.();
    }, [closeDialog, onButtonClick]);

    const BottomSheetProps = React.useMemo(
      (): BottomSheetProps => ({
        title:
          contentType === 'virtual-exam'
            ? t('bottom_sheet_learning_authority_title_virtual_exam')
            : t('bottom_sheet_learning_authority_title'),
        description: t('bottom_sheet_learning_authority_description'),
        isLoadingAiAnimationVisible: true,
        buttons: [
          <Button key={1} onClick={onBottomSheetButtonClick} variant="solid" colorVariant="brand" isFullWidth>
            {contentType === 'virtual-exam'
              ? t('bottom_sheet_learning_authority_button_positive_label_virtual_exam')
              : t('bottom_sheet_learning_authority_button_positive_label')}
          </Button>,
          <Button key={2} onClick={closeDialog} variant="solid" colorVariant="grey" isFullWidth>
            {t('bottom_sheet_learning_authority_button_negative_label')}
          </Button>,
        ],
        onCloseClick: closeDialog,
      }),
      [onBottomSheetButtonClick, closeDialog, t, contentType]
    );

    return (
      <BottomSheetWithDim
        ref={ref}
        css={css`
          z-index: ${ALERT_DIALOG_Z_INDEX};
        `}
        isOpened
        BottomSheetProps={BottomSheetProps}
        {...restProps}
      />
    );
  })
);
