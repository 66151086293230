import {useRouter} from 'next/router';
import React from 'react';
import {Form} from 'react-final-form';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {COLOR_SANTA_I} from '@riiid/design-system';
import {useAtomValue} from 'jotai';

import isEmail from 'validator/lib/isEmail';
import {LoginWithEmailPasswordBadRequestErrorCase} from '@santa-web/gen/open-api/service';
import {Typography} from '@santa-web/service-ui';
import santaAuthorizedOpenapiServicesAtom from '@app/atoms/core/santa-authorized-openapi-services';
import tokenManagerAtom from '@app/atoms/core/token-manager';
import ButtonList from '@app/components/ButtonList';
import TextField from '@app/components/final-form/TextField';
import FullWidthSolidButton from '@app/components/FullWidthSolidButton';
import LinkButton from '@app/components/LinkButton';
import LoadableButton from '@app/components/LoadableButton';
import SingleColumn from '@app/components/SingleColumn';
import {useCommonValidator} from '@app/features/auth/hooks';
import {EmailLoginFormValues} from '@app/features/auth/types';
import {OnboardingLayout} from '@app/features/onboarding';
import {
  redirect,
  isB2bState,
  isRedirectionState,
  useQueryState,
  useGoToB2bOfferGroupOrderPage,
} from '@app/misc/query-state';
import {OnSubmit} from '@app/types/form';
import {SantaResponseError} from '@app/utils/error';

const initialValues: Partial<EmailLoginFormValues> = {
  email: '',
  password: '',
};

const EmailLoginPageContainer = () => {
  const {AuthService} = useAtomValue(santaAuthorizedOpenapiServicesAtom);
  const tokenManager = useAtomValue(tokenManagerAtom);
  const router = useRouter();
  const queryState = useQueryState();
  const {t} = useTranslation();
  const goToB2bOfferGroupOrderPage = useGoToB2bOfferGroupOrderPage();

  const commonValidator = useCommonValidator();

  const handleSubmit: OnSubmit<EmailLoginFormValues> = React.useCallback(
    async values => {
      try {
        const {userToken} = await AuthService.loginWithEmailPassword({
          loginWithEmailPasswordRequest: {
            emailPasswordRegistrationInfo: {
              email: values.email ?? '',
              password: values.password ?? '',
            },
          },
        });
        await tokenManager.setToken(userToken);
        if (isB2bState(queryState)) {
          await goToB2bOfferGroupOrderPage(queryState);
        } else if (isRedirectionState(queryState)) {
          await redirect(router, queryState);
        } else {
          await router.push('/');
        }
      } catch (e) {
        if (e instanceof SantaResponseError) {
          if (e.santaErrorCode === LoginWithEmailPasswordBadRequestErrorCase.NOT_EXISTS) {
            return {email: t('sign_error_email_not_exist')};
          }
          if (e.santaErrorCode === LoginWithEmailPasswordBadRequestErrorCase.WRONG_PASSWORD) {
            return {password: t('sign_error_password_wrong')};
          }
        }
      }
    },
    [AuthService, tokenManager, queryState, goToB2bOfferGroupOrderPage, router, t]
  );

  return (
    <OnboardingLayout isNarrow>
      <Form<EmailLoginFormValues>
        onSubmit={handleSubmit}
        initialValues={initialValues}
        validate={({email}) => {
          if (email && !isEmail(email)) {
            return {email: t('sign_error_email_invalid_format')};
          }
        }}>
        {({handleSubmit, submitting, hasValidationErrors}) => {
          const isLoading = submitting;
          const isDisabled = hasValidationErrors || isLoading;

          return (
            <form
              css={css`
                height: 100%;
              `}
              onSubmit={handleSubmit}>
              <div
                css={css`
                  display: flex;
                  flex-direction: column;
                  justify-content: space-between;
                  flex: 1;
                  height: 100%;
                `}>
                <SingleColumn gap={40}>
                  <Typography variant="caption1" fontWeight="bold" color={COLOR_SANTA_I}>
                    {t('page_signin_email_sub_title')}
                  </Typography>
                  <TextField
                    type="text"
                    name="email"
                    validate={commonValidator.required}
                    label={t('component_sign_form_email_label')}
                    hint={t('component_sign_form_email_hint')}
                  />
                  <TextField
                    type="password"
                    name="password"
                    validate={commonValidator.required}
                    label={t('component_sign_form_password_label')}
                    hint={t('component_signin_form_password_hint')}
                  />
                </SingleColumn>
                <ButtonList
                  css={css`
                    margin-top: 40px;
                  `}>
                  <LoadableButton
                    as={FullWidthSolidButton}
                    isLoading={isLoading}
                    isDisabled={isDisabled}
                    colorVariant="brand"
                    loadingAnimationVariant="gray">
                    {t('sign_in')}
                  </LoadableButton>
                  <LinkButton isFullWidth variant="solid" colorVariant="transparent" href="/forgot-password">
                    {t('find_password')}
                  </LinkButton>
                </ButtonList>
              </div>
            </form>
          );
        }}
      </Form>
    </OnboardingLayout>
  );
};

export default EmailLoginPageContainer;
