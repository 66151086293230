import {useQuery} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';
import {Order} from '@santa-web/gen/open-api/service';
import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {getOfferGroupOrderQueryKey} from '@app/queryKeys';

export const useOfferGroupOrderQuery = (id: number) => {
  const {OrderService} = useAtomValue(santaOpenapiServicesAtom);
  return useQuery<Order>({
    queryKey: getOfferGroupOrderQueryKey(id),
    queryFn: async () => {
      return await OrderService.getOrder({id}).then(({order}) => order);
    },
  });
};
