/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { PassSettingFromJSON, PassSettingToJSON, } from './PassSetting';
import { TicketSettingsFromJSON, TicketSettingsToJSON, } from './TicketSettings';
/**
 * Check if a given object implements the PermitConfig interface.
 */
export function instanceOfPermitConfig(value) {
    let isInstance = true;
    return isInstance;
}
export function PermitConfigFromJSON(json) {
    return PermitConfigFromJSONTyped(json, false);
}
export function PermitConfigFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'passSettings': !exists(json, 'passSettings') ? undefined : (json['passSettings'].map(PassSettingFromJSON)),
        'ticketSettings': !exists(json, 'ticketSettings') ? undefined : (json['ticketSettings'].map(TicketSettingsFromJSON)),
    };
}
export function PermitConfigToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'passSettings': value.passSettings === undefined ? undefined : (value.passSettings.map(PassSettingToJSON)),
        'ticketSettings': value.ticketSettings === undefined ? undefined : (value.ticketSettings.map(TicketSettingsToJSON)),
    };
}
