import React from 'react';

import {css} from '@emotion/react';
import {COLOR_SANTA_A} from '@riiid/design-system';
import {ALERT_DIALOG_Z_INDEX} from '@app/constants/zIndex';
import {useDialogContext} from '@app/contexts/DialogContext';
import {LanguageSelectionView, USER_LANGUAGE_TEXT_MAP} from '@app/features/onboarding';

const useLanguageSelectionModal = () => {
  const {openDialog, closeDialog} = useDialogContext();
  const openLanguageSelectionModal = React.useCallback(
    (currentLanguageKey: string, onLanguageChange: (languageKey: string) => Promise<void>) => {
      openDialog(
        <LanguageSelectionView
          css={css`
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            z-index: ${ALERT_DIALOG_Z_INDEX};
            background-color: ${COLOR_SANTA_A};
          `}
          currentLanguageKey={currentLanguageKey}
          languageMap={USER_LANGUAGE_TEXT_MAP}
          onClose={closeDialog}
          onConfirm={async args => {
            await onLanguageChange(args);
            closeDialog();
          }}
        />
      );
    },
    [openDialog, closeDialog]
  );
  return {
    openLanguageSelectionModal: openLanguageSelectionModal,
  };
};

export default useLanguageSelectionModal;
