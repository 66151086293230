import React from 'react';
import {useAtomValue} from 'jotai';

import {learningStore} from '@app/test-preparation/atoms/stores';
import IsPreviewContext from '@app/test-preparation/contexts/IsPreviewContext';
import {useTestprepConfigContext} from '@app/test-preparation/contexts/TestprepConfigContext';

export function useIsExplanationInvisible(): boolean {
  const isDiagnosis = useAtomValue(learningStore.cell.isDiagnosisAtom);

  return isDiagnosis;
}

export function useIsLearningCell(): boolean {
  try {
    useAtomValue(learningStore.cell.idAtom);
    return true;
  } catch (error) {
    return false;
  }
}

export function useCanSelectUnsolvableQuestion(): boolean {
  const {switchable} = useTestprepConfigContext();

  const isUnsolvableQuestionEnabled = switchable.getIsSwitchableActionEnabled('UNSOLVABLE_QUESTION');

  const isQuestionSet = useAtomValue(learningStore.cell.isQuestionSetAtom);
  const isQuestionStream = useAtomValue(learningStore.cell.isQuestionStreamAtom);
  const isDiagnosis = useAtomValue(learningStore.cell.isDiagnosisAtom);
  const isMyNoteQuiz = useAtomValue(learningStore.cell.isMyNoteQuizAtom);
  const isMockExam = useAtomValue(learningStore.cell.isMockExamAtom);

  const isQuestion = isQuestionSet || isQuestionStream;
  // https://www.notion.so/riiid/869f358301b14113a767374c370e02c3?pvs=4#a44ea143beff42898e6f1a94f5b5b90f
  const isExamLikeCell = isDiagnosis || isMyNoteQuiz || isMockExam;

  return isUnsolvableQuestionEnabled && isQuestion && !isExamLikeCell;
}

export function useIsPreview(): boolean {
  return React.useContext(IsPreviewContext);
}
