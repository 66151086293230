import {useTranslation} from 'react-i18next';
import {AlertDialog, Typography} from '@santa-web/service-ui';

type DeleteConfirmDialogProps = {
  isVisible: boolean;
  onCancel: () => void;
  onDelete: () => void;
};

const DeleteConfirmDialog = ({isVisible, onCancel, onDelete}: DeleteConfirmDialogProps) => {
  const {t} = useTranslation();
  return (
    <AlertDialog
      isVisible={isVisible}
      AlertProps={{
        description: (
          <Typography variant="body1" fontWeight="bold">
            {t(`page_exam_score_delete_alert_title`)}
          </Typography>
        ),
        negativeLabel: t('dict_cancel'),
        onNegativeClick: onCancel,
        positiveLabel: t('highlight_toolbox_delete_label'),
        onPositiveClick: onDelete,
      }}
    />
  );
};

export default DeleteConfirmDialog;
export type {DeleteConfirmDialogProps};
DeleteConfirmDialog.displayName = 'DeleteConfirmDialog';
