import React from 'react';
import {css} from '@emotion/react';
import {Column, SkeletonGrayBox} from '@app/components';
import {CouponBaseCard} from '@app/features/coupon';

const CouponCardSkeleton = () => {
  return (
    <CouponBaseCard
      css={css`
        padding-top: 40px;
        padding-bottom: 40px;
      `}>
      <Column gap={24}>
        <SkeletonGrayBox height={20} width={100} />

        <Column gap={12}>
          <SkeletonGrayBox height={12} width="100%" />
          <SkeletonGrayBox height={12} width="100%" />
        </Column>
      </Column>
    </CouponBaseCard>
  );
};

export default CouponCardSkeleton;
CouponCardSkeleton.displayName = 'CouponCardSkeleton';
