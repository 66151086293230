import { ImageSetFromJSON, ImageSetToJSON, } from './ImageSet';
import { ThumbnailContentImageSetAlignFromJSON, ThumbnailContentImageSetAlignToJSON, } from './ThumbnailContentImageSetAlign';
/**
 * Check if a given object implements the OrderOfferGroupDisplayConfig interface.
 */
export function instanceOfOrderOfferGroupDisplayConfig(value) {
    let isInstance = true;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "thumbnailBgImageSet" in value;
    isInstance = isInstance && "thumbnailContentImageSet" in value;
    isInstance = isInstance && "thumbnailContentImageSetAlign" in value;
    return isInstance;
}
export function OrderOfferGroupDisplayConfigFromJSON(json) {
    return OrderOfferGroupDisplayConfigFromJSONTyped(json, false);
}
export function OrderOfferGroupDisplayConfigFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'name': json['name'],
        'thumbnailBgImageSet': ImageSetFromJSON(json['thumbnailBgImageSet']),
        'thumbnailContentImageSet': ImageSetFromJSON(json['thumbnailContentImageSet']),
        'thumbnailContentImageSetAlign': ThumbnailContentImageSetAlignFromJSON(json['thumbnailContentImageSetAlign']),
    };
}
export function OrderOfferGroupDisplayConfigToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'name': value.name,
        'thumbnailBgImageSet': ImageSetToJSON(value.thumbnailBgImageSet),
        'thumbnailContentImageSet': ImageSetToJSON(value.thumbnailContentImageSet),
        'thumbnailContentImageSetAlign': ThumbnailContentImageSetAlignToJSON(value.thumbnailContentImageSetAlign),
    };
}
