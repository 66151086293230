import { OfferGroupFromJSON, OfferGroupToJSON, } from './OfferGroup';
/**
 * Check if a given object implements the GetOfferGroupResponse interface.
 */
export function instanceOfGetOfferGroupResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "offerGroup" in value;
    return isInstance;
}
export function GetOfferGroupResponseFromJSON(json) {
    return GetOfferGroupResponseFromJSONTyped(json, false);
}
export function GetOfferGroupResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'offerGroup': OfferGroupFromJSON(json['offerGroup']),
    };
}
export function GetOfferGroupResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'offerGroup': OfferGroupToJSON(value.offerGroup),
    };
}
