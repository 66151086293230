/* tslint:disable */
/* eslint-disable */
/**
 * - **LEARNING_DOMAIN_NOT_AVAILABLE**: 학습 도메인이 사용 불가능한 경우
 * - **PROFILE_ALREADY_EXISTS**: 러닝 도메인에 대응하는 프로필이 이미 존재하는 경우
 * @export
 */
export const CreateMyProfileErrorCase = {
    LEARNING_DOMAIN_NOT_AVAILABLE: 'LEARNING_DOMAIN_NOT_AVAILABLE',
    PROFILE_ALREADY_EXISTS: 'PROFILE_ALREADY_EXISTS'
};
export function CreateMyProfileErrorCaseFromJSON(json) {
    return CreateMyProfileErrorCaseFromJSONTyped(json, false);
}
export function CreateMyProfileErrorCaseFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function CreateMyProfileErrorCaseToJSON(value) {
    return value;
}
