import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';

import {LearningCellReport} from '@santa-web/gen/open-api/content-learning-service';
import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {getLearningCellReportQueryKey} from '@app/queryKeys';

const useLearningCellReportQuery = (learningCellId: number): UseQueryResult<LearningCellReport> => {
  const {LearningCellReportService} = useAtomValue(santaOpenapiServicesAtom);

  return useQuery(getLearningCellReportQueryKey(learningCellId), async () => {
    const {learningCellReport} = await LearningCellReportService.getLearningCellReport({learningCellId});
    return learningCellReport;
  });
};

export default useLearningCellReportQuery;
