import React from 'react';
import {Trans} from 'react-i18next';
import {css} from '@emotion/react';
import {
  COLOR_FIX_SANTA_BLACK_1_ALPHA,
  COLOR_FIX_SANTA_GRAY_9,
  COLOR_FIX_SANTA_WHITE_1,
  COLOR_FIX_SANTA_WHITE_1_ALPHA,
  COLOR_SANTA_DC_ALPHA,
} from '@riiid/design-system';

import {Typography} from '@santa-web/service-ui';
import {formatOfferGroupRemainedTime} from '@app/features/offer/utils';
import exhaustiveCheck from '@app/utils/exhaustiveCheck';

export interface TimerProps {
  colorVariant: 'RED' | 'BLACK' | 'WHITE';
  remainedTimeMs: number;
}

const Timer = ({colorVariant, remainedTimeMs, ...props}: TimerProps): JSX.Element => {
  const {backgroundColor, typographyColor} = React.useMemo(() => {
    switch (colorVariant) {
      case 'BLACK': {
        return {
          backgroundColor: COLOR_FIX_SANTA_BLACK_1_ALPHA(0.3),
          typographyColor: COLOR_FIX_SANTA_WHITE_1,
        };
      }
      case 'WHITE': {
        return {
          backgroundColor: COLOR_FIX_SANTA_WHITE_1_ALPHA(0.3),
          typographyColor: COLOR_FIX_SANTA_GRAY_9,
        };
      }
      case 'RED': {
        return {
          backgroundColor: COLOR_SANTA_DC_ALPHA(0.6),
          typographyColor: COLOR_FIX_SANTA_WHITE_1,
        };
      }
      default:
        exhaustiveCheck(colorVariant);
    }
  }, [colorVariant]);

  return (
    <div
      css={css`
        width: 100%;
        height: 28px;
        background-color: ${backgroundColor};
        text-align: center;
      `}
      {...props}>
      <Typography variant="caption2" fontWeight="regular" color={typographyColor}>
        <Trans
          i18nKey="component_offer_group_card_timer_label"
          values={{time: formatOfferGroupRemainedTime(remainedTimeMs)}}
          components={{
            b: (
              <Typography
                css={css`
                  margin-left: 8px;
                `}
                variant="caption1"
                fontWeight="bold"
                component="span"
                color={typographyColor}
              />
            ),
          }}
        />
      </Typography>
    </div>
  );
};

export default React.memo(Timer);
