import React from 'react';
import {useTranslation} from 'react-i18next';

import TabButton from '@app/components/TabButton';
import TabButtonList from '@app/components/TabButtonList';
import {VocaResultType} from '@app/pages/learning-cell/report/LearningCellReportContentsView';

export interface VocaQuestionSwitchProps extends React.HTMLAttributes<HTMLDivElement> {
  vocaResultType: VocaResultType;
  onClickVocaResultVocas: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  onClickVocaResultQuestions: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

const VocaQuestionSwitch = React.forwardRef<HTMLDivElement, VocaQuestionSwitchProps>(
  ({vocaResultType, onClickVocaResultVocas, onClickVocaResultQuestions, ...props}, ref) => {
    const {t} = useTranslation();

    return (
      <TabButtonList ref={ref} {...props}>
        <TabButton onClick={onClickVocaResultVocas} isSelected={vocaResultType === 'vocas'}>
          {t('learning_cell_report_word')}
        </TabButton>
        <TabButton onClick={onClickVocaResultQuestions} isSelected={vocaResultType === 'questions'}>
          {t('learning_cell_report_question')}
        </TabButton>
      </TabButtonList>
    );
  }
);

export default React.memo(VocaQuestionSwitch);
