import React, {useCallback} from 'react';
import {css} from '@emotion/react';
import {COLOR_SANTA_BD, COLOR_SANTA_DD} from '@riiid/design-system';

import {
  QuestionState,
  QuestionStateObjective,
  QuestionStateSubjective,
} from '@santa-web/gen/open-api/content-learning-service';
import {Question} from '@santa-web/gen/open-api/content/models/Question';
import {Snippet} from '@santa-web/gen/open-api/content/models/Snippet';
import {ObjectiveQuestionPage, SentenceCompletionPage, Typography} from '@santa-web/service-ui';
import ContentsLevelInformationContainer from '@app/experiments/contents-level-choice-rate/containers/ContentsLevelInformationContainer';
import useIsContentLevelChoiceRateABTestTarget from '@app/experiments/contents-level-choice-rate/hooks/useIsContentLevelChoiceRateABTestTarget';
import * as ObjectiveStateFacade from '@app/facade/question-state-objective';
import * as SubjectiveStateFacade from '@app/facade/question-state-subjective';
import ComponentObserveTimer from '@app/test-preparation/components/ComponentObserveTimer';
import ObjectiveView from '@app/test-preparation/components/ObjectiveView';
import SnippetView from '@app/test-preparation/components/SnippetView';
import SubjectiveView from '@app/test-preparation/components/SubjectiveView';
import {useObservingTimeContext} from '@app/test-preparation/contexts/ObservingTimeContext';

export type ChoiceAnswerHandler = (payload: {question_id: string; user_choice: number; answer: number}) => void;

type QuestionWithOptionalDescription = Omit<Question, 'description'> & {description: Snippet | undefined};

interface Props {
  isCompleted: boolean;
  contentId: string;
  elementInteractionStateId?: string;
  questionTitle: React.ReactNode;
  isDisabled?: boolean;
  isExplanationInvisible?: boolean;
  isPassageInvisible?: boolean;
  question?: QuestionWithOptionalDescription | null;
  questionState?: QuestionState;
  questionIndex?: number;
  onChange?(questionState: Partial<QuestionState>): void;
  canSelectUnsolvableQuestion: boolean;
  onChoiceAnswer?: ChoiceAnswerHandler;
}

const QuestionView = ({
  isCompleted: isQuestionStateCompleted,
  contentId,
  elementInteractionStateId,
  isDisabled,
  question,
  questionState,
  questionTitle,
  questionIndex,
  onChange,
  isExplanationInvisible,
  isPassageInvisible,
  canSelectUnsolvableQuestion,
  onChoiceAnswer,
}: Props): React.ReactElement | null => {
  const isContentsLevelInformationAvailable = useIsContentLevelChoiceRateABTestTarget();
  const observingTimeContext = useObservingTimeContext();

  const handleObjectiveChange = useCallback(
    (objectiveState: QuestionStateObjective) => {
      onChange?.({
        ...questionState,
        answerState: objectiveState.userAnswer.includes(-1)
          ? 'DOES_NOT_KNOW'
          : ObjectiveStateFacade.getIsCorrect(objectiveState, question?.oneOfTypeSpec.objective)
          ? 'CORRECT'
          : 'INCORRECT',
        details: {
          oneOfCase: 'OBJECTIVE',
          objective: objectiveState,
        },
      });
    },
    [onChange, question?.oneOfTypeSpec.objective, questionState]
  );

  const handleSubjectiveChange = useCallback(
    (subjectiveState: QuestionStateSubjective) => {
      onChange?.({
        ...questionState,
        answerState: SubjectiveStateFacade.getIsCorrect(subjectiveState, question?.oneOfTypeSpec.subjective)
          ? 'CORRECT'
          : 'INCORRECT',
        details: {
          oneOfCase: 'SUBJECTIVE',
          subjective: subjectiveState,
        },
      });
    },
    [onChange, question?.oneOfTypeSpec.subjective, questionState]
  );

  const isCompleted = !isExplanationInvisible && Boolean(isQuestionStateCompleted);
  const isCorrect = questionState?.answerState === 'CORRECT';

  React.useEffect(() => {
    observingTimeContext?.setIsAvailable(isCompleted);
  }, [isCompleted, observingTimeContext]);

  const isComponentObserverTimerAvailable = observingTimeContext !== null;

  const explanations = isCompleted && (
    <ComponentObserveTimer
      isAvailable={isComponentObserverTimerAvailable}
      questionIndex={questionIndex ?? 0}
      componentName="qs">
      <SnippetView
        snippet={question?.oneOfExplanationType.explanation ?? null}
        id={`explanation.0.${elementInteractionStateId}`}
      />
    </ComponentObserveTimer>
  );

  const descriptionTranslation = question?.descriptionTranslation;
  const passagesView = !isPassageInvisible ? (
    <div>
      <SnippetView snippet={question?.description} id={`passage.0.${elementInteractionStateId}`} />
      {isCompleted && descriptionTranslation && (
        <div
          css={css`
            margin-top: 16px;
          `}>
          <SnippetView snippet={descriptionTranslation} id={`passage_translation.0.${elementInteractionStateId}`} />
        </div>
      )}
    </div>
  ) : (
    <></>
  );

  const passages = isCompleted ? (
    <ComponentObserveTimer
      isAvailable={isComponentObserverTimerAvailable}
      questionIndex={questionIndex}
      componentName="question">
      {passagesView}
    </ComponentObserveTimer>
  ) : (
    passagesView
  );

  if (
    question?.oneOfTypeSpec.objective !== undefined &&
    // undefined는 컨텐츠 프리뷰를 위해 존재하는 조건임
    (questionState?.details.oneOfCase === 'OBJECTIVE' || questionState?.details === undefined)
  ) {
    const handleInteract = ({userChoice}: {userChoice: number}) => {
      onChoiceAnswer?.({
        user_choice: userChoice,
        question_id: question.id,
        answer: questionState?.details.objective ? questionState.details.objective.correctAnswer[0] : -1,
      });
    };

    const objectiveView = (
      <ObjectiveView
        isCompleted={isCompleted}
        isDisabled={isDisabled}
        contentId={contentId}
        snippetViewId={`objective.0.${elementInteractionStateId}`}
        objective={question.oneOfTypeSpec.objective}
        questionStateObjective={questionState?.details.objective}
        onChange={handleObjectiveChange}
        canSelectUnsolvableQuestion={canSelectUnsolvableQuestion}
        onInteract={handleInteract}
        questionIndex={questionIndex}
      />
    );
    const objective = isCompleted ? (
      <ComponentObserveTimer
        isAvailable={isComponentObserverTimerAvailable}
        questionIndex={questionIndex}
        componentName="options">
        {objectiveView}
      </ComponentObserveTimer>
    ) : (
      objectiveView
    );
    return (
      <ObjectiveQuestionPage
        questionTitle={
          isContentsLevelInformationAvailable ? (
            <div>
              <ContentsLevelInformationContainer questionIndex={questionIndex ?? 0} />
              {questionTitle}
            </div>
          ) : (
            questionTitle
          )
        }
        passages={passages}
        objective={objective}
        explanations={explanations}
      />
    );
  }

  if (
    question?.oneOfTypeSpec.subjective !== undefined &&
    // undefined는 컨텐츠 프리뷰를 위해 존재하는 조건임
    (questionState?.details.oneOfCase === 'SUBJECTIVE' || questionState?.details === undefined)
  ) {
    const subjective = (
      <SubjectiveView
        isCompleted={isCompleted}
        isDisabled={isDisabled}
        subjective={question.oneOfTypeSpec.subjective}
        questionStateSubjective={questionState?.details.subjective}
        onChange={handleSubjectiveChange}
      />
    );

    return (
      <SentenceCompletionPage
        questionTitle={
          isContentsLevelInformationAvailable ? (
            <div>
              <ContentsLevelInformationContainer questionIndex={questionIndex ?? 0} />
              {questionTitle}
            </div>
          ) : (
            questionTitle
          )
        }
        description={passages}
        subjective={subjective}
        explanations={
          isCompleted && (
            <Typography color={isCorrect ? COLOR_SANTA_BD : COLOR_SANTA_DD} fontWeight="regular" variant="body3">
              {question.oneOfTypeSpec.subjective.allowedAnswers[0]}
            </Typography>
          )
        }
      />
    );
  }

  console.log('null');

  return null;
};

export default React.memo(QuestionView);
