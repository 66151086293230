import {atom} from 'jotai';
import {setAtomLabels} from '@app/misc/atom-label';
import {cisService} from '@app/test-preparation/atoms/services';
import {contentRouterFactory} from '@app/test-preparation/atoms/stores/learning/utils/content-router';

const resourceMapAtom = atom(get => get(cisService.resourceMapAtom));

const contentRouterAtom = atom(get => contentRouterFactory.create(get(cisService.contentAtom)));

const idAtom = atom(get => get(cisService.contentAtom).baseContent.contentId);
const domainAtom = atom(get => get(cisService.contentAtom).baseContent.domain);
const localeAtom = atom(get => get(cisService.contentAtom).baseContent.locale);

const dalcAtom = atom(get => get(contentRouterAtom).getMeta('dalc'));
const partsAtom = atom(get => get(contentRouterAtom).getMeta('partList'));
const subjectAtom = atom(get => get(contentRouterAtom).getMeta('subject'));

const questionSetAtom = atom(get => get(contentRouterAtom).getQuestionSet());
const lessonAtom = atom(get => get(contentRouterAtom).getLesson());
const vocabularyAtom = atom(get => get(contentRouterAtom).getVocabulary());

const recommendedElapsedTimeAtom = atom(get => get(contentRouterAtom).getRecommendedElapsedTime());
const guessElapsedTimeAtom = atom(get => get(contentRouterAtom).getGuessElapsedTime());

const isLCAtom = atom(get => get(contentRouterAtom).isLC());
const isBasicQuestionAtom = atom(get => get(contentRouterAtom).isBasicQuestion());

export const content = {
  resourceMapAtom,

  idAtom,
  domainAtom,
  localeAtom,

  dalcAtom,
  partsAtom,
  subjectAtom,

  questionSetAtom,
  lessonAtom,
  vocabularyAtom,

  recommendedElapsedTimeAtom,
  guessElapsedTimeAtom,

  isLCAtom,
  isBasicQuestionAtom,
};

setAtomLabels(
  {
    resourceMapAtom,
    contentRouterAtom,
    idAtom,
    domainAtom,
    localeAtom,
    dalcAtom,
    partsAtom,
    subjectAtom,
    questionSetAtom,
    lessonAtom,
    vocabularyAtom,
    recommendedElapsedTimeAtom,
    guessElapsedTimeAtom,
    isLCAtom,
    isBasicQuestionAtom,
  },
  'learningStore.content.'
);
