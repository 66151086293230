import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {COLOR_SANTA_I, COLOR_SANTA_L, COLOR_SANTA_BD, COLOR_SANTA_DD, COLOR_SANTA_O_ALPHA} from '@riiid/design-system';
import InfoIcon from '@riiid/design-system/icons/Info';

import {TooltipBox, Typography} from '@santa-web/service-ui';
import BaseCard from '@app/components/card/BaseCard';
import Legend from '@app/components/Legend';
import LegendList from '@app/components/LegendList';

import AnalyticsRcTimeGraph from './components/AnalyticsRcTimeGraph';

export interface PartItem {
  elapsedTime: number;
  recommendedTime: number;
  name: string;
}
export interface AnalyticsRcTimeGraphCardProps {
  data: Array<PartItem>;
  title: string;
  tooltipContents: string;
  isScoreHidden?: boolean;
}

const AnalyticsRcTimeGraphCard = ({
  data,
  title,
  tooltipContents,
  isScoreHidden = false,
  ...props
}: AnalyticsRcTimeGraphCardProps): JSX.Element => {
  const {t} = useTranslation();
  return (
    <BaseCard
      css={css`
        padding: 20px 20px 24px;
      `}
      {...props}>
      <TooltipBox direction="bottom-left" size="medium" variant="black" title={tooltipContents}>
        <div
          css={css`
            display: flex;
            align-items: center;
          `}>
          <Typography variant="body2" fontWeight="bold" color={COLOR_SANTA_L}>
            {title}
          </Typography>
          <InfoIcon
            type="line"
            width={20}
            height={20}
            color={COLOR_SANTA_I}
            css={css`
              display: flex;
              margin-left: 4px;
            `}
          />
        </div>
      </TooltipBox>
      <div
        css={[
          css`
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            row-gap: 24px;
            column-gap: 4px;
            margin: 32px 0 24px;
            justify-items: center;
          `,
          data.length === 3 &&
            css`
              grid-template-columns: repeat(3, 1fr);
            `,
          data.length === 4 &&
            css`
              grid-template-rows: repeat(2, 1fr);
            `,
          data.length === 5 &&
            css`
              grid-template-columns: repeat(5, 1fr);
              grid-template-rows: repeat(2, 1fr);
              > div {
                &:nth-of-type(1) {
                  grid-column: 2;
                }
                &:nth-of-type(2) {
                  grid-column: 4;
                }
                &:nth-of-type(3) {
                  grid-column: 1;
                }
                &:nth-of-type(4) {
                  grid-column: 3;
                }
                &:nth-of-type(5) {
                  grid-column: 5;
                }
              }
            `,
        ]}>
        {data.map((subject, i) => (
          <AnalyticsRcTimeGraph
            key={i}
            title={subject.name}
            elapsedTime={subject.elapsedTime}
            recommendedTime={subject.recommendedTime}
            isEmpty={isScoreHidden}
          />
        ))}
      </div>
      <LegendList>
        <Legend
          color={COLOR_SANTA_O_ALPHA(0.2)}
          subject={t('virtual_exam_result_legend_recommended_time')}
          variant="square"
        />
        <Legend color={COLOR_SANTA_BD} subject={t('virtual_exam_result_legend_my_time')} variant="square" />
        <Legend color={COLOR_SANTA_DD} subject={t('virtual_exam_result_legend_over_time')} variant="square" />
      </LegendList>
    </BaseCard>
  );
};

export default React.memo(AnalyticsRcTimeGraphCard);
