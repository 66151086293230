/**
 * Check if a given object implements the OrderPaymentTransactionPlayStore interface.
 */
export function instanceOfOrderPaymentTransactionPlayStore(value) {
    let isInstance = true;
    isInstance = isInstance && "purchaseToken" in value;
    return isInstance;
}
export function OrderPaymentTransactionPlayStoreFromJSON(json) {
    return OrderPaymentTransactionPlayStoreFromJSONTyped(json, false);
}
export function OrderPaymentTransactionPlayStoreFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'purchaseToken': json['purchaseToken'],
    };
}
export function OrderPaymentTransactionPlayStoreToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'purchaseToken': value.purchaseToken,
    };
}
