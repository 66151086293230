import { PointRewardAuthorityStatusFromJSON, PointRewardAuthorityStatusToJSON, } from './PointRewardAuthorityStatus';
/**
 * Check if a given object implements the PointRewardSummary interface.
 */
export function instanceOfPointRewardSummary(value) {
    let isInstance = true;
    isInstance = isInstance && "authorityStatus" in value;
    isInstance = isInstance && "point" in value;
    isInstance = isInstance && "rank" in value;
    return isInstance;
}
export function PointRewardSummaryFromJSON(json) {
    return PointRewardSummaryFromJSONTyped(json, false);
}
export function PointRewardSummaryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'authorityStatus': PointRewardAuthorityStatusFromJSON(json['authorityStatus']),
        'point': json['point'],
        'rank': json['rank'],
    };
}
export function PointRewardSummaryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'authorityStatus': PointRewardAuthorityStatusToJSON(value.authorityStatus),
        'point': value.point,
        'rank': value.rank,
    };
}
