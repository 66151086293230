import React from 'react';
import {css} from '@emotion/react';
import {COLOR_SANTA_I, COLOR_SANTA_J, COLOR_SANTA_L} from '@riiid/design-system';
import {URL_GIF_AIBUTTON_DEFAULT} from '@riiid/design-system/lotties/santa';
import {LottieAnimation, Typography} from '@santa-web/service-ui';

type OnboardingInformationAreaProps = {
  subTitle?: string;
  title: string;
  description: string;
  isNarrow?: boolean;
  shouldShowAiImage?: boolean;
};

const OnboardingInformationArea = ({
  subTitle,
  title,
  description,
  isNarrow = false,
  shouldShowAiImage = false,
}: OnboardingInformationAreaProps) => {
  return (
    <div
      css={css`
        width: 100%;
      `}>
      <div
        css={css`
          width: 100%;
          display: flex;
          gap: 20px;
          margin-bottom: ${isNarrow ? 12 : 24}px;
        `}>
        <div
          css={[
            css`
              flex: 1;
            `,
            shouldShowAiImage &&
              css`
                padding-top: 40px;
              `,
          ]}>
          {subTitle && (
            <Typography
              css={css`
                margin-bottom: ${isNarrow ? 12 : 24}px;
              `}
              component="p"
              variant="caption1"
              fontWeight="bold"
              color={COLOR_SANTA_I}>
              {subTitle}
            </Typography>
          )}
          <Typography
            css={css`
              white-space: pre-line;
            `}
            variant="h5"
            fontWeight="regular"
            color={COLOR_SANTA_L}
            component="h1">
            {title}
          </Typography>
        </div>
        {shouldShowAiImage && (
          <LottieAnimation
            src={URL_GIF_AIBUTTON_DEFAULT}
            width={100}
            height={100}
            css={css`
              width: fit-content;
              border-radius: 9999px;
            `}
          />
        )}
      </div>
      <Typography
        css={css`
          white-space: pre-line;
        `}
        variant="body3"
        color={COLOR_SANTA_J}>
        {description}
      </Typography>
    </div>
  );
};

export default OnboardingInformationArea;
export type {OnboardingInformationAreaProps};
OnboardingInformationArea.displayName = 'OnboardingInformationArea';
