import React from 'react';

import ChatCautionBottomSheetWithDim from '@app/components/bottom-sheet/ChatCautionBottomSheetWithDim';
import {useDialogContext} from '@app/contexts/DialogContext';

interface IChatCautionBottomSheet {
  openChatCautionBottomSheet: ({onStartClick}: {onStartClick: () => void}) => void;
}

export function useChatCautionBottomSheet(): IChatCautionBottomSheet {
  const {openDialog} = useDialogContext();

  const openChatCautionBottomSheet = React.useCallback<IChatCautionBottomSheet['openChatCautionBottomSheet']>(
    ({onStartClick}) => {
      const dialog = <ChatCautionBottomSheetWithDim onStartClick={onStartClick} />;
      openDialog(dialog);
    },
    [openDialog]
  );

  return React.useMemo(() => ({openChatCautionBottomSheet}), [openChatCautionBottomSheet]);
}
