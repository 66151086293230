import {QuestionState} from '@santa-web/gen/open-api/content-learning-service';
import * as QuestionStateObjectiveFacade from '@app/facade/question-state-objective';
import * as QuestionStateSubjectiveFacade from '@app/facade/question-state-subjective';

export const getIsSelected = (questionState: QuestionState | null | undefined): boolean => {
  if (questionState?.details.oneOfCase === 'OBJECTIVE') {
    return QuestionStateObjectiveFacade.getIsSelected(questionState.details.objective);
  }
  if (questionState?.details.oneOfCase === 'SUBJECTIVE') {
    return QuestionStateSubjectiveFacade.getIsSelected(questionState.details.subjective);
  }
  return false;
};
