import {Content as InternalContent} from '@santa-web/gen/open-api/content/models/Content';
import {validateInteger} from '@app/utils/math';

export const BASE_PARTS = ['PART_1', 'PART_2', 'PART_3', 'PART_4', 'PART_5', 'PART_6', 'PART_7'] as const;
export type BasePart = (typeof BASE_PARTS)[number];

export const VIRTUAL_EXAM_PARTS = [...BASE_PARTS, 'PART_ALL'] as const;
export type VirtualExamPart = (typeof VIRTUAL_EXAM_PARTS)[number];

export const BASE_PART_TAGS = ['p1', 'p2', 'p3', 'p4', 'p5', 'p6', 'p7'] as const;
export const basePartTags = ['p1', 'p2', 'p3', 'p4', 'p5', 'p6', 'p7'];
export type BasePartTag = (typeof BASE_PART_TAGS)[number];

export const convertNumberToBasePart = (num: number): BasePart => {
  if (num < 1 || num > 7 || !validateInteger(num))
    throw new Error('Num for convertNumberToBasePart should be integer between 1 and 7.');
  return `PART_${num}` as BasePart;
};

export const convertPartTagToBasePart = (tag: string): BasePart => {
  const partTagRegex = /^p(\d+)/;
  const [, partNum] = tag.match(partTagRegex) ?? [];
  if (!partNum) throw new Error('Part number is not matched!');
  return convertNumberToBasePart(Number.parseInt(partNum));
};

export const convertStringToVirtualExamPart = (str: string): VirtualExamPart => {
  if (VIRTUAL_EXAM_PARTS.indexOf(str as VirtualExamPart) >= 0) return str as VirtualExamPart;
  throw new Error('String for convertStringToVirtualExamPart should seem like the part');
};

export const getPartNumber = (part: BasePart): number => {
  return Number.parseInt(part.slice(5));
};

export const getNextPart = (part: BasePart): BasePart => {
  const partNum = getPartNumber(part);
  if (partNum < 1 || partNum > 7) throw new Error('Part number should be between 1 and 7');

  return convertNumberToBasePart(partNum + 1);
};

export const getContentPart = (contentInfo: InternalContent): BasePart => {
  const contentTags = contentInfo.oneOfDomainContent.toeicContent?.partList ?? [];
  const matchedTag = contentTags.find(tag => basePartTags.includes(tag));
  if (!matchedTag) throw new Error('There is no matched part tag!');
  return convertPartTagToBasePart(matchedTag);
};
