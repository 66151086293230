/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
/**
 * Check if a given object implements the CheckRegisteredCouponResponse interface.
 */
export function instanceOfCheckRegisteredCouponResponse(value) {
    let isInstance = true;
    return isInstance;
}
export function CheckRegisteredCouponResponseFromJSON(json) {
    return CheckRegisteredCouponResponseFromJSONTyped(json, false);
}
export function CheckRegisteredCouponResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'mostRecentRegisteredAt': !exists(json, 'mostRecentRegisteredAt') ? undefined : (new Date(json['mostRecentRegisteredAt'])),
    };
}
export function CheckRegisteredCouponResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'mostRecentRegisteredAt': value.mostRecentRegisteredAt === undefined ? undefined : (value.mostRecentRegisteredAt.toISOString()),
    };
}
