import React from 'react';
import {Portal} from 'react-portal';
import {HTMLMediaState} from 'react-use/lib/factory/createHTMLMediaHook';
import {css} from '@emotion/react';

import {VocaPoS} from '@santa-web/gen/open-api/content/models/VocaPoS';
import {PlayButton} from '@santa-web/service-ui';
import {AllVocaPronunciationClkPayload} from '@app/api/google-tag-manager/vocabulary';
import BottomSheetListItem from '@app/components/BottomSheetListItem';
import BottomSheetListWithDim from '@app/components/BottomSheetListWithDim';
import {BOTTOM_SHEET_Z_INDEX} from '@app/constants/zIndex';

export interface VocabularyPronouncingBottomSheetListProps {
  isOpened: boolean;
  onClickClose(): void;
  state: HTMLMediaState;
  handlePlayClick: (url: string, payload: AllVocaPronunciationClkPayload) => void;
  src: string;
  pronouncingAudioSet: {
    title: string;
    pos: VocaPoS['pos'];
    url: string;
  }[];
}

const VocabularyPronouncingBottomSheetList = ({
  isOpened,
  onClickClose,
  handlePlayClick,
  pronouncingAudioSet,
  state,
  src,
}: VocabularyPronouncingBottomSheetListProps): JSX.Element => {
  return (
    <Portal>
      <BottomSheetListWithDim
        css={css`
          z-index: ${BOTTOM_SHEET_Z_INDEX};
        `}
        isOpened={isOpened}
        BottomSheetListProps={{
          children: pronouncingAudioSet.map((pronounce, index) => {
            const isPlaying = src === pronounce.url && !state.paused;
            return (
              <BottomSheetListItem
                key={index}
                title={pronounce.title}
                isSelected={true}
                endContent={
                  <PlayButton
                    onClick={() => handlePlayClick(pronounce.url, {text_type: 'word', pos_type: pronounce.pos})}
                    variant={isPlaying ? 'pause' : 'play'}
                  />
                }
                css={css`
                  cursor: default;
                  margin-top: 18px;
                `}
              />
            );
          }),
          onCloseClick: onClickClose,
        }}
      />
    </Portal>
  );
};

export default React.memo(VocabularyPronouncingBottomSheetList);
