import React from 'react';
import {css} from '@emotion/react';
import {
  BREAKPOINT_MEDIUM,
  COLOR_FIX_SANTA_WHITE_1,
  COLOR_FIX_SPACE_BLUE_BLACK_1,
  COLOR_FIX_SPACE_BLUE_GRAY_9,
  COLOR_RIIID_GREEN_3,
  COLOR_SPACE_BLUE_BLACK_1,
} from '@riiid/design-system';
import {URL_IMG_ARTICLE_03_01} from '@riiid/design-system/images/santa';
import {Button, TextButton, Typography} from '@santa-web/service-ui';
import {BenefitCard, BenefitLabel, CountdownTimer} from '@app/features/marketing';
import {OfferGroupCard, OfferGroupCardProps} from '@app/features/offer';
import {use100vh} from '@app/hooks/use100vh';

const BrGteLarge = () => (
  <>
    <br
      css={css`
        display: none;
        @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
          display: block;
        }
      `}
    />
    <span
      css={css`
        display: inline;
        @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
          display: none;
        }
      `}>
      {' '}
    </span>
  </>
);
const BrLtLarge = () => (
  <>
    <br
      css={css`
        display: block;
        @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
          display: none;
        }
      `}
    />
    <span
      css={css`
        display: none;
        @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
          display: inline;
        }
      `}>
      {' '}
    </span>
  </>
);

type FlashDealPageViewProps = {
  offerGroupCardProps: Omit<OfferGroupCardProps, 'onClick'>;
  countdown: {hour: number; minute: number; second: number};
  onPurchaseButtonClick(): void;
  onOfferGroupCardClick(): void;
  onExitButtonClick(): void;
};

const FlashDealPageView = ({
  offerGroupCardProps,
  countdown,
  onOfferGroupCardClick,
  onPurchaseButtonClick,
  onExitButtonClick,
}: FlashDealPageViewProps) => {
  const {hour, minute, second} = countdown;
  const viewportHeight = use100vh();
  return (
    <div
      css={css`
        position: relative;
        width: 100%;
        height: ${viewportHeight};
        background-color: ${COLOR_FIX_SPACE_BLUE_BLACK_1};
        overflow: scroll;
      `}>
      <div
        css={css`
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: calc(var(--mobile-safe-area-top) + 220px);
          background-image: linear-gradient(0deg, ${COLOR_SPACE_BLUE_BLACK_1} 12.04%, transparent 150.91%),
            url(${URL_IMG_ARTICLE_03_01});
          background-repeat: no-repeat;
          background-size: cover;
          background-position: center center;
        `}
      />
      <div
        css={css`
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          display: flex;
          flex-direction: column;
          align-items: center;
        `}>
        <article
          css={css`
            width: 100%;
            max-width: 680px;
            padding: calc(var(--mobile-safe-area-top) + 40px) 20px 0;
            margin-bottom: 48px;
            display: flex;
            flex-direction: column;
            align-items: center;
            @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
              padding: calc(var(--mobile-safe-area-top) + 80px) 40px 0;
            }
          `}>
          <Typography
            css={css`
              margin-bottom: 28px;
              text-align: center;
            `}
            color={COLOR_FIX_SANTA_WHITE_1}
            variant="h5"
            fontWeight="bold"
            component="p">
            산타 AI가 추천한 문제를 푼<BrLtLarge />
            학습자는
            <BrGteLarge />
            토익 점수가
            <BrLtLarge />약{' '}
            <span
              css={css`
                color: ${COLOR_RIIID_GREEN_3};
              `}>
              30%
            </span>{' '}
            더 올랐어요
          </Typography>
          <div
            css={css`
              width: 100%;
              display: grid;
              grid-template: repeat(2, 1fr) / repeat(2, 1fr);
              gap: 12px;
              @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
                grid-template: none / repeat(4, 1fr);
              }
            `}>
            <BenefitCard iconType="question" title="6개 학습 무료 이용" description="24시간 동안 학습 가능" />
            <BenefitCard iconType="rec-study" title="추천 학습 무료 이용" description="매일 이용권 2개 제공" />
            <BenefitCard iconType="lecture" title="464개 강의 무료" description="무료 강의 학습 가능" />
            <BenefitCard iconType="voca" title="4921개 어휘 무료" description="어휘 무료 학습 가능" />
          </div>
        </article>
        <article
          css={css`
            width: 100%;
            max-width: 680px;
            padding: 0 20px;
            margin-bottom: 40px;
            display: flex;
            flex-direction: column;
            align-items: center;
            @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
              padding: 0 40px;
              margin-bottom: 80px;
            }
          `}>
          <BenefitLabel content="이 기간에만 제공되는 추가 혜택" />
          <Typography
            css={css`
              margin-top: 4px;
              margin-bottom: 28px;
              text-align: center;
            `}
            color={COLOR_FIX_SANTA_WHITE_1}
            variant="h5"
            fontWeight="bold"
            component="p">
            무료 학습 기간이 끝나면
            <BrLtLarge />
            다시 만날 수 없는{' '}
            <span
              css={css`
                color: ${COLOR_RIIID_GREEN_3};
              `}>
              학습권
            </span>
          </Typography>
          <div
            css={css`
              margin-bottom: 28px;
            `}>
            <CountdownTimer hour={hour} minute={minute} second={second} />
          </div>
          <OfferGroupCard {...offerGroupCardProps} onClick={onOfferGroupCardClick} />
        </article>
        <div
          css={css`
            width: 100%;
            padding: 20px 0 40px;
            display: flex;
            justify-content: center;
            background: linear-gradient(
              180deg,
              ${COLOR_FIX_SPACE_BLUE_GRAY_9} 0%,
              ${COLOR_FIX_SPACE_BLUE_BLACK_1} 100%
            );
          `}>
          <div
            css={css`
              width: 100%;
              max-width: 680px;
              padding: 0 20px;
              display: grid;
              justify-items: center;
              gap: 12px;
              @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
                padding: 0 40px;
              }
            `}>
            <Button colorVariant="brand" isFullWidth onClick={onPurchaseButtonClick}>
              상품 구매하고 학습 계속하기
            </Button>
            <TextButton color="white-fix" fontWeight="regular" typographyVariant="body3" onClick={onExitButtonClick}>
              아니요, 괜찮아요
            </TextButton>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FlashDealPageView;
export type {FlashDealPageViewProps};
FlashDealPageView.displayName = 'FlashDealPageView';
