import {useMemo} from 'react';

import {VocaPoS} from '@santa-web/gen/open-api/content/models/VocaPoS';

import {usePushEvent} from '.';

export interface AllVocaPronunciationClkPayload extends Record<string, unknown> {
  text_type: 'word' | 'example_sentence';
  pos_type: VocaPoS['pos'];
}

export interface AllVocaImageExpClkPayload extends Record<string, unknown> {
  image_content_id: string;
  image_pos: VocaPoS['pos'];
}

export interface AllVocaSolViewElapsedTime extends Record<string, unknown> {
  total_time_consumed: number;
}

const useVocabularyEvents = () => {
  const pushEvent = usePushEvent();

  const vocabularyEvents = useMemo(
    () => ({
      pushAllVocaPronunciationClkEvent: async (payload: AllVocaPronunciationClkPayload) => {
        await pushEvent('all_voca_pronunciation_clk', payload);
      },
      pushAllVocaImageExpClkEvent: async (payload: AllVocaImageExpClkPayload) => {
        await pushEvent('all_voca_imageexp_clk', payload);
      },
      pushAllVocaSolViewElapsedTime: async (payload: AllVocaSolViewElapsedTime) => {
        await pushEvent('all_voca_sol_scroll', payload);
      },
    }),
    [pushEvent]
  );

  return vocabularyEvents;
};

export default useVocabularyEvents;
