class UnexpectedError extends Error {
  constructor(public cause: any) {
    super('Unexpected error');

    this.name = 'UnexpectedError';
  }
}

/**
 * @throws error which extends Error class
 */
const handleUnexpectedError = (error: unknown): never => {
  if (error instanceof Error) {
    throw error;
  }
  throw new UnexpectedError(error);
};

export {handleUnexpectedError};
