import React from 'react';

import {useDialogContext} from '@app/contexts/DialogContext';
import {ProductSuggestionBottomSheetContainer} from '@app/features/marketing/containers';

type OpenBottomSheetProps = {
  onNotPurchaseButtonClick?: () => void;
};

export function useProductSuggestionBottomSheetContainer() {
  const {openDialog, closeDialog} = useDialogContext();

  const openBottomSheet = React.useCallback(
    ({onNotPurchaseButtonClick}: OpenBottomSheetProps) => {
      openDialog(
        <ProductSuggestionBottomSheetContainer
          onNotPurchaseButtonClick={onNotPurchaseButtonClick}
          onClose={closeDialog}
        />
      );
    },
    [closeDialog, openDialog]
  );

  return {
    openBottomSheet,
  };
}
