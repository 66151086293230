import {useAtomValue} from 'jotai';
import {Language, LearningDomainKey} from '@santa-web/gen/open-api/service';
import learningDomainAtom from '@app/atoms/core/learning-domain';
import {useMyPassStatusQuery} from '@app/features/permit';
import useIsUnderAppReview from '@app/hooks/useIsUnderAppReview';
import useUser from '@app/hooks/user/useUser';

export const useIsNewUserFlashDealAvailable = () => {
  const learningDomain = useAtomValue(learningDomainAtom);
  const {data: user} = useUser();
  const {data: passStatus} = useMyPassStatusQuery();
  const isUnderAppReview = useIsUnderAppReview();

  const isLoading = !user || !passStatus;

  if (isUnderAppReview) {
    return false;
  }

  return isLoading
    ? undefined
    : learningDomain.key === LearningDomainKey.TOEIC &&
        user.language === Language.KO &&
        user.registeredAt &&
        passStatus &&
        passStatus.activePass &&
        passStatus.activePass.sourceType === 'ONBOARDING';
};
