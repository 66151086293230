import {useQuery} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';

import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {getChatRoomQueryKey} from '@app/queryKeys';

const useChatRoomQuery = (contentInteractionStateId: number) => {
  const {ChatRoomService} = useAtomValue(santaOpenapiServicesAtom);
  const queryKey = getChatRoomQueryKey(contentInteractionStateId);

  return useQuery(queryKey, () =>
    ChatRoomService.createOrGetChatRoom({
      createOrGetChatRoomRequest: {
        contentInteractionStateId,
      },
    }).then(({chatRoom}) => chatRoom)
  );
};

export default useChatRoomQuery;
