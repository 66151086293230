import React from 'react';

import {ContentTag} from '@santa-web/gen/ssp/messages/inside';
import MyNoteCard, {MyNoteCardProps} from '@app/components/card/MyNoteCard';
import {getSkills, getParts} from '@app/facade/content-tag';
import TagList from '@app/pages/learning-cell/report/components/TagList';

export interface ContentCardLessonProps extends Omit<MyNoteCardProps, 'title'> {
  lessonTitle?: string;
  tags?: ContentTag[];
}

const ContentCardLesson = React.forwardRef<HTMLDivElement, ContentCardLessonProps>(
  ({lessonTitle, tags, isBookmarked, onBookmarkClick, ...props}, ref) => {
    const tagValues = React.useMemo(() => [...getSkills(tags), ...getParts(tags)], [tags]);

    return (
      <MyNoteCard
        ref={ref}
        title={lessonTitle}
        content={<TagList tags={tagValues} />}
        isBookmarked={isBookmarked}
        onBookmarkClick={onBookmarkClick}
        {...props}
      />
    );
  }
);

export default ContentCardLesson;
