/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { PointRewardInfoFromJSON, PointRewardInfoToJSON, } from './PointRewardInfo';
/**
 * Check if a given object implements the PointRewardInfoResponse interface.
 */
export function instanceOfPointRewardInfoResponse(value) {
    let isInstance = true;
    return isInstance;
}
export function PointRewardInfoResponseFromJSON(json) {
    return PointRewardInfoResponseFromJSONTyped(json, false);
}
export function PointRewardInfoResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'info': !exists(json, 'info') ? undefined : PointRewardInfoFromJSON(json['info']),
    };
}
export function PointRewardInfoResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'info': PointRewardInfoToJSON(value.info),
    };
}
