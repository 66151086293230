import {useQuery} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';

import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {getKeyValueQueryKey} from '@app/queryKeys';

const useServerStorageQuery = (type: string, key: string | null | undefined) => {
  const {KeyValueStoreService} = useAtomValue(santaOpenapiServicesAtom);
  const queryKey = getKeyValueQueryKey({type, key: key ?? ''});

  return useQuery<{key: string; type: string; value: any}>(
    queryKey,
    async () => {
      const response = await KeyValueStoreService.getValueByTypeAndKey({type, key: key ?? ''});
      return {...response, value: response.value ? JSON.parse(response.value) : response.value};
    },
    {enabled: key != null}
  );
};

export default useServerStorageQuery;
