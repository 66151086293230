/* tslint:disable */
/* eslint-disable */
/**
 * 앱 홈 화면 상단에 노출되는 L2E 배너 분기를 위해 제공되는 현재 사용자의 L2E 권한 상태.
 * (also see: [Figma](https://www.figma.com/design/mgJhxCrrwFtTnTItXd3ylX/%F0%9F%A6%84%E2%9A%A1%EF%B8%8F-2024_Santa-1toN_UI-Design_iOS%2BAOS%2BWEB?node-id=5462-204879&t=xj3EEaKSfjzKxmjU-4))
 *
 * - **NO_PAID**: 무료 사용자
 * - **PAID_NORMAL**: 유료 사용자 - 일반 상품
 * - **PAID_L2E**: 유료 사용자 - L2E 상품
 * @export
 */
export const PointRewardAuthorityStatus = {
    NO_PAID: 'NO_PAID',
    PAID_NORMAL: 'PAID_NORMAL',
    PAID_L2_E: 'PAID_L2E'
};
export function PointRewardAuthorityStatusFromJSON(json) {
    return PointRewardAuthorityStatusFromJSONTyped(json, false);
}
export function PointRewardAuthorityStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function PointRewardAuthorityStatusToJSON(value) {
    return value;
}
