import React from 'react';
import {css} from '@emotion/react';
import {COLOR_FIX_SPACE_BLUE_GRAY_9, COLOR_SPACE_BLUE_WHITE_3} from '@riiid/design-system';
import {Typography} from '@santa-web/service-ui';

type CountdownNumberProps = {value: number};

const CountdownNumber = ({value}: CountdownNumberProps) => {
  return (
    <span
      css={css`
        width: 56px;
        height: 64px;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: ${COLOR_SPACE_BLUE_WHITE_3};
        border-radius: 4px;
      `}>
      <Typography color={COLOR_FIX_SPACE_BLUE_GRAY_9} fontWeight="bold" variant="h4" component="span">
        {String(value).padStart(2, '0')}
      </Typography>
    </span>
  );
};

export default CountdownNumber;
export type {CountdownNumberProps};
CountdownNumber.displayName = 'CountdownNumber';
