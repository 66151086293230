/**
 * Check if a given object implements the ToeicVirtualExamAudioDirectionMappings interface.
 */
export function instanceOfToeicVirtualExamAudioDirectionMappings(value) {
    let isInstance = true;
    isInstance = isInstance && "part1" in value;
    isInstance = isInstance && "part2" in value;
    isInstance = isInstance && "part3" in value;
    isInstance = isInstance && "part4" in value;
    return isInstance;
}
export function ToeicVirtualExamAudioDirectionMappingsFromJSON(json) {
    return ToeicVirtualExamAudioDirectionMappingsFromJSONTyped(json, false);
}
export function ToeicVirtualExamAudioDirectionMappingsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'part1': json['part1'],
        'part2': json['part2'],
        'part3': json['part3'],
        'part4': json['part4'],
    };
}
export function ToeicVirtualExamAudioDirectionMappingsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'part1': value.part1,
        'part2': value.part2,
        'part3': value.part3,
        'part4': value.part4,
    };
}
