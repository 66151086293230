import {useAtomValue} from 'jotai';

import {Domain} from '@santa-web/gen/open-api/content/models/Domain';
import {learningStore} from '@app/test-preparation/atoms/stores';

/**
 * bookmark: TestPrep의 BottomToolList 북마크 기능
 * highlight: TestPrep selection을 통한 하이라이트 기능
 * sentence-analysis: TestPrep 문장 구조 기능
 * highlight-config: config 아이콘을 이용한 하이라이트 기능 on-off
 * unsolvable-config: config 아이콘을 이용한 모르는 문제 기능 on-off
 */
type TestPrepFeatures =
  | 'bookmark'
  | 'highlight'
  | 'sentence-analysis'
  | 'switchable-highlight'
  | 'switchable-unsolvable'
  | 'tutor-question';

const AVAILABLE_FEATURES_BY_DOMAIN: Record<Domain, TestPrepFeatures[]> = {
  TOEIC: [
    'bookmark',
    'highlight',
    'sentence-analysis',
    'switchable-highlight',
    'switchable-unsolvable',
    'tutor-question',
  ],
  GTELP: ['bookmark'],
  TOEIC_SPEAKING: [],
};

const useFeatureAvailableByDomain = (feature: TestPrepFeatures) => {
  const domain = useAtomValue(learningStore.content.domainAtom);

  return AVAILABLE_FEATURES_BY_DOMAIN[domain].includes(feature);
};

export default useFeatureAvailableByDomain;
