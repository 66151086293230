import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {COLOR_SANTA_D, COLOR_SANTA_I, COLOR_SANTA_J, COLOR_SANTA_L} from '@riiid/design-system';
import GlobalIcon from '@riiid/design-system/icons/Global';
import {Button, ButtonBase, Typography} from '@santa-web/service-ui';
import {OnboardingLayout} from '@app/features/onboarding';

export interface OnboardingIntroViewProps {
  currentLanguage: string;
  onLanguageButtonClick: () => void;
  onStartButtonClick: () => void;
  onLoginButtonClick: () => void;
}

const OnboardingIntroPageView = ({
  currentLanguage,
  onLanguageButtonClick,
  onStartButtonClick,
  onLoginButtonClick,
}: OnboardingIntroViewProps) => {
  const {t} = useTranslation();
  return (
    <OnboardingLayout shouldShowTopImage>
      <div
        css={css`
          height: 100%;
          display: grid;
          align-content: space-between;
        `}>
        <div>
          <div
            css={css`
              display: flex;
              justify-content: space-between;
              margin-bottom: 24px;
            `}>
            <Typography color={COLOR_SANTA_I} variant="caption1" fontWeight="bold">
              {t('page_onboarding_intro_sub_title')}
            </Typography>
            <ButtonBase onClick={onLanguageButtonClick}>
              <div
                css={css`
                  display: flex;
                  align-items: center;
                  padding: 4px 12px 4px 8px;
                  background-color: ${COLOR_SANTA_D};
                  border-radius: 9999px;
                `}>
                <GlobalIcon width={16} height={16} type="line" color={COLOR_SANTA_I} />
                <Typography
                  css={css`
                    margin-left: 4px;
                  `}
                  color={COLOR_SANTA_I}
                  component="p"
                  variant="caption2"
                  fontWeight="bold">
                  {currentLanguage}
                </Typography>
              </div>
            </ButtonBase>
          </div>
          <Typography
            css={css`
              white-space: pre-line;
              margin-bottom: 24px;
            `}
            color={COLOR_SANTA_L}
            variant="h5"
            fontWeight="regular">
            {t('page_onboarding_intro_title')}
          </Typography>
          <Typography
            css={css`
              white-space: pre-line;
            `}
            color={COLOR_SANTA_J}
            variant="body3">
            {t('page_onboarding_intro_description')}
          </Typography>
        </div>
        <div>
          <Button
            colorVariant="brand"
            isFullWidth
            css={css`
              margin-bottom: 8px;
            `}
            onClick={onStartButtonClick}>
            {t('page_onboarding_intro_start_button')}
          </Button>
          <Button colorVariant="black" variant="box-line" isFullWidth onClick={onLoginButtonClick}>
            {t('page_onboarding_intro_login_button')}
          </Button>
        </div>
      </div>
    </OnboardingLayout>
  );
};

export default React.memo(OnboardingIntroPageView);
