import React from 'react';
import {useTranslation} from 'react-i18next';

import {SinglePageLayout, SystemErrorLayout, Button} from '@santa-web/service-ui';

export interface ContentTimeoutErrorLayoutProps {
  onClick: () => void | Promise<void>;
}

const ContentTimeoutErrorLayout = ({onClick}: ContentTimeoutErrorLayoutProps) => {
  const {t} = useTranslation();
  return (
    <SinglePageLayout isError>
      <SystemErrorLayout
        title={t('virtual_exam_content_timeout_layout_title')}
        description={t('virtual_exam_content_timeout_layout_description')}
        button={
          <Button colorVariant="black" variant="box-line" onClick={onClick}>
            {t('virtual_exam_content_timeout_layout_button')}
          </Button>
        }
        icon="warning"
      />
    </SinglePageLayout>
  );
};

export default React.memo(ContentTimeoutErrorLayout);
