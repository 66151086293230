import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';

import {useAuthButtons} from '@app/features/auth/hooks';
import {OnboardingInformationArea, OnboardingLayout} from '@app/features/onboarding';

export interface LoginViewProps {
  onKakaoLogin: React.EventHandler<React.MouseEvent>;
  onGoogleLogin: Parameters<typeof useAuthButtons>[0]['onGoogleAuth'];
  onAppleLogin: React.EventHandler<React.MouseEvent>;
  onLineLogin: React.EventHandler<React.MouseEvent>;
  onEmailLogin: React.MouseEventHandler<HTMLButtonElement>;
  isLoggingIn: boolean;
}

const LoginPageView = ({
  onKakaoLogin,
  onGoogleLogin,
  onAppleLogin,
  onLineLogin,
  onEmailLogin,
  isLoggingIn,
}: LoginViewProps) => {
  const {t} = useTranslation();
  const buttons = useAuthButtons({
    type: 'login',
    isLoading: isLoggingIn,
    onAppleAuth: onAppleLogin,
    onEmailAuth: onEmailLogin,
    onGoogleAuth: onGoogleLogin,
    onLineAuth: onLineLogin,
    onKakaoAuth: onKakaoLogin,
  });

  return (
    <OnboardingLayout>
      <div
        css={css`
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        `}>
        <OnboardingInformationArea
          shouldShowAiImage
          subTitle={t('page_signin_sub_title')}
          title={t('page_signin_title')}
          description={t('page_signin_description')}
        />
        <div
          css={css`
            display: grid;
            justify-items: center;
            gap: 8px;
          `}>
          {buttons}
        </div>
      </div>
    </OnboardingLayout>
  );
};

export default React.memo(LoginPageView);
