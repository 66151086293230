import {atom} from 'jotai';
import {setAtomLabels} from '@app/misc/atom-label';
import {learningCellService} from '@app/test-preparation/atoms/services';
import {withDefault} from '@app/test-preparation/atoms/stores/learning/utils/atom';

const idAtom = atom(get => get(learningCellService.learningCellAtom).id);
const stateAtom = atom(get => get(learningCellService.learningCellAtom).state);
const typeAtom = atom(get => get(learningCellService.learningCellAtom).providedContext);
const contentTypeAtom = atom(get => get(learningCellService.learningCellAtom).config.value.oneOfCase);
const extraInfoAtom = atom(get => get(learningCellService.learningCellAtom).extraInfo.value);

const requiredCountToCompleteAtom = atom(
  get =>
    get(learningCellService.learningCellAtom).config.value.questionStream?.requiredCountToCompleteLearningCell ?? null
);
const hasQuestionsAtom = atom(
  get => get(learningCellService.learningCellAtom).config.value.voca?.hasQuestions ?? false
);

const isDiagnosisAtom = atom(get => get(typeAtom) === 'DIAGNOSIS');
const isCourseLearningAtom = atom(get => get(typeAtom) === 'COURSE_LEARNING');
const isSelfLearningAtom = atom(get => get(typeAtom) === 'SELF_LEARNING');
const isMyNoteQuizAtom = atom(get => get(typeAtom) === 'MY_NOTE');
const isMockExamAtom = atom(get => {
  const learningCell = get(learningCellService.learningCellAtom);

  return (learningCell.config.value.questionSet?.isExam || learningCell.config.value.questionStream?.isExam) ?? false;
});
const isDiagnosticsAtom = atom(get => get(isDiagnosisAtom) || get(isMockExamAtom));

const isQuestionSetAtom = atom(get => get(contentTypeAtom) === 'QUESTION_SET');
const isQuestionStreamAtom = atom(get => get(contentTypeAtom) === 'QUESTION_STREAM');
const isLessonAtom = atom(get => get(contentTypeAtom) === 'LESSON');
const isVocabularyAtom = atom(get => get(contentTypeAtom) === 'VOCA');
const isToeicVirtualExamAtom = atom(get => get(extraInfoAtom).selfLearning?.isToeicVirtualExam === true);

export const cell = {
  idAtom,
  stateAtom,
  typeAtom: withDefault(typeAtom, null),
  contentTypeAtom: withDefault(contentTypeAtom, null),
  extraInfoAtom: withDefault(contentTypeAtom, null),

  requiredCountToCompleteAtom,
  hasQuestionsAtom,

  isDiagnosisAtom: withDefault(isDiagnosisAtom, false),
  isCourseLearningAtom,
  isSelfLearningAtom,
  isMyNoteQuizAtom,
  isMockExamAtom,
  isDiagnosticsAtom: withDefault(isDiagnosticsAtom, false),

  isQuestionSetAtom,
  isQuestionStreamAtom,
  isLessonAtom,
  isVocabularyAtom,
  isToeicVirtualExamAtom,
};

setAtomLabels(
  {
    idAtom,
    stateAtom,
    typeAtom: cell.typeAtom,
    contentTypeAtom: cell.contentTypeAtom,
    requiredCountToCompleteAtom,
    hasQuestionsAtom,
    isDiagnosisAtom: cell.isDiagnosisAtom,
    isCourseLearningAtom,
    isSelfLearningAtom,
    isMyNoteQuizAtom,
    isMockExamAtom,
    isDiagnosticsAtom: cell.isDiagnosticsAtom,
    isQuestionSetAtom,
    isQuestionStreamAtom,
    isLessonAtom,
    isVocabularyAtom,
  },
  'learningStore.cell.'
);
