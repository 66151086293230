import {BrowserStorage} from '@santa-web/service-common';
import {FlashDealStorage} from '@app/features/marketing';

const useL2EFlashDealDisplay = () => {
  const courseCellCompleteStorage = new BrowserStorage<FlashDealStorage>(localStorage);

  const getCount = () => courseCellCompleteStorage.getItem('l2e-flash-deal-display-count') ?? 0;
  const addCount = () => courseCellCompleteStorage.setItem('l2e-flash-deal-display-count', getCount() + 1);
  const getNextDisplayTime = () => courseCellCompleteStorage.getItem('next-l2e-flash-deal-display-at') ?? 0;
  const setNextDisplayTime = (value: number) =>
    courseCellCompleteStorage.setItem('next-l2e-flash-deal-display-at', value);

  return {getCount, addCount, getNextDisplayTime, setNextDisplayTime};
};

export {useL2EFlashDealDisplay};
