/**
 * Check if a given object implements the MarketingPopupCtaButton interface.
 */
export function instanceOfMarketingPopupCtaButton(value) {
    let isInstance = true;
    isInstance = isInstance && "appUrl" in value;
    isInstance = isInstance && "text" in value;
    isInstance = isInstance && "webUrl" in value;
    isInstance = isInstance && "webUrlIsExternal" in value;
    return isInstance;
}
export function MarketingPopupCtaButtonFromJSON(json) {
    return MarketingPopupCtaButtonFromJSONTyped(json, false);
}
export function MarketingPopupCtaButtonFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'appUrl': json['appUrl'],
        'text': json['text'],
        'webUrl': json['webUrl'],
        'webUrlIsExternal': json['webUrlIsExternal'],
    };
}
export function MarketingPopupCtaButtonToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'appUrl': value.appUrl,
        'text': value.text,
        'webUrl': value.webUrl,
        'webUrlIsExternal': value.webUrlIsExternal,
    };
}
