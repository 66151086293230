import {useABTest} from '@app/experiments/hooks';
import {useTypedRouter} from '@app/hooks/useTypedRouter';
import {useIsPreview} from '@app/test-preparation/hooks';
import {SantaNavigationPath} from '@app/types/navigation';

const LEVEL_CHOICE_AB_TEST_KEY = 'Estimated_difficulty_level';
const LEVEL_CHOICE_AB_TEST_VARIANTS = {
  BASELINE: 'baseline',
  SHOW_CONTENTS_LEVEL_AND_CHOICE_RATE: 'Estimated_difficulty_level',
};

const useIsContentLevelChoiceRateABTestTarget = () => {
  const {pathname} = useTypedRouter();
  const isVirtualExam = pathname === SantaNavigationPath['/virtual-exam'];
  const isPreview = useIsPreview();
  const levelChoiceAbTestVariant = useABTest(LEVEL_CHOICE_AB_TEST_KEY);
  const isABTestTarget =
    !isPreview && levelChoiceAbTestVariant === LEVEL_CHOICE_AB_TEST_VARIANTS.SHOW_CONTENTS_LEVEL_AND_CHOICE_RATE;
  // 실전모의고사에서는 initialize를 하지 않아서, 에러가 발생함. also see - https://riiid-pioneer.atlassian.net/browse/TN-16800
  if (isVirtualExam) return false;
  return isABTestTarget;
};

export default useIsContentLevelChoiceRateABTestTarget;
