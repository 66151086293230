import React from 'react';
import {css} from '@emotion/react';

import {QuestionState} from '@santa-web/gen/open-api/content-learning-service';
import {QuestionSet} from '@santa-web/gen/open-api/content/models/QuestionSet';
import {BlankContext, SingleQuestionTitle, useBlankContext, useFloatingChatButtonList} from '@santa-web/service-ui';
import {getQuestionTitle} from '@app/components/ContentView/utilities';
import {VirtualExamFloatingChatButtonBox} from '@app/components/virtual-exam';
import useFloatingAreaHeight from '@app/hooks/useFloatingAreaHeight';
import QuestionView from '@app/test-preparation/components/QuestionView';
import SingleColumnLayout from '@app/test-preparation/components/SingleColumnLayout';

export interface VirtualExamPart5ViewProps {
  contentId: string;
  questionSet: QuestionSet;
  questionStates: QuestionState[];
  questionStartNumber: number;
  onQuestionAnswerStateChange(questionState: QuestionState): void | Promise<void>;
}

const VirtualExamPart5View = ({
  contentId,
  questionSet,
  questionStates,
  questionStartNumber,
  onQuestionAnswerStateChange,
}: VirtualExamPart5ViewProps) => {
  if (questionSet.questions.length !== 1) throw new Error('A length of questions for part 1 and part 2 must be 1');
  if (questionStates.length !== 1) throw new Error('A length of questionStates for part 1 and part 2 must be 1');

  const blankContext = useBlankContext();

  const question = questionSet.questions[0];
  const questionState = questionStates[0];

  const questionView = (
    <QuestionView
      isCompleted={false}
      contentId={contentId}
      questionTitle={<SingleQuestionTitle content={getQuestionTitle(questionStartNumber)} />}
      question={question}
      questionState={questionState}
      onChange={onQuestionAnswerStateChange}
      canSelectUnsolvableQuestion={false}
    />
  );

  const {floatingChatButtonList} = useFloatingChatButtonList();
  const floatingAreaHeight = useFloatingAreaHeight();

  return (
    <SingleColumnLayout>
      <div
        css={css`
          flex: 1;
          margin-bottom: ${floatingAreaHeight}px;
        `}>
        <BlankContext.Provider value={blankContext}>{questionView}</BlankContext.Provider>
      </div>
      <VirtualExamFloatingChatButtonBox>{floatingChatButtonList}</VirtualExamFloatingChatButtonBox>
    </SingleColumnLayout>
  );
};

export default React.memo(VirtualExamPart5View);
