import { LearningStatusFromJSON, LearningStatusToJSON, } from './LearningStatus';
/**
 * Check if a given object implements the LearningGoalStatus interface.
 */
export function instanceOfLearningGoalStatus(value) {
    let isInstance = true;
    isInstance = isInstance && "continuousLearningDays" in value;
    isInstance = isInstance && "isDailyGoalAchieved" in value;
    isInstance = isInstance && "learningStatus" in value;
    return isInstance;
}
export function LearningGoalStatusFromJSON(json) {
    return LearningGoalStatusFromJSONTyped(json, false);
}
export function LearningGoalStatusFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'continuousLearningDays': json['continuousLearningDays'],
        'isDailyGoalAchieved': json['isDailyGoalAchieved'],
        'learningStatus': LearningStatusFromJSON(json['learningStatus']),
    };
}
export function LearningGoalStatusToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'continuousLearningDays': value.continuousLearningDays,
        'isDailyGoalAchieved': value.isDailyGoalAchieved,
        'learningStatus': LearningStatusToJSON(value.learningStatus),
    };
}
