import React from 'react';
import {css} from '@emotion/react';

type ButtonListProps = React.ComponentPropsWithoutRef<'div'>;

const ButtonList = React.forwardRef<HTMLDivElement, ButtonListProps>((props, ref) => {
  return (
    <div
      ref={ref}
      {...props}
      css={css`
        > button {
          margin-top: 8px;

          &:first-of-type {
            margin-top: 0;
          }
        }
      `}
    />
  );
});

export default React.memo(ButtonList);
