import {useMutation, useQueryClient} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';
import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {getPassesQueryKey} from '@app/queryKeys';

export const useActivatePassMutation = () => {
  const {PassService} = useAtomValue(santaOpenapiServicesAtom);
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (id: number) => {
      await PassService.activatePassV2({passId: id});
    },
    onSuccess: () => {
      queryClient.invalidateQueries(getPassesQueryKey());
    },
  });
};
