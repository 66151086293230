import React from 'react';

import {FloatingPaginationButtonsProps} from '@santa-web/service-ui';

interface IFloatingPaginationButtonsContext extends FloatingPaginationButtonsProps {
  isVisible: boolean;
}

const FloatingPaginationButtonsContext = React.createContext<IFloatingPaginationButtonsContext>({
  isPreviousDisabled: false,
  isNextDisabled: false,
  onNextClick: () => {},
  onPreviousClick: () => {},
  isVisible: false,
});

export default FloatingPaginationButtonsContext;
