import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {COLOR_SANTA_A} from '@riiid/design-system';
import {TabNav, TabNavButton} from '@santa-web/service-ui';
import {CouponListTabValue} from '@app/features/coupon';

type CouponListTabProps = {
  onTabChange: (tab: CouponListTabValue) => void;
  currentTab: CouponListTabValue;
};

const CouponListTab = ({onTabChange, currentTab}: CouponListTabProps) => {
  const {t} = useTranslation();
  return (
    <TabNav
      css={css`
        background-color: ${COLOR_SANTA_A};
      `}>
      <TabNavButton isSelected={currentTab === 'AVAILABLE'} onClick={() => onTabChange('AVAILABLE')}>
        {t('page_coupon_box_list_filter_available')}
      </TabNavButton>
      <TabNavButton isSelected={currentTab === 'PAST'} onClick={() => onTabChange('PAST')}>
        {t('page_coupon_box_list_filter_unavailable')}
      </TabNavButton>
    </TabNav>
  );
};

export default CouponListTab;
export type {CouponListTabProps};
CouponListTab.displayName = 'CouponListTab';
