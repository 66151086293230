import React from 'react';
import {css} from '@emotion/react';
import {BREAKPOINT_MEDIUM} from '@riiid/design-system';
import {URL_GIF_AIBUTTON_DEFAULT} from '@riiid/design-system/lotties/santa';

import {BottomSheet, BottomSheetProps, Dim, DimProps} from '@santa-web/service-ui';
import {BOTTOM_SHEET_Z_INDEX} from '@app/constants/zIndex';

export interface BottomSheetWithDimProps extends Omit<DimProps, 'title'> {
  isOpened?: boolean;
  BottomSheetProps?: Omit<BottomSheetProps, 'isOpened'>;
}

const BottomSheetWithDim = React.forwardRef<HTMLDivElement, BottomSheetWithDimProps>(
  ({isOpened, BottomSheetProps, ...props}, ref) => {
    return isOpened ? (
      <Dim
        css={css`
          position: fixed;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          display: flex;
          flex-direction: column-reverse;
          box-sizing: border-box;
          z-index: ${BOTTOM_SHEET_Z_INDEX};
          @media (min-width: ${BREAKPOINT_MEDIUM}) {
            align-items: center;
            justify-content: center;
          }
        `}
        data-testid="BottomSheetWithDim-Dim"
        ref={ref}
        {...props}>
        <BottomSheet isOpened={isOpened} lottieSrc={URL_GIF_AIBUTTON_DEFAULT} {...BottomSheetProps} />
      </Dim>
    ) : null;
  }
);

export default React.memo(BottomSheetWithDim);
