/**
 * Check if a given object implements the DisplayInfoV2 interface.
 */
export function instanceOfDisplayInfoV2(value) {
    let isInstance = true;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "name" in value;
    return isInstance;
}
export function DisplayInfoV2FromJSON(json) {
    return DisplayInfoV2FromJSONTyped(json, false);
}
export function DisplayInfoV2FromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'description': json['description'],
        'name': json['name'],
    };
}
export function DisplayInfoV2ToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'description': value.description,
        'name': value.name,
    };
}
