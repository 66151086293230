import {useQuery} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';

import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {getChatMessagesQueryKey} from '@app/queryKeys';

const useChatMessagesQuery = (chatRoomId: number) => {
  const {ChatMessageService} = useAtomValue(santaOpenapiServicesAtom);
  const queryKey = getChatMessagesQueryKey(chatRoomId);

  return useQuery(
    queryKey,
    () =>
      ChatMessageService.getChatMessages({
        roomId: chatRoomId,
      }).then(({messages: chatMessages}) => chatMessages),
    {
      cacheTime: 0,
      enabled: chatRoomId !== -1,
    }
  );
};

export default useChatMessagesQuery;
