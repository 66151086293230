import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {BREAKPOINT_MEDIUM, COLOR_SANTA_I, COLOR_SANTA_L} from '@riiid/design-system';
import {URL_IMG_TRACKING} from '@riiid/design-system/images/santa';
import {Button, Typography} from '@santa-web/service-ui';
import {Column, Row} from '@app/components';

type ExamScoreViewTitleProps = {
  learningDomain: string;
  onAddScoreClick: () => void;
};

const ExamScoreViewTitle = ({onAddScoreClick, learningDomain}: ExamScoreViewTitleProps) => {
  const {t} = useTranslation();
  return (
    <Column
      gap={40}
      css={css`
        width: 100%;
        padding: 40px 20px;

        @media (min-width: ${BREAKPOINT_MEDIUM}) {
          padding: 40px;
        }
      `}>
      <Row gap={20} justifyContent="space-between" alignItems="flex-end">
        <Column gap={12}>
          <Typography component="p" variant="caption1" fontWeight="bold" color={COLOR_SANTA_I}>
            {t(`page_exam_score_title`, {domain: learningDomain})}
          </Typography>
          <Typography
            css={css`
              white-space: pre-line;
            `}
            variant="h5"
            fontWeight="bold"
            color={COLOR_SANTA_L}>
            {t(`page_exam_score_sub_title`, {domain: learningDomain})}
          </Typography>
        </Column>

        <img src={URL_IMG_TRACKING} width={96} height={96} alt="" />
      </Row>

      <Button isFullWidth colorVariant="brand" onClick={onAddScoreClick}>
        {t(`page_exam_score_add_score_button_label`)}
      </Button>
    </Column>
  );
};

export default ExamScoreViewTitle;
export type {ExamScoreViewTitleProps};
ExamScoreViewTitle.displayName = 'ExamScoreViewTitle';
