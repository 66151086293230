/**
 * Check if a given object implements the MarketingCampaignButton interface.
 */
export function instanceOfMarketingCampaignButton(value) {
    let isInstance = true;
    isInstance = isInstance && "active" in value;
    isInstance = isInstance && "appUrl" in value;
    isInstance = isInstance && "text" in value;
    isInstance = isInstance && "webNewWindow" in value;
    isInstance = isInstance && "webUrl" in value;
    return isInstance;
}
export function MarketingCampaignButtonFromJSON(json) {
    return MarketingCampaignButtonFromJSONTyped(json, false);
}
export function MarketingCampaignButtonFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'active': json['active'],
        'appUrl': json['appUrl'],
        'text': json['text'],
        'webNewWindow': json['webNewWindow'],
        'webUrl': json['webUrl'],
    };
}
export function MarketingCampaignButtonToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'active': value.active,
        'appUrl': value.appUrl,
        'text': value.text,
        'webNewWindow': value.webNewWindow,
        'webUrl': value.webUrl,
    };
}
