/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { LanguageFromJSON, LanguageToJSON, } from './Language';
import { UserAgreementFromJSON, UserAgreementToJSON, } from './UserAgreement';
import { UserPersonalInfoFromJSON, UserPersonalInfoToJSON, } from './UserPersonalInfo';
import { UserRegistrationTypeFromJSON, UserRegistrationTypeToJSON, } from './UserRegistrationType';
import { UserStatusFromJSON, UserStatusToJSON, } from './UserStatus';
/**
 * Check if a given object implements the User interface.
 */
export function instanceOfUser(value) {
    let isInstance = true;
    isInstance = isInstance && "agreement" in value;
    isInstance = isInstance && "authId" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "language" in value;
    isInstance = isInstance && "registrationType" in value;
    isInstance = isInstance && "status" in value;
    return isInstance;
}
export function UserFromJSON(json) {
    return UserFromJSONTyped(json, false);
}
export function UserFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'agreement': UserAgreementFromJSON(json['agreement']),
        'authId': json['authId'],
        'id': json['id'],
        'language': LanguageFromJSON(json['language']),
        'lastSelectedLearningDomain': !exists(json, 'lastSelectedLearningDomain') ? undefined : json['lastSelectedLearningDomain'],
        'personalInfo': !exists(json, 'personalInfo') ? undefined : UserPersonalInfoFromJSON(json['personalInfo']),
        'registeredAt': !exists(json, 'registeredAt') ? undefined : (new Date(json['registeredAt'])),
        'registrationType': UserRegistrationTypeFromJSON(json['registrationType']),
        'status': UserStatusFromJSON(json['status']),
    };
}
export function UserToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'agreement': UserAgreementToJSON(value.agreement),
        'authId': value.authId,
        'id': value.id,
        'language': LanguageToJSON(value.language),
        'lastSelectedLearningDomain': value.lastSelectedLearningDomain,
        'personalInfo': UserPersonalInfoToJSON(value.personalInfo),
        'registeredAt': value.registeredAt === undefined ? undefined : (value.registeredAt.toISOString()),
        'registrationType': UserRegistrationTypeToJSON(value.registrationType),
        'status': UserStatusToJSON(value.status),
    };
}
