import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {
  COLOR_SANTA_C,
  COLOR_SANTA_G,
  COLOR_SANTA_I,
  COLOR_SANTA_J,
  COLOR_SANTA_L,
  SHADOW_A_2_DOWN,
} from '@riiid/design-system';
import ReportErrorIcon from '@riiid/design-system/icons/ReportError';
import {URL_IMG_PAY, URL_IMG_PAY_FAIL} from '@riiid/design-system/images/santa';
import {Button, TopNavBar, Typography} from '@santa-web/service-ui';
import {BaseCard, Column, Row} from '@app/components';
import {
  OfferGroupOrderSection,
  OfferLayout,
  OfferGroupOrderBillSheet,
  OfferGroupOrderTermsOfUse,
  OfferGroupOrderSectionTitle,
  OfferGroupOrderCautionButton,
  OfferGroupOrderCardLabelListProps,
  OfferGroupOrderCardLabelList,
  OfferGroupOrderPageCommonViewProps,
} from '@app/features/offer';
import {PassCard, PassCardProps, TicketCard, TicketCardProps} from '@app/features/permit';

type OfferGroupOrderDetailViewProps = OfferGroupOrderPageCommonViewProps & {
  permitInfo: {
    passes?: PassCardProps[];
    tickets?: TicketCardProps[];
  };
  orderCardLabelListProps: OfferGroupOrderCardLabelListProps;
  isInAppPurchase?: boolean;
  isCheckoutResult?: boolean;
  hasPaymentProviderError?: boolean;
  onStudyButtonClick?: () => void;
  onSupportButtonClick: () => void;
  onOrderListButtonClick: () => void;
  onSubscriptionManageButtonClick?: () => void;
};

const OfferGroupOrderDetailView = ({
  orderCardLabelListProps,
  displayInfo,
  billSheetProps,
  permitInfo,
  isInAppPurchase,
  isCheckoutResult,
  hasPaymentProviderError,
  onCautionClick,
  onGoBack,
  onStudyButtonClick,
  onSupportButtonClick,
  onOrderListButtonClick,
  onSubscriptionManageButtonClick,
}: OfferGroupOrderDetailViewProps) => {
  const {t} = useTranslation();
  const isFailed = orderCardLabelListProps.isSubscription
    ? orderCardLabelListProps.subscriptionStatus === 'failed'
    : orderCardLabelListProps.status === 'failed';
  const isExpired = orderCardLabelListProps.isSubscription
    ? orderCardLabelListProps.subscriptionStatus === 'expired' ||
      orderCardLabelListProps.subscriptionStatus === 'canceled'
    : orderCardLabelListProps.status === 'expired' || orderCardLabelListProps.status === 'refunded';
  const isInvalid = isFailed || isExpired;

  return (
    <OfferLayout
      topNavBar={!isCheckoutResult && <TopNavBar paginationIcon="back" onPaginationIconClick={onGoBack} />}
      bottomArea={
        isCheckoutResult &&
        (isFailed ? (
          <Button isFullWidth variant="bottom-fix" colorVariant="brand" onClick={onOrderListButtonClick}>
            {t('page_offer_group_order_detail_go_to_purchase_list_button')}
          </Button>
        ) : (
          onStudyButtonClick && (
            <Button isFullWidth variant="bottom-fix" colorVariant="brand" onClick={onStudyButtonClick}>
              {t('page_offer_group_order_detail_go_to_study_button')}
            </Button>
          )
        ))
      }>
      <div
        css={css`
          width: 100%;
          display: grid;
          gap: 8px;
        `}>
        <OfferGroupOrderSection hasTwoBackgroundColor backgroundBreakpoint={isFailed ? 360 : 240}>
          <div
            css={css`
              display: flex;
              gap: 20px;
              justify-content: space-between;
              align-items: flex-end;
            `}>
            {isCheckoutResult ? (
              <div>
                <Typography
                  css={css`
                    margin-bottom: 12px;
                  `}
                  component="h1"
                  variant="caption1"
                  fontWeight="bold"
                  color={COLOR_SANTA_I}>
                  {t('page_offer_group_order_result_title')}
                </Typography>
                <Typography
                  css={css`
                    white-space: pre-line;
                  `}
                  component="h2"
                  variant="h5"
                  fontWeight="regular">
                  <Trans
                    i18nKey={
                      isFailed
                        ? 'page_offer_group_order_result_title_failed'
                        : 'page_offer_group_order_result_title_success'
                    }
                    values={{chargeAmount: billSheetProps.billingInfo.totalAmount}}
                    components={{b: <Typography component="span" variant="h5" fontWeight="bold" />}}
                  />
                </Typography>
              </div>
            ) : (
              <div>
                <OfferGroupOrderCardLabelList {...orderCardLabelListProps} />
                <Typography
                  css={css`
                    margin-top: 12px;
                    white-space: pre-line;
                  `}
                  component="h2"
                  variant="h5"
                  fontWeight="regular">
                  <Trans
                    i18nKey={
                      isFailed
                        ? 'page_offer_group_order_detail_title_failed'
                        : 'page_offer_group_order_detail_title_success'
                    }
                    values={{displayName: displayInfo.title}}
                    components={{b: <Typography component="span" variant="h5" fontWeight="bold" />}}
                  />
                  <div
                    css={css`
                      display: none;
                    `}>
                    {t('page_offer_group_order_detail_title_failed')}
                    {t('page_offer_group_order_detail_title_success')}
                  </div>
                </Typography>
              </div>
            )}
            <img src={isInvalid ? URL_IMG_PAY_FAIL : URL_IMG_PAY} width={96} height={96} />
          </div>
          {isFailed && (
            <div
              css={css`
                margin-top: 24px;
              `}>
              <Typography
                css={css`
                  white-space: pre-line;
                  margin-bottom: 20px;
                `}
                component="p"
                variant="caption1"
                color={COLOR_SANTA_I}>
                {isCheckoutResult
                  ? t('page_offer_group_order_result_description_failed')
                  : t('page_offer_group_order_detail_description_failed')}
              </Typography>
              <Button isFullWidth colorVariant="brand" onClick={onSupportButtonClick}>
                {hasPaymentProviderError
                  ? t('page_offer_group_order_failed_payment_provider_button')
                  : t('page_offer_group_order_failed_customer_service_button')}
              </Button>
            </div>
          )}
          <BaseCard
            css={css`
              box-shadow: ${SHADOW_A_2_DOWN};
              margin-top: 32px;
              margin-bottom: 32px;
            `}>
            <Typography
              css={css`
                margin-bottom: 4px;
              `}
              component="h3"
              variant="caption1"
              fontWeight="regular"
              color={COLOR_SANTA_L}>
              {displayInfo.subTitle}
            </Typography>
            <Typography
              css={css`
                margin-bottom: 16px;
              `}
              component="h2"
              variant="body1"
              fontWeight="bold"
              color={COLOR_SANTA_L}>
              {displayInfo.title}
            </Typography>
            <Typography variant="body3" color={COLOR_SANTA_G}>
              {displayInfo.description}
            </Typography>
          </BaseCard>
          <OfferGroupOrderBillSheet {...billSheetProps} />
          {isInAppPurchase && (
            <Row
              css={css`
                margin-top: 32px;
              `}
              gap={12}
              alignItems="center">
              <ReportErrorIcon type="line" width={32} height={32} color={COLOR_SANTA_J} />
              <Typography variant="body3" color={COLOR_SANTA_J}>
                {t('page_offer_group_order_result_in_app_purchase_info')}
              </Typography>
            </Row>
          )}
          {onSubscriptionManageButtonClick && (
            <Button
              css={css`
                margin-top: 32px;
              `}
              isFullWidth
              variant="box-line"
              onClick={onSubscriptionManageButtonClick}>
              {t('page_offer_group_order_result_subscription_manage_button')}
            </Button>
          )}
        </OfferGroupOrderSection>
        {permitInfo?.passes && permitInfo.passes.length > 0 && (
          <OfferGroupOrderSection>
            <Typography
              css={css`
                margin-bottom: 24px;
              `}
              variant="body1"
              fontWeight="bold"
              color={COLOR_SANTA_L}>
              {t('page_offer_group_order_result_pass_list_title')}
            </Typography>
            <Column gap={12}>
              {permitInfo.passes.map(pass => (
                <PassCard key={pass.title} {...pass} additionalInformation={undefined} />
              ))}
            </Column>
          </OfferGroupOrderSection>
        )}
        {permitInfo?.tickets && permitInfo.tickets.length > 0 && !isFailed && (
          <OfferGroupOrderSection>
            <Typography
              css={css`
                margin-bottom: 4px;
              `}
              variant="body1"
              fontWeight="bold"
              color={COLOR_SANTA_L}>
              {t('page_offer_group_order_result_ticket_list_title')}
            </Typography>
            <Typography
              css={css`
                margin-bottom: 24px;
              `}
              component="p"
              variant="caption1"
              color={COLOR_SANTA_I}>
              {t('page_offer_group_order_result_ticket_list_description')}
            </Typography>
            <ul
              css={css`
                margin: 0;
                padding: 0;
                list-style: none;

                display: grid;
                gap: 12px;
              `}>
              {permitInfo.tickets.map(ticketCardProps => (
                <TicketCard key={ticketCardProps.title} {...ticketCardProps} additionalInformation={undefined} />
              ))}
            </ul>
          </OfferGroupOrderSection>
        )}
        <OfferGroupOrderSection>
          <OfferGroupOrderSectionTitle
            value={t('page_offer_group_order_section_title_caution')}
            icon={<ReportErrorIcon width={28} height={28} color={COLOR_SANTA_L} type="line" />}
          />
          <OfferGroupOrderCautionButton onClick={onCautionClick} />
        </OfferGroupOrderSection>
        <OfferGroupOrderSection
          css={css`
            margin-top: -8px;
            background-color: ${COLOR_SANTA_C};
          `}>
          <OfferGroupOrderTermsOfUse />
        </OfferGroupOrderSection>
      </div>
    </OfferLayout>
  );
};

export default OfferGroupOrderDetailView;
export type {OfferGroupOrderDetailViewProps};
OfferGroupOrderDetailView.displayName = 'OfferGroupOrderDetailView';
