/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { CouponFromJSON, CouponToJSON, } from './Coupon';
import { CursorPageFromJSON, CursorPageToJSON, } from './CursorPage';
/**
 * Check if a given object implements the ListCouponsResponse interface.
 */
export function instanceOfListCouponsResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "coupons" in value;
    return isInstance;
}
export function ListCouponsResponseFromJSON(json) {
    return ListCouponsResponseFromJSONTyped(json, false);
}
export function ListCouponsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'coupons': (json['coupons'].map(CouponFromJSON)),
        'page': !exists(json, 'page') ? undefined : CursorPageFromJSON(json['page']),
    };
}
export function ListCouponsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'coupons': (value.coupons.map(CouponToJSON)),
        'page': CursorPageToJSON(value.page),
    };
}
