import {format, subYears} from 'date-fns';
import {useGetExamDatesQuery, useGetExamScoresQuery} from '@app/features/preferences/hooks/exam-score';
import {StringDate} from '@app/features/preferences/types';

const useAvailableExamDates = ({currentDate}: {currentDate?: StringDate | null}): StringDate[] => {
  const today = new Date();
  const registeredScoreQuery = useGetExamScoresQuery({
    select: response => response.examScores.map(score => format(score.date, 'yyyy-MM-dd') as StringDate),
  });
  const examDatesQuery = useGetExamDatesQuery({
    args: {
      startDate: formatToYYYYMMDD(subYears(today, 2)),
      endDate: formatToYYYYMMDD(today),
    },
    options: {
      select: response => response.examDates as unknown as StringDate[],
    },
  });
  const unavailableDates = registeredScoreQuery.data ?? [];
  const examDates = examDatesQuery.data ?? [];

  const availableDates = getAvailableExamDates({examDates, unavailableDates, currentDate});

  return availableDates;
};

const getAvailableExamDates = ({
  examDates,
  unavailableDates,
  currentDate,
}: {
  examDates: StringDate[];
  unavailableDates: StringDate[];
  currentDate?: StringDate | null;
}): StringDate[] => {
  const examDateSet = new Set<StringDate>(examDates);

  unavailableDates.forEach(unavailableDate => {
    if (unavailableDate !== currentDate) {
      // 현재 수정중인 날짜는 남겨두고, 이미 사용중인 날짜 제외
      examDateSet.delete(unavailableDate);
    }
  });

  return Array.from(examDateSet);
};

const formatToYYYYMMDD = (date: Date): StringDate => {
  return format(date, 'yyyy-MM-dd') as StringDate;
};

export default useAvailableExamDates;
