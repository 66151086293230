import {atom} from 'jotai';
import {ContentInteractionStateWithContent} from '@santa-web/gen/open-api/content-learning-service';
import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';

const listCisesAtom = atom(async get => {
  const {ContentInteractionStateService} = await get(santaOpenapiServicesAtom);

  return async (learningCellId: number) => {
    const listCises = async (cursor = ''): Promise<Array<ContentInteractionStateWithContent>> => {
      const PAGE_SIZE = 100;
      const {
        contentInteractionStates: cises,
        page: {cursor: nextCursor},
      } = await ContentInteractionStateService.listContentInteractionStates({
        learningCellId,
        size: PAGE_SIZE,
        cursor: cursor || undefined,
      });

      return cises.concat(cises.length === PAGE_SIZE ? await listCises(nextCursor) : []);
    };

    return await listCises();
  };
});

export {listCisesAtom};
