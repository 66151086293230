import { MyNoteQuizFromJSON, MyNoteQuizToJSON, } from './MyNoteQuiz';
/**
 * Check if a given object implements the StartMyNoteQuizResponse interface.
 */
export function instanceOfStartMyNoteQuizResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "myNoteQuiz" in value;
    return isInstance;
}
export function StartMyNoteQuizResponseFromJSON(json) {
    return StartMyNoteQuizResponseFromJSONTyped(json, false);
}
export function StartMyNoteQuizResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'myNoteQuiz': MyNoteQuizFromJSON(json['myNoteQuiz']),
    };
}
export function StartMyNoteQuizResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'myNoteQuiz': MyNoteQuizToJSON(value.myNoteQuiz),
    };
}
