/* tslint:disable */
/* eslint-disable */
/**
 * - **HAS_NO_PASS**: 활성화된 pass 또는 활성화 대기중인 pass가 모두 없음.
 * - **HAS_PENDING_PASS**: 활성화된 pass가 없지만 활성화 대기중인 pass가 있음.
 * - **HAS_ACTIVE_PASS**: 활성화된 pass가 있음.
 * @export
 */
export const GetMyPassStatus = {
    NO_PASS: 'HAS_NO_PASS',
    PENDING_PASS: 'HAS_PENDING_PASS',
    ACTIVE_PASS: 'HAS_ACTIVE_PASS'
};
export function GetMyPassStatusFromJSON(json) {
    return GetMyPassStatusFromJSONTyped(json, false);
}
export function GetMyPassStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function GetMyPassStatusToJSON(value) {
    return value;
}
