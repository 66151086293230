/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { OrderPaymentTransactionAppStoreFromJSON, OrderPaymentTransactionAppStoreToJSON, } from './OrderPaymentTransactionAppStore';
import { OrderPaymentTransactionIamportFromJSON, OrderPaymentTransactionIamportToJSON, } from './OrderPaymentTransactionIamport';
import { OrderPaymentTransactionOneOfCaseFromJSON, OrderPaymentTransactionOneOfCaseToJSON, } from './OrderPaymentTransactionOneOfCase';
import { OrderPaymentTransactionPlayStoreFromJSON, OrderPaymentTransactionPlayStoreToJSON, } from './OrderPaymentTransactionPlayStore';
import { OrderPaymentTransactionStripeFromJSON, OrderPaymentTransactionStripeToJSON, } from './OrderPaymentTransactionStripe';
/**
 * Check if a given object implements the OneOfTransaction interface.
 */
export function instanceOfOneOfTransaction(value) {
    let isInstance = true;
    isInstance = isInstance && "oneOfCase" in value;
    return isInstance;
}
export function OneOfTransactionFromJSON(json) {
    return OneOfTransactionFromJSONTyped(json, false);
}
export function OneOfTransactionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'appStore': !exists(json, 'appStore') ? undefined : OrderPaymentTransactionAppStoreFromJSON(json['appStore']),
        'iamPort': !exists(json, 'iamPort') ? undefined : OrderPaymentTransactionIamportFromJSON(json['iamPort']),
        'oneOfCase': OrderPaymentTransactionOneOfCaseFromJSON(json['oneOfCase']),
        'playStore': !exists(json, 'playStore') ? undefined : OrderPaymentTransactionPlayStoreFromJSON(json['playStore']),
        'stripe': !exists(json, 'stripe') ? undefined : OrderPaymentTransactionStripeFromJSON(json['stripe']),
    };
}
export function OneOfTransactionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'appStore': OrderPaymentTransactionAppStoreToJSON(value.appStore),
        'iamPort': OrderPaymentTransactionIamportToJSON(value.iamPort),
        'oneOfCase': OrderPaymentTransactionOneOfCaseToJSON(value.oneOfCase),
        'playStore': OrderPaymentTransactionPlayStoreToJSON(value.playStore),
        'stripe': OrderPaymentTransactionStripeToJSON(value.stripe),
    };
}
