import { CouponDetailsFromJSON, CouponDetailsToJSON, } from './CouponDetails';
import { CouponStatusFromJSON, CouponStatusToJSON, } from './CouponStatus';
/**
 * Check if a given object implements the Coupon interface.
 */
export function instanceOfCoupon(value) {
    let isInstance = true;
    isInstance = isInstance && "details" in value;
    isInstance = isInstance && "displayName" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "registeredAt" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "templateId" in value;
    return isInstance;
}
export function CouponFromJSON(json) {
    return CouponFromJSONTyped(json, false);
}
export function CouponFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'details': CouponDetailsFromJSON(json['details']),
        'displayName': json['displayName'],
        'id': json['id'],
        'registeredAt': (new Date(json['registeredAt'])),
        'status': CouponStatusFromJSON(json['status']),
        'templateId': json['templateId'],
    };
}
export function CouponToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'details': CouponDetailsToJSON(value.details),
        'displayName': value.displayName,
        'id': value.id,
        'registeredAt': (value.registeredAt.toISOString()),
        'status': CouponStatusToJSON(value.status),
        'templateId': value.templateId,
    };
}
