import React from 'react';
import {css} from '@emotion/react';
import {COLOR_SANTA_J, BREAKPOINT_MEDIUM} from '@riiid/design-system';
import {produce} from 'immer';

import {useFloatingChatButtonList, OmrListPart, useIsGteMediumScreen} from '@santa-web/service-ui';
import {VirtualExamFloatingChatButtonBox} from '@app/components/virtual-exam';
import useFloatingAreaHeight from '@app/hooks/useFloatingAreaHeight';
import {BASE_PARTS, VirtualExamPart} from '@app/new-structure/entities/base/parts';
import {PartContentStatesMap} from '@app/new-structure/entities/virtual-exam/PartContentStataesMap';
import {QuestionAnswerStateWithIndex} from '@app/new-structure/entities/virtual-exam/QuestionAnswerState';

import VirtualExamCheckedView from './VirtualExamCheckedView';
import VirtualExamOmrListRow from './VirtualExamOmrListRow';

export interface VirtualExamPartAllViewProps extends React.ComponentPropsWithoutRef<'div'> {
  partContentStatesMap: PartContentStatesMap;
  isSelectedVisible: boolean;
  onAnswerDotClick: (
    contentIndex: number,
    questionIndex: number
  ) => (_questionAnswerState: QuestionAnswerStateWithIndex) => Promise<void>;
  onChevronClick: (part: VirtualExamPart, partContentIndex: number) => void;
}

const VirtualExamPartAllView = React.forwardRef<HTMLDivElement, VirtualExamPartAllViewProps>(
  ({partContentStatesMap, isSelectedVisible, onAnswerDotClick, onChevronClick, ...props}, ref) => {
    const isGteMediumScreen = useIsGteMediumScreen();
    const previousPartContentStatesMapRef = React.useRef<PartContentStatesMap>(partContentStatesMap);

    const {floatingChatButtonList} = useFloatingChatButtonList();
    const floatingAreaHeight = useFloatingAreaHeight();

    React.useEffect(() => {
      previousPartContentStatesMapRef.current = partContentStatesMap;
      // previousPartContentStatesMap need to be updated only re-mounted or tabIndex(isSelectedVisible) changed
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSelectedVisible]);

    const filteredPartContentStatesMap = React.useMemo(() => {
      if (isSelectedVisible) return partContentStatesMap;
      return produce(partContentStatesMap, draft => {
        BASE_PARTS.forEach(part => {
          draft[part] = draft[part]
            .map((contentState, contentStateIndex) =>
              produce(contentState, draft => {
                draft.questionStates = draft.questionStates.filter(
                  (_, questionStateIndex) =>
                    Number(
                      previousPartContentStatesMapRef.current[part][contentStateIndex].questionStates[
                        questionStateIndex
                      ].answer
                    ) === -1
                );
              })
            )
            .filter(contentState => contentState.questionStates.length > 0);
        });
      });
    }, [partContentStatesMap, isSelectedVisible]);

    const view = (() => {
      return BASE_PARTS.map((part, partIndex) => {
        const contentStates = filteredPartContentStatesMap[part];

        if (contentStates.length === 0) return null;
        const rows = contentStates.map(contentState => {
          // Do not use questionStateIndex from map function because it is filtered by `filteredPartContentStatesMap`
          return contentState.questionStates.map(questionState => {
            if (questionState.questionIndex == null) {
              throw new Error('QuestionIndex cannot be null in VirtualExamPartAllView');
            }
            return (
              <VirtualExamOmrListRow
                key={questionState.questionIndex}
                part={part}
                contentIndex={contentState.contentIndex}
                partContentIndex={contentState.partContentIndex}
                questionState={questionState}
                onAnswerDotClick={onAnswerDotClick}
                onChevronClick={onChevronClick}
              />
            );
          });
        });

        return (
          <OmrListPart title={`PART ${partIndex + 1}`} key={partIndex}>
            {rows}
          </OmrListPart>
        );
      });
    })();

    const part1To4View = React.useMemo(() => view.slice(0, 4), [view]);
    const part5To7View = React.useMemo(() => view.slice(4), [view]);
    const isPartAllExist = view.some(part => part != null);
    const isPart1To4ViewExist = part1To4View.some(part => part != null);
    const isPart5To7ViewExist = part5To7View.some(part => part != null);

    const allPartView = (() => {
      if (!isPartAllExist)
        return (
          <VirtualExamCheckedView
            allPart
            css={css`
              min-height: 100%;
            `}
          />
        );

      if (!isGteMediumScreen) {
        return (
          <div
            css={css`
              display: grid;
              grid-auto-flow: row;
              grid-row-gap: 40px;
              row-gap: 40px;
            `}>
            {view}
            <div
              css={css`
                margin-bottom: ${floatingAreaHeight}px;
              `}
            />
          </div>
        );
      }

      return (
        <div
          css={css`
            position: relative;
            min-height: 100%;
            display: flex;
            align-items: flex-start;
            :after {
              content: '';
              position: absolute;
              height: 100%;
              left: 50%;
              width: 1px;
              background-color: ${COLOR_SANTA_J};
            }
          `}>
          {isPart1To4ViewExist ? (
            <div
              css={css`
                width: 100%;
                display: grid;
                grid-auto-flow: row;
                grid-row-gap: 40px;
                row-gap: 40px;
                margin-bottom: ${floatingAreaHeight}px;
              `}>
              {part1To4View}
            </div>
          ) : (
            <VirtualExamCheckedView
              css={css`
                position: sticky;
                transform: translateY(-50%);
                top: 50%;
              `}
              startPart={1}
              endPart={4}
            />
          )}
          {isPart5To7ViewExist ? (
            <div
              css={css`
                width: 100%;
              `}>
              <div
                css={css`
                  display: grid;
                  grid-auto-flow: row;
                  grid-row-gap: 40px;
                  row-gap: 40px;
                  padding-left: 40px;
                  margin-bottom: ${floatingAreaHeight}px;
                `}>
                {part5To7View}
              </div>
            </div>
          ) : (
            <VirtualExamCheckedView
              css={css`
                position: sticky;
                transform: translateY(-50%);
                top: 50%;
              `}
              startPart={5}
              endPart={7}
            />
          )}
        </div>
      );
    })();

    return (
      <div
        css={css`
          width: 100%;
          height: 100%;
          display: flex;
          justify-content: center;
        `}>
        <div
          css={css`
            width: 100%;
            max-width: 1360px;
            min-height: 100%;
            padding: 40px 20px;
            @media (min-width: ${BREAKPOINT_MEDIUM}) {
              padding: 40px;
            }
          `}
          ref={ref}
          {...props}>
          {allPartView}
        </div>
        <VirtualExamFloatingChatButtonBox
          css={css`
            max-width: 1360px;
          `}>
          {floatingChatButtonList}
        </VirtualExamFloatingChatButtonBox>
      </div>
    );
  }
);

export default React.memo(VirtualExamPartAllView);
