function _array_like_to_array(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _array_without_holes(arr) {
    if (Array.isArray(arr)) return _array_like_to_array(arr);
}
function _iterable_to_array(iter) {
    if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}
function _non_iterable_spread() {
    throw new TypeError("Invalid attempt to spread non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _to_consumable_array(arr) {
    return _array_without_holes(arr) || _iterable_to_array(arr) || _unsupported_iterable_to_array(arr) || _non_iterable_spread();
}
function _unsupported_iterable_to_array(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _array_like_to_array(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _array_like_to_array(o, minLen);
}
function cssVarToValue(cssVarName) {
    var computedStyle = getComputedStyle(document.documentElement);
    var value = computedStyle.getPropertyValue(cssVarName).trim();
    return value;
}
export var rgbToRrtv2Rgba = function(rgb) {
    var alpha = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : 0.5;
    var normalRgbRegExp = /^rgb\(\d{1,3} *, *\d{1,3} *, *\d{1,3}\)$/;
    var designSystemRgbRegExp = /^rgb\(var\(--.*\)\){1}$/;
    if (!normalRgbRegExp.test(rgb) && !designSystemRgbRegExp.test(rgb)) {
        return [
            255,
            255,
            255,
            0.5
        ];
    }
    var cssVarMatchResult = /var\(([\w\d-_]+)\)/.exec(rgb);
    if (designSystemRgbRegExp.test(rgb) && (cssVarMatchResult === null || cssVarMatchResult === void 0 ? void 0 : cssVarMatchResult[1])) {
        var _cssVarToValue_match;
        return _to_consumable_array((_cssVarToValue_match = cssVarToValue(cssVarMatchResult[1]).match(/\d{1,3}/g)) !== null && _cssVarToValue_match !== void 0 ? _cssVarToValue_match : []).concat([
            alpha
        ]).map(Number);
    }
    return _to_consumable_array(rgb.match(/\d+(\.\d*)?/g).map(Number)).concat([
        alpha
    ]);
};
export var rgbToRgba = function(rgb) {
    var alpha = arguments.length > 1 && arguments[1] !== void 0 ? arguments[1] : 0.5;
    return "rgba(".concat(rgbToRrtv2Rgba(rgb, alpha).join(","), ")");
};
