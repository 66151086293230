/**
 * Check if a given object implements the OrderPaymentTransactionStripe interface.
 */
export function instanceOfOrderPaymentTransactionStripe(value) {
    let isInstance = true;
    isInstance = isInstance && "sessionId" in value;
    return isInstance;
}
export function OrderPaymentTransactionStripeFromJSON(json) {
    return OrderPaymentTransactionStripeFromJSONTyped(json, false);
}
export function OrderPaymentTransactionStripeFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'sessionId': json['sessionId'],
    };
}
export function OrderPaymentTransactionStripeToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'sessionId': value.sessionId,
    };
}
