import React from 'react';
import {useTranslation} from 'react-i18next';
import {Label, LabelColorVariant} from '@santa-web/service-ui';

export type PermitLabelVariant =
  | 'subscription'
  | 'pending'
  | 'in-use'
  | 'used'
  | 'expired'
  | 'refunded'
  | 'refund-requested'
  | 'renew-failed';
type PermitLabelProps = {
  variant: PermitLabelVariant;
};

const PermitLabel = ({variant}: PermitLabelProps) => {
  const {t} = useTranslation();
  const {colorVariant, value} = React.useMemo((): {colorVariant: LabelColorVariant; value: string} => {
    switch (variant) {
      case 'subscription':
        return {colorVariant: 'secondary-green', value: t('component_permit_label_subscription')};
      case 'pending':
        return {colorVariant: 'secondary-purple', value: t('component_permit_label_pending')};
      case 'in-use':
        return {colorVariant: 'brand-dark', value: t('component_permit_label_in_use')};
      case 'used':
        return {colorVariant: 'dark', value: t('component_permit_label_used')};
      case 'expired':
        return {colorVariant: 'dark', value: t('component_permit_label_expired')};
      case 'refunded':
        return {colorVariant: 'danger-dark', value: t('component_permit_label_refunded')};
      case 'refund-requested':
        return {colorVariant: 'danger-dark', value: t('component_permit_label_refund_requested')};
      case 'renew-failed':
        return {colorVariant: 'danger-dark', value: t('component_permit_label_renew_failed')};
    }
  }, [t, variant]);
  return (
    <Label colorVariant={colorVariant} variant="small">
      {value}
    </Label>
  );
};

export default PermitLabel;
export type {PermitLabelProps};
PermitLabel.displayName = 'PermitLabel';
