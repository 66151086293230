import React from 'react';
import {css} from '@emotion/react';

import {useIsGteMediumScreen} from '@santa-web/service-ui';
import CardList, {CardListProps} from '@app/components/card/CardList';

export type MultiColumnProps = CardListProps;

const MultiColumn = React.forwardRef<HTMLDivElement, MultiColumnProps>(({children, ...props}, ref) => {
  const isGteMediumScreen = useIsGteMediumScreen();

  const modifiedChildren = isGteMediumScreen ? (
    <div
      css={css`
        display: grid;
        width: 100%;
        grid-template-columns: 1fr 1fr;
        grid-gap: 12px;
        gap: 12px;
      `}>
      {[0, 1].map(column => (
        <div key={column}>
          <div
            css={css`
              display: grid;
              width: 100%;
              grid-gap: 12px;
              gap: 12px;
            `}>
            {React.Children.toArray(children).filter((_, index) => index % 2 === column)}
          </div>
        </div>
      ))}
    </div>
  ) : (
    children
  );

  return (
    <CardList
      ref={ref}
      {...props}
      css={css`
        margin-top: 32px;
      `}>
      {modifiedChildren}
    </CardList>
  );
});

export default React.memo(MultiColumn);
