import React from 'react';
import {css, SerializedStyles} from '@emotion/react';
import {COLOR_SANTA_E} from '@riiid/design-system';

import {Typography} from '@santa-web/service-ui';

interface IconProps extends React.ComponentPropsWithoutRef<'svg'> {
  type?: 'line' | 'solid';
  size?: 'S' | 'L';
}

export interface NoDataProps extends React.ComponentPropsWithoutRef<'div'> {
  Icon: React.ComponentType<IconProps>;
  message?: string;
  customCss?: SerializedStyles;
  IconProps?: IconProps;
}

const NoData = ({Icon, message, customCss, IconProps, children, ...divProps}: NoDataProps) => {
  const description = (function () {
    if (children) return children;
    if (message) {
      return (
        <Typography variant="caption1" fontWeight="bold" color={COLOR_SANTA_E}>
          {message}
        </Typography>
      );
    }
    return null;
  })();
  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;
        align-items: center;

        ${customCss}
      `}
      {...divProps}>
      <Icon
        type="line"
        size="L"
        width={48}
        height={48}
        color={COLOR_SANTA_E}
        css={css`
          margin-bottom: 12px;
        `}
        {...IconProps}
      />
      {description}
    </div>
  );
};

export default React.memo(NoData);
