/* eslint-disable @typescript-eslint/no-non-null-assertion */
import {OrderTicketGroup, TicketGroup} from '@santa-web/gen/open-api/service';
import {useGetTicketResourceMutation, useGiftCodeModal} from '@app/features/permit/hooks';
import {useOpenNewWindow} from '@app/hooks/useOpenNewWindow';
import {useTypedRouter} from '@app/hooks/useTypedRouter';

export const useConsumeTicket = () => {
  const {openGiftCodeModal} = useGiftCodeModal();
  const {mutateAsync: getTicketResource} = useGetTicketResourceMutation();
  const openNewWindow = useOpenNewWindow();
  const router = useTypedRouter();

  return async (ticketGroup: OrderTicketGroup | TicketGroup) => {
    // NOTE: resource가 있는 ticketGroup은 항상 ticket이 하나이다.
    const ticketId = ticketGroup.tickets[0].id;
    switch (ticketGroup.permitType) {
      case 'DOWNLOADABLE_FILE':
        try {
          const {downloadableFile} = await getTicketResource(ticketId);
          await openNewWindow(downloadableFile!.downloadUrl);
        } catch (e) {
          // noop
        }
        return;
      case 'RECOMMENDED_LEARNING_CONTENT':
        router.push({
          pathname: '/course/[cycleOrder]',
          query: {cycleOrder: 'now', ticketCount: ticketGroup.tickets.filter(ticket => !ticket.isUsed).length},
        });
        return;
      case 'EXTERNAL_LINK': {
        const {externalLink} = await getTicketResource(ticketId);
        openNewWindow(externalLink!.url);
        return;
      }
      case 'GIFT_CODE': {
        const {giftCode} = await getTicketResource(ticketId);
        openGiftCodeModal({
          serialNumber: giftCode!.serialNumber,
          password: giftCode!.password,
          validUntil: giftCode!.expireAt,
          onCautionLinkClick: () => openNewWindow(giftCode!.noticeLink),
        });
        return;
      }
    }
  };
};
