import React from 'react';
import Decimal from 'decimal.js';
import {OneOfTransaction} from '@santa-web/gen/open-api/service';
import usePurchaseSuccessEvents from '@app/api/google-tag-manager/purchase';
import {OfferGroupOrderProcessingDim} from '@app/features/offer/components';
import {
  useCompleteCheckoutOfferGroupOrderMutation,
  useOfferGroupOrderQuery,
  useRevokeOfferGroupOrderMutation,
  useStoreTransactionData,
} from '@app/features/offer/hooks';
import {useTypedRouter, useTypedSearchParams} from '@app/hooks/useTypedRouter';
import {getQueryValue} from '@app/utils/router';

const OfferGroupOrderCompletePageContainer = () => {
  const router = useTypedRouter();
  const searchParams = useTypedSearchParams('/offer-group/order/complete');
  const isFirstCall = React.useRef(true);
  const storeTransactionData = useStoreTransactionData();
  const {pushPurchaseSuccess} = usePurchaseSuccessEvents();

  const {data: order} = useOfferGroupOrderQuery(searchParams.id);
  const {mutateAsync: completeOrder} = useCompleteCheckoutOfferGroupOrderMutation(searchParams.id);
  const {mutateAsync: revokeOrder} = useRevokeOfferGroupOrderMutation(searchParams.id);

  React.useEffect(() => {
    if (!order || !isFirstCall.current) {
      return;
    }
    isFirstCall.current = false;
    const paymentProvider = order.orderConfig!.selectedPaymentProvider;
    if (searchParams.error_msg) {
      revokeOrder({reason: searchParams.error_msg}).then(() => {
        router.replace({pathname: '/offer-group/order/failure', query: searchParams});
      });
      return;
    }
    let transactionInfo: OneOfTransaction;
    switch (paymentProvider) {
      case 'IAMPORT_KCP':
      case 'IAMPORT_NAVERPAY': {
        const impUid = getQueryValue(router.query, 'imp_uid');
        if (!impUid) {
          throw new Error('imp_uid is not set');
        }
        transactionInfo = {
          oneOfCase: 'IAMPORT',
          iamPort: {impUid},
        };
        break;
      }
      case 'APP_STORE': {
        const transactionData = storeTransactionData.get();
        if (!transactionData) {
          throw new Error('transactionData is not set');
        }
        transactionInfo = {
          oneOfCase: 'APP_STORE',
          appStore: {transactionData},
        };
        break;
      }
      case 'PLAY_STORE': {
        const transactionData = storeTransactionData.get();
        if (!transactionData) {
          throw new Error('transactionData is not set');
        }
        transactionInfo = {
          oneOfCase: 'PLAY_STORE',
          playStore: {purchaseToken: transactionData},
        };
        break;
      }
      case 'STRIPE': {
        const sessionId = getQueryValue(router.query, 'session_id');
        if (!sessionId) {
          throw new Error('session_id is not set');
        }
        transactionInfo = {
          oneOfCase: 'STRIPE',
          stripe: {sessionId},
        };
      }
    }
    completeOrder({transactionInfo}).then(async order => {
      if (order.status === 'PAYMENT_COMPLETED') {
        await pushPurchaseSuccess({
          revenue: new Decimal(order.amountData.totalChargeAmount).toNumber(),
          orderId: String(order.id),
          currency: order.amountData.currency,
          offerId: order.offerGroup.offer.id,
          offerDisplayName: order.offerGroup.offer.displayConfig.name,
        });
        router.replace({pathname: '/offer-group/order/result', query: searchParams});
      } else {
        router.replace({pathname: '/offer-group/order/failure', query: searchParams});
      }
    });
  }, [
    completeOrder,
    order,
    searchParams,
    router.query,
    storeTransactionData,
    router,
    revokeOrder,
    pushPurchaseSuccess,
  ]);

  return <OfferGroupOrderProcessingDim />;
};

export default OfferGroupOrderCompletePageContainer;
OfferGroupOrderCompletePageContainer.displayName = 'OfferGroupOrderCompletePageContainer';
