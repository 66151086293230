/**
 * Check if a given object implements the RegisterCouponRequest interface.
 */
export function instanceOfRegisterCouponRequest(value) {
    let isInstance = true;
    isInstance = isInstance && "couponCode" in value;
    return isInstance;
}
export function RegisterCouponRequestFromJSON(json) {
    return RegisterCouponRequestFromJSONTyped(json, false);
}
export function RegisterCouponRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'couponCode': json['couponCode'],
    };
}
export function RegisterCouponRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'couponCode': value.couponCode,
    };
}
