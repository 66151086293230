import {atom} from 'jotai';

import {LearningCellState} from '@santa-web/gen/open-api/content-learning-service';
import {learningCellService} from '@app/test-preparation/atoms/services';

type DoneCellParams = {
  shouldOptimisticUpdate?: boolean;
};

const doneCellAtom = atom(null, async (get, set, {shouldOptimisticUpdate}: DoneCellParams = {}) => {
  await set(learningCellService.updateLearningCellStateAtom, {
    learningCellState: LearningCellState.DONE,
    shouldOptimisticUpdate,
  });
});

export {doneCellAtom};
