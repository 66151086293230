/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { LearningStatisticsInfoFromJSON, LearningStatisticsInfoToJSON, } from './LearningStatisticsInfo';
import { LearningStatisticsOverviewFromJSON, LearningStatisticsOverviewToJSON, } from './LearningStatisticsOverview';
/**
 * Check if a given object implements the GetLearningStatisticsResponse interface.
 */
export function instanceOfGetLearningStatisticsResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "overview" in value;
    return isInstance;
}
export function GetLearningStatisticsResponseFromJSON(json) {
    return GetLearningStatisticsResponseFromJSONTyped(json, false);
}
export function GetLearningStatisticsResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'histories': !exists(json, 'histories') ? undefined : (json['histories'].map(LearningStatisticsInfoFromJSON)),
        'overview': LearningStatisticsOverviewFromJSON(json['overview']),
        'previousHistories': !exists(json, 'previousHistories') ? undefined : (json['previousHistories'].map(LearningStatisticsInfoFromJSON)),
    };
}
export function GetLearningStatisticsResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'histories': value.histories === undefined ? undefined : (value.histories.map(LearningStatisticsInfoToJSON)),
        'overview': LearningStatisticsOverviewToJSON(value.overview),
        'previousHistories': value.previousHistories === undefined ? undefined : (value.previousHistories.map(LearningStatisticsInfoToJSON)),
    };
}
