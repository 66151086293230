/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { GTelpUserSegmentFromJSON, GTelpUserSegmentToJSON, } from './GTelpUserSegment';
import { ToeicUserSegmentFromJSON, ToeicUserSegmentToJSON, } from './ToeicUserSegment';
import { UserSegmentCaseFromJSON, UserSegmentCaseToJSON, } from './UserSegmentCase';
/**
 * Check if a given object implements the OneOfUserSegment interface.
 */
export function instanceOfOneOfUserSegment(value) {
    let isInstance = true;
    isInstance = isInstance && "oneOfCase" in value;
    return isInstance;
}
export function OneOfUserSegmentFromJSON(json) {
    return OneOfUserSegmentFromJSONTyped(json, false);
}
export function OneOfUserSegmentFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'gTelp': !exists(json, 'gTelp') ? undefined : GTelpUserSegmentFromJSON(json['gTelp']),
        'oneOfCase': UserSegmentCaseFromJSON(json['oneOfCase']),
        'toeic': !exists(json, 'toeic') ? undefined : ToeicUserSegmentFromJSON(json['toeic']),
    };
}
export function OneOfUserSegmentToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'gTelp': GTelpUserSegmentToJSON(value.gTelp),
        'oneOfCase': UserSegmentCaseToJSON(value.oneOfCase),
        'toeic': ToeicUserSegmentToJSON(value.toeic),
    };
}
