import React, {createContext, useCallback, useContext, useEffect, useMemo, useState} from 'react';
import {COLOR_SANTA_BB, COLOR_SANTA_D} from '@riiid/design-system';
import {useAtomValue} from 'jotai';
import {v4 as generateUuid} from 'uuid';

import {
  Highlight,
  rgbToRrtv2Rgba,
  RiiidRichTextV2Path,
  RiiidRichTextViewV2Selection,
  BlankOverriding,
} from '@santa-web/service-ui';
import useTestprepEvents, {getTestPrepFrom} from '@app/api/google-tag-manager/testprep';
import useCreateUnderlineMarkups from '@app/hooks/markup/useCreateUnderlineMarkups';
import useUnderlineMarkups from '@app/hooks/markup/useUnderlineMarkups';
import {learningStore} from '@app/test-preparation/atoms/stores';
import MarkupToolsContext from '@app/test-preparation/contexts/MarkupToolsContext';
import {useIsLearningCell} from '@app/test-preparation/hooks';

type UnderlineMarkup = RiiidRichTextViewV2Selection & {
  id: string;
  snippetViewId: string;
  text: string;
  movement: string;
};

interface IUnderlineMarkupContext {
  getUnderlineHighlights(
    snippetViewId: string,
    blankPaths: RiiidRichTextV2Path[],
    blankOverridings: BlankOverriding[]
  ): Highlight[];
  onAddUnderlineMarkup(newUnderlineMarkup: Omit<UnderlineMarkup, 'id'>): void;
  onSaveUnderlineMarkups(): Promise<void>;
}

const UnderlineMarkupContext = createContext<IUnderlineMarkupContext>({
  getUnderlineHighlights: () => [],
  onAddUnderlineMarkup: () => {},
  onSaveUnderlineMarkups: () => Promise.resolve(),
});

const useUnderlineMarkupValue = (): IUnderlineMarkupContext => {
  const {pushAllQuestionContentMarkEvent, pushAllQuestionContentMarkEraseClick} = useTestprepEvents();

  const isLearningCell = useIsLearningCell();

  const cellType = useAtomValue(learningStore.cell.typeAtom);
  const cellContentType = useAtomValue(learningStore.cell.contentTypeAtom);
  const isDiagnostics = useAtomValue(learningStore.cell.isDiagnosticsAtom);

  const cisId = useAtomValue(learningStore.cis.idAtom);
  const isCompleted = useAtomValue(learningStore.cis.isCompletedAtom);

  const contentId = useAtomValue(learningStore.content.idAtom);

  const isBasicQuestion = useAtomValue(learningStore.content.isBasicQuestionAtom);

  const markupToolsContext = useContext(MarkupToolsContext);
  const {mutateAsync: createUnderlineMarkups} = useCreateUnderlineMarkups(cisId);
  const {data: savedUnderlineMarkups} = useUnderlineMarkups(cisId);

  const [underlineMarkups, setUnderlineMarkups] = useState<UnderlineMarkup[]>([]);

  useEffect(() => {
    if (savedUnderlineMarkups !== undefined && savedUnderlineMarkups.length !== 0) {
      setUnderlineMarkups(
        savedUnderlineMarkups.map(({id, displayInfo}) => ({
          ...displayInfo,
          id: id.toString(),
          anchor: displayInfo.highlight.anchor,
          focus: displayInfo.highlight.focus,
          movement: '',
        }))
      );
    } else {
      setUnderlineMarkups([]);
    }
  }, [savedUnderlineMarkups]);

  const handleRemoveUnderlineMarkup = useCallback(
    async (underlineId: string, underlineText: string) => {
      setUnderlineMarkups(prev => {
        const index = prev.findIndex(underline => underline.id === underlineId);
        if (index !== -1) {
          return [...prev.slice(0, index), ...prev.slice(index + 1)];
        }

        return prev;
      });

      await pushAllQuestionContentMarkEraseClick({
        cis_id: cisId,
        sentence: underlineText,
      });
    },
    [cisId, pushAllQuestionContentMarkEraseClick]
  );

  const handleAddUnderlineMarkup = useCallback(
    async (newUnderlineMarkup: Omit<UnderlineMarkup, 'id'>) => {
      setUnderlineMarkups(prev => [...prev, {...newUnderlineMarkup, id: generateUuid()}]);

      if (isLearningCell) {
        await pushAllQuestionContentMarkEvent({
          mark_type: 'underline',
          from: getTestPrepFrom(cellType, cellContentType, isDiagnostics, isBasicQuestion),
          cis_id: cisId,
          content_id: contentId,
          sentence: newUnderlineMarkup.text,
          delta_coord: newUnderlineMarkup.movement,
        });
      }
    },
    [
      cellContentType,
      cellType,
      cisId,
      contentId,
      isBasicQuestion,
      isDiagnostics,
      isLearningCell,
      pushAllQuestionContentMarkEvent,
    ]
  );

  const handleSaveUnderlineMarkups = useCallback(async () => {
    await createUnderlineMarkups(
      underlineMarkups.map(underlineMarkup => ({
        ...underlineMarkup,
        highlight: {
          anchor: underlineMarkup.anchor!,
          focus: underlineMarkup.focus!,
        },
      }))
    );
  }, [createUnderlineMarkups, underlineMarkups]);

  const getUnderlineMarkups = useCallback(
    (snippetViewId: string, blankPaths: RiiidRichTextV2Path[], blankOverridings: BlankOverriding[]): Highlight[] => {
      return underlineMarkups
        .filter(
          underline =>
            underline.snippetViewId === snippetViewId && underline.anchor !== null && underline.focus !== null
        )
        .map(underline => {
          // 슬라이드된 마지막 word가 blank일 경우 해당 blank의 index (마지막 word가 blank가 아니라면 -1)
          const lastSlideBlankIndex = blankPaths.findIndex(
            blankPath => blankPath.join(',') === underline.focus?.path.join(',')
          );

          const blankOverriding = lastSlideBlankIndex === -1 ? undefined : blankOverridings[lastSlideBlankIndex];
          const focusOffset =
            (blankOverriding !== undefined && isCompleted
              ? // 앞,뒤 괄호 길이 2 추가
                (blankOverriding.value?.length ?? 0) + (blankOverriding.number?.toString().length ?? 0) + 2
              : underline.focus?.offset) ?? 0;

          return {
            ...underline,
            focus: {
              path: underline.focus?.path ?? [],
              offset: focusOffset,
            },
          };
        })
        .map(underline => ({
          id: underline.id,
          type: 'underline',
          color: !isCompleted ? rgbToRrtv2Rgba(COLOR_SANTA_BB, 1) : rgbToRrtv2Rgba(COLOR_SANTA_D, 1),
          anchor: underline.anchor!,
          focus: underline.focus!,
          onClick() {
            if (markupToolsContext.selectedTool === 'ERASER') {
              handleRemoveUnderlineMarkup(underline.id, underline.text);
              return false;
            }
          },
        }));
    },
    [handleRemoveUnderlineMarkup, isCompleted, markupToolsContext.selectedTool, underlineMarkups]
  );

  return useMemo(
    () => ({
      getUnderlineHighlights: getUnderlineMarkups,
      onAddUnderlineMarkup: handleAddUnderlineMarkup,
      onSaveUnderlineMarkups: handleSaveUnderlineMarkups,
    }),
    [handleAddUnderlineMarkup, getUnderlineMarkups, handleSaveUnderlineMarkups]
  );
};

export const UnderlineMarkupContextProvider = ({children}: {children?: React.ReactNode}) => {
  const value = useUnderlineMarkupValue();

  return <UnderlineMarkupContext.Provider value={value}>{children}</UnderlineMarkupContext.Provider>;
};

export default UnderlineMarkupContext;
