/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { ContentInteractionStateFromJSON, ContentInteractionStateToJSON, } from './ContentInteractionState';
import { OneOfContentSummaryFromJSON, OneOfContentSummaryToJSON, } from './OneOfContentSummary';
/**
 * Check if a given object implements the ContentInteractionStateWithContent interface.
 */
export function instanceOfContentInteractionStateWithContent(value) {
    let isInstance = true;
    isInstance = isInstance && "contentInteractionState" in value;
    isInstance = isInstance && "contentSummary" in value;
    isInstance = isInstance && "contentUrl" in value;
    return isInstance;
}
export function ContentInteractionStateWithContentFromJSON(json) {
    return ContentInteractionStateWithContentFromJSONTyped(json, false);
}
export function ContentInteractionStateWithContentFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'contentInteractionState': ContentInteractionStateFromJSON(json['contentInteractionState']),
        'contentSummary': OneOfContentSummaryFromJSON(json['contentSummary']),
        'contentUrl': json['contentUrl'],
        'recommendedElapsedTime': !exists(json, 'recommendedElapsedTime') ? undefined : json['recommendedElapsedTime'],
    };
}
export function ContentInteractionStateWithContentToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'contentInteractionState': ContentInteractionStateToJSON(value.contentInteractionState),
        'contentSummary': OneOfContentSummaryToJSON(value.contentSummary),
        'contentUrl': value.contentUrl,
        'recommendedElapsedTime': value.recommendedElapsedTime,
    };
}
