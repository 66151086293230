import { PriceDisplayTypeFromJSON, PriceDisplayTypeToJSON, } from './PriceDisplayType';
/**
 * Check if a given object implements the OfferDisplayConfig interface.
 */
export function instanceOfOfferDisplayConfig(value) {
    let isInstance = true;
    isInstance = isInstance && "description" in value;
    isInstance = isInstance && "isRecommended" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "priceDisplayType" in value;
    return isInstance;
}
export function OfferDisplayConfigFromJSON(json) {
    return OfferDisplayConfigFromJSONTyped(json, false);
}
export function OfferDisplayConfigFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'description': json['description'],
        'isRecommended': json['isRecommended'],
        'name': json['name'],
        'priceDisplayType': PriceDisplayTypeFromJSON(json['priceDisplayType']),
    };
}
export function OfferDisplayConfigToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'description': value.description,
        'isRecommended': value.isRecommended,
        'name': value.name,
        'priceDisplayType': PriceDisplayTypeToJSON(value.priceDisplayType),
    };
}
