import {useQuery} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';
import {OfferGroup} from '@santa-web/gen/open-api/service';
import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import useIsUnderAppReview from '@app/hooks/useIsUnderAppReview';
import {getOfferGroupsQueryKey} from '@app/queryKeys';

export const useOfferGroupsQuery = (boardCode?: string) => {
  const isUnderAppReview = useIsUnderAppReview();
  const {OfferService} = useAtomValue(santaOpenapiServicesAtom);
  return useQuery<OfferGroup[]>({
    queryKey: getOfferGroupsQueryKey(boardCode, isUnderAppReview),
    queryFn: async () => {
      const {
        offerGroupBoard: {offerGroups: _offerGroups},
      } = await OfferService.getOfferGroupBoard({boardCode});
      return isUnderAppReview
        ? _offerGroups?.filter(offerGroup => offerGroup.paymentMethod === 'IN_APP_PURCHASE')
        : _offerGroups;
    },
  });
};
