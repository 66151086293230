import { ToeicVirtualExamReportCorrectStatFromJSON, ToeicVirtualExamReportCorrectStatToJSON, } from './ToeicVirtualExamReportCorrectStat';
/**
 * Check if a given object implements the ToeicVirtualExamReportPartCorrectStat interface.
 */
export function instanceOfToeicVirtualExamReportPartCorrectStat(value) {
    let isInstance = true;
    isInstance = isInstance && "part1" in value;
    isInstance = isInstance && "part2" in value;
    isInstance = isInstance && "part3" in value;
    isInstance = isInstance && "part4" in value;
    isInstance = isInstance && "part5" in value;
    isInstance = isInstance && "part6" in value;
    isInstance = isInstance && "part7" in value;
    return isInstance;
}
export function ToeicVirtualExamReportPartCorrectStatFromJSON(json) {
    return ToeicVirtualExamReportPartCorrectStatFromJSONTyped(json, false);
}
export function ToeicVirtualExamReportPartCorrectStatFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'part1': ToeicVirtualExamReportCorrectStatFromJSON(json['part1']),
        'part2': ToeicVirtualExamReportCorrectStatFromJSON(json['part2']),
        'part3': ToeicVirtualExamReportCorrectStatFromJSON(json['part3']),
        'part4': ToeicVirtualExamReportCorrectStatFromJSON(json['part4']),
        'part5': ToeicVirtualExamReportCorrectStatFromJSON(json['part5']),
        'part6': ToeicVirtualExamReportCorrectStatFromJSON(json['part6']),
        'part7': ToeicVirtualExamReportCorrectStatFromJSON(json['part7']),
    };
}
export function ToeicVirtualExamReportPartCorrectStatToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'part1': ToeicVirtualExamReportCorrectStatToJSON(value.part1),
        'part2': ToeicVirtualExamReportCorrectStatToJSON(value.part2),
        'part3': ToeicVirtualExamReportCorrectStatToJSON(value.part3),
        'part4': ToeicVirtualExamReportCorrectStatToJSON(value.part4),
        'part5': ToeicVirtualExamReportCorrectStatToJSON(value.part5),
        'part6': ToeicVirtualExamReportCorrectStatToJSON(value.part6),
        'part7': ToeicVirtualExamReportCorrectStatToJSON(value.part7),
    };
}
