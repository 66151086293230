import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';
import {ExamScoreConfig} from '@santa-web/gen/open-api/service';
import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {getScoreConfigQueryKey} from '@app/queryKeys';

const useGetScoreConfigQuery = (
  options?: UseQueryOptions<ExamScoreConfig, unknown, ExamScoreConfig, ReturnType<typeof getScoreConfigQueryKey>>
) => {
  const {ExamScoreConfigService} = useAtomValue(santaOpenapiServicesAtom);
  return useQuery(getScoreConfigQueryKey(), () => ExamScoreConfigService.getExamScoreConfig({}), options);
};

export default useGetScoreConfigQuery;
