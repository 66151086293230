import { EstimatedTagProficiencyFromJSON, EstimatedTagProficiencyToJSON, } from './EstimatedTagProficiency';
/**
 * Check if a given object implements the EstimatedTagProficiencyWithTarget interface.
 */
export function instanceOfEstimatedTagProficiencyWithTarget(value) {
    let isInstance = true;
    isInstance = isInstance && "estimatedTagProficiency" in value;
    isInstance = isInstance && "targetTagProficiency" in value;
    return isInstance;
}
export function EstimatedTagProficiencyWithTargetFromJSON(json) {
    return EstimatedTagProficiencyWithTargetFromJSONTyped(json, false);
}
export function EstimatedTagProficiencyWithTargetFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'estimatedTagProficiency': EstimatedTagProficiencyFromJSON(json['estimatedTagProficiency']),
        'targetTagProficiency': json['targetTagProficiency'],
    };
}
export function EstimatedTagProficiencyWithTargetToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'estimatedTagProficiency': EstimatedTagProficiencyToJSON(value.estimatedTagProficiency),
        'targetTagProficiency': value.targetTagProficiency,
    };
}
