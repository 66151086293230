import { StripeSubscriptionOrderFromJSON, StripeSubscriptionOrderToJSON, } from './StripeSubscriptionOrder';
import { StripeSubscriptionStatusFromJSON, StripeSubscriptionStatusToJSON, } from './StripeSubscriptionStatus';
/**
 * Check if a given object implements the StripeSubscription interface.
 */
export function instanceOfStripeSubscription(value) {
    let isInstance = true;
    isInstance = isInstance && "createdAt" in value;
    isInstance = isInstance && "expireAt" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "status" in value;
    isInstance = isInstance && "stripeSubscriptionOrder" in value;
    return isInstance;
}
export function StripeSubscriptionFromJSON(json) {
    return StripeSubscriptionFromJSONTyped(json, false);
}
export function StripeSubscriptionFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'createdAt': (new Date(json['createdAt'])),
        'expireAt': (new Date(json['expireAt'])),
        'id': json['id'],
        'status': StripeSubscriptionStatusFromJSON(json['status']),
        'stripeSubscriptionOrder': StripeSubscriptionOrderFromJSON(json['stripeSubscriptionOrder']),
    };
}
export function StripeSubscriptionToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'createdAt': (value.createdAt.toISOString()),
        'expireAt': (value.expireAt.toISOString()),
        'id': value.id,
        'status': StripeSubscriptionStatusToJSON(value.status),
        'stripeSubscriptionOrder': StripeSubscriptionOrderToJSON(value.stripeSubscriptionOrder),
    };
}
