import {useInfiniteQuery} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';

import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {getInactiveEventsQueryKey} from '@app/queryKeys';

const PAGE_SIZE = 30;

export const useInactiveEventsInfiniteQuery = () => {
  const {MarketingService} = useAtomValue(santaOpenapiServicesAtom);
  return useInfiniteQuery(
    getInactiveEventsQueryKey(),
    async ({pageParam}) =>
      await MarketingService.listEndedMarketingCampaigns({
        size: PAGE_SIZE,
        cursor: pageParam,
      }),
    {
      getNextPageParam: lastPage =>
        lastPage.marketingCampaigns.length === PAGE_SIZE ? lastPage.page?.cursor : undefined,
    }
  );
};
