import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {
  BREAKPOINT_MEDIUM,
  COLOR_SANTA_BD,
  COLOR_SANTA_CARD_A,
  COLOR_SANTA_CARD_C,
  COLOR_SANTA_E,
  COLOR_SANTA_G,
  COLOR_SANTA_J,
  COLOR_SANTA_L,
  SHADOW_A_2_DOWN,
} from '@riiid/design-system';
import CheckboxIcon from '@riiid/design-system/icons/Checkbox';
import {Label, Typography} from '@santa-web/service-ui';

type DomainListItemProps = {
  name: string;
  description: string;
  imageSrc: string;
  isSelected?: boolean;
  isOnLearning?: boolean;
  shouldHideCheckbox?: boolean;
  onClick?: () => void;
};

type DomainListItemDivProps = DomainListItemProps & React.HTMLAttributes<HTMLDivElement>;

const DomainListItem = ({
  name,
  description,
  imageSrc,
  isSelected = false,
  isOnLearning,
  shouldHideCheckbox = false,
  onClick,
  ...rest
}: DomainListItemDivProps) => {
  const {t} = useTranslation();
  return (
    <div
      tabIndex={0}
      role="radio"
      onClick={onClick}
      css={[
        css`
          width: 100%;
          height: 100%;
          display: flex;
          flex-direction: column;
          border: 1px solid ${COLOR_SANTA_E};
          border-radius: 8px;
          background-color: ${COLOR_SANTA_CARD_A};
          overflow: hidden;

          &:active {
            background-color: ${COLOR_SANTA_CARD_C};
          }

          @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
            position: relative;
            height: auto;
            flex-direction: row;
          }
        `,
        isSelected
          ? css`
              background-color: ${COLOR_SANTA_CARD_C};
            `
          : css`
              @media (hover: hover) {
                &:not(:active):hover {
                  box-shadow: ${SHADOW_A_2_DOWN};
                }
              }
            `,
      ]}
      {...rest}>
      <div
        css={css`
          position: relative;
          flex: 1;
          min-height: 0;
          background-size: cover;
          background-image: url(${imageSrc});
          background-position: center;

          @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
            position: absolute;
            height: 100%;
            width: 280px;
          }
        `}>
        {isOnLearning && (
          <Label
            css={css`
              position: absolute;
              top: 20px;
              right: 20px;
            `}
            colorVariant="brand-light-fix"
            variant="small-round">
            {t('component_domain_list_item_on_learning_label')}
          </Label>
        )}
      </div>
      <div
        css={css`
          flex-grow: 0;
          flex-basis: auto;
          flex-shrink: 0;
          padding: 20px;

          @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
            flex: 1;
            margin-left: 280px;
          }
        `}>
        <div
          css={css`
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-bottom: 12px;
          `}>
          <Typography
            css={css`
              margin-right: 20px;
            `}
            variant="body1"
            fontWeight="bold"
            color={COLOR_SANTA_L}>
            {name}
          </Typography>
          {!shouldHideCheckbox && (
            <CheckboxIcon
              width={28}
              height={28}
              type={isSelected ? 'solid' : 'line'}
              color={isSelected ? COLOR_SANTA_BD : COLOR_SANTA_G}
            />
          )}
        </div>
        <hr
          css={css`
            border: none;
            border-bottom: 1px solid ${COLOR_SANTA_E};
            margin: 0;
            margin-bottom: 12px;
          `}
        />
        <Typography component="p" variant="caption1" color={COLOR_SANTA_J}>
          {description}
        </Typography>
      </div>
    </div>
  );
};

export default DomainListItem;
export type {DomainListItemProps};
DomainListItem.displayName = 'DomainListItem';
