import React from 'react';
import {css} from '@emotion/react';
import {COLOR_SANTA_I, COLOR_SANTA_L} from '@riiid/design-system';

import {Typography} from '@santa-web/service-ui';
import BaseCard from '@app/components/card/BaseCard';

import {UserSegType} from './types';
import usePieImage from './usePieImage';

export type AnalyticsPieImageCardProps = Omit<React.ComponentPropsWithoutRef<'div'>, 'title' | 'content'> & {
  title?: string | null;
  content?: string | null;
  userSeg: UserSegType;
};

const AnalyticsPieImageCard = React.forwardRef<HTMLDivElement, AnalyticsPieImageCardProps>(
  ({title, content, userSeg, ...props}, ref) => {
    const pieImage = usePieImage(userSeg);
    return (
      <BaseCard
        css={css`
          padding: 20px 20px 24px;
        `}
        {...props}
        ref={ref}>
        <Typography
          css={css`
            margin-bottom: 12px;
          `}
          fontWeight="bold"
          variant="body2"
          color={COLOR_SANTA_L}>
          {title}
        </Typography>
        <Typography
          css={css`
            margin-bottom: 32px;
          `}
          fontWeight="regular"
          variant="body3"
          color={COLOR_SANTA_I}>
          {content}
        </Typography>
        <div
          css={css`
            display: flex;
            justify-content: center;
          `}>
          <img
            css={css`
              max-width: 280px;
              width: 100%;
              height: 100%;
            `}
            src={pieImage}
            data-testid="AnalyticsPieImageCard-Image"
          />
        </div>
      </BaseCard>
    );
  }
);

export default React.memo(AnalyticsPieImageCard);
