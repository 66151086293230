import React from 'react';
import {useTranslation} from 'react-i18next';
import useOfferEvents from '@app/api/google-tag-manager/offer';
import {ListSkeleton} from '@app/features/offer/components';
import {useCreateOfferGroupOrderMutation, useOfferGroupQuery} from '@app/features/offer/hooks';
import {mapOfferGroupToOfferGroupDetail} from '@app/features/offer/utils';
import {OfferListPageView} from '@app/features/offer/views';
import useNowBySecond from '@app/hooks/useNowBySecond';
import useUser from '@app/hooks/user/useUser';
import {useTypedRouter, useTypedSearchParams} from '@app/hooks/useTypedRouter';
import {B2bState} from '@app/misc/query-state';

const OfferListPageContainer = () => {
  const {t} = useTranslation();
  const {data: user} = useUser();
  const router = useTypedRouter();
  const now = useNowBySecond();
  const searchParams = useTypedSearchParams('/offer-group/offer/list');
  const {boardCode} = searchParams;
  const isAnonymous = user?.registeredAt == null;
  const {pushClickOfferBuy} = useOfferEvents();

  const {data: offerGroup} = useOfferGroupQuery(searchParams.offerGroupId);
  const {mutateAsync: createOfferGroupOrder} = useCreateOfferGroupOrderMutation();

  const offerCards = offerGroup
    ? mapOfferGroupToOfferGroupDetail({
        t,
        isAnonymous,
        offerGroup,
        now,
        onPurchaseButtonClick: async (offerId: number) => {
          pushClickOfferBuy({
            offerGroupId: offerGroup.id,
            boardCode,
            offerId,
            offerDisplayName: offerGroup.offers.find(offer => offer.id === offerId)!.displayConfig.name,
          });
          if (isAnonymous) {
            router.push({pathname: '/onboarding/intro', query: new B2bState({productId: String(offerId)}).toQuery()});
            return;
          }
          const order = await createOfferGroupOrder({offerId});
          router.push({pathname: '/offer-group/order', query: {...searchParams, id: order.id, offerId}});
        },
      }).offerCards
    : undefined;

  return offerCards ? (
    <OfferListPageView offerCards={offerCards} onGoBack={router.back} />
  ) : (
    <ListSkeleton onGoBack={router.back} shouldHideInfoSkeleton />
  );
};

export default OfferListPageContainer;
OfferListPageContainer.displayName = 'OfferListPageContainer';
