import Link from 'next/link';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';

import {DiagnosisReport} from '@santa-web/gen/open-api/content-learning-service';
import {TopNavBar, Button, SinglePageLayout, useToastContext} from '@santa-web/service-ui';
import FrameLayout from '@app/components/FrameLayout';
import ScrollLayout from '@app/components/ScrollLayout';
import {DiagnosisReportContentView, DiagnosisReportTab} from '@app/features/diagnosis/views';
import useContentInteractionStateWithContents from '@app/hooks/content-interaction-state/useContentInteractionStateWithContents';
import useLearningCellReportQuery from '@app/hooks/learning-cell/useLearningCellReportQuery';
import {use100vh} from '@app/hooks/use100vh';
import {isAppBridgeAvailable} from '@app/utils/app-bridge';
import {getIsMobileOs, getUserAgent} from '@app/utils/user-agent';

export interface DiagnosisReportViewProps {
  diagnosisReport: DiagnosisReport;
  isLoading?: boolean;
  isCompletingCycle?: boolean;
  isConfettiVisible?: boolean;
  userName?: string;
  cellId: number;
  onNavBarClick: React.MouseEventHandler;
  onRecommendedResultFinishLevelTestClick: React.MouseEventHandler;
  onPredictionScoreDescriptionButtonClick: React.MouseEventHandler;
  onTabChange(tab: DiagnosisReportTab): void;
}

const DiagnosisReportView = ({onNavBarClick, cellId, ...props}: DiagnosisReportViewProps) => {
  const viewportHeight = use100vh();
  const {isLoading, isCompletingCycle, onRecommendedResultFinishLevelTestClick} = props;
  const {t} = useTranslation();
  const {toastContainer} = useToastContext();
  const {data: learningCellReport} = useLearningCellReportQuery(cellId);
  const {data: contentInteractionStateWithContents} = useContentInteractionStateWithContents(cellId);
  const isMobileWeb = getIsMobileOs(getUserAgent()) && !isAppBridgeAvailable();
  const {topNavBar, bottomArea} = React.useMemo(() => {
    if (isMobileWeb) {
      return {
        bottomArea: isMobileWeb && (
          <>
            <Link href="/app-download" legacyBehavior>
              <Button colorVariant="brand" variant="bottom-fix" isFullWidth={true}>
                {t('diagnosis_report_view_go_to_app_download_page')}
              </Button>
            </Link>
          </>
        ),
      };
    }
    return {
      topNavBar: !isCompletingCycle && <TopNavBar paginationIcon="exit" onPaginationIconClick={onNavBarClick} />,
      bottomArea: isCompletingCycle && (
        <>
          <Button
            colorVariant="brand"
            variant="bottom-fix"
            isFullWidth={true}
            onClick={onRecommendedResultFinishLevelTestClick}>
            {t('learning_cell_report_top_nav_button')}
          </Button>
        </>
      ),
    };
  }, [isCompletingCycle, isMobileWeb, onNavBarClick, onRecommendedResultFinishLevelTestClick, t]);

  return (
    <div
      css={[
        css`
          height: ${viewportHeight};
        `,
        !topNavBar &&
          css`
            padding-top: var(--mobile-safe-area-top);
          `,
      ]}>
      <FrameLayout topNavBar={topNavBar} bottomArea={bottomArea}>
        <SinglePageLayout isLoading={isLoading}>
          {props.diagnosisReport && (
            <ScrollLayout>
              <DiagnosisReportContentView
                isPredictionScoreHidden={isMobileWeb}
                learningCellReport={learningCellReport}
                contentInteractionStatesWithContents={contentInteractionStateWithContents}
                {...props}
              />
              {toastContainer}
            </ScrollLayout>
          )}
        </SinglePageLayout>
      </FrameLayout>
    </div>
  );
};

export default React.memo(DiagnosisReportView);
