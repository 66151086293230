/**
 * Check if a given object implements the PartialEstimatedScore interface.
 */
export function instanceOfPartialEstimatedScore(value) {
    let isInstance = true;
    isInstance = isInstance && "maxScore" in value;
    isInstance = isInstance && "name" in value;
    isInstance = isInstance && "score" in value;
    isInstance = isInstance && "shortName" in value;
    return isInstance;
}
export function PartialEstimatedScoreFromJSON(json) {
    return PartialEstimatedScoreFromJSONTyped(json, false);
}
export function PartialEstimatedScoreFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'maxScore': json['maxScore'],
        'name': json['name'],
        'score': json['score'],
        'shortName': json['shortName'],
    };
}
export function PartialEstimatedScoreToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'maxScore': value.maxScore,
        'name': value.name,
        'score': value.score,
        'shortName': value.shortName,
    };
}
