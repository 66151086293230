import {useCallback, useMemo} from 'react';

import {BrowserStorage} from '@santa-web/service-common';
import UserUnderlineTutorialAlertDialog from '@app/components/alert-dialog/UserUnderlineTutorialAlertDialog';
import {useDialogContext} from '@app/contexts/DialogContext';

type UserUnderlineTutorialStorage = {
  TUTORIAL_TESTPREP_UNDERLINE_USAGE: string;
};

const useOpenUserUnderlineTutorialAlertDialog = () => {
  const {openDialog} = useDialogContext();
  const userUnderlineTutorialStorage = useMemo(
    () => new BrowserStorage<UserUnderlineTutorialStorage>(localStorage),
    []
  );

  const handleOpenDialog = useCallback(() => {
    if (userUnderlineTutorialStorage.getItem('TUTORIAL_TESTPREP_UNDERLINE_USAGE')) {
      return;
    }

    openDialog(<UserUnderlineTutorialAlertDialog />);
    userUnderlineTutorialStorage.setItem('TUTORIAL_TESTPREP_UNDERLINE_USAGE', 'true');
  }, [openDialog, userUnderlineTutorialStorage]);

  return handleOpenDialog;
};

export default useOpenUserUnderlineTutorialAlertDialog;
