import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {COLOR_SANTA_G} from '@riiid/design-system';
import ReportErrorIcon from '@riiid/design-system/icons/ReportError';
import {Typography} from '@santa-web/service-ui';
import {ListLayout, ListLayoutProps} from '@app/features/offer/components';

type EmptyOfferGroupBoardProps = Pick<ListLayoutProps, 'onGoBack'>;

const EmptyOfferGroupBoard = ({onGoBack}: EmptyOfferGroupBoardProps) => {
  const {t} = useTranslation();
  return (
    <ListLayout onGoBack={onGoBack}>
      <div
        css={css`
          height: 100%;
          display: grid;
          gap: 8px;
          align-items: center;
          align-content: center;
          justify-items: center;
        `}>
        <ReportErrorIcon
          css={css`
            margin-right: 12px;
          `}
          color={COLOR_SANTA_G}
          width={40}
          height={40}
          type="line"
        />
        <Typography color={COLOR_SANTA_G} variant="caption1">
          {t('page_offer_group_board_list_empty')}
        </Typography>
      </div>
    </ListLayout>
  );
};

export default EmptyOfferGroupBoard;
export type {EmptyOfferGroupBoardProps};
EmptyOfferGroupBoard.displayName = 'EmptyOfferGroupBoard';
