import React from 'react';
import {css} from '@emotion/react';
import {atom, useAtom} from 'jotai';
import useOnboardingEvents from '@app/api/google-tag-manager/onboarding';
import {DROPDOWN_Z_INDEX} from '@app/constants/zIndex';
import {FlashDealTimer} from '@app/features/marketing/components';
import {useIsNewUserFlashDealAvailable} from '@app/features/marketing/hooks';
import {useMyPassStatusQuery} from '@app/features/permit';
import useMobileSafeArea from '@app/hooks/useMobileSafeArea';
import useNowBySecond from '@app/hooks/useNowBySecond';
import {useTypedRouter} from '@app/hooks/useTypedRouter';

const shouldShowTimerAtom = atom(false);
const shouldUmountTimerAtom = atom(false);

const FlashDealTimerContainer = () => {
  const router = useTypedRouter();
  const isFlashDealAvailable = useIsNewUserFlashDealAvailable();
  const {data: passStatus} = useMyPassStatusQuery();
  const {data: mobileSafeArea} = useMobileSafeArea();
  const {pushOnboardingTimerClick} = useOnboardingEvents();

  const [shouldUnmountTimer, setShouldUnmountTimer] = useAtom(shouldUmountTimerAtom);
  const [shouldShowTimer, setShouldShowTimer] = useAtom(shouldShowTimerAtom);
  const now = useNowBySecond();

  const remainedTimeMs: number | null = React.useMemo(() => {
    if (!isFlashDealAvailable || !passStatus?.activePass) {
      return null;
    }
    const ms = passStatus.activePass.expireAt.getTime() - now.getTime();
    return ms <= 0 ? 0 : ms;
  }, [isFlashDealAvailable, now, passStatus]);

  React.useEffect(() => {
    if (remainedTimeMs !== null && remainedTimeMs > 1000) {
      setShouldShowTimer(true);
    } else {
      setShouldShowTimer(false);
    }
  }, [remainedTimeMs, setShouldShowTimer]);

  return shouldUnmountTimer ? null : (
    <div
      onTransitionEnd={() => setTimeout(() => setShouldUnmountTimer(!shouldShowTimer), 1000)}
      css={[
        css`
          width: 100%;

          position: fixed;
          left: 50%;
          bottom: ${(mobileSafeArea?.bottom ?? 0) + 70}px;
          z-index: ${DROPDOWN_Z_INDEX};
          opacity: 0;

          transition: opacity 1000ms ease-in-out;
          transition-delay: 1ms;
        `,
        shouldShowTimer &&
          css`
            opacity: 1;
          `,
      ]}>
      <FlashDealTimer
        css={css`
          transform: translateX(-50%);
        `}
        onClick={() => {
          pushOnboardingTimerClick();
          router.push({pathname: '/event/flash-deal/new-user', query: {referrer: 'timer'}});
        }}
        remainedTimeMs={remainedTimeMs ?? 0}
      />
    </div>
  );
};

export default React.memo(FlashDealTimerContainer);
FlashDealTimerContainer.displayName = 'FlashDealTimerContainer';
