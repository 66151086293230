import React from 'react';
import Confetti from 'react-confetti';
import {useTranslation} from 'react-i18next';
import {useWindowSize} from 'react-use';
import {css} from '@emotion/react';
import {COLOR_SANTA_A, COLOR_SANTA_I, COLOR_SANTA_L} from '@riiid/design-system';
import Chevronwithcircle from '@riiid/design-system/icons/Chevronwithcircle';
import {Typography, useIsGteMediumScreen} from '@santa-web/service-ui';
import AiBlockCard from '@app/components/card/AiBlockCard';

import {BREAKPOINT_MEDIUM} from '@app/constants/breakpoints';

const confettiColors = [
  '#2177B2',
  '#3752B3',
  '#0043CE',
  '#1F6BDE',
  '#4589FF',
  '#2A9BD9',
  '#576BBE',
  '#4589FF',
  '#5BC3EE',
  '#7587C9',
  '#78A9FF',
  '#86D4F3',
  '#9DA8D8',
  '#A6C8FF',
  '#B5E5F8',
  '#C4CAE8',
];

export interface ReportScoreSectionProps {
  subTitle?: string;
  mainTitle: string | React.ReactNode;
  content: React.ReactNode;
  subContent?: React.ReactNode;
  isConfettiVisible?: boolean;
  onAiBlockClick?: React.MouseEventHandler;
}

const ReportScoreSection = React.forwardRef<HTMLDivElement, ReportScoreSectionProps>(
  ({subTitle, mainTitle, content, subContent, isConfettiVisible, onAiBlockClick, ...props}, ref) => {
    const {t} = useTranslation();
    const isGteMediumScreen = useIsGteMediumScreen();
    const {width} = useWindowSize();
    const _mainTitle =
      typeof mainTitle === 'string' ? (
        <Typography
          variant="h5"
          fontWeight="regular"
          color={COLOR_SANTA_L}
          css={css`
            white-space: pre-line;
            margin-bottom: 40px;
          `}>
          {mainTitle}
        </Typography>
      ) : (
        mainTitle
      );
    return (
      <div
        ref={ref}
        css={css`
          max-width: 1080px;
          width: 100%;
          margin: 0 auto;
          padding: 40px 20px;
          background: linear-gradient(
            0deg,
            ${COLOR_SANTA_A} 120px,
            rgba(255, 255, 255, 0) 360px,
            rgba(255, 255, 255, 0)
          );

          @media (min-width: ${BREAKPOINT_MEDIUM}) {
            padding: 80px 40px 40px;
            background: linear-gradient(0deg, ${COLOR_SANTA_A}, rgba(255, 255, 255, 0) 240px);
          }
        `}
        {...props}>
        <div
          css={css`
            @media (min-width: ${BREAKPOINT_MEDIUM}) {
              display: grid;
              grid-auto-flow: column;
              grid-auto-columns: 1fr;
            }
          `}>
          <div
            css={css`
              flex-grow: 1;
            `}>
            {subTitle && (
              <Typography
                variant="caption1"
                fontWeight="bold"
                color={COLOR_SANTA_I}
                css={css`
                  display: block;
                  margin-bottom: 24px;
                `}>
                {subTitle}
              </Typography>
            )}
            {_mainTitle}
            {subContent && (
              <div
                css={css`
                  display: flex;
                `}>
                {subContent}
              </div>
            )}
          </div>
          <div
            css={css`
              display: flex;
              justify-content: center;
            `}>
            {content}
          </div>
        </div>
        <AiBlockCard
          css={css`
            margin-top: 40px;
          `}
          type="info"
          content={t('diagnosis_report_view_ai_block_card_content')}
          RightIcon={Chevronwithcircle}
          onClick={onAiBlockClick}
        />
        {isConfettiVisible && (
          <Confetti
            css={css`
              z-index: -1 !important;
            `}
            numberOfPieces={isGteMediumScreen ? 500 : 300}
            confettiSource={{x: 0, y: -20, w: width, h: 0}}
            width={width}
            colors={confettiColors}
            recycle={false}
            initialVelocityY={isGteMediumScreen ? 20 : 7}
            initialVelocityX={0.5}
            tweenDuration={3000}
            gravity={0.2}
            opacity={0.9}
            drawShape={ctx => {
              ctx.fillRect(0, 0, 10, 10);
            }}
          />
        )}
      </div>
    );
  }
);

export default React.memo(ReportScoreSection);
