import React from 'react';
import {css} from '@emotion/react';
import {BREAKPOINT_MEDIUM} from '@riiid/design-system';

export interface FrameLayoutProps extends React.HTMLAttributes<HTMLDivElement> {
  topNavBar?: React.ReactNode;
  children?: React.ReactNode;
  leftNavBar?: React.ReactNode;
}

const FrameWithLeftNavBarTabletLayout = React.forwardRef<HTMLDivElement, FrameLayoutProps>(
  ({leftNavBar, children, ...props}, ref) => {
    return (
      <div
        ref={ref}
        css={css`
          display: flex;
          flex-direction: column;

          @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
            align-items: start;
            flex-direction: row;
            max-width: 1080px;
            margin: 0 auto;
            padding: 0 40px;
          }
        `}
        {...props}>
        <div
          css={css`
            @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
              width: 224px;
            }
          `}
        />
        <div
          css={css`
            width: 100%;
            @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
              flex: 1;
            }
          `}>
          {children}
        </div>
      </div>
    );
  }
);

export default React.memo(FrameWithLeftNavBarTabletLayout);
