import {useRouter} from 'next/router';
import {useCallback, useMemo} from 'react';
import {useAtomValue} from 'jotai';

import {BrowserStorage} from '@santa-web/service-common';
import tokenManagerAtom from '@app/atoms/core/token-manager';
import {FlashDealStorage} from '@app/features/marketing';

export function useLogout(redirectionPath = '/login') {
  const router = useRouter();
  const tokenManager = useAtomValue(tokenManagerAtom);
  const flashDealStorage = useMemo(() => new BrowserStorage<FlashDealStorage>(localStorage), []);

  const removeFlashDealStorage = useCallback(() => {
    flashDealStorage.removeItem('l2e-flash-deal-display-count');
    flashDealStorage.removeItem('next-l2e-flash-deal-display-at');
  }, [flashDealStorage]);

  return useCallback(async () => {
    removeFlashDealStorage();
    await tokenManager.deleteToken();
    await router.push(redirectionPath);
    // TODO: Remove social login tokens
  }, [redirectionPath, router, tokenManager, removeFlashDealStorage]);
}
