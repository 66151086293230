import { OfferDisplayConfigFromJSON, OfferDisplayConfigToJSON, } from './OfferDisplayConfig';
import { OneOfOfferAdditionalPropertiesFromJSON, OneOfOfferAdditionalPropertiesToJSON, } from './OneOfOfferAdditionalProperties';
import { PermitConfigFromJSON, PermitConfigToJSON, } from './PermitConfig';
import { PriceConfigFromJSON, PriceConfigToJSON, } from './PriceConfig';
/**
 * Check if a given object implements the Offer interface.
 */
export function instanceOfOffer(value) {
    let isInstance = true;
    isInstance = isInstance && "additionalProperties" in value;
    isInstance = isInstance && "displayConfig" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "isPurchasable" in value;
    isInstance = isInstance && "noticeLink" in value;
    isInstance = isInstance && "permitConfig" in value;
    isInstance = isInstance && "priceConfig" in value;
    return isInstance;
}
export function OfferFromJSON(json) {
    return OfferFromJSONTyped(json, false);
}
export function OfferFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'additionalProperties': OneOfOfferAdditionalPropertiesFromJSON(json['additionalProperties']),
        'displayConfig': OfferDisplayConfigFromJSON(json['displayConfig']),
        'id': json['id'],
        'isPurchasable': json['isPurchasable'],
        'noticeLink': json['noticeLink'],
        'permitConfig': PermitConfigFromJSON(json['permitConfig']),
        'priceConfig': PriceConfigFromJSON(json['priceConfig']),
    };
}
export function OfferToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'additionalProperties': OneOfOfferAdditionalPropertiesToJSON(value.additionalProperties),
        'displayConfig': OfferDisplayConfigToJSON(value.displayConfig),
        'id': value.id,
        'isPurchasable': value.isPurchasable,
        'noticeLink': value.noticeLink,
        'permitConfig': PermitConfigToJSON(value.permitConfig),
        'priceConfig': PriceConfigToJSON(value.priceConfig),
    };
}
