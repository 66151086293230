import React from 'react';
import {useTranslation} from 'react-i18next';

import {css} from '@emotion/react';
import {Button} from '@santa-web/service-ui';
import {BottomSheetWithDim} from '@app/components';
import {ALERT_DIALOG_Z_INDEX} from '@app/constants/zIndex';
import {useDialogContext} from '@app/contexts/DialogContext';

const useDiagnosisListeningBottomSheet = () => {
  const {openDialog, closeDialog} = useDialogContext();
  const {t} = useTranslation();
  const openDiagnosisListeningBottomSheet = React.useCallback(
    (isListeningRequired: boolean, _onAnswer: (shouldIncludeListening: boolean) => void) => {
      const onAnswer = (shouldIncludeListening: boolean) => {
        _onAnswer(shouldIncludeListening);
        closeDialog();
      };
      openDialog(
        <BottomSheetWithDim
          css={css`
            z-index: ${ALERT_DIALOG_Z_INDEX};
          `}
          isOpened
          BottomSheetProps={{
            title: t('component_diagnosis_listening_bottom_sheet_title'),
            description: t('component_diagnosis_listening_bottom_sheet_description'),
            onCloseClick: closeDialog,
            isLoadingAiAnimationVisible: true,
            buttons: [
              ...(function* () {
                yield (
                  <Button
                    key="with-listening"
                    variant="solid"
                    colorVariant="brand"
                    isFullWidth
                    onClick={() => onAnswer(true)}>
                    {t('component_diagnosis_listening_bottom_sheet_with_button')}
                  </Button>
                );
                if (!isListeningRequired) {
                  yield (
                    <Button key="skip-listening" variant="box-line" isFullWidth onClick={() => onAnswer(false)}>
                      {t('component_diagnosis_listening_bottom_sheet_skip_button')}
                    </Button>
                  );
                }
              })(),
            ],
          }}
        />
      );
    },
    [openDialog, closeDialog, t]
  );
  return {
    openDiagnosisListeningBottomSheet,
  };
};

export default useDiagnosisListeningBottomSheet;
