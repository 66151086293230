import {useEffect, useState} from 'react';
import {css, keyframes} from '@emotion/react';
import {BREAKPOINT_MEDIUM} from '@riiid/design-system';

const bottomSheetInKeyframes = keyframes`
  from {
    opacity: 0;
    transform: translateY(100%);
  }

  to {
    opacity: 100%;
    transform: none;
  }
`;

export const bottomSheetOutKeyframes = keyframes`
  from {
    opacity: 100%;
    transform: none;
  }

  to {
    opacity: 0;
    transform: translateY(100%);
  }
`;

type AnimationState = 'OPEN' | 'CLOSE' | 'INIT';

const bottomSheetAnimationCss = (animationState: AnimationState) => {
  if (animationState === 'INIT') return;

  return css`
    animation: ${animationState === 'OPEN' ? bottomSheetInKeyframes : bottomSheetOutKeyframes} 0.17s
      cubic-bezier(0.18, 0.64, 0.52, 1) forwards;
  `;
};

export const useBottomSheetAnimation = (isOpened = false) => {
  const [animationState, setAnimationState] = useState<AnimationState>('INIT');

  useEffect(() => {
    if (animationState !== 'OPEN' && isOpened) {
      setAnimationState('OPEN');
    } else if (animationState === 'OPEN' && !isOpened) {
      setAnimationState('CLOSE');
    }
  }, [isOpened, animationState]);

  return css`
    opacity: 0;

    @media screen and (max-width: calc(${BREAKPOINT_MEDIUM} - 1px)) {
      transform: translateY(100%);
      ${bottomSheetAnimationCss(animationState)}
    }
    @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
      transition: opacity 0.17s cubic-bezier(0.18, 0.64, 0.52, 1);
      user-select: none;

      ${animationState === 'OPEN' &&
      css`
        opacity: 1;
        user-select: initial;
      `}
    }
  `;
};
