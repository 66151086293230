import React from 'react';
import {useTranslation} from 'react-i18next';
import {css, Global} from '@emotion/react';
import {useAtomValue} from 'jotai';
import {BrowserStorage} from '@santa-web/service-common';
import {RangeInputProps} from '@santa-web/service-ui';
import useTestprepEvents from '@app/api/google-tag-manager/testprep';
import {learningStore} from '@app/test-preparation/atoms/stores';

export const TESTPREP_FONT_SIZES = [16, 18, 22, 26];
export const STORAGE_KEY = 'CURRENT_FONT_SIZE_INDEX';

export interface ITestprepConfigFontSize {
  rangeInputProps: RangeInputProps;
  StyleOverrides: React.ReactNode;
}

export default function useTestprepConfigFontSize(): ITestprepConfigFontSize {
  const {t} = useTranslation();
  const {pushAllContentFontSizeChoice} = useTestprepEvents();
  const currentCisId = useAtomValue(learningStore.cis.idAtom);
  const currentContentId = useAtomValue(learningStore.content.idAtom);

  const storage = React.useMemo(() => new BrowserStorage(window.localStorage), []);
  const initialFontSizeIndex = Number.isSafeInteger(Number(storage.getItem(STORAGE_KEY)))
    ? Number(storage.getItem(STORAGE_KEY))
    : 0;
  const [currentFontSizeIndex, setCurrentFontSizeIndex] = React.useState(initialFontSizeIndex);

  return React.useMemo(
    () => ({
      rangeInputProps: {
        min: 0,
        max: TESTPREP_FONT_SIZES.length - 1,
        defaultValue: currentFontSizeIndex,
        step: 1,
        onChange: (fontSizeIndex: number) => {
          if (fontSizeIndex >= TESTPREP_FONT_SIZES.length) {
            throw new Error(`invalid try to adjust font size: (${fontSizeIndex})`);
          }
          pushAllContentFontSizeChoice({
            cis_id: currentCisId,
            content_id: currentContentId,
            font_size: TESTPREP_FONT_SIZES[fontSizeIndex],
          });
          setCurrentFontSizeIndex(fontSizeIndex);
          storage.setItem(STORAGE_KEY, fontSizeIndex);
        },
        leftLabel: t('testprep_config_switchable_font_size_adjust_smaller'),
        rightLabel: t('testprep_config_switchable_font_size_adjust_larger'),
      },
      StyleOverrides: (
        <Global
          styles={css`
            html {
              font-size: ${TESTPREP_FONT_SIZES[currentFontSizeIndex]}px !important;
            }
          `}
        />
      ),
    }),
    [currentCisId, currentContentId, currentFontSizeIndex, pushAllContentFontSizeChoice, storage, t]
  );
}
