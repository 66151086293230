/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { ListToeicVirtualExamContentInteractionStatesResponseFromJSON, ToeicVirtualExamEventToJSON, ToeicVirtualExamInstanceFromJSON, ToeicVirtualExamReportFromJSON, } from '../models';
/**
 *
 */
export class ToeicVirtualExamV1Api extends runtime.BaseAPI {
    /**
     * TOEIC 선택학습 실전 모의고사 학습 이벤트를 모두 삭제한다. 해당 API는 대상 TOEIC 선택학습 실전 모의고사의 답안을 최종 제출하지 않은 경우에만 정상 작동한다.
     */
    async clearToeicVirtualExamEventsRaw(requestParameters, initOverrides) {
        if (requestParameters.learningCellId === null || requestParameters.learningCellId === undefined) {
            throw new runtime.RequiredError('learningCellId', 'Required parameter requestParameters.learningCellId was null or undefined when calling clearToeicVirtualExamEvents.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/content-learning/v1/toeic-virtual-exams/{learningCellId}/events`.replace(`{${"learningCellId"}}`, encodeURIComponent(String(requestParameters.learningCellId))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * TOEIC 선택학습 실전 모의고사 학습 이벤트를 모두 삭제한다. 해당 API는 대상 TOEIC 선택학습 실전 모의고사의 답안을 최종 제출하지 않은 경우에만 정상 작동한다.
     */
    async clearToeicVirtualExamEvents(requestParameters, initOverrides) {
        await this.clearToeicVirtualExamEventsRaw(requestParameters, initOverrides);
    }
    /**
     * TOEIC 선택학습 실전 모의고사 인스턴스를 조회한다.
     */
    async getToeicVirtualExamInstanceRaw(requestParameters, initOverrides) {
        if (requestParameters.learningCellId === null || requestParameters.learningCellId === undefined) {
            throw new runtime.RequiredError('learningCellId', 'Required parameter requestParameters.learningCellId was null or undefined when calling getToeicVirtualExamInstance.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/content-learning/v1/toeic-virtual-exams/{learningCellId}`.replace(`{${"learningCellId"}}`, encodeURIComponent(String(requestParameters.learningCellId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ToeicVirtualExamInstanceFromJSON(jsonValue));
    }
    /**
     * TOEIC 선택학습 실전 모의고사 인스턴스를 조회한다.
     */
    async getToeicVirtualExamInstance(requestParameters, initOverrides) {
        const response = await this.getToeicVirtualExamInstanceRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * TOEIC 선택학습 실전 모의고사 결과 리포트를 조회한다. 해당 API는 대상 TOEIC 선택학습 실전 모의고사의 채점이 완료된 경우에만 정상 작동한다.
     */
    async getToeicVirtualExamReportRaw(requestParameters, initOverrides) {
        if (requestParameters.learningCellId === null || requestParameters.learningCellId === undefined) {
            throw new runtime.RequiredError('learningCellId', 'Required parameter requestParameters.learningCellId was null or undefined when calling getToeicVirtualExamReport.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/content-learning/v1/toeic-virtual-exams/{learningCellId}/report`.replace(`{${"learningCellId"}}`, encodeURIComponent(String(requestParameters.learningCellId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ToeicVirtualExamReportFromJSON(jsonValue));
    }
    /**
     * TOEIC 선택학습 실전 모의고사 결과 리포트를 조회한다. 해당 API는 대상 TOEIC 선택학습 실전 모의고사의 채점이 완료된 경우에만 정상 작동한다.
     */
    async getToeicVirtualExamReport(requestParameters, initOverrides) {
        const response = await this.getToeicVirtualExamReportRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 완료된 TOEIC 선택학습 실전 모의고사 인스턴스의 문제풀이 이력을 조회한다. 조회 순서는 실전 모의고사 세트 내 문제 콘텐츠 순서와 동일하다. 해당 API는 대상 TOEIC 선택학습 실전 모의고사의 채점이 완료된 경우에만 정상 작동한다.
     */
    async listToeicVirtualExamContentInteractionStatesRaw(requestParameters, initOverrides) {
        if (requestParameters.learningCellId === null || requestParameters.learningCellId === undefined) {
            throw new runtime.RequiredError('learningCellId', 'Required parameter requestParameters.learningCellId was null or undefined when calling listToeicVirtualExamContentInteractionStates.');
        }
        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size', 'Required parameter requestParameters.size was null or undefined when calling listToeicVirtualExamContentInteractionStates.');
        }
        const queryParameters = {};
        if (requestParameters.cursor !== undefined) {
            queryParameters['cursor'] = requestParameters.cursor;
        }
        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }
        if (requestParameters.correctnessFilter !== undefined) {
            queryParameters['correctnessFilter'] = requestParameters.correctnessFilter;
        }
        if (requestParameters.bookmarkedOnly !== undefined) {
            queryParameters['bookmarkedOnly'] = requestParameters.bookmarkedOnly;
        }
        if (requestParameters.partConditions) {
            queryParameters['partConditions'] = requestParameters.partConditions;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/api/content-learning/v1/toeic-virtual-exams/{learningCellId}/content-interaction-states`.replace(`{${"learningCellId"}}`, encodeURIComponent(String(requestParameters.learningCellId))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListToeicVirtualExamContentInteractionStatesResponseFromJSON(jsonValue));
    }
    /**
     * 완료된 TOEIC 선택학습 실전 모의고사 인스턴스의 문제풀이 이력을 조회한다. 조회 순서는 실전 모의고사 세트 내 문제 콘텐츠 순서와 동일하다. 해당 API는 대상 TOEIC 선택학습 실전 모의고사의 채점이 완료된 경우에만 정상 작동한다.
     */
    async listToeicVirtualExamContentInteractionStates(requestParameters, initOverrides) {
        const response = await this.listToeicVirtualExamContentInteractionStatesRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * TOEIC 선택학습 실전 모의고사 학습 이벤트를 기록한다. 만약 이미 실전 모의고사 답안을 최종 제출한 상태라면 아무런 작업도 수행하지 않는다.
     */
    async putToeicVirtualExamEventRaw(requestParameters, initOverrides) {
        if (requestParameters.learningCellId === null || requestParameters.learningCellId === undefined) {
            throw new runtime.RequiredError('learningCellId', 'Required parameter requestParameters.learningCellId was null or undefined when calling putToeicVirtualExamEvent.');
        }
        if (requestParameters.toeicVirtualExamEvent === null || requestParameters.toeicVirtualExamEvent === undefined) {
            throw new runtime.RequiredError('toeicVirtualExamEvent', 'Required parameter requestParameters.toeicVirtualExamEvent was null or undefined when calling putToeicVirtualExamEvent.');
        }
        const queryParameters = {};
        const headerParameters = {};
        headerParameters['Content-Type'] = 'application/json';
        const response = await this.request({
            path: `/api/content-learning/v1/toeic-virtual-exams/{learningCellId}/events`.replace(`{${"learningCellId"}}`, encodeURIComponent(String(requestParameters.learningCellId))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: ToeicVirtualExamEventToJSON(requestParameters.toeicVirtualExamEvent),
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * TOEIC 선택학습 실전 모의고사 학습 이벤트를 기록한다. 만약 이미 실전 모의고사 답안을 최종 제출한 상태라면 아무런 작업도 수행하지 않는다.
     */
    async putToeicVirtualExamEvent(requestParameters, initOverrides) {
        await this.putToeicVirtualExamEventRaw(requestParameters, initOverrides);
    }
}
