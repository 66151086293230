/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { CouponDetailsDiscountMethodCaseFromJSON, CouponDetailsDiscountMethodCaseToJSON, } from './CouponDetailsDiscountMethodCase';
/**
 * Check if a given object implements the CouponDetailsDiscountMethod interface.
 */
export function instanceOfCouponDetailsDiscountMethod(value) {
    let isInstance = true;
    isInstance = isInstance && "oneOfCase" in value;
    return isInstance;
}
export function CouponDetailsDiscountMethodFromJSON(json) {
    return CouponDetailsDiscountMethodFromJSONTyped(json, false);
}
export function CouponDetailsDiscountMethodFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'amount': !exists(json, 'amount') ? undefined : json['amount'],
        'oneOfCase': CouponDetailsDiscountMethodCaseFromJSON(json['oneOfCase']),
        'rate': !exists(json, 'rate') ? undefined : json['rate'],
    };
}
export function CouponDetailsDiscountMethodToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'amount': value.amount,
        'oneOfCase': CouponDetailsDiscountMethodCaseToJSON(value.oneOfCase),
        'rate': value.rate,
    };
}
