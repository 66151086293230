import React from 'react';

import {useAtomValue} from 'jotai';
import {TopNavBar, TopNavBarProps} from '@santa-web/service-ui';
import {learningStore} from '@app/test-preparation/atoms/stores';
export type ToeicSelfLessonTopNavBarProps = Partial<TopNavBarProps>;

function ToeicSelfLessonTopNavBar(props: ToeicSelfLessonTopNavBarProps): React.ReactElement | null {
  const {...restProps} = props;

  const totalElapsedTime = useAtomValue(learningStore.cises.totalElapsedTimeAtom);

  const lesson = useAtomValue(learningStore.content.lessonAtom);

  const content = lesson?.title;
  const timerSeconds = totalElapsedTime / 1000;

  return <TopNavBar content={content} paginationIcon="exit" timerSeconds={timerSeconds} {...restProps} />;
}

export default React.memo(ToeicSelfLessonTopNavBar);
