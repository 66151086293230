/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { BulkGetContentResourcesResultCaseFromJSON, BulkGetContentResourcesResultCaseToJSON, } from './BulkGetContentResourcesResultCase';
import { ContentResourceFromJSON, ContentResourceToJSON, } from './ContentResource';
import { ContentResourceV1GetContentResourceErrorCodesFromJSON, ContentResourceV1GetContentResourceErrorCodesToJSON, } from './ContentResourceV1GetContentResourceErrorCodes';
/**
 * Check if a given object implements the BulkGetContentResourcesResultEntry interface.
 */
export function instanceOfBulkGetContentResourcesResultEntry(value) {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "oneOfCase" in value;
    return isInstance;
}
export function BulkGetContentResourcesResultEntryFromJSON(json) {
    return BulkGetContentResourcesResultEntryFromJSONTyped(json, false);
}
export function BulkGetContentResourcesResultEntryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'failure': !exists(json, 'failure') ? undefined : ContentResourceV1GetContentResourceErrorCodesFromJSON(json['failure']),
        'id': json['id'],
        'oneOfCase': BulkGetContentResourcesResultCaseFromJSON(json['oneOfCase']),
        'success': !exists(json, 'success') ? undefined : ContentResourceFromJSON(json['success']),
    };
}
export function BulkGetContentResourcesResultEntryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'failure': ContentResourceV1GetContentResourceErrorCodesToJSON(value.failure),
        'id': value.id,
        'oneOfCase': BulkGetContentResourcesResultCaseToJSON(value.oneOfCase),
        'success': ContentResourceToJSON(value.success),
    };
}
