import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {Button} from '@santa-web/service-ui';
import HorizontalPicker from '@app/components/HorizontalPicker';
import {OnboardingInformationArea, OnboardingLayout, OnboardingQuestionViewCommonProps} from '@app/features/onboarding';

type TargetScoreViewProps = OnboardingQuestionViewCommonProps & {
  domainName: string;
  defaultTargetScore: number;
  targetScoreItems: Array<{value: number; label?: string}>;
  buttonType?: 'continue' | 'start';
  onPick: (targetScore: number) => void;
};

const TargetScoreView = ({
  isLoading = false,
  isNarrow,
  domainName,
  defaultTargetScore,
  targetScoreItems,
  buttonType,
  onBackButtonClick,
  onPick,
}: TargetScoreViewProps) => {
  const {t} = useTranslation();
  const [targetScore, setTargetScore] = React.useState(defaultTargetScore);
  return (
    <OnboardingLayout isNarrow={isNarrow} onBackButtonClick={onBackButtonClick}>
      <div
        css={css`
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        `}>
        <OnboardingInformationArea
          shouldShowAiImage
          subTitle={domainName}
          title={t('page_onboarding_target_score_title')}
          description={t('page_onboarding_target_score_description')}
        />
        <div
          css={css`
            flex: 1;
            display: flex;
            justify-content: center;
            align-items: center;

            width: 100vw;
            margin-left: 50%;
            transform: translateX(-50%);
          `}>
          <HorizontalPicker
            css={css`
              overflow-x: hidden;
            `}
            defaultValue={targetScore}
            items={targetScoreItems}
            onPick={setTargetScore}
          />
        </div>
        <div
          css={css`
            display: grid;
            gap: 8px;
          `}>
          <Button isLoading={isLoading} colorVariant="brand" isFullWidth onClick={() => onPick(targetScore)}>
            {buttonType === 'continue'
              ? t('page_onboarding_target_score_continue_button')
              : t('page_onboarding_target_score_start_button', {domain: domainName})}
          </Button>
        </div>
      </div>
    </OnboardingLayout>
  );
};

export default TargetScoreView;
export type {TargetScoreViewProps};
TargetScoreView.displayName = 'TargetScoreView';
