import React from 'react';
import {css} from '@emotion/react';

import {COLOR_SANTA_C} from '@riiid/design-system';
import FrameLayout from '@app/components/FrameLayout';
import ScrollLayout from '@app/components/ScrollLayout';
import {use100vh} from '@app/hooks/use100vh';

type OfferLayoutProps = {
  children: React.ReactNode;
  bottomArea?: React.ReactNode;
  topNavBar?: React.ReactNode;
  onScroll?: React.UIEventHandler<HTMLDivElement>;
};

const OfferLayout = ({children, bottomArea, topNavBar, onScroll}: OfferLayoutProps) => {
  const viewportHeight = use100vh();
  return (
    <div
      css={[
        css`
          height: ${viewportHeight};
          position: relative;
        `,
        !topNavBar &&
          css`
            padding-top: var(--mobile-safe-area-top);
          `,
      ]}>
      <FrameLayout bottomArea={bottomArea} topNavBar={topNavBar}>
        <ScrollLayout
          css={css`
            background-color: ${COLOR_SANTA_C};
          `}
          onScroll={onScroll}>
          {children}
        </ScrollLayout>
      </FrameLayout>
    </div>
  );
};

export default OfferLayout;
export type {OfferLayoutProps};
OfferLayout.displayName = 'OfferLayout';
