import { DiagnosisContentOptionFromJSON, DiagnosisContentOptionToJSON, } from './DiagnosisContentOption';
/**
 * Check if a given object implements the GetDiagnosisResponse interface.
 */
export function instanceOfGetDiagnosisResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "contentOption" in value;
    isInstance = isInstance && "learningCellId" in value;
    return isInstance;
}
export function GetDiagnosisResponseFromJSON(json) {
    return GetDiagnosisResponseFromJSONTyped(json, false);
}
export function GetDiagnosisResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'contentOption': DiagnosisContentOptionFromJSON(json['ContentOption']),
        'learningCellId': json['learningCellId'],
    };
}
export function GetDiagnosisResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'ContentOption': DiagnosisContentOptionToJSON(value.contentOption),
        'learningCellId': value.learningCellId,
    };
}
