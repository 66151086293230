import {useInfiniteQuery} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';
import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {getPassesQueryKey} from '@app/queryKeys';

const PAGE_SIZE = 20;

export const usePassesInfiniteQuery = () => {
  const {PassService} = useAtomValue(santaOpenapiServicesAtom);
  return useInfiniteQuery(
    getPassesQueryKey(),
    async ({pageParam: cursor}) => {
      return await PassService.listMyPassesV2({cursor, size: PAGE_SIZE});
    },
    {
      getNextPageParam: lastPage => (lastPage.pass.length === PAGE_SIZE ? lastPage.page.cursor : undefined),
    }
  );
};
