import {useTranslation} from 'react-i18next';
import {PaymentProvider} from '@santa-web/gen/open-api/service';
import useOfferEvents from '@app/api/google-tag-manager/offer';
import {OfferGroupOrderDetailViewProps, useStripeCustomerPortalUrlQuery} from '@app/features/offer';
import {mapOfferGroupOrderToOfferGroupOrderCardLabelListProps} from '@app/features/offer/utils';
import {
  mapPassToPassCardProps,
  mapTicketGroupToTicketCardProps,
  useActivatePass,
  useCanActivatePass,
  useConsumeTicket,
} from '@app/features/permit';
import {useOpenNewWindow} from '@app/hooks/useOpenNewWindow';
import {useTypedRouter} from '@app/hooks/useTypedRouter';
import {useOfferGroupOrderPageCommonViewProps, useOfferGroupOrderQuery} from '.';

export const useOfferGroupOrderDetailViewProps = (
  id: number,
  isCheckoutResult?: boolean
): OfferGroupOrderDetailViewProps | null => {
  const {t} = useTranslation();
  const openNewWindow = useOpenNewWindow();
  const router = useTypedRouter();
  const {pushClickHomePaid} = useOfferEvents();

  const {data: order} = useOfferGroupOrderQuery(id);
  const {data: stripeCustomerPortalUrl} = useStripeCustomerPortalUrlQuery(id);
  const commonViewProps = useOfferGroupOrderPageCommonViewProps(id);

  const consumeTicket = useConsumeTicket();
  const canActivatePass = useCanActivatePass();
  const {activatePass} = useActivatePass();

  if (!order || canActivatePass == null || commonViewProps == null) {
    return null;
  }

  const permitInfo: OfferGroupOrderDetailViewProps['permitInfo'] = {
    passes: order.permitItems?.passes?.map(pass => ({
      ...mapPassToPassCardProps(pass, canActivatePass),
      learningDomain: pass.learningDomainDisplayName,
      onButtonClick: () => activatePass(pass),
    })),
    tickets: order.permitItems?.tickets?.map(ticketGroup => ({
      ...mapTicketGroupToTicketCardProps(ticketGroup),
      learningDomain: ticketGroup.learningDomainDisplayName,
      onButtonClick: () => consumeTicket(ticketGroup),
    })),
  };

  const hasPaymentProviderError = isCheckoutResult
    ? undefined
    : order.orderConfig?.selectedPaymentProvider === 'STRIPE' && order.status === 'SUBSCRIPTION_RENEW_FAILED';

  return {
    ...commonViewProps,
    hasPaymentProviderError,
    isCheckoutResult,
    isInAppPurchase: (['APP_STORE', 'PLAY_STORE'] as PaymentProvider[]).includes(
      order.orderConfig!.selectedPaymentProvider
    ),
    permitInfo,
    orderCardLabelListProps: mapOfferGroupOrderToOfferGroupOrderCardLabelListProps(order),
    ...(isCheckoutResult
      ? {
          onStudyButtonClick: () => {
            pushClickHomePaid({
              offerId: order.offerGroup.offer.id,
              offerDisplayName: order.offerGroup.offer.displayConfig.name,
            });
            router.push('/');
          },
          onSupportButtonClick: () => {
            openNewWindow(t('customer_service_url'));
          },
          onOrderListButtonClick: () => router.push('/offer-group/order/list'),
        }
      : {
          onSupportButtonClick: () => {
            if (hasPaymentProviderError && stripeCustomerPortalUrl) {
              openNewWindow(stripeCustomerPortalUrl);
            } else {
              openNewWindow(t('customer_service_url'));
            }
          },
          onOrderListButtonClick: router.back,
          onSubscriptionManageButtonClick:
            order.orderConfig?.selectedPaymentProvider === 'STRIPE'
              ? () => {
                  if (stripeCustomerPortalUrl) openNewWindow(stripeCustomerPortalUrl);
                  else throw new Error('stripeCustomerPortalUrl is null');
                }
              : undefined,
        }),
  };
};
