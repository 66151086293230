/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
/**
 * Check if a given object implements the GiftCodePermitResource interface.
 */
export function instanceOfGiftCodePermitResource(value) {
    let isInstance = true;
    isInstance = isInstance && "noticeLink" in value;
    isInstance = isInstance && "serialNumber" in value;
    return isInstance;
}
export function GiftCodePermitResourceFromJSON(json) {
    return GiftCodePermitResourceFromJSONTyped(json, false);
}
export function GiftCodePermitResourceFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'expireAt': !exists(json, 'expireAt') ? undefined : (new Date(json['expireAt'])),
        'noticeLink': json['noticeLink'],
        'password': !exists(json, 'password') ? undefined : json['password'],
        'serialNumber': json['serialNumber'],
    };
}
export function GiftCodePermitResourceToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'expireAt': value.expireAt === undefined ? undefined : (value.expireAt.toISOString()),
        'noticeLink': value.noticeLink,
        'password': value.password,
        'serialNumber': value.serialNumber,
    };
}
