export function withSkeleton<ComponentType, SkeletonType>(
  component: ComponentType,
  skeleton: SkeletonType
): ComponentType & {Skeleton: SkeletonType} {
  return Object.assign(component as any, {Skeleton: skeleton});
}

export function withEmpty<ComponentType, EmptyType>(
  component: ComponentType,
  empty: EmptyType
): ComponentType & {Empty: EmptyType} {
  return Object.assign(component as any, {Empty: empty});
}
