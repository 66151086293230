/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { ActivePassV2FromJSON, ActivePassV2ToJSON, } from './ActivePassV2';
import { GetMyPassStatusFromJSON, GetMyPassStatusToJSON, } from './GetMyPassStatus';
/**
 * Check if a given object implements the GetMyPassStatusResponseV2 interface.
 */
export function instanceOfGetMyPassStatusResponseV2(value) {
    let isInstance = true;
    isInstance = isInstance && "status" in value;
    return isInstance;
}
export function GetMyPassStatusResponseV2FromJSON(json) {
    return GetMyPassStatusResponseV2FromJSONTyped(json, false);
}
export function GetMyPassStatusResponseV2FromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'activePass': !exists(json, 'activePass') ? undefined : ActivePassV2FromJSON(json['activePass']),
        'status': GetMyPassStatusFromJSON(json['status']),
    };
}
export function GetMyPassStatusResponseV2ToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'activePass': ActivePassV2ToJSON(value.activePass),
        'status': GetMyPassStatusToJSON(value.status),
    };
}
