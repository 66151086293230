import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {COLOR_SANTA_L} from '@riiid/design-system';

import {Button, TextButton, Typography} from '@santa-web/service-ui';
import Modal from '@app/components/Modal';

export interface ModalFilterProps {
  isOpen: boolean;
  canSubmit: boolean;
  canReset: boolean;
  onRequestClose: () => void;
  onSubmit: () => void;
  onReset: () => void;
  children: React.ReactNode;
}

function ModalFilter({isOpen, canSubmit, canReset, onRequestClose, onSubmit, onReset, children}: ModalFilterProps) {
  const {t} = useTranslation();
  const submitButton = (
    <Button isDisabled={!canSubmit} isFullWidth variant="bottom-fix" onClick={onSubmit} colorVariant="brand">
      {t('__filter_applyFilters')}
    </Button>
  );

  const headerRightElement = (
    <TextButton isDisabled={!canReset} onClick={onReset} color="black" fontWeight="bold" typographyVariant="body3">
      {t('__filter_resetFilters')}
    </TextButton>
  );

  return (
    <Modal
      isOpen={isOpen}
      headerRightElement={headerRightElement}
      footer={submitButton}
      onRequestClose={onRequestClose}>
      <Typography
        variant="body2"
        fontWeight="bold"
        color={COLOR_SANTA_L}
        css={css`
          display: block;
          margin-bottom: 40px;
        `}>
        {t('__filter_title')}
      </Typography>
      {children}
    </Modal>
  );
}

export default React.memo(ModalFilter);
