import { ExamScoreFromJSON, ExamScoreToJSON, } from './ExamScore';
/**
 * Check if a given object implements the CreateExamScoreResponse interface.
 */
export function instanceOfCreateExamScoreResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "created" in value;
    return isInstance;
}
export function CreateExamScoreResponseFromJSON(json) {
    return CreateExamScoreResponseFromJSONTyped(json, false);
}
export function CreateExamScoreResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'created': ExamScoreFromJSON(json['created']),
    };
}
export function CreateExamScoreResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'created': ExamScoreToJSON(value.created),
    };
}
