import React from 'react';
import {useTranslation} from 'react-i18next';
import {useAtomValue} from 'jotai';

import {LoginWithEmailPasswordBadRequestErrorCase} from '@santa-web/gen/open-api/service';
import santaAuthorizedOpenapiServicesAtom from '@app/atoms/core/santa-authorized-openapi-services';
import {EmailLoginFormValues} from '@app/features/auth/types';
import {composeValidators} from '@app/features/auth/utils/validator';
import {SantaResponseError} from '@app/utils/error';

import useCommonValidator from './useCommonValidator';

export default function usePasswordValidator() {
  const {AuthService} = useAtomValue(santaAuthorizedOpenapiServicesAtom);
  const commonValidator = useCommonValidator();
  const {t} = useTranslation();

  return React.useMemo(() => {
    const validateLength = commonValidator.minLength(8, t('sign_error_password_not_enough_length'));

    const validateStrength = (password: string | undefined) => {
      if (!password) {
        return;
      }

      const isIncludingAlphabet = /[a-z]/i.test(password);
      const isIncludingNumber = /\d/.test(password);
      const isIncludingSpecialCharacters = /[!@#$%^&*()\-_=+,./<>?;':"`[\]{}\\|~₩]/.test(password);
      if ([isIncludingAlphabet, isIncludingNumber, isIncludingSpecialCharacters].filter(x => x).length < 2) {
        return t('sign_error_password_insufficient_mix');
      }
    };

    const validateNoSpaceCharacter = (password: string | undefined) => {
      if (password?.match(/\s/)) {
        return t('sign_error_password_contains_blank');
      }
    };

    const validateCorrectness = async (password: string | undefined, {email}: EmailLoginFormValues) => {
      try {
        await AuthService.loginWithEmailPassword({
          loginWithEmailPasswordRequest: {
            emailPasswordRegistrationInfo: {
              email: email ?? '',
              password: password ?? '',
            },
          },
        });
      } catch (e) {
        if (e instanceof SantaResponseError) {
          if (e.santaErrorCode === LoginWithEmailPasswordBadRequestErrorCase.WRONG_PASSWORD) {
            return t('sign_error_password_wrong');
          }
        }
      }
    };

    return {
      length: validateLength,
      confirm(passwordConfirmation: string | undefined, allValues: {password?: string}) {
        if (passwordConfirmation !== allValues.password) {
          return t('sign_error_password_confirm_failed');
        }
      },
      strong: validateStrength,
      noSpaceCharacter: validateNoSpaceCharacter,
      signup: composeValidators(validateLength, validateStrength, validateNoSpaceCharacter),
      correct: validateCorrectness,
      login: validateCorrectness,
    } as const;
  }, [commonValidator, t, AuthService]);
}
