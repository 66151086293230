/* tslint:disable */
/* eslint-disable */
/**
 * - **CREATED**: 주문 생성과 동시에 상품을 선택한 상태.
 * - **PAYMENT_REQUESTED**: 결제 서버에 결제가 요청된 상태
 * - **PAYMENT_COMPLETED**: 결제서버의 결제완료가 확인된상태.
 * - **PAYMENT_ABORTED**: 결제 실패
 * - **REFUND_REQUESTED**: 환불이 요청된 상태. 추가적인 Fulfillment 이행 중지
 * - **REFUNDED**: 주문이 환불된 상태
 * - **SUBSCRIPTION_RENEW_FAILED**: 구독 갱신 실패 등으로 구독이 중지된 상태
 * - **SUBSCRIPTION_CANCELED**: 구독 해지 후 기간이 남은 상태
 * - **PAYMENT_COMPLETED_BUT_UNUSABLE**: 결제가 승인되었으나, STRIPE 오류 등으로 상품 이용이 불가한 상태
 * - **EXPIRED**: 이용이 완료되거나 구독 해지 후 이용기간이 만료된 상태
 * - **REVOKED**: 주문 상품 설정, 결제 취소 등으로 결제가 일어나기전 주문이 취소된 상태.
 * @export
 */
export const OrderStatus = {
    CREATED: 'CREATED',
    PAYMENT_REQUESTED: 'PAYMENT_REQUESTED',
    PAYMENT_COMPLETED: 'PAYMENT_COMPLETED',
    PAYMENT_ABORTED: 'PAYMENT_ABORTED',
    REFUND_REQUESTED: 'REFUND_REQUESTED',
    REFUNDED: 'REFUNDED',
    SUBSCRIPTION_RENEW_FAILED: 'SUBSCRIPTION_RENEW_FAILED',
    SUBSCRIPTION_CANCELED: 'SUBSCRIPTION_CANCELED',
    PAYMENT_COMPLETED_BUT_UNUSABLE: 'PAYMENT_COMPLETED_BUT_UNUSABLE',
    EXPIRED: 'EXPIRED',
    REVOKED: 'REVOKED'
};
export function OrderStatusFromJSON(json) {
    return OrderStatusFromJSONTyped(json, false);
}
export function OrderStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function OrderStatusToJSON(value) {
    return value;
}
