import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {BREAKPOINT_MEDIUM, COLOR_SANTA_C, COLOR_SANTA_I, COLOR_SANTA_J, COLOR_SANTA_L} from '@riiid/design-system';
import {TopNavBar, Typography, useToastContext} from '@santa-web/service-ui';
import {Column, FrameLayout, ScrollLayout} from '@app/components';
import {Coupon, CouponList, CouponListTab, CouponListTabValue, CouponRegisterForm} from '@app/features/coupon';
import {use100vh} from '@app/hooks/use100vh';

type CouponViewProps = {
  isLoading: boolean;
  initialCouponCode?: string;
  couponList: Coupon[];
  learningDomain: string;
  couponListFilter: CouponListTabValue;
  setCouponListFilter: (value: CouponListTabValue) => void;
  onCouponRegister: (code: string) => Promise<void>;
  onCouponUseClick: (coupon: Coupon) => void;
  onEndReached: () => void;
  onBackClick: () => void;
};

const CouponView = ({
  isLoading,
  initialCouponCode,
  couponList,
  learningDomain,
  couponListFilter,
  setCouponListFilter,
  onCouponRegister,
  onBackClick,
  onCouponUseClick,
  onEndReached,
}: CouponViewProps) => {
  const {t} = useTranslation();
  const {toastContainer} = useToastContext();
  const viewportHeight = use100vh();
  return (
    <div
      css={css`
        height: ${viewportHeight};
      `}>
      <FrameLayout
        css={css`
          & > div {
            display: flex;
          }
        `}
        topNavBar={<TopNavBar paginationIcon="back" onPaginationIconClick={onBackClick} />}>
        {toastContainer}
        <ScrollLayout
          css={css`
            flex: 1;
            display: flex;
            flex-direction: column;
            align-items: center;
          `}>
          {/* Coupon register */}
          <Column css={[getSectionCss(40)]}>
            <Column gap={12}>
              <Typography variant="caption1" fontWeight="bold" color={COLOR_SANTA_I}>
                {t('page_coupon_box_title')} {/** KR: 쿠폰함 */}
              </Typography>
              <Typography variant="h5" color={COLOR_SANTA_L}>
                {t('page_coupon_box_registration_title')} {/** KR: 신규 쿠폰을 등록할까요?  */}
              </Typography>
              <Typography variant="caption1" color={COLOR_SANTA_J}>
                {t('page_coupon_box_registration_description', {learningDomain})}
                {/** KR: {{learningDomain}}에서만 사용 가능한 쿠폰을 등록해 주세요. */}
              </Typography>
            </Column>
            <CouponRegisterForm initialValue={initialCouponCode} onCouponRegister={onCouponRegister} />
          </Column>

          {/* Coupon List */}
          <div
            css={css`
              width: 100%;
              max-width: 680px;
            `}>
            <CouponListTab onTabChange={setCouponListFilter} currentTab={couponListFilter} />
          </div>
          <Column
            alignItems="center"
            css={css`
              flex: 1;
              width: 100%;
              background-color: ${COLOR_SANTA_C};
            `}>
            <div css={[getSectionCss(24)]}>
              <CouponList
                coupons={couponList}
                isLoading={isLoading}
                currentTab={couponListFilter}
                learningDomain={learningDomain}
                onEndReached={onEndReached}
                onCouponUseClick={onCouponUseClick}
              />
            </div>
          </Column>
        </ScrollLayout>
      </FrameLayout>
    </div>
  );
};

const getSectionCss = (paddingY = 0) => {
  return css`
    width: 100%;
    max-width: 680px;
    gap: 40px;
    padding: ${paddingY}px 20px;

    @media (min-width: ${BREAKPOINT_MEDIUM}) {
      padding: ${paddingY}px 40px;
    }
  `;
};

export default CouponView;
