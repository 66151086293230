/* tslint:disable */
/* eslint-disable */
/**
 *
 * @export
 */
export const OfferGroupTimerBgColor = {
    BLACK: 'BLACK',
    WHITE: 'WHITE',
    RED: 'RED'
};
export function OfferGroupTimerBgColorFromJSON(json) {
    return OfferGroupTimerBgColorFromJSONTyped(json, false);
}
export function OfferGroupTimerBgColorFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function OfferGroupTimerBgColorToJSON(value) {
    return value;
}
