import React from 'react';
import {css} from '@emotion/react';
import {
  COLOR_SANTA_D,
  COLOR_SANTA_J,
  COLOR_SANTA_A,
  COLOR_SANTA_C,
  COLOR_SANTA_BD,
  COLOR_SANTA_DD,
  COLOR_SANTA_L,
  COLOR_SANTA_B,
} from '@riiid/design-system';
import HideSelectIcon from '@riiid/design-system/icons/Hideselect';
import UndoleftIcon from '@riiid/design-system/icons/Undoleft';

import {useTheme} from '@santa-web/service-ui';
import QaButtonIcon, {QaButtonIconProps} from './QaButtonIcon';
import TutorQuestionButton, {TutorQuestionButtonProps} from './TutorQuestionButton';

export type QaButtonProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'content'> & {
  QaButtonIconProps?: QaButtonIconProps;
  subContent?: React.ReactNode;
  content?: React.ReactNode;
  iconContent?: React.ReactNode;
  isCompleted?: boolean;
  isCorrect?: boolean;
  isDisabled?: boolean;
  isErased?: boolean;
  isErasable?: boolean;
  isSelected?: boolean;
  translation?: React.ReactNode;
  tutorQuestionButtonProps?: Omit<TutorQuestionButtonProps, 'isCorrect'>;
  onEraseClick?: React.MouseEventHandler;
};

const getBackgroundColor = ({
  isCompleted,
  isCorrect,
  isSelected,
}: QaButtonProps): React.CSSProperties['backgroundColor'] => {
  if (isSelected) return COLOR_SANTA_D;
  if (isCompleted && isCorrect) return COLOR_SANTA_C;
  return COLOR_SANTA_A;
};

const getHoverBackgroundColor = ({
  isCompleted,
  isDisabled,
  isSelected,
}: QaButtonProps): React.CSSProperties['backgroundColor'] => {
  if (!isCompleted && !isDisabled && !isSelected) return COLOR_SANTA_B;
  return undefined;
};

const getColor = ({isCompleted, isCorrect, isSelected}: QaButtonProps): React.CSSProperties['color'] => {
  if (isCompleted) {
    if (isCorrect) return COLOR_SANTA_BD;
    if (isSelected) return COLOR_SANTA_DD;
  }
  return COLOR_SANTA_J;
};

const getIconColor = ({isCompleted, isCorrect, isSelected}: QaButtonProps): React.CSSProperties['color'] => {
  if (!isCompleted && isSelected) return COLOR_SANTA_L;
  return getColor({isCompleted, isCorrect, isSelected});
};

const QaButton = React.forwardRef<HTMLDivElement, QaButtonProps>((props, ref): JSX.Element => {
  const {
    QaButtonIconProps,
    subContent,
    content,
    iconContent,
    isCompleted,
    isCorrect,
    isDisabled,
    isErased,
    isErasable = true,
    isSelected,
    translation,
    tutorQuestionButtonProps,
    onEraseClick,
    ...restProps
  } = props;
  const theme = useTheme();

  const color = getColor({isCompleted, isCorrect, isSelected});
  const iconColor = getIconColor({isCompleted, isCorrect, isSelected});

  const iconContainer = (
    <div
      data-testid="QaButton-icon-container"
      css={[
        css`
          padding: 8px 0;
        `,
        !isDisabled &&
          !isCompleted &&
          isErased &&
          css`
            & > * {
              opacity: 0.2;
            }
          `,
      ]}>
      <QaButtonIcon
        data-testid="QaButton-icon"
        content={iconContent}
        isCompleted={isCompleted}
        isCorrect={isCorrect}
        isSelected={isSelected}
        color={iconColor}
        {...QaButtonIconProps}
      />
    </div>
  );

  const contentContainer = (
    <div
      data-testid="QaButton-content-container"
      css={[
        css`
          display: flex;
          flex-direction: column;
          justify-content: center;
          flex: 1;
          padding: 0 16px;
          color: ${color};
          span,
          p {
            color: ${color};
          }
        `,
        !isDisabled &&
          !isCompleted &&
          isErased &&
          css`
            & > * {
              opacity: 0.2;
            }
          `,
      ]}>
      {subContent}
      <div
        css={
          translation &&
          css`
            font-weight: bold;
          `
        }>
        {content}
      </div>
      {translation && (
        <div
          css={css`
            margin-top: 4px;
            p,
            span {
              ${theme.typography.variants['caption1']}
            }
          `}>
          {translation}
        </div>
      )}
    </div>
  );

  const eraseIconProps = {
    width: 20,
    height: 20,
    color: COLOR_SANTA_J,
  };

  const eraseIcon = isErased ? (
    <UndoleftIcon data-testid="QaButton-erase-icon-erased" {...eraseIconProps} type="line" />
  ) : (
    <HideSelectIcon data-testid="QaButton-erase-icon" {...eraseIconProps} type="solid" />
  );

  const eraseIconContainer = !isCompleted && isErasable && (
    <div
      role="button"
      tabIndex={0}
      onClick={isDisabled ? undefined : onEraseClick}
      css={css`
        display: flex;
        align-items: center;
      `}>
      {eraseIcon}
    </div>
  );

  const backgroundColor = getBackgroundColor(props);
  const hoverBackgroundColor = getHoverBackgroundColor(props);

  return (
    <div
      ref={ref}
      data-testid="QaButton"
      role="button"
      tabIndex={0}
      css={[
        css`
          display: flex;
          flex-direction: column;
          width: 100%;
          padding: ${translation || tutorQuestionButtonProps ? 12 : 8}px 20px;
          background: ${backgroundColor};
        `,
        hoverBackgroundColor &&
          css`
            @media (hover: hover) {
              &:hover {
                background: ${hoverBackgroundColor};
              }
            }
          `,
        isDisabled &&
          css`
            & > * {
              opacity: 0.7;
            }
          `,
      ]}
      {...restProps}>
      <div
        css={css`
          display: flex;
        `}>
        {iconContainer}
        {contentContainer}
        {eraseIconContainer}
      </div>
      {tutorQuestionButtonProps && (
        <div
          css={css`
            margin-top: 16px;
          `}>
          <TutorQuestionButton
            isCorrect={isCorrect}
            isDisabled={tutorQuestionButtonProps.isDisabled}
            onClick={tutorQuestionButtonProps.onClick}
          />
        </div>
      )}
    </div>
  );
});

export default QaButton;

QaButton.displayName = 'QaButton';
