/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { GoogleRegistrationInfoFromJSON, GoogleRegistrationInfoToJSON, } from './GoogleRegistrationInfo';
import { SignupRequestAgreementInfoFromJSON, SignupRequestAgreementInfoToJSON, } from './SignupRequestAgreementInfo';
import { UserPersonalInfoFromJSON, UserPersonalInfoToJSON, } from './UserPersonalInfo';
/**
 * Check if a given object implements the SignupWithGoogleRequest interface.
 */
export function instanceOfSignupWithGoogleRequest(value) {
    let isInstance = true;
    isInstance = isInstance && "agreementInfo" in value;
    isInstance = isInstance && "googleRegistrationInfo" in value;
    return isInstance;
}
export function SignupWithGoogleRequestFromJSON(json) {
    return SignupWithGoogleRequestFromJSONTyped(json, false);
}
export function SignupWithGoogleRequestFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'agreementInfo': SignupRequestAgreementInfoFromJSON(json['agreementInfo']),
        'googleRegistrationInfo': GoogleRegistrationInfoFromJSON(json['googleRegistrationInfo']),
        'personalInfo': !exists(json, 'personalInfo') ? undefined : UserPersonalInfoFromJSON(json['personalInfo']),
    };
}
export function SignupWithGoogleRequestToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'agreementInfo': SignupRequestAgreementInfoToJSON(value.agreementInfo),
        'googleRegistrationInfo': GoogleRegistrationInfoToJSON(value.googleRegistrationInfo),
        'personalInfo': UserPersonalInfoToJSON(value.personalInfo),
    };
}
