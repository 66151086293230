/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
/**
 * Check if a given object implements the TimestampInfo interface.
 */
export function instanceOfTimestampInfo(value) {
    let isInstance = true;
    return isInstance;
}
export function TimestampInfoFromJSON(json) {
    return TimestampInfoFromJSONTyped(json, false);
}
export function TimestampInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'paymentSucceededAt': !exists(json, 'paymentSucceededAt') ? undefined : (new Date(json['paymentSucceededAt'])),
        'refundRequestedAt': !exists(json, 'refundRequestedAt') ? undefined : (new Date(json['refundRequestedAt'])),
        'refundedAt': !exists(json, 'refundedAt') ? undefined : (new Date(json['refundedAt'])),
        'subscriptionRenewAt': !exists(json, 'subscriptionRenewAt') ? undefined : (new Date(json['subscriptionRenewAt'])),
    };
}
export function TimestampInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'paymentSucceededAt': value.paymentSucceededAt === undefined ? undefined : (value.paymentSucceededAt.toISOString()),
        'refundRequestedAt': value.refundRequestedAt === undefined ? undefined : (value.refundRequestedAt.toISOString()),
        'refundedAt': value.refundedAt === undefined ? undefined : (value.refundedAt.toISOString()),
        'subscriptionRenewAt': value.subscriptionRenewAt === undefined ? undefined : (value.subscriptionRenewAt.toISOString()),
    };
}
