import React from 'react';
import {useMutation, useQueryClient} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';

import {User, UpdateMyPersonalInfoRequest} from '@santa-web/gen/open-api/service';
import santaAuthorizedOpenapiServicesAtom from '@app/atoms/core/santa-authorized-openapi-services';
import {getUserQueryKey} from '@app/queryKeys';

const userQueryKey = getUserQueryKey();

export default function useUpdateUserPersonalInfo() {
  const {UserService} = useAtomValue(santaAuthorizedOpenapiServicesAtom);
  const queryClient = useQueryClient();

  const onSuccess = React.useCallback(
    function (user: User) {
      queryClient.setQueryData<User>(userQueryKey, user);
    },
    [queryClient]
  );

  const mutationFn = React.useCallback(
    async (updateMyPersonalInfoRequest: UpdateMyPersonalInfoRequest) => {
      const {user} = await UserService.updateMyPersonalInfo({
        updateMyPersonalInfoRequest,
      });
      return user;
    },
    [UserService]
  );
  return useMutation(mutationFn, {onSuccess});
}
