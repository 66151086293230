import React from 'react';
import * as Sentry from '@sentry/nextjs';
import {CHAT_BUTTON_TRANSITION_END_NOT_FIRED_EVENT_NAME} from '@santa-web/service-ui';

// 아래 이슈 원인 파악을 위한 오류 트래킹용 코드
// https://www.notion.so/riiid/Testprep-13b5bc5f630780f9b776fac5a5ea3808
export const useChatButtonTransitionEndNotFiredEffect = () => {
  React.useEffect(() => {
    document.addEventListener(CHAT_BUTTON_TRANSITION_END_NOT_FIRED_EVENT_NAME, e => {
      const event = e as CustomEvent;
      const {position, text} = event.detail;
      Sentry.captureException(new ChatButtonTransitionEndNotFiredError(position, text));
    });
  }, []);
};

class ChatButtonTransitionEndNotFiredError extends Error {
  constructor(position: 'group' | 'switch', text: string) {
    super(`Transition end event(${position}) not called for button: ${text}`);
    this.name = 'ChatButtonTransitionEndNotFiredError';
  }
}
