import {css} from '@emotion/react';
import {COLOR_SANTA_B, COLOR_SANTA_C, COLOR_SANTA_D, COLOR_SANTA_J} from '@riiid/design-system';
import {format} from 'date-fns';
import {Table, Typography} from '@santa-web/service-ui';
import {TableRowProps} from '@santa-web/service-ui/src/components/Table/TableRow';
import {ScoreRowClickHandler, ScoreInfo} from '@app/features/preferences/types';

type ScoreTableRowProps = {
  score: ScoreInfo;
  isDisabled?: boolean;
  onRowClick?: ScoreRowClickHandler;
} & Omit<TableRowProps, 'children' | 'onClick'>;

const ScoreTableRow = ({score, isDisabled = false, onRowClick, ...tableRowProps}: ScoreTableRowProps) => {
  const {date, partialScores, totalScore} = score;
  return (
    <Table.Row
      role="button"
      tabIndex={0}
      onClick={e => onRowClick && onRowClick(e, score)}
      {...tableRowProps}
      css={[
        css`
          border-radius: 4px;
          cursor: ${isDisabled ? 'not-allowed;' : 'pointer'};
          background: ${COLOR_SANTA_B};
          opacity: ${isDisabled ? 0.3 : 1};

          & td {
            max-height: 48px;
            padding: 14px 0px;

            &:first-child {
              border-radius: 4px 0px 0px 4px;
            }

            &:last-child {
              border-radius: 0px 4px 4px 0px;
            }
          }

          @media (hover: hover) {
            &:hover {
              background: ${COLOR_SANTA_C};
            }
          }

          &:active {
            background: ${COLOR_SANTA_D};
          }
        `,
        tableRowProps.css,
      ]}>
      <Table.Row.Cell align="center">
        <Typography component="p" variant="caption1" color={COLOR_SANTA_J}>
          {format(new Date(date), 'yy.MM.dd.')}
        </Typography>
      </Table.Row.Cell>
      {partialScores.map(({name, score}) => (
        <Table.Row.Cell align="center" key={name}>
          <Typography component="p" variant="caption1" color={COLOR_SANTA_J}>
            {score}
          </Typography>
        </Table.Row.Cell>
      ))}
      <Table.Row.Cell align="center">
        <Typography component="p" variant="caption1" color={COLOR_SANTA_J} fontWeight="bold">
          {totalScore}
        </Typography>
      </Table.Row.Cell>
    </Table.Row>
  );
};

export default ScoreTableRow;
export type {ScoreTableRowProps};
ScoreTableRow.displayName = 'ScoreTableRow';
