import {ContentMetadata} from '@app/experiments/contents-level-choice-rate/types';

export const isContentMetadata = (data: unknown): data is ContentMetadata => {
  if (typeof data !== 'object' || data == null) return false;
  const values = Object.values(data);
  return values.every(value => {
    if (!value.correctionRateMap || !value.choiceRateMaps || !value.interactionLength) return false;
    return true;
  });
};

const VALID_SCORE_RANGE = [500, 900];
export const getFlooredScore = (score: number): number => {
  const flooredScore = Math.floor(score / 100) * 100;
  return flooredScore < VALID_SCORE_RANGE[0] ? VALID_SCORE_RANGE[0] : flooredScore;
};
