/* tslint:disable */
/* eslint-disable */
/**
 * - **ACTIVE**: 이용중
 * - **USED_ALL**: 이용완료
 * - **EXPIRED**: 기간만료
 * - **REFUNDED**: 환불완료
 * @export
 */
export const TicketStatus = {
    ACTIVE: 'ACTIVE',
    USED_ALL: 'USED_ALL',
    EXPIRED: 'EXPIRED',
    REFUNDED: 'REFUNDED'
};
export function TicketStatusFromJSON(json) {
    return TicketStatusFromJSONTyped(json, false);
}
export function TicketStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function TicketStatusToJSON(value) {
    return value;
}
