import React from 'react';
import {useTranslation} from 'react-i18next';

import {useToastContext} from '@santa-web/service-ui';

interface Props {
  isBookmarked: boolean;
  toggleBookmark: () => Promise<void>;
}

export const useHandleBookmarkClick = ({isBookmarked, toggleBookmark}: Props) => {
  const {t} = useTranslation();
  const {openToast} = useToastContext();

  const handleBookmarkClick = React.useCallback(async () => {
    await toggleBookmark();

    openToast({
      colorVariant: 'gray',
      message: isBookmarked ? t('bottom_toast_delete_bookmark') : t('bottom_toast_add_bookmark'),
    });
  }, [isBookmarked, openToast, t, toggleBookmark]);

  return {handleBookmarkClick};
};
