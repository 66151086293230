import React from 'react';
import {Portal} from 'react-portal';
import {css, Interpolation, Theme} from '@emotion/react';
import {COLOR_SANTA_A} from '@riiid/design-system';

import FrameLayout, {FrameLayoutProps} from '@app/components/FrameLayout';
import {MODAL_Z_INDEX} from '@app/constants/zIndex';

export interface FrameLayoutModalProps extends React.ComponentPropsWithoutRef<'div'> {
  isOpened: boolean;
  FrameLayoutProps: FrameLayoutProps & {css?: Interpolation<Theme>};
}

const FrameLayoutModal = ({
  children,
  FrameLayoutProps,
  isOpened,
  ...props
}: FrameLayoutModalProps): JSX.Element | null => {
  return isOpened ? (
    <Portal>
      <div
        css={css`
          position: fixed;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          background-color: ${COLOR_SANTA_A};
          z-index: ${MODAL_Z_INDEX};
        `}
        {...props}>
        <FrameLayout {...FrameLayoutProps}>{children}</FrameLayout>
      </div>
    </Portal>
  ) : null;
};

export default React.memo(FrameLayoutModal);
