import {useRouter} from 'next/router';
import React, {useEffect, useMemo} from 'react';

import {BrowserStorage} from '@santa-web/service-common';
import useMarketingBottomSheetEvents from '@app/api/google-tag-manager/marketing-bottom-sheet';
import useOfferEvents from '@app/api/google-tag-manager/offer';
import {useDialogContext} from '@app/contexts/DialogContext';
import {WelcomeMarketingBottomSheet} from '@app/features/marketing';
import {useOpenNewWindow} from '@app/hooks/useOpenNewWindow';
import {isAppBridgeAvailable} from '@app/utils/app-bridge';
import {useAvailablePopupQuery} from './useAvailablePopupQuery';

export function useWelcomeMarketingBottomSheetEffect() {
  const openNewWindow = useOpenNewWindow();
  const {push} = useRouter();
  const {pushClickStore} = useOfferEvents();
  const {data: popup, isSuccess} = useAvailablePopupQuery();
  const {openDialog, closeDialog} = useDialogContext();
  const {
    pushMarketingBottomSheetDisplay,
    pushMarketingBottomSheetDetailClick,
    pushMarketingBottomSheetCloseClick,
    pushMarketingBottomSheetNotTodayClick,
  } = useMarketingBottomSheetEvents();
  const storage = useMemo(() => {
    return new BrowserStorage<{'welcome-bottom-sheet-disable-expiration-time': number}>(localStorage);
  }, []);

  const hasNoPopupInfo = !popup;
  const isDisabled = useMemo(() => {
    const disableExpirationTime = storage.getItem('welcome-bottom-sheet-disable-expiration-time') ?? 0;
    if (!disableExpirationTime) return false;
    const currentTime = new Date().getTime();
    return currentTime <= disableExpirationTime;
  }, [storage]);

  const handleCtaClick = React.useCallback(() => {
    if (hasNoPopupInfo || !popup.ctaButton) return;
    const isOfferGroupUrl =
      popup.ctaButton.appUrl.includes('offer-group') || popup.ctaButton.webUrl.includes('offer-group');

    pushMarketingBottomSheetDetailClick({mkt_bottomsheet_id: popup.id});
    if (isOfferGroupUrl) {
      pushClickStore({referrer: 'mkt_bottomsheet'});
    }
    if (isAppBridgeAvailable()) {
      openNewWindow(popup.ctaButton.appUrl);
    } else {
      const webUrl = isOfferGroupUrl ? `${popup.ctaButton.webUrl}?referrer=mkt_bottomsheet` : popup.ctaButton.webUrl;
      if (popup.ctaButton.webUrlIsExternal) {
        openNewWindow(webUrl);
      } else {
        push(webUrl);
      }
    }
  }, [hasNoPopupInfo, openNewWindow, popup, push, pushClickStore, pushMarketingBottomSheetDetailClick]);

  const handleCloseClick = React.useCallback(() => {
    if (popup?.id) {
      pushMarketingBottomSheetCloseClick({mkt_bottomsheet_id: popup.id});
    }
    closeDialog();
  }, [closeDialog, pushMarketingBottomSheetCloseClick, popup?.id]);

  const handleCloseForWholeDayClick = React.useCallback(() => {
    if (popup?.id) {
      pushMarketingBottomSheetNotTodayClick({mkt_bottomsheet_id: popup.id});
    }

    const currentDay = new Date();
    const midnightForCurrentDay = currentDay.setHours(23, 59, 59);
    storage.setItem('welcome-bottom-sheet-disable-expiration-time', midnightForCurrentDay);
    closeDialog();
  }, [closeDialog, storage, popup?.id, pushMarketingBottomSheetNotTodayClick]);

  const openBottomSheet = React.useCallback(() => {
    if (isDisabled || hasNoPopupInfo) return null;
    pushMarketingBottomSheetDisplay({mkt_bottomsheet_id: popup.id});

    openDialog(
      <WelcomeMarketingBottomSheet
        buttonContent={popup.ctaButton?.text}
        imgSrc={popup.backgroundImage.imageUrlX3}
        onCTAClick={handleCtaClick}
        onCloseClick={handleCloseClick}
        onCloseForWholeDayClick={handleCloseForWholeDayClick}
      />
    );
  }, [
    popup,
    isDisabled,
    hasNoPopupInfo,
    openDialog,
    handleCtaClick,
    handleCloseClick,
    handleCloseForWholeDayClick,
    pushMarketingBottomSheetDisplay,
  ]);

  useEffect(() => {
    if (!isSuccess) return;
    openBottomSheet();
    return () => closeDialog();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSuccess]);
}
