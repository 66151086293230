import React from 'react';
import {css} from '@emotion/react';

import {BREAKPOINT_MEDIUM, COLOR_SANTA_A, COLOR_SANTA_C} from '@riiid/design-system';
import {Button, TopNavBar} from '@santa-web/service-ui';
import {FrameLayout, ImageSet, ResponsiveImage, ScrollLayout} from '@app/components';
import {use100vh} from '@app/hooks/use100vh';

export type EventCtaButtonProps = {
  id: number;
  isDisabled: boolean;
  value: string;
};

export type EventDetailPageViewProps = {
  detailImageSet?: ImageSet;
  buttons: EventCtaButtonProps[];
  onButtonClick: (idx: number) => void;
  onExit: () => void;
};

const EventDetailPageView = ({
  detailImageSet,
  buttons,
  onButtonClick,
  onExit,
}: EventDetailPageViewProps): JSX.Element => {
  const height = use100vh();
  const buttonCount = buttons.length;
  const {bottomFixButton, subButtons} = React.useMemo(() => {
    if (buttonCount === 1 || buttonCount > 3) {
      const [bottomFixButton, ...subButtons] = buttons;
      return {bottomFixButton, subButtons};
    }
    return {
      subButtons: buttons,
    };
  }, [buttonCount, buttons]);

  return (
    <div
      css={css`
        height: ${height};
      `}>
      <FrameLayout
        topNavBar={<TopNavBar paginationIcon="back" onPaginationIconClick={onExit} />}
        bottomArea={
          bottomFixButton && (
            <Button
              colorVariant="brand"
              variant="bottom-fix"
              isDisabled={bottomFixButton.isDisabled}
              onClick={() => onButtonClick(bottomFixButton.id)}>
              {bottomFixButton.value}
            </Button>
          )
        }>
        <ScrollLayout
          css={css`
            height: 100%;
          `}>
          <div
            css={css`
              height: 100%;
              display: flex;
              flex-direction: column;
              align-items: center;
              justify-content: space-between;
              background-color: ${COLOR_SANTA_C};
            `}>
            <div
              css={css`
                width: 100%;
                max-width: ${BREAKPOINT_MEDIUM};
              `}>
              {detailImageSet && (
                <ResponsiveImage
                  css={css`
                    width: 100%;
                    display: block;
                  `}
                  imageSet={detailImageSet}
                />
              )}
            </div>
            {subButtons.length !== 0 && (
              <div
                css={[
                  css`
                    display: flex;
                    justify-content: center;
                    width: 100%;
                    padding: 40px 20px;
                    background-color: ${COLOR_SANTA_A};
                    @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
                      padding: 40px;
                    }
                  `,
                  buttonCount === 2 &&
                    css`
                      position: sticky;
                      bottom: 0;
                    `,
                ]}>
                <div
                  css={css`
                    width: 100%;
                    max-width: ${BREAKPOINT_MEDIUM};
                    display: grid;
                    gap: 8px;
                  `}>
                  {subButtons.map(({id, isDisabled, value}) => (
                    <Button
                      key={id}
                      colorVariant="brand"
                      variant={buttonCount > 3 ? 'box-line' : 'solid'}
                      isDisabled={isDisabled}
                      onClick={() => onButtonClick(id)}>
                      {value}
                    </Button>
                  ))}
                </div>
              </div>
            )}
          </div>
        </ScrollLayout>
      </FrameLayout>
    </div>
  );
};

export default React.memo(EventDetailPageView);
