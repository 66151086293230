/**
 * Check if a given object implements the LearningStatisticsAchievement interface.
 */
export function instanceOfLearningStatisticsAchievement(value) {
    let isInstance = true;
    isInstance = isInstance && "days" in value;
    isInstance = isInstance && "rate" in value;
    return isInstance;
}
export function LearningStatisticsAchievementFromJSON(json) {
    return LearningStatisticsAchievementFromJSONTyped(json, false);
}
export function LearningStatisticsAchievementFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'days': json['days'],
        'rate': json['rate'],
    };
}
export function LearningStatisticsAchievementToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'days': value.days,
        'rate': value.rate,
    };
}
