import {useMemo, useRef, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useQueryClient} from '@tanstack/react-query';
import {Currency, ListCouponsResponse} from '@santa-web/gen/open-api/service';
import {useToastContext} from '@santa-web/service-ui';
import {useDialogContext} from '@app/contexts/DialogContext';
import {createPriceFormatter} from '@app/facade/price/price-formatter';
import {
  Coupon,
  CouponRegisterFailAlert,
  CouponType,
  DiscountCoupon,
  PermissionCoupon,
  useCouponRegisterMutation,
} from '@app/features/coupon';
import {useTypedRouter} from '@app/hooks/useTypedRouter';
import {SantaResponseError} from '@app/utils/error';
import useGetCouponInfinityQuery from './useGetCouponInfinityQuery';

type CouponListFilter = 'AVAILABLE' | 'PAST';

const useCoupon = () => {
  const {t} = useTranslation();
  const queryClient = useQueryClient();
  const hasNextPageRef = useRef(false);
  const [couponListFilter, setCouponListFilter] = useState<CouponListFilter>('AVAILABLE');
  const {push, back} = useTypedRouter();
  const {openToast, toastContainer, closeToast} = useToastContext();
  const {openDialog, closeDialog} = useDialogContext();
  const {
    data: couponPages,
    isLoading,
    hasNextPage,
    fetchNextPage,
    queryKey,
  } = useGetCouponInfinityQuery(
    {availableCondition: couponListFilter === 'AVAILABLE', size: 30},
    {
      select: data => ({
        ...data,
        pages: data.pages.map(page => {
          return {
            ...page,
            coupons: couponMapper(page.coupons),
          };
        }),
      }),
    }
  );
  const {mutateAsync: registerCoupon} = useCouponRegisterMutation({
    onSuccess: () => {
      openToast({
        message: t('page_coupon_box_registration_success_toast'),
        colorVariant: 'brand',
      });

      queryClient.invalidateQueries(queryKey);
    },
  });

  hasNextPageRef.current = hasNextPage ?? false;

  const couponList = useMemo(() => couponPages?.pages.flatMap(page => page.coupons) ?? [], [couponPages]);

  const handleCouponUse = () => {
    push('/permit/list');
  };

  const handleRegisterCoupon = async (code: string) => {
    try {
      return await registerCoupon(code);
    } catch (error) {
      if (
        error instanceof SantaResponseError &&
        error.response.status === 400 &&
        error.santaErrorCode === 'INVALID_COUPON_CODE'
      ) {
        openDialog(<CouponRegisterFailAlert isVisible onClose={closeDialog} />);
      } else {
        throw error;
      }
    }
  };

  return {
    isLoading,
    couponList,
    hasNextPageRef,
    couponListFilter,
    openToast,
    closeToast,
    toastContainer,
    fetchNextPage,
    handleCouponUse,
    handleBackClick: back,
    setCouponListFilter,
    handleRegisterCoupon,
  };
};

const withCurrency = (value: string | number | undefined, currency: Currency) => {
  if (!value) return '';

  const unit = createPriceFormatter(currency).getCurrencyText();

  return `${Number(value).toLocaleString()}${unit}`;
};

const withRate = (value: string | number | undefined) => {
  if (!value) return '';
  return `${Number(value)}%`;
};

const couponMapper = (coupons: ListCouponsResponse['coupons']): Coupon[] => {
  return coupons
    .map<Coupon | undefined>(coupon => {
      switch (coupon.details.oneOfCase) {
        case 'DISCOUNT': {
          // invalid coupon interface
          if (!coupon.details.discount) return;

          const currency = coupon.details.discount.currency as Currency;

          return {
            couponType: CouponType.DISCOUNT,
            status: coupon.status,
            displayName: coupon.displayName,
            discountAmountWithUnit:
              coupon.details.discount.method.oneOfCase === 'AMOUNT'
                ? withCurrency(coupon.details.discount.method.amount, currency)
                : withRate(coupon.details.discount.method.rate),
            minimumPriceToApplyWithCurrency: withCurrency(coupon.details.discount.minPurchasePrice, currency),
            maximumDiscountAmountWithCurrency: withCurrency(coupon.details.discount.maxDiscountAmount, currency),
            from: coupon.details.discount.usableFrom,
            to: coupon.details.discount.usableUntil,
          } as DiscountCoupon;
        }
        case 'PERMIT':
          return {
            couponType: CouponType.PERMIT,
            status: coupon.status,
            displayName: coupon.displayName,
            includes: coupon.details.permit?.items,
          } as PermissionCoupon;
      }
    })
    .filter<Coupon>(isCoupon);
};

const isCoupon = (coupon: Coupon | undefined): coupon is Coupon => {
  return coupon !== undefined;
};

export default useCoupon;
