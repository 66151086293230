import {LearningDomainKey} from '@santa-web/gen/open-api/service';
import exhaustiveCheck from '@app/utils/exhaustiveCheck';

// NOTE: 추후 서버로 이관 예정인 임시 로직입니다
export const onboardingInformationFactory = (domainName: LearningDomainKey): OnboardingInformation => {
  switch (domainName) {
    case 'TOEIC':
      return new ToeicOnboardingInformation();
    case 'G_TELP':
      return new GtelpOnboardingInformation();
    default:
      exhaustiveCheck(domainName);
  }
};

export interface OnboardingInformation {
  defaultTargetScore: number;
  targetScoreItems: Array<{value: number; label?: string}>;
}

class ToeicOnboardingInformation implements OnboardingInformation {
  defaultTargetScore = 750;
  targetScoreItems = Array(55)
    .fill(null)
    .map((_, idx) => ({value: (45 + idx) * 10}));
}

class GtelpOnboardingInformation implements OnboardingInformation {
  private scoreUsageMap = {
    32: '군무원 9급',
    43: '경찰공무원(순경)',
    47: '군무원 7급',
    50: '경찰간부, 소방간부',
    65: '국가공무원 7급, 공인회계사, 국가공무원 5급, 세무사 등',
    73: '카투사',
    77: '변리사',
    88: '외교관 후보자',
  };
  defaultTargetScore = 65;
  targetScoreItems = Array(70)
    .fill(null)
    .map((_, idx) => {
      const value = 30 + idx;
      const entries = Object.entries(this.scoreUsageMap).reverse();
      const targetEntry = entries.find(([score]) => Number(score) <= value);
      if (!targetEntry) {
        return {value};
      }
      return {value, label: targetEntry[1]};
    });
}
