import React, {createContext, useCallback, useContext, useRef} from 'react';

import useComponentElapsedTimeEvents, {
  ExplanationsObservedPayload,
  DiagnosisReportObservedPayload,
} from '@app/api/google-tag-manager/component-elapsed-time';

const EXPLANATION = 'explanation';
const DIAGNOSIS_REPORT = 'diagnosisReport';

type TEventName = typeof EXPLANATION | typeof DIAGNOSIS_REPORT;
interface IDefaultComponentObservedPayload {
  questionCount?: number;
  contentId?: string;
  part?: string;
}

interface IObservingTimeContext {
  sendComponentElapsedTime: () => Promise<void>;
  setDefaultComponentObservedPayload: ({questionCount, contentId, part}: IDefaultComponentObservedPayload) => void;
  updateComponentObservedPayload: (
    payload: ExplanationsObservedPayload & DiagnosisReportObservedPayload,
    index: number
  ) => void;
  componentObservedPayload: React.MutableRefObject<ExplanationsObservedPayload[] & DiagnosisReportObservedPayload[]>;
  setIsAvailable: (available: boolean) => void;
}

const ObservingTimeContext = createContext<IObservingTimeContext | null>(null);

const useObservingTimeContextValue = (eventName: TEventName): IObservingTimeContext => {
  const {pushDiagnosisReportElapsedTimeEvent, pushExplanationsElapsedTime} = useComponentElapsedTimeEvents();
  const componentObservedPayload = useRef<ExplanationsObservedPayload[] & DiagnosisReportObservedPayload[]>([]);
  const isAvailable = useRef<boolean>(true);

  const setIsAvailable = useCallback((available: boolean) => (isAvailable.current = available), []);

  const setDefaultComponentObservedPayload = useCallback(
    ({questionCount = 1, contentId, part}: IDefaultComponentObservedPayload) => {
      componentObservedPayload.current = [];
      if (contentId) {
        for (let i = 0; i < questionCount; i++) {
          componentObservedPayload.current.push({
            content_id: contentId,
            part,
          });
        }
      }
    },
    [componentObservedPayload]
  );

  const updateComponentObservedPayload = useCallback(
    (payload: ExplanationsObservedPayload & DiagnosisReportObservedPayload, index: number) => {
      componentObservedPayload.current[index] = {...componentObservedPayload.current[index], ...payload};
    },
    [componentObservedPayload]
  );

  const sendComponentElapsedTime = useCallback(async () => {
    if (isAvailable.current) {
      componentObservedPayload.current.forEach(payload => {
        switch (eventName) {
          case EXPLANATION:
            pushExplanationsElapsedTime(payload);
            break;
          case DIAGNOSIS_REPORT:
            pushDiagnosisReportElapsedTimeEvent(payload);
            break;
        }
      });
    }
  }, [componentObservedPayload, eventName, pushDiagnosisReportElapsedTimeEvent, pushExplanationsElapsedTime]);

  return {
    componentObservedPayload,
    setDefaultComponentObservedPayload,
    updateComponentObservedPayload,
    sendComponentElapsedTime,
    setIsAvailable,
  };
};

export const ObservingTimeContextProvider = ({
  eventName,
  children,
}: {
  eventName: TEventName;
  children?: React.ReactNode;
}) => {
  const value = useObservingTimeContextValue(eventName);

  return <ObservingTimeContext.Provider value={value}>{children}</ObservingTimeContext.Provider>;
};

export const useObservingTimeContext = (): IObservingTimeContext | null => {
  const observingTimeContext = useContext(ObservingTimeContext);

  return observingTimeContext;
};
