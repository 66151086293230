import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {BREAKPOINT_MEDIUM, COLOR_SANTA_F, COLOR_SANTA_TOEIC_BRAND_4} from '@riiid/design-system';
import {Typography} from '@santa-web/service-ui';

type ExamScoreTotalProps = {
  totalScore?: number;
  isPercent: boolean;
};

const ExamScoreTotal = ({totalScore = 0, isPercent}: ExamScoreTotalProps) => {
  const {t} = useTranslation();
  const unit = t(`page_exam_score_form_score_unit`);

  return (
    <div
      css={css`
        display: flex;
        flex-direction: column;

        @media (min-width: ${BREAKPOINT_MEDIUM}) {
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
        }
      `}>
      <Typography variant="caption1" fontWeight="bold">
        {t('page_exam_score_form_auto_calculated_total')}
      </Typography>
      <Typography variant="h5" color={totalScore === 0 ? COLOR_SANTA_F : COLOR_SANTA_TOEIC_BRAND_4}>
        {totalScore}
        <Typography variant="h5" component="span" fontWeight="regular">
          {isPercent ? `${unit}(%)` : unit}
        </Typography>
      </Typography>
    </div>
  );
};

export default ExamScoreTotal;
export type {ExamScoreTotalProps};
ExamScoreTotal.displayName = 'ExamScoreTotal';
