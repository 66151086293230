import {useEffect, useState} from 'react';
import {OfferGroupCard, OfferGroupCardProps} from '@app/features/offer';

type OfferGroupCardContainerProps = OfferGroupCardProps;

const ONE_SECOND = 1000;

const OfferGroupCardContainer = (offerGroupCard: OfferGroupCardContainerProps) => {
  const [remainedTimeMs, setRemainedTimeMs] = useState(offerGroupCard?.timer?.remainedTimeMs ?? 0);

  useEffect(() => {
    const interval = setInterval(() => {
      setRemainedTimeMs(prev => {
        if (prev === 0) {
          clearInterval(interval);
          return 0;
        }

        const nextRemainedTimeMs = prev - ONE_SECOND;

        return nextRemainedTimeMs > 0 ? nextRemainedTimeMs : 0;
      });
    }, ONE_SECOND);
  }, []);

  return (
    <OfferGroupCard
      {...offerGroupCard}
      timer={offerGroupCard.timer ? {...offerGroupCard.timer, remainedTimeMs} : undefined}
    />
  );
};

export default OfferGroupCardContainer;
