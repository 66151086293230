import React from 'react';
import {useTranslation} from 'react-i18next';
import {COLOR_SANTA_BD, COLOR_SANTA_L} from '@riiid/design-system';
import EraserIcon from '@riiid/design-system/icons/Eraser';
import ToolsIcon from '@riiid/design-system/icons/Tools';

import {useAtomValue} from 'jotai';
import {IconButton, IconButtonProps, Tooltip} from '@santa-web/service-ui';
import useTestprepEvents from '@app/api/google-tag-manager/testprep';
import useOpenUserUnderlineTutorialAlertDialog from '@app/components/alert-dialog/UserUnderlineTutorialAlertDialog/useOpenUserUnderlineTutorialAlertDialog';
import useShouldShowTutorial from '@app/hooks/useShouldShowTutorial';
import {learningStore} from '@app/test-preparation/atoms/stores';
import MarkupToolsContext, {MarkupTool} from '@app/test-preparation/contexts/MarkupToolsContext';

type MarkupToolsBottomIconsProps = Partial<IconButtonProps>;

const MarkupToolsBottomIcons = (props: MarkupToolsBottomIconsProps) => {
  const {t} = useTranslation();

  const markupToolsContext = React.useContext(MarkupToolsContext);

  const {pushAllQuestionContentMarkIconClick} = useTestprepEvents();
  const getShouldShowTutorial = useShouldShowTutorial();
  const openUserUnderlineTutorialAlertDialog = useOpenUserUnderlineTutorialAlertDialog();

  const cisId = useAtomValue(learningStore.cis.idAtom);

  const [isTooltipOpened, setIsTooltipOpened] = React.useState(false);

  const createIconButtonProps = React.useCallback(
    (markupTool: Exclude<MarkupTool, 'NONE'>) => {
      const markIconType = markupTool === 'PENCIL' ? 'underline' : 'erase';

      return {
        onClick: () => {
          openUserUnderlineTutorialAlertDialog();

          const previousSelectedTool = markupToolsContext.selectedTool;
          markupToolsContext.onSelectTool(previousSelectedTool === markupTool ? 'NONE' : markupTool);

          pushAllQuestionContentMarkIconClick({
            mark_icon_type: markIconType,
            cis_id: cisId,
            turnOnOff: previousSelectedTool === markupTool ? 0 : 1,
          });
        },
        IconProps: {
          type: markupToolsContext.selectedTool === markupTool ? 'solid' : 'line',
          color: markupToolsContext.selectedTool === markupTool ? COLOR_SANTA_BD : COLOR_SANTA_L,
        },
      } as const;
    },
    [cisId, markupToolsContext, openUserUnderlineTutorialAlertDialog, pushAllQuestionContentMarkIconClick]
  );

  React.useEffect(() => {
    (async () => {
      const {showTutorial: shouldShowTutorial} = await getShouldShowTutorial({
        tutorialType: 'TESTPREP_UNDERLINE_TOOLTIP',
      });

      if (!shouldShowTutorial) {
        return;
      }

      setIsTooltipOpened(true);
      setTimeout(() => {
        setIsTooltipOpened(false);
      }, 3000);
    })();
  }, [getShouldShowTutorial]);

  return (
    <>
      <Tooltip
        isOpened={isTooltipOpened}
        TooltipCardProps={{
          arrowAlign: 'center',
          arrowDirection: 'block-end',
          color: 'brand',
          size: 'medium',
          content: t('user_underline_tutorial_tooltip'),
        }}>
        <IconButton Icon={ToolsIcon} {...createIconButtonProps('PENCIL')} {...props} />
      </Tooltip>
      <IconButton Icon={EraserIcon} {...createIconButtonProps('ERASER')} {...props} />
    </>
  );
};

export default MarkupToolsBottomIcons;
