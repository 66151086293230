import React from 'react';
import {css} from '@emotion/react';
import {COLOR_SANTA_H, COLOR_SANTA_J} from '@riiid/design-system';

import {Typography} from '@santa-web/service-ui';

type Size = 'small' | 'large';

export interface PartScoreProps {
  name: string;
  score: number | string;
  size: Size;
}

const STYLES_BY_SIZE: Record<Size, React.CSSProperties> = {
  large: {width: 80},
  small: {width: 53.33},
};

const PartScore = ({name, score, size}: PartScoreProps) => {
  return (
    <div
      css={css`
        width: ${STYLES_BY_SIZE[size].width}px;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      `}>
      <Typography variant="body2" fontWeight="regular" color={COLOR_SANTA_J}>
        {score}
      </Typography>
      <Typography variant="caption2" fontWeight="regular" color={COLOR_SANTA_H}>
        {name}
      </Typography>
    </div>
  );
};

export default React.memo(PartScore);
