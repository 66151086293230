import React from 'react';
import {css} from '@emotion/react';

import Legend from '@app/components/Legend';

export interface LegendListProps extends React.ComponentPropsWithoutRef<'div'> {
  children: React.ReactNode | React.ReactComponentElement<typeof Legend> | React.ReactComponentElement<typeof Legend>[];
}

const LegendList = React.forwardRef<HTMLDivElement, LegendListProps>(({children, ...props}, ref) => {
  return (
    <div
      css={css`
        display: flex;
        justify-content: center;
        width: 100%;
      `}
      {...props}
      ref={ref}>
      <div
        css={css`
          display: grid;
          grid-auto-flow: column;
          grid-gap: 16px;
          gap: 16px;
        `}>
        {children}
      </div>
    </div>
  );
});

export default React.memo(LegendList);
