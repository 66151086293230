import React from 'react';
import {css} from '@emotion/react';
import {TopNavBar} from '@santa-web/service-ui';
import ScrollLayout from '@app/components/ScrollLayout';
import TabButton from '@app/components/TabButton';
import TabButtonList from '@app/components/TabButtonList';
import {OfferCard, OfferLayout} from '@app/features/offer';
import {DynamicImageLoader, OfferGroupDetailTimer} from '@app/features/offer/components';
import {OfferGroupDetail} from '@app/features/offer/views/types';

type OfferGroupAndOfferDetailListViewProps = {
  offerGroupDetails: OfferGroupDetail[];
  currentOfferGroupIndex: number;
  onCurrentOfferGroupIndexChange(index: number): void;
  onBackClick(): void;
};

const OfferGroupAndOfferDetailListView = ({
  offerGroupDetails,
  currentOfferGroupIndex,
  onCurrentOfferGroupIndexChange,
  onBackClick,
}: OfferGroupAndOfferDetailListViewProps) => {
  const [timerLeftMargin, setTimerLeftMargin] = React.useState(-999);

  const {offerCards, imageSrcs, title} = offerGroupDetails[currentOfferGroupIndex];
  const currentTimer = offerGroupDetails[currentOfferGroupIndex].timer;
  const hasValidTimer = currentTimer && currentTimer.remainedTimeMs >= 0;

  const handleTabButtonClick = (index: number) => {
    if (index === currentOfferGroupIndex) {
      return;
    }
    onCurrentOfferGroupIndexChange(index);
  };

  return (
    <OfferLayout topNavBar={<TopNavBar paginationIcon="back" onPaginationIconClick={onBackClick} />}>
      <div
        css={css`
          display: flex;
          justify-content: center;
          height: max-content;
          // offer card list의 shadow가 잘리지 않게 하기 위해 패딩이 각각 설정됨
          padding: 0 20px;
        `}>
        <div
          css={css`
            position: relative;
            display: flex;
            flex-direction: column;
            flex: 1;
            width: 100%;
            max-width: 1000px;
            padding-left: 20px;
          `}>
          <TabButtonList
            css={css`
              margin: 40px 0 32px;
            `}>
            {offerGroupDetails.map((offerGroup, index) => {
              return (
                <TabButton
                  key={offerGroup.id}
                  onClick={() => handleTabButtonClick(index)}
                  isSelected={index === currentOfferGroupIndex}>
                  {offerGroup.title}
                </TabButton>
              );
            })}
          </TabButtonList>
          <div
            css={css`
              display: flex;
              align-items: flex-start;
            `}>
            <div
              ref={element => {
                if (element) {
                  const {width, x} = element.getBoundingClientRect();
                  setTimerLeftMargin(x + width / 2);
                }
              }}
              css={css`
                width: 100%;
              `}>
              {hasValidTimer && (
                <OfferGroupDetailTimer
                  css={css`
                    position: sticky;
                    margin-top: 24px;
                    top: 24px;
                    left: ${timerLeftMargin}px;
                    width: fit-content;
                    z-index: 1;
                    transform: translateX(-50%);
                  `}
                  {...currentTimer}
                />
              )}
              <DynamicImageLoader
                key={currentOfferGroupIndex}
                urls={imageSrcs}
                imgAlt={`${title}Image`}
                imgCss={[
                  css`
                    border-radius: 8px;
                    padding-bottom: 80px;
                  `,
                  hasValidTimer &&
                    css`
                      margin-top: -48px;
                    `,
                ]}
                imgStateWrapperCss={css`
                  padding-bottom: 80px;
                `}
              />
            </div>
            <div
              css={css`
                position: sticky;
                top: 36px;
                left: 0;
                width: 100%;
                max-width: 392px;
              `}>
              <ScrollLayout
                css={css`
                  // top nav bar 높이 + top position 위치
                  height: calc(100vh - 58px - 36px);
                  display: flex;
                  flex-direction: column;
                  gap: 8px;
                  padding: 0px 20px 80px 20px;
                `}>
                {offerCards.map(offer => {
                  return <OfferCard key={offer.id} {...offer} hasOfferOptions={false} hasShadow />;
                })}
              </ScrollLayout>
            </div>
          </div>
        </div>
      </div>
    </OfferLayout>
  );
};

export default OfferGroupAndOfferDetailListView;
