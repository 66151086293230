import {useEffect, useState} from 'react';
import {CountdownTimer} from '@app/features/marketing';

const CountDownTimerContainer = ({remainTimeMs}: {remainTimeMs: number}) => {
  const [remainedTimeMs, setRemainedTimeMs] = useState(remainTimeMs);

  const hours = Math.floor(remainedTimeMs / (3600 * 1000));
  const minutes = Math.floor((remainedTimeMs % (3600 * 1000)) / (60 * 1000));
  const seconds = Math.floor((remainedTimeMs % (60 * 1000)) / 1000);

  useEffect(() => {
    const timer = setInterval(() => {
      setRemainedTimeMs(prev => {
        if (prev <= 0) {
          clearInterval(timer);
          return 0;
        }
        return prev - 1000;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  return <CountdownTimer hour={hours} minute={minutes} second={seconds} />;
};

export default CountDownTimerContainer;
