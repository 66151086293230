import {
  WebviewHandlerName as BrowserHandlerName,
  NotifyDeepLinkRequestPayload,
  NotifyDeepLinkResponsePayload,
  NotifyPointerUpRequestPayload,
  NotifyPointerUpResponsePayload,
  NotifyBackButtonPressRequestPayload,
  NotifyBackButtonPressResponsePayload,
  NotifyDeviceStateChangeRequestPayload,
  NotifyDeviceStateChangeResponsePayload,
} from '@santa-web/gen/ssp/messages/bridge/webview';
import {appBridgeRepository} from '@app/api/app-bridge/AppBridgeRepository';
import {IAppBridgeRepository} from '@app/api/app-bridge/AppBridgeRepository.interface';

export type DeviceState = 'FOREGROUND' | 'BACKGROUND';

export class BrowserService {
  constructor(private readonly repository: IAppBridgeRepository<never, BrowserHandlerName>) {}

  subscribeDeepLink(callback: (url: string) => Promise<void>) {
    return this.repository.subscribeToRequest<NotifyDeepLinkRequestPayload, NotifyDeepLinkResponsePayload>(
      'NOTIFY_DEEP_LINK',
      ({deepLink}) => {
        return callback(deepLink).then(() => ({}));
      }
    );
  }

  subscribePointerUp(callback: (dx: number, dy: number) => Promise<void>) {
    return this.repository.subscribeToRequest<NotifyPointerUpRequestPayload, NotifyPointerUpResponsePayload>(
      'NOTIFY_POINTER_UP',
      ({dx, dy}) => {
        return callback(dx, dy).then(() => ({}));
      }
    );
  }

  subscribeBackButtonPress(callback: () => Promise<void>) {
    return this.repository.subscribeToRequest<
      NotifyBackButtonPressRequestPayload,
      NotifyBackButtonPressResponsePayload
    >('NOTIFY_BACK_BUTTON_PRESS', async () => {
      return callback().then(() => ({}));
    });
  }

  subscribeDeviceStateChange(callback: (state: DeviceState) => Promise<void>) {
    return this.repository.subscribeToRequest<
      NotifyDeviceStateChangeRequestPayload,
      NotifyDeviceStateChangeResponsePayload
    >('NOTIFY_DEVICE_STATE_CHANGE', ({state}) => {
      return callback(state).then(() => ({}));
    });
  }

  subscribeMultipleEvents(
    callbacks: Partial<{
      onBackButtonPress: Parameters<BrowserService['subscribeBackButtonPress']>[0];
      onDeepLink: Parameters<BrowserService['subscribeDeepLink']>[0];
      onPointerUp: Parameters<BrowserService['subscribePointerUp']>[0];
      onDeviceStateChange: Parameters<BrowserService['subscribeDeviceStateChange']>[0];
    }>
  ) {
    const unsubscribeHandlers = [
      callbacks.onBackButtonPress && this.subscribeBackButtonPress(callbacks.onBackButtonPress),
      callbacks.onDeepLink && this.subscribeDeepLink(callbacks.onDeepLink),
      callbacks.onPointerUp && this.subscribePointerUp(callbacks.onPointerUp),
      callbacks.onDeviceStateChange && this.subscribeDeviceStateChange(callbacks.onDeviceStateChange),
    ];
    return () => {
      unsubscribeHandlers.forEach(unsubscribe => unsubscribe?.());
    };
  }
}

export const browserService = new BrowserService(appBridgeRepository);
