import {Atom} from 'jotai';

const setAtomLabels = (atomMap: Record<string, Atom<unknown>>, labelPrefix = '') => {
  Object.entries(atomMap).forEach(([key, atom]) => {
    if (key.startsWith('_')) {
      atom.debugPrivate = true;
    } else {
      atom.debugLabel = `${labelPrefix}${key.replace(/Atom$/, '')}`;
    }
  });
};

export {setAtomLabels};
