import {useInfiniteQuery} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';
import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {getTicketsQueryKey} from '@app/queryKeys';

const PAGE_SIZE = 20;

export const useTicketGroupsInfiniteQuery = () => {
  const {TicketService} = useAtomValue(santaOpenapiServicesAtom);
  return useInfiniteQuery(
    getTicketsQueryKey(),
    async ({pageParam: cursor}) => {
      return await TicketService.listMyTicketGroups({cursor, size: 20});
    },
    {getNextPageParam: lastPage => (lastPage.ticketGroups.length === PAGE_SIZE ? lastPage.page.cursor : undefined)}
  );
};
