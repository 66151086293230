import {useQueryClient, useMutation} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';

import {ChatMessage, ChatMessageStatus} from '@santa-web/gen/open-api/content-learning-service';
import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {getChatMessagesQueryKey} from '@app/queryKeys';
import {SantaResponseError} from '@app/utils/error';

type MutationVariables = {
  chatRoomId: number;
  chatMessageId: number;
};

const useReplyChatMessageMutation = () => {
  const queryClient = useQueryClient();

  const {ChatMessageService} = useAtomValue(santaOpenapiServicesAtom);

  return useMutation(
    async ({chatMessageId}: MutationVariables) => {
      try {
        return await ChatMessageService.getReplyChatMessage({
          messageId: chatMessageId,
        }).then(({message: chatMessage}) => chatMessage);
      } catch (error) {
        if (error instanceof SantaResponseError && error.response.status === 404) {
          return null;
        }

        throw error;
      }
    },
    {
      onSuccess: async (newChatMessage, {chatRoomId, chatMessageId}) => {
        const chatMessagesQueryKey = getChatMessagesQueryKey(chatRoomId);

        await queryClient.cancelQueries(chatMessagesQueryKey);
        queryClient.setQueryData<Array<ChatMessage>>(chatMessagesQueryKey, oldChatMessages =>
          oldChatMessages
            ?.map(chatMessage =>
              chatMessage.id === chatMessageId
                ? {...chatMessage, status: newChatMessage ? ChatMessageStatus.NONE : ChatMessageStatus.ERROR}
                : chatMessage
            )
            .concat(
              newChatMessage && !oldChatMessages.find(chatMessage => chatMessage.id === newChatMessage.id)
                ? newChatMessage
                : []
            )
        );
      },
    }
  );
};

export default useReplyChatMessageMutation;
