import React from 'react';
import {css} from '@emotion/react';
import {COLOR_SANTA_A} from '@riiid/design-system';
import CheckIcon from '@riiid/design-system/icons/Check';
import CloseIcon from '@riiid/design-system/icons/Close';

import {Typography} from '@santa-web/service-ui';

export type QaButtonIconProps = Omit<React.HTMLAttributes<HTMLDivElement>, 'content'> & {
  content?: React.ReactNode;
  color: React.CSSProperties['color'];
  isCompleted?: boolean;
  isCorrect?: boolean;
  isSelected?: boolean;
};

const QaButtonIcon = React.forwardRef<HTMLDivElement, QaButtonIconProps>(
  ({content, color, isCompleted, isCorrect, isSelected, ...props}, ref): JSX.Element => {
    const contentColor = isSelected ? COLOR_SANTA_A : color;
    const children = (() => {
      if (isCompleted) {
        if (isCorrect) {
          return <CheckIcon data-testid="check-icon" color={contentColor} />;
        } else {
          if (isSelected) {
            return <CloseIcon data-testid="close-icon" color={contentColor} />;
          }
        }
      }
      return (
        <Typography fontWeight="bold" variant="body2" color={contentColor}>
          {content}
        </Typography>
      );
    })();

    return (
      <div
        ref={ref}
        css={[
          css`
            display: flex;
            align-items: center;
            justify-content: center;

            width: 40px;
            height: 40px;

            border-radius: 50%;
            background-color: ${isSelected ? color : 'transparent'};
            border: 1px solid ${color};
          `,
        ]}
        {...props}>
        {children}
      </div>
    );
  }
);

export default QaButtonIcon;

QaButtonIcon.displayName = 'QaButtonIcon';
