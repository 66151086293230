import React from 'react';

import {useAtomValue} from 'jotai';
import {TopNavBarProps} from '@santa-web/service-ui';

import {learningStore} from '@app/test-preparation/atoms/stores';
import ToeicCourseLessonTopNavBar from './ToeicCourseLessonTopNavBar';
import ToeicCourseQuestionTopNavBar from './ToeicCourseQuestionTopNavBar';
import ToeicCourseVocabularyTopNavBar from './ToeicCourseVocabularyTopNavBar';

type Props = TopNavBarProps;

const ToeicCourseTopNavBar = (props: Props): JSX.Element => {
  const isLessonCell = useAtomValue(learningStore.cell.isLessonAtom);
  const isVocabularyCell = useAtomValue(learningStore.cell.isVocabularyAtom);

  if (isLessonCell) {
    return <ToeicCourseLessonTopNavBar {...props} />;
  }

  if (isVocabularyCell) {
    return <ToeicCourseVocabularyTopNavBar {...props} />;
  }

  return <ToeicCourseQuestionTopNavBar {...props} />;
};

export default React.memo(ToeicCourseTopNavBar);
