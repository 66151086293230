import {atom} from 'jotai';

import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {LearningCell} from '@app/types/learning-cell';

const updateLearningCellAtom = atom(async get => {
  const {LearningCellService} = await get(santaOpenapiServicesAtom);

  return async (learningCell: LearningCell) =>
    await LearningCellService.updateLearningCell({
      id: learningCell.id,
      updateLearningCellRequest: {
        state: learningCell.state,
      },
    });
});

export {updateLearningCellAtom};
