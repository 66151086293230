import { ToeicVirtualExamQuestionAnswerStateFromJSON, ToeicVirtualExamQuestionAnswerStateToJSON, } from './ToeicVirtualExamQuestionAnswerState';
/**
 * Check if a given object implements the ToeicVirtualExamEventDetailsPlaceAnswer interface.
 */
export function instanceOfToeicVirtualExamEventDetailsPlaceAnswer(value) {
    let isInstance = true;
    isInstance = isInstance && "questionAnswerState" in value;
    isInstance = isInstance && "targetContentIdx" in value;
    isInstance = isInstance && "targetQuestionNumber" in value;
    return isInstance;
}
export function ToeicVirtualExamEventDetailsPlaceAnswerFromJSON(json) {
    return ToeicVirtualExamEventDetailsPlaceAnswerFromJSONTyped(json, false);
}
export function ToeicVirtualExamEventDetailsPlaceAnswerFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'questionAnswerState': ToeicVirtualExamQuestionAnswerStateFromJSON(json['questionAnswerState']),
        'targetContentIdx': json['targetContentIdx'],
        'targetQuestionNumber': json['targetQuestionNumber'],
    };
}
export function ToeicVirtualExamEventDetailsPlaceAnswerToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'questionAnswerState': ToeicVirtualExamQuestionAnswerStateToJSON(value.questionAnswerState),
        'targetContentIdx': value.targetContentIdx,
        'targetQuestionNumber': value.targetQuestionNumber,
    };
}
