import {useQuery} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';
import learningDomainAtom from '@app/atoms/core/learning-domain';
import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {getLatestOfferGroupDisplaysAtQueryKey} from '@app/queryKeys';
import {SantaResponseError} from '@app/utils/error';

export const useLatestOfferGroupDisplaysAtQuery = () => {
  const learningDomain = useAtomValue(learningDomainAtom);
  const {OfferService} = useAtomValue(santaOpenapiServicesAtom);
  return useQuery<Date | null>({
    queryKey: getLatestOfferGroupDisplaysAtQueryKey(),
    queryFn: async () => {
      try {
        const result = await OfferService.checkNewOfferGroup({});
        return result.mostRecentStartsAt ?? null;
      } catch (e) {
        if (e instanceof SantaResponseError && e.response.status === 404) {
          return null;
        }

        throw e;
      }
    },
    enabled: !learningDomain.isInFreePromotion,
  });
};
