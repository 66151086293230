import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';
import {ListExamDatesResponse} from '@santa-web/gen/open-api/service';
import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {StringDate} from '@app/features/preferences/types';
import {getExamDatesQueryKey} from '@app/queryKeys';

const useGetExamDatesQuery = <T>({
  args,
  options,
}: {
  args: {startDate: StringDate; endDate: StringDate};
  options?: UseQueryOptions<ListExamDatesResponse, unknown, T, ReturnType<typeof getExamDatesQueryKey>>;
}) => {
  const {ExamDateService} = useAtomValue(santaOpenapiServicesAtom);
  return useQuery(
    getExamDatesQueryKey(args.startDate, args.endDate),
    async () =>
      await ExamDateService.listExamDates({
        from: new Date(args.startDate),
        to: new Date(args.endDate),
      }),
    options
  );
};

export default useGetExamDatesQuery;
