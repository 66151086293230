import React from 'react';
import {Field, FieldProps, FieldRenderProps} from 'react-final-form';

import {DropdownCombobox, DropdownComboboxProps} from '@santa-web/service-ui';

export type DropdownComboboxFieldProps<T> = Pick<
  FieldProps<T, FieldRenderProps<T>>,
  'name' | 'validate' | 'validateFields'
> &
  Pick<DropdownComboboxProps<T>, 'placeholder' | 'options'>;

function DropdownComboboxField<T>({
  name,
  validate,
  validateFields = [],
  ...dropdownComboboxProps
}: DropdownComboboxFieldProps<T>) {
  return (
    <Field<T> name={name} validate={validate} validateFields={validateFields}>
      {({input}) => {
        return <DropdownCombobox<T> {...dropdownComboboxProps} value={input.value} onChange={input.onChange} />;
      }}
    </Field>
  );
}

export default React.memo(DropdownComboboxField) as typeof DropdownComboboxField;
