import {css} from '@emotion/react';

import SingleColumnLayout from '@app/test-preparation/components/SingleColumnLayout';
import TestPrepLayout, {TestPrepLayoutProps} from '@app/test-preparation/components/TestPrepLayout';
import {GTELP_LISTENING_QUESTION_VIEW_MAX_WIDTH} from '@app/test-preparation/constants/test-preparation-layout';

export type GtelpListeningQuestionViewLayoutProps = TestPrepLayoutProps;

const GtelpListeningQuestionViewLayout = ({children, ...rest}: TestPrepLayoutProps) => {
  return (
    <TestPrepLayout maxWidth={GTELP_LISTENING_QUESTION_VIEW_MAX_WIDTH} {...rest}>
      <SingleColumnLayout>
        <div
          css={css`
            flex: 1;
          `}>
          {children}
        </div>
      </SingleColumnLayout>
    </TestPrepLayout>
  );
};

export default GtelpListeningQuestionViewLayout;
