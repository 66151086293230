import React, {useRef} from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {COLOR_SANTA_E, BREAKPOINT_MEDIUM} from '@riiid/design-system';

import {QuestionState} from '@santa-web/gen/open-api/content-learning-service';
import {QuestionSet} from '@santa-web/gen/open-api/content/models/QuestionSet';
import {
  BlankContext,
  ObjectiveQuestionPage,
  SingleQuestionTitle,
  MultiQuestionTitleList,
  MultiQuestionTitle,
  SecondPageLayout,
  useFloatingChatButtons,
  useFloatingChatButtonList,
  useBlankContext,
  useIsGteMediumScreen,
} from '@santa-web/service-ui';
import {getQuestionTitle} from '@app/components/ContentView/utilities';
import {VirtualExamFloatingChatButtonBox} from '@app/components/virtual-exam';
import * as QuestionStateFacade from '@app/facade/question-state';
import useFloatingAreaHeight from '@app/hooks/useFloatingAreaHeight';
import {floatingButtonPriorities} from '@app/new-structure/entities/virtual-exam/FloatingButton';
import DoubleColumnLayout from '@app/test-preparation/components/DoubleColumnLayout';
import ExplanationListView from '@app/test-preparation/components/ExplanationListView';
import PassageListView from '@app/test-preparation/components/PassageListView';
import QuestionView from '@app/test-preparation/components/QuestionView';

const QUESTION_INDEX_PASSAGE = -1;

const SECOND_CONTENT_DEFAULT_HEIGHT = '40%';
const SECOND_CONTENT_MIN_HEIGHT = 28;
const SECOND_CONTENT_MAX_HEIGHT = '100%';

interface VirtualExamPart6To7ViewProps {
  contentId: string;
  questionSet: QuestionSet;
  questionStates: QuestionState[];
  questionStartNumber: number;
  onQuestionAnswerStateChange: (index: number) => (questionState: QuestionState) => void | Promise<void>;
}

const VirtualExamPart6To7View = ({
  contentId,
  questionSet,
  questionStates,
  questionStartNumber: startQuestionIndex,
  onQuestionAnswerStateChange,
}: VirtualExamPart6To7ViewProps) => {
  const {t} = useTranslation();

  const secondContentRef = useRef<HTMLDivElement>(null);

  const blankContext = useBlankContext();
  const isGteMediumScreen = useIsGteMediumScreen();

  const [questionIndex, setQuestionIndex] = React.useState(0);

  React.useEffect(() => {
    // Initialize question index when questionSet changed.
    setQuestionIndex(0);
  }, [questionSet]);

  const questions = questionSet.questions;
  const question = questions[questionIndex];
  const questionState = questionStates[questionIndex];
  const isSelected = QuestionStateFacade.getIsSelected(questionState);
  const isCompleted = questionStates.every(QuestionStateFacade.getIsSelected);

  const numberOfQuestions = questions.length ?? 0;
  const questionIndices = [...Array(numberOfQuestions).keys()];
  const endQuestionIndex = startQuestionIndex + numberOfQuestions - 1;

  const scrollSecondContentToTop = React.useCallback(() => {
    secondContentRef.current?.scrollTo({top: 0, behavior: 'smooth'});
  }, []);

  const handleContinueToSolveClick = React.useCallback(() => {
    setQuestionIndex(questionIndex => questionIndex + 1);
    scrollSecondContentToTop();
  }, [scrollSecondContentToTop]);

  const passageListView = (
    <BlankContext.Provider value={blankContext}>
      <PassageListView passages={questionSet.passages} />
    </BlankContext.Provider>
  );
  const explanationListView = <ExplanationListView explanations={questionSet.explanations} />;

  const {floatingChatButtonList} = useFloatingChatButtonList();
  const floatingAreaHeight = useFloatingAreaHeight();

  const firstContent = (
    <div
      css={css`
        margin-bottom: ${floatingAreaHeight}px;
      `}>
      <ObjectiveQuestionPage
        questionTitle={<SingleQuestionTitle content={getQuestionTitle(startQuestionIndex, endQuestionIndex)} />}
        passages={passageListView}
      />
    </div>
  );

  const floatingChatButtons = React.useMemo(
    () => [
      ...(function* () {
        if (!isGteMediumScreen && isSelected && questionIndex !== numberOfQuestions - 1 && !isCompleted) {
          yield {
            priority: floatingButtonPriorities['goToNextPartContent'],
            props: {
              content: t('virtual_exam_floating_button_next_content'),
              onClick: handleContinueToSolveClick,
            },
          };
        }
      })(),
    ],
    [isSelected, isGteMediumScreen, isCompleted, handleContinueToSolveClick, questionIndex, numberOfQuestions, t]
  );

  useFloatingChatButtons(floatingChatButtons);

  const secondContentView = (() => {
    if (isGteMediumScreen) {
      return (
        <div>
          {questionIndex === QUESTION_INDEX_PASSAGE ? (
            <ObjectiveQuestionPage explanations={explanationListView} />
          ) : (
            <div
              css={css`
                & > *:not(:last-child)::after {
                  display: flex;
                  height: 1px;
                  margin-right: 20px;
                  margin-left: 20px;
                  background: ${COLOR_SANTA_E};
                  content: '';
                  @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
                    margin-right: 40px;
                    margin-left: 40px;
                  }
                }
              `}>
              {questions.map((question, questionIndex) => (
                <div key={questionIndex}>
                  <QuestionView
                    isCompleted={false}
                    contentId={contentId}
                    questionTitle={
                      <SingleQuestionTitle content={getQuestionTitle(startQuestionIndex + questionIndex)} />
                    }
                    question={question}
                    questionState={questionStates[questionIndex]}
                    onChange={onQuestionAnswerStateChange(questionIndex)}
                    canSelectUnsolvableQuestion={false}
                  />
                </div>
              ))}
            </div>
          )}
        </div>
      );
    } else {
      const questionTitle = (
        <MultiQuestionTitleList>
          {questionIndices.map(index => {
            return (
              <MultiQuestionTitle
                key={index}
                type={index === QUESTION_INDEX_PASSAGE ? 'passage' : 'question'}
                isActive={index === questionIndex}
                isSolved={QuestionStateFacade.getIsSelected(questionStates[index])}
                onClick={() => {
                  setQuestionIndex(index);
                }}>
                {index === QUESTION_INDEX_PASSAGE
                  ? t('toeic_question_view_multi_question_title_passage')
                  : t('toeic_question_view_multi_question_title_question_index', {index: startQuestionIndex + index})}
              </MultiQuestionTitle>
            );
          })}
        </MultiQuestionTitleList>
      );
      if (questionIndex === QUESTION_INDEX_PASSAGE) {
        return <ObjectiveQuestionPage questionTitle={questionTitle} explanations={explanationListView} />;
      } else {
        return (
          <QuestionView
            isCompleted={false}
            contentId={contentId}
            question={question}
            questionState={questionState}
            questionTitle={questionTitle}
            onChange={onQuestionAnswerStateChange(questionIndex)}
            canSelectUnsolvableQuestion={false}
          />
        );
      }
    }
  })();

  const secondContent = (
    <div
      css={css`
        display: flex;
        flex-direction: column;

        min-height: 100%;
      `}>
      <div
        css={css`
          flex: 1;
          margin-bottom: ${floatingAreaHeight}px;
        `}>
        {secondContentView}
      </div>
    </div>
  );

  return (
    <DoubleColumnLayout>
      <SecondPageLayout
        defaultHeight={SECOND_CONTENT_DEFAULT_HEIGHT}
        minHeight={SECOND_CONTENT_MIN_HEIGHT}
        maxHeight={SECOND_CONTENT_MAX_HEIGHT}
        content={firstContent}
        secondContent={secondContent}
        secondContentRef={secondContentRef}
      />
      <VirtualExamFloatingChatButtonBox
        css={css`
          max-width: 1360px;
        `}>
        {floatingChatButtonList}
      </VirtualExamFloatingChatButtonBox>
    </DoubleColumnLayout>
  );
};

export default React.memo(VirtualExamPart6To7View);
