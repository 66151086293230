import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {BrowserStorage} from '@santa-web/service-common';
import {Button, SinglePageLayout, SystemErrorLayout} from '@santa-web/service-ui';
import {BottomSheetWithDim, LoadingDim} from '@app/components';
import config from '@app/config';
import {useDialogContext} from '@app/contexts/DialogContext';
import {useGetMobileAppInfoQuery, useGetSystemConfigAppVersionsQuery} from '@app/features/preferences';
import {use100vh} from '@app/hooks/use100vh';
import {useOpenNewWindow} from '@app/hooks/useOpenNewWindow';
import createVersionWrapper from '@app/new-structure/useCases/VersionChecker/VersionWrapper';

type MobileAppUpdateGuardProps = {children?: React.ReactNode};

const MobileAppUpdateGuard = ({children}: MobileAppUpdateGuardProps) => {
  const {t} = useTranslation();
  const browserStorage = React.useMemo(
    () => new BrowserStorage<{lastRecommendedUpdateVersion: string}>(localStorage),
    []
  );
  const openNewWindow = useOpenNewWindow();
  const {openDialog, closeDialog} = useDialogContext();
  const {data: appInfo} = useGetMobileAppInfoQuery();
  const {data: systemConfigAppVersions} = useGetSystemConfigAppVersionsQuery(appInfo?.platform);

  // [강제 업데이트 여부, 선택 업데이트 여부]
  const [shouldUpdate, shouldRecommendUpdate] = React.useMemo(() => {
    if (!systemConfigAppVersions || !appInfo?.version) {
      return [null, null];
    }
    const currentAppVersion = appInfo.version;
    return [
      createVersionWrapper(systemConfigAppVersions.required).gt(currentAppVersion),
      createVersionWrapper(systemConfigAppVersions.optional).gt(currentAppVersion),
    ];
  }, [appInfo, systemConfigAppVersions]);

  const handleUpdateButtonClick = React.useCallback(() => {
    openNewWindow(appInfo?.platform === 'ANDROID' ? config.playStoreUrl : config.appStoreUrl);
  }, [appInfo?.platform, openNewWindow]);

  const isLoading = shouldUpdate == null || shouldRecommendUpdate == null;

  React.useEffect(() => {
    if (!systemConfigAppVersions) {
      return;
    }
    const recommendedVersionString = `${systemConfigAppVersions.optional.major}.${systemConfigAppVersions.optional.minor}.${systemConfigAppVersions.optional.patch}`;
    const lastRecommendedUpdateVersion = browserStorage.getItem('lastRecommendedUpdateVersion');
    if (shouldRecommendUpdate && lastRecommendedUpdateVersion !== recommendedVersionString) {
      openDialog(
        <BottomSheetWithDim
          isOpened
          BottomSheetProps={{
            title: t('component_update_recommended_bottom_sheet_title'),
            description: t('component_update_recommended_bottom_sheet_description'),
            isLoadingAiAnimationVisible: true,
            buttons: [
              <Button key="update" isFullWidth colorVariant="brand" onClick={handleUpdateButtonClick}>
                {t('component_update_recommended_bottom_sheet_positive_button')}
              </Button>,
              <Button
                key="reject"
                isFullWidth
                variant="box-line"
                onClick={() => {
                  browserStorage.setItem('lastRecommendedUpdateVersion', recommendedVersionString);
                  closeDialog();
                }}>
                {t('component_update_recommended_bottom_sheet_negative_button')}
              </Button>,
            ],
          }}
        />
      );
    }
  }, [
    browserStorage,
    closeDialog,
    handleUpdateButtonClick,
    openDialog,
    shouldRecommendUpdate,
    systemConfigAppVersions,
    t,
  ]);

  if (isLoading) {
    return <LoadingDim />;
  }

  if (shouldUpdate) {
    return (
      <UpdateRequiredView isAndroid={appInfo?.platform === 'ANDROID'} onUpdateButtonClick={handleUpdateButtonClick} />
    );
  }
  return <>{children}</>;
};

const UpdateRequiredView = ({
  isAndroid,
  onUpdateButtonClick,
}: {
  isAndroid?: boolean;
  onUpdateButtonClick: () => void;
}) => {
  const viewportHeight = use100vh();
  const {t} = useTranslation();

  return (
    <div
      css={css`
        width: 100%;
        height: ${viewportHeight};
      `}>
      <SinglePageLayout isError>
        <SystemErrorLayout
          icon="warning"
          description={
            <span
              css={css`
                white-space: pre-line;
              `}>
              {t('component_update_required_description')}
              {isAndroid && (
                <>
                  <br />
                  {t('component_update_required_description_for_android')}
                </>
              )}
            </span>
          }
          button={
            <Button
              css={css`
                width: 200px;
              `}
              variant="box-line"
              onClick={onUpdateButtonClick}>
              {t('component_update_required_button')}
            </Button>
          }
        />
      </SinglePageLayout>
    </div>
  );
};

export default MobileAppUpdateGuard;
export type {MobileAppUpdateGuardProps};
MobileAppUpdateGuard.displayName = 'MobileAppUpdateGuard';
