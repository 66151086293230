import React from 'react';
import {css} from '@emotion/react';

import {ElementInteractionState, QuestionState} from '@santa-web/gen/open-api/content-learning-service';
import {Question} from '@santa-web/gen/open-api/content/models/Question';
import {SingleQuestionTitle} from '@santa-web/service-ui';
import {ScrollAreaBottomViewProps} from '@app/components/ContentView/types';
import QuestionView from '@app/test-preparation/components/QuestionView';
import SingleColumnLayout from '@app/test-preparation/components/SingleColumnLayout';
import {IsUserHighlightEnabledContext} from '@app/test-preparation/contexts/IsUserHighlightEnabledContext';
import {SnippetViewBaseIdContext} from '@app/test-preparation/contexts/SnippetViewBaseIdContext';

interface VocaQuestionViewProps extends Partial<ScrollAreaBottomViewProps> {
  isDisabled?: boolean;
  isExplanationVisible?: boolean;
  question?: Question;
  questionElementState?: ElementInteractionState;
  questionTitle: string;
  onQuestionStateChange?(questionState: QuestionState): Promise<void>;
  canSelectUnsolvableQuestion: boolean;
  isUserHighlightEnabled: boolean;
  isCompleted: boolean;
  cisId: number;
  contentId: string;
}

const VocaQuestionView = ({
  isDisabled = false,
  isExplanationVisible = true,
  question,
  questionElementState,
  questionTitle,
  scrollAreaBottomRef,
  scrollAreaMarginBottom,
  onQuestionStateChange,
  canSelectUnsolvableQuestion,
  isUserHighlightEnabled,
  isCompleted,
  cisId,
  contentId,
}: VocaQuestionViewProps): JSX.Element => {
  return (
    <SnippetViewBaseIdContext.Provider value={cisId.toString()}>
      <IsUserHighlightEnabledContext.Provider value={isUserHighlightEnabled}>
        <SingleColumnLayout>
          <div
            css={css`
              flex: 1;
              margin-bottom: ${scrollAreaMarginBottom}px;
            `}>
            <QuestionView
              isCompleted={isCompleted}
              isDisabled={isDisabled}
              isExplanationInvisible={!isExplanationVisible}
              questionTitle={<SingleQuestionTitle content={questionTitle} />}
              question={question}
              questionState={questionElementState?.details.question}
              elementInteractionStateId={questionElementState?.id}
              onChange={onQuestionStateChange}
              canSelectUnsolvableQuestion={canSelectUnsolvableQuestion}
              contentId={contentId}
            />
          </div>
          <div
            ref={scrollAreaBottomRef}
            css={css`
              height: 1px;
            `}
          />
        </SingleColumnLayout>
      </IsUserHighlightEnabledContext.Provider>
    </SnippetViewBaseIdContext.Provider>
  );
};

export default React.memo(VocaQuestionView);
