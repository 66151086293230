import React, {useCallback, useContext, useMemo, useRef, useState} from 'react';

export interface IDialogContext {
  dialog?: React.ReactElement;
  openDialog(dialog: React.ReactElement): {closed: Promise<void>};
  closeDialog(): void;
  addClearListener(listener: () => void): void;
  removeClearListener(listener: () => void): void;
  dequeue?: () => void;
}

const warnUnprovided = () => {
  throw new Error('DialogContext is not provided.');
};

export const DialogContext = React.createContext<IDialogContext>({
  openDialog: warnUnprovided,
  closeDialog: warnUnprovided,
  addClearListener: warnUnprovided,
  removeClearListener: warnUnprovided,
  dequeue: warnUnprovided,
});

export const useDialogContextProvider = (): IDialogContext => {
  const dialogContextContainerRef = useRef<HTMLDivElement>(null);
  const resolveClosedRef = useRef<() => void>();
  const dialogQueue = useRef<Array<React.ReactElement | undefined>>([]).current;
  const currentDialog = dialogQueue[0];
  const clonedWithDataAttribute = useMemo(
    () => (currentDialog ? <div ref={dialogContextContainerRef}>{currentDialog}</div> : undefined),
    [currentDialog]
  );

  const [, forceRender] = useState([]);
  const [clearListeners, setClearListeners] = useState<Array<() => void>>([]);

  const openDialog = useCallback<IDialogContext['openDialog']>(
    dialog => {
      resolveClosedRef.current?.();

      dialogQueue.push(dialog);
      forceRender([]);

      const closed = new Promise<void>(res => {
        resolveClosedRef.current = () => {
          res();
          resolveClosedRef.current = undefined;
        };
      });
      return {closed};
    },
    [dialogQueue]
  );

  const dequeue = useCallback(() => {
    resolveClosedRef.current?.();

    if (dialogQueue.length > 0) {
      dialogQueue.shift();

      if (dialogQueue.length === 0) {
        clearListeners.forEach(handler => handler());
      }

      forceRender([]);
    }
  }, [clearListeners, dialogQueue]);

  const closeDialog = useCallback<IDialogContext['closeDialog']>(() => {
    if (!dialogContextContainerRef.current) return dequeue();

    const container = dialogContextContainerRef.current;

    const closeAnimationElement: Element | null = container.querySelector('[data-has-close-animation]') ?? null;

    if (closeAnimationElement === null) return dequeue();

    // data-animation-close를 set해도 애니메이션이 불리지 않는 경우가 있을 수 있어서, animationend가 발생하지 않으면 그냥 종료함. (최대 200ms 대기)
    const animationTimeout = setTimeout(() => {
      dequeue();
      clearTimeout(animationTimeout);
    }, 200);

    const onCloseAnimationEnd = () => {
      dequeue();
      container.removeEventListener('animationend', onCloseAnimationEnd);
      clearTimeout(animationTimeout);
    };

    container.addEventListener('animationend', onCloseAnimationEnd);

    closeAnimationElement.setAttribute('data-animation-close', 'true');
  }, [dequeue]);

  const addClearListener = useCallback<IDialogContext['addClearListener']>(listener => {
    setClearListeners(listeners => [...listeners, listener]);
  }, []);

  const removeClearListener = useCallback<IDialogContext['removeClearListener']>(listener => {
    setClearListeners(listeners => listeners.filter(_listener => _listener !== listener));
  }, []);

  return useMemo(
    () => ({
      dialog: clonedWithDataAttribute,
      openDialog,
      closeDialog,
      addClearListener,
      removeClearListener,
      dequeue,
    }),
    [clonedWithDataAttribute, openDialog, closeDialog, addClearListener, removeClearListener, dequeue]
  );
};

export const useDialogContext = (): IDialogContext => {
  return useContext(DialogContext);
};
