import React, {useCallback, useEffect} from 'react';

import {browserService} from '@app/api/app-bridge/browser-service';
import useLevelTestEvents from '@app/api/google-tag-manager/level-test';
import useDiagnosisLearningCellQuery from '@app/features/diagnosis/hooks/useDiagnosisLearningCellQuery';
import useDiagnosisReportQuery from '@app/features/diagnosis/hooks/useDiagnosisReportQuery';
import useIsConfettiVisible from '@app/features/diagnosis/hooks/useIsConfettiVisible';
import {DiagnosisReportTab, DiagnosisReportPageView} from '@app/features/diagnosis/views';
import {useIsNewUserFlashDealAvailable} from '@app/features/marketing';
import useUser from '@app/hooks/user/useUser';
import {useTypedRouter, useTypedSearchParams} from '@app/hooks/useTypedRouter';
import {useGoToB2bOfferGroupOrderPage, isB2bState, useQueryState} from '@app/misc/query-state';
import {useObservingTimeContext} from '@app/test-preparation/contexts/ObservingTimeContext';

const DiagnosisReportPageContainer = () => {
  const router = useTypedRouter();
  const {isCompletingCycle, referrer} = useTypedSearchParams('/diagnosis-report');
  const {data: diagnosisReport, isInitialLoading: isLoadingDiagnosisReport} = useDiagnosisReportQuery();
  const {data, isInitialLoading: isLoadingDiagnosis} = useDiagnosisLearningCellQuery();
  const isNewUserFlashDealAvailable = useIsNewUserFlashDealAvailable();
  const learningCellId = data?.learningCellId;
  const goToB2bOfferGroupOrderPage = useGoToB2bOfferGroupOrderPage();

  const {
    pushRecommendedResultFinishLevelTestClickEvent,
    pushRecommendedResultHowAiWorksClickEvent,
    pushRecommendedResultTabClickEvent,
  } = useLevelTestEvents();

  const {data: user, isInitialLoading: isLoadingUser} = useUser();
  const queryState = useQueryState();

  const isConfettiVisible = useIsConfettiVisible(isCompletingCycle);

  const observingTimeContext = useObservingTimeContext();

  const handleTabChange = (tab: DiagnosisReportTab) => {
    pushRecommendedResultTabClickEvent({tab: tab === 'analytics' ? 'overall' : 'solutions'});
  };

  const handleNavBarClick = useCallback(async () => {
    await observingTimeContext?.sendComponentElapsedTime();
    if (isCompletingCycle) {
      if (isB2bState(queryState)) {
        await goToB2bOfferGroupOrderPage(queryState);
      } else if (isNewUserFlashDealAvailable) {
        router.push({pathname: '/event/flash-deal/new-user', query: {referrer: 'diagnosis_report'}});
      } else {
        await router.push('/');
      }
    } else {
      await router.back();
    }
  }, [
    observingTimeContext,
    isCompletingCycle,
    queryState,
    isNewUserFlashDealAvailable,
    goToB2bOfferGroupOrderPage,
    router,
  ]);

  const handleRecommendedResultFinishLevelTestClick = useCallback(async () => {
    await pushRecommendedResultFinishLevelTestClickEvent(referrer);
    await handleNavBarClick();
  }, [handleNavBarClick, pushRecommendedResultFinishLevelTestClickEvent, referrer]);

  const handlePredictionScoreDescriptionButtonClick = useCallback(async () => {
    await observingTimeContext?.sendComponentElapsedTime();
    if (isCompletingCycle) await pushRecommendedResultHowAiWorksClickEvent();
    await router.push('/ai/prediction-score');
  }, [isCompletingCycle, observingTimeContext, pushRecommendedResultHowAiWorksClickEvent, router]);

  useEffect(() => {
    const unsubscribe = browserService.subscribeBackButtonPress(handleNavBarClick);
    return unsubscribe;
  }, [handleNavBarClick]);

  if (!isLoadingDiagnosisReport && diagnosisReport == null) {
    throw new Error('Report and learning-cell cannot be null');
  }

  const isLoading =
    isLoadingDiagnosisReport || isLoadingUser || isLoadingDiagnosis || isNewUserFlashDealAvailable === undefined;

  return learningCellId == null || diagnosisReport == null ? null : (
    <DiagnosisReportPageView
      isLoading={isLoading}
      diagnosisReport={diagnosisReport!}
      isCompletingCycle={isCompletingCycle}
      isConfettiVisible={isConfettiVisible}
      userName={user?.personalInfo?.name}
      onNavBarClick={handleNavBarClick}
      onRecommendedResultFinishLevelTestClick={handleRecommendedResultFinishLevelTestClick}
      onPredictionScoreDescriptionButtonClick={handlePredictionScoreDescriptionButtonClick}
      onTabChange={handleTabChange}
      cellId={learningCellId}
    />
  );
};

export default DiagnosisReportPageContainer;
