import TestPrepLayout, {TestPrepLayoutProps} from '@app/test-preparation/components/TestPrepLayout';
import {VOCA_QUESTION_VIEW_MAX_WIDTH} from '@app/test-preparation/constants/test-preparation-layout';

export type VocaQuestionViewLayoutProps = TestPrepLayoutProps;

const VocaQuestionViewLayout = ({children, ...rest}: VocaQuestionViewLayoutProps) => {
  return (
    <TestPrepLayout maxWidth={VOCA_QUESTION_VIEW_MAX_WIDTH} {...rest}>
      {children}
    </TestPrepLayout>
  );
};

export default VocaQuestionViewLayout;
