import { CurrencyFromJSON, CurrencyToJSON, } from './Currency';
import { PriceAmountFromJSON, PriceAmountToJSON, } from './PriceAmount';
/**
 * Check if a given object implements the PriceConfig interface.
 */
export function instanceOfPriceConfig(value) {
    let isInstance = true;
    isInstance = isInstance && "currency" in value;
    isInstance = isInstance && "discountRate" in value;
    isInstance = isInstance && "priceAmount" in value;
    return isInstance;
}
export function PriceConfigFromJSON(json) {
    return PriceConfigFromJSONTyped(json, false);
}
export function PriceConfigFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'currency': CurrencyFromJSON(json['currency']),
        'discountRate': json['discountRate'],
        'priceAmount': PriceAmountFromJSON(json['priceAmount']),
    };
}
export function PriceConfigToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'currency': CurrencyToJSON(value.currency),
        'discountRate': value.discountRate,
        'priceAmount': PriceAmountToJSON(value.priceAmount),
    };
}
