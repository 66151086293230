import {QuestionStateSubjective} from '@santa-web/gen/open-api/content-learning-service';
import {Subjective} from '@santa-web/gen/ssp/messages/inside/(Question)';

export const getIsCorrect = (
  questionStateSubjective: QuestionStateSubjective | null | undefined,
  subjective: Subjective | null | undefined
): boolean => {
  const userAnswer = questionStateSubjective?.userAnswer ?? '';
  const answers = subjective?.allowedAnswers ?? [];
  return answers.includes(userAnswer);
};

export const getIsSelected = (subjective: QuestionStateSubjective | null | undefined): boolean => {
  return (subjective?.userAnswer?.length ?? 0) > 0;
};
