/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { ListVocabularyLearningHistoriesResponseFromJSON, } from '../models';
/**
 *
 */
export class VocabularyLearningHistoryV1Api extends runtime.BaseAPI {
    /**
     * 주어진 필터 조건을 바탕으로 최근에 학습한 어휘 `ContentInteractionState`를 조회한다. 정렬 순서는 `lastStudiedAt`의 내림차순이다. (Also see: [[DX] 복습 - 단어장](https://www.notion.so/riiid/DX-4d766876eb984cf38aa8dfb9fa696774?pvs=4#5913f78303144ad1803cd89e036d954b))
     * 최근에 학습한 어휘 콘텐츠를 조회한다.
     */
    async listVocabularyLearningHistoriesRaw(requestParameters, initOverrides) {
        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size', 'Required parameter requestParameters.size was null or undefined when calling listVocabularyLearningHistories.');
        }
        const queryParameters = {};
        if (requestParameters.cursor !== undefined) {
            queryParameters['cursor'] = requestParameters.cursor;
        }
        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }
        if (requestParameters.bookmarkedOnly !== undefined) {
            queryParameters['bookmarkedOnly'] = requestParameters.bookmarkedOnly;
        }
        if (requestParameters.markedAsKnownCondition !== undefined) {
            queryParameters['markedAsKnownCondition'] = requestParameters.markedAsKnownCondition;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/api/content-learning/v1/vocabulary-learning-histories/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListVocabularyLearningHistoriesResponseFromJSON(jsonValue));
    }
    /**
     * 주어진 필터 조건을 바탕으로 최근에 학습한 어휘 `ContentInteractionState`를 조회한다. 정렬 순서는 `lastStudiedAt`의 내림차순이다. (Also see: [[DX] 복습 - 단어장](https://www.notion.so/riiid/DX-4d766876eb984cf38aa8dfb9fa696774?pvs=4#5913f78303144ad1803cd89e036d954b))
     * 최근에 학습한 어휘 콘텐츠를 조회한다.
     */
    async listVocabularyLearningHistories(requestParameters, initOverrides) {
        const response = await this.listVocabularyLearningHistoriesRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
