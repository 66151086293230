import {GTELP_SUBJECTS} from './common';

export const TOEIC_RECOMMEND_MS_PART_5 = 22 * 1000;
export const TOEIC_RECOMMEND_MS_PART_6 = 30 * 1000;
export const TOEIC_RECOMMEND_MS_PART_7 = 60 * 1000;
export const GTELP_RECOMMEND_MS_GRAMMAR = 40 * 1000;
export const GTELP_RECOMMEND_MS_RC_VOCA = 85 * 1000;

export const toeicRecommendMsSet: Record<string, number> = {
  p5: TOEIC_RECOMMEND_MS_PART_5,
  p6: TOEIC_RECOMMEND_MS_PART_6,
  p7: TOEIC_RECOMMEND_MS_PART_7,
};

export const gtelpRecommendMsSet: Record<string, number> = {
  [GTELP_SUBJECTS.gr]: GTELP_RECOMMEND_MS_GRAMMAR,
  [GTELP_SUBJECTS.rc_voca]: GTELP_RECOMMEND_MS_RC_VOCA,
};
