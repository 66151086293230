import React from 'react';
import mergeRefs from 'react-merge-refs';
import {css} from '@emotion/react';
import LoadingGrayLogoAnimation from '@app/components/LoadingGrayLogoAnimation';
import Row from '@app/components/Row';
import {
  usePullToRefresh,
  LOADING_INDICATOR_MARGIN,
  TRANSITION_DURATION_MS,
  LOADING_ICON_SIZE,
} from '@app/hooks/usePullToRefresh';

export interface ScrollLayoutProps extends React.ComponentPropsWithoutRef<'div'> {
  floatingButtonsRef?: React.RefObject<HTMLDivElement>;
  isPullToRefreshDisabled?: boolean;
}

const ScrollLayout = React.forwardRef<HTMLDivElement, ScrollLayoutProps>(
  ({floatingButtonsRef, isPullToRefreshDisabled = false, children, ...props}, ref) => {
    const {isRefreshing, scrollAreaRef} = usePullToRefresh(isPullToRefreshDisabled);
    return (
      <div
        css={css`
          position: relative;
          width: 100%;
          height: 100%;
          overflow: hidden;
        `}>
        {!isPullToRefreshDisabled && (
          <Row
            css={[
              css`
                position: absolute;
                top: ${LOADING_INDICATOR_MARGIN}px;
                left: 50%;
                transform: translateX(-50%);
                opacity: 0;
                user-select: none;
                transition: opacity ${TRANSITION_DURATION_MS}ms linear;
              `,
              isRefreshing &&
                css`
                  opacity: 1;
                `,
            ]}
            alignItems="center"
            justifyContent="center">
            <LoadingGrayLogoAnimation width={LOADING_ICON_SIZE} height={LOADING_ICON_SIZE} />
          </Row>
        )}
        <div
          ref={mergeRefs([ref, scrollAreaRef])}
          css={css`
            height: 100%;

            overflow-y: scroll;
            /* iOS15에서 scrolling이 부드럽지 못한 이슈로 인해 추가 됨 */
            -webkit-overflow-scrolling: touch;
            // iOS scroll bounce를 막기 위함
            overscroll-behavior: none;

            ::-webkit-scrollbar {
              display: none;
            }
            -ms-overflow-style: none;
            scrollbar-width: none;

            transition: transform ${TRANSITION_DURATION_MS}ms linear;
          `}
          {...props}>
          {children}
        </div>
      </div>
    );
  }
);

export default React.memo(ScrollLayout);
