import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {COLOR_SANTA_I} from '@riiid/design-system';

import {Typography} from '@santa-web/service-ui';
import MyNoteCard, {MyNoteCardProps} from '@app/components/card/MyNoteCard';

export interface ContentCardVocaProps extends Omit<MyNoteCardProps, 'title'> {
  word?: string;
  meaning?: string;
  knownVoca?: boolean;
}

const ContentCardVoca = React.forwardRef<HTMLDivElement, ContentCardVocaProps>(
  ({word, meaning, isBookmarked, onBookmarkClick, knownVoca, ...props}, ref) => {
    const {t} = useTranslation();

    return (
      <MyNoteCard
        ref={ref}
        title={word}
        subTitle={meaning}
        customContentCss={css`
          margin-top: 4px;
        `}
        status={
          <Typography color={COLOR_SANTA_I} variant="caption1" fontWeight="bold" display="block">
            {knownVoca
              ? t('learning_cell_report_memorized_vocabulary')
              : t('learning_cell_report_unmemorized_vocabulary')}
          </Typography>
        }
        isBookmarked={isBookmarked}
        onBookmarkClick={onBookmarkClick}
        {...props}
      />
    );
  }
);

export default ContentCardVoca;
