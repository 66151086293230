import React from 'react';
import {Form} from 'react-final-form';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {COLOR_SANTA_I} from '@riiid/design-system';
import {useAtomValue} from 'jotai';

import isEmail from 'validator/lib/isEmail';
import {Typography} from '@santa-web/service-ui';
import santaAuthorizedOpenapiServicesAtom from '@app/atoms/core/santa-authorized-openapi-services';
import {SingleColumn} from '@app/components';
import TextField from '@app/components/final-form/TextField';
import FullWidthSolidButton from '@app/components/FullWidthSolidButton';
import LoadableButton from '@app/components/LoadableButton';
import {useDialogContext} from '@app/contexts/DialogContext';
import {ResetPasswordMailSentDialog, ResetPasswordMailFailedDialog} from '@app/features/auth/components';
import {useCommonValidator} from '@app/features/auth/hooks';
import {OnboardingLayout} from '@app/features/onboarding';
import {useTypedRouter} from '@app/hooks/useTypedRouter';
import {OnSubmit} from '@app/types/form';
import {SantaResponseError} from '@app/utils/error';

interface FormValues {
  email?: string;
}

const initialValues = {
  email: '',
};

const ForgotPasswordPageContainer = () => {
  const {push} = useTypedRouter();
  const {AuthService} = useAtomValue(santaAuthorizedOpenapiServicesAtom);
  const {openDialog, closeDialog} = useDialogContext();
  const commonValidator = useCommonValidator();
  const {t} = useTranslation();

  const handleSubmit: OnSubmit<FormValues> = React.useCallback(
    async values => {
      try {
        await AuthService.requestResetPasswordEmail({
          email: values.email ?? '',
        });

        openDialog(
          <ResetPasswordMailSentDialog
            onConfirm={() => {
              push('/login/email');
              closeDialog();
            }}
          />
        );
      } catch (e) {
        if (e instanceof SantaResponseError && e.response.status === 400) {
          return {email: t('forgot_password_email_not_exists_alert')};
        }

        openDialog(<ResetPasswordMailFailedDialog onConfirm={closeDialog} />);
      }
    },
    [AuthService, t, closeDialog, openDialog, push]
  );

  return (
    <Form<FormValues>
      onSubmit={handleSubmit}
      initialValues={initialValues}
      validate={({email}) => {
        if (email && !isEmail(email)) {
          return {email: t('sign_error_email_invalid_format')};
        }
      }}>
      {({handleSubmit, hasValidationErrors, submitting, errors}) => {
        const isLoading = submitting;
        const isDisabled = hasValidationErrors || isLoading;

        return (
          <form
            onSubmit={e => {
              e.preventDefault();
              handleSubmit(e);
            }}
            css={css`
              display: flex;
              flex-direction: column;
            `}>
            <OnboardingLayout
              isNarrow
              bottomArea={
                <LoadableButton
                  as={FullWidthSolidButton}
                  isLoading={isLoading}
                  isDisabled={isDisabled}
                  colorVariant="brand"
                  variant="bottom-fix"
                  loadingAnimationVariant="gray">
                  {t('page_forgot_password_send_email_button')}
                </LoadableButton>
              }>
              <SingleColumn gap={40}>
                <Typography variant="caption1" fontWeight="bold" color={COLOR_SANTA_I}>
                  {t('page_forgot_password_sub_title')}
                </Typography>
                <TextField
                  type="text"
                  name="email"
                  validate={commonValidator.required}
                  label={t('page_forgot_password_email_label')}
                  hint={t('page_forgot_password_email_hint')}
                  error={errors['email']}
                />
              </SingleColumn>
            </OnboardingLayout>
          </form>
        );
      }}
    </Form>
  );
};

export default ForgotPasswordPageContainer;
