import React from 'react';

import {
  RIIID_RICH_TEXT_V2_VIEW_ID,
  TEXT_VIEW_NODE_ID,
  ITextNodePathsContext,
  RiiidRichTextViewV2Selection,
  SelectionChangeEvent,
} from '@santa-web/service-ui';

const useSelectionChangeListener = (
  textNodePaths: ITextNodePathsContext,
  onSelectionChange?: (event: SelectionChangeEvent) => void
) => {
  React.useEffect(() => {
    const handler = () => {
      if (!onSelectionChange) return;
      const selection = document.getSelection();
      const selectionGroupId = textNodePaths.getRiiidRichTextV2ViewId();
      const selectionToRrtv2Selection = (selection: Selection): RiiidRichTextViewV2Selection => {
        const anchorParentElement = selection.anchorNode?.parentElement?.parentElement;
        const focusParentElement = selection.focusNode?.parentElement?.parentElement;

        const anchorNodeId = anchorParentElement?.getAttribute(TEXT_VIEW_NODE_ID);
        const focusNodeId = focusParentElement?.getAttribute(TEXT_VIEW_NODE_ID);

        const anchorSelectionGroupId = anchorParentElement?.getAttribute(RIIID_RICH_TEXT_V2_VIEW_ID);
        const focusSelectionGroupId = focusParentElement?.getAttribute(RIIID_RICH_TEXT_V2_VIEW_ID);

        if (anchorSelectionGroupId !== selectionGroupId) {
          return {
            anchor: null,
            focus: null,
          };
        }

        const anchorPath = anchorNodeId ? textNodePaths.getPath(anchorNodeId) : null;
        const focusPath = focusNodeId ? textNodePaths.getPath(focusNodeId) : null;

        const selectionGroupSelector = `[${RIIID_RICH_TEXT_V2_VIEW_ID}="${selectionGroupId}"]`;
        const nodeIdSelector = (nodeId: string) => `[${TEXT_VIEW_NODE_ID}="${nodeId}"]`;
        const selectionAnchorElements = document.querySelectorAll(
          `${selectionGroupSelector}${nodeIdSelector(anchorNodeId ?? '')}`
        );
        const selectionFocusElements = document.querySelectorAll(
          `${selectionGroupSelector}${nodeIdSelector(focusNodeId ?? '')}`
        );

        const {anchorOffset, focusOffset} = [selectionAnchorElements, selectionFocusElements].reduce(
          (offset, selectionElements) => {
            selectionElements.forEach(element => {
              if (element === anchorParentElement) {
                offset.anchorOffset = offset.totalOffset + selection.anchorOffset;
              }
              if (element === focusParentElement) {
                offset.focusOffset = offset.totalOffset + selection.focusOffset;
              }

              offset.totalOffset = offset.totalOffset + (element.textContent?.length ?? 0);
            });

            return {...offset, totalOffset: 0};
          },
          {anchorOffset: 0, focusOffset: 0, totalOffset: 0}
        );

        return {
          anchor: anchorPath ? {path: anchorPath, offset: anchorOffset} : null,
          focus:
            anchorSelectionGroupId &&
            focusSelectionGroupId &&
            anchorSelectionGroupId === focusSelectionGroupId &&
            focusPath
              ? {path: focusPath, offset: focusOffset}
              : null,
        };
      };
      const rrtv2Selection = selection ? selectionToRrtv2Selection(selection) : null;
      onSelectionChange({
        selectionGroupId,
        selection,
        rrtv2Selection,
      });
    };

    document.addEventListener('selectionchange', handler, false);
    return () => {
      document.removeEventListener('selectionchange', handler);
    };
  }, [onSelectionChange, textNodePaths]);
};

export default useSelectionChangeListener;
