import React from 'react';
import {useClickAway, useKey} from 'react-use';
import {css} from '@emotion/react';
import {BREAKPOINT_MEDIUM} from '@riiid/design-system';

import {BottomSheetList, BottomSheetListProps, Dim, DimProps} from '@santa-web/service-ui';
import {BOTTOM_SHEET_Z_INDEX} from '@app/constants/zIndex';

export interface BottomSheetListWithDimProps extends Omit<DimProps, 'title'> {
  isOpened?: boolean;
  BottomSheetListProps: Omit<BottomSheetListProps, 'isOpened'>;
}

const BottomSheetListWithDim = React.forwardRef<HTMLDivElement, BottomSheetListWithDimProps>(
  ({isOpened, BottomSheetListProps, ...props}, ref) => {
    const {onCloseClick} = BottomSheetListProps;

    const bottomSheetListRef = React.useRef(null);
    useClickAway(bottomSheetListRef, onCloseClick, ['mousedown']);
    useKey('Escape', onCloseClick);

    return isOpened ? (
      <Dim
        css={css`
          position: fixed;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          display: flex;
          flex-direction: column-reverse;
          box-sizing: border-box;
          z-index: ${BOTTOM_SHEET_Z_INDEX};
          @media (min-width: ${BREAKPOINT_MEDIUM}) {
            align-items: center;
            justify-content: center;
          }
        `}
        data-testid="BottomSheetListWithDim-Dim"
        ref={ref}
        {...props}>
        <BottomSheetList ref={bottomSheetListRef} isOpened={isOpened} {...BottomSheetListProps} />
      </Dim>
    ) : null;
  }
);

export default React.memo(BottomSheetListWithDim);
