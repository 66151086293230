import {MobileHandlerName} from '@santa-web/gen/ssp/messages/bridge/mobile';
import {WebviewHandlerName as BrowserHandlerName} from '@santa-web/gen/ssp/messages/bridge/webview';
import config from '@app/config';
import {
  CallbackType,
  DeserializableString,
  IAppBridgeRepository,
  Serializable,
  Unsubscribe,
} from './AppBridgeRepository.interface';

// flutter inappwebview 라이브러리가 제공하는 기능에 맞춘 구현체입니다.
// see: https://inappwebview.dev/docs/webview/javascript/communication

declare global {
  interface Window {
    flutter_inappwebview?: {
      callHandler?(name: string, callbackId: number, payload: Serializable): Promise<Serializable>;
    };
    app_bridge?: Record<BrowserHandlerName, (request: string) => Promise<DeserializableString>>;
  }
}

export class AppBridgeRepository implements IAppBridgeRepository<MobileHandlerName, BrowserHandlerName> {
  private callbackId = -1;
  private shouldLog = config.env !== 'production' || config.enableProdDevtools === 'true';

  get isAppBridgeAvailable() {
    try {
      this.callHandler;
      return true;
    } catch {
      return false;
    }
  }

  private get subscriptionHandler() {
    if (window.app_bridge == null) {
      window.app_bridge = {} as any;
    }
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    return window.app_bridge!;
  }

  private get callHandler() {
    const callHandler = window.flutter_inappwebview?.callHandler;
    if (!callHandler) {
      throw new AppBridgeError('app bridge is not initialized');
    }
    return callHandler;
  }

  private log(name: string, type: 'request' | 'response', payload: Serializable, callbackId?: number) {
    if (this.shouldLog) {
      const isPayloadExists = Object.keys(payload).length > 0;
      console.groupCollapsed(`[${name}] ${callbackId != null ? `(${callbackId})` : ''} ${type}`);
      console.table([{name, type, callbackId, payload: isPayloadExists ? 'exists' : 'empty'}]);
      if (isPayloadExists) {
        console.dir(payload);
      }
      console.groupEnd();
    }
  }

  async requestToMobileApp<Payload extends Serializable, Response extends Serializable>(
    name: MobileHandlerName,
    requestPayload: Payload
  ): Promise<Response> {
    this.callbackId += 1;
    this.log(name, 'request', requestPayload, this.callbackId);
    const responsePayload = await this.callHandler(name, this.callbackId, requestPayload);
    this.log(name, 'response', responsePayload, this.callbackId);
    return responsePayload as Response;
  }

  subscribeToRequest<Request extends Serializable, Response extends Serializable>(
    name: BrowserHandlerName,
    callback: CallbackType<Request, Response>
  ): Unsubscribe {
    this.subscriptionHandler[name] = async (requestString: string) => {
      const requestPayload = JSON.parse(requestString) as Request;
      this.log(name, 'request', requestPayload);
      const responsePayload = await callback(requestPayload);
      this.log(name, 'response', responsePayload);
      return JSON.stringify(responsePayload);
    };
    return () => {
      delete this.subscriptionHandler[name];
    };
  }
}

export class AppBridgeError extends Error {
  constructor(message: string) {
    super(message);
    this.name = 'AppBridgeError';
  }
}

export const appBridgeRepository = new AppBridgeRepository();
