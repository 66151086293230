import React, {useMemo} from 'react';

import {Explanation} from '@santa-web/gen/open-api/content/models/Explanation';
import SnippetListView from '@app/test-preparation/components/SnippetListView';

export interface ExplanationListView extends React.ComponentPropsWithoutRef<'div'> {
  explanations?: Explanation[] | null;
}

const ExplanationListView = React.forwardRef<HTMLDivElement, ExplanationListView>(({explanations, ...props}, ref) => {
  const snippets = useMemo(() => explanations?.map(explanation => explanation?.body ?? {}), [explanations]);
  return <SnippetListView ref={ref} snippets={snippets} id="explanation" {...props} />;
});

export default React.memo(ExplanationListView);
