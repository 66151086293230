import React from "react";
import * as uuid from "uuid";
export var createTextNodePaths = function() {
    var textNodeIdToPath = {};
    var textNodeId = 1;
    var riiidRichtextV2ViewId = uuid.v4();
    return {
        getPath: function getPath(textNodeId) {
            var _textNodeIdToPath_textNodeId;
            return (_textNodeIdToPath_textNodeId = textNodeIdToPath[textNodeId]) !== null && _textNodeIdToPath_textNodeId !== void 0 ? _textNodeIdToPath_textNodeId : null;
        },
        setPath: function setPath(textNodeId, path) {
            textNodeIdToPath[textNodeId] = path;
        },
        getNewTextNodeId: function getNewTextNodeId() {
            return (textNodeId++).toString();
        },
        getRiiidRichTextV2ViewId: function getRiiidRichTextV2ViewId() {
            return riiidRichtextV2ViewId;
        }
    };
};
export var useInitialTextNodePaths = function() {
    return React.useMemo(function() {
        return createTextNodePaths();
    }, []);
};
export var TextNodePathsContext = React.createContext(null);
export var useTextNodePathsContext = function() {
    var textNodePathsContext = React.useContext(TextNodePathsContext);
    return textNodePathsContext;
};
