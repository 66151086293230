const ONE_MINUTE_TO_SECONDS = 60;
const ONE_HOUR_TO_MINUTES = 60;
const ONE_DAY_TO_HOURS = 24;
const ONE_HOUR_TO_SECONDS = ONE_HOUR_TO_MINUTES * ONE_MINUTE_TO_SECONDS;

/*
  't' 가 suffix로 붙은 것은 상위 시간 단위를 제외하지 않은 total을 의미한다.
  예를 들어 3672초의 경우 61분 12초이지만, 시간까지 치면 1시간 1분 12초이기도 하다. 여기서 포매팅 키 'm'의 경우 상위 단위(시간)을 제외하여 1로 변환되지만, 'mt' 의 경우 상위 단위(시간)을 제외하지 않고 61로 변환된다.
 */
export type FormattingTableKeys = 's' | 'm' | 'h' | 'mt' | 'ht' | 'dt' | 'ss' | 'mm' | 'hh' | 'mmt' | 'hht';

/**
 * single quote로 감싼 부분은 포매팅에서 제외한다.
 */
export function formatSeconds(seconds: number, formatter: string) {
  const minutes = Math.floor(seconds / ONE_MINUTE_TO_SECONDS);
  const hours = Math.floor(minutes / ONE_HOUR_TO_MINUTES);
  const days = Math.floor(hours / ONE_DAY_TO_HOURS);

  const s = (seconds % ONE_MINUTE_TO_SECONDS).toString();
  const m = (minutes % ONE_HOUR_TO_MINUTES).toString();
  const h = (hours % ONE_DAY_TO_HOURS).toString();
  const mt = minutes.toString();
  const ht = hours.toString();
  const dt = days.toString();

  const formattingTable: Record<FormattingTableKeys, string> = {
    s,
    m,
    h,
    mt,
    ht,
    dt,
    ss: s.padStart(2, '0'),
    mm: m.padStart(2, '0'),
    hh: h.padStart(2, '0'),
    mmt: mt.padStart(2, '0'),
    hht: ht.padStart(2, '0'),
  };
  const replacingKeys = (Object.keys(formattingTable) as FormattingTableKeys[]).sort((a, b) => b.length - a.length);
  const replace = (formatter: string) =>
    replacingKeys.reduce(
      (result, replacingKey) => result.replace(new RegExp(replacingKey, 'g'), formattingTable[replacingKey]),
      formatter
    );
  const splittedFormatter = formatter.split("'");
  if (splittedFormatter.length === 1) {
    return replace(formatter);
  } else if (splittedFormatter.length % 2 === 0) {
    throw new Error('Invalid number of escaping single quotes');
  }
  return splittedFormatter.reduce((acc, curr, index) => {
    if (index % 2 !== 0) {
      return acc + curr;
    } else {
      return acc + replace(curr);
    }
  }, '');
}

export function formatChartSeconds(seconds: number) {
  if (seconds < ONE_HOUR_TO_SECONDS) {
    return formatSeconds(seconds, 'mm:ss');
  }
  return formatSeconds(seconds, 'hht:mm:ss');
}

// Rule: https://en.wikipedia.org/wiki/Ordinal_indicator#English
export function getOrdinalNumber(n: number) {
  const lastDigit = n % 10;
  const secondToLastDigit = Math.floor(n / 10) % 10;

  if (secondToLastDigit === 1) return `${n}th`;
  if (lastDigit === 1) return `${n}st`;
  if (lastDigit === 2) return `${n}nd`;
  if (lastDigit === 3) return `${n}rd`;
  return `${n}th`;
}

export function convertCamelToSnakeCase(str: string): string {
  return str.replace(/[A-Z]/g, letter => `_${letter.toLowerCase()}`);
}

export function convertMacroToSnakeCase(str: string): string {
  return str.replace(/[A-Z]+/g, letter => `${letter.toLowerCase()}`);
}

export function convertIndexesToString(indexes: number[]): string {
  if (indexes.length === 0) return '';
  if (indexes.length === 1) return (indexes[0] + 1).toString();
  return `${indexes[0] + 1}~${indexes[indexes.length - 1] + 1}`;
}

export function extractIntegerFromText(text: string): number {
  const filteredText = text.replace(/\D/g, '');
  return parseInt(filteredText);
}
