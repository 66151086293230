import React from 'react';
import {css} from '@emotion/react';
import {
  COLOR_SANTA_CARD_C,
  COLOR_SANTA_CARD_D,
  COLOR_SANTA_I,
  COLOR_SANTA_L,
  SHADOW_A_2_DOWN,
} from '@riiid/design-system';

import {LearningCycleCellType} from '@santa-web/gen/open-api/content-learning-service';
import {Typography, Label} from '@santa-web/service-ui';
import BaseCard, {CardProps} from '@app/components/card/BaseCard';
import StudyCellIcon, {StudyCellIconOwnProps} from '@app/components/card/StudyCellCard/StudyCellIcon';
import SelfProgressBar from '@app/components/SelfProgressBar';

export interface StudyCellCardProps extends CardProps, StudyCellIconOwnProps {
  type: LearningCycleCellType;
  title: string;
  description: string;
  tags?: string[];
  progress?: {completedCount: number; totalCount: number};
}

const StudyCellCard = React.forwardRef<HTMLDivElement, StudyCellCardProps>(
  ({type, title, description, tags, progress, isDone, isLocked, ...props}, ref) => {
    return (
      <BaseCard
        css={[
          css`
            padding: 24px 20px;
            cursor: pointer;

            &:not(:active):hover {
              /* shadow-A-2-DOWN */
              box-shadow: ${SHADOW_A_2_DOWN};
            }
          `,
          isDone &&
            css`
              background-color: ${COLOR_SANTA_CARD_C};
              &:active {
                background-color: ${COLOR_SANTA_CARD_D};
              }
            `,
        ]}
        ref={ref}
        {...props}>
        <div
          css={css`
            display: flex;
            align-items: center;
            justify-content: space-between;
          `}>
          <div>
            <Typography variant="caption1" fontWeight="regular" color={COLOR_SANTA_I}>
              {description}
            </Typography>
            <Typography variant="body3" fontWeight="bold" color={COLOR_SANTA_L}>
              {title}
            </Typography>
            {tags && tags.length > 0 && (
              <div
                css={css`
                  display: inline-grid;
                  grid-auto-flow: column;
                  column-gap: 4px;
                  grid-column-gap: 4px;
                  margin-top: 12px;
                `}>
                {tags.map(tag => (
                  <Label key={tag} variant="small-round" colorVariant="dark">
                    {tag}
                  </Label>
                ))}
              </div>
            )}
          </div>
          <StudyCellIcon type={type} isDone={isDone} isLocked={isLocked} />
        </div>
        {isDone ? (
          <SelfProgressBar
            data-testid="StudyCellCard-SelfProgressBarDone"
            css={css`
              padding-top: 8px;
            `}
            isDisabled={isDone}
            completedCount={1}
            totalCount={1}
          />
        ) : (
          progress && (
            <SelfProgressBar
              data-testid="StudyCellCard-SelfProgressBar"
              css={css`
                padding-top: 8px;
              `}
              completedCount={progress.completedCount}
              totalCount={progress.totalCount}
            />
          )
        )}
      </BaseCard>
    );
  }
);

export default React.memo(StudyCellCard);
