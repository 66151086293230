import {useMutation, useQueryClient} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';
import {CreateExamScoreRequest} from '@santa-web/gen/open-api/service';
import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {getExamScoresQueryKey} from '@app/queryKeys';

const usePatchExamScoreDateMutation = () => {
  const queryClient = useQueryClient();
  const {ExamScoreService} = useAtomValue(santaOpenapiServicesAtom);

  return useMutation(
    async ({
      examDate,
      newDate,
      totalScore,
      partialScores,
    }: {
      examDate: Date;
      newDate: Date;
      totalScore: number;
      partialScores: CreateExamScoreRequest['partialScores'];
    }) => {
      await ExamScoreService.deleteExamScore({examDate});
      await ExamScoreService.createExamScore({
        createExamScoreRequest: {date: newDate, totalScore, partialScores},
      });
    },
    {
      onSuccess: () => {
        queryClient.invalidateQueries(getExamScoresQueryKey());
      },
    }
  );
};

export default usePatchExamScoreDateMutation;
