/* tslint:disable */
/* eslint-disable */
/**
 * - **IN_APP_PURCHASE**: Google Play, App Store를 통한 인앱 결제
 * - **PAYMENT_GATEWAY**: IAMPORT, STRIPE 등의 PG사를 통한 결제
 * @export
 */
export const PaymentMethod = {
    IN_APP_PURCHASE: 'IN_APP_PURCHASE',
    PAYMENT_GATEWAY: 'PAYMENT_GATEWAY'
};
export function PaymentMethodFromJSON(json) {
    return PaymentMethodFromJSONTyped(json, false);
}
export function PaymentMethodFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function PaymentMethodToJSON(value) {
    return value;
}
