/* tslint:disable */
/* eslint-disable */
/**
 * - **ONBOARDING**: Onboarding pass 설정을 통해 얻은 pass.
 * - **SINGLE_PURCHASE**: 단건 구매를 통해 얻은 pass.
 * - **SUBSCRIPTION**: 구독을 통해 얻은 pass.
 * - **OTHER**: 기타 다른 이유로 얻은 pass.
 * 관리자가 지급한 경우 또는 기타 특수한 경우.
 * @export
 */
export const SourceTypeV2 = {
    ONBOARDING: 'ONBOARDING',
    SINGLE_PURCHASE: 'SINGLE_PURCHASE',
    SUBSCRIPTION: 'SUBSCRIPTION',
    OTHER: 'OTHER'
};
export function SourceTypeV2FromJSON(json) {
    return SourceTypeV2FromJSONTyped(json, false);
}
export function SourceTypeV2FromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function SourceTypeV2ToJSON(value) {
    return value;
}
