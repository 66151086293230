import React from 'react';
import {css} from '@emotion/react';
import {SkeletonGrayBox} from '@app/components';
import {ListLayout, ListLayoutProps} from '@app/features/offer/components';

const ListSkeleton = ({
  shouldHideInfoSkeleton = false,
  onGoBack,
}: Pick<ListLayoutProps, 'onGoBack'> & {shouldHideInfoSkeleton?: boolean}) => {
  return (
    <ListLayout info={!shouldHideInfoSkeleton && <SkeletonGrayBox width="100%" height={52} />} onGoBack={onGoBack}>
      <div
        css={css`
          display: grid;
          gap: 12px;
        `}>
        {Array(3).fill(<SkeletonGrayBox width="100%" height={160} />)}
      </div>
    </ListLayout>
  );
};

export default ListSkeleton;
ListSkeleton.displayName = 'ListSkeleton';
