import React from 'react';
import {css} from '@emotion/react';

export interface DirectionWrapperProps extends React.ComponentPropsWithoutRef<'div'> {
  gap?: number;
}

const DirectionWrapper = React.forwardRef<HTMLDivElement, DirectionWrapperProps>(({gap = 40, ...props}, ref) => {
  return (
    <div
      css={css`
        display: grid;
        grid-gap: ${gap}px;
        gap: ${gap}px;
      `}
      {...props}
      ref={ref}
    />
  );
});

export default DirectionWrapper;
