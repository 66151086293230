import React from 'react';
import {useAtomValue} from 'jotai';
import useOfferEvents from '@app/api/google-tag-manager/offer';
import learningDomainAtom from '@app/atoms/core/learning-domain';
import {ListSkeleton} from '@app/features/offer/components';
import {useOfferGroupsQuery} from '@app/features/offer/hooks';
import {mapOfferGroupToOfferGroupCardProps} from '@app/features/offer/utils';
import {OfferGroupBoardListView} from '@app/features/offer/views';
import {OfferGroupCardProps} from '@app/features/offer/views/OfferGroupBoardListView/Board';
import useNowBySecond from '@app/hooks/useNowBySecond';
import {useTypedRouter, useTypedSearchParams} from '@app/hooks/useTypedRouter';

const OfferGroupBoardListPageContainer = () => {
  const {pushClickOfferGroupEvent} = useOfferEvents();
  const learningDomain = useAtomValue(learningDomainAtom);

  const router = useTypedRouter();
  const searchParams = useTypedSearchParams('/offer-group/board/list');
  const {boardCode} = searchParams;

  const nowBySecond = useNowBySecond();
  const {data: offerGroups} = useOfferGroupsQuery(boardCode);

  const offerGroupCards: OfferGroupCardProps[] | undefined = React.useMemo(() => {
    return offerGroups?.map(offerGroup => mapOfferGroupToOfferGroupCardProps(offerGroup, nowBySecond));
  }, [offerGroups, nowBySecond]);

  const handleGoBack = () => {
    return router.back();
  };

  const handleGoToOfferGroupDetailPage = (offerGroupId: number) => {
    pushClickOfferGroupEvent({offerGroupId, boardCode});
    router.push({pathname: '/offer-group/board/detail', query: {...searchParams, offerGroupId: offerGroupId}});
  };

  if (!offerGroupCards) {
    return <ListSkeleton onGoBack={handleGoBack} />;
  }

  if (offerGroupCards.length === 0) {
    return <OfferGroupBoardListView.Empty onGoBack={handleGoBack} />;
  }

  return (
    <OfferGroupBoardListView.Board
      learningDomain={learningDomain.displayName}
      offerGroupCards={offerGroupCards}
      onGoBack={handleGoBack}
      onOfferGroupCardClick={handleGoToOfferGroupDetailPage}
    />
  );
};

export default OfferGroupBoardListPageContainer;
OfferGroupBoardListPageContainer.displayName = 'OfferGroupBoardListPageContainer';
