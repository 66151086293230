import {useQuery} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';
import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {getAvailablePopupQueryKey} from '@app/queryKeys';

export const useAvailablePopupQuery = () => {
  const {MarketingService} = useAtomValue(santaOpenapiServicesAtom);

  return useQuery({
    queryKey: getAvailablePopupQueryKey(),
    queryFn: async () => (await MarketingService.getAvailableMarketingPopup({})).available ?? null,
  });
};
