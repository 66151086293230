/**
 * Check if a given object implements the ListExamDatesResponse interface.
 */
export function instanceOfListExamDatesResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "examDates" in value;
    return isInstance;
}
export function ListExamDatesResponseFromJSON(json) {
    return ListExamDatesResponseFromJSONTyped(json, false);
}
export function ListExamDatesResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'examDates': json['examDates'],
    };
}
export function ListExamDatesResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'examDates': value.examDates,
    };
}
