import { LearningHistoryFilterItemBySelfLearningMockExamFromJSON, LearningHistoryFilterItemBySelfLearningMockExamToJSON, } from './LearningHistoryFilterItemBySelfLearningMockExam';
/**
 * Check if a given object implements the LearningHistoryFilterItemBySelfLearningMockExamList interface.
 */
export function instanceOfLearningHistoryFilterItemBySelfLearningMockExamList(value) {
    let isInstance = true;
    isInstance = isInstance && "values" in value;
    return isInstance;
}
export function LearningHistoryFilterItemBySelfLearningMockExamListFromJSON(json) {
    return LearningHistoryFilterItemBySelfLearningMockExamListFromJSONTyped(json, false);
}
export function LearningHistoryFilterItemBySelfLearningMockExamListFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'values': (json['values'].map(LearningHistoryFilterItemBySelfLearningMockExamFromJSON)),
    };
}
export function LearningHistoryFilterItemBySelfLearningMockExamListToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'values': (value.values.map(LearningHistoryFilterItemBySelfLearningMockExamToJSON)),
    };
}
