import {useQuery} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';

import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {getUnderlineMarkupQueryKey} from '@app/queryKeys';
import {UnderlineMarkupInfo} from '@app/test-preparation/types/underlineMarkup';

const useUnderlineMarkups = (cisId: number | null | undefined) => {
  const {UnderlineService} = useAtomValue(santaOpenapiServicesAtom);

  return useQuery(getUnderlineMarkupQueryKey(cisId), async () => {
    if (cisId == null) {
      return [];
    }

    const response = await UnderlineService.getUnderline({
      contentInteractionStateId: cisId,
    });

    return response.underline.underlineInfos.map(({displayInfo, id}) => ({
      id,
      displayInfo: JSON.parse(displayInfo) as unknown as UnderlineMarkupInfo,
    }));
  });
};

export default useUnderlineMarkups;
