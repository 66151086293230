import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {BREAKPOINT_MEDIUM, COLOR_SANTA_L} from '@riiid/design-system';

import {Button, TextButton, TopNavBar, Typography} from '@santa-web/service-ui';
import FrameLayoutModal from '@app/components/FrameLayoutModal';
import {ModalFilterProps} from '@app/components/ModalFilter';
import ScrollLayout from '@app/components/ScrollLayout';

export type FrameLayoutModalFilterProps = ModalFilterProps;

function FrameLayoutModalFilter({
  isOpen,
  canSubmit,
  canReset,
  onRequestClose,
  onSubmit,
  onReset,
  children,
  ...props
}: FrameLayoutModalFilterProps) {
  const {t} = useTranslation();
  const submitButton = (
    <Button isDisabled={!canSubmit} isFullWidth variant="bottom-fix" onClick={onSubmit} colorVariant="brand">
      {t('__filter_applyFilters')}
    </Button>
  );

  const headerRightElement = (
    <TextButton isDisabled={!canReset} onClick={onReset} color="black" fontWeight="bold" typographyVariant="body3">
      {t('__filter_resetFilters')}
    </TextButton>
  );

  return (
    <FrameLayoutModal
      isOpened={isOpen}
      FrameLayoutProps={{
        topNavBar: (
          <TopNavBar paginationIcon="back" onPaginationIconClick={onRequestClose} rightArea={headerRightElement} />
        ),
        bottomArea: submitButton,
      }}
      {...props}>
      <ScrollLayout>
        <div
          css={css`
            width: 100%;
            display: flex;
            justify-content: center;
          `}>
          <div
            css={css`
              width: 100%;
              max-width: 680px;
              padding: 40px 20px;

              @media (min-width: ${BREAKPOINT_MEDIUM}) {
                padding: 40px 40px;
              }
            `}>
            <Typography
              variant="body2"
              fontWeight="bold"
              color={COLOR_SANTA_L}
              css={css`
                display: block;
                margin-bottom: 40px;
              `}>
              {t('__filter_title')}
            </Typography>
            {children}
          </div>
        </div>
      </ScrollLayout>
    </FrameLayoutModal>
  );
}

export default React.memo(FrameLayoutModalFilter);
