import { ExamScoreFromJSON, ExamScoreToJSON, } from './ExamScore';
/**
 * Check if a given object implements the ListExamScoresResponse interface.
 */
export function instanceOfListExamScoresResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "examScores" in value;
    return isInstance;
}
export function ListExamScoresResponseFromJSON(json) {
    return ListExamScoresResponseFromJSONTyped(json, false);
}
export function ListExamScoresResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'examScores': (json['examScores'].map(ExamScoreFromJSON)),
    };
}
export function ListExamScoresResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'examScores': (value.examScores.map(ExamScoreToJSON)),
    };
}
