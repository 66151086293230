import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {BREAKPOINT_MEDIUM, COLOR_SANTA_A, COLOR_SANTA_E} from '@riiid/design-system';

import {ElementInteractionState, QuestionState} from '@santa-web/gen/open-api/content-learning-service';
import {QuestionSet} from '@santa-web/gen/open-api/content/models/QuestionSet';
import {
  MultiQuestionTitle,
  MultiQuestionTitleList,
  ObjectiveQuestionPage,
  SecondPageLayout,
  SingleQuestionTitle,
  BlankContext,
  HelpArea,
  useIsGteMediumScreen,
} from '@santa-web/service-ui';
import {useQuestionTitleListGradient} from '@app/components/ContentView/hooks/useQuestionTItleListGradient';
import {ScrollAreaBottomViewProps} from '@app/components/ContentView/types';
import {getQuestionTitle} from '@app/components/ContentView/utilities';
import * as QuestionStateFacade from '@app/facade/question-state';
import DoubleColumnLayout from '@app/test-preparation/components/DoubleColumnLayout';
import ExplanationListView from '@app/test-preparation/components/ExplanationListView';
import PassageListView from '@app/test-preparation/components/PassageListView';
import QuestionView, {ChoiceAnswerHandler} from '@app/test-preparation/components/QuestionView';
import SnippetView from '@app/test-preparation/components/SnippetView';
import {
  TESTPREP_SECOND_CONTENT_DEFAULT_HEIGHT,
  TESTPREP_SECOND_CONTENT_MAX_HEIGHT,
  TESTPREP_SECOND_CONTENT_MIN_HEIGHT,
} from '@app/test-preparation/constants/test-preparation-layout';
import {IsUserHighlightEnabledContext} from '@app/test-preparation/contexts/IsUserHighlightEnabledContext';
import {SnippetViewBaseIdContext} from '@app/test-preparation/contexts/SnippetViewBaseIdContext';
import {useBlankContext} from '@app/test-preparation/hooks/rrt-v2';

interface GtelpReadingAndVocabularyQuestionViewProps extends Partial<ScrollAreaBottomViewProps> {
  isCompleted?: boolean;
  isDisabled?: boolean;
  isExplanationVisible?: boolean;
  passagesTitle: string;
  questionSet?: QuestionSet | null;
  questionElementStates?: ElementInteractionState[];
  accumulatedQuestionCount?: number;
  questionIndices: number[];
  currentQuestionIndex: number;
  passageIndex: number;
  firstContentRef?: React.RefObject<HTMLDivElement>;
  secondContentRef?: React.RefObject<HTMLDivElement>;
  onScroll?: () => void;
  onQuestionStateChangeOfIndex?: (questionIndex: number) => (newQuestionState: QuestionState) => Promise<void>;
  onCurrentQuestionIndexChange?: (questionIndex: number) => void;
  canSelectUnsolvableQuestion: boolean;
  onChoiceAnswer?: ChoiceAnswerHandler;
  isUserHighlightEnabled: boolean;
  cisId: number;
  contentId: string;
}

const GtelpReadingAndVocabularyQuestionView = ({
  isCompleted = false,
  isDisabled = false,
  isExplanationVisible = true,
  passagesTitle,
  questionSet,
  questionElementStates,
  accumulatedQuestionCount = 0,
  questionIndices,
  currentQuestionIndex: _currentQuestionIndex,
  passageIndex,
  scrollAreaBottomRef,
  scrollAreaMarginBottom,
  firstContentRef,
  secondContentRef,
  onScroll,
  onQuestionStateChangeOfIndex,
  onCurrentQuestionIndexChange,
  canSelectUnsolvableQuestion,
  onChoiceAnswer,
  isUserHighlightEnabled,
  cisId,
  contentId,
}: GtelpReadingAndVocabularyQuestionViewProps): JSX.Element => {
  const {t} = useTranslation();
  const isGteMediumScreen = useIsGteMediumScreen();
  const blankContext = useBlankContext();

  const explanations = questionSet?.explanations;
  const passages = questionSet?.passages;
  const passageTranslations = questionSet?.passageTranslations;
  const questions = questionSet?.questions;
  const description = questionSet?.description;
  const currentQuestionIndex =
    questions === undefined || _currentQuestionIndex >= questions.length ? 0 : _currentQuestionIndex;

  const passageListView = (
    <BlankContext.Provider value={blankContext}>
      <PassageListView passages={passages} />
    </BlankContext.Provider>
  );

  const passageTranslationListView = <PassageListView passages={passageTranslations} />;
  const explanationListView = <ExplanationListView explanations={explanations} />;

  const passageTitle = <SingleQuestionTitle content={passagesTitle} />;

  const firstContent = (
    <div
      css={css`
        margin-bottom: ${scrollAreaMarginBottom}px;
      `}>
      {description && (
        <div
          css={css`
            @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
              padding: 40px 20px 0;
            }
          `}>
          <HelpArea isIconVisible>
            <SnippetView snippet={description} />
          </HelpArea>
        </div>
      )}
      <ObjectiveQuestionPage questionTitle={passageTitle} passages={passageListView} />
    </div>
  );

  const gteLargeScreenSecondContentView = (
    <div>
      {isCompleted && (
        <div
          css={css`
            padding: 40px 40px 0 40px;
          `}>
          <MultiQuestionTitleList>
            <MultiQuestionTitle
              type="passage"
              isActive={currentQuestionIndex === passageIndex}
              onClick={() => onCurrentQuestionIndexChange?.(passageIndex)}>
              {t('toeic_question_view_multi_question_title_passage')}
            </MultiQuestionTitle>
            <MultiQuestionTitle
              type="passage"
              isActive={currentQuestionIndex !== passageIndex}
              onClick={() => onCurrentQuestionIndexChange?.(0)}>
              {t('toeic_question_view_multi_question_title_question')}
            </MultiQuestionTitle>
          </MultiQuestionTitleList>
        </div>
      )}
      {currentQuestionIndex === passageIndex ? (
        <ObjectiveQuestionPage explanations={explanationListView} passages={passageTranslationListView} />
      ) : (
        <div
          css={css`
            & > *:not(:last-child)::after {
              display: flex;
              height: 1px;
              margin-right: 20px;
              margin-left: 20px;
              background: ${COLOR_SANTA_E};
              content: '';
              @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
                margin-right: 40px;
                margin-left: 40px;
              }
            }
          `}>
          {questions?.map((question, questionIndex) => (
            <div key={questionIndex}>
              <QuestionView
                isExplanationInvisible={!isExplanationVisible}
                onChoiceAnswer={onChoiceAnswer}
                canSelectUnsolvableQuestion={canSelectUnsolvableQuestion}
                questionIndex={questionIndex}
                questionTitle={
                  <SingleQuestionTitle content={getQuestionTitle(accumulatedQuestionCount + questionIndex)} />
                }
                question={question}
                questionState={questionElementStates?.[questionIndex].details.question}
                elementInteractionStateId={questionElementStates?.[questionIndex].id}
                isCompleted={isCompleted}
                onChange={onQuestionStateChangeOfIndex?.(questionIndex)}
                contentId={contentId}
              />
            </div>
          ))}
        </div>
      )}
    </div>
  );

  const {
    questionTitleListLayoutRef,
    questionTitleFirstItemRef,
    questionTitleLastItemRef,
    isLeftGradientVisible,
    isRightGradientVisible,
  } = useQuestionTitleListGradient();

  const smallScreenSecondContentView = (() => {
    const multiQuestionTitleList = (
      <div
        css={css`
          box-sizing: border-box;
          position: relative;
          width: calc(100% + 40px);
          transform: translateX(-20px);
        `}
        ref={questionTitleListLayoutRef}>
        <div
          css={css`
            position: absolute;
            top: 0;
            left: 0;
            width: 40px;
            height: 100%;
            z-index: ${isLeftGradientVisible ? 999 : -1};
            opacity: ${isLeftGradientVisible ? 1 : 0};
            transition: opacity linear 0.05s;
            background: linear-gradient(to right, ${COLOR_SANTA_A}, transparent);
          `}
        />
        <div
          css={css`
            position: absolute;
            top: 0;
            left: 100vw;
            transform: translateX(-100%);
            width: 40px;
            height: 100%;
            z-index: ${isRightGradientVisible ? 999 : -1};
            opacity: ${isRightGradientVisible ? 1 : 0};
            transition: opacity linear 0.05s;
            background: linear-gradient(to right, transparent, ${COLOR_SANTA_A});
          `}
        />
        <MultiQuestionTitleList
          css={css`
            overflow: scroll;
            &::-webkit-scrollbar {
              display: none;
            }
            -ms-overflow-style: none;
            scrollbar-width: none;
          `}>
          {questionIndices.map((questionItemIndex, arrayIndex) => {
            const isFirstItem = arrayIndex === 0;
            const isLastItem = arrayIndex === questionIndices.length - 1;
            return (
              <MultiQuestionTitle
                css={[
                  css`
                    word-break: keep-all;
                  `,
                  isFirstItem &&
                    css`
                      margin-left: 20px;
                    `,
                  isLastItem &&
                    css`
                      margin-right: 20px !important;
                    `,
                ]}
                key={questionItemIndex}
                type={questionItemIndex === passageIndex ? 'passage' : 'question'}
                isActive={questionItemIndex === currentQuestionIndex}
                isCompleted={isCompleted}
                isCorrect={questionElementStates?.[questionItemIndex]?.details.question?.answerState === 'CORRECT'}
                isSolved={
                  !isCompleted &&
                  QuestionStateFacade.getIsSelected(questionElementStates?.[questionItemIndex]?.details.question)
                }
                onClick={() => {
                  onCurrentQuestionIndexChange?.(questionItemIndex);
                }}
                ref={isFirstItem ? questionTitleFirstItemRef : isLastItem ? questionTitleLastItemRef : null}>
                {questionItemIndex === passageIndex
                  ? t('toeic_question_view_multi_question_title_passage')
                  : t('toeic_question_view_multi_question_title_question_index', {
                      index: accumulatedQuestionCount + questionItemIndex,
                    })}
              </MultiQuestionTitle>
            );
          })}
        </MultiQuestionTitleList>
      </div>
    );

    if (currentQuestionIndex === passageIndex) {
      return (
        <ObjectiveQuestionPage
          questionTitle={multiQuestionTitleList}
          explanations={explanationListView}
          passages={passageTranslationListView}
        />
      );
    } else {
      const currentQuestion = questions?.[currentQuestionIndex];
      const currentQuestionState = questionElementStates?.[currentQuestionIndex].details.question;
      return (
        <QuestionView
          isDisabled={isDisabled}
          isExplanationInvisible={!isExplanationVisible}
          question={currentQuestion}
          questionIndex={currentQuestionIndex}
          questionState={currentQuestionState}
          questionTitle={multiQuestionTitleList}
          onChange={onQuestionStateChangeOfIndex?.(currentQuestionIndex)}
          canSelectUnsolvableQuestion={canSelectUnsolvableQuestion}
          onChoiceAnswer={onChoiceAnswer}
          elementInteractionStateId={questionElementStates?.[currentQuestionIndex].id}
          isCompleted={isCompleted}
          contentId={contentId}
        />
      );
    }
  })();

  const secondContent = (
    <div
      css={css`
        display: flex;
        flex-direction: column;

        min-height: 100%;
      `}>
      <div
        css={css`
          flex: 1;
        `}>
        {isGteMediumScreen ? gteLargeScreenSecondContentView : smallScreenSecondContentView}
        <div
          ref={scrollAreaBottomRef}
          css={css`
            height: ${scrollAreaMarginBottom}px;
          `}
        />
      </div>
    </div>
  );

  return (
    <SnippetViewBaseIdContext.Provider value={cisId.toString()}>
      <IsUserHighlightEnabledContext.Provider value={isUserHighlightEnabled}>
        <DoubleColumnLayout>
          <SecondPageLayout
            onScroll={isCompleted ? onScroll : undefined}
            defaultHeight={TESTPREP_SECOND_CONTENT_DEFAULT_HEIGHT}
            minHeight={TESTPREP_SECOND_CONTENT_MIN_HEIGHT}
            maxHeight={TESTPREP_SECOND_CONTENT_MAX_HEIGHT}
            content={firstContent}
            contentRef={firstContentRef}
            secondContent={secondContent}
            secondContentRef={secondContentRef}
          />
        </DoubleColumnLayout>
      </IsUserHighlightEnabledContext.Provider>
    </SnippetViewBaseIdContext.Provider>
  );
};

export default React.memo(GtelpReadingAndVocabularyQuestionView);
