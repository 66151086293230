/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { ListExamDatesResponseFromJSON, } from '../models';
/**
 *
 */
export class ExamDateV1Api extends runtime.BaseAPI {
    /**
     * 현재 사용자의 국가 및 사용자가 선택한 학습 도메인에 대한 시험 일정 목록을 조회합니다.
     */
    async listExamDatesRaw(requestParameters, initOverrides) {
        if (requestParameters.from === null || requestParameters.from === undefined) {
            throw new runtime.RequiredError('from', 'Required parameter requestParameters.from was null or undefined when calling listExamDates.');
        }
        if (requestParameters.to === null || requestParameters.to === undefined) {
            throw new runtime.RequiredError('to', 'Required parameter requestParameters.to was null or undefined when calling listExamDates.');
        }
        const queryParameters = {};
        if (requestParameters.from !== undefined) {
            queryParameters['from'] = requestParameters.from.toISOString().substr(0, 10);
        }
        if (requestParameters.to !== undefined) {
            queryParameters['to'] = requestParameters.to.toISOString().substr(0, 10);
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/api/learning-domain/v1/exam-dates`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListExamDatesResponseFromJSON(jsonValue));
    }
    /**
     * 현재 사용자의 국가 및 사용자가 선택한 학습 도메인에 대한 시험 일정 목록을 조회합니다.
     */
    async listExamDates(requestParameters, initOverrides) {
        const response = await this.listExamDatesRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
