import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {BREAKPOINT_MEDIUM} from '@riiid/design-system';

import CardList, {CardListProps} from '@app/components/card/CardList';

export interface LearningCardListProps extends CardListProps {
  title?: string;
}

const LearningCardList = React.forwardRef<HTMLDivElement, LearningCardListProps>(({title, ...props}, ref) => {
  const {t} = useTranslation();

  const listDivCss = css`
    @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
      grid-template-columns: 1fr 1fr;
    }
  `;

  return (
    <CardList
      ref={ref}
      pageTitle={title && t('learning_cell_report_card_list_page_title')}
      title={
        title && (
          <span
            css={css`
              white-space: pre-line;
            `}>
            {title}
          </span>
        )
      }
      css={css`
        position: relative;
      `}
      listDivCss={listDivCss}
      {...props}
    />
  );
});

export default React.memo(LearningCardList);
