import {useEffect} from 'react';
import useFlashDealEvent from '@app/api/google-tag-manager/flashdeal';
import useOfferEvents from '@app/api/google-tag-manager/offer';
import config, {Config} from '@app/config';
import {L2EFlashDealPageView, CountDownTimerContainer, OfferGroupCardContainer} from '@app/features/marketing';
import {useOfferGroupQuery} from '@app/features/offer';
import {mapOfferGroupToOfferGroupCardProps} from '@app/features/offer/utils';
import {useMyPassStatusQuery} from '@app/features/permit';
import {useTypedRouter} from '@app/hooks/useTypedRouter';

const offerGroupIdMap: Record<Config['env'], number> = {
  development: 13,
  staging: 10,
  production: 50018, //https://riiid-pioneer.atlassian.net/browse/SP-5
};
const offerGroupId = offerGroupIdMap[config.env];

const L2EFlashDealPageContainer = () => {
  const {pushL2EFlashDealExit, pushL2EFlashDealNext, pushL2EFlashDealProductCard} = useFlashDealEvent();
  const {pushClickStore} = useOfferEvents();
  const {data: passStatus} = useMyPassStatusQuery();
  const {data: offerGroup, isLoading} = useOfferGroupQuery(offerGroupId);
  const router = useTypedRouter();

  const passRemainedTimeMs = (passStatus?.activePass?.expireAt?.getTime() ?? 0) - new Date().getTime();

  const handleBuyClick = () => {
    if (!offerGroup) return;

    pushClickStore({referrer: 'flash_deal'});

    router.replace({
      pathname: '/offer-group/board/detail',
      query: {offerGroupId: offerGroup.id, boardCode: 'FLASH', backTo: '/course', referrer: 'flash_deal'},
    });
  };

  const handleCloseClick = () => {
    router.replace('/course');
  };

  useEffect(() => {
    if (!isLoading && !offerGroup) {
      router.replace('/course');
    }
  }, [isLoading, offerGroup, router]);

  if (!offerGroup) return null;

  const offerGroupProps = mapOfferGroupToOfferGroupCardProps(offerGroup, new Date());

  return (
    <L2EFlashDealPageView
      onBuyClick={() => {
        pushL2EFlashDealNext();
        handleBuyClick();
      }}
      onCloseClick={() => {
        handleCloseClick();
        pushL2EFlashDealExit();
      }}
      countdownTimer={<CountDownTimerContainer remainTimeMs={passRemainedTimeMs} />}
      offerGroupCard={
        <OfferGroupCardContainer
          {...offerGroupProps}
          onClick={() => {
            pushL2EFlashDealProductCard();
            handleBuyClick();
          }}
        />
      }
    />
  );
};

export default L2EFlashDealPageContainer;
