/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
/**
 * Check if a given object implements the PointRewardGuideUrlResponse interface.
 */
export function instanceOfPointRewardGuideUrlResponse(value) {
    let isInstance = true;
    return isInstance;
}
export function PointRewardGuideUrlResponseFromJSON(json) {
    return PointRewardGuideUrlResponseFromJSONTyped(json, false);
}
export function PointRewardGuideUrlResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'guideUrl': !exists(json, 'guideUrl') ? undefined : json['guideUrl'],
    };
}
export function PointRewardGuideUrlResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'guideUrl': value.guideUrl,
    };
}
