import {useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {COLOR_SANTA_CARD_A_ALPHA, COLOR_SANTA_E} from '@riiid/design-system';
import {Button} from '@santa-web/service-ui';
import {BottomSheetWithDim, BottomSheetWithDimProps, TopBottomGradient} from '@app/components';
import WheelSlot from '@app/components/WheelDatePicker/WheelSlot';
import {StringDate, Year, Month, Day} from '@app/features/preferences/types';

type ExamDatePickBottomSheetProps = {
  dates: StringDate[];
  onCloseClick: () => void;
  onConfirm: (date: StringDate) => void;
  defaultValue?: StringDate;
} & Omit<BottomSheetWithDimProps, 'buttons' | 'onCloseClick' | 'isOpened'>;

type ExamDate = {
  year: Year;
  month: Month;
  day: Day;
};

type LimitedDatesForWheelDatePicker = Map<Year, Map<Month, Day[]>>;

const ExamDatePickBottomSheet = ({
  dates = [],
  defaultValue,
  onConfirm,
  onCloseClick,
  ...bottomSheetProps
}: ExamDatePickBottomSheetProps) => {
  const {t} = useTranslation();
  const defaultDate = defaultValue ?? (dates.at(-1) as StringDate);
  const [defaultYear, defaultMonth, defaultDay] = defaultDate.split('-') as [Year, Month, Day];
  const [examDate, setExamDate] = useState<ExamDate>({year: defaultYear, month: defaultMonth, day: defaultDay});
  const availableDates = useMemo(() => getAvailableDates(dates), [dates]);

  const years = Array.from(availableDates.keys());
  const availableMonthMap = availableDates.get(examDate.year);
  const months = availableMonthMap ? Array.from(availableMonthMap.keys()) : [];
  const days = availableMonthMap ? availableMonthMap.get(examDate.month) ?? [] : [];

  const onWheelChange = (key: keyof ExamDate) => (item: {label: string; value: number}) => {
    setExamDate(prev => {
      const nextDate = {...prev, [key]: item.label};
      return nextDate;
    });
  };

  useEffect(() => {
    const isMonthValid = availableMonthMap?.has(examDate.month);

    /**
     * 연도 변경 시: 해당 연도의 월이 없으면 첫번째 월의 첫번째 변경
     */

    if (!isMonthValid && availableMonthMap) {
      const availableDays = availableMonthMap.get(months[0]);

      if (!availableDays) return;
      const initialDay = availableDays[0];
      setExamDate(prev => ({...prev, month: months[0], day: initialDay}));
    }

    /**
     * 연도 변경 시: 해당 연도의 월이 있으나, 해당 일이 없으면 첫번째 일로 변경
     */
    if (isMonthValid && availableMonthMap) {
      const availableDays = availableMonthMap.get(examDate.month);
      if (!availableDays) return;
      const isDayValid = availableDays.some(day => day === examDate.day);

      if (!isDayValid) {
        setExamDate(prev => ({...prev, day: availableDays[0]}));
      }
    }
  }, [examDate.year]);

  /**
   * 월 변경 시: 해당 월의 일이 없으면 첫번째 일로 변경
   */
  useEffect(() => {
    const isDayValid = days.some(day => day === examDate.day);

    if (!isDayValid) {
      setExamDate(prev => ({...prev, day: days[0]}));
    }
  }, [examDate.month]);

  if (dates.length === 0) return null;

  return (
    <BottomSheetWithDim
      isOpened
      BottomSheetProps={{
        onCloseClick,
        content: (
          <TopBottomGradient
            blurHeight={48}
            css={css`
              position: relative;
              display: flex;
              justify-content: center;
              gap: 16px;
            `}
            blurColor={COLOR_SANTA_CARD_A_ALPHA(0.6)}>
            <WheelSlot
              defaultValue={parseInt(examDate.year)}
              items={years.map(year => ({label: year, value: parseInt(year)}))}
              onChange={onWheelChange('year')}
            />
            <WheelSlot
              key={months.join()}
              defaultValue={parseInt(examDate.month)}
              items={months.map(month => ({label: month, value: parseInt(month)}))}
              onChange={onWheelChange('month')}
            />
            <WheelSlot
              key={days.join()}
              defaultValue={parseInt(examDate.day)}
              items={days.map(day => ({label: day, value: parseInt(day)}))}
              onChange={onWheelChange('day')}
            />
            <div
              css={css`
                position: absolute;
                top: 50%;
                height: 54px;
                width: 100%;
                border-top: 1px solid ${COLOR_SANTA_E};
                border-bottom: 1px solid ${COLOR_SANTA_E};
                transform: translateY(-50%);
              `}
            />
          </TopBottomGradient>
        ),
        buttons: [
          <Button
            isFullWidth
            colorVariant="brand"
            onClick={() => {
              const {year, month, day} = examDate;
              const isValid = [year, month, day].every(Boolean);

              if (isValid) {
                onConfirm(`${year}-${month}-${day}`);
              }
              onCloseClick();
            }}
            key="확인">
            {t('dict_confirm')}
          </Button>,
        ],
      }}
      {...bottomSheetProps}
    />
  );
};

/**
 * dates를 연도, 월, 일로 분리하여 Map으로 반환. 입력된 Key의 순서가 보장되어야 해서 Object 대신 Map 사용
 */
function getAvailableDates(dates: StringDate[]): LimitedDatesForWheelDatePicker {
  const splitDates = dates.map(date => date.split('-') as [Year, Month, Day]);

  return splitDates.reduce<LimitedDatesForWheelDatePicker>((acc, [year, month, day]) => {
    if (!acc.has(year)) {
      acc.set(year, new Map<Month, Day[]>());
    }

    const availableMonthMap = acc.get(year);

    if (availableMonthMap && !availableMonthMap.has(month)) {
      availableMonthMap.set(month, []);
    }

    const availableDays = availableMonthMap?.get(month);

    if (availableDays) {
      availableDays.push(day);
    }

    return acc;
  }, new Map() as LimitedDatesForWheelDatePicker);
}

export default ExamDatePickBottomSheet;
export type {ExamDatePickBottomSheetProps};
ExamDatePickBottomSheet.displayName = 'ExamDatePickBottomSheet';
