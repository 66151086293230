import React from 'react';
import {css} from '@emotion/react';

import {useFloatingChatButtonList} from '@santa-web/service-ui';
import useFloatingPaginationButtons from '@app/pages/content-interaction-states/FloatingPaginationButtons/useFloatingPaginationButtons';

interface FloatingButtonsProps extends React.ComponentPropsWithoutRef<'div'> {
  maxWidth?: React.CSSProperties['maxWidth'];
}

const FloatingButtons = React.forwardRef<HTMLDivElement, FloatingButtonsProps>(
  ({maxWidth, children, ...props}, ref) => {
    const {floatingChatButtonList} = useFloatingChatButtonList();
    const {floatingPaginationButtons} = useFloatingPaginationButtons();

    return (
      <div
        css={css`
          position: relative;
          max-width: ${maxWidth};
          width: 100%;
          margin: 0 auto;
          pointer-events: none;
        `}
        ref={ref}
        {...props}>
        <div
          css={css`
            position: absolute;
            bottom: 0px;
            width: 100%;
          `}>
          {floatingChatButtonList}
          {floatingPaginationButtons}
          {children}
        </div>
      </div>
    );
  }
);

export default React.memo(FloatingButtons);
