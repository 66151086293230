/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { CursorPageFromJSON, CursorPageToJSON, } from './CursorPage';
import { LearningHistoryFromJSON, LearningHistoryToJSON, } from './LearningHistory';
/**
 * Check if a given object implements the ListLearningHistoriesQueryResult interface.
 */
export function instanceOfListLearningHistoriesQueryResult(value) {
    let isInstance = true;
    isInstance = isInstance && "filteredQuestionCount" in value;
    isInstance = isInstance && "learningHistories" in value;
    isInstance = isInstance && "totalLearningHistoryCount" in value;
    return isInstance;
}
export function ListLearningHistoriesQueryResultFromJSON(json) {
    return ListLearningHistoriesQueryResultFromJSONTyped(json, false);
}
export function ListLearningHistoriesQueryResultFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'filteredLessonCount': !exists(json, 'filteredLessonCount') ? undefined : json['filteredLessonCount'],
        'filteredQuestionCount': json['filteredQuestionCount'],
        'learningHistories': (json['learningHistories'].map(LearningHistoryFromJSON)),
        'nextCursorPage': !exists(json, 'nextCursorPage') ? undefined : CursorPageFromJSON(json['nextCursorPage']),
        'totalLearningHistoryCount': json['totalLearningHistoryCount'],
    };
}
export function ListLearningHistoriesQueryResultToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'filteredLessonCount': value.filteredLessonCount,
        'filteredQuestionCount': value.filteredQuestionCount,
        'learningHistories': (value.learningHistories.map(LearningHistoryToJSON)),
        'nextCursorPage': CursorPageToJSON(value.nextCursorPage),
        'totalLearningHistoryCount': value.totalLearningHistoryCount,
    };
}
