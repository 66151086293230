/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { GetLearningGoalStatusResponseFromJSON, GetLearningStatisticsResponseFromJSON, } from '../models';
/**
 *
 */
export class LearningStatisticsV1Api extends runtime.BaseAPI {
    /**
     * 학습 도메인 별로 학습 목표 상태를 조회한다.
     * 학습 목표 상태를 조회한다.
     */
    async getLearningGoalStatusRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/analytics/v1/learning-statistics/status`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetLearningGoalStatusResponseFromJSON(jsonValue));
    }
    /**
     * 학습 도메인 별로 학습 목표 상태를 조회한다.
     * 학습 목표 상태를 조회한다.
     */
    async getLearningGoalStatus(requestParameters, initOverrides) {
        const response = await this.getLearningGoalStatusRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 학습 현황 탭에서 유저는 학습 통계를 일간, 주간, 혹은 월간으로 볼 수 있다.  ## Overview (공통부분) * 일간, 주간, 월간에서 공통적으로 존재하는 영역은 `overview`에 다음과 같이 담겨있다.  ## 일간 * `histories`와 `previousHistories` 모두 `null`  ## 주간 * `histories`와 `previousHistories` 각각 길이가 7  ## 월간 * `histories`의 길이는 요청 받은 월의 주 수     * 만일 `historyGroup`을 `DAILY`로 했다면 (캘린더 렌더링 용)         * `histories`의 길이는 요청 받은 월의 일 수 * `previousHistories`는 `null`  *보다 자세한 것은 200 응답 예제를 참고*
     * 요청에 따라 일간, 주간, 혹은 월간 학습 통계를 반환한다.
     */
    async getLearningStatisticsRaw(requestParameters, initOverrides) {
        if (requestParameters.type === null || requestParameters.type === undefined) {
            throw new runtime.RequiredError('type', 'Required parameter requestParameters.type was null or undefined when calling getLearningStatistics.');
        }
        if (requestParameters.date === null || requestParameters.date === undefined) {
            throw new runtime.RequiredError('date', 'Required parameter requestParameters.date was null or undefined when calling getLearningStatistics.');
        }
        const queryParameters = {};
        if (requestParameters.type !== undefined) {
            queryParameters['type'] = requestParameters.type;
        }
        if (requestParameters.date !== undefined) {
            queryParameters['date'] = requestParameters.date;
        }
        if (requestParameters.historyGroup !== undefined) {
            queryParameters['historyGroup'] = requestParameters.historyGroup;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/api/analytics/v1/learning-statistics/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetLearningStatisticsResponseFromJSON(jsonValue));
    }
    /**
     * 학습 현황 탭에서 유저는 학습 통계를 일간, 주간, 혹은 월간으로 볼 수 있다.  ## Overview (공통부분) * 일간, 주간, 월간에서 공통적으로 존재하는 영역은 `overview`에 다음과 같이 담겨있다.  ## 일간 * `histories`와 `previousHistories` 모두 `null`  ## 주간 * `histories`와 `previousHistories` 각각 길이가 7  ## 월간 * `histories`의 길이는 요청 받은 월의 주 수     * 만일 `historyGroup`을 `DAILY`로 했다면 (캘린더 렌더링 용)         * `histories`의 길이는 요청 받은 월의 일 수 * `previousHistories`는 `null`  *보다 자세한 것은 200 응답 예제를 참고*
     * 요청에 따라 일간, 주간, 혹은 월간 학습 통계를 반환한다.
     */
    async getLearningStatistics(requestParameters, initOverrides) {
        const response = await this.getLearningStatisticsRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
