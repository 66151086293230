import {ContentInteractionState} from '@santa-web/gen/open-api/content-learning-service';
import * as ContentInteractionStateFacade from '@app/facade/content-interaction-state';

function findFirstIncompleteCisIndex(cises: Array<ContentInteractionState>) {
  const incompleteIndex = cises.findIndex(cis => !ContentInteractionStateFacade.getIsCompleted(cis));

  // 모두 완료했거나 완료하지 않은 CIS가 voca가 아닌 경우 반환 (voca의 경우 일괄 완료 처리를 하기 때문에 별도의 처리 필요)
  if (
    (incompleteIndex === -1 &&
      ContentInteractionStateFacade.getStatesByCase(cises[cises.length - 1]).oneOfCase !== 'VOCA') ||
    (incompleteIndex !== -1 &&
      ContentInteractionStateFacade.getStatesByCase(cises[incompleteIndex]).oneOfCase !== 'VOCA')
  ) {
    return incompleteIndex;
  }

  const currentVocabularySession =
    Math.max(
      ...cises
        .filter(cis => cis.elementInteractionStates[0].details.oneOfCase === 'VOCA')
        .map(cis => cis.elementInteractionStates[0].details.voca?.selfReports.length ?? 0)
    ) - 1;

  if (currentVocabularySession === -1) {
    return 0;
  }

  let last = -1;

  const index = cises.findIndex((cis, index) => {
    const reports = cis.elementInteractionStates[0].details.voca?.selfReports ?? [];
    const reportCount = reports.length;
    const previousSessionReport = reports[currentVocabularySession - 1];

    if (reportCount - 1 === currentVocabularySession) {
      last = index;
    }

    return (
      reportCount === currentVocabularySession &&
      (!previousSessionReport || previousSessionReport.isKnowledge === false)
    );
  });

  return index === -1 ? last : index;
}

export {findFirstIncompleteCisIndex};
