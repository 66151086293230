import React from 'react';
import {BREAKPOINT_LARGE, BREAKPOINT_MEDIUM, BREAKPOINT_SMALL} from '@riiid/design-system';

export type ImageSet = {
  imageUrlX1: string;
  imageUrlX2: string;
  imageUrlX3: string;
};

export interface ResponsiveImageProps extends React.ComponentPropsWithoutRef<'img'> {
  imageSet?: ImageSet;
}
const getImageSourceSetByScreenWidth = (imageSet: ImageSet): string => {
  const pxToWidth = (px: string) => px.replace('px', 'w');
  return `${imageSet.imageUrlX1} ${pxToWidth(BREAKPOINT_SMALL)}, ${imageSet.imageUrlX2} ${pxToWidth(
    BREAKPOINT_MEDIUM
  )}, ${imageSet.imageUrlX3} ${pxToWidth(BREAKPOINT_LARGE)}`;
};

const ResponsiveImage = React.forwardRef<HTMLImageElement, ResponsiveImageProps>(
  ({imageSet, ...props}, ref): JSX.Element => {
    return (
      <img
        ref={ref}
        srcSet={imageSet ? getImageSourceSetByScreenWidth(imageSet) : undefined}
        src={imageSet ? imageSet.imageUrlX3 : undefined}
        {...props}
      />
    );
  }
);

export default React.memo(ResponsiveImage);
