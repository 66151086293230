/* tslint:disable */
/* eslint-disable */
/**
 *
 * @export
 */
export const ThumbnailContentImageSetAlign = {
    LEFT: 'LEFT',
    CENTER: 'CENTER'
};
export function ThumbnailContentImageSetAlignFromJSON(json) {
    return ThumbnailContentImageSetAlignFromJSONTyped(json, false);
}
export function ThumbnailContentImageSetAlignFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ThumbnailContentImageSetAlignToJSON(value) {
    return value;
}
