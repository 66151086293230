/* tslint:disable */
/* eslint-disable */
/**
 * - **ALREADY_EXISTS**: 해당 등록 정보로 이미 가입한 사용자가 존재하는 경우
 * - **PASSWORD_POLICY_VIOLATED**: 비밀번호 정책을 위반한 경우
 * - **AGREEMENT_REQUIRED**: 필수 동의사항(serviceTerms, privacy, age14)중 하나가 `false` 로 전송된 경우
 * @export
 */
export const SignupWithEmailPasswordBadRequestErrorCase = {
    ALREADY_EXISTS: 'ALREADY_EXISTS',
    PASSWORD_POLICY_VIOLATED: 'PASSWORD_POLICY_VIOLATED',
    AGREEMENT_REQUIRED: 'AGREEMENT_REQUIRED'
};
export function SignupWithEmailPasswordBadRequestErrorCaseFromJSON(json) {
    return SignupWithEmailPasswordBadRequestErrorCaseFromJSONTyped(json, false);
}
export function SignupWithEmailPasswordBadRequestErrorCaseFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function SignupWithEmailPasswordBadRequestErrorCaseToJSON(value) {
    return value;
}
