import { SelfLearningUnitGroupDetailFromJSON, SelfLearningUnitGroupDetailToJSON, } from './SelfLearningUnitGroupDetail';
/**
 * Check if a given object implements the GetSelfLearningUnitGroupDetailResponse interface.
 */
export function instanceOfGetSelfLearningUnitGroupDetailResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "unitGroupDetail" in value;
    return isInstance;
}
export function GetSelfLearningUnitGroupDetailResponseFromJSON(json) {
    return GetSelfLearningUnitGroupDetailResponseFromJSONTyped(json, false);
}
export function GetSelfLearningUnitGroupDetailResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'unitGroupDetail': SelfLearningUnitGroupDetailFromJSON(json['unitGroupDetail']),
    };
}
export function GetSelfLearningUnitGroupDetailResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'unitGroupDetail': SelfLearningUnitGroupDetailToJSON(value.unitGroupDetail),
    };
}
