import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';

import {Tooltip, TooltipProps, TooltipCardProps} from '@santa-web/service-ui';

export type ReplayAudioTutorialTooltipProps = Omit<TooltipProps, 'TooltipCardProps'>;

export default React.memo(
  React.forwardRef<HTMLDivElement, ReplayAudioTutorialTooltipProps>(function ReplayAudioTutorialTooltip(
    {...props},
    ref
  ) {
    const {t} = useTranslation();

    const tooltipCardProps = useMemo<TooltipCardProps>(
      () => ({
        arrowAlign: 'end',
        arrowDirection: 'block-start',
        color: 'black',
        size: 'medium',
        content: t('tutorial_replay_audio_tooltip_content'),
      }),
      [t]
    );
    return <Tooltip ref={ref} {...props} TooltipCardProps={tooltipCardProps} />;
  })
);
