module.exports = {
  redirectConfigs: [
    {
      /**
       * 베타 기간 동안 사용되었던 마케팅 페이지 path로, 정식 출시 후 마케팅 랜딩 페이지가 신설되어 deprecate됨.
       * 단 해당 path로 유입되는 유저가 남아있을 수 있기 때문에 접근시 메인 페이지로 redirect하기로 결정됨.
       * see: https://riiid.slack.com/archives/C040Y9ZMN05/p1694494598480879
       */
      source: '/toeic-speaking/event',
      destination: '/toeic-speaking',
      permanent: true,
    },
    {
      source: '/webview/cycle-report',
      destination: '/course/report',
      permanent: true,
    },
    {
      source: '/webview/event/info/:path*',
      destination: '/event/:path*',
      permanent: true,
    },
    {
      source: '/landing/:path*',
      destination: '/',
      permanent: true,
    },
    {
      source: '/enrollment/:path*',
      destination: '/',
      permanent: true,
    },
    {
      source: '/event',
      destination: '/event/list',
      permanent: true,
    },
    {
      source: '/product/:path*',
      destination: '/offer-group/board',
      permanent: true,
    },
    {
      source: '/stripe/product/:path*',
      destination: '/offer-group/board',
      permanent: true,
    },
    {
      source: '/webview/test/:path*',
      destination: '/webview-test/:path*',
      permanent: true,
    },
    {
      source: '/webview/:path*',
      destination: '/:path*',
      permanent: true,
    },
  ],
};
