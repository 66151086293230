/**
 * Check if a given object implements the TicketListItemV2 interface.
 */
export function instanceOfTicketListItemV2(value) {
    let isInstance = true;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "isUsed" in value;
    return isInstance;
}
export function TicketListItemV2FromJSON(json) {
    return TicketListItemV2FromJSONTyped(json, false);
}
export function TicketListItemV2FromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'id': json['id'],
        'isUsed': json['isUsed'],
    };
}
export function TicketListItemV2ToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'id': value.id,
        'isUsed': value.isUsed,
    };
}
