import {SignupRequestAgreementInfo, UserPersonalInfo} from '@santa-web/gen/open-api/service';

import {SignupFormValues} from '@app/features/auth/types';

export function toSignupCommonProps({
  email,
  name,
  agreeAge14OrOlder,
  agreeMarketing,
  agreePrivacy,
  agreeServiceTerms,
}: SignupFormValues): {
  agreementInfo: SignupRequestAgreementInfo;
  personalInfo: UserPersonalInfo;
} {
  return {
    personalInfo: {
      name,
      email,
    },
    agreementInfo: {
      age14: agreeAge14OrOlder ?? false,
      privacy: agreePrivacy ?? false,
      serviceTerms: agreeServiceTerms ?? false,
      marketing: agreeMarketing ?? false,
      push: agreeMarketing ?? false,
    },
  };
}
