import React, {useContext} from 'react';

import {State} from '@santa-web/gen/ssp/messages/inside/app/(OnDeviceStateChangedRequest)';

export type IDeviceStateContext = State;

export const DeviceStateContext = React.createContext<IDeviceStateContext>('FOREGROUND');

export const useDeviceStateContext = (): IDeviceStateContext => {
  return useContext(DeviceStateContext);
};
