import {DimProps} from '@santa-web/service-ui';
import {useDialogContext} from '@app/contexts/DialogContext';
import {LearningGoalBottomSheet} from '@app/features/preferences';

type useLearningGoalBottomSheetProps = {
  learningGoalOptionList: {label: string; value: number}[];
  selectedStudyMinutes: number | null;
  setSelectedStudyMinutes: (value: number | null) => void;
  curStudyMinutesPerDay: number | null;
} & DimProps;

const useLearningGoalBottomSheet = (props: useLearningGoalBottomSheetProps) => {
  const {openDialog} = useDialogContext();
  return {
    openLearningGoalBottomSheet: () => openDialog(<LearningGoalBottomSheet {...props} />),
  };
};

export default useLearningGoalBottomSheet;
