/* tslint:disable */
/* eslint-disable */
/**
 * getContentResource API에서 발생할 수 있는 에러 코드
 *
 * - **CONTENT_RESOURCE_NOT_FOUND**: 콘텐츠 리소스를 찾을 수 없음
 * @export
 */
export const ContentResourceV1GetContentResourceErrorCodes = {
    CONTENT_RESOURCE_NOT_FOUND: 'CONTENT_RESOURCE_NOT_FOUND'
};
export function ContentResourceV1GetContentResourceErrorCodesFromJSON(json) {
    return ContentResourceV1GetContentResourceErrorCodesFromJSONTyped(json, false);
}
export function ContentResourceV1GetContentResourceErrorCodesFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function ContentResourceV1GetContentResourceErrorCodesToJSON(value) {
    return value;
}
