import React from 'react';
import {css} from '@emotion/react';
import {COLOR_FIX_SANTA_WHITE_1, COLOR_FIX_SPACE_BLUE_GRAY_6} from '@riiid/design-system';
import VocaIcon from '@riiid/design-system/icons/Fonts';
import LectureIcon from '@riiid/design-system/icons/Playtime';
import QuestionIcon from '@riiid/design-system/icons/Question';
import RecommendedIcon from '@riiid/design-system/icons/SpecialOffer';

type BenefitIconProps = {type: 'question' | 'rec-study' | 'lecture' | 'voca'};

const BenefitIcon = ({type}: BenefitIconProps) => {
  const Icon = React.useMemo(() => {
    switch (type) {
      case 'question':
        return QuestionIcon;
      case 'rec-study':
        return RecommendedIcon;
      case 'lecture':
        return LectureIcon;
      case 'voca':
        return VocaIcon;
    }
  }, [type]);

  return (
    <div
      aria-hidden="true"
      css={css`
        width: 36px;
        height: 36px;
        display: flex;
        justify-content: center;
        align-items: center;
        border-radius: 9999px;
        background-color: ${COLOR_FIX_SPACE_BLUE_GRAY_6};
      `}>
      <Icon color={COLOR_FIX_SANTA_WHITE_1} width={22.15} height={22.15} type="solid" size="L" />
    </div>
  );
};

export default BenefitIcon;
export type {BenefitIconProps};
BenefitIcon.displayName = 'BenefitIcon';
