import {atom} from 'jotai';

import {contentRepository, resourceRepository} from '@app/test-preparation/atoms/repositories';
import {cisService} from '@app/test-preparation/atoms/services';
import {getResourceIds} from '@app/test-preparation/atoms/services/cis/utils/resource';

const loadAllContentsAtom = atom(null, async (get, set) => {
  const getContent = await get(contentRepository.getContentAtom);
  const listResources = await get(resourceRepository.listResourcesAtom);

  const contentDataMap = get(cisService.contentDataMapAtom);

  const nextContentDataMap = Object.fromEntries(
    Object.entries(
      await Promise.all(
        Object.keys(contentDataMap).map(async index => {
          const contentData = contentDataMap[index];

          if (contentData && (contentData.content === undefined || contentData.resources === undefined)) {
            const content = await getContent(contentData.url);
            const resources = await listResources(getResourceIds(content));

            return {
              ...contentData,
              content,
              resources,
            };
          }

          return contentData;
        })
      )
    )
  );

  set(cisService.contentDataMapAtom, nextContentDataMap);
});

export {loadAllContentsAtom};
