/* tslint:disable */
/* eslint-disable */
/**
 *
 * @export
 */
export const PassPermitType = {
    SELF_STUDY_LEARNING_CONTENT: 'SELF_STUDY_LEARNING_CONTENT',
    REVIEW_QUIZ_LEARNING_CONTENT: 'REVIEW_QUIZ_LEARNING_CONTENT',
    RECOMMENDED_LEARNING_CONTENT: 'RECOMMENDED_LEARNING_CONTENT'
};
export function PassPermitTypeFromJSON(json) {
    return PassPermitTypeFromJSONTyped(json, false);
}
export function PassPermitTypeFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function PassPermitTypeToJSON(value) {
    return value;
}
