/* tslint:disable */
/* eslint-disable */
/**
 *
 * @export
 */
export const Currency = {
    KRW: 'KRW',
    JPY: 'JPY',
    USD: 'USD',
    TWD: 'TWD',
    VND: 'VND',
    THB: 'THB'
};
export function CurrencyFromJSON(json) {
    return CurrencyFromJSONTyped(json, false);
}
export function CurrencyFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function CurrencyToJSON(value) {
    return value;
}
