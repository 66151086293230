import {useMemo} from 'react';

import {AuthProvider} from '@app/features/auth';

import {usePushEvent} from '.';

interface FinishSignupEventPayload extends Record<string, unknown> {
  provider: AuthProvider;
}

const useSignupEvents = () => {
  const pushEvent = usePushEvent();

  const signupEvents = useMemo(
    () => ({
      pushStartSignupEvent: async () => {
        await pushEvent('start_signup');
      },
      pushFinishSignupEvent: async (_payload: FinishSignupEventPayload) => {
        const payload = {
          provider: _payload.provider.toLowerCase(),
        };
        await pushEvent('finish_signup', payload);
      },
    }),
    [pushEvent]
  );

  return signupEvents;
};

export default useSignupEvents;
