import { HighlightFromJSON, HighlightToJSON, } from './Highlight';
/**
 * Check if a given object implements the HighlightsPreview interface.
 */
export function instanceOfHighlightsPreview(value) {
    let isInstance = true;
    isInstance = isInstance && "contentInteractionStateId" in value;
    isInstance = isInstance && "firstHighlightCreatedAt" in value;
    isInstance = isInstance && "highlights" in value;
    isInstance = isInstance && "title" in value;
    return isInstance;
}
export function HighlightsPreviewFromJSON(json) {
    return HighlightsPreviewFromJSONTyped(json, false);
}
export function HighlightsPreviewFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'contentInteractionStateId': json['contentInteractionStateId'],
        'firstHighlightCreatedAt': (new Date(json['firstHighlightCreatedAt'])),
        'highlights': (json['highlights'].map(HighlightFromJSON)),
        'title': json['title'],
    };
}
export function HighlightsPreviewToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'contentInteractionStateId': value.contentInteractionStateId,
        'firstHighlightCreatedAt': (value.firstHighlightCreatedAt.toISOString()),
        'highlights': (value.highlights.map(HighlightToJSON)),
        'title': value.title,
    };
}
