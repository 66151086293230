import React from 'react';
import {css} from '@emotion/react';
import {
  BREAKPOINT_MEDIUM,
  COLOR_SANTA_A,
  COLOR_SANTA_BD,
  COLOR_SANTA_DA,
  COLOR_SANTA_DD,
  COLOR_SPACE_BLUE_WHITE_3,
  LINE_HEIGHT_BODY3,
  SHADOW_A_1_DOWN,
} from '@riiid/design-system';
import {Typography} from '@santa-web/service-ui';
import {formatSeconds} from '@app/utils/text';

type FlashDealTimerOwnProps = {
  remainedTimeMs: number;
};

type FlashDealTimerProps = FlashDealTimerOwnProps & React.HTMLAttributes<HTMLButtonElement>;

const FlashDealTimer = ({remainedTimeMs, ...rest}: FlashDealTimerProps) => {
  const [hours, minutes, seconds] = ['hht', 'mm', 'ss'].map(timeString => {
    return formatSeconds(Math.floor(remainedTimeMs / 1000), timeString)
      .split('')
      .map(Number);
  });

  const {backgroundColor, color} = React.useMemo(() => {
    if (remainedTimeMs < 24 * 60 * 60 * 1000) {
      return {
        backgroundColor: COLOR_SANTA_DA,
        color: COLOR_SANTA_DD,
      };
    }
    return {
      backgroundColor: COLOR_SPACE_BLUE_WHITE_3,
      color: COLOR_SANTA_BD,
    };
  }, [remainedTimeMs]);

  return (
    <button
      css={css`
        cursor: pointer;

        display: flex;
        justify-content: center;
        align-items: center;

        padding: 4px 16px;
        border: none;
        border-radius: 9999px;
        background-color: ${COLOR_SANTA_A};
        box-shadow: ${SHADOW_A_1_DOWN};

        @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
          width: 100%;
          max-width: ${BREAKPOINT_MEDIUM};
        }
      `}
      {...rest}>
      <Typography
        css={css`
          display: flex;
          align-items: center;
          white-space: pre;
          & > span {
            color: ${color};
          }
        `}
        variant="body3"
        fontWeight="bold">
        추천 학습 <span>무료 혜택</span>{' '}
        <span
          aria-hidden
          css={css`
            display: flex;
            margin: 0 4px;
            padding: 0 8px;
            border-radius: 9999px;
            background-color: ${backgroundColor};
          `}>
          {hours.map((hour, idx) => (
            <TimeFragment key={idx} value={hour} max={9} min={0} />
          ))}
          {' : '}
          {minutes.map((minute, idx) => (
            <TimeFragment key={idx} value={minute} max={idx === 0 ? 5 : 9} min={0} />
          ))}
          {' : '}
          {seconds.map((second, idx) => (
            <TimeFragment key={idx} value={second} max={idx === 0 ? 5 : 9} min={0} />
          ))}
        </span>
        <span
          css={css`
            opacity: 0;
            width: 0;
            height: 0;
          `}>
          {hours.join('')}:{minutes.join('')}:{seconds.join('')}
        </span>{' '}
        후 <span>종료</span>
      </Typography>
    </button>
  );
};

type TimeFragmentProps = {
  value: number;
  max: number;
  min: number;
};

const TimeFragment = ({value, max, min}: TimeFragmentProps) => {
  const items = React.useMemo(() => [max, ...Array.from({length: max - min + 1}, (_, i) => i + min)], [max, min]);
  const [currentIdx, setCurrentIdx] = React.useState(items.indexOf(value));

  React.useEffect(() => {
    setCurrentIdx(items.indexOf(value));
  }, [items, value]);

  return (
    <span
      css={css`
        height: ${LINE_HEIGHT_BODY3};
        overflow: hidden;
      `}>
      <span
        onTransitionEnd={() => {
          if (currentIdx === 0) {
            setCurrentIdx(items.length - 1);
          }
        }}
        css={[
          css`
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            transform: translateY(calc(-1 * ${currentIdx} * ${LINE_HEIGHT_BODY3}));
          `,
          currentIdx !== items.length - 1 &&
            css`
              transition: transform 0.3s;
            `,
        ]}>
        {items.map((item, idx) => (
          <span key={idx}>{item}</span>
        ))}
      </span>
    </span>
  );
};

export default FlashDealTimer;
export type {FlashDealTimerProps};
FlashDealTimer.displayName = 'FlashDealTimer';
