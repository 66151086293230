import {css} from '@emotion/react';
import {COLOR_SANTA_A} from '@riiid/design-system';
import {ALERT_DIALOG_Z_INDEX} from '@app/constants/zIndex';
import {useDialogContext} from '@app/contexts/DialogContext';
import {GiftCodeView, GiftCodeViewOwnProps} from '@app/features/permit';

export const useGiftCodeModal = () => {
  const {openDialog, closeDialog} = useDialogContext();

  const openGiftCodeModal = (props: Omit<GiftCodeViewOwnProps, 'onClose'>) => {
    openDialog(
      <GiftCodeView
        css={css`
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          z-index: ${ALERT_DIALOG_Z_INDEX};
          background-color: ${COLOR_SANTA_A};
        `}
        {...props}
        onClose={closeDialog}
      />
    );
  };

  return {
    openGiftCodeModal,
  };
};
