import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {
  COLOR_RIIID_DOBH,
  COLOR_RIIID_HZBH,
  COLOR_RIIID_SECONDARY_GC,
  COLOR_SANTA_BC,
  COLOR_SANTA_BD,
  COLOR_SANTA_E,
  COLOR_SANTA_G,
  COLOR_SANTA_H,
  COLOR_SANTA_J,
  COLOR_SANTA_L,
} from '@riiid/design-system';
import CheckIcon from '@riiid/design-system/icons/Check';
import ChevronMiniRightIcon from '@riiid/design-system/icons/ChevronMiniRight';
import CouponIcon from '@riiid/design-system/icons/Coupon';
import DiscountIcon from '@riiid/design-system/icons/Discount';
import EventIcon from '@riiid/design-system/icons/Event';
import FlagIcon from '@riiid/design-system/icons/Flag';
import LetterNIcon from '@riiid/design-system/icons/LetterN';
import PaperIcon from '@riiid/design-system/icons/Paper';
import PerksIcon from '@riiid/design-system/icons/Perks';
import SpecialOfferIcon from '@riiid/design-system/icons/SpecialOffer';
import StatsIcon from '@riiid/design-system/icons/Stats';
import TestReportIcon from '@riiid/design-system/icons/Testreport';
import TimeIcon from '@riiid/design-system/icons/Time';
import UserIcon from '@riiid/design-system/icons/User';
import {Button, SwitchToggle, Typography} from '@santa-web/service-ui';

export enum IconVariant {
  PREDICTION_SCORE = 'PREDICTION_SCORE',
  TARGET_SCORE = 'TARGET_SCORE',
  STUDY_GOAL = 'STUDY_GOAL',
  EXAM_SCORE = 'EXAM_SCORE',
  CALENDAR = 'CALENDAR',
  ACCOUNT_INFO = 'ACCOUNT_INFO',
  PURCHASE_LIST = 'PURCHASE_LIST',
  PERMIT_LIST = 'PERMIT_LIST',
  PRODUCT_BOARD = 'PRODUCT_BOARD',
  COUPON_BOX = 'COUPON_BOX',
  EVENT = 'EVENT',
}

const getIcon = (icon?: IconVariant) => {
  switch (icon) {
    case IconVariant.PREDICTION_SCORE:
      return StatsIcon;
    case IconVariant.TARGET_SCORE:
      return FlagIcon;
    case IconVariant.STUDY_GOAL:
      return TimeIcon;
    case IconVariant.EXAM_SCORE:
      return TestReportIcon;
    case IconVariant.CALENDAR:
      return EventIcon;
    case IconVariant.ACCOUNT_INFO:
      return UserIcon;
    case IconVariant.PURCHASE_LIST:
      return PaperIcon;
    case IconVariant.PERMIT_LIST:
      return SpecialOfferIcon;
    case IconVariant.PRODUCT_BOARD:
      return CouponIcon;
    case IconVariant.COUPON_BOX:
      return DiscountIcon;
    case IconVariant.EVENT:
      return PerksIcon;
    default:
      return null;
  }
};
const getIconProps = (icon?: IconVariant): {type?: 'line' | 'solid'; color: string} => {
  switch (icon) {
    case IconVariant.PREDICTION_SCORE:
      return {
        type: 'solid',
        color: COLOR_RIIID_DOBH,
      };
    case IconVariant.TARGET_SCORE:
      return {
        type: 'solid',
        color: COLOR_RIIID_SECONDARY_GC,
      };
    case IconVariant.STUDY_GOAL:
      return {
        type: 'solid',
        color: COLOR_RIIID_SECONDARY_GC,
      };
    case IconVariant.EXAM_SCORE:
      return {
        type: 'solid',
        color: COLOR_RIIID_HZBH,
      };
    case IconVariant.CALENDAR:
      return {
        type: 'solid',
        color: COLOR_SANTA_BC,
      };
    case IconVariant.ACCOUNT_INFO:
    case IconVariant.PURCHASE_LIST:
    case IconVariant.PERMIT_LIST:
    case IconVariant.PRODUCT_BOARD:
    case IconVariant.COUPON_BOX:
    case IconVariant.EVENT:
    default:
      return {
        type: 'line',
        color: COLOR_SANTA_L,
      };
  }
};

type PreferenceListItemBaseAction = {
  type: string;
  onClick?: React.MouseEventHandler;
};

type PreferenceListItemChevronAction = PreferenceListItemBaseAction & {
  type: 'chevron';
  disabled?: boolean;
};

type PreferenceListItemSwitchAction = PreferenceListItemBaseAction & {
  type: 'switch';
  isLoading?: boolean;
  value: boolean;
};

type PreferenceListItemCheckAction = PreferenceListItemBaseAction & {
  type: 'check';
  value: boolean;
};

type PreferenceListItemButtonAction = PreferenceListItemBaseAction & {
  type: 'button';
  isLoading?: boolean;
  value: string;
};

type PreferenceListItemProps = {
  icon?: IconVariant;
  title: {
    value: string;
    variant?: 'body2' | 'body3';
    fontWeight?: 'regular' | 'bold';
  };
  description?: {
    value: string;
    colorVariant?: 'brand' | 'black';
    subText?: string;
  };
  hintText?: {
    value: string;
    colorVariant?: 'brand' | 'gray' | 'black';
    fontWeight?: 'regular' | 'bold';
  };
  isNew?: boolean;
  isLoading?: boolean;
  isDisabled?: boolean;
  isActionable?: boolean;
  action?:
    | PreferenceListItemChevronAction
    | PreferenceListItemSwitchAction
    | PreferenceListItemCheckAction
    | PreferenceListItemButtonAction;
};

const PreferenceListItem = ({
  icon,
  title: {value: title, variant: titleVariant = 'body2', fontWeight: titleFontWeight = 'regular'},
  description,
  hintText,
  isNew = false,
  isLoading = false,
  isDisabled = false,
  action,
}: PreferenceListItemProps) => {
  const {t} = useTranslation();
  const actionableProps: React.HTMLAttributes<HTMLDivElement> | undefined = React.useMemo(() => {
    if (action == null) {
      return undefined;
    }
    if (action.type === 'button') {
      return undefined;
    }
    if (action.type === 'chevron' && action.disabled) {
      return undefined;
    }
    return {onClick: isDisabled ? undefined : action.onClick, tabIndex: 0, role: 'button'};
  }, [action, isDisabled]);

  const Icon = getIcon(icon);
  const actionComponent = React.useMemo(() => {
    if (!action) {
      return null;
    }
    switch (action.type) {
      case 'chevron':
        return <ChevronMiniRightIcon width={24} height={24} color={action.disabled ? COLOR_SANTA_E : COLOR_SANTA_L} />;
      case 'check':
        return <CheckIcon width={24} height={24} color={action.value ? COLOR_SANTA_BD : 'transparent'} />;
      case 'switch':
        return <SwitchToggle isChecked={action.value} isDisabled={action.isLoading} />;
      case 'button':
        return (
          <Button colorVariant="grey" sizeVariant="small" onClick={action.onClick} isLoading={action.isLoading}>
            {action.value}
          </Button>
        );
    }
  }, [action]);

  return (
    <div
      css={[
        css`
          background-color: transparent;
          display: flex;
          flex-direction: column;
          gap: 8px;
        `,
        actionableProps
          ? isDisabled
            ? css`
                cursor: not-allowed;
                opacity: 0.2;
              `
            : css`
                cursor: pointer;
                @media (hover: hover) {
                  &:hover:not(:active) {
                    opacity: 0.6;
                  }
                }
              `
          : undefined,
      ]}
      {...actionableProps}>
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
          gap: 20px;
        `}>
        <div
          css={css`
            display: flex;
            align-items: center;
          `}>
          {Icon && (
            <Icon
              css={css`
                margin-right: 12px;
              `}
              {...getIconProps(icon)}
              width={24}
              height={24}
            />
          )}
          <Typography variant={titleVariant} fontWeight={titleFontWeight} color={COLOR_SANTA_L}>
            {title}
          </Typography>
          {isNew && (
            <LetterNIcon
              css={css`
                margin-left: 4px;
              `}
              width={24}
              height={24}
              color={COLOR_SANTA_BC}
            />
          )}
        </div>
        <div
          css={css`
            display: flex;
            align-items: center;
            gap: 4px;
          `}>
          {hintText && (
            <Typography
              variant="body3"
              color={
                hintText.colorVariant === 'brand'
                  ? COLOR_SANTA_BD
                  : hintText.colorVariant === 'gray'
                  ? COLOR_SANTA_G
                  : COLOR_SANTA_L
              }
              fontWeight={hintText.fontWeight}>
              {hintText.value}
            </Typography>
          )}
          {actionComponent}
        </div>
      </div>
      {isLoading ? (
        <Typography component="p" variant="caption1" color={COLOR_SANTA_H}>
          {t('component_preference_list_item_loading')}
        </Typography>
      ) : (
        description && (
          <div>
            <Typography
              component="p"
              variant="caption1"
              color={description.colorVariant === 'brand' ? COLOR_SANTA_BD : COLOR_SANTA_J}>
              {description.value}
            </Typography>
            {description.subText && (
              <Typography
                css={css`
                  margin-top: 4px;
                `}
                component="p"
                variant="caption1"
                color={COLOR_SANTA_H}>
                {description.subText}
              </Typography>
            )}
          </div>
        )
      )}
    </div>
  );
};

export default PreferenceListItem;
export type {PreferenceListItemProps};
PreferenceListItem.displayName = 'PreferenceListItem';
