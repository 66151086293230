/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
/**
 * Check if a given object implements the PaymentData interface.
 */
export function instanceOfPaymentData(value) {
    let isInstance = true;
    isInstance = isInstance && "billId" in value;
    return isInstance;
}
export function PaymentDataFromJSON(json) {
    return PaymentDataFromJSONTyped(json, false);
}
export function PaymentDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'billId': json['billId'],
        'stripePaymentUrl': !exists(json, 'stripePaymentUrl') ? undefined : json['stripePaymentUrl'],
    };
}
export function PaymentDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'billId': value.billId,
        'stripePaymentUrl': value.stripePaymentUrl,
    };
}
