import {useQuery} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';

import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {getDiagnosisReportQueryKey} from '@app/queryKeys';

const useDiagnosisReportQuery = () => {
  const {DiagnosisReportService} = useAtomValue(santaOpenapiServicesAtom);

  return useQuery(getDiagnosisReportQueryKey(), async () => {
    const {diagnosisReport} = await DiagnosisReportService.getDiagnosisReport({});
    return diagnosisReport;
  });
};

export default useDiagnosisReportQuery;
