import {Currency} from '@santa-web/gen/open-api/service';

import {JpPriceFormatter} from './JpPriceFormatter';
import {KrPriceFormatter} from './KrPriceFormatter';
import {PriceFormatter} from './PriceFormatter';
import {ThPriceFormatter} from './ThPriceFormatter';
import {TwPriceFormatter} from './TwPriceFormatter';
import {UsPriceFormatter} from './UsPriceFormatter';
import {VnPriceFormatter} from './VnPriceFormatter';

export const createPriceFormatter = (currency: Currency): PriceFormatter => {
  switch (currency) {
    case 'KRW': {
      return new KrPriceFormatter();
    }
    case 'JPY': {
      return new JpPriceFormatter();
    }
    case 'USD': {
      return new UsPriceFormatter();
    }
    case 'VND': {
      return new VnPriceFormatter();
    }
    case 'TWD': {
      return new TwPriceFormatter();
    }
    case 'THB': {
      return new ThPriceFormatter();
    }
  }
};
