import React from 'react';
import {BrowserStorage} from '@santa-web/service-common';

/**
 * NOTE: iOS 단건 결제 시 요구되는 receipt data가 쿼리 파라미터로 전달할 수 없는 길이이기 때문에 session storage를 이용함
 */
export const useStoreTransactionData = () => {
  const browserStorage = React.useMemo(() => new BrowserStorage<{storeTransactionData: string}>(sessionStorage), []);

  return {
    set: (data: string) => {
      browserStorage.setItem('storeTransactionData', data);
    },
    get: () => {
      return browserStorage.getItem('storeTransactionData');
    },
    clear: () => {
      browserStorage.removeItem('storeTransactionData');
    },
  };
};
