import { EstimatedTagProficienciesWithTargetFromJSON, EstimatedTagProficienciesWithTargetToJSON, } from './EstimatedTagProficienciesWithTarget';
import { ToeicVirtualExamReportElapsedTimeStatFromJSON, ToeicVirtualExamReportElapsedTimeStatToJSON, } from './ToeicVirtualExamReportElapsedTimeStat';
import { ToeicVirtualExamReportPartCorrectStatFromJSON, ToeicVirtualExamReportPartCorrectStatToJSON, } from './ToeicVirtualExamReportPartCorrectStat';
import { ToeicVirtualExamReportWeakTagInfoFromJSON, ToeicVirtualExamReportWeakTagInfoToJSON, } from './ToeicVirtualExamReportWeakTagInfo';
/**
 * Check if a given object implements the ToeicVirtualExamReport interface.
 */
export function instanceOfToeicVirtualExamReport(value) {
    let isInstance = true;
    isInstance = isInstance && "elapsedTimeStat" in value;
    isInstance = isInstance && "lcScore" in value;
    isInstance = isInstance && "lcWeakTagInfos" in value;
    isInstance = isInstance && "partCorrectStat" in value;
    isInstance = isInstance && "rcScore" in value;
    isInstance = isInstance && "rcWeakTagInfos" in value;
    isInstance = isInstance && "skillCorrectRates" in value;
    isInstance = isInstance && "unansweredCount" in value;
    return isInstance;
}
export function ToeicVirtualExamReportFromJSON(json) {
    return ToeicVirtualExamReportFromJSONTyped(json, false);
}
export function ToeicVirtualExamReportFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'elapsedTimeStat': ToeicVirtualExamReportElapsedTimeStatFromJSON(json['elapsedTimeStat']),
        'lcScore': json['lcScore'],
        'lcWeakTagInfos': (json['lcWeakTagInfos'].map(ToeicVirtualExamReportWeakTagInfoFromJSON)),
        'partCorrectStat': ToeicVirtualExamReportPartCorrectStatFromJSON(json['partCorrectStat']),
        'rcScore': json['rcScore'],
        'rcWeakTagInfos': (json['rcWeakTagInfos'].map(ToeicVirtualExamReportWeakTagInfoFromJSON)),
        'skillCorrectRates': EstimatedTagProficienciesWithTargetFromJSON(json['skillCorrectRates']),
        'unansweredCount': json['unansweredCount'],
    };
}
export function ToeicVirtualExamReportToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'elapsedTimeStat': ToeicVirtualExamReportElapsedTimeStatToJSON(value.elapsedTimeStat),
        'lcScore': value.lcScore,
        'lcWeakTagInfos': (value.lcWeakTagInfos.map(ToeicVirtualExamReportWeakTagInfoToJSON)),
        'partCorrectStat': ToeicVirtualExamReportPartCorrectStatToJSON(value.partCorrectStat),
        'rcScore': value.rcScore,
        'rcWeakTagInfos': (value.rcWeakTagInfos.map(ToeicVirtualExamReportWeakTagInfoToJSON)),
        'skillCorrectRates': EstimatedTagProficienciesWithTargetToJSON(value.skillCorrectRates),
        'unansweredCount': value.unansweredCount,
    };
}
