import {useCallback} from 'react';
import TagManager from 'react-gtm-module';
import {useAtomValue} from 'jotai';

import {mobileService} from '@app/api/app-bridge/mobile-service';
import tokenManagerAtom from '@app/atoms/core/token-manager';
import {isAppBridgeAvailable} from '@app/utils/app-bridge';

// GA4 Event Database
// https://www.notion.so/riiid/dc62ffc2b3634bf38f0bbbd512218f5f?v=78544cffd15d4dad8766d26f7d5ac591

export const usePushEvent = () => {
  const tokenManager = useAtomValue(tokenManagerAtom);

  const pushEvent = useCallback(
    async <T extends Record<string, unknown>>(eventName: string, payload?: T) => {
      if (isAppBridgeAvailable()) {
        // With the app bridge, send the GA4 event to native app
        // NOTE: 모바일을 거치는 이유
        // 1. GA 뿐만 아니라, 모바일 앱에 세팅된 Braze, Appsflyer에도 같은 이벤트를 로깅해야 함
        // 2. Firebase SDK가 이벤트에 A/B 테스트 관련 property를 자동으로 추가하는 역할을 함
        await mobileService.sendEventTracking(eventName, JSON.stringify(payload ?? {}));
      } else {
        const userId = (await tokenManager.getToken())?.authId;

        TagManager.dataLayer({
          dataLayer: {event: eventName, payload, userId},
        });
      }
    },
    [tokenManager]
  );

  return pushEvent;
};

export const usePushScreenViewEvent = () => {
  const pushScreenViewEvent = useCallback(async (screenName: string, payload: Record<string, unknown>) => {
    /**
     * NOTE: In PC web(=not webview), it does not need to send 'screen_view' event,
     * because 'page_view' event is triggered in PC web when history changes.
     * see: https://analytics.google.com/analytics/web/#/p152900420/reports/dashboard?params=_u..nav%3Dmaui%26_u..comparisons%3D%5B%7B%22name%22:%22All%20Users%22,%22filters%22:%5B%7B%22isCaseSensitive%22:true,%22expression%22:%220%22,%22fieldName%22:%22audience%22%7D%5D%7D%5D%26_r..dimension-value%3D%7B%22dimension%22:%22eventName%22,%22value%22:%22page_view%22%7D&r=events-overview-page-view&collectionId=life-cycle
     */
    if (isAppBridgeAvailable()) {
      await mobileService.sendEventTracking(
        'screen_view',
        JSON.stringify({
          ...payload,
          screen_name: screenName,
          screen_class: screenName,
        })
      );
    }
  }, []);

  return pushScreenViewEvent;
};
