/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { ListLearningHistoriesQueryResultFromJSON, ListLearningHistoriesQueryResultToJSON, } from './ListLearningHistoriesQueryResult';
/**
 * Check if a given object implements the ListLearningHistoriesResponse interface.
 */
export function instanceOfListLearningHistoriesResponse(value) {
    let isInstance = true;
    return isInstance;
}
export function ListLearningHistoriesResponseFromJSON(json) {
    return ListLearningHistoriesResponseFromJSONTyped(json, false);
}
export function ListLearningHistoriesResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'queryResult': !exists(json, 'queryResult') ? undefined : ListLearningHistoriesQueryResultFromJSON(json['queryResult']),
    };
}
export function ListLearningHistoriesResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'queryResult': ListLearningHistoriesQueryResultToJSON(value.queryResult),
    };
}
