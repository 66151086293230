import { LearningGoalFromJSON, LearningGoalToJSON, } from './LearningGoal';
/**
 * Check if a given object implements the GetLearningGoalResponse interface.
 */
export function instanceOfGetLearningGoalResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "learningGoal" in value;
    return isInstance;
}
export function GetLearningGoalResponseFromJSON(json) {
    return GetLearningGoalResponseFromJSONTyped(json, false);
}
export function GetLearningGoalResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'learningGoal': LearningGoalFromJSON(json['learningGoal']),
    };
}
export function GetLearningGoalResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'learningGoal': LearningGoalToJSON(value.learningGoal),
    };
}
