import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';

import {AlertProps, AlertDialog, AlertDialogProps, SecondPageLayoutTutorialAnimation} from '@santa-web/service-ui';
import {useDialogContext} from '@app/contexts/DialogContext';

export type SecondPageLayoutTutorialAlertDialogProps = AlertDialogProps;

export default React.memo<SecondPageLayoutTutorialAlertDialogProps>(
  React.forwardRef<HTMLDivElement, SecondPageLayoutTutorialAlertDialogProps>(
    function SecondPageLayoutTutorialAlertDialog({...props}, ref) {
      const {t} = useTranslation();

      const {closeDialog} = useDialogContext();

      const AlertProps = useMemo(
        (): AlertProps => ({
          image: <SecondPageLayoutTutorialAnimation />,
          title: t('tutorial_second_page_layout_title'),
          description: t('tutorial_second_page_layout_description'),
          neutralLabel: t('tutorial_second_page_layout_neutral_label'),
          onNeutralClick: closeDialog,
          css: css`
            white-space: pre-line;
          `,
        }),
        [t, closeDialog]
      );

      return <AlertDialog ref={ref} AlertProps={AlertProps} isVisible {...props} />;
    }
  )
);
