/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
/**
 * Check if a given object implements the CheckNewOfferGroupResponse interface.
 */
export function instanceOfCheckNewOfferGroupResponse(value) {
    let isInstance = true;
    return isInstance;
}
export function CheckNewOfferGroupResponseFromJSON(json) {
    return CheckNewOfferGroupResponseFromJSONTyped(json, false);
}
export function CheckNewOfferGroupResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'mostRecentStartsAt': !exists(json, 'mostRecentStartsAt') ? undefined : (new Date(json['mostRecentStartsAt'])),
    };
}
export function CheckNewOfferGroupResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'mostRecentStartsAt': value.mostRecentStartsAt === undefined ? undefined : (value.mostRecentStartsAt.toISOString()),
    };
}
