import {usePushEvent} from '.';

type CommonPayload = {
  mkt_bottomsheet_id: number;
};

const useMarketingBottomSheetEvents = () => {
  const pushEvent = usePushEvent();

  return {
    pushMarketingBottomSheetNotTodayClick: (payload: CommonPayload) => {
      pushEvent('marketing_popup_banner_not_today', payload);
    },

    pushMarketingBottomSheetDetailClick: (payload: CommonPayload) => {
      pushEvent('marketing_popup_banner_detail', payload);
    },

    pushMarketingBottomSheetCloseClick: (payload: CommonPayload) => {
      pushEvent('marketing_popup_banner_close', payload);
    },

    // 노출 이벤트
    pushMarketingBottomSheetDisplay: (payload: CommonPayload) => {
      pushEvent('marketing_popup_banner', payload);
    },
  };
};

export default useMarketingBottomSheetEvents;
