import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {URL_GIF_ALERTIMAGE_TUTORIAL_HIGHLIGHT} from '@riiid/design-system/lotties/santa';

import {AlertProps, AlertDialog, AlertDialogProps, LottieAnimation} from '@santa-web/service-ui';
import {useDialogContext} from '@app/contexts/DialogContext';

export type HighlightTutorialAlertDialogProps = AlertDialogProps;

export default React.memo<HighlightTutorialAlertDialogProps>(
  React.forwardRef<HTMLDivElement, HighlightTutorialAlertDialogProps>(({...props}, ref) => {
    const {t} = useTranslation();
    const {closeDialog} = useDialogContext();
    const AlertProps = useMemo(
      (): AlertProps => ({
        image: <LottieAnimation src={URL_GIF_ALERTIMAGE_TUTORIAL_HIGHLIGHT} />,
        title: t('highlight_tutorial_alert_title'),
        description: t('highlight_tutorial_alert_description'),
        neutralLabel: t('highlight_tutorial_alert_label'),
        onNeutralClick: closeDialog,
        css: css`
          white-space: pre-line;
        `,
      }),
      [closeDialog, t]
    );

    return <AlertDialog ref={ref} AlertProps={AlertProps} isVisible {...props} />;
  })
);
