import React from 'react';
import {css} from '@emotion/react';
import {BREAKPOINT_MEDIUM} from '@riiid/design-system';
import {useAtomValue} from 'jotai';
import ContentsLevelInformation, {
  ContentsLevel,
} from '@app/experiments/contents-level-choice-rate/components/ContentsLevelInformation';
import {LEVEL_CHOICE_AB_TEST_KEY_VALUE_STORAGE_TYPE} from '@app/experiments/contents-level-choice-rate/constants';
import useIsContentLevelChoiceRateAvailable from '@app/experiments/contents-level-choice-rate/hooks/useIsContentLevelChoiceRateAvailable';
import {getFlooredScore, isContentMetadata} from '@app/experiments/contents-level-choice-rate/utils';
import useUserProfile from '@app/hooks/user/useUserProfile';
import useServerStorageQuery from '@app/hooks/useServerStorageQuery';
import {learningStore} from '@app/test-preparation/atoms/stores';
import {hasIntersection} from '@app/utils/array';

type ContentsLevelInformationContainerProps = {questionIndex: number};

const THRESHOLDS: Record<ContentsLevel, readonly [number, number]> = {
  [ContentsLevel.VERY_EASY]: [0.9, 1.1],
  [ContentsLevel.EASY]: [0.75, 0.9],
  [ContentsLevel.NORMAL]: [0.65, 0.75],
  [ContentsLevel.HARD]: [0.5, 0.65],
  [ContentsLevel.VERY_HARD]: [0, 0.5],
} as const;

const ContentsLevelInformationContainer = ({questionIndex}: ContentsLevelInformationContainerProps) => {
  const contentPartList = useAtomValue(learningStore.content.partsAtom);
  const contentId = useAtomValue(learningStore.content.idAtom);
  const learningDomain = useAtomValue(learningStore.content.domainAtom);
  const eises = useAtomValue(learningStore.cis.eisesAtom);
  const currentEis = eises[questionIndex];
  const {
    contentElementId: questionId,
    details: {question: questionState},
  } = currentEis;

  const {data: profile} = useUserProfile();
  const isToeicContent = learningDomain === 'TOEIC';
  const doesContentHaveSplitView = isToeicContent && hasIntersection(['p6', 'p7'], contentPartList ?? []);

  const isContentsLevelInformationAvailable =
    useIsContentLevelChoiceRateAvailable() && questionState != null && profile?.currentEstimatedScore != null;

  const {data: storageData} = useServerStorageQuery(LEVEL_CHOICE_AB_TEST_KEY_VALUE_STORAGE_TYPE, contentId);
  const contentMetadata = storageData?.value;

  const contentsLevel = React.useMemo(() => {
    if (
      !isContentsLevelInformationAvailable ||
      profile?.currentEstimatedScore == null ||
      contentMetadata == null ||
      !isContentMetadata(contentMetadata)
    ) {
      return null;
    }
    try {
      const currentScoreRange = getFlooredScore(profile.currentEstimatedScore);
      const contentCorrectionRate = contentMetadata[questionId].correctionRateMap[currentScoreRange];
      const targetLevel = Object.entries(THRESHOLDS).find(
        ([, correctionRateRange]) =>
          contentCorrectionRate >= correctionRateRange[0] && contentCorrectionRate < correctionRateRange[1]
      );
      return targetLevel ? (targetLevel[0] as ContentsLevel) : null;
    } catch (e) {
      return null;
    }
  }, [contentMetadata, isContentsLevelInformationAvailable, profile, questionId]);

  return isContentsLevelInformationAvailable && contentsLevel ? (
    <div
      css={[
        css`
          margin: -20px 0 24px;
        `,
        doesContentHaveSplitView &&
          css`
            margin: 0 0 24px;
            @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
              margin: -20px 0 40px;
            }
          `,
      ]}>
      <ContentsLevelInformation isCorrect={questionState.answerState === 'CORRECT'} level={contentsLevel} />
    </div>
  ) : null;
};

export default ContentsLevelInformationContainer;
export type {ContentsLevelInformationContainerProps};
ContentsLevelInformationContainer.displayName = 'ContentsLevelInformationContainer';
