/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { CouponDiscountFromJSON, CouponDiscountToJSON, } from './CouponDiscount';
import { CurrencyFromJSON, CurrencyToJSON, } from './Currency';
/**
 * Check if a given object implements the AmountData interface.
 */
export function instanceOfAmountData(value) {
    let isInstance = true;
    isInstance = isInstance && "currency" in value;
    isInstance = isInstance && "offerDefaultAmount" in value;
    isInstance = isInstance && "offerDiscountAmount" in value;
    isInstance = isInstance && "totalChargeAmount" in value;
    return isInstance;
}
export function AmountDataFromJSON(json) {
    return AmountDataFromJSONTyped(json, false);
}
export function AmountDataFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'couponDiscounts': !exists(json, 'couponDiscounts') ? undefined : (json['couponDiscounts'].map(CouponDiscountFromJSON)),
        'currency': CurrencyFromJSON(json['currency']),
        'offerDefaultAmount': json['offerDefaultAmount'],
        'offerDiscountAmount': json['offerDiscountAmount'],
        'totalChargeAmount': json['totalChargeAmount'],
    };
}
export function AmountDataToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'couponDiscounts': value.couponDiscounts === undefined ? undefined : (value.couponDiscounts.map(CouponDiscountToJSON)),
        'currency': CurrencyToJSON(value.currency),
        'offerDefaultAmount': value.offerDefaultAmount,
        'offerDiscountAmount': value.offerDiscountAmount,
        'totalChargeAmount': value.totalChargeAmount,
    };
}
