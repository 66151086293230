import { SelfLearningUnitGroupFilterFromJSON, SelfLearningUnitGroupFilterToJSON, } from './SelfLearningUnitGroupFilter';
/**
 * Check if a given object implements the GetSelfLearningUnitGroupFiltersResponse interface.
 */
export function instanceOfGetSelfLearningUnitGroupFiltersResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "filters" in value;
    return isInstance;
}
export function GetSelfLearningUnitGroupFiltersResponseFromJSON(json) {
    return GetSelfLearningUnitGroupFiltersResponseFromJSONTyped(json, false);
}
export function GetSelfLearningUnitGroupFiltersResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'filters': (json['filters'].map(SelfLearningUnitGroupFilterFromJSON)),
    };
}
export function GetSelfLearningUnitGroupFiltersResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'filters': (value.filters.map(SelfLearningUnitGroupFilterToJSON)),
    };
}
