function _array_like_to_array(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _array_with_holes(arr) {
    if (Array.isArray(arr)) return arr;
}
function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _iterable_to_array_limit(arr, i) {
    var _i = arr == null ? null : typeof Symbol !== "undefined" && arr[Symbol.iterator] || arr["@@iterator"];
    if (_i == null) return;
    var _arr = [];
    var _n = true;
    var _d = false;
    var _s, _e;
    try {
        for(_i = _i.call(arr); !(_n = (_s = _i.next()).done); _n = true){
            _arr.push(_s.value);
            if (i && _arr.length === i) break;
        }
    } catch (err) {
        _d = true;
        _e = err;
    } finally{
        try {
            if (!_n && _i["return"] != null) _i["return"]();
        } finally{
            if (_d) throw _e;
        }
    }
    return _arr;
}
function _non_iterable_rest() {
    throw new TypeError("Invalid attempt to destructure non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function _sliced_to_array(arr, i) {
    return _array_with_holes(arr) || _iterable_to_array_limit(arr, i) || _unsupported_iterable_to_array(arr, i) || _non_iterable_rest();
}
function _unsupported_iterable_to_array(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _array_like_to_array(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _array_like_to_array(o, minLen);
}
// pre-condition: node (that has leafNodeId) is overlap with highlight in somewhere
export var getSegment = function(length, leafNodeId, highlight) {
    var anchorNodeId = highlight.anchorNodeId, focusNodeId = highlight.focusNodeId, anchor = highlight.anchor, focus = highlight.focus;
    var isAnchorFirst = function() {
        if (anchorNodeId === focusNodeId) {
            return anchor.offset <= focus.offset;
        }
        return anchorNodeId < focusNodeId;
    };
    var _ref = _sliced_to_array(isAnchorFirst() ? [
        anchor,
        focus
    ] : [
        focus,
        anchor
    ], 2), leftNode = _ref[0], rightNode = _ref[1];
    var _ref1 = _sliced_to_array(isAnchorFirst() ? [
        anchorNodeId,
        focusNodeId
    ] : [
        focusNodeId,
        anchorNodeId
    ], 2), leftNodeId = _ref1[0], rightNodeId = _ref1[1];
    return {
        startOffset: leftNodeId != null && leftNodeId < leafNodeId ? 0 : leftNode.offset,
        endOffset: rightNodeId != null && leafNodeId < rightNodeId ? length : rightNode.offset,
        highlight: _object_spread({}, highlight)
    };
};
export var splitSegments = function(length, segments) {
    if (segments.length === 0) {
        return [
            {
                startOffset: 0,
                endOffset: length,
                highlights: []
            }
        ];
    }
    var uniqueSortedOffsets = function() {
        var uniqueOffsets = new Set(segments.flatMap(function(segment) {
            return [
                segment.startOffset,
                segment.endOffset
            ];
        }));
        uniqueOffsets.add(0).add(length);
        return Array.from(uniqueOffsets).sort(function(a, b) {
            return a - b;
        });
    }();
    var offsetToIndex = new Map(uniqueSortedOffsets.map(function(offset, i) {
        return [
            offset,
            i
        ];
    }));
    var result = [];
    {
        for(var i = 1; i < uniqueSortedOffsets.length; ++i){
            result.push({
                startOffset: uniqueSortedOffsets[i - 1],
                endOffset: uniqueSortedOffsets[i],
                highlights: []
            });
        }
    }
    var splitSegment = function(segment) {
        var l = offsetToIndex.get(segment.startOffset);
        var r = offsetToIndex.get(segment.endOffset);
        for(var i = l; i < r; ++i){
            result[i].highlights.push(segment.highlight);
        }
    };
    segments.forEach(splitSegment);
    return result;
};
// https://www.w3.org/TR/compositing-1/#porterduffcompositingoperators
export var compositeRgba = function(s, b) {
    var cs = [
        s[0],
        s[1],
        s[2]
    ];
    var as = s[3];
    var cb = [
        b[0],
        b[1],
        b[2]
    ];
    var ab = b[3];
    var co = [
        as * cs[0] + ab * cb[0],
        as * cs[1] + ab * cb[1],
        as * cs[2] + ab * cb[2]
    ];
    var ao = as + ab * (1 - as);
    // alpha 값이 1인 경우 b값 반환
    if (as === 1 || ab === 1) {
        return b;
    }
    return [
        co[0],
        co[1],
        co[2],
        ao
    ];
};
export var combineColors = function(colors) {
    if (colors.length === 0) return null;
    return colors.reduce(compositeRgba);
};
export var rgbaToCssString = function(rgba) {
    var _rgba = _sliced_to_array(rgba, 4), r = _rgba[0], g = _rgba[1], b = _rgba[2], a = _rgba[3];
    return "rgba(".concat(r, ", ").concat(g, ", ").concat(b, ", ").concat(a, ")");
};
export var filterHighlightVariants = function(highlightVariants, type) {
    return highlightVariants.filter(function(h) {
        return h.type === type;
    });
};
