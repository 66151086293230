import {useEffect, useMemo, useState} from 'react';

import {BrowserStorage} from '@santa-web/service-common';

const useShouldShowVocaTutorial = (type: string) => {
  const [shouldShowVocaTutorial, setShouldShowVocaTutorial] = useState(false);
  const storage = useMemo(() => new BrowserStorage<Record<string, boolean>>(localStorage), []);

  useEffect(() => {
    if (storage.getItem(type) === true) {
      setShouldShowVocaTutorial(false);
    } else {
      setShouldShowVocaTutorial(true);
      storage.setItem(type, true);
    }
  }, [storage, type]);

  return shouldShowVocaTutorial;
};

export default useShouldShowVocaTutorial;
