/* tslint:disable */
/* eslint-disable */
/**
 * - **NOT_EXISTS**: 가입되어 있지 않은 경우
 * - **OAUTH_ERROR**: OAuth client에서 오류가 발생한 경우
 * @export
 */
export const CommonOAuthLoginBadRequestErrorCase = {
    NOT_EXISTS: 'NOT_EXISTS',
    OAUTH_ERROR: 'OAUTH_ERROR'
};
export function CommonOAuthLoginBadRequestErrorCaseFromJSON(json) {
    return CommonOAuthLoginBadRequestErrorCaseFromJSONTyped(json, false);
}
export function CommonOAuthLoginBadRequestErrorCaseFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function CommonOAuthLoginBadRequestErrorCaseToJSON(value) {
    return value;
}
