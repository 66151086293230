import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {BREAKPOINT_MEDIUM, COLOR_SANTA_C, SHADOW_A_2_DOWN} from '@riiid/design-system';
import ChevronDownIcon from '@riiid/design-system/icons/ChevronDown';
import {URL_IMG_AVATAR_LUMI} from '@riiid/design-system/images/santa';
import {URL_GIF_LOADING_GRAY} from '@riiid/design-system/lotties/santa';

import {
  Button,
  ChatInput,
  ChatSantaBubble,
  ChatSantaBubbleFeedback,
  ChatUserBubble,
  FloatingChatButton,
  LottieAnimation,
  SinglePageLayout,
  SystemErrorLayout,
  TopNavBar,
  useToastContext,
} from '@santa-web/service-ui';
import {browserService} from '@app/api/app-bridge/browser-service';
import {FrameLayout} from '@app/components';
import ChatExitAlertDialog from '@app/components/alert-dialog/ChatExitAlertDialog';
import {use100vh} from '@app/hooks/use100vh';
import useIntersectionObserver from '@app/hooks/useIntersectionObserver';
import useMobileSafeArea from '@app/hooks/useMobileSafeArea';
import useUserAgent from '@app/hooks/useUserAgent';
import {isAndroid} from '@app/utils/user-agent';

type ChatType = 'SANTA' | 'USER';
type Chat = {
  isLoading?: boolean;
  isSelected?: boolean;
  isError?: boolean;
  id: number;
  type: ChatType;
  text?: string;
  feedback?: ChatSantaBubbleFeedback;
};

type ChatLayoutProps = {
  isError?: boolean;
  isOffline?: boolean;
  isLoading?: boolean;
  isChatLoadingDelayed?: boolean;
  isAutoSendError?: boolean;
  chats?: Array<Chat>;
  inputValue?: string;
  onInputChange?: (value: string) => void;
  onSend?: () => void;
  onResend?: (chat: Chat) => void;
  onRemove?: (chat: Chat) => void;
  onFeedback?: (chat: Chat, feedback: ChatSantaBubbleFeedback) => Promise<void>;
  onClose?: () => void;
  onRetry?: () => void;
};

const ChatLayout = React.forwardRef<HTMLDivElement, ChatLayoutProps>(
  (
    {
      isError,
      isOffline,
      isLoading,
      isChatLoadingDelayed,
      isAutoSendError,
      chats,
      inputValue,
      onInputChange,
      onSend,
      onResend,
      onRemove,
      onFeedback,
      onClose,
      onRetry,
      ...props
    },
    ref
  ) => {
    const viewportHeight = use100vh();
    const userAgent = useUserAgent();
    const {data: mobileSafeArea} = useMobileSafeArea();
    const {t} = useTranslation();
    const {toastContainer, openToast, closeToast} = useToastContext();

    const initialWindowHeight = React.useRef(window.innerHeight).current;
    const chatAreaRef = React.useRef<HTMLDivElement | null>(null);
    const lastChatRef = React.useRef<HTMLDivElement | null>(null);

    const [, forceRender] = React.useState([]);
    const [isExitAlertDialogOpened, setIsExitAlertDialogOpened] = React.useState(false);
    const [isLastChatVisible, setIsLastChatVisible] = React.useState(true);
    const [keyboardHeight, setKeyboardHeight] = React.useState(0);

    const hasLoadingChat = React.useMemo(() => chats?.some(chat => chat.isLoading), [chats]);
    const hasLoadingSantaChat = React.useMemo(
      () => chats?.some(chat => chat.isLoading && chat.type === 'SANTA'),
      [chats]
    );

    const chatLoadingText = React.useMemo(() => {
      return isChatLoadingDelayed ? t('chat_reply_delayed') : '';
    }, [isChatLoadingDelayed, t]);

    const createFeedbackHandler = React.useCallback(
      (chat: Chat) => async (feedback: ChatSantaBubbleFeedback) => {
        if (onFeedback) {
          await onFeedback(chat, feedback);

          openToast({
            colorVariant: 'gray',
            anchorOrigin: 'bottom',
            message: t('chat_feedback'),
          });
        }
      },
      [onFeedback, openToast, t]
    );

    const openTopWarningToast = React.useCallback(
      (message: string, autoHide: boolean) => {
        openToast({
          isFloatingType: false,
          colorVariant: 'gray',
          status: 'warning',
          anchorOrigin: 'top',
          message,
          autoHide,
        });
      },
      [openToast]
    );

    const scrollToBottom = React.useCallback(() => {
      if (chatAreaRef.current) {
        chatAreaRef.current.scrollTop = chatAreaRef.current.scrollHeight;
      }
    }, []);

    const handleExitAlertDialogClose = React.useCallback(() => {
      setIsExitAlertDialogOpened(false);
    }, []);

    const handleClose = React.useCallback(async () => {
      if (hasLoadingSantaChat) {
        setIsExitAlertDialogOpened(true);
      } else {
        onClose?.();
      }
    }, [hasLoadingSantaChat, onClose]);

    const handleScrollToBottom = React.useCallback(
      (event: React.UIEvent) => {
        event.preventDefault();
        scrollToBottom();
      },
      [scrollToBottom]
    );

    const handleInputHeightChange = React.useCallback(() => {
      scrollToBottom();
    }, [scrollToBottom]);

    const handleSend = React.useCallback(
      (event: React.UIEvent) => {
        event.preventDefault();
        onSend?.();
      },
      [onSend]
    );

    const measuredChatAreaRef = React.useCallback(
      (node: HTMLDivElement) => {
        if (node) {
          chatAreaRef.current = node;
          scrollToBottom();
        }
      },
      [scrollToBottom]
    );

    const measuredLastChatRef = React.useCallback(
      (node: HTMLDivElement) => {
        if (node) {
          scrollToBottom();

          /**
           * Ref의 변경은 React.useEffect에서 감지할 수 없으므로
           * forceRender를 통해 lastChatRef가 변경되었음을 강제로 알려준다
           */
          lastChatRef.current = node;
          forceRender([]);
        }
      },
      [scrollToBottom]
    );

    useIntersectionObserver({
      target: lastChatRef,
      threshold: 0,
      onIntersect: () => {
        setIsLastChatVisible(true);
      },
      offIntersect: entry => {
        if (!entry?.rootBounds) {
          return;
        }

        setIsLastChatVisible(false);
      },
    });

    React.useEffect(() => {
      const unsubscribe = browserService.subscribeBackButtonPress(handleClose);
      return unsubscribe;
    });

    React.useEffect(() => {
      if (isOffline) {
        openTopWarningToast(t('chat_network_error'), false);
      } else {
        closeToast();
      }
    }, [closeToast, isOffline, openTopWarningToast, t]);

    React.useEffect(() => {
      if (isAutoSendError) {
        openTopWarningToast(t('chat_auto_send_error'), true);
      }
    }, [closeToast, isAutoSendError, openTopWarningToast, t]);

    React.useEffect(() => {
      const handleResize = () => {
        const _nextKeyboardHeight = initialWindowHeight - (window.visualViewport?.height ?? 0);
        const nextKeyboardHeight = _nextKeyboardHeight <= 0 ? 0 : _nextKeyboardHeight;

        if (chatAreaRef.current) {
          const chatAreaElement = chatAreaRef.current;
          const scrollTop = chatAreaElement.scrollTop;
          const scrollHeight = chatAreaElement.scrollHeight;
          const clientHeight = chatAreaElement.clientHeight;

          /**
           * Mobile Keyboard와 SafeArea의 따라 스크롤 위치를 조정한다
           */
          setTimeout(() => {
            chatAreaElement.scrollTop =
              scrollTop +
              (nextKeyboardHeight ? -1 : 1) * (mobileSafeArea?.bottom ?? 0) +
              (isAndroid(userAgent)
                ? nextKeyboardHeight || (scrollHeight <= scrollTop + clientHeight ? 0 : -keyboardHeight)
                : 0);
          });
        }

        setKeyboardHeight(nextKeyboardHeight);
      };

      window.visualViewport?.addEventListener('resize', handleResize);

      return () => {
        window.visualViewport?.removeEventListener('resize', handleResize);
      };
    }, [initialWindowHeight, keyboardHeight, mobileSafeArea?.bottom, userAgent]);

    React.useEffect(() => {
      if (isChatLoadingDelayed) {
        scrollToBottom();
      }
    }, [isChatLoadingDelayed, scrollToBottom]);

    return (
      <FrameLayout
        topNavBar={
          <TopNavBar
            paginationIcon="exit"
            content={t('santa_lumi_chat_topnavbar_title')}
            subContent={t('santa_lumi_chat_topnavbar_description')}
            onPaginationIconClick={handleClose}
          />
        }>
        <div
          ref={ref}
          css={css`
            display: flex;
            flex-direction: column;
            width: 100%;
            height: 100%;
          `}
          {...props}>
          {isError ? (
            <SinglePageLayout isError>
              <SystemErrorLayout
                color="gray"
                icon="warning"
                description={t('chat_system_error')}
                button={
                  <Button
                    variant="box-line"
                    css={css`
                      width: 200px;
                    `}
                    onClick={onRetry}>
                    {t('chat_system_error_retry')}
                  </Button>
                }
              />
            </SinglePageLayout>
          ) : (
            <>
              {(isOffline || isAutoSendError) && toastContainer}
              <div
                ref={measuredChatAreaRef}
                css={css`
                  display: flex;
                  flex-grow: 1;
                  justify-content: center;
                  ${isLoading ? 'align-items: center;' : ''}
                  position: relative;
                  padding: 0 20px;
                  background-color: ${COLOR_SANTA_C};
                  overflow-y: auto;

                  @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
                    padding: 0 40px;
                  }
                `}>
                {isLoading ? (
                  <LottieAnimation src={URL_GIF_LOADING_GRAY} width={40} height={40} />
                ) : (
                  <div
                    css={css`
                      display: flex;
                      flex-direction: column;
                      gap: 12px;
                      width: 100%;
                      height: fit-content;
                      padding: 40px 0;
                      white-space: pre-line;

                      @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
                        width: 600px;
                      }
                    `}>
                    {chats?.map((chat, index) => (
                      <div
                        key={chat.id}
                        ref={index === chats.length - 1 ? measuredLastChatRef : null}
                        css={css`
                          display: grid;
                        `}>
                        {chat.type === 'SANTA' ? (
                          <div
                            css={css`
                              display: flex;
                              align-items: flex-end;
                            `}>
                            <img
                              css={css`
                                margin-right: 8px;
                                border-radius: 8px;
                              `}
                              src={URL_IMG_AVATAR_LUMI}
                              width={36}
                              height={36}
                              alt=""
                            />
                            <ChatSantaBubble
                              isLoading={chat.isLoading}
                              isSelected={chat.isSelected}
                              isDisabled={isOffline}
                              text={chat.text}
                              loadingText={chatLoadingText}
                              feedback={chat.feedback}
                              onFeedback={createFeedbackHandler(chat)}
                            />
                          </div>
                        ) : (
                          <ChatUserBubble
                            isLoading={chat.isLoading}
                            isError={chat.isError}
                            isDisabled={isOffline}
                            text={chat.text}
                            onResend={() => onResend?.(chat)}
                            onRemove={() => onRemove?.(chat)}
                            css={css`
                              justify-self: flex-end;
                            `}
                          />
                        )}
                      </div>
                    ))}
                  </div>
                )}
              </div>
              <div
                css={css`
                  position: relative;
                `}>
                <FloatingChatButton
                  type="round"
                  Icon={ChevronDownIcon}
                  onTouchEnd={handleScrollToBottom}
                  onClick={handleScrollToBottom}
                  css={[
                    css`
                      position: absolute;
                      top: 0;
                      right: 20px;
                      transform: translateY(calc(-100% - 20px));
                      box-shadow: ${SHADOW_A_2_DOWN};
                      opacity: 0;
                      transition-duration: 0.2s;
                      transition-property: opacity;
                      transition-timing: cubic-bezier(0.4, 0, 0.2, 1);
                      pointer-events: none;
                    `,
                    !isLastChatVisible &&
                      css`
                        opacity: 1;
                        pointer-events: auto;
                      `,
                  ]}
                />
                <div
                  css={css`
                    position: absolute;
                    bottom: 100%;
                    width: 100%;
                    height: ${viewportHeight};
                    pointer-events: none;
                  `}>
                  {!isOffline && toastContainer}
                </div>
                <ChatInput
                  css={css`
                    padding-bottom: var(--mobile-safe-area-bottom);
                  `}
                  isDisabled={isOffline || isLoading || hasLoadingChat}
                  placeholder={isOffline ? t('chat_input_placeholder_network_error') : t('chat_input_placeholder')}
                  value={inputValue}
                  onChange={onInputChange}
                  onHeightChange={handleInputHeightChange}
                  onSend={handleSend}
                />
              </div>
            </>
          )}
          {isExitAlertDialogOpened && (
            <ChatExitAlertDialog onNegativeClick={handleExitAlertDialogClose} onNeutralClick={onClose} />
          )}
        </div>
      </FrameLayout>
    );
  }
);

export default React.memo(ChatLayout);
export type {Chat, ChatLayoutProps, ChatType};
