import {PriceFormatter} from './PriceFormatter';

export class UsPriceFormatter extends PriceFormatter {
  getCurrencyText(): string {
    return ' USD';
  }
  getLocale(): string {
    return 'en-US';
  }
  getCutUnit(): number {
    return 0.01;
  }
  getMonthlyCeilUnit(): number {
    return -1;
  }
}
