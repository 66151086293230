import {css} from '@emotion/react';
import {COLOR_SANTA_C} from '@riiid/design-system';
import {Table} from '@santa-web/service-ui';
import {SkeletonGrayBox} from '@app/components';

const EXPOSE_SKELETON_NUMBER = 3;

type ScoreTableSkeletonBodyProps = {
  colspan: number;
};

const ScoreTableSkeletonBody = ({colspan}: ScoreTableSkeletonBodyProps) => {
  const mockList = Array(EXPOSE_SKELETON_NUMBER)
    .fill(null)
    .map((_, index) => index);

  return (
    <Table.Body>
      {mockList.map(value => (
        <tr key={value}>
          <td colSpan={colspan}>
            <SkeletonGrayBox
              css={css`
                border-radius: 4px;
                background-color: ${COLOR_SANTA_C};
              `}
              height={48}
              width="100%"
            />
          </td>
        </tr>
      ))}
    </Table.Body>
  );
};

export default ScoreTableSkeletonBody;
