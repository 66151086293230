import { DurationFromJSON, DurationToJSON, } from './Duration';
import { LearningStatisticsCountInfoFromJSON, LearningStatisticsCountInfoToJSON, } from './LearningStatisticsCountInfo';
/**
 * Check if a given object implements the LearningStatisticsInfo interface.
 */
export function instanceOfLearningStatisticsInfo(value) {
    let isInstance = true;
    isInstance = isInstance && "count" in value;
    isInstance = isInstance && "elapsedTime" in value;
    isInstance = isInstance && "endDate" in value;
    isInstance = isInstance && "startDate" in value;
    return isInstance;
}
export function LearningStatisticsInfoFromJSON(json) {
    return LearningStatisticsInfoFromJSONTyped(json, false);
}
export function LearningStatisticsInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'count': LearningStatisticsCountInfoFromJSON(json['count']),
        'elapsedTime': DurationFromJSON(json['elapsedTime']),
        'endDate': (new Date(json['endDate'])),
        'startDate': (new Date(json['startDate'])),
    };
}
export function LearningStatisticsInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'count': LearningStatisticsCountInfoToJSON(value.count),
        'elapsedTime': DurationToJSON(value.elapsedTime),
        'endDate': (value.endDate.toISOString().substr(0, 10)),
        'startDate': (value.startDate.toISOString().substr(0, 10)),
    };
}
