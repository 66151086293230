import { HighlightColorFromJSON, HighlightColorToJSON, } from './HighlightColor';
/**
 * Check if a given object implements the HighlightTemplate interface.
 */
export function instanceOfHighlightTemplate(value) {
    let isInstance = true;
    isInstance = isInstance && "color" in value;
    isInstance = isInstance && "displayInfoJson" in value;
    isInstance = isInstance && "previewStr" in value;
    return isInstance;
}
export function HighlightTemplateFromJSON(json) {
    return HighlightTemplateFromJSONTyped(json, false);
}
export function HighlightTemplateFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'color': HighlightColorFromJSON(json['color']),
        'displayInfoJson': json['displayInfoJson'],
        'previewStr': json['previewStr'],
    };
}
export function HighlightTemplateToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'color': HighlightColorToJSON(value.color),
        'displayInfoJson': value.displayInfoJson,
        'previewStr': value.previewStr,
    };
}
