import {atom} from 'jotai';
import {atomWithDefault} from 'jotai/utils';

import {ListVisibleLearningDomainsResponseLearningDomain as LearningDomain} from '@santa-web/gen/open-api/service';
import {SantaBootFailureError} from '@app/utils/error';

import santaAuthorizedOpenapiServicesAtom from './santa-authorized-openapi-services';

export const learningDomainsAtom = atomWithDefault<Promise<LearningDomain[]>>(async get => {
  const {LearningDomainService} = await get(santaAuthorizedOpenapiServicesAtom);
  const {learningDomains} = await LearningDomainService.listVisibleLearningDomains({size: 100});

  return learningDomains;
});

export const refreshLearningDomainsAtom = atom(null, async (get, set) => {
  const {LearningDomainService} = await get(santaAuthorizedOpenapiServicesAtom);
  const {learningDomains} = await LearningDomainService.listVisibleLearningDomains({size: 100});

  set(learningDomainsAtom, Promise.resolve(learningDomains));
});

const learningDomainAtom = atomWithDefault<Promise<LearningDomain>>(async get => {
  const learningDomains = await get(learningDomainsAtom);
  const {UserService} = await get(santaAuthorizedOpenapiServicesAtom);
  const {
    user: {lastSelectedLearningDomain: lastSelectedLearningDomainId},
  } = await UserService.getMe({});

  if (lastSelectedLearningDomainId) {
    const lastSelectedLearningDomain = learningDomains.find(({id}) => id === lastSelectedLearningDomainId);
    if (!lastSelectedLearningDomain) {
      throw new SantaLearningDomainIdError(
        `lastSelectedLearningDomain is unavailable for this user: ${lastSelectedLearningDomainId}`
      );
    }
    return lastSelectedLearningDomain;
  } else {
    if (!learningDomains[0]) {
      throw new SantaLearningDomainIdError('user cannot use any domain');
    }
    return learningDomains[0];
  }
});

export class SantaLearningDomainIdError extends SantaBootFailureError {
  constructor(message: string) {
    super(message, learningDomainAtom);
    this.name = 'SantaLearningDomainError';
  }
}

export class SantaToeicLearningDomainNotFoundError extends SantaBootFailureError {
  constructor(message: string) {
    super(message, learningDomainAtom);
    this.name = 'SantaToeicLearningDomainNotFoundError';
  }
}

export default learningDomainAtom;

learningDomainAtom.debugPrivate = true;
