import {useQuery} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';
import {OfferGroup} from '@santa-web/gen/open-api/service';
import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {getOfferGroupQueryKey} from '@app/queryKeys';

export const useOfferGroupQuery = (offerGroupId: number) => {
  const {OfferService} = useAtomValue(santaOpenapiServicesAtom);
  return useQuery<OfferGroup>({
    queryKey: getOfferGroupQueryKey(offerGroupId),
    queryFn: async () => {
      const {offerGroup} = await OfferService.getOfferGroup({id: offerGroupId});
      return offerGroup;
    },
  });
};
