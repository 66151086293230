import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {Button} from '@santa-web/service-ui';
import {OnboardingInformationArea, OnboardingLayout, OnboardingQuestionViewCommonProps} from '@app/features/onboarding';

type DiagnosisStatus = 'NOT_STARTED' | 'ONGOING' | 'DONE';

type DiagnosisPreparingViewProps = OnboardingQuestionViewCommonProps & {
  domainName: string;
  title: string;
  description: string;
  diagnosisStatus?: DiagnosisStatus;
  onAnswer: (shouldGoToDiagnosis: boolean) => void;
};

const DiagnosisPreparingView = ({
  isLoading = false,
  isNarrow,
  domainName,
  diagnosisStatus = 'NOT_STARTED',
  title,
  description,
  onAnswer,
  onBackButtonClick,
}: DiagnosisPreparingViewProps) => {
  const {t} = useTranslation();
  const buttons = React.useMemo(() => {
    switch (diagnosisStatus) {
      case 'NOT_STARTED':
        return (
          <>
            <Button isLoading={isLoading} colorVariant="brand" isFullWidth onClick={() => onAnswer(true)}>
              {t('page_onboarding_diagnosis_start_button')}
            </Button>
            <Button isLoading={isLoading} colorVariant="transparent" isFullWidth onClick={() => onAnswer(false)}>
              {t('page_onboarding_diagnosis_skip_button')}
            </Button>
          </>
        );
      case 'ONGOING':
        return (
          <>
            <Button isLoading={isLoading} colorVariant="brand" isFullWidth onClick={() => onAnswer(true)}>
              {t('page_onboarding_diagnosis_continue_button')}
            </Button>
            <Button isLoading={isLoading} colorVariant="transparent" isFullWidth onClick={() => onAnswer(false)}>
              {t('page_onboarding_diagnosis_skip_button')}
            </Button>
          </>
        );
      case 'DONE':
        return (
          <Button isLoading={isLoading} colorVariant="brand" isFullWidth onClick={() => onAnswer(true)}>
            {t('page_onboarding_diagnosis_result_button')}
          </Button>
        );
    }
  }, [diagnosisStatus, isLoading, onAnswer, t]);

  return (
    <OnboardingLayout isNarrow={isNarrow} onBackButtonClick={onBackButtonClick}>
      <div
        css={css`
          height: 100%;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
        `}>
        <OnboardingInformationArea shouldShowAiImage subTitle={domainName} title={title} description={description} />
        <div
          css={css`
            display: grid;
            gap: 8px;
          `}>
          {buttons}
        </div>
      </div>
    </OnboardingLayout>
  );
};

export default DiagnosisPreparingView;
export type {DiagnosisPreparingViewProps};
DiagnosisPreparingView.displayName = 'DiagnosisPreparingView';
