import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {
  BREAKPOINT_MEDIUM,
  COLOR_FIX_SANTA_GRAY_7,
  COLOR_FIX_SPACE_BLUE_BLACK_2,
  COLOR_FIX_SPACE_BLUE_WHITE_3,
  COLOR_SANTA_J,
  COLOR_SANTA_O,
} from '@riiid/design-system';
import CloseIcon from '@riiid/design-system/icons/Close';
import {
  URL_IMG_CHAT_LUMI_EG,
  URL_IMG_CHAT_LUMI_JP,
  URL_IMG_CHAT_LUMI_KR,
  URL_IMG_CHAT_LUMI_TW,
} from '@riiid/design-system/images/santa';
import {Button, Typography, BaseBottomSheet} from '@santa-web/service-ui';
import {ALERT_DIALOG_Z_INDEX} from '@app/constants/zIndex';

type ChatCautionBottomSheetProps = {
  isOpened?: boolean;
  onCloseButtonClick(): void;
  onAgreeButtonClick(): void;
};

const ChatCautionBottomSheet = ({
  isOpened = false,
  onCloseButtonClick,
  onAgreeButtonClick,
}: ChatCautionBottomSheetProps) => {
  const {i18n, t} = useTranslation();
  const imageSrc = React.useMemo(() => {
    switch (i18n.languages[0]) {
      case 'ko':
        return URL_IMG_CHAT_LUMI_KR;
      case 'ja':
        return URL_IMG_CHAT_LUMI_JP;
      case 'zh':
        return URL_IMG_CHAT_LUMI_TW;
      case 'en':
      default:
        return URL_IMG_CHAT_LUMI_EG;
    }
  }, [i18n.languages]);
  return (
    <BaseBottomSheet
      isOpened={isOpened}
      css={[
        css`
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;

          z-index: ${ALERT_DIALOG_Z_INDEX};
          overflow-y: visible;

          padding: 0 20px 40px;
          @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
            padding: 0 20px 40px;
          }
        `,
      ]}>
      <img
        css={css`
          position: absolute;
          top: -44px;
          left: 50%;
          transform: translateX(-50%);
        `}
        src={imageSrc}
        alt=""
        width={176}
        height={116}
      />
      <button
        css={css`
          position: absolute;
          top: 24px;
          right: 20px;
          padding: 0;
          margin: 0;
          border: none;
          background: none;
          cursor: pointer;
        `}
        onClick={onCloseButtonClick}>
        <CloseIcon width={24} height={24} color={COLOR_SANTA_O} />
      </button>
      <div
        css={css`
          width: 100%;
          max-height: 324px;
          margin-top: 72px;
          overflow-y: auto;
        `}>
        <Typography
          css={css`
            margin-bottom: 12px;
            white-space: pre-line;
            text-align: center;
            @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
              margin-bottom: 16px;
            }
          `}
          variant="h5"
          fontWeight="bold">
          {t('santa_lumi_agree_bottomsheet_title')}
        </Typography>
        <div
          css={css`
            width: 100%;
            padding: 8px 24px;
            margin-bottom: 12px;
            border-radius: 8px;
            box-shadow: 4px 4px 22px 0px #589fff40 inset;
            background-color: ${COLOR_FIX_SPACE_BLUE_WHITE_3};
            @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
              margin-bottom: 16px;
            }
          `}>
          <Typography
            css={css`
              text-align: center;
            `}
            color={COLOR_FIX_SANTA_GRAY_7}
            variant="body3">
            <Trans
              t={t}
              i18nKey="santa_lumi_agree_bottomsheet_greeting"
              components={{
                em: (
                  <Typography color={COLOR_FIX_SPACE_BLUE_BLACK_2} variant="body3" fontWeight="bold" component="span" />
                ),
              }}
            />
          </Typography>
        </div>
        <ul
          css={css`
            width: 100%;
            word-break: break-all;
            display: grid;
            margin: 0;
            padding: 0 0 0 25px;
          `}>
          <li>
            <Typography color={COLOR_SANTA_J} variant="caption1" component="p">
              {t('santa_lumi_agree_bottomsheet_description_1')}
            </Typography>
          </li>
          <li>
            <Typography color={COLOR_SANTA_J} variant="caption1" component="p">
              {t('santa_lumi_agree_bottomsheet_description_2')}
            </Typography>
          </li>
          <li>
            <Typography color={COLOR_SANTA_J} variant="caption1" component="p">
              {t('santa_lumi_agree_bottomsheet_description_3')}
            </Typography>
          </li>
        </ul>
      </div>
      <Button
        css={css`
          margin-top: 40px;
        `}
        isFullWidth
        colorVariant="brand"
        onClick={onAgreeButtonClick}>
        {t('santa_lumi_agree_bottomsheet_agree_button')}
      </Button>
    </BaseBottomSheet>
  );
};

export default ChatCautionBottomSheet;
export type {ChatCautionBottomSheetProps};
ChatCautionBottomSheet.displayName = 'ChatCautionBottomSheet';
