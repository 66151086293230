/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { ImageSetFromJSON, ImageSetToJSON, } from './ImageSet';
import { SelfLearningContentTypeAsEnumFromJSON, SelfLearningContentTypeAsEnumToJSON, } from './SelfLearningContentTypeAsEnum';
import { SelfLearningUnitProgressFromJSON, SelfLearningUnitProgressToJSON, } from './SelfLearningUnitProgress';
import { SelfLearningUnitStateFromJSON, SelfLearningUnitStateToJSON, } from './SelfLearningUnitState';
/**
 * Check if a given object implements the SelfLearningUnit interface.
 */
export function instanceOfSelfLearningUnit(value) {
    let isInstance = true;
    isInstance = isInstance && "contentType" in value;
    isInstance = isInstance && "icon" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "state" in value;
    isInstance = isInstance && "tags" in value;
    isInstance = isInstance && "title" in value;
    return isInstance;
}
export function SelfLearningUnitFromJSON(json) {
    return SelfLearningUnitFromJSONTyped(json, false);
}
export function SelfLearningUnitFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'caption': !exists(json, 'caption') ? undefined : json['caption'],
        'contentType': SelfLearningContentTypeAsEnumFromJSON(json['contentType']),
        'icon': ImageSetFromJSON(json['icon']),
        'id': json['id'],
        'label': !exists(json, 'label') ? undefined : json['label'],
        'progress': !exists(json, 'progress') ? undefined : SelfLearningUnitProgressFromJSON(json['progress']),
        'state': SelfLearningUnitStateFromJSON(json['state']),
        'subTitle': !exists(json, 'subTitle') ? undefined : json['subTitle'],
        'tags': json['tags'],
        'title': json['title'],
    };
}
export function SelfLearningUnitToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'caption': value.caption,
        'contentType': SelfLearningContentTypeAsEnumToJSON(value.contentType),
        'icon': ImageSetToJSON(value.icon),
        'id': value.id,
        'label': value.label,
        'progress': SelfLearningUnitProgressToJSON(value.progress),
        'state': SelfLearningUnitStateToJSON(value.state),
        'subTitle': value.subTitle,
        'tags': value.tags,
        'title': value.title,
    };
}
