import {Content} from '@santa-web/gen/open-api/content/models/Content';
import {GtelpContent} from '@santa-web/gen/open-api/content/models/GtelpContent';
import {ToeicContent} from '@santa-web/gen/open-api/content/models/ToeicContent';
import {LearningDomainKey} from '@santa-web/gen/open-api/service';

function getToeicResourceIds(toeicContent: ToeicContent) {
  return [
    ...(toeicContent.oneOfType.questionSet?.passages.map(passage => passage.audioResourceId) ?? []),
    ...(toeicContent.oneOfType.questionSet?.questions.map(question => question.audioResourceId) ?? []),
    toeicContent.oneOfType.vocabulary?.accentResources.enGb,
    toeicContent.oneOfType.vocabulary?.accentResources.enUs,
    toeicContent.oneOfType.vocabulary?.voiceResourceId,
    ...(toeicContent.oneOfType.vocabulary?.examples.flatMap(example => [
      example.accentResourceId.enGb,
      example.accentResourceId.enUs,
      example.voiceResourceId,
    ]) ?? []),
    ...(toeicContent.oneOfType.vocabulary?.vocaPosSet.flatMap(postSet => [
      postSet.accentResourceId.enGb,
      postSet.accentResourceId.enUs,
      // VVS
      // ...postSet.posImageResourceIds,
      ...postSet.vocaExamples.flatMap(example => [
        example.accentResourceId.enGb,
        example.accentResourceId.enUs,
        example.voiceResourceId,
      ]),
    ]) ?? []),
  ];
}

function getGtelpResourceIds(gtelpContent: GtelpContent) {
  return [
    ...(gtelpContent.oneOfType.questionSet?.passages.map(passage => passage.audioResourceId) ?? []),
    ...(gtelpContent.oneOfType.questionSet?.questions.map(question => question.audioResourceId) ?? []),
    gtelpContent.oneOfType.vocabulary?.accentResources.enGb,
    gtelpContent.oneOfType.vocabulary?.accentResources.enUs,
    gtelpContent.oneOfType.vocabulary?.voiceResourceId,
    ...(gtelpContent.oneOfType.vocabulary?.examples.flatMap(example => [
      example.accentResourceId.enGb,
      example.accentResourceId.enUs,
      example.voiceResourceId,
    ]) ?? []),
    ...(gtelpContent.oneOfType.vocabulary?.vocaPosSet.flatMap(postSet => [
      postSet.accentResourceId.enGb,
      postSet.accentResourceId.enUs,
      // VVS
      // ...postSet.posImageResourceIds,
      ...postSet.vocaExamples.flatMap(example => [
        example.accentResourceId.enGb,
        example.accentResourceId.enUs,
        example.voiceResourceId,
      ]),
    ]) ?? []),
  ];
}

function getResourceIds(content: Content) {
  const learningDomainKey = content.baseContent.domain === 'GTELP' ? 'G_TELP' : 'TOEIC';

  return (
    learningDomainKey === LearningDomainKey.TOEIC && content.oneOfDomainContent.toeicContent
      ? getToeicResourceIds(content.oneOfDomainContent.toeicContent)
      : learningDomainKey === LearningDomainKey.G_TELP && content.oneOfDomainContent.gtelpContent
      ? getGtelpResourceIds(content.oneOfDomainContent.gtelpContent)
      : []
  ).filter((resourceId): resourceId is string => resourceId !== undefined && resourceId !== '');
}

export {getResourceIds};
