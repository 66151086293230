import produce from 'immer';
import {atom} from 'jotai';
import {setAtomLabels} from '@app/misc/atom-label';
import {cisService} from '@app/test-preparation/atoms/services';
import {withDefault} from '@app/test-preparation/atoms/stores/learning/utils/atom';
import {contentRouterFactory} from '@app/test-preparation/atoms/stores/learning/utils/content-router';
import {calibrateVocabularyCises} from '@app/test-preparation/atoms/stores/learning/utils/vocabulary';
import {BookmarkItem} from '@app/types/vocabulary';

const sessionCisesAtom = atom(get => {
  const cises = get(cisService.cisesAtom);

  return calibrateVocabularyCises(
    cises.filter(cis => cis.elementInteractionStates.every(eis => eis.details.oneOfCase === 'VOCA'))
  );
});

const currentSessionAtom = atom(get => {
  const sessionCises = get(sessionCisesAtom);

  const currentSession =
    Math.max(...sessionCises.map(cis => cis.elementInteractionStates[0].details.voca?.selfReports.length ?? 0)) - 1;

  if (currentSession < 0) {
    throw new Error('Session reports not found');
  }

  return currentSession;
});
const currentSessionCisesAtom = atom(get => {
  const sessionCises = get(sessionCisesAtom);

  const currentSession = get(currentSessionAtom);
  const previousSession = currentSession - 1;

  const currentSessionCises = sessionCises.filter(
    cis => cis.elementInteractionStates[0].details.voca?.selfReports[previousSession]?.isKnowledge === false
  );

  return currentSessionCises.length === 0 ? sessionCises : currentSessionCises;
});
const currentSessionActualCisIndexesAtom = atom(get => {
  const sessionCises = get(sessionCisesAtom);
  const currentSessionCises = get(currentSessionCisesAtom);

  return currentSessionCises.map(cis => sessionCises.findIndex(({id}) => id === cis.id));
});
const currentSessionCisIndexAtom = atom(get => {
  const cis = get(cisService.cisAtom);
  const currentSessionCises = get(currentSessionCisesAtom);

  return currentSessionCises.findIndex(({id}) => id === cis.id);
});
const currentSessionCisAtom = atom(get => {
  const currentSessionCises = get(currentSessionCisesAtom);
  const currentSessionCisIndex = get(currentSessionCisIndexAtom);

  return currentSessionCises[currentSessionCisIndex];
});
const currentSessionReportsAtom = atom(get => {
  const currentSession = get(currentSessionAtom);
  const currentSessionCises = get(currentSessionCisesAtom);

  return currentSessionCises.map(cis => {
    const reports = cis.elementInteractionStates[0].details.voca?.selfReports;

    if (reports === undefined) {
      throw new Error('Session reports not found');
    }

    return reports[currentSession];
  });
});
const currentSessionCisReportsAtom = atom(get => {
  const currentSessionCis = get(currentSessionCisAtom);

  const currentSessionCisReports = currentSessionCis.elementInteractionStates[0].details.voca?.selfReports;

  if (currentSessionCisReports === undefined) {
    throw new Error('Session reports not found');
  }

  return currentSessionCisReports;
});
const currentSessionCisStepAtom = atom(get => {
  const currentSessionCisReports = get(currentSessionCisReportsAtom);

  return currentSessionCisReports.length > 1 ? 'STEP2' : 'STEP1';
});

const currentSessionCisCountAtom = atom(get => {
  const currentSessionReports = get(currentSessionReportsAtom);

  return currentSessionReports.length;
});
const currentSessionKnownCountAtom = atom(get => {
  const currentSessionReports = get(currentSessionReportsAtom);

  return currentSessionReports.filter(report => report.isKnowledge === true).length;
});
const currentSessionUnknownCountAtom = atom(get => {
  const currentSessionReports = get(currentSessionReportsAtom);

  return currentSessionReports.filter(report => report.isKnowledge === false).length;
});
const currentSessionRestCountAtom = atom(get => {
  const currentSessionReports = get(currentSessionReportsAtom);

  return currentSessionReports.filter(report => report.isKnowledge === undefined).length;
});

const isFirstSessionCisAtom = atom(get => {
  const currentSessionCisIndex = get(currentSessionCisIndexAtom);

  return currentSessionCisIndex === 0;
});
const isLastSessionCisAtom = atom(get => {
  const currentSessionCises = get(currentSessionCisesAtom);
  const currentSessionCisIndex = get(currentSessionCisIndexAtom);

  return currentSessionCisIndex === currentSessionCises.length - 1;
});
const isAllReportedAtom = atom(get => {
  const currentSessionRestCount = get(currentSessionRestCountAtom);

  return currentSessionRestCount === 0;
});
const isCurrentSessionAllKnownAtom = atom(get => {
  const currentSessionReports = get(currentSessionReportsAtom);
  const currentSessionKnownCount = get(currentSessionKnownCountAtom);

  return currentSessionReports.length === currentSessionKnownCount;
});

const bookmarkListAtom = atom(get => {
  const contentDataMap = get(cisService.contentDataMapAtom);
  const sessionCises = get(sessionCisesAtom);
  const currentSessionCises = get(currentSessionCisesAtom);
  const currentSessionActualCisIndexes = get(currentSessionActualCisIndexesAtom);
  const currentSessionReports = get(currentSessionReportsAtom);
  const isCurrentSessionAllKnown = get(isCurrentSessionAllKnownAtom);

  const bookmarkListData = isCurrentSessionAllKnown
    ? sessionCises.map((cis, index) => ({cis, content: contentDataMap[index]?.content}))
    : currentSessionCises
        .map((cis, index) => ({cis, content: contentDataMap[currentSessionActualCisIndexes[index]]?.content}))
        .filter((_, index) => currentSessionReports[index].isKnowledge === false);
  const hasLoadableContent = bookmarkListData.some(({content}) => content === undefined);

  if (hasLoadableContent) {
    return [];
  }

  return bookmarkListData.map<BookmarkItem>(({cis, content}) => {
    if (content === undefined) {
      throw new Error('Content not found');
    }

    const vocabulary = contentRouterFactory.create(content).getVocabulary();

    if (vocabulary === null) {
      throw new Error('Vocabulary not found');
    }

    return {
      cisId: cis.id,
      isBookmarked: cis.isBookmarked,
      vocabulary: {
        id: vocabulary.id,
        word: vocabulary.word,
        meaning:
          vocabulary.vocaPosSet.length !== 0
            ? vocabulary.vocaPosSet.map(({meaning}) => meaning).join(' / ')
            : vocabulary.meaning,
      },
    };
  });
});

const startNextSessionAtom = atom(null, async (get, set) => {
  const sessionCises = get(sessionCisesAtom);

  const currentSessionReports = get(currentSessionReportsAtom);
  const currentSessionActualCisIndexes = get(currentSessionActualCisIndexesAtom);

  const nextCisIndex =
    currentSessionActualCisIndexes[currentSessionReports.findIndex(report => report.isKnowledge === false)] ?? 0;

  await set(cisService.setCurrentCisIndexAtom, {index: nextCisIndex});

  await set(cisService.updateCisAtom, {
    cis: produce(sessionCises[nextCisIndex], draft => {
      draft.elementInteractionStates[0].details.voca?.selfReports.push({
        isKnowledge: undefined,
        vocaOngoingStep: 'STEP2',
      });
    }),
    isMutationOnly: true,
  });
});

const goToSessionPreviousCisAtom = atom(null, async (get, set) => {
  const cisIndexes = get(currentSessionActualCisIndexesAtom);
  const currentSessionCisIndex = get(currentSessionCisIndexAtom);

  const previousCisIndex = cisIndexes[currentSessionCisIndex - 1];

  await set(cisService.setCurrentCisIndexAtom, {index: previousCisIndex});
});
const goToSessionNextCisAtom = atom(null, async (get, set) => {
  const cisIndexes = get(currentSessionActualCisIndexesAtom);
  const currentSessionCisIndex = get(currentSessionCisIndexAtom);

  const nextCisIndex = cisIndexes[currentSessionCisIndex + 1];

  await set(cisService.setCurrentCisIndexAtom, {index: nextCisIndex});
});

const reportCurrentSessionCisAtom = atom(null, async (get, set, isKnowledge: boolean) => {
  const cis = get(cisService.cisAtom);

  const currentSession = get(currentSessionAtom);
  const currentSessionCisReports = get(currentSessionCisReportsAtom);

  const currentSessionCisReport = currentSessionCisReports[currentSession];

  const nextCis = produce(cis, draft => {
    const reports = draft.elementInteractionStates[0].details.voca?.selfReports;

    if (reports !== undefined) {
      reports[currentSession] = {
        ...currentSessionCisReport,
        isKnowledge,
      };
    }
  });

  await set(cisService.updateCisAtom, {cis: nextCis, shouldUpdateFirst: true});
});

const toggleSessionCisBookmarkAtom = atom(null, async (get, set, id: number) => {
  const cises = get(cisService.cisesAtom);
  const cis = cises.find(cis => cis.id === id);

  if (cis === undefined) {
    throw new Error('Cis not found');
  }

  const nextCis = produce(cis, draft => {
    draft.isBookmarked = !draft.isBookmarked;
  });

  await set(cisService.updateCisAtom, {cis: nextCis});
});

export const vocabularySession = {
  currentSessionCisIndexAtom: withDefault(currentSessionCisIndexAtom, 0),
  currentSessionCisStepAtom: withDefault(currentSessionCisStepAtom, null),
  currentSessionCisCountAtom: withDefault(currentSessionCisCountAtom, 0),
  currentSessionKnownCountAtom,
  currentSessionUnknownCountAtom,
  currentSessionRestCountAtom,

  isFirstSessionCisAtom,
  isLastSessionCisAtom,
  isAllReportedAtom: withDefault(isAllReportedAtom, false),
  isCurrentSessionAllKnownAtom,

  bookmarkListAtom,

  startNextSessionAtom,
  goToSessionPreviousCisAtom,
  goToSessionNextCisAtom,
  reportCurrentSessionCisAtom,
  toggleSessionCisBookmarkAtom,
};

setAtomLabels(
  {
    sessionCisesAtom,
    currentSessionAtom,
    currentSessionCisesAtom,
    currentSessionActualCisIndexesAtom,
    currentSessionCisIndexAtom: vocabularySession.currentSessionCisIndexAtom,
    currentSessionCisAtom,
    currentSessionReportsAtom,
    currentSessionCisReportsAtom,
    currentSessionCisStepAtom: vocabularySession.currentSessionCisStepAtom,
    currentSessionCisCountAtom: vocabularySession.currentSessionCisCountAtom,
    currentSessionKnownCountAtom,
    currentSessionUnknownCountAtom,
    currentSessionRestCountAtom,
    isFirstSessionCisAtom,
    isLastSessionCisAtom,
    isAllReportedAtom: vocabularySession.isAllReportedAtom,
    isCurrentSessionAllKnownAtom,
    bookmarkListAtom,
    startNextSessionAtom,
    goToSessionPreviousCisAtom,
    goToSessionNextCisAtom,
    reportCurrentSessionCisAtom,
    toggleSessionCisBookmarkAtom,
  },
  'learningStore.vocabularySession.'
);
