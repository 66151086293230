import {useMutation, useQueryClient} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';

import {CreateLearningGoalRequest} from '@santa-web/gen/open-api/content-learning-service/models';

import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {getLearningGoalKey} from '@app/queryKeys';

export default function usePostLearningGoalMutation() {
  const queryClient = useQueryClient();
  const {LearningGoalService} = useAtomValue(santaOpenapiServicesAtom);

  return useMutation({
    mutationFn: async (request: CreateLearningGoalRequest) => {
      const {learningGoal} = await LearningGoalService.createLearningGoal({
        createLearningGoalRequest: request,
      });
      return learningGoal;
    },
    onSuccess: response => {
      queryClient.setQueryData(getLearningGoalKey(), response);
    },
  });
}
