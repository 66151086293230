import {Content} from '@santa-web/gen/open-api/content/models/Content';
import {Lesson} from '@santa-web/gen/open-api/content/models/Lesson';
import {QuestionSet} from '@santa-web/gen/open-api/content/models/QuestionSet';
import {Vocabulary} from '@santa-web/gen/open-api/content/models/Vocabulary';

import {GtelpContentRouter} from './domains/gtelp';
import {ToeicContentRouter} from './domains/toeic';

const contentRouterFactory = {
  create(content: Content): ContentRouter {
    switch (content.baseContent.domain) {
      case 'TOEIC':
      case 'TOEIC_SPEAKING':
        return new ToeicContentRouter(content);

      case 'GTELP':
        return new GtelpContentRouter(content);
    }
  },
};

interface ContentRouter {
  getMeta<Name extends string>(name: Name): Name extends `${string}List` ? Array<string> : string;
  getQuestionSet(): QuestionSet | null;
  getLesson(): Lesson | null;
  getVocabulary(): Vocabulary | null;
  getRecommendedElapsedTime(): number | null;
  getGuessElapsedTime(): number;
  isLC(): boolean;
  isBasicQuestion(): boolean;
}

export type {ContentRouter};
export {contentRouterFactory};
