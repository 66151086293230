/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { ElementInteractionStateDetailsOneOfCaseFromJSON, ElementInteractionStateDetailsOneOfCaseToJSON, } from './ElementInteractionStateDetailsOneOfCase';
import { LessonStateFromJSON, LessonStateToJSON, } from './LessonState';
import { QuestionStateFromJSON, QuestionStateToJSON, } from './QuestionState';
import { VocaStateFromJSON, VocaStateToJSON, } from './VocaState';
/**
 * Check if a given object implements the ElementInteractionStateOneOfDetails interface.
 */
export function instanceOfElementInteractionStateOneOfDetails(value) {
    let isInstance = true;
    isInstance = isInstance && "oneOfCase" in value;
    return isInstance;
}
export function ElementInteractionStateOneOfDetailsFromJSON(json) {
    return ElementInteractionStateOneOfDetailsFromJSONTyped(json, false);
}
export function ElementInteractionStateOneOfDetailsFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'lesson': !exists(json, 'lesson') ? undefined : LessonStateFromJSON(json['lesson']),
        'oneOfCase': ElementInteractionStateDetailsOneOfCaseFromJSON(json['oneOfCase']),
        'question': !exists(json, 'question') ? undefined : QuestionStateFromJSON(json['question']),
        'voca': !exists(json, 'voca') ? undefined : VocaStateFromJSON(json['voca']),
    };
}
export function ElementInteractionStateOneOfDetailsToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'lesson': LessonStateToJSON(value.lesson),
        'oneOfCase': ElementInteractionStateDetailsOneOfCaseToJSON(value.oneOfCase),
        'question': QuestionStateToJSON(value.question),
        'voca': VocaStateToJSON(value.voca),
    };
}
