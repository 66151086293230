import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';

import {AlertProps, AlertDialog, AlertDialogProps} from '@santa-web/service-ui';
import {ALERT_DIALOG_Z_INDEX} from '@app/constants/zIndex';
import {useDialogContext} from '@app/contexts/DialogContext';

export interface EditCancelAlertDialogProps extends AlertDialogProps {
  onConfirm: () => void;
  title: string;
  description: string;
}

export default React.memo<EditCancelAlertDialogProps>(
  React.forwardRef<HTMLDivElement, EditCancelAlertDialogProps>(function EditCancelAlertDialog(
    {onConfirm, title, description, ...props},
    ref
  ) {
    const {t} = useTranslation();
    const {closeDialog} = useDialogContext();

    const handlePositiveClick = React.useCallback(() => {
      closeDialog();
      onConfirm();
    }, [closeDialog, onConfirm]);

    const alertProps = useMemo(
      (): AlertProps => ({
        title,
        description,
        negativeLabel: t('dialog_edit_cancel_alert_negative_label'),
        positiveLabel: t('dialog_edit_cancel_alert_positive_label'),
        onNegativeClick: closeDialog,
        onPositiveClick: handlePositiveClick,
        css: css`
          white-space: pre-line;
        `,
      }),
      [closeDialog, handlePositiveClick, title, description, t]
    );

    return (
      <AlertDialog
        ref={ref}
        css={css`
          z-index: ${ALERT_DIALOG_Z_INDEX};
        `}
        AlertProps={alertProps}
        isVisible
        {...props}
      />
    );
  })
);
