import {useMutation, useQueryClient} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';

import {UserProfile} from '@santa-web/gen/open-api/service';
import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {getUserProfileQueryKey} from '@app/queryKeys';

export default function useCreateUserProfileMutation() {
  const {ProfileService} = useAtomValue(santaOpenapiServicesAtom);
  const queryClient = useQueryClient();

  return useMutation(
    async () => {
      const {profile} = await ProfileService.createMyProfile({});
      return profile;
    },
    {
      onSuccess: profile => {
        queryClient.setQueryData<UserProfile>(getUserProfileQueryKey(), profile);
      },
    }
  );
}
