import React from 'react';
import {css} from '@emotion/react';

import {BREAKPOINT_MEDIUM} from '@riiid/design-system';
import {Dim} from '@santa-web/service-ui';
import {BottomSheetWithDimProps} from '@app/components/BottomSheetWithDim';
import {BOTTOM_SHEET_Z_INDEX} from '@app/constants/zIndex';
import {useDialogContext} from '@app/contexts/DialogContext';
import ChatCautionBottomSheet from './ChatCautionBottomSheet';

export interface ChatCautionBottomSheetWithDimProps extends BottomSheetWithDimProps {
  onStartClick(): void;
}

const ChatCautionBottomSheetWithDim = React.forwardRef<HTMLDivElement, ChatCautionBottomSheetWithDimProps>(
  ({onStartClick, ...props}, ref) => {
    const {closeDialog} = useDialogContext();

    return (
      <Dim
        css={css`
          position: fixed;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
          display: flex;
          flex-direction: column-reverse;
          box-sizing: border-box;
          z-index: ${BOTTOM_SHEET_Z_INDEX};
          @media (min-width: ${BREAKPOINT_MEDIUM}) {
            align-items: center;
            justify-content: center;
          }
        `}
        ref={ref}
        {...props}>
        <ChatCautionBottomSheet isOpened onAgreeButtonClick={onStartClick} onCloseButtonClick={closeDialog} />
      </Dim>
    );
  }
);

export default ChatCautionBottomSheetWithDim;
