import React, {useEffect, useMemo, useRef} from 'react';
import {useIntersection} from 'react-use';
import {css} from '@emotion/react';
import {Column} from '@app/components';
import {throttle} from '@app/utils/throttle';

type InfinityListProps<T> = {
  items: T[];
  throttleDelay?: number;
  emptyComponent?: React.ReactNode;
  intersectionOptions?: Partial<IntersectionObserverInit>;
  getKey: (item: T, index: number) => string;
  renderItem: (item: T) => React.ReactNode;
  onEndReached?: () => void;
} & React.HTMLAttributes<HTMLUListElement>;

const InfinityList = <T,>({
  items,
  throttleDelay = 500,
  emptyComponent,
  intersectionOptions,
  getKey,
  renderItem,
  onEndReached,
  ...containerProps
}: InfinityListProps<T>) => {
  const onEndReachedRef = useRef(onEndReached);
  const bottomRef = React.useRef<HTMLDivElement>(null);
  const entry = useIntersection(bottomRef, {...intersectionOptions});

  // onEndReachedRef가 최신의 onEndReached를 바라보게함.
  onEndReachedRef.current = onEndReached;

  // 다음 데이터가 UI에 그려지기 전 다시 한 번 호출되는 것을 방지
  const throttledOnEndReached = useMemo(
    // throttle의 참조 유지를 위해 onEndReached를 ref로 관리함.
    () => throttle(() => onEndReachedRef.current?.(), throttleDelay),
    [throttleDelay]
  );

  useEffect(() => {
    if (entry?.isIntersecting) {
      throttledOnEndReached();
    }
  }, [throttledOnEndReached, entry?.isIntersecting]);

  if (items.length === 0 && emptyComponent)
    return (
      <Column
        as="ul"
        css={css`
          list-style: none;
          margin: 0;
          padding: 0;
          overflow: auto;
        `}
        {...containerProps}>
        {emptyComponent}
      </Column>
    );

  return (
    <Column
      as="ul"
      css={css`
        list-style: none;
        margin: 0;
        padding: 0;
        overflow: auto;
      `}
      {...containerProps}>
      {items.map((item, index) => (
        <li key={getKey(item, index)}>{renderItem(item)}</li>
      ))}
      <div ref={bottomRef} />
    </Column>
  );
};

export default InfinityList;
export type {InfinityListProps};
InfinityList.displayName = 'InfinityList';
