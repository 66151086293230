import {QuestionStateObjective} from '@santa-web/gen/open-api/content-learning-service';
import {Objective} from '@santa-web/gen/open-api/content/models/Objective';

export const emptyAnswer = (objective: QuestionStateObjective | null | undefined): void => {
  if (objective == null) return;

  objective.userAnswer = [];
};

export const choiceAnswer = (objective: QuestionStateObjective | null | undefined, index: number): void => {
  if (objective == null) return;
  if (getIsEliminated(objective, index)) return;

  objective.userAnswer = [index];
};

export const eliminateChoice = (objective: QuestionStateObjective | null | undefined, index: number): void => {
  if (objective == null) return;
  const {userAnswer} = objective;

  const wasEliminated = getIsEliminated(objective, index);

  const eliminatedChoiceIndices = new Set(objective.eliminatedAnswers);
  if (wasEliminated) {
    eliminatedChoiceIndices.delete(index);
  } else {
    eliminatedChoiceIndices.add(index);
  }
  objective.eliminatedAnswers = [...eliminatedChoiceIndices].sort();

  if (!wasEliminated) {
    objective.userAnswer = userAnswer.filter(x => x !== index);
  }
};

export const getIsEliminated = (objective: QuestionStateObjective | null | undefined, index: number): boolean => {
  return objective?.eliminatedAnswers.includes(index) ?? false;
};

export const getIsCorrect = (
  questionStateObjective: QuestionStateObjective | null | undefined,
  objective: Objective | null | undefined
): boolean => {
  const choices = questionStateObjective?.userAnswer ?? [];
  switch (objective?.oneOfAnswers.oneOfCase) {
    case 'AND_ANSWER': {
      const answers = objective.oneOfAnswers.andAnswers ?? [];
      return choices.length > 0 && choices.every(choice => answers.includes(choice));
    }
    case 'OR_ANSWER': {
      const answers = objective.oneOfAnswers.orAnswers ?? [];
      return choices.some(choice => answers.includes(choice));
    }
    default:
      return false;
  }
};

export const getIsSelected = (objective: QuestionStateObjective | null | undefined, index?: number): boolean => {
  if (index == null) {
    return (objective?.userAnswer.length ?? 0) > 0;
  }

  return objective?.userAnswer.includes(index) ?? false;
};
