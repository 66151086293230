import {PriceFormatter} from './PriceFormatter';

export class TwPriceFormatter extends PriceFormatter {
  getCurrencyText(): string {
    return '元';
  }
  getLocale(): string {
    return 'zh-TW';
  }
  getCutUnit(): number {
    return 10;
  }
  getMonthlyCeilUnit(): number {
    return 1;
  }
}
