import {useCallback, useEffect} from 'react';
import useFlashDealEvent from '@app/api/google-tag-manager/flashdeal';
import useOfferEvents from '@app/api/google-tag-manager/offer';
import config, {Config} from '@app/config';
import {CountDownTimerContainer, NewUserFlashDealPageView, OfferGroupCardContainer} from '@app/features/marketing';
import {useOfferGroupQuery} from '@app/features/offer';
import {mapOfferGroupToOfferGroupCardProps} from '@app/features/offer/utils';
import {useMyPassStatusQuery} from '@app/features/permit';
import {useTypedRouter, useTypedSearchParams} from '@app/hooks/useTypedRouter';

const offerGroupIdMap: Record<Config['env'], number> = {
  development: 13,
  staging: 10,
  production: 50017, // https://riiid-pioneer.atlassian.net/browse/SP-4
};
const offerGroupId = offerGroupIdMap[config.env];

const NewUserFlashDealPageContainer = () => {
  const {pushFlashDealExit, pushFlashDealNext, pushFlashDealProductCard} = useFlashDealEvent();
  const {pushClickStore} = useOfferEvents();
  const {referrer} = useTypedSearchParams('/event/flash-deal/new-user');
  const {data: passStatus} = useMyPassStatusQuery();
  const {data: offerGroup, isLoading} = useOfferGroupQuery(offerGroupId);
  const router = useTypedRouter();

  const passRemainedTimeMs = (passStatus?.activePass?.expireAt?.getTime() ?? 0) - new Date().getTime();

  const handleBuyClick = () => {
    if (!offerGroup) return;

    pushClickStore({referrer: 'flash_deal'});

    router.replace({
      pathname: '/offer-group/board/detail',
      query: {
        offerGroupId: offerGroup.id,
        boardCode: 'FLASH',
        backTo: referrer === 'diagnosis_report' ? '/course' : undefined,
        referrer: 'flash_deal',
      },
    });
  };

  const handleCloseClick = useCallback(() => {
    referrer === 'diagnosis_report' ? router.replace('/course') : router.back();
  }, [referrer, router]);

  useEffect(() => {
    if (!isLoading && !offerGroup) {
      handleCloseClick();
    }
  }, [handleCloseClick, isLoading, offerGroup]);

  if (!offerGroup) return null;

  const offerGroupProps = mapOfferGroupToOfferGroupCardProps(offerGroup, new Date());

  return (
    <NewUserFlashDealPageView
      onBuyClick={() => {
        pushFlashDealNext();
        handleBuyClick();
      }}
      onCloseClick={() => {
        pushFlashDealExit();
        handleCloseClick();
      }}
      countdownTimer={<CountDownTimerContainer remainTimeMs={passRemainedTimeMs} />}
      offerGroupCard={
        <OfferGroupCardContainer
          {...offerGroupProps}
          onClick={() => {
            pushFlashDealProductCard();
            handleBuyClick();
          }}
        />
      }
    />
  );
};

export default NewUserFlashDealPageContainer;
