import React, {useCallback, useEffect, useMemo, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {useAudio, useToggle} from 'react-use';
import {css} from '@emotion/react';

import {Vocabulary} from '@santa-web/gen/open-api/content/models/Vocabulary';
import {VocaPoS} from '@santa-web/gen/open-api/content/models/VocaPoS';
import {VocaLayout, VocaLayoutProps, PlayButton, VocaExample, PosLayout} from '@santa-web/service-ui';
import useVocabularyEvents, {AllVocaPronunciationClkPayload} from '@app/api/google-tag-manager/vocabulary';
import VocabularyPronouncingBottomSheetList from '@app/components/ContentView/components/VocabularyPronouncingBottomSheetList';
import {getPosVoiceResourceId, getExampleVoiceResourceId} from '@app/facade/vocabulary';
import {useResources} from '@app/test-preparation/hooks/resource';
import {handleAudioError} from '@app/utils/error';

// VVS https://www.notion.so/riiid/5d9137f2b7fb4a1da29730d42cf1d796?pvs=4
// import {useAtomValue} from 'jotai';
// import PosImageComponent from '@app/components/ContentView/components/PosImageComponent';
// import {learningStore} from '@app/test-preparation/atoms/stores';

export interface FullVocaLayoutProps extends Omit<VocaLayoutProps, 'meaning'> {
  isDisabled?: boolean;
  vocabulary?: Vocabulary | null;
}

const FullVocaView = ({isDisabled, vocabulary, ...props}: FullVocaLayoutProps): JSX.Element => {
  const {t} = useTranslation();
  const {pushAllVocaPronunciationClkEvent} = useVocabularyEvents();

  // VVS
  // const {pushAllVocaImageExpClkEvent} = useVocabularyEvents();
  // const contentId = useAtomValue(learningStore.content.idAtom);

  const posToLanguage: Record<VocaPoS['pos'], string> = useMemo(() => {
    return {
      UNSPECIFIED: '',
      ADJECTIVE: t('toeic_vocabulary_pos_adjective'),
      ADVERB: t('toeic_vocabulary_pos_adverb'),
      CONJUNCTION: t('toeic_vocabulary_pos_conjunction'),
      EXPRESSION: t('toeic_vocabulary_pos_expression'),
      INTERJECTION: t('toeic_vocabulary_pos_interjection'),
      NOUN: t('toeic_vocabulary_pos_noun'),
      PREPOSITION: t('toeic_vocabulary_pos_preposition'),
      PRONOUN: t('toeic_vocabulary_pos_pronoun'),
      VERB: t('toeic_vocabulary_pos_verb'),
    };
  }, [t]);

  // VVS
  // const randomImageResourceIdArray = useMemo(
  //   () =>
  //     vocabulary?.vocaPosSet.map(pos => {
  //       const length = pos.posImageResourceIds.length;

  //       if (!length) {
  //         return null;
  //       }

  //       return pos.posImageResourceIds[Math.floor(Math.random() * pos.posImageResourceIds.length)];
  //     }) ?? [],
  //   [vocabulary?.vocaPosSet]
  // );
  // const randomImageResourceArray = useResources(randomImageResourceIdArray);
  // const randomImageResourceUrlArray = randomImageResourceArray.map(imageResource => imageResource?.downloadUrl ?? '');

  const hasVocaPosSet = vocabulary?.vocaPosSet != undefined && vocabulary.vocaPosSet.length > 0;

  const pronouncingResourceIdArray = useMemo(
    () => (hasVocaPosSet ? vocabulary.vocaPosSet.map(getPosVoiceResourceId) : [vocabulary?.voiceResourceId]) ?? [],
    [hasVocaPosSet, vocabulary?.vocaPosSet, vocabulary?.voiceResourceId]
  );

  const pronouncingResourceArray = useResources(pronouncingResourceIdArray);

  const pronouncingAudioUrlArray = pronouncingResourceArray.map(pronouncingResource => pronouncingResource?.url ?? '');

  const pronouncingAudioSet = useMemo(() => {
    return (
      (hasVocaPosSet
        ? vocabulary.vocaPosSet.map((pos, index) => {
            return {pos: pos.pos, url: pronouncingAudioUrlArray[index], title: posToLanguage[pos.pos]};
          })
        : [{pos: vocabulary?.pos as VocaPoS['pos'], url: pronouncingAudioUrlArray[0], title: ''}]) ?? []
    );
  }, [hasVocaPosSet, posToLanguage, pronouncingAudioUrlArray, vocabulary?.pos, vocabulary?.vocaPosSet]);

  const exampleResourcesArray = useResources(
    (hasVocaPosSet
      ? vocabulary.vocaPosSet.flatMap(pos => pos.vocaExamples.map(getExampleVoiceResourceId))
      : vocabulary?.examples.map(getExampleVoiceResourceId)) ?? []
  );

  const [src, setSrc] = useState('');
  const [audio, state, controls, ref] = useAudio(
    // Key is required because changing a src attribute of a source element does not make an audio element reload an audio file.
    <audio key={src} autoPlay onError={handleAudioError} onStalled={handleAudioError}>
      <source src={src} type="audio/mpeg" />
    </audio>
  );

  const [isPronouncingSheetOpen, setIsPronouncingSheetOpen] = useToggle(false);

  const setAudioSrc = useCallback(
    (url: string) => {
      if (url === src) {
        controls.play();
      } else {
        setSrc(url);
      }
    },
    [controls, src]
  );

  const handleVocaPlayButtonClick = useCallback(() => {
    if (!vocabulary || pronouncingAudioSet.length === 0) {
      return;
    }

    if (pronouncingAudioSet.length <= 1) {
      setAudioSrc(pronouncingAudioSet[0].url);
      if (hasVocaPosSet) {
        pushAllVocaPronunciationClkEvent({text_type: 'word', pos_type: vocabulary.vocaPosSet[0].pos});
      }
      return;
    }
    controls.pause();
    setIsPronouncingSheetOpen(true);
  }, [
    vocabulary,
    pronouncingAudioSet,
    controls,
    setIsPronouncingSheetOpen,
    setAudioSrc,
    hasVocaPosSet,
    pushAllVocaPronunciationClkEvent,
  ]);

  const isPronouncingPlaying = pronouncingAudioUrlArray.includes(src) && !state.paused;

  useEffect(() => {
    if (isDisabled) {
      controls.pause();
    }
  }, [controls, isDisabled]);

  const vocaPlayButton = useMemo(
    () =>
      pronouncingResourceIdArray && (
        <PlayButton
          content={
            isPronouncingPlaying
              ? t('toeic_vocabulary_view_pronunciation_playing')
              : t('toeic_vocabulary_view_pronunciation_play')
          }
          isDisabled={isDisabled || isPronouncingPlaying}
          onClick={handleVocaPlayButtonClick}
        />
      ),
    [pronouncingResourceIdArray, isPronouncingPlaying, t, isDisabled, handleVocaPlayButtonClick]
  );

  const handlePlayClick = useCallback(
    (audioSrc: string, payload?: AllVocaPronunciationClkPayload) => {
      if (ref.current?.paused !== state.paused) return;

      const isPlaying = src === audioSrc && !state.paused;

      if (isPlaying) {
        controls.pause();
      } else {
        setAudioSrc(audioSrc);
      }

      if (payload !== undefined) {
        pushAllVocaPronunciationClkEvent(payload);
      }
    },
    [controls, ref, setAudioSrc, src, state.paused, pushAllVocaPronunciationClkEvent]
  );

  // VVS
  // const [isImagesLoaded, setIsImagesLoaded] = useState(false);
  // const [loadedImagesCount, setLoadedImagesCount] = useState(0);
  // const addLoadedImageCount = useCallback(() => {
  //   setLoadedImagesCount(loadedImagesCount + 1);
  // }, [loadedImagesCount]);
  // useEffect(() => {
  //   if (loadedImagesCount === randomImageResourceUrlArray.filter(v => v).length) {
  //     setIsImagesLoaded(true);
  //   }
  // }, [loadedImagesCount, randomImageResourceUrlArray, randomImageResourceUrlArray.length]);

  const vocaPosList = useMemo(() => {
    let index = 0;

    if (!hasVocaPosSet) {
      return vocabulary?.examples.map((example, index) => {
        const audioSrc = exampleResourcesArray[index]?.url ?? '';
        const isPlaying = src === audioSrc && !state.paused;

        return (
          <VocaExample
            key={index}
            isMigrated={hasVocaPosSet}
            PlayButtonProps={{isDisabled, variant: isPlaying ? 'pause' : 'play'}}
            content={example.text}
            subContent={example.translation}
            onPlayClick={() => {
              handlePlayClick(audioSrc);
            }}
          />
        );
      });
    }

    return vocabulary.vocaPosSet.map((pos, posIndex) => {
      return (
        <>
          {/* VVS */}
          {/* {randomImageResourceUrlArray[posIndex] && (
            <PosImageComponent
              imageUrl={randomImageResourceUrlArray[posIndex]}
              onLoad={addLoadedImageCount}
              shouldShowImage={isImagesLoaded}
              alt={vocabulary.word}
              sendGaEvent={() =>
                pushAllVocaImageExpClkEvent({
                  image_content_id: contentId,
                  image_pos: pos.pos,
                })
              }
            />
          )} */}
          <PosLayout
            key={posIndex}
            pos={posToLanguage[pos.pos]}
            meaning={pos.meaning}
            css={css`
              &:not(:last-child) {
                margin-bottom: 20px;
              }
            `}>
            {pos.vocaExamples.map(({text, translation}, exampleIndex) => {
              const audioSrc = exampleResourcesArray[index]?.url ?? '';
              const isPlaying = src === audioSrc && !state.paused;
              index++;

              return (
                <div
                  key={exampleIndex}
                  css={css`
                    &:not(:last-child) {
                      margin-bottom: 16px;
                    }
                  `}>
                  <VocaExample
                    isMigrated={hasVocaPosSet}
                    PlayButtonProps={{isDisabled, variant: isPlaying ? 'pause' : 'play'}}
                    content={text}
                    subContent={translation}
                    onPlayClick={() => {
                      handlePlayClick(audioSrc, {text_type: 'example_sentence', pos_type: pos.pos});
                    }}
                  />
                </div>
              );
            })}
          </PosLayout>
        </>
      );
    });
  }, [
    hasVocaPosSet,
    vocabulary?.vocaPosSet,
    vocabulary?.examples,
    exampleResourcesArray,
    src,
    state.paused,
    isDisabled,
    handlePlayClick,
    posToLanguage,
  ]);

  return (
    <>
      <VocaLayout
        word={vocabulary?.word}
        playButton={vocaPlayButton}
        vocaPosList={vocaPosList}
        {...props}
        isMigrated={hasVocaPosSet}
      />
      {audio}
      <VocabularyPronouncingBottomSheetList
        isOpened={isPronouncingSheetOpen}
        onClickClose={() => setIsPronouncingSheetOpen(false)}
        pronouncingAudioSet={pronouncingAudioSet}
        state={state}
        src={src}
        handlePlayClick={handlePlayClick}
      />
    </>
  );
};

export default React.memo(FullVocaView);
