import React, {useContext} from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {BREAKPOINT_MEDIUM} from '@riiid/design-system';
import produce from 'immer';

import MultiColumn from '@app/components/MultiColumn';
import {getIsCompleted, getStatesByCase} from '@app/facade/content-interaction-state';
import LearningCellContext from '@app/pages/learning-cell/contexts/LearningCellContext';
import LearningResultContainer from '@app/pages/learning-cell/report/components/LearningResultContainer';
import SwitchContainer from '@app/pages/learning-cell/report/components/SwitchContainer';
import VocaQuestionSwitch from '@app/pages/learning-cell/report/components/VocaQuestionSwitch';
import WrongQuestionsOnlySwitch from '@app/pages/learning-cell/report/components/WrongQuestionsOnlySwitch';
import ContentCard from '@app/pages/learning-cell/report/content-card';
import {GenerateContentCardClickHandler} from '@app/pages/learning-cell/report/LearningCellReportView';
import {contentRouterFactory} from '@app/test-preparation/atoms/stores/learning/utils/content-router';
import {IContentInteractionStateWithContent} from '@app/types/santa-service-protocol';

import EmptyContentNotice from './components/EmptyContentNotice';

export type VocaResultType = 'vocas' | 'questions';

export interface LearningCellReportContentsViewProps {
  focusedCardIndex?: number;
  contentInteractionStatesWithContents?: IContentInteractionStateWithContent[];
  questionTimeHidden?: boolean;
  generateContentCardClickHandler: GenerateContentCardClickHandler;
}

const LearningCellReportContentsView = ({
  focusedCardIndex,
  contentInteractionStatesWithContents,
  questionTimeHidden,
  generateContentCardClickHandler,
}: LearningCellReportContentsViewProps) => {
  const {t} = useTranslation();
  const contentCardsRef = React.useRef<(HTMLDivElement | null)[]>([]);
  const [isWrongQuestionOnly, setIsWrongQuestionOnly] = React.useState<boolean>(false);
  const [vocaResultType, setVocaResultType] = React.useState<VocaResultType>('vocas');
  const learningCell = useContext(LearningCellContext);

  const hasVocabularyContent = React.useMemo(
    () =>
      (contentInteractionStatesWithContents ?? []).some(
        ({content}) => contentRouterFactory.create(content).getVocabulary() !== null
      ),
    [contentInteractionStatesWithContents]
  );

  const uniqueContentInteractionStatesWithContentsByContent = React.useMemo(() => {
    return contentInteractionStatesWithContents?.reduce((uniqueCises, cis) => {
      const contentIds = uniqueCises.map(cis => cis.content?.baseContent.contentId);

      const duplicatedContentIndex = contentIds.findIndex(
        contentId => contentId === cis.content?.baseContent.contentId
      );

      if (duplicatedContentIndex === -1) {
        return [...uniqueCises, cis];
      }

      return produce(uniqueCises, draft => {
        draft[duplicatedContentIndex] = cis;
      });
    }, [] as IContentInteractionStateWithContent[]);
  }, [contentInteractionStatesWithContents]);

  const cisWithContentsAndQuestionIndex = React.useMemo(() => {
    let questionIndex = 1;

    return (uniqueContentInteractionStatesWithContentsByContent ?? []).map(item =>
      contentRouterFactory.create(item.content).getQuestionSet() !== null
        ? {...item, questionIndex: questionIndex++}
        : {...item, questionIndex: undefined}
    );
  }, [uniqueContentInteractionStatesWithContentsByContent]);

  const visibleCISWithContentsAndQuestionIndex = React.useMemo(() => {
    if (cisWithContentsAndQuestionIndex == null) return [];

    const completedCisWithContentsAndQuestionIndex = cisWithContentsAndQuestionIndex.filter(
      ({contentInteractionState}) => getIsCompleted(contentInteractionState)
    );

    if (isWrongQuestionOnly && (!hasVocabularyContent || vocaResultType === 'questions')) {
      return completedCisWithContentsAndQuestionIndex.filter(({contentInteractionState}) => {
        const statesByCase = getStatesByCase(contentInteractionState);

        return (
          statesByCase.oneOfCase === 'QUESTION' && statesByCase.states.some(state => state.answerState !== 'CORRECT')
        );
      });
    }

    if (hasVocabularyContent) {
      if (vocaResultType === 'vocas') {
        return completedCisWithContentsAndQuestionIndex.filter(
          ({content}) => contentRouterFactory.create(content).getVocabulary() !== null
        );
      }
      return completedCisWithContentsAndQuestionIndex.filter(
        ({content}) => contentRouterFactory.create(content).getQuestionSet() !== null
      );
    }

    return completedCisWithContentsAndQuestionIndex;
  }, [cisWithContentsAndQuestionIndex, isWrongQuestionOnly, hasVocabularyContent, vocaResultType]);

  const toggleIsWrongQuestionOnly = React.useCallback(() => {
    setIsWrongQuestionOnly(prevState => !prevState);
  }, []);

  const handleClickSwitchToVocas = React.useCallback(() => {
    setVocaResultType('vocas');
  }, []);

  const handleClickSwitchToQuestions = React.useCallback(() => {
    setVocaResultType('questions');
  }, []);

  // 어휘만 있을 경우 -> 탭 보여주지 않음
  // 어휘&문제가 모두 있을 경우 -> 어휘/문제 탭 보여줌
  // 어휘가 없을 경우 -> 탭 보여주지 않음

  React.useEffect(() => {
    if (focusedCardIndex != null) {
      contentCardsRef.current[focusedCardIndex]?.scrollIntoView();
    }
  }, [focusedCardIndex]);

  return (
    <LearningResultContainer
      learningResultTitle={
        hasVocabularyContent && !learningCell?.config.value.voca?.hasQuestions
          ? t('learning_cell_report_learning_voca_result')
          : t('learning_cell_report_learning_result')
      }>
      <SwitchContainer>
        {hasVocabularyContent && learningCell?.config.value.voca?.hasQuestions ? (
          <VocaQuestionSwitch
            vocaResultType={vocaResultType}
            onClickVocaResultVocas={handleClickSwitchToVocas}
            onClickVocaResultQuestions={handleClickSwitchToQuestions}
          />
        ) : null}
        {(!hasVocabularyContent || vocaResultType === 'questions') && (
          <WrongQuestionsOnlySwitch
            isWrongQuestionOnly={isWrongQuestionOnly}
            onClickIsWrongQuestionOnly={toggleIsWrongQuestionOnly}
          />
        )}
      </SwitchContainer>
      {visibleCISWithContentsAndQuestionIndex && visibleCISWithContentsAndQuestionIndex.length > 0 ? (
        <MultiColumn>
          {visibleCISWithContentsAndQuestionIndex.map(
            ({content, contentInteractionState, contentSummary, questionIndex}, index) => (
              <ContentCard
                ref={element => (contentCardsRef.current[index] = element)}
                key={index}
                content={content}
                contentInteractionState={contentInteractionState}
                contentSummary={contentSummary}
                questionIndex={questionIndex}
                questionTimeHidden={questionTimeHidden}
                onClick={generateContentCardClickHandler({
                  contentInteractionStateIndex: index,
                  isWrongOnly: isWrongQuestionOnly,
                  vocaResultType: vocaResultType,
                })}
              />
            )
          )}
        </MultiColumn>
      ) : (
        isWrongQuestionOnly && (
          <EmptyContentNotice
            css={css`
              margin-top: 32px;
              margin-bottom: 40px;

              @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
                margin: 100px 0;
              }
            `}
          />
        )
      )}
    </LearningResultContainer>
  );
};

export default LearningCellReportContentsView;
