import React, {HTMLAttributes, PropsWithChildren} from 'react';
import {css} from '@emotion/react';

type VirtualExamFloatingChatButtonBoxProps = HTMLAttributes<HTMLDivElement> & PropsWithChildren;

const VirtualExamFloatingChatButtonBox = ({children, ...props}: VirtualExamFloatingChatButtonBoxProps) => {
  return (
    <div
      css={css`
        position: absolute;
        bottom: var(--mobile-safe-area-bottom);
        width: 100%;
        max-width: 680px;
        pointer-events: none;
        transition: all 0.5s;
        opacity: 1;
        visibility: visible;
      `}
      {...props}>
      {children}
    </div>
  );
};

export default VirtualExamFloatingChatButtonBox;
export type {VirtualExamFloatingChatButtonBoxProps};
VirtualExamFloatingChatButtonBox.displayName = 'VirtualExamFloatingChatButtonBox';
