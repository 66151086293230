import {useRouter} from 'next/router';
import React from 'react';
import {useAtomValue} from 'jotai';
import useOnboardingEvents from '@app/api/google-tag-manager/onboarding';
import learningDomainAtom from '@app/atoms/core/learning-domain';
import {
  useOnboardingStep,
  useOnboardingStepCorrectionEffect,
  useUpdateUserProfileOnboardingInfoMutation,
} from '@app/features/onboarding/hooks';
import {onboardingInformationFactory} from '@app/features/onboarding/utils';
import {TargetScoreView} from '@app/features/onboarding/views';
import useUserQuery from '@app/hooks/user/useUser';

const TargetScoreContainer = () => {
  const router = useRouter();
  const learningDomain = useAtomValue(learningDomainAtom);
  const {pushSelectScoreEvent} = useOnboardingEvents();

  const {data: user} = useUserQuery();
  const {mutateAsync: updateMyOnboardingInfo, isLoading} = useUpdateUserProfileOnboardingInfoMutation();

  const {goToNextStep, goToPreviousStep} = useOnboardingStep();
  const onboardingInformation = onboardingInformationFactory(learningDomain.key);
  const isAnonymousUser = user?.registrationType === 'ANONYMOUS';

  const handleTargetScoreSelection = async (targetScore: number) => {
    pushSelectScoreEvent({score: targetScore, from_onb: isAnonymousUser ? 'registration_onb' : 'change_domain_onb'});
    await updateMyOnboardingInfo({targetScore});
    if (isAnonymousUser) {
      goToNextStep();
    } else {
      router.push('/');
    }
  };

  useOnboardingStepCorrectionEffect();

  return (
    <TargetScoreView
      isNarrow={!isAnonymousUser}
      isLoading={isLoading}
      buttonType={isAnonymousUser ? 'continue' : 'start'}
      domainName={learningDomain.displayName}
      defaultTargetScore={onboardingInformation.defaultTargetScore}
      targetScoreItems={onboardingInformation.targetScoreItems}
      onPick={handleTargetScoreSelection}
      onBackButtonClick={goToPreviousStep}
    />
  );
};

export default TargetScoreContainer;
TargetScoreContainer.displayName = 'TargetScoreContainer';
