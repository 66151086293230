/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { PointRewardLeaderBoardFromJSON, PointRewardLeaderBoardToJSON, } from './PointRewardLeaderBoard';
/**
 * Check if a given object implements the PointRewardLeaderBoardResponse interface.
 */
export function instanceOfPointRewardLeaderBoardResponse(value) {
    let isInstance = true;
    return isInstance;
}
export function PointRewardLeaderBoardResponseFromJSON(json) {
    return PointRewardLeaderBoardResponseFromJSONTyped(json, false);
}
export function PointRewardLeaderBoardResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'leaderBoard': !exists(json, 'leaderBoard') ? undefined : PointRewardLeaderBoardFromJSON(json['leaderBoard']),
    };
}
export function PointRewardLeaderBoardResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'leaderBoard': PointRewardLeaderBoardToJSON(value.leaderBoard),
    };
}
