import {atom} from 'jotai';

import {setAtomLabels} from '@app/misc/atom-label';
import {ServiceNotInitializedError} from '@app/test-preparation/atoms/services/shared/errors/not-initialized';
import {LearningCell} from '@app/types/learning-cell';

const _learningCellAtom = atom<LearningCell | null>(null);
const learningCellAtom = atom(
  get => {
    const learningCell = get(_learningCellAtom);

    if (learningCell === null) {
      throw new ServiceNotInitializedError('LearningCellService');
    }

    return learningCell;
  },
  (get, set, learningCell: LearningCell) => {
    set(_learningCellAtom, learningCell);
  }
);

export {learningCellAtom};

setAtomLabels(
  {
    _learningCellAtom,
    learningCellAtom,
  },
  'learningCellService.'
);
