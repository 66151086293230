import {OrderPass, OrderTicketGroup, PassListItemV2, TicketGroup} from '@santa-web/gen/open-api/service';
import {PassCardProps, TicketCardProps} from '@app/features/permit/components';

const mapOrderPassStatusToPassCardStatus = (status: OrderPass['status']): PassCardProps['status'] => {
  switch (status.singlePurchase) {
    case 'PENDING':
      return 'pending';
    case 'ACTIVE':
      return 'in-use';
    case 'EXPIRED':
      return 'expired';
    case 'REFUNDED':
      return 'refunded';
    default:
      switch (status.subscription) {
        case 'ACTIVE':
          return 'in-use';
        case 'CANCELED':
          return 'in-use';
        case 'EXPIRED':
          return 'expired';
        case 'REFUNDED':
          return 'refunded';
        case 'TERMINATED':
          return 'in-use';
      }
  }
  throw new Error('unexpected status');
};

export const mapPassToPassCardProps = (pass: OrderPass | PassListItemV2, canActivatePass: boolean): PassCardProps => {
  const isSubscription = pass.status.subscription !== undefined;
  return {
    title: pass.displayInfo.name,
    description: pass.displayInfo.description,
    status: mapOrderPassStatusToPassCardStatus(pass.status),
    isSubscription,
    period: {
      from: pass.createdAt,
      to: pass.expireAt,
    },
    canStartPass: canActivatePass && (isSubscription ? false : pass.status.singlePurchase === 'PENDING'),
  };
};

export const mapTicketGroupToTicketCardProps = (ticketGroup: TicketGroup | OrderTicketGroup): TicketCardProps => {
  return {
    title: ticketGroup.displayInfo.name,
    description: ticketGroup.displayInfo.description,
    status: (() => {
      switch (ticketGroup.status) {
        case 'ACTIVE':
          return 'in-use';
        case 'USED_ALL':
          return ticketGroup.permitType === 'RECOMMENDED_LEARNING_CONTENT' ? 'used' : 'in-use';
        case 'EXPIRED':
          return 'expired';
        case 'REFUNDED':
          return 'refunded';
      }
    })(),
    expireAt: ticketGroup.expiredAt,
    countInfo:
      ticketGroup.permitType === 'RECOMMENDED_LEARNING_CONTENT'
        ? {total: ticketGroup.tickets.length, remained: ticketGroup.tickets.filter(({isUsed}) => !isUsed).length}
        : undefined,
    type: (() => {
      switch (ticketGroup.permitType) {
        case 'DOWNLOADABLE_FILE':
          return 'file-download';
        case 'EXTERNAL_LINK':
          return 'information-input';
        case 'GIFT_CODE':
          return 'gift-code';
        case 'RECOMMENDED_LEARNING_CONTENT':
          return 'course-learning';
      }
    })(),
  };
};
