/**
 * Check if a given object implements the SignupRequestAgreementInfo interface.
 */
export function instanceOfSignupRequestAgreementInfo(value) {
    let isInstance = true;
    isInstance = isInstance && "age14" in value;
    isInstance = isInstance && "marketing" in value;
    isInstance = isInstance && "privacy" in value;
    isInstance = isInstance && "push" in value;
    isInstance = isInstance && "serviceTerms" in value;
    return isInstance;
}
export function SignupRequestAgreementInfoFromJSON(json) {
    return SignupRequestAgreementInfoFromJSONTyped(json, false);
}
export function SignupRequestAgreementInfoFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'age14': json['age14'],
        'marketing': json['marketing'],
        'privacy': json['privacy'],
        'push': json['push'],
        'serviceTerms': json['serviceTerms'],
    };
}
export function SignupRequestAgreementInfoToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'age14': value.age14,
        'marketing': value.marketing,
        'privacy': value.privacy,
        'push': value.push,
        'serviceTerms': value.serviceTerms,
    };
}
