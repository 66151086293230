import {useMemo} from 'react';
import {Portal} from 'react-portal';
import {css} from '@emotion/react';

import {ElementInteractionState, QuestionState} from '@santa-web/gen/open-api/content-learning-service';
import {Question} from '@santa-web/gen/open-api/content/models/Question';
import {DocumentBlockElements} from '@santa-web/gen/ssp/ubershape/riiid-rich-text-v2';
import {SingleQuestionTitle, PlayButton} from '@santa-web/service-ui';
import ReplayAudioTutorialTooltip from '@app/components/ContentView/components/ReplayAudioTutorialTooltip';
import {use100vh} from '@app/hooks/use100vh';
import QuestionView, {ChoiceAnswerHandler} from '@app/test-preparation/components/QuestionView';
import {IsUserHighlightEnabledContext} from '@app/test-preparation/contexts/IsUserHighlightEnabledContext';
import {SnippetViewBaseIdContext} from '@app/test-preparation/contexts/SnippetViewBaseIdContext';

export interface ToeicPart1and2ViewProps {
  audio: React.ReactNode;
  soundController: React.ReactNode;
  shouldShowReplayAudioTutorial: boolean;
  isLoading: boolean;
  isQuestionViewDisabled: boolean;
  isExplanationVisible: boolean;
  isQuestionDescriptionHidden: boolean;
  question: Question | null | undefined;
  questionElementState: ElementInteractionState | undefined;
  questionTitle: string;
  onQuestionStateChange?(questionState: QuestionState): void;
  onPlayButtonClick(): void;
  canSelectUnsolvableQuestion: boolean;
  onChoiceAnswer?: ChoiceAnswerHandler;
  isUserHighlightEnabled: boolean;
  cisId: number;
  contentId: string;
  isCompleted: boolean;
}

export const fillToeicPart1To2Choices = (question: Question | null | undefined): Question => {
  const documentBlockElements: DocumentBlockElements = {
    children: [
      [
        'paragraph',
        {
          children: [['text', {value: 'Mark your answer'}]],
        },
      ],
    ],
  };
  const objective = question?.oneOfTypeSpec.objective;

  return {
    oneOfExplanationType: {
      oneOfCase: 'EXPLANATION',
      explanation: undefined,
    },
    attachedResourceIds: [],
    tagIds: [],
    descriptionTranslation: {
      oneOfData: {
        oneOfCase: 'TEXT',
        text: '',
      },
    },
    description: {
      oneOfData: {
        oneOfCase: 'TEXT',
        text: '',
      },
    },
    id: '',
    audioResourceId: '',
    ...question,
    oneOfTypeSpec: {
      oneOfCase: 'OBJECTIVE',
      objective: {
        choiceTranslations: [],
        oneOfAnswers: {
          oneOfCase: 'AND_ANSWER',
        },
        ...objective,
        choices:
          objective?.choices.map(() => ({
            oneOfData: {
              oneOfCase: 'RRT_V2_BLOCK_ELEMENTS_JSON',
              rrtV2BlockElementsJson: JSON.stringify(documentBlockElements),
            },
          })) ?? [],
      },
    },
  };
};

const ToeicPart1and2View = ({
  audio,
  soundController,
  shouldShowReplayAudioTutorial,
  isLoading,
  isQuestionViewDisabled,
  isExplanationVisible,
  isQuestionDescriptionHidden,
  question,
  questionElementState,
  questionTitle,
  onQuestionStateChange,
  onPlayButtonClick,
  canSelectUnsolvableQuestion,
  onChoiceAnswer,
  isUserHighlightEnabled,
  cisId,
  contentId,
  isCompleted,
}: ToeicPart1and2ViewProps) => {
  const viewportHeight = use100vh();
  const currentStepQuestion = useMemo(() => {
    if (isExplanationVisible) {
      return question;
    }

    if (isQuestionDescriptionHidden) {
      return {
        ...fillToeicPart1To2Choices(question),
        description: undefined,
      };
    }

    return fillToeicPart1To2Choices(question);
  }, [isExplanationVisible, isQuestionDescriptionHidden, question]);

  const playButton = (
    <ReplayAudioTutorialTooltip isOpened={shouldShowReplayAudioTutorial}>
      {isExplanationVisible && <PlayButton isDisabled={isLoading} onClick={onPlayButtonClick} />}
    </ReplayAudioTutorialTooltip>
  );

  const soundControllerElement = (
    <Portal>
      <div
        css={css`
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          height: ${viewportHeight};
          pointer-events: none;
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
        `}>
        {soundController}
      </div>
    </Portal>
  );

  return (
    <SnippetViewBaseIdContext.Provider value={cisId.toString()}>
      <IsUserHighlightEnabledContext.Provider value={isUserHighlightEnabled}>
        {audio}
        {soundControllerElement}
        <QuestionView
          isDisabled={isQuestionViewDisabled}
          isExplanationInvisible={!isExplanationVisible}
          questionTitle={<SingleQuestionTitle content={questionTitle} playButton={playButton} />}
          question={currentStepQuestion}
          questionState={questionElementState?.details.question}
          onChange={onQuestionStateChange}
          canSelectUnsolvableQuestion={canSelectUnsolvableQuestion}
          onChoiceAnswer={onChoiceAnswer}
          elementInteractionStateId={questionElementState?.id}
          isCompleted={isCompleted}
          contentId={contentId}
        />
      </IsUserHighlightEnabledContext.Provider>
    </SnippetViewBaseIdContext.Provider>
  );
};

export default ToeicPart1and2View;
