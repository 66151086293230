/**
 * Check if a given object implements the QuestionStateObjective interface.
 */
export function instanceOfQuestionStateObjective(value) {
    let isInstance = true;
    isInstance = isInstance && "correctAnswer" in value;
    isInstance = isInstance && "eliminatedAnswers" in value;
    isInstance = isInstance && "userAnswer" in value;
    return isInstance;
}
export function QuestionStateObjectiveFromJSON(json) {
    return QuestionStateObjectiveFromJSONTyped(json, false);
}
export function QuestionStateObjectiveFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'correctAnswer': json['correctAnswer'],
        'eliminatedAnswers': json['eliminatedAnswers'],
        'userAnswer': json['userAnswer'],
    };
}
export function QuestionStateObjectiveToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'correctAnswer': value.correctAnswer,
        'eliminatedAnswers': value.eliminatedAnswers,
        'userAnswer': value.userAnswer,
    };
}
