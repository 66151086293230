import React from 'react';
import {css} from '@emotion/react';
import {
  COLOR_SANTA_G,
  COLOR_SANTA_BD,
  COLOR_RIIID_DOBG,
  COLOR_RIIID_DOBI,
  COLOR_FIX_SANTA_WHITE_1,
  COLOR_SANTA_CARD_C,
} from '@riiid/design-system';
import CheckIcon from '@riiid/design-system/icons/Check';
import FontsIcon from '@riiid/design-system/icons/Fonts';
import LockIcon from '@riiid/design-system/icons/Lock';
import PaperIcon from '@riiid/design-system/icons/Paper';
import PlaytimeIcon from '@riiid/design-system/icons/Playtime';
import QuestionIcon from '@riiid/design-system/icons/Question';
import SpecialOfferIcon from '@riiid/design-system/icons/SpecialOffer';
import TestreportIcon from '@riiid/design-system/icons/Testreport';

import {LearningCycleCellType} from '@santa-web/gen/open-api/content-learning-service';
import exhaustiveCheck from '@app/utils/exhaustiveCheck';

import StudyCellIconTag from './StudyCellIconTag';

export interface StudyCellIconOwnProps {
  type: LearningCycleCellType;
  isDone?: boolean;
  isLocked?: boolean;
}

export interface StudyCellIconProps extends StudyCellIconOwnProps, React.ComponentPropsWithoutRef<'div'> {}

const StudyCellIcon = React.forwardRef<HTMLDivElement, StudyCellIconProps>(
  ({type, isDone, isLocked, ...props}, ref) => {
    const iconProps = {
      width: 32,
      height: 32,
      color: isDone ? COLOR_SANTA_CARD_C : COLOR_FIX_SANTA_WHITE_1,
    };

    const {icon, backgroundColor} = (() => {
      switch (type) {
        case LearningCycleCellType.QUESTION:
          return {
            icon: <QuestionIcon data-testid="StudyCellIcon-QuestionIcon" type="line" {...iconProps} />,
            backgroundColor: COLOR_SANTA_BD,
          };
        case LearningCycleCellType.LESSON:
          return {
            icon: <PlaytimeIcon data-testid="StudyCellIcon-LessonIcon" type="solid" {...iconProps} />,
            backgroundColor: COLOR_RIIID_DOBG,
          };
        case LearningCycleCellType.VOCABULARY:
          return {
            icon: <FontsIcon data-testid="StudyCellIcon-VocaIcon" type="line" {...iconProps} />,
            backgroundColor: COLOR_RIIID_DOBI,
          };
        case LearningCycleCellType.REVIEW:
          return {
            icon: <TestreportIcon data-testid="StudyCellIcon-ReviewIcon" type="solid" {...iconProps} />,
            backgroundColor: COLOR_SANTA_BD,
          };
        case LearningCycleCellType.DIAGNOSIS:
          return {
            icon: <PaperIcon data-testid="StudyCellIcon-DiagnosisIcon" type="solid" {...iconProps} />,
            backgroundColor: COLOR_SANTA_BD,
          };
        case LearningCycleCellType.MOCK_EXAM:
          return {
            icon: <SpecialOfferIcon data-testid="StudyCellIcon-MockExamIcon" type="solid" {...iconProps} />,
            backgroundColor: COLOR_SANTA_BD,
          };
        /* istanbul ignore next */
        default:
          return exhaustiveCheck(type);
      }
    })();

    const tag = (() => {
      if (isDone) {
        return (
          <StudyCellIconTag
            data-testid="StudyCellIcon-DoneIconTag"
            Icon={CheckIcon}
            IconProps={{type: 'line'}}
            isDone={isDone}
          />
        );
      }

      // `isLocked` is only available in `review` type.
      if (isLocked) {
        return (
          <StudyCellIconTag
            data-testid="StudyCellIcon-LockIconTag"
            Icon={LockIcon}
            IconProps={{type: 'solid'}}
            isDone={isDone}
          />
        );
      }

      if (type === LearningCycleCellType.REVIEW) {
        return <StudyCellIconTag content="OPEN" isDone={isDone} />;
      }

      return null;
    })();

    return (
      <div
        css={css`
          position: relative;
          padding: 10px;
          border-radius: 100%;
          background-color: ${isDone ? COLOR_SANTA_G : backgroundColor};

          display: inline-flex;
          align-items: center;
          justify-content: center;
        `}
        ref={ref}
        {...props}>
        {icon}
        {tag && (
          <div
            css={css`
              position: absolute;
              transform: translate(-100%, -100%);
              top: 16px;
              left: 16px;

              width: max-content;
            `}>
            {tag}
          </div>
        )}
      </div>
    );
  }
);

export default React.memo(StudyCellIcon);
