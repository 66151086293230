import {useMutation, useQueryClient} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';
import {Order} from '@santa-web/gen/open-api/service';
import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {getOfferGroupOrderQueryKey} from '@app/queryKeys';

export const useOfferGroupOrderCancelCouponMutation = (orderId: number) => {
  const {OrderService} = useAtomValue(santaOpenapiServicesAtom);
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async (couponId: number): Promise<Order> => {
      const order = queryClient.getQueryData<Order>(getOfferGroupOrderQueryKey(orderId))!;
      return await OrderService.configureCoupon({
        id: orderId,
        configureCouponRequest: {couponIds: (order.orderConfig!.appliedCouponIds ?? []).filter(id => id !== couponId)},
      }).then(({order}) => order);
    },
    onSuccess: order => {
      queryClient.setQueryData(getOfferGroupOrderQueryKey(order.id), order);
    },
  });
};
