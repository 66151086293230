import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {COLOR_SANTA_BC_ALPHA, COLOR_SANTA_DC_ALPHA, COLOR_SANTA_L} from '@riiid/design-system';
import CheckWithCircleIcon from '@riiid/design-system/icons/Checkwithcircle';
import CloseWithCircleIcon from '@riiid/design-system/icons/Closewithcircle';
import {Typography} from '@santa-web/service-ui';

export enum ContentsLevel {
  VERY_EASY = 'VERY_EASY',
  EASY = 'EASY',
  NORMAL = 'NORMAL',
  HARD = 'HARD',
  VERY_HARD = 'VERY_HARD',
}

type ContentsLevelInformationProps = {
  isCorrect?: boolean;
  level: ContentsLevel;
};

const BoldComponent = <Typography component="span" variant="caption1" fontWeight="bold" />;

const ContentsLevelInformation = ({isCorrect = false, level}: ContentsLevelInformationProps) => {
  const {t} = useTranslation();

  const Icon = isCorrect ? CheckWithCircleIcon : CloseWithCircleIcon;
  const description = React.useMemo(() => {
    switch (level) {
      case ContentsLevel.VERY_EASY:
        return isCorrect ? (
          <Trans
            t={t}
            i18nKey="testprep_contents_level_information_correct_very_easy"
            components={{bold: BoldComponent}}
          />
        ) : (
          <Trans
            t={t}
            i18nKey="testprep_contents_level_information_incorrect_very_easy"
            components={{bold: BoldComponent}}
          />
        );
      case ContentsLevel.EASY:
        return isCorrect ? (
          <Trans t={t} i18nKey="testprep_contents_level_information_correct_easy" components={{bold: BoldComponent}} />
        ) : (
          <Trans
            t={t}
            i18nKey="testprep_contents_level_information_incorrect_easy"
            components={{bold: BoldComponent}}
          />
        );
      case ContentsLevel.NORMAL:
        return isCorrect ? (
          <Trans
            t={t}
            i18nKey="testprep_contents_level_information_correct_normal"
            components={{bold: BoldComponent}}
          />
        ) : (
          <Trans
            t={t}
            i18nKey="testprep_contents_level_information_incorrect_normal"
            components={{bold: BoldComponent}}
          />
        );
      case ContentsLevel.HARD:
        return isCorrect ? (
          <Trans t={t} i18nKey="testprep_contents_level_information_correct_hard" components={{bold: BoldComponent}} />
        ) : (
          <Trans
            t={t}
            i18nKey="testprep_contents_level_information_incorrect_hard"
            components={{bold: BoldComponent}}
          />
        );
      case ContentsLevel.VERY_HARD:
        return isCorrect ? (
          <Trans
            t={t}
            i18nKey="testprep_contents_level_information_correct_very_hard"
            components={{bold: BoldComponent}}
          />
        ) : (
          <Trans
            t={t}
            i18nKey="testprep_contents_level_information_incorrect_very_hard"
            components={{bold: BoldComponent}}
          />
        );
    }
  }, [isCorrect, level, t]);

  return (
    <div
      css={css`
        display: grid;
        grid-template-columns: auto 1fr;
        gap: 8px;
        width: 100%;
        padding: 16px;
        border-radius: 8px;
        background-color: ${isCorrect ? COLOR_SANTA_BC_ALPHA(0.3) : COLOR_SANTA_DC_ALPHA(0.3)};
      `}>
      <div
        css={css`
          width: 32px;
          position: relative;
        `}>
        <Icon
          css={css`
            position: absolute;
            top: min(50%, 20px);
            transform: translateY(-50%);
          `}
          type="line"
          width={32}
          height={32}
          color={COLOR_SANTA_L}
        />
      </div>
      <Typography
        css={css`
          align-self: center;
        `}
        component="p"
        variant="caption1"
        color={COLOR_SANTA_L}>
        {description}
      </Typography>
    </div>
  );
};

export default ContentsLevelInformation;
export type {ContentsLevelInformationProps};
ContentsLevelInformation.displayName = 'ContentsLevelInformation';
