/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { GetSelfLearningAllQuestionResponseFromJSON, GetSelfLearningCellIdResponseFromJSON, GetSelfLearningContentTypeResponseFromJSON, GetSelfLearningUnitGroupDetailResponseFromJSON, GetSelfLearningUnitGroupFiltersResponseFromJSON, GetSelfLearningUnitGroupsResponseFromJSON, GetWeaknessBlockResponseFromJSON, ListSelfLearningUnitFiltersResponseFromJSON, ListSelfLearningUnitsResponseFromJSON, } from '../models';
/**
 *
 */
export class SelfLearningV1Api extends runtime.BaseAPI {
    /**
     *
     * 선택학습 그룹 모든 문제 관련 정보를 가져온다.
     */
    async getSelfLearningAllQuestionRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getSelfLearningAllQuestion.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/content-learning/v1/self-learning/unit-groups/{id}/all-question`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetSelfLearningAllQuestionResponseFromJSON(jsonValue));
    }
    /**
     *
     * 선택학습 그룹 모든 문제 관련 정보를 가져온다.
     */
    async getSelfLearningAllQuestion(requestParameters, initOverrides) {
        const response = await this.getSelfLearningAllQuestionRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 선택학습 셀 ID를 가져온다.  없으면 새로운 Cell을 생성하여 생성된 셀 ID를 반환하고, 이미 존재하면 존재하던 셀 ID를 반환한다.
     * 선택학습 셀 ID를 가져온다.
     */
    async getSelfLearningCellIdRaw(requestParameters, initOverrides) {
        if (requestParameters.contentSelectionId === null || requestParameters.contentSelectionId === undefined) {
            throw new runtime.RequiredError('contentSelectionId', 'Required parameter requestParameters.contentSelectionId was null or undefined when calling getSelfLearningCellId.');
        }
        const queryParameters = {};
        if (requestParameters.contentSelectionId !== undefined) {
            queryParameters['contentSelectionId'] = requestParameters.contentSelectionId;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/api/content-learning/v1/self-learning/cells`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetSelfLearningCellIdResponseFromJSON(jsonValue));
    }
    /**
     * 선택학습 셀 ID를 가져온다.  없으면 새로운 Cell을 생성하여 생성된 셀 ID를 반환하고, 이미 존재하면 존재하던 셀 ID를 반환한다.
     * 선택학습 셀 ID를 가져온다.
     */
    async getSelfLearningCellId(requestParameters, initOverrides) {
        const response = await this.getSelfLearningCellIdRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     *
     * 선택학습 유닛 그룹 디테일을 가져온다.
     */
    async getSelfLearningUnitGroupDetailRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling getSelfLearningUnitGroupDetail.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/content-learning/v1/self-learning/unit-groups/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetSelfLearningUnitGroupDetailResponseFromJSON(jsonValue));
    }
    /**
     *
     * 선택학습 유닛 그룹 디테일을 가져온다.
     */
    async getSelfLearningUnitGroupDetail(requestParameters, initOverrides) {
        const response = await this.getSelfLearningUnitGroupDetailRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 선택학습 컨텐츠 타입 목록을 가져온다.
     * 선택학습 컨텐츠 타입 목록을 가져온다.
     */
    async listSelfLearningContentTypesRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/content-learning/v1/self-learning/content-types`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetSelfLearningContentTypeResponseFromJSON(jsonValue));
    }
    /**
     * 선택학습 컨텐츠 타입 목록을 가져온다.
     * 선택학습 컨텐츠 타입 목록을 가져온다.
     */
    async listSelfLearningContentTypes(requestParameters, initOverrides) {
        const response = await this.listSelfLearningContentTypesRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 선택학습 그룹 유닛 필터 목록을 가져온다.
     * 선택학습 그룹 유닛 필터 목록을 가져온다.
     */
    async listSelfLearningUnitFiltersRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling listSelfLearningUnitFilters.');
        }
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/content-learning/v1/self-learning/unit-groups/{id}/filters`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListSelfLearningUnitFiltersResponseFromJSON(jsonValue));
    }
    /**
     * 선택학습 그룹 유닛 필터 목록을 가져온다.
     * 선택학습 그룹 유닛 필터 목록을 가져온다.
     */
    async listSelfLearningUnitFilters(requestParameters, initOverrides) {
        const response = await this.listSelfLearningUnitFiltersRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 선택학습 유닛 그룹 필터 목록을 가져온다.
     * 선택학습 유닛 그룹 필터 목록을 가져온다.
     */
    async listSelfLearningUnitGroupFiltersRaw(requestParameters, initOverrides) {
        if (requestParameters.typeId === null || requestParameters.typeId === undefined) {
            throw new runtime.RequiredError('typeId', 'Required parameter requestParameters.typeId was null or undefined when calling listSelfLearningUnitGroupFilters.');
        }
        const queryParameters = {};
        if (requestParameters.typeId !== undefined) {
            queryParameters['typeId'] = requestParameters.typeId;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/api/content-learning/v1/self-learning/unit-group-filters`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetSelfLearningUnitGroupFiltersResponseFromJSON(jsonValue));
    }
    /**
     * 선택학습 유닛 그룹 필터 목록을 가져온다.
     * 선택학습 유닛 그룹 필터 목록을 가져온다.
     */
    async listSelfLearningUnitGroupFilters(requestParameters, initOverrides) {
        const response = await this.listSelfLearningUnitGroupFiltersRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 선택학습 유닛 그룹 목록을 가져온다.
     * 선택학습 유닛 그룹 목록을 가져온다.
     */
    async listSelfLearningUnitGroupsRaw(requestParameters, initOverrides) {
        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size', 'Required parameter requestParameters.size was null or undefined when calling listSelfLearningUnitGroups.');
        }
        if (requestParameters.typeId === null || requestParameters.typeId === undefined) {
            throw new runtime.RequiredError('typeId', 'Required parameter requestParameters.typeId was null or undefined when calling listSelfLearningUnitGroups.');
        }
        const queryParameters = {};
        if (requestParameters.cursor !== undefined) {
            queryParameters['cursor'] = requestParameters.cursor;
        }
        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }
        if (requestParameters.typeId !== undefined) {
            queryParameters['typeId'] = requestParameters.typeId;
        }
        if (requestParameters.filterId !== undefined) {
            queryParameters['filterId'] = requestParameters.filterId;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/api/content-learning/v1/self-learning/unit-groups`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetSelfLearningUnitGroupsResponseFromJSON(jsonValue));
    }
    /**
     * 선택학습 유닛 그룹 목록을 가져온다.
     * 선택학습 유닛 그룹 목록을 가져온다.
     */
    async listSelfLearningUnitGroups(requestParameters, initOverrides) {
        const response = await this.listSelfLearningUnitGroupsRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 선택학습 유닛 목록을 가져온다.
     * 선택학습 유닛 목록을 가져온다.
     */
    async listSelfLearningUnitsRaw(requestParameters, initOverrides) {
        if (requestParameters.id === null || requestParameters.id === undefined) {
            throw new runtime.RequiredError('id', 'Required parameter requestParameters.id was null or undefined when calling listSelfLearningUnits.');
        }
        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size', 'Required parameter requestParameters.size was null or undefined when calling listSelfLearningUnits.');
        }
        const queryParameters = {};
        if (requestParameters.filterId !== undefined) {
            queryParameters['filterId'] = requestParameters.filterId;
        }
        if (requestParameters.cursor !== undefined) {
            queryParameters['cursor'] = requestParameters.cursor;
        }
        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/api/content-learning/v1/self-learning/unit-groups/{id}/units`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters.id))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => ListSelfLearningUnitsResponseFromJSON(jsonValue));
    }
    /**
     * 선택학습 유닛 목록을 가져온다.
     * 선택학습 유닛 목록을 가져온다.
     */
    async listSelfLearningUnits(requestParameters, initOverrides) {
        const response = await this.listSelfLearningUnitsRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * rank는 목록의 order로 판별 한다.
     * 취약 개념 목록을 가져온다.
     */
    async listWeaknessBlocksRaw(requestParameters, initOverrides) {
        if (requestParameters.size === null || requestParameters.size === undefined) {
            throw new runtime.RequiredError('size', 'Required parameter requestParameters.size was null or undefined when calling listWeaknessBlocks.');
        }
        const queryParameters = {};
        if (requestParameters.cursor !== undefined) {
            queryParameters['cursor'] = requestParameters.cursor;
        }
        if (requestParameters.size !== undefined) {
            queryParameters['size'] = requestParameters.size;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/api/content-learning/v1/self-learning/weakness-blocks`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetWeaknessBlockResponseFromJSON(jsonValue));
    }
    /**
     * rank는 목록의 order로 판별 한다.
     * 취약 개념 목록을 가져온다.
     */
    async listWeaknessBlocks(requestParameters, initOverrides) {
        const response = await this.listWeaknessBlocksRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
