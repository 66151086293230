function _array_like_to_array(arr, len) {
    if (len == null || len > arr.length) len = arr.length;
    for(var i = 0, arr2 = new Array(len); i < len; i++)arr2[i] = arr[i];
    return arr2;
}
function _array_without_holes(arr) {
    if (Array.isArray(arr)) return _array_like_to_array(arr);
}
function _iterable_to_array(iter) {
    if (typeof Symbol !== "undefined" && iter[Symbol.iterator] != null || iter["@@iterator"] != null) return Array.from(iter);
}
function _non_iterable_spread() {
    throw new TypeError("Invalid attempt to spread non-iterable instance.\\nIn order to be iterable, non-array objects must have a [Symbol.iterator]() method.");
}
function _to_consumable_array(arr) {
    return _array_without_holes(arr) || _iterable_to_array(arr) || _unsupported_iterable_to_array(arr) || _non_iterable_spread();
}
function _unsupported_iterable_to_array(o, minLen) {
    if (!o) return;
    if (typeof o === "string") return _array_like_to_array(o, minLen);
    var n = Object.prototype.toString.call(o).slice(8, -1);
    if (n === "Object" && o.constructor) n = o.constructor.name;
    if (n === "Map" || n === "Set") return Array.from(n);
    if (n === "Arguments" || /^(?:Ui|I)nt(?:8|16|32)(?:Clamped)?Array$/.test(n)) return _array_like_to_array(o, minLen);
}
import { useEffect, useRef } from "react";
import * as uuid from "uuid";
import useFloatingChatButtonList from "../useFloatingChatButtonList";
export default function useFloatingChatButtons(items) {
    var _useFloatingChatButtonList = useFloatingChatButtonList(), setFloatingChatButtonProps = _useFloatingChatButtonList.setFloatingChatButtonProps, deleteFloatingChatButton = _useFloatingChatButtonList.deleteFloatingChatButton;
    var keysRef = useRef(new Set());
    var keysMap = useRef(new Map());
    useEffect(function() {
        var previousKeys = keysRef.current;
        var currentKeys = new Set(items.map(getKey));
        var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
        try {
            for(var _iterator = items[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
                var item = _step.value;
                setFloatingChatButtonProps(getKey(item), item.priority, item.props);
            }
        } catch (err) {
            _didIteratorError = true;
            _iteratorError = err;
        } finally{
            try {
                if (!_iteratorNormalCompletion && _iterator.return != null) {
                    _iterator.return();
                }
            } finally{
                if (_didIteratorError) {
                    throw _iteratorError;
                }
            }
        }
        var _iteratorNormalCompletion1 = true, _didIteratorError1 = false, _iteratorError1 = undefined;
        try {
            for(var _iterator1 = _to_consumable_array(previousKeys).filter(function(key) {
                return !currentKeys.has(key);
            })[Symbol.iterator](), _step1; !(_iteratorNormalCompletion1 = (_step1 = _iterator1.next()).done); _iteratorNormalCompletion1 = true){
                var key = _step1.value;
                deleteFloatingChatButton(key);
            }
        } catch (err) {
            _didIteratorError1 = true;
            _iteratorError1 = err;
        } finally{
            try {
                if (!_iteratorNormalCompletion1 && _iterator1.return != null) {
                    _iterator1.return();
                }
            } finally{
                if (_didIteratorError1) {
                    throw _iteratorError1;
                }
            }
        }
        keysRef.current = currentKeys;
    }, [
        deleteFloatingChatButton,
        setFloatingChatButtonProps,
        items
    ]);
    useEffect(function() {
        return function() {
            var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
            try {
                for(var _iterator = keysRef.current[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
                    var key = _step.value;
                    deleteFloatingChatButton(key);
                }
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally{
                try {
                    if (!_iteratorNormalCompletion && _iterator.return != null) {
                        _iterator.return();
                    }
                } finally{
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
            }
        };
    }, [
        deleteFloatingChatButton
    ]);
    function getKey(item) {
        if (item.key != null) return item.key;
        var _keysMap_current_get;
        var key = (_keysMap_current_get = keysMap.current.get(item.priority)) !== null && _keysMap_current_get !== void 0 ? _keysMap_current_get : Symbol(uuid.v4());
        keysMap.current.set(item.priority, key);
        return key;
    }
}
