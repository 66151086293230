import React from 'react';
import {useTranslation} from 'react-i18next';

import {useAtomValue} from 'jotai';
import {TopNavBar as TopNavBarBase, TopNavBarProps} from '@santa-web/service-ui';
import {learningStore} from '@app/test-preparation/atoms/stores';

type Props = TopNavBarProps;

const ToeicCourseVocabularyTopNavBar = (props: Props): JSX.Element => {
  const {t} = useTranslation();

  const currentCisIndex = useAtomValue(learningStore.cises.currentCisIndexAtom);
  const questionCisCount = useAtomValue(learningStore.cises.questionCisCountAtom);
  const vocabularyCisCount = useAtomValue(learningStore.cises.vocabularyCisCountAtom);
  const totalElapsedTime = useAtomValue(learningStore.cises.totalElapsedTimeAtom);

  const isQuestion = useAtomValue(learningStore.cis.isQuestionAtom);

  const currentSessionCisIndex = useAtomValue(learningStore.vocabularySession.currentSessionCisIndexAtom);
  const currentSessionCisCount = useAtomValue(learningStore.vocabularySession.currentSessionCisCountAtom);
  const isAllReported = useAtomValue(learningStore.vocabularySession.isAllReportedAtom);

  const totalCisCount = isQuestion ? questionCisCount : currentSessionCisCount;
  const currentIndex = isQuestion ? currentCisIndex - vocabularyCisCount : currentSessionCisIndex;
  const restCisCount = totalCisCount - currentIndex;
  const isLastCis = restCisCount <= 1;

  const content = (() => {
    if (isQuestion) {
      if (isLastCis) {
        return t('learning_cell_top_nav_bar_course_vocabulary_content_last_question');
      } else {
        return t('learning_cell_top_nav_bar_course_vocabulary_content_question', {
          numberOfQuestions: restCisCount,
        });
      }
    } else {
      if (isAllReported) {
        return;
      }

      if (isLastCis) {
        return t('learning_cell_top_nav_bar_course_vocabulary_content_last_vocabulary');
      } else {
        return t('learning_cell_top_nav_bar_course_vocabulary_content_vocabulary', {
          numberOfWords: restCisCount,
        });
      }
    }
  })();
  const progress = (currentIndex + 1) / totalCisCount;
  const timerSeconds = totalElapsedTime / 1000;

  return (
    <TopNavBarBase content={content} paginationIcon="exit" progress={progress} timerSeconds={timerSeconds} {...props} />
  );
};

export default React.memo(ToeicCourseVocabularyTopNavBar);
