import React from 'react';
import {css} from '@emotion/react';
import {COLOR_SANTA_BC, COLOR_SANTA_G, COLOR_SANTA_I} from '@riiid/design-system';

import {Typography} from '@santa-web/service-ui';

import {AnalyticsRadarCardDataTuple} from '.';

export interface PentagonChartBackgroundProps extends React.ComponentPropsWithoutRef<'svg'> {
  data: AnalyticsRadarCardDataTuple;
  isScoreHidden?: boolean;
}

const POSITION = {
  3: [
    {subjectX: 160, positionY: 18},
    {subjectX: 55, positionY: 230},
    {subjectX: 265, positionY: 230},
  ],
  4: [
    {subjectX: 242, positionY: 44},
    {subjectX: 78, positionY: 44},
    {subjectX: 78, positionY: 244},
    {subjectX: 242, positionY: 244},
  ],
  5: [
    {subjectX: 160, positionY: 20},
    {subjectX: 274, positionY: 88},
    {subjectX: 245, positionY: 241},
    {subjectX: 75, positionY: 241},
    {subjectX: 46, positionY: 88},
  ],
};

// 현재 3~5각만 포함하고 있습니다. 추가 시 수정 필요합니다.
const RadarChartBackground = React.forwardRef<SVGSVGElement, PentagonChartBackgroundProps>(
  ({data, isScoreHidden = false, ...props}, ref) => {
    const width = 320;
    const height = 279;
    const position = data.length === 3 || data.length === 4 || data.length === 5 ? POSITION[data.length] : [];
    return (
      <svg
        width={320}
        height={height}
        viewBox={`0 0 ${width} ${height}`}
        fill="none"
        overflow="visible"
        {...props}
        ref={ref}>
        {position.map((item, index) => (
          <foreignObject
            key={index}
            x={item.subjectX}
            y={item.positionY}
            overflow={'visible'}
            height={'100%'}
            width={100}>
            <div
              css={css`
                display: flex;
                flex-direction: column;
                text-align: center;
                transform: translateY(-50%) translateX(-50%);
                word-break: break-all;
              `}>
              <Typography fontWeight="regular" variant="caption1" color={COLOR_SANTA_I}>
                {data[index].subject}
              </Typography>
              <Typography fontWeight="bold" variant="body3">
                <span
                  css={css`
                    color: ${COLOR_SANTA_BC};
                  `}>
                  {isScoreHidden ? '?' : data[index].nowPercent}
                </span>
                <span
                  css={css`
                    color: ${COLOR_SANTA_G};
                  `}>
                  {'/'}
                </span>
                <span
                  css={css`
                    color: ${COLOR_SANTA_I};
                  `}>
                  {data[index].goalPercent}
                </span>
              </Typography>
            </div>
          </foreignObject>
        ))}
      </svg>
    );
  }
);

export default React.memo(RadarChartBackground);
