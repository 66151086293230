import Link from 'next/link';
import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {COLOR_SANTA_E, COLOR_SANTA_I} from '@riiid/design-system';
import {Alert, Typography, useToastContext} from '@santa-web/service-ui';
import {DimWithEscape, LoadingDim} from '@app/components';
import {useDialogContext} from '@app/contexts/DialogContext';
import {useWithdrawMutation} from '@app/features/auth';
import PreferenceListItem from '@app/features/preferences/components/PreferenceListItem';
import PreferencePageLayoutContainer from '@app/features/preferences/containers/PreferencePageLayoutContainer';
import {useLogout} from '@app/hooks/useLogout';
import useUserQuery from '@app/hooks/user/useUser';
import {SantaNavigationPath} from '@app/types/navigation';
import {isAppBridgeAvailable} from '@app/utils/app-bridge';

const MyAccountPageContainer = () => {
  const {t} = useTranslation();
  const {data: user} = useUserQuery();
  const logout = useLogout(SantaNavigationPath['/onboarding/intro']);
  const {mutateAsync: withdraw, isLoading: isWithdrawing} = useWithdrawMutation();

  const {openDialog, closeDialog} = useDialogContext();
  const {toastContainer, openToast} = useToastContext();

  const accountInformation = `${user?.registrationType}-${user?.id}`;

  const handleAccountInformationCopy = () => {
    try {
      navigator.clipboard.writeText(accountInformation);
      openToast({message: t('page_my_account_list_item_account_info_toast'), colorVariant: 'gray'});
    } catch {
      openToast({message: t('page_my_account_list_item_account_info_toast_error'), colorVariant: 'danger'});
    }
  };

  const handleLogout = () => {
    openDialog(
      <DimWithEscape onEscape={closeDialog}>
        <Alert
          title={t('page_my_account_logout_alert_title')}
          negativeLabel={t('dict_cancel')}
          onNegativeClick={closeDialog}
          positiveLabel={t('dict_confirm')}
          onPositiveClick={async () => {
            logout();
            closeDialog();
          }}
        />
      </DimWithEscape>
    );
  };

  const handleWithdraw = () => {
    openDialog(
      <DimWithEscape onEscape={closeDialog}>
        <Alert
          title={t('page_my_account_list_item_withdraw_title')}
          description={t('page_my_account_withdraw_alert_description')}
          negativeLabel={t('dict_cancel')}
          onNegativeClick={closeDialog}
          positiveLabel={t('dict_confirm')}
          onPositiveClick={async () => {
            withdraw();
            closeDialog();
          }}
        />
      </DimWithEscape>
    );
  };

  return (
    <>
      <PreferencePageLayoutContainer
        isNarrow
        upperArea={
          <div>
            <Typography
              css={css`
                margin-bottom: 40px;
              `}
              component="h1"
              variant="caption1"
              fontWeight="bold"
              color={COLOR_SANTA_I}>
              {t('page_me_list_item_account_info_title')}
            </Typography>
            <div
              css={css`
                display: grid;
                gap: 32px;
              `}>
              <Link href={SantaNavigationPath['/me/account/name']}>
                <PreferenceListItem
                  title={{value: t('dict_name'), fontWeight: 'bold'}}
                  description={{value: user?.personalInfo?.name ?? 'User'}}
                  action={{type: 'chevron'}}
                />
              </Link>
              <PreferenceListItem
                title={{value: t('page_me_list_item_account_info_title'), fontWeight: 'bold'}}
                description={{value: accountInformation}}
                action={{
                  type: 'button',
                  value: t('page_my_account_list_item_account_info_button'),
                  onClick: handleAccountInformationCopy,
                }}
              />
              <PreferenceListItem
                title={{value: t('page_my_account_list_item_email_info_title'), fontWeight: 'bold'}}
                description={{value: user?.personalInfo?.email ?? '-'}}
              />
              <hr
                css={css`
                  margin: 0;
                  border: none;
                  border-top: 1px solid ${COLOR_SANTA_E};
                `}
              />
              <PreferenceListItem
                title={{value: t('page_my_account_list_item_logout_title'), fontWeight: 'bold'}}
                action={{type: 'chevron', onClick: handleLogout}}
              />
              {isAppBridgeAvailable() && (
                <PreferenceListItem
                  title={{value: t('page_my_account_list_item_withdraw_title'), fontWeight: 'bold'}}
                  action={{type: 'chevron', onClick: handleWithdraw}}
                />
              )}
            </div>
            {toastContainer}
          </div>
        }
      />
      {isWithdrawing && <LoadingDim />}
    </>
  );
};

export default MyAccountPageContainer;
MyAccountPageContainer.displayName = 'MyAccountPageContainer';
