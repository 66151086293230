/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { ContentInteractionStateWithContentFromJSON, ContentInteractionStateWithContentToJSON, } from './ContentInteractionStateWithContent';
import { CursorPageFromJSON, CursorPageToJSON, } from './CursorPage';
/**
 * Check if a given object implements the ListToeicVirtualExamContentInteractionStatesResponse interface.
 */
export function instanceOfListToeicVirtualExamContentInteractionStatesResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "contentInteractionStates" in value;
    return isInstance;
}
export function ListToeicVirtualExamContentInteractionStatesResponseFromJSON(json) {
    return ListToeicVirtualExamContentInteractionStatesResponseFromJSONTyped(json, false);
}
export function ListToeicVirtualExamContentInteractionStatesResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'contentInteractionStates': (json['contentInteractionStates'].map(ContentInteractionStateWithContentFromJSON)),
        'nextCursorPage': !exists(json, 'nextCursorPage') ? undefined : CursorPageFromJSON(json['nextCursorPage']),
    };
}
export function ListToeicVirtualExamContentInteractionStatesResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'contentInteractionStates': (value.contentInteractionStates.map(ContentInteractionStateWithContentToJSON)),
        'nextCursorPage': CursorPageToJSON(value.nextCursorPage),
    };
}
