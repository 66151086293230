import {useCallback, useRef} from 'react';

function useThrottle<T extends any[]>(callback: (...params: T) => void, time: number) {
  const timer = useRef<ReturnType<typeof setTimeout> | null>(null);

  return useCallback(
    (...params: T) => {
      if (!timer.current) {
        callback(...params);

        timer.current = setTimeout(() => {
          timer.current = null;
        }, time);
      }
    },
    [callback, time]
  );
}

export default useThrottle;
