/**
 * Check if a given object implements the StockConfig interface.
 */
export function instanceOfStockConfig(value) {
    let isInstance = true;
    isInstance = isInstance && "stockCount" in value;
    return isInstance;
}
export function StockConfigFromJSON(json) {
    return StockConfigFromJSONTyped(json, false);
}
export function StockConfigFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'stockCount': json['stockCount'],
    };
}
export function StockConfigToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'stockCount': value.stockCount,
    };
}
