import React from 'react';

import {css} from '@emotion/react';
import {Dim, DimProps} from '@santa-web/service-ui';
import {ALERT_DIALOG_Z_INDEX} from '@app/constants/zIndex';

type DimWithEscapeOwnProps = DimProps & {onEscape: () => void; children?: React.ReactNode};
type DimWithEscapeProps = DimWithEscapeOwnProps & React.HTMLAttributes<HTMLDivElement>;

const DimWithEscape = ({onEscape, children, ...rest}: DimWithEscapeProps) => {
  const ref = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Escape') {
        onEscape();
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [onEscape]);

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    if (e.target === ref.current) {
      onEscape();
    }
  };

  return (
    <Dim
      ref={ref}
      onClick={handleClick}
      css={css`
        box-sizing: border-box;

        position: fixed;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;

        display: flex;
        flex-direction: column-reverse;
        align-items: center;
        justify-content: center;

        z-index: ${ALERT_DIALOG_Z_INDEX};
      `}
      {...rest}>
      {children}
    </Dim>
  );
};

export default DimWithEscape;
export type {DimWithEscapeProps};
DimWithEscape.displayName = 'DimWithEscape';
