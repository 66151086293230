/*
https://reactjs.org/docs/hooks-faq.html#how-to-read-an-often-changing-value-from-usecallback

We recommend to pass dispatch down in context rather than individual callbacks in props. The approach below is only mentioned here for completeness and as an escape hatch.
Also note that this pattern might cause problems in the concurrent mode. We plan to provide more ergonomic alternatives in the future, but the safest solution right now is to always invalidate the callback if some value it depends on changes.

 */

import {useCallback, useEffect, useRef} from 'react';

type Fn<P extends unknown[], R> = (...args: P) => R;

export default function useEventCallback<P extends unknown[], R>(
  fn: Fn<P, R>,
  dependencies: ReadonlyArray<unknown>
): Fn<P, R> {
  const ref = useRef<Fn<P, R>>(() => {
    throw new Error('Cannot call an event handler while rendering.');
  });

  useEffect(() => {
    ref.current = fn;
  }, [fn, ...dependencies]);

  return useCallback((...args: P): R => {
    const fn = ref.current;
    return fn(...args);
  }, []);
}
