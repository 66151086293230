import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {COLOR_SANTA_G} from '@riiid/design-system';
import TestReportIcon from '@riiid/design-system/icons/Testreport';

import {Typography} from '@santa-web/service-ui';

export interface VirtualExamCheckedViewProps extends React.ComponentPropsWithoutRef<'div'> {
  startPart?: number;
  endPart?: number;
  allPart?: boolean;
}

const VirtualExamCheckedView = React.forwardRef<HTMLDivElement, VirtualExamCheckedViewProps>(
  ({startPart, endPart, allPart, ...props}, ref) => {
    const {t} = useTranslation();
    return (
      <div
        css={css`
          width: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        `}
        ref={ref}
        {...props}>
        <div
          css={css`
            display: flex;
            flex-direction: column;
            align-items: center;
          `}>
          <TestReportIcon type="line" color={COLOR_SANTA_G} />
          <Typography
            css={css`
              margin-top: 16px;
              white-space: pre-line;
              text-align: center;
            `}
            variant="caption1"
            fontWeight="bold"
            color={COLOR_SANTA_G}>
            {allPart
              ? t('virtual_exam_part_all_checked_all')
              : t('virtual_exam_part_all_checked_partial', {start: startPart, end: endPart})}
          </Typography>
        </div>
      </div>
    );
  }
);

export default React.memo(VirtualExamCheckedView);
