import React from 'react';
import {css} from '@emotion/react';

import TestPrepLayout, {TestPrepLayoutProps} from '@app/test-preparation/components/TestPrepLayout';

type LessonViewLayoutProps = TestPrepLayoutProps;

const LessonViewLayout = ({children, ...props}: LessonViewLayoutProps) => {
  return (
    <TestPrepLayout scrollLayoutProps={{}} {...props}>
      <div
        css={css`
          display: flex;
          justify-content: center;
          height: 100%;
        `}>
        <div
          css={css`
            height: 100%;
            width: 100%;
            display: flex;
            flex-direction: column;
          `}>
          <div
            css={css`
              flex: 1;
              height: 100%;
            `}>
            {children}
          </div>
        </div>
      </div>
    </TestPrepLayout>
  );
};

export default React.memo(LessonViewLayout);
