/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { PointRewardNewMemberBonusPointFromJSON, PointRewardNewMemberBonusPointToJSON, } from './PointRewardNewMemberBonusPoint';
import { PointRewardRankUpBenefitFromJSON, PointRewardRankUpBenefitToJSON, } from './PointRewardRankUpBenefit';
/**
 * Check if a given object implements the PointRewardMyRankPoint interface.
 */
export function instanceOfPointRewardMyRankPoint(value) {
    let isInstance = true;
    isInstance = isInstance && "isPaid" in value;
    isInstance = isInstance && "point" in value;
    isInstance = isInstance && "rank" in value;
    return isInstance;
}
export function PointRewardMyRankPointFromJSON(json) {
    return PointRewardMyRankPointFromJSONTyped(json, false);
}
export function PointRewardMyRankPointFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'isPaid': json['isPaid'],
        'newMemberBonusPoint': !exists(json, 'newMemberBonusPoint') ? undefined : PointRewardNewMemberBonusPointFromJSON(json['newMemberBonusPoint']),
        'point': json['point'],
        'rank': json['rank'],
        'rankDifference': !exists(json, 'rankDifference') ? undefined : json['rankDifference'],
        'rankUpBenefit': !exists(json, 'rankUpBenefit') ? undefined : PointRewardRankUpBenefitFromJSON(json['rankUpBenefit']),
    };
}
export function PointRewardMyRankPointToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'isPaid': value.isPaid,
        'newMemberBonusPoint': PointRewardNewMemberBonusPointToJSON(value.newMemberBonusPoint),
        'point': value.point,
        'rank': value.rank,
        'rankDifference': value.rankDifference,
        'rankUpBenefit': PointRewardRankUpBenefitToJSON(value.rankUpBenefit),
    };
}
