import { OrderFromJSON, OrderToJSON, } from './Order';
/**
 * Check if a given object implements the ListOrderResponse interface.
 */
export function instanceOfListOrderResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "orders" in value;
    return isInstance;
}
export function ListOrderResponseFromJSON(json) {
    return ListOrderResponseFromJSONTyped(json, false);
}
export function ListOrderResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'orders': (json['orders'].map(OrderFromJSON)),
    };
}
export function ListOrderResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'orders': (value.orders.map(OrderToJSON)),
    };
}
