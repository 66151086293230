import React from 'react';
import {css} from '@emotion/react';
import {BREAKPOINT_MEDIUM, COLOR_SANTA_A} from '@riiid/design-system';
import ChevronLeftIcon from '@riiid/design-system/icons/ChevronLeft';
import Close from '@riiid/design-system/icons/Close';

import {IconButton} from '@santa-web/service-ui';
import WebComponentWrapper, {WebComponentWrapperProps} from '@app/components/WebComponentWrapper';

export interface ThickTopNavBarProps extends WebComponentWrapperProps {
  type?: 'close' | 'back';
  rightElement?: React.ReactNode;
  onCloseClick(): void;
}

const ThickTopNavBar = ({type = 'close', rightElement, onCloseClick, ...props}: ThickTopNavBarProps): JSX.Element => {
  return (
    <WebComponentWrapper>
      <div
        css={css`
          display: flex;
          align-items: center;
          justify-content: space-between;
          height: 58px;
          position: sticky;
          top: 0;
          padding: 20px;
          background-color: ${COLOR_SANTA_A};

          @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
            padding: 0 40px;
          }
        `}
        {...props}>
        <IconButton
          Icon={type === 'close' ? Close : ChevronLeftIcon}
          onClick={onCloseClick}
          aria-label={`${type} button`}
        />
        <span>{rightElement}</span>
      </div>
    </WebComponentWrapper>
  );
};

export default React.memo(ThickTopNavBar);
