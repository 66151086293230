import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';

import {Button, SinglePageLayout, SystemErrorLayout} from '@santa-web/service-ui';
import {use100vh} from '@app/hooks/use100vh';
import {useOpenNewWindow} from '@app/hooks/useOpenNewWindow';

const SystemCheckView: React.FC<{reference: string}> = ({reference}) => {
  const viewportHeight = use100vh();
  const openNewWindow = useOpenNewWindow();
  const {t} = useTranslation();

  return (
    <div
      css={css`
        width: 100%;
        height: ${viewportHeight};
      `}>
      <SinglePageLayout isError>
        <SystemErrorLayout
          icon="warning"
          description={
            <p
              css={css`
                white-space: pre-line;
              `}>
              {t('system_check_title')}
            </p>
          }
          button={
            <Button variant="box-line" onClick={() => openNewWindow(reference)}>
              {t('system_check_button')}
            </Button>
          }
        />
      </SinglePageLayout>
    </div>
  );
};

export default SystemCheckView;
