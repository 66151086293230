import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';

import {BottomSheetProps, Button} from '@santa-web/service-ui';
import BottomSheetWithDim, {BottomSheetWithDimProps} from '@app/components/BottomSheetWithDim';
import {useDialogContext} from '@app/contexts/DialogContext';

export interface VirtualExamEntranceBottomSheetWithDimProps extends Omit<BottomSheetWithDimProps, 'BottomSheetProps'> {
  isOngoing: boolean;
  title: string;
  lcDurationMinute: number;
  rcDurationMinute: number;
  questionCount: number;
  onStart: () => void;
  onContinue?: () => void;
}

const VirtualExamEntranceBottomSheetWithDim = React.forwardRef<
  HTMLDivElement,
  VirtualExamEntranceBottomSheetWithDimProps
>(({isOngoing, title, lcDurationMinute, rcDurationMinute, questionCount, onStart, onContinue, ...restProps}, ref) => {
  const {t} = useTranslation();
  const {closeDialog} = useDialogContext();

  const bottomSheetProps = React.useMemo((): BottomSheetProps => {
    const totalDurationMinute = lcDurationMinute + rcDurationMinute;
    return {
      isLoadingAiAnimationVisible: true,
      title: t('bottom_sheet_virtual_exam_entrance_title', {
        name: title,
      }),
      description: t('bottom_sheet_virtual_exam_entrance_description', {
        questionCount,
        lcDuration: lcDurationMinute,
        rcDuration: rcDurationMinute,
        totalDuration: totalDurationMinute,
      }),
      onCloseClick: closeDialog,
      buttons: [
        ...(function* () {
          if (isOngoing) {
            yield (
              <Button colorVariant="brand" isFullWidth onClick={onContinue}>
                {t('bottom_sheet_virtual_exam_entrance_button_continue')}
              </Button>
            );
          }
          yield (
            <Button colorVariant="brand" isFullWidth onClick={onStart}>
              {isOngoing
                ? t('bottom_sheet_virtual_exam_entrance_button_start_again')
                : t('bottom_sheet_virtual_exam_entrance_button_start')}
            </Button>
          );
        })(),
      ],
      css: css`
        word-break: keep-all;
      `,
    };
  }, [closeDialog, isOngoing, lcDurationMinute, rcDurationMinute, questionCount, t, title, onContinue, onStart]);

  return <BottomSheetWithDim isOpened BottomSheetProps={bottomSheetProps} ref={ref} {...restProps} />;
});

export default React.memo(VirtualExamEntranceBottomSheetWithDim);
