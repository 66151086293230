/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { ImageSetFromJSON, ImageSetToJSON, } from './ImageSet';
import { MarketingCampaignButtonFromJSON, MarketingCampaignButtonToJSON, } from './MarketingCampaignButton';
/**
 * Check if a given object implements the MarketingCampaign interface.
 */
export function instanceOfMarketingCampaign(value) {
    let isInstance = true;
    isInstance = isInstance && "detailImageSet" in value;
    isInstance = isInstance && "id" in value;
    isInstance = isInstance && "startsAt" in value;
    isInstance = isInstance && "subButtons" in value;
    isInstance = isInstance && "thumbnailImageSet" in value;
    return isInstance;
}
export function MarketingCampaignFromJSON(json) {
    return MarketingCampaignFromJSONTyped(json, false);
}
export function MarketingCampaignFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'detailImageSet': ImageSetFromJSON(json['detailImageSet']),
        'endsAt': !exists(json, 'endsAt') ? undefined : (new Date(json['endsAt'])),
        'id': json['id'],
        'mainButton': !exists(json, 'mainButton') ? undefined : MarketingCampaignButtonFromJSON(json['mainButton']),
        'startsAt': (new Date(json['startsAt'])),
        'subButtons': (json['subButtons'].map(MarketingCampaignButtonFromJSON)),
        'thumbnailDescription': !exists(json, 'thumbnailDescription') ? undefined : json['thumbnailDescription'],
        'thumbnailImageSet': ImageSetFromJSON(json['thumbnailImageSet']),
        'thumbnailTitle': !exists(json, 'thumbnailTitle') ? undefined : json['thumbnailTitle'],
    };
}
export function MarketingCampaignToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'detailImageSet': ImageSetToJSON(value.detailImageSet),
        'endsAt': value.endsAt === undefined ? undefined : (value.endsAt.toISOString()),
        'id': value.id,
        'mainButton': MarketingCampaignButtonToJSON(value.mainButton),
        'startsAt': (value.startsAt.toISOString()),
        'subButtons': (value.subButtons.map(MarketingCampaignButtonToJSON)),
        'thumbnailDescription': value.thumbnailDescription,
        'thumbnailImageSet': ImageSetToJSON(value.thumbnailImageSet),
        'thumbnailTitle': value.thumbnailTitle,
    };
}
