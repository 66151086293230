import {useMemo} from 'react';
import {Currency} from '@santa-web/gen/open-api/service';

import {usePushEvent} from '.';

interface SendLegacyPurchaseSuccessEventProperties extends Record<string, unknown> {
  revenue: number;
  order_id: string;
  currency: Currency;
  product_id?: number;
  product_type?: string;
  product_name?: string;
}

interface SendPurchaseSuccessEventProperties extends Record<string, unknown> {
  revenue: number;
  orderId: string;
  currency: Currency;
  offerId: number;
  offerDisplayName: string;
}

const usePurchaseSuccessEvents = () => {
  const pushEvent = usePushEvent();

  const purchaseSuccessEvents = useMemo(
    () => ({
      /**
       * @deprecated product용
       */
      pushLegacyPurchaseSuccess: async (payload: SendLegacyPurchaseSuccessEventProperties) => {
        const {revenue, order_id, currency, product_id, product_type, product_name} = payload;
        // [이벤트 정의](https://www.notion.so/riiid/af_purchase-6175e422bad341fd80840d7303ee73a7?pvs=4)
        await Promise.all([
          pushEvent('af_purchase', {
            af_revenue: revenue,
            af_order_id: order_id,
            af_currency: currency,
            product_id,
            product_type,
            product_name,
          }),
          pushEvent('purchase', {
            value: revenue,
            transaction_id: order_id,
            currency,
          }),
        ]);
      },
      pushPurchaseSuccess: async (payload: SendPurchaseSuccessEventProperties) => {
        const {revenue, orderId, currency, offerId, offerDisplayName} = payload;
        await Promise.all([
          // https://www.notion.so/riiid/af_purchase-6175e422bad341fd80840d7303ee73a7?pvs=4
          pushEvent('af_purchase', {
            af_revenue: revenue,
            af_order_id: orderId,
            af_currency: currency,
            offerId,
            offerDisplayName,
          }),
          // https://www.notion.so/riiid/purchase-dd5560db42274de4abe5609e11b8bd2d?pvs=4
          pushEvent('purchase', {
            value: revenue,
            transaction_id: orderId,
            currency,
            offerId,
            offerDisplayName,
          }),
        ]);
      },
    }),
    [pushEvent]
  );

  return purchaseSuccessEvents;
};

export default usePurchaseSuccessEvents;
