import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';
import {GetExamScoreResponse} from '@santa-web/gen/open-api/service';
import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {StringDate} from '@app/features/preferences/types';
import {getExamScoreQueryKey} from '@app/queryKeys';

const useGetExamScoreQuery = ({
  args: {date},
  options,
}: {
  args: {date?: StringDate};
  options?: UseQueryOptions<
    GetExamScoreResponse,
    unknown,
    GetExamScoreResponse,
    ReturnType<typeof getExamScoreQueryKey>
  >;
}) => {
  const {ExamScoreService} = useAtomValue(santaOpenapiServicesAtom);

  return useQuery(
    getExamScoreQueryKey(date),
    () => ExamScoreService.getExamScore({examDate: date as unknown as Date}),
    {
      enabled: !!date,
      ...options,
    }
  );
};

export default useGetExamScoreQuery;
