import React from 'react';
import {css} from '@emotion/react';
import ChevronMiniRightIcon from '@riiid/design-system/icons/ChevronMiniRight';
import {URL_GIF_EQUALIZER_SOLID_WHIITE} from '@riiid/design-system/lotties/santa';

import {LottieAnimation} from '@santa-web/service-ui';

export interface ListeningFloatingChatButtonContentProps extends React.ComponentPropsWithoutRef<'div'> {
  text: string;
  isClickable?: boolean;
}

const ListeningFloatingChatButtonContent = React.forwardRef<HTMLDivElement, ListeningFloatingChatButtonContentProps>(
  ({text, isClickable, ...props}, ref) => {
    return (
      <div
        ref={ref}
        {...props}
        css={css`
          display: flex;
          align-items: center;
        `}>
        {text}
        <div
          css={css`
            display: flex;
            margin-left: 4px;
            margin-right: -6px;
          `}>
          {isClickable ? (
            <ChevronMiniRightIcon size="L" width={20} height={20} />
          ) : (
            <LottieAnimation src={URL_GIF_EQUALIZER_SOLID_WHIITE} width={20} height={20} />
          )}
        </div>
      </div>
    );
  }
);

export default React.memo(ListeningFloatingChatButtonContent);
