import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {BREAKPOINT_MEDIUM, COLOR_SANTA_D, COLOR_SANTA_L} from '@riiid/design-system';

import {useGetThemedImagesData, Typography, RiiidLogo} from '@santa-web/service-ui';

const ToeicFooter = () => {
  const {t, i18n} = useTranslation();
  const {alcLogoImageUrl} = useGetThemedImagesData();
  const logo = React.useMemo(() => {
    switch (i18n.languages[0]) {
      case 'ja':
        return <img width={102} height={32} alt="" src={alcLogoImageUrl} />;
      default:
        return <RiiidLogo />;
    }
  }, [alcLogoImageUrl, i18n.languages]);
  return (
    <div
      css={css`
        width: 100%;
        background: ${COLOR_SANTA_D};
      `}>
      <div
        css={css`
          max-width: 1080px;
          margin: 0 auto;
          background: ${COLOR_SANTA_D};
          padding: 40px 20px;
          @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
            padding: 40px;
          }
        `}>
        {logo}
        <div
          css={css`
            margin-top: 16px;
            display: grid;
            grid-auto-flow: row;
            row-gap: 16px;
            grid-row-gap: 16px;
          `}>
          <Typography
            css={css`
              white-space: pre-line;
            `}
            variant="caption1"
            fontWeight="regular"
            color={COLOR_SANTA_L}>
            {t('footer_large_screen')}
          </Typography>
        </div>
      </div>
    </div>
  );
};

export default React.memo(ToeicFooter);
