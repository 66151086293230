import React from 'react';
import {css} from '@emotion/react';
import {COLOR_SANTA_BD, COLOR_SANTA_J, COLOR_SANTA_O_ALPHA} from '@riiid/design-system';
import TogglebtndownIcon from '@riiid/design-system/icons/Togglebtndown';

export interface AnalyticsProgressBarProps extends React.ComponentPropsWithoutRef<'div'> {
  // This prop ranges from 0 to 100.
  nowPercent: number;
  // This prop ranges from 0 to 100.
  goalPercent?: number | null;
}

const AnalyticsProgressBar = React.forwardRef<HTMLDivElement, AnalyticsProgressBarProps>(
  ({nowPercent, goalPercent, ...props}, ref) => {
    return (
      <div
        css={css`
          position: relative;
          padding-top: 12px;
        `}
        ref={ref}
        {...props}>
        {goalPercent ? (
          <div
            data-testid="AnalyticsProgressBar-ArrowDownPosition"
            css={css`
              position: absolute;
              top: -7px;
              left: ${goalPercent}%;
              transform: translateX(-50%);
            `}>
            <TogglebtndownIcon
              css={css`
                width: 16px;
                height: 16px;
                color: ${COLOR_SANTA_J};
              `}
              type="line"
            />
          </div>
        ) : null}
        <div
          css={css`
            position: relative;
            &,
            & > * {
              height: 4px;
            }
            & > * {
              position: absolute;
              top: 0;
              left: 0;
              border-radius: 100px;
            }
          `}>
          <div
            data-testid="AnalyticsProgressBar-BackgroundRail"
            css={css`
              width: 100%;
              background-color: ${COLOR_SANTA_O_ALPHA(0.05)};
            `}
          />
          {goalPercent ? (
            <div
              data-testid="AnalyticsProgressBar-GoalPercentRail"
              css={css`
                width: ${goalPercent}%;
                background-color: ${COLOR_SANTA_O_ALPHA(0.1)};
              `}
            />
          ) : null}
          <div
            data-testid="AnalyticsProgressBar-NowPercentRail"
            css={css`
              width: ${nowPercent}%;
              background-color: ${COLOR_SANTA_BD};
            `}
          />
        </div>
      </div>
    );
  }
);

export default React.memo(AnalyticsProgressBar);
