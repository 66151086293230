import React from 'react';
import {css} from '@emotion/react';
import {COLOR_SANTA_D} from '@riiid/design-system';

export interface SkeletonGrayBoxProps extends React.HTMLAttributes<HTMLDivElement> {
  width: number | string;
  height: number | string;
  color?: string;
}

const SkeletonGrayBox = React.forwardRef<HTMLDivElement, SkeletonGrayBoxProps>(
  ({width, height, color = COLOR_SANTA_D, ...rest}, ref) => (
    <div
      css={css`
        width: ${typeof width === 'number' ? `${width}px` : width};
        height: ${typeof height === 'number' ? `${height}px` : height};
        background-color: ${color};
        border-radius: 2px;
      `}
      ref={ref}
      {...rest}
    />
  )
);

export default React.memo(SkeletonGrayBox);

SkeletonGrayBox.displayName = 'SkeletonGrayBox';
