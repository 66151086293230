import {useMutation, UseMutationOptions, useQueryClient} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';
import {CreateExamScoreRequest, CreateExamScoreResponse} from '@santa-web/gen/open-api/service';
import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {getExamScoresQueryKey} from '@app/queryKeys';

const usePostExamScoreMutation = (
  option?: Omit<UseMutationOptions<CreateExamScoreResponse, unknown, CreateExamScoreRequest, unknown>, 'mutationFn'>
) => {
  const queryClient = useQueryClient();
  const {ExamScoreService} = useAtomValue(santaOpenapiServicesAtom);

  return useMutation<CreateExamScoreResponse, unknown, CreateExamScoreRequest, unknown>(
    (args: CreateExamScoreRequest) => ExamScoreService.createExamScore({createExamScoreRequest: args}),
    {
      ...option,
      onSuccess: () => {
        queryClient.invalidateQueries(getExamScoresQueryKey());
      },
    }
  );
};

export default usePostExamScoreMutation;
