import {useQuery, UseQueryOptions} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';
import {ListExamScoresResponse} from '@santa-web/gen/open-api/service';
import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {getExamScoresQueryKey} from '@app/queryKeys';

const useGetExamScoresQuery = <T = ListExamScoresResponse>(
  options?: UseQueryOptions<ListExamScoresResponse, unknown, T, ReturnType<typeof getExamScoresQueryKey>>
) => {
  const {ExamScoreService} = useAtomValue(santaOpenapiServicesAtom);
  return useQuery(getExamScoresQueryKey(), () => ExamScoreService.listExamScores({}), {
    staleTime: Infinity,
    ...options,
  });
};

export default useGetExamScoresQuery;
