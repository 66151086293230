import { WeaknessBlockFromJSON, WeaknessBlockToJSON, } from './WeaknessBlock';
/**
 * Check if a given object implements the GetWeaknessBlockResponse interface.
 */
export function instanceOfGetWeaknessBlockResponse(value) {
    let isInstance = true;
    isInstance = isInstance && "weaknessBlocks" in value;
    return isInstance;
}
export function GetWeaknessBlockResponseFromJSON(json) {
    return GetWeaknessBlockResponseFromJSONTyped(json, false);
}
export function GetWeaknessBlockResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'weaknessBlocks': (json['weaknessBlocks'].map(WeaknessBlockFromJSON)),
    };
}
export function GetWeaknessBlockResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'weaknessBlocks': (value.weaknessBlocks.map(WeaknessBlockToJSON)),
    };
}
