import React, {useEffect, useRef, useState} from 'react';
import {css} from '@emotion/react';
import {COLOR_SANTA_E} from '@riiid/design-system';
import {getDaysInMonth, getWeeksInMonth, isSameMonth} from 'date-fns';
import {DAYS, MONTHS, WEEKS, YEARS} from './consts';
import {
  getDenormalizedDate,
  getFirstDayInWeek,
  getNormalizedDate,
  getWeek,
  isAfterDayInCalendar,
  isBeforeDayInCalendar,
} from './utils';
import WheelSlot from './WheelSlot';

type WheelDatePickerProps = {
  type?: 'daily' | 'weekly' | 'monthly';
  defaultValue?: WheelDate;
  onChange?: (date: WheelDate) => void;
  minDate?: Date;
  maxDate?: Date;
};

type WheelDate = {
  year: number;
  month: number;
  week: number;
  day: number;
};

const HorizontalLine = React.memo(({offset}: {offset: number}) => {
  return (
    <div
      css={css`
        position: absolute;
        bottom: 50%;
        left: 0;
        transform: translateY(${offset}px);
        width: 100%;
        height: 1px;
        background-color: ${COLOR_SANTA_E};
      `}
    />
  );
});

const WheelDatePicker = ({
  type = 'daily',
  defaultValue = getNormalizedDate(new Date()),
  onChange,
  minDate,
  maxDate,
}: WheelDatePickerProps) => {
  const hasChangedRef = useRef(false);
  const [date, setDate] = useState<WheelDate>(defaultValue);
  const {minYear, maxYear, minMonth, maxMonth, minWeek, maxWeek, minDay, maxDay} = getMinMaxValues(
    date,
    minDate,
    maxDate
  );

  // time(h, m, s)을 제외한 날짜 기준으로 minDate보다 낮거나, maxDate보다 높은 경우를 처리한다.
  useEffect(() => {
    const denormDate = getDenormalizedDate(date);
    if (minDate && isBeforeDayInCalendar(denormDate, minDate)) {
      setDate(getNormalizedDate(minDate));
    } else if (maxDate && isAfterDayInCalendar(denormDate, maxDate)) {
      setDate(getNormalizedDate(maxDate));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date.year, date.month, date.week, date.day]);

  // Week이 변할 때, 해당 Week에 속하는 첫번째 day(일요일)로 바꾼다.
  useEffect(() => {
    if (!hasChangedRef.current) return;
    setDate(prevDate => ({...prevDate, day: getFirstDayInWeek(prevDate).day}));
  }, [date.week]);

  // Year, Month가 변할 때 : 6번째 주를 가리키고 있다가, 주의 개수가 부족한 Month로 변할때 5번째 주로 바꾼다.
  useEffect(() => {
    if (!hasChangedRef.current) return;
    const curWeek = date.week;
    const numOfWeeksInMonth = getWeeksInMonth(getDenormalizedDate(date));
    if (curWeek === 6 && numOfWeeksInMonth === 5) {
      setDate(prevDate => ({...prevDate, week: numOfWeeksInMonth}));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date.year, date.month]);

  useEffect(() => {
    if (!hasChangedRef.current) return;
    if (onChange) {
      onChange(date);
    }
  }, [date, onChange]);

  const handleChange = (dateType: 'year' | 'month' | 'week' | 'day') => (data: {label: string; value: number}) => {
    if (!hasChangedRef.current) hasChangedRef.current = true;
    setDate(prev => ({...prev, [dateType]: data.value}));
  };

  return (
    <>
      <div
        css={css`
          display: flex;
          justify-content: center;
          width: 320px;
          gap: 16px;
          position: relative;
        `}>
        <WheelSlot min={minYear} max={maxYear} items={YEARS} onChange={handleChange('year')} defaultValue={date.year} />
        <WheelSlot
          min={minMonth}
          max={maxMonth}
          items={MONTHS}
          onChange={handleChange('month')}
          defaultValue={date.month}
        />
        {type === 'weekly' && (
          <WheelSlot
            min={minWeek}
            max={maxWeek}
            items={WEEKS}
            onChange={handleChange('week')}
            defaultValue={date.week}
          />
        )}
        {type === 'daily' && (
          <WheelSlot min={minDay} max={maxDay} items={DAYS} onChange={handleChange('day')} defaultValue={date.day} />
        )}
        <HorizontalLine offset={-27.5} />
        <HorizontalLine offset={27.5} />
      </div>
    </>
  );
};

const getMinMaxValues = (date: WheelDate, minDate?: Date, maxDate?: Date) => {
  const denormDate = getDenormalizedDate(date);
  const numberOfWeeksInMonth = getWeeksInMonth(denormDate);
  const numberOfDaysInMonth = getDaysInMonth(denormDate);

  return {
    minYear: minDate?.getFullYear(),
    maxYear: maxDate?.getFullYear(),
    minMonth: minDate?.getFullYear() === date.year ? minDate.getMonth() + 1 : undefined,
    maxMonth: maxDate?.getFullYear() === date.year ? maxDate.getMonth() + 1 : undefined,
    minWeek: minDate && isSameMonth(minDate, denormDate) ? getWeek(minDate) : undefined,
    maxWeek: maxDate && isSameMonth(maxDate, denormDate) ? getWeek(maxDate) : numberOfWeeksInMonth,
    minDay: minDate && isSameMonth(minDate, denormDate) ? minDate.getDate() : undefined,
    maxDay: maxDate && isSameMonth(maxDate, denormDate) ? maxDate.getDate() : numberOfDaysInMonth,
  };
};

export default React.memo(WheelDatePicker);
export type {WheelDatePickerProps, WheelDate};
WheelDatePicker.displayName = 'WheelDatePicker';
