import React from 'react';
import {mobileService} from '@app/api/app-bridge/mobile-service';
import {isAppBridgeAvailable} from '@app/utils/app-bridge';

export interface OpenWindow {
  (url: string): Promise<void>;
}

// stackoverflow.com/questions/20696041/window-openurl-blank-not-working-on-imac-safari
const openWindow: OpenWindow = async (url: string) => {
  const button = document.createElement('button');
  button.setAttribute('onclick', `window.open("${url}", "_blank")`);
  button.click();
};

export const useOpenNewWindow = () => {
  return React.useCallback(async (url: string): Promise<void> => {
    if (isAppBridgeAvailable()) {
      await mobileService.openLink(url);
    } else {
      return openWindow(url);
    }
  }, []);
};
