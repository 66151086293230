import React from 'react';
import {Trans, useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {
  BREAKPOINT_MEDIUM,
  COLOR_SANTA_BC,
  COLOR_SANTA_E,
  COLOR_SANTA_I,
  COLOR_SANTA_J,
  COLOR_SANTA_L,
} from '@riiid/design-system';
import {URL_IMG_COUPON_TOEIC} from '@riiid/design-system/images/santa';
import {format} from 'date-fns';
import {TopNavBar, Typography} from '@santa-web/service-ui';
import {Column, FrameLayout, Row} from '@app/components';

export type GiftCodeViewOwnProps = {
  serialNumber: string;
  password?: string;
  validUntil?: Date;
  onCautionLinkClick: () => void;
  onClose: () => void;
};

type GiftCodeViewProps = GiftCodeViewOwnProps & React.HTMLAttributes<HTMLDivElement>;

const GiftCodeView = ({
  serialNumber,
  password,
  validUntil,
  onCautionLinkClick,
  onClose,
  ...props
}: GiftCodeViewProps) => {
  const {t} = useTranslation();
  return (
    <div {...props}>
      <FrameLayout topNavBar={<TopNavBar paginationIcon="exit" onPaginationIconClick={onClose} />}>
        <Row
          css={css`
            width: 100%;
            margin-top: 40px;
            padding: 0 20px;
            @media screen and (min-width: ${BREAKPOINT_MEDIUM}) {
              padding: 0 40px;
            }
          `}
          justifyContent="center">
          <Column
            gap={40}
            css={css`
              width: 100%;
              max-width: ${BREAKPOINT_MEDIUM};
            `}>
            <Row justifyContent="space-between">
              <Typography
                css={css`
                  white-space: pre-line;
                `}
                component="h1"
                variant="h5"
                fontWeight="regular"
                color={COLOR_SANTA_L}>
                <Trans
                  t={t}
                  i18nKey="gift_code_modal_title"
                  components={{
                    b: <Typography component="span" variant="h5" fontWeight="bold" color={COLOR_SANTA_L} />,
                  }}
                />
              </Typography>
              <img
                css={css`
                  margin-top: 12px;
                `}
                src={URL_IMG_COUPON_TOEIC}
                alt=""
                width={96}
                height={96}
              />
            </Row>
            <Column
              as="ul"
              css={css`
                margin: 0;
                padding: 0;
                > li {
                  list-style: none;
                }
              `}
              gap={4}>
              <li>
                <Row justifyContent="space-between">
                  <Typography variant="body3" color={COLOR_SANTA_I}>
                    {t('gift_code_modal_item_serial_number')}
                  </Typography>
                  <Typography variant="body3" color={COLOR_SANTA_L} fontWeight="bold">
                    {serialNumber}
                  </Typography>
                </Row>
              </li>
              {password && (
                <li>
                  <Row justifyContent="space-between">
                    <Typography variant="body3" color={COLOR_SANTA_I}>
                      {t('gift_code_modal_item_password')}
                    </Typography>
                    <Typography variant="body3" color={COLOR_SANTA_L} fontWeight="bold">
                      {password}
                    </Typography>
                  </Row>
                </li>
              )}
              {validUntil && (
                <li>
                  <Row justifyContent="space-between">
                    <Typography variant="body3" color={COLOR_SANTA_I}>
                      {t('gift_code_modal_item_valid_date')}
                    </Typography>
                    <Typography variant="body3" color={COLOR_SANTA_L} fontWeight="bold">
                      {format(validUntil, 'yyyy-MM-dd HH:mm:ss')}
                    </Typography>
                  </Row>
                </li>
              )}
            </Column>
            <hr
              css={css`
                width: 100%;
                border: none;
                border-bottom: solid 1px ${COLOR_SANTA_E};
              `}
            />
            <Column gap={12}>
              <Typography component="h2" variant="caption1" fontWeight="bold" color={COLOR_SANTA_J}>
                {t('gift_code_modal_caution_title')}
              </Typography>
              <Typography component="p" variant="caption1" color={COLOR_SANTA_I}>
                <Trans
                  t={t}
                  i18nKey="gift_code_modal_caution_description"
                  components={{
                    a: (
                      <Typography
                        tabIndex={0}
                        role="button"
                        css={css`
                          background-color: transparent;
                          padding: 0;
                          margin: 0;
                          border: none;
                          text-decoration: underline;
                          cursor: pointer;
                        `}
                        component="button"
                        variant="caption1"
                        color={COLOR_SANTA_BC}
                        onClick={onCautionLinkClick}
                      />
                    ),
                  }}
                />
              </Typography>
            </Column>
          </Column>
        </Row>
      </FrameLayout>
    </div>
  );
};

export default GiftCodeView;
export type {GiftCodeViewProps};
GiftCodeView.displayName = 'GiftCodeView';
