/* tslint:disable */
/* eslint-disable */
/**
 * LOCKED와 DONE이 동시에 충족될 경우 LOCKED로 표시
 *
 * - **NONE**: 잠금도 완료도 아닌 상태
 * - **LOCKED**: 유료 컨텐츠인데 사용자가 권한이 없는 상태
 * - **DONE**: 셀 컨텐츠를 모두 완료한 상태
 * @export
 */
export const SelfLearningUnitState = {
    NONE: 'NONE',
    LOCKED: 'LOCKED',
    DONE: 'DONE'
};
export function SelfLearningUnitStateFromJSON(json) {
    return SelfLearningUnitStateFromJSONTyped(json, false);
}
export function SelfLearningUnitStateFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function SelfLearningUnitStateToJSON(value) {
    return value;
}
