/**
 * Check if a given object implements the PointRewardLeaderBoardMyRank interface.
 */
export function instanceOfPointRewardLeaderBoardMyRank(value) {
    let isInstance = true;
    isInstance = isInstance && "point" in value;
    isInstance = isInstance && "rank" in value;
    return isInstance;
}
export function PointRewardLeaderBoardMyRankFromJSON(json) {
    return PointRewardLeaderBoardMyRankFromJSONTyped(json, false);
}
export function PointRewardLeaderBoardMyRankFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'point': json['point'],
        'rank': json['rank'],
    };
}
export function PointRewardLeaderBoardMyRankToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'point': value.point,
        'rank': value.rank,
    };
}
