import {useQuery} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';

import santaOpenapiServicesAtom from '@app/atoms/core/santa-openapi-services';
import {getLearningGoalKey} from '@app/queryKeys';
import {SantaResponseError} from '@app/utils/error';

export default function useGetLearningGoalQuery() {
  const {LearningGoalService} = useAtomValue(santaOpenapiServicesAtom);

  return useQuery({
    queryKey: getLearningGoalKey(),
    queryFn: async () => {
      try {
        const {learningGoal} = await LearningGoalService.getLearningGoal({});
        return learningGoal;
      } catch (e) {
        if (e instanceof SantaResponseError && e.response.status === 404) {
          return null;
        }
        throw e;
      }
    },
  });
}
