import TagManager from 'react-gtm-module';
import {useTranslation} from 'react-i18next';
import {useQuery, UseQueryResult, UseQueryOptions} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';

// import {getBraze} from '@src/new-structure/useCases/braze';

import {User} from '@santa-web/gen/open-api/service';
import {mobileService} from '@app/api/app-bridge/mobile-service';
import santaAuthorizedOpenapiServicesAtom from '@app/atoms/core/santa-authorized-openapi-services';
import {convertInsideLanguageToIsoLanguage} from '@app/i18n/utils';
import {getUserQueryKey} from '@app/queryKeys';
import {isAppBridgeAvailable} from '@app/utils/app-bridge';

const useUser = (options?: UseQueryOptions<User | undefined, unknown, User>): UseQueryResult<User> => {
  const {UserService} = useAtomValue(santaAuthorizedOpenapiServicesAtom);
  const userKey = getUserQueryKey();
  const {i18n} = useTranslation();

  return useQuery<User | undefined, unknown, User>(
    userKey,
    async () => {
      const {user} = await UserService.getMe({});
      return user;
    },
    {
      onSuccess: user => {
        TagManager.dataLayer({
          dataLayer: {
            isLoggedIn: user.registrationType === 'ANONYMOUS' ? 0 : 1,
          },
        });
        if (isAppBridgeAvailable()) {
          mobileService.setUserProperties({isLoggedIn: user.registrationType === 'ANONYMOUS' ? '0' : '1'});
        }
        const language = convertInsideLanguageToIsoLanguage(user.language);
        i18n.changeLanguage(language);

        // TODO: PC Web은 아직까지는 braze를 사용하지 않아 주석처리함
        // const authId = user?.authId;
        // if (authId) {
        //   getBraze().then(braze => braze.changeUser(authId));
        // }
      },
      ...options,
    }
  );
};

export default useUser;
