import {atom} from 'jotai';

import {contentRepository, resourceRepository} from '@app/test-preparation/atoms/repositories';
import {cisService} from '@app/test-preparation/atoms/services';
import {getResourceIds} from '@app/test-preparation/atoms/services/cis/utils/resource';
import {ServiceNotInitializedError} from '@app/test-preparation/atoms/services/shared/errors/not-initialized';

type SetCurrentCisIndexParams = {
  index: number;
};

const setCurrentCisIndexAtom = atom(null, async (get, set, {index}: SetCurrentCisIndexParams) => {
  const getContent = await get(contentRepository.getContentAtom);
  const listResources = await get(resourceRepository.listResourcesAtom);

  const contentDataMap = get(cisService.contentDataMapAtom);
  const contentData = contentDataMap[index];

  if (contentData === undefined) {
    throw new ServiceNotInitializedError('CisService');
  }

  if (contentData.content === undefined || contentData.resources === undefined) {
    const content = await getContent(contentData.url);
    const resources = await listResources(getResourceIds(content));

    const nextContentDataMap = {
      ...contentDataMap,
      [index]: {
        ...contentData,
        content,
        resources,
      },
    };

    set(cisService.contentDataMapAtom, nextContentDataMap);
  }

  set(cisService.currentCisIndexAtom, index);
});

export {setCurrentCisIndexAtom};
