import React from 'react';
import {css} from '@emotion/react';
import {COLOR_SANTA_C} from '@riiid/design-system';

import {TabNav, TabNavButton} from '@santa-web/service-ui';
import {TabNavItem} from '@app/new-structure/presenters/virtual-exam/useTopNavBar';

export interface VirtualExamTabNavProps extends React.ComponentPropsWithoutRef<'div'> {
  tabNavItems: TabNavItem[];
  contentIndex: number;
  onChangeContentIndex: (index: number) => void;
}

const VirtualExamTabNav = React.forwardRef<HTMLDivElement, VirtualExamTabNavProps>(
  ({tabNavItems, contentIndex, onChangeContentIndex, ...props}, ref) => {
    return (
      <div
        css={css`
          display: flex;
          justify-content: center;
          background-color: ${COLOR_SANTA_C};
        `}>
        <TabNav
          css={css`
            width: 100%;
          `}
          {...props}
          ref={ref}>
          {tabNavItems.map(({title, index}) => (
            <TabNavButton
              onClick={() => {
                onChangeContentIndex(index);
              }}
              key={index}
              isSelected={contentIndex === index}>
              {title}
            </TabNavButton>
          ))}
        </TabNav>
      </div>
    );
  }
);

export default React.memo(VirtualExamTabNav);
