import {PassListItem, Ticket} from '@santa-web/gen/open-api/service';
import {Currency, OfferGroup} from '.';

export enum OfferGroupOrderState {
  CREATED = 'CREATED',
  PAYMENT_COMPLETED = 'PAYMENT_COMPLETED',
  PAYMENT_FAILED = 'PAYMENT_FAILED',
  CANCELED = 'CANCELED',
  REFUNDED = 'REFUNDED',
}

export class OfferGroupOrder {
  id: number;
  appliedCouponIds: number[];
  state: OfferGroupOrderState;
  // NOTE: 하나의 offer만 가지고 있음
  offerGroup: OfferGroup;
  paymentConfig: {
    availablePaymentProviders: {id: number; variant: 'KCP' | 'NAVER_PAY'}[];
    selectedPaymentProviderId?: number;
    storeProductId?: string;
  };
  billId?: number;
  amountData: {
    currency: Currency;
    productDefaultAmount: number;
    productDiscountAmount?: number;
    couponDiscounts: {
      couponId: number;
      amount: number;
    }[];
    totalChargeAmount: number;
  };
  permit?: {
    pass?: PassListItem;
    tickets?: Ticket[];
  };

  constructor(args: OfferGroupOrder) {
    this.id = args.id;
    this.appliedCouponIds = args.appliedCouponIds;
    this.state = args.state;
    this.offerGroup = args.offerGroup;
    this.paymentConfig = args.paymentConfig;
    this.amountData = args.amountData;
    this.permit = args.permit;
  }
}
