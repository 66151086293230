/* tslint:disable */
/* eslint-disable */
/**
 * 등록한 쿠폰의 상태
 *
 * - **REGISTERED**: 쿠폰을 등록하고 사용 가능함
 * - **USED**: 쿠폰을 사용함
 * - **REVOKED**: 쿠폰을 사용한 후 사용 취소함 (권한 쿠폰만 가능)
 * - **EXPIRED**: 쿠폰 사용 기간이 만료됨
 * @export
 */
export const CouponStatus = {
    REGISTERED: 'REGISTERED',
    USED: 'USED',
    REVOKED: 'REVOKED',
    EXPIRED: 'EXPIRED'
};
export function CouponStatusFromJSON(json) {
    return CouponStatusFromJSONTyped(json, false);
}
export function CouponStatusFromJSONTyped(json, ignoreDiscriminator) {
    return json;
}
export function CouponStatusToJSON(value) {
    return value;
}
