import {
  URL_IMG_TESTRESULT_PIECHART_ADVANCED,
  URL_IMG_TESTRESULT_PIECHART_BASIC,
  URL_IMG_TESTRESULT_PIECHART_INTERMEDIATE,
  URL_IMG_TESTRESULT_PIECHART_ENG_ADVANCED,
  URL_IMG_TESTRESULT_PIECHART_ENG_BASIC,
  URL_IMG_TESTRESULT_PIECHART_ENG_INTERMEDIATE,
  URL_IMG_TESTRESULT_PIECHART_JAPAN_ADVANCED,
  URL_IMG_TESTRESULT_PIECHART_JAPAN_BASIC,
  URL_IMG_TESTRESULT_PIECHART_JAPAN_INTERMEDIATE,
  URL_IMG_TESTRESULT_PIECHART_VIETNAM_ADVANCED,
  URL_IMG_TESTRESULT_PIECHART_VIETNAM_BASIC,
  URL_IMG_TESTRESULT_PIECHART_VIETNAM_INTERMEDIATE,
  URL_IMG_TESTRESULT_PIECHART_TRADITIONAL_ADVANCED,
  URL_IMG_TESTRESULT_PIECHART_TRADITIONAL_BASIC,
  URL_IMG_TESTRESULT_PIECHART_TRADITIONAL_INTERMEDIATE,
  URL_IMG_TESTRESULT_PIECHART_THAI_ADVANCED,
  URL_IMG_TESTRESULT_PIECHART_THAI_BASIC,
  URL_IMG_TESTRESULT_PIECHART_THAI_INTERMEDIATE,
} from '@riiid/design-system/images/santa';

import {getSimpleUserSeg} from '@app/features/diagnosis/utils';
import useCountry from '@app/hooks/i18n/useCountry';
import {Country} from '@app/i18n/types';

import {UserSegType} from './types';

type PieImageUserSegType = 'advanced' | 'basic' | 'intermediate';

type UserSegImageMap = Record<PieImageUserSegType, string>;

type CountryUserSegImageMap = Record<Country, UserSegImageMap>;

// TODO: Change this code to JSON
const COUNTRY_USER_SEG_IMAGE_MAP: CountryUserSegImageMap = {
  KR: {
    advanced: URL_IMG_TESTRESULT_PIECHART_ADVANCED,
    basic: URL_IMG_TESTRESULT_PIECHART_BASIC,
    intermediate: URL_IMG_TESTRESULT_PIECHART_INTERMEDIATE,
  },
  US: {
    advanced: URL_IMG_TESTRESULT_PIECHART_ENG_ADVANCED,
    basic: URL_IMG_TESTRESULT_PIECHART_ENG_BASIC,
    intermediate: URL_IMG_TESTRESULT_PIECHART_ENG_INTERMEDIATE,
  },
  JP: {
    advanced: URL_IMG_TESTRESULT_PIECHART_JAPAN_ADVANCED,
    basic: URL_IMG_TESTRESULT_PIECHART_JAPAN_BASIC,
    intermediate: URL_IMG_TESTRESULT_PIECHART_JAPAN_INTERMEDIATE,
  },
  VN: {
    advanced: URL_IMG_TESTRESULT_PIECHART_VIETNAM_ADVANCED,
    basic: URL_IMG_TESTRESULT_PIECHART_VIETNAM_BASIC,
    intermediate: URL_IMG_TESTRESULT_PIECHART_VIETNAM_INTERMEDIATE,
  },
  TW: {
    advanced: URL_IMG_TESTRESULT_PIECHART_TRADITIONAL_ADVANCED,
    basic: URL_IMG_TESTRESULT_PIECHART_TRADITIONAL_BASIC,
    intermediate: URL_IMG_TESTRESULT_PIECHART_TRADITIONAL_INTERMEDIATE,
  },
  TH: {
    advanced: URL_IMG_TESTRESULT_PIECHART_THAI_ADVANCED,
    basic: URL_IMG_TESTRESULT_PIECHART_THAI_BASIC,
    intermediate: URL_IMG_TESTRESULT_PIECHART_THAI_INTERMEDIATE,
  },
};

const usePieImage = (userSeg: UserSegType) => {
  const country = useCountry();
  return COUNTRY_USER_SEG_IMAGE_MAP[country][getSimpleUserSeg(userSeg)];
};

export default usePieImage;
