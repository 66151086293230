/* tslint:disable */
/* eslint-disable */
import { exists } from '../runtime';
import { MyNoteQuizFromJSON, MyNoteQuizToJSON, } from './MyNoteQuiz';
/**
 * Check if a given object implements the GetMyNoteQuizResponse interface.
 */
export function instanceOfGetMyNoteQuizResponse(value) {
    let isInstance = true;
    return isInstance;
}
export function GetMyNoteQuizResponseFromJSON(json) {
    return GetMyNoteQuizResponseFromJSONTyped(json, false);
}
export function GetMyNoteQuizResponseFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'myNoteQuiz': !exists(json, 'myNoteQuiz') ? undefined : MyNoteQuizFromJSON(json['myNoteQuiz']),
    };
}
export function GetMyNoteQuizResponseToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'myNoteQuiz': MyNoteQuizToJSON(value.myNoteQuiz),
    };
}
