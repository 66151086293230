import {Global, css} from '@emotion/react';
import useMobileSafeArea from '@app/hooks/useMobileSafeArea';

const CssMobileSafeAreaVariable = () => {
  const {data} = useMobileSafeArea();

  return (
    <Global
      styles={css`
        :root {
          --mobile-safe-area-top: max(${data?.top ?? 0}px, env(safe-area-inset-top, 0));
          --mobile-safe-area-bottom: max(${data?.bottom ?? 0}px, env(safe-area-inset-bottom, 0));
          --mobile-safe-area-left: max(${data?.left ?? 0}px, env(safe-area-inset-left, 0));
          --mobile-safe-area-right: max(${data?.right ?? 0}px, env(safe-area-inset-right, 0));
        }
      `}
    />
  );
};

export default CssMobileSafeAreaVariable;
