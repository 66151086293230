import React, {useMemo} from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';

import {AlertProps, AlertDialog, AlertDialogProps} from '@santa-web/service-ui';
import {ALERT_DIALOG_Z_INDEX} from '@app/constants/zIndex';
import {useDialogContext} from '@app/contexts/DialogContext';

export type CourseLockedCellAlertDialogProps = AlertDialogProps;

export default React.memo<CourseLockedCellAlertDialogProps>(
  React.forwardRef<HTMLDivElement, CourseLockedCellAlertDialogProps>(function CourseLockedCellAlertDialog(
    {...props},
    ref
  ) {
    const {t} = useTranslation();
    const {closeDialog} = useDialogContext();

    const AlertProps = useMemo(
      (): AlertProps => ({
        title: t('dialog_course_locked_cell_alert_title'),
        description: t('dialog_course_locked_cell_alert_description'),
        neutralLabel: t('dialog_course_locked_cell_alert_neutral_label'),
        onNeutralClick: closeDialog,
      }),
      [closeDialog, t]
    );

    return (
      <AlertDialog
        ref={ref}
        css={css`
          z-index: ${ALERT_DIALOG_Z_INDEX};
        `}
        AlertProps={AlertProps}
        isVisible
        {...props}
      />
    );
  })
);
