import React from 'react';
import {css} from '@emotion/react';
import {COLOR_SANTA_O, COLOR_SANTA_G} from '@riiid/design-system';

import {Typography} from '@santa-web/service-ui';
import {DonutResultChartType} from '@app/components/DonutResultChart/types';
import {formatSeconds} from '@app/utils/text';

interface DonutResultChartLabelProps {
  chartType: DonutResultChartType;
  correctValue: number;
  totalValue: number;
}

const DonutResultChartLabel = ({
  chartType,
  correctValue,
  totalValue,
}: DonutResultChartLabelProps): JSX.Element | null => {
  if (chartType === 'count') {
    return (
      <Typography variant="body1" color={COLOR_SANTA_O} fontWeight="bold">
        {correctValue}
        <span
          data-testid="total-element"
          css={css`
            color: ${COLOR_SANTA_G};
          `}>{`/${totalValue}`}</span>
      </Typography>
    );
  }

  if (chartType === 'time') {
    return (
      <Typography variant="body2" color={COLOR_SANTA_O} fontWeight="regular">
        {formatSeconds(correctValue, 'mmt:ss')}
      </Typography>
    );
  }

  return null;
};

export default React.memo(DonutResultChartLabel);
