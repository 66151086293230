import {COLOR_SANTA_H} from '@riiid/design-system';

import {Typography} from '@santa-web/service-ui';

const ExamDistributionTick = ({
  x,
  y,
  payload: {value},
}: {
  x: number;
  y: number;
  payload: {
    value: number;
  };
}): React.ReactElement<SVGElement> => {
  return (
    <g transform={`translate(${x},${y})`}>
      <Typography
        x={0}
        y={0}
        dx="-1em"
        dy="0.75em"
        component="text"
        variant="caption2"
        fontWeight="regular"
        fill={COLOR_SANTA_H}>
        {value}
      </Typography>
    </g>
  );
};

export default ExamDistributionTick;
