import {useQuery, UseQueryResult} from '@tanstack/react-query';
import {useAtomValue} from 'jotai';

import santaAuthorizedOpenapiServicesAtom from '@app/atoms/core/santa-authorized-openapi-services';
import {getIsValidResetPasswordTokenQueryKey} from '@app/queryKeys';

const useIsValidResetPasswordToken = (token: string): UseQueryResult<boolean> => {
  const {AuthService} = useAtomValue(santaAuthorizedOpenapiServicesAtom);
  return useQuery(
    getIsValidResetPasswordTokenQueryKey(token),
    async () => {
      const {isValid} = await AuthService.validateResetPasswordToken({token});
      return isValid ?? false;
    },
    {
      enabled: Boolean(token),
    }
  );
};

export default useIsValidResetPasswordToken;
