/* tslint:disable */
/* eslint-disable */
import * as runtime from '../runtime';
import { GetMyNoteQuizResponseFromJSON, StartMyNoteQuizResponseFromJSON, } from '../models';
/**
 *
 */
export class MyNoteQuizV1Api extends runtime.BaseAPI {
    /**
     * 복습퀴즈 권한을 확인한다.
     * 복습퀴즈 권한을 확인한다.
     */
    async checkMyNoteQuizPermitRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/content-learning/v1/my-note-quiz/permit`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * 복습퀴즈 권한을 확인한다.
     * 복습퀴즈 권한을 확인한다.
     */
    async checkMyNoteQuizPermit(requestParameters, initOverrides) {
        await this.checkMyNoteQuizPermitRaw(requestParameters, initOverrides);
    }
    /**
     * 현재 학습 도메인에 대한 복습 퀴즈 진행 상황을 조회한다.
     * 복습 퀴즈 진행 상황을 조회한다.
     */
    async getMyNoteQuizRaw(requestParameters, initOverrides) {
        const queryParameters = {};
        const headerParameters = {};
        const response = await this.request({
            path: `/api/content-learning/v1/my-note-quiz/`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => GetMyNoteQuizResponseFromJSON(jsonValue));
    }
    /**
     * 현재 학습 도메인에 대한 복습 퀴즈 진행 상황을 조회한다.
     * 복습 퀴즈 진행 상황을 조회한다.
     */
    async getMyNoteQuiz(requestParameters, initOverrides) {
        const response = await this.getMyNoteQuizRaw(requestParameters, initOverrides);
        return await response.value();
    }
    /**
     * 주어진 id를 이용해 진행중인 복습 퀴즈를 리셋 시키고, 복습 퀴즈 진행 상황을 초기화 합니다.
     * 진행중인 복습 퀴즈를 리셋 시킵니다.
     */
    async resetMyNoteQuizRaw(requestParameters, initOverrides) {
        if (requestParameters.learningCellId === null || requestParameters.learningCellId === undefined) {
            throw new runtime.RequiredError('learningCellId', 'Required parameter requestParameters.learningCellId was null or undefined when calling resetMyNoteQuiz.');
        }
        const queryParameters = {};
        if (requestParameters.learningCellId !== undefined) {
            queryParameters['learningCellId'] = requestParameters.learningCellId;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/api/content-learning/v1/my-note-quiz/reset`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.VoidApiResponse(response);
    }
    /**
     * 주어진 id를 이용해 진행중인 복습 퀴즈를 리셋 시키고, 복습 퀴즈 진행 상황을 초기화 합니다.
     * 진행중인 복습 퀴즈를 리셋 시킵니다.
     */
    async resetMyNoteQuiz(requestParameters, initOverrides) {
        await this.resetMyNoteQuizRaw(requestParameters, initOverrides);
    }
    /**
     * 주어진 필터 선택지 및 콘텐츠 순서로 새로운 복습 퀴즈 학습 셀을 생성한다.
     * 새로운 복습 퀴즈를 시작한다.
     */
    async startMyNoteQuizRaw(requestParameters, initOverrides) {
        if (requestParameters.filterItemIds === null || requestParameters.filterItemIds === undefined) {
            throw new runtime.RequiredError('filterItemIds', 'Required parameter requestParameters.filterItemIds was null or undefined when calling startMyNoteQuiz.');
        }
        if (requestParameters.contentOrder === null || requestParameters.contentOrder === undefined) {
            throw new runtime.RequiredError('contentOrder', 'Required parameter requestParameters.contentOrder was null or undefined when calling startMyNoteQuiz.');
        }
        const queryParameters = {};
        if (requestParameters.filterItemIds) {
            queryParameters['filterItemIds'] = requestParameters.filterItemIds;
        }
        if (requestParameters.contentOrder !== undefined) {
            queryParameters['contentOrder'] = requestParameters.contentOrder;
        }
        const headerParameters = {};
        const response = await this.request({
            path: `/api/content-learning/v1/my-note-quiz/`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);
        return new runtime.JSONApiResponse(response, (jsonValue) => StartMyNoteQuizResponseFromJSON(jsonValue));
    }
    /**
     * 주어진 필터 선택지 및 콘텐츠 순서로 새로운 복습 퀴즈 학습 셀을 생성한다.
     * 새로운 복습 퀴즈를 시작한다.
     */
    async startMyNoteQuiz(requestParameters, initOverrides) {
        const response = await this.startMyNoteQuizRaw(requestParameters, initOverrides);
        return await response.value();
    }
}
