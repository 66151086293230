const TESTPREP_VIEW_MAX_WIDTH = {
  NARROW: '680px' as const,
  WIDE: '1360px' as const,
} as const;

export const VOCA_VIEW_MAX_WIDTH = TESTPREP_VIEW_MAX_WIDTH.NARROW;
export const BASIC_SENTENCE_COMPLETION_VIEW_MAX_WIDTH = TESTPREP_VIEW_MAX_WIDTH.NARROW;
export const TOEIC_PART_1_TO_4_VIEW_MAX_WIDTH = TESTPREP_VIEW_MAX_WIDTH.NARROW;
export const TOEIC_PART_5_VIEW_MAX_WIDTH = TESTPREP_VIEW_MAX_WIDTH.NARROW;
export const TOEIC_PART_6_TO_7_VIEW_MAX_WIDTH = TESTPREP_VIEW_MAX_WIDTH.WIDE;
export const VOCA_QUESTION_VIEW_MAX_WIDTH = TESTPREP_VIEW_MAX_WIDTH.NARROW;

export const GTELP_GRAMMAR_QUESTION_VIEW_MAX_WIDTH = TESTPREP_VIEW_MAX_WIDTH.NARROW;
export const GTELP_LISTENING_QUESTION_VIEW_MAX_WIDTH = TESTPREP_VIEW_MAX_WIDTH.NARROW;
export const GTELP_READING_AND_VOCABULARY_QUESTION_VIEW_MAX_WIDTH = TESTPREP_VIEW_MAX_WIDTH.WIDE;

export const TESTPREP_SECOND_CONTENT_DEFAULT_HEIGHT = '40%';
export const TESTPREP_SECOND_CONTENT_MIN_HEIGHT = 28;
export const TESTPREP_SECOND_CONTENT_MAX_HEIGHT = '100%';
