import React from 'react';

import {SingleQuestionTitle} from '@santa-web/service-ui';
import DirectionContent from '@app/components/virtual-exam/VirtualExamDirectionView/components/DirectionContent';
import DirectionWrapper from '@app/components/virtual-exam/VirtualExamDirectionView/components/DirectionWrapper';

const Part7DirectionView = React.forwardRef<HTMLDivElement, React.ComponentPropsWithoutRef<'div'>>((props, ref) => {
  return (
    <DirectionWrapper gap={24} {...props} ref={ref}>
      <SingleQuestionTitle content={'PART 7'} />
      <DirectionContent>
        <b>Directions:</b> In this part you will read a selection of texts, such as magazine and newspaper articles,
        e-mails, and instant messages. Each text or set of texts is followed by several questions. Select the best
        answer for each question and mark the letter (A), (B), (C), or (D) on your answer sheet.
      </DirectionContent>
    </DirectionWrapper>
  );
});

export default Part7DirectionView;
