import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {
  COLOR_FIX_SPACE_BLUE_GRAY_6,
  COLOR_FIX_SPACE_BLUE_GRAY_8,
  COLOR_FIX_SANTA_WHITE_1,
  COLOR_FIX_SANTA_DANGER_2,
  COLOR_FIX_SANTA_DANGER_4,
  COLOR_FIX_SANTA_GRAY_5,
  COLOR_SANTA_E,
} from '@riiid/design-system';
import ContactIcon from '@riiid/design-system/icons/Contact';
import {Typography, getLineClampCss} from '@santa-web/service-ui';

export type TutorQuestionButtonProps = {
  isCorrect?: boolean;
  isDisabled?: boolean;
  onClick: React.MouseEventHandler<HTMLButtonElement>;
};

const TutorQuestionButton = ({isCorrect = false, isDisabled = false, onClick}: TutorQuestionButtonProps) => {
  const {t} = useTranslation();
  return (
    <button
      onClick={onClick}
      disabled={isDisabled}
      css={[
        css`
          display: flex;
          width: 100%;
          padding: 10px 20px;
          justify-content: center;
          align-items: center;
          gap: 8px;
          border: 0;
          border-radius: 100px;
          color: ${COLOR_FIX_SANTA_WHITE_1};
          background: linear-gradient(273deg, ${COLOR_FIX_SANTA_DANGER_2}, ${COLOR_FIX_SANTA_DANGER_4});
        `,
        isCorrect &&
          css`
            background: linear-gradient(273deg, ${COLOR_FIX_SPACE_BLUE_GRAY_6}, ${COLOR_FIX_SPACE_BLUE_GRAY_8});
          `,
        isDisabled &&
          css`
            color: ${COLOR_FIX_SANTA_GRAY_5};
            background: ${COLOR_SANTA_E};
          `,
      ]}>
      <ContactIcon type="line" size="L" height={20} width={20} />
      <Typography
        variant="caption1"
        fontWeight="bold"
        css={[
          getLineClampCss(2),
          css`
            max-width: calc(100% - 28px);
          `,
        ]}>
        {isCorrect ? t('tutor_question_button_correct') : t('tutor_question_button_wrong')}
      </Typography>
    </button>
  );
};

export default React.memo(TutorQuestionButton);
