import TestPrepLayout, {TestPrepLayoutProps} from '@app/test-preparation/components/TestPrepLayout';
import {GTELP_READING_AND_VOCABULARY_QUESTION_VIEW_MAX_WIDTH} from '@app/test-preparation/constants/test-preparation-layout';

export type GtelpReadingAndVocabularyQuestionViewLayoutProps = TestPrepLayoutProps;

const GtelpReadingAndVocabularyQuestionViewLayout = ({
  children,
  ...rest
}: GtelpReadingAndVocabularyQuestionViewLayoutProps) => {
  return (
    <TestPrepLayout maxWidth={GTELP_READING_AND_VOCABULARY_QUESTION_VIEW_MAX_WIDTH} {...rest}>
      {children}
    </TestPrepLayout>
  );
};

export default GtelpReadingAndVocabularyQuestionViewLayout;
