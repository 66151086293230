import {GrpcWebError, Status} from '@pbkit/grpc-web-client';

export function isGrpcError(error: unknown): error is GrpcWebError {
  return error instanceof GrpcWebError;
}

export function isNotFoundGrpcError(error: unknown): error is GrpcWebError {
  return isGrpcError(error) && error.status === Status.NOT_FOUND;
}

export function isResourceExhaustedGrpcError(error: unknown): error is GrpcWebError {
  return isGrpcError(error) && error.status === Status.RESOURCE_EXHAUSTED;
}

export function isInvalidArgumentGrpcError(error: unknown): error is GrpcWebError {
  return isGrpcError(error) && error.status === Status.INVALID_ARGUMENT;
}

export function isAlreadyExistsGrpcError(error: unknown): error is GrpcWebError {
  return isGrpcError(error) && error.status === Status.ALREADY_EXISTS;
}

export function isPermissionDeniedGrpcError(error: unknown): error is GrpcWebError {
  return isGrpcError(error) && error.status === Status.PERMISSION_DENIED;
}

export function isOutOfRangeGrpcError(error: unknown): boolean {
  return isGrpcError(error) && error.status === Status.OUT_OF_RANGE;
}
