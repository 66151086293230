/**
 * Check if a given object implements the PointRewardRankEntry interface.
 */
export function instanceOfPointRewardRankEntry(value) {
    let isInstance = true;
    isInstance = isInstance && "point" in value;
    isInstance = isInstance && "rank" in value;
    isInstance = isInstance && "username" in value;
    return isInstance;
}
export function PointRewardRankEntryFromJSON(json) {
    return PointRewardRankEntryFromJSONTyped(json, false);
}
export function PointRewardRankEntryFromJSONTyped(json, ignoreDiscriminator) {
    if ((json === undefined) || (json === null)) {
        return json;
    }
    return {
        'point': json['point'],
        'rank': json['rank'],
        'username': json['username'],
    };
}
export function PointRewardRankEntryToJSON(value) {
    if (value === undefined) {
        return undefined;
    }
    if (value === null) {
        return null;
    }
    return {
        'point': value.point,
        'rank': value.rank,
        'username': value.username,
    };
}
