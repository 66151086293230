import produce from 'immer';
import {atom} from 'jotai';

import {cisService} from '@app/test-preparation/atoms/services';

const toggleCisBookmarkAtom = atom(null, async (get, set) => {
  const cis = get(cisService.cisAtom);

  const nextCis = produce(cis, draft => {
    draft.isBookmarked = !draft.isBookmarked;
  });

  await set(cisService.updateCisAtom, {cis: nextCis});
});

export {toggleCisBookmarkAtom};
