import React from 'react';
import {css} from '@emotion/react';

export type LegendDotVariant = 'square' | 'circle';

export interface DotProps {
  color: string;
  variant: LegendDotVariant;
}

const Dot = React.forwardRef<HTMLDivElement, DotProps>(({color, variant, ...props}, ref) => {
  return (
    <div
      data-testid={`Legend-Dot`}
      css={[
        css`
          margin-right: 8px;
          width: 10px;
          height: 10px;
          background-color: ${color};
        `,
        variant === 'circle' &&
          css`
            border-radius: 100%;
          `,
      ]}
      {...props}
      ref={ref}
    />
  );
});

export default React.memo(Dot);
