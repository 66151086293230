function _tagged_template_literal(strings, raw) {
    if (!raw) {
        raw = strings.slice(0);
    }
    return Object.freeze(Object.defineProperties(strings, {
        raw: {
            value: Object.freeze(raw)
        }
    }));
}
function _templateObject() {
    var data = _tagged_template_literal([
        "\n  display: -moz-box;\n  display: -webkit-box;\n  display: box;\n\n  -webkit-line-clamp: ",
        ";\n  line-clamp: ",
        ";\n\n  -moz-box-orient: vertical;\n  -webkit-box-orient: vertical;\n  box-orient: vertical;\n\n  overflow: hidden;\n  text-overflow: ellipsis;\n\n  ",
        ";\n"
    ]);
    _templateObject = function _templateObject() {
        return data;
    };
    return data;
}
function _templateObject1() {
    var data = _tagged_template_literal([
        "\n  position: absolute;\n  top: 50%;\n  left: 50%;\n  transform: translate(-50%, -50%);\n"
    ]);
    _templateObject1 = function _templateObject() {
        return data;
    };
    return data;
}
import { css } from "@emotion/react";
export var getLineClampCss = function(lineNumber, dotColor) {
    return css(_templateObject(), lineNumber, lineNumber, dotColor ? "color: ".concat(dotColor) : "");
};
export var centerAlignInNearestPositionedAncestor = css(_templateObject1());
