import React from 'react';
import {LoadingDim} from '@app/components';
import {
  OfferGroupOrderDetailView,
  useOfferGroupOrderDetailViewProps,
  useOfferGroupOrderQuery,
} from '@app/features/offer';
import {useTypedSearchParams} from '@app/hooks/useTypedRouter';

const OfferGroupOrderResultPageContainer = () => {
  const searchParams = useTypedSearchParams('/offer-group/order/result');
  const {id} = searchParams;
  const {data: order} = useOfferGroupOrderQuery(id);

  const viewProps = useOfferGroupOrderDetailViewProps(id, true);

  if (!viewProps || !order) {
    return <LoadingDim />;
  }

  return <OfferGroupOrderDetailView {...viewProps} />;
};

export default OfferGroupOrderResultPageContainer;
OfferGroupOrderResultPageContainer.displayName = 'OfferGroupOrderResultPageContainer';
