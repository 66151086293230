import React from 'react';
import BookmarkIcon from '@riiid/design-system/icons/Bookmark';

import {useAtomValue} from 'jotai';
import {
  BottomToolList as BottomToolListBase,
  BottomToolListProps as BottomToolListBaseProps,
  IconButton,
  IconButtonProps,
} from '@santa-web/service-ui';
import TestprepConfigBottomSheetList from '@app/components/ContentView/components/BottomToolList/TestprepConfigBottomSheetList';
import TestprepConfigIconButton from '@app/components/ContentView/components/BottomToolList/TestprepConfigIconButton';
import {learningStore} from '@app/test-preparation/atoms/stores';
import {useTestprepConfigContext} from '@app/test-preparation/contexts/TestprepConfigContext';
import useFeatureAvailableByDomain from '@app/test-preparation/hooks/feature-available/useFeatureAvailableByDomain';

export interface BottomToolListProps extends BottomToolListBaseProps {
  IconButtonProps?: Partial<IconButtonProps>;
  onClickBookmark?: IconButtonProps['onClick'];
  shouldShowConfigTooltip?: boolean;
}

const BottomToolList = React.forwardRef<HTMLDivElement, BottomToolListProps>(
  ({IconButtonProps, onClickBookmark, children, shouldShowConfigTooltip = true, ...props}, ref) => {
    const {fontSize} = useTestprepConfigContext();
    const isBookmarkAvailableByDomain = useFeatureAvailableByDomain('bookmark');
    const [isTestprepConfigSwitchableBottomSheetOpened, setIsTestprepConfigSwitchableBottomSheetOpened] =
      React.useState(false);

    const isBookmarked = useAtomValue(learningStore.cis.isBookmarked);

    const bookmarkIconProps: IconButtonProps['IconProps'] = {
      ...IconButtonProps?.IconProps,
      type: isBookmarked ? 'solid' : 'line',
    };

    const handleTestprepConfigSwitchableIconButtonClick = React.useCallback(() => {
      setIsTestprepConfigSwitchableBottomSheetOpened(true);
    }, []);

    const handleTestprepConfigSwitchableBottomSheetCloseClick = React.useCallback(() => {
      setIsTestprepConfigSwitchableBottomSheetOpened(false);
    }, []);

    return (
      <>
        <BottomToolListBase ref={ref} {...props}>
          {isBookmarkAvailableByDomain ? (
            <IconButton
              {...IconButtonProps}
              Icon={BookmarkIcon}
              IconProps={bookmarkIconProps}
              onClick={onClickBookmark}
            />
          ) : null}
          {children}
          <TestprepConfigIconButton
            {...IconButtonProps}
            onClick={handleTestprepConfigSwitchableIconButtonClick}
            shouldShowConfigTooltip={shouldShowConfigTooltip}
          />
        </BottomToolListBase>
        <TestprepConfigBottomSheetList
          isOpened={isTestprepConfigSwitchableBottomSheetOpened}
          onClickClose={handleTestprepConfigSwitchableBottomSheetCloseClick}
        />
        {fontSize?.StyleOverrides}
      </>
    );
  }
);

export default React.memo(BottomToolList);
