function _define_property(obj, key, value) {
    if (key in obj) {
        Object.defineProperty(obj, key, {
            value: value,
            enumerable: true,
            configurable: true,
            writable: true
        });
    } else {
        obj[key] = value;
    }
    return obj;
}
function _object_spread(target) {
    for(var i = 1; i < arguments.length; i++){
        var source = arguments[i] != null ? arguments[i] : {};
        var ownKeys = Object.keys(source);
        if (typeof Object.getOwnPropertySymbols === "function") {
            ownKeys = ownKeys.concat(Object.getOwnPropertySymbols(source).filter(function(sym) {
                return Object.getOwnPropertyDescriptor(source, sym).enumerable;
            }));
        }
        ownKeys.forEach(function(key) {
            _define_property(target, key, source[key]);
        });
    }
    return target;
}
function ownKeys(object, enumerableOnly) {
    var keys = Object.keys(object);
    if (Object.getOwnPropertySymbols) {
        var symbols = Object.getOwnPropertySymbols(object);
        if (enumerableOnly) {
            symbols = symbols.filter(function(sym) {
                return Object.getOwnPropertyDescriptor(object, sym).enumerable;
            });
        }
        keys.push.apply(keys, symbols);
    }
    return keys;
}
function _object_spread_props(target, source) {
    source = source != null ? source : {};
    if (Object.getOwnPropertyDescriptors) {
        Object.defineProperties(target, Object.getOwnPropertyDescriptors(source));
    } else {
        ownKeys(Object(source)).forEach(function(key) {
            Object.defineProperty(target, key, Object.getOwnPropertyDescriptor(source, key));
        });
    }
    return target;
}
import React from "react";
import visitLeafNode from "./visitLeafNode";
var createTrieNode = function(_id) {
    var children = {};
    var self = {
        add: function add(key, node) {
            children[key] = node;
        },
        next: function next(key) {
            var _children_key;
            return (_children_key = children[key]) !== null && _children_key !== void 0 ? _children_key : null;
        },
        get id () {
            return _id;
        }
    };
    return self;
};
var createTrie = function(rrtv2) {
    var idCount = 1;
    var root = createTrieNode(0);
    var self = {
        createNode: function createNode() {
            return createTrieNode(idCount++);
        },
        addPath: function addPath(path) {
            var node = root;
            var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
            try {
                for(var _iterator = path[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
                    var p = _step.value;
                    if (!node.next(p)) {
                        node.add(p, this.createNode());
                    }
                    node = node.next(p);
                }
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally{
                try {
                    if (!_iteratorNormalCompletion && _iterator.return != null) {
                        _iterator.return();
                    }
                } finally{
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
            }
        },
        getNode: function getNode(path) {
            var node = root;
            var _iteratorNormalCompletion = true, _didIteratorError = false, _iteratorError = undefined;
            try {
                for(var _iterator = path[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true){
                    var p = _step.value;
                    var nextNode = node.next(p);
                    if (!nextNode) return null;
                    node = nextNode;
                }
            } catch (err) {
                _didIteratorError = true;
                _iteratorError = err;
            } finally{
                try {
                    if (!_iteratorNormalCompletion && _iterator.return != null) {
                        _iterator.return();
                    }
                } finally{
                    if (_didIteratorError) {
                        throw _iteratorError;
                    }
                }
            }
            return node;
        }
    };
    visitLeafNode(rrtv2, function(param) {
        var path = param.path;
        self.addPath(path);
    });
    return self;
};
export var createTreeHelper = function(rrtv2Json, meta) {
    var trie = createTrie(rrtv2Json);
    var taggedHighlights = [];
    var self = {
        getNodeId: function getNodeId(path) {
            var node = trie.getNode(path);
            return node ? node.id : null;
        },
        getHighlights: function getHighlights(path) {
            var id = self.getNodeId(path);
            if (id != null) {
                return taggedHighlights.filter(function(highlight) {
                    var left = Math.min(highlight.anchorNodeId, highlight.focusNodeId);
                    var right = Math.max(highlight.anchorNodeId, highlight.focusNodeId);
                    return left <= id && id <= right;
                });
            }
            return [];
        }
    };
    taggedHighlights = meta.highlights.flatMap(function(highlight) {
        var anchorNodeId = self.getNodeId(highlight.anchor.path);
        var focusNodeId = self.getNodeId(highlight.focus.path);
        if (anchorNodeId != null && focusNodeId != null) {
            return [
                _object_spread_props(_object_spread({}, highlight), {
                    anchorNodeId: anchorNodeId,
                    focusNodeId: focusNodeId
                })
            ];
        }
        return [];
    });
    return self;
};
export var TreeHelperContext = React.createContext(null);
export var useTreeHelperContext = function() {
    return React.useContext(TreeHelperContext);
};
