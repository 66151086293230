import {atom} from 'jotai';
import {ContentInteractionState} from '@santa-web/gen/open-api/content-learning-service';
import {setAtomLabels} from '@app/misc/atom-label';
import {ServiceNotInitializedError} from '@app/test-preparation/atoms/services/shared/errors/not-initialized';

const _cisesAtom = atom<Array<ContentInteractionState> | null>(null);
const cisesAtom = atom(
  get => {
    const cises = get(_cisesAtom);

    if (cises === null) {
      throw new ServiceNotInitializedError('CisService');
    }

    return cises;
  },
  (get, set, cises: Array<ContentInteractionState>) => {
    set(_cisesAtom, cises);
  }
);

const elapsedTimeMapAtom = atom<Record<string, number>>({});

const _currentCisIndexAtom = atom<number | null>(null);
const currentCisIndexAtom = atom(
  get => {
    const currentCisIndex = get(_currentCisIndexAtom);

    if (currentCisIndex === null) {
      throw new ServiceNotInitializedError('CisService');
    }

    return currentCisIndex;
  },
  (get, set, currentCisIndex: number) => {
    set(_currentCisIndexAtom, currentCisIndex);
  }
);
const _startCisIndexAtom = atom<number | null>(null);
const startCisIndexAtom = atom(
  get => {
    const startCisIndex = get(_startCisIndexAtom);

    if (startCisIndex === null) {
      throw new ServiceNotInitializedError('CisService');
    }

    return startCisIndex;
  },
  (get, set, startCisIndex: number) => {
    set(_startCisIndexAtom, startCisIndex);
  }
);

const cisAtom = atom(get => {
  const cises = get(cisesAtom);
  const currentCisIndex = get(currentCisIndexAtom);

  return cises[currentCisIndex];
});

export {elapsedTimeMapAtom, cisesAtom, currentCisIndexAtom, startCisIndexAtom, cisAtom};

setAtomLabels(
  {
    _cisesAtom,
    cisesAtom,
    elapsedTimeMapAtom,
    _currentCisIndexAtom,
    currentCisIndexAtom,
    _startCisIndexAtom,
    startCisIndexAtom,
    cisAtom,
  },
  'cisService.'
);
