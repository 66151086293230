import React from 'react';
import {useTranslation} from 'react-i18next';
import {css} from '@emotion/react';
import {COLOR_FIX_SANTA_BLACK_1_ALPHA, COLOR_FIX_SANTA_WHITE_1} from '@riiid/design-system';

import {Label, Typography} from '@santa-web/service-ui';
import {SkeletonGrayBox} from '@app/components';
import ResponsiveImage, {ImageSet} from '@app/components/ResponsiveImage';
import {withSkeleton} from '@app/utils/component';

export interface EventListCardOwnProps {
  isEnded?: boolean;
  thumbnailTitle?: string | null | undefined;
  thumbnailDescription?: string | null | undefined;
  thumbnailImageSet?: ImageSet;
}
export interface EventListCardProps extends React.ComponentPropsWithoutRef<'button'>, EventListCardOwnProps {
  onClick: () => void;
}

const EventListCard = React.forwardRef<HTMLButtonElement, EventListCardProps>(
  ({isEnded, thumbnailTitle, thumbnailDescription, thumbnailImageSet, onClick, ...props}, ref) => {
    const {t} = useTranslation();

    return (
      <button
        css={css`
          position: relative;
          width: 100%;
          height: 120px;

          display: flex;
          justify-content: center;

          border: none;
          border-radius: 4px;
          overflow: hidden;
        `}
        ref={ref}
        onClick={onClick}
        {...props}>
        <ResponsiveImage
          imageSet={thumbnailImageSet}
          css={css`
            height: 100%;
          `}
        />
        {isEnded && (
          <>
            <div
              css={css`
                position: absolute;
                width: 100%;
                height: 100%;
                top: 0;
                left: 0;
                border-radius: 4px;
                background-color: ${COLOR_FIX_SANTA_BLACK_1_ALPHA(0.3)};
                padding: 20px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                text-align: center;
              `}>
              {thumbnailTitle && thumbnailDescription && (
                <>
                  <Typography component="p" variant="body2" fontWeight="bold" color={COLOR_FIX_SANTA_WHITE_1}>
                    {thumbnailTitle}
                  </Typography>
                  <Typography
                    component="p"
                    variant="caption1"
                    fontWeight="regular"
                    color={COLOR_FIX_SANTA_WHITE_1}
                    css={css`
                      margin-top: 2px;
                    `}>
                    {thumbnailDescription}
                  </Typography>
                </>
              )}
              <Label
                variant="small"
                colorVariant="dark"
                css={css`
                  position: absolute;
                  top: 12px;
                  right: 12px;
                `}>
                {t('event_info_end')}
              </Label>
            </div>
          </>
        )}
      </button>
    );
  }
);

const Skeleton = () => {
  return <SkeletonGrayBox width="100%" height={160} />;
};

export default React.memo(withSkeleton(EventListCard, Skeleton));
